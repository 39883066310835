
import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Actions, Getters} from '@/modules/projects/store/types/StoreTypes';
import {
  IncomplianceStatus,
  ProjectCompliance,
  SocialSecurityRegistration,
  SocialSecurityRegistrationCompliance,
  SocialSecurityRegistrationProject,
  SocialSecurityRegistrationStatus
} from '@/modules/projects/types/social-security-registrations';
import {ProjectStatusMixin} from '@/mixins/project-status-mixin.component';
import {GetAddressMixin} from '@/mixins/get-address-mixin.component';
import dayjs from 'dayjs';
import SocialSecurityRegistrationListItem
  from '@/modules/projects/components/social-security-registration-list-item.component.vue';
import SocialSecurityRegistrationListGroup
  from '@/modules/projects/components/social-security-registration-list-group.component.vue';
import {Project} from '../../types/entities';
import {sortNatural} from "@/core/helpers/Sort";

const projects = namespace("projects")

@Component({
  components: {
    'social-security-registration-list-group': SocialSecurityRegistrationListGroup,
    'social-security-registration-list-item': SocialSecurityRegistrationListItem,
  },
  mixins: [
    ProjectStatusMixin,
    GetAddressMixin
  ],
})
export default class SocialSecurityRegistrationsComponent extends Vue {

  // store actions
  @projects.Action(Actions.FETCH_SOCIAL_SECURITY_REGISTRATIONS) fetchSocialSecurityRegistrations!: () => Promise<SocialSecurityRegistration[]>;
  @projects.Action(Actions.FETCH_PROJECT_BY_ID) fetchProject!: (payload: string) => Promise<void>;
  @projects.Action(Actions.EDIT_PROJECT_BY_ID) saveProject!: (payload: Project) => Promise<void>;
  @projects.Action(Actions.EDIT_SOCIAL_SECURITY_REGISTRATION_BY_ID) saveSocialSecurityRegistration!: (payload: SocialSecurityRegistration) => Promise<SocialSecurityRegistration>;

  // store getters
  @projects.Getter(Getters.GET_LOADING) isLoading!: boolean;
  @projects.Getter(Getters.GET_SOCIAL_SECURITY_REGISTRATIONS) socialSecurityRegistrations!: SocialSecurityRegistration[];
  @projects.Getter(Getters.GET_PROJECT_DETAILS) project!: Project;

  // props

  // local state
  state: {
    numberOfDaysShownUntilDue: number,
    expandedProjects: number[],
    expandedProjectsObj: { [key: number]: boolean },
    editProjectSocialSecurityReferences: any,
    editSocialSecurityRegistration: SocialSecurityRegistration | null,
    showCompliant: boolean,
    enableAutoSort: boolean,
  } = {
    numberOfDaysShownUntilDue: 21,
    expandedProjects: [],
    expandedProjectsObj: {},
    editProjectSocialSecurityReferences: {},
    editSocialSecurityRegistration: null,
    showCompliant: true,
    enableAutoSort: true,
  }
  content: {} = {}

  /**
   * A yard is not in compliance when:
   *     - The yard is not declared ( = no identification number & no declared dates)
   *     - There are one or more subcontractors who are not declared on the digital form
   *     - There is a subcontractor who was registered on the social security, but is deleted completely
   *       in the planning. This contractor will need to be also deleted on the digital form of the social
   *       security. When a contractor is deleted from one type of work, but is also assigned to another
   *       type, this yard is still in compliance and has not to be shown in the list NOK. This yard should
   *       be on top of the yards not in compliance
   *     - The end date of the yard, indicated on the social security, will be exceeded by a contractor
   *       in the planning
   * A yard is in compliance when:
   *   - The declaration is fully complete and up to date
   *     - The yard is declared finished or declared excluded from declaration. On the module there is
   *       a toggle provided to indicate that the yard is finished. When declared finished, a yard is
   *       always in compliance, even when there are still contractors on site who exceeds the end
   *       date of the RSZ.
   */
  get ssrCompliances(): SocialSecurityRegistrationCompliance[] {
    const ssrCompliances = this.socialSecurityRegistrations.map(ssr => {
      const ssrCompliance = {
        ...ssr,
        daysUntilDue: -1,
        showDaysUntilDue: false,
        complianceStatus: null,
        isCompliant: false
      } as SocialSecurityRegistrationCompliance
      const contractorStartDate = ssr.contractorStartDate ? dayjs.utc(ssr.contractorStartDate) : null
      const contractorEndDate = ssr.contractorEndDate ? dayjs.utc(ssr.contractorEndDate) : null
      const socialSecurityStartDate = ssr.project.socialSecurityStartDate ? dayjs.utc(ssr.project.socialSecurityStartDate) : null
      const socialSecurityEndDate = ssr.project.socialSecurityEndDate ? dayjs.utc(ssr.project.socialSecurityEndDate) : null
      const complianceStatuses = []

      // general compliance rules (project data & ssr start- and end-date should be provided)
      if (!ssrCompliance.complianceStatus && !(ssr.project.socialSecurityStartDate && ssr.project.socialSecurityEndDate && ssr.project.socialSecurityReference)) complianceStatuses.push(IncomplianceStatus.INCOMPLETE_PROJECT_SSR_DATA) //ssrCompliance.complianceStatus = IncomplianceStatus.INCOMPLETE_PROJECT_SSR_DATA
      if (!ssrCompliance.complianceStatus && (!(contractorStartDate && contractorEndDate))) complianceStatuses.push(IncomplianceStatus.INCOMPLETE_PLANNER_DATA) //ssrCompliance.complianceStatus = IncomplianceStatus.INCOMPLETE_PLANNER_DATA

      // compliance rules specifically for contractor ssr's
      if (socialSecurityStartDate && socialSecurityEndDate && contractorStartDate && contractorEndDate) {
        if (!ssrCompliance.complianceStatus && contractorStartDate.isBefore(socialSecurityStartDate) && contractorStartDate.isBefore(socialSecurityEndDate) && contractorEndDate.isAfter(socialSecurityStartDate) && contractorEndDate.isAfter(socialSecurityEndDate)) complianceStatuses.push(IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_AND_END_DATE) //ssrCompliance.complianceStatus = IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_AND_END_DATE
        if (!ssrCompliance.complianceStatus && contractorStartDate.isBefore(socialSecurityStartDate) && contractorStartDate.isBefore(socialSecurityEndDate) && contractorEndDate.isBefore(socialSecurityStartDate) && contractorEndDate.isBefore(socialSecurityEndDate)) complianceStatuses.push(IncomplianceStatus.CONTRACTOR_PERIOD_BEFORE_SOCIAL_SECURITY_START_DATE) //ssrCompliance.complianceStatus = IncomplianceStatus.CONTRACTOR_PERIOD_BEFORE_SOCIAL_SECURITY_START_DATE
        if (!ssrCompliance.complianceStatus && contractorStartDate.isBefore(socialSecurityStartDate) && contractorStartDate.isBefore(socialSecurityEndDate) && contractorEndDate.isAfter(socialSecurityStartDate) && contractorEndDate.isBefore(socialSecurityEndDate)) complianceStatuses.push(IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_DATE) //ssrCompliance.complianceStatus = IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_DATE
        if (!ssrCompliance.complianceStatus && (contractorStartDate.isAfter(socialSecurityStartDate) || contractorStartDate.isSame(socialSecurityStartDate)) && contractorStartDate.isBefore(socialSecurityEndDate) && contractorEndDate.isAfter(socialSecurityStartDate) && (contractorEndDate.isBefore(socialSecurityEndDate) || contractorEndDate.isSame(socialSecurityEndDate))) complianceStatuses.push(IncomplianceStatus.CONTRACTOR_PERIOD_BETWEEN_SOCIAL_SECURITY_START_AND_END_DATE) //ssrCompliance.complianceStatus = IncomplianceStatus.CONTRACTOR_PERIOD_BETWEEN_SOCIAL_SECURITY_START_AND_END_DATE
        if (!ssrCompliance.complianceStatus && contractorStartDate.isAfter(socialSecurityStartDate) && contractorStartDate.isBefore(socialSecurityEndDate) && contractorEndDate.isAfter(socialSecurityStartDate) && contractorEndDate.isAfter(socialSecurityEndDate)) complianceStatuses.push(IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_END_DATE)// ssrCompliance.complianceStatus = IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_END_DATE
        if (!ssrCompliance.complianceStatus && contractorStartDate.isAfter(socialSecurityStartDate) && contractorStartDate.isAfter(socialSecurityEndDate) && contractorEndDate.isAfter(socialSecurityStartDate) && contractorEndDate.isAfter(socialSecurityEndDate)) complianceStatuses.push(IncomplianceStatus.CONTRACTOR_PERIOD_AFTER_SOCIAL_SECURITY_END_DATE)// ssrCompliance.complianceStatus = IncomplianceStatus.CONTRACTOR_PERIOD_AFTER_SOCIAL_SECURITY_END_DATE
      }

      // take most important compliance issue (sort in descending order)
      complianceStatuses.sort((a, b) => b - a)
      ssrCompliance.complianceStatus = complianceStatuses.length ? complianceStatuses[0] : null

      /**
       * Calculate the days until due
       *   - this is the relative amount of days calculated from now() until the start date of the contractor period
       *   REMARK: using the end date here would not be correct as the start date of the contracting period
       *   is more important to comply with the social security guidelines.
       * */
      if (ssrCompliance.complianceStatus && contractorStartDate) {
        ssrCompliance.daysUntilDue = contractorStartDate.diff(dayjs(), 'day')
      }

      ssrCompliance.isCompliant = this.isSsrCompliant(ssrCompliance)
      ssrCompliance.showDaysUntilDue = this.showDaysUntilDueForSsr(ssrCompliance)

      return ssrCompliance
    }, [])

    return ssrCompliances
  }

  get projectCompliances() {
    const projectCompliances = this.projects.map(project => {
      const projectCompliance = {...project, isDue: false, isCompliant: false} as ProjectCompliance
      const projectSsrCompliances = this.ssrCompliances
        .filter(ssrCompliance => ssrCompliance.project.id === project.id)
        .sort((a, b) => (a.complianceStatus && b.complianceStatus) ? b.complianceStatus - a.complianceStatus : 0)
      const ssrCompliances = projectSsrCompliances.filter(ssrCompliance => ssrCompliance.isCompliant) || []

      // We sort (asc) the incompliances by 'daysUntilDue' so the first ssr in the list is the worst
      // and wil be taken as project daysUntilDue.
      const ssrIncompliances = (projectSsrCompliances.filter(ssrCompliance => !ssrCompliance.isCompliant) || [])
          .sort((a, b) => a.daysUntilDue - b.daysUntilDue)

      projectCompliance.ssrCompliances = projectSsrCompliances
      projectCompliance.ssrIncompliances = ssrIncompliances
      projectCompliance.daysUntilDue = ssrIncompliances.length ? ssrIncompliances[0].daysUntilDue : 0;
      projectCompliance.isCompliant = ssrIncompliances.length === 0 || project.socialSecurityCompleted
      return projectCompliance
    }, [])
    return projectCompliances
  }

  get filteredProjectCompliances() {
    return this.projectCompliances &&
      this.projectCompliances.length &&
      this.projectCompliances.filter(projectCompliance => (this.state.showCompliant && projectCompliance.isCompliant) || (!projectCompliance.isCompliant && projectCompliance.daysUntilDue <= this.state.numberOfDaysShownUntilDue))
  }

  get items() {
    return this.filteredProjectCompliances && this.filteredProjectCompliances.length ? this.state.enableAutoSort ? this.filteredProjectCompliances.sort(this.projectComplianceSorter) : this.filteredProjectCompliances : []
  }

  get showComplianceDueDate() {
    return dayjs().add(this.state.numberOfDaysShownUntilDue, 'day').format('YYYY-MM-DD')
  }

  get hasExpandedProjects() {
    return Object.entries(this.state.expandedProjectsObj).map((v) => v[1]).includes(true)
  }

  get projects() {
    const uniqueProjects = [] as SocialSecurityRegistrationProject[]
    this.socialSecurityRegistrations.forEach(ssr => {
      if (ssr.project && uniqueProjects.findIndex(uniqueProject => uniqueProject.id === ssr.project.id) <= -1) uniqueProjects.push(ssr.project)
    })
    return uniqueProjects
  }

  get dataTableHeaders() {
    return [
      ...(this.socialSecurityRegistrationsHeaders.map(c => {
        return {...c, cssClass: c.cssClass + ' text-start'}
      })),
    ];
  }

  get socialSecurityRegistrationsHeaders() {
    return [
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.projectName').toString(),
        width: '110px',
        value: 'projectName',
        cssClass: '',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.projectLeader').toString(),
        value: 'projectLeader',
        cssClass: '',
        width: '110px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.projectProgressPrecentage').toString(),
        value: 'projectProgressPrecentage',
        cssClass: '',
        width: '110px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.projectStartDate').toString(),
        value: 'projectStartDate',
        cssClass: '',
        width: '110px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.projectEndDate').toString(),
        value: 'projectEndDate',
        cssClass: 'border-right',
        width: '110px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.socialSecurityReference').toString(),
        value: 'socialSecurityReference',
        cssClass: '',
        width: '250px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.socialSecurityStartDate').toString(),
        value: 'socialSecurityStartDate',
        cssClass: '',
        width: '110px',
      },

      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.socialSecurityEndDate').toString(),
        value: 'socialSecurityEndDate',
        cssClass: '',
        width: '110px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.projectSocialSecurityStatus').toString(),
        value: 'projectSocialSecurityStatus',
        cssClass: 'border-right',
        width: '270px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.socialSecurityRegistrationStatus').toString(),
        value: 'socialSecurityRegistrationStatus',
        cssClass: '',
        width: '75px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.contractor').toString(),
        value: 'contractor',
        cssClass: '',
        width: '130px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.contractorBranch').toString(),
        value: 'contractorBranch',
        cssClass: '',
        width: '130px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.contractorVatNumber').toString(),
        value: 'contractorVatNumber',
        cssClass: '',
        width: '130px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.contractorStartDate').toString(),
        value: 'contractorStartDate',
        cssClass: '',
        width: '110px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.contractorEndDate').toString(),
        value: 'contractorEndDate',
        cssClass: '',
        width: '110px',
      },
      {
        text: this.$t('projects.socialSecurityRegistrations.dataTable.headers.comment').toString(),
        value: 'comment',
        cssClass: '',
        width: '250px',
      },
    ]
  }

  // handlers
  async onClickEditProjectSocialSecurityStartDate(project: Project, startDate: string) {
    this.fetchProject(project.id.toString()).then(() => {
      this.saveProject({...this.project, socialSecurityStartDate: startDate}).then(() => {
        this.fetchSocialSecurityRegistrations();
      });
    })
  }

  async onClickEditProjectSocialSecurityEndDate(project: Project, endDate: string) {
    this.fetchProject(project.id.toString()).then(() => {
      this.saveProject({...this.project, socialSecurityEndDate: endDate}).then(() => {
        this.fetchSocialSecurityRegistrations();
      });
    })
  }

  async onBlurProjectSocialSecurityReference(project: Project) {
    console.log(project.socialSecurityReference)
    this.fetchProject(project.id.toString()).then(() => {
      this.saveProject({...this.project, socialSecurityReference: project.socialSecurityReference}).then(() => {
        this.fetchSocialSecurityRegistrations();
      });
    })
  }

  // getters

  async onBlurEditSocialSecurityRegistrationComment(newSocialSecurityRegistration: SocialSecurityRegistration) {
    this.saveSocialSecurityRegistration({
      ...newSocialSecurityRegistration,
      comment: !!newSocialSecurityRegistration.comment ? newSocialSecurityRegistration.comment : null
    })
  }

  onChangeFilterComplianceDueDate(date: string) {
    console.log(date)
    this.state = {...this.state, numberOfDaysShownUntilDue: dayjs(date).diff(dayjs(), 'day') + 1}
  }

  async onClickMarkSocialSecurityRegistrationStatus(socialSecurityRegistration: SocialSecurityRegistration, status: SocialSecurityRegistrationStatus) {
    if (socialSecurityRegistration && status) {
      this.saveSocialSecurityRegistration({...socialSecurityRegistration, status: status})
    }
  }

  async onClickMarkProjectSocialSecurityComplete(project: Project, completed: boolean) {
    this.fetchProject(project.id.toString()).then(() => {
      this.saveProject({...this.project, socialSecurityCompleted: completed}).then(() => {
        this.fetchSocialSecurityRegistrations();
      });
    })
  }

  // methods
  projectComplianceSorter(a: ProjectCompliance, b: ProjectCompliance) {
    if (a.isCompliant && !b.isCompliant) return 1;
    if (!a.isCompliant && b.isCompliant) return -1;
    if (a.daysUntilDue > b.daysUntilDue) return 1;
    if (a.daysUntilDue < b.daysUntilDue) return -1;
    return 0;
  }

  toggleProjectExpansion(projectId: number) {
    const expandedProject: { [key: number]: boolean } = {}
    expandedProject[projectId] = !this.state.expandedProjectsObj[projectId]
    this.state.expandedProjectsObj = {...this.state.expandedProjectsObj, ...expandedProject}
  }

  toggleAllProjectExpansion() {
    if (this.hasExpandedProjects) this.state.expandedProjectsObj = {}
    else {
      let expandedProjects: { [key: number]: boolean } = {};
      this.projects.forEach(p => expandedProjects[p.id] = true)
      this.state.expandedProjectsObj = {...this.state.expandedProjectsObj, ...expandedProjects}
    }
  }

  isSsrCompliant(ssr: SocialSecurityRegistrationCompliance): boolean {
    if(!ssr.complianceStatus) {
      return true;
    }
    return ssr.status === SocialSecurityRegistrationStatus.REGISTERED &&
      [
        IncomplianceStatus.CONTRACTOR_PERIOD_BETWEEN_SOCIAL_SECURITY_START_AND_END_DATE,
        // below 2 statuses were added because the start-date of the RSZ-period cannot be changed. So when a
        // contractor is overlaping with the RSZ start date we'll mark the project still as compliant.
        // These statuses are still visually marked in red though, see: `showDaysUntilDueForSsr`
        IncomplianceStatus.CONTRACTOR_PERIOD_BEFORE_SOCIAL_SECURITY_START_DATE,
        IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_DATE,
      ].includes(ssr.complianceStatus)
  }

  showDaysUntilDueForSsr(ssr: SocialSecurityRegistrationCompliance): boolean {

    if(!ssr.isCompliant) {
      return true;
    }

    if (ssr.complianceStatus /*&& ssr.status === SocialSecurityRegistrationStatus.REGISTERED*/
        && [
          IncomplianceStatus.CONTRACTOR_PERIOD_BEFORE_SOCIAL_SECURITY_START_DATE,
          IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_DATE,
          IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_AND_END_DATE,
          IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_END_DATE,
          IncomplianceStatus.CONTRACTOR_PERIOD_AFTER_SOCIAL_SECURITY_END_DATE
        ].includes(ssr.complianceStatus))
    {
      return true
    }
    return false
  }

  // setters

  // watchers

  // lifecycle
  fetchRequiredData() {
    return [
      // this.availableUsers && this.availableUsers.length ? null : this.fetchUsers(),
    ].filter(v => !!v)
  }

  created(): void {
    Promise.all<any>(this.fetchRequiredData()).then(() => {
      this.fetchSocialSecurityRegistrations();
    })
  }
}
