

	import {Component, Emit, Prop, PropSync, Vue} from "vue-property-decorator";

	@Component
	export default class GmapPickerComponent extends Vue {
		@PropSync('showModal', {type: Boolean}) syncedShowModal!: boolean;
		@Prop() title!: string;
		@Prop() center!: {lat: number, lng: number};
		@Prop() location!: any;

		@Emit('onAutocompleteSelect')
		onAutocompleteSelect(place: any) {
			return place;
		}

		@Emit('onMapClose')
		onMapClose() {
			(this.$refs.autocomplete as HTMLFormElement).$refs.input.value = '';
		}

		@Emit('onMapSave')
		onMapSave() {
			this.syncedShowModal = false;
		}

		@Emit('onChooseAddress')
		onChooseAddress(mapEvent: any) {
			return { mapEvent, ref: this.$refs };
		}
	}
