import axios, { AxiosResponse } from 'axios';
import {Pagination} from '@/core/types/Entities';
import {ContractorBranch} from "@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState";

const BASE_ENDPOINT = '/api/v1/settings/contractor-types';

export default class ContractorBranchesService {

  getBaseEndpoint(): string {
    return BASE_ENDPOINT;
  }

  getAllContractorBranches(url = `${ this.getBaseEndpoint() }`): Promise<AxiosResponse<Pagination<ContractorBranch[]>>> {
    return axios.get<Pagination<ContractorBranch[]>>(url);
  }
}