import { JsonResource, Pagination } from '@/core/types/Entities';
import { Supplier, SupplierType, Retainment } from '@/modules/entities/types/entities';
import axios, { AxiosResponse } from 'axios';

const BASE_ENDPOINT = '/api/v1/suppliers';

export class SuppliersService {
	getSuppliers(params?: URLSearchParams): Promise<AxiosResponse<Pagination<Supplier[]>>> {
		return axios.get<Pagination<Supplier[]>>(`${BASE_ENDPOINT}${params ? '?' + params.toString() : ''}`);
	}

	getSupplierTypes(): Promise<AxiosResponse<JsonResource<SupplierType[]>>> {
		return axios.get('/api/v1/settings/supplier-types');
	}

	deleteTeleinformationById(supplierId: number, teleinformationId: number): Promise<AxiosResponse<void>> {
		return axios.delete(`${BASE_ENDPOINT}/${supplierId}/teleinformations/${teleinformationId}`);
	}

	storeRetainment(supplierId: number, retainment: Retainment): Promise<AxiosResponse<JsonResource<Retainment>>> {
		return axios.post(`${BASE_ENDPOINT}/${supplierId}/retainments`, retainment);
	}

	updateRetainment(supplierId: number, retainment: Retainment): Promise<AxiosResponse<JsonResource<Retainment>>> {
		return axios.put(`${BASE_ENDPOINT}/${supplierId}/retainments/${retainment.id}`, retainment);
	}
}
