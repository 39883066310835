import { MutationTree } from 'vuex';

import { TaxState } from '@/modules/settings/store/modules/taxes/types/TaxState';
import { Mutations } from '@/modules/settings/store/modules/taxes/types/StoreTypes';
import { Tax } from '@/modules/settings/types/entities';

export const mutations: MutationTree<TaxState> = {
	[Mutations.MUTATE_TAXES]: (state: TaxState, payload: Tax[]) => {
		state.taxes = payload;
	},
};
