import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';

import { MediaAnnotation } from '@/modules/entities/types/entities';
import { PlannerPeriod } from '@/modules/calendar/interfaces/project/plannerPeriod';
import { WorkDay } from '@/modules/calendar/interfaces/project/workDay';


const BASE_ENDPOINT = '/api/v1/work-days';

export class WorkDaysService {

  getAllWorkDays(url = `${BASE_ENDPOINT}`): Promise<AxiosResponse<Pagination<WorkDay[]>>> {
    return axios.get<Pagination<WorkDay[]>>(url);
  }

  getWorkDays(params: URLSearchParams): Promise<AxiosResponse<Pagination<WorkDay[]>>> {
    return axios.get<Pagination<WorkDay[]>>(`${BASE_ENDPOINT}${params ? '?' + params.toString() : ''}`);
  }

}

