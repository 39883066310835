import { Filter } from '@/core/types/Filter';
import { Supplier, User } from '@/modules/entities/types/entities';
import { Project, ProjectStatus } from '@/modules/projects/types/entities';
import { ReportState } from '@/modules/reports/store/types/ReportState';
import { MutationTree } from 'vuex';
import { Mutations } from './types/StoreTypes';

export const mutations: MutationTree<ReportState> = {

  [Mutations.MUTATE_TOGGLE_GROSS_MARGIN_FILTER_SIDEBAR]: (state: ReportState, payload: boolean) => {
    state.grossMarginFilterState.sideBarOpen = payload;
  },

  [Mutations.MUTATE_IS_LOADING_GROSS_MARGIN_REPORT]: (state: ReportState, payload: boolean) => {
    state.isLoadingGrossMarginReport = payload;
  },

  [Mutations.MUTATE_IS_LOADING_CONTRACTOR_BRANCH_REPORT]: (state: ReportState, payload: boolean) => {
    state.isLoadingContractorBranchesReport = payload;
  },

  [Mutations.MUTATE_USERS]: (state: ReportState, payload: User[]) => {
    state.users = payload;
  },

  [Mutations.MUTATE_PROJECT_STATUSES]: (state: ReportState, payload: ProjectStatus[]) => {
    state.projectStatuses = payload;
  },

  [Mutations.MUTATE_TOGGLE_GROSS_MARGIN_FILTER_SIDEBAR]: (state: ReportState, payload: boolean) => {
    state.grossMarginFilterState.sideBarOpen = payload;
  },

  [Mutations.MUTATE_TOGGLE_GROSS_MARGIN_FILTER_GROUP]: (state: ReportState, payload: string) => {
    for (const [key, filter] of Object.entries<Filter<any>>(state.grossMarginFilterState.filters)) {
      if(filter.group === payload){
        filter.active = !filter.active;
      } 
    }
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_GROUP_ENABLE]: (state: ReportState, payload: string) => {
    for (const [key, filter] of Object.entries<Filter<any>>(state.grossMarginFilterState.filters)) {
      if(filter.group === payload) filter.active = true;
    }
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_GROUP_DISABLE]: (state: ReportState, payload: string) => {
    for (const [key, filter] of Object.entries<Filter<any>>(state.grossMarginFilterState.filters)) {
      if(filter.group === payload) filter.active = false;
    }
  },

  [Mutations.MUTATE_CLEAR_GROSS_MARGIN_FILTERS]: (state: ReportState, payload: string[]) => {
    // toggle all filters with group property set to 'payload'
    for (const [key, filter] of Object.entries<Filter<any>>(state.grossMarginFilterState.filters)) {
      if(filter.group && payload.includes(filter.group)) filter.clear();
    }
  },

  [Mutations.MUTATE_SEARCH_PROJECTS]: (state: ReportState, payload: Project[]) => {
    state.searchProjects = payload;
  },

  [Mutations.MUTATE_SEARCH_SUPPLIERS]: (state: ReportState, payload: Supplier[]) => {
    state.searchSuppliers = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_PROJECTS]: (state: ReportState, payload: number[]) => {
    state.grossMarginFilterState.filters.projects.value = payload;
  },
  [Mutations.MUTATE_GROSS_MARGIN_FILTER_SUPPLIER]: (state: ReportState, payload: number[]) => {
    state.grossMarginFilterState.filters.suppliers.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_SELECTED_PROJECT]: (state: ReportState, payload: Project) => {
    state.grossMarginFilterState.selectedProject = payload;
  },
  [Mutations.MUTATE_GROSS_MARGIN_FILTER_SELECTED_SUPPLIER]: (state: ReportState, payload: Supplier) => {
    state.grossMarginFilterState.selectedSupplier = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_STATUS]: (state: ReportState, payload: number[]) => {
    state.grossMarginFilterState.filters.projectStatuses.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_LEADER]: (state: ReportState, payload: number[]) => {
    state.grossMarginFilterState.filters.projectLeaders.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_ESTIMATOR]: (state: ReportState, payload: number[]) => {
    state.grossMarginFilterState.filters.projectEstimators.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_CONTRACTOR_TYPES]: (state: ReportState, payload: number[]) => {
    state.grossMarginFilterState.filters.contractorBranches.value = payload;
  },

  //Total Amount Ordered
  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED]: (state: ReportState, payload: number[]) => {
    state.grossMarginFilterState.filters.minAmountOrdered.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED]: (state: ReportState, payload: number[]) => {
    state.grossMarginFilterState.filters.maxAmountOrdered.value = payload;
  },

    //Total Amount Ordered Contractor Branch
    [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH]: (state: ReportState, payload: {contractorBranchId: string | null, minAmountOrdered: number}[]) => {
      state.grossMarginFilterState.filters.minAmountOrderedContractorBranch.value = payload;
    },
  
    [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH]: (state: ReportState, payload: {contractorBranchId: string | null, minAmountOrdered: number}[]) => {
      state.grossMarginFilterState.filters.maxAmountOrderedContractorBranch.value = payload;
    },

  //Startdate planning
  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_FROM]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.firstPlannerDateFrom.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_TO]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.firstPlannerDateTo.value = payload;
  },

  //Enddate planning
  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_FROM]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.lastPlannerDateFrom.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_TO]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.lastPlannerDateTo.value = payload;
  },

  //Date first sales invoice
  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_FROM]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.firstSalesInvoiceDateFrom.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_TO]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.firstSalesInvoiceDateTo.value = payload;
  },

  //Date last sales invoice
  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_FROM]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.lastSalesInvoiceDateFrom.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_TO]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.lastSalesInvoiceDateTo.value = payload;
  },

  //Date first purchase invoice
  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_FROM]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.firstPurchaseInvoiceDateFrom.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_TO]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.firstPurchaseInvoiceDateTo.value = payload;
  },

  //Date last purchase invoice
  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_FROM]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.lastPurchaseInvoiceDateFrom.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_TO]: (state: ReportState, payload: string[]) => {
    state.grossMarginFilterState.filters.lastPurchaseInvoiceDateTo.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_CONTRACTOR_BRANCHES]: (state: ReportState, payload: boolean[]) => {
    state.grossMarginFilterState.filters.showOnlyCompletedContractorBranches.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_PROJECTS]: (state: ReportState, payload: boolean[]) => {
    state.grossMarginFilterState.filters.showOnlyCompletedProjects.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_RELEVANT_CONTRACTOR_BRANCHES]: (state: ReportState, payload: boolean[]) => {
    state.grossMarginFilterState.filters.showOnlyRelevantContractorBranches.value = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_STATUSES]: (state: ReportState, payload: boolean) => {
    state.grossMarginFilterState.showMoreProjectStatuses = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_LEADERS]: (state: ReportState, payload: boolean) => {
    state.grossMarginFilterState.showMoreProjectLeaders = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_ESTIMATORS]: (state: ReportState, payload: boolean) => {
    state.grossMarginFilterState.showMoreProjectEstimators = payload;
  },

  [Mutations.MUTATE_GROSS_MARGIN_REPORT]: (state: ReportState, payload: any) => {
    state.grossMarginReport = payload;
  },

  [Mutations.MUTATE_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT]: (state: ReportState, payload: any) => {
    state.projectsContractorBranchesReport = payload;
  },
  [Mutations.MUTATE_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT]: (state: ReportState, payload: any) => {
    state.filteredProjectsContractorBranchesReport = payload;
  },

  [Mutations.MUTATE_SHOW_PROJECTS_GROSS_MARGIN_REPORT]: (state: ReportState, payload: boolean) => {
    state.showProjectsGrossMarginReport = payload;
  },

  [Mutations.MUTATE_SHOW_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT]: (state: ReportState, payload: boolean) => {
    state.showProjectsContractorBranchesPurchaseReport = payload;
  },

  [Mutations.MUTATE_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC]: (state: ReportState, payload: ProjectsContractorBranchesOverviewReportMetric) => {
    state.projectsContractorBranchesPurchaseReportMetric = payload;
  },

};
