
import {Component, Emit, Prop, PropSync, Vue} from 'vue-property-decorator';
import { WorkflowTaskStatus } from '@/modules/workflow/types/entities';
import { Validate } from 'vuelidate-property-decorators';
import { maxLength, required } from 'vuelidate/lib/validators';
import {taskStatusIcon} from '@/modules/settings/views/workflow/helpers/workflow-helpers';

@Component
export default class TaskStatusModalComponent extends Vue {
	@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
	@Prop() taskStatusItem?: WorkflowTaskStatus;
	@Prop() getTaskStatusColour!: Function;

	@Validate({
		required,
		maxLength: maxLength(255)
	})
	nameValue = '';

	@Validate({required})
	symbolValue = '';

	@Validate({maxLength: maxLength(255)})
	descriptionValue = '';

	@Validate({required})
	colourValue = '';

	colourList: string[] = [
		'GREEN_1',
		'GREEN_2',
		'GREEN_3',
		'GREEN_4',
		'PINK_1',
		'PINK_2',
		'ORANGE_1',
		'ORANGE_2',
		'ORANGE_3',
		'ORANGE_4',
		'BLUE_1',
		'BLUE_2',
		'RED_1',
		'RED_2',
		'RED_3',
		'GREY_1',
		'GREY_2',
		'GREY_3',
		'GREY_4'
	];

	symbolList: string[] = [
		'SYMBOL_ONE',
		'SYMBOL_TWO',
		'SYMBOL_THREE',
		'SYMBOL_FOUR',
		'SYMBOL_FIVE',
		'SYMBOL_SIX',
		'SYMBOL_SEVEN',
		'SYMBOL_EIGHT',
		'SYMBOL_NINE',
		'SYMBOL_TEN',
		'SYMBOL_ELEVEN',
		'SYMBOL_TWELVE',
		'NO_SYMBOL'
	];

	getSymbolPath(symbol: string) {
		return taskStatusIcon(symbol)
	}

	onSelectColourValue(colour: string) {
		if (colour !== this.colourValue) {
			this.colourValue = colour;
			// @ts-ignore
			this.$v.colourValue.$touch();
		}
	}

	onSelectSymbolValue(symbol: string) {
		if (symbol !== this.symbolValue) {
			this.symbolValue = symbol;
			// @ts-ignore
			this.$v.symbolValue.$touch();
		}
	}

	created() {
		if (this.taskStatusItem) {
			if (!this.symbolList.some(x => x === this.taskStatusItem!.symbol)) {
				this.symbolList.push(this.taskStatusItem.symbol!);
			}
			if (!this.colourList.some(x => x === this.taskStatusItem!.color)) {
				this.colourList.push(this.taskStatusItem.color!);
			}
			this.nameValue = this.taskStatusItem.status!;
			this.symbolValue = this.taskStatusItem.symbol!;
			this.descriptionValue = this.taskStatusItem.description!;
			this.colourValue = this.taskStatusItem.color!;
		}
	}

	get isFormValidOrTouched(): boolean {
		// @ts-ignore
		return !((!this.$v.$invalid) && (this.$v.$anyDirty))
	}

	handleFormSubmit(): void {
		if (this.isFormValidOrTouched) {
			return;
		} else {
			this.emitSaveTaskStatus();
		}
	}

	@Emit('saveTaskStatus')
	emitSaveTaskStatus() {
		this.showModalSync = false;
		return {
			status: this.nameValue,
			symbol: this.symbolValue,
			description: this.descriptionValue,
			colour: this.colourValue
		}
	}
}

