import { AxiosResponse } from 'axios';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { User } from '@/modules/entities/types/entities';
import axios from 'axios';

const BASE_ENDPOINT = '/api/v1/settings/users';

export class UsersService {

  getBaseEndpoint(): string {
    return BASE_ENDPOINT;
  }

  createUser(user: User): Promise<AxiosResponse<JsonResource<User>>> {
    return axios.post<JsonResource<User>>(`${ BASE_ENDPOINT }`, user);
  }

  getAllUsers(url = `${BASE_ENDPOINT}`): Promise<AxiosResponse<Pagination<User[]>>> {
    return axios.get<Pagination<User[]>>(url);
  }

  getUserById(userId: number): Promise<AxiosResponse<JsonResource<User>>> {
    return axios.get<JsonResource<User>>(`${ BASE_ENDPOINT }/${ userId }`);
  }

  updateUserById(user: User, id: number): Promise<AxiosResponse<JsonResource<User>>> {
    return axios.put<JsonResource<User>>(`${ BASE_ENDPOINT }/${ id }`, user);
  }

  deleteUserById(userId: number): Promise<AxiosResponse> {
    return axios.delete(`${ BASE_ENDPOINT }/${ userId }`);
  }
}
