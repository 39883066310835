import {Module} from 'vuex';
import {actions} from './actions';
import {mutations} from './mutations';
import {state} from './state';
import {getters} from './getters';
import {RootState} from '@/core/types/RootState';
import {WorkflowState} from '@/modules/workflow/store/types/WorkflowState';


export const store: Module<WorkflowState, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
