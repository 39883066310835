
  import useEmployeeList from '@/composables/useEmployeeList';
  import useHolidayList from '@/composables/useHolidayList';
  import { AbsencePeriod } from '@/modules/settings/types/entities';
  import { defineComponent, ref, PropType, watch, toRefs, computed } from 'vue';

  export default defineComponent({
    name: 'absence-period-modal-form',
    props: {
      value: { type: Object as PropType<AbsencePeriod | null>, default: null },
      validationErrors: {
        type: Object as PropType<Object | null>,
        default: () => ({
          // startDate: 'error',
          // endDate: 'error',
          // employee: 'error',
          // duration: 'error',
          // type: 'error',
          // comment: 'error',
        }),
      },
    },
    setup(props, { emit }) {
      const propsRefs = toRefs(props);
      const form = ref(props.value);
      watch(propsRefs.value, (v, vOld) => (form.value = v));
      watch(form, (f, fOld) => emit('input', f), { deep: true });

      const employeeList = useEmployeeList();
      employeeList.actions.setFilter('is_active', true).then(() => {
        employeeList.actions.fetchEmployees();
      })

      const holidayList = useHolidayList();
      holidayList.actions.fetchHolidayTypes();
      holidayList.state.holidayTypes.value;
      const holidayTypes = computed(() => {
        return holidayList.state.holidayTypes.value
          .filter((h) => ['common', 'individual'].includes(h.type))
          .sort((a, b) => a.sequence - b.sequence);
      });

      return {
        form,
        employeeList,
        holidayList,
        holidayTypes,
      };
    },
  });
