

	import DeletePopup from '@/core/components/delete-popup/delete-popup.component.vue';

	import {Component, Emit, Prop, PropSync, Vue, Watch} from "vue-property-decorator";
	import {ProjectIntersectionData, WorkflowTask, WorkflowTaskStatus} from "@/modules/workflow/types/entities";
	import {Project} from "@/modules/projects/types/entities";
	import {Comment} from "@/modules/entities/types/entities";

	@Component({
		components: {
			DeletePopup
		}
	})
	export default class ModifyNoteComponent extends Vue {
		@PropSync('showModal', {type: Boolean}) showModalSync!: boolean;
		@Prop() taskStatuses!: WorkflowTaskStatus[];
		@Prop() userId!: number;
		@PropSync('intersectionData', {type: Object}) intersectionDataSync!: {project: Project, task: WorkflowTask, intersectionData?: ProjectIntersectionData, comment?: Comment};
		@Prop() getStatusChangerColor!: Function;
		@Prop() getCurrentStatus!: Function;

		modifyNoteState = {
			delete: false,
			date_picker: false
		};

		modifyNoteContent : {
			delete: Comment
			intersectionCopy: ProjectIntersectionData
		} = {
			delete: {
				id: 0,
				body: '',
				commentableId: 0,
				commentableType: ''
			},
			intersectionCopy: {
				intersectionId: 0,
				projectId: 0,
				workflowTaskId: 0,
				comments: [],
			}
		};

		resetModifyNoteContent() {
			this.modifyNoteContent = {
				delete: {
					id: 0,
					body: '',
					commentableId: 0,
					commentableType: ''
				},
				intersectionCopy: {
					intersectionId: 0,
					projectId: 0,
					workflowTaskId: 0,
					comments: [],
				}
			}
		}

		currentDateWithFormat: any;
		commentBody: string = '';

		onDeleteNote(note: Comment) {
			this.resetModifyNoteContent();
			this.modifyNoteState.delete = true;
			this.modifyNoteContent.delete = note;
		}

		setTaskStatus(taskStatusId: number) {
			if (this.intersectionDataSync.intersectionData) {
				if (this.intersectionDataSync.intersectionData.taskStatus && this.intersectionDataSync.intersectionData.taskStatus.id !== taskStatusId) {
					this.onSetTaskStatus(this.intersectionDataSync.intersectionData.projectId, this.intersectionDataSync.intersectionData.workflowTaskId, taskStatusId);
				}
			} else {
				this.onSetTaskStatus(this.intersectionDataSync.project.id!, this.intersectionDataSync.task.id, taskStatusId)
			}
		}

		@Emit('onDeleteWorkflowNote')
		onDeleteWorkflowNote() {
			if (this.modifyNoteContent.delete.id !== 0) {
				this.showModalSync = false;
				return {
					projectId: this.intersectionDataSync.project.id,
					taskId: this.intersectionDataSync.task.id,
					noteId: this.modifyNoteContent.delete.id
				}
			}
		}

		@Emit('onNoteSave')
		onNoteSave() {
			if (this.intersectionDataSync.intersectionData && this.intersectionDataSync.intersectionData.intersectionId) {
				if (this.intersectionDataSync.comment) {
					return {
						intersectionId: this.intersectionDataSync.intersectionData.intersectionId,
						projectId: this.intersectionDataSync.project.id,
						taskId: this.intersectionDataSync.task.id,
						body: this.intersectionDataSync.comment.body,
						commentId: this.intersectionDataSync.comment.id
					};
				}
				return {
					intersectionId: this.intersectionDataSync.intersectionData.intersectionId,
					projectId: this.intersectionDataSync.project.id,
					taskId: this.intersectionDataSync.task.id,
					body: this.commentBody
				}
			}
			return {projectId: this.intersectionDataSync.project.id, taskId: this.intersectionDataSync.task.id, body: this.commentBody}
		}

		@Emit('onSetTaskStatus')
		onSetTaskStatus(projectId:number, taskId: number, taskStatusId: number) {
			return {
				projectId: projectId,
				workflowTaskId: taskId,
				taskStatusId
			}
		}

		@Emit('onSetTaskDate')
		onSetTaskDate() {
			if (this.modifyNoteContent.intersectionCopy.intersectionId !== 0) {
				this.modifyNoteState.date_picker = false;
				return {
					projectId: this.modifyNoteContent.intersectionCopy.projectId,
					workflowTaskId: this.modifyNoteContent.intersectionCopy.workflowTaskId,
					taskStatusId: this.modifyNoteContent.intersectionCopy.taskStatus!.id,
					futureStartDate: this.modifyNoteContent.intersectionCopy.futureStartDate
				}
			}
		}

		@Watch('intersectionData')
		onIntersectionDataChange(val: {project: Project, task: WorkflowTask, intersectionData?: ProjectIntersectionData, comment?: Comment}) {
			this.modifyNoteContent.intersectionCopy = Object.assign({}, val.intersectionData);
		}

		created() {
			this.currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,'/');
			this.modifyNoteContent.intersectionCopy = Object.assign({}, this.intersectionDataSync.intersectionData)
		}
	}
