
	import { Component, Emit, Prop, Vue, Watch, Ref } from "vue-property-decorator";

	@Component
	export default class ActivatorComponent extends Vue {

		@Prop({ type: Boolean, default: false }) active!: boolean;
		@Prop({ default: false }) showBorder!: boolean;
		@Prop({ type: String, default: '#ff5252' }) borderColor!: string;

		@Prop({ type: Boolean, default: false }) prependHideOnHover!: boolean;
		@Prop({ type: Boolean, default: true }) prependHide!: boolean;
		@Prop({ type: Boolean, default: false }) appendHideOnHover!: boolean;
		@Prop({ type: Boolean, default: false }) appendHide!: boolean;

		/**
		 * There are 2 options to change state:
		 *   - either use the `toggle`-method that is passed to each slot
		 *   - by changing the `active`-prop, which has a watcher to change the internal `isActive` data-attribute
		 */
		i_active: boolean = this.active

		//
		// handlers
		//
		onClickStatefulContainer(e: any) {
			if(!this.i_active) this.onClickActivate(e);
		}

		onClickActivate(e: any) {
			this.$emit('activate', { event: e })
		}

		onClickSubmit(e: any) {
			this.$emit('submit', { event: e, active: this.i_active });
		}

		onClickCancel(e: any) {
			this.$emit('cancel', { event: e, active: this.i_active });
		}

		onSubmitSilent(e: any) {
			this.$emit('submitSilent', { event: e, active: this.i_active });
		}

		//
		// watchers
		//
		@Watch('active')
		onWatchActive(newActive: boolean) {
			this.i_active = newActive;
		}

	}
