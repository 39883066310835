import { TaskListType, WorkflowState } from '@/modules/settings/store/modules/workflow/types/WorkflowState';

export const state: WorkflowState = {
	tasks: [],
	taskStatuses: [],
	functionGroups: [],
	infoMessage: {
		text: '',
		isVisible: false,
		timeout: -1,
		buttonVisible: false
	},
	activityLog: {
		lastItem: {
			item: null,
			index: -1
		},
		lastBackendResponse: undefined,
		lastOperationType: null,
		lastOperationListType: null
	},
	highestTaskSequence: -1,
	paginationDetails: undefined,
	activeListType: TaskListType.TASKS_ONGOING,
	filters: {
		fetchArchived: false,
		sortByArchivedAt: 'none',
		sortBySequence: 'none',
	}
};
