import { ThirdParty, ProjectStatus, Project, ProjectUserVM } from '@/modules/projects/types/entities';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
import { ProjectUser } from '@/modules/projects/types/entities';
import { ProvinceEntity } from '@/modules/entities/types/province.entity';
import { Contact } from '@/modules/contacts/types/entities';
import { Permission } from '@/modules/settings/store/modules/permissions/types/PermissionsState';
import { Quote } from '@/modules/calendar/interfaces/project/quote';
import { WorkDay } from '@/modules/calendar/interfaces/project/workDay';

export interface SupplierType {
	id: number;
	type: string;
}

export interface ContactType {
	klant: SupplierType;
	levarancier: SupplierType[];
}

export interface FilterHeaderType {
	title: string;
	items: SupplierType[] | ProjectUser[] | ProjectStatus[];
	displayTitle: string;
}

export interface RawDataEntity {
	supplierId: string;
	mainId: string;
	mode: string;
	item: any;
}

export interface Filter {
	id: number;
	name?: string;
	type?: string;
}

export interface SearchType {
	name: string;
	value?: string;
}

export interface BankAccount {
	id: number;
	isMain: boolean;
	accountNumber: string;
}

export class BankAccountEntity implements BankAccount {
	id = 0;
	isMain = false;
	accountNumber = '';

	constructor(init?: Partial<BankAccount>) {
	  Object.assign(this, init);
	}
}

export interface ProjectClient {
	id: number;
	projectId: number;
	clientId: number;
	vat6CertificateStatus: string | null;
}

export interface Client {
	id: number;
	name: string;
	street: string;
	streetNumber: string;
	postalCode: string;
	city: string;
	bankAccounts: BankAccount[];
	nationalInsuranceNumber: string;
	vatNumber: string;
	preferredPaymentTerm: number;
	preferredPaymentTermBoolean: boolean;
	preferredPaymentDiscountTerm: number;
	preferredPaymentDiscountTermBoolean: boolean;
	preferredPaymentDiscountPercentage: number;
	teleinformations: TeleinformationDetail[];
	comments?: Comment[];
	contacts: Contact[];
	companyType?: string;
	commercialName?: string;
	website?: string;
	projects?: Project[];
	projectClient?: ProjectClient;
	isCompany: boolean;
	createdAt: string;
	updatedAt: string;
}

export class ClientVM implements Client {
	id = 0;
	name = '';
	city = '';
	comments: Comment[] = [];
	commercialName = '';
	contacts: Contact[] = [];
	companyType = '';
	nationalInsuranceNumber = '';
	postalCode = '';
	bankAccounts: BankAccount[] = [];
	preferredPaymentTerm = 0;
	preferredPaymentTermBoolean = false;
	preferredPaymentDiscountTerm = 0;
	preferredPaymentDiscountTermBoolean = false;
	preferredPaymentDiscountPercentage = 0;
	projects: Project[] = [];
	street = '';
	streetNumber = '';
	teleinformations: TeleinformationDetail[] = [];
	vatNumber = '';
	website = '';
	isCompany = false;
	createdAt = '';
	updatedAt = '';

	constructor(init?: Partial<Client>) {
	  Object.assign(this, init);
	}
}

export interface Supplier {
	id: number;
	name: string;
	commercialName: string;
	vatNumber: string;
	ssrNumber: string;
	bankAccounts: BankAccount[];
	price: number | null;
	priceComment: string;
	quality: number | null;
	qualityComment: string;
	technicalExpertise: number | null;
	aestheticQuality: number | null;
	aestheticQualityComment: string;
	workforce: string;
	activities: string;
	speciality: string;
	province: ProvinceEntity | null;
	comment: any;
	street: string;
	streetNumber: string;
	postalCode: string;
	city: string;
	outboundKm: number;
	preferredPaymentTerm: number;
	preferredPaymentTermBoolean: boolean;
	preferredPaymentDiscountTerm: number;
	preferredPaymentDiscountTermBoolean: boolean;
	preferredPaymentDiscountPercentage: number;
	futureWork: number | null;
	futureWorkComment: string;
	companyType?: CompanyType;
	contacts: Contact[];
	companyTypes: SupplierType[];
	companyLocations?: Supplier[];
	pivot?: ThirdParty;
	contractorTypes: ContractorBranch[];
	comments?: Comment[];
	projects?: Project[];
	teleinformations: TeleinformationDetail[];
	quotes?: Quote[];
	quotesCount?: number;
	quotesOrderedCount?: number;
	workDays?: WorkDay[];
	website: string;
	parentSupplierId: number | null;
	createdAt: string;
	updatedAt: string;
	// ThirdParty | undefined | ContractorBranch[] |
	// boolean | Date | null | number | Contact[] | SupplierType[] |
	// ThirdParty | string;
	type?: string;
	retainments: Retainment[];
	retainmentsOk?: boolean;
}

export class SupplierVM implements Supplier {
	id = 0;
	name = '';
	commercialName = '';
	vatNumber = '';
	ssrNumber = '';
	bankAccounts: BankAccount[] = [];
	price = null;
	priceComment = '';
	quality = null;
	qualityComment = '';
	technicalExpertise = null;
	aestheticQuality = null;
	aestheticQualityComment = '';
	workforce = '';
	activities = '';
	speciality = '';
	province = null;
	comment = '';
	street = '';
	streetNumber = '';
	postalCode = '';
	city = '';
	outboundKm = 0.0;
	preferredPaymentTerm = 0;
	preferredPaymentTermBoolean = false;
	preferredPaymentDiscountTerm = 0;
	preferredPaymentDiscountTermBoolean = false;
	preferredPaymentDiscountPercentage = 0.0;
	futureWork = null;
	futureWorkComment = '';
	// companyType = undefined;
	contacts: Contact[] = [];
	companyTypes: SupplierType[] = [];
	// companyLocations = undefined;
	// pivot? = ThirdParty;
	contractorTypes: ContractorBranch[] = [];
	comments: Comment[] = [];
	projects: Project[] = [];
	teleinformations: TeleinformationDetail[] = [];
	quotes: Quote[] = [];
	workDays: WorkDay[] = [];
	website = '';
	parentSupplierId = null;
	createdAt = '';
	updatedAt = '';
	// ThirdParty | undefined | ContractorBranch[] |
	// boolean | Date | null | number | Contact[] | SupplierType[] |
	// ThirdParty | string;
	retainments = [];

	constructor(init?: Partial<Supplier>) {
	  Object.assign(this, init);
	}
}

export interface TeleinformationDetail {
	id: number;
	landlinePhone: string;
	landlinePhoneComment: string;
	mobilePhone: string;
	mobilePhoneComment: string;
	email: string;
	emailComment: string;
	isMainContactInformation: boolean;
	user?: ProjectUser;
	updatedAt: string;
}

export class TeleinformationDetailEntity implements TeleinformationDetail {
	id = 0;
	landlinePhone = '';
	landlinePhoneComment = '';
	mobilePhone = '';
	mobilePhoneComment = '';
	email = '';
	emailComment = '';
	isMainContactInformation = false;
	updatedAt = '';

	constructor(init?: Partial<TeleinformationDetail>) {
	  Object.assign(this, init);
	}
}

export interface CompanyType {
	id: number;
	name: string;
	abbreviation: string;
}

export interface Comment {
	id: number;
	body: string;
	commentableId: number;
	commentableType: string;
	created?: Date | null;
	user?: ProjectUser;
}

export class CommentVM implements Comment {
	body = '';
	commentableId = 0;
	commentableType = '';
	created = null;
	id = 0;
	user = new ProjectUserVM();

	constructor(init?: Partial<Comment>) {
	  Object.assign(this, init);
	}
}

export interface City {
	name: string;
	cityableId: number;
	cityableType: string;
}

export interface User {
	id: number | undefined;
	firstName: string | null;
	lastName: string | null;
	password: string | null;
	username: string | null;
	isActive: boolean;
	alias: string | null;
	email: string | null;
	role: Role | null;
	roles: Role[] | null;
	permissions: Permission[] | null;
}
export class UserEntity implements User {
	id = undefined;
	firstName = null;
	lastName = null;
	password = null;
	username = null;
	isActive = false;
	alias = null;
	email = null;

	role = null;
	roles = [];
	permissions = [];

	constructor(data?: Partial<User>) {
	  Object.assign(this, data);
	}
}

export interface Role {
	id?: number;
	description: string;
	name: string;
	sequence: number;
	permissions?: string[];
}

export interface Media {
	id: number | undefined;
	name?: string;
	collectionName?: string;
	file?: string;
	thumb?: string;
	annotations?: MediaAnnotation[];
	annotationsCount?: number;
}

export interface MediaAnnotationSvg {
	pageNumber: number;
	svg: string;
}

export interface MediaAnnotation {
	id: number | undefined;
	annotation?: any[];
	media?: Media;
	svgData: MediaAnnotationSvg[];
}

export class MediaAnnotationEntity {
	id = undefined;
	annotation = undefined;
	media = undefined;

	constructor(data?: Partial<MediaAnnotation>) {
	  Object.assign(this, data);
	}
}
export type RetainmentType = 'finance' | 'social_security'
export interface Retainment {
	id: number | null;
	type: RetainmentType;
	retain: boolean;
	checkedManually: boolean;
	validUntil: string | null;
	supplier: Supplier | null;
}

export class RetainmentEntity implements Retainment {
	id = null;
	type: RetainmentType = 'finance';
	retain = false;
	checkedManually = true;
	validUntil = null;
	supplier = null;

	constructor(data?: Partial<Retainment>) {
	  Object.assign(this, data);
	}
}