
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
import {
  AccountingStatus,
  PurchaseInvoice,
  PurchaseInvoicePaymentStatus,
  PurchaseInvoiceRecordType,
  PurchaseInvoiceStatus
} from '@/modules/purchase-invoices/types/entities';
import PurchaseInvoiceDetails from '@/modules/purchase-invoices/components/purchase-invoice-details/purchase-invoice-details.component.vue';
import { AxiosError } from 'axios';
import _ from 'lodash'
import useUserList from '@/composables/useUserList';
import {
  PurchaseInvoicesCommandsService
} from "@/modules/purchase-invoices/services/purchase-invoices-commands.service";
import {appMessageStore} from "@/core/store/app-messages.store";
import PurchaseInvoiceDuplicatesError
  from "@/modules/purchase-invoices/components/purchase-invoice-duplicates-error.component.vue";

    // namespaces
    const purchaseInvoices = namespace('purchaseInvoices');

    // composables
	const { state: userState, actions: userActions } = useUserList()

    // services
    const purchaseInvoiceCommandsService = new PurchaseInvoicesCommandsService()

    @Component({
        components: {
            'purchase-invoice-details': PurchaseInvoiceDetails,
        }
    })
    export default class PurchaseInvoiceComponent extends Vue {

        // actions
        @purchaseInvoices.Action(Actions.FETCH_INVOICE_BY_ID) fetchPurchaseInvoiceById!: (payload: string) => Promise<void>;
        @purchaseInvoices.Action(Actions.DELETE_INVOICE) deletePurchaseInvoice!: (payload: PurchaseInvoice) => Promise<void>;
        @purchaseInvoices.Action(Actions.FETCH_STATUSES) fetchStatuses!: () => Promise<void>;
        @purchaseInvoices.Action(Actions.FETCH_PAYMENT_STATUSES) fetchPaymentStatuses!: () => Promise<void>;
        @purchaseInvoices.Action(Actions.FETCH_ACCOUNTING_STATUSES) fetchAccountingStatuses!: () => Promise<void>;
        @purchaseInvoices.Action(Actions.FETCH_APPROVAL_STATUSES) fetchApprovalStatuses!: () => Promise<void>;
        @purchaseInvoices.Action(Actions.FETCH_RECORD_TYPES) fetchRecordTypes!: () => Promise<void>;
        @purchaseInvoices.Action(Actions.FETCH_NEW) fetchNew!: () => Promise<void>;
        @purchaseInvoices.Action(Actions.SAVE_INVOICE) savePurchaseInvoice!: (payload: PurchaseInvoice) => Promise<any>;

        // getters
        @purchaseInvoices.Getter(Getters.GET_INVOICE) purchaseInvoice!: PurchaseInvoice;
        @purchaseInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;
        @purchaseInvoices.Getter(Getters.GET_STATUSES_TRANSLATED) statuses!: PurchaseInvoiceStatus[];
        @purchaseInvoices.Getter(Getters.GET_PAYMENT_STATUSES_TRANSLATED) paymentStatuses!: PurchaseInvoicePaymentStatus[];
        @purchaseInvoices.Getter(Getters.GET_ACCOUNTING_STATUSES_TRANSLATED) accountingStatuses!: AccountingStatus[];
        @purchaseInvoices.Getter(Getters.GET_RECORD_TYPES_TRANSLATED) recordTypes!: PurchaseInvoiceRecordType[];
        @purchaseInvoices.Getter(Getters.GET_NEW) new!: PurchaseInvoice;

        // props
        @Prop({default: 'default'}) viewMode!: string; // 'default','modal'

        // vars
        state: {
            saving: boolean,
            cancelling: boolean,
            modifiedPurchaseInvoice: boolean,
            showConfirmDelete: boolean,
            hasFetchedDuplicatePurchaseInvoices: boolean,
        } = {
            saving: false,
            cancelling: false,
            modifiedPurchaseInvoice: false,
            showConfirmDelete: false,
            hasFetchedDuplicatePurchaseInvoices: false,
        }
        content: {
            purchaseInvoice: PurchaseInvoice|null,
            validationErrors: any,
            duplicatePurchaseInvoices: Array<PurchaseInvoice>,
        } = {
            purchaseInvoice: null,
            validationErrors: {},
            duplicatePurchaseInvoices: [],
        }

        // handlers
        onClickDelete() {
            this.state = {...this.state, showConfirmDelete: true}
        }
        async onClickSave() {
            this.state = {...this.state, saving: true}

            if (! this.state.hasFetchedDuplicatePurchaseInvoices && this.content.purchaseInvoice) {
              await this.searchDuplicatePurchaseInvoices(this.content.purchaseInvoice)
              if (this.content.duplicatePurchaseInvoices.length === 0) {
                this.save(this.content.purchaseInvoice)
              }
              return;
            }

            this.save(this.content.purchaseInvoice)
        }
        onDelete() {
            if(this.content.purchaseInvoice && this.content.purchaseInvoice.id !== undefined && this.content.purchaseInvoice.id > 0) {
                this.deletePurchaseInvoice(this.content.purchaseInvoice).then(() => {
                    this.$router.push({ name: 'purchaseInvoices' });
                });
            }
        }
        onClickCancel() {
            this.state = {...this.state, cancelling: true}
            this.content = { ...this.content, purchaseInvoice: {...this.purchaseInvoice} }
            this.state = {...this.state, cancelling: false}
        }
        
        // methods
        fetchPurchaseInvoice() {
          this.fetchPurchaseInvoiceById(this.$route.params.id);
        }
        save(invoice: PurchaseInvoice|null) {
            if(invoice) {
                this.content = { ...this.content, validationErrors: null }
                this.savePurchaseInvoice(invoice)
                  .then(() => {
                      this.state.hasFetchedDuplicatePurchaseInvoices = false
                      this.content.duplicatePurchaseInvoices = []
                  })
                  .catch((err: AxiosError) => {
                      if (err.response && err.response.status === 422) {
                          this.content = { ...this.content, validationErrors: err.response.data.errors }
                      } else {
                          throw err;
                      }
                  })
                  .finally(() => {
                      // clear some state vars
                      this.state = {...this.state, saving: false}
                  });
            }
        }
        searchDuplicatePurchaseInvoices(purchaseInvoice: PurchaseInvoice) {
            return purchaseInvoiceCommandsService.getDuplicatePurchaseInvoicesFor(purchaseInvoice)
                .then((resp) => {
                    this.state.hasFetchedDuplicatePurchaseInvoices = true;
                    this.state.saving = false;
                    this.content.duplicatePurchaseInvoices = resp.data.data || []

                    if(this.content.duplicatePurchaseInvoices.length > 0) {
                        appMessageStore.actions.warn({
                            message: 'Er zijn gelijkaardige facturen gevonden voor de huidige ingegeven factuur. Indien deze factuur toch correct is ingegeven, kan je nogmaals op "Opslaan" klikken om de factuur alsnog op te slaan.',
                            ttl: 900000,
                            component: {
                                cmp: PurchaseInvoiceDuplicatesError,
                                props: {
                                    duplicatePurchaseInvoices: this.content.duplicatePurchaseInvoices,
                                },
                            },
                        })
                    }
              })
              .catch(() => {
                this.state.saving = false;
              })
        }

        // getters
        get users() { return userState.users.value || [] }

        //watchers
        @Watch('purchaseInvoice', { deep: true, immediate: false })
		onWatchPurchaseInvoice(purchaseInvoice: any) {
			this.content = { ...this.content, purchaseInvoice: {...purchaseInvoice}}
        }
        
        @Watch('content.purchaseInvoice', { deep: true, immediate: true })
		onWatchContentPurchaseInvoice(purchaseInvoice: any) {
            if(purchaseInvoice && this.purchaseInvoice) this.onWatchContentPurchaseInvoiceDebounced(purchaseInvoice, this.purchaseInvoice, this);
        }
        onWatchContentPurchaseInvoiceDebounced = _.debounce((localPurchaseInvoice: PurchaseInvoice, storePurchaseInvoice: PurchaseInvoice, vm: Vue) => {
			vm.$data.state = { ...vm.$data.state, modifiedPurchaseInvoice: !_.isEqual(localPurchaseInvoice, storePurchaseInvoice) }
		}, 400);

        // hooks
        created() {
            if(this.$route.params.id) {
                this.fetchPurchaseInvoice();
                this.fetchStatuses()
                this.fetchPaymentStatuses()
                this.fetchAccountingStatuses()
                this.fetchApprovalStatuses()
                this.fetchRecordTypes()
                // this.fetchAllUsers()
                userActions.fetchUsers()
            }
        }

    }

