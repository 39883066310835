import { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';

const PROJECTS_COMMANDS_ENDPOINT = '/api/v1/commands/projects';
export default class CommandsService {

	getClientHighestProjectSequence(clientId: number): AxiosPromise<{ projectCount: string }> {
		return axios.get<{ projectCount: string }>(`/api/v1/commands/get-client-project-name?id=${ clientId }`);
	}
}
