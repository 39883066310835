var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.environment.getters.showSidebar.value &&
      _vm.environment.getters.activeSidebarDialog.value == _vm.CalendarSidebarDialog.PERIOD
  )?_c('sidemenu',{attrs:{"visible":_vm.environment.getters.showSidebar.value &&
      _vm.environment.getters.activeSidebarDialog.value == _vm.CalendarSidebarDialog.PERIOD},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.environment.getters.selectedPeriod.value.quote.contractor)?[_c('div',{staticClass:"period-details__header-container"},[_c('div',{staticClass:"period-details__row"},[_c('span',{staticClass:"period-details__header__title"},[_vm._v(_vm._s(`${_vm.environment.getters.selectedPeriod.value.quote.contractor.name} (${_vm.environment.getters.selectedPeriod.value.quote.contractorType.abbreviation})`))]),_c('v-btn',{attrs:{"icon":"","small":"","text":"","data-cy":"buttonCloseSideMenu"},on:{"click":function($event){return _vm.environment.actions.clearAndShowSidebar(_vm.environment.getters.activeSidebarDialog.value, false)}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('div',{staticClass:"period-details__row mt-3"},[_c('span',[_vm._v(_vm._s(_vm.environment.getters.selectedPeriod.value.quote.project.projectName))]),_c('img',{attrs:{"src":require('@/assets/projects/statuses/' +
                _vm.helpers.getProjectStatusIcon(_vm.environment.getters.selectedPeriod.value.quote.project.projectStatus.id)),"alt":"project status icon"}})])])]:[_c('div',{staticClass:"period-details__header-container"},[_c('div',{staticClass:"period-details__row"},[_c('span',{staticClass:"period-details__header__title"},[_vm._v("No contractor")]),_c('v-btn',{attrs:{"icon":"","small":"","text":"","data-cy":"buttonCloseSideMenu"},on:{"click":function($event){return _vm.environment.actions.clearAndShowSidebar(_vm.environment.getters.activeSidebarDialog.value, false)}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('div',{staticClass:"period-details__row mt-3"},[_c('span',[_vm._v(_vm._s(_vm.environment.getters.selectedPeriod.value.quote.project.projectName))]),_c('img',{attrs:{"src":require('@/assets/projects/statuses/' +
                _vm.helpers.getProjectStatusIcon(_vm.environment.getters.selectedPeriod.value.quote.project.projectStatus.id)),"alt":"project status icon"}})])])]]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"content-container"},[(_vm.environment.getters.selectedPeriod.value.id)?[_c('date-section'),_c('sidemenu-workday')]:_vm._e(),_c('div',{staticClass:"period-details__row mt-9 px-4 flex-column"},[_vm._v(" "+_vm._s(_vm.$t('dictionary.period'))+" "+_vm._s(_vm.$t('dictionary.description'))+" "),_c('new-comment',{ref:"description",attrs:{"value":_vm.environment.getters.selectedPeriod.value.description,"rows":5},on:{"save":function($event){return _vm.environment.actions.updatePeriod()},"input":value => _vm.environment.actions.setPeriod({..._vm.environment.getters.selectedPeriod.value, description: value})}})],1)],2)]},proxy:true}],null,false,2306447052)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }