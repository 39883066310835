import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { TaxState } from '@/modules/settings/store/modules/taxes/types/TaxState';
import { RootState } from '@/core/types/RootState';
import { Actions, Mutations, Getters } from '@/modules/settings/store/modules/taxes/types/StoreTypes';
import { JsonResource } from '@/core/types/Entities';
import { Tax } from '@/modules/settings/types/entities';

export const actions: ActionTree<TaxState, RootState> = {
  [Actions.FETCH_TAXES]: async ({commit, state}: ActionContext<TaxState, RootState>) => {
    const url = '/api/v1/settings/taxes';

    try {
      const res: AxiosResponse<JsonResource<Tax[]>> = await Vue.prototype.$http.get(url);
      commit(Mutations.MUTATE_TAXES, res.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    }
  },
};
