const symbols = require.context('@/assets/settings/workflow/task-statuses', false, /\.svg$/);

const colorList: { [key: string]: string } = {
	'BLUE_1': 'blue-one',
	'BLUE_2': 'blue-two',
	'BLUE_3': 'blue-three',
	'BLUE_4': 'blue-four',
	'BLUE_5': 'blue-five',
	'BLUE_6': 'blue-six',
	'BLUE_7': 'blue-seven',
	'BLUE_8': 'blue-eight',
	'BLUE_9': 'blue-nine',
	'GREEN_1': 'green-one',
	'GREEN_2': 'green-two',
	'GREEN_3': 'green-three',
	'GREEN_4': 'green-four',
	'GRAY_1': 'gray-one',
	'GRAY_2': 'gray-two',
	'GRAY_3': 'gray-three',
	'GRAY_4': 'gray-four',
	'GREY_1': 'gray-one',
	'GREY_2': 'gray-two',
	'GREY_3': 'gray-three',
	'GREY_4': 'gray-four',
	'PURPLE_1': 'purple-one',
	'PURPLE_2': 'purple-two',
	'PURPLE_3': 'purple-three',
	'YELLOW_1': 'yellow-one',
	'YELLOW_2': 'yellow-two',
	'RED_1': 'red-one',
	'RED_2': 'red-two',
	'RED_3': 'red-three',
	'PINK_1': 'pink-one',
	'PINK_2': 'pink-two',
	'ORANGE_1': 'orange-one',
	'ORANGE_2': 'orange-two',
	'ORANGE_3': 'orange-three',
	'ORANGE_4': 'orange-four'
};

const colorListDefault = 'default';

const symbolsList: { [key: string]: string } = {
	'SYMBOL_ONE': 'symbol-one',
	'SYMBOL_TWO': 'symbol-two',
	'SYMBOL_THREE': 'symbol-three',
	'SYMBOL_FOUR': 'symbol-four',
	'SYMBOL_FIVE': 'symbol-five',
	'SYMBOL_SIX': 'symbol-six',
	'SYMBOL_SEVEN': 'symbol-seven',
	'SYMBOL_EIGHT': 'symbol-eight',
	'SYMBOL_NINE': 'symbol-nine',
	'SYMBOL_TEN': 'symbol-ten',
	'SYMBOL_ELEVEN': 'symbol-eleven',
	'SYMBOL_TWELVE': 'symbol-twelve'
};

export function taskStatusIcon(symbolKey: string): __WebpackModuleApi.RequireContext {
	return symbolsList[symbolKey]
		? symbols(`./${symbolsList[symbolKey]}.svg`)
		: undefined;
}

export function getColorValue(colorKey: string): string {
	return colorList[colorKey]
		? colorList[colorKey]
		: colorListDefault;
}

export function getColorPalette(): string[] {
	return Object.keys(colorList);
}

export function groupColour(colorKey: string): string {
	return `workflow__function-groups__background--${getColorValue(colorKey)}`;
}

export function statusColour(colorKey: string): string {
	return `workflow__task-statuses__background--${getColorValue(colorKey)}`;
}