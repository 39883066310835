import { render, staticRenderFns } from "./project-relationship-approval-actions.component.vue?vue&type=template&id=fdc85e0a&scoped=true"
import script from "./project-relationship-approval-actions.component.vue?vue&type=script&lang=ts"
export * from "./project-relationship-approval-actions.component.vue?vue&type=script&lang=ts"
import style0 from "./project-relationship-approval-actions.component.vue?vue&type=style&index=0&id=fdc85e0a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc85e0a",
  null
  
)

export default component.exports