export const enum Actions {
	FETCH_ROLES = 'FETCH_ROLES',
	SET_ROLE_PERMISSIONS_EDIT_STATE = 'SET_ROLE_PERMISSIONS_EDIT_STATE',
	TOGGLE_SINGLE_ROLE_STATE = "TOGGLE_SINGLE_ROLE_STATE",
	TOGGLE_ROLES_STATE = 'TOGGLE_ROLE_STATE',
	UNDO_ROLES_STATE = 'REVERT_TOGGLE_ROLE_STATE'
}

export const enum Getters {
	GET_PERMISSIONS = 'GET_PERMISSIONS',
	GET_ROLES = 'GET_ROLES',
	GET_ROLE_PERMISSIONS_EDIT_STATE = 'GET_ROLE_PERMISSIONS_EDIT_STATE'
}

export const enum Mutations {
	MUTATE_PERMISSIONS = 'MUTATE_PERMISSIONS',
	MUTATE_ROLES = 'MUTATE_ROLES',
	MUTATE_ROLE_PERMISSIONS_EDIT_STATE = 'MUTATE_ROLE_PERMISSIONS_EDIT_STATE'
}
