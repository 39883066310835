import { reactive, computed } from 'vue';
import { setReactiveProp } from '@/composables/utils';
import { AxiosError, AxiosResponse } from 'axios';
import { Pagination } from '@/core/types/Entities';
import useFilter from '@/composables/useFilter';
import useList from './useList';
import { UsersService } from '@/services/users.service';
import { UseUserListState } from './types/useUserList';
import { useUserListStateFactory } from './factories/useUserList';
import { Role, User } from '@/modules/entities/types/entities';
import { Permission } from '@/modules/settings/store/modules/permissions/types/PermissionsState';
import { RolesService } from '@/services/roles.service';

// services
const userService = new UsersService();
const roleService = new RolesService();

/**
 * `UseUserList`-composable
 *   - All User-related interactions and functionality can be stored here.
 *   - If you need a global store, than just use this composable in eg a `store.ts`
 *
 * @param filter
 * @param defaultState
 * @returns
 */
export default function useUserList(filter = useFilter(), items = useList<User>(), defaultState?: Partial<UseUserListState>)
{
  /**
     * Local state
     *
     */
  const state = reactive(useUserListStateFactory(defaultState));

  /**
     * Actions
     *
     */
  const fetchUsers = async (): Promise<AxiosResponse<Pagination<User[]>>|AxiosError> => {
    setReactiveProp(state.loadingActions, fetchUsers.name, true);
    try {
      const url = userService.getBaseEndpoint() + `${ filter.getters.filterUrlQuery.value ? '?' + filter.getters.filterUrlQuery.value.toString() : '' }`;
      const result = await userService.getAllUsers(url);
      items.actions.setItems(result.data.data);
      return Promise.resolve(result);
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      setReactiveProp(state.loadingActions, fetchUsers.name, false);
    }
  };
  const fetchRoles = async (): Promise<AxiosResponse<{ data: { permissions: Permission[]; roles: Role[] } }>|AxiosError> => {
    setReactiveProp(state.loadingActions, fetchUsers.name, true);
    try {
      const result = await roleService.getAllRolesAndPermissions();
      state.roles = result.data.data.roles;
      return Promise.resolve(result);
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      setReactiveProp(state.loadingActions, fetchUsers.name, false);
    }
  };
  const search = async (query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<User[]>> | AxiosError> => {
    setReactiveProp(state.loadingActions, search.name, true);
    state.searching = true;
    try {
      let urlSearchParams = null;
      if(useAsFilter) {
        if(query) filter.actions.setFilter('search', query);
        else filter.actions.deleteFilter('search');
        urlSearchParams = filter.getters.filterUrlQuery.value;
      } else {
        const f = useFilter();
        if(query) f.actions.setFilter('search', query);
        urlSearchParams = f.getters.filterUrlQuery.value;
      }
      const url = userService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`;
      const result = await userService.getAllUsers(url);
      items.actions.setItems(result.data.data);
      return Promise.resolve(result);
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      setReactiveProp(state.loadingActions, search.name, false);
      state.searching = false;
    }
  };

  // return state and actions
  return {
    state: {
      users: items.getters.items,
      roles: computed<Role[]>(() => state.roles),
      isLoading: computed<boolean>(() => Object.entries(state.loadingActions).map((v: [string, boolean]) => v[1]).includes(true)),
      isSearching: computed<boolean>(() => state.searching),
      ...filter.getters,
    },
    actions: {
      fetchUsers,
      fetchRoles,
      search,
      ...filter.actions,
      ...items.actions,
    },
  };
}