import { Tax } from '@/modules/settings/types/entities';

export enum Type {
	REVENUE = "revenue",
	EXPENSE = "expense",
}

export enum TransactionType {
	DEBIT = "debit",
	CREDIT = "credit",
}

export interface AccountingLedger {
	id?: number;
    name: string;
    startDate: string | null;
    endDate: string | null;
    description: string | null;
    active: boolean;
    accountingLedgerItems: AccountingLedgerItem[] | null;
}

export class AccountingLedgerEntity implements AccountingLedger {
	id = undefined;
    name = '';
    startDate = '';
    endDate = '';
    description = '';
    active = false;
    accountingLedgerItems = null;

    constructor(data: Partial<AccountingLedger> = {}) {
        Object.assign(this, data)
    }
}

export interface AccountingLedgerItem {
	id?: number;
    name: string;
    description: string | null;
    type: Type;
    defaultTransactionType: TransactionType;
    active: boolean;
    isProjectRelated: boolean;
    accountingLedger: AccountingLedger | null;
    taxes: Tax[] | null;
    defaultVat: Tax | null;
}

export class AccountingLedgerItemEntity implements AccountingLedgerItem {
    id = undefined;
    name = '';
    description = '';
    type = Type.EXPENSE;
    defaultTransactionType = TransactionType.DEBIT;
    active = false;
    isProjectRelated = false;
    accountingLedger = null;
    taxes = [] as Tax[];
    defaultVat = null;

    constructor(data: Partial<AccountingLedgerItem> = {}) {
        Object.assign(this, data)
    }
}