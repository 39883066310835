import { GetterTree } from 'vuex';
import { AccountingState } from './types/AccountingState';
import { RootState } from '@/core/types/RootState';
import { Getters } from './types/StoreTypes';

export const getters: GetterTree<AccountingState, RootState> = {
	[Getters.GET_ACTIVE_ACCOUNTING_LEDGER]: (state: AccountingState) => {
		return state.activeAccountingLedger;
	},
	[Getters.GET_IS_LOADING]: (state: AccountingState) => {
		return state.isLoading;
	},
	[Getters.GET_ACCOUNTING_LEDGERS]: (state: AccountingState) => {
		return state.accountingLedgers;
	},
	[Getters.GET_ACCOUNTING_LEDGER_ITEM]: (state: AccountingState) => {
		return state.accountingLedgerItem
	},
	[Getters.GET_ACCOUNTING_LEDGER]: (state: AccountingState) => {
		return state.accountingLedger;
	},

}