import dayjs from 'dayjs';

export class DatetimeCalculator {

    constructor(data?: {}) {
        Object.assign(this, data)
    }

    lastMonth(): string[] {
        return this.lastMonths(1);
    }

    lastMonths(amount = 1): string[] {
        const start = dayjs().subtract(amount, 'month').startOf('month').format('YYYY-MM-DD');
        const end = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        return [start, end];
    }

    lastMonthsToDate(amount = 1): string[] {
        const start = dayjs().subtract(amount, 'month').startOf('month').format('YYYY-MM-DD');
        const end = dayjs().format('YYYY-MM-DD');
        return [start, end];
    }

    yearToDate(): string[] {
        const start = dayjs().startOf('year').format('YYYY-MM-DD');
        const end = dayjs().format('YYYY-MM-DD');
        return [start, end];
    }

    year(): string[] {
        const start = dayjs().startOf('year').format('YYYY-MM-DD');
        const end = dayjs().endOf('year').format('YYYY-MM-DD');
        return [start, end];
    }
}

export default function datetimeCalculate(): DatetimeCalculator {
    return new DatetimeCalculator();
}