
import { defineComponent } from 'vue';
import { formatDate } from '@/core/helpers/useFormatDate';
import authStore from "@/modules/login/store";

export default defineComponent({
	name: 'NewCommentComponent',
	emits: ['close', 'save', 'input'],
	props: {
      value: {
          type: String,
      },
      rows: {
        type: Number,
        default: 3
      }
	},
	setup(props) {
		return {
          auth: authStore.auth,
			formatDate
		};
	}
});
