
  import { computed, defineComponent, ref, watch, PropType } from 'vue';
  import SettingsCollaboratorListItemComponent from '@/modules/calendar/components/settings-collaborator-list-item.component.vue';
  import { Supplier } from '@/modules/entities/types/entities';
  import { Division } from '@/modules/settings/types/entities';

  export default defineComponent({
    components: {
      'collaborator-list-item': SettingsCollaboratorListItemComponent,
    },
    props: {
      collaborators: {
        type: Array as PropType<(Division | Supplier)[]>,
        default: () => [],
      },
    },
    setup(props, {}) {},
  });
