import './registerComponentHooks';
import Vue from 'vue';
import Vuetify, { UserVuetifyPreset } from 'vuetify';
import VueI18n from 'vue-i18n';
import en from '@/i18n/en';
import nl from '@/i18n/nl';
// @ts-ignore
import VCurrencyField from 'v-currency-field';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';
import { store } from '@/core/core.module';
import App from './App.vue';
import router from './router';
import {setupSentry} from '@/config/setupLogging';
import authStore from "@/modules/login/store";

/* Vuetify Material Design plugin */
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuelidate as any);

import '@/styles/main.scss';

Vue.router = router;
Vue.prototype.$can = authStore.auth.can;
Vue.config.productionTip = false;

/* i18n */
Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'nl',
  fallbackLocale: 'en',
  messages: {
    en,
    nl,
  },
  silentTranslationWarn: process.env.NODE_ENV === 'production',
});

/* Vuetify */
const opts: Partial<UserVuetifyPreset> = {
  lang: {
    t: (key: any, ...params: any) => i18n.t(key, params).toString(),
  },
  theme: {
    themes: {
      light: {
        primary: '#337DE4',
        accent: '#979797',
      },
    },
  },
};
Vue.use(Vuetify);

/**
 * Configure http/api
 */
import { configuration as httpConfig } from '@/config/http';
export const http = httpConfig.apply(Vue);

/**
 * Configure logging
 *   - placed after auth setup to be able to use authenticated user information inside logging
 */
setupSentry();


/**
 * Configure filters
 */
import { configuration as filtersConfig } from '@/config/filters';
filtersConfig.apply(Vue, i18n);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: 'places',
  },
});

Vue.prototype.$sfinxHqGeoCoordinates = { lat: 51.0218, lng: 3.4451 };

// @ts-ignore
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

Vue.use(Donut);

// use v-currency-field
const vueCurrencyFieldOptions = {
  value: 0,
  currency: 'EUR',
  locale:  i18n.locale,
  autoDecimalMode: false,
  distractionFree: false, // ISSUE: if you set this to true, the currency symbol will dissapear (which is ok), but if the original value is negative, the minus sign will dissapear after changing
  valueAsInteger: true,
  allowNegative: true,
};
Vue.prototype.$vueCurrencyFieldOptions = vueCurrencyFieldOptions;
Vue.use(VCurrencyField, vueCurrencyFieldOptions);

Vue.directive('focus', {
  inserted: function(el) {
    el.focus();
  },
});

Vue.directive('tooltip', {
  bind(el: HTMLElement): void {
    el.addEventListener('mouseover', (evt: MouseEvent) => {
      const targetEl = evt.target;
      if (targetEl) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (targetEl.offsetWidth < targetEl.scrollWidth) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          targetEl.setAttribute('title', evt.target.textContent);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          targetEl.hasAttribute('title') && targetEl.removeAttribute('title');
        }
      }
    });
  },
});

new Vue({
  i18n,
  router,
  store,
  vuetify: new Vuetify(opts),
  render: (h: any) => h(App),
}).$mount('#app');
