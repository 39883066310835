
    import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
    import DashboardDefaultComponent from '@/modules/home/components/dashboard-default/dashboard-default.component.vue'

    @Component({
        components: {
            'dashboard-default': DashboardDefaultComponent
        }
    })
    export default class HomeComponent extends Vue { }
