
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import { SalesInvoice, SalesInvoiceRecordType } from '@/modules/sales-invoices/types/entities';
import { Project } from '@/modules/projects/types/entities';
import { groupBy } from "lodash";
import { sortNatural } from '@/core/helpers/Sort'
import { DataTableCompareFunction, DataTableHeader } from 'vuetify';
// @ts-ignore
import {groupItems} from 'vuetify/lib/util/helpers'
import { signAmount } from '@/modules/sales-invoices/helpers';

@Component({})
export default class SalesInvoiceRelatedList extends Vue {
	
	@Prop({ required: true })
	value!: SalesInvoice;

    @Prop({ default: [] })
	items!: SalesInvoice[];
	
	@Prop({ default: () => { return true }  })
	groupingEnabled!: boolean;

	@Prop({ default: () => { return true }  })
	groupingFieldShown!: boolean;

	@Prop()
	groupingDefault!: string;

	@Prop({type: Boolean, default: false})
	loading!: boolean;

	// vars
	summarize: string[] = ['total', 'deductedAdvance'];
	selectedGroup: string | null = 'subtype.name';
	typeOrder = ['default','advance','study_agreement','other']

	// methods
	groupBy(key:string, items:any[]) {
		return Object.entries(groupBy(items, key)).map((value) => { 
			return {
				groupBy: value[0],
				items: value[1]
			};
		});
	}
	summarizeBy(key: any, items: SalesInvoice[], sign: boolean = true) {
        return items.reduce((a: number, b: SalesInvoice) => a + (sign && b.recordType ? this.signAmount((b as any)[key] || 0, b.recordType) : ((b as any)[key] || 0)), 0)
	}
	customGroupItems(items: SalesInvoice[], groupBy: string[], groupDesc: boolean[]) {
		const groups = groupItems(items, groupBy, groupDesc);
		let sortedGroups: any[] = [...groups];
		if(this.selectedGroup === 'type.name') sortedGroups.sort((a: any, b: any) => this.typeOrder.findIndex(v => v === a.name) - this.typeOrder.findIndex(v => v === b.name))
		return sortedGroups
	}
	signAmount(amount: number, recordType: SalesInvoiceRecordType) {
		return signAmount(amount, recordType)
	}
	
	// getters
	get project() {
		if(this.value && this.value.project) return this.value.project;
		return null;
	}
	get headers(): any[] {
		return [
			{
				text: this.$t('invoices.sales.details.relatedLists.projectInvoices.headers.name').toString(),
				align: 'start',
				sortable: true,
				value: 'name',
				filterable: true,
				groupable: false,
				sort: (a:any, b:any): number => { return sortNatural(a, b, { locale: this.$i18n.locale }) }
			},
			// {
			// 	text: this.$t('invoices.sales.details.relatedLists.projectInvoices.headers.invoiceDate').toString(),
			// 	align: 'start',
			// 	sortable: true,
			// 	value: 'invoiceDate',
			// 	filterable: true,
			// 	groupable: false,
			// },
			// {
			// 	text: this.$t('invoices.sales.details.relatedLists.projectInvoices.headers.dueDate').toString(),
			// 	align: 'start',
			// 	sortable: true,
			// 	value: 'dueDate',
			// 	filterable: true,
			// 	groupable: false,
			// },
			{
				text: this.$t('invoices.sales.details.relatedLists.projectInvoices.headers.type.name').toString(),
				align: 'start',
				sortable: true,
				value: 'type.name',
				filterable: true,
				groupable: true,
				// sort: (a:any, b:any): number => {
				// 	console.log(a)
				// 	console.log(b)
				// 	return this.typeOrder.findIndex(v => v === a) - this.typeOrder.findIndex(v => v === b)
				// }
			},
			{
				text: this.$t('invoices.sales.details.relatedLists.projectInvoices.headers.subtype.name').toString(),
				align: 'start',
				sortable: true,
				value: 'subtype.name',
				filterable: true,
				groupable: true,
			},
			{
				text: this.$t('invoices.sales.details.relatedLists.projectInvoices.headers.total').toString(),
				align: 'end',
				sortable: true,
				value: 'total',
				filterable: false,
				groupable: false
			},
			{
				text: this.$t('invoices.sales.details.relatedLists.projectInvoices.headers.deductedAdvance').toString(),
				align: 'end',
				sortable: true,
				value: 'deductedAdvance',
				filterable: false,
				groupable: false
			},
			{
				text: this.$t('invoices.sales.details.relatedLists.projectInvoices.headers.paymentStatus.name').toString(),
				align: 'start',
				sortable: true,
				value: 'paymentStatus.name',
				filterable: true,
				groupable: true,
			},
			{
				text: this.$t('invoices.sales.details.relatedLists.projectInvoices.headers.comment').toString(),
				align: 'start',
				sortable: true,
				value: 'comment',
				filterable: true,
				groupable: false,
			},
			{
				text: this.$t('dictionary.totalAmountOrdered').toString(),
				align: 'start',
				sortable: true,
				value: 'progressSnapshot.totalAmountOrdered',
				filterable: true,
				groupable: false,
			},
			{
				text: this.$t('dictionary.totalAmountExecuted').toString(),
				align: 'start',
				sortable: true,
				value: 'progressSnapshot.totalAmountExecuted',
				filterable: true,
				groupable: false,
			}
		];
	}
	summarizeTotal(salesInvoice: SalesInvoice) {
		return salesInvoice.total && salesInvoice.recordType ? this.signAmount(salesInvoice.total, salesInvoice.recordType) : 0
	}
	get summarizers() {
		return [
			{
				value:'total', 
				summarize: (items: SalesInvoice[]) => { 
					let totals = [];
					return this.summarizeBy('total', items)
				}, 
			},{
				value:'deductedAdvance', 
				summarize: (items: SalesInvoice[]) => { 
					let totals = [];
					return this.summarizeBy('deductedAdvance', items)
				}, 
			}
		];
	}
	get formatters() {
		return [
			{
				value:'total', 
				format: (value: any) => { return  this.$options.filters ? this.$options.filters.formatMoney(value) : undefined; }
			},
			{
				value:'deductedAdvance', 
				format: (value: any) => { return  this.$options.filters ? this.$options.filters.formatMoney(value) : undefined; }
			}
		]
	}

	// hooks
	created() {
		this.selectedGroup = this.groupingDefault;
	}
}
