import dayjs from 'dayjs';

export function formatDate(value: string): string {
  try {
    let djs = dayjs(value);
    djs = djs.local();
    return djs.locale('nl-BE').format('L');
  } catch (error: any) {
    return '---';
  }
}
