
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SalesInvoice, SalesInvoiceEntity } from '@/modules/sales-invoices/types/entities';
import SalesInvoiceDetails from '@/modules/sales-invoices/components/sales-invoice-details/sales-invoice-details.component.vue'
import { Actions, Getters } from '@/modules/sales-invoices/store/types/StoreTypes';
import { AxiosError } from 'axios';
import Loading from '@/core/components/loading/loading.component.vue';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
import { PropType } from 'vue';

const salesInvoices = namespace('salesInvoices');

@Component({
	components: {
		"sales-invoice-details": SalesInvoiceDetails,
		"loading": Loading
	}
})
export default class SalesInvoiceModalComponent extends Vue {

	// actions
	@salesInvoices.Action(Actions.SAVE_INVOICE) saveSalesInvoice!: (payload: SalesInvoice) => Promise<any>;
	@salesInvoices.Action(Actions.CREATE_INVOICE) createSalesInvoice!: (payload: SalesInvoice) => void;
	@salesInvoices.Action(Actions.FETCH_NEW) fetchNew!: () => Promise<void>;
	
	// getters
	@salesInvoices.Getter(Getters.GET_INVOICE) salesInvoice!: SalesInvoice;
	@salesInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;
	@salesInvoices.Getter(Getters.GET_NEW) new!: SalesInvoice;

	@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
	@Prop({default: 'modal'}) viewMode!: string; // 'default','modal'
	@Prop({default: () => { return new SalesInvoiceEntity() }}) defaultNewSalesInvoice!: SalesInvoice;
	@Prop({type: Array as PropType<Array<ContractorBranch>>, default: () => [] }) contractorBranches!: ContractorBranch[];

	// local vars
	state: {
		saving: boolean,
		cancelling: boolean,
		modifiedSalesInvoice: boolean,
		showConfirmDelete: boolean,
		showNew: boolean,
	} = {
		saving: false,
		cancelling: false,
		modifiedSalesInvoice: false,
		showConfirmDelete: false,
		showNew: false,
	}
	content: {
		salesInvoice: SalesInvoice|null,
		validationErrors: any,
	} = {
		salesInvoice: null,
		validationErrors: {},
	}

	// handlers
	onClickCancel(): void {
		this.closeModal();
	}
	onClickSave(showNew: boolean) {
		this.state = {...this.state, showNew: showNew}
		this.save(this.content.salesInvoice)
	}

	// methods
	closeModal() {
		this.state = {...this.state, showNew: false }
		this.showModalSync = false
	}
	newSalesInvoice() {
		this.fetchNew().then(() => {
			this.createSalesInvoice({...this.defaultNewSalesInvoice, name: this.new.name, reference: this.new.name})
		})
	}
	save(invoice: SalesInvoice|null) {
		if(invoice) {
			this.saveSalesInvoice(invoice).then((salesInvoice: SalesInvoice) => {
				this.content = {...this.content, validationErrors: {}}
				this.state = {...this.state, saving: false}
				if(!this.state.showNew) {
					this.closeModal();
					this.$router.push(`/invoices/sales/${salesInvoice.id}`);
				} else this.newSalesInvoice()
			}).catch((err: AxiosError) => {
				if (err.response && err.response.status === 422) {
					this.content = { ...this.content, validationErrors: err.response.data.errors }
				} else {
					throw err;
				}
			});
		}
	}

	@Watch('salesInvoice', { deep: true, immediate: false })
	onWatchSalesInvoice(salesInvoice: any) {
		this.content = {...this.content, salesInvoice: {...salesInvoice}}
	}

	// boot methods
	created(): void {
		// 
	}

	destroyed(): void {
		//
	}
}
