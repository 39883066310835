
	import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { ProjectRelationship, ProjectRelationshipEntity } from '@/modules/purchase-invoices/types/entities';
	import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
	import { AxiosResponse } from 'axios';
	import { JsonResource } from '@/core/types/Entities';
	import { PropType } from 'vue';

	const purchaseInvoices = namespace('purchaseInvoices');

	@Component
	export default class ProjectRelationshipDeleteModalComponent extends Vue {

		// vuex actions
		@purchaseInvoices.Action(Actions.DELETE_PROJECT_RELATIONSHIP) deleteProjectRelationship!: (payload: ProjectRelationship) => Promise<AxiosResponse<JsonResource<ProjectRelationship>>>;

		// vuex getters
		@purchaseInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;

		// props
		@PropSync('projectRelationship', { type: Object as PropType<ProjectRelationship> }) projectRelationshipSync!: ProjectRelationship
		@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;

		@Ref('confirmDelete') readonly confirmDelete!: Vue
		
		// vars
		state: {
			local: {
				projectRelationshipDeleting: boolean
				projectRelationshipDeleted: boolean
			}
		} = {...this.newState()}

		// handlers
		onClickClose(value: any): void {
			this.closeModal();
			this.state = { ...this.newState() }
			this.$emit('onCancel')
		}
		async onClickDeleteProjectRelationship() {
			this.state.local = {...this.state.local, projectRelationshipDeleting: true }

			// ensure project relationship
			if(!this.projectRelationshipSync) throw new Error('No project relationship provided')
			
			// save
			const result = await this.deleteProjectRelationship(this.projectRelationshipSync);

			// update state
			this.state.local = {...this.state.local, projectRelationshipDeleted: true, projectRelationshipDeleting: false }
			this.state = { ...this.newState() }
			this.closeModal()
		}

		// methods
		closeModal() {
			this.showModalSync = false
		}
		newState() {
			return {
				local: {
					projectRelationshipDeleted: false,
					projectRelationshipDeleting: false
				}
			}
		}

		// getters 

		// watchers
		@Watch('projectRelationshipSync', { deep: true, immediate: false })
		onWatchValue(value: any) {
			setTimeout(() => { (this.confirmDelete.$el as HTMLButtonElement).focus() } )
		}
		
		// lifecycle
		mounted(): void {
			//
		}

		created(): void {
			//
		}

		destroyed(): void {
			//
		}
	}
