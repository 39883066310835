
	import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from "vuex-class";
	import { debounce } from "lodash";
	import datetimeCalculate from '@/core/helpers/DatetimeCalculator'
    import { Project, ProjectUser } from "@/modules/projects/types/entities";
    import { SalesInvoice, SalesInvoicePaymentStatus, SalesInvoiceStatus, SalesInvoiceType } from "@/modules/sales-invoices/types/entities";
	import { Actions, Getters } from "@/modules/sales-invoices/store/types/StoreTypes";
	import { PropType } from 'vue/types/options';
	import { FilterInterface, FilterState } from '@/core/types/Filter';
	import Fuse from 'fuse.js'
	import _ from 'lodash';
	import ProjectAutocompleteComponent from '@/modules/sales-invoices/components/project-autocomplete/project-autocomplete.component.vue'
	import { Tax } from '@/modules/settings/types/entities';
	import { User } from '@/modules/entities/types/entities';

	const invoices = namespace("salesInvoices");

	@Component({
		components: {
			'project-autocomplete': ProjectAutocompleteComponent
		}
	})
	export default class ProjectsSideMenuComponent extends Vue {

		// store
		@invoices.Action(Actions.TOGGLE_FILTER_SIDEBAR) toggleFilterSidebar!: (payload: boolean) => void;
		@invoices.Action(Actions.TOGGLE_FILTER_GROUP) toggleFilterGroup!: (payload: {group: string; fetch?: boolean}) => void;
		@invoices.Action(Actions.SET_FILTER_ON_STATUS) filterOnStatus!: (payload: number) => void;
		@invoices.Action(Actions.SET_FILTER_ON_PAYMENT_STATUS) filterOnPaymentStatus!: (payload: number) => void;
		@invoices.Action(Actions.SET_FILTER_ON_TYPE) filterOnType!: (payload: number) => void;
		@invoices.Action(Actions.SET_FILTER_ON_DUE_DATE_FROM) filterOnDueDateFrom!: (payload: string|null) => void;
		@invoices.Action(Actions.SET_FILTER_ON_DUE_DATE_TO) filterOnDueDateTo!: (payload: string|null) => void;
		@invoices.Action(Actions.SET_FILTER_ON_INVOICE_DATE_FROM) filterOnInvoiceDateFrom!: (payload: string|null) => void;
		@invoices.Action(Actions.SET_FILTER_ON_INVOICE_DATE_TO) filterOnInvoiceDateTo!: (payload: string|null) => void;
		@invoices.Action(Actions.SET_FILTER_ON_PROJECT_LEAD) filterOnProjectLead!: (payload: number) => void;
		@invoices.Action(Actions.SET_FILTER_ON_PROJECT_NAME) filterOnProjectName!: (payload: string) => void;
		@invoices.Action(Actions.SET_FILTER_ON_PROJECT) filterOnProject!: (payload: Project|null) => void;
		@invoices.Action(Actions.SET_FILTER_ON_VAT) filterOnVat!: (payload: number) => void;
		@invoices.Action(Actions.CLEAR_FILTERS) clearFilters!: (payload: {filters: string[]; fetch?: boolean}) => void;
		@invoices.Action(Actions.SEARCH_PROJECTS) searchProjects!: (payload: string) => Promise<any>;

		// getters
		@invoices.Getter(Getters.GET_SEARCH_PROJECTS) projects!: Project[];
		@invoices.Getter(Getters.GET_FILTER_PROJECT_MODEL) filterProjectModel!: Project;

		// properties
		@Prop() filterState!: FilterState;
		@Prop({ type: Array as PropType<SalesInvoicePaymentStatus[]>, default: [] }) availablePaymentStatuses!: SalesInvoicePaymentStatus[];
		@Prop({ type: Array as PropType<SalesInvoiceStatus[]>, default: [] }) availableStatuses!: SalesInvoiceStatus[];
		@Prop({ type: Array as PropType<SalesInvoiceType[]>, default: [] }) availableTypes!: SalesInvoiceType[];
		@Prop({ type: Array as PropType<Tax[]>, default: [] }) availableTaxes!: Tax[];
		@Prop({ type: Array as PropType<User[]>, default: [] }) users!: User[];

		// vars
		state: {
			searchingProjects: boolean,
		} = {
			searchingProjects: false,
		}

		onSearchProject(searchTerm: string) {
			if(searchTerm) this.onSearchProjectDebounced(searchTerm, this)
		}
		onSearchProjectDebounced = _.debounce((searchTerm: string, vm: Vue) => {
			vm.$data.state = { ...vm.$data.state, searchingProjects: true }
			this.searchProjects(searchTerm).then(() => { vm.$data.state = {...vm.$data.state, searchingProjects: false} });
		}, 300);
		onChangeProject(project: Project) {
			if(project) {
				if(!this.filterGroupIsActive('projects')) this.toggleFilterGroup({group:'projects', fetch: false})
				this.filterOnProject(project)
			}
			else {
				if(this.filterGroupIsActive('projects')) this.toggleFilterGroup({group:'projects'})
				this.filterOnProject(null)
				this.clearFilters({filters: ['projects'], fetch: false})
			}
		}
		onSelectDueDateFrom(from: string) {
			this.filterOnDueDateFrom(from)
		}
		onSelectDueDateTo(to: string) {
			this.filterOnDueDateTo(to)
		}
		onClickClearDueDateFrom() {
			this.filterOnDueDateFrom(null);
		}
		onClickClearDueDateTo() {
			this.filterOnDueDateTo(null);
		}
		onSelectInvoiceDateFrom(from: string) {
			this.filterOnInvoiceDateFrom(from);
		}
		onSelectInvoiceDateTo(to: string) {
			this.filterOnInvoiceDateTo(to);
		}
		onClickClearInvoiceDateFrom() {
			this.filterOnInvoiceDateFrom(null);
		}
		onClickClearInvoiceDateTo() {
			this.filterOnInvoiceDateTo(null);
		}
		onChangeOpenPaymentStatuses(enabled: boolean) {
			if(enabled) {
				this.availablePaymentStatuses.filter(status => status.sequence < 4).forEach(status => {
					this.filterOnPaymentStatus(status.id);
				})
			} else {
				const selectedPaymentStatuses = this.filterValues('paymentStatus');
				this.availablePaymentStatuses.filter(status => status.sequence < 4).forEach(status => {
					if(selectedPaymentStatuses.includes(status.id)) this.filterOnPaymentStatus(status.id);
				})
			}
		}
		
		get hasOpenPaymentStatusesSelected() {
			return this.availablePaymentStatuses.filter(status => status.sequence < 4).some(status => this.filterValueSelected('paymentStatus', status.id))
		}
		get dueDateFrom() {
			const date = this.filterState.filters.dueDateFrom.value
			return date.length && date[0] ? date[0] : ''
		}
		get dueDateTo() {
			const date = this.filterState.filters.dueDateTo.value
			return date.length && date[0] ? date[0] : ''
		}
		get showDueDateFrom() {
			if(this.filterValuesSelected('dueDateFrom')) {
				const date = this.filterState.filters.dueDateFrom.value[0]
				return date ? Vue.prototype.$formatDate(date) : ''
			}
			return '';
		}
		get showDueDateTo() {
			if(this.filterValuesSelected('dueDateTo')) {
				const date = this.filterState.filters.dueDateTo.value[0]
				return date ? Vue.prototype.$formatDate(date) : ''
			}
			return '';
		}
		get invoiceDateFrom() {
			const date = this.filterState.filters.invoiceDateFrom.value
			return date.length && date[0] ? date[0] : ''
		}
		get invoiceDateTo() {
			const date = this.filterState.filters.invoiceDateTo.value
			return date.length && date[0] ? date[0] : ''
		}
		get showInvoiceDateFrom() {
			if(this.filterValuesSelected('invoiceDateFrom')) {
				const date = this.filterState.filters.invoiceDateFrom.value[0]
				return date ? Vue.prototype.$formatDate(date) : ''
			}
			return '';
		}
		get showInvoiceDateTo() {
			if(this.filterValuesSelected('invoiceDateTo')) {
				const date = this.filterState.filters.invoiceDateTo.value[0]
				return date ? Vue.prototype.$formatDate(date) : ''
			}
			return '';
		}
		get hasFilters() {
			for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
				if(filter.hasValues()) return true;
			}
			return false;
		}


		// financial date calculator
		financialDateCalculator: any = datetimeCalculate();

		// projectLead filter
		projectLeadFilterSearchValue: string = '';
		projectLeadFilterSearchFuseOptions = { keys: ['lastName','firstName'], threshold: 0.4 };
		get projectLeads() { return this.users.filter(p => p.isActive) }
		get projectLeadFilterSearchFuse(){ return new Fuse(this.projectLeads, this.projectLeadFilterSearchFuseOptions) }
		get filteredProjectLeadList(): User[] {
			let results = [] as User[];
			if (this.projectLeadFilterSearchValue !== "") {
				this.projectLeadFilterSearchFuse.search(this.projectLeadFilterSearchValue).map((value, index) => {
					results.push(value.item);
				});
				return results;
			}
			return this.projectLeads;
		}

        // status filter
		statusFilterSearchValue: string = '';
		statusFilterSearchFuseOptions = { keys: ['label'], threshold: 0.4 };
		get statusFilterSearchFuse() { return new Fuse(this.availableStatuses, this.statusFilterSearchFuseOptions) }
		get filteredStatusList(): SalesInvoiceStatus[] {
			let results = [] as SalesInvoiceStatus[];
			if (this.statusFilterSearchValue !== "") {
				this.statusFilterSearchFuse.search(this.statusFilterSearchValue).map((value, index) => {
					results.push(value.item);
				});
				return results;
			}
			return this.availableStatuses;
		}

		// payment status filter
		paymentStatusFilterSearchValue: string = '';
		paymentStatusFilterSearchFuseOptions = { keys: ['label'], threshold: 0.4 };
		get paymentStatusFilterSearchFuse() { return new Fuse(this.availablePaymentStatuses, this.paymentStatusFilterSearchFuseOptions) }
		get filteredPaymentStatusList(): SalesInvoicePaymentStatus[] {
			let results = [] as SalesInvoicePaymentStatus[];
			if (this.paymentStatusFilterSearchValue !== "") {
				this.paymentStatusFilterSearchFuse.search(this.paymentStatusFilterSearchValue).map((value, index) => {
					results.push(value.item);
				});
				return results;
			}
			return this.availablePaymentStatuses;
		}

		// type filter
		typeFilterSearchValue: string = '';
		typeFilterSearchFuseOptions = { keys: ['label'], threshold: 0.4 };
		get typeFilterSearchFuse() { return new Fuse(this.availableTypes, this.typeFilterSearchFuseOptions) }
		get filteredTypesList(): SalesInvoiceType[] {
			let results = [] as SalesInvoiceType[];
			if (this.typeFilterSearchValue !== "") {
				this.typeFilterSearchFuse.search(this.typeFilterSearchValue).map((value, index) => {
					results.push(value.item);
				});
				return results;
			}
			return this.availableTypes;
		}

		// dueDate filter
		quickSelectOnDueDate(type: string): void {
			const selectedDueDates = this.calculateFinancialDate(type);
			this.filterOnDueDateFrom(selectedDueDates[0]);
			this.filterOnDueDateTo(selectedDueDates[1]);
		}

		// invoiceDate filter
		quickSelectOnInvoiceDate(type: string): void {
			const selectedInvoiceDates = this.calculateFinancialDate(type);
			this.filterOnInvoiceDateFrom(selectedInvoiceDates[0]);
			this.filterOnInvoiceDateTo(selectedInvoiceDates[1]);
		}
		
		// project filter
		projectNameFilterSearchValue: string = '';
		filterOnProjectNameDebounced = debounce((value: string) => {
			this.filterOnProjectName(value);
		}, 400);

		// general 
		toggleSideBarOpen(): void {
			this.toggleFilterSidebar(!this.filterState.sideBarOpen);
		}

		clearSearchValues(): void {
			this.statusFilterSearchValue = '';
			this.typeFilterSearchValue = '';
			this.projectNameFilterSearchValue = '';
		}

		filterIsIndeterminate(group: string) {
			if(group === 'status') {
				const filter = this.filterState.filters.status as FilterInterface;
				if(filter.active && filter.value.length != this.availableStatuses.length) return true;
				return false;
			}
		}

		filterGroupIsActive(group: string) {
			for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
				if(filter.group === group && filter.active) return true;
			}
			return false;
		}

		filterValueSelected(filterName: string, filterValue: any) {
			for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
				if(filter.name === filterName && filter.value.includes(filterValue)) return true;
			}
			return false;
		}

		filterValues(filterName: string) {
			for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
				if(filter.name === filterName) return filter.value as any[];
			}
			return [];
		}

		filterValuesSelected(filterName: string) {
			for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
				if(filter.name === filterName && filter.hasValues()) return true;
			}
			return false;
		}

		@Emit('clearFilters')
		onEmitClearFilters(): void {
			// clear search values
			this.clearSearchValues();

			// clear all available filters
			const filtersToClear = [] as string[];
			for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
				filtersToClear.push(key);
			}

			// project specific filter
			if(this.filterGroupIsActive('projects')) this.toggleFilterGroup({group:'projects', fetch: false})
			this.filterOnProject(null)

			this.clearFilters({filters: filtersToClear});
		}
		
		calculateFinancialDate(type: string): string[] {
			let start,end;
			switch (type) {
				case 'lastMonth':
					return this.financialDateCalculator.lastMonth();
					break;
				case 'lastThreeMonths':
					return this.financialDateCalculator.lastMonths(3);
					break;
				case 'lastThreeMonthsToDate':
					return this.financialDateCalculator.lastMonthsToDate(3);
					break;
				case 'yearToDate':
					return this.financialDateCalculator.yearToDate();
					break;
				default:
					return [];
					break;
			}
		}
	}
