
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import datetimeCalculate from '@/core/helpers/DatetimeCalculator'
import { FilterInterface, FilterState } from '@/core/types/Filter'
import {
  AccountingStatus,
  ApprovalStatus,
  PurchaseInvoicePaymentStatus,
  PurchaseInvoiceStatus
} from '@/modules/purchase-invoices/types/entities'
import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes'
import { PropType } from 'vue/types/options'
import Fuse from 'fuse.js'
import { Supplier, User } from '@/modules/entities/types/entities'
import _ from 'lodash'
import SupplierAutocompleteComponent from '@/modules/purchase-invoices/components/supplier-autocomplete/supplier-autocomplete.component.vue'
import { AxiosResponse } from 'axios'
import { Pagination } from '@/core/types/Entities'

const invoices = namespace('purchaseInvoices')

@Component({
  components: {
    'supplier-autocomplete': SupplierAutocompleteComponent,
  },
})
export default class PurchaseInvoicesSideMenuComponent extends Vue {
  // store
  @invoices.Action(Actions.TOGGLE_LISTVIEW_FILTER_SIDEBAR) toggleFilterSidebar!: (payload: boolean) => void
  @invoices.Action(Actions.TOGGLE_LISTVIEW_FILTER_GROUP) toggleFilterGroup!: (payload: { group: string; fetch?: boolean }) => void
  @invoices.Action(Actions.TOGGLE_LISTVIEW_FILTER) toggleFilter!: (payload: { filterName: string; fetch?: boolean }) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_STATUS) filterOnStatus!: (payload: number) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_PAYMENT_STATUS) filterOnPaymentStatus!: (payload: number) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_APPROVAL_STATUS) filterOnApprovalStatus!: (payload: number) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_ACCOUNTING_STATUS) filterOnAccountingStatus!: (payload: number) => void
  // @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_DUE_DATE) filterOnDueDate!: (payload: string[]) => void;
  // @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_INVOICE_DATE) filterOnInvoiceDate!: (payload: string[]) => void;
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_DUE_DATE_FROM) filterOnDueDateFrom!: (payload: string | null) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_DUE_DATE_TO) filterOnDueDateTo!: (payload: string | null) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_INVOICE_DATE_FROM) filterOnInvoiceDateFrom!: (payload: string | null) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_INVOICE_DATE_TO) filterOnInvoiceDateTo!: (payload: string | null) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_PAYMENT_DISCOUNT_DUE_DATE_FROM) filterOnPaymentDiscountDueDateFrom!: (
    payload: string | null
  ) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_PAYMENT_DISCOUNT_DUE_DATE_TO) filterOnPaymentDiscountDueDateTo!: (
    payload: string | null
  ) => void
  @invoices.Action(Actions.CLEAR_LISTVIEW_FILTERS) clearFilters!: (payload: { filters: string[]; fetch?: boolean }) => void
  @invoices.Action(Actions.SEARCH_SUPPLIERS) searchSuppliers!: (payload: string) => Promise<AxiosResponse<Pagination<Supplier[]>>>
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_SUPPLIER) filterOnSupplier!: (payload: Supplier | null) => void
  @invoices.Action(Actions.SET_LISTVIEW_FILTER_ON_PROJECT_LEAD) filterOnProjectLead!: (payload: number) => void

  // getters
  @invoices.Getter(Getters.GET_SEARCH_SUPPLIERS) suppliers!: Supplier[]
  @invoices.Getter(Getters.GET_LISTVIEW_FILTER_SUPPLIER_MODEL) filterSupplierModel!: Supplier

  // properties
  @Prop() filterState!: FilterState
  @Prop({ type: Array as PropType<PurchaseInvoicePaymentStatus[]>, default: () => [] }) availablePaymentStatuses!: PurchaseInvoicePaymentStatus[]
  @Prop({ type: Array as PropType<ApprovalStatus[]>, default: () => [] }) availableApprovalStatuses!: ApprovalStatus[]
  @Prop({ type: Array as PropType<PurchaseInvoiceStatus[]>, default: () => [] }) availableStatuses!: PurchaseInvoiceStatus[]
  @Prop({ type: Array as PropType<AccountingStatus[]>, default: () => [] }) availableAccountingStatuses!: AccountingStatus[]
  @Prop({ type: Array as PropType<User[]>, default: [] }) users!: User[]

  // local vars
  state: {
    searchingSuppliers: boolean
  } = {
    searchingSuppliers: false,
  }
  searchValueState: {
    filterSearchStatus: string,
    filterSearchPaymentStatus: string,
    filterSearchAccountingStatus: string,
    filterSearchApprovalStatus: string,
    filterSearchProjectLead: string,
  } = {
    filterSearchStatus: '',
    filterSearchPaymentStatus: '',
    filterSearchAccountingStatus: '',
    filterSearchApprovalStatus: '',
    filterSearchProjectLead: '',
  }
  fuseStatusOptions: object = { keys: ['label'], threshold: 0.4 }
  fusePaymentStatusOptions: object = { keys: ['label'], threshold: 0.4 }
  fuseAccountingStatusOptions: object = { keys: ['label'], threshold: 0.4 }
  fuseApprovalStatusOptions: object = { keys: ['label'], threshold: 0.4 }
  projectLeadFilterSearchFuseOptions: object = { keys: ['lastName', 'firstName'], threshold: 0.4 }
  financialDateCalculator: any = datetimeCalculate()

  // handlers
  onSearchSupplier(searchTerm: string) {
    if (searchTerm) this.onSearchSupplierDebounced(searchTerm, this)
  }
  onSearchSupplierDebounced = _.debounce((searchTerm: string, vm: Vue) => {
    vm.$data.state = { ...vm.$data.state, searchingSuppliers: true }
    this.searchSuppliers(searchTerm).then(() => {
      vm.$data.state = { ...vm.$data.state, searchingSuppliers: false }
    })
  }, 300)
  onChangeSupplier(supplier: Supplier) {
    if (supplier) {
      if (!this.filterGroupIsActive('suppliers')) this.toggleFilterGroup({ group: 'suppliers', fetch: false })
      this.filterOnSupplier(supplier)
    } else {
      if (this.filterGroupIsActive('suppliers')) this.toggleFilterGroup({ group: 'suppliers' })
      this.filterOnSupplier(null)
      this.clearFilters({ filters: ['suppliers'], fetch: false })
    }
  }

  // filtered statuses
  get statusFilterSearchFuse() {
    return new Fuse(this.availableStatuses, this.fuseStatusOptions)
  }
  get filteredStatusList(): PurchaseInvoiceStatus[] {
    let results = [] as PurchaseInvoiceStatus[]
    if (this.searchValueState.filterSearchStatus !== '') {
      this.statusFilterSearchFuse.search(this.searchValueState.filterSearchStatus).map((value, index) => {
        results.push(value.item)
      })
      return results
    }
    return this.availableStatuses
  }

  // payment status filter
  get paymentStatusFilterSearchFuse() {
    return new Fuse(this.availablePaymentStatuses, this.fusePaymentStatusOptions)
  }
  get filteredPaymentStatusList(): PurchaseInvoicePaymentStatus[] {
    let results = [] as PurchaseInvoicePaymentStatus[]
    if (this.searchValueState.filterSearchPaymentStatus !== '') {
      this.paymentStatusFilterSearchFuse.search(this.searchValueState.filterSearchPaymentStatus).map((value, index) => {
        results.push(value.item)
      })
      return results
    }
    return this.availablePaymentStatuses
  }

  // accounting status filter
  get accountingStatusFilterSearchFuse() {
    return new Fuse(this.availableAccountingStatuses, this.fuseAccountingStatusOptions)
  }
  get filteredAccountingStatusList(): AccountingStatus[] {
    let results = [] as AccountingStatus[]
    if (this.searchValueState.filterSearchAccountingStatus !== '') {
      this.accountingStatusFilterSearchFuse.search(this.searchValueState.filterSearchAccountingStatus).map((value, index) => {
        results.push(value.item)
      })
      return results
    }
    return this.availableAccountingStatuses
  }

  // approval status filter
  get approvalStatusFilterSearchFuse() {
    return new Fuse(this.availableApprovalStatuses, this.fusePaymentStatusOptions)
  }
  get filteredApprovalStatusList(): ApprovalStatus[] {
    let results = [] as ApprovalStatus[]
    if (this.searchValueState.filterSearchApprovalStatus !== '') {
      this.approvalStatusFilterSearchFuse.search(this.searchValueState.filterSearchApprovalStatus).map((value, index) => {
        results.push(value.item)
      })
      return results
    }
    return this.availableApprovalStatuses
  }

  // project lead filtered
  get projectLeads() {
    return this.users.filter((p) => p.isActive)
  }
  get projectLeadFilterSearchFuse() {
    return new Fuse(this.projectLeads, this.projectLeadFilterSearchFuseOptions)
  }
  get filteredProjectLeadList(): User[] {
    let results = [] as User[]
    if (this.searchValueState.filterSearchProjectLead !== '') {
      this.projectLeadFilterSearchFuse.search(this.searchValueState.filterSearchProjectLead).map((value, index) => {
        results.push(value.item)
      })
      return results
    }
    return this.projectLeads
  }

  // dueDate filter
  onSelectDueDateFrom(from: string) {
    this.filterOnDueDateFrom(from)
    // also set discount_due_date when changing due date
    this.filterOnPaymentDiscountDueDateFrom(from)
  }
  onSelectDueDateTo(to: string) {
    this.filterOnDueDateTo(to)
    // also set discount_due_date when changing due date
    this.filterOnPaymentDiscountDueDateTo(to)
  }
  onClickClearDueDateFrom() {
    this.filterOnDueDateFrom(null)
  }
  onClickClearDueDateTo() {
    this.filterOnDueDateTo(null)
  }
  get dueDateFrom() {
    const date = this.filterState.filters.dueDateFrom.value
    return date.length && date[0] ? date[0] : ''
  }
  get dueDateTo() {
    const date = this.filterState.filters.dueDateTo.value
    return date.length && date[0] ? date[0] : ''
  }
  get showDueDateFrom() {
    if (this.filterValuesSelected('dueDateFrom')) {
      const date = this.filterState.filters.dueDateFrom.value[0]
      return date ? Vue.prototype.$formatDate(date) : ''
    }
    return ''
  }
  get showDueDateTo() {
    if (this.filterValuesSelected('dueDateTo')) {
      const date = this.filterState.filters.dueDateTo.value[0]
      return date ? Vue.prototype.$formatDate(date) : ''
    }
    return ''
  }
  quickSelectOnDueDate(type: string): void {
    const selectedDueDates = this.calculateFinancialDate(type)
    this.filterOnDueDateFrom(selectedDueDates[0])
    this.filterOnDueDateTo(selectedDueDates[1])
  }

  // invoiceDate filter
  onSelectInvoiceDateFrom(from: string) {
    this.filterOnInvoiceDateFrom(from)
  }
  onSelectInvoiceDateTo(to: string) {
    this.filterOnInvoiceDateTo(to)
  }
  onClickClearInvoiceDateFrom() {
    this.filterOnInvoiceDateFrom(null)
  }
  onClickClearInvoiceDateTo() {
    this.filterOnInvoiceDateTo(null)
  }
  get invoiceDateFrom() {
    const date = this.filterState.filters.invoiceDateFrom.value
    return date.length && date[0] ? date[0] : ''
  }
  get invoiceDateTo() {
    const date = this.filterState.filters.invoiceDateTo.value
    return date.length && date[0] ? date[0] : ''
  }
  get showInvoiceDateFrom() {
    if (this.filterValuesSelected('invoiceDateFrom')) {
      const date = this.filterState.filters.invoiceDateFrom.value[0]
      return date ? Vue.prototype.$formatDate(date) : ''
    }
    return ''
  }
  get showInvoiceDateTo() {
    if (this.filterValuesSelected('invoiceDateTo')) {
      const date = this.filterState.filters.invoiceDateTo.value[0]
      return date ? Vue.prototype.$formatDate(date) : ''
    }
    return ''
  }
  quickSelectOnInvoiceDate(type: string): void {
    const selectedInvoiceDates = this.calculateFinancialDate(type)
    this.filterOnInvoiceDateFrom(selectedInvoiceDates[0])
    this.filterOnInvoiceDateTo(selectedInvoiceDates[1])
  }

  // paymentDiscountDueDate filter
  onSelectPaymentDiscountDueDateFrom(from: string) {
    this.filterOnPaymentDiscountDueDateFrom(from)
  }
  onSelectPaymentDiscountDueDateTo(to: string) {
    this.filterOnPaymentDiscountDueDateTo(to)
  }
  onClickClearPaymentDiscountDueDateFrom() {
    this.filterOnPaymentDiscountDueDateFrom(null)
  }
  onClickClearPaymentDiscountDueDateTo() {
    this.filterOnPaymentDiscountDueDateTo(null)
  }
  get paymentDiscountDueDateFrom() {
    const date = this.filterState.filters.paymentDiscountDueDateFrom.value
    return date.length && date[0] ? date[0] : ''
  }
  get paymentDiscountDueDateTo() {
    const date = this.filterState.filters.paymentDiscountDueDateTo.value
    return date.length && date[0] ? date[0] : ''
  }
  get showPaymentDiscountDueDateFrom() {
    if (this.filterValuesSelected('paymentDiscountDueDateFrom')) {
      const date = this.filterState.filters.paymentDiscountDueDateFrom.value[0]
      return date ? Vue.prototype.$formatDate(date) : ''
    }
    return ''
  }
  get showPaymentDiscountDueDateTo() {
    if (this.filterValuesSelected('paymentDiscountDueDateTo')) {
      const date = this.filterState.filters.paymentDiscountDueDateTo.value[0]
      return date ? Vue.prototype.$formatDate(date) : ''
    }
    return ''
  }
  quickSelectOnPaymentDiscountDueDate(type: string): void {
    const selectedDates = this.calculateFinancialDate(type)
    this.filterOnPaymentDiscountDueDateFrom(selectedDates[0])
    this.filterOnPaymentDiscountDueDateTo(selectedDates[1])
  }

  // general
  toggleSideBarOpen(): void {
    this.toggleFilterSidebar(!this.filterState.sideBarOpen)
  }

  clearSearchValues(): void {
    this.searchValueState = {
      ...this.searchValueState,
      filterSearchStatus: '',
      filterSearchPaymentStatus: '',
      filterSearchApprovalStatus: '',
    }
  }

  filterGroupIsActive(group: string) {
    for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
      if (filter.group === group && filter.active) return true
    }
    return false
  }
  filterIsActive(filterName: string) {
    for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
      if (filter.name === filterName && filter.active) return true
    }
    return false
  }

  filterValueSelected(filterName: string, filterValue: any) {
    for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
      if (filter.name === filterName && filter.value.includes(filterValue)) return true
    }
    return false
  }

  filterValuesSelected(filterName: string) {
    for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
      if (filter.name === filterName && filter.hasValues()) return true
    }
    return false
  }

  get hasFilters() {
    for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
      if (filter.hasValues()) return true
    }
    return false
  }

  @Emit('clearFilters')
  onEmitClearFilters(): void {
    // clear search values
    this.clearSearchValues()

    // clear all available filters
    const filtersToClear = [] as string[]
    for (let [key, filter] of Object.entries<FilterInterface>(this.filterState.filters)) {
      filtersToClear.push(key)
    }
    this.clearFilters({ filters: filtersToClear })
  }

  calculateFinancialDate(type: string): string[] {
    let start, end
    switch (type) {
      case 'lastMonth':
        return this.financialDateCalculator.lastMonth()
        break
      case 'lastThreeMonths':
        return this.financialDateCalculator.lastMonths(3)
        break
      case 'lastThreeMonthsToDate':
        return this.financialDateCalculator.lastMonthsToDate(3)
        break
      case 'yearToDate':
        return this.financialDateCalculator.yearToDate()
        break
      default:
        return []
        break
    }
  }

  // watchers
}
