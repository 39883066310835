import { reactive, computed } from 'vue';
import { setReactiveProp } from '@/composables/utils';
import { AxiosError, AxiosResponse } from 'axios';
import { JsonResource, Pagination } from '@/core/types/Entities';
import useFilter from '@/composables/useFilter';
import { Holiday, HolidayType } from '@/modules/settings/store/modules/holidays/types/HolidaysState';
import { HolidayService } from '@/services/holiday.service';
import { UseHolidayListState } from './types/useHolidayList';
import { useHolidayListStateFactory } from './factories/useHolidayList';
import useList from './useList';

// services
const holidayService = new HolidayService()

/**
 * `UseHolidayList`-composable
 *   - All Holiday-related interactions and functionality can be stored here.
 *   - If you need a global store, than just use this composable in eg a `store.ts`
 * 
 * @param filter
 * @param items
 * @param defaultState 
 * @returns 
 */
export default function useHolidayList(filter = useFilter(), items = useList<Holiday>(), defaultState?: Partial<UseHolidayListState>)
{
    /**
     * Local state
     * 
     */
    const state = reactive(useHolidayListStateFactory(defaultState))

    /**
     * Actions
     * 
     */
    const fetchHolidays = async (): Promise<AxiosResponse<Pagination<Holiday[]>>|AxiosError> => {
        setReactiveProp(state.loadingActions, fetchHolidays.name, true)
        try {
            const url = holidayService.getBaseEndpoint() + `${ filter.getters.filterUrlQuery.value ? '?' + filter.getters.filterUrlQuery.value.toString() : '' }`
            const result = await holidayService.getAllHolidays(url);
            items.actions.setItems(result.data.data)
            return Promise.resolve(result)
        } catch (err: any) {
            return Promise.reject(err)
        } finally {
            setReactiveProp(state.loadingActions, fetchHolidays.name, false)
        }
    }
    /**
     * `HolidayTypes` could be setup in a separate composable, but this is not necessary
     * 
     * @param types
     * @returns 
     */
    const fetchHolidayTypes = async (types: Array<string> = []): Promise<AxiosResponse<JsonResource<HolidayType[]>>|AxiosError> => {
        setReactiveProp(state.loadingActions, fetchHolidayTypes.name, true)
        try {
            const result = await holidayService.getAllHolidayTypes(types);
            state.holidayTypes = result.data.data
            return Promise.resolve(result)
        } catch (err: any) {
            return Promise.reject(err)
        } finally {
            setReactiveProp(state.loadingActions, fetchHolidayTypes.name, false)
        }
    }
    const search = async (query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<Holiday[]>> | AxiosError> => {
        setReactiveProp(state.loadingActions, search.name, true)
        state.searching = true
        try {
            let urlSearchParams = null
            if(useAsFilter) {
                if(query) filter.actions.setFilter('search', query)
                else filter.actions.deleteFilter('search')
                urlSearchParams = filter.getters.filterUrlQuery.value
            } else {
                const f = useFilter()
                if(query) f.actions.setFilter('search', query)
                urlSearchParams = f.getters.filterUrlQuery.value
            }
            const url = holidayService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`
            const result = await holidayService.getAllHolidays(url);
            items.actions.setItems(result.data.data)
            return Promise.resolve(result)
        } catch (err: any) {
            return Promise.reject(err)
        } finally {
            setReactiveProp(state.loadingActions, search.name, false)
            state.searching = false
        }
    }

    // return state and actions
    return {
        state: {
            holidays: items.getters.items,
            holidayTypes: computed<HolidayType[]>(() => state.holidayTypes),
            isLoading: computed<boolean>(() => Object.entries(state.loadingActions).map((v: [string, boolean]) => v[1]).includes(true)),
            isSearching: computed<boolean>(() => state.searching),
            ...filter.getters,
        },
        actions: {
            ...filter.actions,
            ...items.actions,
            fetchHolidays,
            fetchHolidayTypes,
            search,
        }
    }
}