
import { defineComponent, PropType, ref, watch, onMounted, nextTick } from 'vue';
import { User } from '@/modules/entities/types/entities';
import { WorkDaysService } from '../../services/workDays.service';
import { WorkDay } from '@/modules/calendar/interfaces/project/workDay';
import { Supplier, Division } from '@/modules/calendar/interfaces/project/interfaces';
import calendarStore from '@/modules/calendar/store';
import { Quote } from '@/modules/calendar/interfaces/project/quote';
import dayjs from 'dayjs';
import { sortNatural } from '@/core/helpers/Sort';
import helpers from '@/modules/calendar/services/helper.service';
import useFilter from '@/composables/useFilter';



export default defineComponent({
	name: 'MyPlannedProjectsList',
	props: {
		user: { type: Object as PropType<User | null>, required: false, default: undefined },
	},
	setup(props) {
		const relatedList = ref();
		const loading = ref(false);
		const workDays = ref<WorkDay[]>([]);
		const workDaysService = new WorkDaysService();
		const loadedWeeksBefore = ref(4); // Default value set to 4 weeks
		const loadedWeeksAfter = ref(4); // Default value set to 4 weeks
		const groupHeadersExpanded = ref(true);

		const onClickRefresh = () => {
			fetchDataForUser();
		};

		const fetchDataForUser = async () => {
			loading.value = true;
			await fetchMyWorkDays();
		};

		const fetchMyWorkDays = async (weeksBefore = 0,weeksAfter = 4) => {
			// Update the loadedWeeks variable
			loadedWeeksBefore.value = weeksBefore;
			loadedWeeksAfter.value = weeksAfter;

			try {
				const start = new Date();
				start.setDate(start.getDate() -1 - weeksBefore * 7);
				const startFormatted = dayjs.utc(start).format('YYYYMMDD');
				const stop = new Date();
				stop.setDate(stop.getDate() + Number(weeksAfter) * 7);
				const stopFormatted = dayjs.utc(stop).format('YYYYMMDD');
				// Set filter
				const filter = useFilter();
				filter.actions.setFilters(new Map([
				['workdays.from', startFormatted],
				['workdays.to', stopFormatted],
				]));
				const result = await workDaysService.getWorkDays(filter.getters.filterUrlQuery.value);
				// add markedDays
				let transformedData = transformDataAddMarkedDays(result.data.data);
				transformedData = transformedData.map(item => ({
					...item,
					plannerStartDateFormatted: formatDateToDayOfWeek(item.day)
				}));
				transformedData.sort((a, b) => sortNatural(a.plannerPeriod?.quote?.project?.projectName.toUpperCase(), b.plannerPeriod?.quote?.project?.projectName.toUpperCase(), { numeric: false }));
				workDays.value = transformedData;
				return result;
			} catch (error) {
				console.warn(error);
			} finally {
				loading.value = false;
			}
		};

		const loadMoreWeeksBefore = () => {
			// Increment the number of weeks loaded by 4
			const newStart = loadedWeeksBefore.value + 4;
			fetchMyWorkDays(newStart, loadedWeeksAfter.value);
		};

		const loadMoreWeeksAfter = () => {
			// Increment the number of weeks loaded by 4
			const newWeeks = loadedWeeksAfter.value + 4;
			fetchMyWorkDays(loadedWeeksBefore.value,newWeeks);
		};

		const onClickOpenPlannerAddProject = async (item: WorkDay) =>  {
			const projectId: number = item.plannerPeriod?.quote?.project?.id || 0;
			await checkDateSettingsForPlanner(item);
			await calendarStore.calendarSettings.actions.addProjectsToCalendar([projectId]);
		}

		const onClickOpenPlannerAddContractor = async (item: WorkDay) => {
			const contractorId: number = item.plannerPeriod?.quote?.contractor?.id || 0;
			await checkDateSettingsForPlanner(item);
			if (checkDivision(item)) {
				await calendarStore.calendarSettings.actions.addDivisionsToCalendar([contractorId]);
			} else {
				await calendarStore.calendarSettings.actions.addSuppliersToCalendar([contractorId]);
			}
		}

		const checkDateSettingsForPlanner = async (item: WorkDay) => {
			// Activate state
			calendarStore.calendarSettings.actions.fetchEnvironment().then(async () => {
				const selectedDay = new Date(item.day);
				const selectedDayjs = dayjs.utc(selectedDay);
				// Get current end date of dateRange
				let dateRangeEndString = await calendarStore.calendarSettings.getters.environment.value?.dateRange.end;
				if (dateRangeEndString != null) {
					let dateRangeEndDate = new Date(dateRangeEndString);
					if (selectedDay > dateRangeEndDate) {
						// Set new dateRange
						let newDateRangeDateEnd = selectedDayjs.add(10, "day").format('YYYY-MM-DD');
						calendarStore.calendarSettings.actions.setSelectedDateRange(newDateRangeDateEnd, "end").then(async () => {
							await calendarStore.calendarSettings.actions.saveDateRangeSelection();
						});
					}
					if (selectedDay < dateRangeEndDate) {
						// Set new dateRange
						let newDateRangeDateStart = selectedDayjs.add(-10, "day").format('YYYY-MM-DD');
						calendarStore.calendarSettings.actions.setSelectedDateRange(newDateRangeDateStart, "start").then(async () => {
							await calendarStore.calendarSettings.actions.saveDateRangeSelection();
						});
					}
				}
				if (selectedDayjs.day() == 0 || selectedDayjs.day() == 6) {
					calendarStore.calendarSettings.actions.showWeekendsAndHolidays(true);
				} else {
					const holidays: { name: string; days: string[] }[] | undefined = calendarStore.calendarSettings.getters.environment.value?.holidays;
					if (holidays != null) {
						const holidayDays: string[] | undefined = holidays
							.map(x => x.days)
							.reduce((prev, curr) => {
								prev.push(...curr);
								return prev;
							}, []);
						if(holidayDays != null && holidayDays.some(x => selectedDayjs.format('YYYY-MM-DD') === x)){
							calendarStore.calendarSettings.actions.showWeekendsAndHolidays(true);
						}
					}
				}
			});
		}

		const checkDivision = (item : any) => {
			if(item.plannerPeriod?.quote?.contractor){
			return helpers.isDivision(item.plannerPeriod?.quote?.contractor);
			}
		};


		const giveSupplierClass = (quote: Quote) => {
			//orange--opaque
			//green--opaqu
			if (quote?.contractorPermAssigned === false ) {
				return 'orange--opaque';
			}
			return '';
		};

		const transformDataAddMarkedDays = (workdays: WorkDay[]) => {
			// Initialize the markedDays array
			let markedDays: string[] = [];
			let markedWeek = -1;
			// Transform the sorted data and add marked days
			const transformedData = workdays.map((item, index, array) => {
				let plannerStartDateIsMarked;
				const dayjsPlannerStartDate = dayjs.utc(new Date(item.day));
				if(markedWeek != dayjsPlannerStartDate.week()){
				// Check for each day if it's in the same week, if not, mark the previous day
				for (let i = index + 1; i < array.length; i++) {
					const nextPlannerStartDate = new Date(array[i].day);
					const dayjsNextPlannerStartDate = dayjs.utc(nextPlannerStartDate);
					if (dayjsNextPlannerStartDate.week() !== dayjsPlannerStartDate.week()) {
						markedDays.push(array[i].day);
						markedWeek = dayjsPlannerStartDate.week();
						break;
					}
				}
			}
				//82723
				//3787
				plannerStartDateIsMarked = markedDays.includes(item.day) ? item.day : "";
				return {
					...item,
					plannerStartDateIsMarked
				};
			});
			return transformedData;
		};

		const formatDateToDayOfWeek = (dateString: string) => {
			const date = new Date(dateString);
			const daysOfWeek = ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'];
			const dayOfWeek = daysOfWeek[date.getDay()];
			const dayMonth = dayjs.utc(date).format('DD/MM');
			return `${dayOfWeek} ${dayMonth}`;
		};

		const toggleAllGroupHeaders = () => {
			if (relatedList.value) {
				// referentie docs toevoegen
				nextTick(() => {
					let table = relatedList.value.$refs.table;
					let keys = Object.keys(table.$vnode.componentInstance.openCache);
					keys.forEach(x => {
						table.$vnode.componentInstance.openCache[x] = groupHeadersExpanded.value;
					});
				});
				groupHeadersExpanded.value = !groupHeadersExpanded.value;
			} else {

			}
		};

		const setAllGroupHeadersClosed = () => {
			if (relatedList.value) {
				// referentie docs toevoegen
				nextTick(() => {
					let table = relatedList.value.$refs.table;
					let keys = Object.keys(table.$vnode.componentInstance.openCache);
					keys.forEach(x => {
						table.$vnode.componentInstance.openCache[x] = false;
					});
					if(loadedWeeksAfter.value == 4){
						table.$vnode.componentInstance.openCache[keys[1]] = true;
					}
				});
			} else {

			}
		};


		const itemClass = (item: WorkDay) => {
			// function for itemCLass, property of Vuetify v-data-table
			const today = dayjs().startOf('day');
			const itemDate = dayjs(item.day).startOf('day');
			if (itemDate.isBefore(today)) {
				return 'isPast';
			}else{
				return 'normal';
			}
		};

		const groupDividers = [
			{
				header: 'day',
				function: (props: any) => {
					// Check if the plannerStartDateIsMarked property of the first item is not an empty string
					return props.items.length > 0 && props.items[0].plannerStartDateIsMarked !== '';
				}
			},
		];

		const headers = [
			{
				text: 'Datum',
				value: 'day',
				align: 'start text-truncate',
				groupable: true,
				class: 'text-no-wrap'
			},
			{
				text: 'Project',
				value: 'project',
				align: 'start text-truncate td-text-truncate-2 td-toggle',
				groupable: false,
				class: 'text-no-wrap'
			},
			{
				text: '',
				value: 'abbreviation',
				align: 'center',
				groupable: false,
				class: 'text-no-wrap'
			},
			{
				text: 'leverancier/onderaannemer',
				value: 'contractor',
				align: 'start text-truncate td-text-truncate-2 td-toggle',
				groupable: false,
				class: 'text-no-wrap'
			},
			{
				text: 'Acties',
				value: 'actions',
				align: 'center',
				groupable: false,
				class: 'text-no-wrap'
			},
			{
				text: 'Opmerkingen',
				value: 'opmerkingen',
				align: 'start text-truncate td-text-truncate-1 td-toggle',
				groupable: false,
				class: 'text-no-wrap'
			}
		];

		onMounted(() => {
			fetchDataForUser();
		});

		return {
			loading,
			workDays,
			headers,
			groupDividers,
			relatedList,
			formatDateToDayOfWeek,
			onClickRefresh,
			onClickOpenPlannerAddProject,
			onClickOpenPlannerAddContractor,
			setAllGroupHeadersClosed,
			toggleAllGroupHeaders,
			giveSupplierClass,
			checkDivision,
			loadMoreWeeksAfter,
			loadMoreWeeksBefore,
			itemClass
		};
	}
});
