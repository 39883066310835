import { InvoiceState } from '@/modules/purchase-invoices/store/types/InvoiceState';
import { MutationTree } from 'vuex';
import { Mutations } from '@/modules/purchase-invoices/store/types/StoreTypes';
import { PurchaseInvoice, PurchaseInvoiceStatus, PurchaseInvoiceRecordType, PurchaseInvoicePaymentStatus, ProjectRelationshipStatus, ApprovalStatus, AccountingStatus } from '@/modules/purchase-invoices/types/entities';
import { FilterInterface } from '@/core/types/Filter';
import { MediaAnnotation, Supplier } from '@/modules/entities/types/entities';
import { Project } from '@/modules/projects/types/entities';

export const mutations: MutationTree<InvoiceState> = {

  [Mutations.MUTATE_LISTVIEW_IS_LOADING]: (state: InvoiceState, payload: boolean) => {
    state.listviewIsLoading = payload;
  },
  [Mutations.MUTATE_IS_LOADING]: (state: InvoiceState, payload: boolean) => {
    state.isLoading = payload;
  },
  [Mutations.MUTATE_LISTVIEW_INVOICES]: (state: InvoiceState, payload: PurchaseInvoice[]) => {
    state.listviewInvoices = payload;
  },
  [Mutations.MUTATE_LISTVIEW_LINKS]: (state: InvoiceState, payload: any) => {
    state.listviewLinks = payload;
  },
  [Mutations.MUTATE_LISTVIEW_META]: (state: InvoiceState, payload: any) => {
    state.listviewMeta = payload;
  },
  [Mutations.MUTATE_LISTVIEW_PUSH_NEXT_PAGE]: (state: InvoiceState, payload: {links: any; invoices: PurchaseInvoice[]}) => {
    state.listviewLinks = payload.links;
    state.listviewInvoices = [
      ...state.listviewInvoices,
      ...payload.invoices,
    ];
  },
  [Mutations.MUTATE_STATUSES]: (state: InvoiceState, payload: PurchaseInvoiceStatus[]) => {
    state.statuses = payload;
  },
  [Mutations.MUTATE_APPROVAL_STATUSES]: (state: InvoiceState, payload: ApprovalStatus[]) => {
    state.approvalStatuses = payload;
  },
  [Mutations.MUTATE_PROJECT_RELATIONSHIP_STATUSES]: (state: InvoiceState, payload: ProjectRelationshipStatus[]) => {
    state.projectRelationshipStatuses = payload;
  },
  [Mutations.MUTATE_PAYMENT_STATUSES]: (state: InvoiceState, payload: PurchaseInvoicePaymentStatus[]) => {
    state.paymentStatuses = payload;
  },
  [Mutations.MUTATE_ACCOUNTING_STATUSES]: (state: InvoiceState, payload: AccountingStatus[]) => {
    state.accountingStatuses = payload;
  },
  [Mutations.MUTATE_INVOICE]: (state: InvoiceState, payload: PurchaseInvoice) => {
    state.invoice = payload;
  },
  [Mutations.MUTATE_RECORD_TYPES]: (state: InvoiceState, payload: PurchaseInvoiceRecordType[]) => {
    state.recordTypes = payload;
  },
  [Mutations.MUTATE_SUPPLIERS]: (state: InvoiceState, payload: Supplier[]) => {
    state.suppliers = payload;
  },
  [Mutations.MUTATE_NEW]: (state: InvoiceState, payload: PurchaseInvoice) => {
    state.new = payload;
  },
  [Mutations.MUTATE_APPEND_TO_INVOICES]: (state: InvoiceState, payload: PurchaseInvoice[]) => {
    state.listviewInvoices = payload;
  },
  [Mutations.MUTATE_MEDIA_ANNOTATIONS]: (state: InvoiceState, payload: MediaAnnotation[]) => {
    state.mediaAnnotations = payload;
  },

  // reporting
  [Mutations.MUTATE_INVOICES_SUMMARY_REPORT]: (state: InvoiceState, payload: any) => {
    state.invoicesSummaryReport = payload;
  },
  [Mutations.MUTATE_INVOICES_METRICS_REPORT]: (state: InvoiceState, payload: any) => {
    state.invoicesMetricsReport = payload;
  },
  [Mutations.MUTATE_INVOICES_SHOW_SUMMARY_REPORT]: (state: InvoiceState, payload: boolean) => {
    state.invoicesShowSummaryReport = payload;
  },
  [Mutations.MUTATE_INVOICES_SHOW_METRICS_REPORT]: (state: InvoiceState, payload: boolean) => {
    state.invoicesShowMetricsReport = payload;
  },

  // search
  [Mutations.MUTATE_SEARCH_PROJECTS]: (state: InvoiceState, payload: Project[]) => {
    state.searchProjects = payload;
  },
  [Mutations.MUTATE_SEARCH_SUPPLIERS]: (state: InvoiceState, payload: Supplier[]) => {
    state.searchSuppliers = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_SUPPLIER_MODEL]: (state: InvoiceState, payload: Supplier) => {
    state.filtersSupplierModel = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_SUPPLIER]: (state: InvoiceState, payload: number[]) => {
    state.listviewFilterState.filters.supplier.value = payload;
  },


  // filters
  [Mutations.MUTATE_TOGGLE_LISTVIEW_FILTER_SIDEBAR]: (state: InvoiceState, payload: boolean) => {
    state.listviewFilterState.sideBarOpen = payload;
  },
  [Mutations.MUTATE_TOGGLE_LISTVIEW_FILTER_GROUP]: (state: InvoiceState, payload: string) => {
    Object.keys(state.listviewFilterState.filters).forEach(function(key) {
      const filter = state.listviewFilterState.filters[key] as FilterInterface;
      if(filter.group === payload) filter.active = !filter.active;
    });
  },
  [Mutations.MUTATE_TOGGLE_LISTVIEW_FILTER]: (state: InvoiceState, payload: string) => {
    Object.keys(state.listviewFilterState.filters).forEach(function(key) {
      const filter = state.listviewFilterState.filters[key] as FilterInterface;
      if(filter.name === payload) filter.active = !filter.active;
    });
  },
  [Mutations.MUTATE_CLEAR_LISTVIEW_FILTERS]: (state: InvoiceState, payload: string[]) => {
    Object.keys(state.listviewFilterState.filters).forEach(function(key) {
      if(payload.includes(key)) {
        const filter = state.listviewFilterState.filters[key] as FilterInterface;
        filter.clear();
      }
    });
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_STATUS]: (state: InvoiceState, payload: number[]) => {
    state.listviewFilterState.filters.status.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_PAYMENT_STATUS]: (state: InvoiceState, payload: number[]) => {
    state.listviewFilterState.filters.paymentStatus.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_APPROVAL_STATUS]: (state: InvoiceState, payload: number[]) => {
    state.listviewFilterState.filters.approvalStatus.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_ACCOUNTING_STATUS]: (state: InvoiceState, payload: number[]) => {
    state.listviewFilterState.filters.accountingStatus.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_DUE_DATE_FROM]: (state: InvoiceState, payload: string[]) => {
    state.listviewFilterState.filters.dueDateFrom.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_DUE_DATE_TO]: (state: InvoiceState, payload: string[]) => {
    state.listviewFilterState.filters.dueDateTo.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_PAYMENT_DISCOUNT_DUE_DATE_FROM]: (state: InvoiceState, payload: string[]) => {
    state.listviewFilterState.filters.paymentDiscountDueDateFrom.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_PAYMENT_DISCOUNT_DUE_DATE_TO]: (state: InvoiceState, payload: string[]) => {
    state.listviewFilterState.filters.paymentDiscountDueDateTo.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_INVOICE_DATE_FROM]: (state: InvoiceState, payload: string[]) => {
    state.listviewFilterState.filters.invoiceDateFrom.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_INVOICE_DATE_TO]: (state: InvoiceState, payload: string[]) => {
    state.listviewFilterState.filters.invoiceDateTo.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_FOR_SEARCH]: (state: InvoiceState, payload: string[]) => {
    state.listviewFilterState.filters.search.value = payload;
  },
  [Mutations.MUTATE_LISTVIEW_FILTER_PROJECT_LEAD]: (state: InvoiceState, payload: number[]) => {
    state.listviewFilterState.filters.projectLead.value = payload;
  },

};
