import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from 'vuex';

import {RootState} from '@/core/types/RootState';
import {DeletedLogState} from '@/modules/settings/store/modules/deleted-log/types/DeletedLogState';
import {Getters} from '@/modules/settings/store/modules/deleted-log/types/StoreTypes';
import {Actions, Mutations} from '@/modules/settings/store/modules/deleted-log/types/StoreTypes';
import Vue from 'vue';
import {LogItem} from '@/modules/settings/types/entities';

const getters: GetterTree<DeletedLogState, RootState> = {
	[Getters.GET_DELETED_LOG]: (state: DeletedLogState) => {
		return state.log;
	}
};

const actions: ActionTree<DeletedLogState, RootState> = {
	[Actions.FETCH_DELETED_LOG]: async({commit}: ActionContext<DeletedLogState, RootState>) => {
		const res = await Vue.prototype.$http.get(`/api/v1/settings/deleted-log`);
		commit(Mutations.MUTATE_DELETED_LOG, res.data.data);
	},
	[Actions.REVERT_DELETION]: async({commit, state}: ActionContext<DeletedLogState, RootState>, payload: LogItem) => {
		const requestBody = {
			type: payload.subjectType,
			id: payload.subjectId
		};

		const res = await Vue.prototype.$http.post('/api/v1/commands/revert-deletion', requestBody);

		if (res.status === 200) {
			const updatedDeleteLog = state.log.filter(log => {
				return !(log.subjectType === payload.subjectType && log.subjectId === payload.subjectId);
			});

			commit(Mutations.MUTATE_DELETED_LOG, updatedDeleteLog)
		}
	}
};

const mutations: MutationTree<DeletedLogState> = {
	[Mutations.MUTATE_DELETED_LOG]: (state: DeletedLogState, payload: LogItem[]) => {
		state.log = payload;
	}
};

const state: DeletedLogState = {
	log: []
};

export const store: Module<DeletedLogState, RootState> = {
	namespaced: true,
	actions,
	getters,
	state,
	mutations
};
