
	import { Component, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { AccountingItem } from '@/modules/purchase-invoices/types/entities';
	import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
	import { AxiosResponse } from 'axios';
	import { JsonResource } from '@/core/types/Entities';
	import { PropType } from 'vue';

	const purchaseInvoices = namespace('purchaseInvoices');

	@Component
	export default class AccountingItemDeleteModalComponent extends Vue {

		// vuex actions
		@purchaseInvoices.Action(Actions.DELETE_ACCOUNTING_ITEM) deleteAccountingItem!: (payload: AccountingItem) => Promise<AxiosResponse<JsonResource<AccountingItem>>>;

		// vuex getters
		@purchaseInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;

		// props
		@PropSync('accountingItem', { type: Object as PropType<AccountingItem> }) accountingItemSync!: AccountingItem
		@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;

		@Ref('confirmDelete') readonly confirmDelete!: Vue
		
		// vars
		state: {
			accountingItemDeleting: boolean,
			accountingItemDeleted: boolean
		} = {...this.newState()}

		// handlers
		onClickClose(value: any): void {
			this.closeModal();
			this.state = { ...this.newState() }
			this.$emit('onCancel')
		}
		onClickDeleteAccountingItem() {
			this.state = {...this.state, accountingItemDeleting: true }
			if(!this.accountingItemSync) throw new Error('No accounting item provided')
			
			// delete
			this.deleteAccountingItem(this.accountingItemSync).then(() => {
				// update state
				this.state = {...this.state, accountingItemDeleted: true, accountingItemDeleting: false }
				this.state = { ...this.newState() }
				this.closeModal()
			});
		}

		// methods
		closeModal() {
			this.showModalSync = false
		}
		newState() {
			return {
				accountingItemDeleted: false,
				accountingItemDeleting: false
			}
		}

		// getters 

		// watchers
		@Watch('accountingItemSync', { deep: true, immediate: false })
		onWatchValue(value: any) {
			setTimeout(() => { (this.confirmDelete.$el as HTMLButtonElement).focus() } )
		}
		
		// lifecycle
		mounted(): void {
			//
		}

		created(): void {
			//
		}

		destroyed(): void {
			//
		}
	}
