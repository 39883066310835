
  import { defineComponent, PropType } from 'vue';
  import { WorkDay } from '@/modules/calendar/interfaces/project/workDay';
  import { CalendarCrudState } from '../types/CalendarCrudState';
  import store from '../store';

  export default defineComponent({
    name: 'WorkdaySequenceComponent',
    emits: ['set'],
    props: {
      workday: Object as PropType<WorkDay>,
    },
    setup(props, { emit }) {
      const sequenceCount = 8;
      const allWorkCellsActive = (): boolean => props.workday?.sequences.length === sequenceCount;
      const setCell = (val: number, toggle = true) => {
        emit('set', [val], toggle);
        // if (props.workday) store.calendar.actions.setSequences(props.workday, [val]);
      };

      const setAllCells = (toggle = false) => {
        const sequencesToSet = !allWorkCellsActive() ? Array.from(Array(sequenceCount).keys(), (i) => i + 1) : [];
        emit('set', sequencesToSet, toggle);
        // if (props.workday) {
        //   store.calendar.actions.setSequences(props.workday, sequencesToSet, false);
        // }
      };

      const isSequenceActive = (sequence: number): boolean => {
        return !!props.workday?.sequences.some((s) => s.value === sequence);
      };

      return {
        environment: store.calendar,
        sequenceCount,
        isSequenceActive,
        allWorkCellsActive,
        setCell,
        setAllCells,
        CalendarCrudState,
      };
    },
  });
