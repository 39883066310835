
  import { computed, defineComponent, ref, watch, PropType } from 'vue';

  export default defineComponent({
    setup(props, {}) {
      const panel = ref(0);

      return {
        panel,
      };
    },
  });
