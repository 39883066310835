import { fabric } from 'fabric'
import { FabricShapeService } from '../services/drawing-shape.service'
import {
    CustomFabricEllipse,
    CustomFabricIText,
    CustomFabricLine,
    CustomFabricObject,
    CustomFabricPath,
    CustomFabricPolygon,
    CustomFabricRect,
    CustomFabricAuth,
    DrawingColours,
    DrawingThickness,
    DrawingTools,
    FabricObjectType,
    Pointer,
} from '../types/drawing';

const RANGE_AROUND_CENTER = 20;

export class DrawingEventHandlerService {

    public imageDataUrl!: string;
    public canvas!: fabric.Canvas;
    private _selectedTool: DrawingTools = DrawingTools.LINEANDTEXT;
    private previousTop: number | undefined;
    private previousLeft: number | undefined;
    private previousScaleX: number | undefined;
    private previousScaleY: number | undefined;

    set selectedTool(t: DrawingTools) {
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        this._selectedTool = t;
        if (
            this._selectedTool === DrawingTools.SELECT ||
            this._selectedTool === DrawingTools.ERASER ||
            this._selectedTool === DrawingTools.FILL
        ) {
            this.objectsSelectable(true);
        } else {
            this.objectsSelectable(false);
        }
        if (this.selectedTool === DrawingTools.GARBAGE) {
            const background = this.canvas.backgroundImage;
            this.canvas.clear();
            if(background) this.canvas.setBackgroundImage(background, () => null);
        }
    }
    get selectedTool(): DrawingTools {
        return this._selectedTool;
    }
    _selectedColour: DrawingColours = DrawingColours.RED;
    set selectedColour(c: DrawingColours) {
        this._selectedColour = c;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
    }
    get selectedColour(): DrawingColours {
        return this._selectedColour;
    }
    selectedThickness: DrawingThickness = DrawingThickness.MEDIUM;
    private _isMouseDown = false;
    private _elementUnderDrawing!:
        | CustomFabricEllipse
        | CustomFabricRect
        | CustomFabricPath
        | CustomFabricLine
        | CustomFabricPolygon 
        | CustomFabricIText | undefined;
    private _initPositionOfElement!: Pointer;
    private _auth: CustomFabricAuth = { 
        isEditable: () => true 
    };
    private _props: any = {};

    constructor(private fabricShapeService: FabricShapeService) { }

    useObjectAuthorization(auth: CustomFabricAuth): void {
        this._auth = auth;
        if(this.fabricShapeService) this.fabricShapeService.useObjectAuthorization(this._auth);
    }

    useObjectProperties(customObjectProps: any) {
        const hasFalsyProps = !Object.values(customObjectProps).some(v => !!v);
        if(hasFalsyProps) throw 'Some custom properties do not have a value assigned.'
        this._props = customObjectProps;
        if(this.fabricShapeService) this.fabricShapeService.useObjectProperties(this._props);
    }

    setPageNumber(pageNumber: number) {
        this._props = {...this._props, pageNumber: pageNumber}
        this.fabricShapeService.useObjectProperties(this._props)
    }
    setUserId(userId: string) {
        this._props = {...this._props, userId: userId}
        this.fabricShapeService.useObjectProperties(this._props)
    }

    rebuildObjects(obj: CustomFabricObject[]): void {
        fabric.util.enlivenObjects(obj, (objects: CustomFabricObject[]) => {
            const origRenderOnAddRemove = this.canvas.renderOnAddRemove;
            this.canvas.renderOnAddRemove = false;
            objects.forEach((o) => {
                o.selectable = this._auth.isEditable(o);
                this.canvas.add(o)
            });
            this.canvas.renderOnAddRemove = origRenderOnAddRemove;
            this.canvas.renderAll();
        }, 'fabric')
    }

    onChangeDrawingTool(): void {
        this.canvas.getObjects('i-text').forEach(obj => (obj as CustomFabricIText).exitEditing())
    }

    mouseDown(e: Event) {
        this._isMouseDown = true;
        const pointer = this.canvas.getPointer(e);
        this._initPositionOfElement = { x: pointer.x, y: pointer.y };

        switch (this._selectedTool) {
            case DrawingTools.ELLIPSE:
                this._elementUnderDrawing = this.fabricShapeService.createEllipse(
                    this.canvas,
                    this.selectedThickness,
                    this._selectedColour,
                    pointer,
                );
                break;
            case DrawingTools.RECTANGLE:
                this._elementUnderDrawing = this.fabricShapeService.createRectangle(
                    this.canvas,
                    this.selectedThickness,
                    this._selectedColour,
                    pointer,
                );
                break;
            case DrawingTools.PENCIL:
            case DrawingTools.PENCILANDTEXT:
                this.canvas.selection = false
                this._elementUnderDrawing = this.fabricShapeService.createPath(
                    this.canvas,
                    this.selectedThickness,
                    this._selectedColour,
                    pointer,
                );
                break;
            case DrawingTools.LINE:
            case DrawingTools.LINEANDTEXT:
                this._elementUnderDrawing = this.fabricShapeService.createLine(
                    this.canvas,
                    this.selectedThickness,
                    this._selectedColour,
                    [5, 0],
                    pointer,
                );
                break;
            case DrawingTools.DASHED_LINE:
                this._elementUnderDrawing = this.fabricShapeService.createLine(
                    this.canvas,
                    this.selectedThickness,
                    this._selectedColour,
                    [5, 5],
                    pointer,
                );
                break;
            case DrawingTools.POLYGON:
                if (!this._elementUnderDrawing) {
                    this._elementUnderDrawing = this.fabricShapeService.createPolygon(
                        this.canvas,
                        this.selectedThickness,
                        this._selectedColour,
                        pointer,
                    );
                } else {
                    if (
                        this.fabricShapeService.isClickNearPolygonCenter(
                            this._elementUnderDrawing as CustomFabricPolygon,
                            pointer,
                            RANGE_AROUND_CENTER,
                        )
                    ) {
                        this._elementUnderDrawing = this.fabricShapeService.finishPolygon(this.canvas, this
                            ._elementUnderDrawing as CustomFabricPolygon);
                        this._elementUnderDrawing = undefined;
                    } else {
                        this.fabricShapeService.addPointToPolygon(this._elementUnderDrawing as CustomFabricPolygon, pointer);
                    }
                }
                break;
            case DrawingTools.TEXT:
                this._elementUnderDrawing = this.fabricShapeService.createIText(this.canvas, {
                    thickness: 0.5,
                    fontSize: 12,
                    colour: this._selectedColour,
                    pointer,
                }).enterEditing().selectAll() as CustomFabricIText;
                break;
            default:
                this.canvas.selection = true
                break
        }
    }

    mouseMove(e: Event) {
        if (!this._isMouseDown) {
            return;
        }
        const pointer = this.canvas.getPointer(e);
        switch (this._selectedTool) {
            case DrawingTools.ELLIPSE:
                this.fabricShapeService.formEllipse(
                    this._elementUnderDrawing as CustomFabricEllipse,
                    this._initPositionOfElement,
                    pointer,
                );
                break;
            case DrawingTools.RECTANGLE:
                this.fabricShapeService.formRectangle(
                    this._elementUnderDrawing as CustomFabricRect,
                    this._initPositionOfElement,
                    pointer,
                );
                break;
            case DrawingTools.PENCIL:
            case DrawingTools.PENCILANDTEXT:
                this.fabricShapeService.formPath(this._elementUnderDrawing as CustomFabricPath, pointer);
                break;
            case DrawingTools.LINE:
            case DrawingTools.LINEANDTEXT:
            case DrawingTools.DASHED_LINE:
                this.fabricShapeService.formLine(this._elementUnderDrawing as CustomFabricLine, pointer);
                break;
            case DrawingTools.POLYGON:
                this.fabricShapeService.formFirstLineOfPolygon(
                    this._elementUnderDrawing as CustomFabricPolygon,
                    this._initPositionOfElement,
                    pointer,
                );
                break;
        }
        this.canvas.renderAll();
    }

    mouseUp(e: Event) {
        this._isMouseDown = false;
        if (this._selectedTool === DrawingTools.PENCIL || this._selectedTool === DrawingTools.PENCILANDTEXT) {
            this._elementUnderDrawing = this.fabricShapeService.finishPath(this.canvas, this._elementUnderDrawing as CustomFabricPath);
        }
        if (this._selectedTool !== DrawingTools.POLYGON) {
            this._elementUnderDrawing = undefined;
        }
        if (this._selectedTool !== DrawingTools.SELECT) {
            this.canvas.renderAll();
        }
        if (this._selectedTool === DrawingTools.LINEANDTEXT) {
            const initialPosition = this._initPositionOfElement
            const endPosition = this.canvas.getPointer(e)
            const textPosition = new fabric.Point( ((endPosition.x+initialPosition.x)/2)+10, (endPosition.y+initialPosition.y)/2)
            const text = this.fabricShapeService.createIText(this.canvas, {
                content: 'Text',
                thickness: 0.5,
                fontSize: 12,
                colour: this._selectedColour,
                pointer: textPosition,
            });
            text.enterEditing()
            text.selectAll()
        }
        if (this._selectedTool === DrawingTools.PENCILANDTEXT) {
            const pointer = this.canvas.getPointer(e);
            const text = this.fabricShapeService.createIText(this.canvas, {
                content: 'Text',
                thickness: 0.5,
                fontSize: 12,
                colour: this._selectedColour,
                pointer,
            });
            text.enterEditing()
            text.selectAll()
        }
    }

    keyDown(e: KeyboardEvent) {
        const selectedObjects = this.canvas.getActiveObjects()

        switch (e.code) {
            case 'Delete':
                if(selectedObjects && selectedObjects.length) {
                    this.canvas.remove(...selectedObjects)
                }
                break;
            case 'Escape':
                this._selectedTool = DrawingTools.SELECT
                break;
            default:
                break;
        }
    }

    extendObjectWithId(): void {
        fabric.Object.prototype.toObject = (function (toObject) {
            return function (attrs: string[]|undefined) {
                //@ts-ignore
                const thisFabricObject: CustomFabricObject = this as CustomFabricObject
                return fabric.util.object.extend(toObject.call(thisFabricObject, attrs), {
                    id: thisFabricObject.id,
                    userId: thisFabricObject.userId,
                    pageNumber: thisFabricObject.pageNumber,
                    mediaAnnotationId: thisFabricObject.mediaAnnotationId,
                });
            };
        // eslint-disable-next-line @typescript-eslint/unbound-method
        })(fabric.Object.prototype.toObject);
    }

    objectSelected(object: CustomFabricObject): void {
        this.previousLeft = object.left;
        this.previousTop = object.top;
        this.previousScaleX = object.scaleX;
        this.previousScaleY = object.scaleY;
        switch (this._selectedTool) {
            case DrawingTools.ERASER:
                if (object.type === FabricObjectType.ELLIPSE) {
                    const otherEllipses = this.getOtherEllipses(object.id);
                    otherEllipses.forEach(e => this.canvas.remove(e));
                }
                this.canvas.remove(object);
                break;
            case DrawingTools.FILL:
                this.fabricShapeService.fillShape(object, this._selectedColour);
                break;
        }
    }

    objectMoving(id: string, type: FabricObjectType, newLeft: number, newTop: number) {
        if (type !== FabricObjectType.ELLIPSE) {
            return;
        }
        const diffX = newLeft - (this.previousLeft ? this.previousLeft : 0);
        const diffY = newTop - (this.previousTop ? this.previousTop : 0);
        this.previousLeft = newLeft;
        this.previousTop = newTop;

        const otherEllipses = this.getOtherEllipses(id);
        otherEllipses.forEach(e => {
            if(e.left !== undefined) e.left += diffX;
            if(e.top !== undefined) e.top += diffY;
        });
    }

    objectScaling(
        id: string,
        type: FabricObjectType,
        newScales: { x: number; y: number },
        newCoords: { left: number; top: number },
    ) {
        if (type !== FabricObjectType.ELLIPSE) {
            return;
        }
        const scaleDiffX = newScales.x - (this.previousScaleX ? this.previousScaleX : 0);
        const scaleDiffY = newScales.y - (this.previousScaleY ? this.previousScaleY : 0);
        this.previousScaleX = newScales.x;
        this.previousScaleY = newScales.y;

        const otherEllipses = this.getOtherEllipses(id);
        otherEllipses.forEach(e => {
            if(e.scaleX) e.scaleX += scaleDiffX;
            if(e.scaleY) e.scaleY += scaleDiffY;
        });
        this.objectMoving(id, type, newCoords.left, newCoords.top);
    }

    private objectsSelectable(isSelectable: boolean) {
        this.canvas.forEachObject((obj: fabric.Object) => {
            const fabricObject = (obj as CustomFabricObject);
            if(this._auth.isEditable(fabricObject)) {
                obj.selectable = isSelectable;
            }
        });
    }

    private getOtherEllipses(notIncludedId: string): CustomFabricEllipse[] {
        return this.canvas
            .getObjects(FabricObjectType.ELLIPSE)
            .filter(e => (e as CustomFabricEllipse).id !== notIncludedId) as CustomFabricEllipse[];
    }


}