

	import {Component, Emit, Prop, PropSync, Vue, Watch} from "vue-property-decorator";
	import {Project} from "@/modules/projects/types/entities";
	import {ProjectIntersectionData, WorkflowTask, WorkflowTaskStatus} from "@/modules/workflow/types/entities";
	import {Comment} from "@/modules/entities/types/entities";

	const icons = require.context('@/assets/workflow/show-all-notes', false, /\.svg$/);

	@Component
	export default class ShowAllNotesComponent extends Vue {
		@PropSync ('showModal', {type: Boolean}) showModalSync!: boolean;
		@Prop() data!: {project: Project, task: WorkflowTask, intersectionData: ProjectIntersectionData};
		@Prop() taskStatuses!: WorkflowTaskStatus[];
		@Prop() tasks!: WorkflowTask[];
		@Prop() getStatusChangerColor!: Function;
		@Prop() getCurrentStatus!: Function;
		@Prop() userId!: number;
		@Prop() attach!: string;

		showAllNotesState = {
			date_picker: false
		};

		showAllNotesContent: {
			intersectionCopy: ProjectIntersectionData
		} = {
			intersectionCopy: {
				intersectionId: 0,
				projectId: 0,
				workflowTaskId: 0,
				comments: [],
			}
		};

		getStatusIcon(intersectionData: ProjectIntersectionData) {
			if (intersectionData.taskStatus) {
				switch (intersectionData.taskStatus.id) {
					case 1:
						return icons('./done_circle.svg');
					case 2:
						return icons('./not_applicable_circle.svg');
					case 3:
						return icons('./done_follow_up_circle.svg');
					case 4:
						return icons(`./in_progress_circle.svg`);
					case 5:
						return icons(`./stuck_circle.svg`);
					case 6:
						return icons(`./not_ok_follow_up_circle.svg`);
					case 7:
						return icons('./not_yet_started_circle.svg');
					case 8:
						return icons('./date_circle.svg');
					default:
						return;
				}
			}
		}

		getStatusText(intersectionData: ProjectIntersectionData) {
			if (intersectionData.taskStatus) {
				switch (intersectionData.taskStatus.id) {
					case 1:
						return 'Done';
					case 2:
						return 'Does not apply';
					case 3:
						return 'Ok, but needs follow up';
					case 4:
						return 'Working on it';
					case 5:
						return 'Stuck';
					case 6:
						return 'Not ok, follow up!';
					case 7:
						return 'Not yet started';
					case 8:
						return 'Planned for: ' + intersectionData.futureStartDate;
					default:
						return 'NO STATUS'
				}
			}
		}

		getStatusTextColor(taskStatusId: number) {
			switch (taskStatusId) {
				case 1:
					return 'workflow-done text single';
				case 2:
					return 'workflow-not-applicable text single';
				case 3:
					return 'workflow-done-follow-up text single';
				case 4:
					return 'workflow-in-progress text single';
				case 5:
					return 'workflow-stuck text single';
				case 6:
					return 'workflow-error-follow-up text single';
				case 7:
					return 'workflow-not-yet-started text single';
				case 8:
					return 'workflow-date-only text single';
				default:
					return 'workflow-date-only text single';
			}
		}

		@Emit('onEditNote')
		onEditNote(project: Project, task: WorkflowTask, intersectionData: ProjectIntersectionData, comment: Comment) {
			return {project, task, intersectionData, comment};
		}

		@Emit('onAddNote')
		onAddNote(project: Project, task: WorkflowTask, intersectionData: ProjectIntersectionData) {
			return {project, task, intersectionData};
		}

		@Emit('onChangeTask')
		onSelectedTaskChange(taskId: number, project: Project) {
			return {taskId, project};
		}

		setTaskStatus(taskStatusId: number) {
			if (taskStatusId !== this.data.intersectionData.taskStatus!.id) {
				this.onSetTaskStatus(taskStatusId);
			}
		}

		@Emit('onSetTaskStatus')
		onSetTaskStatus(taskStatusId: number) {
			return {
				projectId: this.data.project.id,
				workflowTaskId: this.data.task.id,
				taskStatusId
			}
		}

		@Emit('onSetTaskDate')
		onSetTaskDate() {
			if (this.showAllNotesContent.intersectionCopy.intersectionId !== 0) {
				this.showAllNotesState.date_picker = false;
				return {
					projectId: this.showAllNotesContent.intersectionCopy.projectId,
					workflowTaskId: this.showAllNotesContent.intersectionCopy.workflowTaskId,
					taskStatusId: this.showAllNotesContent.intersectionCopy.taskStatus!.id,
					futureStartDate: this.showAllNotesContent.intersectionCopy.futureStartDate
				}
			}
		}

		@Watch('data')
		onDataChange(val: {project: Project, task: WorkflowTask, intersectionData: ProjectIntersectionData}) {
			this.showAllNotesContent.intersectionCopy = Object.assign({}, val.intersectionData);
		}

		created() {
			if (this.data) {
				this.showAllNotesContent.intersectionCopy = Object.assign({}, this.data.intersectionData);
			}
		}

		getCommentsList(): Comment[] {
			return this.data.intersectionData.comments;
		}

		contentClass(): string {
			const classList = [
				'show-all-notes__dialog'
			];

			if (!!this.attach) {
				classList.push('show-all-notes__dialog--extended');
			}
			return classList.join(' ');
		}
	}
