import Vue from 'vue';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import {
  SuppliersActivityLog,
  SuppliersLastActionType,
  SupplierState,
  SupplierStateFilters
} from '@/modules/entities/store/modules/suppliers/types/SupplierState';
import { RootState } from '@/core/types/RootState';
import { Actions, Getters, Mutations } from '@/modules/entities/store/modules/suppliers/types/StoreTypes';
import { City, Comment, Supplier, SupplierType, TeleinformationDetail, Retainment } from '@/modules/entities/types/entities';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
import { ProvinceEntity } from '@/modules/entities/types/province.entity';
import { CompanyTypeEntity } from '@/modules/entities/types/company-type.entity';
import { Team } from '@/modules/settings/types/entities';
import { Contact } from '@/modules/contacts/types/entities';
import { SuppliersService } from '@/modules/entities/services/suppliers/suppliers.service';
import { RetainmentsService } from '@/services/retainments.service';
import ContractorsService from "@/services/contractors.service";

const suppliersService = new SuppliersService();
const retainmentsService = new RetainmentsService();
const contractorsService = new ContractorsService();

const getters: GetterTree<SupplierState, RootState> = {
  [Getters.GET_FETCHED_SUPPLIERS]: (state: SupplierState) => {
    return state.suppliers;
  },
  [Getters.GET_FETCHED_CONTRACTORS]: (state: SupplierState) => {
    return state.contractors;
  },
  [Getters.GET_SUPPLIER_DETAILS]: (state: SupplierState) => {
    return state.inspectedSupplier;
  },
  [Getters.GET_SUPPLIER_TYPES]: (state: SupplierState) => {
    return state.supplierTypes;
  },
  [Getters.GET_CONTRACTOR_TYPES]: (state: SupplierState) => {
    return state.contractorTypes;
  },
  [Getters.GET_CONTACT_TYPES]: (state: SupplierState) => {
    return state.contactTypes;
  },
  [Getters.GET_SUPPLIER_FILTERS]: (state: SupplierState) => {
    return state.filters;
  },
  [Getters.GET_LOADING]: (state: SupplierState) => {
    return state.isLoading;
  },
  [Getters.GET_PROVINCE_LIST]: (state: SupplierState): ProvinceEntity[] => state.province,
  [Getters.GET_COMPANY_TYPES]: (state: SupplierState): CompanyTypeEntity[] => state.companyTypes,
};

const actions: ActionTree<SupplierState, RootState> = {
  [Actions.FETCH_CONTACT_TYPES]: async ({ commit }: ActionContext<SupplierState, RootState>) => {
    const res: AxiosResponse<Pagination<SupplierType[]>> = await Vue.prototype.$http.get(`/api/v1/types?fetching_page=contacts`);
    commit(Mutations.MUTATE_CONTACT_TYPES, res.data);
  },
  [Actions.ATTACH_CREATED_CONTACT_TO_SUPPLIER]: async ({ commit, state }: ActionContext<SupplierState, RootState>, payload: { supplierId: number; contact: Contact }) => {
    const res = await Vue.prototype.$http.post(`/api/v1/suppliers/${ payload.supplierId }/contacts`, payload.contact);

    if (res.status === 201) {
      const supplier = JSON.parse(JSON.stringify(state.inspectedSupplier));
      if (supplier.contacts && supplier.contacts.length > 0) {
        supplier.contacts.push(res.data.data);
      } else {
        supplier.contacts = [res.data.data];
      }
      commit(Mutations.MUTATE_INSPECTED_SUPPLIER, supplier);
    }
  },
  [Actions.APPEND_CURRENT_SUPPLIER_TYPE]: ({ commit }: ActionContext<SupplierState, RootState>, payload: number) => {
    commit(Mutations.MUTATE_CURRENT_SUPPLIER_TYPE, payload);
  },
  [Actions.DELETE_SUPPLIER_BY_ID]: async ({ commit, state }: ActionContext<SupplierState, RootState>, supplierId: number) => {
    commit(Mutations.MUTATE_LOADING, true);
    await Vue.prototype.$http.delete(`/api/v1/suppliers/${ supplierId }`)
      .then((res: AxiosResponse<any>) => {
        const deletedItem = state.suppliers.find(x => x.id === supplierId);
        const deletedIndex = state.suppliers.findIndex(x => x.id === supplierId);
        const activityLog = {
          lastOperationType: SuppliersLastActionType.DELETE,
          lastItem: {
            item: deletedItem,
            index: deletedIndex,
          },
          lastBackendResponse: res.data.logItem,
        };

        commit(Mutations.MUTATE_SUPPLIERS_ACTIVITY_LOG, activityLog);
        const suppliers = state.suppliers.filter(supplier => supplier.id !== supplierId);
        commit(Mutations.MUTATE_FETCHED_SUPPLIERS, suppliers);
      }).finally(() => {
        commit(Mutations.MUTATE_LOADING, false);
      });
  },
  [Actions.DELETE_INSPECTED_SUPPLIER_BY_ID]: async ({ commit }: ActionContext<SupplierState, RootState>, supplierId: number) => {
    const res = await Vue.prototype.$http.delete(`/api/v1/suppliers/${ supplierId }`);

    if (res.status === 200) {
      commit(Mutations.MUTATE_INSPECTED_SUPPLIER);
      Vue.router.go(-1);
    }
  },
  [Actions.DESTROY_SUPPLIER_DETAILS]: ({ commit }: ActionContext<SupplierState, RootState>) => {
    commit(Mutations.MUTATE_INSPECTED_SUPPLIER);
  },
  [Actions.DESTROY_FETCHED_SUPPLIERS]: ({ commit }: ActionContext<SupplierState, RootState>) => {
    commit(Mutations.MUTATE_FETCHED_SUPPLIERS, []);
  },
  [Actions.EDIT_SUPPLIER_BY_ID]: async ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>, payload: Supplier): Promise<AxiosPromise<JsonResource<Supplier>>> => {
    commit(Mutations.MUTATE_LOADING, true);
    return await Vue.prototype.$http.put(`/api/v1/suppliers/${ payload.id }`, payload)
      .then((res: AxiosResponse<JsonResource<Supplier>>) => {
        const suppliers = state.suppliers.map(supplier => {
          return supplier.id === payload.id ? res.data.data : supplier;
        });
        commit(Mutations.MUTATE_FETCHED_SUPPLIERS, suppliers);
      })
      .finally(() => {
        commit(Mutations.MUTATE_LOADING, false);
      });
  },
  [Actions.EDIT_INSPECTED_SUPPLIER_BY_ID]: async ({ commit }: ActionContext<SupplierState, RootState>, payload: Supplier) => {
    commit(Mutations.MUTATE_LOADING, true);
    try {
      const res: AxiosResponse<JsonResource<Supplier>> = await Vue.prototype.$http.put(`/api/v1/suppliers/${ payload.id }`, payload);
      commit(Mutations.MUTATE_INSPECTED_SUPPLIER, res.data.data);
      return res;
    } finally {
      commit(Mutations.MUTATE_LOADING, false);
    }
  },
  [Actions.CREATE_NEW_SUPPLIER]: async (
    { commit, dispatch }: ActionContext<SupplierState, RootState>,
    supplier: Supplier
  ): Promise<AxiosResponse<JsonResource<Supplier>> | AxiosError> => {
    commit(Mutations.MUTATE_LOADING, true);
    return await Vue.prototype.$http
      .post(`/api/v1/suppliers`, supplier)
      .finally(() => {
        commit(Mutations.MUTATE_LOADING, false);
      });
  },
  [Actions.FETCH_SUPPLIER_BY_ID]: async ({ commit }: ActionContext<SupplierState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_LOADING, true);
    const res = await Vue.prototype.$http.get(`/api/v1/suppliers/${ payload }`);

    if (res.status === 200) {
      commit(Mutations.MUTATE_LOADING, false);
      commit(Mutations.MUTATE_INSPECTED_SUPPLIER, res.data.data);
    }
  },
  [Actions.FETCH_NEXT_PAGE]: async ({ commit, state }: ActionContext<SupplierState, RootState>, payload: string) => {
    if (state.paginationDetails && state.paginationDetails.next) {
      commit(Mutations.MUTATE_LOADING, true);
      let urlParameters = '';
      if (state.filters.nameQuery !== null && !state.filters.nameQuery.startsWith(' ') && state.filters.nameQuery !== '') {
        urlParameters += `&name=${ state.filters.nameQuery }`;
      }
      if (state.filters.mainQuery !== null && !state.filters.mainQuery.startsWith(' ') && state.filters.mainQuery !== '') {
        urlParameters += `&searchQuery=${ state.filters.mainQuery }`;
      }
      if (state.filters.selectedFutureWorkNumbers.length) {
        urlParameters += `&futureWork=${ state.filters.selectedFutureWorkNumbers.join() }`;
      }
      if (state.filters.selectedAestheticQualityNumbers.length) {
        urlParameters += `&aestheticQuality=${ state.filters.selectedAestheticQualityNumbers.join() }`;
      }
      if (state.filters.selectedTechnicalExpertiseNumbers.length) {
        urlParameters += `&technicalExpertise=${ state.filters.selectedTechnicalExpertiseNumbers.join() }`;
      }
      if (state.filters.selectedQualityNumbers.length) {
        urlParameters += `&quality=${ state.filters.selectedQualityNumbers.join() }`;
      }
      if (state.filters.selectedPriceNumbers.length) {
        urlParameters += `&price=${ state.filters.selectedPriceNumbers.join() }`;
      }
      if (state.filters.selectedContractorTypeIds.length) {
        urlParameters += `&contractorTypes=${ state.filters.selectedContractorTypeIds.join() }`;
      }
      if (state.filters.selectedSupplierTypeIds.length) {
        urlParameters += `&supplierTypes=${ state.filters.selectedSupplierTypeIds.join() }`;
      }
      if (state.filters.workedTogetherFilterToggled && state.filters.additionalFiltersActive) {
        urlParameters += '&worked-together=true';
      }
      if (state.filters.offerReceivedFilterToggled && state.filters.additionalFiltersActive) {
        urlParameters += '&offer-received=true';
      }
      if (state.filters.plannedFilterToggled && state.filters.additionalFiltersActive) {
        urlParameters += '&planned-to-work=true';
      }

      const res: AxiosResponse<Pagination<Supplier[]>> = await Vue.prototype.$http.get(state.paginationDetails.next + urlParameters);

      if (res.status === 200) {
        commit(Mutations.MUTATE_LOADING, false);
        commit(Mutations.MUTATE_PUSH_NEXT_PAGE, res.data);
      }
    }
  },
  [Actions.EDIT_SUPPLIER_CONTACTS]: async ({ commit }: ActionContext<SupplierState, RootState>, payload) => {
    const res = await Vue.prototype.$http.put(`/api/v1/suppliers/${ payload.supplierId }/${ payload.mode }/edit/${ payload.item.id }`, payload.item);

    if (res.status === 200) {
      commit(Mutations.MUTATE_SUPPLIER_CONTACTS, res.data.data);
    }
  },
  [Actions.FETCH_SUPPLIER_TYPES]: async ({ commit }: ActionContext<SupplierState, RootState>) => {
    const res = await Vue.prototype.$http.get(`/api/v1/settings/supplier-types`);

    if (res.status === 200) {
      commit(Mutations.MUTATE_SUPPLIER_TYPES, res.data.data);
    }
  },
  [Actions.FETCH_CONTRACTOR_TYPES]: async ({ commit }: ActionContext<SupplierState, RootState>) => {
    const res = await Vue.prototype.$http.get(`/api/v1/settings/contractor-types`);

    if (res.status === 200) {
      commit(Mutations.MUTATE_CONTRACTOR_TYPES, res.data.data);
    }
  },
  [Actions.TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_SUPPLIER]: async ({ commit }: ActionContext<SupplierState, RootState>, payload: { supplierId: number; contactId: number }) => {
    commit(Mutations.MUTATE_LOADING, true);
    const res = await Vue.prototype.$http.put(`/api/v1/suppliers/${ payload.supplierId }/contacts/${ payload.contactId }`);

    if (res.status === 200) {
      commit(Mutations.MUTATE_LOADING, false);
      commit(Mutations.MUTATE_INSPECTED_SUPPLIER, res.data.data);
    }
  },
  [Actions.FETCH_CONTRACTORS_BY_FILTERS]: async ({ commit, state }: ActionContext<SupplierState, RootState>) => {
    commit(Mutations.MUTATE_LOADING, true);

    try {
      const params = new URLSearchParams();
      if (state.contractorFilters.nameQuery !== null && !state.contractorFilters.nameQuery.startsWith(' ') && state.contractorFilters.nameQuery !== '') {
        params.set('name', state.contractorFilters.nameQuery);
      }
      const res = await contractorsService.getContractors(params);

      commit(Mutations.MUTATE_FETCHED_CONTRACTORS, res.data.data);

    } finally {
      commit(Mutations.MUTATE_LOADING, false);
    }
  },
  [Actions.FETCH_SUPPLIERS_BY_FILTERS]: async ({ commit, state }: ActionContext<SupplierState, RootState>) => {
    commit(Mutations.MUTATE_LOADING, true);
    let url = '/api/v1/suppliers?';
    if (state.filters.nameQuery !== null && !state.filters.nameQuery.startsWith(' ') && state.filters.nameQuery !== '') {
      url += `name=${ state.filters.nameQuery }&`;
    }
    if (state.filters.mainQuery !== null && !state.filters.mainQuery.startsWith(' ') && state.filters.mainQuery !== '') {
      url += `searchQuery=${ state.filters.mainQuery }&`;
    }
    if (state.filters.selectedFutureWorkNumbers.length && state.filters.futureWorkFilterActive) {
      url += `futureWork=${ state.filters.selectedFutureWorkNumbers.join() }&`;
    }
    if (state.filters.selectedAestheticQualityNumbers.length && state.filters.aestheticQualityFilterActive) {
      url += `aestheticQuality=${ state.filters.selectedAestheticQualityNumbers.join() }&`;
    }
    if (state.filters.selectedTechnicalExpertiseNumbers.length && state.filters.technicalExpertiseFilterActive) {
      url += `technicalExpertise=${ state.filters.selectedTechnicalExpertiseNumbers.join() }&`;
    }
    if (state.filters.selectedQualityNumbers.length && state.filters.qualityFilterActive) {
      url += `quality=${ state.filters.selectedQualityNumbers.join() }&`;
    }
    if (state.filters.selectedPriceNumbers.length && state.filters.priceFilterActive) {
      url += `price=${ state.filters.selectedPriceNumbers.join() }&`;
    }
    if (state.filters.selectedContractorTypeIds.length && state.filters.contractorTypeFilterActive) {
      url += `contractorTypes=${ state.filters.selectedContractorTypeIds.join() }&`;
    }
    if (state.filters.selectedSupplierTypeIds.length && state.filters.supplierTypeFilterActive) {
      url += `supplierTypes=${ state.filters.selectedSupplierTypeIds.join() }&`;
    }
    if (state.filters.workedTogetherFilterToggled && state.filters.additionalFiltersActive) {
      url += 'worked-together=true&';
    }
    if (state.filters.offerReceivedFilterToggled && state.filters.additionalFiltersActive) {
      url += 'offer-received=true&';
    }
    if (state.filters.plannedFilterToggled && state.filters.additionalFiltersActive) {
      url += 'planned-to-work=true';
    }

    const res: AxiosResponse<Pagination<Contact[]>> = await Vue.prototype.$http.get(url);

    if (res.status === 200) {
      commit(Mutations.MUTATE_LOADING, false);
      commit(Mutations.MUTATE_PAGINATION_DETAILS, res.data);
      commit(Mutations.MUTATE_FETCHED_SUPPLIERS, res.data.data);
    }
  },
  [Actions.FILTER_SUPPLIER_CONTACTS]: ({ commit, state }: ActionContext<SupplierState, RootState>, payload: number) => {
    const supplier = {
      ...state.inspectedSupplier,
      contacts: state.inspectedSupplier && state.inspectedSupplier.contacts && state.inspectedSupplier.contacts.filter(x => x.id !== payload),
    };
    commit(Mutations.MUTATE_INSPECTED_SUPPLIER, supplier);
  },
  [Actions.SAVE_COMMENT]: async ({ commit, state }: ActionContext<SupplierState, RootState>, payload: Comment) => {
    const res = await Vue.prototype.$http.post(`/api/v1/comments`, payload);

    if (res.status === 200 || res.status === 201) {
      let comments: Comment[] = [];

      if (state.inspectedSupplier) {
        if (state.inspectedSupplier.comments) {
          comments = [
            ...state.inspectedSupplier.comments,
          ];
        }
        comments = [
          ...comments,
          res.data.data,
        ];
        const inspectedSupplier = {
          ...state.inspectedSupplier,
          comments,
        };
        commit(Mutations.MUTATE_INSPECTED_SUPPLIER, inspectedSupplier);
      }
    }
  },
  [Actions.EDIT_COMMENT]: async ({ commit, state }: ActionContext<SupplierState, RootState>, payload: Comment) => {
    commit(Mutations.MUTATE_LOADING, true);
    try {
      const res: AxiosResponse<JsonResource<Comment>> = await Vue.prototype.$http.put(`/api/v1/comments/${ payload.id }`, payload);

      if (state.inspectedSupplier && state.inspectedSupplier.comments && state.inspectedSupplier.comments.length) {
        const updatedComments = state.inspectedSupplier.comments.map(x => x.id !== payload.id ? x : res.data.data);
        commit(Mutations.MUTATE_INSPECTED_SUPPLIER, {
          ...state.inspectedSupplier,
          comments: updatedComments,
        });
      }
    } finally { commit(Mutations.MUTATE_LOADING, false); }
  },
  [Actions.DELETE_COMMENT]: async ({ commit, state }: ActionContext<SupplierState, RootState>, payload: number) => {
    commit(Mutations.MUTATE_LOADING, true);
    try {
      const res = await Vue.prototype.$http.delete(`/api/v1/comments/${ payload }`);

      if (state.inspectedSupplier && state.inspectedSupplier.comments && state.inspectedSupplier.comments.length) {
        const updatedComments = state.inspectedSupplier.comments.filter(x => x.id !== payload);
        const inspectedSupplier = {
          ...state.inspectedSupplier,
          comments: updatedComments,
        };
        commit(Mutations.MUTATE_INSPECTED_SUPPLIER, inspectedSupplier);
      }
    } finally { commit(Mutations.MUTATE_LOADING, false); }
  },
  [Actions.SAVE_CITY]: async ({ commit }: ActionContext<SupplierState, RootState>, payload: City) => {
    const res = await Vue.prototype.$http.post(`/api/v1/projects/cities`, payload);
  },
  [Actions.SET_CONTRACTORS_FILTER_NAME_QUERY]: ({ commit, dispatch }: ActionContext<SupplierState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_CONTRACTORS_FILTERS_NAME_QUERY, payload);
    dispatch(Actions.FETCH_CONTRACTORS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_SUPPLIER_TYPES]: ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>, payload: number) => {
    let mutatedSupplierTypeIds = [...state.filters.selectedSupplierTypeIds];
    if (state.filters.selectedSupplierTypeIds.some(id => id === payload)) {
      mutatedSupplierTypeIds = mutatedSupplierTypeIds.filter(id => id !== payload);
    } else {
      mutatedSupplierTypeIds.push(payload);
    }
    commit(Mutations.MUTATE_FILTERS_SUPPLIER_TYPES, mutatedSupplierTypeIds);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_CONTRACTOR_TYPES]: ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>, payload: number) => {
    let mutatedContractorTypeIds = [...state.filters.selectedContractorTypeIds];
    if (state.filters.selectedContractorTypeIds.some(id => id === payload)) {
      mutatedContractorTypeIds = mutatedContractorTypeIds.filter(id => id !== payload);
    } else {
      mutatedContractorTypeIds.push(payload);
    }
    commit(Mutations.MUTATE_FILTERS_CONTRACTOR_TYPES, mutatedContractorTypeIds);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_PRICE]: ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>, payload: number) => {
    let mutatedPriceNumbers = [...state.filters.selectedPriceNumbers];
    if (state.filters.selectedPriceNumbers.some(id => id === payload)) {
      mutatedPriceNumbers = mutatedPriceNumbers.filter(id => id !== payload);
    } else {
      mutatedPriceNumbers.push(payload);
    }
    commit(Mutations.MUTATE_FILTERS_PRICE, mutatedPriceNumbers);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_QUALITY]: ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>, payload: number) => {
    let mutatedQualityNumbers = [...state.filters.selectedQualityNumbers];
    if (state.filters.selectedQualityNumbers.some(id => id === payload)) {
      mutatedQualityNumbers = mutatedQualityNumbers.filter(id => id !== payload);
    } else {
      mutatedQualityNumbers.push(payload);
    }
    commit(Mutations.MUTATE_FILTERS_QUALITY, mutatedQualityNumbers);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_TECHNICAL_EXPERTISE]: ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>, payload: number) => {
    let mutatedTechnicalExpertiseNumbers = [...state.filters.selectedTechnicalExpertiseNumbers];
    if (state.filters.selectedTechnicalExpertiseNumbers.some(id => id === payload)) {
      mutatedTechnicalExpertiseNumbers = mutatedTechnicalExpertiseNumbers.filter(id => id !== payload);
    } else {
      mutatedTechnicalExpertiseNumbers.push(payload);
    }
    commit(Mutations.MUTATE_FILTERS_TECHNICAL_EXPERTISE, mutatedTechnicalExpertiseNumbers);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_AESTHETIC_QUALITY]: ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>, payload: number) => {
    let mutatedAestheticQualityNumbers = [...state.filters.selectedAestheticQualityNumbers];
    if (state.filters.selectedAestheticQualityNumbers.some(id => id === payload)) {
      mutatedAestheticQualityNumbers = mutatedAestheticQualityNumbers.filter(id => id !== payload);
    } else {
      mutatedAestheticQualityNumbers.push(payload);
    }
    commit(Mutations.MUTATE_FILTERS_AESTHETIC_QUALITY, mutatedAestheticQualityNumbers);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_FUTURE_WORK]: ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>, payload: number) => {
    let mutatedFutureWorkNumbers = [...state.filters.selectedFutureWorkNumbers];
    if (state.filters.selectedFutureWorkNumbers.some(id => id === payload)) {
      mutatedFutureWorkNumbers = mutatedFutureWorkNumbers.filter(id => id !== payload);
    } else {
      mutatedFutureWorkNumbers.push(payload);
    }
    commit(Mutations.MUTATE_FILTERS_FUTURE_WORK, mutatedFutureWorkNumbers);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_ADDITIONAL_FILTER]: ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>, payload: string) => {
    switch (payload) {
    case 'offerReceivedFilter':
      commit(Mutations.MUTATE_FILTERS_OFFER_RECEIVED, !state.filters.offerReceivedFilterToggled);
      break;
    case 'workedTogetherFilter':
      commit(Mutations.MUTATE_FILTERS_WORKED_TOGETHER, !state.filters.workedTogetherFilterToggled);
      break;
    case 'plannedFilter':
      commit(Mutations.MUTATE_FILTERS_PLANNED, !state.filters.plannedFilterToggled);
      break;
    default:
      break;
    }
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_NAME_QUERY]: ({ commit, dispatch }: ActionContext<SupplierState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_FILTERS_NAME_QUERY, payload);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_MAIN_QUERY]: ({ commit, dispatch }: ActionContext<SupplierState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_FILTERS_MAIN_QUERY, payload);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_SIDEBAR_OPEN]: ({ commit }: ActionContext<SupplierState, RootState>, payload: boolean) => {
    commit(Mutations.MUTATE_FILTERS_SIDEBAR_OPEN, payload);
  },
  [Actions.SET_SUPPLIERS_FILTER_CLEAR]: ({ commit, dispatch }: ActionContext<SupplierState, RootState>) => {
    commit(Mutations.MUTATE_FILTERS_CLEAR);
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.SET_SUPPLIERS_FILTER_CLEAR_NO_FETCH]: ({ commit }: ActionContext<SupplierState, RootState>) => {
    commit(Mutations.MUTATE_FILTERS_CLEAR);
  },
  [Actions.TOGGLE_IS_FILTER_TYPE_ACTIVE]: ({ commit, dispatch, state }: ActionContext<SupplierState, RootState>, payload: string) => {
    const updatedFilters = { ...state.filters };
    switch (payload) {
    case 'supplierTypes':
      updatedFilters.supplierTypeFilterActive = !updatedFilters.supplierTypeFilterActive;
      commit(Mutations.MUTATE_FILTERS, updatedFilters);
      if (updatedFilters.selectedSupplierTypeIds.length) {
        dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
      }
      break;
    case 'contractorBranches':
      updatedFilters.contractorTypeFilterActive = !updatedFilters.contractorTypeFilterActive;
      commit(Mutations.MUTATE_FILTERS, updatedFilters);
      if (updatedFilters.selectedContractorTypeIds.length) {
        dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
      }
      break;
    case 'futureWork':
      updatedFilters.futureWorkFilterActive = !updatedFilters.futureWorkFilterActive;
      commit(Mutations.MUTATE_FILTERS, updatedFilters);
      if (updatedFilters.selectedFutureWorkNumbers.length) {
        dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
      }
      break;
    case 'price':
      updatedFilters.priceFilterActive = !updatedFilters.priceFilterActive;
      commit(Mutations.MUTATE_FILTERS, updatedFilters);
      if (updatedFilters.selectedPriceNumbers.length) {
        dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
      }
      break;
    case 'quality':
      updatedFilters.qualityFilterActive = !updatedFilters.qualityFilterActive;
      commit(Mutations.MUTATE_FILTERS, updatedFilters);
      if (updatedFilters.selectedQualityNumbers.length) {
        dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
      }
      break;
    case 'additionalFilters':
      updatedFilters.additionalFiltersActive = !updatedFilters.additionalFiltersActive;
      commit(Mutations.MUTATE_FILTERS, updatedFilters);
      dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
      break;
    default:
      break;
    }
    dispatch(Actions.FETCH_SUPPLIERS_BY_FILTERS);
  },
  [Actions.FETCH_PROVINCE_LIST]: async (
    { commit }: ActionContext<SupplierState, RootState>
  ): Promise<ProvinceEntity[]> => {
    return await Vue.prototype.$http.get(`/api/v1/settings/provinces`)
      .then((response: { data: { data: ProvinceEntity[] } }) => {
        commit(Mutations.MUTATE_PROVINCE, response.data.data);
        return response.data.data;
      })
      .catch(() => []);
  },
  [Actions.CREATE_SUPPLIER_BRANCH]: async ({ commit, dispatch }: ActionContext<SupplierState, RootState>, payload: { parentSupplierId: number; supplier: Supplier }): Promise<AxiosPromise<JsonResource<Supplier>>> => {
    commit(Mutations.MUTATE_LOADING, true);
    return await Vue.prototype.$http.post(`/api/v1/suppliers/${ payload.parentSupplierId }/locations`, payload.supplier)
      .then((res: AxiosResponse<JsonResource<Supplier>>) => {
        commit(Mutations.MUTATE_INSPECTED_SUPPLIER, res.data.data);
      })
      .finally(() => commit(Mutations.MUTATE_LOADING, false));
  },
  [Actions.UPDATE_SUPPLIER_BRANCH]: async ({ commit, dispatch }: ActionContext<SupplierState, RootState>, payload: { parentSupplierId: number; supplier: Supplier }): Promise<AxiosPromise<JsonResource<Supplier>>> => {
    commit(Mutations.MUTATE_LOADING, true);
    return await Vue.prototype.$http.put(`/api/v1/suppliers/${ payload.parentSupplierId }/locations/${ payload.supplier.id }`, payload.supplier)
      .then((res: AxiosResponse<JsonResource<Supplier>>) => {
        commit(Mutations.MUTATE_INSPECTED_SUPPLIER, res.data.data);
      })
      .finally(() => commit(Mutations.MUTATE_LOADING, false));
  },
  [Actions.DELETE_SUPPLIER_BRANCH]: async ({ commit, dispatch }: ActionContext<SupplierState, RootState>, payload: { parentSupplierId: number; branchId: number }): Promise<void> => {
    commit(Mutations.MUTATE_LOADING, true);
    await Vue.prototype.$http.delete(`/api/v1/suppliers/${ payload.parentSupplierId }/locations/${ payload.branchId }`)
      .then((res: AxiosResponse<JsonResource<Supplier>>) => {
        commit(Mutations.MUTATE_INSPECTED_SUPPLIER, res.data.data);
      }).finally(() => commit(Mutations.MUTATE_LOADING, false));
  },
  [Actions.FETCH_COMPANY_TYPES]: async (
    { commit }: ActionContext<SupplierState, RootState>
  ): Promise<any[]> => {
    return await Vue.prototype.$http.get('/api/v1/settings/company-types')
      .then((response: { data: { data: CompanyTypeEntity[] } }) => {
        commit(Mutations.MUTATE_COMPANY_TYPES, response.data.data);
        return response.data.data;
      })
      .catch(() => []);
  },
  [Actions.CHECK_FOR_EXISTING_SUPPLIER]: async ({ commit }: ActionContext<SupplierState, RootState>, payload: Supplier): Promise<AxiosPromise<JsonResource<Supplier>>> => {
    commit(Mutations.MUTATE_LOADING, true);
    return await Vue.prototype.$http.get(`/api/v1/commands/check-existing-suppliers?name=${ payload.commercialName }`).finally(() => {
      commit(Mutations.MUTATE_LOADING, false);
    });
  },
  [Actions.REVERT_LAST_ACTION]: async ({ commit, state, dispatch }: ActionContext<SupplierState, RootState>) => {
    commit(Mutations.MUTATE_LOADING, true);
    if (state.activityLog && state.activityLog.lastBackendResponse) {
      const requestBody = {
        type: state.activityLog.lastBackendResponse.subjectType,
        id: state.activityLog.lastBackendResponse.subjectId,
      };
      switch (state.activityLog.lastOperationType) {
      case SuppliersLastActionType.DELETE:
        await Vue.prototype.$http.post('/api/v1/commands/revert-deletion', requestBody)
          .then(() => {
            const updatedList = [...state.suppliers];
            updatedList.splice(state.activityLog.lastItem.index, 0, state.activityLog.lastItem.item);
            commit(Mutations.MUTATE_FETCHED_SUPPLIERS, updatedList);
          })
          .finally(() => commit(Mutations.MUTATE_LOADING, false));
        break;
      default:
        break;
      }
    }
  },
  [Actions.DELETE_TELEINFORMATION_BY_ID]: async ({ commit, state }: ActionContext<SupplierState, RootState>, payload: {supplierId: string; teleinformationId: string }) => {
    commit(Mutations.MUTATE_LOADING, true);

    try {
      const result = await suppliersService.deleteTeleinformationById(Number.parseInt(payload.supplierId),Number.parseInt(payload.teleinformationId));
      const items = state.inspectedSupplier && state.inspectedSupplier.teleinformations ? [...state.inspectedSupplier.teleinformations] : [] as TeleinformationDetail[];

      if(state.inspectedSupplier && state.inspectedSupplier.teleinformations) {
        const index = items.findIndex(teleinformation => teleinformation.id === Number.parseInt(payload.teleinformationId));
        items.splice(index, 1);
      }

      const supplier = { ...state.inspectedSupplier, teleinformations: items } as Supplier;
      commit(Mutations.MUTATE_INSPECTED_SUPPLIER, supplier);

      return result;
    } catch (err: any) {
      //
    } finally {
      commit(Mutations.MUTATE_LOADING, false);
    }
  },
  [Actions.SAVE_RETAINMENT]: async ({ commit, state }: ActionContext<SupplierState, RootState>, payload: {supplierId: string; retainment: Retainment}) => {
    commit(Mutations.MUTATE_LOADING, true);

    try {
      let result: AxiosResponse<JsonResource<Retainment>>;
      let items: Retainment[] = [];
      if(payload.retainment.id) {
        // update
        result = await suppliersService.updateRetainment(Number.parseInt(payload.supplierId), payload.retainment);
        if(state.inspectedSupplier && state.inspectedSupplier.retainments && !!result) {
          const retainment = result.data.data;
          items = state.inspectedSupplier.retainments && state.inspectedSupplier.retainments.length ? [...state.inspectedSupplier.retainments] : [] as Retainment[];
          const index = items.findIndex(item => item.id === retainment.id);
          items.splice(index === -1 ? 0 : index, index === -1 ? 0 : 1, {...result.data.data});
        }
      } else {
        // create
        result = await suppliersService.storeRetainment(Number.parseInt(payload.supplierId), payload.retainment);
        if(state.inspectedSupplier && state.inspectedSupplier.retainments) {
          items = [...state.inspectedSupplier.retainments, result.data.data];
        }
      }

      const supplier = { ...state.inspectedSupplier, retainments: items } as Supplier;
      commit(Mutations.MUTATE_INSPECTED_SUPPLIER, supplier);

      return result.data;
    } catch (err: any) {
      //
    } finally {
      commit(Mutations.MUTATE_LOADING, false);
    }
  },

  [Actions.DELETE_RETAINMENT]: async ({ commit, state }: ActionContext<SupplierState, RootState>, payload: { retainmentId: string }) => {
    commit(Mutations.MUTATE_LOADING, true);
    try {
      if(payload && payload.retainmentId) {
        await retainmentsService.deleteRetainmentById(Number.parseInt(payload.retainmentId));

        if(state.inspectedSupplier && state.inspectedSupplier.retainments) {
          const items = state.inspectedSupplier.retainments.filter(x => x.id !== Number.parseInt(payload.retainmentId));
          commit(Mutations.MUTATE_INSPECTED_SUPPLIER, {
            ...state.inspectedSupplier,
            retainments: items,
          });
        }
      }
    } finally { commit(Mutations.MUTATE_LOADING, false); }
  },
};

const mutations: MutationTree<SupplierState> = {
  [Mutations.MUTATE_SUPPLIER_CONTACTS]: (state: SupplierState, payload: any) => {
		state.inspectedSupplier!.contacts = payload;
  },
  [Mutations.MUTATE_CURRENT_SUPPLIER_TYPE]: (state: SupplierState, payload: string) => {
    state.currentSupplierType = payload;
  },
  [Mutations.MUTATE_FETCHED_SUPPLIERS]: (state: SupplierState, payload: Supplier[]) => {
    state.suppliers = payload;
  },
  [Mutations.MUTATE_FETCHED_CONTRACTORS]: (state: SupplierState, payload: (Supplier | Team)[]) => {
    state.contractors = payload;
  },
  [Mutations.MUTATE_INSPECTED_SUPPLIER]: (state: SupplierState, payload?: Supplier) => {
    state.inspectedSupplier = payload;
  },
  [Mutations.MUTATE_PUSH_NEXT_PAGE]: (state: SupplierState, payload: Pagination<Supplier[]>) => {
    state.paginationDetails = {
      first: payload.links.first,
      last: payload.links.last,
      prev: payload.links.prev,
      next: payload.links.next,
      current_page: payload.meta.current_page,
    };

    state.suppliers = [
      ...state.suppliers,
      ...payload.data,
    ];
  },
  [Mutations.MUTATE_PAGINATION_DETAILS]: (state: SupplierState, payload: Pagination<any>) => {
    state.paginationDetails = {
      first: payload.links.first,
      last: payload.links.last,
      prev: payload.links.prev,
      next: payload.links.next,
      current_page: payload.meta.current_page,
    };
  },
  [Mutations.MUTATE_SUPPLIER_TYPES]: (state: SupplierState, payload: SupplierType[]) => {
    state.supplierTypes = payload;
  },
  [Mutations.MUTATE_CONTRACTOR_TYPES]: (state: SupplierState, payload: ContractorBranch[]) => {
    state.contractorTypes = payload;
  },
  [Mutations.MUTATE_CONTACT_TYPES]: (state: SupplierState, payload: { klant: SupplierType; levarancier: SupplierType[] }) => {
    state.contactTypes = payload;
  },
  [Mutations.MUTATE_CONTRACTORS_FILTERS_NAME_QUERY]: (state: SupplierState, payload: string) => {
    state.contractorFilters.nameQuery = payload;
  },
  [Mutations.MUTATE_FILTERS_NAME_QUERY]: (state: SupplierState, payload: string) => {
    state.filters.nameQuery = payload;
  },
  [Mutations.MUTATE_FILTERS_MAIN_QUERY]: (state: SupplierState, payload: string) => {
    state.filters.mainQuery = payload;
  },
  [Mutations.MUTATE_FILTERS_SUPPLIER_TYPES]: (state: SupplierState, payload: number[]) => {
    state.filters.selectedSupplierTypeIds = payload;
  },
  [Mutations.MUTATE_FILTERS_CONTRACTOR_TYPES]: (state: SupplierState, payload: number[]) => {
    state.filters.selectedContractorTypeIds = payload;
  },
  [Mutations.MUTATE_FILTERS_PRICE]: (state: SupplierState, payload: number[]) => {
    state.filters.selectedPriceNumbers = payload;
  },
  [Mutations.MUTATE_FILTERS_QUALITY]: (state: SupplierState, payload: number[]) => {
    state.filters.selectedQualityNumbers = payload;
  },
  [Mutations.MUTATE_FILTERS_TECHNICAL_EXPERTISE]: (state: SupplierState, payload: number[]) => {
    state.filters.selectedTechnicalExpertiseNumbers = payload;
  },
  [Mutations.MUTATE_FILTERS_AESTHETIC_QUALITY]: (state: SupplierState, payload: number[]) => {
    state.filters.selectedAestheticQualityNumbers = payload;
  },
  [Mutations.MUTATE_FILTERS_FUTURE_WORK]: (state: SupplierState, payload: number[]) => {
    state.filters.selectedFutureWorkNumbers = payload;
  },
  [Mutations.MUTATE_FILTERS_OFFER_RECEIVED]: (state: SupplierState, payload: boolean) => {
    state.filters.offerReceivedFilterToggled = payload;
  },
  [Mutations.MUTATE_FILTERS_WORKED_TOGETHER]: (state: SupplierState, payload: boolean) => {
    state.filters.workedTogetherFilterToggled = payload;
  },
  [Mutations.MUTATE_FILTERS_PLANNED]: (state: SupplierState, payload: boolean) => {
    state.filters.plannedFilterToggled = payload;
  },
  [Mutations.MUTATE_FILTERS_SIDEBAR_OPEN]: (state: SupplierState, payload: boolean) => {
    state.filters.sideBarOpen = payload;
  },
  [Mutations.MUTATE_FILTERS_CLEAR]: (state: SupplierState) => {
    state.contractorFilters = {
      nameQuery: '',
    };
    state.filters = {
      sideBarOpen: state.filters.sideBarOpen,
      nameQuery: null,
      mainQuery: null,
      selectedContractorTypeIds: [],
      contractorTypeFilterActive: true,
      selectedSupplierTypeIds: [],
      supplierTypeFilterActive: true,
      selectedPriceNumbers: [],
      priceFilterActive: true,
      selectedQualityNumbers: [],
      qualityFilterActive: true,
      selectedAestheticQualityNumbers: [],
      aestheticQualityFilterActive: true,
      selectedTechnicalExpertiseNumbers: [],
      technicalExpertiseFilterActive: true,
      selectedFutureWorkNumbers: [],
      futureWorkFilterActive: true,
      additionalFiltersActive: true,
      offerReceivedFilterToggled: false,
      workedTogetherFilterToggled: false,
      plannedFilterToggled: false,
    };
  },
  [Mutations.MUTATE_FILTERS]: (state: SupplierState, payload: SupplierStateFilters) => {
    state.filters = payload;
  },
  [Mutations.MUTATE_LOADING]: (state: SupplierState, payload: boolean) => {
    state.isLoading = payload;
  },
  [Mutations.MUTATE_PROVINCE]: (
    state: SupplierState,
    provinces: ProvinceEntity[]
  ): void => {
    state.province = provinces;
  },
  [Mutations.MUTATE_COMPANY_TYPES]: (
    state: SupplierState,
    companyTypes: CompanyTypeEntity[]
  ): void => {
    state.companyTypes = companyTypes;
  },
  [Mutations.MUTATE_SUPPLIERS_ACTIVITY_LOG]: (state: SupplierState, payload: SuppliersActivityLog) => {
    state.activityLog = payload;
  },
};

const state: SupplierState = {
  isLoading: false,
  suppliers: [],
  contractors: [],
  paginationDetails: undefined,
  inspectedSupplier: undefined,
  supplierTypes: [],
  currentSupplierType: '',
  contractorTypes: [],
  currentContractorTypes: [],
  province: [],
  contractorFilters: {
    nameQuery: '',
  },
  filters: {
    sideBarOpen: false,
    nameQuery: null,
    mainQuery: null,
    selectedContractorTypeIds: [],
    contractorTypeFilterActive: true,
    selectedSupplierTypeIds: [],
    supplierTypeFilterActive: true,
    selectedPriceNumbers: [],
    priceFilterActive: true,
    selectedQualityNumbers: [],
    qualityFilterActive: true,
    selectedAestheticQualityNumbers: [],
    aestheticQualityFilterActive: true,
    selectedTechnicalExpertiseNumbers: [],
    technicalExpertiseFilterActive: true,
    selectedFutureWorkNumbers: [],
    futureWorkFilterActive: true,
    additionalFiltersActive: true,
    offerReceivedFilterToggled: false,
    workedTogetherFilterToggled: false,
    plannedFilterToggled: false,
  },
  contactTypes: [],
  companyTypes: [],
  activityLog: {
    lastItem: {
      item: null,
      index: -1,
    },
    lastBackendResponse: undefined,
    lastOperationType: undefined,
  },
};

export const store: Module<SupplierState, RootState> = {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
