

	import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";
	import { Project } from "@/modules/projects/types/entities";
	import { unionBy } from "lodash";

	@Component
	export default class ProjectPhaseListComponent extends Vue {
		@Prop() selectedProjectStatuses!: number[];
		@Prop() allProjects!: Project[];
		@Prop() selectedProjects!: Project[];
		@Prop() projectStatusId!: number;
		@Prop() projectStatusName!: string;
		@Prop() isProjectStatusSelected!: Function;
		@Prop() onAllProjectsClick!: Function;
		@Prop() isProjectChecked!: Function;

		get showProjectPhaseList() {
			return this.selectedProjectStatuses.some(id => id === this.projectStatusId);
		}

		get isProjectStatusIdActive() {
			return this.projectStatusId >= 1 && this.projectStatusId <= 7;
		}

		get filteredProjects() {
			if (this.projectStatusId === 0) {
				return unionBy(this.allProjects, this.selectedProjects, 'id').filter((x: Project) => !x.projectStatus);
			}
			return unionBy(this.allProjects, this.selectedProjects, 'id').filter((x: Project) => x.projectStatus && x.projectStatus.id === this.projectStatusId)
		}

		@Emit('loadMoreProjects')
		emitLoadMoreProjects() {
			// console.log('scrollToBottom');
		}

		@Emit('toggleProjectStatusSelection')
		emitToggleProjectStatusSelection(projectStatusId: number) {
			return projectStatusId;
		}
	}
