
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    watch,
    watchEffect,
  } from 'vue';
  import { TeleinformationDetail, TeleinformationDetailEntity } from '@/modules/entities/types/entities';
  import ActivatorComponent from '@/core/components/activator/activator.component.vue';
  import { cloneDeep } from 'lodash';
  import useTeleinformation from '@/composables/useTeleinformation';
  import DeleteModal from '@/modules/contacts/components/delete-modal/delete-modal.component.vue';

  enum PartialEdit {
    EDIT_TELEINFORMATION_LANDLINE_PHONE = 'editTeleinformationLandlinePhone',
    EDIT_TELEINFORMATION_MOBILE_PHONE = 'editTeleinformationMobilePhone',
    EDIT_TELEINFORMATION_EMAIL = 'editTeleinformationEmail',
  }

  const partialSetters: {
    [s: string]: (target: TeleinformationDetail, src: TeleinformationDetail) => TeleinformationDetail;
  } = {
    [PartialEdit.EDIT_TELEINFORMATION_LANDLINE_PHONE]: (t, s) => {
      t.landlinePhone = s.landlinePhone;
      t.landlinePhoneComment = s.landlinePhoneComment;
      return t;
    },
    [PartialEdit.EDIT_TELEINFORMATION_MOBILE_PHONE]: (t, s) => {
      t.mobilePhone = s.mobilePhone;
      t.mobilePhoneComment = s.mobilePhoneComment;
      return t;
    },
    [PartialEdit.EDIT_TELEINFORMATION_EMAIL]: (t, s) => {
      t.email = s.email;
      t.emailComment = s.emailComment;
      return t;
    },
  };

  export default defineComponent({
    name: 'ClientTeleinformationsListItem',
    components: {
      activator: ActivatorComponent,
      'delete-modal': DeleteModal,
      'confirm-dialog': () =>
        import(
          /* webpackChunkName: "confirmDialogComponent" */ '@/core/components/confirm-dialog/confirm-dialog.component.vue'
        ),
    },
    props: {
      teleinformationDetail: {
        type: Object as PropType<TeleinformationDetail>,
        default: () =>
          new TeleinformationDetailEntity({
            landlinePhone: '---',
            landlinePhoneComment: '---',
            mobilePhone: '---',
            mobilePhoneComment: '---',
            email: '---',
            emailComment: '---',
          }),
      },
      validationErrors: {
        type: Object as PropType<any>,
        default: () => {},
      },
    },
    setup(props, { emit }) {
      const { getters: teleinformationGetters, actions: teleinformationActions } = useTeleinformation();
      const teleinformationCopy = computed(() => cloneDeep(props.teleinformationDetail));

      // child 'component' states
      const teleinformationPartialEditContent: { [s: string]: TeleinformationDetail } = reactive({
        [PartialEdit.EDIT_TELEINFORMATION_LANDLINE_PHONE]: cloneDeep(teleinformationCopy.value),
        [PartialEdit.EDIT_TELEINFORMATION_MOBILE_PHONE]: cloneDeep(teleinformationCopy.value),
        [PartialEdit.EDIT_TELEINFORMATION_EMAIL]: cloneDeep(teleinformationCopy.value),
      });
      const teleinformationPartialEditState: { [s: string]: boolean } = reactive({
        [PartialEdit.EDIT_TELEINFORMATION_LANDLINE_PHONE]: false,
        [PartialEdit.EDIT_TELEINFORMATION_MOBILE_PHONE]: false,
        [PartialEdit.EDIT_TELEINFORMATION_EMAIL]: false,
      });

      const activate = (e: any, partialEdit: PartialEdit) => {
        teleinformationPartialEditState[partialEdit] = true;
      };
      const cancel = (e: any, partialEdit: PartialEdit) => {
        teleinformationPartialEditState[partialEdit] = false;
      };
      const submit = (e: any, partialEdit: PartialEdit) => {
        teleinformationActions
          .setTeleinformation(
            partialSetters[partialEdit](
              cloneDeep(teleinformationCopy.value),
              teleinformationPartialEditContent[partialEdit]
            )
          )
          .then(() => emit('onUpdate', cloneDeep(teleinformationGetters.teleinformation.value)));
      };
      const onDeleteTeleinformation = () => emit('onDelete', cloneDeep(teleinformationCopy.value));
      const refreshTeleinformationPartialEditContent = () => {
        Object.keys(teleinformationPartialEditContent).forEach((key: string) => {
          // copy the latest data to every partial edit but keep their original states (specific fields)
          teleinformationPartialEditContent[key] = partialSetters[key](
            cloneDeep(teleinformationCopy.value),
            cloneDeep(teleinformationPartialEditContent[key])
          );
        });
      };
      const resetTeleinformationPartialEditContent = () => {
        Object.keys(teleinformationPartialEditContent).forEach((key: string) => {
          // copy the new data to every partial edit
          teleinformationPartialEditContent[key] = cloneDeep(teleinformationCopy.value);
        });
      };
      const resetTeleinformationPartialEditState = () => {
        Object.keys(teleinformationPartialEditContent).forEach((key: string) => {
          // copy the new data to every partial edit
          teleinformationPartialEditState[key] = false;
        });
      };
      watch(teleinformationCopy, (newValue: TeleinformationDetail, oldValue: TeleinformationDetail) => {
        refreshTeleinformationPartialEditContent();
        resetTeleinformationPartialEditState();
      });

      return {
        teleinformationCopy,
        teleinformationGetters,
        teleinformationActions,

        teleinformationPartialEditContent,
        teleinformationPartialEditState,

        onDeleteTeleinformation,

        activate,
        cancel,
        submit,
      };
    },
  });
