
import { computed, defineComponent, onMounted, PropType, reactive, ref, watch, watchEffect } from 'vue'
import { Client, ClientVM, TeleinformationDetail } from '@/modules/entities/types/entities'
import { cloneDeep } from 'lodash'
import ClientTeleinformationsListItemComponent from './client-teleinformations-list-item.component.vue'

export default defineComponent({
  name: 'ClientTeleinformationsList',
  components: {
    'teleinformations-list-item': ClientTeleinformationsListItemComponent,
  },
  props: {
    client: {
      type: Object as PropType<Client>,
      default: () =>
        new ClientVM({
          name: 'Client Not Provided',
          createdAt: new Date('2020-01-01').toDateString(),
          updatedAt: new Date('2020-08-31').toDateString(),
          teleinformations: [],
        }),
    },
  },
  setup(props, { emit }) {
    const clientCopy = computed(() => cloneDeep(props.client))

    return {
      clientCopy,
    }
  },
})
