
  import { defineComponent, PropType } from 'vue';
  import { CalendarViewType } from '@/modules/calendar/types/CalendarViewType';
  import helpers from '@/modules/calendar/services/helper.service';
  import store from '../store';
  import { CalendarSidebarDialog } from '../types/CalendarSidebarDialog';
  import {CalendarSupplier} from "@/modules/calendar/interfaces/supplier/CalendarSupplier";

  export default defineComponent({
    name: 'CalendarGridSupplierHeader',
    props: {
      supplier: Object as PropType<CalendarSupplier | null>,
    },
    setup() {
      return {
        CalendarViewType,
        CalendarSidebarDialog,
        calendar: store.calendar,
        calendarSettings: store.calendarSettings,
        ...helpers,
      };
    },
  });
