
  import useClient from '@/composables/useClient';
  import useGoogleMapsAutocomplete from '@/composables/useGoogleMapsAutocomplete';
  import useRouter from '@/composables/useRouter';
  import ActivatorComponent from '@/core/components/activator/activator.component.vue';
  import DetailsExpansionPanelComponent from '@/core/components/details-page/details-expansion-panel.component.vue';
  import { transform } from '@/core/helpers/transform';
  import ProjectsListComponent from '@/modules/clients/components/client-projects-list.component.vue';
  import {
    setClientAddressData,
    setClientAddressFromGoogleMapsAddress,
    setClientCompanyInformation,
    setClientPrimaryAndSecondaryBankAccount,
  } from '@/modules/clients/utils';
  import ContactsListComponent from '@/modules/contacts/components/contacts-list/contacts-list.component.vue';
  import { Contact, ContactVM } from '@/modules/contacts/types/entities';
  import { Client, ClientVM, CompanyType, TeleinformationDetail } from '@/modules/entities/types/entities';
  import { CompanyTypesService } from '@/services/company-types.service';
  import Vue, { computed, defineComponent, PropType, reactive, ref, watchEffect } from 'vue';
  import { cloneDeep } from 'lodash';
  // import Vue from 'vue';
  import ClientTeleinformationsListComponent from '../components/client-teleinformations-list.component.vue';
  const companyTypesService = new CompanyTypesService();

  enum PartialEdit {
    EDIT_CLIENT_NAME = 'editClientName',
    EDIT_CLIENT_COMMERCIAL_NAME = 'editClientCommercialName',
    EDIT_CLIENT_ADDRESS = 'editClientAddress',
    EDIT_CLIENT_PERSONAL_INFORMATION = 'editClientPersonalInformation',
    EDIT_CLIENT_BANK_ACCOUNTS = 'editClientBankAccounts',
    EDIT_CLIENT_COMPANY_INFORMATION = 'editClientCompanyInformation',
  }

  const partialSetters: {
    [s: string]: (target: Client, src: Client) => Client;
  } = {
    [PartialEdit.EDIT_CLIENT_NAME]: (t, s) => {
      t.name = s.name;
      return t;
    },
    [PartialEdit.EDIT_CLIENT_COMMERCIAL_NAME]: (t, s) => {
      t.commercialName = s.commercialName;
      return t;
    },
    [PartialEdit.EDIT_CLIENT_ADDRESS]: transform(setClientAddressData),
    [PartialEdit.EDIT_CLIENT_PERSONAL_INFORMATION]: (t, s) => t,
    [PartialEdit.EDIT_CLIENT_COMPANY_INFORMATION]: transform(setClientCompanyInformation),
    [PartialEdit.EDIT_CLIENT_BANK_ACCOUNTS]: transform(setClientPrimaryAndSecondaryBankAccount),
  };

  export default defineComponent({
    name: 'ClientView',
    components: {
      activator: ActivatorComponent,
      'details-expansion-panel': DetailsExpansionPanelComponent,
      'teleinformations-list': ClientTeleinformationsListComponent,
      'contacts-list': ContactsListComponent,
      'projects-list': ProjectsListComponent,
      'create-contact': () =>
        import(
          /* webpackChunkName: "createContactComponent" */ '@/modules/contacts/components/create-contact/create-contact.component.vue'
        ),
    },
    props: {
      client: {
        type: Object as PropType<Client>,
        default: () =>
          new ClientVM({
            name: '---',
            createdAt: new Date('2020-01-01').toDateString(),
            updatedAt: new Date('2020-08-31').toDateString(),
          }),
      },
    },
    setup(props) {
      const { actions, getters } = useClient();
      const clientCopy = computed(() => cloneDeep(getters.client.value) || cloneDeep(props.client));
      const loading = computed(() => getters.isLoading.value);
      const validationErrors = computed(() => getters.clientValidationErrors.value);
      const addressSearchInput = ref<Vue | null>(null);
      const addContact = reactive<Partial<Contact>>(new ContactVM());
      const companyTypes = reactive<{ value: CompanyType[] }>({ value: [] });

      watchEffect(
        () => {
          // the DOM element will be assigned to the ref after initial render
          if (addressSearchInput.value) {
            // fixme
            // const addressSearchInputHtml = (addressSearchInput.value as Vue).$el.querySelector(
            //   'input'
            // ) as HTMLInputElement;
            const addressSearchInputHtml = addressSearchInput.value.$el.querySelector(
                'input'
            ) as HTMLInputElement;
            const autocomplete = useGoogleMapsAutocomplete(
              addressSearchInputHtml,
              (placeResult, args) => {
                if (placeResult.address_components) {
                  const client = setClientAddressFromGoogleMapsAddress(
                    cloneDeep(clientPartialEditContent[PartialEdit.EDIT_CLIENT_ADDRESS]),
                    placeResult.address_components,
                    ['street', 'streetNumber', 'postalCode', 'city'].filter((f) => !!f) as Array<string>
                  );
                  partialSetters[PartialEdit.EDIT_CLIENT_ADDRESS](
                    clientPartialEditContent[PartialEdit.EDIT_CLIENT_ADDRESS],
                    client
                  );
                }
              },
              {
                fields: ['address_component'],
                autoCompleteOptions: { types: ['geocode'] },
              }
            );
          }
        },
        { flush: 'post' }
      );

      // child 'component' states
      const clientPartialEditContent: { [s: string]: Client } = reactive({
        [PartialEdit.EDIT_CLIENT_NAME]: cloneDeep(clientCopy.value),
        [PartialEdit.EDIT_CLIENT_COMMERCIAL_NAME]: cloneDeep(clientCopy.value),
        [PartialEdit.EDIT_CLIENT_ADDRESS]: cloneDeep(clientCopy.value),
        [PartialEdit.EDIT_CLIENT_PERSONAL_INFORMATION]: cloneDeep(clientCopy.value),
        [PartialEdit.EDIT_CLIENT_COMPANY_INFORMATION]: cloneDeep(clientCopy.value),
        [PartialEdit.EDIT_CLIENT_BANK_ACCOUNTS]: cloneDeep(clientCopy.value),
      });
      const clientPartialEditState: { [s: string]: boolean } = reactive({
        [PartialEdit.EDIT_CLIENT_NAME]: false,
        [PartialEdit.EDIT_CLIENT_COMMERCIAL_NAME]: false,
        [PartialEdit.EDIT_CLIENT_ADDRESS]: false,
        [PartialEdit.EDIT_CLIENT_PERSONAL_INFORMATION]: false,
        [PartialEdit.EDIT_CLIENT_COMPANY_INFORMATION]: false,
        [PartialEdit.EDIT_CLIENT_BANK_ACCOUNTS]: false,
      });

      const activate = (e: any, partialEdit: PartialEdit) => {
        clientPartialEditState[partialEdit] = true;
      };
      const cancel = (e: any, partialEdit: PartialEdit) => {
        clientPartialEditState[partialEdit] = false;
      };
      const submit = (e: any, partialEdit: PartialEdit) => {
        actions.setClient(
          partialSetters[partialEdit](cloneDeep(clientCopy.value), clientPartialEditContent[partialEdit])
        );
        actions.updateClient().then(() => {
          refreshClientPartialEditContent();
          clientPartialEditState[partialEdit] = false;
        });
      };
      const submitSilent = (e: any, partialEdit: PartialEdit) => {
        clientPartialEditState[partialEdit] = false;
      };
      const formatClientAddress = (client: Client) => {
        const streetPart = `${client.street ? client.street : ''}${
          client.streetNumber ? ' ' + client.streetNumber : ''
        }`;
        const cityPart = `${client.postalCode ? client.postalCode : ''}${client.city ? ' ' + client.city : ''}`;
        const result = [streetPart.trim(), cityPart.trim()].filter((p) => p).join(', ');
        return result ? result : null;
      };
      const onNavigateBack = () => useRouter().actions.navigateBack();
      const resetClientPartialEditContent = () => {
        Object.keys(clientPartialEditContent).forEach((key: string) => {
          // copy the new data to every partial edit
          clientPartialEditContent[key] = cloneDeep(clientCopy.value);
        });
      };
      const refreshClientPartialEditContent = () => {
        Object.keys(clientPartialEditContent).forEach((key: string) => {
          // copy the latest data to every partial edit but keep their original states (specific fields)
          clientPartialEditContent[key] = partialSetters[key](
            cloneDeep(clientCopy.value),
            cloneDeep(clientPartialEditContent[key])
          );
        });
      };
      const onUpdateTeleinformation = (teleinformation: TeleinformationDetail) => {
        actions.setTeleinformation(teleinformation).then(() => {
          actions.updateClient().then(() => {
            refreshClientPartialEditContent();
          });
        });
      };

      // TODO: not implemented feature, deleting teleinformations via Client resource needs to be provided on API
      const onDeleteTeleinformation = (teleinformation: TeleinformationDetail) => {
        // console.log(teleinformation)
        // actions.removeTeleinformation(teleinformation).then(() => {
        //   actions.updateClient().then(() => {
        //     refreshClientPartialEditContent()
        //   })
        // })
      };
      const onAttachContact = (contactId: number) => {
        actions.addContact(contactId).then(() => {
          actions.showDialog('addContact', false);
        });
      };
      const onRemoveContact = (contactId: number) => {
        actions.removeContact(contactId);
      };
      const onDeleteContact = (contactId: number) => {
        actions.removeContact(contactId, true);
      };

      // init setup
      actions.fetchClient(Number.parseInt(Vue.router.app.$route.params.id)).then(() => {
        resetClientPartialEditContent();
      });
      companyTypesService.getAllCompanyTypes().then((resp) => {
        companyTypes.value = resp.data.data;
      });

      return {
        clientCopy,
        getters,
        actions,
        validationErrors,
        onNavigateBack,
        activate,
        cancel,
        submit,
        submitSilent,
        clientPartialEditContent,
        clientPartialEditState,
        formatClientAddress,
        loading,
        companyTypes,

        // data
        addContact,

        // handlers
        onUpdateTeleinformation,
        onDeleteTeleinformation,
        onRemoveContact,
        onDeleteContact,
        onAttachContact,

        // refs
        addressSearchInput,
      };
    },
  });
