import { setReactiveProp } from '@/composables/utils';
import {computed, ComputedRef, del, reactive, UnwrapRef, unref, Ref} from 'vue';

export interface UseDynamicProps<T> {
  getters: {
    all: ComputedRef<{ [key: string]: T }>;
    unique: (value: T) => ComputedRef<string | null>;
    some: (value: T) => ComputedRef<string | null>;
  };
  actions: {
    set: <T>(key: string, value: T) => Promise<void>;
    remove: (key: string) => Promise<void>;
    setAll: <T>(value: T) => Promise<void>;
  };
}

// composable
export function useDynamicProps<T = boolean>(): UseDynamicProps<T> {
  /**
   * Local state
   */
  const state = reactive<{
    store: { [key: string]: T };
  }>({
    store: {},
  });

  /**
   * Actions
   */
  const set = async <T>(key: string, value: T): Promise<void> => new Promise((resolve, reject) => {
    setReactiveProp(state.store, key, value); resolve();
  });
  const remove = async (key: string): Promise<void> => new Promise((resolve, reject) => {
    del(state.store, key); resolve();
  });
  const setAll = async <T>(value: T): Promise<void> => new Promise((resolve, reject) => {
    Object.keys(state.store).forEach(key => { set(key, value); }); resolve();
  });

  // return state and actions
  return {
    getters: {
      all: computed(() => state.store), //readonly(toRefs(state.store))
      unique: (value: T) => computed<string | null>(() => {
        const uniqueEntries = Object.entries(state.store).filter(v => v[1] === value);
        return uniqueEntries.length === 1 ? uniqueEntries[0][0] : null;
      }),
      some: (value: T) => computed<string | null>(() => {
        const someEntries = Object.entries(state.store).filter(v => v[1] === value);
        return someEntries.length >= 1 ? someEntries[0][0] : null;
      }),
    },
    actions: {
      set,
      remove,
      setAll,
    },
  };
}
