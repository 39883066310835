
	import { PropType } from 'vue';
    import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    import { fabric } from 'fabric'
    import { DrawingEventHandlerService as EventHandlerService } from '@/modules/purchase-invoices/services/drawing-event-handler.service'
    import { FabricShapeService } from '@/modules/purchase-invoices/services/drawing-shape.service'
    import { CustomFabricObject } from '../../types/drawing';
    import { DrawingEventHandlerService } from '@/modules/purchase-invoices/services/drawing-event-handler.service'
    import _ from 'lodash';

	@Component
	export default class DrawingCanvasComponent extends Vue {

        @Ref('canvas') canvas!: HTMLCanvasElement;

        @Prop() dimensions!: {width: number, height: number};
        @Prop({default: undefined, required: true}) eventHandler!: DrawingEventHandlerService;

        // vars
        content: {
            fabricCanvas: fabric.Canvas|null
        } = {
            fabricCanvas: null
        }

        // handlers
        onCanvasMouseDown(event: { e: Event }) {
            this.eventHandler.mouseDown(event.e);
            this.avoidDragAndClickEventsOfOtherUILibs(event.e);
        }
        onCanvasMouseMove(event: { e: Event }) {
            this.eventHandler.mouseMove(event.e);
        }
        onCanvasMouseUp(event: { e: Event }) {
            this.eventHandler.mouseUp(event.e);
        }
        onCanvasKeyDown(e: KeyboardEvent) {
            this.eventHandler.keyDown(e);
        }
        onSelectionCreated(e: fabric.IEvent) {
            const customFabricObject = e.target as CustomFabricObject
            this.eventHandler.objectSelected(customFabricObject);
        }
        onSelectionUpdated(e: fabric.IEvent) {
            const customFabricObject = e.target as CustomFabricObject
            this.eventHandler.objectSelected(customFabricObject);
        }
        onObjectMoving(e: any) {
            this.eventHandler.objectMoving(e.target.id, e.target.type, e.target.left, e.target.top);
        }
        onObjectScaling(e: any) {
            this.eventHandler.objectScaling(
                e.target.id,
                e.target.type,
                { x: e.target.scaleX, y: e.target.scaleY },
                { left: e.target.left, top: e.target.top },
            );
        }
        onAfterRender(e: fabric.IEvent) {
            if(this.content.fabricCanvas) this.debouncedOnAfterRender(this.content.fabricCanvas, this)
        }

        // methods
        debouncedOnAfterRender = _.debounce((canvas: fabric.Canvas, vm: Vue) => {
            vm.$emit('objectsChanged', canvas.getObjects())
        }, 250)
        scaleCanvasObjects(scale: {scaleX: number; scaleY: number}) {
            const scaleRatio = Math.min(scale.scaleX, scale.scaleY)
            if(this.content.fabricCanvas) {
                this.content.fabricCanvas.setZoom(this.content.fabricCanvas.getZoom() * scaleRatio)
            }
        }
        updateCanvasDimensions(dimensions: {width: number, height: number}) {
            if(this.canvas) {
                this.canvas.width = dimensions.width;
                this.canvas.height = dimensions.height;
            }
            if(this.content.fabricCanvas) {
                this.content.fabricCanvas.setDimensions({width: dimensions.width, height: dimensions.height})
            }
        }
        addEventHandlers() {
            if(this.content.fabricCanvas){
                this.content.fabricCanvas.on('mouse:down', this.onCanvasMouseDown);
                this.content.fabricCanvas.on('mouse:move', this.onCanvasMouseMove);
                this.content.fabricCanvas.on('mouse:up', this.onCanvasMouseUp);
                this.content.fabricCanvas.on('selection:created', this.onSelectionCreated);
                this.content.fabricCanvas.on('selection:updated', this.onSelectionUpdated);
                this.content.fabricCanvas.on('object:moving', this.onObjectMoving);
                this.content.fabricCanvas.on('object:scaling', this.onObjectScaling);

                this.content.fabricCanvas.on('after:render', this.onAfterRender);
            }
        }
        removeEventHandlers() {
            if(this.content.fabricCanvas){
                this.content.fabricCanvas.off('mouse:down', this.onCanvasMouseDown);
                this.content.fabricCanvas.off('mouse:move', this.onCanvasMouseMove);
                this.content.fabricCanvas.off('mouse:up', this.onCanvasMouseUp);
                this.content.fabricCanvas.off('selection:created', this.onSelectionCreated);
                this.content.fabricCanvas.off('selection:updated', this.onSelectionUpdated);
                this.content.fabricCanvas.off('object:moving', this.onObjectMoving);
                this.content.fabricCanvas.off('object:scaling', this.onObjectScaling);
            }
        }
        avoidDragAndClickEventsOfOtherUILibs(e: Event) {
            e.stopPropagation();
        }

        // watchers
        @Watch('dimensions') onWatchDimensions(newDimensions: {width: number, height: number}, oldDimensions: {width: number, height: number}) {
            const dimensionsChanged = newDimensions && oldDimensions && (newDimensions.width !== oldDimensions.width || newDimensions.width !== oldDimensions.width)
            if(dimensionsChanged) {
                const scale = { scaleX: 1, scaleY: 1 }
                if(newDimensions && oldDimensions) {
                    scale.scaleX = oldDimensions.width ? newDimensions.width / oldDimensions.width : 1; // 1000 / 500 ==> zoom in = 2 OR 500 / 1000 ==> zoom out = 0.5
                    scale.scaleY = oldDimensions.height ? newDimensions.height / oldDimensions.height : 1; // 1000 / 500 ==> zoom in = 2 OR 500 / 1000 ==> zoom out = 0.5
                }
                this.updateCanvasDimensions(newDimensions);
                this.scaleCanvasObjects(scale)
            }
        }

        // lifecycle
        created() {
        }
        mounted() {
            this.content = {...this.content, 
                fabricCanvas: new fabric.Canvas(this.canvas, {
                    selection: true,
                    selectionColor: '#00000005',
                    preserveObjectStacking: true, 
                })
            }
            if(this.content.fabricCanvas) {
                this.eventHandler.canvas = this.content.fabricCanvas
                this.eventHandler.extendObjectWithId()
                fabric.Object.prototype.objectCaching = false
                this.addEventHandlers()
            }
        }

        destroyed() {
            // this.removeEventHandlers()
        }


    }

