
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { maxLength, numeric, required } from 'vuelidate/lib/validators';
import { WorkflowFunctionGroup, WorkflowTask } from '@/modules/workflow/types/entities';
import { PropType } from 'vue';

@Component
export default class TaskModalComponent extends Vue {
	@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
	@Prop() mainTaskSequence!: number;
	@Prop() taskItem?: WorkflowTask;
	@Prop({ type: Boolean , default: false }) isCreatingSubTask!: boolean;
	@Prop({ type: Array as PropType<WorkflowFunctionGroup[]>, default: () => [] }) functionGroups!: WorkflowFunctionGroup[];
	@Prop() getFunctionGroupColour!: Function;
	@Prop()
	@Validate({
		$each: {
			sequence: { required, numeric },
			name: {
				required,
				maxLength: maxLength(255)
			},
			description: {
				maxLength: maxLength(255)
			},
		}
	})
	newSubTasks!: WorkflowTask[] | null;

	@Validate({
		required,
		sequence: { required, numeric },
		name: {
			required,
			maxLength: maxLength(255)
		},
		children: {
			$each: {
				id: {},
				sequence: { required, numeric },
				name: {
					required,
					maxLength: maxLength(255)
				},
				description: {
					maxLength: maxLength(255)
				},
				functionGroupsResponsible: {}
			}
		}
	})
	taskItemCopy: WorkflowTask = {
		id: 0,
		sequence: 0,
		name: '',
		description: '',
		parentTaskId: null,
		archivedAt: null,
		children: []
	};

	onAddSubTask() {
		if (this.taskItemCopy.children && this.taskItemCopy.children.length) {
			let childrenHighestSequence = Math.max(...this.taskItemCopy.children.map((childTask) => {
				return childTask.sequence!;
			}));
			childrenHighestSequence += 1;
			this.taskItemCopy.children.push({
				id: 0,
				name: '',
				sequence: childrenHighestSequence,
				description: '',
				parentTaskId: 0,
				archivedAt: null
			});
		} else {
			this.taskItemCopy.children = [{
				id: 0,
				name: '',
				sequence: 1,
				description: '',
				parentTaskId: 0,
				archivedAt: null,
				functionGroupsResponsible: []
			}]
		}
	}

	onDeleteSubTask(index: number) {
		if (this.taskItemCopy.children) {
			this.taskItemCopy.children.splice(index, 1);
		}
	}

	getFunctionGroupSequence(task: WorkflowFunctionGroup, model?: WorkflowFunctionGroup[]): string {
		if (model) {
			const result = model.find(functionGroup => functionGroup.id === task.id);
			if (result && result.pivot) return `(${result.pivot.sequence})`
		}
		return '';
	}

	onFunctionGroupChange(functionGroupItems: WorkflowFunctionGroup[]): void {
		/* functionGroups come sorted by sequence from the backend */
		functionGroupItems.map((x: WorkflowFunctionGroup, idx: number, arr: WorkflowFunctionGroup[]) => {
			const result = {
				...x,
				pivot: {
					sequence: idx + 1
				}
			};
			Vue.set(arr, idx, result);
		});
	}

	get isFormValidOrTouched(): boolean {
		return !((!this.$v.$invalid) && (this.$v.$anyDirty))
	}

	handleFormSubmit(): void {
		if (this.isFormValidOrTouched) {
			return;
		} else {
			this.emitSaveTask();
		}
	}

	@Emit('saveTask')
	emitSaveTask(): WorkflowTask {
		if (this.taskItemCopy.children) {
			if (this.newSubTasks) {
				return {
					...this.taskItemCopy,
					children: [
						...this.taskItemCopy.children,
						...this.newSubTasks
					]
				}
			}
			return this.taskItemCopy;
		}
		return this.taskItemCopy;
	}

	created(): void {
		this.taskItemCopy.sequence = this.mainTaskSequence;
		if (this.taskItem) {
			this.taskItemCopy = {...this.taskItem};
		}
	}
}

