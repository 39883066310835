import { reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import useFilter from '@/composables/useFilter';
import { UseEmployeeState, UseEmployeeComposables, UseEmployeeContext } from './types/useEmployee';
import { merge } from 'lodash';
import { Options } from './types/composable';
import { actions as employeeActions } from './actions/useEmployee';
import { useDynamicProps } from './useDynamicProps';
import { Employee } from '@/modules/settings/types/entities';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseEmployeeState>): UseEmployeeState => {
  return merge(
    {
      employee: null,
      employeeValidationErrors: null,
      employeeBusinessErrors: null,
    },
    state
  );
};

/**
 * `UseEmployee`-composable
 *   - All Employee-related interactions and functionality can be stored here.
 *   - If you need a global store, than just use this composable in eg a `store.ts`
 *
 */
export default function useEmployee(options?: Options<UseEmployeeState, UseEmployeeComposables>) {

  const state: UseEmployeeState = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    filter: useFilter(),
    filterTimesheets: useFilter(),
    filterWorkDays: useFilter(),
    filterAbsencePeriods: useFilter(),
    showDialog: useDynamicProps<boolean>(),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseEmployeeContext = { state, composables };
  const actions = wrapActions(ctx, employeeActions);

  // return state and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      filterTimesheets: ctx.composables.filterTimesheets.getters,
      filterWorkDays: ctx.composables.filterWorkDays.getters,
      filterAbsencePeriods: ctx.composables.filterAbsencePeriods.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // other
      showDialog: ctx.composables.showDialog.getters,
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      employee: computed<Employee | null>(() => state.employee),
      employeeValidationErrors: computed(() => state.employeeValidationErrors),
      employeeBusinessErrors: computed(() => state.employeeBusinessErrors),
    },
    actions: {
      ...ctx.composables.filter.actions,
      filterTimesheets: ctx.composables.filterTimesheets.actions,
      filterWorkDays: ctx.composables.filterWorkDays.actions,
      filterAbsencePeriods: ctx.composables.filterAbsencePeriods.actions,
      showDialog: ctx.composables.showDialog.actions.set,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...actions,
    },
  };
}