import axios, { AxiosResponse } from 'axios';
import { JsonResource } from '@/core/types/Entities';
import { PlannerEnvironment } from '@/modules/calendar/interfaces/PlannerEnvironment';
import { BaseProject } from '@/modules/calendar/interfaces/project/BaseCalendarProject';
import { CalendarProject } from '@/modules/calendar/interfaces/project/CalendarProject';
import { PlannerPeriod } from '@/modules/calendar/interfaces/project/plannerPeriod';
import { WorkDaySequence } from '@/modules/calendar/interfaces/project/workDaySequence';
import dayjs from 'dayjs';
import { PlannerDay } from '@/modules/calendar/interfaces/project/PlannerDay';
import { Project } from '@/modules/projects/types/entities';
import { Division, Supplier } from '@/modules/calendar/interfaces/project/interfaces';
import { WorkDay } from '@/modules/calendar/interfaces/project/workDay';
import { BaseDivision } from '@/modules/calendar/interfaces/division/BaseDivision';
import { CalendarDivision } from '@/modules/calendar/interfaces/division/CalendarDivision';
import {CalendarSupplier} from "@/modules/calendar/interfaces/supplier/CalendarSupplier";
import {BaseSupplier} from "@/modules/calendar/interfaces/supplier/BaseSupplier";

const PLANNER_ENDPOINT = '/api/v1/planner';

export default class CalendarService {

  getBaseEndpoint(): string {
    return PLANNER_ENDPOINT;
  }

  async fetchEnvironment(): Promise<AxiosResponse<JsonResource<PlannerEnvironment>>> {
    return axios.get(`${PLANNER_ENDPOINT}/environment`);
  }

  async addProjectToEnvironment(environmentId: number, projectId: number): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.post(`${ PLANNER_ENDPOINT }/${ environmentId }/projects`, { id: projectId });
  }

  async removeProjectFromEnvironment(environmentId: number, projectId: number): Promise<AxiosResponse<void>> {
    return axios.delete(`${ PLANNER_ENDPOINT }/${ environmentId }/projects/${ projectId }`);
  }

  async addSupplierToEnvironment(environmentId: number, supplierId: number): Promise<AxiosResponse<JsonResource<Supplier>>> {
    return axios.post(`${ PLANNER_ENDPOINT }/${ environmentId }/suppliers`, { id: supplierId });
  }

  async removeSupplierFromEnvironment(environmentId: number, supplierId: number): Promise<AxiosResponse<void>> {
    return axios.delete(`${ PLANNER_ENDPOINT }/${ environmentId }/suppliers/${ supplierId }`);
  }

  async addDivisionToEnvironment(environmentId: number, divisionId: number): Promise<AxiosResponse<JsonResource<Division>>> {
    return axios.post(`${ PLANNER_ENDPOINT }/${ environmentId }/divisions`, { id: divisionId });
  }

  async removeDivisionFromEnvironment(environmentId: number, divisionId: number): Promise<AxiosResponse<void>> {
    return axios.delete(`${ PLANNER_ENDPOINT }/${ environmentId }/divisions/${ divisionId }`);
  }

  async updatePlannerDateRange(environmentId: number, dateRange: (string | undefined)[]): Promise<AxiosResponse<JsonResource<any>>> {
    return axios.put(`${PLANNER_ENDPOINT}/${environmentId}`, { dateRange: dateRange });
  }

  async fetchProjects(environmentId: number | undefined): Promise<AxiosResponse<JsonResource<BaseProject[]>>> {
    return axios.get(`${PLANNER_ENDPOINT}/${environmentId}/projects`);
  }

  async fetchDivisions(environmentId: number | undefined): Promise<AxiosResponse<JsonResource<BaseDivision[]>>> {
    return axios.get(`${PLANNER_ENDPOINT}/${environmentId}/divisions`);
  }

  async fetchSuppliers(environmentId: number | undefined): Promise<AxiosResponse<JsonResource<BaseSupplier[]>>> {
    return axios.get(`${PLANNER_ENDPOINT}/${environmentId}/suppliers`);
  }

  async fetchProjectById(environmentId: number | undefined, projectId: number): Promise<AxiosResponse<JsonResource<CalendarProject>>> {
    return axios.get(`${PLANNER_ENDPOINT}/${environmentId}/projects/${projectId}`);
  }

  async fetchDivisionById(environmentId: number | undefined, divisionId: number): Promise<AxiosResponse<JsonResource<CalendarDivision>>> {
    return axios.get(`${PLANNER_ENDPOINT}/${environmentId}/divisions/${ divisionId }`);
  }

  async fetchSupplierById(environmentId: number | undefined, supplierId: number): Promise<AxiosResponse<JsonResource<CalendarSupplier>>> {
    return axios.get(`${PLANNER_ENDPOINT}/${environmentId}/suppliers/${ supplierId }`);
  }

  async fetchPlannerPeriodById(periodId: number): Promise<AxiosResponse<JsonResource<PlannerPeriod>>> {
    return axios.get(`${PLANNER_ENDPOINT}/periods/${periodId}`);
  }

  async fetchDay(quoteId: number, date: string): Promise<AxiosResponse<JsonResource<PlannerDay>>> {
    const formattedDate = dayjs.utc(date).format('YYYYMMDD');
    return axios.get(`${PLANNER_ENDPOINT}/quotes/${quoteId}/days/${formattedDate}`);
  }

  async createPlannerPeriod(quoteId: number, date: string): Promise<AxiosResponse<JsonResource<PlannerDay>>> {
    const res = await axios.post(`${PLANNER_ENDPOINT}/quotes/${quoteId}/days`, { date });
    return res;
  }

  /**
   * //TODO: provide bulk endpoint to update a collection of WorkDaySequences for multiple workDays
   *
   * @param workDayId
   * @param sequences
   */
  async saveWorkDaySequences(workDayId: number, sequences: WorkDaySequence[]): Promise<AxiosResponse<JsonResource<WorkDaySequence[]>>> {
    return axios.post(`${PLANNER_ENDPOINT}/work-days/${workDayId}/sequences`, sequences);
  }

  async createWorkDayForPlannerPeriod(plannerPeriodId: number, workDay: WorkDay): Promise<AxiosResponse<JsonResource<WorkDay>>> {
    return axios.post(`${PLANNER_ENDPOINT}/periods/${ plannerPeriodId }/work-days`, workDay);
  }

  async deletePlannerPeriod(plannerPeriodId: number) {
    return axios.delete(`${PLANNER_ENDPOINT}/periods/${plannerPeriodId}`);
  }

  async savePeriodComment(periodId: number, comment: string) {
    return axios.post(`${PLANNER_ENDPOINT}/periods/${periodId}/comments`, { comment: comment });
  }

  async deleteWorkDay(workDayId: number) {
    return axios.delete(`${PLANNER_ENDPOINT}/work-days/${workDayId}`);
  }

  async saveWorkDayComment(workDayId: number, comment: string) {
    return axios.post(`${PLANNER_ENDPOINT}/work-days/${workDayId}/comments`, { comment: comment });
  }

  async updateDay(quoteId: number, day: string, data: PlannerDay) {
    return axios.put(`${PLANNER_ENDPOINT}/quotes/${quoteId}/days/${day}`, data);
  }

  async deleteDay(quoteId: number, day: string) {
    return axios.delete(`${PLANNER_ENDPOINT}/quotes/${quoteId}/days/${day}`);
  }

  async updatePeriod(periodId: number, data: PlannerPeriod) {
    return axios.put(`${PLANNER_ENDPOINT}/periods/${periodId}`, data);
  }
}