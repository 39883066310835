
import Vue, { computed, defineComponent, PropType } from 'vue'
import { debounce, cloneDeep } from 'lodash'
import {ProjectRelationship} from "@/modules/purchase-invoices/types/entities";
import ProjectAutocomplete from '@/modules/purchase-invoices/components/project-autocomplete/project-autocomplete.component.vue'
import useProjectList from "@/composables/useProjectList";
import useContractorBranchList from "@/composables/useContractorBranchList";
import ContractorBranchAutocomplete from '@/modules/purchase-invoices/components/contractor-branch-autocomplete/contractor-branch-autocomplete.component.vue';
import AccountingLedgerItemAutocomplete from '@/modules/accounting/components/accounting-ledger-item-autocomplete/accounting-ledger-item-autocomplete.component.vue';
import {AccountingLedger, AccountingLedgerItem} from "@/modules/accounting/types/entities";
import {filterAccountingLedgerItemsForProjectRelationship} from "@/modules/purchase-invoices/helpers";

export default defineComponent({
  name: 'ProjectRelationshipSplitPopup',
  emits: ['confirmSplit'],
  components: {
    AccountingLedgerItemAutocomplete,
    ContractorBranchAutocomplete,
    ProjectAutocomplete
  },
  props: {
    value: {
      type: Object as PropType<ProjectRelationship|null>,
    },
    currentAccountingLedger: {
      type: Object as PropType<AccountingLedger>,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: Object as PropType<any>,
      default: () => {},
    },
  },
  setup(props, { emit }) {

    // store
    const {
      actions: { search: searchProjects, ...projectsActions },
      getters: { isSearching: isSearchingProjects, projects, ...projectsGetters}
    } = useProjectList()

    const {
      actions: { search: searchContractorBranches, ...contractorBranchesActions },
      getters: { isSearching: isSearchingContractorBranches, contractorBranches, ...contractorBranchesGetters}
    } = useContractorBranchList()

    // computed
    const accountingLedgerItems = computed(() => {
      return props.value ? filterAccountingLedgerItemsForProjectRelationship(props.value, props.currentAccountingLedger) : [];
    })

    // handlers
    const onSearchProjectDebounced = debounce(searchProjects, 300);
    const onSearchProject = (searchTerm: string) => {
      if(searchTerm) {
        onSearchProjectDebounced(searchTerm)
      }
    }

    const onSearchContractorBranchDebounced = debounce(searchContractorBranches, 300);
    const onSearchContractorBranch = (searchTerm: string) => {
      if(searchTerm) {
        onSearchContractorBranchDebounced(searchTerm)
      }
    }

    const confirmSplit = () => emit('confirmSplit')

    return {
      confirmSplit,

      onSearchProject,
      isSearchingProjects,
      projects,

      onSearchContractorBranch,
      isSearchingContractorBranches,
      contractorBranches,

      accountingLedgerItems,
    }
  },
})
