
  import { computed, defineComponent, ref, watch, PropType, reactive } from 'vue';
  import { Project } from '@/modules/projects/types/entities';
  import SettingsProjectsTableComponent from './settings-project-table.component.vue';
  import SettingsCollaboratorsTableComponent from './settings-collaborators-table.component.vue';
  import SettingsExpansionPanelComponent from '@/modules/calendar/components/settings-expansion-panel.component.vue';
  import SettingsSidebarComponent from '@/modules/calendar/components/settings-sidebar.component.vue';
  import store from '@/modules/calendar/store';
  import { Supplier, Division } from '@/modules/calendar/interfaces/project/interfaces';
  import { AxiosResponse } from 'axios';
  import { JsonResource } from '@/core/types/Entities';
  import helpers from '../services/helper.service';
  import { debounce } from 'lodash';

  export default defineComponent({
    components: {
      'settings-expansion-panel': SettingsExpansionPanelComponent,
      'settings-sidebar': SettingsSidebarComponent,
      'projects-table': SettingsProjectsTableComponent,
      'collaborators-table': SettingsCollaboratorsTableComponent,
    },
    props: {
      open: { type: Boolean, default: false },
    },
    setup(props, {}) {
      const filteredProjectsNameSearchValue = ref('');
      // const plannerProjectNameSearchValue = ref('');
      const filteredCollaboratorsNameSearchValue = ref('');
      // const plannerCollaboratorsNameSearchValue = ref('');
      const deselectedProjectsCache = ref<Array<number>>([]);
      const selectedProjectsCache = ref<Array<number>>([]);

      const bothCollaboratorsTablesOpen = computed(() => true);
      const bothProjectsTablesOpen = computed(() => true);
      const selectedProjectsAreLoading = store.calendarSettings.getters.isLoadingAction([
        store.calendarSettings.actions.fetchSelectedProjects.name,
        store.calendarSettings.actions.removeProjectsFromCalendar.name,
      ]);
      const filteredProjectsAreLoading = store.calendarSettings.getters.isLoadingAction([
        store.calendarSettings.actions.fetchProjectsByFilters.name,
        store.calendarSettings.actions.addProjectsToCalendar.name,
      ]);
      const selectedCollaboratorsAreLoading = store.calendarSettings.getters.isLoadingAction([
        store.calendarSettings.actions.fetchSelectedCollaborators.name,
      ]);
      const filteredCollaboratorsAreLoading = store.calendarSettings.getters.isLoadingAction([
        store.calendarSettings.actions.fetchCollaboratorsByFilters.name,
      ]);

      const selectProject = (project: Project) => {
        if (selectedProjectsCache.value.includes(project.id)) return;
        selectedProjectsCache.value.push(project.id);
        store.calendarSettings.actions
          .addProjectsToCalendar([project.id])
          .then(() => store.calendarSettings.actions.fetchSelectedProjects())
          .then(() => selectedProjectsCache.value.splice(selectedProjectsCache.value.indexOf(project.id), 1));
      };
      const deselectProject = (project: Project) => {
        if (deselectedProjectsCache.value.includes(project.id)) return;
        deselectedProjectsCache.value.push(project.id);
        store.calendarSettings.actions
          .removeProjectsFromCalendar([project.id])
          .then(() => store.calendarSettings.actions.fetchSelectedProjects())
          .then(() => deselectedProjectsCache.value.splice(deselectedProjectsCache.value.indexOf(project.id), 1));
      };

      const selectCollaborator = (collaborator: Division | Supplier) => {
        let addCollaborator: Promise<AxiosResponse<JsonResource<Supplier | Division>>[]> | null = null;
        if (helpers.isSupplier(collaborator)) {
          addCollaborator = store.calendarSettings.actions.addSuppliersToCalendar([(collaborator as Supplier).id]);
        } else if (helpers.isDivision(collaborator)) {
          addCollaborator = store.calendarSettings.actions.addDivisionsToCalendar([(collaborator as Division).id]);
        }
        if (addCollaborator) {
          addCollaborator.then(() => store.calendarSettings.actions.fetchSelectedCollaborators());
        }
      };

      const deselectCollaborator = (collaborator: Division | Supplier) => {
        let removeCollaborator;
        if (helpers.isSupplier(collaborator)) {
          removeCollaborator = store.calendarSettings.actions.removeSuppliersFromCalendar([
            (collaborator as Supplier).id,
          ]);
        } else if (helpers.isDivision(collaborator)) {
          removeCollaborator = store.calendarSettings.actions.removeDivisionsFromCalendar([
            (collaborator as Division).id,
          ]);
        }
        if (removeCollaborator) {
          removeCollaborator.then(() => store.calendarSettings.actions.fetchSelectedCollaborators());
        }
      };

      const filterProjectNameDebounced = debounce((name: string) => {
        store.calendarSettings.actions.filterByProjectName(name);
      }, 300);
      const filterCollaboratorNameDebounced = debounce((name: string) => {
        store.calendarSettings.actions.filterByCollaboratorName(name);
      }, 300);

      // watch(filteredProjectsNameSearchValue, (name) => {
      //   return filterProjectNameDebounced(name);
      // });
      // watch(filteredCollaboratorsNameSearchValue, (name) => {
      //   return filterCollaboratorNameDebounced(name);
      // });

      // init
      store.calendarSettings.actions.initSettings();
      store.calendarSettings.actions.fetchProjectsByFilters();
      store.calendarSettings.actions.fetchCollaboratorsByFilters();
      store.calendarSettings.actions.fetchSelectedProjects();
      store.calendarSettings.actions.fetchSelectedCollaborators();

      return {
        environment: store.calendar,
        calendarSettings: store.calendarSettings,

        selectProject,
        deselectProject,
        selectCollaborator,
        deselectCollaborator,

        filterProjectNameDebounced,
        filterCollaboratorNameDebounced,

        filteredProjectsNameSearchValue,
        // plannerProjectNameSearchValue,
        filteredCollaboratorsNameSearchValue,
        // plannerCollaboratorsNameSearchValue,
        bothCollaboratorsTablesOpen,
        bothProjectsTablesOpen,

        selectedProjectsAreLoading,
        filteredProjectsAreLoading,
        selectedCollaboratorsAreLoading,
        filteredCollaboratorsAreLoading,
      };
    },
  });
