import { GetterTree } from 'vuex';
import { TaxState } from '@/modules/settings/store/modules/taxes/types/TaxState';
import { RootState } from '@/core/types/RootState';
import { Actions, Mutations, Getters } from '@/modules/settings/store/modules/taxes/types/StoreTypes';
import { Tax } from '@/modules/settings/types/entities';

export const getters: GetterTree<TaxState, RootState> = {
	[Getters.GET_TAXES]: (state: TaxState) => {
		return state.taxes;
	},
	[Getters.GET_TAXES_FOR_SALES]: (state: TaxState) => {
		return state.taxes && state.taxes.length ? state.taxes.filter((t: Tax) => t.forSales) : state.taxes;
	},
	[Getters.GET_TAXES_FOR_PURCHASES]: (state: TaxState) => {
		return state.taxes && state.taxes.length ? state.taxes.filter((t: Tax) => t.forPurchases) : state.taxes;
	},
};
