
import { PropType } from 'vue';
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Actions, Getters } from '@/modules/accounting/store/types/StoreTypes';
import { Actions as TaxesActions, Getters as TaxesGetters } from '@/modules/settings/store/modules/taxes/types/StoreTypes';
import { AxiosError } from 'axios';
import AccountingLedgerItemDetails from '../accounting-ledger-item-details/accounting-ledger-item-details.component.vue'
import { AccountingLedgerItem, AccountingLedgerItemEntity } from '../../types/entities';
import { Tax } from '@/modules/settings/types/entities';

const accounting = namespace('accounting');
const taxes = namespace('settings/taxes');

@Component({
	components: {
		"accounting-ledger-item-details": AccountingLedgerItemDetails,
	}
})
export default class AccountingLedgerItemUpdateModalComponent extends Vue {

	// actions
	@accounting.Action(Actions.SAVE_ACCOUNTING_LEDGER_ITEM_BY_ID) saveAccountingLedgerItem!: (payload: AccountingLedgerItem) => Promise<any>
	@accounting.Action(Actions.CREATE_ACCOUNTING_LEDGER_ITEM) createAccountingLedgerItem!: (payload: AccountingLedgerItem) => void
	@taxes.Action(TaxesActions.FETCH_TAXES) fetchTaxes!: () => Promise<void>

	// getters
	// @accounting.Getter(Getters.GET_ACCOUNTING_LEDGER_ITEM) accountingLedgerItem!: AccountingLedgerItem;
	@accounting.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;
	@taxes.Getter(TaxesGetters.GET_TAXES) taxes!: Tax[];

	// props
	@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
	@Prop({ type: Object as PropType<AccountingLedgerItem> }) accountingLedgerItem!: AccountingLedgerItem

	// local vars
	state: {
		saving: boolean,
		cancelling: boolean,
		modifiedAccountingLedgerItem: boolean,
		showConfirmDelete: boolean,
		showNew: boolean,
	} = {...this.newState() }
	content: {
		accountingLedgerItem: AccountingLedgerItem|null,
		validationErrors: any,
	} = {...this.newContentState() }

	// handlers
	onClickSave(showNew: boolean) {
		this.state = {...this.state, showNew: showNew }
		this.save(this.content.accountingLedgerItem)
	}
	onClickCancel(): void {
		this.closeModal();
	}
	onClickClose(value: any): void {
		this.closeModal();
		this.content = { ...this.newContentState() }
		this.state = { ...this.newState() }
	}

	// methods
	closeModal() {
		this.state.showNew = false
		this.showModalSync = false
	}
	newAccountingLedgerItem() {
		this.createAccountingLedgerItem(new AccountingLedgerItemEntity)
	}
	save(accountingLedgerItem: AccountingLedgerItem|null) {
		if(accountingLedgerItem) {
			this.saveAccountingLedgerItem(accountingLedgerItem).then(() => {
				this.state = {...this.state, saving: false}
				this.content = {...this.content, validationErrors: {}}
				if(!this.state.showNew) this.closeModal()
				else this.newAccountingLedgerItem()
			}).catch((err: AxiosError) => {
				if (err.response && err.response.status === 422) {
					this.content = { ...this.content, validationErrors: err.response.data.errors }
				} else {
					throw err;
				}
			});
		}
	}
	newContentState() {
		return {
			accountingLedgerItem: new AccountingLedgerItemEntity,
			validationErrors: {},
		}
	}
	newState() {
		return {
			saving: false,
			cancelling: false,
			modifiedAccountingLedgerItem: false,
			showConfirmDelete: false,
			showNew: false,
		}
	}

	// watchrs
	@Watch('accountingLedgerItem', { deep: true, immediate: false })
	onWatchAccountingLedgerItem(accountingLedgerItem: any) {
		this.content = {...this.content,
			accountingLedgerItem: {...accountingLedgerItem}
		}
	}

	// boot methods
	created(): void {
		this.fetchTaxes()
	}

	destroyed(): void {
		//
	}
}
