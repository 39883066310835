import {computed, reactive, readonly, toRefs} from 'vue';
import { merge, groupBy } from 'lodash';
import { actions as settingsActions } from './actions/useCalendarSettings';
import { Options } from '@/composables/types/composable';
import { UseCalendarSettingsComposables, UseCalendarSettingsContext, UseCalendarSettingsState } from './types/useCalendarSettings';
import { useDynamicProps } from '@/composables/useDynamicProps';
import {mergeComposables, wrapActions} from '@/composables/utils';
import helpers from '@/modules/calendar/services/helper.service';
import dayjs from 'dayjs';
import { DateRangeAggregate } from '../interfaces/DateRangeAggregate';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseCalendarSettingsState>): UseCalendarSettingsState => {
  return merge<UseCalendarSettingsState, typeof state>(
    {
      plannerSettingsModalOpen: false,

      // environment
      environment: undefined,
      displayWeekendsAndHolidays: false,
      displayEmptyRows: false,
      selectedDateRange: {
        start: undefined,
        end: undefined,
      },
      isDateRangeMenuActive: {
        start: false,
        end: false,
      },

      // settings modal content
      sideBarOpen: true,
      areFilteredProjectCollaboratorsDisplayed: false,
      arePlannerProjectCollaboratorsDisplayed: false,
      filteredDisplayedCollaboratorsProject: undefined,
      plannerDisplayedCollaboratorsProject: undefined,
      // query filters
      filteredProjectsNameFilter: '',
      filteredCollaboratorsNameFilter: '',
      plannerProjectsNameFilter: '',
      plannerCollaboratorsNameFilter: '',
      // selected ids
      selectedProjectStatusesIds: [5,6],
      selectedSupplierTypesIds: [],
      selectedProjectLeadersIds: [],
      selectedClientsIds: [],
      // filters data
      supplierTypes: [],
      projectStatuses: [],
      projectLeaders: [],
      // expansion panels
      plannerProjectsExpansionPanelExpanded: false,
      filteredProjectsExpansionPanelExpanded: false,
      plannerCollaboratorsExpansionPanelExpanded: false,
      filteredCollaboratorsExpansionPanelExpanded: false,
      //
      filteredProjects: [],
      filteredCollaborators: [],
      selectedProjects: [],
      selectedCollaborators: [],

    },
    state
  );
};

/**
 * `UseCalendarSettings`-composable
 *   - All CalendarEnvironment-related interactions and functionality can be stored here.
 *   - If you need a global store, than just use this composable in eg a `store.ts`
 *
 * @param filter
 * @param defaultState
 * @returns
 */
// type ComposableReturn =
export default function useCalendarSettings(options?: Options<UseCalendarSettingsState, UseCalendarSettingsComposables>) {
  const state: UseCalendarSettingsState = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    loadingActions: useDynamicProps<boolean>(),
    silentActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseCalendarSettingsContext = { state, composables };
  const actions = wrapActions(ctx, settingsActions);

  // return getters and actions
  return {
    state: readonly({
      ...ctx.state,
      composables: readonly(ctx.composables),
    }),
    getters: {
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),

      // environment
      environment: computed(() => state.environment),
      displayWeekendsAndHolidays: computed(() => state.displayWeekendsAndHolidays),
      displayEmptyRows: computed(() => state.displayEmptyRows),
      dateRange: computed(() => {
        const environment = state.environment;
        const dateRange: DateRangeAggregate = { dates: [], weeks: {} };
        if(environment) {
          const start = environment.dateRange.start || '';
          const end = environment.dateRange.end || '';
          const displayWeekendsAndHolidays = state.displayWeekendsAndHolidays;
          const holidays = environment.holidays;
          const dateRangeDates = helpers.getDatesArray(start, end, displayWeekendsAndHolidays, holidays);
          const dateRangeWeeks = groupBy(dateRangeDates, (date: string) => {
            const startOfWeek = dayjs.utc(date).startOf('week');
            const week = startOfWeek.week();
            const year = startOfWeek.year();
            return `${ year }-${ week }`;
          });
          dateRange.dates = dateRangeDates;
          dateRange.weeks = dateRangeWeeks;
        }
        return dateRange;
      }),
      isDateRangeMenuActive: computed(() => state.isDateRangeMenuActive),
      selectedDateRange: computed(() => [state.selectedDateRange.start, state.selectedDateRange.end]),
      getHolidaysNames(date: string): string[] {
        const holidayNames: string[] = [];
        const environment = state.environment;
        environment && environment.holidays.forEach((holiday: { name: string; days: string[] }) => {
          if (holiday.days.some((day: string) => day === date)) holidayNames.push(holiday.name);
        });
        return holidayNames;
      },

      // settings
      // - view
      sideBarOpen: computed(() => state.sideBarOpen),
      // - data
      supplierTypes: computed(() => state.supplierTypes),
      projectStatuses: computed(() => state.projectStatuses),
      projectLeaders: computed(() => state.projectLeaders),
      // - selections/filters
      selectedSupplierTypesIds: computed(() => state.selectedSupplierTypesIds),
      selectedProjectStatusesIds: computed(() => state.selectedProjectStatusesIds),
      selectedProjectLeadersIds: computed(() => state.selectedProjectLeadersIds),
      hasProjectFilters: computed(() => state.selectedProjectStatusesIds.length > 0 || state.selectedProjectLeadersIds.length > 0),
      hasCollaboratorFilters: computed(() => !!state.selectedSupplierTypesIds.length),
      filteredProjectsNameFilter: computed(() => state.filteredProjectsNameFilter),
      filteredCollaboratorsNameFilter: computed(() => state.filteredCollaboratorsNameFilter),

      // selected & filtered projects & collaborators
      filteredProjects: computed(() => state.filteredProjects),
      filteredAndNonSelectedProjects: computed(() => state.filteredProjects.filter(p => {
        return !state.selectedProjects.map(p => p.id).includes(p.id);
      })),
      filteredAndNonSelectedCollaborators: computed(() => state.filteredCollaborators.filter(c => {
        return !state.selectedCollaborators.map(p => p.id).includes(c.id);
      })),
      filteredCollaborators: computed(() => state.filteredCollaborators),
      selectedProjects: computed(() => state.selectedProjects),
      selectedCollaborators: computed(() => state.selectedCollaborators),
    },
    actions: {
      ...actions,
    },
  };
}
