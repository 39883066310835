import { InvoiceState } from './types/InvoiceState';
import { SalesInvoice, SalesInvoiceStatus, SalesInvoiceType, SalesInvoiceRecordType, SalesInvoicePaymentStatus } from '@/modules/sales-invoices/types/entities';
import { Filter, FilterState } from '@/core/types/Filter';
import datetimeCalculate from '@/core/helpers/DatetimeCalculator'
import { Supplier, Client } from '@/modules/entities/types/entities';
import { Project } from '@/modules/projects/types/entities';
import { Contact } from '@/modules/contacts/types/entities';

export const state: InvoiceState = {

    // crud
    invoice: null,
    
    // listview
    listviewIsLoading: false,
    listviewInvoices: [] as SalesInvoice[],

    // search
    searchProjects: [] as Project[],

    // reporting
    invoicesSummaryReport: null,
    invoicesShowSummaryReport: false,
    invoicesMetricsReport: null,
    invoicesShowMetricsReport: false,

    // shared data
    statuses: [] as SalesInvoiceStatus[],
    paymentStatuses: [] as SalesInvoicePaymentStatus[],
    types: [] as SalesInvoiceType[],
    subtypes: [] as SalesInvoiceType[],
    recordTypes: [] as SalesInvoiceRecordType[],
    contacts: [] as Contact[],
    projects: [] as Project[],
    clients: [] as Client[],
    suppliers: [] as Supplier[],
    relatedBillingAddresses: [] as SalesInvoice[],
    projectInvoices: [] as SalesInvoice[],
    isLoading: false,
    new: null,
    projectSearchFilters: {
        filters: {
            search: new Filter<string>(
                'search',
                'query',
                [] as string[],
                true,
                'search',
                null
            ),
        },
    } as FilterState,
    projectInvoicesFilters: {
        filters: {
            except: new Filter<number>(
                'except',
                'except',
                [] as number[],
                true,
                'except'
            ),
        },
    } as FilterState,
    clientSearchFilters: {
        filters: {
            search: new Filter<string>(
                'search',
                'name',
                [] as string[],
                true,
                'search',
                null
            ),
            projects: new Filter<string>(
                'projects',
                'projects',
                [] as string[],
                true,
                'projects',
                null
            ),
        }
    } as FilterState,
    supplierSearchFilters: {
        filters: {
            search: new Filter<string>(
                'search',
                'searchQuery',
                [] as string[],
                true,
                'search',
                null
            ),
        },
    } as FilterState,
    contactSearchFilters: {
        filters: {
            search: new Filter<string>(
                'search',
                'search',
                [] as string[],
                true,
                'search'
            ),
            clients: new Filter<number>(
                'clients',
                'clients',
                [] as number[],
                true,
                'clients'
            ),
            suppliers: new Filter<number>(
                'suppliers',
                'suppliers',
                [] as number[],
                true,
                'suppliers'
            ),
            projects: new Filter<number>(
                'projects',
                'projects',
                [] as number[],
                true,
                'projects'
            ),
        }
    } as FilterState,

    // filters listview
    filtersProjectModel: null,
    filters: {
        sideBarOpen: true,
        filters: {
            search: new Filter<string>(
                'search',
                'search',
                [] as string[],
                true,
                'search'
            ),
            project: new Filter<number>(
                'projects',
                'projects',
                [] as number[],
                false,
                'projects'
            ),
            projectName: new Filter<string>(
                'projectName',
                'project_name',
                [] as string[],
                true,
                'projectName'
            ),
            projectLead: new Filter<number>(
                'projectLead',
                'leaders',
                [] as number[],
                true,
                'projectLead'
            ),
            status: new Filter<number[]>(
                'status',
                'statuses',
                [] as number[],
                true,
                'status'
            ),
            paymentStatus: new Filter<number[]>(
                'paymentStatus',
                'payment_statuses',
                [] as number[],
                true,
                'paymentStatus'
            ),
            type: new Filter<number[]>(
                'type',
                'types',
                [] as number[],
                true,
                'type'
            ),
            dueDateFrom: new Filter<string>(
                'dueDateFrom',
                'due_date_from',
                [],
                true,
                'dueDate'
            ),
            dueDateTo: new Filter<string>(
                'dueDateTo',
                'due_date_to',
                [],
                true,
                'dueDate'
            ),
            invoiceDateFrom: new Filter<string>(
                'invoiceDateFrom',
                'invoice_date_from',
                [datetimeCalculate().lastMonthsToDate(3)[0]],
                true,
                'invoiceDate'
            ),
            invoiceDateTo: new Filter<string>(
                'invoiceDateTo',
                'invoice_date_to',
                [datetimeCalculate().lastMonthsToDate(3)[1]],
                true,
                'invoiceDate'
            ),
            vat: new Filter<number[]>(
                'vat',
                'vat',
                [] as number[],
                true,
                'vat'
            )
        },
    } as FilterState,
    relatedBillingAddressesSearchFilters: {
        filters: {
            projects: new Filter<number>(
                'projects',
                'projects',
                [] as number[],
                true,
                'projects'
            ),
        },
    } as FilterState
}