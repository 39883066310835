
	import { Component, Vue } from "vue-property-decorator";

	import { Actions, Getters } from "@/modules/settings/store/modules/divisions/types/StoreTypes";
	import { namespace } from "vuex-class";
	import { Division } from "@/modules/settings/types/entities";

	const division = namespace("settings/divisions");

	@Component
	export default class DivisionsComponent extends Vue {
		@division.Action(Actions.FETCH_DIVISIONS) fetchAllTeams!: () => void;
		@division.Action(Actions.ADD_NEW_DIVISION) addDivision!: (payload: Division) => void;
		@division.Action(Actions.DELETE_DIVISION_BY_ID) deleteDivision!: (id: number) => void;
		@division.Action(Actions.EDIT_DIVISION_BY_ID) editDivision!: (payload: Division) => void;

		@division.Getter(Getters.GET_DIVISIONS) divisions!: Division[];

		dialogState = {
			add: false,
			edit: false,
			delete: false
		};

		dialogContent: { add: Division, edit: Division, delete: Division } = {
			add: {
				id: -1,
				name: ""
			},
			edit: {
				id: -1,
				name: ""
			},
			delete: {
				id: -1,
				name: ""
			}
		};

		created() {
			this.fetchAllTeams();
		}

		openAddDivisionDialog() {
			this.resetDialogs();
			this.dialogState.add = true;
		}

		openEditDialog(division: Division) {
			this.resetDialogs();
			this.dialogContent.edit = Object.assign({}, division);
			this.dialogState.edit = true;
		}

		openDeleteDialog(division: Division) {
			this.resetDialogs();
			this.dialogContent.delete = {
				id: division.id,
				name: division.name
			};
			this.dialogState.delete = true;
		}

		addNewDivision() {
			this.addDivision(this.dialogContent.add);
			this.dialogState.add = false;
			this.resetDialogs();
		}

		onDeleteDivision() {
			if (this.dialogContent.delete.id) {
				this.deleteDivision(this.dialogContent.delete.id);
			}
		}

		onEditDivision() {
			if (this.dialogContent.edit.id) {
				this.editDivision(this.dialogContent.edit);
			}
			this.dialogState.edit = false;
		}

		resetDialogs() {
			this.dialogState = {
				edit: false,
				add: false,
				delete: false
			};

			this.dialogContent = {
				add: {
					id: -1,
					name: ""
				},
				edit: {
					id: -1,
					name: ""
				},
				delete: {
					id: -1,
					name: ""
				}
			};
		}
	}
