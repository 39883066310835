import axios, {AxiosResponse, AxiosPromise} from 'axios';

import {JsonResource} from '@/core/types/Entities';
import {ProjectRelationship} from "@/modules/purchase-invoices/types/entities";

const BASE_ENDPOINT = '/api/v1/project-relationships';
const BASE_COMMANDS_ENDPOINT = '/api/v1/commands/project-relationships';

export class ProjectRelationshipsService {

  getBaseEndpoint(): string {
    return BASE_ENDPOINT;
  }

  getProjectRelationshipById(id: number): Promise<AxiosResponse<JsonResource<ProjectRelationship>>> {
    return axios.get<JsonResource<ProjectRelationship>>(`${BASE_ENDPOINT}/${id}`);
  }

}

export class ProjectRelationshipCommandsService {

  splitProjectRelationship(newProjectRelationship: ProjectRelationship, oldProjectRelationship: ProjectRelationship): AxiosPromise<JsonResource<ProjectRelationship>> {
    return axios.post(`${BASE_COMMANDS_ENDPOINT}/${ oldProjectRelationship.id }/split`, newProjectRelationship);
  }

  duplicateProjectRelationship(projectRelationship: ProjectRelationship): AxiosPromise<JsonResource<ProjectRelationship>> {
    return axios.post(`${BASE_COMMANDS_ENDPOINT}/${ projectRelationship.id }/duplicate`, projectRelationship);
  }

}
