export const enum Actions {
	FETCH_WORKFLOW_TASKS = 'FETCH_WORKFLOW_TASKS',
	FETCH_WORKFLOW_TASK_STATUSES = 'FETCH_WORKFLOW_TASK_STATUSES',
	FETCH_WORKFLOW_FUNCTION_GROUPS = 'FETCH_WORKFLOW_FUNCTION_GROUPS',
	FETCH_HIGHEST_TASK_SEQUENCE = 'FETCH_HIGHEST_TASK_SEQUENCE',
	FETCH_NEXT_PAGE = 'FETCH_NEXT_PAGE',
	EDIT_WORKFLOW_TASK_BY_ID = 'EDIT_WORKFLOW_TASK_BY_ID',
	EDIT_WORKFLOW_FUNCTION_GROUP_BY_ID = 'EDIT_WORKFLOW_FUNCTION_GROUP_BY_ID',
	EDIT_WORKFLOW_TASK_STATUS_BY_ID = 'EDIT_WORKFLOW_TASK_STATUS_BY_ID',
	DELETE_WORKFLOW_TASK_BY_ID = 'DELETE_WORKFLOW_TASK_BY_ID',
	DELETE_MULTI_WORKFLOW_TASK_BY_ID = 'DELETE_MULTI_WORKFLOW_TASK_BY_ID',
	ARCHIVE_WORKFLOW_TASK_BY_ID = 'ARCHIVE_WORKFLOW_TASK_BY_ID',
	ARCHIVE_MULTI_WORKFLOW_TASK_BY_ID = 'ARCHIVE_MULTI_WORKFLOW_TASK_BY_ID',
	UN_ARCHIVE_MULTI_WORKFLOW_TASK_BY_ID = 'UN_ARCHIVE_MULTI_WORKFLOW_TASK_BY_ID',
	DELETE_WORKFLOW_TASK_STATUS_BY_ID = 'DELETE_WORKFLOW_TASK_STATUS_BY_ID',
	DELETE_WORKFLOW_FUNCTION_GROUP_BY_ID = 'DELETE_WORKFLOW_FUNCTION_GROUP_BY_ID',
	CREATE_NEW_WORKFLOW_TASK = 'CREATE_NEW_WORKFLOW_TASK',
	REVERT_LAST_ACTION = 'REVERT_LAST_ACTION',
	CREATE_NEW_WORKFLOW_TASK_STATUS = 'CREATE_NEW_WORKFLOW_TASK_STATUS',
	CREATE_NEW_WORKFLOW_FUNCTION_GROUP = 'CREATE_NEW_WORKFLOW_FUNCTION_GROUP',
	SET_WORKFLOW_FILTERS_SORT_BY_SEQUENCE = 'SET_FILTERS_SORT_BY_SEQUENCE',
	SET_WORKFLOW_FILTERS_SORT_BY_ARCHIVED_AT = 'SET_FILTERS_SORT_BY_ARCHIVED_AT',
	SET_WORKFLOW_FILTERS_FETCH_ARCHIVED = 'SET_FILTERS_FETCH_ARCHIVED',
	CHANGE_WORKFLOW_TASK_SEQUENCE = 'CHANGE_TASK_SEQUENCE'
}

export const enum Getters {
	GET_WORKFLOW_TASKS = 'GET_WORKFLOW_TASKS',
	GET_WORKFLOW_TASK_STATUSES = 'GET_WORKFLOW_TASK_STATUSES',
	GET_WORKFLOW_FUNCTION_GROUPS = 'GET_WORKFLOW_FUNCTION_GROUPS',
	GET_WORKFLOW_INFO_MESSAGE = 'GET_WORKFLOW_INFO_MESSAGE',
	GET_WORKFLOW_ACTIVITY_LOG = 'GET_WORKFLOW_ACTIVITY_LOG',
	GET_WORKFLOW_HIGHEST_TASK_SEQUENCE = 'GET_WORKFLOW_HIGHEST_TASK_SEQUENCE',
	GET_WORKFLOW_FILTERS = 'GET_FILTERS'
}

export const enum Mutations {
	MUTATE_WORKFLOW_TASKS = 'MUTATE_WORKFLOW_TASKS',
	MUTATE_WORKFLOW_TASK_STATUSES = 'MUTATE_WORKFLOW_TASK_STATUSES',
	MUTATE_WORKFLOW_FUNCTION_GROUPS = 'MUTATE_WORKFLOW_FUNCTION_GROUPS',
	MUTATE_WORKFLOW_INFO_MESSAGE = 'MUTATE_WORKFLOW_INFO_MESSAGE',
	MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY = 'MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY',
	MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT = 'MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT',
	MUTATE_WORKFLOW_ACTIVITY_LOG = 'MUTATE_WORKFLOW_ACTIVITY_LOG',
	MUTATE_WORKFLOW_HIGHEST_TASK_SEQUENCE = 'MUTATE_WORKFLOW_HIGHEST_TASK_SEQUENCE',
	MUTATE_PAGINATION_DETAILS = 'MUTATE_PAGINATION_DETAILS',
	MUTATE_PUSH_NEXT_PAGE = 'MUTATE_PUSH_NEXT_PAGE',
	MUTATE_WORKFLOW_FILTERS_SORT_BY_SEQUENCE = 'MUTATE_FILTERS_SORT_BY_SEQUENCE',
	MUTATE_WORKFLOW_FILTERS_SORT_BY_ARCHIVED_AT = 'MUTATE_FILTERS_SORT_BY_ARCHIVED_AT',
	MUTATE_WORKFLOW_FILTERS_FETCH_ARCHIVED = 'MUTATE_FILTERS_FETCH_ARCHIVED',
	MUTATE_WORKFLOW_ACTIVE_LIST_TYPE = 'MUTATE_ACTIVE_LIST_TYPE'
}
