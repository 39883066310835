
  import Vue, { defineComponent, ref } from 'vue';
  import authStore from '@/modules/login/store';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import AppMessageComponent from '@/core/components/app-message/app-message.component.vue';

  export default defineComponent({
    components: {AppMessageComponent},
    setup() {
      const loginUsername = ref('');
      const loginPassword = ref('');

      const sendLogin = () => {
        const user = loginUsername.value;
        const pass = loginPassword.value;

        if (!user || !pass) {
          appMessageStore.actions.warn({
            message: 'Gelieve een gebruikersnaam en paswoord in te geven'
          });
        } else {
          authStore.auth.actions.login(user,pass).then(() => {
              Vue.router.push({ name: 'home' })
          }).catch(() => null)
        }
      };

      return {
        loginUsername,
        loginPassword,

        sendLogin,

        authStore
      };
    },
  });
