
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { AxiosError, AxiosPromise } from 'axios';
  import authStore from '@/modules/login/store'

  import {
    City,
    Comment,
    CompanyType,
    RawDataEntity,
    Retainment,
    RetainmentEntity,
    Supplier,
    SupplierType,
    TeleinformationDetail,
    User,
  } from '@/modules/entities/types/entities';
  import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
  import DeletePopup from '@/core/components/delete-popup/delete-popup.component.vue';
  import { Actions, Getters } from '@/modules/entities/store/modules/suppliers/types/StoreTypes';
  import DetailsTextFieldComponent from '@/core/components/details-page/details-text-field.component.vue';
  import DetailsDatePickerComponent from '@/core/components/details-page/details-date-picker.component.vue';
  import GmapPickerComponent from '@/core/components/details-page/gmap-picker.component.vue';
  import SupplierBranchComponent from '../../components/suppliers/supplier-branch.component.vue';
  import { ProvinceEntity } from '@/modules/entities/types/province.entity';
  import DetailsMultiSelectComponent from '@/core/components/details-page/details-multi-select.component.vue';
  import DetailsSingleSelectComponent from '@/core/components/details-page/details-single-select.component.vue';
  import { Actions as ContactActions } from '@/modules/contacts/store/types/StoreTypes';
  import { OneToFiveMixin } from '@/mixins/one-to-five-mixin.conponent';
  import ContactsListComponent from '@/modules/contacts/components/contacts-list/contacts-list.component.vue';
  import { GetAddressMixin } from '@/mixins/get-address-mixin.component';
  import { JsonResource } from '@/core/types/Entities';
  import DetailsPageSupplierBranchListComponent from '@/modules/entities/components/suppliers/details-page-supplier-branch-list.component.vue';
  import { Contact } from '@/modules/contacts/types/entities';
  import DetailsExpansionPanelComponent from '@/core/components/details-page/details-expansion-panel.component.vue';
  import RetainmentsListComponent from '@/modules/entities/components/suppliers/supplier-retainments-list.component.vue';
  import { UsersService } from '@/services/users.service';
  import RetainmentEditPopupComponent from '../../components/suppliers/retainment-edit-popup.component.vue';
  import { cloneDeep } from 'lodash';
  import RetainmentDeletePopup from '../../components/suppliers/retainment-delete-popup.component.vue';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import { AppMessageType } from '@/core/components/app-message/entities';

  const suppliers = namespace('entities/suppliers');
  const contacts = namespace('contacts');

  const userService = new UsersService();

  declare let google: any;

  @Component({
    components: {
      'confirm-dialog': () =>
        import(/* webpackChunkName: "confirmDialog" */ '@/core/components/confirm-dialog/confirm-dialog.component.vue'),
      'project-date-select': () =>
        import(
          /* webpackChunkName: "projectDateSelect" */ '@/modules/projects/components/project-date-select/project-date-select.component.vue'
        ),
      'contacts-list': ContactsListComponent,
      'details-branch-list': DetailsPageSupplierBranchListComponent,
      DeletePopup,
      DetailsTextFieldComponent,
      DetailsDatePickerComponent,
      DetailsMultiSelectComponent,
      DetailsSingleSelectComponent,
      GmapPickerComponent,
      SupplierBranchComponent,
      'supplier-teleinformations': () =>
        import(
          /* webpackChunkName: "supplierTeleinformations" */ '@/modules/entities/components/suppliers/supplier-teleinformations.component.vue'
        ),
      'supplier-bank-accounts': () =>
        import(
          /* webpackChunkName: "supplierBankAccounts" */ '@/modules/entities/components/suppliers/supplier-bank-accounts.component.vue'
        ),
      'supplier-payments': () =>
        import(
          /* webpackChunkName: "supplierPayments" */ '@/modules/entities/components/suppliers/supplier-payments.component.vue'
        ),
      'details-expansion-panel': DetailsExpansionPanelComponent,
      'retainments-list': RetainmentsListComponent,
      'retainment-create-popup': RetainmentEditPopupComponent,
      'retainment-edit-popup': RetainmentEditPopupComponent,
      'retainment-delete-popup': RetainmentDeletePopup,
    },
    mixins: [OneToFiveMixin, GetAddressMixin],
  })
  export default class SupplierDetailsComponent extends Vue {
    @suppliers.Action(Actions.FETCH_SUPPLIER_BY_ID) fetchSupplierById!: (id: string) => Promise<void>;
    @suppliers.Action(Actions.FETCH_COMPANY_TYPES) fetchCompanyTypes!: () => Promise<void>;
    @suppliers.Action(Actions.FETCH_PROVINCE_LIST) fetchProvinceList!: () => Promise<ProvinceEntity[]>;
    @suppliers.Action(Actions.DESTROY_SUPPLIER_DETAILS) destroySupplierDetails!: () => void;
    @suppliers.Action(Actions.EDIT_INSPECTED_SUPPLIER_BY_ID) editSupplier!: (
      payload: Supplier
    ) => Promise<AxiosPromise<JsonResource<Supplier>>>;
    @suppliers.Action(Actions.TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_SUPPLIER)
    toggleAssociationExistingContactToSupplier!: (payload: { supplierId: number; contactId: number }) => Promise<void>;
    @suppliers.Action(Actions.ATTACH_CREATED_CONTACT_TO_SUPPLIER) attachCreatedContactToSupplier!: (payload: {
      supplierId: number;
      contact: Contact;
    }) => void;
    @suppliers.Action(Actions.DELETE_INSPECTED_SUPPLIER_BY_ID) deleteSupplier!: (payload: number) => void;
    @suppliers.Action(Actions.FETCH_CONTRACTOR_TYPES) fetchContractorTypes!: () => Promise<void>;
    @suppliers.Action(Actions.EDIT_SUPPLIER_CONTACTS) editContact!: (payload: RawDataEntity) => void;
    @suppliers.Action(Actions.FETCH_SUPPLIER_TYPES) fetchSupplierTypes!: () => void;
    @suppliers.Action(Actions.SAVE_CITY) saveCity!: (payload: City) => void;
    @suppliers.Action(Actions.FILTER_SUPPLIER_CONTACTS) filterSupplierContacts!: (contactId: number) => Promise<void>;
    @suppliers.Action(Actions.SAVE_COMMENT) saveCommentToSupplier!: (payload: Comment) => void;
    @suppliers.Action(Actions.EDIT_COMMENT) editComment!: (payload: Comment) => Promise<void>;
    @suppliers.Action(Actions.SAVE_RETAINMENT) saveRetainmentToSupplier!: (payload: {
      supplierId: string;
      retainment: Retainment;
    }) => Promise<JsonResource<Retainment>>;
    @suppliers.Action(Actions.DELETE_RETAINMENT) deleteRetainment!: (payload: {
      retainmentId: string;
    }) => Promise<JsonResource<void>>;
    @suppliers.Action(Actions.DELETE_COMMENT) deleteComment!: (payload: number) => void;
    @suppliers.Action(Actions.DELETE_TELEINFORMATION_BY_ID) deleteTeleinformationById!: (payload: {
      supplierId: string;
      teleinformationId: string;
    }) => void;
    @contacts.Action(ContactActions.DELETE_CONTACT_BY_ID) deleteContactById!: (contactId: number) => Promise<void>;

    @suppliers.Getter(Getters.GET_SUPPLIER_DETAILS) supplier!: Supplier;
    @suppliers.Getter(Getters.GET_CONTRACTOR_TYPES) contractorTypes!: ContractorBranch[];
    @suppliers.Getter(Getters.GET_SUPPLIER_TYPES) supplierTypes!: SupplierType[];
    @suppliers.Getter(Getters.GET_PROVINCE_LIST) provinces!: ProvinceEntity[];
    @suppliers.Getter(Getters.GET_COMPANY_TYPES) companyTypes!: CompanyType[];
    @suppliers.Getter(Getters.GET_LOADING) suppliersLoading!: boolean;

    supplierDetailsDialogState = {
      editWebsite: false,
      editName: false,
      editCommercialName: false,
      editVat: false,
      editSsr: false,
      editActivities: false,
      editFutureWork: false,
      editFutureWorkComment: false,
      editQuality: false,
      editQualityComment: false,
      editPrice: false,
      editPriceComment: false,
      editProvince: false,
      editContractorTypes: false,
      editCompanyType: false,
      editCreatedAtDate: false,
      editCompanyTypes: false,
      editAddress: false,
      add_contact: false,
      create_contact: false,
      remove_contact: false,
      delete: false,
      multi_select_popup: false,
      remove_project: false,
      show_map: false,
      showCompanyLocations: true,
      showSupplierBranchModal: false,
      editPaymentDueDays: false,
      editDiscountedPaymentWithin: false,
      editDiscountedPaymentPercentage: false,
      addComment: false,
      editComment: false,
      deleteComment: false,
      addRetainment: false,
      editRetainment: false,
      deleteRetainment: false,
    };
    editedSupplier: Supplier | null = null;
    validationErrors: any = null;
    autocomplete!: google.maps.places.Autocomplete;
    citiesAutocomplete!: google.maps.places.Autocomplete;
    postalAutocomplete!: google.maps.places.Autocomplete;

    supplierDetailsDialogContent: {
      beforeEdit: Supplier;
      edit: Supplier;
      delete: Supplier;
      before_gmap: string;
      sfinx_hq_coordinates: { lat: number; lng: number };
      location: any;
      city: string;
      currentlyEditedTeleinformationIndex: number;
      currentlyEditedTeleinformationType: string;
      addComment: string;
      comment: Comment;
      currentUser: User | null;
      editRetainment: Retainment | null;
      deleteRetainment: Retainment | null;
    } = {
      beforeEdit: {} as Supplier,
      edit: {} as Supplier,
      delete: {} as Supplier,
      before_gmap: '',
      sfinx_hq_coordinates: Vue.prototype.$sfinxHqGeoCoordinates,
      location: undefined,
      city: '',
      currentlyEditedTeleinformationIndex: -1,
      currentlyEditedTeleinformationType: 'none',
      addComment: '',
      comment: {
        id: 0,
        body: '',
        commentableId: 0,
        commentableType: '',
      },
      currentUser: null,
      editRetainment: null,
      deleteRetainment: null,
    };

    onMultiSelectPopupSave(type: string, items: any) {
      switch (type) {
        case 'supplierTypes':
          this.supplierDetailsDialogContent.edit.companyTypes = items;
          return;
        case 'contractorTypes':
          this.supplierDetailsDialogContent.edit.contractorTypes = items;
          return;
      }
    }

    created() {
      this.fetch();
      this.fetchContractorTypes().then(() => {});
      this.fetchSupplierTypes();
      this.fetchProvinceList();
      this.fetchCompanyTypes();
      this.supplierDetailsDialogContent.currentUser = authStore.auth.getters.user.value || null;
    }

    fetch(supplierId?: string) {
      this.fetchSupplierById(supplierId ? supplierId : this.$route.params.id)
        .then(() => {
          this.onSupplierChange(this.supplier);
          this.initAutocomplete();
        })
        .catch((err: AxiosError) => {
          if (err.response && err.response.status === 403) this.$router.go(-1);
        });
    }

    onOpenAddContactModal() {
      this.resetSupplierDetailsDialogContent();
      this.supplierDetailsDialogState.add_contact = true;
    }

    onOpenDeleteSupplierDialog(supplier: Supplier) {
      this.resetSupplierDetailsDialogContent();
      this.supplierDetailsDialogState.delete = true;
      this.supplierDetailsDialogContent.delete = supplier;
    }

    onAttachContact(contactId: number) {
      if (this.supplier.contacts.length && this.supplier.contacts.some((x) => x.id === contactId)) {
        appMessageStore.actions.set({
          message: 'common.messages.contactAlreadyAttached',
          ttl: 2500,
          dismissed: false,
          type: AppMessageType.WARNING,
        });
      } else {
        this.toggleAssociationExistingContactToSupplier({
          supplierId: this.supplier.id,
          contactId: contactId,
        }).then(() => (this.supplierDetailsDialogState.add_contact = false));
      }
    }

    onAddComment($event: any) {
      this.supplierDetailsDialogState.addComment = true;
      this.supplierDetailsDialogContent.addComment = '';
    }

    saveComment(): void {
      if (this.supplierDetailsDialogContent.comment.id > 0) {
        this.editComment(this.supplierDetailsDialogContent.comment).then(
          () => (this.supplierDetailsDialogState.editComment = false)
        );
      }
    }

    onDeleteComment(comment: Comment): void {
      this.resetSupplierDetailsDialogContent();
      this.supplierDetailsDialogContent.comment = comment;
      this.supplierDetailsDialogState.deleteComment = true;
    }

    onEditComment(comment: Comment): void {
      this.resetSupplierDetailsDialogContent();
      this.supplierDetailsDialogContent.comment = Object.assign({}, comment);
      this.supplierDetailsDialogState.editComment = true;
    }

    onNewCommentSave() {
      if (this.supplierDetailsDialogContent.addComment !== '') {
        const comment: Comment = {
          id: 0,
          body: this.supplierDetailsDialogContent.addComment,
          commentableId: this.supplier.id!,
          commentableType: 'App\\Models\\Common\\Supplier',
        };
        this.saveCommentToSupplier(comment);
        this.supplierDetailsDialogState.addComment = false;
      }
    }

    displayCommentsConfirmDialog(comment: Comment): boolean {
      return (
        this.supplierDetailsDialogState.deleteComment && this.supplierDetailsDialogContent.comment.id === comment.id
      );
    }

    onDeleteSupplier() {
      if (this.supplierDetailsDialogContent.delete.id !== 0) {
        this.deleteSupplier(this.supplierDetailsDialogContent.delete.id);
      }
    }

    onRemoveContact(contactId: number) {
      if (contactId > 0) {
        this.toggleAssociationExistingContactToSupplier({
          supplierId: this.supplier.id,
          contactId,
        });
      }
    }

    onDeleteContact(contactId: number) {
      if (contactId > 0) {
        this.deleteContactById(contactId).then(() => {
          this.filterSupplierContacts(contactId).catch(() => {
            appMessageStore.actions.set({
              message: 'suppliers.messages.deleteContactError',
              ttl: 7500,
              dismissed: false,
              type: AppMessageType.ERROR,
            });
          });
        });
      }
    }

    onEditSupplierDetails(supplier?: Supplier): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        this.editSupplier(supplier || this.supplierDetailsDialogContent.edit)
          .then(() => {
            resolve();
          })
          .catch((err: AxiosError) => {
            if (err.response && err.response.status === 422) {
              this.validationErrors = err.response.data.errors;
              reject();
            } else {
              reject();
              throw err;
            }
          });
      });
    }

    onClickDeleteRetainment(retainment: Retainment) {
      this.supplierDetailsDialogContent.deleteRetainment = cloneDeep(retainment);
      this.supplierDetailsDialogState.deleteRetainment = true;
    }
    onClickEditRetainment(retainment: Retainment) {
      this.supplierDetailsDialogContent.editRetainment = cloneDeep(retainment);
      this.supplierDetailsDialogState.editRetainment = true;
    }
    onClickAddRetainment() {
      this.supplierDetailsDialogState.addRetainment = !this.supplierDetailsDialogState.addRetainment;
      this.supplierDetailsDialogContent.editRetainment = new RetainmentEntity();
    }
    onClickCheckRetainment() {
      // const result = await fetch(`https://services.socialsecurity.be/REST/coBackend/v1/retainment/0880940142`)
      // const result = await Vue.prototype.$http.get(`https://services.socialsecurity.be/REST/coBackend/v1/retainment/0880940142`)
      // const suppliersService = new SuppliersService()
      // const vat = this.supplier.vatNumber.replace(/[^0-9]/g, "");
      // const result = await suppliersService.fetchSupplierRetainment(vat)
      // console.log(result.json())
    }
    onClickSaveRetainment(retainment: Retainment, supplier: Supplier) {
      this.saveRetainmentToSupplier({
        supplierId: supplier.id.toString(),
        retainment: { ...retainment, supplier: supplier },
      }).then((v) => {
        this.supplierDetailsDialogState.addRetainment = false;
        this.supplierDetailsDialogState.editRetainment = false;
        this.supplierDetailsDialogContent.editRetainment = new RetainmentEntity();
      });
    }
    onClickConfirmDeleteRetainment(retainment: Retainment) {
      if (retainment.id) {
        this.deleteRetainment({ retainmentId: retainment.id.toString() });
      }
    }

    onDeleteTeleinformation(teleinformation: TeleinformationDetail) {
      this.deleteTeleinformationById({
        supplierId: this.supplier.id.toString(),
        teleinformationId: teleinformation.id.toString(),
      });
    }

    editSupplierThenMutateState(booleanReference: string): void {
      this.onEditSupplierDetails().then(() => {
        switch (booleanReference) {
          case 'commercialName':
            this.supplierDetailsDialogState.editCommercialName = false;
            break;
          case 'name':
            this.supplierDetailsDialogState.editName = false;
            break;
          case 'website':
            this.supplierDetailsDialogState.editWebsite = false;
            break;
          case 'vat':
            this.supplierDetailsDialogState.editVat = false;
            break;
          case 'ssr':
            this.supplierDetailsDialogState.editSsr = false;
            break;
          case 'activities':
            this.supplierDetailsDialogState.editActivities = false;
            break;
          case 'futureWork':
            this.supplierDetailsDialogState.editFutureWork = false;
            break;
          case 'futureWorkComment':
            this.supplierDetailsDialogState.editFutureWorkComment = false;
            break;
          case 'quality':
            this.supplierDetailsDialogState.editQuality = false;
            break;
          case 'qualityComment':
            this.supplierDetailsDialogState.editQualityComment = false;
            break;
          case 'price':
            this.supplierDetailsDialogState.editPrice = false;
            break;
          case 'priceComment':
            this.supplierDetailsDialogState.editPriceComment = false;
            break;
          case 'province':
            this.supplierDetailsDialogState.editProvince = false;
            break;
          case 'contractorTypes':
            this.supplierDetailsDialogState.editContractorTypes = false;
            break;
          case 'companyType':
            this.supplierDetailsDialogState.editCompanyType = false;
            break;
          case 'createdAt':
            this.supplierDetailsDialogState.editCreatedAtDate = false;
            break;
          case 'companyTypes':
            this.supplierDetailsDialogState.editCompanyTypes = false;
            break;
          case 'teleinformation':
            this.supplierDetailsDialogContent.currentlyEditedTeleinformationIndex = -1;
            this.supplierDetailsDialogContent.currentlyEditedTeleinformationType = 'none';
            break;
          case 'address':
            this.supplierDetailsDialogState.editAddress = false;
            break;
          case 'paymentDueDays':
            this.supplierDetailsDialogState.editPaymentDueDays = false;
            break;
          case 'discountedPaymentWithin':
            this.supplierDetailsDialogState.editDiscountedPaymentWithin = false;
            break;
          case 'discountedPaymentPercentage':
            this.supplierDetailsDialogState.editDiscountedPaymentPercentage = false;
            break;
          default:
            break;
        }
      });
    }

    translateCompanyType(item: CompanyType) {
      return this.$i18n.t(`${item.name}`);
    }

    translateProvince(item: ProvinceEntity) {
      return this.$i18n.t(`suppliers.details.${item.name}`);
    }

    initAutocomplete(): void {
      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.streetInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['geocode'] }
      );
      this.citiesAutocomplete = new google.maps.places.Autocomplete(
        (this.$refs.cityInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['(cities)'] }
      );

      this.postalAutocomplete = new google.maps.places.Autocomplete(
        (this.$refs.postalInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['(regions)'] }
      );

      this.autocomplete.setFields(['address_component']);
      this.citiesAutocomplete.setFields(['address_component']);
      this.postalAutocomplete.setFields(['address_component']);

      this.autocomplete.addListener('place_changed', this.fillInAddress);
      this.citiesAutocomplete.addListener('place_changed', this.fillInCity);
      this.postalAutocomplete.addListener('place_changed', this.fillInPostalCode);

      (this.$refs.streetInput as Vue).$el.addEventListener('focus', this.attachAutocompleteResultsToContainer, true);
      (this.$refs.cityInput as Vue).$el.addEventListener('focus', this.attachAutocompleteResultsToContainer, true);
      (this.$refs.postalInput as Vue).$el.addEventListener('focus', this.attachAutocompleteResultsToContainer, true);
    }

    attachAutocompleteResultsToContainer(): void {
      const pacContainer = document.getElementsByClassName('pac-container');
      Array.from(pacContainer).forEach((x: Element) => {
        (this.$refs['google-autocomplete-container'] as HTMLElement).appendChild(x);
      });
    }

    fillInAddress(): void {
      const place = this.autocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];

        switch (addressType) {
          case 'route':
            this.supplierDetailsDialogContent.edit.street = component.long_name;
            break;
          case 'postal_code':
            if (!this.supplierDetailsDialogContent.edit.postalCode)
              this.supplierDetailsDialogContent.edit.postalCode = component.short_name;
            break;
          case 'locality':
            if (!this.supplierDetailsDialogContent.edit.city)
              this.supplierDetailsDialogContent.edit.city = component.long_name;
            break;
          case 'street_number':
            this.supplierDetailsDialogContent.edit.streetNumber = component.short_name;
            break;
          default:
            break;
        }
      }
    }

    fillInCity(): void {
      const place = this.citiesAutocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];
        if (addressType === 'locality') {
          this.supplierDetailsDialogContent.edit.city = component.long_name;
        }
      }
    }

    fillInPostalCode(): void {
      const place = this.postalAutocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];
        if (addressType === 'postal_code') {
          this.supplierDetailsDialogContent.edit.postalCode = component.short_name;
        }
      }
    }

    onNavigateBack() {
      window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/suppliers');
    }

    resetSupplierDetailsDialogContent() {
      this.supplierDetailsDialogContent = {
        beforeEdit: this.supplierDetailsDialogContent.beforeEdit,
        edit: this.supplierDetailsDialogContent.edit,
        delete: {
          id: 0,
          name: '',
          commercialName: '',
          vatNumber: '',
          ssrNumber: '',
          bankAccounts: [],
          price: 0,
          priceComment: '',
          province: {
            id: 0,
            name: '',
          },
          parentSupplierId: 0,
          quality: 0,
          qualityComment: '',
          technicalExpertise: 0,
          aestheticQuality: 0,
          aestheticQualityComment: '',
          activities: '',
          speciality: '',
          comment: '',
          street: '',
          streetNumber: '',
          postalCode: '',
          city: '',
          outboundKm: 0.0,
          preferredPaymentTerm: 0,
          preferredPaymentTermBoolean: false,
          preferredPaymentDiscountTerm: 0,
          preferredPaymentDiscountTermBoolean: false,
          preferredPaymentDiscountPercentage: 0,
          futureWork: 0,
          futureWorkComment: '',
          workforce: '',
          contacts: [],
          companyTypes: [],
          contractorTypes: [],
          teleinformations: [],
          website: '',
          createdAt: '',
          updatedAt: '',
          retainments: [],
        },
        before_gmap: '',
        sfinx_hq_coordinates: this.supplierDetailsDialogContent.sfinx_hq_coordinates,
        location: undefined,
        city: '',
        currentlyEditedTeleinformationIndex: -1,
        currentlyEditedTeleinformationType: 'none',
        addComment: '',
        comment: {
          id: 0,
          body: '',
          commentableId: 0,
          commentableType: '',
        },
        currentUser: this.supplierDetailsDialogContent.currentUser,
        editRetainment: null,
        deleteRetainment: null,
      };
    }

    destroyed() {
      this.destroySupplierDetails();
    }

    toggleSupplierBranchModal(supplier?: Supplier): void {
      this.editedSupplier = supplier ? supplier : null;
      this.supplierDetailsDialogState.showSupplierBranchModal = !this.supplierDetailsDialogState
        .showSupplierBranchModal;
    }

    onSuccessSupplierBranchModal(): void {
      this.toggleSupplierBranchModal();
    }

    onAddTeleinformation(): void {
      const supplier = JSON.parse(JSON.stringify(this.supplierDetailsDialogContent.edit));
      supplier.teleinformations.push({
        id: 0,
        landlinePhone: '',
        landlinePhoneComment: '',
        mobilePhone: '',
        mobilePhoneComment: '',
        email: '',
        emailComment: '',
        isMainContactInformation: !this.supplierDetailsDialogContent.edit.teleinformations.length,
      });
      this.onEditSupplierDetails(supplier);
    }

    openSupplierBranchDetails(supplier: Supplier): void {
      this.$router.push({ name: 'supplierDetails', params: { id: String(supplier.id) } });
      // this.fetch(supplier.id.toString());
    }

    @Watch('supplier')
    onSupplierChange(newVal: Supplier) {
      this.supplierDetailsDialogContent.edit = JSON.parse(JSON.stringify(newVal));
    }

    @Watch('supplierDetailsDialogContent.edit.companyTypes')
    onSupplierDetailsDialogContentEditCompanyTypesChange(): void {
      if (this.validationErrors && this.validationErrors.companyTypes) this.validationErrors.companyTypes = null;
    }

    @Watch('supplierDetailsDialogContent.edit.province')
    onSupplierDetailsDialogContentEditProvinceChange(): void {
      if (this.validationErrors && this.validationErrors.province) this.validationErrors.province = null;
    }

    @Watch('supplierDetailsDialogState.editWebsite')
    @Watch('supplierDetailsDialogState.editName')
    @Watch('supplierDetailsDialogState.editCommercialName')
    @Watch('supplierDetailsDialogState.editVat')
    @Watch('supplierDetailsDialogState.editSsr')
    @Watch('supplierDetailsDialogState.editActivities')
    @Watch('supplierDetailsDialogState.editFutureWork')
    @Watch('supplierDetailsDialogState.editQuality')
    @Watch('supplierDetailsDialogState.editPrice')
    @Watch('supplierDetailsDialogState.editProvince')
    @Watch('supplierDetailsDialogState.editContractorTypes')
    @Watch('supplierDetailsDialogState.editCompanyType')
    @Watch('supplierDetailsDialogState.editCreatedAtDate')
    @Watch('supplierDetailsDialogState.editCompanyTypes')
    @Watch('supplierDetailsDialogState.editAddress')
    @Watch('supplierDetailsDialogState.editPaymentDueDays')
    @Watch('supplierDetailsDialogState.editDiscountedPaymentWithin')
    @Watch('supplierDetailsDialogState.editDiscountedPaymentPercentage')
    onEditStateBooleansChange(newVal: boolean): void {
      if (!newVal) this.onSupplierChange(this.supplier);
    }

    @Watch('supplierDetailsDialogContent.currentlyEditedTeleinformationIndex')
    onTeleinformationsIndexChange(newVal: number): void {
      if (newVal === -1) this.supplierDetailsDialogContent.edit = JSON.parse(JSON.stringify(this.supplier));
    }
  }
