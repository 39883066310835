
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Project } from '@/modules/projects/types/entities'

@Component
export default class ClientProjectsListComponent extends Vue {
  // vuex actions
  // vuex getters

  // props
  @Prop({ type: Array as PropType<Project[]>, default: () => [], required: true }) projects!: Project[]

  // local/children state
  state: {} = {}

  // handlers

  // methods

  // getters
  get projectsHeaders() {
    return [
      {
        text: this.$t('contacts.details.projects.projectName').toString(),
        value: 'projectName',
        align: 'start column-name--width',
        sortable: true,
        groupable: false,
        class: 'text-no-wrap',
      },
      {
        text: '',
        value: 'actions',
        align: 'center',
      },
    ]
  }

  // setters

  // watchers

  // lifecycle
}
