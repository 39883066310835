import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import { ProgressSnapshotItem } from '@/modules/projects/types/entities';

const BASE_ENDPOINT = '/api/v1/progress-snapshots';

export class ProgressSnapshotsService {

	createProgressSnapshotItem(progressSnapshotId: number, item: ProgressSnapshotItem): Promise<AxiosResponse<JsonResource<ProgressSnapshotItem>>> {
		return axios.post<JsonResource<ProgressSnapshotItem>>(`${ BASE_ENDPOINT }/${ progressSnapshotId }/items`, item);
	}

	updateProgressSnapshotItemById(progressSnapshotId: number, item: ProgressSnapshotItem): Promise<AxiosResponse<JsonResource<ProgressSnapshotItem>>> {
		return axios.put<JsonResource<ProgressSnapshotItem>>(`${ BASE_ENDPOINT }/${ progressSnapshotId }/items/${ item.id }`, item);
	}

	deleteProgressSnapshotItemById(progressSnapshotId: number, progressSnapshotItemId: number): AxiosPromise<void> {
		return axios.delete(`${ BASE_ENDPOINT }/${ progressSnapshotId }/items/${ progressSnapshotItemId }`);
	}

}
