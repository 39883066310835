import { render, staticRenderFns } from "./social-security-registration-list-group.component.vue?vue&type=template&id=2f00fc09&scoped=true"
import script from "./social-security-registration-list-group.component.vue?vue&type=script&lang=ts"
export * from "./social-security-registration-list-group.component.vue?vue&type=script&lang=ts"
import style0 from "./social-security-registration-list-group.component.vue?vue&type=style&index=0&id=2f00fc09&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f00fc09",
  null
  
)

export default component.exports