
import { AxiosResponse } from 'axios';
import axios from 'axios';

const BASE_ENDPOINT = '/api/v1/retainments';

export class RetainmentsService {

	getBaseEndpoint(): string {
		return BASE_ENDPOINT
	}

	deleteRetainmentById(retainmentId: number): Promise<AxiosResponse<void>> {
		return axios.delete(`${BASE_ENDPOINT}/${retainmentId}`);
	}

}
