
	import { Component, Vue } from "vue-property-decorator";
	import { namespace } from "vuex-class";

	import { Actions, Getters } from "@/modules/settings/store/modules/activity-log/types/StoreTypes";
	import { LogItem } from "@/modules/settings/types/entities";

	const activityLog = namespace("settings/activity-log");

	@Component
	export default class ActivityLogComponent extends Vue {
		@activityLog.Action(Actions.FETCH_ACTIVITY_LOG) fetchActivityLog!: () => void;

		@activityLog.Getter(Getters.GET_ACTIVITY_LOG) activityLog!: LogItem[];

		headers = [
			{
				text: "ID",
				align: "left",
				sortable: false,
				value: "id"
			},
			{
				text: "Log name",
				value: "logName"
			},
			{
				text: "Description",
				value: "description"
			},
			{
				text: "Causer ID",
				value: "causerId"
			},
			{
				text: "Causer Type",
				value: "causerType"
			},
			{
				text: "Subject ID",
				value: "subjectId"
			},
			{
				text: "Subject Type",
				value: "subjectType"
			},
			{}
		];

		created() {
			this.fetchActivityLog();
		}

		filterLogAttributes(logItem: LogItem, propertyName: string) {
			switch (logItem.description) {
				case "updated":
					if (propertyName === "old") {
						return "old";
					}
					return "new";
				case "created":
					return "created";
				case "deleted":
					return "deleted";
			}
		}
	}
