
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import {
      ProjectRelationship,
      PurchaseInvoice,
      PurchaseInvoiceRecordType,
      TransactionType
    } from '@/modules/purchase-invoices/types/entities';
    import { DataTableHeader } from 'vuetify';
    import { signAmountProjectRelationship, signAmount as signAmountPurchaseInvoice } from '@/modules/purchase-invoices/helpers'
    import { Project } from '../../types/entities';

    @Component
    export default class ProjectPurchaseInvoicesListComponent extends Vue {

        // vuex actions

        // vuex getters

        // props
        @Prop({type: Array as PropType<PurchaseInvoice[]>, default: () => [], required: true}) purchaseInvoices!: PurchaseInvoice[];
        @Prop({type: Object as PropType<Project>, required: true}) project!: Project;

        // local/children state
        state: {
            isGrouped: boolean,
            itemsPerPage: number,
        } = {
            isGrouped: false,
            itemsPerPage: 15,
        }
        content: {} = {}

        // handlers
        onClickGroupRemove() {
            this.state = { ...this.state, isGrouped: false }
        }
        onClickGroupBy() {
            this.state = { ...this.state, isGrouped: true }
        }
        onUpdateItemsPerPage(itemsPerPage: number) {
            if(!this.state.isGrouped) {
                this.state = { ...this.state, itemsPerPage: itemsPerPage }
            }
        }

        // methods
        signAmount(amount: number, type: TransactionType) {
            return signAmountProjectRelationship(amount, type);
		}
        signAmountPurchaseInvoice(amount: number, recordType: PurchaseInvoiceRecordType) {
          return signAmountPurchaseInvoice(amount, recordType)
        }

        // getters
        get purchaseInvoiceProjectRelationships() {
            let projectRelationships = [] as ProjectRelationship[]
            const processed = [] as number[]
            this.purchaseInvoices.forEach((purchaseInvoice: PurchaseInvoice) => { 
                let piProjectRelationships = purchaseInvoice.projectRelationships && purchaseInvoice.projectRelationships.length ? purchaseInvoice.projectRelationships : []
                if(purchaseInvoice.id && !processed.includes(purchaseInvoice.id)) {
                    const destructuredPurchaseInvoice = (({ id, name, total, supplier, externalName, recordType }) => ({ id, name, total, supplier, externalName, recordType }) as PurchaseInvoice )({...purchaseInvoice});
                    piProjectRelationships = piProjectRelationships.filter(pr => pr.projectId === this.project.id).map(pr => { pr.purchaseInvoice = destructuredPurchaseInvoice; return pr; })
                    projectRelationships.push(...piProjectRelationships)
                    processed.push(purchaseInvoice.id)
                }
            })
            return projectRelationships
        }
        get projectRelationshipHeaders() {
            return [
                {
                    text: this.$t('projects.details.purchaseInvoices.name').toString(),
                    value: 'purchaseInvoice.name',
                    align: 'start',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.purchaseInvoices.projectRelationshipTotal').toString(),
                    value: 'total',
                    align: 'end',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.purchaseInvoices.contractorType').toString(),
                    value: 'contractorType.id',
                    align: 'start',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.purchaseInvoices.purchaseInvoiceTotal').toString(),
                    value: 'purchaseInvoice.total',
                    align: 'end',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.purchaseInvoices.supplier').toString(),
                    value: 'purchaseInvoice.supplier.id',
                    align: 'start',
                    sortable: false,
                    groupable: true,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.purchaseInvoices.externalName').toString(),
                    value: 'purchaseInvoice.externalName',
                    align: 'start',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
            ];
        }
        get projectRelationshipsSummarizers() {
            return [
                {
                    value:'total',
                    summarize: (items: ProjectRelationship[] = []) => { 
                        let totals = [];
                        totals = items.map((item: ProjectRelationship) => item.total ? this.signAmount(item.total, item.type) : 0)
                        return totals.length > 0 ? totals.reduce((prev: number, curr: number) => { return prev+curr }) : 0
                    },
                },
                {
                    value:'contractorType.id',
                    summarize: (items: ProjectRelationship[] = []) => { 
                        let types = [];
                        types = items.map((item: ProjectRelationship) => item.contractorType && item.contractorType.abbreviation ? item.contractorType.abbreviation : '')
                        return types.length > 0 ? types.reduce((prev: string, curr: string) => { return prev.includes(curr) ? prev : prev + ', ' + curr }) : ''
                    },
                },
            ]
        }
        get projectRelationshipsFormatters() {
            return [
                {
                    value:'total',
                    format: (value: number) => {
                        return this.$options.filters !== undefined ? this.$options.filters.formatMoney(value) : null
                    }
                },
                {
                    
                    value:'contractorType.id',
                    format: (value: string) => {
                        return value;
                    }
                }
            ]
        }
        
        

        // setters

        // watchers

        // lifecycle
    }
