import { InvoiceState } from '@/modules/sales-invoices/store/types/InvoiceState';
import { MutationTree } from 'vuex';
import { Mutations } from '@/modules/sales-invoices/store/types/StoreTypes';
import { SalesInvoice, SalesInvoiceStatus, SalesInvoiceType, SalesInvoiceRecordType, SalesInvoicePaymentStatus } from '@/modules/sales-invoices/types/entities';
import { FilterInterface } from '@/core/types/Filter';
import { Client, Supplier } from '@/modules/entities/types/entities';
import { Project } from '@/modules/projects/types/entities';
import { Contact } from '@/modules/contacts/types/entities';

export const mutations: MutationTree<InvoiceState> = {
	[Mutations.MUTATE_LISTVIEW_IS_LOADING]: (state: InvoiceState, payload: boolean) => {
		state.listviewIsLoading = payload;
	},
	[Mutations.MUTATE_LISTVIEW_INVOICES]: (state: InvoiceState, payload: SalesInvoice[]) => {
		state.listviewInvoices = payload;
	},
	[Mutations.MUTATE_INVOICES_SUMMARY_REPORT]: (state: InvoiceState, payload: any) => {
		state.invoicesSummaryReport = payload;
	},
	[Mutations.MUTATE_INVOICES_METRICS_REPORT]: (state: InvoiceState, payload: any) => {
		state.invoicesMetricsReport = payload;
	},
	[Mutations.MUTATE_INVOICES_SHOW_SUMMARY_REPORT]: (state: InvoiceState, payload: boolean) => {
		state.invoicesShowSummaryReport = payload;
	},
	[Mutations.MUTATE_INVOICES_SHOW_METRICS_REPORT]: (state: InvoiceState, payload: boolean) => {
		state.invoicesShowMetricsReport = payload;
	},
	[Mutations.MUTATE_LISTVIEW_LINKS]: (state: InvoiceState, payload: any) => {
		state.links = payload;
	},
	[Mutations.MUTATE_LISTVIEW_META]: (state: InvoiceState, payload: any) => {
		state.meta = payload;
	},
	[Mutations.MUTATE_LISTVIEW_PUSH_NEXT_PAGE]: (state: InvoiceState, payload: { links: any; invoices: SalesInvoice[] }) => {
		state.links = payload.links;
		state.listviewInvoices = [
			...state.listviewInvoices,
			...payload.invoices
		];
	},
	[Mutations.MUTATE_STATUSES]: (state: InvoiceState, payload: SalesInvoiceStatus[]) => {
		state.statuses = payload;
	},
	[Mutations.MUTATE_PAYMENT_STATUSES]: (state: InvoiceState, payload: SalesInvoicePaymentStatus[]) => {
		state.paymentStatuses = payload;
	},
	[Mutations.MUTATE_RECORD_TYPES]: (state: InvoiceState, payload: SalesInvoiceRecordType[]) => {
		state.recordTypes = payload;
	},
	[Mutations.MUTATE_TYPES]: (state: InvoiceState, payload: SalesInvoiceType[]) => {
		state.types = payload;
	},
	[Mutations.MUTATE_IS_LOADING]: (state: InvoiceState, payload: boolean) => {
		state.isLoading = payload;
	},
	[Mutations.MUTATE_CONTACTS]: (state: InvoiceState, payload: Contact[]) => {
		state.contacts = payload;
	},
	[Mutations.MUTATE_PROJECTS]: (state: InvoiceState, payload: Project[]) => {
		state.projects = payload;
	},
	[Mutations.MUTATE_SUPPLIERS]: (state: InvoiceState, payload: Supplier[]) => {
		state.suppliers = payload;
	},
	[Mutations.MUTATE_CLIENTS]: (state: InvoiceState, payload: Client[]) => {
		state.clients = payload;
	},
	[Mutations.MUTATE_RELATED_BILLING_ADDRESSES]: (state: InvoiceState, payload: SalesInvoice[]) => {
		state.relatedBillingAddresses = payload;
	},
	[Mutations.MUTATE_NEW]: (state: InvoiceState, payload: SalesInvoice) => {
		state.new = payload;
	},
	[Mutations.MUTATE_PROJECT_INVOICES]: (state: InvoiceState, payload: SalesInvoice[]) => {
		// make sure these invoices are ALWAYS ordered from old -> new BASED ON 'name' (string)
		// USING natural sort, which means eg: '110' will be treated as greater than '10' !important.
		const orderedInvoiceListAsc = payload.sort((invoiceA: SalesInvoice, invoiceB: SalesInvoice) => {
			// natural sort
			return invoiceA.name.localeCompare(invoiceB.name, undefined, {
				numeric: true,
				sensitivity: 'base'
			});
		})

		state.projectInvoices = orderedInvoiceListAsc;
	},
	[Mutations.MUTATE_APPEND_TO_INVOICES]: (state: InvoiceState, payload: SalesInvoice[]) => {
		state.listviewInvoices = payload;
	},
	[Mutations.MUTATE_INVOICE]: (state: InvoiceState, payload: SalesInvoice) => {
		state.invoice = payload;
	},

	

	// filters
	[Mutations.MUTATE_TOGGLE_SIDEBAR]: (state: InvoiceState, payload: boolean) => {
		state.filters.sideBarOpen = payload;
	},
	[Mutations.MUTATE_TOGGLE_FILTER_GROUP]: (state: InvoiceState, payload: string) => {
		// toggle all filters with group property set to 'payload'
		Object.keys(state.filters.filters).forEach(function(key) {
			const filter = state.filters.filters[key] as FilterInterface;
			if(filter.group === payload) filter.active = !filter.active;
		});
	},
	[Mutations.MUTATE_CLEAR_FILTERS]: (state: InvoiceState, payload: string[]) => {
		// toggle all filters with group property set to 'payload'
		Object.keys(state.filters.filters).forEach(function(key) {
			if(payload.includes(key)) {
				const filter = state.filters.filters[key] as FilterInterface;
				filter.clear();
			}
		});
	},
	[Mutations.MUTATE_FILTER_PROJECT_NAME]: (state: InvoiceState, payload: string[]) => {
		state.filters.filters.projectName.value = payload;
	},
	[Mutations.MUTATE_FILTER_PROJECT]: (state: InvoiceState, payload: number[]) => {
		state.filters.filters.project.value = payload;
	},
	[Mutations.MUTATE_FILTER_PROJECT_MODEL]: (state: InvoiceState, payload: Project) => {
		state.filtersProjectModel = payload;
	},
	[Mutations.MUTATE_FILTER_FOR_SEARCH]: (state: InvoiceState, payload: string[]) => {
		state.filters.filters.search.value = payload;
	},
	[Mutations.MUTATE_FILTER_PROJECT_LEAD]: (state: InvoiceState, payload: number[]) => {
		state.filters.filters.projectLead.value = payload;
	},
	[Mutations.MUTATE_FILTER_STATUS]: (state: InvoiceState, payload: number[]) => {
		state.filters.filters.status.value = payload;
	},
	[Mutations.MUTATE_FILTER_PAYMENT_STATUS]: (state: InvoiceState, payload: number[]) => {
		state.filters.filters.paymentStatus.value = payload;
	},
	[Mutations.MUTATE_FILTER_TYPE]: (state: InvoiceState, payload: number[]) => {
		state.filters.filters.type.value = payload;
	},
	[Mutations.MUTATE_FILTER_INVOICE_DATE_FROM]: (state: InvoiceState, payload: string[]) => {
		state.filters.filters.invoiceDateFrom.value = payload;
	},
	[Mutations.MUTATE_FILTER_INVOICE_DATE_TO]: (state: InvoiceState, payload: string[]) => {
		state.filters.filters.invoiceDateTo.value = payload;
	},
	[Mutations.MUTATE_FILTER_DUE_DATE_FROM]: (state: InvoiceState, payload: string[]) => {
		state.filters.filters.dueDateFrom.value = payload;
	},
	[Mutations.MUTATE_FILTER_DUE_DATE_TO]: (state: InvoiceState, payload: string[]) => {
		state.filters.filters.dueDateTo.value = payload;
	},
	[Mutations.MUTATE_FILTER_VAT]: (state: InvoiceState, payload: number[]) => {
		state.filters.filters.vat.value = payload;
	},
	[Mutations.MUTATE_FILTER_ON_CONTACT_SEARCH]: (state: InvoiceState, payload: string[]) => {
		state.contactSearchFilters.filters.search.value = payload;
	},
	[Mutations.MUTATE_FILTER_ON_CONTACT_SEARCH_PROJECTS]: (state: InvoiceState, payload: number[]) => {
		state.contactSearchFilters.filters.projects.value = payload;
	},
	[Mutations.MUTATE_FILTER_ON_CONTACT_SEARCH_SUPPLIERS]: (state: InvoiceState, payload: number[]) => {
		state.contactSearchFilters.filters.suppliers.value = payload;
	},
	[Mutations.MUTATE_FILTER_ON_CONTACT_SEARCH_CLIENTS]: (state: InvoiceState, payload: number[]) => {
		state.contactSearchFilters.filters.clients.value = payload;
	},
	[Mutations.MUTATE_CLEAR_FILTERS_ON_CONTACT_SEARCH]: (state: InvoiceState, payload: string[]) => {
		// toggle all filters with group property set to 'payload'
		Object.keys(state.contactSearchFilters.filters).forEach(function(key) {
			if(payload.includes(key)) {
				const filter = state.contactSearchFilters.filters[key] as FilterInterface;
				filter.clear();
			}
		});
	},
	[Mutations.MUTATE_FILTER_ON_PROJECT_SEARCH]: (state: InvoiceState, payload: string[]) => {
		state.projectSearchFilters.filters.search.value = payload;
	},
	[Mutations.MUTATE_FILTER_ON_SUPPLIER_SEARCH]: (state: InvoiceState, payload: string[]) => {
		state.supplierSearchFilters.filters.search.value = payload;
	},
	[Mutations.MUTATE_FILTER_ON_CLIENT_SEARCH]: (state: InvoiceState, payload: string[]) => {
		state.clientSearchFilters.filters.search.value = payload;
	},
	[Mutations.MUTATE_FILTER_ON_CLIENT_SEARCH_PROJECTS]: (state: InvoiceState, payload: string[]) => {
		state.clientSearchFilters.filters.projects.value = payload;
	},
	[Mutations.MUTATE_FILTER_ON_RELATED_BILLING_ADDRESSES_PROJECTS]: (state: InvoiceState, payload: number[]) => {
		state.relatedBillingAddressesSearchFilters.filters.projects.value = payload;
	},
	[Mutations.MUTATE_FILTER_ON_PROJECT_INVOICES_EXCEPT]: (state: InvoiceState, payload: number[]) => {
		state.projectInvoicesFilters.filters.except.value = payload;
	},

	// search 
	[Mutations.MUTATE_SEARCH_PROJECTS]: (state: InvoiceState, payload: Project[]) => {
		state.searchProjects = payload;
	},
};
