import { render, staticRenderFns } from "./sales-invoice-modal.component.vue?vue&type=template&id=6dfd7a28&scoped=true"
import script from "./sales-invoice-modal.component.vue?vue&type=script&lang=ts"
export * from "./sales-invoice-modal.component.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dfd7a28",
  null
  
)

export default component.exports