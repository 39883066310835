import {RouteConfig} from 'vue-router';
import CalendarView from "@/modules/calendar/views/calendar.view.vue";

export const routes: RouteConfig[] = [
    {
        path: '/planner',
        name: 'calendar',
        component: CalendarView
    }
];
