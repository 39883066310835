
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class TileGroupComponent extends Vue {
    @Prop({ default: '#03D7BF' })
    colorIncrease!: string;

    @Prop({ default: '#FF5252' })
    colorDecrease!: string;

    @Prop({ default: '#FEAA00' })
    colorMiddleState!: string;

    @Prop()
    value!: number;

    @Prop({ default: false })
    isDisabled!: boolean;

    getIcon(value: number): string {
        return value === 3
            ? '0'
            : [1, 2].includes(value)
                ? '-'
                : '+';
    }
    
    getStyle(value: number): object {
        return {
            fontSize: [1,5].includes(value)
                ? '18px'
                : '12px'
        }
    }

    getColor(value: number): string {
        return value === 3
            ? this.colorMiddleState
            : [1, 2].includes(value)
                ? this.colorDecrease
                : this.colorIncrease;
    }

    getActiveStyleColor(value: number): object {
        if (value === this.value) {
            return {
                background: this.getColor(value)
            };
        }
        return {};
    }

    setNewValue(value: number): void {
        if (!this.isDisabled) {
            this.$emit('update:value', value);
        }
    }
}
