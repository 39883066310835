
import Vue from 'vue'
import { AxiosPromise, AxiosResponse } from 'axios';
import { JsonResource } from '@/core/types/Entities';
import axios from 'axios';
import { Role } from '@/modules/entities/types/entities';
import { Permission } from '@/modules/settings/store/modules/permissions/types/PermissionsState';

const BASE_ENDPOINT = '/api/v1/settings/roles';

export class RolesService {

	getBaseEndpoint(): string {
		return BASE_ENDPOINT
	}

	getAllRolesAndPermissions(url = `${BASE_ENDPOINT}`): Promise<AxiosResponse<{data: {permissions: Permission[]; roles: Role[]}}>> {
		return axios.get<{data: {permissions: Permission[]; roles: Role[]}}>(url);
	}

}
