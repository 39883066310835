import Vue from 'vue';
import {ActionContext, ActionTree} from 'vuex';
import {WorkflowState} from '@/modules/workflow/store/types/WorkflowState';
import {RootState} from '@/core/types/RootState';
import {WorkflowTask, ProjectIntersectionData} from '@/modules/workflow/types/entities';
import {Actions, Mutations} from '@/modules/workflow/store/types/StoreTypes';
import {Project, ProjectUser} from '@/modules/projects/types/entities';
import {Comment} from '@/modules/entities/types/entities';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/core/types/Entities';

const namespace = 'workflow';

export const actions: ActionTree<WorkflowState, RootState> = {
	[Actions.FETCH_WORKFLOW_DATA]: async ({commit}: ActionContext<WorkflowState, RootState>, payload?: number) => {
		commit(Mutations.MUTATE_WORKFLOW_LOADING, true);

		const res = await Vue.prototype.$http.get(`/api/v1/workflow`);

		if (res.status !== 200) return;

		commit(Mutations.MUTATE_WORKFLOW_TASK_STATUSES, res.data.taskStatuses);
		commit(Mutations.MUTATE_WORFKLOW_TASKS, res.data.tasks);
		commit(Mutations.MUTATE_WORFKLOW_FUNCTION_GROUPS, res.data.functionGroups);
		commit(Mutations.MUTATE_WORKFLOW_SELECTED_PROJECTS, res.data.selectedProjects);
		commit(Mutations.MUTATE_WORKFLOW_FILTER_SHOW_ALL_PROJECTS, res.data.showMyProjects);
		const expandedProjects = res.data.selectedProjects.map((project: Project) => {
			return project.id
		});
		commit(Mutations.MUTATE_WORKFLOW_ADD_EXPANDED_PROJECT, expandedProjects);
		commit(Mutations.MUTATE_WORKFLOW_ENVIRONMENT_ID, res.data.environmentId);
		commit(Mutations.MUTATE_WORKFLOW_USER_ID, res.data.userId);
		commit(Mutations.MUTATE_WORKFLOW_LOADING, false);
	},
	[Actions.FETCH_NEXT_PROJECTS]: async ({ commit, state }: ActionContext<WorkflowState, RootState>) => {
		if (state.paginationDetails && state.paginationDetails.next) {
			let url = state.paginationDetails.next;
			if (state.filters.projectStatusesIds.length) url += `&statuses=${state.filters.projectStatusesIds.join()}&`;
			if (state.filters.projectNameQuery !== '' && !state.filters.projectNameQuery.startsWith(" ")) url += `name=${state.filters.projectNameQuery}`;

			const res: AxiosResponse<Pagination<Project[]>> = await Vue.prototype.$http.get(url);
			commit(Mutations.MUTATE_PUSH_NEXT_PROJECTS, res.data);
		}
	},
	[Actions.FETCH_PROJECTS_BY_WORKFLOW_FILTER]: async ({ state, commit }: ActionContext<WorkflowState, RootState>) => {
		let url = '/api/v1/projects?';
		if (!state.filters.showMyProjects) {
			if (state.filters.projectStatusesIds.length) url += `statuses=${state.filters.projectStatusesIds.join()}&`;
			if (state.filters.projectNameQuery !== '' && !state.filters.projectNameQuery.startsWith(" ")) url += `name=${state.filters.projectNameQuery}`;

			const res: AxiosResponse<Pagination<Project[]>> = await Vue.prototype.$http.get(url);

			commit(Mutations.MUTATE_WORFKLOW_ALL_PROJECTS, res.data.data);
			commit(Mutations.MUTATE_PROJECTS_PAGINATION_DETAILS, res.data);
		}
	},
	[Actions.FETCH_PROJECTS_BY_USER_FILTER]: async ({ state, commit }: ActionContext<WorkflowState, RootState>) => {
		let url = '/api/v1/workflow?';
		if (state.filters.leadersIds.length) {
			url += `leaders=${state.filters.leadersIds.join()}&`;
		}
		if (state.filters.estimatorsIds.length) {
			url += `estimators=${state.filters.estimatorsIds.join()}`;
		}

		commit(Mutations.MUTATE_WORKFLOW_LOADING, true);

		const res: AxiosResponse = await Vue.prototype.$http.get(url);

		if (res.status === 200) {
			const expandedProjects = res.data.selectedProjects.map((project: Project) => {
				return project.id
			});

			commit(Mutations.MUTATE_WORKFLOW_SELECTED_PROJECTS, res.data.selectedProjects);
			commit(Mutations.MUTATE_WORKFLOW_ADD_EXPANDED_PROJECT, expandedProjects);
			commit(Mutations.MUTATE_WORKFLOW_LOADING, false);
		}
	},
	[Actions.SET_WORKFLOW_LOADING]: ({commit}: ActionContext<WorkflowState, RootState>, payload: boolean) => {
		commit(Mutations.MUTATE_WORKFLOW_LOADING, payload);
	},
	[Actions.SET_WORKFLOW_TASKS]: async ({commit}: ActionContext<WorkflowState, RootState>, payload: WorkflowTask[]) => {
		commit(Mutations.MUTATE_WORFKLOW_TASKS, payload);

		const tasks = [];
		for (let i = 0; i < payload.length; i++) {
			const task = payload[i];
			tasks.push({
				id: task.id,
				sequence: i + 1
			});

			if (task.children) {
                for (let y = 0; y < task.children.length; y++) {
                    tasks.push({
                        id: task.children[y].id,
                        sequence: y + 1
                    })
                }
            }
		}
		await Vue.prototype.$http.patch('/api/v1/commands/workflow/update-sequence', { tasks });
	},
	[Actions.SET_WORKFLOW_USER_SELECTED_CONSTRUCTION_SITES]: async () => {
		const res = await Vue.prototype.$http.get(`/api/v1/commands/workflow/select-user-construction-sites`);
	},
	[Actions.SET_WORKFLOW_SIDEBAR_OPEN]: ({commit}: ActionContext<WorkflowState, RootState>, payload: boolean) => {
		commit(Mutations.MUTATE_WORKFLOW_SIDEBAR_OPEN, payload);
	},
	[Actions.SET_WORKFLOW_PROJECT_WORKFLOW_TASK]: async ({state, commit}: ActionContext<WorkflowState, RootState>, payload: {projectId: number; workflowTaskId: number; taskStatusId?: number; futureStartDate?: Date}) => {
		const res = await Vue.prototype.$http.put(`/api/v1/workflow/${payload.projectId}/tasks/${payload.workflowTaskId}`, {
			taskStatusId: payload.taskStatusId ? payload.taskStatusId : null,
			futureStartDate: payload.futureStartDate ? payload.futureStartDate : null
		});
		if (res.status === 200) {
			const intersectionDataResponse = res.data.data.intersectionData.find((x: ProjectIntersectionData) => x.projectId === payload.projectId && x.workflowTaskId === payload.workflowTaskId);
			commit(Mutations.MUTATE_WORKFLOW_EDIT_PROJECT_WORKFLOW_TASK, intersectionDataResponse);
		}
	},
	[Actions.SET_WORKFLOW_SHOW_FUNCTION_GROUPS]: ({commit}: ActionContext<WorkflowState, RootState>, payload: boolean) => {
		commit(Mutations.MUTATE_WORKFLOW_SHOW_FUNCTION_GROUPS, payload);
	},
	[Actions.ADD_WORKFLOW_SELECTED_PROJECT]: async ({ state, commit, dispatch}: ActionContext<WorkflowState, RootState>, payload: Project) => {
		commit(Mutations.MUTATE_WORKFLOW_LOADING, true);
		const res: AxiosResponse = await Vue.prototype.$http.post(`/api/v1/workflow/projects`, {id: payload.id});

		if (res.status === 200) {
			commit(Mutations.MUTATE_WORKFLOW_ADD_SELECTED_CONSTRUCTION_SITE, res.data.data);
			const expandedProjects = [...state.expandedProjects, res.data.data.id];
			commit(Mutations.MUTATE_WORKFLOW_ADD_EXPANDED_PROJECT, expandedProjects);
			commit(Mutations.MUTATE_WORKFLOW_LOADING, false);
		}
	},
	[Actions.SET_HIGHLIGHTED_PROJECT_ID]: ({ commit }: ActionContext<WorkflowState, RootState>, payload: number) => {
		commit(Mutations.MUTATE_HIGHLIGHTED_PROJECT_ID, payload);
	},
	[Actions.REMOVE_WORKFLOW_SELECTED_PROJECT]: async ({commit}: ActionContext<WorkflowState, RootState>, payload: Project) => {
		commit(Mutations.MUTATE_WORKFLOW_LOADING, true);
		const res = await Vue.prototype.$http.delete(`/api/v1/workflow/projects/${payload.id}`);
		if (res.status === 204 || res.status === 200) {
			commit(Mutations.MUTATE_WORKFLOW_REMOVE_SELECTED_CONSTRUCTION_SITE, payload);
			commit(Mutations.MUTATE_WORKFLOW_LOADING, false);
		}
	},
	[Actions.ADD_WORKFLOW_EXPANDED_PROJECT]: ({commit}: ActionContext<WorkflowState, RootState>, payload: number) => {
		commit(Mutations.MUTATE_WORKFLOW_ADD_EXPANDED_PROJECT, payload);
	},
	[Actions.REMOVE_WORKFLOW_EXPANDED_PROJECT]: ({commit}: ActionContext<WorkflowState, RootState>, payload: number) => {
		commit(Mutations.MUTATE_WORKFLOW_REMOVE_EXPANDED_PROJECT, payload);
	},
	[Actions.ADD_WORKFLOW_EXPANDED_TASK]: ({commit}: ActionContext<WorkflowState, RootState>, payload: number) => {
		commit(Mutations.MUTATE_WORKFLOW_ADD_EXPANDED_TASK, payload);
	},
	[Actions.REMOVE_WORKFLOW_EXPANDED_TASK]: ({commit}: ActionContext<WorkflowState, RootState>, payload: number) => {
		commit(Mutations.MUTATE_WORKFLOW_REMOVE_EXPANDED_TASK, payload);
	},
	[Actions.SAVE_TASK_NOTE]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: {comment: Comment; projectId: number; taskId: number}) => {
		/* If the task has not yet been associated with project, then no intersection
		* database record is present, hence we need to create it first, before associating comment with it */
		if (payload.comment.commentableId === 0) {
			const res = await Vue.prototype.$http.post(`/api/v1/workflow/${payload.projectId}/tasks`, {taskId: payload.taskId});
			if (res.status === 200) {
				const intersectionDataResponse = res.data.data.intersectionData.find((x: ProjectIntersectionData) => x.projectId === payload.projectId && x.workflowTaskId === payload.taskId);
				payload.comment.commentableId = intersectionDataResponse.intersectionId;
				const response = await Vue.prototype.$http.post(`/api/v1/comments`, payload.comment);
				if (response.status === 201) {
					const updatedProjects = state.selectedProjects.map(project => {
						if (project.id !== payload.projectId) return project;
						if (!project.intersectionData) return {
							...project,
							intersectionData: [intersectionDataResponse]
						};
						const intersection = [...project.intersectionData];
						return {
							...project,
							intersectionData: [
								...intersection,
								{
									...intersectionDataResponse,
									comments: [response.data.data]
								}
							]
						}
					});
					commit(Mutations.MUTATE_WORKFLOW_SELECTED_PROJECTS, updatedProjects);
				}
			}
		/* Intersection data is present, but we are adding yet another comment */
		} else if (payload.comment.id === 0) {
			const res = await Vue.prototype.$http.post(`/api/v1/comments`, payload.comment);
			if (res.status === 201) {
				const updatedProjects = state.selectedProjects.map(project => {
					if (project.id !== payload.projectId) return project;
					return {
						...project,
						intersectionData: project.intersectionData!.map(intersection => {
							if (intersection.intersectionId !== payload.comment.commentableId) return intersection;
							let comments: Comment[] = [];
							if (intersection.comments) {
								comments = [
									...intersection.comments
								]
							}
							comments = [
								res.data.data,
								...comments
							];
							return {
								...intersection,
								comments: comments
							}
						})
					}
				});
				commit(Mutations.MUTATE_WORKFLOW_SELECTED_PROJECTS, updatedProjects);
			}
		/* Existing comment edition */
		} else {
			const res = await Vue.prototype.$http.put(`/api/v1/comments/${payload.comment.id}`, payload.comment);
			if (res.status === 200) {
				const updatedProjects = state.selectedProjects.map(project => {
					if (project.id !== payload.projectId) return project;
					return {
						...project,
						intersectionData: project.intersectionData!.map(intersection => {
							if (intersection.intersectionId !== payload.comment.commentableId) return intersection;
							return {
								...intersection,
								comments: intersection.comments.map(comment => {
									if (comment.id !== res.data.data.id) return comment;
									return res.data.data;
								})
							}
						})
					}
				});
				commit(Mutations.MUTATE_WORKFLOW_SELECTED_PROJECTS, updatedProjects);
			}
		}
	},
	[Actions.DELETE_TASK_NOTE]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: {projectId: number; taskId: number; noteId: number}) => {
		const res = await Vue.prototype.$http.delete(`/api/v1/comments/${payload.noteId}`);
		if (res.status === 200) {
			const updatedProjects = state.selectedProjects.map(project => {
				if (project.id !== payload.projectId) return project;
				return {
					...project,
					intersectionData: project.intersectionData!.map(intersection => {
						if (intersection.workflowTaskId !== payload.taskId) return intersection;
						return {
							...intersection,
							comments: intersection.comments.filter(x => x.id !== payload.noteId)
						}
					})
				}
			});
			commit(Mutations.MUTATE_WORKFLOW_SELECTED_PROJECTS, updatedProjects);
		}
	},
	[Actions.TOGGLE_ALL_TASKS_PANELS]: ({commit}: ActionContext<WorkflowState, RootState>) => {
		commit(Mutations.MUTATE_WORKFLOW_TOGGLE_ALL_TASKS_PANELS)
	},
	[Actions.SET_WORKFLOW_FILTER_SHOW_MY_PROJECTS]: async ({commit, state, dispatch}: ActionContext<WorkflowState, RootState>, payload: boolean) => {
		const res = await Vue.prototype.$http.post(`/api/v1/workflow`, { showMyProjects: payload });

		if (res.status === 200) {
			commit(Mutations.MUTATE_WORKFLOW_FILTER_SHOW_ALL_PROJECTS, payload);
			dispatch(Actions.FETCH_WORKFLOW_DATA);
		}
	},
	[Actions.SET_WORKFLOW_FILTER_PROJECT_STATUS]: ({ commit, dispatch, state }: ActionContext<WorkflowState, RootState>, payload: number) => {
		commit(Mutations.MUTATE_WORKFLOW_FILTER_PROJECT_STATUS_ID, payload);
		dispatch(Actions.FETCH_PROJECTS_BY_WORKFLOW_FILTER);
	},
	[Actions.SET_WORKFLOW_FILTER_PROJECT_NAME]: ({ commit, dispatch }: ActionContext<WorkflowState, RootState>, payload: string) => {
		commit(Mutations.MUTATE_WORKFLOW_FILTER_PROJECT_NAME, payload);
		dispatch(Actions.FETCH_PROJECTS_BY_WORKFLOW_FILTER);
	},
	[Actions.SET_WORKFLOW_FILTER_USERS]: ({ commit, dispatch }: ActionContext<WorkflowState, RootState>, payload: {user: ProjectUser; userGroup: string}) => {
		commit(Mutations.MUTATE_WORKFLOW_FILTER_USERS, payload);
		dispatch(Actions.FETCH_PROJECTS_BY_USER_FILTER);
	},
	[Actions.SELECT_MULTIPLE_TASK_STATUS_ID]: ({ commit }: ActionContext<WorkflowState, RootState>, taskStatusId: number) => {
		commit(Mutations.MUTATE_WORKFLOW_MULTIPLE_TASK_STATUS_ID, taskStatusId);
	},
	[Actions.SELECT_MULTIPLE_TASK_STATUS_DATE]: ({ commit }: ActionContext<WorkflowState, RootState>, futureStartDate: Date) => {
		commit(Mutations.MUTATE_WORKFLOW_MULTIPLE_TASK_STATUS_DATE, futureStartDate);
	}
};
