
  import { computed, defineComponent, PropType, reactive, ref, watch, h } from 'vue';
  import { ErrorResponse } from '@/core/types/Entities';

  export default defineComponent({
    name: 'EmployeeAbsencesError',
    components: {},
    emits: [],
    props: {
      errorResponse: {
        type: Object as PropType<ErrorResponse>,
        default: () => null,
      },
    },
    setup(props, { emit }) {
      return {};
    },
  });
