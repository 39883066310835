

	import { Component, Vue, Prop, Watch, Ref, Model } from 'vue-property-decorator';
	import AppInputComponent from '@/core/components/app-input/app-input.component.vue'
	//@ts-ignore
	import VTextField from 'vuetify/lib/components/VTextField/VTextField'
	import { i18n } from '@/main'

	@Component({})
	export default class AppInputPercentageComponent extends Vue {

		// props
		@Prop() value!: any;

		// data
		input: HTMLInputElement|null = null;
		formatter: Intl.NumberFormat = Intl.NumberFormat(i18n.locale,{style:'percent',minimumFractionDigits:2, maximumFractionDigits:2});
		decimalDelimiter: string = this.formatter.format(1.1).includes(',') ? ',' : '.'
		isFocussed: boolean = false;
		isSelected: boolean = false;

		// methods
		formatValue(value: number) : string {
			let formattedValue = this.formatter.format(value/100);
			return formattedValue;
		}
		parseValue(value: string): string {
			let filteredValue = value;
			if(typeof value === 'string'){
				filteredValue = value.split('%').join('').trim();
				filteredValue = this.filterDelimiters(filteredValue, this.whichDelimiter(filteredValue));
			}

			let parsedAsNumber = Number.parseFloat(filteredValue)
			let returnValue = isNaN(parsedAsNumber) ? '' : filteredValue
			return returnValue;
		}
		toNumber(value: string): number {
			let parsedAsNumber = Number.parseFloat(value)
			let returnValue = isNaN(parsedAsNumber) ? 0 : parsedAsNumber
			return returnValue;
		}
		replaceCharAt(value: string, index: number, replaceValue: string) {
			return value.substring(0, index) + replaceValue + value.substring(index + 1);
		}
		whichDelimiter(value: string) {
			if(value.lastIndexOf(',') > value.lastIndexOf('.')) return ','
			return '.'
		}
		filterDelimiters(value: string, decimalDelimiter: string): string {
			let filteredValue = value
			if(value.includes(',') || value.includes('.')) {
				let integerPart = filteredValue.substring(0,filteredValue.lastIndexOf(decimalDelimiter));
				const decimalPart = filteredValue.substring(filteredValue.lastIndexOf(decimalDelimiter)+1,filteredValue.length);
				integerPart = integerPart.split('.').join('').split(',').join('')
				filteredValue = integerPart + '.' + decimalPart;
			}
			return filteredValue;
		}

		// handlers 
		onInput(value: any) {
			this.formattedValue = value
			this.$emit('input', this.toNumber(this.parseValue(value)))
			this.isSelected = false;
		}

		onChange(value: any) {
			this.formattedValue = value
			this.$emit('change', this.toNumber(this.parseValue(value)))
		}
		onBlur(value: string) {
			this.formattedValue = value
			this.isFocussed = false;
			this.isSelected = false;
			this.$emit('blur')
		}
		onFocus(event: FocusEvent) {
			this.isFocussed = true;
			if(this.input) {
				// event.preventDefault();
				this.input.select();
				this.isSelected = true;
			}
			this.$emit('focus')
		}
		onKeyPress(event: any) {
			const formattedValueAsString = this.formattedValue.toString()
			const delimiterPosition = formattedValueAsString.lastIndexOf('.');
			const decimals = delimiterPosition > -1 ? formattedValueAsString.substring(delimiterPosition+1, formattedValueAsString.length) : null
			if(decimals && decimals.length >= 2 && !this.isSelected) {
				event.preventDefault();
			}
			this.$emit('keypress')
		}
		
		// computed / getters
		get attrs() {
			// eslint-disable-next-line
			const { ...attrs } = this.$attrs; // all but input event
			return attrs;
		}
		get slots() {
			// eslint-disable-next-line
			const { ...slots } = this.$slots; // pass all to datatable slots
			return slots;
		}
		get scopedSlots() {
			// eslint-disable-next-line
			const { ...scopedSlots } = this.$scopedSlots; // pass all to datatable scopedSlots
			return scopedSlots;
		}
		get formattedValue(): string {
			let parsedValue = this.parseValue(this.value);
			if(this.isFocussed) {
				return this.value ? parsedValue : '';
			}

			// if value is null, show empty string
			let returnValue = this.value ? this.formatValue(this.toNumber(parsedValue)) : ''

			return returnValue;
		}
		set formattedValue(value: string) {
			let newValue = this.toNumber(this.parseValue(this.value));
			newValue = Math.round((newValue + Number.EPSILON) * 100) / 100;
		}
		

		// watchers
		@Watch('value')
		onValueChange(newValue: any, oldValue: any) {
			this.formattedValue = newValue
		}

		// created / mounted
		mounted () {
			this.input = this.$el.querySelector('input');
			this.formattedValue = this.value
		}

		beforeDestroy () {

		}
	}
