import Vue from 'vue';
import Vuex from 'vuex';

import {store as workflow} from '@/modules/workflow/workflow.module';
import {store as settings} from '@/modules/settings/settings.module';
import {store as entities} from '@/modules/entities/store';
import {store as projects} from '@/modules/projects/store';
import {store as contacts} from '@/modules/contacts/store';
import {store as salesInvoices} from '@/modules/sales-invoices/sales-invoices.module';
import {store as purchaseInvoices} from '@/modules/purchase-invoices/purchase-invoices.module';
import { store as accounting } from '@/modules/accounting/store';
import { store as loading } from '@/modules/loading/store';
import { store as payments } from '@/modules/payments/store';
import { store as reports } from '@/modules/reports/store';
import { store as monthlyOverview } from '@/modules/monthly-overview/store';
import {RootState} from '@/core/types/RootState';

Vue.use(Vuex);

export const store = new Vuex.Store<RootState>({
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		workflow,
		entities,
		settings,
		projects,
		contacts,
		salesInvoices,
		purchaseInvoices,
		accounting,
		loading,
		payments,
		reports,
		monthlyOverview
	}
});
