import { UseAppMessageListContext } from '../types/useAppMessageList';
import { AppMessage, AppMessageType } from '@/core/components/app-message/entities';
import {uniqueId, merge} from 'lodash';

// const setAppMessages = async (ctx: UseAppMessageListContext, appMessages: AppMessage[]): Promise<void> => new Promise((resolve, reject) => {
//   const { composables } = ctx;
//   try {
//     composables.list.actions.setItems(appMessages)
//     resolve();
//   } catch (err: any) { reject(err) }
// })

const add = async (ctx: UseAppMessageListContext, appMessage: AppMessage): Promise<void> => new Promise((resolve, reject) => {
  const { state, composables } = ctx;
  try {
    composables.list.actions.addItem(appMessage);
    resolve();
  } catch (err: any) { reject(err); }
});

const set = async (ctx: UseAppMessageListContext, appMessage: AppMessage): Promise<void> => new Promise((resolve, reject) => {
  const { state, composables } = ctx;
  try {
    if(state.message) {
      add(ctx, state.message);
      state.message = null;
    }
    appMessage.id = uniqueId();
    state.message = appMessage;
    resolve();
  } catch (err: any) { reject(err); }
});

const warn = async (ctx: UseAppMessageListContext, appMessage: Partial<AppMessage>): Promise<void> => new Promise((resolve, reject) => {
  try {
    const defaultMessage = {
      dismissed: false,
      message: 'dictionary.warning',
      type: AppMessageType.WARNING,
      ttl: 7500,
    } as AppMessage;
    const mergedAppMessage = merge(defaultMessage, appMessage);
    set(ctx, mergedAppMessage);
    return resolve();
  } catch (err: any) { return reject(err); }
});

const info = async (ctx: UseAppMessageListContext, appMessage: Partial<AppMessage>): Promise<void> => new Promise((resolve, reject) => {
  try {
    const defaultMessage = {
      dismissed: false,
      message: 'dictionary.info',
      type: AppMessageType.INFO,
      ttl: 5000,
    } as AppMessage;
    const mergedAppMessage = merge(defaultMessage, appMessage);
    set(ctx, mergedAppMessage);
    return resolve();
  } catch (err: any) { return reject(err); }
});

const error = async (ctx: UseAppMessageListContext, appMessage: Partial<AppMessage>): Promise<void> => new Promise((resolve, reject) => {
  try {
    const defaultMessage = {
      dismissed: false,
      message: 'dictionary.error',
      type: AppMessageType.ERROR,
      ttl: 10000,
    } as AppMessage;
    const mergedAppMessage = merge(defaultMessage, appMessage);
    set(ctx, mergedAppMessage);
    return resolve();
  } catch (err: any) { return reject(err); }
});

const success = async (ctx: UseAppMessageListContext, appMessage: Partial<AppMessage>): Promise<void> => new Promise((resolve, reject) => {
  try {
    const defaultMessage = {
      dismissed: false,
      message: 'dictionary.success',
      type: AppMessageType.SUCCESS,
      ttl: 2000,
    } as AppMessage;
    const mergedAppMessage = merge(defaultMessage, appMessage);
    set(ctx, mergedAppMessage);
    return resolve();
  } catch (err: any) { return reject(err); }
});

const dismiss = async (ctx: UseAppMessageListContext, appMessageId: string|undefined): Promise<void> => new Promise((resolve, reject) => {
  const { state, composables } = ctx;
  try {
    const appMessage = state.message;

    if(appMessage && appMessage.id === appMessageId) {
      appMessage.dismissed = true;
      add(ctx, appMessage);
      state.message = null;
    }
    return resolve();

    // appMessage = composables.list.getters.items.value.find(x => (x as AppMessage).id === appMessageId) as AppMessage|undefined
    // if(!item) throw new Error(`AppMessage could not be located with this id '${ appMessageId }'.`)
    // item.dismissed = true
    // resolve();
  } catch (err: any) { reject(err); }
});

export const actions = {
  set,
  dismiss,
  warn,
  error,
  info,
  success,
};
