import { JsonResource, Pagination } from '@/core/types/Entities';
import { AxiosResponse } from 'axios';
import { UseHolidayContext } from '../types/useHoliday';
import useFilter from '../useFilter';
import { HolidayService } from '@/services/holiday.service';
import { Holiday } from '@/modules/settings/store/modules/holidays/types/HolidaysState';
import { setReactiveProp } from '../utils';

const holidayService = new HolidayService();

const setHoliday = async (ctx: UseHolidayContext, holiday: Holiday|null): Promise<void> => new Promise((resolve, reject) => {
  const { state } = ctx;
  try {
    state.holiday = holiday;
    resolve();
  } catch (err: any) { reject(err); }
});

const clearHolidayValidationErrors = async (ctx: UseHolidayContext) => {
  const { state } = ctx;
  state.holidayValidationErrors = null;
  return Promise.resolve();
};

const clearHolidayBusinessErrors = async (ctx: UseHolidayContext) => {
  const { state } = ctx;
  state.holidayBusinessErrors = null;
  return Promise.resolve();
};

const clearHolidayErrors = async (ctx: UseHolidayContext) => {
  clearHolidayValidationErrors(ctx);
  clearHolidayBusinessErrors(ctx);
  return Promise.resolve();
};

const fetchHoliday = async (ctx: UseHolidayContext, id: number): Promise<AxiosResponse<JsonResource<Holiday>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchHoliday.name, true);
  try {
    clearHolidayErrors(ctx);
    const res = await holidayService.getHolidayById(id);
    setHoliday(ctx, res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchHoliday.name, false);
  }
};

const createHoliday = async (ctx: UseHolidayContext): Promise<AxiosResponse<JsonResource<Holiday>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(createHoliday.name, true);
  clearHolidayErrors(ctx);
  try {
    if(state.holiday !== null) {
      clearHolidayErrors(ctx);
      const res = await holidayService.createHoliday(state.holiday);
      setHoliday(ctx, res.data.data);
      return Promise.resolve(res);
    }
    else return Promise.reject(new Error('Holiday not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.holidayValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.holidayBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(createHoliday.name, false);
  }
};

const updateHoliday = async (ctx: UseHolidayContext): Promise<AxiosResponse<JsonResource<Holiday>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(updateHoliday.name, true);
  clearHolidayErrors(ctx);
  try {
    if(state.holiday !== null && state.holiday.id != null) {
      clearHolidayErrors(ctx);
      const res = await holidayService.editHoliday(state.holiday);
      setHoliday(ctx, res.data.data);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No holiday available in state or id not provided'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.holidayValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.holidayBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateHoliday.name, false);
  }
};

const deleteHoliday = async (ctx: UseHolidayContext): Promise<AxiosResponse<void>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(deleteHoliday.name, true);
  clearHolidayErrors(ctx);
  try {
    if(state.holiday !== null && state.holiday.id != null) {
      const res = await holidayService.deleteHoliday(state.holiday.id);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No holiday available in state or id not provided'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteHoliday.name, false);
  }
};

const search = async (ctx: UseHolidayContext, query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<Holiday[]>>> => {
  const { composables: { loadingActions, filter } } = ctx;
  loadingActions.actions.set(search.name, true);
  try {
    let urlSearchParams = null;
    if(useAsFilter) {
      if(query) filter.actions.setFilter('search', query);
      else filter.actions.deleteFilter('search');
      urlSearchParams = filter.getters.filterUrlQuery.value;
    } else {
      const f = useFilter();
      if(query) f.actions.setFilter('search', query);
      urlSearchParams = f.getters.filterUrlQuery.value;
    }
    const url = holidayService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`;
    const res = await holidayService.getAllHolidays(url);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(search.name, false);
  }
};

export const actions = {
  fetchHoliday,
  createHoliday,
  updateHoliday,
  deleteHoliday,
  setHoliday,
  search,
  clearHolidayValidationErrors,
  clearHolidayBusinessErrors,
  clearHolidayErrors,
};
