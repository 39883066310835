

	import {Component, Emit, Prop, PropSync, Vue, Watch} from "vue-property-decorator";
	import {ProjectIntersectionData, WorkflowTask, WorkflowTaskStatus} from "../../../../types/entities";
	import {Project} from "@/modules/projects/types/entities";

	interface ICarouselState {
		date_picker: boolean,
		date_picker2: boolean,
		multi_status_select: boolean
	}

	@Component
	export default class TaskStatusCarouselComponent extends Vue {
		@PropSync('carouselValue', { type: Number }) carouselValueSync!: number;
		@Prop() taskStatuses!: WorkflowTaskStatus[];
		@Prop() project!: Project;
		@Prop() task!: WorkflowTask;
		@Prop() getStatusChangerColor!: Function;
		@Prop() getCurrentStatus!: Function;
		@Prop() intersectionData?: ProjectIntersectionData;
		@Prop() vertical!: boolean;

		futureStartDateFallbackHelper: string = new Date().toISOString().substr(0, 10);

		taskStatusCarouselState: ICarouselState = {
			date_picker: false,
			date_picker2: false,
			multi_status_select: false
		};

		taskStatusCarouselContent : {
			intersectionCopy: ProjectIntersectionData
		} = {
			intersectionCopy: {
				intersectionId: 0,
				projectId: 0,
				workflowTaskId: 0,
				futureStartDate: undefined,
				comments: [],
			}
		};

		get taskStatusesChunks() {
			const chunkSize = 8;
			return this.taskStatuses.map((taskStatus: WorkflowTaskStatus, index: number) => {
				return index % chunkSize === 0 ? this.taskStatuses.slice(index, index + chunkSize) : null;
			}).filter((element) => element);
		}

		handleAvatarMouseEvent(type: string, taskStatus: WorkflowTaskStatus) {
			switch (type) {
				case 'mouseover':
					this.handleOnHoveredTaskChange(taskStatus);
					break;
				case 'mouseleave':
					this.handleOnHoveredTaskChange();
					break;
				default:
					break;
			}
		}

		setTaskStatus(taskStatusId: number) {
			if (this.intersectionData && this.intersectionData.taskStatus) {
				if (taskStatusId !== this.intersectionData.taskStatus!.id) {
					this.onSetTaskStatus(taskStatusId, this.intersectionData.projectId, this.intersectionData.workflowTaskId);
				}
			} else {
				this.onSetTaskStatus(taskStatusId);
			}
		}

		setMultipleTaskStatuses(taskStatusId: number) {
			this.setTaskStatus(taskStatusId);
			this.emitTaskStatusMultipleSelect(taskStatusId);
		}

		setMultipleTaskStatusesWithDate(taskStatusId: number, datePickerModel: number) {
			datePickerModel === 1 ? this.taskStatusCarouselState.date_picker = true : this.taskStatusCarouselState.date_picker2 = true;
			this.emitTaskStatusMultipleSelect(taskStatusId);
			this.taskStatusCarouselState.multi_status_select = true;
		}

		@Emit('onHoveredTaskChange')
		handleOnHoveredTaskChange(taskStatus?: WorkflowTaskStatus) {
			return taskStatus ? taskStatus: null;
		}

		@Emit('onSetTaskStatus')
		onSetTaskStatus(taskStatusId: number, projectId?: number, workflowTaskId?: number) {
			return projectId && workflowTaskId ? {
				projectId,
				workflowTaskId,
				taskStatusId
			} : {
				projectId: this.project.id,
				workflowTaskId: this.task.id,
				taskStatusId
			};
		}

		@Emit('onSetTaskDate')
		onSetTaskDate(taskStatusId: number) {
			if (this.taskStatusCarouselContent.intersectionCopy.intersectionId && this.taskStatusCarouselContent.intersectionCopy.intersectionId !== 0) {
				if (this.taskStatusCarouselState.multi_status_select) this.emitTaskStatusMultipleSelectDate(this.taskStatusCarouselContent.intersectionCopy.futureStartDate!);
				return {
					projectId: this.taskStatusCarouselContent.intersectionCopy.projectId,
					workflowTaskId: this.taskStatusCarouselContent.intersectionCopy.workflowTaskId,
					taskStatusId,
					futureStartDate: this.taskStatusCarouselContent.intersectionCopy.futureStartDate
				};
			} else {
				if (this.taskStatusCarouselState.multi_status_select) this.emitTaskStatusMultipleSelectDate(this.futureStartDateFallbackHelper);
				return {
					projectId: this.project.id,
					workflowTaskId: this.task.id,
					taskStatusId,
					futureStartDate: this.futureStartDateFallbackHelper
				}
			}
		}

		@Emit('taskStatusMultipleSelect')
		emitTaskStatusMultipleSelect(taskStatusId: number) {
			return taskStatusId;
		}

		@Emit('taskStatusMultipleSelectDate')
		emitTaskStatusMultipleSelectDate(futureStartDate: Date | string) {
			return futureStartDate;
		}

		@Watch('intersectionData')
		onIntersectionDataChange(val: ProjectIntersectionData) {
			this.taskStatusCarouselContent.intersectionCopy = Object.assign({}, val);
		}

		created() {
			if (this.intersectionData) this.taskStatusCarouselContent.intersectionCopy = Object.assign({}, this.intersectionData);
		}
	}
