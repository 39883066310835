
import {computed, PropType} from 'vue';
	import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
	import { Actions as ProjectActions, Getters as ProjectGetters } from '@/modules/projects/store/types/StoreTypes';
	import { Actions as ContractorBranchActions, Getters as ContractorBranchGetters } from '@/modules/settings/store/modules/contractor-branches/types/StoreTypes';
	import { Actions as AccountingActions, Getters as AccountingGetters } from '@/modules/accounting/store/types/StoreTypes';
	import { PurchaseInvoice, ProjectRelationship, ProjectRelationshipStatus, ProjectRelationshipEntity, TransactionType } from '@/modules/purchase-invoices/types/entities';
	import { User, UserEntity } from '@/modules/entities/types/entities';
	import { UsersService } from '@/services/users.service';
	import { Project, ProjectUser } from '@/modules/projects/types/entities';
	import _ from 'lodash';
	import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
	import { AccountingLedgerItem, AccountingLedger } from '@/modules/accounting/types/entities';
	import ProjectRelationshipStatusAutocompleteComponent from '@/modules/purchase-invoices/components/project-relationship-status-autocomplete/project-relationship-status-autocomplete.component.vue';
	import ContractorBranchAutocompleteComponent from '@/modules/purchase-invoices/components/contractor-branch-autocomplete/contractor-branch-autocomplete.component.vue';
	import AccountingLedgerItemAutocompleteComponent from '@/modules/accounting/components/accounting-ledger-item-autocomplete/accounting-ledger-item-autocomplete.component.vue';
	import ProjectAutocompleteComponent from '@/modules/purchase-invoices/components/project-autocomplete/project-autocomplete.component.vue';
import {useAccounting} from "@/modules/accounting/store/accounting.store";
import {filterAccountingLedgerItemsForProjectRelationship} from "@/modules/purchase-invoices/helpers";

	const purchaseInvoices = namespace('purchaseInvoices');
	const projects = namespace('projects');
	const contractorBranches = namespace('settings/contractor-branches');
	const accounting = namespace('accounting');

	const usersService = new UsersService();

	@Component({
		components: {
			'project-relationship-status-autocomplete': ProjectRelationshipStatusAutocompleteComponent,
			'contractor-branch-autocomplete': ContractorBranchAutocompleteComponent,
			'accounting-ledger-item-autocomplete': AccountingLedgerItemAutocompleteComponent,
			'project-autocomplete': ProjectAutocompleteComponent,
		},
      props: {
        currentAccountingLedger: {
          type: Object as PropType<AccountingLedger|undefined>,
          default: undefined
        }
      },
      setup(props, ctx) {

        const accountingLedgerItems = computed(() => {
          return filterAccountingLedgerItemsForProjectRelationship(props.projectRelationship, props.currentAccountingLedger);
        })

        return {
          accountingLedgerItems
        }
      }
	})
	export default class ProjectRelationshipDetails extends Vue {

		// actions
		@purchaseInvoices.Action(Actions.SAVE_PROJECT_RELATIONSHIP) saveProjectRelationship!: (payload: ProjectRelationship) => Promise<void>;
		@purchaseInvoices.Action(Actions.SEARCH_PROJECTS) searchProjects!: (payload: string) => Promise<any>;
		@purchaseInvoices.Action(Actions.FETCH_PROJECT_RELATIONSHIP_STATUSES) fetchProjectRelationshipStatuses!: () => Promise<void>;
		@contractorBranches.Action(ContractorBranchActions.FETCH_ALL_CONTRACTOR_BRANCHES) fetchContractorBranches!: () => Promise<void>;

		// getters
		@purchaseInvoices.Getter(Getters.GET_SEARCH_PROJECTS) projects!: Project[];
		@purchaseInvoices.Getter(Getters.GET_PROJECT_RELATIONSHIP_STATUSES_TRANSLATED) projectRelationshipStatuses!: ProjectRelationshipStatus[];
		@purchaseInvoices.Getter(Getters.GET_INVOICE) invoice!: PurchaseInvoice[];
		@contractorBranches.Getter(ContractorBranchGetters.GET_ALL_BRANCHES) contractorBranches!: ContractorBranch[];

		// props
		@Prop({ type: Object as PropType<ProjectRelationship>, required: true, default: () => new ProjectRelationshipEntity }) projectRelationship!: ProjectRelationship;
		@Prop({ type: Number, default: 0 }) remainder!: number;
		@PropSync('validationErrors', { type: Object }) validationErrorsSync!: any;

		// local state + vars
		state: {
			searchingProjects: boolean,
		} = {
			searchingProjects: false,
		}
		fuseAssignedToOptions: object = { keys: ['firstName','lastName'], threshold: 0.4 };

		// handlers
		onSearchProject(searchTerm: string) {
			if(searchTerm) this.onSearchProjectDebounced(searchTerm, this)
		}
		onSearchProjectDebounced = _.debounce((searchTerm: string, vm: Vue) => {
			vm.$data.state = { ...vm.$data.state, searchingProjects: true }
			this.searchProjects(searchTerm).then(() => { vm.$data.state = {...vm.$data.state, searchingProjects: false} });
		}, 300);
		onClickConfirmRemainder() {
			if(this.projectRelationship) {
				this.projectRelationship.total = Math.abs(this.remainder)
			}
		}

		// getters
		
		// watchers

		// hooks
		created() {
			if(!this.projectRelationshipStatuses || (this.projectRelationshipStatuses && this.projectRelationshipStatuses.length === 0)) { this.fetchProjectRelationshipStatuses()}
			if(!this.contractorBranches || (this.contractorBranches && this.contractorBranches.length === 0)) this.fetchContractorBranches()
		}
        destroyed() {}
	}
