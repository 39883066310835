import { MutationTree } from 'vuex';

import {
	ActivityLogLastActionType, TaskListType,
	WorkflowState
} from '@/modules/settings/store/modules/workflow/types/WorkflowState';
import { Mutations } from '@/modules/settings/store/modules/workflow/types/StoreTypes';
import { WorkflowFunctionGroup, WorkflowTask, WorkflowTaskStatus } from '@/modules/workflow/types/entities';
import { LogItem } from '@/modules/settings/types/entities';
import { Pagination } from '@/core/types/Entities';

export const mutations: MutationTree<WorkflowState> = {
	[Mutations.MUTATE_WORKFLOW_TASKS]: (state: WorkflowState, payload: WorkflowTask[]) => {
		state.tasks = payload;
	},
	[Mutations.MUTATE_WORKFLOW_TASK_STATUSES]: (state: WorkflowState, payload: WorkflowTaskStatus[]) => {
		state.taskStatuses = payload;
	},
	[Mutations.MUTATE_WORKFLOW_FUNCTION_GROUPS]: (state: WorkflowState, payload: WorkflowFunctionGroup[]) => {
		state.functionGroups = payload;
	},
	[Mutations.MUTATE_WORKFLOW_INFO_MESSAGE]: (state: WorkflowState, payload: { text: string; visibility: boolean; buttonVisible: boolean }) => {
		state.infoMessage.text = payload.text;
		state.infoMessage.isVisible = payload.visibility;
		state.infoMessage.buttonVisible = payload.buttonVisible;
	},
	[Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY]: (state: WorkflowState, payload: boolean) => {
		if (state.infoMessage.timeout !== -1) {
			clearTimeout(state.infoMessage.timeout);
		}
		state.infoMessage.isVisible = payload;
	},
	[Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT]: (state: WorkflowState, payload: number) => {
		if (state.infoMessage.timeout !== -1) {
			clearTimeout(state.infoMessage.timeout);
		}
		state.infoMessage.timeout = payload;
	},
	[Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG]: (state: WorkflowState, payload: { lastActionType: ActivityLogLastActionType; lastItem: { item: any; index: number }; lastBackendResponse: LogItem; lastOperationListType: TaskListType }) => {
		state.activityLog.lastOperationType = payload.lastActionType;
		state.activityLog.lastItem.item = payload.lastItem.item;
		state.activityLog.lastItem.index = payload.lastItem.index;
		state.activityLog.lastBackendResponse = payload.lastBackendResponse;
		state.activityLog.lastOperationListType = payload.lastOperationListType;
	},
	[Mutations.MUTATE_WORKFLOW_ACTIVE_LIST_TYPE]: (state: WorkflowState, payload: TaskListType) => {
		state.activeListType = payload;
	},
	[Mutations.MUTATE_WORKFLOW_HIGHEST_TASK_SEQUENCE]: (state: WorkflowState, payload: number) => {
		state.highestTaskSequence = payload;
	},
	[Mutations.MUTATE_PAGINATION_DETAILS]: (state: WorkflowState, payload: Pagination<any>) => {
		state.paginationDetails = {
			first: payload.links.first,
			last: payload.links.last,
			prev: payload.links.prev,
			next: payload.links.next,
			current_page: payload.meta.current_page
		}
	},
	[Mutations.MUTATE_PUSH_NEXT_PAGE]: (state: WorkflowState, payload: { pagination: Pagination<any[]>; listType: string }) => {
		state.paginationDetails = {
			first: payload.pagination.links.first,
			last: payload.pagination.links.last,
			prev: payload.pagination.links.prev,
			next: payload.pagination.links.next,
			current_page: payload.pagination.meta.current_page
		};

		switch (payload.listType) {
			case 'tasks':
				state.tasks = [
					...state.tasks,
					...payload.pagination.data
				];
				break;
			case 'taskStatuses':
				state.taskStatuses = [
					...state.taskStatuses,
					...payload.pagination.data
				];
				break;
			case 'functionGroups':
				state.functionGroups = [
					...state.functionGroups,
					...payload.pagination.data
				];
				break;
			default:
				break;
		}
	},
	[Mutations.MUTATE_WORKFLOW_FILTERS_SORT_BY_ARCHIVED_AT]: (state: WorkflowState) => {
		switch (state.filters.sortByArchivedAt) {
			case 'none':
				state.filters.sortByArchivedAt = 'asc';
				break;
			case 'asc':
				state.filters.sortByArchivedAt = 'desc';
				break;
			case 'desc':
				state.filters.sortByArchivedAt = 'none';
				break;
			default:
				state.filters.sortByArchivedAt = 'none';
		}
	},
	[Mutations.MUTATE_WORKFLOW_FILTERS_SORT_BY_SEQUENCE]: (state: WorkflowState) => {
		switch (state.filters.sortBySequence) {
			case 'none':
				state.filters.sortBySequence = 'asc';
				break;
			case 'asc':
				state.filters.sortBySequence = 'desc';
				break;
			case 'desc':
				state.filters.sortBySequence = 'none';
				break;
			default:
				state.filters.sortBySequence = 'none';
		}
	},
	[Mutations.MUTATE_WORKFLOW_FILTERS_FETCH_ARCHIVED]: (state: WorkflowState, payload: boolean) => {
		state.filters.fetchArchived = payload;
	}
};
