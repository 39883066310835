import datetimeCalculate from "@/core/helpers/DatetimeCalculator";
import useEmployeeList from "@/composables/useEmployeeList";
import useFilter from "@/composables/useFilter";

// shared state composables
const employeeListViewFilter = useFilter()
employeeListViewFilter.actions.setFilter('startDate', datetimeCalculate().year()[0])
employeeListViewFilter.actions.setFilter('endDate', datetimeCalculate().year()[1])
const employeeListView = useEmployeeList({composables: {filter: employeeListViewFilter}})

/**
 * Global state/store:
 *   - Because we can re-use composables as different states, we can also use 1 composable as a global state.
 *   - This global state can be stored in a `store.ts` file.
 */
const store: {
	employeeListView: typeof employeeListView;
} = {
	employeeListView: employeeListView
};

export default store