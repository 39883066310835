
	import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { Actions, Getters } from '@/modules/entities/store/modules/clients/types/StoreTypes';
	import { Client } from '../../types/entities';
	import _ from 'lodash';
	import { GetAddressMixin } from '@/mixins/get-address-mixin.component';
	import { PropType } from 'vue';

	const clients = namespace('entities/clients');

	@Component({
		mixins: [GetAddressMixin],
		components: {
			'client-modal': () => import(/* webpackChunkName: "cientModal" */ '@/modules/entities/components/clients/client-modal.component.vue')
		}
	})
	export default class SelectClientListComponent extends Vue {
		@PropSync('showModal', { type: Boolean, default: false }) showModalSync!: boolean;
		@Prop({ type: Array as PropType<Client[]>}) providedSelectedClients?: Client[];

		@clients.Action(Actions.FETCH_CLIENTS_BY_FILTER) fetchClientsByFilter!: () => void;
		@clients.Action(Actions.SET_CLIENTS_FILTER_NAME_QUERY) setClientsNameFilter!: (payload: string) => void;
		@clients.Action(Actions.SET_CLIENTS_FILTER_CLEAR_NO_FETCH) setClientsFilterClear!: () => void;
		@clients.Action(Actions.FETCH_NEXT_PAGE) fetchNextPage!: (payload: string) => void;
		@clients.Action(Actions.APPEND_FETCHED_CLIENTS) appendFetchedClients!: (payload: Client) => Promise<void>;

		@clients.Getter(Getters.GET_FETCHED_CLIENTS) clients!: Client[];
		@clients.Getter(Getters.GET_LOADING) clientsLoading!: boolean;

		state = {
			showClientModal: false
		};

		selectedClientIds: number[] = [];
		searchValue = '';

		loadNextPage() {
			this.fetchNextPage('clients');
		}

		onCreateClient(client: Client): void {
			this.appendFetchedClients(client).then(() => {
				this.selectedClientIds = [...this.selectedClientIds, client.id];
			});
			this.state.showClientModal = false;
		}

		onClientSelect(clientId: number): void {
			console.log(clientId);
			this.selectedClientIds.some(x => x === clientId) ? this.selectedClientIds = this.selectedClientIds.filter(x => x !== clientId) : this.selectedClientIds.push(clientId);
		}

		highlightedName(client: Client) {
			if (!this.searchValue) return client.name || client.commercialName;
			if (client.name) {
				return client.name.replace(new RegExp(this.searchValue, 'gi'), match => {
					return `<span class="highlight">${ match } </span>`
				});
			} else if (client.commercialName) {
				return client.commercialName.replace(new RegExp(this.searchValue, 'gi'), match => {
					return `<span class="highlight">${ match } </span><v-icon small v-if="selectedClientIds.some(x => x === client.id)">mdi-check</v-icon>`
				});
			}
		}

		isClientSelected(clientId: number): boolean {
			return this.selectedClientIds.some(x => x === clientId) || (!!(this.providedSelectedClients && this.providedSelectedClients.some(x => x.id === clientId)));
		}

		destroyed(): void {
			this.setClientsFilterClear();
		}

		@Watch('searchValue', { immediate: true })
		onChangeFilter = _.debounce((value: string) => {
			this.setClientsNameFilter(value);
		}, 200);
	};
