
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { DataTableHeader } from 'vuetify';
    import { Project } from '../../types/entities';
    import {TimesheetEntry} from "@/modules/settings/types/entities";
    import {formatTime, formatTime as timeFormatter} from "@/modules/monthly-overview/helpers";
    import {ProjectRelationship} from "@/modules/purchase-invoices/types/entities";

    @Component
    export default class ProjectTimesheetEntriesListComponent extends Vue {

        // vuex actions

        // vuex getters

        // props
        @Prop({type: Array as PropType<TimesheetEntry[]>, default: () => [], required: true}) timesheetEntries!: TimesheetEntry[];
        @Prop({type: Object as PropType<Project>, required: true}) project!: Project;

        // local/children state
        state: {
            isGrouped: boolean,
            itemsPerPage: number,
        } = {
            isGrouped: false,
            itemsPerPage: 15,
        }
        content: {} = {}

        // handlers
        onClickGroupRemove() {
            this.state = { ...this.state, isGrouped: false }
        }
        onClickGroupBy() {
            this.state = { ...this.state, isGrouped: true }
        }
        onUpdateItemsPerPage(itemsPerPage: number) {
            if(!this.state.isGrouped) {
                this.state = { ...this.state, itemsPerPage: itemsPerPage }
            }
        }

        // methods
        formatTime(timeToFormat: number) {
          return timeFormatter(timeToFormat)
        };

        // getters
        get timesheetEntriesHeaders() {
            return [
                {
                    text: this.$t('projects.details.timesheetEntries.name').toString(),
                    value: 'timesheet.employee.fullName',
                    align: 'start',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.timesheetEntries.day').toString(),
                    value: 'day',
                    align: 'start',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.timesheetEntries.duration').toString(),
                    value: 'duration',
                    align: 'end',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.timesheetEntries.durationExtra').toString(),
                    value: 'durationExtra',
                    align: 'end',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
              {
                text: this.$t('projects.details.timesheetEntries.hourlyRate').toString(),
                value: 'wage.hourlyRate',
                align: 'end',
                sortable: true,
                groupable: false,
                class:'text-no-wrap'
              } as DataTableHeader,
              {
                text: this.$t('projects.details.timesheetEntries.avgMaterialCost').toString(),
                value: 'wage.avgMaterialCost',
                align: 'end',
                sortable: true,
                groupable: false,
                class:'text-no-wrap'
              } as DataTableHeader,
              {
                text: this.$t('projects.details.timesheetEntries.totalCost').toString(),
                value: 'totalCost',
                align: 'end',
                sortable: true,
                groupable: false,
                class:'text-no-wrap'
              } as DataTableHeader,
              {
                text: this.$t('projects.details.timesheetEntries.contractorBranch').toString(),
                value: 'workDay.plannerPeriod.quote.contractorType.id',
                align: 'start',
                sortable: true,
                groupable: true,
                class:'text-no-wrap'
              } as DataTableHeader,
                {
                  text: '',
                  value: ' ',
                  align: 'end',
                  sortable: false,
                  groupable: false,
                },
            ];
        }
        get timesheetEntriesSummarizers() {
            return [
                {
                    value: 'duration',
                    summarize: (items: TimesheetEntry[] = []) => {
                        let durationTotals = [];
                        durationTotals = items.map((item: TimesheetEntry) => item.duration != null ? item.duration : 0)
                        return durationTotals.length > 0 ? durationTotals.reduce((prev: number, curr: number) => { return prev+curr }) : 0
                    },
                },
                {
                  value: 'durationExtra',
                  summarize: (items: TimesheetEntry[] = []) => {
                    let durationTotals = [];
                    durationTotals = items.map((item: TimesheetEntry) => item.durationExtra != null ? item.durationExtra : 0)
                    return durationTotals.length > 0 ? durationTotals.reduce((prev: number, curr: number) => { return prev+curr }) : 0
                  },
                },
                {
                  value: 'totalCost',
                  summarize: (items: TimesheetEntry[] = []) => {
                    let totalCosts = [];
                    totalCosts = items.map((item: TimesheetEntry) => item.totalCost != null ? item.totalCost : 0)
                    return totalCosts.length > 0 ? totalCosts.reduce((prev: number, curr: number) => { return prev+curr }) : 0
                  },
                },
              {
                value:'workDay.plannerPeriod.quote.contractorType.id',
                summarize: (items: TimesheetEntry[] = []) => {
                  let types = [];
                  types = items.map((item: TimesheetEntry) => item.workDay?.plannerPeriod?.quote?.contractorType.abbreviation ?? '')
                  return types.length > 0 ? types.reduce((prev: string, curr: string) => { return prev.includes(curr) ? prev : prev + ', ' + curr }) : ''
                },
              },
            ]
        }
        get timesheetEntriesFormatters() {
            return [
                {
                    value: 'duration',
                    format: (value: number) => {
                      return formatTime(value)
                    }
                },
                {
                  value: 'durationExtra',
                  format: (value: number) => {
                    return formatTime(value)
                  }
                },
                {
                  value:'totalCost',
                  format: (value: number) => {
                    return this.$options.filters !== undefined ? this.$options.filters.formatMoney(value) : null
                  }
                },
                {
                  value:'workDay.plannerPeriod.quote.contractorType.id',
                  format: (value: string) => {
                    return value;
                  }
                }
            ]
        }
        
        

        // setters

        // watchers

        // lifecycle
    }
