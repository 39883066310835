import { render, staticRenderFns } from "./workflow-settings-task-statuses-list.component.vue?vue&type=template&id=c8834d2c&scoped=true"
import script from "./workflow-settings-task-statuses-list.component.vue?vue&type=script&lang=ts"
export * from "./workflow-settings-task-statuses-list.component.vue?vue&type=script&lang=ts"
import style0 from "./workflow-settings-task-statuses-list.component.vue?vue&type=style&index=0&id=c8834d2c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8834d2c",
  null
  
)

export default component.exports