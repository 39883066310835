import { MutationTree } from 'vuex';
import { AccountingState } from './types/AccountingState';
import { Mutations } from './types/StoreTypes';
import { AccountingLedger, AccountingLedgerItem } from '../types/entities';

export const mutations: MutationTree<AccountingState> = {
	[Mutations.MUTATE_IS_LOADING]: (state: AccountingState, payload: boolean) => {
		state.isLoading = payload;
	},
	[Mutations.MUTATE_ACTIVE_ACCOUNTING_LEDGER]: (state: AccountingState, payload: AccountingLedger) => {
		state.activeAccountingLedger = payload;
	},
	[Mutations.MUTATE_ACCOUNTING_LEDGERS]: (state: AccountingState, payload: AccountingLedger[]) => {
		state.accountingLedgers = payload;
	},
	[Mutations.MUTATE_ACCOUNTING_LEDGER]: (state: AccountingState, payload: AccountingLedger) => {
		state.accountingLedger = payload;
	},
	[Mutations.MUTATE_ACCOUNTING_LEDGER_ITEM]: (state: AccountingState, payload: AccountingLedgerItem) => {
		state.accountingLedgerItem = payload
	},
}