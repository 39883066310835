import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import { SalesInvoice, SalesInvoicePaymentStatus, SalesInvoiceRecordType, SalesInvoiceStatus, SalesInvoiceType } from '../types/entities';
import { Payment } from '@/modules/payments/types/entities';

const BASE_ENDPOINT = '/api/v1/invoices/sales-invoices';

export class SalesInvoicesService {

	getAllStatuses(url = `/api/v1/invoices/sales-invoice-statuses`): AxiosPromise<JsonResource<SalesInvoiceStatus[]>> {
		return axios.get<JsonResource<SalesInvoiceStatus[]>>(`${ url }`);
	}

	getAllPaymentStatuses(url = `/api/v1/invoices/sales-invoice-payment-statuses`): AxiosPromise<JsonResource<SalesInvoicePaymentStatus[]>> {
		return axios.get<JsonResource<SalesInvoicePaymentStatus[]>>(`${ url }`);
	}

	getAllTypes(url = `/api/v1/invoices/sales-invoice-types`): AxiosPromise<JsonResource<SalesInvoiceType[]>> {
		return axios.get<JsonResource<SalesInvoiceType[]>>(`${ url }`);
	}

	getAllRecordTypes(url = `/api/v1/invoices/sales-invoice-record-types`): AxiosPromise<JsonResource<SalesInvoiceRecordType[]>> {
		return axios.get<JsonResource<SalesInvoiceRecordType[]>>(`${ url }`);
	}

	getAllInvoices(url = `${ BASE_ENDPOINT }`): Promise<AxiosResponse<Pagination<SalesInvoice[]>>> {
		return axios.get<Pagination<SalesInvoice[]>>(url);
	}

	getInvoiceById(invoiceId: number): AxiosPromise<JsonResource<SalesInvoice>> {
		return axios.get<JsonResource<SalesInvoice>>(`${ BASE_ENDPOINT }/${ invoiceId }`);
	}

	createInvoice(invoice: SalesInvoice): AxiosPromise<JsonResource<SalesInvoice>> {
		return axios.post<JsonResource<SalesInvoice>>(`${ BASE_ENDPOINT }`, invoice);
	}

	createPayment(invoiceId: number, payment: Payment): AxiosPromise<JsonResource<Payment>> {
		return axios.post<JsonResource<Payment>>(`${ BASE_ENDPOINT }/${ invoiceId }/payments`, payment);
	}

	updateInvoiceById(invoice: SalesInvoice): AxiosPromise<JsonResource<SalesInvoice>> {
		return axios.put<JsonResource<SalesInvoice>>(`${ BASE_ENDPOINT }/${ invoice.id }`, invoice);
	}

	updatePaymentById(invoiceId: number, payment: Payment): AxiosPromise<JsonResource<Payment>> {
		return axios.put<JsonResource<Payment>>(`${ BASE_ENDPOINT }/${ invoiceId }/payments/${ payment.id }`, payment);
	}

	deleteInvoiceById(invoiceId: number): AxiosPromise<void> {
		return axios.delete(`${ BASE_ENDPOINT }/${ invoiceId }`);
	}

	deletePaymentById(invoiceId: number, paymentId: number): AxiosPromise<JsonResource<Payment>> {
		return axios.delete(`${ BASE_ENDPOINT }/${ invoiceId }/payments/${ paymentId }`);
	}
}
