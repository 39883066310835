import { Pagination } from '@/core/types/Entities';
import { AxiosResponse } from 'axios';
import { UseTeamListContext } from '../types/useTeamList';
import useFilter from '../useFilter';
import { TeamsService } from '@/services/teams.service';
import { Team } from '@/modules/settings/types/entities';

const teamsService = new TeamsService();

const setTeams = async (ctx: UseTeamListContext, teams: Team[]): Promise<void> => new Promise((resolve, reject) => {
  const { composables } = ctx;
  try {
    composables.list.actions.setItems(teams);
    resolve();
  } catch (err: any) { reject(err); }
});

const fetchTeams = async (ctx: UseTeamListContext): Promise<AxiosResponse<Pagination<Team[]>>> => {
  const { composables: { loadingActions, filter } } = ctx;
  loadingActions.actions.set(fetchTeams.name, true);
  try {
    const urlSearchParams = filter.getters.filterUrlQuery.value;
    const url = teamsService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`;
    const res = await teamsService.getAllTeams(url);
    setTeams(ctx, res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchTeams.name, false);
  }
};

const search = async (ctx: UseTeamListContext, query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<Team[]>>> => {
  const { composables: { list ,loadingActions, filter } } = ctx;
  loadingActions.actions.set(search.name, true);
  try {
    let urlSearchParams = null;
    if(useAsFilter) {
      if(query) filter.actions.setFilter('search', query);
      else filter.actions.deleteFilter('search');
      urlSearchParams = filter.getters.filterUrlQuery.value;
    } else {
      const f = useFilter();
      if(query) f.actions.setFilter('search', query);
      urlSearchParams = f.getters.filterUrlQuery.value;
    }
    const url = teamsService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`;
    const res = await teamsService.getAllTeams(url);
    list.actions.setItems(res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(search.name, false);
  }
};

export const actions = {
  fetchTeams,
  search,
};
