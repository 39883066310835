import { ref, customRef } from 'vue';
import {debounce, DebounceSettings} from 'lodash';

// const debounce = (fn: Function, delay = 0, immediate = false) => {
//   let timeout: number|undefined
//   return (...args: any[]) => {
//     if (immediate && !timeout) fn(...args)
//     clearTimeout(timeout)

//     timeout = setTimeout(() => {
//       fn(...args)
//     }, delay)
//   }
// }

const useDebouncedRef = <T>(initialValue: T, delay: number, opts?: DebounceSettings) => {
  const state = ref(initialValue);
  const debouncedRef = customRef((track, trigger) => ({
    get() {
      track();
      return state.value;
    },
    set: debounce(
      (value: any) => {
        state.value = value;
        trigger();
      },
      delay,
      opts
    ),
  }));
  return debouncedRef;
};

export default useDebouncedRef;