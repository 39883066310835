
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { Actions } from '@/modules/purchase-invoices/store/types/StoreTypes';
	import { Actions as PurchaseInvoiceActions } from '@/modules/purchase-invoices/store/types/StoreTypes';
    import { Getters as PurchaseInvoiceGetters } from '@/modules/purchase-invoices/store/types/StoreTypes';
	import { ApprovalStatus, ProjectRelationship, ProjectRelationshipEntity, PurchaseInvoice, TransactionType } from '../../types/entities';
	import { DataTableHeader } from 'vuetify';
	import { Project } from '@/modules/projects/types/entities';
	import { ProjectsService } from '@/services/projects.service';
	import { AxiosResponse } from 'axios';
	import { Pagination } from '@/core/types/Entities';
	import { User } from '@/modules/entities/types/entities';
	import { filterProjectRelationshipsByStatusName, signAmountProjectRelationship } from '@/modules/purchase-invoices/helpers'
	import PdfRenderer from '@/modules/media/components/pdf-renderer/pdf-renderer.component.vue'
	import { PurchaseInvoicesCommandsService } from '../../services/purchase-invoices-commands.service';

	// namespaces
	const purchaseInvoices = namespace("purchaseInvoices");

	// services
	const projectsService = new ProjectsService()
	const purchaseInvoicesCommandsService = new PurchaseInvoicesCommandsService()

	@Component({
		components: {
			'pdf-renderer': PdfRenderer
		}
	})
	export default class MyPurchaseInvoicesListComponent extends Vue {

		// vuex actions
		@purchaseInvoices.Action(Actions.FETCH_APPROVAL_STATUSES) fetchApprovalStatuses!: () => Promise<void>;

		// vuex getters
		@purchaseInvoices.Getter(PurchaseInvoiceGetters.GET_APPROVAL_STATUSES_TRANSLATED) approvalStatuses!: ApprovalStatus[];
		@purchaseInvoices.Getter(PurchaseInvoiceGetters.GET_IS_LOADING) isLoading!: boolean;

		// getters
		get openApprovalStatuses() { return ['pending','partially_approved','not_approved_temporary'] }
		get openStatuses() { return ['requested','pending_claim','pending_annotation','needs_approval_claim','needs_approval_annotation'] }

		// props
		@Prop({type: Object, default: undefined})
		user!: User;

		// local state + vars
		state: {
			loading: boolean,
			status: 'open' | 'finished'
		} = {
			loading: false,
			status: 'open'
		}
		content: {
			invoices: PurchaseInvoice[],
			resultData: Pagination<PurchaseInvoice[]> | null,
		} = {
			invoices: [] as PurchaseInvoice[],
			resultData: null,
		}

		// handlers
		onClickRefresh() {
			this.fetchDataForUser(this.user)
		}

		// methods
		assignedToMe(projectRelationships: ProjectRelationship[]): ProjectRelationship[] {
			return projectRelationships.filter(pr => pr.assignedTo && pr.assignedTo.id === this.user.id)
		}
		assignedToOthers(projectRelationships: ProjectRelationship[]): ProjectRelationship[] {
			return projectRelationships.filter(pr => pr.assignedTo && pr.assignedTo.id !== this.user.id)
		}
		assignedToNobody(projectRelationships: ProjectRelationship[]): ProjectRelationship[] {
			return projectRelationships.filter(pr => pr.assignedTo == null)
		}
		toBeLabeled(projectRelationships: ProjectRelationship[]): ProjectRelationship[] {
			return projectRelationships.filter(pr => pr.status && pr.status.name && this.openStatuses.includes(pr.status.name))
		}
		toBeApproved(projectRelationships: ProjectRelationship[]): ProjectRelationship[] {
			return projectRelationships.filter(pr => pr.approvalStatus && pr.approvalStatus.name && this.openApprovalStatuses.includes(pr.approvalStatus.name))
		}
		forMyProjects(projectRelationships: ProjectRelationship[]): ProjectRelationship[] {
			return projectRelationships.filter(pr => pr.project && pr.project.projectLeaders && pr.project.projectLeaders.find(pl => pl.id === this.user.id))
		}
		// forOtherProjects(projectRelationships: ProjectRelationship[]): ProjectRelationship[] {
		// 	return projectRelationships.filter(pr => pr.project && pr.project.projectLeaders && pr.project.projectLeaders.find(pl => pl.id !== this.user.id))
		// }
		countProjectRelationshipsToBeLabeledByMe(purchaseInvoice: PurchaseInvoice) {
			if(purchaseInvoice.projectRelationships && purchaseInvoice.projectRelationships.length) {
				const items = [...this.assignedToMe(purchaseInvoice.projectRelationships), ...this.forMyProjects(purchaseInvoice.projectRelationships)]
				const uniqueItems = this.unique(items)
				return this.toBeLabeled(uniqueItems).length
			}
			return 0
		}
		countProjectRelationshipsToBeLabeledByOthers(purchaseInvoice: PurchaseInvoice) {
			if(purchaseInvoice.projectRelationships && purchaseInvoice.projectRelationships.length) {
				const items = [
					...this.assignedToOthers(purchaseInvoice.projectRelationships), 
					// ...this.forOtherProjects(purchaseInvoice.projectRelationships),
				]
				const uniqueItems = this.unique(items)
				return this.toBeLabeled(uniqueItems).length
			}
			return 0
		}
		countProjectRelationshipsToBeLabeledByNobody(purchaseInvoice: PurchaseInvoice) {
			if(purchaseInvoice.projectRelationships && purchaseInvoice.projectRelationships.length) {
				const items = [...this.assignedToNobody(purchaseInvoice.projectRelationships)]
				const uniqueItems = this.unique(items)
				return this.toBeLabeled(uniqueItems).length
			}
			return 0
		}
		countProjectRelationshipsToBeApprovedByMe(purchaseInvoice: PurchaseInvoice) {
			if(purchaseInvoice.projectRelationships && purchaseInvoice.projectRelationships.length) {
				const items = [...this.assignedToMe(purchaseInvoice.projectRelationships), ...this.forMyProjects(purchaseInvoice.projectRelationships)]
				const uniqueItems = this.unique(items)
				return this.toBeApproved(uniqueItems).length
			}
			return 0
		}
		countProjectRelationshipsToBeApprovedByOthers(purchaseInvoice: PurchaseInvoice) {
			if(purchaseInvoice.projectRelationships && purchaseInvoice.projectRelationships.length) {
				const items = [
					...this.assignedToOthers(purchaseInvoice.projectRelationships), 
					// ...this.forOtherProjects(purchaseInvoice.projectRelationships).
				]
				const uniqueItems = this.unique(items)
				return this.toBeApproved(uniqueItems).length
			}
			return 0
		}
		countProjectRelationshipsToBeApprovedByNobody(purchaseInvoice: PurchaseInvoice) {
			if(purchaseInvoice.projectRelationships && purchaseInvoice.projectRelationships.length) {
				const items = [...this.assignedToNobody(purchaseInvoice.projectRelationships)]
				const uniqueItems = this.unique(items)
				return this.toBeApproved(uniqueItems).length
			}
			return 0
		}
		unique(projectRelationships: ProjectRelationship[]): ProjectRelationship[] {
			const uniqueItems = [] as ProjectRelationship[]
			projectRelationships.forEach(pr => { if(uniqueItems.findIndex(uniqueItem => uniqueItem.id === pr.id) <= -1) uniqueItems.push(pr)})
			return uniqueItems
		}
		signAmount(amount: number, type: TransactionType) {
            return signAmountProjectRelationship(amount, type);
		}
		async fetchDataForUser(user: User) {
			// fetch invoices
			await this.fetchMyInvoices();
		}
		async fetchMyInvoices() {
            if(this.user) {
				this.state.loading = true
				try {
					const result = await purchaseInvoicesCommandsService.getMyPurchaseInvoicesToBeCompleted(undefined, this.state.status)
					this.content = { ...this.content, invoices: result.data.data, resultData: result.data }
					return result
				} catch (error: any) { console.warn(error) }
				finally {
					this.state.loading = false
				}
            }
		}
		async loadMore(data: Pagination<PurchaseInvoice[]>) {
            if(data) {
				try {
					const result = await purchaseInvoicesCommandsService.getMyPurchaseInvoicesToBeCompleted(data.links.next)
					this.content = { ...this.content, invoices: [...this.content.invoices, ...result.data.data], resultData: result.data }
					return result
				} catch (error: any) { console.warn(error) }
            }
		}
		
		// getters
		get todoByMe() {
			if(this.content.invoices && this.content.invoices.length) {
				const totalAmountToBeLabeledAssignedToMe = this.content.invoices.map(invoice => this.countProjectRelationshipsToBeLabeledByMe(invoice) > 0 ? 1 : 0).reduce((prev: number, curr: number) => prev+curr, 0)
				const totalAmountToBeApprovedAssignedToMe = this.content.invoices.map(invoice => this.countProjectRelationshipsToBeApprovedByMe(invoice) > 0 ? 1 : 0).reduce((prev: number, curr: number) => prev+curr, 0)
				return totalAmountToBeLabeledAssignedToMe + totalAmountToBeApprovedAssignedToMe
			}
			return 0
		}
		get headers() {
            return [
				{
                    text: this.$t('invoices.purchase.details.labels.name').toString(),
                    value: 'name',
                    align: 'start',
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
				{
                    text: '',
                    value: 'createdAt',
                    align: 'start text-truncate td-text-truncate-1 td-toggle',
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
				{
                    text: this.$t('invoices.purchase.details.labels.supplier').toString(),
                    value: 'supplier.name',
                    align: 'start text-truncate td-text-truncate-2 td-toggle',
                    groupable: false,
                    class:'text-no-wrap'
				} /*as DataTableHeader*/,
				{
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.labeled').toString(),
                    value: 'projectRelationships.*.status',
                    align: 'center',
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
				{
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.approved').toString(),
                    value: 'projectRelationships.*.approvalStatus',
                    align: 'center',
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                // { text: '', value: 'actions', sortable: false, groupable: false} as DataTableHeader,
            ];
		}
		
		// watchers
		@Watch('user', { immediate: true, deep: true })
		onWatchChangeUser() {
			this.fetchApprovalStatuses()
			if(this.user && this.user.id) {
				this.fetchDataForUser(this.user)
			}
		}

		// hooks
		mounted() {
			// 
		}
	}
