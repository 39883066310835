import axios, { AxiosResponse, AxiosPromise } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import { Employee, EmployeeWage, Timesheet, AbsencePeriod } from '@/modules/settings/types/entities';
import dayjs from 'dayjs';

const EMPLOYEES_ENDPOINT = '/api/v1/settings/employees';

export class EmployeesService {

	getBaseEndpoint(): string {
		return EMPLOYEES_ENDPOINT
	}

	getAllEmployees(url = `${EMPLOYEES_ENDPOINT}`): Promise<AxiosResponse<Pagination<Employee[]>>> {
		return axios.get(url);
	}

	getEmployeeById(id: number): Promise<AxiosResponse<JsonResource<Employee>>> {
		return axios.get<JsonResource<Employee>>(`${ EMPLOYEES_ENDPOINT }/${ id }`);
	}

	createEmployee(employee: Employee): AxiosPromise<JsonResource<Employee>> {
		return axios.post(`${ EMPLOYEES_ENDPOINT }`, employee);
	}

	editEmployee(payload: Employee): AxiosPromise<JsonResource<Employee>> {
		return axios.put(`${ EMPLOYEES_ENDPOINT }/${ payload.id }`, payload);
	}

	deleteEmployee(payload: number): AxiosPromise {
		return axios.delete(`${ EMPLOYEES_ENDPOINT }/${ payload }`);
	}

	createEmployeeWage(employeeId: number, wage: EmployeeWage): Promise<AxiosResponse<JsonResource<Employee>>> {
		return axios.post(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/wages`, wage)
	}

	editEmployeeWage(employeeId: number, wage: EmployeeWage): Promise<AxiosResponse<JsonResource<Employee>>> {
		return axios.put(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/wages/${ wage.id }`, wage);
	}

	deleteEmployeeWage(employeeId: number, wageId: number): Promise<AxiosResponse<JsonResource<Employee>>> {
		return axios.delete(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/wages/${ wageId }`)
	}

	getTimesheets(employeeId: number, params: URLSearchParams): Promise<AxiosResponse<JsonResource<Employee>>> {
		return axios.get<JsonResource<Employee>>(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/timesheets${params ? '?' + params.toString() : ''}`);
	}

	createTimesheet(employeeId: number, timesheet: Timesheet): AxiosPromise<JsonResource<Employee>> {
		return axios.post(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/timesheets`, timesheet);
	}
	
	editTimesheet(employeeId: number, timesheet: Timesheet): Promise<AxiosResponse<JsonResource<Employee>>> {
		return axios.put(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/timesheets/${ timesheet.id }`, timesheet);
	}

	getWorkDays(employeeId: number, params: URLSearchParams): Promise<AxiosResponse<JsonResource<Employee>>> {
		return axios.get(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/work-days${params ? '?' + params.toString() : ''}`);
	}

	getAbsencePeriods(employeeId: number, params: URLSearchParams): Promise<AxiosResponse<JsonResource<Employee>>> {
		return axios.get(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/absence-periods${params ? '?' + params.toString() : ''}`);
	}

	createAbsencePeriod(employeeId: number, absencePeriod: AbsencePeriod): AxiosPromise<JsonResource<Employee>> {
		return axios.post(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/absence-periods`, absencePeriod);
	}

	updateAbsencePeriod(employeeId: number, absencePeriod: AbsencePeriod): AxiosPromise<JsonResource<Employee>> {
		return axios.put(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/absence-periods/${ absencePeriod.id }`, absencePeriod);
	}

	deleteAbsencePeriod(employeeId: number, absencePeriodId: number): Promise<AxiosResponse<JsonResource<Employee>>> {
		return axios.delete(`${ EMPLOYEES_ENDPOINT }/${ employeeId }/absence-periods/${ absencePeriodId }`)
	}
}
