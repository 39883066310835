import {Module} from 'vuex';
import {EntitiesState} from "@/modules/entities/store/types/EntitiesState";
import {RootState} from "@/core/types/RootState";

import {store as clients} from "@/modules/entities/store/modules/clients";
import {store as suppliers} from "@/modules/entities/store/modules/suppliers";

export const store: Module<EntitiesState, RootState> = {
    namespaced: true,
    modules: {
        'clients': clients,
        'suppliers': suppliers
    }
};
