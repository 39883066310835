import { reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import useFilter from '@/composables/useFilter';
import { UseTeamState, UseTeamComposables, UseTeamContext } from './types/useTeam';
import { merge } from 'lodash';
import { Options } from './types/composable';
import { actions as teamActions } from './actions/useTeam';
import { useDynamicProps } from './useDynamicProps';
import { Team } from '@/modules/settings/types/entities';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseTeamState>): UseTeamState => {
  return merge(
    {
      team: null,
      teamValidationErrors: null,
      teamBusinessErrors: null,
    },
    state
  );
};

/**
 * `UseTeam`-composable
 *   - All Team-related interactions and functionality can be stored here.
 *   - If you need a global store, than just use this composable in eg a `store.ts`
 *
 */
export default function useTeam(options?: Options<UseTeamState, UseTeamComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    filter: useFilter(),
    showDialog: useDynamicProps<boolean>(),
    loadingActions: useDynamicProps<boolean>(),
  });
  const ctx: UseTeamContext = { state, composables };
  const actions = wrapActions(ctx, teamActions);

  // return state and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // other
      showDialog: ctx.composables.showDialog.getters,
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      team: computed<Team | null>(() => state.team),
      teamValidationErrors: computed(() => state.teamValidationErrors),
      teamBusinessErrors: computed(() => state.teamBusinessErrors),
    },
    actions: {
      ...ctx.composables.filter.actions,
      showDialog: ctx.composables.showDialog.actions.set,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...actions,
    },
  };
}