
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { PropType } from 'vue';
  import { Project } from '@/modules/projects/types/entities';
  import { Actions, Getters } from '@/modules/projects/store/types/StoreTypes';
  import { namespace } from 'vuex-class';
  import ConfirmDialogComponent from '@/core/components/confirm-dialog/confirm-dialog.component.vue';
  import { Supplier } from '@/modules/entities/types/entities';
  import { Team } from '@/modules/settings/types/entities';
  import { Quote } from '@/modules/calendar/interfaces/project/quote';
  import { PlannerPeriod } from '@/modules/calendar/interfaces/project/plannerPeriod';
  import { cloneDeep } from 'lodash'

  const projects = namespace('projects');

  @Component({
    components: {
      'confirm-dialog': ConfirmDialogComponent,
      'quote-modal': () =>
        import(
          /* webpackChunkName: "quoteModal" */ '@/modules/projects/components/quote-modal/quote-modal.component.vue'
        ),
    },
  })
  export default class ProjectQuotesListComponent extends Vue {
    @projects.Action(Actions.EDIT_QUOTE) editQuote!: (payload: Quote) => void;
    @projects.Action(Actions.REMOVE_QUOTE) removeQuote!: (payload: Quote) => void;
    @projects.Getter(Getters.GET_VALIDATION_ERRORS) validationErrors!: Object;

    @Prop({ type: Array as PropType<Quote[]>, default: () => [] }) quotes!: Quote[];
    @Prop({ type: Object as PropType<Project>, required: true }) project!: Project;

    quotesCopy: Quote[] | null = null
    projectCopy: Project | null = null

    state: {
      removeQuote: boolean;
      offerRequestedPicker: boolean;
      offerReceivedPicker: boolean;
      offerDonePicker: boolean;
      addQuoteSupplier: boolean;
    } = {
      removeQuote: false,
      offerRequestedPicker: false,
      offerReceivedPicker: false,
      offerDonePicker: false,
      addQuoteSupplier: false,
    };

    content: {
      removeQuote: Quote;
      quote: Quote | null;
    } = {
      removeQuote: {} as Quote,
      quote: null,
    };

    resetContent(): void {
      this.content = {
        removeQuote: {
          id: 0,
          withOffer: true,
          contractorType: {
            id: 0,
            name: '',
            abbreviation: '',
            description: '',
            sequence: 0,
          },
          contractor: undefined,
          contractorTempAssigned: false,
          contractorPermAssigned: false,
          contractorTypePermAssigned: false,
          contractorTypeTempAssigned: false,
          offerDone: null,
          offerReceived: null,
          offerRequested: null,
          comment: '',
          plannerPeriods: [],
        },
        quote: null,
      };
    }

    onQuoteAssignmentToggle(quote: Quote, value: string): void {
      let quoteEdit: Quote = cloneDeep(quote)
      this.content.quote = quoteEdit
      switch (value) {
        case 'contractorType.none':
          quoteEdit.contractorTypeTempAssigned = false;
          quoteEdit.contractorTypePermAssigned = false;
          break;
        case 'contractorType.temp':
          quoteEdit.contractorTypeTempAssigned = true;
          quoteEdit.contractorTypePermAssigned = false;
          break;
        case 'contractorType.perm':
          quoteEdit.contractorTypePermAssigned = true;
          quoteEdit.contractorTypeTempAssigned = false;
          break;
        case 'contractor.none':
          quoteEdit.contractorPermAssigned = false;
          quoteEdit.contractorTempAssigned = false;
          break;
        case 'contractor.temp':
          quoteEdit.contractorTempAssigned = true;
          quoteEdit.contractorPermAssigned = false;
          break;
        case 'contractor.perm':
          quoteEdit.contractorPermAssigned = true;
          quoteEdit.contractorTempAssigned = false;
          break;
        default:
          break;
      }
      this.editQuote(quoteEdit);
    }

    // onDetachContractorFromQuote(quote: Quote): void {
    //   this.editQuote({
    //     ...quote,
    //     contractor: undefined,
    //   });
    // }

    onEditQuoteDate(quote: Quote, value: Date, quoteProperty: string): void {
      const quoteEdit: Quote = cloneDeep(quote);
      switch (quoteProperty) {
        case 'offerRequested':
          quoteEdit.offerRequested = value;
          break;
        case 'offerReceived':
          quoteEdit.offerReceived = value;
          break;
        case 'offerDone':
          quoteEdit.offerDone = value;
          break;
        default:
          break;
      }
      this.editQuote(quoteEdit);
    }

    onAttachedQuoteRemove(quote: Quote): void {
      this.resetContent();
      this.state.removeQuote = true;
      this.content.removeQuote = quote;
    }

    onRemoveQuote(): void {
      if (this.content.removeQuote.id !== 0) {
        this.removeQuote(this.content.removeQuote);
      }
    }

    onQuoteSupplierSelect(quote: Quote): void {
      this.resetContent();
      this.content.quote = quote;
      this.state.addQuoteSupplier = true;
    }

    displayQuoteConfirmDialog(quote: Quote): boolean {
      return this.state.removeQuote && this.content.removeQuote.id === quote.id;
    }

    isQuoteAlsoAnOrder(quote: Quote | PlannerPeriod): boolean {
      return this.project.orders.some((x) => x.id === quote.id);
    }

    isTeam(contractor: Supplier | Team): contractor is Team {
      return 'abbreviation' in contractor;
    }

    cloneDeep(item: any) {
      return cloneDeep(item)
    }

    @Watch('quotes', {deep: true, immediate: true})
    onQuotesChange(newVal: Quote[]): void {
      this.quotesCopy = cloneDeep(newVal)
      if (this.content.quote) {
        this.content.quote = cloneDeep(this.quotesCopy.find((x: Quote) => x.id === (this.content.quote && this.content.quote.id))) || null;
      }
    }
  }
