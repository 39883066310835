
  import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
  import { maxLength, required } from 'vuelidate/lib/validators';
  import { Validate } from 'vuelidate-property-decorators';
  import { validationMixin } from 'vuelidate';
  import { WorkflowFunctionGroup } from '@/modules/workflow/types/entities';

  @Component({
    mixins: [validationMixin],
  })
  export default class FunctionGroupModalComponent extends Vue {
    @PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
    @Prop() functionGroupItem?: WorkflowFunctionGroup;
    @Prop() getFunctionGroupColour!: Function;

    @Validate({
      required,
      maxLength: maxLength(255),
    })
    nameValue = '';

    @Validate({
      maxLength: maxLength(255),
    })
    descriptionValue = '';

    @Validate({ required })
    colourValue = '';

    colourList: string[] = [
      'GREEN_1',
      'GREEN_2',
      'GREEN_3',
      'GREEN_4',
      'PURPLE_1',
      'PURPLE_2',
      'PURPLE_3',
      'BLUE_1',
      'BLUE_2',
      'BLUE_3',
      'BLUE_4',
      'YELLOW_1',
      'YELLOW_2',
      'RED_1',
      'RED_2',
      'PINK_1',
      'ORANGE_1',
      'GRAY_1',
      'GRAY_2',
    ];

    onSelectColourValue(colour: string) {
      if (colour !== this.colourValue) {
        this.colourValue = colour;
        // @ts-ignore
        this.$v.colourValue.$touch();
      }
    }

    created() {
      if (this.functionGroupItem) {
        if (!this.colourList.some((x) => x === this.functionGroupItem!.color)) {
          this.colourList.push(this.functionGroupItem.color);
        }
        this.nameValue = this.functionGroupItem.name;
        this.descriptionValue = this.functionGroupItem.description;
        this.colourValue = this.functionGroupItem.color;
      }
    }

    get isFormInvalidOrTouched(): boolean {
      // @ts-ignore
      return !(!this.$v.$invalid && this.$v.$anyDirty);
    }

    handleFormSubmit(): void {
      if (this.isFormInvalidOrTouched) {
        return;
      } else {
        this.emitSaveFunctionGroup();
      }
    }

    @Emit('saveFunctionGroup')
    emitSaveFunctionGroup() {
      this.showModalSync = false;
      return {
        name: this.nameValue,
        description: this.descriptionValue,
        color: this.colourValue,
      };
    }
  }
