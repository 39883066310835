
  import { PropType } from 'vue';
  import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { AxiosError, AxiosResponse } from 'axios';

  import { CompanyType, Supplier } from '../../types/entities';
  import {
    Actions as SuppliersActions,
    Getters as SupplierGetters,
  } from '../../store/modules/suppliers/types/StoreTypes';
  import TileGroupComponent from '../tile-group/tile-group.component.vue';
  import DetailsMultiSelectComponent from '@/core/components/details-page/details-multi-select.component.vue';
  import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
  import '@/styles/input.scss';
  import { ProvinceEntity } from '../../types/province.entity';
  import { JsonResource } from '@/core/types/Entities';

  const suppliers = namespace('entities/suppliers');

  @Component({
    mixins: [validationMixin],
    components: {
      'tile-group': TileGroupComponent,
      'details-multi-select': DetailsMultiSelectComponent,
    },
  })
  export default class SupplierModalComponent extends Vue {
    @suppliers.Action(SuppliersActions.FETCH_CONTRACTOR_TYPES) fetchContractorTypes!: () => Promise<void>;
    @suppliers.Action(SuppliersActions.FETCH_COMPANY_TYPES) fetchCompanyTypes!: () => Promise<void>;
    @suppliers.Action(SuppliersActions.FETCH_SUPPLIER_TYPES) fetchSupplierTypes!: () => Promise<void>;
    @suppliers.Action(SuppliersActions.FETCH_PROVINCE_LIST) fetchProvinceList!: () => Promise<ProvinceEntity[]>;
    @suppliers.Action(SuppliersActions.CREATE_NEW_SUPPLIER) createNewSupplier!: (
      supplier: Supplier
    ) => Promise<AxiosResponse<JsonResource<Supplier>>>;
    @suppliers.Action(SuppliersActions.EDIT_SUPPLIER_BY_ID) editSupplierById!: (
      payload: Supplier
    ) => Promise<AxiosResponse<JsonResource<Supplier>>>;

    @suppliers.Getter(SupplierGetters.GET_CONTRACTOR_TYPES) contractorTypes!: ContractorBranch[];
    @suppliers.Getter(SupplierGetters.GET_PROVINCE_LIST) provinces!: ProvinceEntity[];
    @suppliers.Getter(SupplierGetters.GET_SUPPLIER_TYPES) supplierTypes!: ProvinceEntity[];
    @suppliers.Getter(SupplierGetters.GET_COMPANY_TYPES) companyTypes!: CompanyType[];
    @suppliers.Getter(SupplierGetters.GET_LOADING) isLoading!: boolean;

    @PropSync('showModal', { type: Boolean, default: false }) showModalSync!: boolean;

    @Prop({ type: Object as PropType<Supplier> }) supplier!: Supplier;

    autocomplete!: google.maps.places.Autocomplete;
    citiesAutocomplete!: google.maps.places.Autocomplete;
    postalAutocomplete!: google.maps.places.Autocomplete;

    validations() {
      return {
        supplier: {
          name: { required },
          companyTypes: { required },
        },
      };
    }

    validationErrors: any = null;

    get nameErrors(): string[] {
      let errors: string[] = [];
      this.$v.supplier.name &&
        !this.$v.supplier.name.required &&
        errors.push(this.$t('suppliers.add.error.required.name') as string);
      return errors;
    }

    get companyTypesErrors(): string[] {
      let errors: string[] = [];
      this.$v.supplier.companyTypes &&
        !this.$v.supplier.companyTypes.required &&
        errors.push(this.$t('suppliers.add.error.required.companyTypes') as string);
      return errors;
    }

    created(): void {
      this.fetchContractorTypes();
      this.fetchProvinceList();
      this.fetchCompanyTypes();
      this.fetchSupplierTypes();
    }

    mounted(): void {
      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.streetInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['geocode'] }
      );
      this.citiesAutocomplete = new google.maps.places.Autocomplete(
        (this.$refs.cityInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['(cities)'] }
      );
      this.postalAutocomplete = new google.maps.places.Autocomplete(
        (this.$refs.postalInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['(regions)'] }
      );

      this.autocomplete.setFields(['address_component']);
      this.citiesAutocomplete.setFields(['address_component']);
      this.postalAutocomplete.setFields(['address_component']);

      this.autocomplete.addListener('place_changed', this.fillInAddress);
      this.citiesAutocomplete.addListener('place_changed', this.fillInCity);
      this.postalAutocomplete.addListener('place_changed', this.fillInPostalCode);
    }

    fillInAddress(): void {
      const place = this.autocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];

        switch (addressType) {
          case 'route':
            this.supplier.street = component.long_name;
            break;
          case 'postal_code':
            if (!this.supplier.postalCode) this.supplier.postalCode = component.short_name;
            break;
          case 'locality':
            if (!this.supplier.city) this.supplier.city = component.long_name;
            break;
          case 'street_number':
            this.supplier.streetNumber = component.short_name;
            break;
          case 'administrative_area_level_2':
            const province = this.provinces.find((x) =>
              x.name.toLowerCase().includes(component.long_name.toLowerCase())
            );
            if (province) this.supplier.province = province;
            break;
          default:
            break;
        }
      }
    }

    fillInCity(): void {
      const place = this.citiesAutocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];
        if (addressType === 'locality') {
          this.supplier.city = component.long_name;
        }
      }
    }

    fillInPostalCode(): void {
      const place = this.postalAutocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];
        if (addressType === 'postal_code') {
          this.supplier.postalCode = component.short_name;
        }
      }
    }

    translateCompanyType(item: CompanyType) {
      return this.$i18n.t(`${item.abbreviation}`);
    }

    translateProvince(item: ProvinceEntity) {
      return this.$i18n.t(`suppliers.details.${item.name}`);
    }

    onSubmit(): void {
      this.$v.$touch();

      if (this.supplier.id > 0) {
        this.editSupplierById(this.supplier)
          .then(() => {
            this.$emit('onResetDialogContent');
            this.showModalSync = false;
          })
          .catch((err: AxiosError) => {
            if (err.response && err.response.status === 422) {
              this.validationErrors = err.response.data.errors;
            } else {
              throw err;
            }
          });
      } else {
        const supplier: Supplier = JSON.parse(JSON.stringify(this.supplier));
        supplier.bankAccounts = supplier.bankAccounts.filter((x) => x.accountNumber);
        this.createNewSupplier(supplier)
          .then((result: AxiosResponse<JsonResource<Supplier>>) => {
            this.showModalSync = false;
            this.$emit('onSupplierSuccessfulCreation', result.data.data);
          })
          .catch((err: AxiosError) => {
            if (err.response && err.response.status === 422) {
              this.validationErrors = err.response.data.errors;
            } else {
              throw err;
            }
          });
      }
    }

    onAddTeleinformationDetails(): void {
      this.supplier.teleinformations.push({
        id: 0,
        landlinePhone: '',
        landlinePhoneComment: '',
        mobilePhone: '',
        mobilePhoneComment: '',
        email: '',
        emailComment: '',
        isMainContactInformation: !this.supplier.teleinformations.length,
        user: undefined,
        updatedAt: '',
      });
    }

    onAddBankAccount(): void {
      this.supplier.bankAccounts.push({
        id: 0,
        accountNumber: '',
        isMain: !this.supplier.bankAccounts.length,
      });
    }

    @Watch('supplier.name')
    onSupplierNameChange() {
      if (this.validationErrors && this.validationErrors.name) this.validationErrors.name = null;
    }

    @Watch('supplier.companyTypes')
    onSupplierCompanyTypesChange() {
      if (this.validationErrors && this.validationErrors.companyTypes) this.validationErrors.companyTypes = null;
    }
  }
