
import {defineComponent, computed, getCurrentInstance} from 'vue';
  import { Holiday, HolidayEntity } from '@/modules/settings/store/modules/holidays/types/HolidaysState';
  import useHoliday from '@/composables/useHoliday';
  import store from '../../store/modules/holidays';
  import { cloneDeep } from 'lodash';

  export default defineComponent({
    name: 'Holidays',
    setup() {

      const vm = getCurrentInstance()!.proxy

      // composables
      const { state: holidayListState, actions: holidayListActions } = store.holidayListView;
      const { getters: createHolidayGetters, actions: createHolidayActions } = useHoliday();
      const { getters: updateHolidayGetters, actions: updateHolidayActions } = useHoliday();
      const { getters: deleteHolidayGetters, actions: deleteHolidayActions } = useHoliday();

      /**
       * Handlers
       *
       */
      const onClickCreateHoliday = async () => {
        await createHolidayActions.createHoliday().then(() => {
          if (createHolidayGetters.holiday.value)
            holidayListActions.addItem(cloneDeep(createHolidayGetters.holiday.value));
          createHolidayActions.showDialog('add', false);
          createHolidayActions.setHoliday(null);
        });
      };
      const onClickUpdateHoliday = async () => {
        await updateHolidayActions.updateHoliday().then(() => {
          if (updateHolidayGetters.holiday.value) {
            holidayListActions.replaceItem(
              cloneDeep(updateHolidayGetters.holiday.value),
              (x, item) => x.id === item.id
            );
          }
          updateHolidayActions.showDialog('edit', false);
          updateHolidayActions.setHoliday(null);
        });
      };
      const onClickDeleteHoliday = async () => {
        await deleteHolidayActions.deleteHoliday().then(() => {
          if (deleteHolidayGetters.holiday.value) {
            holidayListActions.removeItem(deleteHolidayGetters.holiday.value, (x, item) => x.id === item.id);
          }
          deleteHolidayActions.showDialog('delete', false);
          deleteHolidayActions.setHoliday(null);
        });
      };
      const onChangeFilter = (filter: string, value: string) => {
        holidayListActions.setFilter(filter, value);
        holidayListActions.fetchHolidays();
      };

      /**
       * Methods
       *
       */
      const openModal = (modal: string, holiday: Holiday) => {
        switch (modal) {
          case 'add':
            createHolidayActions.clearHolidayErrors();
            if (!createHolidayGetters.holiday.value) createHolidayActions.setHoliday(new HolidayEntity());
            createHolidayActions.showDialog(modal, true);
            break;
          case 'edit':
            if (holiday.id) {
              updateHolidayActions.fetchHoliday(holiday.id).then(() => {
                updateHolidayActions.showDialog(modal, true);
              });
            }
            break;
          case 'delete':
            deleteHolidayActions.setHoliday(holiday);
            deleteHolidayActions.showDialog(modal, true);
            break;
        }
      };

      /**
       * Computed
       *
       * Computed values are cached. Only when the underlying data changes, the value gets re-computed.
       *   so, using a cached copy of the prop in combination with :value and @input on form elements, we can
       *   emit changes and let the parent mutate the changes
       */
      // const userCopy = computed<User>(() => JSON.parse(JSON.stringify(props.user)))
      const headers = computed<any>(() => {
        return [
          {
            text: vm.$t('dictionary.name'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.type'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.startDate'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.endDate'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.actions'),
            sortable: false,
          },
        ];
      });
      const loading = computed(
        () =>
          holidayListState.isLoading.value ||
          createHolidayGetters.isLoading.value ||
          updateHolidayGetters.isLoading.value ||
          deleteHolidayGetters.isLoading.value
      );

      /**
       * Watchers
       *
       */

      /**
       * Initial data
       *
       */
      holidayListActions.fetchHolidays();
      holidayListActions.fetchHolidayTypes(['common', 'collective']);

      /**
       * Return state + actions
       */
      return {
        onClickCreateHoliday,
        onClickUpdateHoliday,
        onClickDeleteHoliday,
        onChangeFilter,

        openModal,

        headers,
        loading,

        holidayListState,
        createHolidayGetters,
        updateHolidayGetters,
        deleteHolidayGetters,
        holidayListActions,
        createHolidayActions,
        updateHolidayActions,
        deleteHolidayActions,
      };
    },
  });
