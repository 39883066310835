
  import useProjectHelpers from '@/modules/projects/helpers/useProjectHelpers';
  import { Project } from '@/modules/projects/types/entities';
  import { computed, defineComponent, ref, watch, PropType } from 'vue';
  import { CalendarProject } from '../interfaces/project/CalendarProject';

  export default defineComponent({
    emits: ['onSelectProjectItem'],
    props: {
      project: {
        type: Object as PropType<CalendarProject | Project>,
        required: true,
      },
    },
    setup(props, {}) {
      const projectHelpers = useProjectHelpers();
      const isChecked = ref(false);
      return {
        ...projectHelpers,
        isChecked,
      };
    },
  });
