import {RouteConfig} from 'vue-router';
import HomeComponent from '@/modules/home/views/home/home.component.vue';

export const routes: RouteConfig[] = [
	{
		path: '',
		redirect: '/home'
	},
	{
		path: '/home',
		name: 'home',
		component: HomeComponent,
		meta: {
			requiresAuth: true,
			auth: {
				roles: 'ACCESS_PAGE__HOME',
				forbiddenRedirect: '/403'
			}
		}
	}
];
