import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import Vue from 'vue';
import { ClientState } from '@/modules/entities/store/modules/clients/types/ClientState';
import { RootState } from '@/core/types/RootState';
import { Actions, Getters, Mutations } from '@/modules/entities/store/modules/clients/types/StoreTypes';
import { Client, Comment } from '@/modules/entities/types/entities';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { ClientService } from '@/services/client.service';
import CommandsService from '@/services/commands.service';
import { CommentsService } from '@/services/comments.service';

const clientService = new ClientService();
const commentsService = new CommentsService();
const commandsService = new CommandsService();

const getters: GetterTree<ClientState, RootState> = {
	[Getters.GET_CLIENT_DETAILS]: (state: ClientState) => {
		return state.inspectedClient;
	},
	[Getters.GET_FETCHED_CLIENTS]: (state: ClientState) => {
		return state.clients;
	},
	[Getters.GET_LOADING]: (state: ClientState) => {
		return state.isLoading;
	}
};

const actions: ActionTree<ClientState, RootState> = {
	[Actions.APPEND_FETCHED_CLIENTS]: ({ commit, state }: ActionContext<ClientState, RootState>, payload: Client) => {
		commit(Mutations.MUTATE_FETCHED_CLIENTS, [
			payload,
			...state.clients
		]);
	},
	[Actions.DELETE_CLIENT_BY_ID]: async ({ commit }: ActionContext<ClientState, RootState>, clientId: number) => {
		commit(Mutations.MUTATE_LOADING, true);
		await clientService.deleteClientById(clientId)
		.then((res: AxiosResponse<void>) => {
			commit(Mutations.MUTATE_INSPECTED_CLIENT);
		})
		.finally(() => commit(Mutations.MUTATE_LOADING, false));
	},
	[Actions.DESTROY_CLIENT_DETAILS]: ({ commit }: ActionContext<ClientState, RootState>) => {
		commit(Mutations.MUTATE_INSPECTED_CLIENT);
	},
	[Actions.CREATE_NEW_CLIENT]: async ({ commit, dispatch }: ActionContext<ClientState, RootState>, payload: Client): Promise<AxiosResponse<JsonResource<Client>>> => {
		commit(Mutations.MUTATE_LOADING, true);
		return await clientService
			.createClient(payload)
			.finally(() => {
				commit(Mutations.MUTATE_LOADING, false)
			});
	},
	[Actions.GET_CLIENT_HIGHEST_PROJECT_NAME]: async ({ commit }: ActionContext<ClientState, RootState>, clientId: number): Promise<AxiosResponse<{ projectCount: string }>> => {
		commit(Mutations.MUTATE_LOADING, true);
		return await commandsService.getClientHighestProjectSequence(clientId)
		.finally(() => commit(Mutations.MUTATE_LOADING, false));
	},
	[Actions.EDIT_CLIENT_BY_ID]: async ({ commit }: ActionContext<ClientState, RootState>, payload: Client) => {
		commit(Mutations.MUTATE_LOADING, true);
		await clientService.editClientById(payload)
		.then((res: AxiosResponse<JsonResource<Client>>) => {
			commit(Mutations.MUTATE_INSPECTED_CLIENT, res.data.data);
		})
		.finally(() => commit(Mutations.MUTATE_LOADING, false));
	},
	[Actions.FETCH_CLIENT_BY_ID]: async ({ commit }: ActionContext<ClientState, RootState>, payload: string): Promise<AxiosResponse<JsonResource<Client>>> => {
		commit(Mutations.MUTATE_LOADING, true);
		return await clientService.getClientById(parseInt(payload))
		.then((res: AxiosResponse<JsonResource<Client>>) => {
			commit(Mutations.MUTATE_INSPECTED_CLIENT, res.data.data);
			return res;
		})
		.finally(() => {
			commit(Mutations.MUTATE_LOADING, false);
		});
	},
	[Actions.FETCH_CLIENTS_BY_FILTER]: async ({ commit, state }: ActionContext<ClientState, RootState>) => {
		commit(Mutations.MUTATE_LOADING, true);

		let url = '/api/v1/clients';
		if (state.filters.nameQuery !== null && !state.filters.nameQuery.startsWith(' ') && state.filters.nameQuery !== '') {
			url += `?name=${ state.filters.nameQuery }`;
		}

		await clientService.getAllClients(url).then((res: AxiosResponse<Pagination<Client[]>>) => {
			commit(Mutations.MUTATE_PAGINATION_DETAILS, res.data);
			commit(Mutations.MUTATE_FETCHED_CLIENTS, res.data.data);
		}).finally(() => commit(Mutations.MUTATE_LOADING, false));
	},
	[Actions.FETCH_NEXT_PAGE]: async ({ commit, state }: ActionContext<ClientState, RootState>) => {
		if (state.paginationDetails && state.paginationDetails.next) {
			commit(Mutations.MUTATE_LOADING, true);

			let urlParameters = '';
			if (state.filters.nameQuery !== null && !state.filters.nameQuery.startsWith(' ') && state.filters.nameQuery !== '') {
				urlParameters += `&name=${ state.filters.nameQuery }`;
			}

			await clientService.getAllClients(state.paginationDetails.next.concat(urlParameters)).then((res: AxiosResponse<Pagination<Client[]>>) => {
				commit(Mutations.MUTATE_PUSH_NEXT_PAGE, res.data);
			}).finally(() => commit(Mutations.MUTATE_LOADING, false));
		}
	},
	[Actions.SET_CLIENTS_FILTER_CLEAR_NO_FETCH]: ({ commit }: ActionContext<ClientState, RootState>) => {
		commit(Mutations.MUTATE_FILTERS_NAME_QUERY, '');
	},
	[Actions.SET_CLIENTS_FILTER_NAME_QUERY]: ({ commit, dispatch }: ActionContext<ClientState, RootState>, payload: string) => {
		commit(Mutations.MUTATE_FILTERS_NAME_QUERY, payload);
		dispatch(Actions.FETCH_CLIENTS_BY_FILTER);
	},
	[Actions.TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_CLIENT]: async ({ commit }: ActionContext<ClientState, RootState>, payload: { clientId: number; contactId: number }) => {
		commit(Mutations.MUTATE_LOADING, true);
		await clientService.toggleAssociationContactToClient(payload.clientId, payload.contactId)
			.then((res: AxiosResponse<JsonResource<Client>>) => {
				commit(Mutations.MUTATE_INSPECTED_CLIENT, res.data.data);
			})
			.finally(() => commit(Mutations.MUTATE_LOADING, false));
	},
	[Actions.SAVE_COMMENT]: async ({ commit, state }: ActionContext<ClientState, RootState>, payload: Comment) => {
		commit(Mutations.MUTATE_LOADING, true);
		await commentsService.createComment(payload)
		.then((res: AxiosResponse<JsonResource<Comment>>) => {
			let comments: Comment[] = [];
			if (state.inspectedClient) {
				if (state.inspectedClient.comments) {
					comments = [
						...state.inspectedClient.comments
					];
				}
				comments = [
					...comments,
					res.data.data
				];
				const inspectedClient = {
					...state.inspectedClient,
					comments
				};
				commit(Mutations.MUTATE_INSPECTED_CLIENT, inspectedClient);
			}
		})
		.finally(() => commit(Mutations.MUTATE_LOADING, false));
	},
	[Actions.FILTER_CLIENT_CONTACTS]: ({ commit, state }: ActionContext<ClientState, RootState>, payload: number) => {
		const client = {
			...state.inspectedClient,
			contacts: state.inspectedClient && state.inspectedClient.contacts.filter(x => x.id !== payload)
		};
		commit(Mutations.MUTATE_INSPECTED_CLIENT, client);
	},
	[Actions.CLEAR_FETCHED_CLIENTS]: ({ commit }: ActionContext<ClientState, RootState>) => {
		commit(Mutations.MUTATE_FETCHED_CLIENTS, []);
	}
};

const mutations: MutationTree<ClientState> = {
	[Mutations.MUTATE_INSPECTED_CLIENT]: (state: ClientState, payload?: Client) => {
		if (payload && !payload.teleinformations) {
			payload.teleinformations = [];
		}

		if (payload && !payload.projects) {
			payload.projects = [];
		}

		if (payload && !payload.contacts) {
			payload.contacts = [];
		}
		state.inspectedClient = payload ? payload : undefined;
	},
	[Mutations.MUTATE_PAGINATION_DETAILS]: (state: ClientState, payload: Pagination<any>) => {
		state.paginationDetails = {
			first: payload.links.first,
			last: payload.links.last,
			prev: payload.links.prev,
			next: payload.links.next,
			current_page: payload.meta.current_page
		};
	},
	[Mutations.MUTATE_FETCHED_CLIENTS]: (state: ClientState, payload?: Client[]) => {
		state.clients = payload ? payload : [];
	},
	[Mutations.MUTATE_FILTERS_NAME_QUERY]: (state: ClientState, payload: string) => {
		state.filters.nameQuery = payload;
	},
	[Mutations.MUTATE_LOADING]: (state: ClientState, payload: boolean) => {
		state.isLoading = payload;
	},
	[Mutations.MUTATE_PUSH_NEXT_PAGE]: (state: ClientState, payload: Pagination<Client[]>) => {
		state.paginationDetails = {
			first: payload.links.first,
			last: payload.links.last,
			prev: payload.links.prev,
			next: payload.links.next,
			current_page: payload.meta.current_page
		};

		state.clients = [
			...state.clients,
			...payload.data
		];
	}
};

const state: ClientState = {
	isLoading: false,
	inspectedClient: undefined,
	clients: [],
	paginationDetails: undefined,
	filters: {
		nameQuery: ''
	}
};

export const store: Module<ClientState, RootState> = {
	namespaced: true,
	actions,
	getters,
	state,
	mutations
};
