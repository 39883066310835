var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"supplier-branch",class:{
				'supplier-branch__heading--dimmed': !_vm.branches.length,
				'opened': _vm.showListSync,
				'disabled': !_vm.branches.length
			}},[_c('div',{staticClass:"supplier-branch__heading",class:{
					'supplier-branch__heading--opened': _vm.showListSync,
					'disabled': !_vm.branches.length
				}},[_vm._v(" "+_vm._s(_vm.$t('suppliers.details.companyLocations.title'))+" ("+_vm._s(_vm.branches.length || 0)+") "),_c('button',{staticClass:"supplier-branch__button",attrs:{"data-testid":"button--add"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('onToggleSupplierBranchModal')}}},[_vm._v(" "+_vm._s(_vm.$t('suppliers.details.companyLocations.add'))+" ")])]),_c('transition',{attrs:{"name":"slide-y-transition"}},[(_vm.showListSync)?_c('div',{staticClass:"supplier-branch__container"},[_vm._l((_vm.branches),function(companyLocation,idx){return _c('supplier-branch-item',{key:`supplier-branch-item-${ idx }`,attrs:{"companyLocation":companyLocation},on:{"onOpenSupplierBranchDetails":function($event){return _vm.$emit('onOpenSupplierBranchDetails', $event)},"onToggleSupplierBranchModal":function($event){return _vm.$emit('onToggleSupplierBranchModal', $event)},"onRemoveSupplierLocation":_vm.onRemoveSupplierLocation}})}),(_vm.supplierBranchListState.deleteBranch)?_c('div',{staticClass:"supplier-branch__delete--bar"},[_c('confirm-dialog',{attrs:{"showDialog":_vm.supplierBranchListState.deleteBranch,"title":"suppliers.messages.confirmCompanyLocationDeletion","title-argument":{ name: _vm.supplierBranchListContent.delete.commercialName }},on:{"onCancel":function($event){_vm.supplierBranchListState.deleteBranch = false},"onRemove":_vm.removeSupplierLocation}})],1):_vm._e()],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }