export const enum Actions {
	FETCH_WORKFLOW_DATA = 'FETCH_DATA',
	FETCH_NEXT_PROJECTS = 'FETCH_NEXT_PROJECTS',
	FETCH_PROJECTS_BY_WORKFLOW_FILTER = 'FETCH_PROJECTS_BY_WORKFLOW_FILTER',
	FETCH_PROJECTS_BY_USER_FILTER = 'FETCH_PROJECTS_BY_USER_FILTER',
	SET_WORKFLOW_LOADING = 'SET_LOADING',
	SET_WORKFLOW_USER_SELECTED_CONSTRUCTION_SITES = 'SET_USER_SELECTED_CONSTRUCTION_SITES',
	SET_WORKFLOW_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN',
	SET_WORKFLOW_PROJECT_WORKFLOW_TASK = 'SET_PROJECT_WORKFLOW_TASK',
	SET_WORKFLOW_SHOW_FUNCTION_GROUPS = 'SET_SHOW_FUNCTION_GROUPS',
	SET_WORKFLOW_TASKS = 'SET_TASKS',
	ADD_WORKFLOW_SELECTED_PROJECT = 'ADD_SELECTED_PROJECT',
	SET_HIGHLIGHTED_PROJECT_ID = 'SET_HIGHLIGHTED_PROJECT_ID',
	REMOVE_WORKFLOW_SELECTED_PROJECT = 'REMOVE_SELECTED_PROJECT',
	ADD_WORKFLOW_EXPANDED_PROJECT = 'ADD_EXPANDED_PROJECT',
	REMOVE_WORKFLOW_EXPANDED_PROJECT = 'REMOVE_EXPANDED_PROJECT',
	ADD_WORKFLOW_EXPANDED_TASK = 'ADD_EXPANDED_TASK',
	REMOVE_WORKFLOW_EXPANDED_TASK = 'REMOVE_EXPANDED_TASK',
	SAVE_TASK_NOTE = 'SAVE_TASK_NOTE',
	DELETE_TASK_NOTE = 'DELETE_TASK_NOTE',
	TOGGLE_ALL_TASKS_PANELS = 'TOGGLE_ALL_TASKS_PANELS',
	SET_WORKFLOW_FILTER_SHOW_MY_PROJECTS = 'SET_FILTER_SHOW_MY_PROJECTS',
	SET_WORKFLOW_FILTER_PROJECT_STATUS = 'SET_FILTER_PROJECT_STATUS',
	SET_WORKFLOW_FILTER_PROJECT_NAME = 'SET_FILTER_PROJECT_NAME',
	SET_WORKFLOW_FILTER_USERS = 'SET_FILTER_USERS',
	SELECT_MULTIPLE_TASK_STATUS_ID = 'SELECT_MULTIPLE_TASK_STATUS_ID',
	SELECT_MULTIPLE_TASK_STATUS_DATE = 'SELECT_MULTIPLE_TASK_STATUS_DATE'
}

export const enum Getters {
	GET_WORKFLOW_TASKS = 'GET_TASKS',
	GET_WORKFLOW_ALL_PROJECTS = 'GET_ALL_PROJECTS',
	GET_WORKFLOW_EXPANDED_PROJECTS = 'GET_EXPANDED_PROJECTS',
	GET_WORKFLOW_EXPANDED_TASKS = 'GET_EXPANDED_TASKS',
	GET_WORKFLOW_SELECTED_PROJECTS = 'GET_SELECTED_PROJECTS',
	GET_WORKFLOW_FUNCTION_GROUPS = 'GET_FUNCTION_GROUPS',
	GET_WORKFLOW_LOADING = 'GET_LOADING',
	GET_WORKFLOW_TASK_STATUSES = 'GET_TASK_STATUSES',
	GET_WORKFLOW_SHOW_FUNCTION_GROUPS = 'GET_SHOW_FUNCTION_GROUPS',
	GET_WORKFLOW_USER_ID = 'GET_USER_ID',
	GET_WORKFLOW_ANY_TASK_EXPANDED = 'GET_ANY_TASK_EXPANDED',
	GET_WORKFLOW_FILTERS = 'GET_FILTERS',
	GET_WORKFLOW_MULTIPLE_TASK_STATUS = 'GET_MULTIPLE_TASK_STATUS',
	GET_WORKFLOW_SIDEBAR_OPEN = 'GET_SIDEBAR_OPEN',
	GET_WORKFLOW_HIGHLIGHTED_PROJECT_ID = 'GET_WORKFLOW_HIGHLIGHTED_PROJECT_ID'
}

export const enum Mutations {
	MUTATE_WORFKLOW_TASKS = 'SET_TASKS',
	MUTATE_WORFKLOW_FUNCTION_GROUPS = 'SET_FUNCTION_GROUPS',
	MUTATE_WORFKLOW_ALL_PROJECTS = 'SET_ALL_PROJECTS',
	MUTATE_WORKFLOW_SELECTED_PROJECTS = 'SET_SELECTED_PROJECTS',
	MUTATE_WORKFLOW_PREVIOUS_SELECTED_PROJECTS = 'SET_PREVIOUS_SELECTED_PROJECTS',
	MUTATE_WORKFLOW_ENVIRONMENT_ID = 'SET_ENVIRONMENT_ID',
	MUTATE_WORKFLOW_USER_ID = 'SET_USER_ID',
	MUTATE_WORKFLOW_TASK_STATUSES = 'SET_TASK_STATUSES',
	MUTATE_WORKFLOW_LOADING = 'SET_LOADING',
	MUTATE_WORKFLOW_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN',
	MUTATE_WORKFLOW_SHOW_FUNCTION_GROUPS = 'SET_SHOW_FUNCTION_GROUPS',
	MUTATE_WORKFLOW_ADD_SELECTED_CONSTRUCTION_SITE = 'ADD_SELECTED_CONSTRUCTION_SITE',
	MUTATE_WORKFLOW_EDIT_PROJECT_WORKFLOW_TASK = 'EDIT_PROJECT_WORKFLOW_TASK',
	MUTATE_WORKFLOW_REMOVE_SELECTED_CONSTRUCTION_SITE = 'REMOVE_SELECTED_CONSTRUCTION_SITE',
	MUTATE_WORKFLOW_ADD_EXPANDED_PROJECT = 'ADD_EXPANDED_PROJECT',
	MUTATE_WORKFLOW_REMOVE_EXPANDED_PROJECT = 'REMOVE_EXPANDED_PROJECT',
	MUTATE_WORKFLOW_ADD_EXPANDED_TASK = 'ADD_EXPANDED_TASK',
	MUTATE_WORKFLOW_REMOVE_EXPANDED_TASK = 'REMOVE_EXPANDED_TASK',
	MUTATE_WORKFLOW_TOGGLE_ALL_TASKS_PANELS = 'TOGGLE_ALL_TASKS_PANELS',
	MUTATE_WORKFLOW_FILTER_PROJECT_STATUS_ID = 'MUTATE_FILTER_PROJECT_STATUS_ID',
	MUTATE_WORKFLOW_FILTER_SHOW_ALL_PROJECTS = 'MUTATE_FILTER_SHOW_ALL_PROJECTS',
	MUTATE_WORKFLOW_FILTER_USERS = 'MUTATE_FILTER_USERS',
	MUTATE_WORKFLOW_FILTER_PROJECT_NAME = 'MUTATE_FILTER_PROJECT_NAME',
	MUTATE_WORKFLOW_MULTIPLE_TASK_STATUS_ID = 'MUTATE_MULTIPLE_TASK_STATUS_ID',
	MUTATE_WORKFLOW_MULTIPLE_TASK_STATUS_DATE = 'MUTATE_MULTIPLE_TASK_STATUS_DATE',
	MUTATE_PUSH_NEXT_PROJECTS = 'MUTATE_PUSH_NEXT_PROJECTS',
	MUTATE_PROJECTS_PAGINATION_DETAILS = 'MUTATE_PROJECTS_PAGINATION_DETAILS',
	MUTATE_HIGHLIGHTED_PROJECT_ID = 'MUTATE_HIGHLIGHTED_PROJECT_ID'
}
