
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

  import InfoPopup from '../info-popup/info-popup-component.vue';
  import {
    WorkflowFunctionGroup,
    ProjectIntersectionData,
    WorkflowTask,
    WorkflowTaskStatus,
  } from '@/modules/workflow/types/entities';
  import { Project } from '@/modules/projects/types/entities';
  import { Comment } from '@/modules/entities/types/entities';
  import { statusColour } from '@/modules/settings/views/workflow/helpers/workflow-helpers';
  import { taskStatusIcon } from '@/modules/settings/views/workflow/helpers/workflow-helpers';

  @Component({
    components: {
      'info-popup': InfoPopup,
    },
  })
  export default class StatusGridComponent extends Vue {
    @Prop() projects!: Project[];
    @Prop() expandedProjects!: number[];
    @Prop() expandedTasks!: number[];
    @Prop() tasks!: WorkflowTask[];
    @Prop() options!: {};
    @Prop() functionGroups!: WorkflowFunctionGroup[];
    @Prop() showFunctionGroups!: boolean;
    @Prop() userId!: number;
    @Prop() taskStatuses!: WorkflowTaskStatus[];
    @Prop() getStatusChangerColor!: Function;
    @Prop() getCurrentStatus!: Function;
    @Prop() isTaskExpanded!: Function;
    @Prop() findIntersectionData!: Function;
    @Prop() isProjectExpanded!: Function;
    @Prop() multiTaskStatus!: { id: number; futureStartDate?: Date };
    @Prop() projectStatusId!: number;

    hoveredIntersectionId: number | null = null;

    get filteredProjects() {
      if (this.projectStatusId !== 0)
        return this.projects.filter((x) => x.projectStatus && x.projectStatus.id === this.projectStatusId);
      return this.projects.filter((x) => !x.projectStatus);
    }

    colorClass(intersectionData?: ProjectIntersectionData) {
      return statusColour(
        intersectionData && intersectionData.taskStatus && intersectionData.taskStatus.color
          ? intersectionData.taskStatus.color
          : ''
      );
    }

    handleCommentMouseEvent(type: string, intersectionData: ProjectIntersectionData) {
      switch (type) {
        case 'mouseover':
          this.hoveredIntersectionId = intersectionData.intersectionId;
          break;
        case 'mouseleave':
          this.hoveredIntersectionId = null;
          break;
        default:
          break;
      }
    }

    getIsCommentPresent(intersectionData?: ProjectIntersectionData) {
      return intersectionData && intersectionData.comments && intersectionData.comments.length > 0;
    }

    getIntersectionIcon(intersectionData?: ProjectIntersectionData) {
      if (intersectionData && intersectionData.taskStatus) {
        return intersectionData.taskStatus.symbol ? taskStatusIcon(intersectionData.taskStatus.symbol) : undefined
      }
    }

    isTaskStatusDate(intersectionData?: ProjectIntersectionData) {
      if (intersectionData && intersectionData.taskStatus) {
        return intersectionData.taskStatus.id !== 8;
      }
      return true;
    }

    setTaskStatus(taskId: number, project: Project) {
      if (this.multiTaskStatus.id !== 0) {
        if (project.intersectionData) {
          const intersectionData = project.intersectionData.find((x) => x.workflowTaskId === taskId);
          if (intersectionData && intersectionData.taskStatus) {
            if (intersectionData.taskStatus.id !== this.multiTaskStatus.id) {
              this.onSetTaskStatus({
                projectId: project.id!,
                workflowTaskId: taskId,
                taskStatusId: this.multiTaskStatus.id,
              });
            }
            if (this.multiTaskStatus.futureStartDate !== undefined)
              this.onSetTaskDate({
                projectId: project.id!,
                workflowTaskId: taskId,
                futureStartDate: this.multiTaskStatus.futureStartDate,
              });
          } else {
            this.onSetTaskStatus({
              projectId: project.id!,
              workflowTaskId: taskId,
              taskStatusId: this.multiTaskStatus.id,
            });
            if (this.multiTaskStatus.futureStartDate !== undefined)
              this.onSetTaskDate({
                projectId: project.id!,
                workflowTaskId: taskId,
                futureStartDate: this.multiTaskStatus.futureStartDate,
              });
          }
        } else {
          this.onSetTaskStatus({
            projectId: project.id!,
            workflowTaskId: taskId,
            taskStatusId: this.multiTaskStatus.id,
          });
          if (this.multiTaskStatus.futureStartDate !== undefined)
            this.onSetTaskDate({
              projectId: project.id!,
              workflowTaskId: taskId,
              futureStartDate: this.multiTaskStatus.futureStartDate,
            });
        }
      }
    }

    @Emit('addNote')
    addNote(data: { project: Project; task: WorkflowTask; intersectionData?: ProjectIntersectionData }) {
      return data;
    }

    @Emit('editNote')
    editNote(data: {
      project: Project;
      task: WorkflowTask;
      intersectionData: ProjectIntersectionData;
      comment: Comment;
    }) {
      return data;
    }

    @Emit('showAllNotes')
    showAllNotes(data: { project: Project; task: WorkflowTask; intersectionData: ProjectIntersectionData }) {
      return data;
    }

    @Emit('setTaskStatus')
    onSetTaskStatus(data: { projectId: number; workflowTaskId: number; taskStatusId: number }) {
      return data;
    }

    @Emit('setTaskDate')
    onSetTaskDate(data: { projectId: number; workflowTaskId: number; futureStartDate: Date }) {
      return data;
    }

    @Emit('taskStatusMultipleSelect')
    taskStatusMultipleSelect(taskStatusId: number) {
      return taskStatusId;
    }

    @Emit('taskStatusMultipleSelectDate')
    taskStatusMultipleSelectDate(futureStartDate: Date) {
      return futureStartDate;
    }

    getIntersectionComment(projectId: number, taskId: number) {
      // let projectIndex = this.projects.findIndex(project => project.id === projectId && project.);
      // return projectIndex ? this.projects[projectIndex].intersectionData.comment :
    }

    determineTaskFunctionGroup(functionGroup: WorkflowFunctionGroup, task: WorkflowTask) {
      if (!task.functionGroupsResponsible) return 'background-gray';
      if (
        task.functionGroupsResponsible.some(
          (functionGroupResponsible) => functionGroupResponsible.id === functionGroup.id
        )
      ) {
        return 'background-green';
      }
    }
  }
