export const namespace = 'entities/suppliers';

export const enum Actions {
	ATTACH_CREATED_CONTACT_TO_SUPPLIER = 'ATTACH_CREATED_CONTACT_TO_SUPPLIER',
	APPEND_CURRENT_SUPPLIER_TYPE = 'APPEND_CURRENT_SUPPLIER_TYPE',
	DELETE_SUPPLIER_BY_ID = 'DELETE_SUPPLIER_BY_ID',
	DELETE_INSPECTED_SUPPLIER_BY_ID = 'DELETE_INSPECTED_SUPPLIER_BY_ID',
	DESTROY_SUPPLIER_DETAILS = 'DESTROY_SUPPLIER_DETAILS',
	DESTROY_FETCHED_SUPPLIERS = 'DESTROY_FETCHED_SUPPLIERS',
	EDIT_SUPPLIER_BY_ID = 'EDIT_SUPPLIER_BY_ID',
	EDIT_INSPECTED_SUPPLIER_BY_ID = 'EDIT_INSPECTED_SUPPLIER_BY_ID',
	FETCH_NEXT_PAGE = 'FETCH_NEXT_PAGE',
	FETCH_SUPPLIER_BY_ID = 'FETCH_SUPPLIER_BY_ID',
	FETCH_SUPPLIER_TYPES = 'FETCH_SUPPLIER_TYPES',
	SET_CONTRACTORS_FILTER_NAME_QUERY = 'SET_CONTRACTORS_FILTER_NAME_QUERY',
	SET_SUPPLIERS_FILTER_SUPPLIER_TYPES = 'SET_FILTER_SUPPLIER_TYPES',
	SET_SUPPLIERS_FILTER_CONTRACTOR_TYPES = 'SET_FILTER_CONTRACTOR_TYPES',
	SET_SUPPLIERS_FILTER_PRICE = 'SET_FILTER_PRICE',
	SET_SUPPLIERS_FILTER_QUALITY = 'SET_FILTER_QUALITY',
	SET_SUPPLIERS_FILTER_TECHNICAL_EXPERTISE = 'SET_FILTER_TECHNICAL_EXPERTISE',
	SET_SUPPLIERS_FILTER_AESTHETIC_QUALITY = 'SET_FILTER_AESTHETIC_QUALITY',
	SET_SUPPLIERS_FILTER_FUTURE_WORK = 'SET_FILTER_FUTURE_WORK',
	SET_SUPPLIERS_FILTER_ADDITIONAL_FILTER = 'SET_FILTER_ADDITIONAL_FILTER',
	SET_SUPPLIERS_FILTER_NAME_QUERY = 'SET_FILTER_NAME_QUERY',
	SET_SUPPLIERS_FILTER_MAIN_QUERY = 'SET_FILTER_MAIN_QUERY',
	SET_SUPPLIERS_FILTER_SIDEBAR_OPEN = 'SET_FILTER_SIDEBAR_OPEN',
	SET_SUPPLIERS_FILTER_CLEAR = 'SET_FILTER_CLEAR',
	SET_SUPPLIERS_FILTER_CLEAR_NO_FETCH = 'SET_SUPPLIERS_FILTER_CLEAR_NO_FETCH',
	TOGGLE_IS_FILTER_TYPE_ACTIVE = 'TOGGLE_IS_FILTER_TYPE_ACTIVE',
	FETCH_CONTRACTOR_TYPES = 'FETCH_CONTRACTOR_TYPES',
	FETCH_CONTRACTORS_BY_FILTERS = 'FETCH_CONTRACTORS_BY_FILTERS',
	FETCH_SUPPLIERS_BY_FILTERS = 'FETCH_SUPPLIERS_BY_FILTERS',
	FETCH_CONTACT_TYPES = 'FETCH_CONTACT_TYPES',
	EDIT_SUPPLIER_CONTACTS = 'EDIT_SUPPLIER_CONTACTS',
	TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_SUPPLIER = 'TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_SUPPLIER',
	FILTER_SUPPLIER_CONTACTS = 'FILTER_SUPPLIER_CONTACTS',
	SAVE_COMMENT = 'SAVE_COMMENT',
	EDIT_COMMENT = 'EDIT_COMMENT',
	DELETE_COMMENT = 'DELETE_COMMENT',
	SAVE_CITY = 'SAVE_CITY',
	CREATE_NEW_SUPPLIER = 'CREATE_NEW_SUPPLIER',
	FETCH_PROVINCE_LIST = 'FETCH_PROVINCE_LIST',
	CREATE_SUPPLIER_BRANCH = 'CREATE_SUPPLIER_BRANCH',
	UPDATE_SUPPLIER_BRANCH = 'UPDATE_SUPPLIER_BRANCH',
	DELETE_SUPPLIER_BRANCH = 'DELETE_SUPPLIER_BRANCH',
	FETCH_COMPANY_TYPES = 'FETCH_COMPANY_TYPES',
	CHECK_FOR_EXISTING_SUPPLIER = 'CHECK_FOR_EXISTING_SUPPLIER',
	REVERT_LAST_ACTION = 'REVERT_LAST_ACTION',
	DELETE_TELEINFORMATION_BY_ID = 'DELETE_TELEINFORMATION_BY_ID',
	SAVE_RETAINMENT = 'SAVE_RETAINMENT',
	DELETE_RETAINMENT = 'DELETE_RETAINMENT',
}

export const enum Getters {
	GET_SUPPLIER_DETAILS = 'GET_SUPPLIER_DETAILS',
	GET_SUPPLIER_TYPES = 'GET_SUPPLIER_TYPES',
	GET_FETCHED_CONTRACTORS = 'GET_FETCHED_CONTRACTORS',
	GET_FETCHED_SUPPLIERS = 'GET_FETCHED_SUPPLIERS',
	GET_CONTRACTOR_TYPES = 'GET_CONTRACTOR_TYPES',
	GET_CONTACT_TYPES = 'GET_CONTACT_TYPES',
	GET_SUPPLIER_FILTERS = 'GET_SUPPLIER_FILTERS',
	GET_LOADING = 'GET_LOADING',
	GET_PROVINCE_LIST = 'GET_PROVINCE_LIST',
	GET_COMPANY_TYPES = 'GET_COMPANY_TYPES'
}

export const enum Mutations {
	MUTATE_LOADING = 'MUTATE_LOADING',
	MUTATE_SUPPLIER_CONTACTS = 'MUTATE_SUPPLIER_CONTACTS',
	MUTATE_CURRENT_SUPPLIER_TYPE = 'MUTATE_CURRENT_SUPPLIER_TYPE',
	MUTATE_FETCHED_SUPPLIERS = 'MUTATE_FETCHED_SUPPLIERS',
	MUTATE_FETCHED_CONTRACTORS = 'MUTATE_FETCHED_CONTRACTORS',
	MUTATE_INSPECTED_SUPPLIER = 'MUTATE_INSPECTED_SUPPLIER',
	MUTATE_PAGINATION_DETAILS = 'MUTATE_PAGINATION_DETAILS',
	MUTATE_SUPPLIER_TYPES = 'MUTATE_SUPPLIER_TYPES',
	MUTATE_PUSH_NEXT_PAGE = 'MUTATE_PUSH_NEXT_PAGE',
	MUTATE_CONTRACTOR_TYPES = 'MUTATE_CONTRACTOR_TYPES',
	MUTATE_CONTACT_TYPES = 'MUTATE_CONTACT_TYPES',
	MUTATE_CONTRACTORS_FILTERS_NAME_QUERY = 'MUTATE_CONTRACTORS_FILTERS_NAME_QUERY',
	MUTATE_FILTERS_NAME_QUERY = 'MUTATE_FILTERS_NAME_QUERY',
	MUTATE_FILTERS_MAIN_QUERY = 'MUTATE_FILTERS_MAIN_QUERY',
	MUTATE_FILTERS_SUPPLIER_TYPES = 'MUTATE_FILTERS_SUPPLIER_TYPES',
	MUTATE_FILTERS_CONTRACTOR_TYPES = 'MUTATE_FILTERS_CONTRACTOR_TYPES',
	MUTATE_FILTERS_PRICE = 'MUTATE_FILTERS_PRICE',
	MUTATE_FILTERS_QUALITY = 'MUTATE_FILTERS_QUALITY',
	MUTATE_FILTERS_TECHNICAL_EXPERTISE = 'MUTATE_FILTERS_TECHNICAL_EXPERTISE',
	MUTATE_FILTERS_AESTHETIC_QUALITY = 'MUTATE_FILTERS_AESTHETIC_QUALITY',
	MUTATE_FILTERS_FUTURE_WORK = 'MUTATE_FILTERS_FUTURE_WORK',
	MUTATE_FILTERS_OFFER_RECEIVED = 'MUTATE_FILTERS_OFFER_RECEIVED',
	MUTATE_FILTERS_WORKED_TOGETHER = 'MUTATE_FILTERS_WORKED_TOGETHER',
	MUTATE_FILTERS_PLANNED = 'MUTATE_FILTERS_PLANNED',
	MUTATE_FILTERS_SIDEBAR_OPEN = 'MUTATE_FILTERS_SIDEBAR_OPEN',
	MUTATE_FILTERS_CLEAR = 'MUTATE_FILTERS_CLEAR',
	MUTATE_FILTERS = 'MUTATE_FILTERS',
	MUTATE_PROVINCE = 'MUTATE_PROVINCE',
	MUTATE_COMPANY_TYPES = 'MUTATE_COMPANY_TYPES',
	MUTATE_SUPPLIERS_ACTIVITY_LOG = 'MUTATE_ACTIVITY_LOG',
}
