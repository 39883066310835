import { Client, Comment, Supplier, Role } from '@/modules/entities/types/entities';
import { ProjectIntersectionData, WorkflowTask } from '@/modules/workflow/types/entities';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
import { PurchaseInvoice } from '@/modules/purchase-invoices/types/entities';
import { SalesInvoice } from '@/modules/sales-invoices/types/entities';
import { Contact } from '@/modules/contacts/types/entities';
import { Quote } from '@/modules/calendar/interfaces/project/quote';
import {TimesheetEntry} from "@/modules/settings/types/entities";

export interface Project {
	uniqueId: string;
	id: number;
	projectName: string;
	presentedPrice?: number;
	initialOffer: number;
	initialOfferComment: string;
	dateFinancialDetails: Date | null;
	dateInitialOffer: Date | null;
	projectStatus?: ProjectStatus;
	addedDate: string | null;
	street: string | null;
	streetNumber: string | null;
	postalCode: string | null;
	city: string | null;
	distanceToSfinxHq: number | null;
	socialSecurityCompleted: boolean;
	socialSecurityReference: string | null;
	socialSecurityStartDate: string | null;
	socialSecurityEndDate: string | null;
	contacts?: Contact[];
	thirdParties: ThirdParty[];
	clients: Client[];
	contractorParties: ContractorParty[];
	projectLeaders: ProjectUser[];
	estimators: ProjectUser[];
	designers: ProjectUser[];
	drawers: ProjectUser[];
	intersectionData?: ProjectIntersectionData[];
	workflowTasks?: WorkflowTask[];
	comments?: Comment[];
	quotes: Quote[];
	orders: Quote[];
	plannerContractors?: Quote[];
	purchaseInvoices?: PurchaseInvoice[];
	salesInvoices?: SalesInvoice[];
  timesheetEntries?: TimesheetEntry[];
	archivedAt: string | null;
	latestProgressSnapshot: ProgressSnapshot | null;
	contractorBranches: ProjectContractorBranch[] | null;
}

export interface ProjectUser {
	id: number;
	firstName: string;
	lastName: string;
	username: string;
	alias: string;
	isActive: boolean;
	role: Role|null;
	workPercentage?: number;
}

export class ProjectUserVM implements ProjectUser {
	id = 0;
	firstName = '';
	lastName = '';
	username = '';
	alias = '';
	isActive = false;
	role = null;
	workPercentage = 0;

	constructor(init?: Partial<ProjectUser>) {
	  Object.assign(this, init);
	}
}

export interface ProjectStatus {
	id: number;
	name: string;
	label?: string;
}

export interface ThirdParty {
	supplierTypeId: number;
	pivotId: number;
	name: string;
	description: string;
	supplier?: Supplier;
	supplierPermAssigned: number;
	supplierTempAssigned: number;
	thirdPartyPermAssigned: number;
	thirdPartyTempAssigned: number;
	comment: string;
}

export interface SelectEntity {
	isMenuActive: boolean;
	activateMenu: Function;
	blur: Function;
}

export interface PercentageTypesNames {
	[key: string]: string;
}

export interface ContractorParty {
	id: number;
	pivotId: number;
	name: string;
	abbreviation: string;
	description: string;
	sequence: number;
	supplier?: Supplier;
	contractorTypePermAssigned: number;
	contractorTypeTempAssigned: number;
	supplierPermAssigned: number;
	supplierTempAssigned: number;
}

export interface ProjectContractorBranch {
	id?: number;
	projectId?: number;
	contractorTypeId?: number;
	contractorTypeName: string;
	isRelevant: boolean;
	comment: string;
}

export class ProjectContractorBranchEntity implements ProjectContractorBranch {
	id = undefined;
    projectId = undefined;
    contractorTypeId = undefined;
    contractorTypeName = "";
    isRelevant = false;
    comment = '';

    constructor(data: Partial<ProjectContractorBranch> = {}) {
        Object.assign(this, data)
    }
}

export interface ProgressSnapshot {
	id?: number;
	totalAmountOrdered: number;
	totalAmountExecuted: number;
	snapshotCreatedAt: string | null;
	currency: string;

	items: ProgressSnapshotItem[] | null;
	projectId: number | null;
	salesInvoiceId: number | null;
	project: Project | null;
	salesInvoice: SalesInvoice | null;
}

export class ProgressSnapshotEntity implements ProgressSnapshot {
	id = undefined;
	totalAmountOrdered = 0;
	totalAmountExecuted = 0;
	snapshotCreatedAt = null;
	currency = 'EUR';

	items = [];
	projectId = null;
	salesInvoiceId = null;
	project = null;
	salesInvoice = null;

	constructor(data: Partial<ProgressSnapshot> = {}) {
	  Object.assign(this, data);
	}
}

export interface ProgressSnapshotItem {
	id?: number;
	totalAmountOrdered: number;
	totalAmountExecuted: number;
	currency: string;

	progressSnapshot: ProgressSnapshot | null;
	contractorBranch: ContractorBranch | null;
}

export class ProgressSnapshotItemEntity implements ProgressSnapshotItem {
	id = undefined;
	totalAmountOrdered = 0;
	totalAmountExecuted = 0;
	currency = 'EUR';

	progressSnapshot = null;
	contractorBranch = null;

	constructor(data: Partial<ProgressSnapshotItem> = {}) {
	  Object.assign(this, data);
	}
}
