import { InvoiceState } from '@/modules/sales-invoices/store/types/InvoiceState';
import {GetterTree} from 'vuex';
import {RootState} from '@/core/types/RootState';
import {Getters} from '@/modules/sales-invoices/store/types/StoreTypes';
import {i18n} from '@/main';
import {SalesInvoiceStatus, SalesInvoiceType, SalesInvoiceRecordType, SalesInvoicePaymentStatus} from '@/modules/sales-invoices/types/entities'

export const getters: GetterTree<InvoiceState, RootState> = {
	[Getters.GET_LISTVIEW_IS_LOADING]: (state: InvoiceState) => {
		return state.listviewIsLoading;
	},
	[Getters.GET_LISTVIEW_INVOICES]: (state: InvoiceState) => {
		return state.listviewInvoices;
	},
	[Getters.GET_INVOICES_SUMMARY_REPORT]: (state: InvoiceState) => {
		return state.invoicesSummaryReport;
	},
	[Getters.GET_INVOICES_METRICS_REPORT]: (state: InvoiceState) => {
		return state.invoicesMetricsReport;
	},
	[Getters.GET_SHOW_INVOICES_SUMMARY_REPORT]: (state: InvoiceState) => {
		return state.invoicesShowSummaryReport;
	},
	[Getters.GET_SHOW_INVOICES_METRICS_REPORT]: (state: InvoiceState) => {
		return state.invoicesShowMetricsReport;
	},
	[Getters.GET_STATUSES]: (state: InvoiceState) => {
		return state.statuses;
	},
	[Getters.GET_STATUSES_TRANSLATED]: (state: InvoiceState) => {
		const statuses = [] as SalesInvoiceStatus[];
		state.statuses.map((value) => { 
			const status = {
				id: value.id,
				label: i18n.t('invoices.sales.statuses.'+value.name).toString(),
				name: value.name,
				color: value.color
			} as SalesInvoiceStatus;
			statuses.push(status);
		});
		return statuses;
	},
	[Getters.GET_PAYMENT_STATUSES]: (state: InvoiceState) => {
		return state.paymentStatuses;
	},
	[Getters.GET_PAYMENT_STATUSES_TRANSLATED]: (state: InvoiceState) => {
		const statuses = [] as SalesInvoicePaymentStatus[];
		state.paymentStatuses.map((value) => { 
			const status = {
				id: value.id,
				label: i18n.t('invoices.sales.paymentStatuses.'+value.name).toString(),
				name: value.name,
				color: value.color,
				sequence: value.sequence
			} as SalesInvoicePaymentStatus;
			statuses.push(status);
		});
		return statuses;
	},
	[Getters.GET_TYPES]: (state: InvoiceState) => {
		return state.types;
	},
	[Getters.GET_TYPES_TRANSLATED]: (state: InvoiceState) => {
		const types = [] as SalesInvoiceType[];
		state.types.map((value) => { 
			const type = {
				id: value.id,
				label: i18n.t('invoices.sales.types.'+value.name).toString(),
				name: value.name,
				children: value.children,
				parentId: value.parentId
			} as SalesInvoiceType;
			types.push(type);
		});
		return types;
	},
	[Getters.GET_RECORD_TYPES]: (state: InvoiceState) => {
		return state.recordTypes;
	},
	[Getters.GET_RECORD_TYPES_TRANSLATED]: (state: InvoiceState) => {
		const recordTypes = [] as SalesInvoiceRecordType[];
		state.recordTypes.map((value) => { 
			const recordType = {
				id: value.id,
				label: i18n.t('invoices.sales.recordTypes.'+value.name).toString(),
				name: value.name
			} as SalesInvoiceRecordType;
			recordTypes.push(recordType);
		});
		return recordTypes;
	},
	[Getters.GET_LISTVIEW_FILTER_STATE]: (state: InvoiceState) => {
		return state.filters;
	},
	[Getters.GET_IS_LOADING]: (state: InvoiceState) => {
		return state.isLoading;
	},
	[Getters.GET_INVOICE]: (state: InvoiceState) => {
		return state.invoice;
	},
	[Getters.GET_CONTACTS]: (state: InvoiceState) => {
		return state.contacts;
	},
	[Getters.GET_PROJECTS]: (state: InvoiceState) => {
		return state.projects;
	},
	[Getters.GET_SUPPLIERS]: (state: InvoiceState) => {
		return state.suppliers;
	},
	[Getters.GET_CLIENTS]: (state: InvoiceState) => {
		return state.clients;
	},
	[Getters.GET_RELATED_BILLING_ADDRESSES]: (state: InvoiceState) => {
		return state.relatedBillingAddresses;
	},
	[Getters.GET_NEW]: (state: InvoiceState) => {
		return state.new;
	},
	[Getters.GET_PROJECT_INVOICES]: (state: InvoiceState) => {
		return state.projectInvoices;
	},
	[Getters.GET_FILTER_PROJECT_MODEL]: (state: InvoiceState) => {
		return state.filtersProjectModel;
	},

	// search
	[Getters.GET_SEARCH_PROJECTS]: (state: InvoiceState) => {
		return state.searchProjects;
	},

}