
  import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
  import { Project, ProjectStatus, ProjectUser } from '@/modules/projects/types/entities';
  import ProjectPhaseListComponent from '@/modules/workflow/views/workflow/components/project-selection-menu/project-phase-list/project-phase-list.component.vue';
  import ProjectPeopleListComponent from '@/modules/workflow/views/workflow/components/project-selection-menu/project-people-list/project-people-list.component.vue';
  import { WorkflowFilter } from '@/modules/workflow/store/types/WorkflowState';

  @Component({
    components: {
      'project-phase-list': ProjectPhaseListComponent,
      'project-people-list': ProjectPeopleListComponent,
    },
  })
  export default class ProjectSelectionMenuComponent extends Vue {
    @PropSync('isSelectionDialogOpen', { type: Boolean }) isSelectMenuOpen!: boolean;
    @Prop() projectStatuses!: ProjectStatus[];
    @Prop() allProjects!: Project[];
    @Prop() selectedProjects!: Project[];
    @Prop() users!: ProjectUser[];
    @Prop() filter!: WorkflowFilter;
    @Prop() sidebarOpen!: boolean;
    @Prop() isProjectStatusSelected!: Function;

    searchValue = '';
    private searchTimeout: any;

    projectSelectionMenuState = {
      showMore: false,
    };

    debounceSearch(val: string): void {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.emitSearchByProjectNameEvent(val);
      }, 200);
    }

    isProjectChecked(item: Project): boolean {
      return this.selectedProjects.some((x) => x.id === item.id);
    }

    onAllProjectsClick(item: Project): void {
      this.selectedProjects.some((x) => x.id === item.id)
        ? this.emitRemoveSelectedProjectEvent(item)
        : this.emitSelectProject(item);
    }

    @Emit('emitSearchByProjectName')
    emitSearchByProjectNameEvent(val: string): string {
      return val;
    }

    @Emit('emitRemoveSelectedProjectEvent')
    emitRemoveSelectedProjectEvent(item: Project): Project {
      return item;
    }

    @Emit('emitSelectProject')
    emitSelectProject(item: Project): Project {
      return item;
    }

    @Emit('emitSetToolsDialogState')
    emitSetToolsDialogState(data: boolean): boolean {
      this.isSelectMenuOpen = data;
      return data;
    }

    @Emit('onToggleUserGroup')
    onEmitToggleUserGroup(data: { user: ProjectUser; userGroup: string }): { user: ProjectUser; userGroup: string } {
      return data;
    }

    @Emit('toggleProjectStatusSelection')
    emitToggleProjectStatusSelection(projectStatusId: number): number {
      return projectStatusId;
    }

    @Emit('loadMoreProjects')
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    emitLoadMoreProjects(): void {}

    @Watch('searchValue')
    onSearchValueChange(val: string): void {
      this.debounceSearch(val);
    }
  }
