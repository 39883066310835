import { Client, Comment, Supplier, TeleinformationDetail } from "@/modules/entities/types/entities";


export interface Contact {
	id: number;
	firstName: string;
	lastName: string;
	street: string;
	postalCode: string;
	streetNumber: string;
	flatNumber: string;
	city: string;
	website: string;
	bankAccountNumber: string;
	socialSecurityNumber: string;
	contactFunction: string;
	vatNumber: string;
	comment: string;
	teleinformations: TeleinformationDetail[];
	clients?: Client[];
	suppliers: Supplier[];
	comments?: Comment[];
}

export class ContactVM implements Contact {
	id = 0;
	firstName = '';
	lastName = '';
	street = '';
	postalCode = '';
	streetNumber = '';
	flatNumber = '';
	city = '';
	website = '';
	bankAccountNumber = '';
	socialSecurityNumber = '';
	contactFunction = '';
	vatNumber = '';
	comment = '';
	teleinformations: TeleinformationDetail[] = [];
	clients: Client[] = [];
	suppliers: Supplier[] = [];
	comments: Comment[] = [];

	constructor(init?: Partial<Contact>) {
		Object.assign(this, init);
	}
}
