
  import { defineComponent } from 'vue';
  import { CalendarViewType } from '@/modules/calendar/types/CalendarViewType';
  import helpers from '@/modules/calendar/services/helper.service';
  import store from '../store';
  import { CalendarSidebarDialog } from '../types/CalendarSidebarDialog';
  import CalendarGridProjectQuote from './calendar-grid-project-quote.component.vue';
  import CalendarGridDivisionQuote from './calendar-grid-division-quote.component.vue';
  import CalendarGridSupplierQuote from './calendar-grid-supplier-quote.component.vue';
  import CalendarGridProjectHeader from './calendar-grid-project-header.component.vue';
  import CalendarGridDivisionHeader from './calendar-grid-division-header.component.vue';
  import CalendarGridSupplierHeader from './calendar-grid-supplier-header.component.vue';

  export default defineComponent({
    name: 'CalendarGrid',
    components: {
      CalendarGridProjectHeader,
      CalendarGridProjectQuote,
      CalendarGridDivisionHeader,
      CalendarGridDivisionQuote,
      CalendarGridSupplierHeader,
      CalendarGridSupplierQuote,
    },
    setup(props) {
      return {
        CalendarViewType,
        CalendarSidebarDialog,
        calendar: store.calendar,
        calendarSettings: store.calendarSettings,
        ...helpers,
      };
    },
  });
