
	import { PropType } from 'vue';
    import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
    import Fuse from 'fuse.js'
    import { User } from '@/modules/entities/types/entities';
    import { ProjectRelationship, ProjectRelationshipStatus } from '../../types/entities';
    import { groupBy } from 'lodash';
    import { sortNatural } from '@/core/helpers/Sort';
    import { DataTableHeader } from 'vuetify';

	@Component
	export default class ProjectRelationshipAssignToListComponent extends Vue {

        @Prop({type: Array as PropType<User[]>, default: null})
        value!: User[];

		@Prop({type: Array, default: () => [] as User[] })
        items!: User[];

        @Prop({type: Array, default: () => [] as ProjectRelationship[] })
        projectRelationships!: ProjectRelationship[];
		
        // vars
        statusOrderOfImportance: string[] = ['approved','completed_annotation','pending_annotation','pending_claim','requested']
		
        // handlers

        // methods
        userWithProjectRelationshipStatus(userId: number) {
            let group = this.groupedProjectRelationshipsPerAssignedTo.find(group => group.userId === userId)
            let resultingStatus = null;
            for(let i in this.statusOrderOfImportance) {
                let status = this.statusOrderOfImportance[i]
                if(group && group.statuses && group.statuses.includes(status)) {
                    resultingStatus = status;
                    break;
                }
            }
            return resultingStatus;
        }
        compare(a: any, b: any) {
			if(a && b && a.id !== undefined && b.id !== undefined) return a.id === b.id
			return false
        }
        groupBy(key:string, items:any[]) {
            return Object.entries(groupBy(items, key)).map((value) => { 
                return {
                    groupBy: value[0],
                    items: value[1]
                };
            });
        }
        
        // getters
        get users() {
            return this.value ? this.value : []
        }
        get groupedProjectRelationshipsPerStatus() {
            const byStatus = Object.entries(groupBy(this.projectRelationships, 'status.name')).map((value) => { 
                return {
                    status: value[0],
                    items: value[1],
                    userIds: value[1] && value[1].length ? value[1].map(rel => rel.assignedTo && rel.assignedTo.id) : []
                };
            });
            return byStatus;
        }
        get groupedProjectRelationshipsPerAssignedTo() {
            const byUser = Object.entries(groupBy(this.projectRelationships, 'assignedTo.id')).map((value) => { 
                return {
                    userId: Number.parseInt(value[0]),
                    items: value[1],
                    statuses: (value[1] && value[1].length ? value[1].map(rel => rel.status && rel.status.name) : [])
                };
            });
            return byUser;
        }
        get projectRelationshipsForAssignedTos() {
            return this.projectRelationships.filter((r => this.users && this.users.some((u => r.assignedTo && u.id === r.assignedTo.id))))
        }
        get projectsHeaders() {
            return [
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.total').toString(),
                    value: 'total',
                    align: 'end',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.project').toString(),
                    value: 'project.projectName',
                    align: 'start text-truncate td-text-truncate-2 td-toggle',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.contractorType').toString(),
                    value: 'contractorType.id',
                    align: 'start text-truncate td-text-truncate-2 td-toggle',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.accountingLedgerItem').toString(),
                    value: 'accountingLedgerItem.name',
                    align: 'start text-truncate td-text-truncate-2 td-toggle',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.status').toString(),
                    value: 'status.name',
                    align: 'start text-truncate td-text-truncate-3 td-toggle',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.assignedTo', {name:''}).toString(),
                    value: 'assignedTo.id',
                    align: 'start text-truncate td-text-truncate-3 td-toggle',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                { text: '', value: 'actions', sortable: false, groupable: false} as DataTableHeader,
            ];
        }
        get projectsSummarizers() {
            return [
                {
                    value:'total',
                    summarize: (items: ProjectRelationship[] = []) => { 
                        let totals = [];
                        totals = items.map((item: ProjectRelationship) => item.total ? item.total : 0)
                        return totals.length > 0 ? totals.reduce((prev: number, curr: number) => { return prev+curr }) : 0
                    },
                },
                {
                    value:'contractorType.abbreviation',
                    summarize: (items: ProjectRelationship[] = []) => { 
                        let types = [];
                        types = items.map((item: ProjectRelationship) => item.contractorType && item.contractorType.abbreviation ? item.contractorType.abbreviation : '')
                        return types.length > 0 ? types.reduce((prev: string, curr: string) => { return prev.includes(curr) ? prev : prev + ',' + curr }) : ''
                    },
                },
            ]
        }
        get projectsFormatters() {
            return [
                {
                    value:'total',
                    format: (value: number) => {
                        return this.$options.filters !== undefined ? this.$options.filters.formatMoney(value) : null
                    }
                },
                {
                    value:'contractorType.abbreviation',
                    format: (value: string) => {
                        return value;
                    }
                }
            ]
        }

        // setters

        // watchers
	}
