
	import { Component, Emit, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
	import { DataTableHeader } from 'vuetify';

	@Component
	export default class RelatedListComponent extends Vue {

		// properties
		@Prop({type: String, default:'eg: Contacts'}) 
		title!: string;

		@Prop({type: String, default:'mdi-format-list-text'})
		icon!: string;

		@Prop({type: Boolean, default:false}) 
		hideIcon!: boolean;

		@Prop({type: Boolean, default:false}) 
		hideHeaders!: boolean;

		@Prop({type: Array, default:() => { return new Array<String>(); }}) 
		hideActions!: string[]; // eg; ['add', 'delete']

		@Prop({type: Array, default:() => { return new Array<String>(); }}) 
		hideActionLabels!: string[]; // eg; ['add', 'delete']

		@Prop({type: Array, default:() => { return new Array<String>(); }}) 
		disableActions!: string[]; // eg; ['add', 'delete']

		@Prop({type: Array, default:() => { return new Array<String>(); }})
		hideItemActions!: string[]; // eg; ['edit', 'delete']

		@Prop({type: Array, default:() => { return new Array<String>(); }})
		hideGroupSummaries!: string[]; // eg; ['name', 'randomGroupedField']

		@Prop({type: Array, default:() => { return new Array<String>(); }})
		hideGroupRemoves!: string[]; // eg; ['name', 'randomGroupedField']

		@Prop({type: Array, default:() => { return new Array<any>(); }}) 
		headers!: Array<DataTableHeader>; // eg; ['edit', 'delete']

		@Prop({type: String, default: 'id', required:true}) 
		itemKey!: string;

		@Prop({type: Boolean, default: false}) 
		disableCrud!: boolean;

		@Prop({type: Boolean, default: false}) 
		showSummaryBottom!: boolean;

		@Prop({type: Boolean, default: false}) 
		showSummaryTop!: boolean;

		@Prop({type: Array, default: () => { return []; }}) 
		summarizers!: [];

		@Prop({ type: Array, default: () => { return [] } })
		groupDividers!: { header: string, function: Function }[];

		@Prop({ type: Function, default: () => { return } })
		itemClass!: Function ;

		@Prop({type: String, default: null, required:false}) 
		groupBy!: string|null;

		@Prop({type: String, default: null, required:false}) 
		sortBy!: string|null;

		@Prop({type: Boolean, default: false}) 
		showModelHeader!: boolean;

		@Prop({type: Array, default: () => { return []; }}) 
		formatters!: []; // [{value:'fieldName', format: fn()}]

		// vars
		selected: any[] = [];
		// page: number = 1;
		// pageCount: number = 0;

		// methods
		addCssClassToHeader(header: DataTableHeader): DataTableHeader {
			let cssClass = 'caption';
			if(header.hasOwnProperty('class')) cssClass += ' '+header.class;
			header = {...header, class: cssClass};
			return header;
		}
		comparer(bList: any[], itemKey: string){
			return function(aValue: any){
				return bList.filter(function(bValue){
					return bValue[itemKey] === aValue[itemKey]
				}).length == 0;
			}
		}
		showSummaryForHeader(header: string) {
			const summarizeFields = [] as string[];
			this.summarizers.map((i: any) => (summarizeFields.push(i.value.toString())))
			return summarizeFields.includes(header)
		}
		summarize(header: string, items: []) {
			let summarizer = {summarize: (items: []) => { return null; }};
			const filteredSummarizers = this.summarizers.filter((i: any) => { return header === i.value.toString() })
			summarizer = filteredSummarizers[0];
			return summarizer.summarize(items)
		}

		shouldAddDivider(props: any) {
			for (const { header, function: func } of this.groupDividers) {
				if (func(props)) {
					return true;
				}
			}

			return false;
		}

		format(header: string, value: any) {
			let formatter = {format: (items: []) => { return null; }};
			const filteredFormatters = this.formatters.filter((i: any) => { return header === i.value.toString() })
			formatter = filteredFormatters[0];
			return formatter.format(value)
		}

		// handlers
		onClickAdd(): void {
			this.$emit('onClickAdd');
		}
		onClickDelete(): void {
			if(this.selected && this.selected.length === 1) {
				this.$emit('onClickDelete', this.selected);
			} else throw 'At the moment only 1 item can be deleted using multiple selects.';
		}
		onClickEditItem(item: any): void {
			this.$emit('onClickEditItem', item);
		}
		onClickDeleteItem(item: any): void {
			this.$emit('onClickDeleteItem', item);
		}
		onSelectItem(item: any) {
			this.$emit('onSelect', item);
		}
		onSelectAll(items: any[]) {
			this.$emit('onSelectAll', items);
		}
		onClickGroupBy(header: any, on: any) {
			on.group(header.value)
			this.$emit('onClickGroupBy', header.value);
		}
		onClickGroupRemove(props: any) {
			props.remove();
			this.$emit('onClickGroupRemove');
		}
		onUpdateItemsPerPage(itemsPerPage: number) {
			this.$emit('onUpdateItemsPerPage', itemsPerPage);
		}

		// getters
		get attrs() {
			// eslint-disable-next-line
			const { ...attrs } = this.$attrs; // all but input event
			return attrs;
		}
		get slots() {
			// eslint-disable-next-line
			const { ...slots } = this.$slots; // pass all to datatable slots
			return slots;
		}
		get scopedSlots() {
			// eslint-disable-next-line
			const { ...scopedSlots } = this.$scopedSlots; // pass all to datatable scopedSlots
			return scopedSlots;
		}
		get filteredHeaders() {
			let filteredHeaders = new Array<DataTableHeader>();
			this.headers.forEach((header: DataTableHeader) => { filteredHeaders.push(this.addCssClassToHeader(header)); });
			filteredHeaders = filteredHeaders.filter((header: DataTableHeader) => {
				return (header.value === 'data-table-select' && this.attrs.showSelect) || (header.value === 'data-table-expand' && this.attrs.showExpand) || (header.value !== 'data-table-select' && header.value !== 'data-table-expand');
			})

			return filteredHeaders;
		}

		// watchers
		@Watch('attrs.items')
		onItemsChanged(newItems: any[], oldItems: any[]) {
			if(newItems && oldItems && newItems.length > oldItems.length) {
				const addedItems = newItems.filter(this.comparer(oldItems, this.itemKey));
				this.$emit('onItemsAdded', addedItems);
			}
			if(newItems && oldItems && newItems.length < oldItems.length) {
				const removedItems = oldItems.filter(this.comparer(newItems, this.itemKey));
				this.$emit('onItemsRemoved', removedItems);
			}
			this.$emit('itemsChanged');
		}

		// hooks
		mounted() {
		}

		// example headers
		// headers: DataTableHeader[] = [
        //     {
        //         text: '',
        //         value: 'data-table-select',
        //         groupable: false
        //     },
        //     {
        //         text: '',
        //         value: 'data-table-expand',
        //         groupable: false
        //     },
        //     {
        //         text: i18n.t('invoices.purchase.details.labels.name').toString(),
        //         value: 'name',
        //         groupable: false
        //     },
        //     {
        //         text: 'InvoiceDate',
        //         value: 'invoiceDate',
        //     },
        //     { text: '', value: 'actions', sortable: false, groupable: false, align: 'end' },
        // ];

	}
