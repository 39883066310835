import { computed, reactive, readonly } from 'vue';
import { actions as timesheetActions } from './actions/useTimesheet';
import { Options } from '@/composables/types/composable';
import useFilter from '@/composables/useFilter';
import {mergeComposables, wrapActions} from '@/composables/utils';
import { UseTimesheetState, UseTimesheetContext, UseTimesheetComposables } from '@/composables/types/useTimesheet';
import {merge} from "lodash";
import {useDynamicProps} from "@/composables/useDynamicProps";
import {Team, Timesheet} from "@/modules/settings/types/entities";

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseTimesheetState>): UseTimesheetState => {
  return merge<UseTimesheetState, typeof state>(
    {
      timesheet: null,
      timesheetBusinessErrors: null,
      timesheetValidationErrors: null,
    },
    state
  );
};

/**
 * `UseEmployeeTimesheetEnvironment`-composable
 *   - All EmployeeTimesheetEnvironment-related interactions and functionality can be stored here.
 *   - If you need a global store, than just use this composable in eg a `store.ts`
 *
 * @param options
 * @returns
 */
export default function useTimesheet(options?: Options<UseTimesheetState, UseTimesheetComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    filter: useFilter(),
    showDialog: useDynamicProps<boolean>(),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseTimesheetContext = { state, composables };
  const actions = wrapActions(ctx, timesheetActions);

  // return getters and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // other
      showDialog: ctx.composables.showDialog.getters,
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      timesheet: computed<Timesheet | null>(() => state.timesheet),
      timesheetValidationErrors: computed(() => state.timesheetValidationErrors),
      timesheetBusinessErrors: computed(() => state.timesheetBusinessErrors),
    },
    actions: {
      ...ctx.composables.filter.actions,
      ...actions,
    },
  };
}
