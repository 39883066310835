import Vue from 'vue';
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from 'vuex';

import {RootState} from '@/core/types/RootState';
import {ContractorBranch, ContractorBranchesState} from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
import {Actions, Getters, Mutations} from '@/modules/settings/store/modules/contractor-branches/types/StoreTypes';

const getters: GetterTree<ContractorBranchesState, RootState> = {
	[Getters.GET_ALL_BRANCHES]: (state: ContractorBranchesState) => {
		return [...state.branches].sort((a,b) => a.sequence - b.sequence);
	}
};

const actions: ActionTree<ContractorBranchesState, RootState> = {
	[Actions.CREATE_CONTRACTOR_BRANCH]: async ({commit, state}: ActionContext<ContractorBranchesState, RootState>, payload: ContractorBranch) => {
		const res = await Vue.prototype.$http.post(`/api/v1/settings/contractor-types`, payload);

		if (res.status === 201 || res.status === 200) {
			commit(Mutations.MUTATE_BRANCHES, [
				...state.branches,
				res.data.data
			])
		}
	},
	[Actions.DELETE_CONTRACTOR_BRANCH_BY_ID]: async ({commit, state}: ActionContext<ContractorBranchesState, RootState>, contractorBranchId: number) => {
		const res = await Vue.prototype.$http.delete(`/api/v1/settings/contractor-types/${contractorBranchId}`);

		if (res.status === 200) {
			const contractorBranches = state.branches.filter(branch => branch.id !== contractorBranchId);
			commit(Mutations.MUTATE_BRANCHES, contractorBranches);
		}
	},
	[Actions.EDIT_CONTRACTOR_BRANCH]: async ({commit}: ActionContext<ContractorBranchesState, RootState>, payload: ContractorBranch) => {
		const res = await Vue.prototype.$http.put(`/api/v1/settings/contractor-types/${payload.id}`, payload);

		if (res.status === 200) {
			commit(Mutations.MUTATE_BRANCHES, res.data.data);
		}
	},
	[Actions.FETCH_ALL_CONTRACTOR_BRANCHES]: async ({commit}: ActionContext<ContractorBranchesState, RootState>) => {
		const res = await Vue.prototype.$http.get(`/api/v1/settings/contractor-types`);
		if (res.status === 200) {
			commit(Mutations.MUTATE_BRANCHES, res.data.data);
		}
	},
	[Actions.APPEND_SEARCH_QUERY]: ({commit}: ActionContext<ContractorBranchesState, RootState>, payload: string) => {
		commit(Mutations.MUTATE_SEARCH_QUERY, payload);
	},
	[Actions.FETCH_CONTRACTOR_TYPES_BY_QUERY]: async ({commit}: ActionContext<ContractorBranchesState, RootState>, payload: string) => {
		let url = '/api/v1/settings/contractor-types';
		url += `?searchquery=${payload}`;
		const res = await Vue.prototype.$http.get(url);
		commit(Mutations.MUTATE_BRANCHES, res.data.data)
	},

};

const mutations: MutationTree<ContractorBranchesState> = {
	[Mutations.MUTATE_BRANCHES]: (state: ContractorBranchesState, payload: ContractorBranch[]) => {
		state.branches = payload;
	},
	[Mutations.MUTATE_SEARCH_QUERY]: (state: ContractorBranchesState, payload: string) => {
		state.searchQuery = payload;
	},
};

const state: ContractorBranchesState = {
	branches: [],
	searchQuery: ''
};

export const store: Module<ContractorBranchesState, RootState> = {
	namespaced: true,
	actions,
	getters,
	state,
	mutations
};
