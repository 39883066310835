import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';

export const BASE_ENDPOINT = '/api/v1/reports';

export class ReportsService {

	getReport(url = BASE_ENDPOINT): AxiosPromise<JsonResource<any>> {
		return axios.get<JsonResource<any>>(`${ url }`);
	}

}
