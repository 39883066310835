
  import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
  import { WorkflowTask } from '@/modules/workflow/types/entities';
  import draggable from 'vuedraggable';
  import { statusColour } from '../../helpers/workflow-helpers';

  @Component({
    components: {
      draggable,
    },
  })
  export default class WorkflowSettingsTasksListComponent extends Vue {
    @Prop() showArchived!: boolean;
    @PropSync('tasks', { type: Array }) tasksSync!: WorkflowTask[];
    @PropSync('expandedTasksList', { type: Array }) expandedTasksListSync!: number[];
    @PropSync('tickedTasksList', { type: Array }) tickedTasksListSync!: number[];

    isTaskExpanded(taskId: number) {
      return this.expandedTasksListSync.find((id) => id === taskId);
    }

    toggleTaskExpansion(taskId: number) {
      const isTaskExpanded = this.expandedTasksListSync.find((id) => id === taskId);
      if (isTaskExpanded) {
        this.expandedTasksListSync = this.expandedTasksListSync.filter((id) => id !== taskId);
      } else {
        this.expandedTasksListSync.push(taskId);
      }
    }

    isTaskTicked(taskId: number) {
      return this.tickedTasksListSync.find((id) => id === taskId);
    }

    toggleTaskTicked(taskId: number) {
      const isTaskTicked = this.tickedTasksListSync.find((id) => id === taskId);
      if (isTaskTicked) {
        this.tickedTasksListSync = this.tickedTasksListSync.filter((id) => id !== taskId);
      } else {
        this.tickedTasksListSync.push(taskId);
      }
    }

    @Emit('onDragEnd')
    onEmitDragEnd(event: { moved: { element: WorkflowTask; newIndex: number; oldIndex: number } }) {
      return event;
    }

    @Emit('onDragEndSubTask')
    onEmitDragEndSubTask(
      event: { moved: { element: WorkflowTask; newIndex: number; oldIndex: number } },
      parentTask: WorkflowTask
    ) {
      return { event, parentTask };
    }

    @Emit('onAddTask')
    onEmitAddTask(
      task: WorkflowTask,
      hierarchy: string,
      childTaskSequence?: number
    ): { task: WorkflowTask; hierarchy: string; childTaskSequence?: number } {
      if (childTaskSequence) {
        switch (hierarchy) {
          case 'up':
            break;
          case 'down':
            childTaskSequence += 1;
            break;
          default:
            break;
        }
      }
      return { task, hierarchy, childTaskSequence };
    }

    @Emit('onEditTask')
    onEmitEditTask(task: WorkflowTask): WorkflowTask {
      return task;
    }
    @Emit('onArchiveTask')
    onEmitArchiveTask(task: WorkflowTask): WorkflowTask {
      return task;
    }

    @Emit('onUnarchiveTask')
    onEmitUnarchiveTask(task: WorkflowTask): WorkflowTask {
      return task;
    }

    @Emit('onAddSubTask')
    onEmitAddSubTask(task: WorkflowTask): WorkflowTask {
      return task;
    }

    @Emit('onDeleteTask')
    onEmitDeleteTask(task: WorkflowTask): WorkflowTask {
      return task;
    }

    getStatusColor(colour: string): string {
      return statusColour(colour);
    }
  }
