
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { debounce } from 'lodash';
import dayjs from 'dayjs'

import { SalesInvoice, SalesInvoiceStatus, SalesInvoiceType, SalesInvoiceEntity, SalesInvoicePaymentStatus, SalesInvoiceRecordType } from '@/modules/sales-invoices/types/entities';
import { FilterState } from '@/core/types/Filter'
import { Actions, Getters } from '@/modules/sales-invoices/store/types/StoreTypes';
import { Actions as TaxesActions, Getters as TaxesGetters } from '@/modules/settings/store/modules/taxes/types/StoreTypes';
import InvoicesSideMenuComponent from '@/modules/sales-invoices/components/sales-invoices-sidebar.component.vue';
import SalesInvoiceModal from '@/modules/sales-invoices/components/sales-invoice-modal/sales-invoice-modal.component.vue';
import { BankTransactionEntity, Payment, PaymentEntity } from '@/modules/payments/types/entities';
import { calculatePaymentDifference, signAmount, suggestPayment } from '@/modules/sales-invoices/helpers';
import { Tax } from '@/modules/settings/types/entities';
import { Actions as ContractorBranchActions, Getters as ContractorBranchGetters } from '@/modules/settings/store/modules/contractor-branches/types/StoreTypes';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
import { User } from '@/modules/entities/types/entities';
import useUserList from '@/composables/useUserList';
import PaymentEditPopupComponent from '@/modules/payments/components/payment-edit-popup.component.vue';
import SalesInvoiceMetricsComponent from "@/modules/reports/components/sales-invoice-metrics.component.vue";
    
	const salesInvoices = namespace("salesInvoices");
	const taxes = namespace('settings/taxes');
	const contractorBranches = namespace('settings/contractor-branches');

	// composables
	const { state: userState, actions: userActions } = useUserList()

	@Component({
		components: {
			"invoices-side-menu": InvoicesSideMenuComponent,
			"sales-invoice-modal": SalesInvoiceModal,
			'payment-create-popup': PaymentEditPopupComponent,
      SalesInvoiceMetricsComponent
		}
	})
    export default class SalesInvoicesComponent extends Vue {

		// actions
		@salesInvoices.Action(Actions.FETCH_LISTVIEW_INVOICES) fetchInvoices!: () => Promise<void>;
		@salesInvoices.Action(Actions.FETCH_LISTVIEW_NEXT_PAGE) fetchNextPage!: () => Promise<void>;
		@salesInvoices.Action(Actions.FETCH_STATUSES) fetchStatuses!: () => Promise<void>;
		@salesInvoices.Action(Actions.FETCH_PAYMENT_STATUSES) fetchPaymentStatuses!: () => Promise<void>;
		@salesInvoices.Action(Actions.FETCH_TYPES) fetchTypes!: () => Promise<void>;
		@salesInvoices.Action(Actions.SET_FILTER_FOR_SEARCH) filterForSearch!: (value: string) => Promise<void>;
		@salesInvoices.Action(Actions.CLEAR_FILTERS) clearFilters!: (payload: string[]) => void;
		@salesInvoices.Action(Actions.APPEND_TO_INVOICES) appendToInvoices!: (payload: SalesInvoice) => void;
		@salesInvoices.Action(Actions.CREATE_INVOICE) createSalesInvoice!: (payload: SalesInvoice) => void;
		@salesInvoices.Action(Actions.FETCH_SALES_INVOICE_BY_ID) fetchSalesInvoiceById!: (payload: string) => Promise<void>;
		@salesInvoices.Action(Actions.FETCH_RECORD_TYPES) fetchRecordTypes!: () => Promise<void>;
		@salesInvoices.Action(Actions.FETCH_NEW) fetchNew!: () => Promise<void>;
		@salesInvoices.Action(Actions.SAVE_PAYMENT_ON_LISTVIEW_INVOICES) savePayment!: (payload: { invoiceId: string, payment: Payment }) => Promise<void>;
		@salesInvoices.Action(Actions.FETCH_INVOICES_SUMMARY_REPORT) fetchInvoicesSummaryReport!: () => Promise<any>;
		@salesInvoices.Action(Actions.FETCH_INVOICES_METRICS_REPORT) fetchMetricsReport!: () => Promise<any>;
		@salesInvoices.Action(Actions.TOGGLE_SHOW_INVOICES_SUMMARY_REPORT) toggleShowInvoicesSummaryReport!: () => Promise<void>;
		@salesInvoices.Action(Actions.TOGGLE_SHOW_INVOICES_METRICS_REPORT) toggleShowInvoicesMetricsReport!: () => Promise<void>;
		@taxes.Action(TaxesActions.FETCH_TAXES) fetchTaxes!: () => Promise<void>
		@contractorBranches.Action(ContractorBranchActions.FETCH_ALL_CONTRACTOR_BRANCHES) fetchContractorBranches!: () => Promise<void>;

		// getters 
		@salesInvoices.Getter(Getters.GET_LISTVIEW_INVOICES) invoices!: SalesInvoice[];
		@salesInvoices.Getter(Getters.GET_NEW) new!: SalesInvoice;
		@salesInvoices.Getter(Getters.GET_STATUSES_TRANSLATED) statuses!: SalesInvoiceStatus[];
		@salesInvoices.Getter(Getters.GET_PAYMENT_STATUSES_TRANSLATED) paymentStatuses!: SalesInvoicePaymentStatus[];
		@salesInvoices.Getter(Getters.GET_TYPES_TRANSLATED) types!: SalesInvoiceType[];
		@salesInvoices.Getter(Getters.GET_RECORD_TYPES_TRANSLATED) recordTypes!: SalesInvoiceRecordType[];
		@salesInvoices.Getter(Getters.GET_LISTVIEW_FILTER_STATE) filterState!: FilterState;
		@salesInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;
		@salesInvoices.Getter(Getters.GET_LISTVIEW_IS_LOADING) listviewIsLoading!: boolean;
		@salesInvoices.Getter(Getters.GET_INVOICES_SUMMARY_REPORT) invoicesSummaryReport!: any;
		@salesInvoices.Getter(Getters.GET_INVOICES_METRICS_REPORT) invoicesMetricsReport!: any;
		@salesInvoices.Getter(Getters.GET_SHOW_INVOICES_SUMMARY_REPORT) showInvoicesSummaryReport!: boolean;
		@salesInvoices.Getter(Getters.GET_SHOW_INVOICES_METRICS_REPORT) showInvoicesMetricsReport!: boolean;
		@taxes.Getter(TaxesGetters.GET_TAXES_FOR_SALES) taxes!: Tax[];
		@contractorBranches.Getter(ContractorBranchGetters.GET_ALL_BRANCHES) contractorBranches!: ContractorBranch[];

		// local vars
		paymentStatusesAvailableForFilter: SalesInvoicePaymentStatus[] = [];
		statusesAvailableForFilter: SalesInvoiceStatus[] = [];
		typesAvailableForFilter: SalesInvoiceType[] = [];
		taxesAvailableForFilter: Tax[] = [];
		showStatusLabel: boolean = false;
		showPaymentStatusLabel: boolean = false;
		
		// local vars
		state: {
			showModalCreateSalesInvoice: boolean,
			showStatusLabel: boolean,
			showMenuAddPayment: {}
        } = {
			showModalCreateSalesInvoice: false,
			showStatusLabel: true,
			showMenuAddPayment: {}
		}
		content: {
			paymentCreate: Payment,
			searchFilterSearchValue: string,
		} = {
			paymentCreate: this.newPayment(),
			searchFilterSearchValue: '',
		}

		// handlers
		onClickAddPaymentForInvoice(invoice: SalesInvoice) {
			// const paymentTotal = invoice.paymentTotal !== undefined ? invoice.paymentTotal : 0;
			// const isPaidOrPartiallyPaid = invoice.paymentStatus && ['paid','partially_paid'].includes(invoice.paymentStatus.name) ? true : false;
			if(invoice.id) this.$set(this.state.showMenuAddPayment, invoice.id.toString(), true);
			const paymentSuggestion = this.suggestPayment(invoice)
			this.content = { ...this.content, 
				paymentCreate: {...this.newPayment(), type: paymentSuggestion.type } as Payment
			}
		}
		onClickSavePaymentForInvoice(payment: Payment, invoice: SalesInvoice) {
			const invoiceId = !!invoice.id && invoice.id;
            this.savePayment({ invoiceId: invoiceId.toString(), payment: payment }).then((value) => {
                this.$set(this.state.showMenuAddPayment, invoiceId.toString(), false);
                // this.content = {...this.content, paymentCreate: {...this.newPayment()} }
            })
		}
		onClickStatus(event: Event): void {
			this.showStatusLabel = !this.showStatusLabel;
		}
		onClickPaymentStatus(event: Event): void {
			this.showPaymentStatusLabel = !this.showPaymentStatusLabel;
		}
		onClickAddSalesInvoice() {
			this.fetchNew().then(() => {
				this.createSalesInvoice({...this.newSalesInvoice(), name: this.new.name, reference: this.new.name})
				this.state = {...this.state, showModalCreateSalesInvoice: true}
			})
		}
		onClickEditSalesInvoice(invoice: SalesInvoice) {
			if(invoice.id !== undefined) {
				this.fetchSalesInvoiceById(invoice.id.toString());
			}
		}

		// methods
		filterForSearchDebounced = debounce((value: string) => {
			this.filterForSearch(value);
		}, 400);
		clearSearchFilter(): void {
			this.content = {...this.content, searchFilterSearchValue: ''}
			this.clearFilters(['search']);
		}
		loadNextPage(): void {
			this.fetchNextPage();
		}
		newSalesInvoice() {
			const invoice = new SalesInvoiceEntity({
				vat: this.taxes.find((tax) => tax.name === 'be_vat_6'),
				invoiceDate: dayjs.utc().format('YYYY-MM-DD'),
				dueDate: dayjs.utc().add(8, 'day').format('YYYY-MM-DD'),
				status: this.statuses.find((status) => status.name === 'draft'),
				paymentStatus: this.paymentStatuses.find((paymentStatus) => paymentStatus.name === 'not_paid'),
				recordType: this.recordTypes.find((recordType) => recordType.name === 'invoice'),
				type: this.types.find((type) => type.name === 'default'),
			});
			return invoice;
		}
		newPayment() {
			const payment = new PaymentEntity({
				total: null, // show empty amount by default
				bankTransaction: new BankTransactionEntity({
					executedAt: Vue.prototype.$formatISO8601()
				})
			})
			return payment;
		}
		navigateToSalesInvoiceDetailsPage(salesInvoice: SalesInvoice): void {
			this.$router.push(`/invoices/sales/${ salesInvoice.id }`);
		}
		signAmount(amount: number, recordType: SalesInvoiceRecordType) {
			return signAmount(amount, recordType)
		}
		getColorForVat6CertificateStatus(status: string) {
			switch (status) {
				case 'not_required': return 'rgb(0, 195, 125)'
				case 'not_received': return 'rgb(255, 0, 0)'
				case 'received_first': return 'rgb(254, 170, 0)'
				case 'received_all': return 'rgb(0, 195, 125)'
			}
		}
		calculatePaymentDifference(invoice: SalesInvoice) {
			if(invoice.paymentTotal && invoice.recordType && invoice.payments) {
				return calculatePaymentDifference(invoice, invoice.payments)
			}
			return 0
		}
		suggestPayment(invoice: SalesInvoice) {
			return suggestPayment(invoice, invoice.payments ? invoice.payments : [])
		}

		// getters & setters
        get users() { return userState.users.value || [] }
		get showReports() {
			return this.showInvoicesSummaryReport || this.showInvoicesMetricsReport
		}
		get showStatus(): boolean {
			return this.showStatusLabel;
		}
		get showPaymentStatus(): boolean {
			return this.showPaymentStatusLabel;
		}
		get showExecutedAtDate(): string {
			if(this.content.paymentCreate && this.content.paymentCreate.bankTransaction && this.content.paymentCreate.bankTransaction.executedAt) {
				return Vue.prototype.$formatDate(this.content.paymentCreate.bankTransaction.executedAt);
			}
			return '';
        }
		get headers() {
			return [
				{
					text: '',
					iconPath: '',
					width: '45px',
					minWidth: '45px',
				},
				{
					text: "invoices.sales.dataTable.headers.invoiceNumber",
					iconPath: "",
				},
				{
					text: "invoices.sales.dataTable.headers.projectOrSupplier",
					iconPath: "",
				},
				{
					text: "invoices.sales.dataTable.headers.invoiceDate",
					iconPath: "",
				},
				{
					text: "invoices.sales.dataTable.headers.dueDate",
					iconPath: "",
				},
				{
					text: "invoices.sales.dataTable.headers.type",
					iconPath: "",
				},
				{
					text: "invoices.sales.dataTable.headers.paymentStatus",
					iconPath: "",
					width: '80px',
					minWidth: '80px',
				},
				{
					text: "invoices.sales.dataTable.headers.total",
					iconPath: "",
				},
				{
					text: "invoices.sales.dataTable.headers.grandTotal",
					iconPath: "",
				},
				// {
				// 	text: "invoices.sales.dataTable.headers.paymentTotal",
				// 	iconPath: "",
				// },
				{
					text: "invoices.sales.dataTable.headers.unpaidTotal", // open payment amount
					iconPath: "",
				},
				{
					text: "invoices.sales.dataTable.headers.vat",
					iconPath: "",
					width: '80px',
					minWidth: '80px',
				},
				{
					text: "invoices.sales.dataTable.headers.comment",
					iconPath: ""
				}
			]
		}
		get invoicesSummaryReportFormatters() {
			return [
				{
					value:'totalBalance', 
					format: (value: any) => { return Vue.prototype.$formatMoney(value) }
				},
				{
					value:'grandTotalBalance', 
					format: (value: any) => { return Vue.prototype.$formatMoney(value) }
				},
			]
		}
		get invoicesSummaryReportSummarizers() {
			return [
				{
					value: 'totalBalance',
					summarize: (items: any[]) => { 
						return items.map((item: any) => item.totalBalance ? Number.parseInt(item.totalBalance) : 0).reduce((prev, curr) => { return prev+curr }, 0)
					}, 
				},
				{
					value: 'grandTotalBalance',
					summarize: (items: any[]) => { 
						return items.map((item: any) => item.grandTotalBalance ? Number.parseInt(item.grandTotalBalance) : 0).reduce((prev, curr) => { return prev+curr }, 0)
					}, 
				},
			];
		}
		get invoicesSummaryReportHeaders() {
			return [
				{
					text: this.$t('invoices.sales.dataTable.headers.year').toString(),
					align: 'end',
					sortable: true,
					value: 'year',
					groupable: true,
					sort: (a: string, b: string) => { return b && a ? Number.parseInt(a) - Number.parseInt(b) : 0}
				},
				{
					text: this.$t('invoices.sales.dataTable.headers.month').toString(),
					align: 'start',
					sortable: true,
					value: 'month',
					groupable: true,
					sort: (a: string, b: string) => { return b && a ? Number.parseInt(a) - Number.parseInt(b) : 0}
				},
				{
					text: this.$t('invoices.sales.dataTable.headers.total').toString(),
					align: 'end',
					sortable: true,
					value: 'totalBalance',
					groupable: false,
				},
				{
					text: this.$t('invoices.sales.dataTable.headers.grandTotal').toString(),
					align: 'end',
					sortable: true,
					value: 'grandTotalBalance',
					groupable: false,
				},
			]
		}

		// watchers
		
		requiredData() {
            return [
                this.statuses && this.statuses.length ? null : this.fetchStatuses(),
                this.paymentStatuses && this.paymentStatuses.length ? null : this.fetchPaymentStatuses(),
                this.recordTypes && this.recordTypes.length ? null : this.fetchRecordTypes(),
                this.types && this.types.length ? null : this.fetchTypes(),
				this.taxes && this.taxes.length ? null : this.fetchTaxes(),
				this.contractorBranches && this.contractorBranches.length ? null : this.fetchContractorBranches(),
            ].filter(v => !!v)
        }
		
		// boot methods
		created(): void {
			if(!(this.users && this.users.length)) userActions.fetchUsers()
			Promise.all(this.requiredData())
				.then((results) => {
					this.fetchInvoices();
					this.statusesAvailableForFilter = [...this.statuses]
					this.paymentStatusesAvailableForFilter = [...this.paymentStatuses]
					this.typesAvailableForFilter = [...this.types]
					this.taxesAvailableForFilter = [...this.taxes]
				});
    }
}
