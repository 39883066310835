
  import { computed, defineComponent, ref, watch, PropType } from 'vue';
  import { Project } from '@/modules/projects/types/entities';
  import PlannerSettingsProjectListItemComponent from '@/modules/calendar/components/settings-project-list-item.component.vue';

  export default defineComponent({
    components: {
      'project-list-item': PlannerSettingsProjectListItemComponent,
    },
    props: {
      projects: {
        type: Array as PropType<Project[]>,
        default: () => [],
      },
    },
    setup(props, {}) {
      return {};
    },
  });
