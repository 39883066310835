export default {
  administration: {
    title: 'Administratie',
  },
  contacts: {
    title: 'Contacten',
    createModal: {
      title: 'Contact toevoegen',
      existingContactsFound: 'We hebben een soortgelijk contact gevonden in onze database',
      choose: 'Kiezen',
      useExistingContact: 'Gebruik bestaande contactpersoon',
      useThisContact: 'Gebruik deze contactpersoon',
      resultsFound: 'resultaten gevonden',
    },
    common: {
      addTeleinformation: 'Contactgegevens toevoegen',
      address: 'Adres',
      contactDetails: 'Contactgegevens',
      other: 'Andere',
      add: 'Contact toevoegen',
      title: 'Contactpersoon | Contactpersonen',
      name: 'Contactnaam',
      landline: 'Telefoonnummer',
      mobile: 'Mobiel',
      email: 'Email',
      noContacts: 'Geen contactpersonen',
      noComment: 'Geen commentaar',
    },
    details: {
      title: 'Contactpersoon',
      labels: {
        address: 'Adres',
        name: 'Naam',
        personalInformation: 'Persoonlijke Informatie',
        street: 'Straat',
        number: 'Nummer',
        postalCode: 'Postcode',
        city: 'Stad',
        landlinePhone: 'Vaste telefoon',
        landlinePhoneComment: 'Vaste telefoon (commentaar)',
        mobilePhone: 'GSM/Smartphone',
        mobilePhoneComment: 'GSM/Smartphone (commentaar)',
        email: 'Email',
        emailComment: 'Email (commentaar)',
        isMainContactInformation: 'Meest gebruikte contactgegevens',
        bankAccountNumber: 'Bankrekeningnummer',
        nationalInsuranceNumber: 'Rijksregisternummer',
        vatNumber: 'BTW-nummer',
        function: 'Functie',
        socialSecurityNumber: 'Rijksregisternummer',
        website: 'Website',
        streetNumber: 'Huisnummer',
        flatNumber: 'Bus',
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        teleinformations: 'Contactgegevens',
        comment: 'Opmerkingen',
        noContactInformation: 'Geen contactgegevens',
      },
      comments: {
        add: 'Commentaar toevoegen',
        title: 'Opmerkingen',
        author: 'Auteur',
        date: 'Datum',
        noComments: 'Geen reacties',
      },
      suppliers: {
        title: 'Gekoppelde leveranciers',
        name: '@:common.name',
        vatNumber: 'BTW-nummer',
        ssrNumber: 'SSR-nummer',
        add: 'Leverancier toevoegen',
        noSuppliers: 'Geen gekoppelde leveranciers',
      },
      clients: {
        title: 'Gekoppelde klanten',
        name: '@:common.name',
        add: 'Klant koppelen',
        noClients: 'Geen gekoppelde klanten',
      },
      projects: {
        title: 'Gekoppelde projecten',
        projectName: 'Projectnaam',
        add: 'Project koppelen',
        noProjects: 'Geen gekoppelde projecten',
      },
    },
  },
  suppliers: {
    sideMenu: {
      searchByName: 'Zoek op leveranciersnaam',
      searchBySupplierType: 'Zoek op type leverancier',
      searchByContractorBranch: 'Zoeken per aannemer',
      typeOfSupplier: 'Type leverancier',
      contractorTypes: 'Aannemingstakken',
      additionalFilters: 'Overige filters',
      offerReceivedLabel: 'Offerte ontvangen',
      workedTogetherLabel: 'Reeds samengewerkt',
      plannedLabel: 'Ingepland in de toekomst',
    },
    title: 'Leveranciers',
    selectTypeTable: {
      title: 'Kies type leverancier',
      chooseSupplier: 'Kies externe partij',
    },
    selectSupplierTable: {
      title: 'Kies leverancier',
      createNew: 'Nieuwe leverancier toevoegen',
    },
    details: {
      title: 'Leverancier gegevens',
      attributes: {
        companyName: 'Bedrijfsnaam',
        commercialName: 'Commerciele naam',
        noCommercialName: 'Geen Commercial name',
        vatNumber: 'BTW-nummer',
        ssrNumber: 'SSR-number',
        province: 'Werkregio',
        accountNumber: 'Rekeningnummer',
        noAccountNumbers: 'Geen rekeningnummer',
        outboundKm: 'Outbound Km',
        sfinxAgreement: 'Sfinx agreement',
        agreementEndDate: 'Einddatum Contract',
        agreementStartDate: 'Begindatum Contract',
        price: 'Prijs',
        quality: 'Kwaliteit',
        selection: 'SEL.',
        technicalExpertise: 'Technische expertise',
        aestheticQuality: 'Esthetische kwaliteit',
        landlinePhone: 'Vaste telefoon',
        mobilePhone: 'Mobiele telefoon',
        email: 'Mail',
        website: 'Website',
        futureWork: 'Selectie Toekomst',
        activities: 'Activiteiten',
        speciality: 'Specialiteiten',
        address: 'Adres',
        contractorTypes: 'Aannemingstakken',
        supplierTypes: 'Type leverancier',
        noContractorTypes: 'Geen typen aannemers',
        noSupplierTypes: 'Geen typen leveranciers',
        noContactInformation: 'Geen contactgegevens',
        createdAt: 'Aangemaakt',
        updatedAt: 'Laatst bijgewerkt',
        noDate: 'Geen datum',
      },
      teleinformations: {
        add: 'Contactgegevens toevoegen',
      },
      contacts: {
        title: 'Contactpersonen',
      },
      companyLocations: {
        title: 'Vestigingen',
        add: '+ Voeg locatie toe',
        provinces: {
          antwerp: 'Antwerpen',
          eastFlanders: 'Oost-Vlaanderen',
          flemishBrabant: 'Vlaams-Brabant',
          limburg: 'Limburg',
          westFlanders: 'West-Vlaanderen',
          hainault: 'Henegouwen',
          liege: 'Luik',
          luxemburg: 'Luxemburg',
          namur: 'Namen',
          walloonBrabant: 'Waals-Brabant',
          brusselsCapitalRegion: 'Brussels Hoofdstedelijk Gewest',
          belgium: 'België',
        },
      },
      comments: {
        add: 'Toevoegen',
        title: 'Opmerkingen',
        author: 'Auteur',
        date: 'Datum',
        noComments: 'Geen comments',
      },
      projects: {
        title: 'Projecten',
        name: 'Project Naam',
        startDate: 'Toegevoegd op',
        endDate: 'End datum',
        status: 'Status',
        noProjects: 'Geen projecten',
        noStatus: 'Geen Status',
      },
      gmap: {
        popupTitle: 'Adres selecteren',
      },
    },
    add: {
      heading: 'Leverancier toevoegen',
      name: 'Naam',
      addTeleinformations: 'Contactgegevens toevoegen',
      headings: {
        registrationDetails: 'Registratiegegevens',
        address: 'Adres',
        type: 'Type',
        contactDetails: 'Contactgegevens',
        invoicingAndPayments: 'Facturatie en betalingen',
        evaluation: 'Evaluatie',
      },
      placeholders: {
        name: 'Naam',
        commercialName: 'Commerciele naam',
        vatNumber: 'BTW-nummer',
        ssrNumber: 'SSR-nummer',
        companyType: 'Type selecteren',
        companyTypes: 'Type selecteren',
        accountNumber: '0000 0000 0000 0000;',
        street: 'Straatnaam',
        streetNumber: 'Straatnummer',
        postcode: 'Postcode',
        city: 'Stad',
        province: 'Provincie',
        contractorType: 'Type selecteren',
        activities: 'Bedrijfsactiviteit',
        workforce: 'Personeelsbestand',
        comment: 'Commentaar',
        website: 'http://',
      },
      labels: {
        name: 'Naam',
        commercialName: 'Commerciele naam',
        vatNumber: 'BTW-nummer',
        ssrNumber: 'SSR-nummer',
        companyType: 'Bedrijfsvorm',
        companyTypes: 'Leverancierstypen',
        primaryAccountNumber: 'Primair rekeningnummer',
        otherAccountNumbers: 'Andere rekeningnummers',
        street: 'Straatnaam',
        streetNumber: 'Straatnummer',
        postcode: 'Postcode',
        city: 'Stad',
        province: 'Werkregio',
        contractorType: 'Aannemingstakken',
        activities: 'Bedrijfsactiviteit',
        workforce: 'Personeelsbestand',
        landlinePhone: 'Telefoonuummer',
        otherLandlinePhone: 'Andere telefoonuummer',
        mobilePhone: 'Primair telefoonnummer',
        otherMobilePhone: 'Andere mobile',
        email: 'Primair e-mailadres',
        otherEmail: 'Andere email',
        comment: 'Commentaar',
        website: 'Website',
        aestheticQuality: 'Esthetische kwaliteit',
        price: 'Prijs',
        quality: 'Kwaliteit',
        paymentDueDays: 'Betalingstermijn',
        discountedPaymentWithin: 'Betaling met korting binnen',
        discountedPaymentPercentage: 'Percentage van de betalingskorting',
        monthEndExtend: 'Maandeinde verlengen',
      },
      error: {
        required: {
          name: 'Naam is vereist.',
          companyTypes: 'Bedrijfstypen zijn vereist.',
        },
        button: {
          cancel: 'Annuleren',
          retry: 'Opnieuw proberen',
        },
        message: 'Helaas was het niet mogelijk om deze actie uit te voeren. Gelieve opnieuw te proberen.',
      },
      others: {
        selectionMultiplePlaceholder: '(+{count} anderen)',
        existingSupplierFound: 'We hebben al een zeer vergelijkbare leverancier in onze database gevonden',
      },
    },
    branchForm: {
      add: 'Add vestiging',
      edit: 'Edit vestiging',
      headings: {
        name: 'Naam',
        address: 'Adres',
        contact: 'Contactgegevens',
      },
      labels: {
        commercialName: 'Commerciële naam',
        vatNumber: 'BTW-nummer',
      },
      placeholders: {
        commercialName: 'Commerciële naam',
        vatNumber: 'BTW-nummer',
      },
      error: {
        message: 'Helaas was het niet mogelijk om deze actie uit te voeren. Gelieve opnieuw te proberen.',
        button: {
          cancel: 'Annuleren',
          retry: 'Opnieuw proberen',
        },
        required: {
          commercialName: 'Naam is vereist.',
        },
      },
      success: {
        messageUpdate: `Aannemingstak werd bewerkt.`,
        messageCreate: 'Aannemingstak werd aangemaakt.',
        button: {
          close: 'Afsluiten',
        },
      },
    },
    messages: {
      deleteError: 'Er is een fout opgetreden bij het verwijderen van de leverancier',
      deleteSuccess: 'De leverancier is succesvol verwijderd',
      editSuccess: 'Leverancier succesvol bijgewerkt',
      undoDeleteSuccess: 'De bewerking is teruggedraaid',
      undoDeleteError: 'Er is een fout opgetreden bij het ongedaan maken van de bewerking',
      deleteContactSuccess: 'Contact is succesvol verwijderd',
      deleteContactError: 'Er is een fout opgetreden bij het verwijderen van de contactpersoon',
      deleteCompanyLocationSuccess: 'Vestiging werd verwijderd',
      confirmCompanyLocationDeletion: 'Weet je zeker dat je {name} wilt verwijderen?',
    },
  },
  clients: {
    modal: {
      title: 'Voeg een klant toe',
      streetLabel: 'Straat',
      numberLabel: 'Nummer',
      postalCodeLabel: 'Postcode',
      cityLabel: 'Stad',
      landlinePhoneLabel: 'Vaste telefoon',
      phoneCommentLabel: 'Telefooncommentaar',
      primaryMobilePhoneLabel: 'Primaire mobiele telefoon',
      secondaryMobilePhoneLabel: 'Secundaire mobiele telefoon',
      primaryEmailLabel: 'Primaire email',
      secondaryEmailLabel: 'Secundaire email',
      emailCommentLabel: 'Emailcommentaar',
      bankAccountNumberLabel: 'Bankrekeningnummer',
      nationalInsuranceNumberLabel: 'Rijksregisternummer',
      address: 'Adres',
      contactDetails: 'Contactgegevens',
      other: 'Andere',
      vatNumberLabel: 'BTW-nummer',
      createNew: 'Nieuwe klant toevoegen',
    },
    details: {
      title: 'Klantgegevens',
      type: 'Klant',
      labels: {
        entityType: 'Bedrijfsvorm',
        vatNumber: 'BTW-nummer',
        nationalInsuranceNumber: 'RSZ nummer',
        bankAccountNumber: 'Rekeningnummer',
        commercialName: 'Commerciële naam',
        address: 'Adres',
        website: 'Website',
        landlinePhone: 'Telefoonnummer',
        mobilePhone: 'Mobiel',
        email: 'Email',
        addedDate: 'toegevoegd op',
        lastModified: 'laatst gewijzigd op',
      },
      placeholders: {
        entityType: 'Bedrijfstype',
        vatNumber: 'BTW-nummer',
        nationalInsuranceNumber: 'RSZ nummer',
        primaryBankAccountNumber: 'Primair rekeningnummer',
        secondaryBankAccountNumber: 'Secundair rekeningnummer',
        commercialName: 'Commerciele naam',
        street: 'Straatnaamt',
        streetNumber: 'Straatnummer',
        postalCode: 'Postcode',
        city: 'Staad',
        website: 'http://',
        primaryLandlinePhone: 'Primair vast telefoonnummer',
        secondaryLandlinePhone: 'Secundair vast telefoonnummer',
        primaryMobilePhone: 'Primair GSM-nummer',
        secondaryMobilePhone: 'Secundair GSM-nummer',
        primaryEmail: 'Primair e-mailadres',
        secondaryEmail: 'Secundair e-mailadres',
        landlinePhoneComment: 'Commentaar',
        mobilePhoneComment: 'Commentaar',
        emailComment: 'Commentaar',
      },
      attributes: {
        name: '@:common.name',
      },
      contacts: {
        add: 'Toevoegen',
        title: 'Contactpersonen',
        name: '@:common.name',
        landline: 'Vast telefoonnummer',
        mobile: 'GSM-nummer',
        email: 'E-mailadres',
        noContacts: 'Geen contactpersonen',
        confirmDelete: 'Bent u zeker dat u deze contact wilt verwijderen?',
      },
      projects: {
        title: 'Projecten',
        add: 'Toevoegen',
        empty: 'Geen projecten',
        projectName: 'Projectnaam',
        projectLeaders: 'Projectleider',
        estimators: 'Calculator',
        initialOffer: 'Bedrag voor',
        totalAmountOrdered: 'Totaal besteld',
        totalAmountExecuted: 'Totaal uigev.',
        dateInitialOffer: 'bedrag eerst voorgelegde offerte',
        dateFinancialDetails: 'Datum fin.',
        status: 'Status',
      },
      comments: {
        add: 'Commentaar toevoegen',
        title: 'Opmerkingen',
        author: 'Auteur',
        date: 'Datum',
        noComments: 'Geen comments',
      },
    },
  },
  company: {
    types: {
      bvba: 'Besloten vennootschap met beperkte aansprakelijkheid',
      sbvba: 'Besloten vennootschap met beperkte aansprakelijkheid “starter”',
      nv: 'Naamloze vennootschap',
      cvba: 'CooperatieveVennootschap met BeperkteAansprakelijkheid',
      vof: 'Vennootschap onder firma',
      gcv: 'Gewone commanditaire vennootschap',
      cva: 'Commanditaire vennootschap op aandelen',
      vzw: 'Vereniging zonder winstoogmerk',
      ivzw: 'Internationale vereniging zonder winstoogmerk',
      een: 'Eenmanszaak',
      gvg: 'Geen vennootschapssvorm gekend',
      bv: 'Besloten Vennootschap',
    },
  },
  common: {
    search: 'Zoeken',
    filter: 'Filter | Filters',
    description: 'Beschrijving | Beschrijvingen',
    client: 'Klant | Klanten',
    noClients: 'Geen klanten',
    noProjects: 'Geen projecten',
    noSuppliers: 'Geen leveranciers',
    or: 'of',
    and: 'en',
    contractor: 'Aannemers | Aannemers',
    menu: {
      toggleMenu: 'Menu in/uitschakelen',
    },
    date: 'Datum',
    tools: 'Gereedschap',
    save: 'Opslaan',
    saveAndNew: 'Opslaan en nieuw',
    cancel: 'Annuleren',
    lorem: 'Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet Lorem ipsum sin dolor anet',
    done: 'Klaar',
    name: 'Naam',
    status: 'Status',
    undo: 'Ongedaan maken',
    delete: 'Verwijderen',
    deletePermanently: 'Permanent verwijderen',
    loading: 'laden...',
    add: 'Toevoegen',
    noDataText: 'Geen Data',
    noDataDash: '---',
    select: 'Selecteer',
    address: 'Adres',
    archive: 'Archief',
    retry: 'Opnieuw proberen',
    choose: 'Kiezen',
    noName: 'Geen naam',
    comment: 'Nota',
    messages: {
      invalidFormData: 'Opgegeven gegevens waren ongeldig',
      forbidden: 'Je hebt onvoldoende rechten om deze data te raadplegen of te wijzigen',
      conflict: 'Er trad een conlict op bij het opslaan van de gegevens.',
      unexpectedError: 'Er is een onverwachte fout opgetreden',
      savedSuccess: 'Succesvol opgeslagen',
      contactAlreadyAttached: 'Deze contactpersoon werd reeds toegevoegd.',
    },
    searchResult: '%{num} %{val} gevonden',
    other: 'Andere',
    all: 'Allemaal',
    ok: 'OK',
    close: 'Sluiten',
    okClose: 'Ok, sluiten',
    confirm: 'Bevestigen',
    activate: 'Activeer | Activeren',
    currentSelection: 'Huidige selectie',
    days: 'Dagen',
    inactive: 'Inactief',
    yes: 'Ja',
    no: 'Neen',
    detach: 'Losmaken',
    provideTextToCreateComment: 'De commentaar werd niet toegevoegd. Gelieve een tekst in te geven',
    cannotSaveEmptyComment: 'Er kan geen lege commentaar opgeslagen worden',
    upload: 'Uploaden',
    createdAt: 'Aangemaakt op',
    updatedAt: 'Gewijzigd op',
    deletedAt: 'Verwijderd op',
    to: 'Naar',
    areYouSure: 'Ben je zeker?',
    areYouSureToDelete: 'Ben je zeker dit te verwijderen?',
    login: 'Aanmelden',
    logout: 'Afmelden',
    untilInclusive: 'Tot en met',
    until: 'Tot',
  },
  finance: {
    title: 'Financieel',
    incoming: {
      title: 'Inkomende facturen',
    },
    outgoing: {
      title: 'Uitgaande facturen',
    },
    summary: {
      title: 'Overzicht',
      tim: 'Overzicht Tim',
    },
    postCalculation: {
      title: 'Nacalculatie',
    },
    forecast: {
      title: 'Vooruitzicht',
    },

  },
  home: {
    title: 'Home',
  },
  planner: {
    title: 'Planning',
    deletePopup: {
      periodsAreAvailable: 'Deze periode kan samengevoegd worden met andere periodes',
      continueWithoutMerge: 'Verdergaan zonder samenvoegen',
      merge: 'Samenvoegen',
      mergeWithPeriods: '--- | Samenvoegen met een bestaande periode van één dag. | Samenvoegen met een bestaande periode van {count} dagen.',
      startDate: 'Startdatum',
      dataWillBeMerged: 'Alle bestaande data (nota\'s, werkdagen, enz.) worden samengevoegd in 1 periode',
      detachContractor: 'Ben je zeker dat u {contractor} wilt losmaken?',
      removePlannerPeriod: 'Bent u zeker dat u deze periode wilt verwijderen?',
      removeWorkDay: 'Bent u zeker dat u deze werkdag wilt verwijderen?',
      removeComment: 'Bent u zeker dat u deze commentaar wilt verwijderen?',
    },
    header: {
      title: 'Planner',
      mainTimeRange: 'Alle rijen van een tijdsbestek: ',
      hideHolidays: 'Weekends en vakanties verbergen',
      hideEmptyRows: 'Lege rijen verbergen',
      dateRanges: {
        LAST_EVENT_90_DAYS: 'Laatste event + 90 dagen',
        TODAY_90_DAYS: 'Vandaag + 90 dagen',
        TODAY_180_DAYS: 'Vandaag + 180 dagen',
        FIRST_TO_LAST_EVENT: 'Eerste-laatste event',
      },
    },
    grid: {
      openAll: 'Alles uitvouwen',
      collapseAll: 'Alles sluiten',
      noContractors: 'Geen medewerkers',
      noProjects: 'Geen projecten',
      noTeamMembers: 'Geen teamleden',
    },
    sideMenu: {
      workers: 'Werknemers',
      general: 'Algemeen',
      selectedDays: '1 dag | {count} dagen',
      duration: 'Tijdsduur',
      moveProjectLabel: 'Project verplaatsen',
      moveFirstEvent: 'Project verplaatsen met eerste event op:',
      moveLastEvent: 'Project verplaatsen met laatste event op:',
      daysLabel: 'Dag(en)',
      weeksLabel: 'Weken',
      monthsLabel: 'Maand(en)',
    },
    settings: {
      title: 'Weergave',
      sideMenu: {
        showMyProjects: 'Enkel mijn projecten tonen',
        showAllResponsibilityLevels: 'Alle verantwoordelijkheidsniveaus tonen',
        statuses: 'Statussen',
        leaders: 'Projectleiders',
        searchByLeaders: 'Zoek op projectleiders',
        searchByClients: 'Zoek op Klanten',
        supplierTypes: 'Aannemingstypes',
      },
      shared: {
        displayedInPlanner: 'weergegeven in planner',
        filtered: 'gefilterd',
      },
      projectsTable: {
        searchByProjects: 'Zoek op projecten',
        showInTeams: 'Toon in teams',
        filtersDisabled: 'Alle filters zijn uitgeschakeld',
        projects: 'Projecten',
      },
      collaboratorsTable: {
        teamName: 'Teamnaam',
        searchByCollaborators: 'Zoek op teams',
        collaborators: 'Medewerker',
        showAllTeams: 'Alle teams tonen',
      },
    },
    globalMessage: {
      workDayDeletionSuccess: 'De werkdag is verwijderd.',
      periodDeletionSuccess: 'De periode is verwijderd.',
      findWorkDayFailure: 'De werkdag kon niet gevonden worden.',
      cannotFindDataForPeriodMerge: 'De nodige data om de periodes samen te voegen kan niet gevonden worden',
      workDayAlreadyExist: 'Er is al werk ingepland op deze datums.',
      periodDatesGotAdjusted: 'De datums in deze periode werden aangepast',
      workDayConflict: 'U kunt de datum niet wijzigen. Op die dag staat er al een event gepland.',
      adjacentDayNotHoliday: 'Data kan enkel verplaatst worden naar een andere verlofdag.',
      adjacentDayHoliday: 'Aanliggende dag is een verlofdag.',
      valueCannotBeEmpty: 'Value cannot be empty.',
      noContractorsForProject: 'There are no contractors for this project',
    },
  },
  projects: {
    title: 'Projecten',
    globalMessage: {
      archiveSuccess: 'Project is succesvol gearchiveerd',
      archiveError: 'Er is een fout opgetreden bij het archiveren van het project',
      unArchiveSuccess: 'Project is uit het archief gehaald',
      unArchiveError: 'Er is een fout opgetreden bij het uit het archief halen van het project',
      deleteContactSuccess: 'Contact is succesvol verwijderd',
      deleteContactError: 'Er is een fout opgetreden bij het verwijderen van de contactpersoon',
      editSuccess: 'Project is succesvol bewerkt',
    },
    sideMenu: {
      searchByName: 'Zoek op projectnaam',
      listTitle: 'PROJECT',
      projectStatuses: 'Projectstatus',
      searchByProjectStatus: 'Zoek op projectstatus',
      projectLeaders: 'Projectleider',
      searchByLeader: 'Zoek op leider',
      showMoreLeaders: 'Toon meer projectleiders',
      showLessLeaders: 'Toon minder projectleiders',
      showMoreStatuses: 'Toon meer statussen',
      showLessStatuses: 'Toon minder statussen',
      coWorkers: 'Andere medewerkers',
      estimators: 'Calculators',
      searchByEstimators: 'Zoek op calculators',
      designers: 'Ontwerpers',
      searchByDesigners: 'Zoek op ontwerpers',
      drawers: 'Tekenaar',
      searchByDrawers: 'Zoek op tekenaar',
      cities: 'Steden',
    },
    modal: {
      addProject: 'Project toevoegen',
      editProject: 'Project bewerken',
      namePlaceholder: 'Naam van het project',
      clientPlaceholder: 'Typ klantnaam',
      addClient: 'Client toevoegen',
      noClients: 'Klant bestaat nog niet',
      addressLabel: 'Adres',
      streetLabel: 'Straat',
      streetNumberLabel: 'Huisnummer',
      postalCodeLabel: 'Postcode',
      cityLabel: 'Stad',
      projectLeadersLabel: 'Projectleider',
      projectLeadersPlaceholder: 'Wijs projectleiders toe',
      estimatorsLabel: 'Calculator',
      estimatorsPlaceholder: 'Wijs schatters toe',
      designersLabel: 'Ontwerper',
      designersPlaceholder: 'Wijs ontwerpers toe',
      drawersLabel: 'Tekenaar',
      drawersPlaceholder: 'Wijs tekenaars toe',
      responsibilityPercentage: 'Percentage',
      projectStatusLabel: 'Projectstatus',
      projectStatusPlaceholder: 'Stel een status in',
      addedDateLabel: 'Toegevoegd op',
      datePlaceholder: 'Selecteer een datum',
      initialOfferDateLabel: 'bedrag eerst voorgelegde offerte',
      financialDetailsLabel: 'Datum Fin',
      initialOfferLabel: 'Bedrag voorgelegd',
      totalAmountOrdersLabel: 'Totaal besteld',
      totalAmountExecutedLabel: 'Totaal uigevoerd',
      comment: 'Nota',
    },
    errors: {
      projectNameLength: 'Projectnaam mag niet langer zijn dan 191 tekens',
    },
    clients: {
      assignClients: 'Klant toewijzen',
    },
    ongoing: 'Voortdurende',
    archived: 'Gearchiveerde',
    dataTable: {
      headers: {
        ID: 'ID',
        pnum: 'P. num.',
        address: 'Werf Adres',
        projectName: 'Project Naam',
        contacts: 'Contacten',
        executed: 'Uitgevoerd',
        projectLeader: 'Projectleider',
        estimator: 'Calculator',
        designer: 'Ontwerper',
        drawer: 'Techn. Tekenaar',
        presentedPrice: 'Bedrag voor.',
        totalAmountOrdered: 'Besteld',
        initialOffer: 'bedrag eerst voorgelegde offerte',
        totalAmountExecuted: 'Totaal uigev.',
        dateFinancialDetails: 'Datum Fin.',
        projectStatus: '@:common.status',
      },
      noStatus: 'Geen status',
      noProjects: 'Geen projecten',
    },
    search: {
      projectName: 'Zoeken by project naam',
      projectStatuses: 'Projectstatussen',
      projectLeaders: 'Projectleiders',
      designers: 'Ontwerpers',
      estimators: 'Calculator',
    },
    details: {
      title: 'Projecten details',
      notAssigned: 'Niet besteld',
      tempAssigned: 'Voorlopig toegewezen',
      permAssigned: 'Besteld',
      labels: {
        address: 'Werfadres',
        client: 'Klant',
        status: 'Status',
        addedDate: 'Toegevoegd op',
        projectLeader: 'Projectleider [{count}]',
        calculator: 'Calculator [{count}]',
        designer: 'Ontwerper [{count}]',
        drawers: 'Tekenaar [{count}]',
      },
      headings: {
        responsibility: 'Verantwoordelijkheid',
        generalComments: 'Algemene commentaren',
      },
      others: {
        showWorkflow: 'Workflow tonen',
        hideWorkflow: 'Workflow verbergen',
        showMoreComments: 'Meer commentaren tonen',
        showLessComments: 'Minder commentaren tonen',
      },
      placeholders: {
        street: 'Straatnaam',
        streetNumber: 'Nr.',
        postalCode: '00-000',
        city: 'Stad',
        client: 'Typen om te zoeken',
        comment: 'Gelieve tekst in te voeren',
      },
      attributes: {
        projectName: '@:common.name',
        address: 'Adres',
        distanceToSfinxHq: 'Afstand',
        presentedPrice: 'Voorgelegd aan klant',
        projectStatus: '@:common.status',
        totalAmountOrdered: 'Totaal Besteld',
        addedDate: 'Toegevoegd op',
        projectLeaders: 'Projectleiders',
        totalAmountExecuted: 'Totaal uitgevoerd',
        estimators: 'Calculatoren',
        initialOffer: 'Bedrag voorgelegd',
        noProjectLeaders: 'Geen projectleiders',
        noEstimators: 'Geen Calculators',
        noDesigners: 'Geen Designers',
        noDrawers: 'Geen Drawers',
        dateFinancialDetails: 'Datum financiële gegevems',
        dateInitialOffer: 'bedrag eerst voorgelegde offerte',
        edit: 'Bewerken',
        workPercentage: 'Werk Percentage',
      },
      financialCharts: {
        executed: 'Uitgevoerd',
        ordered: 'Besteld',
        paid: 'Betaald',
        invoiced: 'Gefactureerd',
        costs: 'Kosten',
        hoursInternalEmployees: 'Werkuren eigen arbeiders',
        of: 'van',
      },
      thirdParties: {
        title: 'Externe Partij | Externe Partijen',
        supplierType: 'Type leverancier',
        companyName: 'Bedrijfsnaam',
        addSupplier: 'Kies externe partij',
        name: '@:common.name',
        supplier: 'Leverancier',
        noThirdParties: 'Geen externe partijen',
      },
      contractorParties: {
        add: 'Aannemer toevoegen',
        title: 'Aannemers',
        name: '@:common.name',
        supplier: 'Leverancier',
        noContractorParties: 'Geen aannemers',
      },
      contractorPartiesModal: {
        title: 'Aannemingstak toevoegen',
      },
      quotes: {
        title: 'Offertes',
        status: 'Status bestelling',
        branch: 'Vestiging',
        contractor: 'Aannemer',
        ordering: 'Nr.',
        offerRequested: 'Offerte aangevraagd',
        offerDone: 'Offerte klaar',
        offerReceived: 'Offerte ontvangen',
        invoicing: 'Facturatie',
        noQuotes: 'Geen offertes',
        addSupplier: 'Aannemer toewijzen',
        nextSupplier: 'Volgende aannemer',
        newSupplier: 'Nieuwe aannemer',
      },
      planning: {
        title: 'Planning',
        numeration: 'Nr. {index}',
        addPeriod: '+ Periode toevoegen',
      },
      orders: {
        navigateToPlanner: 'Planning',
        title: 'Bestellingen',
        plan: 'Plan',
        start: 'Begin',
        end: 'Einde',
        noOrders: 'Geen bestellingen',
        plannerPeriodNotFound: 'Periode kon niet gevonden worden. Gelieve opnieuw te proberen.',
      },
      purchaseInvoices: {
        title: 'Inkomende facturen',
        name: 'Volgnummer',
        projectRelationshipTotal: 'Deelbedrag',
        purchaseInvoiceTotal: 'Factuurbedrag',
        supplier: 'Leverancier',
        externalName: 'Factuurnummer (lev.)',
        contractorType: 'Aannemingstak',
      },
      salesInvoices: {
        title: 'Uitgaande facturen',
        name: 'Factuurnummer',
        total: 'Bedrag (excl BTW)',
        grandTotal: 'Bedrag (incl BTW)',
        paymentTotal: 'Bedrag (te betalen)',
        vat: 'BTW',
        invoiceDate: 'Factuurdatum',
        dueDate: 'Vervaldatum',
        type: 'Type',
        paymentStatus: 'Betaalstatus',
      },
      timesheetEntries: {
        title: 'Prestaties arbeiders',
        name: 'Naam',
        duration: 'Duur',
        durationExtra: 'Extra duur',
        hourlyRate: 'Uurtarief',
        avgMaterialCost: 'Materiaalkost',
        totalCost: 'Totale kost',
        day: 'Datum',
        contractorBranch: 'Aannemingstak',
      },
      contractorBranches:{
        title: 'Nacalculatie: niet relevante aannemingstakken',
        subtitle:"Toevoegen/ verwijderen en wijzigen van niet relevante aannemingstakken",
        name: 'Naam',
        comment: "Opmerking",
        newContractorBranch: "Toevoegen",
        deleteContractorBranch: "Aannemingstak verwijderen",
        areYouSureToDeleteProjectContractorBranch: 'Ben je zeker dat je deze aannemingstak terug als relevant wilt markeren?',
        notRelevantContractorBranch: 'De aannemingstak zal als niet relevant beschouwd worden in de nacalculatie.',
        editContractorBranch: "Aannemingstak wijzigen",

      },
      clients: {
        title: 'Attesten',
        name: 'Naam',
        vat6CertificateStatus: 'BTW-attest (6%)',
      },
      comments: {
        add: 'Commentaar toevoegen',
        title: 'Opmerkingen',
        author: 'Auteur',
        date: 'Datum',
        noComments: 'Geen commentaar',
      },
      gmap: {
        popupTitle: 'Adres selecteren',
      },
      responsibilitySelect: {
        title: 'Projectmedewerkers',
        sumWarning: 'De som kan niet groter zijn dan 100%.',
        sumErrorTotal: 'De som moet gelijk zijn aan 100%.',
        errorInteger: 'De waarde moet een geheel getal zijn.',
        sumTitle: '{length} gebruiker{char}',
      },
    },
    statuses: {
      PROSPECT: 'In prospectie',
      DESIGN: 'Ontwerpfase',
      OFFER_IN_PROGRESS: 'Offerte in opmaak',
      OFFER_READY: 'Offerte klaar',
      IN_PROGRESS: 'In uitvoering',
      VERIFICATION: 'In nazicht',
      READY_FOR_ADMINISTRATION: 'Klaar voor administratie',
      POSTPONED_LONG_TERM: 'Uitgesteld op lange termijn',
      DONE: 'Afgewerkt',
      NO_OFFER: 'Geen prijs gegeven',
      NOT_SOLD: 'Niet verkocht',
    },
    socialSecurityRegistrations: {
      title: 'Projectmeldingen',
      dataTable: {
        headers: {
          projectName: 'Projectnaam',
          projectLeader: 'Projectleider',
          projectProgressPrecentage: 'Vordering',
          projectStartDate: 'Begin',
          projectEndDate: 'Einde',
          socialSecurityReference: 'MeldingsNr.',
          socialSecurityStartDate: 'Vanaf',
          socialSecurityEndDate: 'Tot',
          projectSocialSecurityStatus: '# Dagen tot Overtijd',
          socialSecurityRegistrationStatus: 'Status',
          contractor: 'Aannemer',
          contractorBranch: 'AT',
          contractorVatNumber: 'BTW-Nummer',
          contractorStartDate: 'Start',
          contractorEndDate: 'Einde',
          comment: 'Commentaar',
        },
      },
      buttons: {
        labels: {
          toggleShowCompliant: 'Compliant',
        },
        titles: {
          markAsRegistered: 'Aangemeld bij sociale zekerheid',
          markAsNotRegistered: 'Niet aangemeld bij sociale zekerheid',
          markProjectSocialSecurityAsCompleted: 'Markeer de sociale zekerheid voor dit project als afgerond',
          markProjectSocialSecurityAsNotCompleted: 'Markeer de sociale zekerheid voor dit project als niet afgerond',
        },
      },
      icons: {
        titles: {
          contractorIsRegisteredButNotInPlanner: 'Aannemer is geregistreerd maar is niet (correct) ingepland',
          projectSocialSecurityIsCompleted: 'De sociale zekerheid voor dit project is afgerond',
        },
      },
      statuses: {
        registered: 'Geregistreerd',
        not_registered: 'Niet geregistreerd',
      },
    },
  },
  projectClients: {
    vat6CertificateStatuses: {
      not_received: 'Nog geen attest ontvangen',
      received_all: 'Alle attesten ontvangen/aangevuld',
      not_required: 'Geen attest noodzakelijk',
      received_first: 'Eerste attest ontvangen',
    },
  },
  settings: {
    title: 'Instellingen',
    divisions: {
      title: 'Interne Teams',
    },
    teamMembers: {
      title: 'Teamleden',
      table: {
        firstName: 'Voornaam',
        lastName: 'Familienaam',
        hourlyRate: 'Uurtarief',
        avgMaterialCost: 'Gemiddelde materiaalkosten',
        overallCost: 'Totale kosten',
        startDate: 'Startdatum',
        endDate: 'Einddatum',
      },
      sidemenu: {
        addRates: {
          addTitle: 'Tarief van teamlid toevoegen',
          editTitle: 'Tarief van teamlid bewerken',
          newRate: 'Nieuw tarief',
          averageMaterialCost: '@:settings.teamMembers.table.avgMaterialCost',
          startDate: '@:settings.teamMembers.table.startDate',
          endDate: '@:settings.teamMembers.table.endDate',
        },
      },
      deletePopup: {
        confirmWageDeletion: 'Bent u zeker dat u dit tarief wilt verwijderen?',
      },
      globalMessage: {
        wageEditionSuccess: 'Het tarief werd succesvol gewijzigd.',
        wageCreationSuccess: 'Het tarief werd succesvol aangemaakt.',
        wageDeletionSuccess: 'Het tarief werd succesvol verwijderd.',
        wageDateRangeError: 'Er is al een tarief actief voor deze periode.',
        missingEndDate: 'Gelieve een einddatum toe te voegen aan het vorige tarief.',
        undoDeleteSuccess: '@:suppliers.messages.undoDeleteSuccess',
      },
    },
    collectiveHolidays: {
      title: 'Collectief verlof',
      types: {
        paid: 'Betaald verlof',
        sickLeave: 'Ziekteverlof',
      },
      globalMessage: {
        createSuccess: 'Vakantie is met succes gemaakt',
        editSuccess: 'Vakantie is met succes bewerkt',
        deleteSuccess: 'Vakantie is verwijderd',
      },
    },
    collaboratorBranches: {
      title: 'Aannemingstakken',
    },
    deleted: {
      title: 'Verwijderd',
    },
    logs: {
      title: 'Logs',
    },
    profile: {
      title: 'Profiel',
    },
    workflow: {
      title: 'Workflow instellingen',
      tasks: {
        title: 'Taken',
        addTask: '+ Nieuwe taak toevoegen',
        editTask: 'Taak bewerken',
        mainTask: 'Taak titel | Taak Titels',
        noTasks: 'Geen Taken',
        subTask: 'Taak | Taken',
        ongoing: 'Voortdurende',
        archived: 'Gearchiveerde',
        archive: 'Archief',
        unArchive: 'Unarchive',
        sequence: 'Volgorde',
        errors: {
          sequenceRequiredError: 'Volgorde is verplicht',
        },
        modal: {
          taskName: 'Opdrachtnaam',
          mainTask: 'Hoofdtaak',
          subTask: 'Subtaak',
          subTasks: 'Subtaken',
          noSubTasks: 'Geen subtaken',
        },
      },
      taskStatuses: {
        addTaskStatus: '+ Taakstatus toevoegen',
        title: 'Taakstatussen',
        date: 'Datum',
        noTaskStatuses: 'Geen Taakstatussen',
        modal: {
          colourSelection: 'Kleur selectie',
          symbolSelection: 'Symbool selectie',
        },
      },
      functionGroups: {
        addFunctionGroup: '+ Functiegroep toevoegen',
        noFunctionGroups: 'Geen Functiegroepen',
        title: 'Verantwoordelijke',
      },
      shared: {
        name: 'Naam',
        symbol: 'Symbool',
        description: 'Beschrijving',
        errors: {
          nameIsRequiredError: 'Naam is vereist',
          maxStringLengthError: 'Maximale tekstlengte is 255',
          colorError: 'Kleur is verplicht',
          symbolError: 'Symbool is verplicht',
        },
      },
    },
    errorMessages: {
      abbreviationLimit: 'De afkorting mag niet bestaan uit meer dan twee karakters.',
    },
    accounting: {
      title: 'Boekhouding',
    },
  },
  constructionSiteManagement: {
    title: 'Werfbeheer',
  },
  workflow: {
    title: 'Workflow',
    overview: {
      title: 'Overzicht',
      myProjectsButton: 'Mijn projecten tonen',
      functionGroups: 'Verantwoordelijke',
      noConstructionSitesSelected: 'Geen werven geselecteerd',
      configureNewStatus: 'Nieuwe status configureren',
      defaultStatus: 'NO_STATUS',
      allNotes: {
        latestNote: 'Laatste comentaar',
        addNote: 'Commentaar tovoegen',
        phase: 'Fase:',
      },
      modifyNote: {
        addTitle: 'Commentaar tovoegen',
        editTitle: 'Comentaar bewerken',
        addingTo: 'U bent de commentaar aan het toevoegen op:',
        editing: 'U bent de commentaar aan het bewerken op:',
        statusChange: '@:common.status change:',
      },
      siteSelection: {
        selectPhaseTitle: 'Selectie werven',
        selectPeopleTitle: 'Selectie mensen',
        search: 'Zoek naar project',
        functionGroup: 'Functie groep | Functie groepen',
        group_leaders: 'Projectleiders',
        group_estimators: 'Calculatoren',
        group_designers: 'Ontwerpers',
        group_drawers: 'Tekenaren',
        showMore: 'Meer tonen',
        hide: 'Verbergen',
      },
      infoPopup: {
        addNote: 'Commentaar toevoegen',
        showAll: 'Alle comentaren tonen',
        hideAll: 'Alles verbergen',
        title: 'Details',
        lastUpdate: 'Aangepast op',
        currentStatus: 'Huidige status',
        notYetUpdated: 'Nog niet aangepast',
        comment: 'Nota',
        taskStatuses: {
          DONE: '@:common.done',
          NOT_APPLICABLE: 'NVT',
          DONE_FOLLOW_UP: 'Klaar, maar op te volgen',
          IN_PROGRESS: 'Bezig',
          STUCK: 'Zit vast',
          NOT_OK_FOLLOW_UP: 'Niet oké, follow-up',
          NOT_YET_STARTED: 'nog niet gestart',
          DATE_STATUS: 'datum status',
        },
      },
      statusDialog: {
        title: '@:common.status',
        currentStatus: 'Huidige status',
        noStatus: 'Geen',
        selectNewStatus: 'Nieuwe status kiezen',
        comment: 'Opmerking',
        updateComment: 'Opmerking opslaan',
      },
    },
  },
  invoices: {
    title: 'Facturen',
    sales: {
      title: 'Uitgaande Facturen',
      search: {
        searchOn: 'Zoek op project, leverancier, factuurnummer, bedrag, opmerkingen...',
      },
      sideMenu: {
        statuses: 'Factuurstatus',
        paymentStatuses: 'Betaalstatus',
        types: 'Type',
        dueDate: 'Vervaldatum',
        invoiceDate: 'Factuurdatum',
        fromUntilDateRange: 'van ... tot ...',
        searchByStatus: 'Zoek een status...',
        searchByPaymentStatus: 'Zoek op betaalstatus...',
        searchByType: 'Zoek een type...',
        listTitleInvoices: 'Facturen',
        listTitlePayments: 'Betalingen',
        listTitleProjects: 'Projecten',
        lastMonth: 'Afgelopen maand',
        lastThreeMonths: 'Afgelopen 3 maand',
        lastThreeMonthsToDate: 'Afgelopen 3 maand tot nu',
        yearToDate: 'Begin dit jaar tot nu',
        projectLead: 'Projectleider',
        project: 'Project',
        searchByProjectLead: 'Zoek een projectleider...',
        searchByProjectName: 'Zoek op projectnaam...',
        vat: 'BTW',
        dueDateFrom: 'Vervaldatum vanaf...',
        dueDateTo: 'Vervaldatum tem...',
        invoiceDateFrom: 'Factuurdatum vanaf...',
        invoiceDateTo: 'Factuurdatum tem...',
      },
      dataTable: {
        headers: {
          invoiceNumber: '#',
          projectOrSupplier: 'Project / Leverancier',
          invoiceDate: 'Factuur datum',
          dueDate: 'Verval datum',
          type: 'Type',
          status: 'Factuurstatus',
          paymentStatus: 'Status',
          total: 'Bedrag (excl BTW)',
          grandTotal: 'Bedrag (incl BTW)',
          paymentTotal: 'Bedrag (te betalen)',
          vat: 'BTW',
          paymentDiscount: 'Betalingskorting',
          comment: 'Opmerkingen (intern)',
          year: 'Jaar',
          month: 'Maand',
          unpaidTotal: 'Bedrag (openstaand)',
        },
      },
      details: {
        title: 'Uitgaande {recordType}',
        labels: {
          project: 'Project',
          supplier: 'Leverancier',
          contact: 'Contact',
          contactData: 'Contactgegevens',
          relatedTo: 'Gerelateerd aan',
          addressData: 'Adresgegevens',
          paymentData: 'Factuurbedrag',
          invoiceData: 'Factuurgegevens',
          billingAddress: 'Factuuradres',
          billingAddressStreet: 'Straat',
          billingAddressNumber: 'Nummer',
          billingAddressStreetAdditional: 'Bus',
          billingAddressPostalCode: 'Postcode',
          billingAddressCity: 'Stad / Gemeente',
          billingAddressPostalCodeAndCity: 'Postcode & Stad/Gemeente',
          billingAddressState: 'Provincie',
          billingAddressCountry: 'Land',
          status: 'Factuurstatus',
          paymentStatus: 'Betaalstatus',
          type: 'Type',
          recordType: 'Factuur / Creditnota',
          subtype: 'Subtype',
          totalPaid: 'Totaal betaald',
          lastPaymentDate: 'Laatste betaaldatum',
          name: 'Factuurnummer',
          vatNumber: 'BTW-nummer',
          description: 'Factuurlijnen',
          comment: 'Opmerkingen (intern, zichtbaar in overzicht)',
          invoiceDate: 'Factuurdatum',
          dueDate: 'Vervaldatum',
          terms: 'Voorwaarden',
          vatPercentage: 'BTW-percentage',
          subTotal: 'Subtotaal (excl. product-kortingen en BTW)',
          total: 'Bedrag (excl. BTW)',
          grandTotal: 'Bedrag (incl. BTW)',
          paymentTotal: 'Bedrag (te betalen)',
          taxTotal: 'BTW-bedrag',
          paymentDiscount: 'Betalingskorting',
          reference: 'Betaalreferentie',
          addressAndContactData: 'Factuuradres',
          vat: 'BTW',
          contactName: 'Naam',
          companyName: 'Bedrijfsnaam',
          deductedAdvance: 'Verrekend voorschot (excl BTW)',
          searchContactInformation: 'Zoek naar contactgegevens...',
          private: 'Particulier',
          business: 'Bedrijf',
          client: 'Klant',
          calculateInvoiceAmount: 'Bereken factuurbedrag',
          invoiceTotalsSummary: 'Overzicht alle project-facturen',
          previousInvoiceTotalsSummary: 'Overzicht voorgaande project-facturen',
          reuseBillingInformation: 'Ik wil deze adresgegevens als template gebruiken voor toekomstige facturen.',
          individualInvoices: 'Individuele facturen',
          summaryInvoices: 'Aggregatie facturen',
          individualPreviousInvoices: 'Individuele voorgaande facturen',
          previousInvoices: 'Voorgaande facturen',
          summaryPreviousInvoices: 'Aggregatie voorgaande facturen',
          projectAddress: 'Werfadres',
          clientAddress: 'Klantadres',
          supplierAddress: 'Leverancieradres',
          progressSnapshot: 'Vorderingsstaat',
        },
        placeholders: {
          name: 'Factuurnummer',
          project: 'Project',
          supplier: 'Leverancier',
          contact: 'Contact',
          invoicedTo: 'Gefactureerd aan',
          billingAddress: 'Factuuradres',
          billingAddressStreet: 'Straat',
          billingAddressNumber: 'Nummer',
          billingAddressStreetAdditional: 'Bus',
          billingAddressPostalCode: 'Postcode',
          billingAddressCity: 'Stad / Gemeente',
          billingAddressPostalCodeAndCity: 'Postcode & Stad/Gemeente',
          billingAddressState: 'Provincie',
          billingAddressCountry: 'Land',
          status: 'Factuurstatus',
          paymentStatus: 'Betaalstatus',
          recordType: 'Factuur / Creditnota',
          type: 'Type',
          subtype: 'Subtype',
          reference: 'Betaalreferentie',
          vatNumber: 'BTW-nummer',
          invoiceDate: 'Factratiedatum',
          dueDate: 'Vervaldatum',
          vat: 'BTW',
          subTotal: 'Subtotaal (excl. product-kortingen en BTW)',
          total: 'Bedrag (excl. BTW)',
          grandTotal: 'Bedrag (incl. BTW, excl. betaalkorting)',
          paymentTotal: 'Bedrag (incl. BTW, incl. betaalkorting)',
          taxTotal: 'BTW-bedrag',
          paymentDiscount: 'Betalingskorting',
          contactName: 'Contactnaam',
          companyName: 'Bedrijfsnaam',
          description: '"Factuurlijnen" zoals informatie over specifieke producten, premies, etc kan je hier neerschrijven. Dit komt op de factuur (document) te staan en is dus zichtbaar voor klanten.',
          terms: 'Voorwaarden',
          searchContactInformation: 'Zoek naar contactgegevens...',
          client: 'Klant',
          comment: 'Commentaar kan je hier neerschrijven. Dit is zichtbaar voor alle collega\'s en zal/kan niet gebruikt worden voor communicatie naar klanten.',
        },
        interactions: {
          relatedTo: 'Aan welk project en/of leverancier is deze factuur gekoppeld?',
          billingAddress: 'Wat is het adres waarop moet worden gefactureerd?',
          status: 'Wat is de huidige status van deze factuur?',
          type: 'Welke soort factuur is dit?',
          paymentData: 'Wat zijn de bedragen die moeten worden betaald?',
          invoiceData: 'Welke gegevens zijn nodig voor een correcte administratie? (BTW-nummer, referentie, ...)',
          addressAndContactData: 'Op welk adres en aan wie is deze factuur gericht?',
          comment: 'Is er belangrijke/interressante informatie voor collega\'s, ter referentie, ...?',
          reuseBillingAddressInformation: 'Eerder gebruikte factuuradressen:',
          searchForRelatedContacts: 'Zoek naar adresgegevens van contactpersonen gerelateerd aan project, klant of leverancier',
          invoiceTotalsSummary: 'Aggregaties van alle project-facturen voor het huidige project vind je hieronder',
          previousInvoiceTotalsSummary: 'Aggregaties van alle voorgaande facturen (exclusief deze factuur) voor het huidige project vind je hieronder',
          explainProgressSnapshot: 'Geef het besteld en uitgevoerd bedrag op dat je in de vorderingsstaat kan terugvinden.',
          explainInvoiceCalculation: 'Als het voorgestelde totaalbedrag (al dan niet met verrekende voorschotten) ok is, bevestig je dit als nieuwe factuurbedrag. Je kan uiteraard het bekomen bedrag nog steeds aanpassen indien nodig.',
          noProjectSelected: 'Er is nog geen project geselecteerd.',
        },
        relatedLists: {
          projectInvoices: {
            labels: {
              groupBy: 'Groepeer veld',
            },
            placeholders: {
              groupBy: 'Selecteer een veld om op te groeperen...',
            },
            headers: {
              id: 'Id',
              name: 'Factuurnummer',
              type: {
                name: 'Type',
              },
              subtype: {
                name: 'Sub Type',
              },
              status: {
                name: 'Factuurstatus',
              },
              paymentStatus: {
                name: 'Betaalstatus',
              },
              projectOrSupplier: 'Project / Leverancier',
              invoiceDate: 'Factuurdatum',
              dueDate: 'Vervaldatum',
              total: 'Bedrag (excl BTW)',
              deductedAdvance: 'Verrekend voorschot (excl BTW)',
              paymentTotal: 'Bedrag (incl BTW)',
              vatPercentage: 'BTW-tarief',
              paymentDiscount: 'Betalingskorting',
              comment: 'Opmerkingen (intern)',
              projectTotalAmountOrderedSnapshot: 'Besteld (excl BTW)',
              projectTotalAmountProcessedSnapshot: 'Uitgevoerd (excl BTW)',
            },
          },
        },
        errors: {
          noFirstAdvance: 'Er werd nog geen eerste voorschot betaald.',
          firstAdvanceButNoSecondAdvance: 'Let op: voorschot deel 2 werd nog niet aangerekend.',
          projectProgressStateNotProvided: 'Niet alle projectgegevens omtrent besteld en uitgevoerd bedrag zijn voorzien.',
          noProgressCalculationApplied: 'Er werden (nog) <b><u>geen vorderingsgegevens ingegeven</u></b>. Je kan de factuur wel opslaan. Weet wel dat een gebrek aan gegevens mogelijks een invloed kan hebben op rapportering.',
          differentProgression: 'Het huidige factuurbedrag lijkt niet overeen te komen met wat werd berekend op basis van de ingegeven vorderingsgegevens.',
          processedAmountCannotBeGreaterThanOrderedAmount: 'Uitgevoerd bedrag kan niet groter zijn dan besteld bedrag.',
          addressIncomplete: 'Onvolledig adres',
        },
      },
      icons: {
        isDue: 'Overtijd / Achterstallig',
      },
      buttons: {
        labels: {
          showTotals: 'Toon totalen',
          showTotalsPerYearMonth: 'Toon totalen per jaar/maand',
        },
      },
      reports: {
        labels: {
          totals: 'Totalen',
          totalsPerYearMonth: 'Totalen per jaar/maand',
          paymentStatus: 'Betaalstatus',
          openNotOverdue: 'Totaal openstaand niet vervallen',
          openOverdue: 'Totaal openstaand vervallen',
          open: 'Totaal openstaand',
          types: 'Factuurtype',
          allWithoutStudyAgreement: 'Totaal exclusief studieovereenkomst',
          allWithoutStudyAgreementShort: 'Totaal excl. studie',
          allWithoutStudyAgreementAndAdvance: 'Totaal exclusief studieovereenkomst en voorschotten',
          allWithoutStudyAgreementAndAdvanceShort: 'Totaal excl. studie & voorsch',
          onlyStudyAgreement: 'Totaal studieovereenkomst',
          onlyAdvance: 'Totaal voorschotten',
          generalTotal: 'Algemeen totaal',
          summaryTotal: 'Totaal',
          total: 'Bedrag (excl BTW)',
          grandTotal: 'Bedrag (incl BTW)',

        },
      },
      info: {
        onlyNegativeValuesAllowed: 'Enkel negatieve waarden toegestaan (uitgaande factuur vs creditnota)',
        onlyPositiveValuesAllowed: 'Enkel positieve waarden toegestaan (uitgaande factuur vs creditnota)',
      },
      modal: {
        addInvoice: 'Nieuwe uitgaande factuur',
      },
      globalMessage: {
        createInvoiceSuccess: 'Factuur werd succesvol aangemaakt.',
        updateInvoiceSuccess: 'Factuur werd succesvol gewijzigd.',
        deleteInvoiceSuccess: 'Factuur werd succesvol verwijderd.',
      },
      components: {
        progressCalculator: {
          labels: {
            openVersusTotalAdvance: 'Niet verrekend / totaal voorschot',
            toBeInvoicedExcludingAdvance: 'Factuurbedrag (excl voorschot)',
            appliedAdvance: 'Verrekend voorschot',
            toBeInvoicedIncludingAdvance: 'Factuurbedrag (incl voorschot)',
            apply: 'Toepassen',
            beforeCalculation: 'Vóor berekening',
            afterCalculation: 'Ná berekening',
            toBeInvoicedTotal: 'Totaal te factureren',
            orderedAmount: 'Besteld bedrag',
            processedAmount: 'Uitgevoerd bedrag',
            previousProgressInvoice: 'Voorgaande vorderingsfactuur',
            project: 'Project',
            firstAdvanceCanBeDeducted: 'Factuurbedrag: {toBeInvoicedWithAdvance}. Eerste deel voorschot wordt verrekend.', // ({advance} van {totalAdvances} totaal)',
            firstAdvanceCannotBeDeductedTooSmall: 'Factuurbedrag: {toBeInvoicedWithAdvance}. Eerste deel voorschot wordt niet verrekend.',
            finalAdvanceCanBeDeducted: 'Factuurbedrag: {toBeInvoicedWithAdvance}. Finale deel voorschot wordt verrekend.', // {advance}',
            finalAdvanceCannotBeDeductedTooSmall: 'Factuurbedrag: {toBeInvoicedWithAdvance}. Finale deel voorschot kan niet worden verrekend, te betalen bedrag té klein ({toBeInvoiced}).',
            defaultResult: 'Factuurbedrag: {toBeInvoicedWithAdvance}. Er wordt geen voorschot verrekend.',
            calculateSimulation: 'Simuleer bedrag',
            confirmSimulation: 'Bevestig als nieuw factuurbedrag',
            confirmInvoiceToCreditNoteForFinalAdvance: 'Factuur aanpassen naar creditnota',
            progressState: 'Vorderingsstaat',
            previousProgressState: 'Vorige vorderingsstaat',
            newProgressState: 'Nieuwe vorderingsstaat (huidige berekening)',
          },
        },
        summary: {
          labels: {
            extraInformation: 'Extra informatie',
          },
        },
      },
      statuses: {
        draft: 'Kladfactuur',
        done: 'Factuur',
      },
      paymentStatuses: {
        not_paid: 'Nog niet betaald',
        paid_too_much: 'Te veel betaald',
        paid_too_little: 'Te weinig betaald',
        paid: 'Betaald',
        overdue: 'Achterstallige betaling',
        undefined: 'Niet gespecifieerd',
      },
      recordTypes: {
        invoice: 'Factuur',
        credit_note: 'Creditnota',
        undefined: 'Niet gespecifieerd',
      },
      types: {
        default: 'Vordering',
        advance: 'Voorschot',
        study_agreement: 'Studiekost',
        other: 'Andere',
        advance_full: 'Volledig',
        advance_first: 'Eerste deel',
        advance_second: 'Tweede deel',
        undefined: 'Niet gespecifieerd',
      },
    },
    purchase: {
      title: 'Inkomende Facturen',
      search: {
        searchOn: 'Zoek op leverancier, factuurnummer, bedrag, opmerkingen...',
      },
      sideMenu: {
        statuses: 'Labelstatus',
        approvalStatuses: 'Goedkeuringsstatus',
        dueDate: 'Vervaldatum',
        invoiceDate: 'Factuurdatum',
        fromUntilDateRange: 'van ... tot ...',
        searchByStatus: 'Zoek een status...',
        listTitleInvoices: 'Facturen',
        listTitleSuppliers: 'Leveranciers',
        lastMonth: 'Afgelopen maand',
        lastThreeMonths: 'Afgelopen 3 maand',
        lastThreeMonthsToDate: 'Afgelopen 3 maand tot nu',
        yearToDate: 'Begin dit jaar tot nu',
        listTitlePayments: 'Betalingen',
        paymentStatuses: 'Betaalstatus',
        accountingStatuses: 'Boekhoudstatus',
        searchByPaymentStatus: 'Zoek een betaalstatus...',
        searchByAccountingStatus: 'Zoek een boekhoudstatus...',
        supplier: 'Leverancier',
        paymentDiscountDueDate: 'Betalingskortingdatum',
        paymentDiscountDueDateFrom: 'Vanaf ...',
        paymentDiscountDueDateTo: 'Tot ...',
        projects: 'Projecten',
      },
      dataTable: {
        headers: {
          name: '#',
          externalName: '# (leverancier)',
          supplier: 'Leverancier',
          invoiceDate: 'Factuur datum',
          dueDate: 'Verval datum',
          status: 'Status',
          total: 'Bedrag (excl BTW)',
          grandTotal: 'Bedrag (incl BTW)',
          paymentTotal: 'Bedrag (incl BTW)',
          paymentDiscount: 'Betalingskorting',
          comment: 'Opmerkingen (intern)',
        },
      },
      details: {
        title: 'Inkomende {recordType}',
        labels: {
          supplier: 'Leverancier',
          contactData: 'Contactgegevens',
          relatedTo: 'Gerelateerd aan',
          paymentData: 'Factuurbedrag',
          invoiceData: 'Factuurgegevens',
          status: 'Status',
          labelStatus: 'Labelstatus',
          paymentStatus: 'Betaalstatus',
          accountingStatus: 'Boekhoudstatus',
          approvalStatus: 'Goedkeuringsstatus',
          recordType: 'Factuur / Creditnota',
          type: 'Type',
          totalPaid: 'Totaal betaald',
          name: 'Volgnummer',
          externalName: 'Factuurnummer (lev.)',
          description: 'Beschrijving',
          comment: 'Opmerkingen (intern, zichtbaar in overzicht)',
          invoiceDate: 'Factuurdatum',
          dueDate: 'Vervaldatum',
          paymentDiscountDueDate: 'Betaalkorting vervaldatum',
          subTotal: 'Subtotaal (excl. product-kortingen en BTW)',
          total: 'Bedrag (excl. BTW)',
          grandTotal: 'Bedrag (incl. BTW, excl. betaalkorting)',
          paymentTotal: 'Bedrag (incl. BTW, incl. betaalkorting)',
          taxTotal: 'BTW-bedrag',
          paymentDiscount: 'Betalingskorting',
          individualInvoices: 'Individuele facturen',
          individualPreviousInvoices: 'Individuele voorgaande facturen',
          previousInvoices: 'Voorgaande facturen',
          paymentTerm: 'Betaaltermijn',
          paymentTermInDays: '--- | {paymentTermInDays} dag | {paymentTermInDays} dagen',
          paymentDiscountTermInDays: '--- | {paymentDiscountTermInDays} dag | {paymentDiscountTermInDays} dagen',
          lastPaymentDate: 'Laatste betaaldatum',
          projectData: 'Projecten en deelbedragen',
          untilEndOfMonth: 'Tot eindemaand',
          recalculateAccountingItems: 'Boekhoudlijnen automagisch berekenen',
        },
        placeholders: {
          supplier: 'Leverancier',
          status: 'Factuurstatus',
          paymentStatus: 'Betaalstatus',
          accountingStatus: 'Boekhoudstatus',
          approvalStatus: 'Goedkeuringsstatus',
          recordType: 'Factuur / Creditnota',
          type: 'Type',
          totalPaid: 'Totaal betaald',
          name: 'Factuurnummer',
          externalName: 'Factuurnummer (extern)',
          description: 'Beschrijving',
          comment: 'Opmerkingen (intern, zichtbaar in overzicht)',
          invoiceDate: 'Factuurdatum',
          dueDate: 'Vervaldatum',
          paymentDiscountDueDate: 'Betaalkorting vervaldatum',
          subTotal: 'Subtotaal (excl. product-kortingen en BTW)',
          total: 'Bedrag (excl. BTW)',
          grandTotal: 'Bedrag (incl. BTW, excl. betaalkorting)',
          paymentTotal: 'Bedrag (incl. BTW, incl. betaalkorting)',
          taxTotal: 'BTW-bedrag',
          paymentDiscount: 'Betaalkorting',
          paymentDiscountPercentage: '% betaalkorting',
        },
      },
      buttons: {
        labels: {
          showTotals: 'Toon totalen',
          showTotalsPerYearMonth: 'Toon totalen per jaar/maand',
        },
      },
      reports: {
        labels: {
          totals: 'Totalen',
          totalsPerYearMonth: 'Totalen per jaar/maand',
          openNotOverdue: 'Totaal openstaand niet vervallen',
          openOverdue: 'Totaal openstaand vervallen',
          open: 'Totaal openstaand',
          paymentStatus: 'Betaalstatus',
          generalTotal: 'Algemeen totaal',
          status: 'Status',
          notLabeled: 'Niet (100%) gelabeled',
          notApproved: 'Niet goedgekeurd',
          month: 'Maand',
          year: 'Jaar',
          summaryTotal: 'Totaal',
          total: 'Bedrag (excl BTW)',
          grandTotal: 'Bedrag (incl BTW)',
        },
      },
      titles: {
        questionsOrComments: 'Vragen of opmerkingen...',
        pdf: 'Pdf',
      },
      info: {
        questionsOrComments: 'Vragen of opmerkingen zijn zichtbaar voor collega\'s en zijn altijd gelinked aan de factuur (bv niet aan een deelbedrag).',
        onlyNegativeValuesAllowed: 'Enkel negatieve waarden toegestaan (inkomende factuur vs creditnota)',
        onlyPositiveValuesAllowed: 'Enkel positieve waarden toegestaan (inkomende factuur vs creditnota)',
        recalculateAccountingItems: 'Boekhoudlijnen zullen automatisch worden herberekend op basis van de deelbedragen voor deze factuur.',
      },
      modal: {
        addInvoice: 'Nieuwe inkomende factuur',
      },
      globalMessage: {
        createInvoiceSuccess: 'Factuur werd succesvol aangemaakt.',
        updateInvoiceSuccess: 'Factuur werd succesvol gewijzigd.',
        deleteInvoiceSuccess: 'Factuur werd succesvol verwijderd.',
      },
      statuses: {
        labeled: 'Gelabeld',
        not_labeled: 'Niet (100%) gelabeld',
        undefined: 'Niet gespecifieerd',
      },
      paymentStatuses: {
        paid: 'Betaald',
        not_paid: 'Niet betaald',
        partially_paid: 'Deels betaald',
        undefined: 'Niet gespecifieerd',
      },
      accountingStatuses: {
        completed: 'Ok',
        not_completed: 'Niet Ok',
      },
      approvalStatuses: {
        pending: 'Nog goed te keuren',
        approved: 'Goedgekeurd',
        not_approved_temporary: 'Niet goedgekeurd (tijdelijk)',
        not_approved_permanent: 'Niet goedgekeurd (definitief)',
        partially_approved: 'Deels goedgekeurd',
        not_approved: 'Niet goedgekeurd',
        undefined: 'Niet gespecifieerd',
      },
      recordTypes: {
        invoice: 'Factuur',
        credit_note: 'Creditnota',
        undefined: 'Niet gespecifieerd',
      },
      comments: {
        saveComment: 'Post',
        label: 'Comments',
        placeholder: 'Plaats comment...',
        noComments: 'Geen comments',
      },
    },
    purchaseAccountingItems: {
      details: {
        labels: {
          subTotal: 'Subtotaal (excl. product-kortingen en BTW)',
          total: 'Bedrag (excl. BTW)',
          totalShort: 'Bedrag',
          grandTotal: 'Bedrag (incl. BTW)',
          grandTotalShort: 'Bedrag',
          paymentTotal: 'Bedrag (incl. BTW, incl. betaalkorting)',
          paymentTotalShort: 'Te betalen',
          taxTotal: 'BTW',
          description: 'Omshrijving',
          vatPercentage: 'BTW (%)',
          accountingLedgerItem: 'Boekhoudpost',
          vat: 'BTW (%)',
          typeDebit: 'Debit',
          typeCredit: 'Credit',
        },
        placeholders: {
          subTotal: 'Subtotaal (excl. product-kortingen en BTW)',
          total: 'Bedrag (excl. BTW)',
          totalShort: 'Bedrag',
          grandTotal: 'Bedrag (incl. BTW)',
          grandTotalShort: 'Bedrag',
          paymentTotal: 'Bedrag (incl. BTW, incl. betaalkorting)',
          paymentTotalShort: 'Te betalen',
          taxTotal: 'BTW',
          description: 'Omshrijving',
          vatPercentage: 'BTW (%)',
          accountingLedgerItem: 'Boekhoudpost',
          vat: 'BTW (%)',
        },
      },
      titles: {
        editAccountingItemModal: 'Wijzig boekhoudlijn',
        createAccountingItemModal: 'Nieuwe boekhoudlijn',
        deleteAccountingItemModal: 'Verwijder beokhoudlijn',
      },
      info: {
        ifNoVatAvailableAskAdministrator: 'Indien er geen BTW percentages beschikbaar zijn, moeten deze worden ingesteld voor de boekhoudpost op deze boekhoudlin. Vraag jouw Administrator om dit te doen. {0}',
      },
      components: {
        relatedList: {
          title: 'Boekhoudlijnen',
        },
      },
      globalMessage: {
        createLineItemSuccess: 'Factuurlijn werd toegevoegd.',
        updateLineItemSuccess: 'Factuurlijn werd succesvol gewijzigd.',
      },
    },
    purchaseProjectRelationships: {
      details: {
        labels: {
          total: 'Bedrag (excl. BTW)',
          totalShort: 'Bedrag',
          description: 'Omshrijving',
          project: 'Project',
          contractorType: 'Aannemingstak',
          request: 'Aanvraag?',
          accountingLedgerItem: 'Boekhoudpost',
          status: 'Status',
          approvalStatus: 'Goedgekeurd',
          assignedTo: 'Aan {name}',
          requestedBy: 'Aangevraagd door{name}',
          forwardedBy: 'Doorgestuurd door',
          requestedByTo: 'Aangevraagd door {by} aan {to}',
          requestedByShort: 'Van {name}',
          recipient: 'Ontvanger | Ontvanger | Ontvangers',
          typeCredit: 'Ingehouden',
          typeDebit: 'Toegekend',
          labeled: 'Gelabeld',
          approved: 'Goedgekeurd',
        },
        placeholders: {
          total: 'Bedrag (excl. BTW)',
          totalShort: 'Bedrag',
          description: 'Omshrijving',
          project: 'Project',
          contractorType: 'Aannemingstak',
          request: 'Aanvraag?',
          accountingLedgerItem: 'Boekhoudpost',
          status: 'Status',
          approvalStatus: 'Goedgekeurd',
          assignedTo: 'Aan...',
          requestedBy: 'Aangevraagd door...',
        },
      },
      buttons: {
        annotatePdf: 'Pdf labelen',
        viewPdf: 'Bekijk Pdf',
        saveProjectRelationshipRequest: 'Opslaan en verzenden',
        newProjectRelationshipRequest: 'Aanvragen',
        oneMoreProjectRelationshipRequest: 'Nieuwe aanvraag',
        newProjectRelationship: 'Toevoegen',
        saveProjectRelationshipPendingClaim: 'Later afwerken',
        saveProjectRelationshipApproved: 'Opslaan',
        saveProjectRelationshipPendingAnnotation: 'Later afwerken',
        claimProjectRelationships: 'Claimen',
        approve: 'Goedkeuren',
        allProjectLeaders: 'Alle projectleiders',
        forwardProjectRelationship: 'Doorsturen',
        forwardProjectRelationshipNeedsApprovalClaim: 'Doorsturen ter nazicht',
        forwardProjectRelationshipNeedsApprovalAnnotation: 'Doorsturen ter nazicht',
      },
      titles: {
        claimable: 'Deelbedrag invullen',
        annotatePdf: 'Aanduiden op Pdf',
        sendToMultiple: 'Versturen naar',
        requestProjectRelationshipModal: '+ Deelbedrag aanvragen',
        createProjectRelationshipModal: '+ Deelbedrag toevoegen',
        updateProjectRelationshipModal: 'Deelbedrag wijzigen',
        deleteProjectRelationshipModal: 'Deelbedrag verwijderen?',
        myClaimableProjectRelationships: 'Mijn deelbedragen',
        claimAndApproveProjectRelationshipsModal: 'Deelbedragen claimen en goedkeuren',
        otherClaimableProjectRelationships: 'Andere deelbedragen',
        forwardTo: 'Doorsturen naar',
        forwardedBy: 'Doorgestuurd door',
      },
      subtitles: {

      },
      info: {
        sendToMultiple: 'Je kan 1 of meerder ontvangers ingeven. Mochten er reeds eerder aangemaakte deelbedragen in verwerking zijn door een ontvanger, zullen die hieronder worden opgelijst.',
        annotatePdf: 'Indien het een uitgebreide factuur betreft of het lijkt eenvoudiger om direct op de originele factuur annotaties te maken, klik hieronder op "@:invoices.purchaseProjectRelationships.buttons.annotatePdf". Ook annotaties van collega\'s zullen zichtbaar zijn.',
        requestsWereSent: 'Aanvragen zijn verstuurd.',
        myClaimableProjectRelationships: 'Hieronder vind je een overzicht van aan jou toegewezen en/of zelf aangemaakte deelbedragen. Ook de deelbedragen die bij collega\'s werden aangevraagd zijn zichtbaar. Je bent vrij om deelbedragen van jouw collega\'s in hun naam te vervolledigen/claimen.',
        onlyNegativeValuesAllowed: 'Enkel negatieve waarden toegestaan (inkomende factuur vs creditnota)',
        onlyPositiveValuesAllowed: 'Enkel positieve waarden toegestaan (inkomende factuur vs creditnota)',
        forwardTo: 'Indien je denkt dat dit deelbedrag door iemand kan/moet worden gelabeld kan je het via deze weg doorsturen.',
      },
      errors: {
        projectRequired: 'Project is verplicht',
        contractorTypeRequired: 'Aannemingstak is verplcht',
        projectRelationshipShouldBeCompletedBeforeApproving: 'Eens het deelbedrag is vervolledigd, kan je het goedkeuren.',
      },
      components: {
        relatedList: {
          title: 'Deelbedragen',
        },
      },
      globalMessage: {
        saveSuccess: 'Deelbedrag werd opgeslagen.',
      },
      statuses: {
        requested: 'Aangevraagd',
        pending_claim: 'Wordt later afgewerkt',
        pending_annotation: 'Annotatie deels gedaan',
        needs_approval_annotation: 'Ter nazicht (annotatie)',
        approved: 'Volledig afgewerkt',
      },
    },
    payments: {
      details: {
        labels: {
          total: 'Betaald bedrag',
          executedAt: 'Betaald/uitgevoerd op',
          comment: 'Opmerkingen',
          type: 'Uitgaand/Inkomend',
          typeCredit: 'Uitgaand',
          typeDebit: 'Inkomend',
        },
        placeholders: {
          total: 'Betaald bedrag',
          executedAt: 'Betaald/uitgevoerd op',
          comment: 'Deelbetaling creditnota, ...',
          type: 'Uitgaand/Inkomend',
          typeCredit: 'Uitgaand',
          typeDebit: 'Inkomend',
        },
      },
      titles: {
        payments: 'Betalingen',
        addPaymentToInvoice: 'Betaling toevoegen',
      },
      buttons: {
        newPayment: 'Toevoegen',
        paidInFull: 'Volledig betaald',
      },
    },
  },
  accounting: {
    accountingLedger: {
      details: {
        labels: {
          name: 'Naam',
          description: 'Omschrijving',
          startDate: 'Startdatum',
          endDate: 'Einddatum',
          active: 'Actief?',
          startAndEndDate: 'Start- en einddatum',
        },
        placeholders: {
          name: 'Naam',
          description: 'Omschrijving',
          startDate: 'Startdatum',
          endDate: 'Einddatum',
          active: 'Actief?',
        },
      },
      modal: {
        editAccountingLedger: 'Wijzig boekhoudsysteem',
        addAccountingLedger: 'Boekhoudsysteem toevoegen',
        copyAccountingLedger: 'Boekhoudsysteem kopiëren',
      },
      globalMessage: {
        fetchActiveAccountingLedgerFailed: 'Er blijken geen (of meerdere) boekhoudsystemen of postnummers actief te zijn. Gelieve de instellingen voor het boekhoudsysteem na te kijken en er zeker van te zijn dat er maar 1 actief boekhoudsysteem is.',
      },
    },
    accountingLedgerItem: {
      details: {
        labels: {
          name: 'Post',
          description: 'Omschrijving',
          active: 'Actief?',
          isProjectRelated: 'Projectgerelateerd?',
          taxes: 'Taxen',
          defaultVat: 'Standaard BTW',
          type: 'Type',
        },
        placeholders: {
          name: 'Post',
          description: 'Omschrijving',
          active: 'Actief?',
          isProjectRelated: 'Projectgerelateerd?',
          taxes: 'Taxen',
          defaultVat: 'Standaard BTW',
          type: 'Type',
        },
      },
      taxes: {
        beVat21: 'Belgische BTW 21%',
        beVat6: 'Belgische BTW 6%',
        beVat0: 'Belgische BTW 0%',
      },
      modal: {
        editAccountingLedgerItem: 'Wijzig boekhoudpost',
        addAccountingLedgerItem: 'Boekhoudpost toevoegen',
      },
      components: {
        relatedList: {
          title: 'Boekhoudposten',
        },
      },
    },
    settings: {
      accountingLedger: {
        title: 'Instellingen boekhoudsysteem',
      },
    },
  },
  reports: {
    title: 'Rapportering',
    grossMargin: {
      title: 'Nacalculatie',
      buttons: {
        labels: {
          showProjectsGrossMarginReport: 'Projecten',
          showProjectsContractorBranchesOverviewReport: 'Aannemingstakken',
        },
      },
      sideMenu: {
        listTitleProjects: 'Projecten',
        listTitleSpecialFilters: 'Speciale filters',
        project: 'Project',
        projectStatuses: 'Status',
        searchByProjectStatus: 'Zoek projectstatus...',
        showMoreProjectStatuses: 'Toon meer',
        showLessProjectStatuses: 'Toon minder',
        showInactiveProjectLeaders: 'Toon inactief',
        showActiveProjectLeaders: 'Verberg inactief',
        showInactiveProjectEstimators: 'Toon inactief',
        showActiveProjectEstimators: 'Verberg inactief',
        projectLeaders: 'Projectleider',
        projectEstimators: 'Calculator',
        searchByProjectLeaders: 'Zoek projectleiders...',
        searchByProjectEstimators: 'Zoek calculators...',
        amountOrderedProject: 'Omzet project',
        amountOrderedContractorBranch: 'Omzet aannemingstak',
        minAmountOrdered: 'Minimumbedrag omzet...',
        maxAmountOrdered: 'Maximumbedrag omzet...',
        lastMonth: 'Afgelopen maand',
        lastThreeMonths: 'Afgelopen 3 maand',
        lastThreeMonthsToDate: 'Afgelopen 3 maand tot nu',
        yearToDate: 'Begin dit jaar tot nu',
        firstPlannerDate: 'Startdatum planning',
        firstPlannerDateFrom: 'Startdatum planning van',
        firstPlannerDateTo: 'Startdatum planning tot',
        lastPlannerDate: 'Einddatum planning',
        lastPlannerDateFrom: 'Einddatum planning van',
        lastPlannerDateTo: 'Einddatum planning tot',
        firstSalesInvoiceDate: 'Datum eerste uitgaande factuur',
        firstSalesInvoiceDateFrom: 'Eerste factuurdatum van',
        firstSalesInvoiceDateTo: 'Eerste factuurdatum tot',
        lastSalesInvoiceDate: 'Datum laatste uitgaande factuur',
        lastSalesInvoiceDateFrom: 'Laatste factuurdatum van',
        lastSalesInvoiceDateTo: 'Laatste factuurdatum tot',
        firstPurchaseInvoiceDate: 'Datum eerste kostenfactuur',
        firstPurchaseInvoiceDateFrom: 'Eerste uitgave factuurdatum van',
        firstPurchaseInvoiceDateTo: 'Eerste uitgave factuurdatum tot',
        lastPurchaseInvoiceDate: 'Datum laatste kostenfactuur',
        lastPurchaseInvoiceDateFrom: 'Laatste uitgave factuurdatum van',
        lastPurchaseInvoiceDateTo: 'Laatste uitgave factuurdatum tot',
        supplier: 'Leverancier',
        completedContractorBranches: 'Afgewerkte aannemingstakken',
        completedContractorBranchesInfo: 'Enkel de data van aannemingstakken waarvan het uitgevoerde bedrag gelijk is aan het bestelde bedrag, m.a.w die volledig uitgevoerd is, wordt weergegeven.',
        completedProjects: 'Afgewerkte projecten',
        completedProjectsInfo: 'Enkel de data van aannemingstakken waarvan het uitgevoerde bedrag gelijk is aan het bestelde bedrag, m.a.w die volledig uitgevoerd is, wordt weergegeven.',
        relevantContractorBranches: 'Relevante aannemingstakken',
        relevantContractorBranchesInfo: 'Een aantal aannemingstakken, waarvan de nacalculatie niet relevant is (een kost door een uitzonderlijk voorval of werfsituatie  bijvoorbeeld, waardoor die niet kan vergeleken worden met andere projecten), wordt hier uit gefilterd.',

      },
      dataTable: {
        headers: {
          uniqueId: 'Nr.',
          projectName: 'Projectnaam',
          latestSalesInvoiceDate: 'Laatste uitgaande fact.',
          latestPurchaseInvoiceDate: 'Laatste inkomende fact.',
          totalAmountOrdered: 'Tot. Besteld',
          totalAmountExecuted: 'Tot. Uitgevoerd',
          projectProgressPrecentage: '%',
          totalSalesInvoices: 'Tot. Gefactureerd',
          totalPurchaseInvoices: 'Tot. Ink. Facturen',
          totalCosts: 'Tot. Kosten',
          grossMargin: 'Marge',
          grossProfitOrLoss: 'Bruto winst/verlies',
          ownLabourCost: 'Eigen arbeidskost',
        },
        noProjects: 'Geen projecten',
      },
    },
  },
  confirmDialog: {
    removeContact: 'Contact loskopelen?',
    deleteContact: 'Contact permanent verwijderen',
    removeThirdParty: 'Bent u zeker dat u de geselecteerde derde partij wilt verwijderen?',
    removeContractorParty: 'Bent u zeker dat u de geselecteerde aannemer wilt verwijderen?',
    removeQuote: 'Weet u zeker dat u de geselecteerde offerte wilt verwijderen?',
    removeOrder: 'Weet u zeker dat u de geselecteerde bestelling wilt verwijderen?',
    cannotRemoveQuote: 'Een offerte kan niet verwijderd worden wanneer deze nog samenhangt aan een bestelling.',
    deleteComment: 'Weet u zeker dat u deze opmerking wilt verwijderen?',
  },
  monthlyOverview: {
    title: 'Maandoverzicht',
  },

  $vuetify: {
    dataIterator: {
      rowsPerPageText: 'Items per pagina:',
      rowsPerPageAll: 'Alles',
      pageText: '{0}-{1} van {2}',
      noResultsText: 'Geen bijbehorende resultaten gevonden',
      nextPage: 'Volgende pagina',
      prevPage: 'Vorige pagina',
      loadingText: '@:common.loading',
    },
    dataTable: {
      rowsPerPageText: 'Rijen per pagina:',
      itemsPerPageText: 'Rijen per pagina:',
      sortBy: 'Sorteren op:',
    },
    dataFooter: {
      itemsPerPageAll: 'Alle items',
      itemsPerPageText: 'Rijen per pagina:',
      pageText: '{0}-{1} van {2}',
    },
    noDataText: 'Geen Data',
    fileInput: {
      counterSize: '{0} / {1}',
      counter: '{0} bestanden geselecteerd',
    },
  },
  holidayTypes: {
    collectiveClosing: 'Collectief verlof',
    lieuDays: 'Inhaalrustdag',
    bankHoliday: 'Feestdag',
    replacementHoliday: 'Vervangingsdag voor feestdag',
    other: 'Andere',
    paid: 'Verlof',
    sickLeave: 'Ziekteverlof',
  },
  error: {
    dayHasPlannedData: 'Deze dag bevat reeds ingeplande data.',
    memberHasPlannedDaysInPeriod: 'Teamlid heeft reeds ingeplande data tijdens deze periode.',
    teamPeriodHasOverlap: 'De opgegeven periode voor het team overlapt met andere periodes.',
    teamPeriodMembersCannotBeAddedToAlreadyStartedTeamPeriod: 'Teamleden kunnen niet worden toegevoegd/gewijzigd eens een periode is gestart. Maak een nieuwe periode aan en voeg daar de nieuwe teamleden aan toe.',
    teamPeriodMembersCannotBeRemovedFromPassedPeriod: 'Teamleden kunnen niet worden verwijderd uit een reeds afgelopen periode.',
    teamMemberHasOverlappingPeriods: 'Teamlid overlapt met een andere periode (in dit team of in een ander team).',
    workDayHasLockedTimesheetEntries: 'Team (-lid) heeft reeds een vergrendelde maandbon of ingevulde uren. De wijziging in de planner kan slechts worden doorgevoerd indien de maandbon wordt ontgrendeld of de gepresteerde uren worden aangepast/verwijderd.',
    quoteCannotSwitchContractorType: 'Je hebt niet de mogelijkheid om te wisselen tussen onderaannemers en teams. Je kan dit bekomen door een nieuwe offerte te maken voor de juiste aannemer/teams en de planning over te nemen.',
  },
  dictionary: {
    monthlyOverview: 'Maandoverzicht',
    employee: 'Werknemer | Werknemers',
    overview: 'Overzicht',
    distance: 'Afstand',
    planner: 'Planner',
    user: 'Gebruiker | Gebruikers',
    username: 'Gebruikersnaam',
    firstName: 'Voornaam',
    lastName: 'Familienaam',
    userGroup: 'Gebruikersgroep | Gebruikersgroepen',
    role: 'Rol | Rollen',
    add: 'Toevoegen',
    delete: 'Verwijderen',
    edit: 'Aanpassen',
    addUser: 'Gebruiker toevoegen',
    editUser: 'Gebruiker editeren',
    deleteUser: 'Gebruiker verwijderen',
    password: 'Wachtwoord',
    confirmDeleteUser: 'Bent u zeker dat u de gebruiker {0} wilt verwijderen?',
    projectWorker: 'Projectmederwerker | Projectmedewerkers',
    statusQuote: 'Status offerte',
    statusOrder: 'Status bestelling',
    constructionSiteAddress: 'Werfadres',
    companyDetails: 'Bedrijfsgegevens',
    clientDetails: 'Klantgegevens',
    of: 'van',
    ordered: 'Besteld',
    quotes: 'Offertes',
    paidHoliday: 'Betaald verlof',
    sickLeave: 'Ziekteverlof',
    nationalHoliday: 'Nationale feestdag',
    collectiveHoliday: 'Collectief verlof',
    recoveryDay: 'Inhaalrustdag',
    other: 'Andere | Anderen',
    street: 'Straat',
    number: 'Nummer',
    box: 'Bus',
    postalCode: 'Postcode',
    city: 'Stad',
    phone: 'Telefoon',
    mobile: 'GSM',
    comment: 'Commentaar',
    function: 'Functie',
    bankAccountNumber: 'Bankrekeningnummer | Bankrekeningnummers',
    SSN: 'Rijksregisternummer',
    email: 'Email',
    address: 'Adres',
    name: 'Naam',
    period: 'Periode',
    day: 'Dag',
    contactPerson: 'Contactpersoon | Contactpersonen',
    client: 'Klant | Klanten',
    project: 'Project | Projecten',
    insufficientPermissions: 'Onvoldoende rechten',
    lieuDays: 'Inhaalrust',
    collectiveClosing: 'Collectieve sluiting',
    replacementHoliday: 'Vervanging voor feestdag',
    bankHoliday: 'Feestdag',
    discountPaymentTerm: 'Termijn betalingskorting',
    discountedPercentage: 'Kortingspercentage',
    passwordConfirmation: 'Wachtwoord bevestigen',
    passwordsDoNotMatch: 'Wachtwoorden komen niet overeen.',
    startTypingToSearch: 'Begin met typen om te zoeken',
    totalAmountOrdered: 'Totaal bedrag besteld',
    totalAmountExecuted: 'Totaal bedrag uitgevoerd',
    noPayment: 'Geen betaling | Geen betalingen',
    noAccountingItem: 'Geen boekhoudlijn | Geen boekhoudlijnen',
    noProjectRelationship: 'Geen deelbedrag | Geen deelbedragen',
    permission: 'Recht | Rechten',
    progressSnapshotItem: 'Vorderingsstaat detail | Vorderingsstaat details',
    duplicateSelection: 'Dubbele selectie | Dubbele selecties',
    contractorBranch: 'Aannemingstak | Aannemingstakken',
    availableRole: 'Beschikbare rol | Beschikbare rollen',
    invalid: 'Ongeldig',
    required: 'Verplicht',
    alias: 'Alias',
    active: 'Actief',
    archive: 'Archiveren',
    projectLead: 'Projectleider | Projectleiders',
    rating: 'Rating',
    type: 'Type',
    confirmDeleteHoliday: 'Bent u zeker dat u de verlofperiode "{0}" wilt verwijderen?',
    startDate: 'Startdatum',
    endDate: 'Einddatum',
    actions: 'Acties',
    paid: 'Betaald',
    generalComment: 'Algemene commentaar | Algemene commentaar',
    contact: 'Contactpersoon | Contactpersonen',
    contactInformation: 'Contactinformatie | Contactinformatie',
    billingInformation: 'Facturatiegegeven | Facturatiegegevens',
    companyInformation: 'Bedrijfsgegeven | Bedrijfsgegevens',
    vatNumber: 'BTW-nummer | BTW-nummers',
    website: 'Website | Websites',
    nationalInsuranceNumber: 'RSZ-nummer | RSZ-nummers',
    companyLegalForm: 'Rechtsvorm | Rechtsvormen',
    commercialName: 'Commerciële naam | Commerciële namen',
    primary: 'Primair | Primaire',
    secondary: 'Secundair | Secundaire',
    thisIsA: 'Dit is een',
    company: 'Bedrijf | Bedrijven',
    individual: 'Particulier | Particulieren',
    search: 'Zoek | Zoeken',
    mobilePhone: 'Mobiele telefoon | Mobiele telefoons',
    phoneComment: 'Telefoon (commentaar) | Telefoon (commentaar)',
    mobilePhoneComment: 'Mobiele telefoon (commentaar) | Mobiele telefoon (commentaar)',
    noComment: 'Geen commentaar',
    supplier: 'Leverancier | Leveranciers',
    editPayment: 'Betaling wijzigen',
    EditContractorBranch: 'Contractorbranch wijzigen',
    addPayment: 'Betaling toevoegen',
    addContractorBranch: 'Contractorbranch toevoegen',
    deletePayment: 'Betaling verwijderen',
    yesDelete: 'Ja, verwijderen',
    switchingUserRolesFailed: 'Wisselen van rol is niet gelukt.',
    allocated: 'toegekend',
    withdrawn: 'ingehouden',
    debit: 'debet',
    credit: 'credit',
    mostUsedContactInformation: 'Meest gebruikte contactgegevens',
    checkRetainmentObligation: 'Check Inhoudingsplicht',
    validUntilInclusive: 'Geldig tem',
    validUntilExclusive: 'Geldig tot',
    retainmentObligation: 'Inhoudingsplicht',
    addRetainment: 'Inhoudingsplicht toevoegen',
    editRetainment: 'Inhoudingsplicht wijzigen',
    deleteRetainment: 'Inhoudingsplicht verwijderen',
    retain: 'Inhouden',
    doNotRetain: 'Niet inhouden',
    federalGovernmentOfficeFinance: 'FOD Financiën',
    federalGovernmentOfficeSocialSecurity: 'Sociale zekerheid',
    createdAt: 'Aangemaakt op',
    updatedAt: 'Gewijzigd op',
    deletedAt: 'Verwijderd op',
    yes: 'ja',
    no: 'nee',
    confirm: 'Bevestig',
    employees: 'Werknemer | Werknemers',
    hourlyRate: 'Uurtarief',
    averageMaterialCost: 'Gemiddelde materiaalkosten',
    done: 'Klaar',
    save: 'Opslaan',
    saveAndNew: 'Opslaan en nieuw',
    cancel: 'Annuleren',
    overallCost: 'Totale kost',
    addWage: 'Salaris toevoegen',
    editWage: 'Salaris wijzigen',
    deleteWage: 'Salaris verwijderen',
    confirmDeleteWage: 'Ben je zeker dat je dit salaris/tarief wil verwijderen ({0})?',
    addEmployee: 'Werknemer toevoegen',
    editEmployee: 'Werknemer wijzigen',
    deleteEmployee: 'Werknemer verwijderen',
    confirmDeleteEmployee: 'Ben je zeker dat je deze werknemer wil verwijderen ({0})?',
    viewDetails: 'Details bekijken',
    hideDetails: 'Details verbergen',
    untilInclusive: 'Tem',
    untilExclusive: 'Tot',
    team: 'Team | Teams',
    forPeriod: 'Voor periode',
    startsOn: 'Start op',
    endsOn: 'Eindigt op',
    lock: 'Vergrendelen',
    unlock: 'Ontgrendelen',
    createAbsencePeriod: 'Nieuwe periode van afwezigheid',
    editAbsencePeriod: 'Wijzig periode van afwezigheid',
    description: 'Beschrijving',
    purchaseInvoice: 'Aankoopfactuur | Aankoopfacturen',
    salesInvoice: 'Verkoopfactuur | Verkoopfacturen',

    // questions
    areYouSureToDeletePayment: 'Ben je zeker dat je deze betaling wil verwijderen?',
    areYouSureToDeleteRetainment: 'Ben je zeker dat je deze inhoudingsplicht wil verwijderen?',

    // tooltips
    tooltipGenerateNewPurchaseInvoiceNumber: 'Genereer een nieuw volgnummer voor deze aankoopfactuur.',
    tooltipSaveApprovedProjectRelationship: 'Sla het deelbedrag finaal op. Hiervoor moet minstens het bedrag én een boekhoudpost worden ingevuld.',
    tooltipSaveApprovedProjectRelationshipAndCreateNew: 'Sla het deelbedrag finaal op en vul meteen een nieuw deelbedrag in. Om het deelbedrag finaal op te slaan moet minstens het bedrag én een boekhoudpost worden ingevuld.',
    tooltipSavePendingProjectRelationship: 'Het deelbedrag deels/tijdelijk opslaan. Dit is handig indien nog niet alle informatie gekend is.',
    tooltipAllocateProjectRelationshipRemainder: 'Gebruik het resterend - nog niet toegekend - factuurbedrag. Dit houdt geen rekening met (niet-)goedgekeurde deelbedragen.',
    tooltipFillOutProjectRelationship: 'Vul het deelbedrag in. Om een deelbedrag "finaal" op te slaan, moet je minstens een bedrag én een boekhoudpost opgeven. Indien dit niet mogelijk is kan je nog steeds "later afwerken" of doorsturen naar een collega om aan te vullen (of ter controle).',
    tooltipSetProjectRelationshipDebitCredit: 'De meeste deelbedragen zullen worden opgeslagen als debit. Het bedrag wordt dus aan een project toegekend. Wanneer een deelbedrag wordt ingehouden (wegens een te grote/verkeerde bestelling, etc.) moet het deelbedrag geregistreerd worden als credit.',
    tooltipSetProjectRelationshipAsProjectRequired: 'Sommige boekhoudposten vereisen het invullen van een project. Indien de boekhoudpost het toch niet zou vereisen, kan je nog steeds handmatig verplichten een project in te vullen door deze checkbox aan te klikken. Deze optie is enkel aan te passen bij het aanmaken van een deelbedrag.',
    tooltipIncorrectTotalAmountForPurchaseInvoiceProjectRelationshipsList: 'De som van de deelbedragen komt (nog) niet overeen met het totale factuurbedrag.',
    tooltipIncorrectTotalAmountCompletedForPurchaseInvoiceProjectRelationshipsList: 'De som van vervolledigde deelbedragen komt (nog) niet overeen met het totale factuurbedrag.',
    tooltipIncorrectTotalAmountApprovedForPurchaseInvoiceProjectRelationshipsList: 'De som van goedgekeurde deelbedragen komt (nog) niet overeen met het totale factuurbedrag.',
    tooltipManagePurchaseInvoiceProjectRelationships: 'Na het ingeven van de factuurbedragen, vervolledig dan eerst de nodige deelbedragen. Hierna kan je de gegenereerde boekhoudlijnen aanpassen indien nodig.',
    tooltipManagePurchaseInvoiceAccountingItems: 'Indien de boekhoudlijnen nog niet 100% juist zijn, kan je ze handmatig aanpassen. {br}{br}Let op: wanneer een boekhoudlijn wordt aangepast (of nieuw wordt aangemaakt) dan zullen - bij het aanpassen van een deelbedrag - de boekhoudlijnen niet meer automatisch worden berekend. Je kan dit gedrag controleren door de schakelaar hiernaast en dan op op te slaan.',
    tooltipIncorrectTotalAmountForPurchaseInvoiceAccountingItemsList: 'De som van de boekhoudlijnen komt (nog) niet overeen met het totale factuurbedrag.',
    tooltipIncorrectTotalVatAmountForPurchaseInvoiceAccountingItemsList: 'De som van de BTW-bedragen van de boekhoudlijnen komt (nog) niet overeen met het totale BTW-bedrag van de factuur.',
    tooltipIncorrectGrandTotalAmountForPurchaseInvoiceAccountingItemsList: 'De som van de bedragen (incl BTW) van de boekhoudlijnen komt (nog) niet overeen met het totale factuurbedrag (incl BTW).',
    tooltipManagePurchaseInvoicePayments: 'Betalingen voor deze factuur kan je hier ingeven. Belangrijk: zorg ervoor dat steeds de juiste "richting" van de betaling wordt opgegeven. {br}{br}Een betaling wordt áltijd opgegeven vanuit het standpunt van het bedrijf. Bij een inkomende factuur moet er een uitgaande betaling worden ingegeven. Dit geldt ook voor een uitgaande creditnota. Aan een creditnota van een leverancier of een factuur aan een klant wordt dan een inkomende betaling gekoppeld.',
    tooltipIncorrectTotalAmountForPurchaseInvoicePaymentsList: 'De som van de betalingen komt (nog) niet overeen met het totale factuurbedrag (incl BTW).',
    tooltipUnpaidAmountForSalesInvoicesOverview: 'Ongeacht of het een creditnota of gewone factuur betreft, het openstaand bedrag zal altijd als "positief" worden getoond. Behalve als er te veel werd betaald, dan is het negatief.',
    tooltipFilterProjectOnSalesInvoices: 'Wanneer gefilterd wordt op een project worden alle overige filters gereset. Maw, je zal alle facturen te zien krijgen voor het geselecteerde project (dus over alle jaren heen indien het een langdurig project is).',
    tooltipSocialSecurityRegistrationsShowCompliantProjects: 'Projecten waarvan de sociale zekerheid in orde is, kan je via deze knop verbergen. De non-compliant projecten worden altijd getoond.',
    tooltipSocialSecurityRegistrationsDaysUntilDue: 'Het aantal dagen tot overtijd (of in extreme gevallen het effectieve aantal dagen overtijd) is het verschil in dagen tussen vandaag en de startdatum van de aannemer op de werf.',
    tooltipChangeRole: 'Verander van rol om meer/minder toegang te krijgen tot data en andere functionaliteiten. Let op: de pagina zal na het wijzigen worden verversd. Zorg er daarom voor dat alle nog niet opgeslagen gegevens worden opgeslagen.',
    tooltipProgressSnapshotItemsTotalAmountOrderedDiff: 'Dit bedrag toont het nog te vervolledigen bedrag vooraleer is voldaan aan het totaal bestelde bedrag.',
    tooltipProgressSnapshotItemsTotalAmountExecutedDiff: 'Dit bedrag toont het nog te vervolledigen bedrag vooraleer is voldaan aan het totaal uitgevoerde bedrag.',
    tooltipClientBillingInformationName: 'Deze naam wordt gebruikt bij het opmaken van een factuur. Je kan ze daarom verschillend instellen tov van de officiële naam bovenaan.',
    tooltipSocialSecurityRegistrationsExpandAll: 'Open alle projecten met bijhorende meldingen. Mogelijks moet je even wachten na he klikken tot alles wordt weergegeven.',
    tooltipAddContactsToProjectButNotClients: 'Voeg een contactpersoon toe aan het project. Indien je contactpersonen aan een klant wil toekennen, ga dan naar de klantenpagina en klik daar op toevoegen.',
    tooltipSupplierHasRetainmentThatAreValidAndShoulBeRetained: 'Deze leverancier heeft 1 of meerdere inhoudingsplichten die niet ok zijn of er werd recent niet meer gecontroleerd op inhoudingplicht.',
    tooltipMoveProjectByAmountOfDaysAreStrictlyWorkingWeekdays: 'Opgelet, het getoonde aantal dagen is inclusief weekends en feestdagen. Maar, bij het verschuiven worden weekends en feestdagen echter overgeslaan. Het project zal dus verplaatst worden met {0} effectieve werkdagen.',
    tooltipProjectContractorBranches: 'Hier kunnen de niet relevante aannemingstakken voor specifieke projecten gedefineerd worden.',
  },
  validation: {
    required: 'Veld is verplicht.',
    unique: 'Waarde moet uniek zijn.',
    string: 'Waarde moet tekst zijn.',
    dateFormat: 'Datumformaat lijkt incorrect.',
    email: 'Waarde moet in de vorm van een email-adres zijn.',
    distinct: 'Waarden moeten verschillen.',
    min: {
      string: 'Waarde moet een minimum lengte hebben.',
    },
    gte: {
      numeric: 'Waarde moet groter zijn dan.',
    },
    lte: {
      numeric: 'Waarde moet kleiner zijn dan.',
    },
    purchaseInvoice: {
      externalName: {
        required: 'Factuurnummer van leverancier is verplicht.',
        // min: {
        // 	string: 'Tekst moet minstens {0} (max {1}) karakters lang zijn.'
        // },
      },
    },
    salesInvoice: {
      progressSnapshot: {
        requiredIf: 'Vorderingsstaat verplicht in te geven bij vorderingsfactuur.',
        items: {
          totalAmountOrdered: {
            gte: 'Moet groter/gelijk zijn aan totaal uitgevoerd.',
          },
          totalAmountExecuted: {
            lte: 'Moet kleiner/gelijk zijn aan totaal besteld.',
          },
        },
      },
      client: {
        requiredWithout: 'Gelieve een klant en/of leverancier te selecteren',
      },
      supplier: {
        requiredWithout: 'Gelieve een leverancier en/of klant te selecteren',
      },
    },
    user: {
      role: {
        inArray: 'De actieve rol moet ook worden geselecteerd in de "Beschikbare rollen"',
      },
    },
  },
};
