
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

	@Component
	export default class ClientsSmall extends Vue {
		@Prop() id!: number;
		@Prop() clients!: any;
		@Prop() title!: string;
		@Prop() onOpenEditDialog!: Function;
		@Prop() onOpenRemoveDialog!: Function;
		@Prop() onOpenAddDialog!: Function;
	}
