import {i18n} from '@/main'
import { mapKeys, pickBy, replace, startsWith } from 'lodash'

function shouldBeTranslated(item: string): boolean {
    return item.startsWith('validation.');
}

function translateItem(item: string): string {
    const split = item.split(':')
    const label = split[0]
    const params = split.length > 1 ? split.slice(1) : [];
    return i18n.t(label, params).toString()
}

export function translate(items: Array<string>|string): Array<string>|string {
    if(Array.isArray(items)) return items.map(i => { return shouldBeTranslated(i) ? translateItem(i) : i }) 
    else return shouldBeTranslated(items) ? translateItem(items) : items
}

export function filterValidationErrors(obj: object|null, keyStartsWith: string, omitSearchKey = true): object|null {
    const result = pickBy(obj, (value, key) => startsWith(key, keyStartsWith))
    if(!omitSearchKey) return result
    return mapKeys(result, (value, key: string) => replace(key, keyStartsWith, ''))
}