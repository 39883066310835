
import {defineComponent, PropType} from "vue";

type InvoiceAggregate = {
  totalDebit: string;
  totalCredit: string
  totalBalance: string;
  countDebit: number;
  countCredit: number;
  count: number;
  grandTotalDebit: string;
  grandTotalCredit: string;
  grandTotalBalance: string;
}

type PaymentAggregate = {
  totalDebit: string;
  totalCredit: string
  totalBalance: string;
}

type InvoiceSegments = {
  openNotOverdue: InvoiceAggregate;
  openOverdue: InvoiceAggregate;
  open: InvoiceAggregate;
  total: InvoiceAggregate;
  allWithoutStudyAgreement: InvoiceAggregate;
  allWithoutStudyAgreementAndAdvance: InvoiceAggregate;
  onlyStudyAgreement: InvoiceAggregate;
  onlyAdvance: InvoiceAggregate;
}

type PaymentSegments = {
  openNotOverdue: PaymentAggregate;
  openOverdue: PaymentAggregate;
  open: PaymentAggregate;
  total: PaymentAggregate;
}

type Segments = InvoiceSegments | PaymentSegments

interface InvoiceMetricsReport {
  invoiceAggregates: InvoiceSegments;
  paymentAggregates: PaymentSegments;
}

export default defineComponent({
  name: 'SalesInvoiceMetrics',
  emits: [],
  props: {
    invoicesMetricsReport: {
      type: Object as PropType<InvoiceMetricsReport>,
      default: null
    },
  },
  setup(props) {

    const getPaymentAggregate = (name: keyof PaymentSegments) => {
      return props.invoicesMetricsReport && props.invoicesMetricsReport.paymentAggregates && props.invoicesMetricsReport.paymentAggregates[name];
    }

    const getInvoiceAggregate = (name: keyof InvoiceSegments) => {
      return props.invoicesMetricsReport && props.invoicesMetricsReport.invoiceAggregates && props.invoicesMetricsReport.invoiceAggregates[name];
    }

    const getUnpaidAggregate = (name: keyof Segments) => {
      const invoiced = getInvoiceAggregate(name)
      const paid = getPaymentAggregate(name)
      const grandTotalInvoiced = invoiced && invoiced.grandTotalBalance || '0'
      const totalPaid = paid && paid.totalBalance || '0'
      return (Math.abs(Number.parseInt(grandTotalInvoiced)) - Math.abs(Number.parseInt(totalPaid))).toString()
    }

    return {
      getPaymentAggregate,
      getInvoiceAggregate,
      getUnpaidAggregate
    }
  },
})
