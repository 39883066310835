import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import { Client } from '@/modules/entities/types/entities';

const BASE_ENDPOINT = '/api/v1/clients';

export class ClientService {

	getBaseEndpoint(): string {
		return BASE_ENDPOINT
	}
	
	getAllClients(url = `${ BASE_ENDPOINT }`): Promise<AxiosResponse<Pagination<Client[]>>> {
		return axios.get<Pagination<Client[]>>(`${ url }`);
	}

	getClientById(clientId: number): AxiosPromise<JsonResource<Client>> {
		return axios.get<JsonResource<Client>>(`${ BASE_ENDPOINT }/${ clientId }`);
	}

	editClientById(client: Client): AxiosPromise<JsonResource<Client>> {
		return axios.put<JsonResource<Client>>(`${ BASE_ENDPOINT }/${ client.id }`, client);
	}

	createClient(client: Client): AxiosPromise<JsonResource<Client>> {
		return axios.post<JsonResource<Client>>(`${ BASE_ENDPOINT }`, client);
	}

	deleteClientById(clientId: number): AxiosPromise<void> {
		return axios.delete(`${ BASE_ENDPOINT }/${ clientId }`);
	}

	toggleAssociationContactToClient(clientId: number, contactId: number): AxiosPromise<JsonResource<Client>> {
		return axios.put(`${ BASE_ENDPOINT }/${ clientId }/contacts/${ contactId }`);
	}
}
