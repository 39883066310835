
import { BankTransactionEntity, Payment, PaymentEntity, TransactionType } from '@/modules/payments/types/entities'
import { PropType } from 'vue'
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { namespace } from 'vuex-class'
import { PurchaseInvoice, PurchaseInvoiceEntity, PurchaseInvoiceRecordType } from '../../types/entities'
import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes'
import { calculatePaymentDifference, signAmount as signAmountInvoice, suggestPayment } from '@/modules/purchase-invoices/helpers'
import { signAmount, summarizeBy } from '@/modules/payments/helpers'
import PaymentEditPopupComponent from '@/modules/payments/components/payment-edit-popup.component.vue'
import { cloneDeep } from 'lodash'

const purchaseInvoices = namespace('purchaseInvoices')

@Component({
  components: {
    'payment-create-popup': PaymentEditPopupComponent,
    'payment-edit-popup': PaymentEditPopupComponent,
  },
})
export default class PurchaseInvoicePaymentsListComponent extends Vue {
  // vuex actions
  @purchaseInvoices.Action(Actions.SAVE_PAYMENT) savePayment!: (payload: { invoiceId: string; payment: Payment }) => Promise<void>
  @purchaseInvoices.Action(Actions.DELETE_PAYMENT) deletePayment!: (payload: { invoiceId: string; paymentId: string }) => Promise<void>

  // vuex getters
  @purchaseInvoices.Getter(Getters.GET_IS_LOADING) loading!: boolean

  // props
  @PropSync('payments', { type: Array as PropType<Payment[]>, default: () => [], required: true }) paymentsSync!: Payment[]
  @Prop({ type: Object as PropType<PurchaseInvoice>, default: () => new PurchaseInvoiceEntity(), required: true })
  purchaseInvoice!: PurchaseInvoice

  // local/children state
  state: {
    showMenuAddPayment: boolean
    showMenuEditPayment: boolean
    showMenuDeletePayment: boolean
  } = {
    showMenuAddPayment: false,
    showMenuEditPayment: false,
    showMenuDeletePayment: false,
  }
  content: {
    paymentCreate: Payment
    paymentUpdate: Payment | null
    paymentDelete: Payment | null
  } = {
    paymentCreate: this.newPayment(),
    paymentUpdate: null,
    paymentDelete: null,
  }

  // handlers
  onClickAddPayment() {
    const paymentSuggestion = this.suggestPayment(this.purchaseInvoice)
    this.content = { ...this.content, paymentCreate: { ...this.newPayment(), type: paymentSuggestion.type } }
  }
  onClickEditPayment(payment: Payment) {
    this.state.showMenuEditPayment = true
    this.content.paymentUpdate = cloneDeep(payment)
  }
  onClickSavePaymentCreate(payment: Payment) {
    const invoiceId = !!this.purchaseInvoice.id && this.purchaseInvoice.id
    this.savePayment({ invoiceId: invoiceId.toString(), payment: payment }).then((value) => {
      this.state = { ...this.state, showMenuAddPayment: false }
      this.content = { ...this.content, paymentCreate: { ...this.newPayment() } }
    })
  }
  onClickSavePaymentUpdate(payment: Payment) {
    const invoiceId = !!this.purchaseInvoice.id && this.purchaseInvoice.id
    this.savePayment({ invoiceId: invoiceId.toString(), payment: payment }).then((value) => {
      this.state = { ...this.state, showMenuEditPayment: false }
      this.content = { ...this.content, paymentUpdate: null }
    })
  }
  onClickDeletePayment(payment: Payment) {
    this.state.showMenuDeletePayment = true
    this.content.paymentDelete = cloneDeep(payment)
  }
  confirmDeletePayment(payment: Payment) {
    const invoiceId = !!this.purchaseInvoice.id && this.purchaseInvoice.id
    const paymentId = !!payment.id && payment.id
    this.deletePayment({ invoiceId: invoiceId.toString(), paymentId: paymentId.toString() }).then(() => {
      this.state = { ...this.state, showMenuDeletePayment: false }
    })
  }

  // methods
  newPayment() {
    return new PaymentEntity({
      total: null,
      bankTransaction: new BankTransactionEntity({
        executedAt: Vue.prototype.$formatISO8601(),
      }),
    })
  }
  signAmount(amount: number, type: TransactionType) {
    return signAmount(amount, type)
  }
  signAmountInvoice(amount: number, recordType: PurchaseInvoiceRecordType) {
    return signAmountInvoice(amount, recordType)
  }
  calculatePaymentDifference(invoice: PurchaseInvoice) {
    if (invoice.paymentTotal && invoice.recordType && this.paymentsSync) {
      return calculatePaymentDifference(invoice, this.paymentsSync)
    }
    return 0
  }
  suggestPayment(invoice: PurchaseInvoice) {
    return suggestPayment(invoice, this.paymentsSync ? this.paymentsSync : [])
  }

  // getters
  get headers() {
    return [
      {
        text: this.$t('invoices.payments.details.labels.total').toString(),
        value: 'total',
        align: 'end',
        sortable: true,
        groupable: false,
        class: 'text-no-wrap',
      } as DataTableHeader,
      {
        text: this.$t('invoices.payments.details.labels.executedAt').toString(),
        value: 'executedAt',
        align: 'start',
        sortable: true,
        groupable: true,
        class: 'text-no-wrap',
      },
      {
        text: this.$t('dictionary.comment').toString(),
        value: 'comment',
        align: 'start',
        sortable: false,
        groupable: false,
        class: 'text-no-wrap',
      },
      {
        text: this.$t('common.createdAt').toString(),
        value: 'createdAt',
        align: 'start',
        sortable: true,
        groupable: true,
        class: 'text-no-wrap',
      },
      {
        text: this.$t('common.updatedAt').toString(),
        value: 'updatedAt',
        align: 'start',
        sortable: true,
        groupable: true,
        class: 'text-no-wrap',
      },
      { text: '', value: 'actions', sortable: false, groupable: false } as DataTableHeader,
    ]
  }
  get summarizers() {
    return [
      {
        value: 'total',
        summarize: (items: Payment[] = []) => Math.abs(summarizeBy('total', items)),
      },
    ]
  }
  get formatters() {
    return [
      {
        value: 'total',
        format: (value: number) => {
          return this.$options.filters !== undefined ? this.$options.filters.formatMoney(value) : null
        },
      },
    ]
  }

  // setters

  // watchers

  // lifecycle
}
