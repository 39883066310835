
	import { PropType } from 'vue';
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { GetAddressMixin } from '@/mixins/get-address-mixin.component';
	import ConfirmDialogComponent from '@/core/components/confirm-dialog/confirm-dialog.component.vue';
	import { GetContactName } from '@/mixins/get-contact-name.component';
	import { Contact } from '@/modules/contacts/types/entities';

	@Component({
		mixins: [GetAddressMixin, GetContactName],
		components: {
			'confirm-dialog': ConfirmDialogComponent
		}
	})
	export default class ContactsListComponent extends Vue {
		@Prop({ type: Array as PropType<Contact[]>, default: () => [] }) contacts!: Contact[];
		@Prop({ type: Boolean, default: false }) disabled!: boolean;
		@Prop({ type: Boolean, default: false }) hideActions!: boolean;

		state: {
			removeContact: boolean
		} = {
			removeContact: false
		};

		content: {
			removeContact: Contact
		} = {
			removeContact: {
				id: 0,
				firstName: '',
				lastName: '',
				street: '',
				postalCode: '',
				streetNumber: '',
				flatNumber: '',
				city: '',
				website: '',
				bankAccountNumber: '',
				socialSecurityNumber: '',
				vatNumber: '',
				contactFunction: '',
				comment: '',
				suppliers: [],
				teleinformations: []
			}
		};

		resetContent(): void {
			this.content = {
				removeContact: {
					id: 0,
					firstName: '',
					lastName: '',
					street: '',
					postalCode: '',
					streetNumber: '',
					flatNumber: '',
					city: '',
					website: '',
					bankAccountNumber: '',
					socialSecurityNumber: '',
					vatNumber: '',
					contactFunction: '',
					comment: '',
					suppliers: [],
					teleinformations: []
				}
			};
		}

		onAttachedContactRemove(contact: Contact) {
			this.resetContent();

			if (this.state.removeContact && this.content.removeContact.id === contact.id) {
				this.state.removeContact = false;
			} else {
				this.state.removeContact = true;
				this.content.removeContact = contact;
			}
		}

		// methods
		displayContactConfirmDialog(contact: Contact): boolean {
			return this.state.removeContact && this.content.removeContact.id === contact.id;
		}
		getMainTeleInformation(contact: Contact) {
			if(contact.teleinformations && contact.teleinformations.length) {
				const mainTeleInformationIdx = contact.teleinformations.findIndex(ti => ti.isMainContactInformation)
				if(mainTeleInformationIdx >= 0) return contact.teleinformations[mainTeleInformationIdx]
				return contact.teleinformations[0]
			}
			return null
		}
	}
