
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PurchaseInvoice, PurchaseInvoiceEntity } from '@/modules/purchase-invoices/types/entities';
import PurchaseInvoiceDetails from '@/modules/purchase-invoices/components/purchase-invoice-details/purchase-invoice-details.component.vue'
import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
import { AxiosError } from 'axios';
import Loading from '@/core/components/loading/loading.component.vue';
import {appMessageStore} from "@/core/store/app-messages.store";
import PurchaseInvoiceDuplicatesError
  from "@/modules/purchase-invoices/components/purchase-invoice-duplicates-error.component.vue";
import {PurchaseInvoicesCommandsService} from "@/modules/purchase-invoices/services/purchase-invoices-commands.service";

const purchaseInvoices = namespace('purchaseInvoices');

// services
const purchaseInvoiceCommandsService = new PurchaseInvoicesCommandsService()

@Component({
	components: {
		"purchase-invoice-details": PurchaseInvoiceDetails,
		"loading": Loading
	}
})
export default class PurchaseInvoiceModalComponent extends Vue {

	// actions
	@purchaseInvoices.Action(Actions.SAVE_INVOICE) savePurchaseInvoice!: (payload: PurchaseInvoice) => Promise<PurchaseInvoice>;
	@purchaseInvoices.Action(Actions.CREATE_INVOICE) createPurchaseInvoice!: (payload: PurchaseInvoice) => void;
	@purchaseInvoices.Action(Actions.FETCH_NEW) fetchNew!: () => Promise<void>;

	// getters
	@purchaseInvoices.Getter(Getters.GET_INVOICE) purchaseInvoice!: PurchaseInvoice;
	@purchaseInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;
	@purchaseInvoices.Getter(Getters.GET_NEW) new!: PurchaseInvoice;

	// props
	@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
	@Prop({default: 'modal'}) viewMode!: string; // 'default','modal'
	@Prop({default: () => { return new PurchaseInvoiceEntity() }}) defaultNewPurchaseInvoice!: PurchaseInvoice;

	// local vars
	state: {
		saving: boolean,
		cancelling: boolean,
		modifiedPurchaseInvoice: boolean,
		showConfirmDelete: boolean,
		showNew: boolean,
        hasFetchedDuplicatePurchaseInvoices: boolean,
	} = {
		saving: false,
		cancelling: false,
		modifiedPurchaseInvoice: false,
		showConfirmDelete: false,
		showNew: false,
        hasFetchedDuplicatePurchaseInvoices: false,
	}
	content: {
		purchaseInvoice: PurchaseInvoice|null,
		validationErrors: any,
        duplicatePurchaseInvoices: Array<PurchaseInvoice>,
	} = {
		purchaseInvoice: null,
		validationErrors: {},
        duplicatePurchaseInvoices: [],
	}

	// handlers
	onClickCancel(): void {
		this.closeModal();
	}
	async onClickSave(showNew: boolean) {
		this.state = {...this.state, showNew: showNew}

        if (! this.state.hasFetchedDuplicatePurchaseInvoices && this.content.purchaseInvoice) {
          await this.searchDuplicatePurchaseInvoices(this.content.purchaseInvoice)
          if (this.content.duplicatePurchaseInvoices.length === 0) {
            this.save(this.content.purchaseInvoice)
          }
          return;
        }

		this.save(this.content.purchaseInvoice)
	}

	// methods
	closeModal() {
		this.state = {...this.state, showNew: false }
		this.showModalSync = false
	}
	newPurchaseInvoice() {
		this.fetchNew().then(() => {
			this.createPurchaseInvoice({ ...this.defaultNewPurchaseInvoice, name: this.new.name })
		})
	}
	save(invoice: PurchaseInvoice|null) {
		if(invoice) {
            this.state = {...this.state, saving: false}
            this.content = {...this.content, validationErrors: {}}

			this.savePurchaseInvoice(invoice)
              .then((purchaseInvoice: PurchaseInvoice) => {
                this.state.hasFetchedDuplicatePurchaseInvoices = false
                this.content.duplicatePurchaseInvoices = []
				if(!this.state.showNew) { 
                  this.closeModal();
                  this.$router.push({path: `/invoices/purchase/${purchaseInvoice.id}`, query: { openModal: 'createProjectRelationship'}  });
				} else this.newPurchaseInvoice()
			  })
              .catch((err: AxiosError) => {
				if (err.response && err.response.status === 422) {
					this.content = { ...this.content, validationErrors: err.response.data.errors }
				} else {
					throw err;
				}
			  });
		}
	}
    searchDuplicatePurchaseInvoices(purchaseInvoice: PurchaseInvoice) {
      return purchaseInvoiceCommandsService.getDuplicatePurchaseInvoicesFor(purchaseInvoice)
        .then((resp) => {
          this.state.hasFetchedDuplicatePurchaseInvoices = true;
          this.state.saving = false;
          this.content.duplicatePurchaseInvoices = resp.data.data || []

          if(this.content.duplicatePurchaseInvoices.length > 0) {
            appMessageStore.actions.warn({
              message: 'Er zijn gelijkaardige facturen gevonden voor de huidige ingegeven factuur. Indien deze factuur toch correct is ingegeven, kan je nogmaals op "Opslaan" klikken om de factuur alsnog op te slaan.',
              ttl: 900000,
              component: {
                cmp: PurchaseInvoiceDuplicatesError,
                props: {
                  duplicatePurchaseInvoices: this.content.duplicatePurchaseInvoices,
                },
              },
            })
          }
        })
        .catch(() => {
          this.state.saving = false;
        })
    }

	//watchers
	@Watch('purchaseInvoice', { deep: true, immediate: false })
	onWatchPurchaseInvoice(purchaseInvoice: any) {
		this.content = {...this.content, purchaseInvoice: {...purchaseInvoice} }
	}

	// boot methods
	created(): void {
		//
	}

	destroyed(): void {
		//
	}
}
