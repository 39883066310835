import {MutationTree} from 'vuex';
import {ContactsState} from '@/modules/contacts/store/types/ContactsState';
import {Mutations} from '@/modules/contacts/store/types/StoreTypes';
import { Contact } from '../types/entities';

export const mutations: MutationTree<ContactsState> = {
	[Mutations.MUTATE_CONTACTS_FILTERED]: (state, payload) => state.contactsFiltered = payload,

	[Mutations.MUTATE_INSPECTED_CONTACT]: (state: ContactsState, payload: Contact) => {
		state.inspectedContact = payload;
	},
	[Mutations.MUTATE_LOADING]: (state: ContactsState, payload: boolean) => {
		state.isLoading = payload;
	}
};
