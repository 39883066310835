

	import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

	@Component
	export default class DetailsMultiSelectComponent extends Vue {
		@Prop() iconPath!: string;
		@Prop() translationPath!: string;
		@Prop() disabled!: boolean;
		@Prop() itemText!: string;
		@Prop() itemValue!: string;
		@PropSync("selectedValue", { type: Array }) syncedSelectedValue!: any;
		@Prop() listValues!: any;
		@Prop() classList!: any;
		@Prop() isFlat!: boolean;
		@Prop() isOutlined!: boolean;
		@Prop() placeholder!: string;
		@Prop() errorMessages!: any;
	}
