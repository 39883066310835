
  import { useDynamicProps } from '@/composables/useDynamicProps';
  import useTeam from '@/composables/useTeam';
  import { sortNatural } from '@/core/helpers/Sort';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import { computed, defineComponent, PropType, reactive, ref, watch, h, defineEmits } from 'vue';
  import dayjs from 'dayjs';
  import { cloneDeep } from 'lodash';
  import { helpers } from 'vuelidate/lib/validators';
  import { DataTableHeader } from 'vuetify';
  import { Employee, TeamMember2, TeamMember2Entity } from '../types/entities';
  import { Team } from '../types/entities';
  import { AppMessageType } from '@/core/components/app-message/entities/app-message-type.entity';
  import AppInputPercentageComponent from '@/core/components/app-input-percentage/app-input-percentage.component.vue';
  import TeamTeamMembersErrorComponent from './team-team-members-error.component.vue';

  interface TeamMemberForTable extends TeamMember2 {
    name?: string;
  }

  export default defineComponent({
    name: 'DivisionTeamMembersList',
    components: {},
    emits: ['add-team-member','after-saved-team-member','delete-team-member'],
    props: {
      team: {
        type: Object as PropType<Team>,
        default: () => null,
      },
      teamMembers: {
        type: Array as PropType<Array<TeamMember2>>,
        default: () => [],
      },
      employees: {
        type: Array as PropType<Array<Employee[]>>,
        default: () => [],
      },
    },
    setup(props, {emit}) {
      const { actions: teamActions, getters: teamGetters } = useTeam();
      const editTeamMembers = useDynamicProps<TeamMember2>();
      const deleteTeamMembers = useDynamicProps<TeamMember2>();
      let data = reactive<{
        addTeamMember: null | TeamMember2;
      }>({
        addTeamMember: null,
      });

      const onClickEditTeamMember = (teamMember: TeamMember2) => {
        const clonedTeamMember = cloneDeep(teamMember);
        if (clonedTeamMember.id) {
          editTeamMembers.actions.set(clonedTeamMember.id.toString(), clonedTeamMember);
        }
      };
      const onClickDeleteTeamMember = (teamMember: TeamMember2) => {
        const clonedTeamMember = cloneDeep(teamMember);
        if (clonedTeamMember.id) {
          deleteTeamMembers.actions.set(clonedTeamMember.id.toString(), clonedTeamMember);
        }
      };
      const onClickAddTeamMember = () => {
        emit('add-team-member');
      };
      const onClickConfirmEditTeamMember = async (teamMember: TeamMember2) => {
        await teamActions.setTeam(cloneDeep(props.team));
        await teamActions
          .saveTeamMember(teamMember)
          .then(() => {
            emit('after-saved-team-member', teamMember);
            finishEditing(teamMember);
          })
          .catch((err) => {
            appMessageStore.actions.warn({
              message: err.response.data.message,
              ttl: 60000,
              component: {
                cmp: TeamTeamMembersErrorComponent,
                props: {
                  errorResponse: teamGetters.teamBusinessErrors.value,
                },
              },
            });
          });
      };
      const onClickConfirmDeleteTeamMember = (teamMember: TeamMember2) => {
        emit('delete-team-member', teamMember);
        finishEditing(teamMember);
      };
      const onClickCancelEditTeamMember = (teamMember: TeamMember2) => {
        finishEditing(teamMember);
      };
      const onClickCancelDeleteTeamMember = (teamMember: TeamMember2) => {
        finishDeleting(teamMember);
      };
      const editing = computed(() => editTeamMembers.getters.all.value);
      const deleting = computed(() => deleteTeamMembers.getters.all.value);
      const addTeamMember = computed(() => data.addTeamMember);
      const teamMembersForTable = computed(() => {
        return buildTeamMemberForTable([
          ...(!!addTeamMember && !!addTeamMember.value ? [addTeamMember.value] : []),
          ...props.teamMembers,
        ]);
      });
      const finishEditing = (teamMember: TeamMember2) => {
        if (teamMember.id) {
          editTeamMembers.actions.remove(teamMember.id.toString());
        }
      };
      const finishDeleting = (teamMember: TeamMember2) => {
        if (teamMember.id) {
          deleteTeamMembers.actions.remove(teamMember.id.toString());
        }
      };

      const activeTeamMembers = computed(() => {
        const team = props.team;
        if (team.teamMembers) {
          const teamMembers = team.teamMembers as TeamMember2[];
          return teamMembers.filter((tm) => tm.isActive);
        }
        return [];
      });

      const buildFullNameForEmployee = (employee: Employee) => {
        return employee.firstName + ' ' + employee.lastName;
      };

      const buildTeamMemberForTable = (teamMembers: TeamMember2[]) => {
        return teamMembers.map((tm) => {
          return {
            ...tm,
            name: tm.employee ? buildFullNameForEmployee(tm.employee) : null,
          } as TeamMemberForTable;
        });
      };

      const headers = computed(() => {
        return [
          {
            text: 'start',
            value: 'start',
            groupable: true,
            align: 'center text-truncate td-text-truncate-1 td-toggle column-start--width',
            class: 'text-no-wrap',
          },
          {
            text: 'end',
            value: 'end',
            groupable: true,
            align: 'center text-truncate td-text-truncate-1 td-toggle column-end--width',
            class: 'text-no-wrap',
          },
          {
            text: 'name',
            value: 'name',
            groupable: true,
            align: 'start text-truncate td-text-truncate-1 td-toggle column-name--width',
            class: 'text-no-wrap',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
            groupable: false,
            align: 'end text-truncate td-text-truncate-1 td-toggle column-start--width',
          },
          {
            text: '',
            value: 'spacer-column',
          },
        ];
      });

      return {
        buildTeamMemberForTable,
        onClickAddTeamMember,
        onClickEditTeamMember,
        onClickDeleteTeamMember,
        onClickConfirmEditTeamMember,
        onClickConfirmDeleteTeamMember,
        onClickCancelEditTeamMember,
        onClickCancelDeleteTeamMember,
        buildFullNameForEmployee,
        teamMembersForTable,
        addTeamMember,
        editTeamMembers,
        deleteTeamMembers,
        editing,
        deleting,
        headers,
        activeTeamMembers,

        teamActions,
        teamGetters,
      };
    },
  });
