
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({})
export default class ProjectHalfPieDonutChart extends Vue {
    @Prop({ type: Number, default: 0 }) value!: number;
    @Prop({ type: Boolean, default: false }) inverted!: boolean;

    getSections(): Array<{ value: number, color: string }> {
        return [
            { value: this.value, color: 'rgb(0, 195, 125)' }
        ];
    }
}
