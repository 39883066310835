
    import { PropType } from 'vue';
    import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    import { PurchaseInvoice } from '@/modules/purchase-invoices/types/entities';
    import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
    import SupplierAutocompleteComponent from '@/modules/purchase-invoices/components/supplier-autocomplete/supplier-autocomplete.component.vue';
    import { Supplier } from '@/modules/entities/types/entities';
    import _ from 'lodash';
    import { calculatePaymentDueDateForSupplier, calculatePaymentDiscountDueDateForSupplier, calculatePaymentTotal, calculateGrandTotal, calculatePaymentDiscountByPercentage } from '@/modules/purchase-invoices/helpers'
    import { AxiosResponse } from 'axios';
    import { Pagination } from '@/core/types/Entities';

    const purchaseInvoices = namespace('purchaseInvoices');

    @Component({
        components: {
            'supplier-autocomplete': SupplierAutocompleteComponent
        }
    })
    export default class PurchaseInvoiceInformationComponent extends Vue {

        // vuex actions
        @purchaseInvoices.Action(Actions.SEARCH_SUPPLIERS) searchSuppliers!: (payload: string) => Promise<AxiosResponse<Pagination<Supplier[]>>>;
        @purchaseInvoices.Action(Actions.FETCH_NEW) fetchNew!: () => Promise<void>;

        // vuex getters
        @purchaseInvoices.Getter(Getters.GET_SEARCH_SUPPLIERS) suppliers!: Supplier[];
        @purchaseInvoices.Getter(Getters.GET_NEW) new!: PurchaseInvoice;

        // props
        @PropSync('purchaseInvoice', { type: Object as PropType<PurchaseInvoice>, required: true }) purchaseInvoiceSync!: PurchaseInvoice;
        // local usage like: `itemSync.prop = <value>`
        // parent usage like: `:item.sync="parentItemContent"`

        @Prop({type: Object, default: () => {}}) validationErrors!: any;

        // local/children state
        state: {
            searchingSuppliers: boolean,
        } = {
            searchingSuppliers: false,
        }
        content: {} = {}

        // handlers
        onSearchSupplier(searchTerm: string) {
			if(searchTerm) this.onSearchSupplierDebounced(searchTerm, this)
		}
		onSearchSupplierDebounced = _.debounce((searchTerm: string, vm: Vue) => {
            vm.$data.state = { ...vm.$data.state, searchingSuppliers: true }
            this.searchSuppliers(searchTerm).then(() => { vm.$data.state = {...vm.$data.state, searchingSuppliers: false} });
        }, 300)
        onClickRefreshInvoiceName() {
            this.fetchNewInvoiceName();
        }
        onChangeSupplier() {
            // set values
            this.purchaseInvoiceSync.dueDate = this.calculatePaymentDueDateForSupplier()
            this.purchaseInvoiceSync.paymentDiscountDueDate = this.calculatePaymentDiscountDueDateForSupplier(),
            this.purchaseInvoiceSync.paymentDiscount = this.calculatePaymentDiscountByPercentage(this.paymentDiscountPercentageSupplier)

            // post calculate other values (totals etc)
            this.purchaseInvoiceSync.paymentTotal = this.calculatePaymentTotal()
        }
        onClickInvoiceDate(invoiceDate: string) {
            // set new invoice date
            this.purchaseInvoiceSync.invoiceDate = Vue.prototype.$dayjs(invoiceDate).format('YYYY-MM-DD')
            
            // set values
            this.purchaseInvoiceSync.dueDate = this.calculatePaymentDueDateForSupplier(),
            this.purchaseInvoiceSync.paymentDiscountDueDate = this.calculatePaymentDiscountDueDateForSupplier()
        }

        // methods
        fetchNewInvoiceName() {
            this.fetchNew().then(() => { this.purchaseInvoiceSync.name = this.new.name });
        }
        calculatePaymentDueDateForSupplier(): string|undefined {
            let dueDate = undefined;
            if(this.purchaseInvoiceSync && this.purchaseInvoiceSync.supplier) dueDate = calculatePaymentDueDateForSupplier(this.purchaseInvoiceSync, this.purchaseInvoiceSync.supplier)
            return dueDate;
        }
        calculatePaymentDiscountDueDateForSupplier() {
            let dueDate = undefined;
            if(this.purchaseInvoiceSync && this.purchaseInvoiceSync.supplier) dueDate = calculatePaymentDiscountDueDateForSupplier(this.purchaseInvoiceSync, this.purchaseInvoiceSync.supplier)
            return dueDate;
        }
        calculatePaymentTotal(): number {
            let total = 0;
            if(this.purchaseInvoiceSync) total = calculatePaymentTotal(this.purchaseInvoiceSync)
            return total;
        }
        calculateGrandTotal(): number {
            let total = 0;
            if(this.purchaseInvoiceSync) total = calculateGrandTotal(this.purchaseInvoiceSync)
            return total;
        }
        calculatePaymentDiscountByPercentage(percentage: number) {
            let discount = 0;
            if(this.purchaseInvoiceSync) discount = calculatePaymentDiscountByPercentage(this.purchaseInvoiceSync, percentage)
            return discount;
        }

        // getters
        get showSelectedInvoiceDate(): string {
			if(this.purchaseInvoiceSync && this.purchaseInvoiceSync.invoiceDate) {
				return Vue.prototype.$formatDate(this.purchaseInvoiceSync.invoiceDate);
			}
			return '';
        }
        get showSelectedDueDate(): string {
			if(this.purchaseInvoiceSync && this.purchaseInvoiceSync.dueDate) {
				return Vue.prototype.$formatDate(this.purchaseInvoiceSync.dueDate);
			}
			return '';
        }
        get showSelectedPaymentDiscountDueDate(): string {
			if(this.purchaseInvoiceSync && this.purchaseInvoiceSync.paymentDiscountDueDate) {
				return Vue.prototype.$formatDate(this.purchaseInvoiceSync.paymentDiscountDueDate);
			}
			return '';
        }
        get paymentDiscountPercentageSupplier(): number {
            const supplier = (this.purchaseInvoiceSync && this.purchaseInvoiceSync.supplier) || null;
            if(supplier && supplier.preferredPaymentDiscountPercentage > 0) {
                // round to 2 decimals
                return Math.round(supplier.preferredPaymentDiscountPercentage*100)/100;
            }
            return 0;
        }

        // setters

        // watchers

        // lifecycle
    }
