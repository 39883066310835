
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

  import { namespace } from 'vuex-class';

  import TaskListComponent from './components/task-list/task-list-component.vue';
  import HeaderListComponent from './components/header-list/header-list-component.vue';
  import StatusGridComponent from './components/status-grid/status-grid.component.vue';
  import ProjectSelectionMenuComponent from './components/project-selection-menu/project-selection-menu.component.vue';
  import ModifyNoteComponent from '@/modules/workflow/views/workflow/components/modify-note/modify-note-component.vue';
  import ShowAllNotesComponent from '@/modules/workflow/views/workflow/components/show-all-notes/show-all-notes-component.vue';

  import {
    WorkflowFunctionGroup,
    ProjectIntersectionData,
    WorkflowTask,
    WorkflowTaskStatus,
  } from '@/modules/workflow/types/entities';
  import { Actions, Getters } from '@/modules/workflow/store/types/StoreTypes';
  import { Actions as ProjectActions, Getters as ProjectGetters } from '@/modules/projects/store/types/StoreTypes';
  import { Project, ProjectStatus, ProjectUser } from '@/modules/projects/types/entities';
  import { Comment } from '@/modules/entities/types/entities';
  import LoadingComponent from '@/core/components/loading/loading.component.vue';
  import { WorkflowFilter } from '@/modules/workflow/store/types/WorkflowState';
  import { statusColour } from '@/modules/settings/views/workflow/helpers/workflow-helpers';

  const workflow = namespace('workflow');
  const projects = namespace('projects');

  @Component({
    components: {
      ShowAllNotesComponent,
      loading: LoadingComponent,
      'task-list': TaskListComponent,
      'header-list': HeaderListComponent,
      'status-grid': StatusGridComponent,
      'project-selection-menu': ProjectSelectionMenuComponent,
      'modify-note': ModifyNoteComponent,
      'show-all-notes': ShowAllNotesComponent,
    },
  })
  export default class WorkflowComponent extends Vue {
    @workflow.Action(Actions.FETCH_WORKFLOW_DATA) fetchWorkflowData!: () => void;
    @workflow.Action(Actions.FETCH_NEXT_PROJECTS) fetchNextProjects!: () => void;
    @workflow.Action(Actions.SET_WORKFLOW_FILTER_PROJECT_NAME) setWorkflowFilterProjectName!: (filter: string) => void;
    @workflow.Action(Actions.SET_WORKFLOW_TASKS) setTasks!: (tasks: WorkflowTask[]) => void;
    @workflow.Action(Actions.SET_WORKFLOW_SIDEBAR_OPEN) setSidebarOpen!: (isOpen: boolean) => void;
    @workflow.Action(Actions.SET_WORKFLOW_PROJECT_WORKFLOW_TASK) setProjectWorkflowTask!: (data: {
      projectId: number;
      workflowTaskId: number;
      taskStatusId?: number;
      futureStartDate?: Date;
    }) => void;
    @workflow.Action(Actions.SET_WORKFLOW_SHOW_FUNCTION_GROUPS) setShowFunctionGroups!: (val: boolean) => void;
    @workflow.Action(Actions.ADD_WORKFLOW_SELECTED_PROJECT) addSelectedProject!: (project: Project) => void;
    @workflow.Action(Actions.REMOVE_WORKFLOW_SELECTED_PROJECT) removeSelectedProject!: (project: Project) => void;
    @workflow.Action(Actions.ADD_WORKFLOW_EXPANDED_PROJECT) addExpandedProject!: (payload: number[]) => void;
    @workflow.Action(Actions.REMOVE_WORKFLOW_EXPANDED_PROJECT) removeExpandedProject!: (id: number) => void;
    @workflow.Action(Actions.ADD_WORKFLOW_EXPANDED_TASK) addExpandedTask!: (id: number) => void;
    @workflow.Action(Actions.REMOVE_WORKFLOW_EXPANDED_TASK) removeExpandedTask!: (id: number) => void;
    @workflow.Action(Actions.SAVE_TASK_NOTE) saveTaskNote!: (payload: {
      comment: Comment;
      projectId?: number;
      taskId?: number;
    }) => void;
    @workflow.Action(Actions.DELETE_TASK_NOTE) deleteTaskNote!: (payload: {
      projectId: number;
      taskId: number;
      noteId: number;
    }) => void;
    @workflow.Action(Actions.TOGGLE_ALL_TASKS_PANELS) toggleAllTasksPanels!: () => void;
    @workflow.Action(Actions.SET_WORKFLOW_FILTER_SHOW_MY_PROJECTS) setWorkflowFilterShowMyProjects!: (
      payload: boolean
    ) => void;
    @workflow.Action(Actions.SET_WORKFLOW_FILTER_PROJECT_STATUS) toggleProjectStatusSelection!: (
      payload: number
    ) => void;
    @workflow.Action(Actions.SET_WORKFLOW_FILTER_USERS) setWorkflowFilterUsers!: (payload: {
      userId: number;
      userGroup: string;
    }) => void;
    @workflow.Action(Actions.SELECT_MULTIPLE_TASK_STATUS_ID) taskStatusMultipleSelectId!: (
      taskStatusId: number
    ) => void;
    @workflow.Action(Actions.SELECT_MULTIPLE_TASK_STATUS_DATE) taskStatusMultipleSelectDate!: (
      futureStartDate: Date
    ) => void;
    @projects.Action(ProjectActions.FETCH_ALL_USERS) fetchAllUsers!: () => void;
    @projects.Action(ProjectActions.FETCH_PROJECT_STATUSES) fetchProjectStatuses!: () => Promise<void>;

    @workflow.Getter(Getters.GET_WORKFLOW_TASKS) tasks!: WorkflowTask[];
    @workflow.Getter(Getters.GET_WORKFLOW_FUNCTION_GROUPS) functionGroups!: WorkflowFunctionGroup[];
    @workflow.Getter(Getters.GET_WORKFLOW_SELECTED_PROJECTS) selectedProjects!: Project[];
    @workflow.Getter(Getters.GET_WORKFLOW_ALL_PROJECTS) allProjects!: Project[];
    @workflow.Getter(Getters.GET_WORKFLOW_EXPANDED_PROJECTS) expandedProjects!: number[];
    @workflow.Getter(Getters.GET_WORKFLOW_EXPANDED_TASKS) expandedTasks!: number[];
    @workflow.Getter(Getters.GET_WORKFLOW_LOADING) isLoading!: boolean;
    @workflow.Getter(Getters.GET_WORKFLOW_TASK_STATUSES) taskStatuses!: WorkflowTaskStatus[];
    @workflow.Getter(Getters.GET_WORKFLOW_SHOW_FUNCTION_GROUPS) showFunctionGroups!: boolean;
    @workflow.Getter(Getters.GET_WORKFLOW_USER_ID) userId!: number;
    @workflow.Getter(Getters.GET_WORKFLOW_ANY_TASK_EXPANDED) anyTaskExpanded!: boolean;
    @workflow.Getter(Getters.GET_WORKFLOW_FILTERS) workflowFilter!: WorkflowFilter;
    @workflow.Getter(Getters.GET_WORKFLOW_MULTIPLE_TASK_STATUS) multiTaskStatus!: {
      id: number;
      futureStartDate?: Date;
    };
    @workflow.Getter(Getters.GET_WORKFLOW_SIDEBAR_OPEN) sidebarOpen!: boolean;
    @workflow.Getter(Getters.GET_WORKFLOW_HIGHLIGHTED_PROJECT_ID) highlightedProjectId!: number;
    @projects.Getter(ProjectGetters.GET_USERS) users!: ProjectUser[];
    @projects.Getter(ProjectGetters.GET_PROJECT_STATUSES) projectStatuses!: ProjectStatus[];

    workflowState = {
      select_dialog_open: false,
      expand_all_task_panels: 1,
      modify_note: false,
      show_all_notes: false,
    };

    workflowContent: {
      note: any;
      show_all_notes: any;
    } = {
      note: undefined,
      show_all_notes: undefined,
    };

    resetWorkflowContent() {
      this.workflowContent = {
        note: {},
        show_all_notes: undefined,
      };
    }

    taskListOptions: {} = {};
    statusGridOptions: {} = {};

    addNote(data: { project: Project; task: WorkflowTask; intersectionData?: ProjectIntersectionData }) {
      this.workflowContent.note = undefined;
      this.workflowContent.note = JSON.parse(JSON.stringify(data));
      this.workflowState.modify_note = true;
    }

    editNote(data: {
      project: Project;
      task: WorkflowTask;
      intersectionData: ProjectIntersectionData;
      comment: Comment;
    }) {
      this.workflowContent.note = undefined;
      this.workflowContent.note = JSON.parse(JSON.stringify(data));
      this.workflowState.modify_note = true;
    }

    showAllNotes(data: { project: Project; task: WorkflowTask; intersectionData: ProjectIntersectionData }) {
      this.resetWorkflowContent();
      this.workflowContent.show_all_notes = data;
      this.workflowState.show_all_notes = true;
    }

    findIntersectionData(project: Project, task: WorkflowTask): ProjectIntersectionData | undefined {
      if (project.intersectionData) {
        return project.intersectionData.find((intersectionData: ProjectIntersectionData) => {
          return intersectionData.workflowTaskId === task.id;
        });
      } else {
        return undefined;
      }
    }

    changeTask(data: { taskId: number; project: Project }) {
      //TODO: Can this be done better?
      let newTask: any;
      let newIntersectionData: any;
      this.tasks.forEach((task) => {
        if (task.children) {
          task.children.forEach((childrenTask) => {
            childrenTask.id === data.taskId ? (newTask = childrenTask) : undefined;
          });
        }
        if (task.id === data.taskId) newTask = task;
      });
      if (newTask) {
        newIntersectionData = data.project.intersectionData!.find((x) => x.workflowTaskId === newTask.id);
      }
      this.workflowContent.show_all_notes.task = newTask;
      this.workflowContent.show_all_notes.intersectionData = newIntersectionData ? newIntersectionData : undefined;
    }

    saveNote(data: { body: string; commentId?: number; intersectionId?: number; projectId?: number; taskId?: number }) {
      const comment: Comment = {
        id: data.commentId ? data.commentId : 0,
        body: data.body,
        commentableId: data.intersectionId ? data.intersectionId : 0,
        commentableType: 'App\\Models\\Common\\ProjectWorkflowTask',
      };
      const payload = {
        comment,
        projectId: data.projectId,
        taskId: data.taskId,
      };
      this.saveTaskNote(payload);
      this.workflowState.modify_note = false;
    }

    updateTasks(data: WorkflowTask[]) {
      this.setTasks(data);
    }

    get allProjectsFilterSelected() {
      return this.allProjects;
    }

    getStatusChangerColor(item: WorkflowTaskStatus) {
      return statusColour(item.color || '');
    }

    getCurrentStatus(taskStatusId: number, intersectionData?: ProjectIntersectionData) {
      if (intersectionData) {
        if (intersectionData.taskStatus && intersectionData.taskStatus.id === taskStatusId) {
          return 'current-task';
        }
      }
      return;
    }

    projectsFiltered(projectStatusId: number) {
      if (projectStatusId !== 0)
        return this.selectedProjects.filter((x) => x.projectStatus && x.projectStatus.id === projectStatusId);
      return this.selectedProjects.filter((x) => !x.projectStatus);
    }

    isProjectStatusSelected(projectStatusId: number) {
      return this.workflowFilter.projectStatusesIds.some((id) => id === projectStatusId);
    }

    isTaskExpanded(taskId: number) {
      return this.expandedTasks.some((val) => val === taskId) ? 0 : 1;
    }

    get isAnyProjectDisplayed() {
      let isDisplayed = false;
      for (let index = 0; index < this.workflowFilter.projectStatusesIds.length; index++) {
        if (
          this.projectsFiltered(this.workflowFilter.projectStatusesIds[index]).length &&
          this.isProjectStatusSelected(this.workflowFilter.projectStatusesIds[index])
        )
          isDisplayed = true;
        if (isDisplayed) break;
      }
      return isDisplayed;
    }

    /* method to render project headers in expanded state */
    isProjectExpanded(projectId: number) {
      return this.expandedProjects.some((val) => val === projectId);
    }

    toggleProjectHeader(projectId: number) {
      let projectExpanded = this.expandedProjects.some((val: number) => {
        return val === projectId;
      });
      projectExpanded
        ? this.removeExpandedProject(projectId)
        : this.addExpandedProject([...this.expandedProjects, projectId]);
    }

    toggleParentTask(taskId: number) {
      let taskExpanded = this.expandedTasks.some((val: number) => {
        return val === taskId;
      });
      taskExpanded ? this.removeExpandedTask(taskId) : this.addExpandedTask(taskId);
    }

    /*
     * Watch for selectedProjects update in case of show-all-notes
     * or modify-note component being shown */
    @Watch('selectedProjects')
    onSelectedProjectsChange(val: Project[]) {
      if (this.workflowContent.show_all_notes !== undefined) {
        let project = val.find((x) => x.id === this.workflowContent.show_all_notes.project.id);
        if (project && project.intersectionData) {
          this.workflowContent.show_all_notes.intersectionData = project.intersectionData.find(
            (x) => x.intersectionId === this.workflowContent.show_all_notes.intersectionData.intersectionId
          );
        }
      }
      if (this.workflowContent.note !== undefined) {
        let project = val.find((x) => x.id === this.workflowContent.note.project.id);
        if (project && project.intersectionData) {
          this.workflowContent.note.intersectionData = project.intersectionData.find((x) => {
            if (!this.workflowContent.note.intersectionData) return x !== undefined;
            return x.intersectionId === this.workflowContent.note.intersectionData.intersectionId;
          });
        }
      }
    }

    statusGridScrollListener(e: any) {
      (this.$refs['task-list'] as Vue).$el.scrollTop = e.target.scrollTop;
      (this.$refs['header-list'] as HTMLElement).scrollLeft = e.target.scrollLeft;
    }

    taskListWheelListener(e: any) {
      if (!e) {
        e = window.event;
      } /* IE7, IE8, Chrome, Safari */
      if (e.preventDefault) {
        e.preventDefault();
      } /* Chrome, Safari, Firefox */
      e.returnValue = false; /* IE7, IE8 */
    }

    headerListWheelListener(e: any) {
      if (!e) {
        e = window.event;
      } /* IE7, IE8, Chrome, Safari */
      if (e.preventDefault) {
        e.preventDefault();
      } /* Chrome, Safari, Firefox */
      e.returnValue = false; /* IE7, IE8 */
    }

    created() {
      this.fetchProjectStatuses().then(() => {
        this.fetchWorkflowData();
      });
      this.fetchAllUsers();
    }

    mounted() {
      // Scroll task list and header list when the main grid gets scrolled
      (this.$refs['status-grid'] as HTMLElement).addEventListener('scroll', this.statusGridScrollListener);

      // Disable scrolling on task list and header list
      (this.$refs['task-list'] as Vue).$el.addEventListener('wheel', this.taskListWheelListener);

      (this.$refs['header-list'] as HTMLElement).addEventListener('wheel', this.headerListWheelListener);
    }

    beforeDestroy() {
      (this.$refs['status-grid'] as HTMLElement).removeEventListener('scroll', this.statusGridScrollListener);
      (this.$refs['task-list'] as Vue).$el.removeEventListener('wheel', this.taskListWheelListener);
      (this.$refs['header-list'] as HTMLElement).removeEventListener('wheel', this.headerListWheelListener);
    }
  }
