import {FilterInterface, FilterState} from '@/core/types/Filter';

export default function applyFilter(filterState: FilterState): string {

    const parameters = new URLSearchParams();

    // apply filter if active
    Object.keys(filterState.filters).forEach(function(value: string) {
        const filter = filterState.filters[value] as FilterInterface;
        if(filter.isBackend() && filter.active && filter.hasValues()) {
            parameters.set(filter.buildUrlParameterKey(), filter.buildUrlParameterValue());
        }
    });

    return parameters.toString();
}