import Vue from 'vue';
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RootState} from '@/core/types/RootState';
import {DivisionsState} from '@/modules/settings/store/modules/divisions/types/DivisionsState';
import {Actions, Getters, Mutations} from '@/modules/settings/store/modules/divisions/types/StoreTypes';
import {Division, DivisionDetail, Team} from '@/modules/settings/types/entities';

const getters: GetterTree<DivisionsState, RootState> = {
  [Getters.GET_DIVISIONS]: (state: DivisionsState) => {
    return state.allDivisions;
  },
  [Getters.GET_DIVISION_DETAIL]: (state: DivisionsState) => {
    return state.divisionDetail;
  },
};

const actions: ActionTree<DivisionsState, RootState> = {
  [Actions.FETCH_DIVISIONS]: async ({commit}: ActionContext<DivisionsState, RootState>) => {
    const res = await Vue.prototype.$http.get(`/api/v1/settings/divisions`);

    if (res.status === 200) {
      commit(Mutations.MUTATE_DIVISIONS, res.data.data);
    }
  },
  [Actions.FETCH_DIVISION_BY_ID]: async ({commit}: ActionContext<DivisionsState, RootState>, payload: string) => {
    const res = await Vue.prototype.$http.get(`/api/v1/settings/divisions/${payload}`);

    if (res.status === 200) {
      commit(Mutations.MUTATE_DIVISION_DETAIL, res.data.data);
    }
  },

  [Actions.ADD_NEW_DIVISION]: async ({commit, state}: ActionContext<DivisionsState, RootState>, payload: Division) => {
    const res = await Vue.prototype.$http.post(`/api/v1/settings/divisions`, payload);
    if (res.status === 200) {
      commit(Mutations.MUTATE_DIVISIONS, [
        ...state.allDivisions,
        res.data.data,
      ]);
    }
  },
  [Actions.ADD_NEW_DIVISION_TEAM]: async ({commit, state}: ActionContext<DivisionsState, RootState>, payload: {divisionId: number; team: Team}) => {
    const res = await Vue.prototype.$http.post(`/api/v1/settings/divisions/${payload.divisionId}/teams`, payload.team);
    if (res.status === 201) {
      let teams: Team[] = [];

      if (state.divisionDetail) {
        if (state.divisionDetail.teams) {
          teams = [
            ...state.divisionDetail.teams,
          ];
        }
        teams = [
          ...teams,
          {
            ...res.data.data,
          },
        ];

        const divisionDetail = {
          ...state.divisionDetail,
          teams,
        };

        commit(Mutations.MUTATE_DIVISION_DETAIL, divisionDetail);
      }
    }
  },

  [Actions.EDIT_DIVISION_BY_ID]: async({commit, state}: ActionContext<DivisionsState, RootState>, payload: Division) => {
    const res = await Vue.prototype.$http.put(`/api/v1/settings/divisions/${payload.id}`, payload);
    if (res.status === 200) {
      const divisions = state.allDivisions.map(x => {
        if (x.id === payload.id) {
          return {
            ...x,
            name: payload.name,
          };
        } else {
          return x;
        }
      });
      commit(Mutations.MUTATE_DIVISIONS, divisions);
    }
  },
  [Actions.EDIT_DIVISION_TEAM]: async({commit, state, dispatch}: ActionContext<DivisionsState, RootState>, payload: {divisionId: number; team: Team}) => {

    try {
      const res = await Vue.prototype.$http.put(`/api/v1/settings/divisions/${payload.divisionId}/teams/${payload.team.id}`, payload.team);
      if (state.divisionDetail && state.divisionDetail.teams) {

        const teams = state.divisionDetail.teams.map((x: Team) => {
          if (x.id === payload.team.id) {
            return {
              id: payload.team.id,
              name: payload.team.name,
              abbreviation: payload.team.abbreviation,
              contactId: payload.team.contactId,
            };
          } else {
            return x;
          }
        });

        commit(Mutations.MUTATE_DIVISION_DETAIL, {
          ...state.divisionDetail,
          teams,
        });
      }
    } catch (err: any) {
      // throw err
    }
  },

  [Actions.DELETE_DIVISION_BY_ID]: async ({commit, state}: ActionContext<DivisionsState, RootState>, divisionId: number ) => {
    const res = await Vue.prototype.$http.delete(`/api/v1/settings/divisions/${divisionId}`);
    if(res.status === 200) {
      const divisions = state.allDivisions.filter(x =>  x.id !== divisionId);
      commit(Mutations.MUTATE_DIVISIONS, divisions);
    }
  },
};

const mutations: MutationTree<DivisionsState> = {
  [Mutations.MUTATE_DIVISIONS]: (state: DivisionsState, payload: Division[]) => {
    state.allDivisions = payload;
  },
  [Mutations.MUTATE_DIVISION_DETAIL]: (state: DivisionsState, payload: DivisionDetail) => {
    state.divisionDetail = payload;
  },
};

const state: DivisionsState = {
  allDivisions: [],
  divisionDetail: {
    id: undefined,
    name: '',
    teams: [],
  },
};

export const store: Module<DivisionsState, RootState> = {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
