export const enum Actions {
	FETCH_LISTVIEW_INVOICES = 'FETCH_LISTVIEW_INVOICES',
	FETCH_LISTVIEW_NEXT_PAGE = 'FETCH_LISTVIEW_NEXT_PAGE',
	FETCH_STATUSES = 'FETCH_STATUSES',
	FETCH_PAYMENT_STATUSES = 'FETCH_PAYMENT_STATUSES',
	FETCH_TYPES = 'FETCH_TYPES',
	FETCH_RECORD_TYPES = 'FETCH_RECORD_TYPES',
	FETCH_CONTACTS = 'FETCH_CONTACTS',
	FETCH_PROJECTS = 'FETCH_PROJECTS',
	FETCH_SUPPLIERS = 'FETCH_SUPPLIERS',
	FETCH_CLIENTS = 'FETCH_CLIENTS',
	FETCH_RELATED_BILLING_ADDRESSES = 'FETCH_RELATED_BILLING_ADDRESSES',
	FETCH_NEW = 'FETCH_NEW',
	FETCH_PROJECT_INVOICES = 'FETCH_PROJECT_INVOICES',
	APPEND_TO_INVOICES = 'APPEND_TO_INVOICES',
	DELETE_INVOICE_BY_ID = 'DELETE_INVOICE_BY_ID',
	UPDATE_INVOICE_IN_LISTVIEW_INVOICES = 'UPDATE_INVOICE_IN_LISTVIEW_INVOICES',
	SAVE_PAYMENT = 'SAVE_PAYMENT',
	SAVE_PAYMENT_ON_LISTVIEW_INVOICES = 'SAVE_PAYMENT_ON_LISTVIEW_INVOICES',
	DELETE_PAYMENT = 'DELETE_PAYMENT',
	SAVE_PROGRESS_SNAPSHOT_ITEM = 'SAVE_PROGRESS_SNAPSHOT_ITEM',

	CREATE_INVOICE = 'CREATE_INVOICE',

	// filters
	TOGGLE_FILTER_SIDEBAR = 'TOGGLE_FILTER_SIDEBAR',
	TOGGLE_FILTER_GROUP = 'TOGGLE_FILTER_GROUP',
	CLEAR_FILTERS = 'CLEAR_FILTERS',
	SET_FILTER_ON_STATUS = 'SET_FILTER_ON_STATUS',
	SET_FILTER_ON_PAYMENT_STATUS = 'SET_FILTER_ON_PAYMENT_STATUS',
	SET_FILTER_ON_TYPE = 'SET_FILTER_ON_TYPE',
	SET_FILTER_ON_INVOICE_DATE_FROM = 'SET_FILTER_ON_INVOICE_DATE_FROM',
	SET_FILTER_ON_INVOICE_DATE_TO = 'SET_FILTER_ON_INVOICE_DATE_TO',
	SET_FILTER_ON_DUE_DATE_FROM = 'SET_FILTER_ON_DUE_DATE_FROM',
	SET_FILTER_ON_DUE_DATE_TO = 'SET_FILTER_ON_DUE_DATE_TO',
	SET_FILTER_ON_PROJECT_LEAD = 'SET_FILTER_ON_PROJECT_LEAD',
	SET_FILTER_ON_PROJECT_NAME = 'SET_FILTER_ON_PROJECT_NAME',
	SET_FILTER_ON_PROJECT = 'SET_FILTER_ON_PROJECT',
	SET_FILTER_FOR_SEARCH = 'SET_FILTER_FOR_SEARCH',
	SET_FILTER_ON_VAT = 'SET_FILTER_ON_VAT',
	SET_FILTER_ON_CONTACT_SEARCH = 'SET_FILTER_ON_CONTACT_SEARCH',
	SET_FILTER_ON_CONTACT_SEARCH_PROJECTS = 'SET_FILTER_ON_CONTACT_SEARCH_PROJECT',
	SET_FILTER_ON_CONTACT_SEARCH_SUPPLIERS = 'SET_FILTER_ON_CONTACT_SEARCH_SUPPLIER',
	SET_FILTER_ON_CONTACT_SEARCH_CLIENTS = 'SET_FILTER_ON_CONTACT_SEARCH_CLIENT',
	CLEAR_FILTERS_ON_CONTACT_SEARCH = 'CLEAR_FILTER_ON_CONTACT_SEARCH',
	SET_FILTER_ON_PROJECT_SEARCH = 'SET_FILTER_ON_PROJECT_SEARCH',
	SET_FILTER_ON_SUPPLIER_SEARCH = 'SET_FILTER_ON_SUPPLIER_SEARCH',
	SET_FILTER_ON_CLIENT_SEARCH = 'SET_FILTER_ON_CLIENT_SEARCH',
	SET_FILTER_ON_CLIENT_SEARCH_PROJECTS = 'SET_FILTER_ON_CLIENT_SEARCH_PROJECTS',
	SET_FILTER_ON_RELATED_BILLING_ADDRESSES_PROJECTS = 'SET_FILTER_ON_RELATED_BILLING_ADDRESSES_PROJECTS',
	SET_FILTER_ON_PROJECT_INVOICES_EXCEPT = 'SET_FILTER_ON_PROJECT_INVOICES_EXCEPT',
	
	// search
	SEARCH_PROJECTS = 'SEARCH_PROJECTS',

	// summary
	FETCH_INVOICES_SUMMARY_REPORT = 'FETCH_INVOICES_SUMMARY_REPORT',
	FETCH_INVOICES_METRICS_REPORT = 'FETCH_INVOICES_METRICS_REPORT',
	TOGGLE_SHOW_INVOICES_SUMMARY_REPORT = 'TOGGLE_SHOW_INVOICES_SUMMARY_REPORT',
	TOGGLE_SHOW_INVOICES_METRICS_REPORT = 'TOGGLE_SHOW_INVOICES_METRICS_REPORT',

	// crud
	FETCH_SALES_INVOICE_BY_ID = 'FETCH_SALES_INVOICE_BY_ID',
	SAVE_INVOICE = 'SAVE_INVOICE',
}

export const enum Getters {
	GET_LISTVIEW_IS_LOADING = 'GET_LISTVIEW_IS_LOADING',
	GET_LISTVIEW_INVOICES = 'GET_LISTVIEW_INVOICES',
	GET_STATUSES = 'GET_STATUSES',
	GET_STATUSES_TRANSLATED = 'GET_STATUSES_TRANSLATED',
	GET_PAYMENT_STATUSES = 'GET_PAYMENT_STATUSES',
	GET_PAYMENT_STATUSES_TRANSLATED = 'GET_PAYMENT_STATUSES_TRANSLATED',
	GET_TYPES = 'GET_TYPES',
	GET_TYPES_TRANSLATED = 'GET_TYPES_TRANSLATED',
	GET_RECORD_TYPES = 'GET_RECORD_TYPES',
	GET_RECORD_TYPES_TRANSLATED = 'GET_RECORD_TYPES_TRANSLATED',
	GET_IS_LOADING = 'GET_IS_LOADING',
	GET_CONTACTS = 'GET_CONTACTS',
	GET_PROJECTS = 'GET_PROJECTS',
	GET_SUPPLIERS = 'GET_SUPPLIERS',
	GET_CLIENTS = 'GET_CLIENTS',
	GET_RELATED_BILLING_ADDRESSES = 'GET_RELATED_BILLING_ADDRESSES',
	GET_NEW = 'GET_NEW',
	GET_PROJECT_INVOICES = 'GET_PROJECT_INVOICES',

	// reporting
	GET_INVOICES_SUMMARY_REPORT = 'GET_INVOICES_SUMMARY_REPORT',
	GET_INVOICES_METRICS_REPORT = 'GET_INVOICES_METRICS_REPORT',
	GET_SHOW_INVOICES_SUMMARY_REPORT = 'GET_SHOW_INVOICES_SUMMARY_REPORT',
	GET_SHOW_INVOICES_METRICS_REPORT = 'GET_SHOW_INVOICES_METRICS_REPORT',

	// search
	GET_SEARCH_PROJECTS = 'GET_SEARCH_PROJECTS',

	// filters listview
	GET_FILTER_PROJECT_MODEL = 'GET_FILTER_PROJECT_MODEL',
	GET_LISTVIEW_FILTER_STATE = 'GET_LISTVIEW_FILTER_STATE',

	// crud
	GET_INVOICE = 'GET_INVOICE',
}

export const enum Mutations {
	MUTATE_LISTVIEW_IS_LOADING = 'MUTATE_LISTVIEW_IS_LOADING',
	MUTATE_LISTVIEW_INVOICES = 'MUTATE_LISTVIEW_INVOICES',
	MUTATE_LISTVIEW_LINKS = 'MUTATE_LISTVIEW_LINKS',
	MUTATE_LISTVIEW_META = 'MUTATE_LISTVIEW_META',
	MUTATE_LISTVIEW_PUSH_NEXT_PAGE = 'MUTATE_LISTVIEW_PUSH_NEXT_PAGE',
	MUTATE_STATUSES = 'MUTATE_STATUSES',
	MUTATE_PAYMENT_STATUSES = 'MUTATE_PAYMENT_STATUSES',
	MUTATE_TYPES = 'MUTATE_TYPES',
	MUTATE_RECORD_TYPES = 'MUTATE_RECORD_TYPES',
	MUTATE_IS_LOADING = 'MUTATE_IS_LOADING',
	MUTATE_CONTACTS = 'MUTATE_CONTACTS',
	MUTATE_PROJECTS = 'MUTATE_PROJECTS',
	MUTATE_SUPPLIERS = 'MUTATE_SUPPLIERS',
	MUTATE_CLIENTS = 'MUTATE_CLIENTS',
	MUTATE_RELATED_BILLING_ADDRESSES = 'MUTATE_RELATED_BILLING_ADDRESSES',
	MUTATE_NEW = 'MUTATE_NEW',
	MUTATE_PROJECT_INVOICES = 'MUTATE_PROJECT_INVOICES',
	MUTATE_APPEND_TO_INVOICES = 'MUTATE_APPEND_TO_INVOICES',

	// crud
	MUTATE_INVOICE = 'MUTATE_INVOICE',

	// filters
	MUTATE_TOGGLE_SIDEBAR = 'MUTATE_TOGGLE_SIDEBAR',
	MUTATE_TOGGLE_FILTER_GROUP = 'MUTATE_TOGGLE_FILTER_GROUP',
	MUTATE_CLEAR_FILTERS = 'MUTATE_CLEAR_FILTERS',
	MUTATE_FILTER_STATUS = 'MUTATE_FILTER_STATUS',
	MUTATE_FILTER_PAYMENT_STATUS = 'MUTATE_FILTER_PAYMENT_STATUS',
	MUTATE_FILTER_TYPE = 'MUTATE_FILTER_TYPE',
	MUTATE_FILTER_INVOICE_DATE_FROM = 'MUTATE_FILTER_INVOICE_DATE_FROM',
	MUTATE_FILTER_INVOICE_DATE_TO = 'MUTATE_FILTER_INVOICE_DATE_TO',
	MUTATE_FILTER_DUE_DATE_FROM = 'MUTATE_FILTER_DUE_DATE_FROM',
	MUTATE_FILTER_DUE_DATE_TO = 'MUTATE_FILTER_DUE_DATE_TO',
	MUTATE_FILTER_PROJECT_LEAD = 'MUTATE_FILTER_PROJECT_LEAD',
	MUTATE_FILTER_PROJECT_NAME = 'MUTATE_FILTER_PROJECT_NAME',
	MUTATE_FILTER_PROJECT = 'MUTATE_FILTER_PROJECT',
	MUTATE_FILTER_PROJECT_MODEL = 'MUTATE_FILTER_PROJECT_MODEL',
	MUTATE_FILTER_FOR_SEARCH = 'MUTATE_FILTER_FOR_SEARCH',
	MUTATE_FILTER_VAT = 'MUTATE_FILTER_VAT',
	MUTATE_FILTER_ON_CONTACT_SEARCH = 'MUTATE_FILTER_ON_CONTACT_SEARCH',
	MUTATE_FILTER_ON_CONTACT_SEARCH_PROJECTS = 'MUTATE_FILTER_ON_CONTACT_SEARCH_PROJECT',
	MUTATE_FILTER_ON_CONTACT_SEARCH_SUPPLIERS = 'MUTATE_FILTER_ON_CONTACT_SEARCH_SUPPLIER',
	MUTATE_FILTER_ON_CONTACT_SEARCH_CLIENTS = 'MUTATE_FILTER_ON_CONTACT_SEARCH_CLIENT',
	MUTATE_CLEAR_FILTERS_ON_CONTACT_SEARCH = 'MUTATE_CLEAR_FILTER_ON_CONTACT_SEARCH',
	MUTATE_FILTER_ON_PROJECT_SEARCH = 'MUTATE_FILTER_ON_PROJECT_SEARCH',
	MUTATE_FILTER_ON_SUPPLIER_SEARCH = 'MUTATE_FILTER_ON_SUPPLIER_SEARCH',
	MUTATE_FILTER_ON_CLIENT_SEARCH = 'MUTATE_FILTER_ON_CLIENT_SEARCH',
	MUTATE_FILTER_ON_CLIENT_SEARCH_PROJECTS = 'MUTATE_FILTER_ON_CLIENT_SEARCH_PROJECTS',
	MUTATE_FILTER_ON_RELATED_BILLING_ADDRESSES_PROJECTS = 'MUTATE_FILTER_ON_RELATED_BILLING_ADDRESSES_PROJECTS',
	MUTATE_FILTER_ON_PROJECT_INVOICES_EXCEPT = 'MUTATE_FILTER_ON_PROJECT_INVOICES_EXCEPT',

	// reporting
	MUTATE_INVOICES_SUMMARY_REPORT = 'MUTATE_INVOICES_SUMMARY_REPORT',
	MUTATE_INVOICES_METRICS_REPORT = 'MUTATE_INVOICES_METRICS_REPORT',
	MUTATE_INVOICES_SHOW_SUMMARY_REPORT = 'MUTATE_INVOICES_SHOW_SUMMARY_REPORT',
	MUTATE_INVOICES_SHOW_METRICS_REPORT = 'MUTATE_INVOICES_SHOW_METRICS_REPORT',

	// search
	MUTATE_SEARCH_PROJECTS = 'MUTATE_SEARCH_PROJECTS',
}
