
	import { Component, Vue } from "vue-property-decorator";
	import { namespace } from "vuex-class";

	import { Actions, Getters } from "@/modules/settings/store/modules/permissions/types/StoreTypes";
	import { Getters as LoadingGetters } from '@/modules/loading/store/types/StoreTypes';
	import { Permission } from "@/modules/settings/store/modules/permissions/types/PermissionsState";
	import LoadingComponent from '@/core/components/loading/loading.component.vue';
    import _ from 'lodash'
import { Role } from "@/modules/entities/types/entities";

	const rolePermissions = namespace("settings/permissions");
	const loading = namespace('loading');

    interface GroupedPermission extends Permission {
        group: string
    }

	@Component({
		components: {
			loading: LoadingComponent
		}
	})
	export default class PermissionsComponent extends Vue {
		@rolePermissions.Action(Actions.FETCH_ROLES) fetchRoles!: () => void;
		@rolePermissions.Action(Actions.SET_ROLE_PERMISSIONS_EDIT_STATE) setEditState!: (payload: boolean) => void;
		@rolePermissions.Action(Actions.TOGGLE_SINGLE_ROLE_STATE) toggleSingleRoleState!: (payload: { role: Role, permission: Permission }) => void;
		@rolePermissions.Action(Actions.TOGGLE_ROLES_STATE) toggleRolesState!: (payload: { roles: Role[], oldRoles: Role[] }) => void;
		@rolePermissions.Action(Actions.UNDO_ROLES_STATE) undoRolesState!: (payload: Role[]) => void;

		@rolePermissions.Getter(Getters.GET_PERMISSIONS) permissions!: Permission[];
		@rolePermissions.Getter(Getters.GET_ROLES) roles!: Role[];
		@rolePermissions.Getter(Getters.GET_ROLE_PERMISSIONS_EDIT_STATE) editState!: boolean;
		@loading.Getter(LoadingGetters.GET_LOADING) loading!: boolean;

		private rolesOldState!: Role[];
		private rolesLocalCopyToEdit!: Role[];

		onToggleRoleState(e: { role: Role, permission: Permission }) {
			this.rolesLocalCopyToEdit = this.rolesLocalCopyToEdit.map(role => {
				if (!role.permissions!.includes(e.permission.name) && role.id === e.role.id) {
					return {
						...role,
						permissions: role.permissions!.concat(e.permission.name)
					};
				} else if (role.id === e.role.id) {
					return {
						...role,
						permissions: role.permissions!.filter((permission: string) => {
							return permission !== e.permission.name;
						})
					};
				}
				return role;
			});
			this.toggleSingleRoleState(e);
		}

		created() {
			this.fetchRoles();
		}

		toggleEditState(type: string) {
			switch (type) {
				case "undo":
					this.undoRolesState(this.rolesOldState);
					break;
				case "edit":
					this.rolesOldState = [...this.roles];
					this.rolesLocalCopyToEdit = [...this.roles];
					break;
				case "save":
					this.toggleRolesState({ roles: this.rolesLocalCopyToEdit, oldRoles: this.rolesOldState });
					break;
			}

			this.setEditState(!this.editState);
		}

        roleHasPermission(role: Role, permission: Permission) {
			if (role.permissions!.length === 0) {
				return false;
			}

			return role.permissions!.find((x: string) => x === permission.name);
		}

        comparePermissions(permission1: Permission, permission2: Permission) {
            return permission1.sequence - permission2.sequence;
        }

        isFirstOfGroup(permission: GroupedPermission, items: GroupedPermission[]) {
            const filtered = items.filter(x => x.group === permission.group)
            return filtered && filtered.length && filtered[0].id === permission.id
        }

        // getters
        get rolePermissionsHeaders() {
            if(this.roles && this.roles.length) {
                return [
                    {
                        text: '',
                        value: 'permissions',
                    },
                    ...this.roles.map((role: Role) => {
                        return {
                            text: role.name,
                            value: role.name,
                        }
                    })
                ]
            }
            return []
        }

        makeGroupedPermission(permission: Permission, group: string) {
            const y = {...permission} as GroupedPermission
            y.group = group
            return y
        }

        get groupedPermissions() {
            // current groups prefixed in `Permission.name`
            const groupPrefixes = ['APP__','ACCESS_PAGE__','CREATE_MODEL__','VIEW_MODEL__','UPDATE_MODEL__','DELETE_MODEL__','DATA_VISIBILITY__'];
            let permissions = [] as GroupedPermission[];

            // known groups, currently done with/by prefix, but the group/category could be saved in the database in a different field on 'Permission'
            groupPrefixes.forEach((groupStartWith: string) => {
                const items = this.permissions
                    .filter(p => p.name.startsWith(groupStartWith))
                    .map(x => this.makeGroupedPermission(x, groupStartWith))
                    .sort(this.comparePermissions)
                permissions = [...permissions, ...items]
            })

            // permissions that do not (yet) belong to a group 
            const diff = _.differenceBy(this.permissions.map(x => this.makeGroupedPermission(x, 'OTHER')), permissions, 'id')
            if(diff && diff.length) permissions = [...permissions, ...diff]

            return permissions
        }
	}
