import {RouteConfig} from 'vue-router';
import PurchaseInvoicesComponent from './views/purchase-invoices/purchase-invoices.component.vue';
import PurchaseInvoiceComponent from './views/purchase-invoice/purchase-invoice.component.vue';

export const routes: RouteConfig[] = [
	{
        path: '/invoices/purchase',
		name: 'purchaseInvoices',
		component: PurchaseInvoicesComponent
	},
	{
		path: '/invoices/purchase/:id',
		name: 'purchaseInvoiceDetails',
		component: PurchaseInvoiceComponent
	}
];
