
	import { AxiosResponse } from 'axios';
    import { Component, Vue, Provide, Watch, Prop, Model, Emit, PropSync } from 'vue-property-decorator';

	@Component
	export default class AuthImgComponent extends Vue {

        @Prop({default: ''}) src!: string;
        @Prop({default: ''}) alt!: string;

        content: {
            dataUri: string|null
        } = {
            dataUri: null
        }

        async fetchDataUri(newSrc: string) {
            try {
                let src = newSrc ? newSrc.trimStart().replace(/^\/+/, '') : ''; // trim and remove leading slashes
                let resp: AxiosResponse = await Vue.prototype.$http.get(process.env.VUE_APP_API_URL + `/api/v1/${ src }`)
                this.content = {...this.content, dataUri: resp.data}
            } catch (err: any) {
                console.warn(err);
            } finally {
                // 
            }
        }

        @Watch('src', {immediate: true})
        onWatchSrc(newSrc: string, oldSrc: string) {
            if(newSrc) this.fetchDataUri(newSrc);
        }
	}
