import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from 'vuex';

import Vue from 'vue';

import {RootState} from '@/core/types/RootState';
import {ActivityLogState} from '@/modules/settings/store/modules/activity-log/types/ActivityLogState';
import {Actions, Getters, Mutations} from '@/modules/settings/store/modules/activity-log/types/StoreTypes';
import {LogItem} from '@/modules/settings/types/entities';

const getters: GetterTree<ActivityLogState, RootState> = {
	[Getters.GET_ACTIVITY_LOG]: (state: ActivityLogState) => {
		return state.log;
	}
};

const actions: ActionTree<ActivityLogState, RootState> = {
	[Actions.FETCH_ACTIVITY_LOG]: async({commit}: ActionContext<ActivityLogState, RootState>) => {
		const res = await Vue.prototype.$http.get(`/api/v1/settings/activity-log`);
		commit(Mutations.MUTATE_ACTIVITY_LOG, res.data.data);
	},
};

const mutations: MutationTree<ActivityLogState> = {
	[Mutations.MUTATE_ACTIVITY_LOG]: (state: ActivityLogState, payload: LogItem[]) => {
		state.log = payload;
	}
};

const state: ActivityLogState = {
	log: []
};

export const store: Module<ActivityLogState, RootState> = {
	namespaced: true,
	actions,
	getters,
	state,
	mutations
};
