import axios, { AxiosResponse } from 'axios';

import { JsonResource } from '@/core/types/Entities';
import { AbsencePeriod } from '@/modules/settings/types/entities';

const ABSENCES_ENDPOINT = '/api/v1/settings/absences';
const ABSENCE_PERIODS_ENDPOINT = '/api/v1/settings/absence-periods';

export class AbsencesService {

	getBaseEndpoint(): string {
		return ABSENCES_ENDPOINT
	}

	getAbsencePeriods(params: URLSearchParams): Promise<AxiosResponse<JsonResource<AbsencePeriod[]>>> {
		return axios.get<JsonResource<AbsencePeriod[]>>(`${ ABSENCE_PERIODS_ENDPOINT }${params ? '?' + params.toString() : ''}`);
	}
}
