
  import { defineComponent, PropType, computed } from 'vue';
  import { Employee, EmployeeEntity } from '@/modules/settings/types/entities';

  export default defineComponent({
    name: 'EmployeeInfoSection',
    emits: ['update:employee'],
    props: {
      employee: { type: Object as PropType<Employee>, required: true, default: () => new EmployeeEntity() },
      validationErrors: { type: Object, default: null },
    },
    setup(props, { emit }) {
      /**
       * Methods
       *
       */
      const onInput = (employee: Employee) => emit('update:employee', employee);

      /**
       * Computed
       *
       * Computed values are cached. Only when the underlying data changes, the value gets re-computed.
       *   so, a cached copy of the prop in combination with :value and @input on form elements, we can
       *   emit changes and let the parent mutate the changes
       */
      const employeeCopy = computed<Employee>(() => JSON.parse(JSON.stringify(props.employee)));

      /**
       * Watchers
       *
       */
      // watch(props.user, (newValue, oldValue) => {
      //     console.log(props.user)
      //     console.log(employeeCopy)
      // }, { immediate: true, deep: true })

      return {
        employeeCopy,
        onInput,
      };
    },
  });
