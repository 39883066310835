
    import { Component, Vue, Provide, Watch, Prop, Model, Emit, PropSync } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    import { Actions, Getters } from '@/modules/accounting/store/types/StoreTypes';
    import { AccountingLedgerItem } from '@/modules/accounting/types/entities';
    import { Tax } from '@/modules/settings/types/entities';
    import { PropType } from 'vue';
    import TaxAutocompleteComponent from '@/modules/settings/store/modules/taxes/components/tax-autocomplete/tax-autocomplete.component.vue';
    import TaxMultipleAutocompleteComponent from '@/modules/settings/store/modules/taxes/components/tax-multiple-autocomplete/tax-multiple-autocomplete.component.vue';

    // namespaces
    const accounting = namespace('accounting');

    @Component({
        components: {
            'tax-autocomplete': TaxAutocompleteComponent,
            'tax-multi-autocomplete': TaxMultipleAutocompleteComponent,
        }
    })
    export default class AccountingLedgerItemDetailsComponent extends Vue {

        // actions

        // getters

        // props
        @PropSync('accountingLedgerItem', { type: Object as PropType<AccountingLedgerItem>, required: true }) accountingLedgerItemSync!: AccountingLedgerItem;
        @PropSync('validationErrors', { type: Object }) validationErrorsSync!: any;
        @Prop({default: 'default'}) viewMode!: string; // 'default','modal'
        @Prop({type: Array as PropType<Tax[]>, default: () => [] as Tax[] }) taxes!: Tax[];

        // local state + vars

        // methods
        
        // getters
        get availableDefaultTaxes() {
            const accountingLedgerItem = this.accountingLedgerItemSync || null
            let availableTaxes = [] as Tax[];

            if(accountingLedgerItem) {
                if(accountingLedgerItem.taxes) accountingLedgerItem.taxes.map(t => availableTaxes.push(t))
                if(accountingLedgerItem.defaultVat) availableTaxes = [...availableTaxes.filter(t => accountingLedgerItem.defaultVat && accountingLedgerItem.defaultVat.id !== t.id), accountingLedgerItem.defaultVat]
            }
            
            return availableTaxes;
        }
        get availableTaxes() {
            if (this.accountingLedgerItemSync.name && this.accountingLedgerItemSync.name.startsWith('6')) return this.taxes.filter((t: Tax) => t.forPurchases)
            return this.taxes
        }

        // watchers

        // lifecycle
        created() {
            //
        }
    }

