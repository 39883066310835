
import {Component, Prop, Vue} from "vue-property-decorator";
import {cloneDeep} from 'lodash'
import {
  IncomplianceStatus,
  SocialSecurityRegistrationCompliance,
  SocialSecurityRegistrationStatus
} from "@/modules/projects/types/social-security-registrations";

@Component
export default class SocialSecurityRegistrationListItem extends Vue {
    @Prop() ssrCompliance!: any;
    @Prop() projectCompliance!: any;
    @Prop({default: false}) expanded!: boolean;

    // state.editSocialSecurityRegistration.comment = state.editSocialSecurityRegistration && state.editSocialSecurityRegistration.id === ssrCompliance.id ? state.editSocialSecurityRegistration.comment = e : state.editSocialSecurityRegistration.comment
    comment: string|null = null;

    get ssrComplianceCopy() {
        return cloneDeep(this.ssrCompliance)
    }

    isContractorRegisteredButNotInPlanner(ssrCompliance: SocialSecurityRegistrationCompliance) {
        if(ssrCompliance) {
            return ssrCompliance.status === SocialSecurityRegistrationStatus.REGISTERED && (!ssrCompliance.contractorStartDate || !ssrCompliance.contractorEndDate)
        }
        return false
    }

    getColorForSsrStatus(ssrCompliance: SocialSecurityRegistrationCompliance) {
		switch (ssrCompliance.status) {
			case SocialSecurityRegistrationStatus.REGISTERED:
				return '#048D97'
			case SocialSecurityRegistrationStatus.NOT_REGISTERED:
                if(ssrCompliance.complianceStatus === IncomplianceStatus.CONTRACTOR_PERIOD_BETWEEN_SOCIAL_SECURITY_START_AND_END_DATE
                    && ssrCompliance.daysUntilDue < 0)
                {
                    return '#ff392e';
                }

				return '#FD8430'
			default:
				break;
		}
	}

    isContractorStartDateDue(ssrCompliance: SocialSecurityRegistrationCompliance) {
		switch(ssrCompliance.complianceStatus) {
			case IncomplianceStatus.CONTRACTOR_PERIOD_BEFORE_SOCIAL_SECURITY_START_DATE:
			case IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_DATE:
			case IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_AND_END_DATE:
				return true
			default:
				return false
		}
	}
	isContractorEndDateDue(ssrCompliance: SocialSecurityRegistrationCompliance) {
		switch(ssrCompliance.complianceStatus) {
			case IncomplianceStatus.CONTRACTOR_PERIOD_AFTER_SOCIAL_SECURITY_END_DATE:
			case IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_END_DATE:
			case IncomplianceStatus.CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_AND_END_DATE:
				return true
			default:
				return false
		}
	}

    created() {}
}
