
import {defineComponent, computed, reactive, getCurrentInstance} from 'vue';
  import { cloneDeep, debounce } from 'lodash';
  import useEmployee from '@/composables/useEmployee';
  import store from '../../store/modules/employees';
  import { newEmployeeWage } from '../../store/modules/employees/helpers';
  import { Employee, EmployeeEntity, EmployeeWage, EmployeeWageEntity } from '../../types/entities';
  import Dinero from 'dinero.js';
  import EmployeeInfoSectionComponent from '../../components/employees/employee-info-section.component.vue';

  export default defineComponent({
    name: 'Employees',
    components: {
      'employee-info-section': EmployeeInfoSectionComponent,
      'wage-sidemenu': () =>
        import(
          /* webpackChunkName: "sideMenu" */ '@/modules/settings/components/employees/wage-sidemenu.component.vue'
        ),
    },
    setup() {

      const vm = getCurrentInstance()!.proxy

      // composables
      const { getters: employeeListGetters, actions: employeeListActions } = store.employeeListView;
      const { getters: createEmployeeGetters, actions: createEmployeeActions } = useEmployee();
      const { getters: updateEmployeeGetters, actions: updateEmployeeActions } = useEmployee();
      const { getters: deleteEmployeeGetters, actions: deleteEmployeeActions } = useEmployee();
      // vars
      const wageData = reactive<{
        wage: EmployeeWage | null;
        employee: Employee | null;
      }>({
        wage: newEmployeeWage(),
        employee: null,
      });

      /**
       * Handlers
       *
       */
      const onClickCreateEmployee = async () => {
        await createEmployeeActions.createEmployee().then(() => {
          if (createEmployeeGetters.employee.value) {
            employeeListActions.addItem(cloneDeep(createEmployeeGetters.employee.value));
          }
          createEmployeeActions.showDialog('add', false);
          createEmployeeActions.setEmployee(null);
        });
      };
      const onClickUpdateEmployee = async () => {
        await updateEmployeeActions.updateEmployee().then(() => {
          if (updateEmployeeGetters.employee.value) {
            employeeListActions.replaceItem(
              cloneDeep(updateEmployeeGetters.employee.value),
              (x, item) => (x as Employee).id === (item as Employee).id
            );
          }
          updateEmployeeActions.showDialog('edit', false);
          updateEmployeeActions.setEmployee(null);
        });
      };
      const onClickDeleteEmployee = async () => {
        await deleteEmployeeActions.deleteEmployee().then(() => {
          if (deleteEmployeeGetters.employee.value) {
            employeeListActions.removeItem(
              deleteEmployeeGetters.employee.value,
              (x, item) => (x as Employee).id === (item as Employee).id
            );
          }
          deleteEmployeeActions.showDialog('delete', false);
          deleteEmployeeActions.setEmployee(null);
        });
      };
      const onChangeFilter = (filter: string, value: string) => {
        employeeListActions.setFilter(filter, value);
        employeeListActions.fetchEmployees();
      };
      const onUpdateEmployeeInfo = (employee: Employee, modal = 'add') => {
        if (modal === 'add' && createEmployeeGetters.employee.value) {
          createEmployeeActions.setEmployee({
            ...createEmployeeGetters.employee.value,
            firstName: employee.firstName,
            lastName: employee.lastName,
          });
        } else if (modal === 'edit' && updateEmployeeGetters.employee.value) {
          updateEmployeeActions.setEmployee({
            ...updateEmployeeGetters.employee.value,
            firstName: employee.firstName,
            lastName: employee.lastName,
          });
        }
      };
      const saveWage = async (employee: Employee, wage: EmployeeWage) => {
        await updateEmployeeActions.setEmployee(employee);
        await updateEmployeeActions.saveWage(wage).then((resp) => {
          employeeListActions.replaceItem(
            cloneDeep(resp.data.data),
            (x, item) => (x as Employee).id === (item as Employee).id
          );
          updateEmployeeActions.showDialog('add-wage', false);
          updateEmployeeActions.showDialog('edit-wage', false);
        });
      };
      const deleteWage = async (employee: Employee, wage: EmployeeWage) => {
        await updateEmployeeActions.setEmployee(employee);
        await updateEmployeeActions.deleteWage(wage).then((resp) => {
          employeeListActions.replaceItem(
            cloneDeep(resp.data.data),
            (x, item) => (x as Employee).id === (item as Employee).id
          );
          updateEmployeeActions.showDialog('delete-wage', false);
        });
      };

      const cancelWage = (wage: EmployeeWage) => {
        updateEmployeeActions.showDialog('add-wage', false);
        updateEmployeeActions.showDialog('edit-wage', false);
      };

      /**
       * Methods
       *
       */
      const openModal = (modal: string, employee: Employee, wage?: EmployeeWage) => {
        switch (modal) {
          case 'add':
            createEmployeeActions.clearEmployeeErrors();
            if (!createEmployeeGetters.employee.value) createEmployeeActions.setEmployee(new EmployeeEntity());
            createEmployeeActions.showDialog(modal, true);
            break;
          case 'edit':
            if (employee.id) {
              updateEmployeeActions.fetchEmployee(employee.id).then(() => {
                updateEmployeeActions.showDialog(modal, true);
              });
            }
            break;
          case 'delete':
            deleteEmployeeActions.setEmployee(employee);
            deleteEmployeeActions.showDialog(modal, true);
            break;
          case 'add-wage':
            wageData.employee = cloneDeep(employee);
            const latestWage = wageData.employee.wages.length ? wageData.employee.wages[0] : null;
            wageData.wage = latestWage ? newEmployeeWage({ ...latestWage, id: -1 }) : newEmployeeWage({ id: -1 });
            updateEmployeeActions.showDialog(modal, true);
            break;
          case 'edit-wage':
            wageData.employee = cloneDeep(employee);
            if (wage && wage.id) {
              wageData.wage = cloneDeep(wage);
              updateEmployeeActions.showDialog(modal, true);
            }
            break;
          case 'delete-wage':
            wageData.employee = cloneDeep(employee);
            if (wage && wage.id) {
              wageData.wage = cloneDeep(wage);
              updateEmployeeActions.showDialog(modal, true);
            }
            break;
        }
      };

      const overallCost = (wage: EmployeeWage): number | undefined => {
        // if (employee.wages.length) {
        //   const hourly = Dinero({ amount: employee.wages[idx].hourlyRate, currency: 'EUR', precision: 2 });
        //   const avgMaterialCost = Dinero({ amount: employee.wages[idx].avgMaterialCost, currency: 'EUR', precision: 2 });
        //   return hourly.add(avgMaterialCost).getAmount();
        // }
        if (wage) {
          const hourly = Dinero({ amount: wage.hourlyRate, currency: 'EUR', precision: 2 });
          const avgMaterialCost = Dinero({
            amount: wage.avgMaterialCost,
            currency: 'EUR',
            precision: 2,
          });
          return hourly.add(avgMaterialCost).getAmount();
        }
        return undefined;
      };

      const employeeExpanded = (employeeId: number): boolean => {
        return employeeListGetters.expandedIds.value.some((x) => x === employeeId);
      };
      const toggleEmployee = (employeeId: number) => {
        employeeExpanded(employeeId)
          ? employeeListActions.collapse(employeeId)
          : employeeListActions.expand(employeeId);
      };

      const searchEmployees = (query: string) => searchEmployeesDebounced(query);
      const searchEmployeesDebounced = debounce((query: string) => {
        if (employeeListGetters.filters.value['search'] !== query) {
          employeeListActions.search(query, true);
        }
      }, 180);

      /**
       * Computed
       *
       */
      const headers = computed<any>(() => {
        return [
          {
            text: vm.$t('dictionary.firstName'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.lastName').toString(),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.hourlyRate').toString(),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.averageMaterialCost').toString(),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.overallCost').toString(),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.startDate').toString(),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.endDate').toString(),
            sortable: false,
          },
          {
            text: '',
            sortable: false,
          },
          {
            text: '',
            sortable: false,
          },
        ];
      });

      const loading = computed(
        () =>
          employeeListGetters.isLoading.value ||
          createEmployeeGetters.isLoading.value ||
          updateEmployeeGetters.isLoading.value ||
          deleteEmployeeGetters.isLoading.value
      );

      /**
       * Watchers
       *
       */

      /**
       * Initial data
       *
       */
      employeeListActions.fetchEmployees();

      /**
       * Return state + actions
       */
      return {
        wageData,

        onClickCreateEmployee,
        onClickUpdateEmployee,
        onClickDeleteEmployee,
        onChangeFilter,
        onUpdateEmployeeInfo,

        openModal,
        overallCost,
        employeeExpanded,
        searchEmployees,
        toggleEmployee,
        saveWage,
        deleteWage,
        cancelWage,

        headers,
        loading,

        employeeListGetters,
        createEmployeeGetters,
        updateEmployeeGetters,
        deleteEmployeeGetters,
        employeeListActions,
        createEmployeeActions,
        updateEmployeeActions,
        deleteEmployeeActions,
      };
    },
  });
