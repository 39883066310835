

import { defineComponent } from 'vue';
import { formatDate } from '@/core/helpers/useFormatDate';
import authStore from "@/modules/login/store";

export default defineComponent({
	name: 'AppNewCommentComponent',
	emits: ['close', 'save', 'input'],
	props: {
		value: {
			type: String,
			required: true
		}
	},
	setup(props) {
		return {
          auth: authStore.auth,
          formatDate
		};
	}
});
