import axios, { AxiosResponse } from 'axios';
import {JsonResource} from '@/core/types/Entities';
import { Supplier } from '@/modules/entities/types/entities';
import {Division, Team} from '@/modules/settings/types/entities';

const BASE_ENDPOINT = '/api/v1/contractors';

export default class ContractorsService {

  getBaseEndpoint(): string {
    return BASE_ENDPOINT;
  }

  getContractors(params?: URLSearchParams): Promise<AxiosResponse<JsonResource<Supplier|Division|Team[]>>> {
    return axios.get<JsonResource<Supplier|Division|Team[]>>(`${ BASE_ENDPOINT }${ params ? '?' + params.toString() : '' }`);
  }
}