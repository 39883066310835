import { WorkDaySequence } from "@/modules/calendar/interfaces/project/workDaySequence";
import { Contractor } from "@/modules/calendar/interfaces/project/contractor";
import { Supplier, Comment } from "@/modules/entities/types/entities";
import { Division, Team } from '@/modules/settings/types/entities';
import { Quote } from "@/modules/calendar/interfaces/project/quote";
import { Project } from "@/modules/projects/types/entities";
import { PlannerPeriod } from "@/modules/calendar/interfaces/project/plannerPeriod";

export interface WorkDay {
	id: number;
	day: string;
	description: string;
	contractorTempAssigned: boolean;
	contractorTypeTempAssigned: boolean;
	contractorPermAssigned: boolean;
	contractorTypePermAssigned: boolean;
	quoteId: number;
	periodId: number;
	sequences: WorkDaySequence[];
	comments: string[] | Comment[];
	contractorable?: Team | Division | Supplier;
 	plannerPeriod?: PlannerPeriod;
}

export class WorkDayEntity implements WorkDay {
	id = -1;
	day = '';
	description = '';
	contractorTempAssigned = false;
	contractorTypeTempAssigned = false;
	contractorPermAssigned = false;
	contractorTypePermAssigned = false;
	quoteId = -1;
	periodId = -1;
	sequences = [];
	comments = [];
	contractorable = undefined;

	constructor(data: Partial<WorkDay> = {}) {
	  Object.assign(this, data);
	}
}

export interface WorkDayData {
	workDay?: {
		id: number;
		sequences: WorkDaySequence[];
		comments: any;
		date: string;
	};
	contractor?: Contractor;
	plannerPeriod?: PlannerPeriod;
	project?: Project;
	quote?: Quote;
}