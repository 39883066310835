
  import { computed, defineComponent, PropType } from 'vue';
  import helpers from '@/modules/calendar/services/helper.service';
  import SidemenuSubPeriodComponent from '@/modules/calendar/components/sidemenu-sub-period.component.vue';
  import store from '../store';
  import { CalendarSidebarDialog } from '../types/CalendarSidebarDialog';
  import SidemenuWorkdaySequenceComponent from './sidemenu-workday-sequence.component.vue';
  import { ContractorType } from '../types/ContractorType';
  import { CalendarCrudState } from '../types/CalendarCrudState';
  import { Team } from '../interfaces/project/team';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import ErrorWorkdayHasLockedTimesheetEntries
    from "@/modules/calendar/components/errors/error-workdays-have-locked-timesheet-entries.component.vue";

  export default defineComponent({
    name: 'CalendarSidemenuWorkSectionComponent',
    emits: [],
    components: {
      'sidemenu-sub-period': SidemenuSubPeriodComponent,
      'sidemenu-workday-sequence': SidemenuWorkdaySequenceComponent,
    },
    setup(props) {

      const saveSequences = () => {
        store.calendar.actions
          .saveSequences()
          .catch((err) => {
            console.log(err)
            appMessageStore.actions.warn({
              message: err.response.data.message,
              ttl: 60000,
              component: {
                cmp: ErrorWorkdayHasLockedTimesheetEntries,
                props: {
                  errorResponse: err.response.data,
                },
              },
            });
          })
      }

      const availableTeamsForThisDay = computed(() => {
        let teams: Team[] = [];
        const plannerDay = store.calendar.getters.selectedPlannerDay.value;
        const quote = plannerDay && plannerDay.quote;
        const division = quote && quote.contractor && helpers.isDivision(quote.contractor) && quote.contractor;
        if (plannerDay && division && division.teams) {
          teams = helpers.getActiveTeamsBetween(division.teams, plannerDay.day, plannerDay.day);
        }
        return teams;
      });

      const availableTeamsForThisPeriod = computed(() => {
        let teams: Team[] = [];
        const plannerPeriod = store.calendar.getters.selectedPeriod.value;
        const quote = plannerPeriod && plannerPeriod.quote;
        const division = quote && quote.contractor && helpers.isDivision(quote.contractor) && quote.contractor;
        if (plannerPeriod && division && division.teams) {
          teams = helpers.getActiveTeamsBetween(
            division.teams,
            plannerPeriod.plannerStartDate,
            plannerPeriod.plannerEndDate
          );
        }
        return teams;
      });

      const availableTeams = computed(() => {
        switch (store.calendar.getters.activeSidebarDialog.value) {
          case CalendarSidebarDialog.PERIOD:
            return availableTeamsForThisPeriod.value;
          case CalendarSidebarDialog.DAY:
            return availableTeamsForThisDay.value;
          default:
            return [];
        }
      });

      // TODO: next up
      /**
       * In een Period kan een Team mogelijks niet de volledige periode actief zijn.
       * Bv, periode van 3 dagen, waarbij "Team 1" maar beschikbaar is tot de 2e dag.
       * Wat moet er dan gebeuren?
       *  - "Team 1" voor elke dag zichtbaar houden en visueel weergeven dat "Team 1" op de derde dag eigenlijk niet beschikbaar is?
       *  - OF "Team 1" helemaal uit de lijst houden, zodat die helemaal niet beschikbaar is <== **dit lijkt de beste optie**
       */
      const isTeamAvailable = (team: Team) => {
        return availableTeams.value.some((t) => t.id === team.id);
      };

      const contractor = computed(() => {
        switch (store.calendar.getters.activeSidebarDialog.value) {
          case CalendarSidebarDialog.PERIOD:
            return store.calendar.getters.selectedPeriod.value?.quote?.contractor;
          case CalendarSidebarDialog.DAY:
            return store.calendar.getters.selectedPlannerDay.value?.quote?.contractor;
          default:
            return null;
        }
      });

      return {
        ...helpers,
        calendar: store.calendar,
        CalendarSidebarDialog,
        ContractorType,
        CalendarCrudState,
        contractor,
        isTeamAvailable,
        saveSequences,
      };
    },
  });
