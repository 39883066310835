export const enum Actions {
	FETCH_ACTIVE_ACCOUNTING_LEDGER = 'FETCH_ACTIVE_ACCOUNTING_LEDGER',
	SET_IS_LOADING = 'SET_IS_LOADING',

	FETCH_ACCOUNTING_LEDGERS = 'FETCH_ACCOUNTING_LEDGERS',
	FETCH_ACCOUNTING_LEDGER = 'FETCH_ACCOUNTING_LEDGER',
	SAVE_ACCOUNTING_LEDGER_BY_ID = 'SAVE_ACCOUNTING_LEDGER_BY_ID',
	CREATE_ACCOUNTING_LEDGER = 'CREATE_ACCOUNTING_LEDGER',

	FETCH_ACCOUNTING_LEDGER_ITEM = 'FETCH_ACCOUNTING_LEDGER_ITEM',
	CREATE_ACCOUNTING_LEDGER_ITEM = 'CREATE_ACCOUNTING_LEDGER_ITEM',
	SAVE_ACCOUNTING_LEDGER_ITEM_BY_ID = 'SAVE_ACCOUNTING_LEDGER_ITEM_BY_ID',

	ACTIVATE_ACCOUNTING_LEDGER = 'ACTIVATE_ACCOUNTING_LEDGER',
}

export const enum Getters {
	GET_ACTIVE_ACCOUNTING_LEDGER = 'GET_ACTIVE_ACCOUNTING_LEDGER',
	GET_IS_LOADING = 'GET_IS_LOADING',
	
	GET_ACCOUNTING_LEDGERS = 'GET_ACCOUNTING_LEDGERS',
	GET_ACCOUNTING_LEDGER = 'GET_ACCOUNTING_LEDGER',
	GET_ACCOUNTING_LEDGER_IS_LOADING = 'GET_ACCOUNTING_LEDGER_IS_LOADING',
	
	GET_ACCOUNTING_LEDGER_ITEM = 'GET_ACCOUNTING_LEDGER_ITEM',
	GET_ACCOUNTING_LEDGER_ITEM_IS_LOADING = 'GET_ACCOUNTING_LEDGER_ITEM_IS_LOADING',

}

export const enum Mutations {
	MUTATE_IS_LOADING = 'MUTATE_IS_LOADING',
	MUTATE_ACTIVE_ACCOUNTING_LEDGER = 'MUTATE_ACTIVE_ACCOUNTING_LEDGER',
	MUTATE_ACCOUNTING_LEDGERS = 'MUTATE_ACCOUNTING_LEDGERS',

	MUTATE_ACCOUNTING_LEDGER = 'MUTATE_ACCOUNTING_LEDGER',

	MUTATE_ACCOUNTING_LEDGER_ITEM = 'MUTATE_ACCOUNTING_LEDGER_ITEM',
	
	
}
