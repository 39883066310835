import useCalendar from '@/modules/calendar/composables/useCalendar';
import useCalendarSettings from '../composables/useCalendarSettings';

const calendarSettings = useCalendarSettings();
const calendar = useCalendar({
  composables: {
    calendarSettings: calendarSettings,
  },
});

const store = {
  calendar: calendar,
  calendarSettings: calendarSettings,
};

export default store;
