import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { state } from './state';
import { RootState } from '@/core/types/RootState';
import { TaxState } from '@/modules/settings/store/modules/taxes/types/TaxState';

export const store: Module<TaxState, RootState> = {
	namespaced: true,
	actions,
	getters,
	state,
	mutations
};
