import { JsonResource } from '@/core/types/Entities';
import { AxiosResponse } from 'axios';
import { UseProjectRelationshipContext } from '@/composables/types/useProjectRelationship';
import { ProjectRelationshipsService, ProjectRelationshipCommandsService } from '@/services/project-relationships.service';
import {ProjectRelationship} from "@/modules/purchase-invoices/types/entities";

const service = new ProjectRelationshipsService();
const commands = new ProjectRelationshipCommandsService();

const setProjectRelationship = async (ctx: UseProjectRelationshipContext, projectRelationship: ProjectRelationship|null): Promise<void> => new Promise((resolve, reject) => {
  const { state } = ctx;
  try {
    state.projectRelationship = projectRelationship;
    resolve();
  } catch (err: any) { reject(err); }
});

const clearProjectRelationshipValidationErrors = async (ctx: UseProjectRelationshipContext) => {
  const { state } = ctx;
  state.projectRelationshipValidationErrors = null;
  return Promise.resolve();
};

const clearProjectRelationshipBusinessErrors = async (ctx: UseProjectRelationshipContext) => {
  const { state } = ctx;
  state.projectRelationshipBusinessErrors = null;
  return Promise.resolve();
};

const clearProjectRelationshipErrors = async (ctx: UseProjectRelationshipContext) => {
  await clearProjectRelationshipValidationErrors(ctx);
  await clearProjectRelationshipBusinessErrors(ctx);
  return Promise.resolve();
};

const fetchProjectRelationship = async (ctx: UseProjectRelationshipContext, id: number): Promise<AxiosResponse<JsonResource<ProjectRelationship>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchProjectRelationship.name, true);
  try {
    clearProjectRelationshipErrors(ctx);
    const res = await service.getProjectRelationshipById(id);
    setProjectRelationship(ctx, res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchProjectRelationship.name, false);
  }
};

const splitProjectRelationship = async (ctx: UseProjectRelationshipContext, newProjectRelationship: ProjectRelationship): Promise<AxiosResponse<JsonResource<ProjectRelationship>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(splitProjectRelationship.name, true);
  clearProjectRelationshipErrors(ctx);
  try {
    if(state.projectRelationship !== null) {
      clearProjectRelationshipErrors(ctx);
      const res = await commands.splitProjectRelationship(newProjectRelationship, state.projectRelationship);
      setProjectRelationship(ctx, res.data.data);
      return Promise.resolve(res);
    }
    else return Promise.reject(new Error('ProjectRelationship not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.projectRelationshipValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.projectRelationshipBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(splitProjectRelationship.name, false);
  }
};

export const actions = {
  fetchProjectRelationship,
  splitProjectRelationship,

  setProjectRelationship,
  clearProjectRelationshipValidationErrors,
  clearProjectRelationshipBusinessErrors,
  clearProjectRelationshipErrors,
};
