export const enum Actions {
	SET_SELECTED_TEAM_MEMBER = 'SET_SELECTED_TEAM_MEMBER',
	FETCH_MONTHLY_OVERVIEW_DATA = 'FETCH_MONTHLY_OVERVIEW_DATA'
}

export const enum Getters {
	GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS',
	GET_SELECTED_TEAM_MEMBER = 'GET_SELECTED_TEAM_MEMBER',
	GET_MONTHLY_OVERVIEW_DATA = 'GET_MONTHLY_OVERVIEW_DATA'
}

export const enum Mutations {
	MUTATE_TEAM_MEMBERS = 'MUTATE_TEAM_MEMBERS',
	MUTATE_SELECTED_TEAM_MEMBER = 'MUTATE_SELECTED_TEAM_MEMBER',
	MUTATE_MONTHLY_OVERVIEW_DATA = 'MUTATE_MONTHLY_OVERVIEW_DATA'
}
