import {RouteConfig} from 'vue-router';
import SuppliersComponent from '@/modules/entities/views/suppliers/suppliers.vue';
import SupplierDetailsComponent from '@/modules/entities/views/suppliers/supplier-detail-component.vue';

export const routes: RouteConfig[] = [
	{
		path: '/suppliers',
		component: SuppliersComponent,
	},
	{
		path: '/suppliers/:id',
		name: 'supplierDetails',
		component: SupplierDetailsComponent,
	},
];
