
  import { AxiosResponse } from 'axios';
  import { getDocument, GlobalWorkerOptions, PageViewport, PDFWorker, PDFDocumentProxy } from 'pdfjs-dist'
  import DrawingCanvasComponent from './drawing-canvas.component.vue'
  import DrawingToolbarComponent from './drawing-toolbar.component.vue'
  import { DrawingEventHandlerService } from '@/modules/purchase-invoices/services/drawing-event-handler.service'
  import { FabricShapeService } from '@/modules/purchase-invoices/services/drawing-shape.service'
  import { CustomFabricObject } from '../../types/drawing';
  import { Media, MediaAnnotation, MediaAnnotationSvg, User } from '@/modules/entities/types/entities';
  import Vue, {computed, defineComponent, onMounted, PropType, provide, reactive, ref, toRaw, watch} from "vue";

  const pdfWorker = require('!!file-loader!pdfjs-dist/build/pdf.worker')
  GlobalWorkerOptions.workerSrc = pdfWorker
  // GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js`;

  export default defineComponent({
    name: 'PdfAnnotateComponent',
    components: {
      'drawing-canvas': DrawingCanvasComponent,
      'drawing-toolbar': DrawingToolbarComponent,
    },
    emits: ['updatedMediaAnnotation'],
    props: {
      src: {
        type: String,
        required: true,
        default: '',
      },
      media: {
        type: Object as PropType<Media>,
        required: true,
      },
      user: {
        type: Object as PropType<User>,
        required: true,
      },
      mediaAnnotation: {
        type: Object as PropType<{current: MediaAnnotation, all: MediaAnnotation[]}>,
        required: true,
      },
    },
    setup(props, {emit}) {

      const pdfCanvas = ref<InstanceType<typeof HTMLCanvasElement>>()
      const viewport = ref<InstanceType<typeof HTMLDivElement>>()

      const currentPageNumber = ref(0)
      const eventHandler = new DrawingEventHandlerService(new FabricShapeService())
      provide('drawingEventHandler', eventHandler);

      const state = reactive({
        pdfIsRendering: false
      })
      const content = reactive<{
        dataUri: string|null,
        pdfBytes: ArrayBuffer|null,
        pdf: PDFDocumentProxy|null,
        pdfPageViewport: PageViewport|null,
        numPages: number,
        defaultPage: number,
        scale: number,
        scaleFactor: number,
        allObjects: CustomFabricObject[],
        svgPerPage: MediaAnnotationSvg[]
      }>({
        dataUri: null,
        pdfBytes: null,
        pdf: null,
        pdfPageViewport: null,
        numPages: 0,
        defaultPage: 1,
        scale: 1.25,
        scaleFactor: 1.25,
        allObjects: [] as CustomFabricObject[],
        svgPerPage: []
      })

      onMounted(() => {
        initDocument()
        initAnnotations()
      })

      const dimensions = computed(() => {
        const dimensions: {width: number; height: number } = { width: content.pdfPageViewport ? content.pdfPageViewport.width : 0, height: content.pdfPageViewport ? content.pdfPageViewport.height : 0 }
        return dimensions
      })

      const currentMediaAnnotation = computed(() => {
        return props.mediaAnnotation.current;
      })

      const allMediaAnnotation = computed(() => {
        return props.mediaAnnotation.all;
      })

      const objectsForCurrentmediaAnnotation = computed(() => {
        if(currentMediaAnnotation.value && currentMediaAnnotation.value.id) {
          const currentMediaAnnotationId = currentMediaAnnotation.value.id.toString()
          return content.allObjects.filter(obj => !obj.mediaAnnotationId || obj.mediaAnnotationId === currentMediaAnnotationId)
        }
        throw 'Current media annotation not provided'
      })

      watch(() => props.src, (src, oldSrc) => {
        initDocument()
      }, {
        immediate: false
      })

      watch(() => props.mediaAnnotation, (mediaAnnotation, oldMediaAnnotation) => {
        initAnnotations()
      }, {
        immediate: false,
        deep: true
      })

      const saveCanvasObjects = () => {
        const currentMediaAnnotationId = currentMediaAnnotation.value && currentMediaAnnotation.value.id ? currentMediaAnnotation.value.id.toString() : undefined;
        const canvasObjects = eventHandler.canvas.getObjects() as CustomFabricObject[]

        const all = [...toRaw(content).allObjects]

        // replace or add objects
        canvasObjects.forEach((obj) => {
          const index = all.findIndex(allItem => allItem.id === obj.id )
          if(index >= 0) all.splice(index, 1, obj)
          else all.push(obj)
        })

        // remove objects
        const initialCurrentPageObjects = all.filter(obj => obj.pageNumber === currentPageNumber.value)
        initialCurrentPageObjects.forEach(obj => {
          const stillOnCanvas = canvasObjects.findIndex(canvasItem => canvasItem.id === obj.id)
          if(stillOnCanvas === -1) all.splice(all.findIndex(allObj => obj.id === allObj.id), 1)
        })

        content.allObjects = all

        return all;
      }

      const onObjectsChanged = () => {
        if(currentMediaAnnotation.value) {
          saveCanvasObjects()
          if(currentPageNumber.value !== 0) saveCanvasSvgPerPage()

          // emit 'MediaAnnotation' to parent component
          emit('updatedMediaAnnotation', toMediaAnnotation(objectsForCurrentmediaAnnotation.value))
        }
      }

      const saveCanvasSvgPerPage = () => {
        // cache/save svg
        const canvasSvg: any = eventHandler.canvas.toSVG()
        let svgForPage = {
          pageNumber: currentPageNumber.value,
          svg: canvasSvg ? canvasSvg.replaceAll('\n','') : ''
        }
        let keepPages = toRaw(content).svgPerPage.filter(item => item.pageNumber !== currentPageNumber.value)
        content.svgPerPage = [...keepPages, svgForPage]
      }

      const onClickNextPage = () => {
        // cache current canvas objects
        const currentMediaAnnotationObjects = saveCanvasObjects()

        // remove them from canvas
        eventHandler.canvas.remove(...eventHandler.canvas.getObjects())

        // update page number and render page
        if(currentPageNumber.value < content.numPages) {
          currentPageNumber.value += 1;
          eventHandler.setPageNumber(currentPageNumber.value)
          renderPage(currentPageNumber.value)
        }

        // emit 'MediaAnnotation' to parent component
        emit('updatedMediaAnnotation', toMediaAnnotation(objectsForCurrentmediaAnnotation.value))
      }

      const onClickPreviousPage = () => {
        // cache current canvas objects
        const currentMediaAnnotationObjects = saveCanvasObjects()

        // remove them from canvas
        eventHandler.canvas.remove(...eventHandler.canvas.getObjects())

        // update page number and render page
        if(currentPageNumber.value > 1) {
          currentPageNumber.value -= 1;
          eventHandler.setPageNumber(currentPageNumber.value)
          renderPage(currentPageNumber.value)
        }

        // emit 'MediaAnnotation' to parent component
        emit('updatedMediaAnnotation', toMediaAnnotation(objectsForCurrentmediaAnnotation.value))
      }

      const onClickZoomIn = () => {
        content.scale = content.scale * content.scaleFactor
        loadPage(currentPageNumber.value)
      }

      const onClickZoomOut = () => {
        content.scale = content.scale / content.scaleFactor
        loadPage(currentPageNumber.value)
      }

      const renderPage = (currentPageNumber: number) => {
        // first render pdf page, then render canvas objects
        loadPage(currentPageNumber).then((v: any) => {
          loadObjectsToCanvas(getObjectsForPage(currentPageNumber))
        }).catch((err: any) => {
          console.error(err)
        })
      }

      const getObjectsForPage = (pageNumber: number) => {
        return toRaw(content).allObjects.filter(obj => obj.pageNumber === pageNumber)
      }

      const loadObjectsToCanvas = (objects: CustomFabricObject[]) => {
        eventHandler.canvas.setZoom(content.scale)
        eventHandler.rebuildObjects(objects)
      }

      const fetchDataUri = async () => {
        try {
          let src = props.src ? props.src.trimStart().replace(/^\/+/, '') : ''; // trim and remove leading slashes
          const resp: AxiosResponse = await Vue.prototype.$http.get(process.env.VUE_APP_API_URL + `/api/v1/${ src }`)
          const pdfBytes = await fetch(resp.data).then(res => res.arrayBuffer())
          content.dataUri = resp.data
          content.pdfBytes = pdfBytes
        } catch (err: any) {
          console.warn(err);
        } finally {
          //
        }
      }

      const loadDocument = async (): Promise<PDFDocumentProxy> => {
        if(content.dataUri) {
        const data = content.dataUri ? content.dataUri.split('base64,') : null
        const loadingTask = data && data.length ? getDocument({data:atob(data[1])}) : null
        if(loadingTask) {
          const pdf = await loadingTask.promise;
          return pdf
        }
      }
      throw 'no data'
    }

      const loadPage = async (pageNumber: number) => {
        const pdf = content.pdf;
        if(pdf) {
          content.numPages = pdf.numPages

          pdf.getPage(pageNumber).then((page) => {

            if(viewport.value && pdfCanvas.value) {

              const vp = page.getViewport({ scale: content.scale });
              const context = pdfCanvas.value.getContext('2d');
              const renderContext: any = {
                canvasContext: context,
                viewport: vp
              };

              // set dimensions
              viewport.value.style.width = vp.width.toString() + 'px'
              viewport.value.style.height = vp.height.toString() + 'px'
              pdfCanvas.value.height = vp.height;
              pdfCanvas.value.width = vp.width;

              // set content/state vars
              currentPageNumber.value = page.pageNumber;
              content.pdfPageViewport = vp

              // ensure non-rendering state before rerendering
              if (!state.pdfIsRendering) {
                state.pdfIsRendering = true

                // render page async
                page.render(renderContext).promise.then(() => {
                  state.pdfIsRendering = false
                  return Promise.resolve(page)
                })
              }
            }

          }).catch((reason) => {
            return Promise.reject(reason)
          })
        }
      }

      const fromMediaAnnotations = (mediaAnnotations: MediaAnnotation[]) => {
        let objects = [] as CustomFabricObject[]
        mediaAnnotations.forEach(mediaAnnotation => {
          let items = (mediaAnnotation.annotation ? mediaAnnotation.annotation : []) as CustomFabricObject[]
          objects = [...objects, ...items]
        })
        return objects;
      }

      const toMediaAnnotation = (objects: CustomFabricObject[]) => {
        const mediaAnnotationId = currentMediaAnnotation.value.id ? currentMediaAnnotation.value.id.toString() : undefined
        if(mediaAnnotationId) {
          objects.forEach(obj => obj.mediaAnnotationId = mediaAnnotationId)
          return {
            ...currentMediaAnnotation.value,
            annotation: JSON.parse(JSON.stringify(objects)),
            svgData: content.svgPerPage
          } as MediaAnnotation
        }
      }

      const initDocument = () => {
        fetchDataUri().then(() => {
          loadDocument().then((pdf: PDFDocumentProxy) => {
            content.pdf = pdf
            content.numPages = pdf.numPages
            loadPage(content.defaultPage)
          })
        });
      }

      const initAnnotations = () => {
        if(currentMediaAnnotation.value && currentMediaAnnotation.value.id && props.user) {
          const currentMediaAnnotationId = currentMediaAnnotation.value.id.toString();
          const userId = props.user.id

          // reset canvas
          eventHandler.canvas.remove(...eventHandler.canvas.getObjects())

          // set content
          content.allObjects = fromMediaAnnotations(allMediaAnnotation.value)

          // TODO: improve usage of authorization for canvas objects
          eventHandler.useObjectAuthorization({
            isEditable: (obj) => { return obj.mediaAnnotationId === currentMediaAnnotationId }
          })

          if(!userId) throw 'User not defined'
          eventHandler.setUserId(userId.toString())
          eventHandler.setPageNumber(content.defaultPage)

          renderPage(content.defaultPage)
        }
      }

      return {

        pdfCanvas,
        viewport,

        currentPageNumber,
        eventHandler,
        state,
        content,

        dimensions,

        onClickPreviousPage,
        onClickNextPage,
        onClickZoomOut,
        onClickZoomIn,
        onObjectsChanged

      }
    },

  })
