export const enum Actions {
	CREATE_CONTRACTOR_BRANCH = 'CREATE_CONTRACTOR_BRANCH',
	DELETE_CONTRACTOR_BRANCH_BY_ID = 'DELETE_CONTRACTOR_BRANCH_BY_ID',
	EDIT_CONTRACTOR_BRANCH = 'EDIT_CONTRACTOR_BRANCH',
	FETCH_ALL_CONTRACTOR_BRANCHES = 'FETCH_ALL_CONTRACTOR_BRANCHES',
	FETCH_CONTRACTOR_TYPES_BY_QUERY = 'FETCH_CONTRACTOR_TYPES_BY_QUERY',
	APPEND_SEARCH_QUERY = 'APPEND_SEARCH_QUERY',
}

export const enum Getters {
	GET_ALL_BRANCHES = 'GET_ALL_BRANCHES'
}

export const enum Mutations {
	MUTATE_BRANCHES = 'MUTATE_BRANCHES',
	MUTATE_SEARCH_QUERY = 'MUTATE_SEARCH_QUERY'
}
