var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"caption project-quotes__header"},[_c('v-col',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"pb-0 pr-0"},[_vm._v(_vm._s(_vm.$t('dictionary.statusQuote')))]),_c('v-col',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"pb-0 text-sm-start",attrs:{"sm":"3"}},[_vm._v(_vm._s(_vm.$t('projects.details.quotes.contractor')))]),_c('v-col',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"pb-0 text-sm-center"},[_vm._v(_vm._s(_vm.$t('projects.details.quotes.offerRequested')))]),_c('v-col',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"pb-0 text-sm-center"},[_vm._v(_vm._s(_vm.$t('projects.details.quotes.offerDone')))]),_c('v-col',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"pb-0 text-sm-center"},[_vm._v(_vm._s(_vm.$t('projects.details.quotes.offerReceived')))]),_c('v-col',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"pb-0"},[_vm._v(_vm._s(_vm.$t('projects.details.comments.title')))]),_c('v-col',{attrs:{"sm":"1"}})],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-divider')],1)],1),(_vm.quotesCopy.length)?[_vm._l((_vm.quotesCopy),function(quote,idx){return [_c('v-row',{key:`${quote.id}-quote-row`,class:['mx-0 pl-10 pr-3 align-start project-quotes__row'],attrs:{"data-testid":"quote"}},[_c('v-col',{staticClass:"d-flex pa-2 pr-0"},[_c('div',{class:[
              'details-page__third--parties__type--container',
              {
                'gray--opaque': !quote.contractorTypeTempAssigned && !quote.contractorTypePermAssigned,
                'orange--opaque': quote.contractorTypeTempAssigned && !quote.contractorTypePermAssigned,
                'green--opaque': quote.contractorTypePermAssigned,
                'danger red--text': _vm.displayQuoteConfirmDialog(quote),
              },
            ]},[_c('span',[_vm._v(" "+_vm._s(quote.contractorType.abbreviation)+" ")]),_c('v-tooltip',{attrs:{"top":"","color":"rgba(3,7,48,1)","max-width":"500px","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({class:[
                    'button--assign-none ml-0 ml-lg-2',
                    { active: !quote.contractorTypeTempAssigned && !quote.contractorTypePermAssigned },
                  ],on:{"click":function($event){return _vm.onQuoteAssignmentToggle(quote, 'contractorType.none')}}},'button',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/projects/details/not-assigned.svg"),"alt":"Not assigned icon"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('projects.details.notAssigned')))])]),_c('v-tooltip',{attrs:{"top":"","color":"rgba(3,7,48,1)","max-width":"500px","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({class:[
                    'button--assign-temp',
                    'ml-1',
                    'ml-lg-2',
                    { active: quote.contractorTypeTempAssigned && !quote.contractorTypePermAssigned },
                  ],attrs:{"data-testid":"contractorTypeTempAssign"},on:{"click":function($event){return _vm.onQuoteAssignmentToggle(quote, 'contractorType.temp')}}},'button',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/projects/details/temp-assigned.svg"),"alt":"Temporarily assigned icon"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('projects.details.tempAssigned')))])]),_c('v-tooltip',{attrs:{"top":"","color":"rgba(3,7,48,1)","max-width":"500px","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({class:['button--assign-perm', 'ml-1', 'ml-lg-2', { active: quote.contractorTypePermAssigned }],attrs:{"data-testid":"contractorTypePermAssign"},on:{"click":function($event){return _vm.onQuoteAssignmentToggle(quote, 'contractorType.perm')}}},'button',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/projects/details/perm-assigned.svg"),"alt":"Permanently assigned icon"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('projects.details.permAssigned')))])])],1),(_vm.validationErrors && _vm.validationErrors.errors && _vm.validationErrors.errors.contractorTypePermAssigned
              && _vm.content.quote && _vm.content.quote.id === quote.id)?_c('div',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.validationErrors.errors.contractorTypePermAssigned[0])+" ")]):_vm._e(),(_vm.validationErrors && _vm.validationErrors.errors && _vm.validationErrors.errors.contractorTypeTempAssigned
              && _vm.content.quote && _vm.content.quote.id === quote.id)?_c('div',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.validationErrors.errors.contractorTypeTempAssigned[0])+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex pa-2 align-center justify-center",attrs:{"sm":"3"}},[(quote.contractor)?[_c('div',{staticClass:"project-quotes__supplier__container"},[_c('router-link',{class:[
                  { 'red--text font-weight-bold': _vm.displayQuoteConfirmDialog(quote) },
                  'project-quotes__supplier__router-link',
                ],attrs:{"to":{ name: 'supplierDetails', params: { id: quote.contractor.id } }}},[_vm._v(" "+_vm._s(quote.contractor.name || '-')+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:[
                    'details-page__third--parties__type--container',
                    {
                      'gray--opaque': !quote.contractorTempAssigned && !quote.contractorPermAssigned,
                      'orange--opaque': quote.contractorTempAssigned && !quote.contractorPermAssigned,
                      'green--opaque': quote.contractorPermAssigned,
                    },
                  ]},[_c('v-tooltip',{attrs:{"top":"","color":"rgba(3,7,48,1)","max-width":"500px","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({class:[
                          'button--assign-none ml-1 ml-lg-2 d-flex',
                          { active: !quote.contractorTempAssigned && !quote.contractorPermAssigned },
                        ],on:{"click":function($event){return _vm.onQuoteAssignmentToggle(quote, 'contractor.none')}}},'button',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/projects/details/not-assigned.svg"),"alt":"Not assigned icon"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('projects.details.notAssigned')))])]),_c('v-tooltip',{attrs:{"top":"","color":"rgba(3,7,48,1)","max-width":"500px","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({class:[
                          'button--assign-temp ml-1 ml-lg-2 d-flex',
                          { active: quote.contractorTempAssigned && !quote.contractorPermAssigned },
                        ],attrs:{"data-testid":"contractorTempAssign"},on:{"click":function($event){return _vm.onQuoteAssignmentToggle(quote, 'contractor.temp')}}},'button',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/projects/details/temp-assigned.svg"),"alt":"Temporarily assigned icon"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('projects.details.tempAssigned')))])]),_c('v-tooltip',{attrs:{"top":"","color":"rgba(3,7,48,1)","max-width":"500px","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({class:['button--assign-perm ml-1 ml-lg-2 d-flex', { active: quote.contractorPermAssigned }],attrs:{"data-testid":"contractorPermAssign"},on:{"click":function($event){return _vm.onQuoteAssignmentToggle(quote, 'contractor.perm')}}},'button',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/projects/details/perm-assigned.svg"),"alt":"Permanently assigned icon"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('projects.details.permAssigned')))])])],1),_c('button',{staticClass:"details-page__project__button-edit ml-1 ml-lg-2",on:{"click":function($event){return _vm.onQuoteSupplierSelect(quote)}}},[_c('img',{attrs:{"src":require("@/assets/edit-button.svg"),"alt":"edit icon"}})]),_c('div',{class:[
                    'details-page__confirm-button details-page__confirm-button--remove flex-shrink-0',
                    { 'details-page__confirm-button--remove--active': _vm.displayQuoteConfirmDialog(quote) },
                  ],on:{"click":function($event){return _vm.$emit('detachContractor', quote)}}})])],1)]:[_c('button',{class:[
                'button-save--inverted',
                'overflow-x-hidden',
                { 'danger font-weight-bold': _vm.displayQuoteConfirmDialog(quote) },
              ],staticStyle:{"width":"100%","max-width":"calc(100%)","text-overflow":"ellipsis"},on:{"click":function($event){return _vm.onQuoteSupplierSelect(quote)}}},[_vm._v(" "+_vm._s(_vm.$t('projects.details.quotes.addSupplier'))+" ")])]],2),_c('v-col',{staticClass:"d-flex justify-center pa-2"},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('button',_vm._g({class:[
                  quote.offerRequested ? 'project-quotes__date-button--filled' : 'project-quotes__date-button',
                  { danger: _vm.displayQuoteConfirmDialog(quote) },
                ]},on),[_vm._v(" "+_vm._s(quote.offerRequested ? quote.offerRequested : '+')+" ")])]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":"1","value":quote.offerRequested},on:{"change":function($event){return _vm.onEditQuoteDate(quote, $event, 'offerRequested')}}})],1)],1),_c('v-col',{staticClass:"d-flex justify-center pa-2"},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('button',_vm._g({class:[
                  quote.offerDone ? 'project-quotes__date-button--filled' : 'project-quotes__date-button',
                  { danger: _vm.displayQuoteConfirmDialog(quote) },
                ]},on),[_vm._v(" "+_vm._s(quote.offerDone ? quote.offerDone : '+')+" ")])]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":"1","value":quote.offerDone},on:{"change":function($event){return _vm.onEditQuoteDate(quote, $event, 'offerDone')}}})],1)],1),_c('v-col',{staticClass:"d-flex justify-center pa-2"},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('button',_vm._g({class:[
                  quote.offerReceived ? 'project-quotes__date-button--filled' : 'project-quotes__date-button',
                  { danger: _vm.displayQuoteConfirmDialog(quote) },
                ]},on),[_vm._v(" "+_vm._s(quote.offerReceived ? quote.offerReceived : '+')+" ")])]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":"1","value":quote.offerReceived},on:{"change":function($event){return _vm.onEditQuoteDate(quote, $event, 'offerReceived')}}})],1)],1),_c('v-col',{staticClass:"project-quotes__comment-section pa-2"},[(quote)?_c('app-textarea',{attrs:{"auto-grow":"","rows":"1","outlined":"","spellcheck":"false","hide-details":"","value":quote.comment},on:{"input":e => quote.comment = e,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return $event.target.blur()},"blur":e => _vm.editQuote(quote)}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-start mt-1 pa-2",attrs:{"sm":"1"}},[_c('div',{class:[
              'details-page__confirm-button details-page__confirm-button--remove mr-2',
              { 'details-page__confirm-button--remove--active': _vm.displayQuoteConfirmDialog(quote) },
            ],attrs:{"data-testid":"button--remove"},on:{"click":function($event){$event.stopPropagation();return _vm.onAttachedQuoteRemove(quote)}}})])],1),_c('confirm-dialog',{key:`${quote.id}-confirm-dialog`,staticClass:"mx-0",attrs:{"showDialog":_vm.displayQuoteConfirmDialog(quote),"title":_vm.isQuoteAlsoAnOrder(quote) ? 'confirmDialog.cannotRemoveQuote' : 'confirmDialog.removeQuote'},on:{"onCancel":function($event){_vm.state.removeQuote = false},"onRemove":_vm.onRemoveQuote},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.isQuoteAlsoAnOrder(quote))?[_c('div',{staticClass:"details-page__confirm-button details-page__confirm-button--cancel",attrs:{"data-testid":"button--cannot-remove-quote"},on:{"click":function($event){_vm.state.removeQuote = false}}})]:_vm._e()]},proxy:true}],null,true)})]})]:[_c('v-row',[_c('v-col',{staticClass:"text-sm-center accent--text",attrs:{"sm":"12","data-testid":"noQuotes"}},[_vm._v(" "+_vm._s(_vm.$t('projects.details.quotes.noQuotes'))+" ")])],1)],(_vm.project && _vm.content.quote)?_c('quote-modal',{attrs:{"showModal":_vm.state.addQuoteSupplier,"quote":_vm.content.quote,"project":_vm.project},on:{"update:showModal":function($event){return _vm.$set(_vm.state, "addQuoteSupplier", $event)},"update:show-modal":function($event){return _vm.$set(_vm.state, "addQuoteSupplier", $event)},"onQuoteAssignmentToggle":value => _vm.onQuoteAssignmentToggle(_vm.content.quote, value),"onQuoteSave":_vm.editQuote}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }