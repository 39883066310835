interface UseGoogleMapsAutocompleteOptions {
    fields?: Array<string>;
    autoCompleteOptions?: google.maps.places.AutocompleteOptions;
}

// composable
export default function useGoogleMapsAutocomplete(input: HTMLInputElement, handler: (placeResult: google.maps.places.PlaceResult, args?: any[]) => void, options: UseGoogleMapsAutocompleteOptions )
{
    // default options
    const defaults: UseGoogleMapsAutocompleteOptions = {
        fields: ['address_component'],
        autoCompleteOptions: {}
    }

    // set custom options
    const opts: UseGoogleMapsAutocompleteOptions = Object.assign({}, defaults, options)

    // init autocomplete
    const autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(input, opts.autoCompleteOptions);
    autocomplete.setFields(opts.fields)

    // add wrapper around default Places handler (becaude the default args are undefined, WHY?) 
    // pass in the `PlaceResult` after 'place changed'-event has fired
    const listener = autocomplete.addListener('place_changed', (args) => {
        handler(autocomplete.getPlace(), args)
    });

    // return
    return {
        autocomplete,
        listener,
    }
}