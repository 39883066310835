
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { debounce } from 'lodash';

  import { Supplier } from '@/modules/entities/types/entities';
  import { Actions, Getters } from '@/modules/entities/store/modules/suppliers/types/StoreTypes';
  import SuppliersSideMenuComponent from '@/modules/entities/components/suppliers/suppliers-sidemenu.component.vue';
  import { SupplierStateFilters } from '@/modules/entities/store/modules/suppliers/types/SupplierState';
  import SupplierModalComponent from '@/modules/entities/components/suppliers/supplier-modal.component.vue';
  import '@/styles/dialog.scss';
  import { GetAddressMixin } from '@/mixins/get-address-mixin.component';
  import { OneToFiveMixin } from '@/mixins/one-to-five-mixin.conponent';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import { AppMessageType } from '@/core/components/app-message/entities';

  const suppliers = namespace('entities/suppliers');

  @Component({
    mixins: [GetAddressMixin, OneToFiveMixin],
    components: {
      'suppliers-side-menu': SuppliersSideMenuComponent,
      SupplierModalComponent,
    },
  })
  export default class SuppliersComponent extends Vue {
    @suppliers.Action(Actions.FETCH_NEXT_PAGE) fetchNextPage!: () => void;
    @suppliers.Action(Actions.FETCH_SUPPLIERS_BY_FILTERS) fetchSuppliersByFilters!: () => void;
    @suppliers.Action(Actions.SET_SUPPLIERS_FILTER_MAIN_QUERY) setMainQueryFilter!: (payload: string) => void;
    @suppliers.Action(Actions.DELETE_SUPPLIER_BY_ID) deleteSupplierById!: (payload: number) => Promise<void>;
    @suppliers.Action(Actions.REVERT_LAST_ACTION) revertLastAction!: () => void;

    @suppliers.Getter(Getters.GET_SUPPLIER_FILTERS) suppliersFilter!: SupplierStateFilters;
    @suppliers.Getter(Getters.GET_FETCHED_SUPPLIERS) suppliers!: Supplier[];
    @suppliers.Getter(Getters.GET_LOADING) isLoading!: boolean;

    mainQueryValue: string = '';

    suppliersState = {
      delete: false,
      supplierModalOpen: false,
    };

    suppliersContent: {
      supplier: Supplier;
    } = {
      supplier: {} as Supplier,
    };

    headers = [
      {
        text: 'suppliers.details.attributes.companyName',
        iconPath: '/suppliers/table/icon-name.svg',
      },
      {
        text: 'suppliers.details.attributes.vatNumber',
      },
      {
        text: 'suppliers.details.attributes.address',
        iconPath: '/shared/address-icon.svg',
      },
      {
        text: 'suppliers.details.attributes.landlinePhone',
        iconPath: '/suppliers/table/icon-landline.svg',
      },
      {
        text: 'suppliers.details.attributes.mobilePhone',
        iconPath: '/suppliers/table/icon-mobile.svg',
      },
      {
        text: 'suppliers.details.attributes.email',
        iconPath: '/suppliers/table/icon-email.svg',
      },
      {
        text: 'suppliers.details.attributes.selection',
      },
      {
        text: 'dictionary.quotes',
      },
      {
        text: 'dictionary.ordered',
      },
      {
        text: 'suppliers.details.attributes.contractorTypes',
      },
    ];

    onMainSearchValueChange = debounce((value: string) => {
      this.setMainQueryFilter(value);
    }, 200);

    onClearFilters(): void {
      this.mainQueryValue = '';
    }

    onDeleteSupplier(supplier: Supplier): void {
      this.resetSuppliersContent();
      this.suppliersState.delete = true;
      this.suppliersContent.supplier = supplier;
    }

    deleteSupplier(): void {
      if (this.suppliersContent.supplier.id > 0) {
        this.deleteSupplierById(this.suppliersContent.supplier.id).catch(() => {
          appMessageStore.actions.set({
            message: 'suppliers.messages.deleteError',
            ttl: 7500,
            dismissed: false,
            type: AppMessageType.ERROR,
          });
        });
      }
    }

    onEditSupplier(supplier: Supplier): void {
      this.resetSuppliersContent();
      this.suppliersContent.supplier = JSON.parse(JSON.stringify(supplier));
      this.suppliersState.supplierModalOpen = true;
    }

    navigateToSupplierDetails(supplier: Supplier, newTab = false): void {
      if (newTab) {
        let route = this.$router.resolve({ name: 'supplierDetails', params: { id: String(supplier.id) } });
        window.open(route.href, '_blank');
      } else {
        this.$router.push({ name: 'supplierDetails', params: { id: String(supplier.id) } });
      }
    }

    resetSuppliersContent(): void {
      this.suppliersContent = {
        supplier: {
          id: 0,
          name: '',
          commercialName: '',
          vatNumber: '',
          ssrNumber: '',
          bankAccounts: [
            {
              id: 0,
              accountNumber: '',
              isMain: true,
            },
          ],
          price: 0,
          priceComment: '',
          quality: 0,
          qualityComment: '',
          technicalExpertise: 0,
          aestheticQuality: 0,
          aestheticQualityComment: '',
          workforce: '',
          activities: '',
          speciality: '',
          province: null,
          parentSupplierId: null,
          comment: '',
          street: '',
          streetNumber: '',
          postalCode: '',
          city: '',
          outboundKm: 0,
          preferredPaymentTerm: 0,
          preferredPaymentTermBoolean: false,
          preferredPaymentDiscountTerm: 0,
          preferredPaymentDiscountTermBoolean: false,
          preferredPaymentDiscountPercentage: 0,
          futureWork: 0,
          futureWorkComment: '',
          contacts: [],
          companyTypes: [],
          contractorTypes: [],
          teleinformations: [
            {
              id: 0,
              landlinePhone: '',
              landlinePhoneComment: '',
              mobilePhone: '',
              mobilePhoneComment: '',
              email: '',
              emailComment: '',
              isMainContactInformation: true,
              user: undefined,
              updatedAt: '',
            },
            {
              id: 0,
              landlinePhone: '',
              landlinePhoneComment: '',
              mobilePhone: '',
              mobilePhoneComment: '',
              email: '',
              emailComment: '',
              isMainContactInformation: false,
              user: undefined,
              updatedAt: '',
            },
          ],
          website: '',
          createdAt: '',
          updatedAt: '',
          retainments: [],
        },
      };
    }

    created(): void {
      this.fetchSuppliersByFilters();
    }

    loadNextPage(): void {
      this.fetchNextPage();
    }
  }
