
	import { PropType } from 'vue'
	import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { debounce } from 'lodash';
	import { minValue, numeric, required } from 'vuelidate/lib/validators';
	import { AccountingItem, AccountingItemEntity, TransactionType } from '@/modules/purchase-invoices/types/entities';
	import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
	import Loading from '@/core/components/loading/loading.component.vue';
	import TaxAutocompleteComponent from '@/modules/settings/store/modules/taxes/components/tax-autocomplete/tax-autocomplete.component.vue';
	import { Tax } from '@/modules/settings/types/entities';
	import { AxiosError, AxiosResponse } from 'axios';
	import { JsonResource } from '@/core/types/Entities';
	import { calculateGrandTotalForAccountingItem, calculateTaxTotalForAccountingItem } from '../../helpers';
	import { Actions as AccountingActions, Getters as AccountingGetters } from '@/modules/accounting/store/types/StoreTypes';
	import { AccountingLedgerItem, AccountingLedger } from '@/modules/accounting/types/entities';
	import AccountingLedgerItemAutocompleteComponent from '@/modules/accounting/components/accounting-ledger-item-autocomplete/accounting-ledger-item-autocomplete.component.vue';

	const purchaseInvoices = namespace('purchaseInvoices');
	const accounting = namespace('accounting');

	@Component({
      components: {
        'tax-autocomplete': TaxAutocompleteComponent,
        'accounting-ledger-item-autocomplete': AccountingLedgerItemAutocompleteComponent,
      },
      props: {
        currentAccountingLedger: {
          type: Object as PropType<AccountingLedger|undefined>,
          default: undefined
        }
      },
      setup(props, ctx) {

      }
	})
	export default class AccountingItemUpdateModalComponent extends Vue {

		// actions
		@purchaseInvoices.Action(Actions.SAVE_ACCOUNTING_ITEM) saveAccountingItem!: (payload: AccountingItem) => Promise<AxiosResponse<JsonResource<AccountingItem>>>;

		// getters
		@purchaseInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;

		// props
		@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
		@PropSync('accountingItem', { type: Object as PropType<AccountingItem> }) accountingItemSync!: AccountingItem;
		
		// vars
		state: {
			accountingItemSaving: boolean
			accountingItemSaved: boolean
		} = {...this.newState()}
		content: {
			accountingItemValidationErrors: any,
        } = {...this.newContentState()}

		// handlers
		onClickClose(value: any): void {
			this.closeModal();
			this.content = { ...this.newContentState() }
			this.state = { ...this.newState() }
		}
		onClickSave() {
			this.state = {...this.state, accountingItemSaving: true }

			if(this.accountingItemSync) {
				// save
				const result = this.save(this.accountingItemSync).then((v) => {
					// update state
					this.content = { ...this.newContentState() }
					this.state = { ...this.newState() }
					this.accountingItemSync = {...(new AccountingItemEntity)}
					this.closeModal()
				})
			}
		}
		onInputTotal(total: number) {
			const accountingItem = {...this.accountingItemSync } as AccountingItem
			accountingItem.subTotal = total
			accountingItem.total = total
			accountingItem.taxTotal = accountingItem ? calculateTaxTotalForAccountingItem(accountingItem) : 0
            accountingItem.grandTotal = accountingItem ? calculateGrandTotalForAccountingItem(accountingItem) : 0
            this.accountingItemSync = {...this.accountingItemSync, subTotal: accountingItem.total, total: accountingItem.total, taxTotal: accountingItem.taxTotal, grandTotal: accountingItem.grandTotal }
        }
		onInputTaxTotal(taxTotal: number) {
			const accountingItem = {...this.accountingItemSync } as AccountingItem
			accountingItem.taxTotal = taxTotal
            accountingItem.grandTotal = accountingItem ? calculateGrandTotalForAccountingItem(accountingItem) : 0
            this.accountingItemSync = {...this.accountingItemSync, taxTotal: accountingItem.taxTotal, grandTotal: accountingItem.grandTotal }
        }
		onChangeVat(vat: Tax) {
			const accountingItem = {...this.accountingItemSync } as AccountingItem
			accountingItem.vat = vat
			accountingItem.taxTotal = accountingItem ? calculateTaxTotalForAccountingItem(accountingItem) : 0
			accountingItem.grandTotal = accountingItem ? calculateGrandTotalForAccountingItem(accountingItem) : 0
			this.accountingItemSync = {...this.accountingItemSync, vat: accountingItem.vat, taxTotal: accountingItem.taxTotal, grandTotal: accountingItem.grandTotal }
		}
		onChangeAccountingLedgerItem(accountingLedgerItem: AccountingLedgerItem) {
			const accountingItem = {...this.accountingItemSync } as AccountingItem
			const defaultVat = accountingLedgerItem.defaultVat ? accountingLedgerItem.defaultVat : (accountingLedgerItem.taxes && accountingLedgerItem.taxes.length ? accountingLedgerItem.taxes[0] : null)
			const type = accountingLedgerItem.defaultTransactionType ? accountingLedgerItem.defaultTransactionType : TransactionType.DEBIT
			accountingItem.accountingLedgerItem = accountingLedgerItem
			accountingItem.vat = defaultVat
			accountingItem.type = type
			accountingItem.taxTotal = accountingItem ? calculateTaxTotalForAccountingItem(accountingItem) : 0
            accountingItem.grandTotal = accountingItem ? calculateGrandTotalForAccountingItem(accountingItem) : 0
			this.accountingItemSync = {...this.accountingItemSync, accountingLedgerItem: accountingItem.accountingLedgerItem, vat: accountingItem.vat, type: accountingItem.type, taxTotal: accountingItem.taxTotal, grandTotal: accountingItem.grandTotal }
		}

		// methods
		save(item: AccountingItem) {
			return new Promise<AxiosError|AxiosResponse>((resolve, reject) => {
				let accountingItem = item ? {...item} : null
			
				if(accountingItem) {
					this.saveAccountingItem(accountingItem)
						.then((result: AxiosResponse<JsonResource<AccountingItem>>) => {
							resolve(result)
						})
						.catch((err: AxiosError) => {
							if (err.response && err.response.status === 422) {
								this.content = { ...this.content, accountingItemValidationErrors: err.response.data.errors }
							} else {
								this.state = {...this.state, accountingItemSaved: false, accountingItemSaving: false }
								throw err;
							}
							reject(err)
						});
				}
			})
		}
		closeModal() {
			this.showModalSync = false
		}
		newContentState() {
			return {
				accountingItemValidationErrors: {},
			}
		}
		newState() {
			return {
				accountingItemSaved: false,
				accountingItemSaving: false
			}
		}

		// getters
		get availableVatTaxes() {
			const taxes = this.accountingItemSync && this.accountingItemSync.accountingLedgerItem && this.accountingItemSync.accountingLedgerItem.taxes || [] as Tax[];
			return taxes;
		}

		// watchers

		// lifecycle
	}
