import Vue from 'vue';
import { InvoiceState } from '@/modules/purchase-invoices/store/types/InvoiceState';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/core/types/RootState';
import { Actions, Mutations, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
import applyFilter from '@/core/helpers/Filter';
import { AxiosResponse } from 'axios';
import { PurchaseInvoice, AccountingItem, ProjectRelationship, MediaAnnotatatable } from '@/modules/purchase-invoices/types/entities';
import { Filter, FilterState } from '@/core/types/Filter';
import { Project } from '@/modules/projects/types/entities';
import { PurchaseInvoicesCommandsService } from '@/modules/purchase-invoices/services/purchase-invoices-commands.service';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { PurchaseInvoicesService } from '@/modules/purchase-invoices/services/purchase-invoices.service';
import { Comment, MediaAnnotation, Supplier } from '@/modules/entities/types/entities';
import { Payment } from '@/modules/payments/types/entities';
import { SuppliersService } from '@/modules/entities/services/suppliers/suppliers.service';
import { ReportsService } from '@/modules/reports/services/reports.service';

const namespace = 'purchaseInvoices';
const purchaseInvoiceCommandsService = new PurchaseInvoicesCommandsService();
const purchaseInvoicesService = new PurchaseInvoicesService();
const reportsService = new ReportsService();
const purchaseInvoiceMorph = 'purchase_invoice';

export const actions: ActionTree<InvoiceState, RootState> = {

  [Actions.FETCH_LISTVIEW_INVOICES]: async({state, dispatch, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_LISTVIEW_IS_LOADING, true);

    try {
      const filterUrl = applyFilter(state.listviewFilterState);
      const url = '/api/v1/purchase-invoices' + (filterUrl ? '?'+filterUrl : '');

      const result = await purchaseInvoicesService.getAllInvoices(url);
      commit(Mutations.MUTATE_LISTVIEW_LINKS, result.data.links);
      commit(Mutations.MUTATE_LISTVIEW_META, result.data.meta);
      commit(Mutations.MUTATE_LISTVIEW_INVOICES, result.data.data);

      // when reporting summary is shown, automatically retrieve report results to update summary
      if(state.invoicesShowSummaryReport) await dispatch(Actions.FETCH_INVOICES_SUMMARY_REPORT);
      if(state.invoicesShowMetricsReport) await dispatch(Actions.FETCH_INVOICES_METRICS_REPORT);

    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_LISTVIEW_IS_LOADING, false);
    }
  },
  [Actions.FETCH_LISTVIEW_NEXT_PAGE]: async ({ commit, state }: ActionContext<InvoiceState, RootState>) => {
    const listviewIsLoading = state.listviewIsLoading;
    if(!listviewIsLoading) {
      commit(Mutations.MUTATE_LISTVIEW_IS_LOADING, true);
      try {
        if (state.listviewLinks && state.listviewLinks.next) {
          const url = state.listviewLinks.next;
          const result = await purchaseInvoicesService.getAllInvoices(url);
          commit(Mutations.MUTATE_LISTVIEW_LINKS, result.data.links);
          commit(Mutations.MUTATE_LISTVIEW_META, result.data.meta);
          commit(Mutations.MUTATE_LISTVIEW_PUSH_NEXT_PAGE, { links: result.data.links, invoices: result.data.data });
        }
      } catch (err: any) {
        console.warn(err);
        throw err;
      } finally {
        commit(Mutations.MUTATE_LISTVIEW_IS_LOADING, false);
      }
    }
  },
  [Actions.FETCH_STATUSES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const result = await purchaseInvoicesService.getAllStatuses();
      commit(Mutations.MUTATE_STATUSES, result.data.data);
    } catch (err: any) {
      //
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_APPROVAL_STATUSES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const result = await purchaseInvoicesService.getAllApprovalStatuses();
      commit(Mutations.MUTATE_APPROVAL_STATUSES, result.data.data);
    } catch (err: any) {
      //
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_PROJECT_RELATIONSHIP_STATUSES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const result = await purchaseInvoicesService.getAllProjectRelationshipStatuses();
      commit(Mutations.MUTATE_PROJECT_RELATIONSHIP_STATUSES, result.data.data);
    } catch (err: any) {
      //
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_PAYMENT_STATUSES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const result = await purchaseInvoicesService.getAllPaymentStatuses();
      commit(Mutations.MUTATE_PAYMENT_STATUSES, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_ACCOUNTING_STATUSES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const result = await purchaseInvoicesService.getAllAccountingStatuses();
      commit(Mutations.MUTATE_ACCOUNTING_STATUSES, result.data.data);
    } catch(err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_INVOICE_BY_ID]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    // commit(Mutations.MUTATE_INVOICE, null);

    try {
      const result = await purchaseInvoicesService.getInvoiceById(Number.parseInt(payload));
      commit(Mutations.MUTATE_INVOICE, result.data.data);
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_RECORD_TYPES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const result = await purchaseInvoicesService.getAllRecordTypes();
      commit(Mutations.MUTATE_RECORD_TYPES, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_NEW]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const result = await purchaseInvoiceCommandsService.getNewInvoice();
      commit(Mutations.MUTATE_NEW, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.SAVE_INVOICE]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: PurchaseInvoice) => {

    // has ids?
    const purchaseInvoiceId = (payload.id !== undefined && payload.id) || undefined;

    // try {
    let result: AxiosResponse<JsonResource<PurchaseInvoice>> | null = null;

    // create
    if(!purchaseInvoiceId) {
      result = await purchaseInvoicesService.createInvoice(payload);
      commit(Mutations.MUTATE_INVOICE, result.data.data);
      dispatch(Actions.APPEND_TO_INVOICES, result.data.data);
    }
    // update
    else {
      result = await purchaseInvoicesService.updateInvoiceById(payload);
      commit(Mutations.MUTATE_INVOICE, result.data.data);
    }

    return result.data.data;
  },
  [Actions.APPEND_TO_INVOICES]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: PurchaseInvoice) => {
    const invoices = [payload,...state.listviewInvoices.slice()];
    commit(Mutations.MUTATE_APPEND_TO_INVOICES, invoices);
  },
  [Actions.DELETE_INVOICE]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: PurchaseInvoice) => {
    const purchaseInvoiceId = (payload && payload.id !== undefined && payload.id) || undefined;
    try {
      if(purchaseInvoiceId) {
        await purchaseInvoicesService.deleteInvoiceById(purchaseInvoiceId);
        const invoices = state.listviewInvoices.filter(invoice => invoice.id !== purchaseInvoiceId);
        commit(Mutations.MUTATE_LISTVIEW_INVOICES, invoices);
      }
    } catch (err: any) {
      //
    }
  },
  [Actions.SAVE_ACCOUNTING_ITEM]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: AccountingItem) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has ids?
    const accountingItemId = (payload.id !== undefined && payload.id);
    let items: AccountingItem[] = [];
    let result: null|AxiosResponse<JsonResource<AccountingItem>> = null;

    try {
      // create
      if(!accountingItemId) {
        result = await purchaseInvoicesService.createAccountingItem(payload);
        // add item to list on invoice
        if(state.invoice && state.invoice.accountingItems) {
          items = [...state.invoice.accountingItems, result.data.data];
        }
      }
      // update
      else {
        result = await purchaseInvoicesService.updateAccountingItemById(payload);
        const accountingItem = result.data.data;
        // edit item in list on invoice
        if(state.invoice && state.invoice.accountingItems) {
          items = state.invoice.accountingItems.map((item: AccountingItem) => item.id !== accountingItem.id ? item : {...accountingItem});
        }
      }

      // set related state
      const accountingItem = result.data.data;
      const invoice = {
        ...state.invoice,
        accountingStatus: accountingItem && accountingItem.purchaseInvoice ? accountingItem.purchaseInvoice.accountingStatus : null,
        accountingItems: items,
        recalculateAccountingItems: accountingItem && accountingItem.purchaseInvoice ? accountingItem.purchaseInvoice.recalculateAccountingItems : false,
      };
      commit(Mutations.MUTATE_INVOICE, invoice);

      return result;

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.CREATE_INVOICE]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: PurchaseInvoice) => {
    commit(Mutations.MUTATE_INVOICE, payload);
  },
  [Actions.DELETE_ACCOUNTING_ITEM]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: AccountingItem) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    const accountingItemId = (payload.id !== undefined && payload.id) || undefined;

    try {
      if(accountingItemId) {
        const result = await purchaseInvoicesService.deleteAccountingItemById(accountingItemId);

        if(state.invoice && state.invoice.accountingItems) {
          const items = state.invoice.accountingItems.filter((value: AccountingItem) => value.id !== accountingItemId);
          const invoice = {
            ...state.invoice,
            accountingStatus: result.data.data.purchaseInvoice ? result.data.data.purchaseInvoice.accountingStatus : state.invoice.accountingStatus,
            accountingItems: items,
          };
          commit(Mutations.MUTATE_INVOICE, invoice);
        }
      }
    } catch(err: any) {
      console.warn(err);
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.DELETE_PROJECT_RELATIONSHIP]: async ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: ProjectRelationship ) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    const projectRelationshipId = (payload.id !== undefined && payload.id) || undefined;

    try {
      if(projectRelationshipId) {
        const result = await purchaseInvoicesService.deleteProjectRelationshipById(projectRelationshipId);
        const projectRelationship = result.data.data;

        if(state.invoice && state.invoice.projectRelationships) {
          const invoice = {
            ...state.invoice,
            projectRelationships: projectRelationship.purchaseInvoice && projectRelationship.purchaseInvoice.projectRelationships ? projectRelationship.purchaseInvoice.projectRelationships : state.invoice.projectRelationships,
            status: projectRelationship.purchaseInvoice ? projectRelationship.purchaseInvoice.status : state.invoice.status,
            approvalStatus: projectRelationship.purchaseInvoice ? projectRelationship.purchaseInvoice.approvalStatus : state.invoice.approvalStatus,
            accountingStatus: projectRelationship.purchaseInvoice ? projectRelationship.purchaseInvoice.accountingStatus : state.invoice.accountingStatus,
            accountingItems: projectRelationship.purchaseInvoice ? projectRelationship.purchaseInvoice.accountingItems : state.invoice.accountingItems,
          };
          commit(Mutations.MUTATE_INVOICE, invoice);
        }
      }
    } catch (err: any) {
      // TODO: proper delete error message
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.DELETE_PAYMENT]: async ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: {invoiceId: string; paymentId: string} ) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const result = await purchaseInvoicesService.deletePaymentById(Number.parseInt(payload.invoiceId), Number.parseInt(payload.paymentId));
      const items = state.invoice && state.invoice.payments ? [...state.invoice.payments] : [] as Payment[];

      if(state.invoice && state.invoice.payments) {
        const index = items.findIndex(payment => payment.id === Number.parseInt(payload.paymentId));
        items.splice(index, 1);
      }

      // invoice data to be updated
      const paymentStatus = result.data.data.invoice ? result.data.data.invoice.paymentStatus : null;
      const invoice = { ...state.invoice, payments: items, paymentStatus: paymentStatus };
      commit(Mutations.MUTATE_INVOICE, invoice);
    } catch (err: any) {
      // TODO: proper delete error message
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_ACCOUNTING_ITEM_BY_ID]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await purchaseInvoicesService.getAccountingItemById(Number.parseInt(payload));
      return result.data.data;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_PROJECT_RELATIONSHIP_BY_ID]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await purchaseInvoicesService.getProjectRelationshipById(Number.parseInt(payload));
      return result.data.data;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_MEDIA_ANNOTATIONS_BY_MEDIA_ID]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_MEDIA_ANNOTATIONS, null);
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await purchaseInvoicesService.getMediaAnnotations(Number.parseInt(payload));
      commit(Mutations.MUTATE_MEDIA_ANNOTATIONS, result.data.data);
      return result;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_MEDIA_ANNOTATION_FOR_INVOICE_PDF]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: {invoiceId: string; mediaId: string}) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await purchaseInvoicesService.getInvoiceMediaAnnotation(Number.parseInt(payload.invoiceId), Number.parseInt(payload.mediaId));
      const pdf = state.invoice && state.invoice.pdf;
      if(pdf) {
        const mediaAnnotation = result.data.data;
        let pdfAnnotations = pdf.annotations && pdf.annotations.length ? [...pdf.annotations] : [] as MediaAnnotation[];
        const index = pdfAnnotations.findIndex(a => a.id === mediaAnnotation.id);
        if(index === -1) pdfAnnotations = [...pdfAnnotations, mediaAnnotation];
        else pdfAnnotations.splice(index, 1, mediaAnnotation);
        commit(Mutations.MUTATE_INVOICE, {...state.invoice, pdf: {...pdf,
          annotations: pdfAnnotations,
          annotationsCount: pdfAnnotations.filter(a => a.annotation && a.annotation.length).length,
        }});
      }
      return result;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_MEDIA_ANNOTATION_FOR_PROJECT_RELATIONSHIP]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: {projectRelationshipId: string; mediaId: string}) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await purchaseInvoicesService.getProjectRelationshipMediaAnnotation(Number.parseInt(payload.projectRelationshipId), Number.parseInt(payload.mediaId));
      return result;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.SAVE_MEDIA_ANNOTATION]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: {mediaAnnotation: MediaAnnotation; mediaId: string; annotatableId: string; annotatableType: MediaAnnotatatable}) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has ids?
    const mediaAnnotationId = payload.mediaAnnotation && (payload.mediaAnnotation.id !== undefined && payload.mediaAnnotation.id) || undefined;
    let result: AxiosResponse<JsonResource<MediaAnnotation>> | undefined = undefined;

    try {
      // create
      if(!mediaAnnotationId) {
        // mediaAnnotations are first created on retrieval
      }
      // update
      else {
        switch(payload.annotatableType) {
        case MediaAnnotatatable.PROJECT_RELATIONSHIP:
          result = await purchaseInvoicesService.updateProjectRelationshipMediaAnnotationById(Number.parseInt(payload.annotatableId), Number.parseInt(payload.mediaId), payload.mediaAnnotation);
          break;
        case MediaAnnotatatable.PURCHASE_INVOICE: {
          result = await purchaseInvoicesService.updateInvoiceMediaAnnotationById(Number.parseInt(payload.annotatableId),Number.parseInt(payload.mediaId),payload.mediaAnnotation);
          const pdf = state.invoice && state.invoice.pdf;
          if(pdf) {
            const mediaAnnotation = result.data.data;
            let pdfAnnotations = pdf.annotations && pdf.annotations.length ? [...pdf.annotations] : [] as MediaAnnotation[];
            const index = pdfAnnotations.findIndex(a => a.id === mediaAnnotation.id);
            if(index === -1) pdfAnnotations = [...pdfAnnotations, mediaAnnotation];
            else pdfAnnotations.splice(index, 1, mediaAnnotation);
            commit(Mutations.MUTATE_INVOICE, {...state.invoice, pdf: {...pdf,
              annotations: pdfAnnotations,
              annotationsCount: pdfAnnotations.filter(a => a.annotation && a.annotation.length).length,
            }});
          }
          break;
        }
        default:
          break;
        }
      }

      // result
      return result;

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.SAVE_PAYMENT]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: {invoiceId: string; payment: Payment}) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has id?
    const paymentId = payload.payment && (payload.payment.id !== undefined && payload.payment.id) || undefined;
    let result: AxiosResponse<JsonResource<Payment>> | undefined = undefined;
    let items = state.invoice && state.invoice.payments ? [...state.invoice.payments] : [] as Payment[];

    try {
      // create
      if(!paymentId) {
        result = await purchaseInvoicesService.createPayment(Number.parseInt(payload.invoiceId), payload.payment);

        // add item to list on invoice
        if(state.invoice && state.invoice.payments) {
          items = [...state.invoice.payments, result.data.data];
        }
      }
      // update
      else {
        result = await purchaseInvoicesService.updatePaymentById(Number.parseInt(payload.invoiceId), payload.payment);

        // edit item in list on invoice
        if(state.invoice && state.invoice.payments && !!result) {
          const payment = result.data.data;
          items = state.invoice.payments && state.invoice.payments.length ? [...state.invoice.payments] : [] as Payment[];
          const index = items.findIndex(item => item.id === payment.id);
          items.splice(index === -1 ? 0 : index, index === -1 ? 0 : 1, {...result.data.data});
        }
      }

      // invoice data to be updated
      const paymentStatus = result.data.data.invoice ? result.data.data.invoice.paymentStatus : null;

      const invoice = { ...state.invoice, payments: items, paymentStatus: paymentStatus } as PurchaseInvoice;
      commit(Mutations.MUTATE_INVOICE, invoice);

      // result
      return result;

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.SAVE_PAYMENT_ON_LISTVIEW_INVOICES]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: {invoiceId: string; payment: Payment}) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has id?
    const paymentId = payload.payment && (payload.payment.id !== undefined && payload.payment.id) || undefined;
    let result: AxiosResponse<JsonResource<Payment>> | undefined = undefined;
    const items = state.listviewInvoices ? [...state.listviewInvoices] : [] as PurchaseInvoice[];

    try {
      // create
      if(!paymentId) {
        result = await purchaseInvoicesService.createPayment(Number.parseInt(payload.invoiceId), payload.payment);

        // invoice data to be updated
        const paymentStatus = result.data.data.invoice ? result.data.data.invoice.paymentStatus : null;

        // update invoice in listview
        const index = items.findIndex(invoice => invoice.id === Number.parseInt(payload.invoiceId));
        if(index > -1 && paymentStatus) {
          items.splice(index, 1, {...items[index], paymentStatus: paymentStatus} as PurchaseInvoice);
        }
      }

      commit(Mutations.MUTATE_LISTVIEW_INVOICES, items);

      // result
      return result;

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.SAVE_PROJECT_RELATIONSHIP]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: ProjectRelationship) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has ids?
    const projectRelationshipId = (payload.id !== undefined && payload.id) || undefined;
    let result: null|AxiosResponse<JsonResource<ProjectRelationship>>;
    let items: ProjectRelationship[] = [];

    try {
      // create
      if(!projectRelationshipId) {
        result = await purchaseInvoicesService.createProjectRelationship(payload);
        // add item to list on invoice
        if(state.invoice && state.invoice.projectRelationships) {
          items = [...state.invoice.projectRelationships, result.data.data];
        }
      }
      // update
      else {
        result = await purchaseInvoicesService.updateProjectRelationshipById(payload);
        // edit item in list on invoice
        if(state.invoice && state.invoice.projectRelationships) {
          items = state.invoice.projectRelationships && state.invoice.projectRelationships.length ? [...state.invoice.projectRelationships] : [] as ProjectRelationship[];
          const projectRelationship = result.data.data;
          const index = items.findIndex(item => item.id === projectRelationship.id);
          items.splice(index === -1 ? 0 : index, index === -1 ? 0 : 1, {...result.data.data});// = state.invoice.projectRelationships.map((item: ProjectRelationship) => item.id !== result.data.data.id ? item : {...result.data.data});
        }
      }

      // update related state
      const projectRelationship = result.data.data;
      const invoice = {
        ...state.invoice,
        projectRelationships: items,
        status: projectRelationship && projectRelationship.purchaseInvoice ? projectRelationship.purchaseInvoice.status : null,
        approvalStatus: projectRelationship && projectRelationship.purchaseInvoice ? projectRelationship.purchaseInvoice.approvalStatus : null,
        accountingStatus: projectRelationship && projectRelationship.purchaseInvoice ? projectRelationship.purchaseInvoice.accountingStatus : null,
        accountingItems: projectRelationship && projectRelationship.purchaseInvoice ? projectRelationship.purchaseInvoice.accountingItems : null,
      } as PurchaseInvoice;
      commit(Mutations.MUTATE_INVOICE, invoice);

      return result;

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }

    return result;
  },
  [Actions.SAVE_COMMENT_TO_PURCHASE_INVOICE]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: Comment) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has ids?
    const commentId = (payload.id !== undefined && payload.id) || undefined;
    let result: AxiosResponse<JsonResource<Comment>>;

    try {
      // create
      if(!commentId) {
        const newComment = { ...payload, commentableType: purchaseInvoiceMorph } as Comment;
        result = await Vue.prototype.$http.post(`/api/v1/comments`, newComment);

        // add item to list on invoice
        if(state.invoice && state.invoice.comments) {
          const invoice = { ...state.invoice, comments: [...state.invoice.comments, result.data.data] };
          commit(Mutations.MUTATE_INVOICE, invoice);
        }
      }
      // update
      else {
        result = await Vue.prototype.$http.put(`/api/v1/comments/${ payload.id }`, payload);

        // edit item in list on invoice
        if(state.invoice && state.invoice.comments) {
          const items = state.invoice.comments.map((item: Comment) => item.id !== result.data.data.id ? item : {...result.data.data});
          const invoice = { ...state.invoice, comments: items };
          commit(Mutations.MUTATE_INVOICE, invoice);
        }
      }

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }

    return result;
  },
  [Actions.DELETE_COMMENT]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: Comment) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await Vue.prototype.$http.delete(`/api/v1/comments/${ payload.id }`);

      if (state.invoice && state.invoice.comments) {
        const items = state.invoice.comments.filter(x => x.id !== payload.id);
        const invoice = {...state.invoice, comments: items};
        commit(Mutations.MUTATE_INVOICE, invoice);
      }
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.UPLOAD_PDF]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: {
        purchaseInvoiceId: number;
        files: Array<{name: string; content: string|ArrayBuffer|null}>;
    }) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      // const files = await this.prepareFilesForUpload(this.content.files);
      const url = `/api/v1/purchase-invoices/${ payload.purchaseInvoiceId }/media`;

      // 1 file upload
      const file = payload.files[0];
      const result: AxiosResponse = await Vue.prototype.$http.post(url, {file : file});

      if(state.invoice) {
        commit(Mutations.MUTATE_INVOICE, {...state.invoice, pdf: result.data.data});
      }
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.DELETE_PDF]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: {purchaseInvoiceId: number; mediaId: number}) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await Vue.prototype.$http.delete(`/api/v1/purchase-invoices/${ payload.purchaseInvoiceId }/media/${ payload.mediaId }`);
      commit(Mutations.MUTATE_INVOICE, {...state.invoice, pdf: null});

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_INVOICES_SUMMARY_REPORT]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const filterUrl = applyFilter(state.listviewFilterState);
      const url = '/api/v1/reports/purchase-invoices-summary' + (filterUrl ? '?'+filterUrl : '');
      const result = await reportsService.getReport(url);
      commit(Mutations.MUTATE_INVOICES_SUMMARY_REPORT, result.data.data);
      return result.data.data;
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_INVOICES_METRICS_REPORT]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const filterUrl = applyFilter(state.listviewFilterState);
      const url = '/api/v1/reports/purchase-invoices-metrics' + (filterUrl ? '?'+filterUrl : '');
      const result = await reportsService.getReport(url);
      commit(Mutations.MUTATE_INVOICES_METRICS_REPORT, result.data.data);
      return result.data.data;
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.TOGGLE_SHOW_INVOICES_SUMMARY_REPORT]: async({state, commit, dispatch}: ActionContext<InvoiceState, RootState>) => {
    if(state.invoicesShowSummaryReport) commit(Mutations.MUTATE_INVOICES_SHOW_SUMMARY_REPORT, false);
    else {
      await dispatch(Actions.FETCH_INVOICES_SUMMARY_REPORT);
      commit(Mutations.MUTATE_INVOICES_SHOW_SUMMARY_REPORT, true);
    }
  },
  [Actions.TOGGLE_SHOW_INVOICES_METRICS_REPORT]: async({state, commit, dispatch}: ActionContext<InvoiceState, RootState>) => {
    if(state.invoicesShowMetricsReport) commit(Mutations.MUTATE_INVOICES_SHOW_METRICS_REPORT, false);
    else {
      await dispatch(Actions.FETCH_INVOICES_METRICS_REPORT);
      commit(Mutations.MUTATE_INVOICES_SHOW_METRICS_REPORT, true);
    }
  },

  // search
  [Actions.SEARCH_PROJECTS]: async({state, commit}: ActionContext<InvoiceState, RootState>, payload: string) => {
    const filterState = {
      filters: {
        search: new Filter<string>(
          'search',
          'query',
                    [payload] as string[],
                    true,
                    'search',
                    null
        ),
      },
    } as FilterState;
    const filterUrl = applyFilter(filterState);
    const url = '/api/v1/projects' + (filterUrl ? '?'+filterUrl : '');
    const client = Vue.prototype.$http;
    await client.get(url).then((response: AxiosResponse<JsonResource<Project[]>>) => {
      if(response.status === 200){
        commit(Mutations.MUTATE_SEARCH_PROJECTS, response.data.data);
      }
    });
  },

  // listview filters
  [Actions.TOGGLE_LISTVIEW_FILTER_SIDEBAR]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: boolean) => {
    commit(Mutations.MUTATE_TOGGLE_LISTVIEW_FILTER_SIDEBAR, payload);
  },
  [Actions.TOGGLE_LISTVIEW_FILTER_GROUP]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: {group: string|undefined; fetch?: boolean}) => {
    const newPayload = {group: undefined, fetch: true};
    Object.assign(newPayload, payload);

    commit(Mutations.MUTATE_TOGGLE_LISTVIEW_FILTER_GROUP, newPayload.group);
    if(newPayload.fetch) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.TOGGLE_LISTVIEW_FILTER]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: {filterName: string|undefined; fetch?: boolean}) => {
    const newPayload = {filterName: undefined, fetch: true};
    Object.assign(newPayload, payload);

    commit(Mutations.MUTATE_TOGGLE_LISTVIEW_FILTER, newPayload.filterName);
    if(newPayload.fetch) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.CLEAR_LISTVIEW_FILTERS]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: {filters: string[]; fetch?: boolean}) => {
    const newPayload = {filters: [], fetch: true};
    Object.assign(newPayload, payload);

    commit(Mutations.MUTATE_CLEAR_LISTVIEW_FILTERS, payload.filters);
    if(newPayload.fetch) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_STATUS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.listviewFilterState.filters.status.value];
    if (state.listviewFilterState.filters.status.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_LISTVIEW_FILTER_STATUS, ids);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_PAYMENT_STATUS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.listviewFilterState.filters.paymentStatus.value];
    if (state.listviewFilterState.filters.paymentStatus.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_LISTVIEW_FILTER_PAYMENT_STATUS, ids);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_APPROVAL_STATUS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.listviewFilterState.filters.approvalStatus.value];
    if (state.listviewFilterState.filters.approvalStatus.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_LISTVIEW_FILTER_APPROVAL_STATUS, ids);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_ACCOUNTING_STATUS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.listviewFilterState.filters.accountingStatus.value];
    if (state.listviewFilterState.filters.accountingStatus.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_LISTVIEW_FILTER_ACCOUNTING_STATUS, ids);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_DUE_DATE_FROM]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_LISTVIEW_FILTER_DUE_DATE_FROM, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_DUE_DATE_TO]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_LISTVIEW_FILTER_DUE_DATE_TO, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_INVOICE_DATE_FROM]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_LISTVIEW_FILTER_INVOICE_DATE_FROM, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_INVOICE_DATE_TO]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_LISTVIEW_FILTER_INVOICE_DATE_TO, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_PAYMENT_DISCOUNT_DUE_DATE_FROM]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_LISTVIEW_FILTER_PAYMENT_DISCOUNT_DUE_DATE_FROM, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_PAYMENT_DISCOUNT_DUE_DATE_TO]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_LISTVIEW_FILTER_PAYMENT_DISCOUNT_DUE_DATE_TO, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_SUPPLIER]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: Supplier) => {
    // when a project is selected, all other filters should be cleared
    if(state.listviewFilterState.filters.project && (!state.listviewFilterState.filters.project.active || !state.listviewFilterState.filters.project.hasValues())) {
      const filtersToClear = [] as string[];
      Object.keys(state.listviewFilterState.filters).forEach((key: string) => {
        filtersToClear.push(key);
      });
      commit(Mutations.MUTATE_CLEAR_LISTVIEW_FILTERS, filtersToClear);
    }

    commit(Mutations.MUTATE_LISTVIEW_FILTER_SUPPLIER_MODEL, payload ? payload : null);
    commit(Mutations.MUTATE_LISTVIEW_FILTER_SUPPLIER, payload ? [payload.id] : []);

    // if(state.invoicesMetricsReport) dispatch(Actions.FETCH_INVOICES_METRICS_REPORT);
    if(state.listviewFilterState.filters.supplier.active) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_FOR_SEARCH]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string) => {
    const terms = [] as string[];
    terms.push(payload);

    // set search filters
    commit(Mutations.MUTATE_LISTVIEW_FILTER_FOR_SEARCH, terms);

    // fetch the invoices
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_LISTVIEW_FILTER_ON_PROJECT_LEAD]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.listviewFilterState.filters.projectLead.value];
    if (state.listviewFilterState.filters.projectLead.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_LISTVIEW_FILTER_PROJECT_LEAD, ids);
    if(state.listviewFilterState.filters.projectLead.active) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },

  // search
  [Actions.SEARCH_SUPPLIERS]: async({state, commit}: ActionContext<InvoiceState, RootState>, payload: string) => {
    const suppliersService = new SuppliersService();

    try {
      const parameters = new URLSearchParams();
      parameters.set('searchQuery', payload);
      const result = await suppliersService.getSuppliers(parameters);

      commit(Mutations.MUTATE_SEARCH_SUPPLIERS, result.data.data);
      return result;
    } catch (err: any) {
      console.error(err);
    }
  },
};