
	import { PropType } from 'vue';
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { ProjectRelationship, ProjectRelationshipEntity } from '@/modules/purchase-invoices/types/entities';

	const purchaseInvoices = namespace('purchaseInvoices');

	@Component
	export default class ProjectRelationshipApprovalActionsComponent extends Vue {

		// actions

		// getters

		// props
		@Prop({ type: Object as PropType<ProjectRelationship>, required: true, default: () => new ProjectRelationshipEntity }) projectRelationship!: ProjectRelationship;

		// local state + vars
		state: {} = {}

		// handlers
		onClickApprovalStatus(projectRelationship: ProjectRelationship, newStatus: string) {
            this.$emit('onClickApprovalStatus', {item: projectRelationship, status: newStatus})
        }

		// getters
		
		// watchers

		// hooks
		created() {}
        destroyed() {}
	}
