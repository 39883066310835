import { reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import { UseAuthState, UseAuthComposables, UseAuthContext } from './types/useAuth';
import { merge } from 'lodash';
import { Options } from './types/composable';
import { useDynamicProps } from './useDynamicProps';
import { actions as authActions } from '@/composables/actions/useAuth';
import {can, canAny} from "@/modules/login/helpers";

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseAuthState>): UseAuthState => {
  return merge(
    {
      auth: null,
      checkedAt: null,
      checkCount: 0,
      loginValidationErrors: null,
    },
    state
  );
};

export default function useAuth(options?: Options<UseAuthState, UseAuthComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    showDialog: useDynamicProps<boolean>(),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseAuthContext = { state, composables };
  const actions = wrapActions(ctx, authActions);

  return {
    getters: {
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // other
      showDialog: ctx.composables.showDialog.getters,

      user: computed(() => state.auth?.user),
      loginValidationErrors: computed(() => state.loginValidationErrors),

      isAuthenticated: computed<boolean>(() => state.auth != null),
      isImpersonating: computed<boolean>(() => state.auth?.impersonator != null),
    },
    actions: {
      showDialog: ctx.composables.showDialog.actions.set,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...actions,
    },
    canAny: (permissions: string[]) => computed(() => state.auth ? canAny(state.auth.permissions || [], permissions) : false),
    can: (permission: string) => state.auth ? can(state.auth.permissions || [], permission) : false,
  };
}