import { JsonResource, Pagination } from '@/core/types/Entities';
import { AxiosResponse } from 'axios';
import { UseTeamContext } from '../types/useTeam';
import useFilter from '../useFilter';
import { TeamsService } from '@/services/teams.service';
import { Team, TeamMember2 } from '@/modules/settings/types/entities';

const teamsService = new TeamsService();

const setTeam = async (ctx: UseTeamContext, team: Team|null): Promise<void> => new Promise((resolve, reject) => {
  const { state } = ctx;
  try {
    state.team = team;
    resolve();
  } catch (err: any) { reject(err); }
});

const clearTeamValidationErrors = async (ctx: UseTeamContext) => {
  const { state } = ctx;
  state.teamValidationErrors = null;
  return Promise.resolve();
};

const clearTeamBusinessErrors = async (ctx: UseTeamContext) => {
  const { state } = ctx;
  state.teamBusinessErrors = null;
  return Promise.resolve();
};

const clearTeamErrors = async (ctx: UseTeamContext) => {
  clearTeamValidationErrors(ctx);
  clearTeamBusinessErrors(ctx);
  return Promise.resolve();
};

const fetchTeam = async (ctx: UseTeamContext, id: number): Promise<AxiosResponse<JsonResource<Team>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchTeam.name, true);
  try {
    clearTeamErrors(ctx);
    const res = await teamsService.getTeamById(id);
    setTeam(ctx, res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchTeam.name, false);
  }
};

const createTeam = async (ctx: UseTeamContext): Promise<AxiosResponse<JsonResource<Team>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(createTeam.name, true);
  clearTeamErrors(ctx);
  try {
    if(state.team !== null) {
      clearTeamErrors(ctx);
      const res = await teamsService.createTeam(state.team);
      setTeam(ctx, res.data.data);
      return Promise.resolve(res);
    }
    else return Promise.reject(new Error('Team not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.teamValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.teamBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(createTeam.name, false);
  }
};

const updateTeam = async (ctx: UseTeamContext): Promise<AxiosResponse<JsonResource<Team>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(updateTeam.name, true);
  clearTeamErrors(ctx);
  try {
    if(state.team !== null && state.team.id != null) {
      clearTeamErrors(ctx);
      const res = await teamsService.editTeam(state.team);
      setTeam(ctx, res.data.data);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No team available in state or id not provided'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.teamValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.teamBusinessErrors = err.response.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateTeam.name, false);
  }
};

const deleteTeam = async (ctx: UseTeamContext): Promise<AxiosResponse<void>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(deleteTeam.name, true);
  clearTeamErrors(ctx);
  try {
    if(state.team !== null && state.team.id != null) {
      const res = await teamsService.deleteTeam(state.team.id);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No team available in state or id not provided'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteTeam.name, false);
  }
};

const search = async (ctx: UseTeamContext, query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<Team[]>>> => {
  const { composables: { loadingActions, filter } } = ctx;
  loadingActions.actions.set(search.name, true);
  try {
    let urlSearchParams = null;
    if(useAsFilter) {
      if(query) filter.actions.setFilter('search', query);
      else filter.actions.deleteFilter('search');
      urlSearchParams = filter.getters.filterUrlQuery.value;
    } else {
      const f = useFilter();
      if(query) f.actions.setFilter('search', query);
      urlSearchParams = f.getters.filterUrlQuery.value;
    }
    const url = teamsService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`;
    const res = await teamsService.getAllTeams(url);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(search.name, false);
  }
};

const newTeamMember = async (ctx: UseTeamContext): Promise<AxiosResponse<JsonResource<Team>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(newTeamMember.name, true);
  clearTeamErrors(ctx);
  try {
    if(state.team !== null) {
      clearTeamErrors(ctx);
      const res = await teamsService.createTeamMember(state.team.id, null);
      setTeam(ctx, res.data.data);
      return Promise.resolve(res);
    } else return Promise.reject(new Error('Team not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.teamValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.teamBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(newTeamMember.name, false);
  }
};

const saveTeamMember = async (ctx: UseTeamContext, teamMember: TeamMember2): Promise<AxiosResponse<JsonResource<Team>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(saveTeamMember.name, true);
  clearTeamErrors(ctx);
  try {
    if(state.team !== null) {
      clearTeamErrors(ctx);
      if(teamMember.id) {
        const res = await teamsService.updateTeamMember(state.team.id, teamMember);
        setTeam(ctx, res.data.data);
        return Promise.resolve(res);
      } else {
        const res = await teamsService.createTeamMember(state.team.id, teamMember);
        setTeam(ctx, res.data.data);
        return Promise.resolve(res);
      }
    } else return Promise.reject(new Error('Team not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.teamValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.teamBusinessErrors = err.response.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveTeamMember.name, false);
  }
};

const deleteTeamMember = async (ctx: UseTeamContext, teamMember: TeamMember2): Promise<AxiosResponse<JsonResource<Team>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(deleteTeamMember.name, true);
  clearTeamErrors(ctx);
  try {
    if(state.team != null) {
      clearTeamErrors(ctx);
      if(!teamMember.id) throw new Error('teammember has no id, cannot be deleted');
      const res = await teamsService.deleteTeamMember(state.team.id, teamMember.id);
      setTeam(ctx, res.data.data);
      return Promise.resolve(res);
    } else return Promise.reject(new Error('Team not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.teamValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.teamBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteTeamMember.name, false);
  }
};

export const actions = {
  fetchTeam,
  createTeam,
  updateTeam,
  deleteTeam,
  setTeam,
  search,
  clearTeamValidationErrors,
  clearTeamBusinessErrors,
  clearTeamErrors,
  saveTeamMember,
  deleteTeamMember,
  newTeamMember,
};
