
// import Vue from 'vue'
import Vue, { computed, defineComponent, PropType } from 'vue';
import { cloneDeep } from 'lodash';
import { ProjectContractorBranch, ProjectContractorBranchEntity } from '../types/entities';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';


export default defineComponent({
  name: 'ProjectContractorBranchEditPopupComponent',
  emits: ['update:contractorTypeId', 'update:contractorTypeName', 'update:comment', 'onClickSave'],
  components: {},
  props: {
    unusedContractorBranches: {
      type: Array as PropType<ContractorBranch[]>,
      default: []
    },
    contractorBranch: {
      type: Object as PropType<ProjectContractorBranch>,
      default: () =>
        new ProjectContractorBranchEntity({
          id: -1,
          projectId: undefined,
          contractorTypeId: undefined,
          contractorTypeName: "",
          isRelevant: false,
          comment: '',
        }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: Object as PropType<any>,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    // computed
    const selectedContractorBranch = { id: props.contractorBranch.contractorTypeId, name: props.contractorBranch.contractorTypeName };
    const contractorBranchCopy = computed(() => cloneDeep(props.contractorBranch))
    return {
      contractorBranchCopy,
      selectedContractorBranch
    }
  },
})
