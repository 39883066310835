import axios, { AxiosResponse } from 'axios';
import { AxiosPromise } from 'axios';

import { Holiday, HolidayType } from '@/modules/settings/store/modules/holidays/types/HolidaysState';
import { JsonResource, Pagination } from '@/core/types/Entities';

const BASE_ENDPOINT = '/api/v1/settings/holidays';

export class HolidayService {
	getBaseEndpoint(): string {
		return BASE_ENDPOINT
	}

	getAllHolidays(url = `${BASE_ENDPOINT}`): Promise<AxiosResponse<Pagination<Holiday[]>>> {
		return axios.get(url);
	}

	getAllHolidayTypes(types?: string[]): AxiosPromise<JsonResource<HolidayType[]>> {
		const filters =  types && types.length > 0 ? '?types=' + types.toString() : null;
		return axios.get(`/api/v1/settings/holiday-types${filters ? filters : ''}`)
	}

	getHolidayById(id: number): Promise<AxiosResponse<JsonResource<Holiday>>> {
		return axios.get<JsonResource<Holiday>>(`${ BASE_ENDPOINT }/${ id }`);
	}

	createHoliday(payload: Holiday): AxiosPromise<JsonResource<Holiday>> {
		return axios.post(`${ BASE_ENDPOINT }`, payload);
	}

	editHoliday(payload: Holiday): AxiosPromise<JsonResource<Holiday>> {
		return axios.put(`${ BASE_ENDPOINT }/${ payload.id }`, payload);
	}

	deleteHoliday(payload: number): AxiosPromise {
		return axios.delete(`${ BASE_ENDPOINT }/${ payload }`);
	}
}
