import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import {
  ProjectRelationship,
  ProjectRelationshipStatus,
  PurchaseInvoice,
  AccountingItem,
  PurchaseInvoiceStatus,
  PurchaseInvoicePaymentStatus,
  PurchaseInvoiceRecordType,
  ApprovalStatus,
  AccountingStatus
} from '../types/entities';
import { MediaAnnotation } from '@/modules/entities/types/entities';
import { Payment } from '@/modules/payments/types/entities';

const BASE_ENDPOINT = '/api/v1/purchase-invoices';

export class PurchaseInvoicesService {

  getAllProjectRelationshipStatuses(url = `${ BASE_ENDPOINT }/project-relationship-statuses`): AxiosPromise<JsonResource<ProjectRelationshipStatus[]>> {
    return axios.get<JsonResource<ProjectRelationshipStatus[]>>(`${ url }`);
  }

  getAllStatuses(url = `/api/v1/purchase-invoice-statuses`): AxiosPromise<JsonResource<PurchaseInvoiceStatus[]>> {
    return axios.get<JsonResource<PurchaseInvoiceStatus[]>>(`${ url }`);
  }

  getAllPaymentStatuses(url = `/api/v1/purchase-invoice-payment-statuses`): AxiosPromise<JsonResource<PurchaseInvoicePaymentStatus[]>> {
    return axios.get<JsonResource<PurchaseInvoicePaymentStatus[]>>(`${ url }`);
  }

  getAllApprovalStatuses(url = `/api/v1/purchase-invoice-approval-statuses`): AxiosPromise<JsonResource<ApprovalStatus[]>> {
    return axios.get<JsonResource<ApprovalStatus[]>>(`${ url }`);
  }

  getAllAccountingStatuses(url = `/api/v1/purchase-invoice-accounting-statuses`): AxiosPromise<JsonResource<AccountingStatus[]>> {
    return axios.get<JsonResource<AccountingStatus[]>>(`${ url }`);
  }

  getAllRecordTypes(url = `/api/v1/purchase-invoice-record-types`): AxiosPromise<JsonResource<PurchaseInvoiceRecordType[]>> {
    return axios.get<JsonResource<PurchaseInvoiceRecordType[]>>(`${ url }`);
  }

  getAllInvoices(url = `${ BASE_ENDPOINT }`): Promise<AxiosResponse<Pagination<PurchaseInvoice[]>>> {
    return axios.get<Pagination<PurchaseInvoice[]>>(url);
  }

  getInvoiceById(invoiceId: number): AxiosPromise<JsonResource<PurchaseInvoice>> {
    return axios.get<JsonResource<PurchaseInvoice>>(`${ BASE_ENDPOINT }/${ invoiceId }`);
  }

  getAccountingItemById(accountingItemId: number): AxiosPromise<JsonResource<AccountingItem>> {
    return axios.get<JsonResource<AccountingItem>>(`${ BASE_ENDPOINT }/accounting-items/${ accountingItemId }`);
  }

  getProjectRelationshipById(projectRelationshipId: number): AxiosPromise<JsonResource<ProjectRelationship>> {
    return axios.get<JsonResource<ProjectRelationship>>(`${ BASE_ENDPOINT }/project-relationships/${ projectRelationshipId }`);
  }

  getInvoiceMediaAnnotation(invoiceId: number, mediaId: number): AxiosPromise<JsonResource<MediaAnnotation>> {
    return axios.get<JsonResource<MediaAnnotation>>(`${ BASE_ENDPOINT }/${ invoiceId }/media/${ mediaId }/annotations`);
  }

  getProjectRelationshipMediaAnnotation(projectRelationshipId: number, mediaId: number): AxiosPromise<JsonResource<MediaAnnotation>> {
    return axios.get<JsonResource<MediaAnnotation>>(`${ BASE_ENDPOINT }/project-relationships/${ projectRelationshipId }/media/${ mediaId }/annotations`);
  }

  getMediaAnnotations(mediaId: number): AxiosPromise<JsonResource<MediaAnnotation[]>> {
    return axios.get<JsonResource<MediaAnnotation[]>>(`/api/v1/media/${ mediaId }/annotations`);
  }

  createInvoice(invoice: PurchaseInvoice): AxiosPromise<JsonResource<PurchaseInvoice>> {
    return axios.post<JsonResource<PurchaseInvoice>>(`${ BASE_ENDPOINT }`, invoice);
  }

  createAccountingItem(accountingItem: AccountingItem): AxiosPromise<JsonResource<AccountingItem>> {
    return axios.post<JsonResource<AccountingItem>>(`${ BASE_ENDPOINT }/accounting-items`, accountingItem);
  }

  createProjectRelationship(projectRelationship: ProjectRelationship): AxiosPromise<JsonResource<ProjectRelationship>> {
    return axios.post<JsonResource<ProjectRelationship>>(`${ BASE_ENDPOINT }/project-relationships`, projectRelationship);
  }

  createPayment(invoiceId: number, payment: Payment): AxiosPromise<JsonResource<Payment>> {
    return axios.post<JsonResource<Payment>>(`${ BASE_ENDPOINT }/${ invoiceId }/payments`, payment);
  }

  updateInvoiceById(invoice: PurchaseInvoice): AxiosPromise<JsonResource<PurchaseInvoice>> {
    return axios.put<JsonResource<PurchaseInvoice>>(`${ BASE_ENDPOINT }/${ invoice.id }`, invoice);
  }

  updateAccountingItemById(accountingItem: AccountingItem): AxiosPromise<JsonResource<AccountingItem>> {
    return axios.put<JsonResource<AccountingItem>>(`${ BASE_ENDPOINT }/accounting-items/${ accountingItem.id }`, accountingItem);
  }

  updateInvoiceMediaAnnotationById(invoiceId: number, mediaId: number, annotation: MediaAnnotation): AxiosPromise<JsonResource<MediaAnnotation>> {
    return axios.put<JsonResource<MediaAnnotation>>(`${ BASE_ENDPOINT }/${ invoiceId }/media/${ mediaId }/annotations/${ annotation.id }`, annotation);
  }

  updateProjectRelationshipMediaAnnotationById(projectRelationshipId: number, mediaId: number, annotation: MediaAnnotation): AxiosPromise<JsonResource<MediaAnnotation>> {
    return axios.put<JsonResource<MediaAnnotation>>(`${ BASE_ENDPOINT }/project-relationships/${ projectRelationshipId }/media/${ mediaId }/annotations/${ annotation.id }`, annotation);
  }

  updateProjectRelationshipById(projectRelationship: ProjectRelationship): AxiosPromise<JsonResource<ProjectRelationship>> {
    return axios.put<JsonResource<ProjectRelationship>>(`${ BASE_ENDPOINT }/project-relationships/${ projectRelationship.id }`, projectRelationship);
  }

  updatePaymentById(invoiceId: number, payment: Payment): AxiosPromise<JsonResource<Payment>> {
    return axios.put<JsonResource<Payment>>(`${ BASE_ENDPOINT }/${ invoiceId }/payments/${ payment.id }`, payment);
  }

  deleteInvoiceById(invoiceId: number): AxiosPromise<void> {
    return axios.delete(`${ BASE_ENDPOINT }/${ invoiceId }`);
  }

  deleteProjectRelationshipById(projectRelationshipId: number): AxiosPromise<JsonResource<ProjectRelationship>> {
    return axios.delete(`${ BASE_ENDPOINT }/project-relationships/${ projectRelationshipId }`);
  }

  deletePaymentById(invoiceId: number, paymentId: number): AxiosPromise<JsonResource<Payment>> {
    return axios.delete(`${ BASE_ENDPOINT }/${ invoiceId }/payments/${ paymentId }`);
  }

  deleteAccountingItemById(accountingItemId: number): AxiosPromise<JsonResource<AccountingItem>> {
    return axios.delete(`${ BASE_ENDPOINT }/accounting-items/${ accountingItemId }`);
  }
}
