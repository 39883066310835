import {RouteConfig} from 'vue-router';

import HolidaysView from '@/modules/settings/views/holidays/holidays.view.vue';
import RolePermissionsComponent from '@/modules/settings/views/permissions/permissions.component.vue';
import ContractorTypesComponent from '@/modules/settings/views/contractor-types/contractor-types.component.vue';
import DeletedLogComponent from '@/modules/settings/views/deleted-log/deleted-log.component.vue';
import ActivityLogComponent from '@/modules/settings/views/activity-log/activity-log.component.vue';
import ProfileComponent from '@/modules/settings/views/profile/profile.component.vue';
import EmployeesView from '@/modules/settings/views/employees/employees.view.vue';
import DivisionsComponent from '@/modules/settings/views/divisions/divisions.component.vue';
import DivisionDetailComponent from '@/modules/settings/views/division-detail/division-detail.component.vue';
import WorkflowSettingsComponent from '@/modules/settings/views/workflow/workflow-settings.component.vue';
import AccountingLedgersComponent from '@/modules/accounting/views/accounting-ledgers/accounting-ledgers.component.vue';
import UsersView from "@/modules/settings/views/users/users.view.vue";


export const routes: RouteConfig[] = [
	{
		path: '/settings/divisions',
		component: DivisionsComponent
	},
	{
		path: '/settings/divisions/:id',
		component: DivisionDetailComponent
	},
	{
		path: '/settings/employees',
		component: EmployeesView
	},
	{
		path: '/settings/holidays',
		component: HolidaysView
	},
	{
		path: '/settings/permissions',
		component: RolePermissionsComponent,
		name: 'RolePermissions'
	},
	{
		path: '/settings/contractor-types',
		component: ContractorTypesComponent
	},
	{
		path: '/settings/accounting',
		component: AccountingLedgersComponent
	},
	{
		path: '/settings/deleted',
		component: DeletedLogComponent
	},
	{
		path: '/settings/activity-log',
		component: ActivityLogComponent
	},
	{
		path: '/settings/profile',
		component: ProfileComponent
	},
	{
		path: '/settings/workflow',
		component: WorkflowSettingsComponent
	},
	{
		path: '/settings/users',
		component: UsersView,
		name: 'users'
	}
];
