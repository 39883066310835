import {GetterTree} from "vuex";
import {MonthlyOverviewState} from "@/modules/monthly-overview/store/types/MonthlyOverviewState";
import {RootState} from "@/core/types/RootState";
import {Getters} from "@/modules/monthly-overview/store/types/storeTypes";

export const getters: GetterTree<MonthlyOverviewState, RootState> = {
	[Getters.GET_TEAM_MEMBERS]: state => state.teamMembers,
	[Getters.GET_SELECTED_TEAM_MEMBER]: state => state.selectedTeamMember,
	[Getters.GET_MONTHLY_OVERVIEW_DATA]: state => state.monthlyOverviewData
}
