
import {computed, markRaw, PropType} from 'vue';
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectRelationship, PurchaseInvoice, PurchaseInvoiceEntity, TransactionType } from '@/modules/purchase-invoices/types/entities';
import { DataTableHeader } from 'vuetify';
import { calculateProjectRelationshipsDifference, summarizeProjectRelationshipsBy } from '@/modules/purchase-invoices/helpers'
import ApprovalActions from '@/modules/purchase-invoices/components/project-relationship-details/project-relationship-approval-actions.component.vue';
import { signAmountProjectRelationship } from '@/modules/purchase-invoices/helpers'
import {cloneDeep} from "lodash";
import ProjectRelationshipSplitPopup
  from "@/modules/purchase-invoices/components/project-relationship-split-popup/project-relationship-split-popup.component.vue";
import {AccountingLedger} from "@/modules/accounting/types/entities";
import useProjectRelationship from "@/composables/useProjectRelationship";

const purchaseInvoices = namespace('purchaseInvoices');

    @Component({
      components: {
        ProjectRelationshipSplitPopup,
        ApprovalActions
      },
      props: {
        currentAccountingLedger: {
          type: Object as PropType<AccountingLedger|undefined>,
          default: undefined
        },
      },
      setup(props, ctx) {

      }
    })
    export default class PurchaseInvoiceProjectRelationshipsListComponent extends Vue {

        projectRelationshipForm = useProjectRelationship();

        // props
        @PropSync('projectRelationships', { type: Array as PropType<ProjectRelationship[]>, required: true }) projectRelationshipsSync!: ProjectRelationship[];
        @Prop({type: Object as PropType<PurchaseInvoice>, default: () => new PurchaseInvoiceEntity(), required: true}) purchaseInvoice!: PurchaseInvoice;

        // local/children state
        state: {
            showMenuSplitProjectRelationship: number|null,
        } = {
            showMenuSplitProjectRelationship: null
        }
        content: {
            splitProjectRelationship: ProjectRelationship|null
        } = {
            splitProjectRelationship: null
        }

        // handlers
        onClickApprovalStatus(item: any) {
            this.$emit('onClickApprovalStatus', item)
        }
        onClickSplitProjectRelationship(projectRelationship: ProjectRelationship|undefined) {
            if(projectRelationship?.id) {
                this.state.showMenuSplitProjectRelationship = projectRelationship.id
                this.content = {...this.content, splitProjectRelationship: markRaw(cloneDeep(projectRelationship))}
            }
        }
        onClickConfirmSplit = (newProjectRelationship: ProjectRelationship, oldProjectRelationship: ProjectRelationship) => {
            this.projectRelationshipForm.actions.setProjectRelationship(oldProjectRelationship)
                .then(() => this.projectRelationshipForm.actions.splitProjectRelationship(newProjectRelationship))
                .then(() => {
                  this.$emit('splitProjectRelationshipSucceeded')
                  this.content.splitProjectRelationship = null
                  this.state.showMenuSplitProjectRelationship = null
                  this.projectRelationshipForm.actions.clearProjectRelationshipValidationErrors()
                }).catch(error => null)
        }

        // methods
        signAmount(amount: number, type: TransactionType) {
            return signAmountProjectRelationship(amount, type);
        }
        calculateProjectRelationshipsDifference(invoice: PurchaseInvoice) {
            if (invoice.total && invoice.recordType && this.projectRelationshipsSync) {
                return calculateProjectRelationshipsDifference(invoice, this.projectRelationshipsSync)
            }
            return 0
        }

        // getters
        get projectRelationshipHeaders() {
            return [
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.total').toString(),
                    value: 'total',
                    align: 'end text-no-wrap',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.project').toString(),
                    value: 'project.projectName',
                    align: 'start text-truncate td-text-truncate-2 td-toggle',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.contractorType').toString(),
                    value: 'contractorType.id',
                    align: 'start text-truncate td-text-truncate-1 td-toggle',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.accountingLedgerItem').toString(),
                    value: 'accountingLedgerItem.name',
                    align: 'start text-truncate td-text-truncate-2 td-toggle',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.status').toString(),
                    value: 'status.id',
                    align: 'start text-truncate td-text-truncate-5 td-toggle',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                {
                    text: this.$t('invoices.purchaseProjectRelationships.details.labels.approvalStatus').toString(),
                    value: 'approvalStatus.id',
                    align: 'center',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } /*as DataTableHeader*/,
                { text: '', value: 'actions', sortable: false, groupable: false} as DataTableHeader,
            ];
        }
        get projectRelationshipsSummarizers() {
            return [
                {
                    value:'total',
                    summarize: (items: ProjectRelationship[] = []) => Math.abs(summarizeProjectRelationshipsBy('total', items))
                },
                {
                    value:'contractorType.abbreviation',
                    summarize: (items: ProjectRelationship[] = []) => { 
                        let types = [];
                        types = items.map((item: ProjectRelationship) => item.contractorType && item.contractorType.abbreviation ? item.contractorType.abbreviation : '')
                        return types.length > 0 ? types.reduce((prev: string, curr: string) => { return prev.includes(curr) ? prev : prev + ',' + curr }) : ''
                    },
                },
            ]
        }
        get projectRelationshipsFormatters() {
            return [
                {
                    value:'total',
                    format: (value: number) => {
                        return this.$options.filters !== undefined ? this.$options.filters.formatMoney(value) : null
                    }
                },
                {
                    
                    value:'contractorType.abbreviation',
                    format: (value: string) => {
                        return value;
                    }
                }
            ]
        }

        // setters

        // watchers

        // lifecycle
    }
