import { GetterTree } from 'vuex';
import { WorkflowState } from '@/modules/settings/store/modules/workflow/types/WorkflowState';
import { RootState } from '@/core/types/RootState';
import { Getters } from '@/modules/settings/store/modules/workflow/types/StoreTypes';

export const getters: GetterTree<WorkflowState, RootState> = {
	[Getters.GET_WORKFLOW_TASKS]: (state: WorkflowState) => {
		return state.tasks;
	},
	[Getters.GET_WORKFLOW_TASK_STATUSES]: (state: WorkflowState) => {
		return state.taskStatuses;
	},
	[Getters.GET_WORKFLOW_FUNCTION_GROUPS]: (state: WorkflowState) => {
		return state.functionGroups;
	},
	[Getters.GET_WORKFLOW_INFO_MESSAGE]: (state: WorkflowState) => {
		return state.infoMessage;
	},
	[Getters.GET_WORKFLOW_ACTIVITY_LOG]: (state: WorkflowState) => {
		return state.activityLog;
	},
	[Getters.GET_WORKFLOW_HIGHEST_TASK_SEQUENCE]: (state: WorkflowState) => {
		return state.highestTaskSequence;
	},
	[Getters.GET_WORKFLOW_FILTERS]: (state: WorkflowState) => {
		return state.filters;
	}
};
