
  import { computed, defineComponent, ref, watch } from 'vue';
  import helpers from '@/modules/calendar/services/helper.service';
  import store from '../store';
  import { SupplierType } from '@/modules/entities/types/entities';
  import { ProjectStatus } from '../interfaces/project/projectStatus';
  import { ProjectUser } from '@/modules/projects/types/entities';
  import useDebouncedRef from '@/composables/useDebouncedRef';

  export default defineComponent({
    setup() {
      // const projectLeaderSearchValue = ref('');
      const projectLeaderSearchValue = useDebouncedRef('', 300);

      const filtersAreLoading = store.calendarSettings.getters.isLoadingAction([
        store.calendarSettings.actions.initSettings.name,
      ]);

      const selectedSupplierTypes = computed((): SupplierType[] => {
        return store.calendarSettings.getters.supplierTypes.value.filter((st) => {
          return store.calendarSettings.getters.selectedSupplierTypesIds.value.includes(st.id);
        });
      });
      const areAllSupplierTypesSelected = computed((): boolean => {
        return (
          selectedSupplierTypes.value.length === store.calendarSettings.getters.supplierTypes.value.length &&
          !!store.calendarSettings.getters.supplierTypes.value.length
        );
      });
      const onSelectAllSupplierTypes = () => {
        if (areAllSupplierTypesSelected.value) {
          store.calendarSettings.actions.filterBySupplierTypeIds([], false);
        } else {
          store.calendarSettings.actions.filterBySupplierTypeIds(
            store.calendarSettings.getters.supplierTypes.value.map((x) => x.id),
            false
          );
        }
      };

      // PROJECT STATUSES
      const filteredProjectStatuses = computed(() => {
        return store.calendarSettings.getters.projectStatuses.value;
      });
      const selectedProjectStatuses = computed((): ProjectStatus[] => {
        return store.calendarSettings.getters.projectStatuses.value.filter((ps) => {
          return store.calendarSettings.getters.selectedProjectStatusesIds.value.includes(ps.id);
        });
      });
      const areAllProjectStatusesSelected = computed((): boolean => {
        return (
          selectedProjectStatuses.value.length === store.calendarSettings.getters.projectStatuses.value.length &&
          !!store.calendarSettings.getters.projectStatuses.value.length
        );
      });
      const onSelectAllProjectStatuses = () => {
        if (areAllProjectStatusesSelected.value) {
          store.calendarSettings.actions.filterByProjectStatusIds([], false);
        } else {
          store.calendarSettings.actions.filterByProjectStatusIds(
            store.calendarSettings.getters.projectStatuses.value.map((x) => x.id),
            false
          );
        }
      };

      // PROJECT LEADERS
      const filteredProjectLeaders = computed(() => {
        return store.calendarSettings.getters.projectLeaders.value.filter((projectLeader: ProjectUser) => {
          if (!!projectLeaderSearchValue.value.trim()) {
            return (
              projectLeader.firstName.toUpperCase().includes(projectLeaderSearchValue.value.trim().toUpperCase()) ||
              projectLeader.alias.toUpperCase().includes(projectLeaderSearchValue.value.trim().toUpperCase())
            );
          }
          return projectLeader;
        });
      });
      const selectedProjectLeaders = computed((): ProjectUser[] => {
        return store.calendarSettings.getters.projectLeaders.value.filter((ps) => {
          return store.calendarSettings.getters.selectedProjectLeadersIds.value.includes(ps.id);
        });
      });
      const areAllProjectLeadersSelected = computed((): boolean => {
        return (
          selectedProjectLeaders.value.length === store.calendarSettings.getters.projectLeaders.value.length &&
          !!store.calendarSettings.getters.projectLeaders.value.length
        );
      });
      const onSelectAllProjectLeaders = () => {
        if (areAllProjectLeadersSelected.value) {
          store.calendarSettings.actions.filterByProjectLeaderIds([], false);
        } else {
          store.calendarSettings.actions.filterByProjectLeaderIds(
            store.calendarSettings.getters.projectLeaders.value.map((x) => x.id),
            false
          );
        }
      };

      return {
        calendar: store.calendar,
        calendarSettings: store.calendarSettings,
        ...helpers,

        projectLeaderSearchValue,

        // local handlers
        onSelectAllSupplierTypes,
        onSelectAllProjectStatuses,
        onSelectAllProjectLeaders,

        // local getters
        areAllSupplierTypesSelected,
        areAllProjectStatusesSelected,
        areAllProjectLeadersSelected,
        selectedSupplierTypes,
        selectedProjectStatuses,
        selectedProjectLeaders,
        filteredProjectLeaders,
        filteredProjectStatuses,

        filtersAreLoading,
      };
    },
  });
