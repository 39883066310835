import Vue from 'vue';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { RootState } from '@/core/types/RootState';
import { Actions, Getters, Mutations } from '@/modules/settings/store/modules/permissions/types/StoreTypes';
import { Permission, PermissionsState } from '@/modules/settings/store/modules/permissions/types/PermissionsState';
import { Role } from '@/modules/entities/types/entities';

const getters: GetterTree<PermissionsState, RootState> = {
	[Getters.GET_ROLES]: (state: PermissionsState) => {
		return state.roles;
	},
	[Getters.GET_PERMISSIONS]: (state: PermissionsState) => {
		return state.permissions;
	},
	[Getters.GET_ROLE_PERMISSIONS_EDIT_STATE]: (state: PermissionsState) => {
		return state.editState;
	}
};

const actions: ActionTree<PermissionsState, RootState> = {
	[Actions.FETCH_ROLES]: async ({ commit, dispatch }: ActionContext<PermissionsState, RootState>) => {
		try {
			const res = await Vue.prototype.$http.get(`/api/v1/settings/roles`);
			commit(Mutations.MUTATE_ROLES, res.data.data.roles);
			commit(Mutations.MUTATE_PERMISSIONS, res.data.data.permissions);
		} finally {
			dispatch('loading/SET_LOADING', false, { root: true });
		}
	},
	[Actions.SET_ROLE_PERMISSIONS_EDIT_STATE]: async ({ commit }: ActionContext<PermissionsState, RootState>, payload: boolean) => {
		commit(Mutations.MUTATE_ROLE_PERMISSIONS_EDIT_STATE, payload);
	},
	[Actions.TOGGLE_SINGLE_ROLE_STATE]: ({ commit, state }: ActionContext<PermissionsState, RootState>, payload: { role: Role; permission: Permission }) => {
		const updatedRoles = state.roles.map(role => {
			if (!role.permissions!.includes(payload.permission.name) && role.id === payload.role.id) {
				return {
					...role,
					permissions: role.permissions!.concat(payload.permission.name)
				};
			} else if (role.permissions!.includes(payload.permission.name) && role.id === payload.role.id) {
				return {
					...role,
					permissions: role.permissions!.filter((right: string) => {
						return right !== payload.permission.name;
					})
				};
			}
			return role;
		});
		commit(Mutations.MUTATE_ROLES, updatedRoles);
	},
	[Actions.TOGGLE_ROLES_STATE]: async ({ commit, dispatch }: ActionContext<PermissionsState, RootState>, payload: { roles: Role[]; oldRoles: Role[] }) => {
		try {
			await Vue.prototype.$http.put(`/api/v1/settings/roles`, { roles: payload.roles })
		} catch (e) {
			commit(Mutations.MUTATE_ROLES, payload.oldRoles);
		} finally {
			dispatch('loading/SET_LOADING', false, { root: true });
		}
	},
	[Actions.UNDO_ROLES_STATE]: ({ commit }: ActionContext<PermissionsState, RootState>, payload: Role[]) => {
		commit(Mutations.MUTATE_ROLES, payload);
	}
};

const mutations: MutationTree<PermissionsState> = {
	[Mutations.MUTATE_ROLES]: (state: PermissionsState, payload: Role[]) => {
		state.roles = payload;
	},
	[Mutations.MUTATE_PERMISSIONS]: (state: PermissionsState, payload: Permission[]) => {
		state.permissions = payload;
	},
	[Mutations.MUTATE_ROLE_PERMISSIONS_EDIT_STATE]: (state: PermissionsState, payload: boolean) => {
		state.editState = payload;
	}
};

const state: PermissionsState = {
	roles: [],
	permissions: [],
	editState: false
};

export const store: Module<PermissionsState, RootState> = {
	namespaced: true,
	actions,
	getters,
	state,
	mutations
};
