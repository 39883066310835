
	import { Component, Emit, Prop, Vue, Watch, Ref } from "vue-property-decorator";

	@Component
	export default class AppInputCurrency extends Vue {
		
		// props
		@Prop() value!: number;
		@Prop({type: Object, default: () => {return {}}}) options!: any;
		@Prop({type: Boolean, default: true}) selectOnFocus!: boolean;

		// refs
		$refs!: {
			input: HTMLInputElement
		}

		// data
		input: HTMLInputElement|null = null;
		formattedValue: number|null = null;

		// methods
		listeners() {
			// eslint-disable-next-line
			const { keyup, ...listeners } = this.$listeners; // all but input event
			return listeners;  
		}

		// handlers
		// onInput(value: number) {
		// 	this.$emit("input", value);
		// }
		onFocus(value: string) {
			if(this.selectOnFocus && this.input) this.input.select();
		}

		// computed / getters
		get currencyOptions() {
			return { ...Vue.prototype.$vueCurrencyFieldOptions, ...this.options };
		}
		get attrs() {
			// eslint-disable-next-line
			const { value,...attrs } = this.$attrs; // all but input event
			return { ...this.currencyOptions, ...attrs };
		}

		// watchers
		@Watch('value', {immediate: true})
		onValueChange(value: any) {
			this.formattedValue = value;
		}

		// created / mounted
		mounted(){
			this.input = this.$el.querySelector('input');
		}
	}
