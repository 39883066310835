var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{staticClass:"text-center",attrs:{"hide-overlay":"","max-width":"100%","scrollable":"","transition":"bottom-sheet-transition"},model:{value:(_vm.showMessage),callback:function ($$v) {_vm.showMessage=$$v},expression:"showMessage"}},[(!!_vm.message)?_c('v-card',{class:[
        'app-message',
        _vm.isSuccessType ? 'app-message--success' : '',
        _vm.isWarningType ? 'app-message--warning' : '',
        _vm.isErrorType ? 'app-message--error' : '',
        _vm.isInfoType ? 'app-message--info' : '',
      ],attrs:{"tile":"","max-height":"300"}},[_c('v-container',[_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"height":"36px"}},[_c('v-card-title',{staticStyle:{"color":"white","font-size":"1rem","line-height":"1.4rem"}},[_vm._v(" "+_vm._s(_vm.$tc(_vm.message.message))+" ")]),_c('v-card-actions',[(_vm.message.component)?_c('v-btn',{staticClass:"caption mr-4",attrs:{"small":"","text":"","color":"white"},on:{"click":function($event){_vm.showDetails = !_vm.showDetails}}},[_vm._v(_vm._s(!_vm.showDetails ? _vm.$tc('dictionary.viewDetails') : _vm.$tc('dictionary.hideDetails')))]):_vm._e(),_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){return _vm.dismissMessage(_vm.message)}}},[_vm._v("mdi-close")])],1)],1)]),(_vm.showDetails)?_c('v-container',{staticStyle:{"background":"rgba(255,255,255,0.87)","overflow-y":"scroll"},attrs:{"fluid":""}},[_c('v-container',[(_vm.message.component && _vm.message.component.cmp)?_c(_vm.message.component.cmp,_vm._b({tag:"component"},'component',_vm.message.component.props,false)):_vm._e()],1)],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }