import { TeleinformationDetail } from '@/modules/entities/types/entities';
import { UseTeleinformationContext } from '../types/useTeleinformation';

const setTeleinformation = async (ctx: UseTeleinformationContext, teleinformation: TeleinformationDetail | null) => {
  const { state } = ctx;
  try {
    state.teleinformation = teleinformation;
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  }
};

const clearTeleinformationValidationErrors = async (ctx: UseTeleinformationContext) => {
  const { state } = ctx;
  state.teleinformationValidationErrors = null;
  return Promise.resolve();
};

export const actions = {
  setTeleinformation,
  clearTeleinformationValidationErrors,
};
