import {computed, reactive, readonly} from 'vue';
import { merge } from 'lodash';
import { actions as environmentActions } from './actions/useCalendar';
import { Options } from '@/composables/types/composable';
import { UseCalendarComposables, UseCalendarContext, UseCalendarState } from './types/useCalendar';
import { useDynamicProps } from '@/composables/useDynamicProps';
import useFilter from '@/composables/useFilter';
import {mergeComposables, wrapActions} from '@/composables/utils';
import { CalendarViewType } from '@/modules/calendar/types/CalendarViewType';
import { CalendarSidebarDialog } from '../types/CalendarSidebarDialog';
import useCalendarSettings from './useCalendarSettings';
import { AbsencePeriod, Absence } from '@/modules/settings/types/entities';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseCalendarState>): UseCalendarState => {
  return merge<UseCalendarState, typeof state>(
    {
      // environment
      dateRangeCollection: [],
      holidays: [],
      showEmptyRows: true,
      plannerType: CalendarViewType.PROJECTS,//TEAMS_AND_CONTRACTORS, //CalendarViewType.PROJECTS,
      detailSideMenu: {
        visible: false,
      },
      grid: {
        expandedDivisionIds: [],
        expandedProjectIds: [],
        expandedSupplierIds: [],
        areMyProjectsHighlighted: false,
        myProjectIds: [],
      },
      expandedProjects: [],
      expandedDivisions: [],
      expandedSuppliers: [],
      headerCalendarLeftOffset: 0,
      showSettings: false,
      selectedPeriod: undefined,
      selectedPlannerDay: undefined,
      moveProjectByDays: undefined,

      // data
      projects: [],
      divisions: [],
      suppliers: [],
      adjacentPeriods: [],
      selectedAdjacentPeriods: [],
      lastSelectedData: undefined,
      commentToAdd: '',
      availableTeams: [],
      absencePeriods: [],
    },
    state
  );
};

/**
 * `UseCalendarEnvironment`-composable
 *   - All CalendarEnvironment-related interactions and functionality can be stored here.
 *   - If you need a global store, than just use this composable in eg a `store.ts`
 *
 * @param options
 * @returns
 */
export default function useCalendar(options?: Options<UseCalendarState, UseCalendarComposables>) {

  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    calendarSettings: useCalendarSettings(),
    filter: useFilter(),
    showDialog: useDynamicProps<boolean>(),
    showSidebar: useDynamicProps<boolean>(),
    crudState: useDynamicProps<boolean>(),
    loadingActions: useDynamicProps<boolean>(),
    silentActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseCalendarContext = { state, composables };
  const actions = wrapActions(ctx, environmentActions);

  // return getters and actions
  return {
    state: readonly({
      ...ctx.state,
      composables: readonly(ctx.composables),
    }),
    getters: {
      ...ctx.composables.filter.getters,
      showDialog: ctx.composables.showDialog.getters.all,
      // isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.value).some(v => v[0] === actions.search.name && v[1])),

      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),

      // sidebar
      showSidebar: computed<boolean>(() => !!ctx.composables.showSidebar.getters.some(true).value),
      activeSidebarDialog: computed<CalendarSidebarDialog>(() => ctx.composables.showSidebar.getters.unique(true).value as CalendarSidebarDialog),

      // crud state: edit, read, delete, ...
      crudState: ctx.composables.crudState.getters.all,

      plannerType: computed(() => state.plannerType),
      detailSideMenu: computed(() => state.detailSideMenu),
      grid: computed(() => state.grid),
      expandedProjects: computed(() => state.expandedProjects),
      expandedDivisions: computed(() => state.expandedDivisions),
      expandedSuppliers: computed(() => state.expandedSuppliers),
      headerCalendarLeftOffset: computed(() => state.headerCalendarLeftOffset),
      dateRangeCollection: computed(() => state.dateRangeCollection),
      showSettings: computed(() => state.showSettings),

      selectedPeriod: computed(() => state.selectedPeriod),
      selectedPlannerDay: computed(() => state.selectedPlannerDay),
      moveProjectByDays: computed(() => state.moveProjectByDays),

      // data
      projects: computed(() => state.projects),
      divisions: computed(() => state.divisions),
      suppliers: computed(() => state.suppliers),
      adjacentPeriods: computed(() => state.adjacentPeriods),
      selectedAdjacentPeriods: computed(() => state.selectedAdjacentPeriods),
      lastSelectedData: computed(() => state.lastSelectedData),
      commentToAdd: computed(() => state.commentToAdd),
      availableTeams: computed(() => state.availableTeams),
      absencePeriods: computed(() => state.absencePeriods),
      absences: computed(() => state.absencePeriods && state.absencePeriods.reduce((prev: Absence[], curr: AbsencePeriod) => {
        return [...prev, ...(curr.absences && curr.absences.length ? curr.absences : []) ];
      }, [])),
    },
    actions: {
      ...ctx.composables.filter.actions,
      showDialog: ctx.composables.showDialog.actions.set,
      setLoading: ctx.composables.loadingActions.actions.set,
      setCrudState: ctx.composables.crudState.actions.set,
      ...actions,
    },
  };
}
