import { JsonResource, Pagination } from '@/core/types/Entities';
import { AxiosResponse } from 'axios';
import { UseEmployeeContext } from '../types/useEmployee';
import useFilter from '../useFilter';
import { EmployeesService } from '@/services/employees.service';
import { Employee, EmployeeWage, Timesheet, AbsencePeriod, Absence } from '@/modules/settings/types/entities';
import {cloneDeep, merge} from 'lodash';

const employeesService = new EmployeesService();

const setEmployee = async (ctx: UseEmployeeContext, employee: Employee|null): Promise<void> => new Promise((resolve, reject) => {
  const { state } = ctx;
  try {
    if(employee) {
      state.employee = merge(cloneDeep(employee), {
        timesheets: employee.timesheets ? employee.timesheets : [],
        workDays: employee.workDays ? employee.workDays : [],
        absencePeriods: employee.absencePeriods ? employee.absencePeriods : [],
      });
    } else state.employee = employee;
    // state.employee = employee
    return resolve();
  } catch (err: any) { return reject(err); }
});

const clearEmployeeValidationErrors = async (ctx: UseEmployeeContext) => {
  const { state } = ctx;
  state.employeeValidationErrors = null;
  return Promise.resolve();
};

const clearEmployeeBusinessErrors = async (ctx: UseEmployeeContext) => {
  const { state } = ctx;
  state.employeeBusinessErrors = null;
  return Promise.resolve();
};

const clearEmployeeErrors = async (ctx: UseEmployeeContext) => {
  clearEmployeeValidationErrors(ctx);
  clearEmployeeBusinessErrors(ctx);
  return Promise.resolve();
};

const fetchEmployee = async (ctx: UseEmployeeContext, id: number): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchEmployee.name, true);
  try {
    clearEmployeeErrors(ctx);
    const res = await employeesService.getEmployeeById(id);
    setEmployee(ctx, res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchEmployee.name, false);
  }
};

const createEmployee = async (ctx: UseEmployeeContext): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(createEmployee.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null) {
      clearEmployeeErrors(ctx);
      const res = await employeesService.createEmployee(state.employee);
      setEmployee(ctx, res.data.data);
      return Promise.resolve(res);
    }
    else return Promise.reject(new Error('Employee not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.employeeValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.employeeBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(createEmployee.name, false);
  }
};

const updateEmployee = async (ctx: UseEmployeeContext): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(updateEmployee.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id != null) {
      clearEmployeeErrors(ctx);
      const res = await employeesService.editEmployee(state.employee);
      setEmployee(ctx, res.data.data);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No employee available in state or id not provided'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.employeeValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.employeeBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateEmployee.name, false);
  }
};

const deleteEmployee = async (ctx: UseEmployeeContext): Promise<AxiosResponse<void>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(deleteEmployee.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id != null) {
      const res = await employeesService.deleteEmployee(state.employee.id);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No employee available in state or id not provided'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteEmployee.name, false);
  }
};

const search = async (ctx: UseEmployeeContext, query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<Employee[]>>> => {
  const { composables: { loadingActions, filter } } = ctx;
  loadingActions.actions.set(search.name, true);
  try {
    let urlSearchParams = null;
    if(useAsFilter) {
      if(query) filter.actions.setFilter('search', query);
      else filter.actions.deleteFilter('search');
      urlSearchParams = filter.getters.filterUrlQuery.value;
    } else {
      const f = useFilter();
      if(query) f.actions.setFilter('search', query);
      urlSearchParams = f.getters.filterUrlQuery.value;
    }
    const url = employeesService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`;
    const res = await employeesService.getAllEmployees(url);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(search.name, false);
  }
};
const createWage = async (ctx: UseEmployeeContext, wage: EmployeeWage): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(createWage.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id) {
      clearEmployeeErrors(ctx);
      const res = await employeesService.createEmployeeWage(state.employee.id, wage);
      setEmployee(ctx, res.data.data);
      return Promise.resolve(res);
    }
    else return Promise.reject(new Error('Employee not set in state or has no id.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.employeeValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.employeeBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(createWage.name, false);
  }
};
const updateWage = async (ctx: UseEmployeeContext, wage: EmployeeWage): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(updateWage.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id) {
      const res = await employeesService.editEmployeeWage(state.employee.id, wage);
      setEmployee(ctx, res.data.data);
      return Promise.resolve(res);
    }
    else return Promise.reject(new Error('Employee not set in state or has no id.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.employeeValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.employeeBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateWage.name, false);
  }
};
const deleteWage = async (ctx: UseEmployeeContext, wage: EmployeeWage): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(deleteWage.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id != null && wage.id !== null) {
      const res = await employeesService.deleteEmployeeWage(state.employee.id, wage.id);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No employee available in state or id not provided, or the provided wage has no id.'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteWage.name, false);
  }
};
const saveWage = async (ctx: UseEmployeeContext, wage: EmployeeWage): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(saveWage.name, true);
  try {
    if(wage.id && wage.id <= 0) return createWage(ctx, wage);
    else return updateWage(ctx, wage);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveWage.name, false);
  }
};

const fetchTimesheets = async (ctx: UseEmployeeContext): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions, filterTimesheets } } = ctx;
  loadingActions.actions.set(fetchTimesheets.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id != null) {
      const urlParams = filterTimesheets.getters.filterUrlQuery.value;
      const res = await employeesService.getTimesheets(state.employee.id, urlParams);
      if(res.data.data.timesheets && res.data.data.timesheets.length > 0) {
        state.employee.timesheets = res.data.data.timesheets;
        return Promise.resolve(res);
      } else {
        state.employee.timesheets = [];
        return Promise.reject(Error('No timesheets available.'));
      }
    } else return Promise.reject(Error('No employee available in state or id not provided.'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchTimesheets.name, false);
  }
};

const createTimesheet = async (ctx: UseEmployeeContext, timesheet: Timesheet): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(createTimesheet.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id) {
      const res = await employeesService.createTimesheet(state.employee.id, timesheet);
      if(res.data.data.timesheets && res.data.data.timesheets.length > 0) {
        state.employee.timesheets = res.data.data.timesheets;
        return Promise.resolve(res);
      } else {
        return Promise.reject(Error('No timesheets available.'));
      }
    }
    else return Promise.reject(new Error('Employee not set in state or has no id.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.employeeValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.employeeBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(createTimesheet.name, false);
  }
};
const updateTimesheet = async (ctx: UseEmployeeContext, timesheet: Timesheet): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(updateTimesheet.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id) {
      const res = await employeesService.editTimesheet(state.employee.id, timesheet);
      if(res.data.data.timesheets && res.data.data.timesheets.length > 0) {
        state.employee.timesheets = res.data.data.timesheets;
        return Promise.resolve(res);
      } else {
        return Promise.reject(Error('No timesheets available.'));
      }
    }
    else return Promise.reject(new Error('Employee not set in state or has no id.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.employeeValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.employeeBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateTimesheet.name, false);
  }
};
const saveTimesheet = async (ctx: UseEmployeeContext, timesheet: Timesheet): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(saveTimesheet.name, true);
  try {
    if((timesheet.id && timesheet.id <= 0) || !timesheet.id) return createTimesheet(ctx, timesheet);
    else return updateTimesheet(ctx, timesheet);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveTimesheet.name, false);
  }
};

const fetchWorkDays = async (ctx: UseEmployeeContext): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions, filterWorkDays } } = ctx;
  loadingActions.actions.set(fetchWorkDays.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id != null) {
      const urlParams = filterWorkDays.getters.filterUrlQuery.value;
      const res = await employeesService.getWorkDays(state.employee.id, urlParams);
      if(res.data.data.workDays && res.data.data.workDays.length > 0) {
        state.employee.workDays = res.data.data.workDays;
        return Promise.resolve(res);
      } else {
        state.employee.workDays = [];
        return Promise.reject(Error('No workdays available.'));
      }
    } else return Promise.reject(Error('No employee available in state or id not provided.'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchWorkDays.name, false);
  }
};

const setTimesheet = async (ctx: UseEmployeeContext, timesheet: Timesheet): Promise<Timesheet> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(setTimesheet.name, true);
  try {
    const timesheetIdx = state.employee?.timesheets?.findIndex(ts => ts.id === timesheet.id);
    if(timesheetIdx && timesheetIdx >= 0) state.employee?.timesheets?.splice(timesheetIdx, 1, timesheet);
    if(timesheetIdx === -1) state.employee?.timesheets?.splice(0, 0, timesheet);
    return Promise.resolve(timesheet);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(setTimesheet.name, false);
  }
};

const fetchAbsencePeriods = async (ctx: UseEmployeeContext): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions, filterAbsencePeriods } } = ctx;
  loadingActions.actions.set(fetchAbsencePeriods.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id != null) {
      const urlParams = filterAbsencePeriods.getters.filterUrlQuery.value;
      const res = await employeesService.getAbsencePeriods(state.employee.id, urlParams);
      if(res.data.data.absencePeriods && res.data.data.absencePeriods.length > 0) {
        state.employee.absencePeriods = res.data.data.absencePeriods;
        return Promise.resolve(res);
      } else {
        state.employee.absencePeriods = [];
        return Promise.reject(Error('No absencePeriods available.'));
      }
    } else return Promise.reject(Error('No employee available in state or id not provided.'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchAbsencePeriods.name, false);
  }
};
const createAbsencePeriod = async (ctx: UseEmployeeContext, absencePeriod: AbsencePeriod): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(createAbsencePeriod.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id) {
      const resp = await employeesService.createAbsencePeriod(state.employee.id, absencePeriod);
      fetchAbsencePeriods(ctx);
      return Promise.resolve(resp);
    }
    else return Promise.reject(new Error('Employee not set in state or has no id.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.employeeValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.employeeBusinessErrors = err.response.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(createAbsencePeriod.name, false);
  }
};
const updateAbsencePeriod = async (ctx: UseEmployeeContext, absencePeriod: AbsencePeriod): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(updateAbsencePeriod.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id) {
      const resp = await employeesService.updateAbsencePeriod(state.employee.id, absencePeriod);
      fetchAbsencePeriods(ctx);
      return Promise.resolve(resp);
    }
    else return Promise.reject(new Error('Employee not set in state or has no id.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.employeeValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.employeeBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateAbsencePeriod.name, false);
  }
};
const saveAbsencePeriod = async (ctx: UseEmployeeContext, absencePeriod: AbsencePeriod): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(saveTimesheet.name, true);
  try {
    if((absencePeriod.id && absencePeriod.id <= 0) || !absencePeriod.id) {
      const resp = await createAbsencePeriod(ctx, absencePeriod);
      return Promise.resolve(resp);
    } else {
      const resp = await updateAbsencePeriod(ctx, absencePeriod);
      return Promise.resolve(resp);
    }
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveTimesheet.name, false);
  }
};
const deleteAbsencePeriod = async (ctx: UseEmployeeContext, absencePeriod: AbsencePeriod): Promise<AxiosResponse<JsonResource<Employee>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(deleteAbsencePeriod.name, true);
  clearEmployeeErrors(ctx);
  try {
    if(state.employee !== null && state.employee.id != null && absencePeriod.id && absencePeriod.id > 0) {
      const res = await employeesService.deleteAbsencePeriod(state.employee.id, absencePeriod.id);
      fetchAbsencePeriods(ctx);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No employee available in state or id not provided, or the provided absence period has no id.'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteAbsencePeriod.name, false);
  }
};

export const actions = {
  fetchEmployee,
  createEmployee,
  updateEmployee,
  deleteEmployee,
  setEmployee,
  search,
  clearEmployeeValidationErrors,
  clearEmployeeBusinessErrors,
  clearEmployeeErrors,
  createWage,
  updateWage,
  deleteWage,
  saveWage,
  fetchTimesheets,
  saveTimesheet,
  updateTimesheet,
  createTimesheet,
  fetchWorkDays,
  setTimesheet,
  fetchAbsencePeriods,
  updateAbsencePeriod,
  createAbsencePeriod,
  saveAbsencePeriod,
  deleteAbsencePeriod,
};
