
    import { PropType } from 'vue';
    import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    import { PurchaseInvoice, PurchaseInvoiceRecordType } from '@/modules/purchase-invoices/types/entities';
    import { calculatePaymentTotal, calculateGrandTotal, calculatePaymentDiscountByPercentage } from '@/modules/purchase-invoices/helpers'

    const purchaseInvoices = namespace('purchaseInvoices');

    @Component
    export default class PurchaseInvoiceAmountsComponent extends Vue {

        // props
        @PropSync('purchaseInvoice', { type: Object as PropType<PurchaseInvoice>, required: true }) purchaseInvoiceSync!: PurchaseInvoice;
        @PropSync('paymentDiscountPercentage', { type: Number }) paymentDiscountPercentageSync!: number;
        
        @Prop({type: Object, default: () => {}}) validationErrors!: any;
        @Prop({type: Array, default: () => [] as PurchaseInvoiceRecordType[]}) recordTypes!: PurchaseInvoiceRecordType[];

        // local/children state
        state: {} = {}
        content: {} = {}

        // handlers
        onInputPaymentDiscount(paymentDiscount: number) {
            const invoice = this.purchaseInvoiceSync ? {...this.purchaseInvoiceSync, paymentDiscount: paymentDiscount} as PurchaseInvoice : null
            const paymentTotal = invoice ? calculatePaymentTotal(invoice) : 0
            this.purchaseInvoiceSync = { ...this.purchaseInvoiceSync, paymentTotal: paymentTotal }
        }
        onInputTotal(total: number) {
            const invoice = this.purchaseInvoiceSync ? {...this.purchaseInvoiceSync, total: total} as PurchaseInvoice : null
            // first get the current discount percentage if any, apply the same percentage later on 
            const discountPercentage = this.currentPaymentDiscountPercentage;
            const grandTotal = invoice ? calculateGrandTotal(invoice) : 0
            const paymentDiscount = invoice ? calculatePaymentDiscountByPercentage(invoice, discountPercentage) : 0
            const paymentTotal = invoice ? calculatePaymentTotal(invoice) : 0
            this.purchaseInvoiceSync = {...this.purchaseInvoiceSync, total: total, grandTotal: grandTotal, paymentDiscount: paymentDiscount, paymentTotal: paymentTotal }
        }
        onInputTaxTotal(taxTotal: number) {
            const invoice = this.purchaseInvoiceSync ? {...this.purchaseInvoiceSync, taxTotal: taxTotal} as PurchaseInvoice : null
            // first get the current discount percentage if any, apply the same percentage later on 
            const discountPercentage = this.currentPaymentDiscountPercentage;
            const grandTotal = invoice ? calculateGrandTotal(invoice) : 0
            const paymentDiscount = invoice ? calculatePaymentDiscountByPercentage(invoice, discountPercentage) : 0
            const paymentTotal = invoice ? calculatePaymentTotal(invoice) : 0
            this.purchaseInvoiceSync = {...this.purchaseInvoiceSync, taxTotal: taxTotal, grandTotal: grandTotal, paymentDiscount: paymentDiscount, paymentTotal: paymentTotal }
        }
        onInputPaymentDiscountPercentage(discountPercentage: number) {
            let invoice = this.purchaseInvoiceSync ? {...this.purchaseInvoiceSync} as PurchaseInvoice : null
            let paymentDiscount = invoice ? calculatePaymentDiscountByPercentage(invoice, discountPercentage) : 0;
            invoice = {...invoice, paymentDiscount: paymentDiscount }
            const paymentTotal = invoice ? calculatePaymentTotal(invoice) : 0
            this.purchaseInvoiceSync = {...this.purchaseInvoiceSync, paymentDiscount: paymentDiscount, paymentTotal: paymentTotal }
        }

        // methods
        

        // getters
        get currentPaymentDiscountPercentage(): number {
            const invoice = this.purchaseInvoiceSync || null;
            if(invoice) {
                const paymentDiscount = (invoice && invoice.paymentDiscount) || 0;
                const grandTotal = (invoice && invoice.grandTotal) || 1;
                return Math.round(paymentDiscount/grandTotal*10000)/100;
            }
            return 0;
        }
        

        // setters

        // watchers

        // lifecycle
    }
