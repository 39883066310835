import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosStatic, AxiosTransformer } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import { ThisTypedComponentOptionsWithArrayProps } from 'vue/types/options';
import { VueConstructor } from 'vue/types/umd';
import { ConfigurationInterface } from '.';
import { store } from '@/core/core.module';
import { appMessageStore } from '@/core/store/app-messages.store';
import { translate as translateValidation } from '@/core/helpers/validation';
import { AppMessage, AppMessageType } from '@/core/components/app-message/entities';
import authStore from "@/modules/login/store";
import router from "@/router";

export class AxiosConfiguration implements ConfigurationInterface {

    axios: AxiosStatic|null = null;

    constructor(data?: Partial<ConfigurationInterface>) {
      Object.assign(this, data);
      if(!this.axios) this.axios = axios;
    }

    camelCaseKeysForSuccessResponse = (response: AxiosResponse): AxiosResponse|Promise<AxiosResponse> => {
      if(response && response.data) response = {...response, data: camelcaseKeys(response.data, { deep: true }) };
      return response;
    }

    camelCaseKeysForErrorResponse = (error: AxiosError): AxiosError|Promise<AxiosError> => {
      const stopPaths: string[] = []; //['errors']
      if(error && error.response) error.response = {...error.response, data: camelcaseKeys(error.response.data, { deep: true, stopPaths: stopPaths }) };
      return Promise.reject(error);
    }

    snakeCaseKeysForRequest = (config: AxiosRequestConfig): AxiosRequestConfig|Promise<AxiosRequestConfig> => {
      const endPointsToSnakecase = [
        // rest
        '/account','/api/v1/invoices','/api/v1/purchase-invoices','/api/v1/accounting-ledgers',
        // commands
        '/api/v1/commands/search-contacts','/api/v1/commands/search-billing-addresses','/api/v1/commands/new-sales-invoice','/api/v1/commands/new-purchase-invoice',
        '/api/v1/commands/update-project-relationship-totals','/api/v1/commands/update-line-item-totals','/api/v1/commands/activate-accounting-ledger',
      ];
      const regex = RegExp('(' + endPointsToSnakecase.join('|') + ').*');
      const endpointMatched = config.url ? regex.exec(config.url) : null;
      if(config.data && endpointMatched) {
        config = {...config, data: snakecaseKeys(config.data, { deep: true })};
      }
      return config;
    }

    mutateLoadingModuleOnRequest = (config: AxiosRequestConfig): AxiosRequestConfig|Promise<AxiosRequestConfig> => {
      store.dispatch('loading/SET_LOADING', true);
      return config;
    }

    handleHttpOrServerErrors = (error: AxiosError): AxiosError|Promise<AxiosError> => {
      const resp = error.response;

      if (resp?.status === 401) {
        if (router.currentRoute.name !== 'login') {
          authStore.auth.actions
            .setAuth(null)
            .finally(() => {
              if (! authStore.auth.getters.isAuthenticated.value) {
                router.push({ name: 'login' });
              }
            });
        }
      }

      // if resource is not available to a User, a "403 Forbidden" should be returned
      const errorMessage: AppMessage = {
        message: resp && resp.status === 422 ? 'common.messages.invalidFormData' :
          resp && resp.status === 403 ? 'common.messages.forbidden' :
            resp && resp.status === 409 ? 'common.messages.conflict' :
              'common.messages.unexpectedError',
        type: resp && (resp.status === 422 || resp.status === 403 || resp.status === 409) ? AppMessageType.WARNING : AppMessageType.ERROR,
        ttl: 7000,
        dismissed: false,
      };

      if(resp?.status !== 401) {
        appMessageStore.actions.set(errorMessage).then(() => error);
      }

      // translate validation if there is an `errors` object in the response data
      if(resp && resp.data) {
        if(resp.data.errors && typeof resp.data.errors === 'object') {
          Object.keys(resp.data.errors).forEach(function(key){ resp.data.errors[key] = translateValidation(resp.data.errors[key]); });
        }
      }

      return Promise.reject(error);
    }

    apply = (vue: VueConstructor<Vue>): AxiosStatic => {
      if(!this.axios) throw new Error('axios property is not provided. Please inject through constructor.');

      // plugin config
      // interceptors
      this.axios.interceptors.request.use(this.snakeCaseKeysForRequest);
      this.axios.interceptors.request.use(this.mutateLoadingModuleOnRequest);
      this.axios.interceptors.response.use(this.camelCaseKeysForSuccessResponse,this.camelCaseKeysForErrorResponse);
      this.axios.interceptors.response.use((val: AxiosResponse) => Promise.resolve(val), this.handleHttpOrServerErrors);

      // defaults
      this.axios.defaults.baseURL = `${ process.env.VUE_APP_API_URL }`;
      this.axios.defaults.withCredentials = true;

      // register plugins
      vue.use(VueAxios, this.axios);

      return this.axios;
    }

    getVueInstanceConfig = (): ThisTypedComponentOptionsWithArrayProps<Vue, object, object, object, string, object, object, object> => {
      return {};
    }
}

export const configuration = new AxiosConfiguration();
