import { JsonResource, Pagination } from '@/core/types/Entities';
import { Contact } from '@/modules/contacts/types/entities';
import { BankAccountEntity, Client, TeleinformationDetail, TeleinformationDetailEntity } from '@/modules/entities/types/entities';
import { ClientService } from '@/services/client.service';
import { ContactsService } from '@/services/contacts.service';
import {AxiosError, AxiosResponse} from 'axios';
import { UseClientContext } from '../types/useClient';
import useFilter from '../useFilter';

const clientService = new ClientService();
const contactService = new ContactsService();

const setClient = async (ctx: UseClientContext, client: Client | null): Promise<void> =>
  new Promise((resolve, reject) => {
    const { state } = ctx;
    try {
      state.client = client;
      // set some default values if they're not provided
      if (state.client && (state.client.bankAccounts == null || state.client.bankAccounts.length !== 2)) {
        state.client.bankAccounts = state.client.bankAccounts ? state.client.bankAccounts.slice(0, 1) : [];
        state.client.bankAccounts =
          state.client.bankAccounts.length === 0
            ? [new BankAccountEntity({ id: -1 }), new BankAccountEntity({ id: -2 })]
            : state.client.bankAccounts.length === 1
              ? [...state.client.bankAccounts, new BankAccountEntity({ id: -1 })]
              : state.client.bankAccounts;
      }
      if (state.client && (state.client.teleinformations == null || !state.client.teleinformations.length)) {
        state.client.teleinformations = [
          new TeleinformationDetailEntity({ id: -1 }),
          new TeleinformationDetailEntity({ id: -2 }),
          new TeleinformationDetailEntity({ id: -3 }),
          new TeleinformationDetailEntity({ id: -4 }),
        ];
      }
      resolve();
    } catch (err: any) {
      reject(err);
    }
  });
const clearClientValidationErrors = (ctx: UseClientContext): void => {
  const { state } = ctx;
  state.clientValidationErrors = null;
};
const fetchClient = async (ctx: UseClientContext, id: number): Promise<AxiosResponse<JsonResource<Client>>> => {
  const {
    composables: { loadingActions },
  } = ctx;
  loadingActions.actions.set(fetchClient.name, true);
  try {
    clearClientValidationErrors(ctx);
    const result = await clientService.getClientById(id);
    setClient(ctx, result.data.data);
    return Promise.resolve(result);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchClient.name, false);
  }
};
const createClient = async (ctx: UseClientContext): Promise<AxiosResponse<JsonResource<Client>>> => {
  const {
    state,
    composables: { loadingActions },
  } = ctx;
  loadingActions.actions.set(createClient.name, true);
  state.clientValidationErrors = null;
  try {
    if (state.client !== null) {
      clearClientValidationErrors(ctx);
      const result = await clientService.createClient(state.client);
      setClient(ctx, result.data.data);
      return Promise.resolve(result);
    } else return Promise.reject(Error('No client available in state'));
  } catch (err: any) {
    if (err.response && err.response.status === 422) state.clientValidationErrors = err.response.data.errors;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(createClient.name, false);
  }
};
const updateClient = async (ctx: UseClientContext): Promise<AxiosResponse<JsonResource<Client>>> => {
  const {
    state,
    composables: { loadingActions },
  } = ctx;
  loadingActions.actions.set(updateClient.name, true);
  state.clientValidationErrors = null;
  try {
    if (state.client !== null && state.client.id != null) {
      clearClientValidationErrors(ctx);
      const result = await clientService.editClientById(state.client);
      setClient(ctx, result.data.data);
      return Promise.resolve(result);
    } else return Promise.reject(Error('No client available in state or id not provided'));
  } catch (err: any) {
    if (err.response && err.response.status === 422) state.clientValidationErrors = err.response.data.errors;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateClient.name, false);
  }
};
const deleteClient = async (ctx: UseClientContext): Promise<AxiosResponse<void>> => {
  const {
    state,
    composables: { loadingActions },
  } = ctx;
  loadingActions.actions.set(deleteClient.name, true);
  state.clientValidationErrors = null;
  try {
    if (state.client !== null && state.client.id != null) {
      const result = await clientService.deleteClientById(state.client.id);
      return Promise.resolve(result);
    } else return Promise.reject(Error('No client available in state or id not provided'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteClient.name, false);
  }
};
const search = async (ctx: UseClientContext, query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<Client[]>>> => {
  const {
    composables: { filter, loadingActions },
  } = ctx;
  loadingActions.actions.set(search.name, true);
  try {
    let urlParams = null;
    if (useAsFilter) {
      if (query) filter.actions.setFilter('search', query);
      else filter.actions.deleteFilter('search');
      urlParams = filter.getters.filterUrlQuery.value;
    } else {
      const f = useFilter();
      if (query) f.actions.setFilter('search', query);
      urlParams = f.getters.filterUrlQuery.value;
    }
    const url = clientService.getBaseEndpoint() + `${urlParams ? '?' + urlParams.toString() : ''}`;
    const result = await clientService.getAllClients(url);
    return Promise.resolve(result);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(search.name, false);
  }
};
const setTeleinformation = async (ctx: UseClientContext, teleinformation: TeleinformationDetail): Promise<void> =>
  new Promise((resolve, reject) => {
    const {
      state,
      // composables: { loadingActions },
    } = ctx;
    // loadingActions.actions.set(setTeleinformation.name, true)
    try {
      if (state.client) {
        const idx = state.client.teleinformations.findIndex((t: TeleinformationDetail) => t.id === teleinformation.id);
        state.client.teleinformations.splice(idx === -1 ? 0 : idx, idx === -1 ? 0 : 1, teleinformation);
        resolve();
        return;
      }
      reject();
    } catch (err: any) {
      reject(err);
    } finally {
      // loadingActions.actions.set(setTeleinformation.name, false)
    }
  });
const removeTeleinformation = async (ctx: UseClientContext, teleinformation: TeleinformationDetail): Promise<void> =>
  new Promise((resolve, reject) => {
    const {
      state,
      // composables: { loadingActions },
    } = ctx;
    // loadingActions.actions.set(removeTeleinformation.name, true)
    try {
      if (state.client) {
        const idx = state.client.teleinformations.findIndex((t: TeleinformationDetail) => t.id === teleinformation.id);
        state.client.teleinformations.splice(idx === -1 ? 0 : idx, idx === -1 ? 0 : 1);
        resolve();
        return;
      }
      reject();
    } catch (err: any) {
      reject(err);
    } finally {
      // loadingActions.actions.set(removeTeleinformation.name, false)
    }
  });
const addContact = async (ctx: UseClientContext, contact: number | Contact): Promise<AxiosResponse<JsonResource<Client>>> => {
  const {
    state,
    composables: { loadingActions },
  } = ctx;
  loadingActions.actions.set(addContact.name, true);
  try {
    if (state.client !== null && state.client.id != null) {
      const contactId = contact && typeof contact === 'number' ? contact : (contact as Contact).id;
      const idx = state.client.contacts.findIndex((c: Contact) => c.id === contactId);
      if (idx > -1) return Promise.reject('Contact already added.');
      const result = await clientService.toggleAssociationContactToClient(state.client.id, contactId);
      state.client.contacts = result.data.data.contacts;
      return Promise.resolve(result);
    } else return Promise.reject(Error('No client available in state or id not provided'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(addContact.name, false);
  }
};
const removeContact = async (ctx: UseClientContext, contact: number | Contact, forceDelete = false): Promise<AxiosResponse<JsonResource<Client>>> => {
  const {
    state,
    composables: { loadingActions },
  } = ctx;
  loadingActions.actions.set(removeContact.name, true);
  try {
    if (state.client !== null && state.client.id != null) {
      const contactId = contact && typeof contact === 'number' ? contact : (contact as Contact).id;
      const idx = state.client.contacts.findIndex((c: Contact) => c.id === contactId);
      if (idx === -1) return Promise.reject('Contact is not attached to Client, therefor cannot be detached.');
      const result = await clientService.toggleAssociationContactToClient(state.client.id, contactId);
      if (forceDelete) {
        await contactService.deleteContactById(contactId);
      }
      state.client.contacts = result.data.data.contacts;
      return Promise.resolve(result);
    } else return Promise.reject(Error('No client available in state or id not provided'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(removeContact.name, false);
  }
};

export const actions = {
  fetchClient,
  createClient,
  updateClient,
  deleteClient,
  setClient,
  search,
  clearClientValidationErrors,
  setTeleinformation,
  removeTeleinformation,
  addContact,
  removeContact,
};
