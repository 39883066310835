
  import { AbsencePeriod } from '@/modules/settings/types/entities';
  import { defineComponent, ref, PropType, watch, toRefs } from 'vue';

  export default defineComponent({
    name: 'AbsencePeriodModalDeleteForm',
    props: {
      value: { type: Object as PropType<AbsencePeriod | null>, default: null },
      validationErrors: {
        type: Object as PropType<Object | null>,
        default: () => ({
          // startDate: 'error',
          // endDate: 'error',
          // employee: 'error',
          // duration: 'error',
          // type: 'error',
          // comment: 'error',
        }),
      },
    },
    setup(props, { emit }) {
      const propsRefs = toRefs(props);
      const form = ref(props.value);
      watch(propsRefs.value, (v, vOld) => (form.value = v));
      watch(form, (f, fOld) => emit('input', f), { deep: true });

      return {
        form,
      };
    },
  });
