import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { LoadingState } from '@/modules/loading/store/types/LoadingState';
import { RootState } from '@/core/types/RootState';
import { Getters, Actions, Mutations } from '@/modules/loading/store/types/StoreTypes';

const getters: GetterTree<LoadingState, RootState> = {
	[Getters.GET_LOADING]: (state: LoadingState) => {
		return state.loading;
	}
}

const actions: ActionTree<LoadingState, RootState> = {
	[Actions.SET_LOADING]: ({ commit, state }: ActionContext<LoadingState, RootState>, payload: boolean) => {
		if (state.loading !== payload) {
			commit(Mutations.MUTATE_LOADING_STATE, payload);
		}
	}
}

const mutations: MutationTree<LoadingState> = {
	[Mutations.MUTATE_LOADING_STATE]: (state: LoadingState, payload: boolean) => {
		state.loading = payload;
	}
}

export const state: LoadingState = {
	loading: false
}

export const store: Module<LoadingState, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
