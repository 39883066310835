
	import { PropType } from 'vue';
    import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
    import { Project } from '@/modules/projects/types/entities';
    import Fuse from 'fuse.js'

	@Component
	export default class ProjectAutocompleteComponent extends Vue {

        @Prop({type: Array as PropType<Project[]>, default: () => []})
        value!: Project[];

		@Prop({type: Array, default: () => [] as Project[] })
        items!: Project[];
		
        // vars
        v_proxyValue: Project[] = this.value;
        v_originalValue: Project[] = this.value;
        v_searchValue: string|null = null
        itemsFuseOptions: object = { keys: ['text'], threshold: 0.4, includeScore: true, shouldSort: true, includeMatches:true, distance: 50, maxPatternLength: 32, minMatchCharLength: 2 };
		
		// handlers

        // methods
        getLabel(item: Project) {
            return item.projectName
        }
        compare(a: any, b: any) {
			if(a && b && a.id !== undefined && b.id !== undefined) return a.id === b.id
			return false
        }
        highlight(resultItem: any) {
            if(!resultItem.matches) { 
                console.warn('To use possible matches you should enable the "includeMatches" option in Fuse.'); 
                return null;
            }
            resultItem.matches.forEach((matchItem: any) => {
                const text = resultItem.item[matchItem.key];
                const result = []
                const matches = [].concat(matchItem.indices); // limpar referencia
                let pair = matches.shift()
                
                for (let i = 0; i < text.length; i++) {
                const char = text.charAt(i)
                if (pair && i == pair[0]) {
                    result.push('<mark>')
                }
                result.push(char)
                    if (pair && i == pair[1]) {
                        result.push('</mark>')
                        pair = matches.shift()
                    }
                }
                resultItem.highlight = result.join('');

                if(resultItem.children && resultItem.children.length > 0){
                    resultItem.children.forEach((child: any) => {
                        this.highlight(child);
                    });
                }
            });

            return resultItem.highlight;
        };
        createAutocompleteItem(item: Project, options?: { disabled?: boolean}) {

            // extra autocomplete options?
            const defaults = { disabled: false }
            const opts = Object.assign({}, defaults, options?options:{})

            return {
                disabled: opts.disabled,
                text: this.getLabel(item),
                value: item,
            }
        }
        filterWithFuse() {
            let results = [] as any[];
            if (this.searchValue && this.itemsFuseFilter) {
                results = this.itemsFuseFilter
                    .search(this.searchValue)
                    .map((result) => {
                        return { ...result.item, highlight: this.highlight(result) }
                    });
            }

            // Include the currently selected items in the results
            if (this.proxyValue) {
                this.proxyValue.forEach((selectedItem) => {
                    const inResults = results.find(item => item.value.id === selectedItem.id);
                    if (!inResults) {
                        results.push(this.createAutocompleteItem(selectedItem));
                    }
                });
            }

            return results;
        }

        // Method to remove a selected item
        removeItem(index: number) {
            if (this.proxyValue) {
                this.proxyValue.splice(index, 1);
                // Emit a change event
                this.$emit('change', this.proxyValue);
                // Set searchValue to the new value
                this.$emit('search', this.searchValue);
            }
        }
        
        // getters
        get searchValue(): string|null { return this.v_searchValue }
        get proxyValue(): Project[] { return this.v_proxyValue }
        get originalValue(): Project[] { return this.v_originalValue }
        get autocompleteItems(): any[] {
            // resulting autocomplete items
            let autocompleteItems = [] as { text: string, value: Project, disabled: boolean }[];
            // if initial values are not available in items, add them manually
            if (this.originalValue) {
                this.originalValue.forEach((originalItem) => {
                    const inItems = this.items.find(item => item.id === originalItem.id);
                    if (!inItems) {
                        autocompleteItems.push(this.createAutocompleteItem(originalItem, { disabled: true }));
                    }
                });
            }
            // add all other values
            this.items.forEach((item: Project) => autocompleteItems.push(this.createAutocompleteItem(item)));
            // add currently selected items
            if (this.proxyValue) {
                this.proxyValue.forEach((selectedItem) => {
                    const inItems = autocompleteItems.find(item => item.value.id === selectedItem.id);
                    if (!inItems) {
                        autocompleteItems.push(this.createAutocompleteItem(selectedItem));
                    }
                });
            }
            return autocompleteItems;
        }
        get itemsFuseFilter() {
            return this.autocompleteItems && this.autocompleteItems.length > 0 ? new Fuse(this.autocompleteItems || [], this.itemsFuseOptions) : null
        }
        get filteredItems() {

            // filter with fuse
            let fuseResults = this.filterWithFuse()

            // resulting autocomplete items
            let autocompleteItems = [] as any[];

            // map fuse result if there are matches
            if (fuseResults.length) {
                fuseResults.sort((a: any, b: any) => a.score < b.score ? -1 : (a.score > b.score) ? 1 : 0)
                autocompleteItems = [...fuseResults]
            } else {
                autocompleteItems = [...this.autocompleteItems]
            }
            return autocompleteItems;
        }

        // setters
        set searchValue(value: string|null) { this.v_searchValue = value && value.trim() ? value : null }
        set proxyValue(value: Project[]) { this.v_proxyValue = value }
        set originalValue(value: Project[]) { this.v_originalValue = value }

        // watchers
        @Watch('value', { immediate: true, deep: true })
        watchOnValueChange(newValue: Project[]) {
            // set the internal value from the outside world
            this.proxyValue = newValue;
        }

        @Watch('searchValue')
        watchOnSearch(newValue: string|null) {
            this.$emit('search',newValue)
        }

	}
