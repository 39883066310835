
  import { computed, defineComponent, ref, watch } from 'vue';
  import store from '@/modules/calendar/store';
  import { CalendarSidebarDialog } from '../types/CalendarSidebarDialog';
  import helpers from '@/modules/calendar/services/helper.service';
  import dayjs from 'dayjs';

  export default defineComponent({
    name: 'SidemenuDetailsMoveProject',
    components: {
      sidemenu: () =>
        import(/* webpackChunkName: "plannerSidemenu" */ '@/modules/calendar/components/sidemenu.component.vue'),
    },
    setup(props) {
      const offsetDate = ref();
      const newDate = ref();
      const offsetDatePicker = ref(false);
      const newDatePicker = ref(false);
      const saveDisabled = computed(() => {
        const firstDay = store.calendar.getters.moveProjectByDays.value?.firstDay;
        const lastDay = store.calendar.getters.moveProjectByDays.value?.lastDay;
        let dateInBetweenProjectStartAndEnd = false;
        if (newDate.value && offsetDate.value && firstDay && lastDay) {
          dateInBetweenProjectStartAndEnd =
            (dayjs.utc(offsetDate.value).isAfter(dayjs.utc(firstDay)) &&
              dayjs.utc(offsetDate.value).isBefore(dayjs.utc(lastDay))) ||
            dayjs.utc(offsetDate.value).isSame(dayjs.utc(firstDay)) ||
            dayjs.utc(offsetDate.value).isSame(dayjs.utc(lastDay));
        }
        return !dateInBetweenProjectStartAndEnd;
      });
      const daysPicker = ref(false);
      const daysInput = ref();
      const days = computed(() => {
        if (newDate.value && offsetDate.value) {
          return -1 * dayjs.utc(offsetDate.value).diff(dayjs.utc(newDate.value), 'day');
        }
        return 0;
      });
      const applyDays = () => {
        const days = daysInput.value;
        if (days >= 0)
          newDate.value = dayjs
            .utc(offsetDate.value)
            .add(days, 'day')
            .format('YYYY-MM-DD');
        else
          newDate.value = dayjs
            .utc(offsetDate.value)
            .subtract(Math.abs(days), 'day')
            .format('YYYY-MM-DD');
        daysPicker.value = false;
      };

      return {
        environment: store.calendar,
        helpers,
        CalendarSidebarDialog,
        offsetDate,
        newDate,
        offsetDatePicker,
        newDatePicker,
        saveDisabled,
        days,
        applyDays,
        daysPicker,
        daysInput,
      };
    },
  });
