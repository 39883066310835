import { Module } from 'vuex';
import { InvoiceState } from '@/modules/purchase-invoices/store/types/InvoiceState';
import { RootState } from '@/core/types/RootState';
import {actions} from './actions';
import {mutations} from './mutations';
import {state} from './state';
import {getters} from './getters';

export const store: Module<InvoiceState, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};