import { Component, Vue } from 'vue-property-decorator';

@Component
export class OneToFiveMixin extends Vue {
    getGenericStyleObject(styleNo: number): { fontSize?: string; "--color-hover": string; "--color": string; "--text-color": string  } | undefined {
        switch (styleNo) {
            case -2:
                return {
                    fontSize: "18px",
                    "--color-hover": "#ff040a",
                    "--color": "#FF373C",
                    "--text-color": "#000000"
                };
            case -1:
                return {
                    fontSize: "12px",
                    "--color-hover": "#cb0404",
                    "--color": "#FA0808",
                    "--text-color": "#000000"
                };
            case 0:
                return {
                    "--color-hover": "#1b64c9",
                    "--color": "#337DE4",
                    "--text-color": "#ffffff"
                };
            case 1:
                return {
                    "--color-hover": "#02a592",
                    fontSize: "12px",
                    "--color": "#03D7BF",
                    "--text-color": "#000000"
                };
            case 2:
                return {
                    fontSize: "18px",
                    "--color-hover": "#007265",
                    "--color": "#00A592",
                    "--text-color": "#000000"
                };
            default:
                break;
        }
    }

    getIcon(styleNo: number): string {
        if (styleNo < 0) {
            return "-";
        }

        if (styleNo > 0) {
            return "+";
        }
        return "0";
    }
}
