import { reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import useFilter, {createState as createFilterState} from '@/composables/useFilter';
import { UseAccountingLedgerListState, UseAccountingLedgerListComposables, UseAccountingLedgerListContext } from '@/composables/types/useAccountingLedgerList';
import { merge } from 'lodash';
import { Options } from '@/composables/types/composable';
import { actions as accountingLedgerListActions } from '@/composables/actions/useAccountingLedgerList';
import { useDynamicProps } from './useDynamicProps';
import useList from '@/composables/useList';
import {AccountingLedger} from "@/modules/accounting/types/entities";
/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseAccountingLedgerListState>): UseAccountingLedgerListState => {
  return merge(
    {
      expandedIds: [],
    },
    state
  );
};

export default function useAccountingLedgerList(options?: Options<UseAccountingLedgerListState, UseAccountingLedgerListComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    list: useList<AccountingLedger>(),
    filter: useFilter(),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseAccountingLedgerListContext = { state, composables };
  const actions = wrapActions(ctx, accountingLedgerListActions);

  // return state and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // searching
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      // content
      accountingLedgers: computed(() => ctx.composables.list.getters.items.value),
      currentAccountingLedger: computed(() => ctx.composables.list.getters.items.value.find(al => al.active)),
    },
    actions: {
      ...ctx.composables.filter.actions,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...ctx.composables.list.actions,
      ...actions,
    },
  };
}