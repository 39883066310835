

	import {Component, Emit, Prop, Vue} from "vue-property-decorator";
	import {ProjectUser} from "@/modules/projects/types/entities";

	@Component
	export default class ProjectPeopleListComponent extends Vue {
		@Prop() userGroup!: string;
		@Prop() users!: ProjectUser[];
		@Prop() filterUsersIds!: number[];

		projectPeopleListState = {
			show: false
		};

		isUserChecked(user: ProjectUser) {
			return this.filterUsersIds.some(id => id === user.id);
		}

		@Emit('onToggleUserGroup')
		emitToggleUserGroup(user: ProjectUser) {
			const userGroup = this.userGroup;
			return {
				userId: user.id,
				userGroup
			}
		}
	}
