
  import Vue from 'vue';
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import '@/styles/input.scss';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { mixins } from 'vue-class-component';
  import { Supplier } from '../../types/entities';
  import { namespace } from 'vuex-class';
  import {
    Actions as SuppliersActions,
    Getters as SuppliersGetters,
  } from '../../store/modules/suppliers/types/StoreTypes';
  import { GetAddressMixin } from '@/mixins/get-address-mixin.component';
  import { AxiosError, AxiosResponse } from 'axios';
  import { JsonResource } from '@/core/types/Entities';

  const suppliers = namespace('entities/suppliers');

  @Component({
    mixins: [GetAddressMixin, validationMixin],
    validations: {
      formData: {
        commercialName: { required },
        vatNumber: {},
        street: {},
        streetNumber: {},
        postalCode: {},
        city: {},
      },
    },
  })
  export default class SupplierBranchComponent extends mixins(Vue, validationMixin) {
    @suppliers.Action(SuppliersActions.CREATE_SUPPLIER_BRANCH) createSupplierBranch!: (payload: {
      parentSupplierId: number;
      supplier: Supplier;
    }) => Promise<void>;
    @suppliers.Action(SuppliersActions.UPDATE_SUPPLIER_BRANCH) updateSupplierBranch!: (payload: {
      parentSupplierId: number;
      supplier: Supplier;
    }) => Promise<void>;
    @suppliers.Action(SuppliersActions.CHECK_FOR_EXISTING_SUPPLIER) checkForExistingSupplier!: (
      payload: Supplier
    ) => Promise<AxiosResponse<JsonResource<Supplier>>>;

    @suppliers.Getter(SuppliersGetters.GET_LOADING) isLoading!: boolean;

    // Parent supplier
    @Prop() parent!: Supplier;

    // Current edited supplier (optional)
    @Prop() supplier!: Supplier;

    autocomplete!: google.maps.places.Autocomplete;
    citiesAutocomplete!: google.maps.places.Autocomplete;
    postalAutocomplete!: google.maps.places.Autocomplete;
    fetchedSupplier: Supplier | null = null;
    isEditMode: boolean = false;
    validationErrors: any = null;

    formData: Supplier = {
      id: 0,
      name: '',
      commercialName: '',
      vatNumber: '',
      ssrNumber: '',
      bankAccounts: [],
      price: 0,
      priceComment: '',
      quality: 0,
      province: {
        id: 0,
        name: '',
      },
      parentSupplierId: 0,
      qualityComment: '',
      technicalExpertise: 0,
      aestheticQuality: 0,
      aestheticQualityComment: '',
      workforce: '',
      activities: '',
      speciality: '',
      comment: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      outboundKm: 0,
      preferredPaymentTerm: 0,
      preferredPaymentTermBoolean: false,
      preferredPaymentDiscountTerm: 0,
      preferredPaymentDiscountTermBoolean: false,
      preferredPaymentDiscountPercentage: 0,
      futureWork: 0,
      futureWorkComment: '',
      contacts: [],
      companyTypes: [],
      contractorTypes: [],
      teleinformations: [
        {
          id: 0,
          landlinePhone: '',
          landlinePhoneComment: '',
          mobilePhone: '',
          mobilePhoneComment: '',
          email: '',
          emailComment: '',
          isMainContactInformation: true,
          user: undefined,
          updatedAt: '',
        },
      ],
      website: '',
      createdAt: '',
      updatedAt: '',
      retainments: [],
    };

    get commercialNameErrors(): string[] {
      let errors: string[] = [];
      this.$v.formData.commercialName &&
        !this.$v.formData.commercialName.required &&
        errors.push(this.$t('suppliers.branchForm.error.required.commercialName') as string);
      return errors;
    }

    onCancel(): void {
      this.$emit('onCancel');
    }

    onSubmit(): void {
      this.$v.$touch();

      if (this.isEditMode) {
        this.submitFormFinally();
        return;
      }

      if (this.formData.commercialName) {
        this.checkForExistingSupplier(this.formData).then((result: AxiosResponse<JsonResource<Supplier>>) => {
          if (result.status === 204) {
            this.submitFormFinally();
            return;
          }
          if (this.fetchedSupplier) {
            if (this.fetchedSupplier.id !== result.data.data.id) {
              this.fetchedSupplier = result.data.data;
            } else {
              this.submitFormFinally();
            }
            return;
          }
          this.fetchedSupplier = result.data.data;
        });
      } else {
        this.submitFormFinally();
      }
    }

    submitFormFinally(): void {
      (this.isEditMode
        ? this.updateSupplierBranch({ parentSupplierId: this.parent.id, supplier: this.formData })
        : this.createSupplierBranch({ parentSupplierId: this.parent.id, supplier: this.formData })
      )
        .then(() => {
          this.$emit('onSuccess');
        })
        .catch((err: AxiosError) => {
          if (err.response && err.response.status === 422) {
            this.validationErrors = err.response.data.errors;
          } else {
            throw err;
          }
        });
    }

    deepCopy<T>(object: T): T {
      return JSON.parse(JSON.stringify(object));
    }

    initAutocomplete(): void {
      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.streetInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['geocode'] }
      );
      this.citiesAutocomplete = new google.maps.places.Autocomplete(
        (this.$refs.cityInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['(cities)'] }
      );
      this.postalAutocomplete = new google.maps.places.Autocomplete(
        (this.$refs.postalInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['(regions)'] }
      );

      this.autocomplete.setFields(['address_component']);
      this.citiesAutocomplete.setFields(['address_component']);
      this.postalAutocomplete.setFields(['address_component']);

      this.autocomplete.addListener('place_changed', this.fillInAddress);
      this.citiesAutocomplete.addListener('place_changed', this.fillInCity);
      this.postalAutocomplete.addListener('place_changed', this.fillInPostalCode);
    }

    fillInAddress(): void {
      const place = this.autocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];

        switch (addressType) {
          case 'route':
            this.formData.street = component.long_name;
            break;
          case 'postal_code':
            if (!this.formData.postalCode) this.formData.postalCode = component.short_name;
            break;
          case 'locality':
            if (!this.formData.city) this.formData.city = component.long_name;
            break;
          case 'street_number':
            this.formData.streetNumber = component.short_name;
            break;
          default:
            break;
        }
      }
    }

    fillInCity(): void {
      const place = this.citiesAutocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];
        if (addressType === 'locality') {
          this.formData.city = component.long_name;
        }
      }
    }

    fillInPostalCode(): void {
      const place = this.postalAutocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];
        if (addressType === 'postal_code') {
          this.formData.postalCode = component.short_name;
        }
      }
    }

    created(): void {
      // Only for edit
      if (this.supplier) {
        this.isEditMode = true;
        this.formData = this.deepCopy<Supplier>(this.supplier);
      }
      this.formData.parentSupplierId = this.parent.id;
    }

    mounted(): void {
      this.initAutocomplete();
    }

    @Watch('fetchedSupplier')
    onFetchedSupplierChange(val: Supplier): void {
      this.$nextTick(() => {
        (this.$refs.modalContent as HTMLElement).scrollTop = (this.$refs.modalContent as HTMLElement).scrollHeight;
      });
    }

    @Watch('formData.commercialName')
    onFormDataCommercialNameChange(): void {
      if (this.validationErrors && this.validationErrors.commercialName) this.validationErrors.commercialName = null;
    }
  }
