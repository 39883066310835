import helpers from '@/modules/calendar/services/helper.service';
import { WorkDaySequence } from '@/modules/calendar/interfaces/project/workDaySequence';
import CalendarService from '@/services/calendar.service';
import CalendarCommandsService from '@/services/calendar-commands.service';
import { UseCalendarContext } from '../types/useCalendar';
import { Quote } from '@/modules/calendar/interfaces/project/quote';
import { BaseProject } from '@/modules/calendar/interfaces/project/BaseCalendarProject';
import { WorkDay, WorkDayEntity } from '@/modules/calendar/interfaces/project/workDay';
import { CalendarViewType } from '@/modules/calendar/types/CalendarViewType';
import { CalendarDateRangeOption } from '../../types/CalendarDateRangeOption';
import { SubPeriod } from '../../interfaces/project/subPeriod';
import { cloneDeep } from 'lodash';
import { CalendarSidebarDialog } from '../../types/CalendarSidebarDialog';
import { AxiosResponse } from 'axios';
import { JsonResource } from '@/core/types/Entities';
import { CalendarCrudState } from '../../types/CalendarCrudState';
import { ContractorType } from '../../types/ContractorType';
import { CalendarDialog } from '../../types/CalendarDialog';
import useTeamList from '@/composables/useTeamList';
import { PlannerPeriod } from '../../interfaces/project/plannerPeriod';
import { AbsencesService } from '@/services/absences.service';
import useFilter from '@/composables/useFilter';
import { BaseDivision } from '../../interfaces/division/BaseDivision';
import {PlannerDay} from "@/modules/calendar/interfaces/project/PlannerDay";
import {BaseSupplier} from "@/modules/calendar/interfaces/supplier/BaseSupplier";
import store from '@/modules/calendar/store';
import {nextTick} from "vue";

const calendarService = new CalendarService();
const calendarCommandsService = new CalendarCommandsService();
const absencesService = new AbsencesService();

const setCommentToAdd = async (ctx: UseCalendarContext, comment: string) => {
  ctx.state.commentToAdd = comment;
  return Promise.resolve();
};

const setProject = async (ctx: UseCalendarContext, project: BaseProject) => {
  const { state } = ctx;
  const projectIndex = state.projects.findIndex(x => x.id === project.id);
  if (projectIndex > -1) {
    const projectsArr = [...state.projects];
    projectsArr.splice(projectIndex, 1, { id: 0, projectName: "", projectStatus: { id: 0, name: '' }, uniqueId: "" });
    state.projects = projectsArr;
    projectsArr[projectIndex] = project;
    state.projects = projectsArr;
  } else {
    state.projects = [...state.projects, project];
  }
  return Promise.resolve();
};

const setDivision = async (ctx: UseCalendarContext, division: BaseDivision) => {
  const { state } = ctx;
  const projectIndex = state.divisions.findIndex(x => x.id === division.id);
  if (projectIndex > -1) {
    const divisionsArr = [...state.divisions];
    divisionsArr.splice(projectIndex, 1, { id: 0, name: '' });
    state.divisions = divisionsArr;
    divisionsArr[projectIndex] = division;
    state.divisions = divisionsArr;
  } else {
    state.divisions = [...state.divisions, division];
  }
  return Promise.resolve();
};

const setSupplier = async (ctx: UseCalendarContext, supplier: BaseSupplier) => {
  const { state } = ctx;
  const idx = state.suppliers.findIndex(x => x.id === supplier.id);
  if (idx > -1) {
    const items = [...state.suppliers];
    items.splice(idx, 1, { id: 0, name: '' });
    state.suppliers = items;
    items[idx] = supplier;
    state.suppliers = items;
  } else {
    state.suppliers = [...state.suppliers, supplier];
  }
  return Promise.resolve();
};

const fetchProjectById = async (ctx: UseCalendarContext, projectId: number) => {
  const { composables: { loadingActions, silentActions, calendarSettings } } = ctx;
  if(!silentActions.getters.all.value[fetchProjectById.name]) loadingActions.actions.set(fetchProjectById.name, true);

  try {
    if(calendarSettings.getters.environment.value) {
      const res = await calendarService.fetchProjectById(calendarSettings.getters.environment.value.environmentId, projectId);
      setProject(ctx, res.data.data);
      return Promise.resolve(res);
    }
    throw new Error('No environmnet set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchProjectById.name, false);
    silentActions.actions.set(fetchProjectById.name, false);
  }
};

const fetchDivisionById = async (ctx: UseCalendarContext, divisionId: number) => {
  const { composables: { loadingActions, silentActions, calendarSettings } } = ctx;
  if(!silentActions.getters.all.value[fetchDivisionById.name]) loadingActions.actions.set(fetchDivisionById.name, true);

  try {
    if(calendarSettings.getters.environment.value) {
      const res = await calendarService.fetchDivisionById(calendarSettings.getters.environment.value.environmentId, divisionId);
      setDivision(ctx, res.data.data);
      return Promise.resolve(res);
    }
    throw new Error('No environmnet set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchDivisionById.name, false);
    silentActions.actions.set(fetchDivisionById.name, false);
  }
};

const fetchSupplierById = async (ctx: UseCalendarContext, supplierId: number) => {
  const { composables: { loadingActions, silentActions, calendarSettings } } = ctx;
  if(!silentActions.getters.all.value[fetchSupplierById.name]) loadingActions.actions.set(fetchSupplierById.name, true);
  try {
    if(calendarSettings.getters.environment.value) {
      const res = await calendarService.fetchSupplierById(calendarSettings.getters.environment.value.environmentId, supplierId);
      setSupplier(ctx, res.data.data);
      return Promise.resolve(res);
    }
    throw new Error('No environmnet set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchSupplierById.name, false);
    silentActions.actions.set(fetchSupplierById.name, false);
  }
};

const fetchAllExpandedProjects = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  if(!silentActions.getters.all.value[fetchAllExpandedProjects.name]) loadingActions.actions.set(fetchAllExpandedProjects.name, true);

  try {
    const fetchExpandedProjects = [...state.expandedProjects].map(async (projectId) => {
      const data = await fetchProjectById(ctx, projectId);
      return data;
    });

    const res = await Promise.all(fetchExpandedProjects).then((res) => {
      helpers.recalculateContractorColumn();
      return Promise.resolve(res);
    });

    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchAllExpandedProjects.name, false);
    silentActions.actions.set(fetchAllExpandedProjects.name, false);
  }
};

const fetchAllExpandedContractors = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  if(!silentActions.getters.all.value[fetchAllExpandedContractors.name]) loadingActions.actions.set(fetchAllExpandedContractors.name, true);

  try {
    const fetchExpandedSuppliers = [...state.expandedSuppliers].map(async (id) => {
      return await fetchSupplierById(ctx, id);
    });
    const fetchExpandedDivisions = [...state.expandedDivisions].map(async (id) => {
      return await fetchDivisionById(ctx, id);
    });

    const res = await Promise.all([...fetchExpandedSuppliers, ...fetchExpandedDivisions]).then((res) => {
      helpers.recalculateContractorColumn();
      return Promise.resolve(res);
    });

    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchAllExpandedContractors.name, false);
    silentActions.actions.set(fetchAllExpandedContractors.name, false);
  }
};

const fetchAllExpandedDivisions = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  if(!silentActions.getters.all.value[fetchAllExpandedDivisions.name]) loadingActions.actions.set(fetchAllExpandedDivisions.name, true);

  try {
    const fetchExpandedDivisions = [...state.expandedDivisions].map(async (divisionId) => {
      const data = await fetchDivisionById(ctx, divisionId);
      return data;
    });

    const res = await Promise.all(fetchExpandedDivisions).then((res) => {
      helpers.recalculateContractorColumn();
      return Promise.resolve(res);
    });

    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchAllExpandedDivisions.name, false);
    silentActions.actions.set(fetchAllExpandedDivisions.name, false);
  }
};

const fetchAllExpandedSuppliers = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  if(!silentActions.getters.all.value[fetchAllExpandedSuppliers.name]) loadingActions.actions.set(fetchAllExpandedSuppliers.name, true);

  try {
    const fetchExpandedSuppliers = [...state.expandedSuppliers].map(async (supplierId) => {
      const data = await fetchSupplierById(ctx, supplierId);
      return data;
    });

    const res = await Promise.all(fetchExpandedSuppliers).then((res) => {
      helpers.recalculateContractorColumn();
      return Promise.resolve(res);
    });

    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchAllExpandedSuppliers.name, false);
    silentActions.actions.set(fetchAllExpandedSuppliers.name, false);
  }
};

const setAllProjectsExpanded = async (ctx: UseCalendarContext, val: boolean) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(setAllProjectsExpanded.name, true);

  try {
    if (val) {
      state.expandedProjects = state.projects.map(x => x.id);
      await fetchAllExpandedProjects(ctx);
    } else {
      state.expandedProjects = [];
    }
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(setAllProjectsExpanded.name, false);
  }
};

const setAllDivisionsExpanded = async (ctx: UseCalendarContext, val: boolean) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(setAllDivisionsExpanded.name, true);

  try {
    if (val) {
      state.expandedDivisions = [...state.divisions.map(x => x.id)];
      await fetchAllExpandedDivisions(ctx);
    } else {
      state.expandedDivisions = [];
    }
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(setAllDivisionsExpanded.name, false);
  }
};

const setAllSuppliersExpanded = async (ctx: UseCalendarContext, val: boolean) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(setAllSuppliersExpanded.name, true);

  try {
    if (val) {
      state.expandedSuppliers = state.suppliers.map(x => x.id);
      await fetchAllExpandedSuppliers(ctx);
    } else {
      state.expandedSuppliers = [];
    }
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(setAllSuppliersExpanded.name, false);
  }
};

const fetchProjects = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, calendarSettings } } = ctx;
  loadingActions.actions.set(fetchProjects.name, true);

  try {
    // All close on load now. 
    if(calendarSettings.getters.environment.value) {
      const res = await calendarService.fetchProjects(calendarSettings.getters.environment.value.environmentId);
      state.projects = res.data.data;
      await setAllProjectsExpanded(ctx, false);
      return Promise.resolve(res);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchProjects.name, false);
  }
};

const fetchDivisions = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, calendarSettings } } = ctx;
  loadingActions.actions.set(fetchDivisions.name, true);

  try {
    if(calendarSettings.getters.environment.value) {
      const res = await calendarService.fetchDivisions(calendarSettings.getters.environment.value.environmentId);
      state.divisions = res.data.data;
      await setAllDivisionsExpanded(ctx, false);
      return Promise.resolve(res);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchDivisions.name, false);
  }
};

const fetchSuppliers = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, calendarSettings } } = ctx;
  loadingActions.actions.set(fetchSuppliers.name, true);

  try {
    if(calendarSettings.getters.environment.value) {
      const res = await calendarService.fetchSuppliers(calendarSettings.getters.environment.value.environmentId);
      state.suppliers = res.data.data;
      await setAllSuppliersExpanded(ctx, false);
      return Promise.resolve(res);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchSuppliers.name, false);
  }
};

const fetchPeriod = async (ctx: UseCalendarContext, periodId: number) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchPeriod.name, true);

  try {
    // const periodIdToFetch = periodId ? periodId : state.selectedPeriod?.id
    if(!periodId) throw new Error('No period id provided.');
    const res = await calendarService.fetchPlannerPeriodById(periodId);
    state.selectedPeriod = cloneDeep(res.data.data);

    if(state.selectedPeriod.workDays &&
        state.selectedPeriod.quote?.contractor &&
        helpers.isDivision(state.selectedPeriod.quote.contractor) &&
        state.selectedPeriod.quote.contractor.teams)
    {
      const teamWorkDays = helpers.makeTeamWorkDaysForDays(
        state.selectedPeriod.quote.contractor.teams,
        state.selectedPeriod.plannerStartDate,
        new WorkDayEntity({
          periodId: state.selectedPeriod.id,
          quoteId: state.selectedPeriod.quote.id,
        }),
        state.selectedPeriod.plannerEndDate
      );
      const contractorWorkDays = helpers.makeContractorWorkDaysForDays(
        state.selectedPeriod.quote.contractor,
        state.selectedPeriod.plannerStartDate,
        new WorkDayEntity({
          periodId: state.selectedPeriod.id,
          quoteId: state.selectedPeriod.quote.id,
        }),
        state.selectedPeriod.plannerEndDate
      );
      const mergedWorkDays = helpers.mergeWorkDays(state.selectedPeriod.workDays, [...teamWorkDays, ...contractorWorkDays]);
      const mergedSubPeriods = helpers.mergeSubPeriods(state.selectedPeriod.subPeriods, mergedWorkDays);
      state.selectedPeriod.workDays = mergedWorkDays;
      state.selectedPeriod.subPeriods = mergedSubPeriods;
    }
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchPeriod.name, false);
  }
};
const refetchPeriod = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(refetchPeriod.name, true);
  try {
    if(!state.selectedPeriod || !state.selectedPeriod?.id) throw new Error('No period id provided.');
    return fetchPeriod(ctx, state.selectedPeriod.id);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(refetchPeriod.name, false);
  }
};
const refetchExpandedProject = async (ctx: UseCalendarContext, project?: BaseProject, silent = true) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  await loadingActions.actions.set(refetchExpandedProject.name, true);
  try {
    if(project && state.expandedProjects.includes(project.id)) {
      // await silentActions.actions.set(fetchProjectById.name, silent)
      return fetchProjectById(ctx, project.id);
    }
    console.warn('Could not refetch expanded project, either it is not provided or the id is undefined.');
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(refetchExpandedProject.name, false);
  }
};

const refetchExpandedContractor = async (ctx: UseCalendarContext, contractor?: BaseSupplier | BaseDivision, silent = true) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  await loadingActions.actions.set(refetchExpandedContractor.name, true);
  try {
    if(contractor && helpers.isDivision(contractor) && state.expandedDivisions.includes(contractor.id)) {
      return fetchDivisionById(ctx, contractor.id);
    }
    if(contractor && helpers.isSupplier(contractor) && state.expandedSuppliers.includes(contractor.id)) {
      return fetchSupplierById(ctx, contractor.id);
    }
    console.warn('Could not refetch expanded contractor (supplier or division), either it is not provided or the id is undefined.');
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(refetchExpandedContractor.name, false);
  }
};

const refetchExpandedDivision = async (ctx: UseCalendarContext, division?: BaseDivision, silent = true) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  loadingActions.actions.set(refetchExpandedDivision.name, true);
  try {
    if(division && state.expandedDivisions.includes(division.id)) {
      // await silentActions.actions.set(fetchDivisionById.name, silent)
      return fetchDivisionById(ctx, division.id);
    }
    console.warn('Could not refetch expanded division, either it is not provided or the id is undefined.');
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(refetchExpandedDivision.name, false);
  }
};

const refetchExpandedSupplier = async (ctx: UseCalendarContext, supplier?: BaseSupplier, silent = true) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  loadingActions.actions.set(refetchExpandedSupplier.name, true);
  try {
    if(supplier && state.expandedSuppliers.includes(supplier.id)) {
      // await silentActions.actions.set(fetchDivisionById.name, silent)
      return fetchSupplierById(ctx, supplier.id);
    }
    console.warn('Could not refetch expanded division, either it is not provided or the id is undefined.');
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(refetchExpandedSupplier.name, false);
  }
};

const fetchDay = async (ctx: UseCalendarContext, quoteId: number, date: string) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchDay.name, true);

  try {
    const res = await calendarService.fetchDay(quoteId, date);
    state.selectedPlannerDay = res.data.data;

    if(state.selectedPlannerDay.workDays &&
        state.selectedPlannerDay.quote?.contractor &&
        helpers.isDivision(state.selectedPlannerDay.quote.contractor) &&
        state.selectedPlannerDay.quote.contractor.teams)
    {
      const teamWorkDays = helpers.makeTeamWorkDaysForDays(
        state.selectedPlannerDay.quote.contractor.teams,
        state.selectedPlannerDay.day,
        new WorkDayEntity({
          periodId: state.selectedPlannerDay.plannerPeriod?.id,
          quoteId: state.selectedPlannerDay.quote.id,
        })
      );
      const contractorWorkDays = helpers.makeContractorWorkDaysForDays(
        state.selectedPlannerDay.quote.contractor,
        state.selectedPlannerDay.day,
        new WorkDayEntity({
          periodId: state.selectedPlannerDay.plannerPeriod?.id,
          quoteId: state.selectedPlannerDay.quote.id,
        })
      );
      const mergedWorkDays = helpers.mergeWorkDays(cloneDeep(state.selectedPlannerDay.workDays), [...teamWorkDays, ...contractorWorkDays]);
      state.selectedPlannerDay.workDays = mergedWorkDays;
    }

    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchDay.name, false);
  }
};
const refetchDay = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(refetchDay.name, true);
  try {
    if(!state.selectedPlannerDay || !state.selectedPlannerDay.quote || !state.selectedPlannerDay?.day) throw new Error('No quote or day provided.');
    return fetchDay(ctx, state.selectedPlannerDay.quote.id, state.selectedPlannerDay.day);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(refetchDay.name, false);
  }
};

// TODO: this should be refactored in a `saveDay` and `savePeriod` action, with corresponding API endpoints
const saveSequences = async (ctx: UseCalendarContext) => {
  const { state, composables: { showSidebar, loadingActions, crudState, silentActions } } = ctx;
  const activeSidebarDialog = showSidebar.getters.unique(true).value;
  loadingActions.actions.set(saveSequences.name, true);

  try {
    let workDaysToHandle: WorkDay[] = [];
    let plannerPeriod: PlannerPeriod|undefined = undefined;
    let savePromises: Promise<AxiosResponse<JsonResource<WorkDaySequence[]>>>[] = [];
    let createPromises: Promise<AxiosResponse<JsonResource<WorkDay>>>[] = [];
    if (activeSidebarDialog == CalendarSidebarDialog.DAY) {
      workDaysToHandle = state.selectedPlannerDay?.workDays || [];
      plannerPeriod = state.selectedPlannerDay?.plannerPeriod;
    } else if (activeSidebarDialog == CalendarSidebarDialog.PERIOD) {
      workDaysToHandle = state.selectedPeriod?.workDays || [];
      plannerPeriod = state.selectedPeriod;
    }

    // save workDay sequences
    // workDaysToSave = workDaysToSave.filter(wd => wd.id > 0 && wd.contractorable && [ContractorType.SUPPLIER, ContractorType.TEAM].includes(wd.contractorable.type as ContractorType));
    const workDaysToSave = workDaysToHandle.filter(wd => wd.id > 0);
    savePromises = (workDaysToSave && workDaysToSave.map(wd => {
      return calendarService.saveWorkDaySequences(wd.id, wd.sequences);
    })) || [];

    // TODO: what to do with errors?
    // make sure all updates succeeded (because of locked timesheets that might block these updates)
    await Promise.all(savePromises);

    // create workDays if necessary
    if(plannerPeriod) {
      const plannerPeriodId = plannerPeriod.id;
      const workDaysToCreate = workDaysToHandle.filter(wd => (wd.id == null || wd.id < 0) && wd.sequences && wd.sequences.length > 0);
      createPromises = (workDaysToCreate && workDaysToCreate.map(wd => {
        return calendarService.createWorkDayForPlannerPeriod(plannerPeriodId, wd);
      })) || [];
    }

    await Promise.all(createPromises);

    // re-fetch period or day?
    if (activeSidebarDialog == CalendarSidebarDialog.DAY) refetchDay(ctx);
    else if (activeSidebarDialog == CalendarSidebarDialog.PERIOD) refetchPeriod(ctx);
    else {
      //
    }

    silentActions.actions.set(fetchAllExpandedProjects.name, true);
    silentActions.actions.set(fetchAllExpandedDivisions.name, true);
    fetchAllExpandedProjects(ctx);
    fetchAllExpandedDivisions(ctx);

    crudState.actions.set(CalendarCrudState.EDIT_WORK, false);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveSequences.name, false);
  }
};

const cancelSaveSequences = async (ctx: UseCalendarContext) => {
  const { state, composables: { showSidebar, loadingActions, crudState } } = ctx;
  const activeSidebarDialog = showSidebar.getters.unique(true).value as CalendarSidebarDialog;
  loadingActions.actions.set(cancelSaveSequences.name, true);

  try {
    const answer = confirm('Opgelet. Alle niet opgeslagen aanpassingen gaan verloren.');
    if(answer) {
      if (activeSidebarDialog == CalendarSidebarDialog.PERIOD) await refetchPeriod(ctx);
      else if (activeSidebarDialog == CalendarSidebarDialog.DAY) await refetchDay(ctx);
      else throw Error(`No such calendar dialog: ${activeSidebarDialog}`);
    }

    crudState.actions.set(CalendarCrudState.EDIT_WORK, false);
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(cancelSaveSequences.name, false);
  }
};

const setSequences = async (ctx: UseCalendarContext, workDay: WorkDay, sequences: number[], subPeriod?: SubPeriod, toggle = true) => {
  const { state, composables: { loadingActions, showSidebar, crudState } } = ctx;
  loadingActions.actions.set(setSequences.name, true);
  const activeSidebarDialog = showSidebar.getters.unique(true).value;
  let idx = 0;
  let sequencesToSave: WorkDaySequence[] = [];
  let workDayToSave: WorkDay | undefined = undefined;

  try {
    if (!crudState.getters.all.value[CalendarCrudState.EDIT_WORK]) throw Error('Work section not in edit mode.');
    let workDaysToSave: WorkDay[] = [];
    switch (activeSidebarDialog) {
    case CalendarSidebarDialog.DAY:
      workDayToSave = state.selectedPlannerDay?.workDays?.find(w => w.id === workDay.id);
      if (!workDayToSave) throw Error('Workday to set sequence on is not available in period.');
      if(toggle) {
        sequencesToSave = cloneDeep(workDayToSave.sequences);
        sequences.forEach((sequence) => {
          idx = sequencesToSave.findIndex(s => s.value === sequence);
          if(idx === -1) sequencesToSave = [...sequencesToSave, { value: sequence }];
          else sequencesToSave.splice(idx, 1);
        });
      } else {
        sequencesToSave = cloneDeep(sequences).map(s => ({ value: s }));
      }
      workDayToSave.sequences = sequencesToSave;
      break;
    case CalendarSidebarDialog.PERIOD:
      if(subPeriod && state.selectedPeriod) {
        // sequences need to be set for all workdays within 1 subPeriod, for the same contractor
        workDaysToSave = helpers
          .getWorkDaysForSubPeriod(state.selectedPeriod, subPeriod, [ContractorType.SUPPLIER,ContractorType.TEAM])
          .filter(w => w.contractorable?.type === workDay.contractorable?.type && w.contractorable?.id === workDay.contractorable?.id) || [];
        workDaysToSave.forEach(wd => {
          if(toggle) {
            sequencesToSave = cloneDeep(wd.sequences);
            sequences.forEach((sequence) => {
              idx = sequencesToSave.findIndex(s => s.value === sequence);
              if(idx === -1) sequencesToSave = [...sequencesToSave, { value: sequence }];
              else sequencesToSave.splice(idx, 1);
            });
          } else {
            sequencesToSave = cloneDeep(sequences).map(s => ({ value: s }));
          }
          wd.sequences = sequencesToSave;
        });
      }
      break;
    default:
      break;
    }
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(setSequences.name, false);
  }
};

const showSettings = async (ctx: UseCalendarContext, val: boolean) => {
  ctx.state.showSettings = val;
  // @todo: now we always fetch all projects, divisions, ... when the settings are closed. This should change to
  // an event. When the event is triggered from within the settings, only fetch the necessary data
  if(!val) {
    fetchProjects(ctx);
    fetchDivisions(ctx);
    fetchSuppliers(ctx);
  }
  return Promise.resolve();
};
const setPlannerType = async (ctx: UseCalendarContext, plannerType: CalendarViewType) => {
  ctx.state.plannerType = plannerType;
  return Promise.resolve();
};
const setHeaderCalendarLeftOffset = async (ctx: UseCalendarContext, val: number) => {
  ctx.state.headerCalendarLeftOffset = val;
  return Promise.resolve();
};

const clearSelectedAdjacentPeriods = async (ctx: UseCalendarContext) => {
  const { state } = ctx;
  state.selectedAdjacentPeriods = [];
  return Promise.resolve();
};

const setLastSelectedData = async (ctx: UseCalendarContext, quote?: Quote, project?: BaseProject, date?: string) => {
  const { state } = ctx;
  if (project && quote && date) {
    state.lastSelectedData = {
      project,
      quote,
      date,
      commentToAdd: '',
    };
  } else {
    state.lastSelectedData = undefined;
  }
  return Promise.resolve();
};

const setAdjacentPeriodsConfirmationDialog = async (ctx: UseCalendarContext, show: boolean): Promise<void> => {
  const { state, composables: { loadingActions, showDialog } } = ctx;
  loadingActions.actions.set(setAdjacentPeriodsConfirmationDialog.name, true);

  try {
    if (!show) {
      clearSelectedAdjacentPeriods(ctx);
      setLastSelectedData(ctx);
    }
    showDialog.actions.set(CalendarDialog.ADJACENT_DAYS, show);
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(setAdjacentPeriodsConfirmationDialog.name, false);
  }
};

const toggleProjectExpanded = async (ctx: UseCalendarContext, projectId: number) => {
  const { state, composables: { loadingActions} } = ctx;
  loadingActions.actions.set(toggleProjectExpanded.name, true);

  try {
    const clonedExpandedProjects = [...state.expandedProjects];
    if (state.expandedProjects.includes(projectId)) {
      clonedExpandedProjects.splice(clonedExpandedProjects.indexOf(projectId), 1);
      state.expandedProjects = clonedExpandedProjects;
    } else {
      state.expandedProjects = [...state.expandedProjects, projectId];
      await fetchProjectById(ctx, projectId);
    }
    helpers.recalculateContractorColumn();
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(toggleProjectExpanded.name, false);
  }
};

const toggleDivisionExpanded = async (ctx: UseCalendarContext, divisionId: number) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(toggleDivisionExpanded.name, true);

  try {
    const clonedExpandedDivisions = [...state.expandedDivisions];
    if (state.expandedDivisions.includes(divisionId)) {
      clonedExpandedDivisions.splice(clonedExpandedDivisions.indexOf(divisionId), 1);
      state.expandedDivisions = clonedExpandedDivisions;
    } else {
      state.expandedDivisions = [...state.expandedDivisions, divisionId];
      await fetchDivisionById(ctx, divisionId);
    }
    helpers.recalculateContractorColumn();
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(toggleDivisionExpanded.name, false);
  }
};

const toggleSupplierExpanded = async (ctx: UseCalendarContext, supplierId: number) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(toggleSupplierExpanded.name, true);

  try {
    const clonedExpandedSuppliers = [...state.expandedSuppliers];
    if (state.expandedSuppliers.includes(supplierId)) {
      clonedExpandedSuppliers.splice(clonedExpandedSuppliers.indexOf(supplierId), 1);
      state.expandedSuppliers = clonedExpandedSuppliers;
    } else {
      state.expandedSuppliers = [...state.expandedSuppliers, supplierId];
      await fetchSupplierById(ctx, supplierId);
    }
    helpers.recalculateContractorColumn();
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(toggleSupplierExpanded.name, false);
  }
};

const fetchAvailableTeams = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, calendarSettings } } = ctx;
  loadingActions.actions.set(fetchAvailableTeams.name, true);
  try {
    const useTeamsList = useTeamList();
    const calendarDateRange = calendarSettings.getters.environment.value?.dateRange;
    if(calendarDateRange && calendarDateRange.start && calendarDateRange.end) {
      await useTeamsList.actions.setFilter('active_from', calendarDateRange.start);
      await useTeamsList.actions.setFilter('active_until', calendarDateRange.end);
      await useTeamsList.actions.fetchTeams();
    }
    state.availableTeams = useTeamsList.getters.teams.value && useTeamsList.getters.teams.value.length ? useTeamsList.getters.teams.value : [];
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchAvailableTeams.name, false);
  }
};

const fetchAbsencePeriods = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, calendarSettings } } = ctx;
  loadingActions.actions.set(fetchAbsencePeriods.name, true);
  try {
    const calendarDateRange = calendarSettings.getters.environment.value?.dateRange;
    if(calendarDateRange && calendarDateRange.start && calendarDateRange.end) {
      const filter = useFilter();
      filter.actions.setFilters(new Map([
        ['start_date', calendarDateRange.start],
        ['end_date', calendarDateRange.end],
      ]));
      const resp = await absencesService.getAbsencePeriods(filter.getters.filterUrlQuery.value);
      state.absencePeriods = resp.data.data;
    } else {
      throw new Error('Calendar start/end date is not set, could not retrieve absence periods.');
    }
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchAbsencePeriods.name, false);
  }
};

const saveProjectDateRange = async (ctx: UseCalendarContext, data: { timeRange: CalendarDateRangeOption; projectId: number }) => {
  const { composables: { loadingActions, calendarSettings } } = ctx;
  loadingActions.actions.set(saveProjectDateRange.name, true);

  try {
    const res = await calendarCommandsService.setProjectPeriod(data.projectId, data.timeRange.id);
    await calendarSettings.actions.fetchEnvironment();
    await fetchAllExpandedProjects(ctx);
    await fetchAllExpandedContractors(ctx);
    await fetchAvailableTeams(ctx);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveProjectDateRange.name, false);
  }
};

const saveDateRange = async (ctx: UseCalendarContext, calendarDateRangeOption: CalendarDateRangeOption) => {
  const { state, composables: { loadingActions, calendarSettings } } = ctx;
  loadingActions.actions.set(saveDateRange.name, true);

  try {
    if (calendarSettings.getters.environment.value) {
      const res = await calendarCommandsService.setPlannerPeriod(calendarSettings.getters.environment.value.environmentId, calendarDateRangeOption.id);
      await calendarSettings.actions.fetchEnvironment();
      await fetchAllExpandedProjects(ctx);
      await fetchAllExpandedDivisions(ctx);
      await fetchAllExpandedSuppliers(ctx);
      await fetchAvailableTeams(ctx);
      return Promise.resolve(res);
    }

    throw ('No environment id specified');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveDateRange.name, false);
  }
};

const savePeriodComment = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;

  try {
    if(!state.selectedPeriod) throw new Error('No period selected, cannot save comment.');
    loadingActions.actions.set(savePeriodComment.name, true);
    await calendarService.savePeriodComment(state.selectedPeriod.id, state.commentToAdd);
    loadingActions.actions.set(savePeriodComment.name, false);
    await refetchPeriod(ctx);
    await setCommentToAdd(ctx, '');
    refetchExpandedProject(ctx, state.selectedPeriod?.quote?.project);
    refetchExpandedContractor(ctx, state.selectedPeriod?.quote?.contractor);
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(savePeriodComment.name, false);
  }
};
const updateDay = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  loadingActions.actions.set(updateDay.name, true);

  try {
    if(!state.selectedPlannerDay) throw new Error('No day selected, cannot save description.');
    const resp = await calendarService.updateDay(state.selectedPlannerDay.quote.id, state.selectedPlannerDay.day, state.selectedPlannerDay);
    await refetchDay(ctx);
    refetchExpandedProject(ctx, state.selectedPlannerDay.quote.project);
    refetchExpandedContractor(ctx, state.selectedPlannerDay.quote.contractor);
    return Promise.resolve(resp);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateDay.name, false);
  }
};
const setDay = async (ctx: UseCalendarContext, day: PlannerDay) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  loadingActions.actions.set(setDay.name, true);

  try {
    if(!state.selectedPlannerDay) throw new Error('No day selected, cannot save description.');
    state.selectedPlannerDay = day;
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(setDay.name, false);
  }
};
const updatePeriod = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(updatePeriod.name, true);

  try {
    if(!state.selectedPeriod) throw new Error('No period selected, cannot save description.');
    const resp = await calendarService.updatePeriod(state.selectedPeriod?.id, state.selectedPeriod);
    await refetchPeriod(ctx);
    refetchExpandedProject(ctx, state.selectedPeriod?.quote?.project);
    refetchExpandedContractor(ctx, state.selectedPeriod?.quote?.contractor);
    return Promise.resolve(resp);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updatePeriod.name, false);
  }
};
const setPeriod = async (ctx: UseCalendarContext, period: PlannerPeriod) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(setPeriod.name, true);

  try {
    if(!state.selectedPeriod) throw new Error('No period selected, cannot save description.');
    state.selectedPeriod = period;
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(setPeriod.name, false);
  }
};

const saveWorkDayComment = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  loadingActions.actions.set(saveWorkDayComment.name, true);

  try {
    if(!state.selectedPlannerDay) throw new Error('No day selected, cannot save comment.');
    const workDay = state.selectedPlannerDay.workDays.find(wd => wd.contractorable?.type && [ContractorType.SUPPLIER, ContractorType.DIVISION].includes(wd.contractorable.type as ContractorType));
    if(!workDay) throw new Error('No workDay (neither a supplier or division) found to save comment on.');
    await calendarService.saveWorkDayComment(workDay.id, state.commentToAdd);
    await refetchDay(ctx);
    await setCommentToAdd(ctx, '');
    refetchExpandedProject(ctx, state.selectedPlannerDay.quote.project);
    refetchExpandedContractor(ctx, state.selectedPlannerDay.quote.contractor);
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveWorkDayComment.name, false);
  }
};

const fetchAdjacentPeriods = async (ctx: UseCalendarContext, quoteId: number, date: string) => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchAdjacentPeriods.name, true);
  try {
    const res = await calendarCommandsService.getAdjacentPeriods(quoteId, date);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchAdjacentPeriods.name, false);
  }
};

const addPlannerPeriod = async (ctx: UseCalendarContext, projectId: number, quoteId: number, date: string, mergeIds: number[]) => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(addPlannerPeriod.name, true);

  try {
    const res = await calendarService.createPlannerPeriod(quoteId, date);
    if (mergeIds.length > 0) {
      if(!res.data.data.plannerPeriod) throw new Error('New plannerPeriod for day is undefined. Cannot merge days as 1 period.');
      await calendarCommandsService.mergePeriods([...mergeIds, res.data.data.plannerPeriod.id]);
    }

    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(addPlannerPeriod.name, false);
  }
};

const toggleSelectedAdjacentPeriod = async (ctx: UseCalendarContext, e: boolean, periodId: number) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(toggleSelectedAdjacentPeriod.name, true);
  try {
    const selectedPeriods = [...state.selectedAdjacentPeriods];
    if (e) selectedPeriods.push(periodId);
    else selectedPeriods.splice(selectedPeriods.indexOf(periodId), 1);

    state.selectedAdjacentPeriods = selectedPeriods;
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(toggleSelectedAdjacentPeriod.name, false);
  }
};

const clearSidebar = async (ctx: UseCalendarContext) => ctx.composables.showSidebar.actions.setAll(false);
const clearAndShowSidebar = async (ctx: UseCalendarContext, key: string, value: boolean) => {
  await clearSidebar(ctx);
  await ctx.composables.showSidebar.actions.set(key, value);
  return Promise.resolve();
};

const handleCellClick = async (ctx: UseCalendarContext, quote: Quote, project: BaseProject, date: string, calendarDialog: CalendarSidebarDialog) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(handleCellClick.name, true);

  try {
    const workday = helpers.isDateInQuote(quote, date);

    if (workday) {
      if (calendarDialog == CalendarSidebarDialog.PERIOD) {
        await fetchPeriod(ctx, workday.periodId);
        await clearAndShowSidebar(ctx, calendarDialog, true);
      }
      else if (calendarDialog == CalendarSidebarDialog.DAY) {
        await fetchDay(ctx, quote.id, date);
        await clearAndShowSidebar(ctx, calendarDialog, true);
      } else {
        throw Error(`No such calendar dialog: ${calendarDialog}`);
      }
    } else {
      clearSidebar(ctx);
      const adjacentPeriods = (await fetchAdjacentPeriods(ctx, quote.id, date)).data.data;
      if (adjacentPeriods && adjacentPeriods.length > 1) {
        await setLastSelectedData(ctx, quote, project, date);
        state.adjacentPeriods = adjacentPeriods;
        await setAdjacentPeriodsConfirmationDialog(ctx, true);
        adjacentPeriods.forEach(period => {
          toggleSelectedAdjacentPeriod(ctx, true, period.id);
        });
      } else if (adjacentPeriods && adjacentPeriods.length === 1) {
        await addPlannerPeriod(ctx, project.id, quote.id, date, [adjacentPeriods[0].id]);
      } else {
        await addPlannerPeriod(ctx, project.id, quote.id, date, []);
      }
      refetchExpandedProject(ctx, project);
      refetchExpandedContractor(ctx, quote.contractor);
    }
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(handleCellClick.name, false);
  }
};

const handleMergePeriods = async (ctx: UseCalendarContext, merge: boolean) => {
  const { state, composables: { loadingActions, showDialog } } = ctx;
  loadingActions.actions.set(handleMergePeriods.name, true);

  try {
    const lastSelectedData = state.lastSelectedData;
    if (lastSelectedData && lastSelectedData.date && lastSelectedData.quote?.id && lastSelectedData.project?.id) {
      showDialog.actions.set(CalendarDialog.ADJACENT_DAYS, false);
      await addPlannerPeriod(ctx, lastSelectedData.project.id, lastSelectedData.quote.id, lastSelectedData.date, merge ? [...state.selectedAdjacentPeriods] : []);
      refetchExpandedProject(ctx, lastSelectedData.project);
      refetchExpandedContractor(ctx, lastSelectedData.quote.contractor);
    }
    await setAdjacentPeriodsConfirmationDialog(ctx, false);
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(handleMergePeriods.name, false);
  }
};

const splitPlannerDay = async (ctx: UseCalendarContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(splitPlannerDay.name, true);

  try {
    const projectId = state.selectedPlannerDay?.quote.project?.id;
    const divisionId = helpers.isDivision(state.selectedPlannerDay?.quote.contractor) && state.selectedPlannerDay?.quote.contractor?.id;
    const plannerPeriodId = state.selectedPlannerDay?.plannerPeriod?.id;
    const day = state.selectedPlannerDay?.day;

    if (projectId && plannerPeriodId && day) {
      const res = await calendarCommandsService.splitPeriod(plannerPeriodId, day);
      if(state.expandedProjects.includes(projectId)) {
        fetchProjectById(ctx, projectId);
      }
      if(divisionId && state.expandedDivisions.includes(divisionId)) {
        fetchDivisionById(ctx, divisionId);
      }

      return Promise.resolve(res);
    } else throw new Error('Could not split period because either planner period, day or project is undefined.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(splitPlannerDay.name, false);
  }
};

const deletePlannerPeriod = async (ctx: UseCalendarContext) => {
  const { state, composables: { showSidebar, loadingActions } } = ctx;
  loadingActions.actions.set(deletePlannerPeriod.name, true);

  try {
    const projectId = state.selectedPeriod?.quote?.project?.id;
    const plannerPeriodId = state.selectedPeriod?.id;
    if (plannerPeriodId && projectId) {
      await calendarService.deletePlannerPeriod(plannerPeriodId);
      refetchExpandedProject(ctx, state.selectedPeriod?.quote?.project);
      refetchExpandedContractor(ctx, state.selectedPeriod?.quote?.contractor);
      showSidebar.actions.set(CalendarSidebarDialog.PERIOD, false);
      return Promise.resolve();
    } else throw new Error('Could not delete period because either planner period, workDay or project is undefined.');

  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deletePlannerPeriod.name, false);
  }
};
const deleteDay = async (ctx: UseCalendarContext) => {
  const { state, composables: { showSidebar, loadingActions } } = ctx;
  loadingActions.actions.set(deleteDay.name, true);

  try {
    const quoteId = state.selectedPlannerDay?.quote?.id;
    const day = state.selectedPlannerDay?.day;
    // const projectId = state.selectedPlannerDay?.quote.project?.id;
    // const workDay = state.selectedPlannerDay?.workDays.find(wd => wd.contractorable?.type && [ContractorType.SUPPLIER, ContractorType.DIVISION].includes(wd.contractorable.type as ContractorType));
    // const workDayId = workDay && workDay.id;
    if (quoteId && day) {
      // await calendarService.deleteWorkDay(workDayId);
      await calendarService.deleteDay(quoteId, day);
      refetchExpandedProject(ctx, state.selectedPlannerDay?.quote?.project);
      refetchExpandedContractor(ctx, state.selectedPlannerDay?.quote?.contractor);
      showSidebar.actions.set(CalendarSidebarDialog.DAY, false);
    }

    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteDay.name, false);
  }
};

const movePeriod = async (ctx: UseCalendarContext, id: number, count: number) => {
  const { state, composables: { loadingActions, showSidebar } } = ctx;
  loadingActions.actions.set(movePeriod.name, true);
  const activeSidebarDialog = showSidebar.getters.unique(true).value;

  try {
    if(activeSidebarDialog == CalendarSidebarDialog.PERIOD) {
      await calendarCommandsService.movePeriod(id, count);
      await refetchPeriod(ctx);
      refetchExpandedProject(ctx, state.selectedPeriod?.quote?.project);
      refetchExpandedContractor(ctx, state.selectedPeriod?.quote?.contractor);
      return Promise.resolve();
    }
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(movePeriod.name, false);
  }
};

//TODO: move project by user provided amount of dates/days
const showMoveProjectByDays = async (ctx: UseCalendarContext, projectId: number) => {
  const { state, composables: { loadingActions, showSidebar } } = ctx;
  showSidebar.actions.set(CalendarSidebarDialog.MOVE_PROJECT, true);

  try {
    if (projectId) {
      const project = state.projects.find(p => p.id === projectId);
      state.moveProjectByDays = cloneDeep(project);
    }
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    //
  }
};
const confirmMoveProjectByDays = async (ctx: UseCalendarContext, offsetDate: string, newDate: string) => {
  const { state, composables: { loadingActions, showSidebar } } = ctx;
  loadingActions.actions.set(confirmMoveProjectByDays.name, true);

  try {
    const projectId = state.moveProjectByDays?.id;
    if(projectId && offsetDate && newDate) {
      const res = await calendarCommandsService.moveProjectByDate(projectId, offsetDate, newDate);
      await fetchAllExpandedProjects(ctx);
      await showMoveProjectByDays(ctx, projectId);
      return Promise.resolve();
    }
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(confirmMoveProjectByDays.name, false);
  }
};

export const actions = {

  // environment
  fetchAllExpandedProjects,
  fetchAllExpandedContractors,
  showSettings,
  setPlannerType,
  setHeaderCalendarLeftOffset,
  setAdjacentPeriodsConfirmationDialog,
  setAllProjectsExpanded,
  setAllDivisionsExpanded,
  setAllSuppliersExpanded,
  toggleProjectExpanded,
  toggleDivisionExpanded,
  toggleSupplierExpanded,
  saveProjectDateRange,
  saveDateRange,
  showMoveProjectByDays,
  confirmMoveProjectByDays,
  clearAndShowSidebar,
  clearSidebar,
  setSequences,
  saveSequences,
  cancelSaveSequences,
  saveWorkDayComment,
  savePeriodComment,

  // data
  setCommentToAdd,
  fetchAdjacentPeriods,
  addPlannerPeriod,
  toggleSelectedAdjacentPeriod,
  clearSelectedAdjacentPeriods,
  handleCellClick,
  handleMergePeriods,
  splitPlannerDay,
  deletePlannerPeriod,
  movePeriod,
  deleteDay,
  updateDay,
  setDay,
  setPeriod,
  updatePeriod,

  // repository
  fetchProjects,
  fetchProjectById,
  fetchAvailableTeams,
  fetchAbsencePeriods,
  fetchDivisions,
  fetchDivisionById,
  fetchSuppliers,
  fetchSupplierById,

};
