
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    import { Actions, Getters } from '@/modules/sales-invoices/store/types/StoreTypes';
    import { SalesInvoice, SalesInvoicePaymentStatus, SalesInvoiceRecordType, SalesInvoiceStatus, SalesInvoiceType } from '@/modules/sales-invoices/types/entities';
    import SalesInvoiceDetails from '@/modules/sales-invoices/components/sales-invoice-details/sales-invoice-details.component.vue';
    import { AxiosError } from 'axios';
    import Loading from '@/core/components/loading/loading.component.vue';
    import _ from 'lodash'
    import { Actions as TaxesActions, Getters as TaxesGetters } from '@/modules/settings/store/modules/taxes/types/StoreTypes';
    import { Tax } from '@/modules/settings/types/entities';
    import { Actions as ContractorBranchActions, Getters as ContractorBranchGetters } from '@/modules/settings/store/modules/contractor-branches/types/StoreTypes';
    import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';

    // namespaces
    const salesInvoices = namespace('salesInvoices');
    const taxes = namespace('settings/taxes');
    const contractorBranches = namespace('settings/contractor-branches');

    @Component({
        components: {
            "sales-invoice-details": SalesInvoiceDetails,
            "loading": Loading
        }
    })
    export default class SalesInvoiceComponent extends Vue {

        // actions
        @salesInvoices.Action(Actions.FETCH_SALES_INVOICE_BY_ID) fetchSalesInvoiceById!: (payload: string) => Promise<void>;
        @salesInvoices.Action(Actions.FETCH_STATUSES) fetchStatuses!: () => Promise<void>;
        @salesInvoices.Action(Actions.FETCH_PAYMENT_STATUSES) fetchPaymentStatuses!: () => Promise<void>;
        @salesInvoices.Action(Actions.FETCH_TYPES) fetchTypes!: () => Promise<void>;
        @salesInvoices.Action(Actions.FETCH_RECORD_TYPES) fetchRecordTypes!: () => Promise<void>;
        @salesInvoices.Action(Actions.FETCH_CONTACTS) fetchContacts!: () => Promise<void>;
        @salesInvoices.Action(Actions.DELETE_INVOICE_BY_ID) deleteSalesInvoice!: (payload: SalesInvoice) => Promise<void>;
        @salesInvoices.Action(Actions.SAVE_INVOICE) saveSalesInvoice!: (payload: SalesInvoice) => Promise<any>;
        @salesInvoices.Action(Actions.FETCH_PROJECT_INVOICES) fetchProjectInvoices!: (payload: number) => Promise<void>;
        @salesInvoices.Action(Actions.SET_FILTER_ON_RELATED_BILLING_ADDRESSES_PROJECTS) filterOnRelatedBillingAddressesProject!: (payload: number) => void;
        @taxes.Action(TaxesActions.FETCH_TAXES) fetchTaxes!: () => Promise<void>
        @contractorBranches.Action(ContractorBranchActions.FETCH_ALL_CONTRACTOR_BRANCHES) fetchContractorBranches!: () => Promise<void>;

        // getters
        @salesInvoices.Getter(Getters.GET_INVOICE) salesInvoice!: SalesInvoice;
        @salesInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;
        @salesInvoices.Getter(Getters.GET_STATUSES_TRANSLATED) statuses!: SalesInvoiceStatus[];
		@salesInvoices.Getter(Getters.GET_PAYMENT_STATUSES_TRANSLATED) paymentStatuses!: SalesInvoicePaymentStatus[];
		@salesInvoices.Getter(Getters.GET_TYPES_TRANSLATED) types!: SalesInvoiceType[];
		@salesInvoices.Getter(Getters.GET_RECORD_TYPES_TRANSLATED) recordTypes!: SalesInvoiceRecordType[];
        @taxes.Getter(TaxesGetters.GET_TAXES) taxes!: Tax[];
        @contractorBranches.Getter(ContractorBranchGetters.GET_ALL_BRANCHES) contractorBranches!: ContractorBranch[];

        // vars
        state: {
            saving: boolean,
            cancelling: boolean,
            modifiedPurchaseInvoice: boolean,
            showConfirmDelete: boolean,
        } = {
            saving: false,
            cancelling: false,
            modifiedPurchaseInvoice: false,
            showConfirmDelete: false,
        }
        content: {
            salesInvoice: SalesInvoice|null,
            validationErrors: any,
        } = {
            salesInvoice: null,
            validationErrors: {},
        }

        viewMode: string = 'default';

        // methods
        onClickDelete() {
            this.state = {...this.state, showConfirmDelete: true}
        }
        onClickSave() {
            this.state = {...this.state, saving: true}
            this.save(this.content.salesInvoice)
        }
        onDelete() {
            if(this.content.salesInvoice && this.content.salesInvoice.id !== undefined && this.content.salesInvoice.id > 0) {
                this.deleteSalesInvoice(this.content.salesInvoice).then(() => {
                    this.$router.push({ name: 'salesInvoices' });
                });
            }
        }
        onClickCancel() {
            this.state = {...this.state, cancelling: true}
            this.content = { ...this.content, salesInvoice: {...this.salesInvoice} }
            this.state = {...this.state, saving: false, cancelling: false}
        }

        // methods
        save(invoice: SalesInvoice|null) {
            if(invoice) {
                this.content = { ...this.content, validationErrors: null }
                this.saveSalesInvoice(invoice).catch((err: AxiosError) => {
                    if (err.response && err.response.status === 422) {
						this.content = { ...this.content, validationErrors: err.response.data.errors }
					} else {
						throw err;
					}
                }).finally(() => {
                    // clear some state vars
                    this.state = {...this.state, saving: false}
                });
            }
        }

        //watchers
        @Watch('salesInvoice', { deep: true, immediate: false })
		onWatchSalesInvoice(salesInvoice: SalesInvoice) {
			this.content = { ...this.content, salesInvoice: JSON.parse(JSON.stringify(salesInvoice))}
        }
        
        @Watch('content.salesInvoice', { deep: true, immediate: true })
		onWatchContentSalesInvoiceInvoice(salesInvoice: any) {
            if(salesInvoice && this.salesInvoice) this.onWatchContentSalesInvoiceDebounced(salesInvoice, this.salesInvoice, this);
        }
        onWatchContentSalesInvoiceDebounced = _.debounce((localSalesInvoice: SalesInvoice, storeSalesInvoice: SalesInvoice, vm: Vue) => {
			vm.$data.state = { ...vm.$data.state, modifiedSalesInvoice: !_.isEqual(localSalesInvoice, storeSalesInvoice) }
        }, 400);

        // fetch needed data
        requiredData() {
            return [
                this.statuses && this.statuses.length ? null : this.fetchStatuses(),
                this.paymentStatuses && this.paymentStatuses.length ? null : this.fetchPaymentStatuses(),
                this.recordTypes && this.recordTypes.length ? null : this.fetchRecordTypes(),
                this.types && this.types.length ? null : this.fetchTypes(),
                this.taxes && this.taxes.length ? null : this.fetchTaxes(),
                this.contractorBranches && this.contractorBranches.length ? null : this.fetchContractorBranches(),
            ].filter(v => !!v)
        }

        // hooks
        created() {
            if(this.$route.params.id) {
                Promise.all(this.requiredData())
                    .then((results) => {
                        this.fetchSalesInvoiceById(this.$route.params.id).then(() => {
                            if(this.salesInvoice && this.salesInvoice.project && this.salesInvoice.project.id !== undefined) {
                                this.fetchProjectInvoices(this.salesInvoice.project.id);
                                this.filterOnRelatedBillingAddressesProject(this.salesInvoice.project.id);
                            }
                        })
                    });
            }
        }
    }

