import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/core/types/RootState';
import { Getters, Actions, Mutations } from '@/modules/payments/store/types/StoreTypes';
import { PaymentsState } from './types/PaymentsState';
import { Payment } from '../types/entities';

const getters: GetterTree<PaymentsState, RootState> = {
	[Getters.GET_PAYMENT]: (state: PaymentsState) => {
		return state.payment;
	}
}

const actions: ActionTree<PaymentsState, RootState> = {
	[Actions.FETCH_PAYMENT]: ({ commit, state }: ActionContext<PaymentsState, RootState>, payload: string) => {
		// fetch payment 

		// mutate payment
		commit(Mutations.MUTATE_PAYMENT, payload);
	}
}

const mutations: MutationTree<PaymentsState> = {
	[Mutations.MUTATE_PAYMENT]: (state: PaymentsState, payload: Payment) => {
		state.payment = payload;
	}
}

export const state: PaymentsState = {
	payment: null
}

export const store: Module<PaymentsState, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
