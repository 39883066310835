import { UseAuthContext } from '../types/useAuth';
import { AxiosResponse } from 'axios';
import { JsonResource } from '@/core/types/Entities';
import {AuthenticationService} from "@/services/authentication.service";
import {Auth} from "@/modules/login/types/entities";
import dayjs from "dayjs";

const authService = new AuthenticationService();

const setAuth = async (ctx: UseAuthContext, auth: Auth | null) => {
  const { state } = ctx;
  try {
    state.auth = auth;
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  }
};

const clearLoginValidationErrors = async (ctx: UseAuthContext) => {
  const { state } = ctx;
  state.loginValidationErrors = null;
  return Promise.resolve();
};

const login = async (ctx: UseAuthContext, username: string, password: string) => {
  const { state, composables: { loadingActions }  } = ctx;
  loadingActions.actions.set(login.name, true);

  try {
    clearLoginValidationErrors(ctx);
    await authService.ensureXsrf();
    await authService.attemptLogin(username, password);
    const res = await authService.user();

    await setAuth(ctx, res.data.data);

    state.checkedAt = dayjs().toISOString();
    state.checkCount = 0;

    return Promise.resolve();
  } catch (err: any) {
    if (err.response && err.response.status === 422) state.loginValidationErrors = err.response.data.errors;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(login.name, false);
  }
};

const logout = async (ctx: UseAuthContext) => {
  const { state, composables: { loadingActions }  } = ctx;
  loadingActions.actions.set(logout.name, true);

  try {
    await authService.logout();

    await setAuth(ctx, null);

    state.checkedAt = null;
    state.checkCount = 0;

    return Promise.resolve();
  } catch (err: any) {
    // if (err.response && err.response.status === 422) state.loginValidationErrors = err.response.data.errors;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(logout.name, false);
  }
};

const check = async (ctx: UseAuthContext, force = false) => {
  const { state, composables: { loadingActions }  } = ctx;
  loadingActions.actions.set(check.name, true);

  try {

    const lastCheckedAt = state.checkedAt ? dayjs(state.checkedAt) : null;
    const difference = lastCheckedAt ? lastCheckedAt.diff(dayjs(), 'minutes') : 0;
    let res: AxiosResponse<JsonResource<Auth>> | null = null;

    if(state.checkCount < 1 && (force || lastCheckedAt === null || difference > 4)) {
      state.checkCount = state.checkCount + 1;

      res = await authService.user();

      // if check succeeds, this will set the auth data. If it fails it'll set auth to null.
      await setAuth(ctx, res?.data.data ?? null);

      // Result was ok, (re)set flags
      state.checkedAt = dayjs().toISOString();
      state.checkCount = 0;
    }

    return Promise.resolve();
  } catch (err: any) {

    // Something failed during the check, make sure 'auth' is set to null.
    await setAuth(ctx, null);

    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(check.name, false);
  }
};

const impersonate = async (ctx: UseAuthContext, userId: number): Promise<AxiosResponse<JsonResource<Auth>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(impersonate.name, true);
  try {
    const res = await authService.impersonate(userId);

    window.location.href = '/';

    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(impersonate.name, false);
  }
};

export const actions = {
  login,
  logout,
  check,
  impersonate,
  setAuth,
};
