
import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Actions, Getters} from '@/modules/purchase-invoices/store/types/StoreTypes';
import {
  AccountingItem,
  AccountingItemEntity, AccountingStatus,
  ApprovalStatus,
  MediaAnnotatatable,
  ProjectRelationship,
  ProjectRelationshipEntity,
  PurchaseInvoice,
  PurchaseInvoicePaymentStatus,
  PurchaseInvoiceRecordType,
  PurchaseInvoiceStatus,
  TransactionType
} from '@/modules/purchase-invoices/types/entities';
import {Media, MediaAnnotation, User} from '@/modules/entities/types/entities';
import dayjs from 'dayjs';
import AccountingItemCreateModalComponent
  from '@/modules/purchase-invoices/components/accounting-item-create-modal/accounting-item-create-modal.component.vue';
import AccountingItemDeleteModalComponent
  from '@/modules/purchase-invoices/components/accounting-item-delete-modal/accounting-item-delete-modal.component.vue';
import AccountingItemUpdateModalComponent
  from '@/modules/purchase-invoices/components/accounting-item-update-modal/accounting-item-update-modal.component.vue';
import ProjectRelationshipCreateModalComponent
  from '@/modules/purchase-invoices/components/project-relationship-create-modal/project-relationship-create-modal.component.vue';
import ProjectRelationshipUpdateModalComponent
  from '@/modules/purchase-invoices/components/project-relationship-update-modal/project-relationship-update-modal.component.vue';
import ProjectRelationshipDeleteModalComponent
  from '@/modules/purchase-invoices/components/project-relationship-delete-modal/project-relationship-delete-modal.component.vue';
import MediaUploadComponent from '@/modules/media/components/media-upload/media-upload.component.vue';
import PurchaseInvoiceInformationComponent
  from '@/modules/purchase-invoices/components/purchase-invoice-details/purchase-invoice-information.component.vue';
import PurchaseInvoiceAmountsComponent
  from '@/modules/purchase-invoices/components/purchase-invoice-details/purchase-invoice-amounts.component.vue';
import PurchaseInvoiceProjectRelationshipsListComponent
  from '@/modules/purchase-invoices/components/purchase-invoice-project-relationships-list/purchase-invoice-project-relationships-list.component.vue';
import PurchaseInvoiceCommentsComponent
  from '@/modules/purchase-invoices/components/purchase-invoice-comments/purchase-invoice-comments.component.vue';
import PdfAnnotateModalComponent
  from '@/modules/purchase-invoices/components/pdf-annotate/pdf-annotate-modal.component.vue';
import PurchaseInvoicePaymentsListComponent
  from '@/modules/purchase-invoices/components/purchase-invoice-payments-list/purchase-invoice-payments-list.component.vue';
import PurchaseInvoiceAccountingItemsListComponent
  from '@/modules/purchase-invoices/components/purchase-invoice-accounting-items-list/purchase-invoice-accounting-items-list.component.vue';
import {AxiosResponse} from 'axios';
import {PropType} from 'vue';
import {JsonResource} from '@/core/types/Entities';
import {UsersService} from '@/services/users.service';
import {useAccounting} from "@/modules/accounting/store/accounting.store";
import useProjectRelationship from "@/composables/useProjectRelationship";
import authStore from "@/modules/login/store";

// namespaces
    const purchaseInvoices = namespace('purchaseInvoices');

    // services
    const usersService = new UsersService()

    @Component({
        components: {
            'accounting-items-list': PurchaseInvoiceAccountingItemsListComponent,
            "accounting-item-create-modal": AccountingItemCreateModalComponent,
            "accounting-item-delete-modal": AccountingItemDeleteModalComponent,
            "accounting-item-update-modal": AccountingItemUpdateModalComponent,
            "project-relationship-create-modal": ProjectRelationshipCreateModalComponent,
            "project-relationship-update-modal": ProjectRelationshipUpdateModalComponent,
            "project-relationship-delete-modal": ProjectRelationshipDeleteModalComponent,
            'purchase-invoice-information': PurchaseInvoiceInformationComponent,
            'purchase-invoice-amounts': PurchaseInvoiceAmountsComponent,
            'project-relationships-list': PurchaseInvoiceProjectRelationshipsListComponent,
            'purchase-invoice-comments': PurchaseInvoiceCommentsComponent,
            'media-upload': MediaUploadComponent,
            'pdf-annotate-modal': PdfAnnotateModalComponent,
            'payments-list': PurchaseInvoicePaymentsListComponent,
        },
      setup(props, ctx) {

        const accountingStore = useAccounting();

        return {
          currentAccountingLedger: accountingStore.currentAccountingLedger,
        }
      }
    })
    export default class PurchaseInvoiceDetailsComponent extends Vue {

        // actions
        @purchaseInvoices.Action(Actions.FETCH_ACCOUNTING_ITEM_BY_ID) fetchAccountingItemById!: (payload: string) => Promise<AccountingItem>;
        @purchaseInvoices.Action(Actions.FETCH_PROJECT_RELATIONSHIP_BY_ID) fetchProjectRelationshipById!: (payload: string) => Promise<ProjectRelationship>;
        @purchaseInvoices.Action(Actions.SAVE_PROJECT_RELATIONSHIP) saveProjectRelationship!: (payload: ProjectRelationship) => Promise<void>;
        @purchaseInvoices.Action(Actions.UPLOAD_PDF) uploadPdf!: (payload: {purchaseInvoiceId: number, files: Array<{name: string; content: string|ArrayBuffer|null}>}) => Promise<void>;
        @purchaseInvoices.Action(Actions.DELETE_PDF) deletePdf!: (payload: {purchaseInvoiceId: number, mediaId: number}) => Promise<void>;
        @purchaseInvoices.Action(Actions.FETCH_MEDIA_ANNOTATIONS_BY_MEDIA_ID) fetchMediaAnnotationsByMediaId!: (mediaId: string) => Promise<AxiosResponse<JsonResource<MediaAnnotation[]>>>;
        @purchaseInvoices.Action(Actions.FETCH_MEDIA_ANNOTATION_FOR_INVOICE_PDF) fetchMediaAnnotationsForPurchaseInvoice!: (payload: {invoiceId: string; mediaId: string}) => Promise<AxiosResponse<JsonResource<MediaAnnotation>>>;
        @purchaseInvoices.Action(Actions.FETCH_MEDIA_ANNOTATION_FOR_PROJECT_RELATIONSHIP) fetchMediaAnnotationsForProjectRelationship!: (payload: {projectRelationshipId: string; mediaId: string}) => Promise<AxiosResponse<JsonResource<MediaAnnotation>>>;
        @purchaseInvoices.Action(Actions.SAVE_MEDIA_ANNOTATION) saveMediaAnnotation!: (payload: {mediaAnnotation: MediaAnnotation; mediaId: string; annotatableId: string; annotatableType: MediaAnnotatatable}) => Promise<AxiosResponse<JsonResource<MediaAnnotation>>>;

        // getters
        @purchaseInvoices.Getter(Getters.GET_STATUSES_TRANSLATED) statuses!: PurchaseInvoiceStatus[];
        @purchaseInvoices.Getter(Getters.GET_PAYMENT_STATUSES_TRANSLATED) paymentStatuses!: PurchaseInvoicePaymentStatus[];
        @purchaseInvoices.Getter(Getters.GET_APPROVAL_STATUSES_TRANSLATED) approvalStatuses!: ApprovalStatus[];
        @purchaseInvoices.Getter(Getters.GET_ACCOUNTING_STATUSES_TRANSLATED) accountingStatuses!: AccountingStatus[];
        @purchaseInvoices.Getter(Getters.GET_RECORD_TYPES_TRANSLATED) recordTypes!: PurchaseInvoiceRecordType[];
        @purchaseInvoices.Getter(Getters.GET_NEW) new!: PurchaseInvoice;

        // props
        @PropSync('purchaseInvoice', { type: Object as PropType<PurchaseInvoice>, required: true }) purchaseInvoiceSync!: PurchaseInvoice;
        @PropSync('validationErrors', { type: Object }) validationErrorsSync!: any;
        @Prop({default: 'default'}) viewMode!: string; // 'default','modal'
        @Prop({type: Boolean, default: false}) hasModifications!: boolean;
        @Prop({type: Array as PropType<User[]>, default: () => [] as User[] }) users!: User[]

        // local state + vars
        state: {
            showModalCreateProjectRelationship: boolean,
            showModalUpdateProjectRelationship: boolean,
            showModalDeleteProjectRelationship: boolean,
            showModalDeletePdf: boolean,
            showModalUpdateAccountingItem: boolean,
            showModalDeleteAccountingItem: boolean,
            showModalCreateAccountingItem: boolean,
            showModalAnnotatePdf: boolean,
            openExpansionPanels: number[],
            filesUploading: boolean,
        } = {
            showModalCreateProjectRelationship: false,
            showModalUpdateProjectRelationship: false,
            showModalDeleteProjectRelationship: false,
            showModalDeletePdf: false,
            showModalUpdateAccountingItem: false,
            showModalDeleteAccountingItem: false,
            showModalCreateAccountingItem: false,
            showModalAnnotatePdf: false,
            openExpansionPanels: [0,1,2],
            filesUploading: false,
        }
        content: {
            deletingProjectRelationship: ProjectRelationship|null,
            deletingAccountingItem: AccountingItem|null,
            deletingPdf: Media|null,
            updatingAccountingItem: null|AccountingItem,
            creatingAccountingItem: null|AccountingItem,
            creatingProjectRelationship: null|ProjectRelationship,
            updatingProjectRelationship: null|ProjectRelationship,
            paymentDiscountPercentage: number|undefined,
            files: File[],
            validFiles: boolean,
            mediaAnnotation: {
                current: MediaAnnotation|null, 
                all: MediaAnnotation[],
                annotatableType: MediaAnnotatatable|undefined,
                annotatableId: string|undefined,
            }
		} = {
            deletingProjectRelationship: null,
            deletingAccountingItem: null,
            deletingPdf: null,
            updatingAccountingItem: null,
            creatingAccountingItem: null,
            creatingProjectRelationship: null,
            updatingProjectRelationship: null,
            paymentDiscountPercentage: undefined,
            files: [],
            validFiles: false,
            mediaAnnotation: {
                current: null, 
                all: [],
                annotatableType: undefined,
                annotatableId: undefined,
            }
		}

        // handlers
        onClickUploadPdf() {
            this.upload();
        }
        onClickDeletePdf(pdf: Media) {
            this.state = {...this.state, showModalDeletePdf: true};
            this.content = {...this.content, deletingPdf: pdf}
        }
        onClickConfirmDeletePdf() {
            const mediaId = this.content.deletingPdf && this.content.deletingPdf.id
            const invoiceId = this.purchaseInvoiceSync.id
            if(invoiceId && mediaId) {
                this.deletePdf({purchaseInvoiceId: invoiceId, mediaId: mediaId})
            }
        }
        onClickCancelDeletePdf() {
            this.state = {...this.state, showModalDeletePdf: false};
            this.content = {...this.content, deletingPdf: null}
        }
        onClickAnnotatePdf() {
            const invoiceId = this.purchaseInvoiceSync.id ? this.purchaseInvoiceSync.id.toString() : undefined
            const mediaId = this.purchaseInvoiceSync.pdf && this.purchaseInvoiceSync.pdf.id ? this.purchaseInvoiceSync.pdf.id.toString() : undefined
            
            if(invoiceId && mediaId) {
                this.fetchMediaAnnotationsForPurchaseInvoice({invoiceId: invoiceId, mediaId: mediaId}).then(invoiceMediaAnnotationResult => {
                    this.fetchMediaAnnotationsByMediaId(mediaId).then(allMediaAnnotationsResult => {
                        this.content = {...this.content, 
                            mediaAnnotation: {
                                current: invoiceMediaAnnotationResult.data.data, 
                                all: allMediaAnnotationsResult.data.data,
                                annotatableType: MediaAnnotatatable.PURCHASE_INVOICE,
                                annotatableId: invoiceId
                            }
                        }

                        this.state = {...this.state, showModalAnnotatePdf: true}
                    }).catch(e => {
                        this.content = {...this.content, mediaAnnotation: { current: null, all: [], annotatableType: undefined, annotatableId: undefined}}
                        this.state = {...this.state, showModalAnnotatePdf: false}
                        throw e;
                    })
                }).catch(e => {
                    this.content = {...this.content, mediaAnnotation: { current: null, all: [], annotatableType: undefined, annotatableId: undefined}}
                    this.state = {...this.state, showModalAnnotatePdf: false}
                    throw e;
                })
            }
        }
        onChangeFiles(value: {isValid: boolean, items: File[]}) {
            this.content = {...this.content,
                files: value.items,
                validFiles: value.isValid,
            }
        }
        onClickCreateProjectRelationship() {
            this.content = {...this.content,
                creatingProjectRelationship: new ProjectRelationshipEntity({
                    purchaseInvoice: {...this.purchaseInvoiceSync},
                    approvalStatus: this.approvalStatuses.find((status) => status.name === 'pending'),
                    type: this.purchaseInvoiceSync && this.purchaseInvoiceSync.recordType && this.purchaseInvoiceSync.recordType.name === 'credit_note' ? TransactionType.CREDIT : TransactionType.DEBIT,
                })
            }
            this.state = {...this.state, showModalCreateProjectRelationship: true};
        }
        onClickDeleteProjectRelationship(projectRelationship: ProjectRelationship) {
            this.state = {...this.state, showModalDeleteProjectRelationship: true};
            this.content = {...this.content, deletingProjectRelationship: projectRelationship}
        }
        onClickEditProjectRelationship(projectRelationship: ProjectRelationship) {
            const projectRelationshipId = projectRelationship.id || null;
            if(projectRelationshipId) {
                this.state = {...this.state, showModalUpdateProjectRelationship: true};
                this.fetchProjectRelationshipById(projectRelationshipId.toString()).then(projectRelationship => {
                    this.content = {...this.content, updatingProjectRelationship: projectRelationship}
                })
            }
        }
        onClickEditAccountingItem(accountingItem: AccountingItem) {
            const accountingItemId = accountingItem.id || null;
            if(accountingItemId) {
                this.state = {...this.state, showModalUpdateAccountingItem: true};
                this.fetchAccountingItemById(accountingItemId.toString()).then(accountingItem => {
                    this.content = {...this.content, updatingAccountingItem: accountingItem}
                })
            }
        }
        onClickDeleteAccountingItem(accountingItem: AccountingItem) {
            this.state = {...this.state, showModalDeleteAccountingItem: true};
            this.content = {...this.content, deletingAccountingItem: accountingItem}
        }
        onClickCreateAccountingItem() {
            this.state = { ...this.state, showModalCreateAccountingItem: true }
            this.content = { ...this.content, creatingAccountingItem: this.newAccountingItem() }
        }
        onClickApprovalStatus(item: any) {
            this.fetchProjectRelationshipById(item.item.id).then(projectRelationship => {
                const newStatus = this.approvalStatuses.find((status: ApprovalStatus) => status.name === item.status)
                if(projectRelationship && newStatus) {
                    projectRelationship = {...projectRelationship, approvalStatus: newStatus}
                    this.saveProjectRelationship(projectRelationship)
                }
            })
        }
        onClickSaveMediaAnnotation(mediaAnnotation: MediaAnnotation, annotatableType: MediaAnnotatatable, annotatableId: string) {
            const mediaId = this.purchaseInvoiceSync.pdf && this.purchaseInvoiceSync.pdf.id ? this.purchaseInvoiceSync.pdf.id.toString() : undefined

            if(mediaAnnotation && annotatableType && annotatableId && mediaId) {
                this.saveMediaAnnotation({
                    mediaAnnotation: mediaAnnotation,
                    mediaId: mediaId,
                    annotatableId: annotatableId,
                    annotatableType: annotatableType
                }).then(res => {
                    this.content = {...this.content, mediaAnnotation: { current: null, all: [], annotatableType: undefined, annotatableId: undefined }}
                    this.state = {...this.state, showModalAnnotatePdf: false}
                }).catch(e => {
                    console.error(e)
                })
            }
        }
        onClickCancelMediaAnnotation() {
            this.content = {...this.content, mediaAnnotation: { current: null, all: [], annotatableType: undefined, annotatableId: undefined }}
            this.state = {...this.state, showModalAnnotatePdf: false}
        }
        showNewCreateModal() {
            this.onClickCreateProjectRelationship()
        }

        // methods
        toBase64(file: File): Promise<string|ArrayBuffer|null> { 
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
        async prepareFilesForUpload(files: File[]) {
            if(files.length) {
                const preparedFiles = [] as {name: string, content: string|ArrayBuffer|null}[]
                for (const file of files) {
                    const base64 = await this.toBase64(file)
                    const base64File = {name: file.name, content: base64}
                    preparedFiles.push(base64File);
                }
                return preparedFiles
            }
            return [];
        }
        async upload() {
            this.state = {...this.state, filesUploading: true}

            try {
                const files = await this.prepareFilesForUpload(this.content.files);

                if(this.purchaseInvoiceSync.id) {
                    this.uploadPdf({purchaseInvoiceId: this.purchaseInvoiceSync.id, files: files}).then(() => {
                        this.state = {...this.state, filesUploading: false}
                        this.content = {...this.content, files: []}
                    });
                }
            } catch (err: any) {
                console.warn(err)
            }
        }
        newAccountingItem(): AccountingItem {
            return {
                ...new AccountingItemEntity(),
                name: this.purchaseInvoiceSync ? this.purchaseInvoiceSync.name : '',
                description: this.purchaseInvoiceSync ? this.purchaseInvoiceSync.name : null,
                purchaseInvoice: this.purchaseInvoiceSync,
                type: (this.purchaseInvoiceSync.recordType && this.purchaseInvoiceSync.recordType.name === 'credit_note') ? TransactionType.CREDIT : TransactionType.DEBIT,
            };
        }

        // getters
        get paymentTermInDays() {
            if(this.purchaseInvoiceSync && this.purchaseInvoiceSync.invoiceDate && this.purchaseInvoiceSync.dueDate) {
                const from = dayjs(this.purchaseInvoiceSync.invoiceDate);
                const to = dayjs(this.purchaseInvoiceSync.dueDate);
                return to.diff(from,'day');
            }
            return null;
        }
        get paymentTermInDaysSupplier() {
            if(this.purchaseInvoiceSync && this.purchaseInvoiceSync.invoiceDate && this.purchaseInvoiceSync.supplier) {
                const from = dayjs(this.purchaseInvoiceSync.invoiceDate);
                let to = from.add(this.purchaseInvoiceSync.supplier.preferredPaymentTerm, 'day');
                to = this.purchaseInvoiceSync.supplier.preferredPaymentTermBoolean ? to.endOf('month') : to;
                return to.diff(from,'day');
            }
            return null;
        }
        get paymentDiscountTermInDays() {
            if(this.purchaseInvoiceSync && this.purchaseInvoiceSync.invoiceDate && this.purchaseInvoiceSync.paymentDiscountDueDate) {
                const from = dayjs(this.purchaseInvoiceSync.invoiceDate);
                const to = dayjs(this.purchaseInvoiceSync.paymentDiscountDueDate);
                return to.diff(from,'day');
            }
            return null;
        }
        get paymentDiscountTermInDaysSupplier() {
            if(this.purchaseInvoiceSync && this.purchaseInvoiceSync.invoiceDate && this.purchaseInvoiceSync.supplier) {
                const from = dayjs(this.purchaseInvoiceSync.invoiceDate);
                let to = from.add(this.purchaseInvoiceSync.supplier.preferredPaymentDiscountTerm, 'day');
                to = this.purchaseInvoiceSync.supplier.preferredPaymentDiscountTermBoolean ? to.endOf('month') : to;
                return to.diff(from,'day');
            }
            return null;
        }
        get paymentDiscountPercentage(): number {
            const invoice = this.purchaseInvoiceSync ? {...this.purchaseInvoiceSync} : null;
            if(invoice) {
                const paymentDiscount = (invoice && invoice.paymentDiscount) || 0;
                const grandTotal = (invoice && invoice.grandTotal) || 1;
                return Math.round(paymentDiscount/grandTotal*10000)/100;
            }
            return 0;
        }
        get currentUser() {
            return authStore.auth.getters.user.value || null;
        }
        get hasAnnotations() {
            return this.purchaseInvoiceSync.pdf && this.purchaseInvoiceSync.pdf.annotationsCount
        }

        // @Watch('state.openExpansionPanels')
        // onWatchChangeOpenExpansionPanels(idx: number[]) {
        //     localStorage.purchaseInvoices = JSON.stringify({openExpansionPanels: this.state.openExpansionPanels})
        // }

        mounted() {
            //
            if (localStorage.purchaseInvoices) {
                const localStoragePurchaseInvoices = JSON.parse(localStorage.purchaseInvoices)
                // this.state.openExpansionPanels = localStoragePurchaseInvoices.openExpansionPanels ? localStoragePurchaseInvoices.openExpansionPanels : [] as number[];
            }

            if(this.$route.query.openModal && this.$route.query.openModal === 'createProjectRelationship') {
               // open modal
               this.onClickCreateProjectRelationship();
            }
        }

        destroyed() {
            //
        }
    }

