import { reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import useFilter from '@/composables/useFilter';
import { UseHolidayState, UseHolidayComposables, UseHolidayContext } from './types/useHoliday';
import { merge } from 'lodash';
import { Options } from './types/composable';
import { actions as holidayActions } from './actions/useHoliday';
import { Holiday } from '@/modules/settings/store/modules/holidays/types/HolidaysState';
import { useDynamicProps } from './useDynamicProps';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseHolidayState>): UseHolidayState => {
  return merge(
    {
      holiday: null,
      holidayValidationErrors: null,
      holidayBusinessErrors: null,
    },
    state
  );
};

export default function useHoliday(options?: Options<UseHolidayState, UseHolidayComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    filter: useFilter(),
    showDialog: useDynamicProps<boolean>(),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseHolidayContext = { state, composables };
  const actions = wrapActions(ctx, holidayActions);

  // return state and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // other
      showDialog: ctx.composables.showDialog.getters,
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      holiday: computed<Holiday | null>(() => state.holiday),
      holidayValidationErrors: computed(() => state.holidayValidationErrors),
      holidayBusinessErrors: computed(() => state.holidayBusinessErrors),
    },
    actions: {
      ...ctx.composables.filter.actions,
      showDialog: ctx.composables.showDialog.actions.set,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...actions,
    },
  };
}