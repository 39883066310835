
  import { PropType } from 'vue';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { Project } from '../../types/entities';
  import { Actions as WorkflowActions, Getters as WorkflowGetters } from '@/modules/workflow/store/types/StoreTypes';
  import {
    ProjectIntersectionData,
    WorkflowFunctionGroup,
    WorkflowTask,
    WorkflowTaskStatus,
  } from '@/modules/workflow/types/entities';
  import { statusColour } from '@/modules/settings/views/workflow/helpers/workflow-helpers';
  import { Comment } from '@/modules/entities/types/entities';
  import { Actions } from '@/modules/projects/store/types/StoreTypes';
  import TaskListComponent from '@/modules/workflow/views/workflow/components/task-list/task-list-component.vue';
  import StatusGridComponent from '@/modules/workflow/views/workflow/components/status-grid/status-grid.component.vue';
  import ModifyNoteComponent from '@/modules/workflow/views/workflow/components/modify-note/modify-note-component.vue';
  import ShowAllNotesComponent from '@/modules/workflow/views/workflow/components/show-all-notes/show-all-notes-component.vue';

  const projects = namespace('projects');
  const workflow = namespace('workflow');

  @Component({
    components: {
      'task-list': TaskListComponent,
      'status-grid': StatusGridComponent,
      'modify-note': ModifyNoteComponent,
      'show-all-notes': ShowAllNotesComponent,
    },
  })
  export default class ProjectWorkflowSectionComponent extends Vue {
    @Prop({ type: Object as PropType<Project>, required: true }) project!: Project;
    @Prop({ type: Number, required: true }) userId!: number;
    @Prop({ type: String, required: true }) user!: string;

    @projects.Action(Actions.SET_PROJECT_WORKFLOW_TASK) setProjectWorkflowTask!: (data: {
      projectId: number;
      workflowTaskId: number;
      taskStatusId?: number;
      futureStartDate?: Date;
    }) => void;
    @projects.Action(Actions.DELETE_PROJECT_WORKFLOW_TASK_NOTE) deleteTaskNote!: (payload: {
      projectId: number;
      taskId: number;
      noteId: number;
    }) => void;
    @projects.Action(Actions.SAVE_PROJECT_WORKFLOW_TASK_NOTE) saveTaskNote!: (payload: {
      comment: Comment;
      projectId?: number;
      taskId?: number;
    }) => void;
    @workflow.Action(WorkflowActions.ADD_WORKFLOW_SELECTED_PROJECT) addSelectedProject!: (
      payload: Project
    ) => Promise<void>;
    @workflow.Action(WorkflowActions.SET_HIGHLIGHTED_PROJECT_ID) addHighlightedProjectToWorkflow!: (
      payload: number
    ) => Promise<void>;
    @workflow.Action(WorkflowActions.FETCH_WORKFLOW_DATA) fetchWorkflowData!: () => Promise<void>;
    @workflow.Action(WorkflowActions.SELECT_MULTIPLE_TASK_STATUS_ID) taskStatusMultipleSelectId!: (
      taskStatusId: number
    ) => void;
    @workflow.Action(WorkflowActions.SELECT_MULTIPLE_TASK_STATUS_DATE) taskStatusMultipleSelectDate!: (
      futureStartDate: Date
    ) => void;

    @workflow.Getter(WorkflowGetters.GET_WORKFLOW_TASKS) tasks!: WorkflowTask[];
    @workflow.Getter(WorkflowGetters.GET_WORKFLOW_FUNCTION_GROUPS) functionGroups!: WorkflowFunctionGroup[];
    @workflow.Getter(WorkflowGetters.GET_WORKFLOW_TASK_STATUSES) taskStatuses!: WorkflowTaskStatus[];

    localState: {
      showFunctionGroups: boolean;
      modifyNote: boolean;
      showAllNotes: boolean;
    } = {
      showFunctionGroups: false,
      modifyNote: false,
      showAllNotes: false,
    };

    localContent: {
      expandedTasks: number[];
      note: any;
      showAllNotes: any;
    } = {
      expandedTasks: [],
      note: {},
      showAllNotes: {},
    };

    addProjectToWorkflow(): void {
      this.addSelectedProject(this.project).then(() => {
        this.addHighlightedProjectToWorkflow(this.project.id).then(() => this.$router.push(`/workflow`));
      });
    }

    isTaskExpanded(taskId: number): number {
      return this.localContent.expandedTasks.some((val) => val === taskId) ? 0 : 1;
    }

    get anyTaskExpanded(): boolean {
      return this.localContent.expandedTasks.length > 0;
    }

    toggleParentTask(taskId: number) {
      let taskExpanded = this.localContent.expandedTasks.some((val: number) => {
        return val === taskId;
      });
      taskExpanded ? this.removeExpandedTask(taskId) : this.addExpandedTask(taskId);
    }

    removeExpandedTask(taskId: number): void {
      this.localContent.expandedTasks = this.localContent.expandedTasks.filter((id: number) => id !== taskId);
    }

    addExpandedTask(taskId: number): void {
      this.localContent.expandedTasks.push(taskId);
    }

    toggleAllTasksPanels(): void {
      this.localContent.expandedTasks = this.anyTaskExpanded ? [] : this.tasks.map((task: WorkflowTask) => task.id);
    }

    getStatusChangerColor(item: WorkflowTaskStatus) {
      return statusColour(item.color || '');
    }

    findIntersectionData(project: Project, task: WorkflowTask): ProjectIntersectionData | undefined {
      if (project.intersectionData) {
        return project.intersectionData.find((intersectionData: ProjectIntersectionData) => {
          return intersectionData.workflowTaskId === task.id;
        });
      } else {
        return undefined;
      }
    }

    getCurrentStatus(taskStatusId: number, intersectionData?: ProjectIntersectionData) {
      if (intersectionData) {
        if (intersectionData.taskStatus && intersectionData.taskStatus.id === taskStatusId) {
          return 'current-task';
        }
      }
      return;
    }

    addNote(data: { project: Project; task: WorkflowTask; intersectionData?: ProjectIntersectionData }) {
      this.localContent.note = undefined;
      this.localContent.note = JSON.parse(JSON.stringify(data));
      this.localState.modifyNote = true;
    }

    editNote(data: {
      project: Project;
      task: WorkflowTask;
      intersectionData: ProjectIntersectionData;
      comment: Comment;
    }) {
      this.localContent.note = undefined;
      this.localContent.note = JSON.parse(JSON.stringify(data));
      this.localState.modifyNote = true;
    }

    saveNote(data: { body: string; commentId?: number; intersectionId?: number; projectId?: number; taskId?: number }) {
      const comment: Comment = {
        id: data.commentId ? data.commentId : 0,
        body: data.body,
        commentableId: data.intersectionId ? data.intersectionId : 0,
        commentableType: 'App\\Models\\Common\\ProjectWorkflowTask',
      };
      const payload = {
        comment,
        projectId: data.projectId,
        taskId: data.taskId,
      };
      this.saveTaskNote(payload);
      this.localState.modifyNote = false;
    }

    showAllNotes(data: { project: Project; task: WorkflowTask; intersectionData: ProjectIntersectionData }) {
      this.localContent.showAllNotes = data;
      this.localState.showAllNotes = true;
    }

    created(): void {
      this.fetchWorkflowData().then(() => {
        if (this.project.intersectionData && this.project.intersectionData.length) {
          for (let i = 0; i < this.tasks.length; i++) {
            const result =
              this.tasks[i].children &&
              !this.tasks[i].children!.every((childTask: WorkflowTask) =>
                this.project.workflowTasks!.some((task: WorkflowTask) => task.id === childTask.id)
              );
            if (result) {
              this.addExpandedTask(this.tasks[i].id);
              break;
            }
          }
        } else {
          this.addExpandedTask(this.tasks.length && this.tasks[0].id);
        }
      });
    }

    statusGridScrollListener(e: any) {
      (this.$refs['task-list'] as Vue).$el.scrollTop = e.target.scrollTop;
      (this.$refs['header-list'] as HTMLElement).scrollLeft = e.target.scrollLeft;
    }

    taskListWheelListener(e: any) {
      if (!e) {
        e = window.event;
      } /* IE7, IE8, Chrome, Safari */
      if (e.preventDefault) {
        e.preventDefault();
      } /* Chrome, Safari, Firefox */
      e.returnValue = false; /* IE7, IE8 */
    }

    mounted() {
      // Scroll task list and header list when the main grid gets scrolled -- DISABLED --
      // (this.$refs['status-grid'] as Vue).$el.addEventListener('scroll', this.statusGridScrollListener);
      // Disable scrolling on task list and header list -- DISABLED --
      // (this.$refs['task-list'] as Vue).$el.addEventListener('wheel', this.taskListWheelListener);
    }

    beforeDestroy() {
      // DISABLED
      // (this.$refs['status-grid'] as Vue).$el.removeEventListener('scroll', this.statusGridScrollListener);
      // (this.$refs['task-list'] as Vue).$el.removeEventListener('wheel', this.taskListWheelListener);
    }

    @Watch('project', { immediate: true })
    onSelectedProjectsChange(project: Project) {
      if (this.localContent.showAllNotes !== undefined) {
        if (
          project &&
          project.intersectionData &&
          this.localContent.showAllNotes &&
          this.localContent.showAllNotes.intersectionData
        ) {
          this.localContent.showAllNotes.intersectionData = project.intersectionData.find(
            (x) => x.intersectionId === this.localContent.showAllNotes.intersectionData.intersectionId
          );
        }
      }
    }
  }
