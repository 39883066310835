import Vue from 'vue';
import { ThisTypedComponentOptionsWithArrayProps } from 'vue/types/options';
import { VueConstructor } from 'vue/types/umd';
import { ConfigurationInterface } from '.';
import Dinero from 'dinero.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import nl from 'dayjs/locale/nl';
import en from 'dayjs/locale/en';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import VueI18n from 'vue-i18n';
import { filterValidationErrors } from '@/core/helpers/validation';

export class FiltersConfiguration implements ConfigurationInterface {

  constructor(data?: Partial<ConfigurationInterface>) {
    Object.assign(this, data);
    dayjs.extend(utc);
    dayjs.extend(localizedFormat);
    dayjs.extend(weekOfYear);
    dayjs.extend(relativeTime);
  }

    deleteUndefinedKeys = (options: any = {}): any => {
      const cleansedOptions = { ...options };
      Object.keys(cleansedOptions).forEach(key => cleansedOptions[key] === undefined && delete cleansedOptions[key]);
      return cleansedOptions;
    }

    formatMoney = (value: number, options: any = {}): string => {
      const defaults: MoneyOptions = { baseCurrency: 'EUR', targetCurrency: 'EUR', rate: 1.0, locale: 'nl-BE', precision: 2, valueAsInteger: true, valueOnFail: '---' };
      const cleansedOptions = this.deleteUndefinedKeys(options);
      const opts: MoneyOptions = Object.assign({}, defaults, cleansedOptions);
      const convertMoney = opts.baseCurrency !== opts.targetCurrency;

      try {
        const base = Dinero({ amount: value, currency: opts.baseCurrency as any, precision: opts.precision }).setLocale(opts.locale);
        const target = convertMoney ? Dinero({ amount: value, currency: opts.targetCurrency as any, precision: opts.precision }).setLocale(opts.locale).multiply(opts.rate) : null;
        return target ? target.toFormat() : base.toFormat();
      } catch (error: any) {
        return opts.valueOnFail;
      }
    }

    formatDate = (value: string, options: any = {}): string => {
      const defaults: DateOptions = { format: 'L', inputIsUTC: true, outputInUTC: false, valueOnFail: '---' };
      const cleansedOptions = this.deleteUndefinedKeys(options);
      const opts: DateOptions = Object.assign({}, defaults, cleansedOptions);

      try {
        let djs = opts.inputIsUTC ? dayjs.utc(value) : dayjs(value);
        djs = opts.outputInUTC ? djs.utc() : djs.local();
        if (!djs.isValid()) {
          throw new Error();
        }
        return djs.locale('nl-BE').format(opts.format);
      } catch (error: any) {
        return opts.valueOnFail;
      }
    }

    formatTime = (value: string, options: any = {}): string => {
      const defaults: DateOptions = { format: 'HH:mm', inputIsUTC: true, outputInUTC: false, valueOnFail: '---' };
      const cleansedOptions = this.deleteUndefinedKeys(options);
      const opts: DateOptions = Object.assign({}, defaults, cleansedOptions);
      return this.formatDate(value, opts);
    }

    formatDateTime = (value: string, options: any = {}): string => {
      const defaults: DateOptions = { format: 'L LT', inputIsUTC: true, outputInUTC: false, valueOnFail: '---' };
      const cleansedOptions = this.deleteUndefinedKeys(options);
      const opts: DateOptions = Object.assign({}, defaults, cleansedOptions);

      try {
        let djs = opts.inputIsUTC ? dayjs.utc(value) : dayjs(value);
        djs = opts.outputInUTC ? djs.utc() : djs.local();
        return djs.locale('nl-BE').format(opts.format);
      } catch (error: any) {
        return opts.valueOnFail;
      }
    }

    formatISO8601 = (value: string, options: any = {}): string => {
      const defaults: DateOptions = { format: 'YYYY-MM-DDTHH:mm:ss.SSSZ', inputIsUTC: true, outputInUTC: false, valueOnFail: '---' };
      const cleansedOptions = this.deleteUndefinedKeys(options);
      const opts: DateOptions = Object.assign({}, defaults, cleansedOptions);

      try {
        let djs = opts.inputIsUTC ? dayjs.utc(value) : dayjs(value);
        djs = opts.outputInUTC ? djs.utc() : djs.local();
        return djs.locale('nl-BE').format(opts.format);
      } catch (error: any) {
        return opts.valueOnFail;
      }
    }

    dateToDateTime = (value: string): string => {
      try {
        // a Date string (2020-12-05) should always be parsed in UTC, with a Time portion of 00:00:00
        const datetime = dayjs.utc(value).toISOString();
        return this.formatISO8601(datetime);
      } catch (error: any) {
        throw 'Could not convert Date string to DateTime string (ISO8601).';
      }
    }

    timeAgo = (to: string, options: { from?: string } = {}): string => {
      const defaults: { from: string } = { from: dayjs.utc().toISOString() };
      const cleansedOptions = this.deleteUndefinedKeys(options);
      const opts: { from: string } = Object.assign({}, defaults, cleansedOptions);
      try {
        return dayjs.utc(to).from(opts.from);
      } catch (error: any) {
        throw 'Could not calculate time ago.';
      }
    }

    apply = (vue: VueConstructor<Vue>, i18n?: VueI18n): void => {

      // which locale to use? use VueI18n plugin?
      if (i18n && i18n.locale) {
        if (i18n.locale.startsWith('nl')) dayjs.locale(nl);
        if (i18n.locale.startsWith('en')) dayjs.locale(en);
      }

      // init instance objects
      vue.prototype.$dinero = Dinero;
      vue.prototype.$dayjs = dayjs;

      // init instance methods
      vue.prototype.$formatMoney = this.formatMoney;
      vue.prototype.$formatDate = this.formatDate;
      vue.prototype.$formatDateTime = this.formatDateTime;
      vue.prototype.$formatTime = this.formatTime;
      vue.prototype.$formatISO8601 = this.formatISO8601;
      vue.prototype.$dateToDateTime = this.dateToDateTime;
      vue.prototype.$timeAgo = this.timeAgo;
      vue.prototype.$validationKey = (value: object | null, key: string): object | null => {
        return filterValidationErrors(value, key);
      };

      // init filters
      vue.filter('formatMoney', function (value: number, { baseCurrency = undefined, targetCurrency = undefined, rate = undefined, locale = undefined, valueOnFail = undefined } = {}) {
        return vue.prototype.$formatMoney(value, { baseCurrency: baseCurrency, targetCurrency: targetCurrency, rate: rate, locale: locale, valueOnFail: valueOnFail });
      });
      vue.filter('formatDate', function (value: string, { format = undefined, inputIsUTC = undefined, outputInUTC = undefined, valueOnFail = undefined } = {}) {
        return vue.prototype.$formatDate(value, { format: format, inputIsUTC: inputIsUTC, outputInUTC: outputInUTC, valueOnFail: valueOnFail });
      });
      vue.filter('formatDateTime', function (value: string, { format = undefined, inputIsUTC = undefined, outputInUTC = undefined, valueOnFail = undefined } = {}) {
        return vue.prototype.$formatDateTime(value, { format: format, inputIsUTC: inputIsUTC, outputInUTC: outputInUTC, valueOnFail: valueOnFail });
      });
      vue.filter('formatTime', function (value: string, { format = undefined, inputIsUTC = undefined, outputInUTC = undefined, valueOnFail = undefined } = {}) {
        return vue.prototype.$formatTime(value, { format: format, inputIsUTC: inputIsUTC, outputInUTC: outputInUTC, valueOnFail: valueOnFail });
      });
      vue.filter('formatISO8601', function (value: string, { format = undefined, inputIsUTC = undefined, outputInUTC = undefined, valueOnFail = undefined } = {}) {
        return vue.prototype.$formatISO8601(value, { format: format, inputIsUTC: inputIsUTC, outputInUTC: outputInUTC, valueOnFail: valueOnFail });
      });
      vue.filter('timeAgo', function (value: string, opts: { from?: string } = {}) {
        return vue.prototype.$timeAgo(value, { from: opts.from });
      });
      vue.filter('validationKey', function (value: object | null, key: string) {
        return vue.prototype.$validationKey(value, key);
      });
    }

    getVueInstanceConfig = (): ThisTypedComponentOptionsWithArrayProps<Vue, object, object, object, string, object, object, object> => {
      return {};
    }
}

interface MoneyOptions {
    baseCurrency: string;
    targetCurrency: string;
    rate: number;
    locale: string;
    precision: number;
    valueAsInteger: boolean;
    valueOnFail: string;
}
interface DateOptions {
    format: string;
    inputIsUTC: boolean;
    outputInUTC: boolean;
    valueOnFail: string;
}

export const configuration = new FiltersConfiguration();
