
// import Vue from 'vue'
import Vue, { computed, defineComponent, PropType } from 'vue'
import { cloneDeep } from 'lodash'
import { Retainment, RetainmentEntity } from '../../types/entities'

export default defineComponent({
  name: 'RetainmentEditPopup',
  emits: ['update:retain', 'update:type', 'update:validUntil', 'onClickSave'],
  components: {},
  props: {
    retainment: {
      type: Object as PropType<Retainment>,
      default: () =>
        new RetainmentEntity()
    },
    loading: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: Object as PropType<any>,
      default: () => { },
    },
  },
  setup(props, { emit }) {
    // computed
    const retainmentCopy = computed(() => cloneDeep(props.retainment))
    const showValidUntilDate = computed((): string => {
      if (retainmentCopy.value && retainmentCopy.value && retainmentCopy.value.validUntil) {
        return Vue.prototype.$formatDate(retainmentCopy.value.validUntil)
      }
      return ''
    })

    return {
      retainmentCopy,
      showValidUntilDate,
    }
  },
})
