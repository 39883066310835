
	import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

	@Component
	export default class ConfirmDialogComponent extends Vue {
		@Prop({ type: Boolean, default: false }) showDialog!: boolean;
		@Prop({ type: String, required: true }) title!: string;
		@Prop({ type: Object }) titleArgument?: Object;
		@Prop({ type: String, default: 'common.deletePermanently' }) checkboxLabel!: string;
		@Prop({ type: Boolean, default: false }) allowFullDelete!: boolean;

		checkboxValue = false;

		onConfirm() {
			if (!this.allowFullDelete) {
				this.onEmitRemove();
			} else {
				this.checkboxValue ? this.onEmitDelete() : this.onEmitRemove();
			}
		}

		@Emit('onRemove')
		onEmitRemove(): void {}

		@Emit('onDelete')
		onEmitDelete(): void {}
	}
