
    import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
    import MyPurchaseInvoicesListComponent from '@/modules/purchase-invoices/components/dashboard/my-purchase-invoices-list.component.vue';
    import MyPlannedProjectListComponent from '@/modules/projects/components/dashboard/my-planned-projects-list.component.vue';
    import { UsersService } from '@/services/users.service';
    import { User } from '@/modules/entities/types/entities';
    import authStore from "@/modules/login/store";

    // namespaces

    // services
    const usersService = new UsersService()

    @Component({
        components: {
            'my-purchase-invoices-list': MyPurchaseInvoicesListComponent,
            'my-planned-projects-list': MyPlannedProjectListComponent
        }
    })
       export default class DashboardDefaultComponent extends Vue {

        // vuex actions

        // vuex getters


        // props

        // local vars/state
        user: User | null = null;

        // methods


        // handlers ('on*')

        // getters

        // watchers

        // lifecycle hooks
        mounted() {
            // get current logged in user
            this.user = authStore.auth.getters.user.value || null;
        }
    }
