import {Module} from "vuex";
import {MonthlyOverviewState} from "@/modules/monthly-overview/store/types/MonthlyOverviewState";
import {RootState} from "@/core/types/RootState";
import {state} from "@/modules/monthly-overview/store/state";
import {mutations} from "@/modules/monthly-overview/store/mutations";
import {actions} from "@/modules/monthly-overview/store/actions";
import {getters} from "@/modules/monthly-overview/store/getters";

export const store: Module<MonthlyOverviewState, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}