import { computed, ref, set } from 'vue';
import {Action, Context} from './types/composable';

type StripFirstArgument<F extends (...args: any[]) => any> = F extends (first: any, ...rest: infer A) => infer R ? (...args: A) => R : never;

/**
 * This adds a non-existing property to a reactive object and makes it reactive, so that the
 * reactive object can observe any changes on this new property.
 *   eg: `setReactiveProp(state.store, key, value)`
 *   where: `const state = reactive({ store: {} })`
 * @param obj
 * @param key
 * @param value
 */
export function setReactiveProp(obj: object, key: string, value: any): void {
  set(
    obj,
    key,
    computed(() => ref(value).value)
  );
}

/**
 * Wrap action(ctx, ...args) => { return (...args) => { return actions(ctx, args) }}
 *
 * When composables are created, we usually place actions inside the composable. For small
 * functionalities this is doable ánd can be 'easily' tested.
 *
 * Larger Composables, such as composables that provide business logic can become too big
 * after a while. Also testability is getting more difficult because the actions are hidden
 * inside the composable.
 * Therefor we can extract the actions within a single/separate file/module and import them
 * when the composable is created.
 * To be able to access the `state` that was provided within a composable, a `Context`-object
 * is provided and can be used inside the actions. But whenever we want to call an action on
 * the composable, we don't want to manually provide the context as it was already available
 * when the composable was created. Therefor wrap the original action within a new function
 * without `Context`-parameter (1st param). This allows us to build actions with 1 Context and
 * an infinite amount of parameters.
 *
 * @param ctx
 * @param actions
 * @returns
 */
export const wrapActions = <C extends Context, T extends Record<keyof T, Action>>(ctx: C, actions: T): { [K in keyof T]: StripFirstArgument<T[K]> } => {
  return Object.entries(actions).reduce(
    (wrappedActions: any, [actionName, action]: any) => {
      const proxiedAction = new Proxy(action, {
        apply: (target, thisArg, args) => {
          target.bind(thisArg);
          return target(ctx, ...args);
        },
      });
      return ({ ...wrappedActions, [actionName]: proxiedAction });
    },
    {} // initial wrappedActions object
  );
};

export const mergeComposables = <T>(defaultComposables: T, composables?: Partial<T>): T => {
  const mergedComposables = Object.assign({}, defaultComposables, composables);
  return mergedComposables;
};

/**
 * First try at merging composables and state in 1 go, but seemed better to try it in separate methods.
 */
// export const mergeOptions = <T extends Options<any, any>>(defaultOptions: T, options?: T): T => {
//
//   const mergedComposables = Object.assign({}, defaultOptions.composables, options?.composables || {});
//   const mergedState = merge({}, defaultOptions.initialState, options?.initialState || {});
//
//   return {
//     composables: mergedComposables,
//     initialState: mergedState,
//   } as T;
// };
