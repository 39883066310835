
	import { Component, Vue } from "vue-property-decorator";
	import { namespace } from "vuex-class";

	import { Actions, Getters } from "@/modules/settings/store/modules/contractor-branches/types/StoreTypes";
	import { ContractorBranch } from "@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState";
	import DeletePopup from "@/core/components/delete-popup/delete-popup.component.vue";
	import EditContractorBranchPopup
		from "@/modules/settings/views/contractor-types/components/edit-contractor-branch.component.vue";

	const contractorBranches = namespace("settings/contractor-branches");

	@Component({
		components: {
			DeletePopup,
			EditContractorBranchPopup
		}
	})
	export default class ContractorTypesComponent extends Vue {
		@contractorBranches.Action(Actions.FETCH_ALL_CONTRACTOR_BRANCHES) fetchContractorBranches!: () => void;
		@contractorBranches.Action(Actions.CREATE_CONTRACTOR_BRANCH) createContractorBranch!: (payload: ContractorBranch) => void;
		@contractorBranches.Action(Actions.DELETE_CONTRACTOR_BRANCH_BY_ID) deleteContractorBranch!: (contractorBranchId: number) => void;
		@contractorBranches.Action(Actions.EDIT_CONTRACTOR_BRANCH) editContractorBranch!: (payload: ContractorBranch) => void;

		@contractorBranches.Getter(Getters.GET_ALL_BRANCHES) allContractorBranches!: ContractorBranch[];

		contractorBranchesDialogState = {
			add: false,
			edit: false,
			delete: false
		};

		contractorBranchesDialogContent: { add: ContractorBranch, edit: ContractorBranch, delete: ContractorBranch } = {
			add: {
				id: undefined,
				abbreviation: "",
				name: "",
				description: "",
				sequence: 0,
			},
			edit: {
				id: undefined,
				abbreviation: "",
				name: "",
				description: "",
				sequence: 0,
			},
			delete: {
				id: undefined,
				abbreviation: "",
				name: "",
				description: "",
				sequence: 0,
			}
		};

		/**
		 * Default sort for our data-table.
		 * <v-data-table
		 *            v-bind:pagination.sync="pagination"
		 */
		pagination = {
			"sortBy": "sequence",
			"ascending": true,
			"rowsPerPage": -1
		};

		headers = [
			{
				text: "Abbreviation",
				align: "left",
				value: "abbreviation",
				sortable: false
			},
			{
				text: "Name",
				align: "left",
				value: "name",
				sortable: false
			},
			{
				text: "Sequence",
				align: "left",
				value: "sequence",
				sortable: false
			},
			{
				text: " ",
				align: "left",
				value: "action",
				sortable: false
			}
		];

		onOpenAddDialog() {
			this.contractorBranchesDialogState.add = true;
		}

		onOpenEditDialog(contractorBranch: ContractorBranch) {
			this.resetContractorBranchesDialogContent();
			this.contractorBranchesDialogContent.edit = contractorBranch;
			this.contractorBranchesDialogState.edit = true;
		}

		onOpenDeleteDialog(contractorBranch: ContractorBranch) {
			this.resetContractorBranchesDialogContent();
			this.contractorBranchesDialogContent.delete = contractorBranch;
			this.contractorBranchesDialogState.delete = true;
		}

		resetContractorBranchesDialogContent() {
			this.contractorBranchesDialogContent = {
				add: {
					id: undefined,
					abbreviation: "",
					name: "",
					description: "",
					sequence: 0,
				},
				edit: {
					id: undefined,
					abbreviation: "",
					name: "",
					description: "",
					sequence: 0,
				},
				delete: {
					id: undefined,
					abbreviation: "",
					name: "",
					description: "",
					sequence: 0,
				}
			};
		}

		onCreateContractorBranch() {
			this.createContractorBranch(this.contractorBranchesDialogContent.add);
			this.contractorBranchesDialogState.add = false;
		}

		onDeleteContractorBranch() {
			if (this.contractorBranchesDialogContent.delete.id) {
				this.deleteContractorBranch(this.contractorBranchesDialogContent.delete.id);
			}
		}

		onEditContractorBranch(contractorBranch: ContractorBranch) {
			this.editContractorBranch(contractorBranch);
			this.contractorBranchesDialogState.edit = false;
		}

		onEditContractorBranchInline(contractorBranch: ContractorBranch, event: any) {
			const contractorBranchCopy: ContractorBranch = JSON.parse(JSON.stringify(contractorBranch));
			contractorBranchCopy.sequence = event.target.value;
			this.editContractorBranch(contractorBranchCopy);
		}

		created() {
			this.fetchContractorBranches();
		}
	}

