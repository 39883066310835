import {RouteConfig} from 'vue-router';
import ClientView from "@/modules/clients/views/client.view.vue";

export const routes: RouteConfig[] = [
    {
        path: '/clients/:id',
        name: 'clientDetails',
        component: ClientView,
    }
];
