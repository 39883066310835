
    import { defineComponent, PropType, computed } from 'vue';
    import { User, UserEntity } from '@/modules/entities/types/entities';

    export default defineComponent({
        name: 'UserInfoSection',
        emits: ['update:user'],
        props: {
            user: { type: Object as PropType<User>, required: true, default: () => new UserEntity() },
            emailRules: { type: Array, default: () => [] },
            validationErrors: { type: Object, default: null }
        },
        setup(props, { emit }) {

            /**
             * Methods
             * 
             */
            const onInputUser = (user: User) => emit('update:user', user)

            /**
             * Computed
             * 
             * Computed values are cached. Only when the underlying data changes, the value gets re-computed.
             *   so, a cached copy of the prop in combination with :value and @input on form elements, we can 
             *   emit changes and let the parent mutate the changes 
             */
            const userCopy = computed<User>(() => JSON.parse(JSON.stringify(props.user)))

            /**
             * Watchers
             * 
             */
            // watch(props.user, (newValue, oldValue) => {
            //     console.log(props.user)
            //     console.log(userCopy)
            // }, { immediate: true, deep: true })
            
            return {
                userCopy,
                onInputUser,
            }
        },
    })
