
  import { computed, defineComponent } from 'vue';
  import { Team, TeamMember2 } from '@/modules/settings/types/entities';
  import dayjs from 'dayjs';
  import DivisionTeamMembersListComponent from '@/modules/settings/components/division-team-members-list.component.vue';
  import useTeam from '@/composables/useTeam';
  import useEmployeeList from '@/composables/useEmployeeList';
  import useTeamList from '@/composables/useTeamList';
  import useRouter from '@/composables/useRouter';

  export default defineComponent({
    name: 'DivisionDetail',
    components: {
      'team-members': DivisionTeamMembersListComponent,
    },
    setup() {
      const { getters: teamGetters, actions: teamActions } = useTeam();
      const { getters: employeeListGetters, actions: employeeListActions } = useEmployeeList();
      const { getters: teamListGetters, actions: teamListActions } = useTeamList();
      const loading = computed(() => employeeListGetters.isLoading.value || teamGetters.isLoading.value);
      const divisionId = useRouter().getters.params.value.id;

      const onAddTeamMember = async (team: Team) => {
        await teamActions.setTeam(team);
        await teamActions.newTeamMember();
        await teamListActions.fetchTeams();
      };

      const onAfterSavedTeamMember = async (team: Team, teamMember: TeamMember2) => {
        await teamListActions.fetchTeams();
      };

      const onDeleteTeamMember = async (team: Team, teamMember: TeamMember2) => {
        await teamActions.setTeam(team);
        await teamActions.deleteTeamMember(teamMember);
        await teamListActions.fetchTeams();
      };

      const getTeamMembersPerActiveState = async (team: Team) => {
        let activeTeamMembers: TeamMember2[] = [];
        let inactiveTeamMembers: TeamMember2[] = [];

        if (team.teamMembers) {
          const today = dayjs.utc();
          (team.teamMembers as TeamMember2[]).forEach((x) => {
            const start = dayjs.utc(x.start);
            const end = dayjs.utc(x.end);
            if ((start.isBefore(today) || start.isSame(today)) && (today.isBefore(end) || today.isSame(end))) {
              activeTeamMembers.push(x);
            } else {
              inactiveTeamMembers.push(x);
            }
          });

          // sort team members desc by end date
          activeTeamMembers.sort((a, b) => Number(new Date(b.end)) - Number(new Date(a.end)));
          inactiveTeamMembers.sort((a, b) => Number(new Date(b.end)) - Number(new Date(a.end)));
        }

        return {
          activeTeamMembers,
          inactiveTeamMembers,
        };
      };

      teamListActions.setFilter('divisions', [divisionId]).then(() => {
        teamListActions.fetchTeams();
      });
      employeeListActions.fetchEmployees();

      return {
        onAddTeamMember,
        onDeleteTeamMember,
        onAfterSavedTeamMember,
        getTeamMembersPerActiveState,
        employeeListGetters,
        teamListGetters,
        loading,
      };
    },
  });

  // // const divisions = namespace('settings/divisions');
  // const { getters: teamGetters, actions: teamActions } = useTeam();
  // const { getters: employeeListGetters, actions: employeeListActions } = useEmployeeList();
  // const { getters: teamListGetters, actions: teamListActions } = useTeamList();
  // const loading = computed(() => employeeListGetters.isLoading.value || teamGetters.isLoading.value);

  // @Component({
  //   components: {
  //     'team-members': DivisionTeamMembersListComponent,
  //   },
  // })
  // export class DivisionDetailComponent extends Vue {
  //   // @divisions.Action(Actions.FETCH_DIVISION_BY_ID) fetchDivisionById!: (id: string) => void;
  //   // @divisions.Action(Actions.ADD_NEW_DIVISION_TEAM) addTeam!: (payload: { divisionId?: number; team: Team }) => void;
  //   // @divisions.Action(Actions.EDIT_DIVISION_TEAM) editTeam!: (payload: { divisionId?: number; team: Team }) => void;
  //   // @divisions.Action(Actions.DELETE_DIVISION_TEAM_BY_ID) deleteTeam!: (payload: {
  //   //   divisionId?: number;
  //   //   team: Team;
  //   // }) => void;

  //   // @divisions.Getter(Getters.GET_DIVISION_DETAIL) divisionDetail!: Division;

  //   allEmployees = employeeListGetters.employees;
  //   divisionTeams = teamListGetters.teams;
  //   teamGetters = teamGetters;
  //   loading = loading;

  //   // teamDialogState = {
  //   //   add: false,
  //   //   edit: false,
  //   //   delete: false,
  //   // };

  //   // teamDialogContent: { add: Team; edit: Team; delete: Team } = {
  //   //   add: {
  //   //     id: -1,
  //   //     name: '',
  //   //     abbreviation: '',
  //   //   },
  //   //   edit: {
  //   //     id: -1,
  //   //     name: '',
  //   //     abbreviation: '',
  //   //     contactId: undefined,
  //   //   },
  //   //   delete: {
  //   //     id: -1,
  //   //     name: '',
  //   //     abbreviation: '',
  //   //     contactId: undefined,
  //   //   },
  //   // };

  //   // rules = {
  //   //   required: (value: any) => !!value || 'Required.',
  //   //   counterText: (value: any) => (value && value.length >= 1 && value.length <= 50) || 'Min 1 character',
  //   // };

  //   // greatestTeamMemberByEndDate = (teamMembers: TeamMember2[]) =>
  //   //   [...teamMembers].sort((a, b) => sortNatural(b.end, a.end))[0];
  //   // greatestEndDate = (teamMembers: TeamMember2[]) => this.greatestTeamMemberByEndDate(teamMembers).end;

  //   async onAddTeamMember(team: Team) {
  //     // const teamMembers = team.teamMembers as TeamMember2[];
  //     // const greatestTeamMemberByEndDate = this.greatestTeamMemberByEndDate(teamMembers);
  //     // const oldEndDate = dayjs.utc(greatestTeamMemberByEndDate.end);
  //     // const teamMember = new TeamMember2Entity({
  //     //   start: oldEndDate.add(1, 'day').format('YYYY-MM-DD'),
  //     //   end: oldEndDate.add(7, 'day').format('YYYY-MM-DD'),
  //     //   employee: cloneDeep(greatestTeamMemberByEndDate.employee),
  //     // });
  //     await teamActions.setTeam(team);
  //     await teamActions.newTeamMember();
  //     await teamListActions.fetchTeams();
  //   }

  //   async onAfterSavedTeamMember(team: Team, teamMember: TeamMember2) {
  //     await teamListActions.fetchTeams();
  //   }

  //   async onDeleteTeamMember(team: Team, teamMember: TeamMember2) {
  //     await teamActions.setTeam(team);
  //     await teamActions.deleteTeamMember(teamMember);
  //     await teamListActions.fetchTeams();
  //   }

  //   getTeamMembersPerActiveState(team: Team) {
  //     if (team.teamMembers) {
  //       let activeTeamMembers: TeamMember2[] = [];
  //       let inactiveTeamMembers: TeamMember2[] = [];

  //       const today = dayjs.utc();

  //       (team.teamMembers as TeamMember2[]).forEach((x) => {
  //         const start = dayjs.utc(x.start);
  //         const end = dayjs.utc(x.end);
  //         if ((start.isBefore(today) || start.isSame(today)) && (today.isBefore(end) || today.isSame(end))) {
  //           activeTeamMembers.push(x);
  //         } else {
  //           inactiveTeamMembers.push(x);
  //         }
  //       });

  //       // sort team members desc by end date
  //       activeTeamMembers.sort((a, b) => Number(new Date(b.end)) - Number(new Date(a.end)));
  //       inactiveTeamMembers.sort((a, b) => Number(new Date(b.end)) - Number(new Date(a.end)));

  //       return {
  //         activeTeamMembers,
  //         inactiveTeamMembers,
  //       };
  //     }

  //     return {
  //       activeTeamMembers: [],
  //       inactiveTeamMembers: [],
  //     };
  //   }

  //   created() {
  //     // this.fetchDivisionById(this.$route.params.id);
  //     teamListActions.setFilter('divisions', [this.$route.params.id]).then(() => {
  //       teamListActions.fetchTeams();
  //     });
  //     employeeListActions.fetchEmployees();
  //   }

  //   // onAddTeam() {
  //   //   this.addTeam({
  //   //     divisionId: this.divisionDetail.id,
  //   //     team: this.teamDialogContent.add,
  //   //   });
  //   //   this.teamDialogState.add = false;
  //   // }

  //   // onDeleteTeam() {
  //   //   this.deleteTeam({
  //   //     divisionId: this.divisionDetail.id,
  //   //     team: this.teamDialogContent.delete,
  //   //   });
  //   // }

  //   // onEditTeam() {
  //   //   // this.editTeam({
  //   //     divisionId: this.divisionDetail.id,
  //   //     team: this.teamDialogContent.edit,
  //   //   });
  //   //   this.teamDialogState.edit = false;
  //   // }

  //   // onOpenAddTeamDialog() {
  //   //   this.resetTeamDialogContent();
  //   //   this.teamDialogState.add = true;
  //   // }

  //   // onOpenEditTeamDialog(team: Team) {
  //   //   this.resetTeamDialogContent();
  //   //   this.teamDialogContent.edit = Object.assign({}, team);
  //   //   this.teamDialogState.edit = true;
  //   // }

  //   // onOpenDeleteTeamDialog(team: Team) {
  //   //   this.resetTeamDialogContent();
  //   //   this.teamDialogContent.delete = team;
  //   //   this.teamDialogState.delete = true;
  //   // }

  //   // resetTeamDialogContent() {
  //   //   this.teamDialogContent = {
  //   //     add: {
  //   //       id: -1,
  //   //       name: '',
  //   //       abbreviation: '',
  //   //     },
  //   //     edit: {
  //   //       id: -1,
  //   //       name: '',
  //   //       abbreviation: '',
  //   //       contactId: undefined,
  //   //     },
  //   //     delete: {
  //   //       id: -1,
  //   //       name: '',
  //   //       abbreviation: '',
  //   //       contactId: undefined,
  //   //     },
  //   //   };
  //   // }
  // }
