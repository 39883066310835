
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { Actions, Getters } from '@/modules/projects/store/types/StoreTypes';
  import { Project, ProjectStatus, ProjectUser, ThirdParty } from '@/modules/projects/types/entities';
  import { Supplier, Comment } from '@/modules/entities/types/entities';
  import DetailsTextFieldComponent from '@/core/components/details-page/details-text-field.component.vue';
  import DetailsDatePickerComponent from '@/core/components/details-page/details-date-picker.component.vue';
  import { JsonResource } from '@/core/types/Entities';
  import {
    Actions as ClientActions,
    Getters as ClientGetters,
  } from '@/modules/entities/store/modules/clients/types/StoreTypes';
  import { Actions as ContactActions } from '@/modules/contacts/store/types/StoreTypes';
  import { Client } from '@/modules/entities/types/entities';
  import { debounce } from 'lodash';
  import { ProjectStatusMixin } from '@/mixins/project-status-mixin.component';
  import ContactsListComponent from '@/modules/contacts/components/contacts-list/contacts-list.component.vue';
  import { AxiosError, AxiosResponse } from 'axios';
  import ProjectFinancesSectionComponent from '@/modules/projects/components/project-finances-section/project-finances-section.component.vue';
  import ProjectWorkflowSectionComponent from '@/modules/projects/components/project-workflow-section/project-workflow-section.component.vue';
  import DetailsExpansionPanelComponent from '@/core/components/details-page/details-expansion-panel.component.vue';
  import ProjectQuotesListComponent from '@/modules/projects/components/project-quotes-list/project-quotes-list.component.vue';
  import ProjectPurchaseInvoicesListComponent from '@/modules/projects/components/project-purchase-invoices-list/project-purchase-invoices-list.component.vue';
  import ProjectSalesInvoicesListComponent from '@/modules/projects/components/project-sales-invoices-list/project-sales-invoices-list.component.vue';
  import ProjectSalesInvoicesMetricsReportComponent from '@/modules/projects/components/project-sales-invoices-metrics-report/project-sales-invoices-metrics-report.component.vue';
  import ProjectClientsListComponent from '@/modules/projects/components/project-clients-list/project-clients-list.component.vue';
  import { GetAddressMixin } from '@/mixins/get-address-mixin.component';
  import { ReportsService } from '@/modules/reports/services/reports.service';
  import { Contact } from '@/modules/contacts/types/entities';
  import calendarStore from '@/modules/calendar/store';
  import { Quote } from '@/modules/calendar/interfaces/project/quote';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import { AppMessageType } from '@/core/components/app-message/entities';
  import {ProjectContractorBranch} from '@/modules/projects/types/entities';
  import ProjectTimesheetEntriesListComponent
    from "@/modules/projects/components/project-timesheet-entries-list/project-timesheet-entries-list.component.vue";
  import ProjectContractorBranchListComponent
    from '@/modules/projects/components/project-contractor-branch-list/project-contractor-branch-list.component.vue';
  import authStore from "@/modules/login/store";

  const projects = namespace('projects');
  const contacts = namespace('contacts');
  const clients = namespace('entities/clients');
  const workflow = namespace('workflow');
  const planner = namespace('planner');
  declare let google: any;

  @Component({
    mixins: [ProjectStatusMixin, GetAddressMixin],
    components: {
      SelectContractorTypeTable: () =>
        import(
          /* webpackChunkName: "selectContractorTypeTable" */ '@/modules/settings/views/contractor-types/components/select-contractor-type-table.component.vue'
        ),
      SelectSupplierTable: () =>
        import(
          /* webpackChunkName: "selectSupplierTable" */ '@/modules/entities/components/suppliers/select-supplier-list.component.vue'
        ),
      SelectSupplierTypeComponent: () =>
        import(
          /* webpackChunkName: "selectSupplierTypeTable" */ '@/modules/entities/components/suppliers/select-supplier-type.component.vue'
        ),
      CreateContactComponent: () =>
        import(
          /* webpackChunkName: "createContactComponent" */ '@/modules/contacts/components/create-contact/create-contact.component.vue'
        ),
      SupplierModal: () =>
        import(
          /* webpackChunkName: "supplierModalComponent" */ '@/modules/entities/components/suppliers/supplier-modal.component.vue'
        ),
      DetailsTextFieldComponent,
      DetailsDatePickerComponent,
      GmapPickerComponent: () =>
        import(
          /* webpackChunkName: "gmapPickerComponent" */ '@/core/components/details-page/gmap-picker.component.vue'
        ),
      'confirm-dialog': () =>
        import(
          /* webpackChunkName: "confirmDialogComponent" */ '@/core/components/confirm-dialog/confirm-dialog.component.vue'
        ),
      'contacts-list': ContactsListComponent,
      'project-finances': ProjectFinancesSectionComponent,
      'project-workflow': ProjectWorkflowSectionComponent,
      'details-expansion-panel': DetailsExpansionPanelComponent,
      'project-quotes': ProjectQuotesListComponent,
      'project-purchase-invoices': ProjectPurchaseInvoicesListComponent,
      'project-sales-invoices': ProjectSalesInvoicesListComponent,
      'project-sales-invoices-metrics-report': ProjectSalesInvoicesMetricsReportComponent,
      'project-timesheet-entries': ProjectTimesheetEntriesListComponent,
      'project-clients': ProjectClientsListComponent,
      'contractor-branches-list': ProjectContractorBranchListComponent,
      'project-orders': () =>
        import(
          /* webpackChunkName: "projectOrders" */ '@/modules/projects/components/project-orders-list/project-orders-list.component.vue'
        ),
      'select-client-table': () =>
        import(
          /* webpackChunkName: "selectClientTable" */ '@/modules/entities/components/clients/select-client-list.component.vue'
        ),
      'project-status-select': () =>
        import(
          /* webpackChunkName: "projectStatusSelect" */ '@/modules/projects/components/project-status-select/project-status-select.component.vue'
        ),
      'project-date-select': () =>
        import(
          /* webpackChunkName: "projectDatePicker" */ '@/modules/projects/components/project-date-select/project-date-select.component.vue'
        ),
      'project-third-parties': () =>
        import(
          /* webpackChunkName: "projectThirdParties" */ '@/modules/projects/components/project-third-parties-list/project-third-parties-list.component.vue'
        ),
      'project-responsibility-section': () =>
        import(
          /* webpackChunkName: "projectResponsibilitySection" */ '@/modules/projects/components/project-responsibility-section/project-responsibility-section.component.vue'
        ),
      // 'project-purchase-invoices': () => import(/* webpackChunkName: "projectResponsibilitySection" */ '@/modules/projects/components/project-purchase-invoices-list/project-purchase-invoices-list.component.vue')
    },
    setup() {
      return {
        auth: authStore.auth
      }
    }
  })
  export default class ProjectDetailsComponent extends Vue {
    @projects.Action(Actions.FETCH_PROJECT_BY_ID) fetchProjectById!: (payload: string) => Promise<void>;
    @projects.Action(Actions.FETCH_ALL_USERS) fetchAllUsers!: () => void;
    @projects.Action(Actions.FETCH_PROJECT_STATUSES) fetchProjectStatuses!: () => void;
    @projects.Action(Actions.EDIT_INSPECTED_PROJECT_BY_ID) editProject!: (
      payload: Project
    ) => Promise<JsonResource<Project>>;
    @projects.Action(Actions.TOGGLE_ASSOCIATION_EXISTING_CLIENT_TO_PROJECT)
    toggleAssociationExistingClientToProject!: (payload: { projectId: number; clientId: number }) => void;
    @projects.Action(Actions.FILTER_PROJECT_CONTACTS) filterProjectContacts!: (contactId: number) => Promise<void>;
    @projects.Action(Actions.DESTROY_PROJECT_DETAILS) destroyProjectDetails!: () => void;
    @projects.Action(Actions.ADD_THIRD_PARTY) addThirdParty!: (payload: {
      projectId: number;
      supplierTypeId: number;
    }) => Promise<any>;
    @projects.Action(Actions.TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_PROJECT)
    toggleAssociationExistingContactToProject!: (payload: { projectId: number; contactId: number }) => Promise<any>;
    @projects.Action(Actions.DELETE_INSPECTED_PROJECT_BY_ID) deleteProjectById!: (payload: number) => Promise<void>;
    @projects.Action(Actions.TOGGLE_ASSOCIATION_EXISTING_SUPPLIER_TO_THIRD_PARTY)
    toggleAssociationExistingSupplierToThirdParty!: (payload: {
      projectId: number;
      thirdPartyPivotId: number;
      supplierTypeId: number;
      supplierId: number | null;
    }) => Promise<void>;
    @projects.Action(Actions.ATTACH_NEW_QUOTE_TO_PROJECT) attachQuote!: (payload: {
      projectId: number;
      contractorTypeIds: number | number[];
      withOffer: boolean;
    }) => Promise<void>;
    @projects.Action(Actions.SAVE_COMMENT) saveCommentToProject!: (payload: Comment) => void;
    @projects.Action(Actions.EDIT_COMMENT) editComment!: (payload: Comment) => Promise<void>;
    @projects.Action(Actions.DELETE_COMMENT) deleteComment!: (payload: number) => void;
    @projects.Action(Actions.UN_ARCHIVE_PROJECT_BY_ID) unArchiveProjectById!: (payload: number) => Promise<void>;
    @projects.Action(Actions.ARCHIVE_PROJECT_BY_ID) archiveProjectById!: (payload: number) => Promise<void>;
    @projects.Action(Actions.SET_DETACHED_CONTRACTOR) setOnDetachContractor!: (payload: Quote) => void;
    @projects.Action(Actions.SET_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP) setDetachedContractorShowDeletePopup!: (
      payload: boolean
    ) => void;
    @projects.Action(Actions.DETACH_CONTRACTOR) detachContractor!: () => void;

    @clients.Action(ClientActions.SET_CLIENTS_FILTER_NAME_QUERY) setClientsFilterName!: (payload: string) => void;
    @contacts.Action(ContactActions.DELETE_CONTACT_BY_ID) deleteContactById!: (contactId: number) => Promise<void>;

    @projects.Getter(Getters.GET_PROJECT_DETAILS) project!: Project;
    @projects.Getter(Getters.GET_USERS) users!: ProjectUser[];
    @projects.Getter(Getters.GET_PROJECT_STATUSES) projectStatuses!: ProjectStatus[];
    @projects.Getter(Getters.GET_LOADING) isLoading!: boolean;
    @projects.Getter(Getters.GET_DETACHED_CONTRACTOR_STATE) detachedContractorState!: {
      quote: Quote;
      showDeleteDialog: boolean;
    };
    @clients.Getter(ClientGetters.GET_FETCHED_CLIENTS) clients!: Client[];

    autocomplete!: google.maps.places.Autocomplete;
    citiesAutocomplete!: google.maps.places.Autocomplete;
    postalAutocomplete!: google.maps.places.Autocomplete;
    sfinxHqGeoCoordinates = { lat: 51.0218, lng: 3.4451 };

    validationErrors: any = null;

    projectDetailsDialogState = {
      edit: false,
      edit_third_party: false,
      add_contact: false,
      add_contractor_branch_relevance: false,
      add_supplier: false,
      add_comment: false,
      edit_supplier: false,
      remove_third_party: false,
      delete: false,
      add_third_party: false,
      select_project_status: false,
      showMap: false,
      is_workflow_opened: true, // CHANGE IT
      createSupplierModalOpen: false,
      isGeneralCommentsExpanded: false,
      addQuote: false,
      addOrder: false,
      editClient: false,
      showClientModal: false,
      editAddress: false,
      showClientTooltip: false,
      editProjectStatus: false,
      editAddedDate: false,
      editInitialOfferDate: false,
      editProjectName: false,
      deleteComment: false,
      editComment: false,
      editProjectFinances: false,
      editResponsibleGroups: false,
      unArchive: false,
      archive: false,
    };

    projectDetailsDialogContent: {
      beforeEdit: Project;
      edit: Project;
      add_contact: Contact;
      add_contractor_branch_relevance: ProjectContractorBranch;
      add_comment: string;
      supplier: Supplier;
      remove_third_party: ThirdParty;
      delete: Project;
      supplierTypeId: number;
      thirdPartyPivotId: number;
      location: any;
      beforeGmap: { address: string; distance: string };
      city: string;
      comment: Comment;
    } = {
      beforeEdit: {} as Project,
      edit: {} as Project,
      add_contact: {} as Contact,
      add_contractor_branch_relevance: {} as ProjectContractorBranch,
      add_comment: '',
      supplier: {} as Supplier,
      remove_third_party: {} as ThirdParty,
      delete: {} as Project,
      supplierTypeId: 0,
      thirdPartyPivotId: 0,
      location: {},
      beforeGmap: { address: '', distance: '' },
      city: '',
      comment: {
        id: 0,
        body: '',
        commentableId: 0,
        commentableType: '',
      },
    };

    clientSearchValue = '';
    projectCopy: any = null;

    salesInvoicesMetricsReport: any = null;

    onNavigateBack(): void {
      window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/projects');
    }

    attachClients(clientIds: number[]): void {
      this.projectDetailsDialogState.showClientModal = false;
      clientIds.forEach((clientId: number) => {
        this.toggleAssociationExistingClientToProject({
          projectId: this.project.id,
          clientId,
        });
      });
    }

    mergeClientAndProjectContacts(project: Project) {
      let contacts: Contact[] = [];
      // project.clients.forEach((client: Client) => {
      // 	if (client.contacts.length > 0) {
      // 		contacts = contacts.concat(client.contacts);
      // 	}
      // })

      if (project.contacts && project.contacts.length > 0) {
        contacts = contacts.concat(project.contacts);
      }

      return contacts;
    }

    onExistingContactAttachToProject() {
      this.resetProjectDetailsDialogContent();
      this.projectDetailsDialogState.add_contact = true;
    }

    onOpenAddContractorBranchRelevanceDialog() {
      this.resetProjectDetailsDialogContent();
      this.projectDetailsDialogState.add_contractor_branch_relevance = true;
    }

    onAttachQuoteToProject(withQuote: boolean): void {
      this.resetProjectDetailsDialogContent();
      if (withQuote) {
        this.projectDetailsDialogState.addQuote = true;
      } else if (!withQuote) {
        this.projectDetailsDialogState.addOrder = true;
      }
    }

    onOpenDeleteProjectDialog(project: Project) {
      this.resetProjectDetailsDialogContent();
      this.projectDetailsDialogContent.delete = project;
      this.projectDetailsDialogState.delete = true;
    }

    onOpenAddThirdPartyDialog() {
      this.resetProjectDetailsDialogContent();
      this.projectDetailsDialogState.add_third_party = true;
    }

    onRemoveContact(contactId: number) {
      if (contactId > 0) {
        this.toggleAssociationExistingContactToProject({
          projectId: this.project.id!,
          contactId,
        });
      }
    }

    onDeleteContact(contactId: number): void {
      if (contactId > 0) {
        this.deleteContactById(contactId).then(() => {
          this.filterProjectContacts(contactId).catch(() => {
            appMessageStore.actions.set({
              message: 'projects.globalMessage.deleteContactError',
              ttl: 5000,
              dismissed: false,
              type: AppMessageType.ERROR,
            });
          });
        });
      }
    }

    onDeleteProject() {
      if (this.projectDetailsDialogContent.delete.id) {
        this.deleteProjectById(this.projectDetailsDialogContent.delete.id)
          .then(() => {
            this.$router.go(-1);
          })
          .catch((err) => {
            //
          });
      }
    }

    navigateToPlanner(): void {
      calendarStore.calendarSettings.actions
        .addProjectsToCalendar([this.project.id])
        .then(() => this.$router.push({ name: 'calendar' }));
    }

    resetProjectDetailsDialogContent() {
      this.projectDetailsDialogContent = {
        beforeEdit: this.projectDetailsDialogContent.beforeEdit,
        edit: {
          id: 0,
          uniqueId: '',
          projectName: '',
          presentedPrice: undefined,
          initialOffer: 0,
          initialOfferComment: '',
          dateInitialOffer: null,
          dateFinancialDetails: null,
          thirdParties: [],
          clients: [],
          contractorParties: [],
          addedDate: null,
          street: null,
          streetNumber: null,
          postalCode: null,
          city: null,
          distanceToSfinxHq: null,
          socialSecurityCompleted: false,
          socialSecurityReference: '',
          socialSecurityStartDate: null,
          socialSecurityEndDate: null,
          projectLeaders: [],
          estimators: [],
          drawers: [],
          designers: [],
          quotes: [],
          orders: [],
          archivedAt: null,
          latestProgressSnapshot: null,
          contractorBranches: [],
        },
        add_contact: {
          id: 0,
          firstName: '',
          lastName: '',
          street: '',
          postalCode: '',
          streetNumber: '',
          flatNumber: '',
          city: '',
          website: '',
          bankAccountNumber: '',
          socialSecurityNumber: '',
          vatNumber: '',
          contactFunction: '',
          comment: '',
          suppliers: [],
          teleinformations: [],
        },
        add_contractor_branch_relevance: {
          id: 0,
          projectId: 0,
          contractorTypeId: 0,
          contractorTypeName: '',
          isRelevant: false,
          comment: '',
        },
        add_comment: '',
        supplier: {
          id: 0,
          name: '',
          commercialName: '',
          vatNumber: '',
          ssrNumber: '',
          bankAccounts: [],
          price: 0,
          priceComment: '',
          quality: 0,
          qualityComment: '',
          technicalExpertise: 0,
          aestheticQuality: 0,
          aestheticQualityComment: '',
          workforce: '',
          activities: '',
          speciality: '',
          province: {
            id: 0,
            name: '',
          },
          parentSupplierId: 0,
          comment: '',
          street: '',
          streetNumber: '',
          postalCode: '',
          city: '',
          outboundKm: 0,
          preferredPaymentTerm: 0,
          preferredPaymentTermBoolean: false,
          preferredPaymentDiscountTerm: 0,
          preferredPaymentDiscountTermBoolean: false,
          preferredPaymentDiscountPercentage: 0,
          futureWork: 0,
          futureWorkComment: '',
          contacts: [],
          companyTypes: [],
          contractorTypes: [],
          teleinformations: [],
          website: '',
          createdAt: '',
          updatedAt: '',
          retainments: [],
        },
        remove_third_party: {
          supplierTypeId: 0,
          pivotId: 0,
          name: '',
          description: '',
          supplier: undefined,
          supplierPermAssigned: 0,
          supplierTempAssigned: 0,
          thirdPartyPermAssigned: 0,
          thirdPartyTempAssigned: 0,
          comment: '',
        },
        delete: {
          id: 0,
          uniqueId: '',
          projectName: '',
          presentedPrice: undefined,
          initialOffer: 0,
          initialOfferComment: '',
          dateInitialOffer: null,
          dateFinancialDetails: null,
          thirdParties: [],
          clients: [],
          contractorParties: [],
          addedDate: null,
          street: null,
          streetNumber: null,
          postalCode: null,
          city: null,
          distanceToSfinxHq: null,
          socialSecurityCompleted: false,
          socialSecurityReference: '',
          socialSecurityStartDate: null,
          socialSecurityEndDate: null,
          projectLeaders: [],
          estimators: [],
          drawers: [],
          designers: [],
          quotes: [],
          orders: [],
          archivedAt: null,
          latestProgressSnapshot: null,
          contractorBranches: [],
        },
        supplierTypeId: 0,
        thirdPartyPivotId: 0,
        location: undefined,
        beforeGmap: { address: '', distance: '' },
        city: this.projectDetailsDialogContent.city,
        comment: {
          id: 0,
          body: '',
          commentableId: 0,
          commentableType: '',
        },
      };
    }

    onEditProjectDetails(project?: Project): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        this.editProject(project ? project : this.projectCopy)
          .then(() => {
            this.validationErrors = null;
            resolve(true);
          })
          .catch((err: AxiosError) => {
            if (err.response && err.response.status === 422) {
              this.validationErrors = err.response.data.errors;
              reject();
            } else {
              reject();
              throw err;
            }
          });
      });
    }

    onEditProjectAddress(): void {
      this.onEditProjectDetails().then(() => (this.projectDetailsDialogState.editAddress = false));
    }

    onEditProjectAddedDate(): void {
      this.onEditProjectDetails().then(() => (this.projectDetailsDialogState.editAddedDate = false));
    }

    onEditProjectInitialOfferDate(): void {
      this.onEditProjectDetails().then(() => (this.projectDetailsDialogState.editInitialOfferDate = false));
    }

    onEditProjectName(): void {
      this.onEditProjectDetails().then(() => (this.projectDetailsDialogState.editProjectName = false));
    }

    onEditProjectFinances(project: Project): void {
      this.onEditProjectDetails(project).then(() => (this.projectDetailsDialogState.editProjectFinances = false));
    }

    onEditProjectStatus(): void {
      this.onEditProjectDetails().then(() => (this.projectDetailsDialogState.editProjectStatus = false));
    }

    onEditProjectResponsibleGroups(): void {
      this.onEditProjectDetails().then(() => (this.projectDetailsDialogState.editResponsibleGroups = false));
    }

    unArchiveProject(): void {
      this.unArchiveProjectById(this.project.id).catch(() => {
        appMessageStore.actions.set({
          message: 'projects.globalMessage.unArchiveError',
          ttl: 7500,
          dismissed: false,
          type: AppMessageType.ERROR,
        });
      });
    }

    archiveProject(): void {
      this.archiveProjectById(this.project.id).catch(() => {
        appMessageStore.actions.set({
          message: 'projects.globalMessage.archiveError',
          ttl: 7500,
          dismissed: false,
          type: AppMessageType.ERROR,
        });
      });
    }

    onAddComment($event: any) {
      if ($event) {
        $event.stopPropagation();
      }
      this.projectDetailsDialogState.add_comment = true;
      this.projectDetailsDialogContent.add_comment = '';
    }

    saveComment(): void {
      if (this.projectDetailsDialogContent.comment.id > 0) {
        this.editComment(this.projectDetailsDialogContent.comment).then(
          () => (this.projectDetailsDialogState.editComment = false)
        );
      }
    }

    onDeleteComment(comment: Comment): void {
      this.resetProjectDetailsDialogContent();
      this.projectDetailsDialogContent.comment = comment;
      this.projectDetailsDialogState.deleteComment = true;
    }

    onEditComment(comment: Comment): void {
      this.resetProjectDetailsDialogContent();
      this.projectDetailsDialogContent.comment = Object.assign({}, comment);
      this.projectDetailsDialogState.editComment = true;
    }

    onNewCommentSave() {
      if (this.projectDetailsDialogContent.add_comment !== '') {
        const comment: Comment = {
          id: 0,
          body: this.projectDetailsDialogContent.add_comment,
          commentableId: this.project.id!,
          commentableType: 'App\\Models\\Common\\Project',
        };
        this.saveCommentToProject(comment);
        this.projectDetailsDialogState.add_comment = false;
      }
    }

    onAttachContact(contactId: number) {
      if (
        this.project.contacts &&
        this.project.contacts.length &&
        this.project.contacts.some((x) => x.id === contactId)
      ) {
        appMessageStore.actions.set({
          message: 'common.messages.contactAlreadyAttached',
          ttl: 7500,
          dismissed: false,
          type: AppMessageType.WARNING,
        });
      } else {
        this.toggleAssociationExistingContactToProject({
          projectId: this.project.id!,
          contactId: contactId,
        }).then(() => (this.projectDetailsDialogState.add_contact = false));
      }
    }

    onThirdPartySupplierSelect(thirdPartyPivotId: number, supplierTypeId: number) {
      this.resetProjectDetailsDialogContent();
      this.projectDetailsDialogContent.supplierTypeId = supplierTypeId;
      this.projectDetailsDialogContent.thirdPartyPivotId = thirdPartyPivotId;
      this.projectDetailsDialogState.add_supplier = true;
    }

    onAttachThirdParty(supplierTypeId: number) {
      this.projectDetailsDialogState.add_third_party = false;
      this.addThirdParty({
        projectId: this.project.id!,
        supplierTypeId: supplierTypeId,
      });
    }

    onChooseThirdPartySupplier(supplierTypeId: number) {
      this.resetProjectDetailsDialogContent();
      this.projectDetailsDialogState.add_third_party = false;
      this.addThirdParty({
        projectId: this.project.id!,
        supplierTypeId,
      }).then((res: AxiosResponse<JsonResource<Project>>) => {
        this.projectDetailsDialogContent.supplierTypeId = supplierTypeId;
        this.projectDetailsDialogContent.thirdPartyPivotId =
          res.data.data.thirdParties[res.data.data.thirdParties.length - 1].pivotId;
        this.projectDetailsDialogState.add_supplier = true;
      });
    }

    onAttachSupplierToThirdParty(supplierId: number) {
      this.toggleAssociationExistingSupplierToThirdParty({
        projectId: this.project.id!,
        thirdPartyPivotId: this.projectDetailsDialogContent.thirdPartyPivotId,
        supplierTypeId: this.projectDetailsDialogContent.supplierTypeId,
        supplierId,
      }).then(() => (this.projectDetailsDialogState.add_supplier = false));
    }

    onAttachQuote(contractorTypeIds: number | number[]): void {
      this.attachQuote({
        projectId: this.project.id!,
        contractorTypeIds,
        withOffer: true,
      }).then(() => (this.projectDetailsDialogState.addQuote = false));
    }

    onAttachOrder(contractorTypeIds: number | number[]): void {
      this.attachQuote({
        projectId: this.project.id!,
        contractorTypeIds,
        withOffer: false,
      }).then(() => (this.projectDetailsDialogState.addOrder = false));
    }

    onCreateNewSupplier() {
      this.projectDetailsDialogState.add_supplier = false;
      this.projectDetailsDialogState.createSupplierModalOpen = true;
    }

    initAutocomplete(): void {
      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.streetInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['geocode'] }
      );

      this.citiesAutocomplete = new google.maps.places.Autocomplete(
        (this.$refs.citiesInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['(cities)'] }
      );

      this.postalAutocomplete = new google.maps.places.Autocomplete(
        (this.$refs.postalInput as Vue).$el.querySelector('input') as HTMLInputElement,
        { types: ['(regions)'] }
      );

      this.autocomplete.setFields(['address_component']);
      this.citiesAutocomplete.setFields(['address_component']);
      this.postalAutocomplete.setFields(['address_component']);

      this.autocomplete.addListener('place_changed', this.fillInAddress);
      this.citiesAutocomplete.addListener('place_changed', this.fillInCity);
      this.postalAutocomplete.addListener('place_changed', this.fillInPostalCode);

      (this.$refs.streetInput as Vue).$el.addEventListener('focus', this.attachAutocompleteResultsToContainer, true);
      (this.$refs.citiesInput as Vue).$el.addEventListener('focus', this.attachAutocompleteResultsToContainer, true);
      (this.$refs.postalInput as Vue).$el.addEventListener('focus', this.attachAutocompleteResultsToContainer, true);
    }

    attachAutocompleteResultsToContainer(): void {
      const pacContainer = document.getElementsByClassName('pac-container');
      Array.from(pacContainer).forEach((x: Element) => {
        (this.$refs['google-autocomplete-container'] as HTMLElement).appendChild(x);
      });
    }

    fillInAddress(): void {
      const place = this.autocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];

        switch (addressType) {
          case 'route':
            this.projectCopy.street = component.long_name;
            break;
          case 'postal_code':
            if (!this.projectCopy.postalCode) this.projectCopy.postalCode = component.short_name;
            break;
          case 'locality':
            if (!this.projectCopy.postalCode) this.projectCopy.city = component.long_name;
            break;
          case 'street_number':
            this.projectCopy.streetNumber = component.short_name;
            break;
          default:
            break;
        }
      }
    }

    fillInCity(): void {
      const place = this.citiesAutocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];
        if (addressType === 'locality') {
          this.projectCopy.city = component.long_name;
        }
      }
    }

    fillInPostalCode(): void {
      const place = this.postalAutocomplete.getPlace();

      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const addressType = component.types[0];
        if (addressType === 'postal_code') {
          this.projectCopy.postalCode = component.short_name;
        }
      }
    }

    fetchSalesInvoicesMetricsReport(): void {
      const reportsService = new ReportsService();
      const parameters = new URLSearchParams();
      parameters.set('filter[projects]', this.project.id.toString());
      reportsService
        .getReport(`/api/v1/reports/sales-invoices-metrics?${parameters.toString()}`)
        .then((result: AxiosResponse<JsonResource<any>>) => {
          this.salesInvoicesMetricsReport = result.data.data;
        });
    }

    created(): void {
      this.fetchProjectById(this.$route.params.id).then(() => {
        this.projectCopy = JSON.parse(JSON.stringify(this.project));
        this.fetchSalesInvoicesMetricsReport();
      });
      this.fetchProjectStatuses();
      this.fetchAllUsers();
    }

    destroyed(): void {
      this.destroyProjectDetails();
    }

    displayCommentsConfirmDialog(comment: Comment): boolean {
      return this.projectDetailsDialogState.deleteComment && this.projectDetailsDialogContent.comment.id === comment.id;
    }

    get contactsCountAll() {
      const projectContactsCount = (this.project && this.project.contacts && this.project.contacts.length) || 0;
      const clientsContactsCount =
        this.project &&
        this.project.clients &&
        this.project.clients.length &&
        this.project.clients.map((c) => (c.contacts && c.contacts.length) || 0).reduce((a, b) => a + b, 0);
      return projectContactsCount + clientsContactsCount;
    }

    @Watch('clientSearchValue')
    onClientSearchValueChange = debounce((val: string) => {
      this.setClientsFilterName(val);
    });

    @Watch('projectDetailsDialogState.editAddress')
    onProjectDetailsDialogStateEditAddressChange(newVal: boolean): void {
      if (!newVal) {
        this.projectCopy = JSON.parse(JSON.stringify(this.project));
      } else {
        this.initAutocomplete();
      }
    }

    @Watch('projectDetailsDialogState.editAddedDate')
    @Watch('projectDetailsDialogState.editInitialOfferDate')
    @Watch('projectDetailsDialogState.editResponsibleGroups')
    onProjectDetailsDialogStateEditChange(newVal: boolean): void {
      if (!newVal) {
        this.projectCopy = JSON.parse(JSON.stringify(this.project));
      }
    }

    @Watch('projectDetailsDialogState.editProjectName')
    onProjectDetailsDialogStateEditProjectNameChange(newVal: boolean): void {
      if (!newVal) {
        this.validationErrors && this.validationErrors.projectName ? (this.validationErrors.projectName = null) : '';
        this.projectCopy = JSON.parse(JSON.stringify(this.project));
      }
    }

    @Watch('project.clients')
    onProjectCopyClientsChange(): void {
      if (this.validationErrors && this.validationErrors.clients) this.validationErrors.clients = null;
    }

    @Watch('project')
    onProjectChange(newVal: Project): void {
      this.projectCopy = JSON.parse(JSON.stringify(newVal));
    }
  }
