import { PurchaseInvoice } from "@/modules/purchase-invoices/types/entities";
import { SalesInvoice } from "@/modules/sales-invoices/types/entities";

export enum TransactionType {
	DEBIT = "debit",
	CREDIT = "credit",
}

export interface Payment {
	id?: number;
    currency?: string | null;
    total?: number | null;
    bankTransaction: BankTransaction | null;
    invoice: PurchaseInvoice | SalesInvoice | null;
    comment?: string | null;
    type: TransactionType;
    createdAt?: string;
	updatedAt?: string;
}

export class PaymentEntity implements Payment {
	id = undefined;
    currency = 'EUR';
    total = 0;
    bankTransaction = null;
    invoice = null;
    comment = '';
    type = TransactionType.DEBIT;

    constructor(data: Partial<Payment> = {}) {
        Object.assign(this, data)
    }
}

export interface BankTransaction {
	id?: number;
    currency?: string | null;
    total?: number;
    executedAt?: string | null;
    createdAt?: string;
	updatedAt?: string;
}

export class BankTransactionEntity implements BankTransaction {
	id = undefined;
    currency = 'EUR';
    total = 0;
    executedAt = null;

    constructor(data: Partial<BankTransaction> = {}) {
        Object.assign(this, data)
    }
}