import { Pagination } from '@/core/types/Entities';
import { AxiosResponse } from 'axios';
import { UseProjectListContext } from '../types/useProjectList';
import useFilter from '../useFilter';
import { ProjectsService } from '@/services/projects.service';
import {Project} from "@/modules/projects/types/entities";

const projectsService = new ProjectsService();

const setProjects = async (ctx: UseProjectListContext, items: Project[]): Promise<void> => new Promise((resolve, reject) => {
  const { composables } = ctx;
  try {
    composables.list.actions.setItems(items);
    resolve();
  } catch (err: any) { reject(err); }
});

const fetchProjects = async (ctx: UseProjectListContext): Promise<AxiosResponse<Pagination<Project[]>>> => {
  const { composables: { loadingActions, filter } } = ctx;
  loadingActions.actions.set(fetchProjects.name, true);
  try {
    const urlSearchParams = filter.getters.filterUrlQuery.value;
    const url = projectsService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`;
    const res = await projectsService.getAllProjects(url);
    setProjects(ctx, res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchProjects.name, false);
  }
};

const search = async (ctx: UseProjectListContext, query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<Project[]>>> => {
  const { composables: { list ,loadingActions, filter } } = ctx;
  loadingActions.actions.set(search.name, true);
  try {
    let urlSearchParams = null;
    if(useAsFilter) {
      if(query) filter.actions.setFilter('query', query);
      else filter.actions.deleteFilter('query');
      urlSearchParams = filter.getters.filterUrlQuery.value;
    } else {
      // fixme: make projects index controller use 'filter.' prefix, so for now: copy config from inected filter
      const f = useFilter({
        composables: {},
        initialState: {
          urlParamPrefix: filter.state.urlParamPrefix,
          urlParamArraySeparator: filter.state.urlParamArraySeparator,
        }});
      if(query) f.actions.setFilter('query', query);
      urlSearchParams = f.getters.filterUrlQuery.value;
    }
    const url = projectsService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`;
    const res = await projectsService.getAllProjects(url);
    list.actions.setItems(res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(search.name, false);
  }
};

export const actions = {
  fetchProjects,
  search,
};
