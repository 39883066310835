import {GetterTree} from 'vuex';
import {RootState} from '@/core/types/RootState';
import {ContactsState} from '@/modules/contacts/store/types/ContactsState';
import {Getters} from '@/modules/contacts/store/types/StoreTypes';

export const getters: GetterTree<ContactsState, RootState> = {
	[Getters.GET_CONTACTS_FILTERED]: (state: ContactsState) => state.contactsFiltered,

	
	[Getters.GET_CONTACT_DETAILS]: (state: ContactsState) => {
		return state.inspectedContact;
	},
	[Getters.GET_LOADING]: (state: ContactsState) => {
		return state.isLoading;
	}
};
