import { AxiosResponse } from 'axios';
import {http} from "@/main";
import {JsonResource} from "@/core/types/Entities";
import {User} from "@/modules/entities/types/entities";
import {Auth} from "@/modules/login/types/entities";

const BASE_ENDPOINT = '/api/v1/auth';

export class AuthenticationService {

  ensureXsrf(): Promise<AxiosResponse> {
    return http.get('/sanctum/csrf-cookie');
  }

  attemptLogin(username: string, password: string): Promise<AxiosResponse> {
    return http.post('/account/login', {
      username,
      password,
    });
  }

  impersonate(userId: number): Promise<AxiosResponse<JsonResource<Auth>>> {
    return http.put(`account/${userId}/impersonate`, {userId: userId});
  }

  user(): Promise<AxiosResponse<JsonResource<Auth>>> {
    return http.get(`${ BASE_ENDPOINT }/me`);
  }

  logout(): Promise<AxiosResponse> {
    return http.post('/account/logout');
  }

}
