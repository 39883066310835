

	import { PropType } from 'vue';
	import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';

	import { Supplier } from '@/modules/entities/types/entities';
	import SupplierBranchItemComponent
		from '@/modules/entities/components/suppliers/supplier-branch-item.component.vue';
	import { Actions } from '@/modules/entities/store/modules/suppliers/types/StoreTypes';
	import ConfirmDialogComponent from '@/core/components/confirm-dialog/confirm-dialog.component.vue';

	const suppliers = namespace('entities/suppliers');

	@Component({
		components: {
			'supplier-branch-item': SupplierBranchItemComponent,
			'confirm-dialog': ConfirmDialogComponent
		}
	})
	export default class DetailsPageSupplierBranchListComponent extends Vue {
		@suppliers.Action(Actions.DELETE_SUPPLIER_BRANCH) deleteSupplierBranch!: (payload: { parentSupplierId: number, branchId: number }) => void;

		@PropSync('showList', { type: Boolean }) showListSync!: boolean;
		@Prop({ type: Array as PropType<Supplier[]>, default: () => [] }) branches!: Supplier[];

		supplierBranchListState: {
			deleteBranch: boolean
		} = {
			deleteBranch: false
		};

		supplierBranchListContent: {
			delete: Supplier
		} = {
			delete: {
				id: 0,
				name: "",
				commercialName: "",
				vatNumber: "",
				ssrNumber: "",
				bankAccounts: [],
				price: 0,
				priceComment: "",
				province: {
					id: 0,
					name: '',
				},
				quality: 0,
				qualityComment: "",
				technicalExpertise: 0,
				aestheticQuality: 0,
				aestheticQualityComment: "",
				activities: "",
				speciality: "",
				comment: "",
				street: "",
				streetNumber: "",
				postalCode: "",
				city: "",
				parentSupplierId: 0,
				outboundKm: 0.0,
				preferredPaymentTerm: 0,
				preferredPaymentTermBoolean: false,
				preferredPaymentDiscountTerm: 0,
				preferredPaymentDiscountTermBoolean: false,
				preferredPaymentDiscountPercentage: 0,
				futureWork: 0,
				futureWorkComment: '',
				workforce: "",
				contacts: [],
				companyTypes: [],
				contractorTypes: [],
				teleinformations: [],
				website: "",
				createdAt: "",
				updatedAt: "",
				retainments: [],
			}
		};

		onRemoveSupplierLocation(supplier: Supplier): void {
			this.supplierBranchListState.deleteBranch = true;
			this.supplierBranchListContent.delete = supplier;
		}

		removeSupplierLocation(): void {
			this.deleteSupplierBranch({
				parentSupplierId: this.supplierBranchListContent.delete.parentSupplierId || 0,
				branchId: this.supplierBranchListContent.delete.id
			});
			this.supplierBranchListState.deleteBranch = false;
		}

		toggleCompanyLocations(): void {
			this.showListSync = this.branches.length ? !this.showListSync : false;
		}

		@Watch('branches')
		onBranchesChange(newVal: Supplier[]): void {
			if (!newVal.length) this.showListSync = false;
		}
	}
