
  import { computed, defineComponent, PropType, reactive, ref, watch, h } from 'vue';
  import { ErrorResponse } from '@/core/types/Entities';
  import {PurchaseInvoice} from "@/modules/purchase-invoices/types/entities";

  export default defineComponent({
    name: 'PurchaseInvoiceDuplicatesError',
    components: {},
    emits: [],
    props: {
      duplicatePurchaseInvoices: {
        type: Array as PropType<Array<PurchaseInvoice>>,
        default: () => [],
      },
    },
    setup(props, { emit }) {
      return {};
    },
  });
