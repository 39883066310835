import {RouteConfig} from 'vue-router';
import SalesInvoicesComponent from './views/sales-invoices/sales-invoices.component.vue';
import SalesInvoiceComponent from './views/sales-invoice/sales-invoice.component.vue';

export const routes: RouteConfig[] = [
	{
        path: '/invoices/sales',
		name: 'salesInvoices',
		component: SalesInvoicesComponent
	},
	{
		path: '/invoices/sales/:id',
		component: SalesInvoiceComponent
	}
];
