var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"planner-sidemenu__work-section__container"},[_vm._t("header",function(){return [_c('div',{staticClass:"planner-sidemenu__row"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.contractor && _vm.isSupplier(_vm.contractor) ? _vm.$t('planner.sideMenu.general') : _vm.$t('planner.sideMenu.workers')))]),(!_vm.calendar.getters.crudState.value[_vm.CalendarCrudState.EDIT_WORK])?[_c('button',{staticClass:"planner-sidemenu__button-edit ml-2",on:{"click":function($event){return _vm.calendar.actions.setCrudState(_vm.CalendarCrudState.EDIT_WORK, true)}}},[_c('img',{attrs:{"src":require("@/assets/edit-button.svg"),"alt":"edit icon"}})])]:[_c('button',{staticClass:"details-page__project__button-edit--save ml-2",on:{"click":function($event){return _vm.saveSequences()}}},[_c('img',{attrs:{"src":require("@/assets/action_success.svg"),"alt":"save icon"}})]),_c('button',{staticClass:"details-page__project__button-edit--cancel ml-1",on:{"click":function($event){return _vm.calendar.actions.cancelSaveSequences()}}},[_c('img',{attrs:{"src":require("@/assets/action_plus.svg"),"alt":"save icon"}})])]],2)]}),(
      _vm.calendar.getters.activeSidebarDialog.value == _vm.CalendarSidebarDialog.PERIOD &&
        _vm.calendar.getters.selectedPeriod.value
    )?_vm._l((_vm.calendar.getters.selectedPeriod.value.subPeriods),function(subPeriod,idx){return _c('sidemenu-sub-period',{key:`contractor-subperiod-${idx}`,attrs:{"sub-period":subPeriod},scopedSlots:_vm._u([{key:"slider",fn:function(){return [(_vm.contractor && _vm.contractor.type === 'division')?[_vm._l((_vm.getWorkDaysForSubPeriod(_vm.calendar.getters.selectedPeriod.value, subPeriod, [
              _vm.ContractorType.TEAM,
            ]).filter((wd) => wd.day === subPeriod.startDate)),function(workDay){return [(workDay.contractorable.type === 'team')?[(workDay)?_c('sidemenu-workday-sequence',{key:`team-subperiod-workday-${workDay.id}`,attrs:{"workday":workDay},on:{"set":(sequences, toggle) => _vm.calendar.actions.setSequences(workDay, sequences, subPeriod, toggle)}}):_vm._e()]:_vm._e()]})]:(_vm.contractor && _vm.contractor.type === 'supplier')?[_vm._l((_vm.getWorkDaysForSubPeriod(_vm.calendar.getters.selectedPeriod.value, subPeriod, [
              _vm.ContractorType.SUPPLIER,
            ]).filter((wd) => wd.day === subPeriod.startDate)),function(workDay){return [_c('sidemenu-workday-sequence',{key:`supplier-subperiod-workday-${workDay.id}`,attrs:{"workday":workDay},on:{"set":(sequences, toggle) => _vm.calendar.actions.setSequences(workDay, sequences, subPeriod, toggle)}})]})]:[_c('span',[_vm._v("Unable to show work planning for this contractor type")])]]},proxy:true}],null,true)})}):(
      _vm.calendar.getters.activeSidebarDialog.value == _vm.CalendarSidebarDialog.DAY &&
        _vm.calendar.getters.selectedPlannerDay.value
    )?[(_vm.contractor && _vm.contractor.type === 'division')?[_vm._l((_vm.calendar.getters.selectedPlannerDay.value.workDays),function(workDay){return [(workDay.contractorable.type === 'team')?[_c('sidemenu-workday-sequence',{key:`team-workday-${workDay.id}`,attrs:{"workday":workDay},on:{"set":(sequences, toggle) => _vm.calendar.actions.setSequences(workDay, sequences, null, toggle)}})]:_vm._e()]})]:(_vm.contractor && _vm.contractor.type === 'supplier')?[_vm._l((_vm.calendar.getters.selectedPlannerDay.value.workDays),function(workDay){return [_c('sidemenu-workday-sequence',{key:`team-workday-${workDay.id}`,attrs:{"workday":workDay},on:{"set":(sequences, toggle) => _vm.calendar.actions.setSequences(workDay, sequences, null, toggle)}})]})]:[_c('span',[_vm._v("Unable to show work planning for this contractor type")])]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }