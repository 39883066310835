
  import { Component, Vue, Provide } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import _, { debounce } from 'lodash';
  import dayjs from 'dayjs';

  import { Actions, Getters } from '@/modules/projects/store/types/StoreTypes';
  import { Project, ProjectStatus, ProjectUser } from '@/modules/projects/types/entities';
  import ProjectsSideMenuComponent from '@/modules/projects/components/projects-sidebar.component.vue';
  import { ProjectStatusMixin } from '@/mixins/project-status-mixin.component';
  import { ProjectStateFilters } from '../../store/types/ProjectState';
  import { GetAddressMixin } from '@/mixins/get-address-mixin.component';
  import { GetContactName } from '@/mixins/get-contact-name.component';
  import { Supplier } from '@/modules/entities/types/entities';
  import { Contact } from '@/modules/contacts/types/entities';
  import { AppMessageType } from '@/core/components/app-message/entities';
  import { appMessageStore } from '@/core/store/app-messages.store';

  const projects = namespace('projects');

  @Component({
    mixins: [ProjectStatusMixin, GetAddressMixin, GetContactName],
    components: {
      'project-modal': () =>
        import(
          /* webpackChunkName: "projectModal" */ '@/modules/projects/components/project-modal/project-modal.component.vue'
        ),
      'projects-side-menu': ProjectsSideMenuComponent,
    },
  })
  export default class ProjectsComponent extends Vue {
    @projects.Action(Actions.FETCH_PROJECTS_BY_FILTERS) fetchProjectsByFilters!: () => void;
    @projects.Action(Actions.FETCH_ALL_USERS) fetchAllUsers!: () => Promise<void>;
    @projects.Action(Actions.FETCH_NEXT_PAGE) fetchNextPage!: () => void;
    @projects.Action(Actions.DELETE_PROJECT_BY_ID) deleteProjectById!: (payload: number) => void;
    @projects.Action(Actions.ARCHIVE_PROJECT_BY_ID) archiveProjectById!: (payload: number) => Promise<void>;
    @projects.Action(Actions.UN_ARCHIVE_PROJECT_BY_ID) unArchiveProjectById!: (payload: number) => Promise<void>;
    @projects.Action(Actions.FETCH_PROJECT_STATUSES) fetchProjectStatuses!: () => Promise<void>;
    @projects.Action(Actions.SET_PROJECTS_FILTER_MAIN_QUERY) setMainQueryFilter!: (payload: string) => void;
    @projects.Action(Actions.SET_PROJECTS_FILTER_FETCH_ONGOING) setFetchOngoingFilter!: (payload: boolean) => void;

    @projects.Getter(Getters.GET_PROJECTS) projects!: Project[];
    @projects.Getter(Getters.GET_PROJECT_STATUSES) projectStatuses!: ProjectStatus[];
    @projects.Getter(Getters.GET_LOADING) loading!: boolean;
    @projects.Getter(Getters.GET_PROJECTS_FILTERS) projectsFilters!: ProjectStateFilters;
    @projects.Getter(Getters.GET_USERS) users!: ProjectUser[];

    filteredProjectStatuses: ProjectStatus[] = [];

    mainQueryValue: string = '';

    projectsState = {
      showProjectModal: false,
      filters_toggled: false,
      delete: false,
      archive: false,
      unArchive: false,
    };

    projectsContent: {
      project: Project;
    } = {
      project: {} as Project,
    };

    headers = [
      {
        text: 'projects.dataTable.headers.pnum',
      },
      {
        text: 'projects.dataTable.headers.projectName',
      },
      {
        text: 'projects.dataTable.headers.executed',
      },
      {
        text: 'projects.dataTable.headers.totalAmountOrdered',
      },
      {
        text: 'projects.dataTable.headers.address',
      },
      {
        text: 'projects.dataTable.headers.contacts',
      },
      {
        text: 'projects.dataTable.headers.projectLeader',
      },
      {
        text: 'projects.dataTable.headers.estimator',
      },
      {
        text: 'projects.dataTable.headers.drawer',
      },
      {
        text: 'projects.dataTable.headers.designer',
      },
      {
        text: '',
      },
    ];

    onDeleteProject(project: Project): void {
      this.resetProjectsContent();
      this.projectsState.delete = true;
      this.projectsContent.project = project;
    }

    onEditProject(project: Project): void {
      this.resetProjectsContent();
      this.projectsState.showProjectModal = true;
      this.projectsContent.project = JSON.parse(JSON.stringify(project));
    }

    onArchiveProject(project: Project): void {
      this.resetProjectsContent();
      this.projectsState.archive = true;
      this.projectsContent.project = project;
    }

    archiveProject(): void {
      if (this.projectsContent.project.id && this.projectsContent.project.id > 0) {
        this.archiveProjectById(this.projectsContent.project.id).catch(() => {
          appMessageStore.actions.set({
            message: 'projects.globalMessage.archiveError',
            ttl: 7500,
            dismissed: false,
            type: AppMessageType.ERROR,
          });
        });
      }
    }

    onUnArchiveProject(project: Project): void {
      this.resetProjectsContent();
      this.projectsState.unArchive = true;
      this.projectsContent.project = project;
    }

    unArchiveProject(): void {
      if (this.projectsContent.project.id && this.projectsContent.project.id > 0) {
        this.unArchiveProjectById(this.projectsContent.project.id).catch(() => {
          appMessageStore.actions.set({
            message: 'projects.globalMessage.unArchiveError',
            ttl: 7500,
            dismissed: false,
            type: AppMessageType.ERROR,
          });
        });
      }
    }

    onAddProject(): void {
      this.resetProjectsContent();
      this.projectsState.showProjectModal = true;
    }

    resetProjectsContent(): void {
      this.projectsContent = {
        project: {
          id: 0,
          uniqueId: '',
          projectName: '',
          presentedPrice: 0,
          initialOffer: 0,
          initialOfferComment: '',
          dateInitialOffer: null,
          dateFinancialDetails: null,
          thirdParties: [],
          clients: [
            {
              id: 0,
              name: '',
              street: '',
              streetNumber: '',
              postalCode: '',
              city: '',
              bankAccounts: [
                {
                  id: 0,
                  accountNumber: '',
                  isMain: true,
                },
                {
                  id: 0,
                  accountNumber: '',
                  isMain: false,
                },
              ],
              nationalInsuranceNumber: '',
              vatNumber: '',
              teleinformations: [],
              comments: [],
              preferredPaymentTerm: 0,
              preferredPaymentTermBoolean: false,
              preferredPaymentDiscountTerm: 0,
              preferredPaymentDiscountTermBoolean: false,
              preferredPaymentDiscountPercentage: 0,
              contacts: [],
              commercialName: '',
              website: '',
              isCompany: false,
              createdAt: '',
              updatedAt: '',
            },
          ],
          projectStatus: {
            id: 1,
            name: 'PROSPECT',
          },
          contractorParties: [],
          projectLeaders: [],
          estimators: [],
          designers: [],
          drawers: [],
          addedDate: dayjs().format('YYYY-MM-DD'),
          street: null,
          streetNumber: null,
          postalCode: null,
          city: null,
          distanceToSfinxHq: null,
          socialSecurityCompleted: false,
          socialSecurityReference: '',
          socialSecurityStartDate: null,
          socialSecurityEndDate: null,
          quotes: [],
          orders: [],
          archivedAt: null,
          latestProgressSnapshot: null,
          contractorBranches: [],
        },
      };
    }

    onMainSearchValueChange = debounce((value: string) => {
      this.setMainQueryFilter(value);
    }, 200);

    onClearFilters(): void {
      this.mainQueryValue = '';
    }

    created(): void {
      this.fetchAllUsers();
      this.fetchProjectsByFilters();
      this.fetchProjectStatuses().then(() => (this.filteredProjectStatuses = [...this.projectStatuses]));
      this.mainQueryValue = '';
      this.onMainSearchValueChange('');
    }

    loadNextPage(): void {
      this.fetchNextPage();
    }
  }
