
  import { defineComponent } from 'vue';
  import { CalendarSidebarDialog } from '../types/CalendarSidebarDialog';
  import helpers from '@/modules/calendar/services/helper.service';
  import store from '../store';
  import {appMessageStore} from "@/core/store/app-messages.store";
  import ErrorWorkdayHasLockedTimesheetEntries
    from "@/modules/calendar/components/errors/error-workdays-have-locked-timesheet-entries.component.vue";
  import {PlannerPeriod} from "@/modules/calendar/interfaces/project/plannerPeriod";

  export default defineComponent({
    setup() {

      const deleteDay = () => {
        store.calendar.actions
          .deleteDay()
          .catch((err) => {
            appMessageStore.actions.warn({
              message: err.response.data.message,
              ttl: 60000,
              component: {
                cmp: ErrorWorkdayHasLockedTimesheetEntries,
                props: {
                  errorResponse: err.response.data,
                },
              },
            });
          })
      }

      const deletePeriod = () => {
        store.calendar.actions
          .deletePlannerPeriod()
          .catch((err) => {
            appMessageStore.actions.warn({
              message: err.response.data.message,
              ttl: 60000,
              component: {
                cmp: ErrorWorkdayHasLockedTimesheetEntries,
                props: {
                  errorResponse: err.response.data,
                },
              },
            });
          })
      }

      const movePeriod = (plannerPeriod: PlannerPeriod, countDays: number) => {
        store.calendar.actions
          .movePeriod(plannerPeriod.id, countDays)
          .catch((err) => {
            appMessageStore.actions.warn({
              message: err.response.data.message,
              ttl: 60000,
              component: {
                cmp: ErrorWorkdayHasLockedTimesheetEntries,
                props: {
                  errorResponse: err.response.data,
                },
              },
            });
          })
      }

      return {
        calendar: store.calendar,
        helpers: helpers,
        CalendarSidebarDialog,
        deleteDay,
        deletePeriod,
        movePeriod,
      };
    },
  });
