import axios, { AxiosPromise } from 'axios';

import { JsonResource } from '@/core/types/Entities';
import { SalesInvoice } from '../types/entities';

const BASE_ENDPOINT = '/api/v1/invoices/sales-invoices';

export class SalesInvoicesCommandsService {

	getNewInvoice(): AxiosPromise<JsonResource<SalesInvoice>> {
		return axios.get<JsonResource<SalesInvoice>>(`/api/v1/commands/invoices/new-sales-invoice`);
	}

}
