

	import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { Actions, Getters } from '../../store/modules/suppliers/types/StoreTypes';
	import { namespace } from 'vuex-class';
	import { Supplier } from '@/modules/entities/types/entities';
	import { debounce } from 'lodash';
	import { GetAddressMixin } from '@/mixins/get-address-mixin.component';

	const suppliers = namespace('entities/suppliers');

	@Component({
		mixins: [GetAddressMixin]
	})
	export default class SelectSupplierListComponent extends Vue {
		@PropSync('showModal', { type: Boolean, default: false }) showModalSync!: boolean;
		@Prop({ type: Number }) supplierTypeId?: number;

		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_SUPPLIER_TYPES) setSupplierTypeFilter!: (supplierTypeId: number) => void;
		@suppliers.Action(Actions.DESTROY_FETCHED_SUPPLIERS) destroyFetchedSuppliers!: () => void;
		@suppliers.Action(Actions.FETCH_NEXT_PAGE) fetchNextPage!: (payload: string) => void;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_CLEAR_NO_FETCH) setClearFiltersWithoutFetching!: () => void;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_MAIN_QUERY) appendSearchQuery!: (payload: string) => void;

		@suppliers.Getter(Getters.GET_FETCHED_SUPPLIERS) suppliers!: Supplier[];

		selectedSupplierId = -1;
		searchValue = '';

		@Emit('onSelectSupplier')
		onEmitSelectSupplier() {
			return this.selectedSupplierId;
		}

		@Emit('onCreateNewSupplier')
		onEmitCreateNewSupplier() {
		}

		@Watch('searchValue')
		onChangeFilter = debounce((value: string) => {
			this.appendSearchQuery(value);
		}, 200);

		loadNextPage() {
			this.fetchNextPage('suppliers');
		}

		created(): void {
			if (this.supplierTypeId) {
				this.setSupplierTypeFilter(this.supplierTypeId);
			} else {
				this.appendSearchQuery('');
			}
		}

		destroyed(): void {
			this.setClearFiltersWithoutFetching();
			this.destroyFetchedSuppliers();
		}
	}
