export const namespace = 'entities/clients';

export const enum Actions {
    APPEND_FETCHED_CLIENTS = 'APPEND_FETCHED_CLIENTS',
    DELETE_CLIENT_BY_ID = 'DELETE_CLIENT_BY_ID',
    SAVE_COMMENT = 'SAVE_COMMENT',
    FILTER_CLIENT_CONTACTS = 'FILTER_CLIENT_CONTACTS',
    DESTROY_CLIENT_DETAILS = 'DESTROY_CLIENT_DETAILS',
    CREATE_NEW_CLIENT = 'CREATE_NEW_CLIENT',
    GET_CLIENT_HIGHEST_PROJECT_NAME = 'GET_CLIENT_HIGHEST_PROJECT_NAME',
    EDIT_CLIENT_BY_ID = 'EDIT_CLIENT_BY_ID',
    FETCH_CLIENT_BY_ID = 'FETCH_CLIENT_BY_ID',
    FETCH_CLIENTS_BY_FILTER = 'FETCH_CLIENTS_BY_FILTER',
    FETCH_NEXT_PAGE = 'FETCH_NEXT_PAGE',
    SET_CLIENTS_FILTER_NAME_QUERY = 'SET_CLIENTS_FILTER_NAME_QUERY',
    SET_CLIENTS_FILTER_CLEAR_NO_FETCH = 'SET_CLIENTS_FILTER_CLEAR_NO_FETCH',
    TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_CLIENT = 'TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_CLIENT',
    CLEAR_FETCHED_CLIENTS = 'CLEAR_FETCHED_CLIENTS'
}

export const enum Getters {
    GET_CLIENT_DETAILS = 'GET_CLIENT_DETAILS',
    GET_FETCHED_CLIENTS = 'GET_FETCHED_CLIENTS',
    GET_LOADING = 'GET_LOADING'
}

export const enum Mutations {
    MUTATE_INSPECTED_CLIENT = 'MUTATE_INSPECTED_CLIENT',
    MUTATE_PAGINATION_DETAILS = 'MUTATE_PAGINATION_DETAILS',
    MUTATE_FETCHED_CLIENTS = 'MUTATE_FETCHED_CLIENTS',
    MUTATE_PUSH_NEXT_PAGE = 'MUTATE_PUSH_NEXT_PAGE',
    MUTATE_FILTERS_NAME_QUERY = 'MUTATE_FILTERS_NAME_QUERY',
    MUTATE_LOADING = 'MUTATE_LOADING'
}
