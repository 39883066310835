import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { state } from './state';
import { RootState } from '@/core/types/RootState';
import { WorkflowState } from '@/modules/settings/store/modules/workflow/types/WorkflowState';

export const store: Module<WorkflowState, RootState> = {
	namespaced: true,
	actions,
	getters,
	state,
	mutations
};
