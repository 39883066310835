
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Actions } from '@/modules/projects/store/types/StoreTypes';
import { DataTableHeader } from 'vuetify';
import { Project } from '../../types/entities';
import { Client, ProjectClient } from '@/modules/entities/types/entities';
import { AxiosResponse } from 'axios';
import { JsonResource } from '@/core/types/Entities';

const projects = namespace('projects');

@Component
export default class ProjectClientsListComponent extends Vue {

    // vuex actions
    @projects.Action(Actions.EDIT_PROJECT_CLIENT) saveProjectClient!: (payload: ProjectClient) => Promise<AxiosResponse<JsonResource<ProjectClient>>>;

    // vuex getters

    // props
    @Prop({type: Array as PropType<Client[]>, default: () => [], required: true}) clients!: Client[];
    @Prop({type: Object as PropType<Project>, required: true}) project!: Project;

    // local/children state
    state: {
    } = {
    }

    // handlers
    onClickCertificateStatus(projectClient: ProjectClient, newStatus: string) {
        if(projectClient) {
            console.log(projectClient)
            this.saveProjectClient({...projectClient, vat6CertificateStatus: newStatus })
        }
    }

    // methods

    // getters
    get clientsHeaders() {
        return [
            {
                text: this.$t('projects.details.clients.name').toString(),
                value: 'name',
                align: 'start column-name--width',
                sortable: true,
                groupable: false,
                class:'text-no-wrap'
            },
            {
                text: this.$t('projects.details.clients.vat6CertificateStatus').toString(),
                value: 'projectClient.vat6CertificateStatus',
                align: 'center column-vat6-certificate-status--width',
                sortable: false,
                groupable: false,
                class:'text-no-wrap'
            },
            {
                text: '',
                value: 'actions',
                align: 'center'
            },
        ];
    }

    // setters

    // watchers

    // lifecycle
}
