import {ActionContext, ActionTree} from 'vuex';
import {RootState} from '@/core/types/RootState';
import {ContactsState} from '@/modules/contacts/store/types/ContactsState';
import {Actions} from '@/modules/contacts/store/types/StoreTypes';
import {AxiosError, AxiosResponse} from 'axios';
import {JsonResource, Pagination} from '@/core/types/Entities';
import Vue from 'vue';
import {Mutations} from '@/modules/contacts/store/types/StoreTypes';
import { Contact } from '../types/entities';
import { ContactsService } from '@/services/contacts.service';
import { TeleinformationDetail } from '@/modules/entities/types/entities';

const contactsService = new ContactsService();

export const actions: ActionTree<ContactsState, RootState> = {
  [Actions.FETCH_CONTACTS_PAGINATED]: async ({}: ActionContext<ContactsState, RootState>) => {

  },
  [Actions.FETCH_CONTACTS_PAGINATED_NEXT]: async ({}: ActionContext<ContactsState, RootState>) => {

  },
  [Actions.FETCH_CONTACTS_PAGINATED_PREVIOUS]: async ({}: ActionContext<ContactsState, RootState>) => {

  },
  [Actions.FETCH_CONTACTS_BY_QUERY]: async ({commit, state}: ActionContext<ContactsState, RootState>, payload?: string) => {
    const url = `/api/v1/contacts${payload ? '?searchquery=' + payload : ""}`;
    const res: AxiosResponse<Pagination<Contact[]>> = await Vue.prototype.$http.get(url);

    if (res.status === 200) {
      commit(Mutations.MUTATE_CONTACTS_FILTERED, res.data.data);
      return res;
    }
  },
  [Actions.FETCH_CONTACTS_BY_NAME]: async ({ commit }: ActionContext<ContactsState, RootState>, payload: string): Promise<AxiosResponse<Pagination<Contact[]>>> => {
    commit(Mutations.MUTATE_LOADING, true);
    const res = await Vue.prototype.$http.get(`/api/v1/contacts?name=${ payload }`);

    if (res.status === 200) {
      commit(Mutations.MUTATE_LOADING, false);
      return res;
    }
    return Promise.reject();
  },
  [Actions.FETCH_CONTACTS_BY_FILTER]: async ({commit, state}: ActionContext<ContactsState, RootState>, payload: string) => {

  },
  [Actions.CHECK_FOR_EXISTING_CONTACT]: async ({ commit }: ActionContext<ContactsState, RootState>, payload: Contact) => {
    commit(Mutations.MUTATE_LOADING, true);
    return await Vue.prototype.$http
      .get(`/api/v1/commands/check-existing-contacts?name=${ payload.firstName }`)
      .finally(() => commit(Mutations.MUTATE_LOADING, false));
  },
  [Actions.CREATE_NEW_CONTACT]: async ({ commit, dispatch }: ActionContext<ContactsState, RootState>, payload: Contact) => {
    commit(Mutations.MUTATE_LOADING, true);
    return await Vue.prototype.$http.post(`/api/v1/contacts`, payload)
      .catch((err: AxiosError) => {
        throw err;
      })
      .finally(() => commit(Mutations.MUTATE_LOADING, false));
  },
  [Actions.DELETE_CONTACT_BY_ID]: async ({ commit }: ActionContext<ContactsState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_LOADING, true);

    try {
      const result = await contactsService.deleteContactById(Number.parseInt(payload));

      return result;
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      commit(Mutations.MUTATE_LOADING, false);
    }
  },
  [Actions.DELETE_TELEINFORMATION_BY_ID]: async ({ commit, state }: ActionContext<ContactsState, RootState>, payload: {contactId: string; teleinformationId: string }) => {
    commit(Mutations.MUTATE_LOADING, true);

    try {
      const result = await contactsService.deleteTeleinformationById(Number.parseInt(payload.contactId),Number.parseInt(payload.teleinformationId));
      const items = state.inspectedContact && state.inspectedContact.teleinformations ? [...state.inspectedContact.teleinformations] : [] as TeleinformationDetail[];

      if(state.inspectedContact && state.inspectedContact.teleinformations) {
        const index = items.findIndex(teleinformation => teleinformation.id === Number.parseInt(payload.teleinformationId));
        items.splice(index, 1);
      }

      const contact = { ...state.inspectedContact, teleinformations: items } as Contact;
      commit(Mutations.MUTATE_INSPECTED_CONTACT, contact);

      return result;
    } catch (err: any) {
      //
    } finally {
      commit(Mutations.MUTATE_LOADING, false);
    }
  },
  [Actions.FETCH_CONTACT_BY_ID]: async ({ commit }: ActionContext<ContactsState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_LOADING, true);

    try {
      const result = await contactsService.getContactById(Number.parseInt(payload));
      commit(Mutations.MUTATE_INSPECTED_CONTACT, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_LOADING, false);
    }
  },

  [Actions.SAVE_CONTACT]: async ({ commit, dispatch, state }: ActionContext<ContactsState, RootState>, payload: Contact) => {
    commit(Mutations.MUTATE_LOADING, true);

    // has ids?
    const contactId = (payload.id !== undefined && payload.id) || undefined;

    try {
      let result: AxiosResponse<JsonResource<Contact>> | null = null;

      // create
      if(!contactId) {
        result = await contactsService.createContact(payload);
        commit(Mutations.MUTATE_INSPECTED_CONTACT, result.data.data);
      }
      // update
      else {
        result = await contactsService.updateContactById(payload);
        commit(Mutations.MUTATE_INSPECTED_CONTACT, result.data.data);
      }

      return result;
    } finally {
      commit(Mutations.MUTATE_LOADING, false);
    }
  },

};
