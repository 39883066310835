export const namespace = 'settings/deleted-log';

export const enum Actions {
	FETCH_DELETED_LOG = 'FETCH_DELETED_LOG',
	REVERT_DELETION = 'REVERT_DELETION'
}

export const enum Getters {
	GET_DELETED_LOG = 'GET_DELETED_LOG',
}

export const enum Mutations {
	MUTATE_DELETED_LOG = 'MUTATE_DELETED_LOG'
}
