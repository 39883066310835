
	import {Component, Prop, PropSync, Vue} from "vue-property-decorator";

	@Component
	export default class DetailsTextFieldComponent extends Vue {
		@Prop() iconPath!: string;
		@Prop() translationPath!: string;
		@Prop() disabled!: boolean;
		@PropSync('textValue', {type: [String, Number]}) syncedTextValue!: string | number;
		@Prop() textFieldType?: string;
	}
