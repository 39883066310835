
	import { PropType } from 'vue';
    import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
    import Fuse from 'fuse.js'
    import { User } from '@/modules/entities/types/entities';
    import { groupBy } from 'lodash';
    import { sortNatural } from '@/core/helpers/Sort';
    import { DataTableHeader } from 'vuetify';
    import { Tax } from '@/modules/settings/types/entities';

	@Component
	export default class TaxMultiAutocompleteComponent extends Vue {

        @Prop({type: Array as PropType<Tax[]>, default: null})
        value!: Tax[];

		@Prop({type: Array, default: () => [] as Tax[] })
        items!: Tax[];
		
        // vars
        v_proxyValue: Tax[]|null = this.value;
        v_originalValue: Tax[]|null = this.value;
        v_searchValue: string|null = null
        itemsFuseOptions: object = { keys: ['text'], threshold: 0.4, includeScore: true, shouldSort: true, includeMatches:true, distance: 50, maxPatternLength: 32, minMatchCharLength: 2 };
		
        // handlers
        onClickClose(item: Tax) {
            if(this.proxyValue) {
                this.proxyValue = [ ...this.proxyValue.filter(u => u.id !== item.id) ]
                this.$emit('input', this.proxyValue);
            }
        }
        onChangeSelected() {
			this.searchValue = '';
        }

        // methods
        getLabel(item: Tax) {
            return item.label
        }
        compare(a: any, b: any) {
			if(a && b && a.id !== undefined && b.id !== undefined) return a.id === b.id
			return false
        }
        highlight(resultItem: any) {
            if(!resultItem.matches) { 
                console.warn('To use possible matches you should enable the "includeMatches" option in Fuse.'); 
                return null;
            }
            resultItem.matches.forEach((matchItem: any) => {
                const text = resultItem.item[matchItem.key];
                const result = []
                const matches = [].concat(matchItem.indices); // limpar referencia
                let pair = matches.shift()
                
                for (let i = 0; i < text.length; i++) {
                const char = text.charAt(i)
                if (pair && i == pair[0]) {
                    result.push('<mark>')
                }
                result.push(char)
                    if (pair && i == pair[1]) {
                        result.push('</mark>')
                        pair = matches.shift()
                    }
                }
                resultItem.highlight = result.join('');

                if(resultItem.children && resultItem.children.length > 0){
                    resultItem.children.forEach((child: any) => {
                        this.highlight(child);
                    });
                }
            });

            return resultItem.highlight;
        };
        createAutocompleteItem(item: Tax, options?: { disabled?: boolean}) {
            // extra autocomplete options?
            const defaults = { disabled: false }
            const opts = Object.assign({}, defaults, options?options:{})

            return {
                disabled: opts.disabled,
                text: this.getLabel(item),
                value: item,
            }
        }
        filterWithFuse() {
            let results = [] as any[];
			if (this.searchValue && this.itemsFuseFilter) {
                results = this.itemsFuseFilter
                    .search(this.searchValue)
                    .map((result) => {
                        return { ...result.item, highlight: this.highlight(result) }
                    })
            }
            return results;
        }
        groupBy(key:string, items:any[]) {
            return Object.entries(groupBy(items, key)).map((value) => { 
                return {
                    groupBy: value[0],
                    items: value[1]
                };
            });
        }
        
        // getters
        get searchValue(): string|null { return this.v_searchValue }
        get proxyValue(): Tax[]|null { return this.v_proxyValue }
        get originalValue(): Tax[]|null { return this.v_originalValue }
        get autocompleteItems(): any[]
        {
            // resulting autocomplete items
            let autocompleteItems = [] as { text: string, value: Tax, disabled: boolean }[];

            // if initial value is not available in items, add it manually
            // const inItems = this.items.length && this.originalValue ? this.items.find(item => this.originalValue && item.id === this.originalValue.id) : false;
            // if(!inItems && this.originalValue && this.originalValue.length) this.originalValue.forEach((originalItem: Tax) => autocompleteItems.push(this.createAutocompleteItem(originalItem, { disabled: true })))

            // add all other values
            this.items.filter(u => !this.selectedValues.some(u2 => this.compare(u,u2)))
                .forEach(u => autocompleteItems.push(this.createAutocompleteItem(u)))
                
            autocompleteItems.sort((a,b) => sortNatural(a.text,b.text))

            return autocompleteItems;
        }
        get itemsFuseFilter() {
			return this.autocompleteItems && this.autocompleteItems.length > 0 ? new Fuse(this.autocompleteItems, this.itemsFuseOptions) : null
        }
        get selectedValues() {
            return this.proxyValue ? this.proxyValue : []
        }
        get filteredItems() 
        {
            // filter with fuse
            let fuseResults = this.filterWithFuse()

            // resulting autocomplete items
            let autocompleteItems = [] as any[];

            // map fuse result if there are matches
            if(fuseResults.length) {
                fuseResults.sort((a: any, b: any) => a.score < b.score ? -1 : (a.score > b.score) ? 1 : 0)
                autocompleteItems = [ ...fuseResults ]
            } else {
                autocompleteItems = [ ...this.autocompleteItems ]
            }

            autocompleteItems = [ ...autocompleteItems, ...this.selectedValues.map(u => this.createAutocompleteItem(u)) ]
			
			return autocompleteItems;
        }

        // setters
        set searchValue(value: string|null) { this.v_searchValue = value && value.trim() ? value : null }
        set proxyValue(value: Tax[]|null) { this.v_proxyValue = value }
        set originalValue(value: Tax[]|null) { this.v_originalValue = value }

        // watchers
        @Watch('value', {immediate: true})
        watchOnValueChange(newValue: Tax[]) {
            // set the internal value from the outside world
            this.proxyValue = newValue;
        }

        @Watch('searchValue')
        watchOnSearch(newValue: string|null) {
            this.$emit('search',newValue)
        }
	}
