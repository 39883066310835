import axios, { AxiosResponse } from 'axios';
import { JsonResource } from '@/core/types/Entities';
import { PlannerPeriod } from '@/modules/calendar/interfaces/project/plannerPeriod';
import { Quote } from '@/modules/calendar/interfaces/project/quote';
import { Supplier, Comment } from '@/modules/entities/types/entities';
import { Team } from '@/modules/settings/types/entities';

const BASE_ENDPOINT = '/api/v1/quotes';

export default class QuotesService {

	getBaseEndpoint(): string {
		return BASE_ENDPOINT
	}

	createNewQuote(projectId: number, contractorTypeId: number | number[], withOffer: boolean, contractor?: Supplier | Team): Promise<AxiosResponse<JsonResource<Quote>>> {
		const data: any = {
			projectId,
			contractor,
			withOffer
		}

		if (Array.isArray(contractorTypeId)) {
			data.contractorTypeIds = contractorTypeId
		} else {
			data.contractorTypeId = contractorTypeId
		}

		return axios.post<JsonResource<Quote>>(`${ BASE_ENDPOINT }`, data);
	}

	fetchQuoteDetails(quoteId: number): Promise<AxiosResponse<JsonResource<Quote>>>{
		return axios.get<JsonResource<Quote>>(`${ BASE_ENDPOINT }/${ quoteId }`);
	}

	editQuoteById(quote: Quote): Promise<AxiosResponse<JsonResource<Quote>>> {
		return axios.put<JsonResource<Quote>>(`${ BASE_ENDPOINT }/${ quote.id }`, quote);
	}

	deleteQuote(quote: Quote): Promise<AxiosResponse<void>> {
		return axios.delete(`${ BASE_ENDPOINT }/${ quote.id }`);
	}

	createNewPlannerPeriod(quoteId: number, plannerPeriod: { plannerStartDate?: string; plannerEndDate?: string }): Promise<AxiosResponse<JsonResource<PlannerPeriod>>> {
		return axios.post<JsonResource<PlannerPeriod>>(`${ BASE_ENDPOINT }/${ quoteId }/periods`, plannerPeriod);
	}

	editPlannerPeriodById(quoteId: number, plannerperiod: PlannerPeriod): Promise<AxiosResponse<JsonResource<PlannerPeriod>>> {
		return axios.put<JsonResource<PlannerPeriod>>(`${ BASE_ENDPOINT }/${quoteId}/periods/${ plannerperiod.id }`, plannerperiod);
	}

	deletePlannerPeriod(plannerPeriodId: number): Promise<AxiosResponse<void>> {
		return axios.delete(`${ BASE_ENDPOINT }/periods/${ plannerPeriodId }`);
	}

	createNewCommentForPlannerPeriod(plannerPeriodId: number, comment: Comment): Promise<AxiosResponse<JsonResource<Comment>>> {
		return axios.post<JsonResource<Comment>>(`${ BASE_ENDPOINT }/periods/${ plannerPeriodId }/comments`, comment);
	}
}