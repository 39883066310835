import {ActionContext, ActionTree} from "vuex";
import {MonthlyOverviewState} from "@/modules/monthly-overview/store/types/MonthlyOverviewState";
import {RootState} from "@/core/types/RootState";
import {Actions, Mutations} from "@/modules/monthly-overview/store/types/storeTypes";
import Vue from "vue";
import { Employee } from '@/modules/settings/types/entities';

export const actions: ActionTree<MonthlyOverviewState, RootState> = {
	[Actions.SET_SELECTED_TEAM_MEMBER]: async ({state, commit, dispatch}: ActionContext<MonthlyOverviewState, RootState>, payload: Employee) => {
		commit(Mutations.MUTATE_SELECTED_TEAM_MEMBER, payload);
		await dispatch(Actions.FETCH_MONTHLY_OVERVIEW_DATA, payload.id);
	},
	[Actions.FETCH_MONTHLY_OVERVIEW_DATA]: async ({state, commit}: ActionContext<MonthlyOverviewState, RootState>, teamMemberId) => {
		const res = await Vue.prototype.$http.get(`/api/v1/monthly-overview?member=${teamMemberId}`);
		commit(Mutations.MUTATE_MONTHLY_OVERVIEW_DATA, res.data);
	}
}
