import { JsonResource } from '@/core/types/Entities';
import axios, { AxiosResponse } from 'axios';
import { AccountingLedger } from '../types/entities';

const ACCOUNTING_COMMANDS_ENDPOINT = '/api/v1/commands/accounting';

export class AccountingCommandsService {

	activateAccountinLedgerById(accountingLedgerId: number): Promise<AxiosResponse<JsonResource<AccountingLedger>>> {
		return axios.put(`${ ACCOUNTING_COMMANDS_ENDPOINT }/activate-accounting-ledger/${ accountingLedgerId }`);
	}
	
}
