import { reactive, computed, ComputedRef } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import useFilter from '@/composables/useFilter';
import { UseEmployeeListState, UseEmployeeListComposables, UseEmployeeListContext } from './types/useEmployeeList';
import { merge } from 'lodash';
import { Options } from './types/composable';
import { actions as employeeListActions } from './actions/useEmployeeList';
import { useDynamicProps } from './useDynamicProps';
import { Employee } from '@/modules/settings/types/entities';
import useList from './useList';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseEmployeeListState>): UseEmployeeListState => {
  return merge(
    {
      expandedIds: [],
    },
    state
  );
};

export default function useEmployeeList(options?: Options<UseEmployeeListState, UseEmployeeListComposables>) {
  const state: UseEmployeeListState = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    list: useList<Employee>(),
    filter: useFilter(),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseEmployeeListContext = { state, composables };
  const actions = wrapActions(ctx, employeeListActions);

  // return state and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // searching
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      // content
      employees: computed(() => ctx.composables.list.getters.items.value),
      expandedIds: computed(() => state.expandedIds),
    },
    actions: {
      ...ctx.composables.filter.actions,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...ctx.composables.list.actions,
      ...actions,
    },
  };
}