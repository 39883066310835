
	import {Component, Prop, PropSync, Vue} from "vue-property-decorator";

	@Component
	export default class DetailsDatePickerComponent extends Vue {
		@Prop() iconPath!: string;
		@Prop() translationPath!: string;
		@Prop() disabled!: boolean;
		@PropSync('dateValue', {type : [Date, String] }) syncedDateValue!: Date;
	}
