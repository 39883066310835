import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import { Contact } from '@/modules/contacts/types/entities';

const BASE_ENDPOINT = '/api/v1/contacts';

export class ContactsService {

	createContact(contact: Contact): Promise<AxiosResponse<JsonResource<Contact>>> {
		return axios.post<JsonResource<Contact>>(BASE_ENDPOINT, contact);
	}

	getAllContacts(url = `${ BASE_ENDPOINT }`): Promise<AxiosResponse<Pagination<Contact[]>>> {
		return axios.get<Pagination<Contact[]>>(`${ url }`);
	}

	getContactById(contactId: number): Promise<AxiosResponse<JsonResource<Contact>>> {
		return axios.get<JsonResource<Contact>>(`${ BASE_ENDPOINT }/${ contactId }`);
	}

	updateContactById(contact: Contact): Promise<AxiosResponse<JsonResource<Contact>>> {
		return axios.put<JsonResource<Contact>>(`${ BASE_ENDPOINT }/${ contact.id }`, contact);
	}

	deleteContactById(contactId: number): AxiosPromise<void> {
		return axios.delete(`${ BASE_ENDPOINT }/${ contactId }`);
	}

	deleteTeleinformationById(contactId: number, teleinformationId: number): AxiosPromise<void> {
		return axios.delete(`${ BASE_ENDPOINT }/${ contactId }/teleinformations/${ teleinformationId }`);
	}

}
