import axios, { AxiosResponse } from 'axios';
import { PlannerPeriod } from "@/modules/calendar/interfaces/project/plannerPeriod"
import { JsonResource } from '@/core/types/Entities';
import { CalendarProject } from '@/modules/calendar/interfaces/project/CalendarProject';

const BASE_ENDPOINT_PLANNER = '/api/v1/commands/planner';
const BASE_ENDPOINT = BASE_ENDPOINT_PLANNER;
export default class CalendarCommandsService {

	getBaseEndpoint(): string {
		return BASE_ENDPOINT
	}

	async mergePeriods(plannerPeriodsIds: number[]): Promise<AxiosResponse<void>> {
		return axios.put(`${BASE_ENDPOINT}/merge-periods`, { plannerPeriodsIds });
	}

	async splitPeriod(plannerPeriodId: number, day: string): Promise<AxiosResponse<JsonResource<PlannerPeriod[]>>> {
		return axios.put(`${BASE_ENDPOINT}/split-period`, {
			plannerPeriodId,
			day
		})
	}

	async movePeriod(id: number, count: number): Promise<AxiosResponse<void>> {
		return axios.post(`${BASE_ENDPOINT}/move-period`, {
			id,
			count
		});
	}

	async getAdjacentPeriods(quoteId: number, date: string): Promise<AxiosResponse<JsonResource<PlannerPeriod[]>>> {
		const res = await axios.get(`${BASE_ENDPOINT}/get-adjacent-periods`, {
			params: {
				start: date,
				end: null,
				quoteId: quoteId
			}
		});

		return res
	}

	async addPlannerPeriod(projectId: number, quoteId: number, date: string): Promise<AxiosResponse<JsonResource<number>>> {
		const res = await axios.post(`/api/v1/planner/projects/${projectId}/quotes/${quoteId}/periods`, { date });
		return res;
	}

	async setProjectPeriod(projectId: number, timeRangeId: number): Promise<AxiosResponse<JsonResource<any>>> {
		const res = await axios.put(`${BASE_ENDPOINT}/set-project-period`, {
			projectId: projectId,
			timeRangeId: timeRangeId
		});
		return res;
	}

	async setPlannerPeriod(environmentId: number, timeRangeId: number): Promise<AxiosResponse<JsonResource<any>>> {
		const res = await axios.put(`${BASE_ENDPOINT}/set-planner-period`, {
			plannerEnvironmentId: environmentId,
			timeRangeId: timeRangeId
		});
		return res;
	}

	async moveProjectByDate(projectId: number, offsetDate: string, newDate: string): Promise<AxiosResponse<JsonResource<CalendarProject>>> {
		const res = await axios.post(`${BASE_ENDPOINT}/move-project-by-date`, {
			projectId: projectId,
			offsetDate: offsetDate,
			newDate: newDate,
		});
		return res;
	}

}
