import {ContactsState} from './types/ContactsState';

export const state: ContactsState = {
	contactsFiltered: [],

	filters: [],
	searchQuery: undefined,
	inspectedContact: undefined,
	searchTypes: [],
	isLoading: false
};
