
  import { defineComponent } from 'vue';
  import helpers from '../services/helper.service';
  import store from '../store';

  export default defineComponent({
    setup(props) {
      const expandAll = (expand: boolean) => {
        store.calendar.actions.setAllProjectsExpanded(expand)
        store.calendar.actions.setAllDivisionsExpanded(expand)
        store.calendar.actions.setAllSuppliersExpanded(expand)
      }
      return {
        calendarSettings: store.calendarSettings,
        calendar: store.calendar,
        ...helpers,
        expandAll,
      };
    },
  });
