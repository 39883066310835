import useAppMessageList from '@/composables/useAppMessageList';

// shared state composables
export const appMessageStore = useAppMessageList()

/**
 * Global state/store
 *   Because we can re-use composables as different states, we can also use 1 composable as a global state.
 *   This global state can be stored in a `store.ts` file.
 */
// export const store = {
// 	appMessages
// };
