
	import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';

	import { Project } from '@/modules/projects/types/entities';
	import { WorkflowTask } from '@/modules/workflow/types/entities';
	import { Actions } from '@/modules/workflow/store/types/StoreTypes';

	const workflow = namespace('workflow');

	@Component
	export default class HeaderListComponent extends Vue {
		@workflow.Action(Actions.SET_HIGHLIGHTED_PROJECT_ID) setHighlightedProjectId!: (payload: number) => Promise<void>;

		@Prop() projects!: Project[];
		@Prop() tasks!: WorkflowTask[];
		@Prop() projectStatusId!: any;
		@Prop() projectStatusName!: string;
		@Prop() isProjectExpanded!: Function;
		@Prop({ type: Number }) glowingProjectId!: number;


		disableGlowingClass(projectId: number): void {
			if (projectId === this.glowingProjectId) {
				this.setHighlightedProjectId(-1);
			}
		}

		@Emit('onProjectHeaderToggle')
		onProjectHeaderToggle(projectId: number) {
			return projectId;
		}

		get filteredProjects() {
			if (this.projectStatusId !== 0) {
				return this.projects.filter(x => x.projectStatus && x.projectStatus.id === this.projectStatusId);
			}
			return this.projects.filter(x => !x.projectStatus);
		}
	};
