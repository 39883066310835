import { render, staticRenderFns } from "./sidemenu-details-period.component.vue?vue&type=template&id=1f810882&scoped=true"
import script from "./sidemenu-details-period.component.vue?vue&type=script&lang=ts"
export * from "./sidemenu-details-period.component.vue?vue&type=script&lang=ts"
import style0 from "./sidemenu-details-period.component.vue?vue&type=style&index=0&id=1f810882&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f810882",
  null
  
)

export default component.exports