import { Component, Vue } from 'vue-property-decorator';
import { Client, Supplier } from '@/modules/entities/types/entities';
import { Project } from '@/modules/projects/types/entities';
import { Contact } from '@/modules/contacts/types/entities';

@Component
export class GetAddressMixin extends Vue {
	getAddress(param: (Contact | Supplier | Client | Project)): string {
		let address = "";
		if (param.street) address += param.street;
		if (param.streetNumber) address += ` ${ param.streetNumber }`;
		if (param.postalCode) {
			if (address !== '') address += ', ';
			address += param.postalCode;
		}
		if (param.city) {
			if ( address !== '') address += ', ';
			address += param.city;
		}
		if (!address) address += '---';
		return address;
	}
}
