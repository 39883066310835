export const enum Permissions {
	login = 'APP__LOGIN',
	impersonate = 'APP__IMPERSONATE',
	accessHome = 'ACCESS_PAGE__HOME',
	accessProjects = 'ACCESS_PAGE__PROJECTS',
	accessSuppliers = 'ACCESS_PAGE__SUPPLIERS',
	accessConstructionSiteManagement = 'ACCESS_CONSTRUCTION_SITE_MANAGEMENT_PAGE',
	accessWorkflow = 'ACCESS_PAGE__WORKFLOW',
	accessContacts = 'ACCESS_PAGE__CONTACTS',
	accessPlanner = 'ACCESS_PAGE__PLANNER',
	accessSettings = 'ACCESS_PAGE__SETTINGS',
	accessMonthlyOverview = 'ACCESS_PAGE__MONTHLY_OVERVIEW',
	accessPermissions = 'ACCESS_PAGE__PERMISSIONS',
	accessEmployees = 'ACCESS_PAGE__EMPLOYEES',
	accessSocialSecurityRegistrations = 'ACCESS_PAGE__SOCIAL_SECURITY_REGISTRATIONS',
	accessInvoices = 'ACCESS_PAGE__INVOICES',
	accessSalesInvoices = 'ACCESS_PAGE__SALES_INVOICES',
	accessPurchaseInvoices = 'ACCESS_PAGE__PURCHASE_INVOICES',
	accessReports = 'ACCESS_PAGE__REPORTS',
	accessReportsGrossMargin = 'ACCESS_PAGE__REPORTS_GROSS_MARGIN',
	editPreferredPaymentTerm = 'EDIT_PREFERRED_PAYMENT_TERM',
	editPreferredPaymentDiscountTerm = 'EDIT_PREFERRED_PAYMENT_DISCOUNT_TERM',
	editPreferredPaymentDiscountPercentage = 'EDIT_PREFERRED_PAYMENT_DISCOUNT_PERCENTAGE',
}
