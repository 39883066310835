import { Supplier } from '@/modules/entities/types/entities';
import { Division } from '@/modules/settings/types/entities';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
import { Project } from '@/modules/projects/types/entities';

export interface SocialSecurityRegistration {
	id: number;
	status: string;
	comment: string | null;
	contractorStartDate: string | null;
	contractorEndDate: string | null;
	project: SocialSecurityRegistrationProject;
	contractor: Supplier | Division;
	contractorBranches: ContractorBranch[];
}

export interface SocialSecurityRegistrationProject extends Project {
	projectStartDate: string | null;
	projectEndDate: string | null;
}

export interface ProjectCompliance extends SocialSecurityRegistrationProject {
	isDue: boolean;
	daysUntilDue: number;
	isCompliant: boolean;
	ssrCompliances: SocialSecurityRegistrationCompliance[];
	ssrIncompliances: SocialSecurityRegistrationCompliance[];
	incomplianceStatuses: IncomplianceStatus[];
}

export interface SocialSecurityRegistrationCompliance extends SocialSecurityRegistration {
    daysUntilDue: number;
    showDaysUntilDue: boolean;
    complianceStatus: IncomplianceStatus | null;
    isCompliant: boolean;
}

/**
 * To calculate the "Order of Importance/Necessity" for social security registrations an Enum 
 * is used with named keys and numeric values:
 *   - the keys are possible states in the SSR-data such as dates that are out of range, ...
 *   - the numeric values are used to sort the items according to their importance. A higher key has 
 *     a higher importance than lower values.
 */
export enum IncomplianceStatus {
	INCOMPLETE_PROJECT_SSR_DATA = 1100,
	INCOMPLETE_PLANNER_DATA = 1050,
	// ranges
	CONTRACTOR_PERIOD_BEFORE_SOCIAL_SECURITY_START_DATE = 1040,
	CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_DATE = 1035,
	CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_START_AND_END_DATE = 1030,
	CONTRACTOR_PERIOD_BETWEEN_SOCIAL_SECURITY_START_AND_END_DATE = 1025,
	CONTRACTOR_PERIOD_OVERLAPS_SOCIAL_SECURITY_END_DATE = 1020,
	CONTRACTOR_PERIOD_AFTER_SOCIAL_SECURITY_END_DATE = 1015,
}

export enum SocialSecurityRegistrationStatus {
	REGISTERED = 'registered',
	NOT_REGISTERED = 'not_registered',
}
