import Vue from 'vue';
import { AccountingState } from './types/AccountingState';
import { RootState } from '@/core/types/RootState';
import { ActionTree, ActionContext } from 'vuex';
import { Actions, Mutations } from './types/StoreTypes';
import applyFilter from '@/core/helpers/Filter';
import { AxiosResponse, AxiosError, AxiosPromise } from 'axios';
import { Filter, FilterState } from '@/core/types/Filter';
import { AccountingLedger, AccountingLedgerItem } from '../types/entities';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { AccountingService, BASE_ENDPOINT } from '@/services/accounting.service';
import { AccountingCommandsService } from '../services/accounting-commands.service';
import { appMessageStore } from '@/core/store/app-messages.store';
import { AppMessageType } from '@/core/components/app-message/entities';

const namespace = 'accounting';

const accountingService = new AccountingService();
const accountingCommandsService = new AccountingCommandsService();

export const actions: ActionTree<AccountingState, RootState> = {

  [Actions.FETCH_ACTIVE_ACCOUNTING_LEDGER]: async({state, commit, dispatch}: ActionContext<AccountingState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    const activeFilter = new Filter<boolean>('active', 'active', [true],true,'active');
    const fs = { filters: { active: activeFilter }} as FilterState;
    const filterUrl = applyFilter(fs);
    const url = BASE_ENDPOINT + (filterUrl ? '?'+filterUrl : '');
    await accountingService.getAllAccountingLedgers(url)
      .then((response: AxiosResponse<Pagination<AccountingLedger[]>>) => {
        const accountingLedgers = response.data.data;
        if(accountingLedgers.length === 1) {
          commit(Mutations.MUTATE_ACTIVE_ACCOUNTING_LEDGER, accountingLedgers[0]);
        } else {
          appMessageStore.actions.set({
            message: 'accounting.accountingLedger.globalMessage.fetchActiveAccountingLedgerFailed',
            ttl: 15000,
            dismissed: false,
            type: AppMessageType.WARNING,
          });
        }
      }).catch((err) => {
        throw err;
      }).finally(() => {
        commit(Mutations.MUTATE_IS_LOADING, false);
      });
  },

  [Actions.SET_IS_LOADING]: ({state, commit}: ActionContext<AccountingState, RootState>, payload: boolean) => {
    commit(Mutations.MUTATE_IS_LOADING, payload);
  },
  [Actions.FETCH_ACCOUNTING_LEDGERS]: async ({state, commit}: ActionContext<AccountingState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const url = BASE_ENDPOINT;
      const result = await accountingService.getAllAccountingLedgers(url);
      commit(Mutations.MUTATE_ACCOUNTING_LEDGERS, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_ACCOUNTING_LEDGER]: async ({ commit, state }: ActionContext<AccountingState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    commit(Mutations.MUTATE_ACCOUNTING_LEDGER, null);

    try {
      const result = await accountingService.getAccountingLedgerById(Number.parseInt(payload));
      commit(Mutations.MUTATE_ACCOUNTING_LEDGER, result.data.data);
      return result;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_ACCOUNTING_LEDGER_ITEM]: async ({ commit, state }: ActionContext<AccountingState, RootState>, payload: {accountingLedgerId: string; accountingLedgerItemId: string}) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    commit(Mutations.MUTATE_ACCOUNTING_LEDGER_ITEM, null);

    try {
      const result = await accountingService.getAccountingLedgerItemById(Number.parseInt(payload.accountingLedgerItemId));
      commit(Mutations.MUTATE_ACCOUNTING_LEDGER_ITEM, result.data.data);
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.SAVE_ACCOUNTING_LEDGER_BY_ID]: async ({ commit, dispatch, state }: ActionContext<AccountingState, RootState>, payload: AccountingLedger) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has ids?
    const id = (payload.id !== undefined && payload.id) || undefined;

    try {
      let result: AxiosResponse<JsonResource<AccountingLedger>> | null = null;
      const items: AccountingLedger[] = state.accountingLedgers && state.accountingLedgers.length ? [...state.accountingLedgers] : [];

      // create
      if(!id) {
        result = await accountingService.createAccountingLedger(payload);
        commit(Mutations.MUTATE_ACCOUNTING_LEDGER, result.data.data);
      }
      // update
      else {
        result = await accountingService.updateAccountingLedgerById(payload);
        commit(Mutations.MUTATE_ACCOUNTING_LEDGER, result.data.data);
      }

      // update listview items instead of refetching them
      const idx = items.findIndex(i => i.id === id);
      items.splice(idx === -1 ? 0 : idx, idx === -1 ? 0 : 1, {...result.data.data});
      commit(Mutations.MUTATE_ACCOUNTING_LEDGERS, items);

      return result;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.CREATE_ACCOUNTING_LEDGER]: ({commit, state, dispatch}: ActionContext<AccountingState, RootState>, payload: AccountingLedger) => {
    commit(Mutations.MUTATE_ACCOUNTING_LEDGER, payload);
  },
  [Actions.CREATE_ACCOUNTING_LEDGER_ITEM]: ({commit, state, dispatch}: ActionContext<AccountingState, RootState>, payload: AccountingLedger) => {
    commit(Mutations.MUTATE_ACCOUNTING_LEDGER_ITEM, payload);
  },
  [Actions.SAVE_ACCOUNTING_LEDGER_ITEM_BY_ID]: async ({ commit, dispatch, state }: ActionContext<AccountingState, RootState>, payload: AccountingLedgerItem) => {
    // loading
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has ids?
    const accountingLedgerItemId = (payload.id !== undefined && payload.id) || undefined;
    let result: AxiosResponse<JsonResource<AccountingLedgerItem>>;

    try {

      // create
      if(!accountingLedgerItemId) {
        result = await accountingService.createAccountingLedgerItem(payload);
        commit(Mutations.MUTATE_ACCOUNTING_LEDGER_ITEM, result.data.data);
        if(state.accountingLedger && state.accountingLedger.accountingLedgerItems) {
          const items = [result.data.data, ...state.accountingLedger.accountingLedgerItems];
          commit(Mutations.MUTATE_ACCOUNTING_LEDGER, { ...state.accountingLedger, accountingLedgerItems: items } as AccountingLedger);
        }
      }
      // update
      else {
        result = await accountingService.updateAccountingLedgerItem(payload);
        commit(Mutations.MUTATE_ACCOUNTING_LEDGER_ITEM, result.data.data);
        if(state.accountingLedger && state.accountingLedger.accountingLedgerItems) {
          const items = state.accountingLedger.accountingLedgerItems.map((item: AccountingLedgerItem) => item.id !== result.data.data.id ? item : {...item, ...result.data.data});
          commit(Mutations.MUTATE_ACCOUNTING_LEDGER, { ...state.accountingLedger, accountingLedgerItems: items } as AccountingLedger);
        }
      }

      return result;

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.ACTIVATE_ACCOUNTING_LEDGER]: async ({ commit, dispatch, state }: ActionContext<AccountingState, RootState>, payload: AccountingLedger) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      if(payload.id !== undefined && payload.id) {
        let result = await accountingCommandsService.activateAccountinLedgerById(payload.id);

        // fetch accountingledgers
        result = await dispatch(Actions.FETCH_ACCOUNTING_LEDGERS);
        return result;
      }
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
};