import { Filter, filterType } from '@/core/types/Filter';
import { Supplier, User } from '@/modules/entities/types/entities';
import { Project, ProjectStatus } from '@/modules/projects/types/entities';
import { ReportState } from '@/modules/reports/store/types/ReportState';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';

export const state: ReportState = {

  isLoadingGrossMarginReport: false,
  isLoadingContractorBranchesReport: false,
  users: [] as User[],
  projectStatuses: [] as ProjectStatus[],
  searchProjects: [] as Project[],
  searchSuppliers: [] as Supplier[],
  contractorBranches: [] as ContractorBranch[],


  // gross margin
  grossMarginReport: null,
  projectsContractorBranchesReport: null,
  filteredProjectsContractorBranchesReport: null,
  showProjectsGrossMarginReport: true,
  showProjectsContractorBranchesPurchaseReport: true,
  projectsContractorBranchesPurchaseReportMetric: 'cost',

  grossMarginFilterState: {
    sideBarOpen: false,
    selectedProject: null,
    selectedSupplier: null,
    selectedContractorBranch: null,
    showMoreProjectStatuses: false,
    showMoreProjectLeaders: false,
    showMoreProjectEstimators: false,
    filters: {
      projects: new Filter('projects','projects',[],true,'projects'),
      suppliers: new Filter('suppliers','suppliers',[],true,'suppliers',undefined,undefined,filterType.FRONTEND),
      projectLeaders: new Filter('projectLeaders','project_leaders',[],true,'projectLeaders'),
      projectEstimators: new Filter('projectEstimators','project_estimators',[],true,'projectEstimators'),
      contractorBranches: new Filter('contractorBranches','contractorBranches',[],false,'contractorBranches'),
      // Total Amount Ordered
      minAmountOrdered: new Filter('minAmountOrdered','minAmountOrdered',[],true,'minAmountOrdered'),
      maxAmountOrdered: new Filter('maxAmountOrdered','maxAmountOrdered',[],true,'maxAmountOrdered'),
      // Total Amount Ordered Contractor Branch
      minAmountOrderedContractorBranch: new Filter('minAmountOrderedContractorBranch','minAmountOrderedContractorBranch',[],true,'minAmountOrderedContractorBranch',undefined,undefined,filterType.FRONTEND),
      maxAmountOrderedContractorBranch: new Filter('maxAmountOrderedContractorBranch','maxAmountOrderedContractorBranch',[],true,'maxAmountOrderedContractorBranch',undefined,undefined,filterType.FRONTEND),
      //Startdate planning
      firstPlannerDateFrom: new Filter('firstPlannerDateFrom', 'first_planner_date_from', [], true, 'firstPlannerDateFrom'),
      firstPlannerDateTo: new Filter('firstPlannerDateTo', 'first_planner_date_to', [], true, 'firstPlannerDateTo'),
      //Enddate planning
      lastPlannerDateFrom: new Filter('lastPlannerDateFrom', 'last_planner_date_from', [], true, 'lastPlannerDateFrom'),
      lastPlannerDateTo: new Filter('lastPlannerDateTo', 'last_planner_date_to', [], true, 'lastPlannerDateTo'),
      //Date first sales invoice
      firstSalesInvoiceDateFrom: new Filter('firstSalesInvoiceDateFrom', 'first_sales_invoice_date_from', [], true, 'firstSalesInvoiceDateFrom'),
      firstSalesInvoiceDateTo: new Filter('firstSalesInvoiceDateTo', 'first_sales_invoice_date_to', [], true, 'firstSalesInvoiceDateTo'),
      //Date last sales invoice
      lastSalesInvoiceDateFrom: new Filter('lastSalesInvoiceDateFrom', 'last_sales_invoice_date_from', [], true, 'lastSalesInvoiceDateFrom'),
      lastSalesInvoiceDateTo: new Filter('lastSalesInvoiceDateTo', 'last_sales_invoice_date_to', [], true, 'lastSalesInvoiceDateTo'),
      //Date first purchase invoice
      firstPurchaseInvoiceDateFrom: new Filter('firstPurchaseInvoiceDateFrom', 'first_purchase_invoice_date_from', [], true, 'firstPurchaseInvoiceDateFrom'),
      firstPurchaseInvoiceDateTo: new Filter('firstPurchaseInvoiceDateTo', 'first_purchase_invoice_date_to', [], true, 'firstPurchaseInvoiceDateTo'),
      //Date last purchase invoice
      lastPurchaseInvoiceDateFrom: new Filter('lastPurchaseInvoiceDateFrom', 'last_purchase_invoice_date_from', [], true, 'lastPurchaseInvoiceDateFrom'),
      lastPurchaseInvoiceDateTo: new Filter('lastPurchaseInvoiceDateTo', 'last_purchase_invoice_date_to', [], true, 'lastPurchaseInvoiceDateTo'),

      projectStatuses: new Filter('projectStatuses','project_statuses',[5],true,'projectStatuses'),

      showOnlyCompletedContractorBranches: new Filter('showOnlyCompletedContractorBranches','only_completed_contractor_branches',[true],true,'showOnlyCompletedContractorBranches'),
      showOnlyRelevantContractorBranches: new Filter('showOnlyRelevantContractorBranches','only_relevant_contractor_branches',[true],true,'showOnlyRelevantContractorBranches',undefined,undefined,filterType.FRONTEND),
      showOnlyCompletedProjects: new Filter('showOnlyCompletedProjects','only_completed_projects',[],true,'showOnlyCompletedProjects'),

    },
  },

};