import { render, staticRenderFns } from "./division-team-members-list.component.vue?vue&type=template&id=c65ccd5a&scoped=true"
import script from "./division-team-members-list.component.vue?vue&type=script&lang=ts"
export * from "./division-team-members-list.component.vue?vue&type=script&lang=ts"
import style0 from "./division-team-members-list.component.vue?vue&type=style&index=0&id=c65ccd5a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c65ccd5a",
  null
  
)

export default component.exports