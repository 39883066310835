
    import { PropType } from 'vue';
    import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';

	@Component
	export default class MediaUploadComponent extends Vue {

        // props
        @PropSync('validationErrors', { type: Object, default: () => { return {} } }) validationErrorsSync!: any;
        @Prop({ type: Number, default: 1 }) maxFiles!: number;
        @Prop({ type: Number, default: 1024*1024*2 }) maxSize!: number; // in bytes

        // refs
        @Ref('fileInput') fileInput!: Vue

        // vars/state
        content: {
            files: File[],
        } = {
            files: [],
        }

        state: {
            isSaving: boolean,
            isDragging: boolean,
            isValid: boolean,
        } = {
            isSaving: false,
            isDragging: false,
            isValid: true,
        }

        // methods
        isValid(): boolean {
            this.state = {...this.state, isValid: true }
            let validationErrors = {}

            // amount: max 1 file
            if(this.content.files.length <= 0 || this.content.files.length > this.maxFiles) {
                this.state = {...this.state, isValid: false }
                validationErrors = {...validationErrors,
                    fileAmountExceeded: `Only ${ this.maxFiles } file(s) allowed.`
                }
            }

            // size: max 2MB
            if(this.totalSize <= 0 || this.totalSize > this.maxSize) {
                this.state = {...this.state, isValid: false }
                validationErrors = {...validationErrors,
                    fileSizeExceeded: `Max ${ this.humanFileSize(this.maxSize) } allowed.`
                }
            }

            this.validationErrorsSync = {...this.validationErrorsSync, ...validationErrors}
            return this.state.isValid
        }
        humanFileSize(bytes: number, si=false, dp=1) {
            const thresh = si ? 1000 : 1024;
            if (Math.abs(bytes) < thresh) {
                return bytes + ' B';
            }
            const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
            let u = -1;
            const r = 10**dp;
            do {
                bytes /= thresh;
                ++u;
            } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

            return bytes.toFixed(dp) + ' ' + units[u];
        }

        // handlers
        onSelectFiles(files: any) {
            const filesList: File[] = Array.isArray(files) ? files : [files]
            this.content = {...this.content,
              files: filesList.map(f => f)
            }
            this.onFilesChanged();
        }
        onAddDropFiles(e: DragEvent) {
            if(!e.dataTransfer) return;
            this.onDragLeave()
            this.content = {...this.content,
                files: Array.from(e.dataTransfer.files).map(f => f)
            }
            this.onFilesChanged();
        }
        onFilesChanged() {
            this.isValid()
            this.$emit('change', {isValid: this.state.isValid, items: this.content.files})
        }
        onClickDropArea() {
            const fileInput = this.fileInput.$el as HTMLInputElement
            const input = fileInput.getElementsByTagName('input')[0]
            input.click()
        }
        onDragEnter() {
            this.state = {...this.state, isDragging: true}
        }
        onDragLeave() {
            this.state = {...this.state, isDragging: false}
        }

        // getters
        get totalSize(): number {
            return this.content.files.map((value: File) => value.size).reduce((prev: number, curr: number) => prev+curr, 0)
        }
        get totalSizeHumanReadable() {
            return this.humanFileSize(this.totalSize, true, 1)
        }


        // setters

        // watchers
	}
