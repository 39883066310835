import { Comment, Media, Supplier, User } from '@/modules/entities/types/entities';
import { AccountingLedgerItem } from '@/modules/accounting/types/entities';
import { Project, ProjectUser } from '@/modules/projects/types/entities';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
import { Payment } from '@/modules/payments/types/entities';
import { Tax } from '@/modules/settings/types/entities';

export enum MediaAnnotatatable {
	PURCHASE_INVOICE = 'PURCHASE_INVOICE',
	PROJECT_RELATIONSHIP = 'PROJECT_RELATIONSHIP',
}

export enum TransactionType {
	DEBIT = "debit",
	CREDIT = "credit",
}

export interface PurchaseInvoice {
	id?: number;
	name?: string;
	externalName?: string;
	invoiceDate?: string;
	dueDate?: string | null;
	paymentDiscountDueDate?: string | null;
	status?: PurchaseInvoiceStatus | null;
	paymentStatus?: PurchaseInvoicePaymentStatus | null;
	approvalStatus?: ApprovalStatus | null;
	accountingStatus?: AccountingStatus | null;
	recordType?: PurchaseInvoiceRecordType | null;
	supplier?: Supplier | null;
	total?: number | undefined;
	subTotal?: number | undefined;
	grandTotal?: number | undefined;
	taxTotal?: number | undefined;
	paymentTotal?: number | undefined;
	paymentDiscount?: number | undefined;
	currency?: string | null;
	reference?: string | null;
	comment?: string | null;
	description?: string | null;
	accountingItems?: AccountingItem[] | null;
	projectRelationships?: ProjectRelationship[] | null;
	comments?: Comment[] | null;
	payments?: Payment[] | null;
	pdf?: Media | null;
	recalculateAccountingItems?: boolean;
	createdAt?: string | null;
	updatedAt?: string | null;
	deletedAt?: string | null;
}

export class PurchaseInvoiceEntity implements PurchaseInvoice {
	id = undefined;
	name = '';
	externalName = '';
	invoiceDate = '';
	dueDate = '';
	paymentDiscountDueDate = '';
	status = null;
	paymentStatus = null;
	approvalStatus = null;
	accountingStatus = null;
	recordType = null;
	supplier = null;
	total = 0;
	subTotal = 0;
	grandTotal = 0;
	taxTotal = 0;
	paymentTotal = 0;
	paymentDiscount = 0;
	currency = 'EUR';
	reference = '';
	comment = '';
	description = '';
	accountingItems = [];
	projectRelationships = [];
	comments = [];
	payments = [];
	pdf = null;
	recalculateAccountingItems = true;
	createdAt = '';
	updatedAt = '';
	deletedAt = '';

	constructor(data: Partial<PurchaseInvoice> = {}) {
	  Object.assign(this, data);
	}
}

export interface AccountingItem {
	id?: number;
	name?: string;
	description?: string | null;
	purchaseInvoice?: PurchaseInvoice | null;
	total?: number | undefined;
	subTotal?: number | undefined;
	grandTotal?: number | undefined;
	taxTotal?: number | undefined;
	paymentTotal?: number | undefined;
	currency?: string | null;
	vat?: Tax | null;
	accountingLedgerItem?: AccountingLedgerItem | null;
	type: TransactionType;
}

export class AccountingItemEntity implements AccountingItem {
	id = undefined;
	name = '';
	description = '';
	purchaseInvoice = null;
	total = 0;
	subTotal = 0;
	grandTotal = 0;
	taxTotal = 0;
	paymentTotal = 0;
	currency = 'EUR';
	accountingLedgerItem = null;
	vat = null;
	type = TransactionType.DEBIT;

	constructor(data: Partial<AccountingItem> = {}) {
	  Object.assign(this, data);
	}
}

export interface PurchaseInvoiceStatus {
	id: number;
	name: string;
	label: string;
	color?: string;
	sequence: number;
}

export interface PurchaseInvoicePaymentStatus {
	id: number;
	name: string;
	label: string;
	color?: string;
	sequence: number;
}

export interface PurchaseInvoiceRecordType {
	id: number;
	label: string;
	name: string;
}

export interface ProjectRelationshipStatus {
	id?: number;
	name?: string;
	label?: string;
	color?: string;
	sequence?: number;
}

export class ProjectRelationshipStatusEntity implements ProjectRelationshipStatus {
	id =undefined;
	name ='';
	label ='';
	color? ='';
	sequence =undefined;

	constructor(data: Partial<ProjectRelationshipStatus> = {}) {
	  Object.assign(this, data);
	}
}

export interface ApprovalStatus {
	id?: number;
	name?: string;
	label?: string;
	color?: string;
	sequence?: number;
}

export class ApprovalStatusEntity implements ApprovalStatus {
	id =undefined;
	name ='';
	label ='';
	color? ='';
	sequence =undefined;

	constructor(data: Partial<ApprovalStatus> = {}) {
	  Object.assign(this, data);
	}
}

export interface AccountingStatus {
	id?: number;
	name?: string;
	label?: string;
	color?: string;
	sequence?: number;
}

export class AccountingStatusEntity implements AccountingStatus {
	id =undefined;
	name ='';
	label ='';
	color? ='';
	sequence =undefined;

	constructor(data: Partial<ApprovalStatus> = {}) {
	  Object.assign(this, data);
	}
}

export interface ProjectRelationship {
	id?: number;
	name?: string;
	total?: number;
	currency?: string | null;
	description?: string | null;
	project?: Project | null;
	purchaseInvoice?: PurchaseInvoice | null;
	contractorType?: ContractorBranch | null;
	accountingLedgerItem?: AccountingLedgerItem | null;
	assignedTo?: User | null;
	requestedBy?: User | null;
	status?: ProjectRelationshipStatus | null;
	approvalStatus?: ApprovalStatus | null;
	isProjectRequired?: boolean;
	type: TransactionType;
	createdAt?: string | null;
	updatedAt?: string | null;
	deletedAt?: string | null;

	purchaseInvoiceId?: number;
	projectId?: number;
}

export class ProjectRelationshipEntity implements ProjectRelationship {
	id = undefined;
	name = '';
	total = undefined;
	currency = 'EUR';
	description = '';
	project = null;
	purchaseInvoice = null;
	contractorType = null;
	accountingLedgerItem = null;
	assignedTo = null;
	requestedBy = null;
	status = null;
	approvalStatus = null;
	isProjectRequired = false;
	type = TransactionType.DEBIT;
	createdAt = '';
	updatedAt = '';
	deletedAt = '';

	purchaseInvoiceId = undefined;

	constructor(data: Partial<ProjectRelationship> = {}) {
	  Object.assign(this, data);
	}
}

export interface ObjectForAutocomplete<T> {
	value: T | null;
	label: string;
}

