
  import { PropType } from 'vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { DataTableHeader } from 'vuetify';
  import { Project } from '../../types/entities';
  import { ProjectContractorBranch, ProjectContractorBranchEntity } from '../../types/entities';
  import { namespace } from 'vuex-class'
  import { Actions, Getters } from '@/modules/projects/store/types/StoreTypes';
  import ProjectContractorBranchEditPopupComponent from '@/modules/projects/components/contractor-branch-edit-popup.component.vue'
  import { Actions as ContractorBranchActions, Getters as ContractorBranchGetters } from '@/modules/settings/store/modules/contractor-branches/types/StoreTypes';
  import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
  import { cloneDeep } from 'lodash'
  import authStore from '@/modules/login/store';
  import { Permissions } from '@/core/types/Permissions';

  const projects = namespace('projects')
  const contractorBranches = namespace('settings/contractor-branches');

  @Component({
  components: {
    'contractor-branch-create-popup': ProjectContractorBranchEditPopupComponent,
    'contractor-branch-edit-popup': ProjectContractorBranchEditPopupComponent,
  },
})

  export default class ProjectContractBranchListComponent extends Vue {

    // vuex actions
    @projects.Action(Actions.SAVE_CONTRACTOR_BRANCH) saveContractorBranch!: (payload: { projectId: string; contractorBranch: ProjectContractorBranch }) => Promise<void>
    @projects.Action(Actions.DELETE_CONTRACTOR_BRANCH) deleteContractorBranch!: (payload: { projectId: string; contractorBranchId: string }) => Promise<void>
    @contractorBranches.Action(ContractorBranchActions.FETCH_ALL_CONTRACTOR_BRANCHES) fetchAllContractorBranches!: () => Promise<void>;


    // vuex getters
    @projects.Getter(Getters.GET_LOADING) loading!: boolean
		@contractorBranches.Getter(ContractorBranchGetters.GET_ALL_BRANCHES) allContractorBranches!: ContractorBranch[];


      // props
      @Prop({type: Array as PropType<ProjectContractorBranch[]>, default: () => [], required: true}) projectContractorBranches!: ProjectContractorBranch[];
      @Prop({type: Object as PropType<Project>, required: true}) project!: Project;

      // local/children state
      state: {
          itemsPerPage: number,
          showMenuAddContractorBranch: boolean
          showMenuEditContractorBranch: boolean
          showMenuDeleteContractorBranch: boolean
      } = {
          itemsPerPage: 15,
          showMenuAddContractorBranch: false,
          showMenuEditContractorBranch: false,
          showMenuDeleteContractorBranch: false,
      }
      content: {
        contractorBranchCreate: ProjectContractorBranch
        contractorBranchUpdate: ProjectContractorBranch | null
        contractorBranchDelete: ProjectContractorBranch | null
      } = {
          contractorBranchCreate: this.newContractorBranch(),
          contractorBranchUpdate: null,
          contractorBranchDelete: null,
        }

      // handlers
      onClickAddContractorBranch() {
        this.content = { ...this.content, contractorBranchCreate: { ...this.newContractorBranch()} }
      }
      onClickSaveContractorBranchCreate(contractorBranch: ProjectContractorBranch) {
        const projectId = !!this.project.id && this.project.id
        this.saveContractorBranch({ projectId: projectId.toString(), contractorBranch: contractorBranch }).then((value) => {
          this.state = { ...this.state, showMenuAddContractorBranch: false }
          this.content = { ...this.content, contractorBranchCreate: { ...this.newContractorBranch() } }
        })
      }
      onClickEditContractorBranch(contractorBranch: ProjectContractorBranch) {
        this.state.showMenuEditContractorBranch = true
        this.content.contractorBranchUpdate = cloneDeep(contractorBranch)
      }
      onClickSaveContractorBranchUpdate(contractorBranch: ProjectContractorBranch) {
        const projectId = !!this.project.id && this.project.id
        this.saveContractorBranch({ projectId: projectId.toString(), contractorBranch: contractorBranch }).then((value) => {
          this.state = { ...this.state, showMenuEditContractorBranch: false }
          this.content = { ...this.content, contractorBranchUpdate: { ...this.newContractorBranch() } }
        })
      }
      onClickDeleteContractorBranch(contractorBranch: ProjectContractorBranch) {
        this.state.showMenuDeleteContractorBranch = true
        this.content.contractorBranchDelete = cloneDeep(contractorBranch)
      }
      confirmDeleteContractorBranch(contractorBranch: ProjectContractorBranch) {
        const projectId = !!this.project.id && this.project.id
        const contractorBranchId = !!contractorBranch.id && contractorBranch.id
        this.deleteContractorBranch({ projectId: projectId.toString(), contractorBranchId: contractorBranchId.toString() }).then(() => {
          this.state = { ...this.state, showMenuDeleteContractorBranch: false }
        })
      }

      // methods
      newContractorBranch() {
        return new ProjectContractorBranchEntity({
          projectId: this.project.id
        })
      }

      collectOrderedContractorBranches() {
        let orders = this.project.orders;
        let abbreviations = new Set<ContractorBranch>();
        orders.forEach(order => {
          if (order.contractorType) {
            abbreviations.add(order.contractorType);
          }
        });
        return Array.from(abbreviations);
      }

      // getters
      get contractorBranchesHeaders() {
          return [
              {
                  text: this.$t('projects.details.contractorBranches.name').toString(),
                  value: 'contractorTypeName',
                  align: 'start',
                  sortable: false,
                  groupable: false,
                  class:'text-no-wrap'
              } as DataTableHeader,
              {
                  text: this.$t('projects.details.contractorBranches.comment').toString(),
                  value: 'comment',
                  align: 'start',
                  sortable: false,
                  groupable: false,
                  class:'text-no-wrap'
              } as DataTableHeader,
              { text: '', value: 'actions', sortable: false, groupable: false } as DataTableHeader,
          ];
      }

      get unusedContractorBranches():ContractorBranch[]{
        return this.orderedContractorBranches.filter(branch => !this.projectContractorBranches.some(cb => cb.contractorTypeId === branch.id));
      }

      get unusedContractorBranchesWithEditedItem():ContractorBranch[]{
        return this.orderedContractorBranches.filter(branch => !this.projectContractorBranches.some(cb => cb.contractorTypeId === branch.id && cb.contractorTypeId !== this.content.contractorBranchUpdate?.contractorTypeId));
      }

      get orderedContractorBranches():ContractorBranch[]{
        return this.collectOrderedContractorBranches();
      }

      // setters

      // watchers

      // lifecycle
      created() {
			if(!this.allContractorBranches || (this.allContractorBranches && this.allContractorBranches.length === 0)) this.fetchAllContractorBranches();
		}
  }
