import {Module} from 'vuex';

import {RootState} from '@/core/types/RootState';
import {SettingsState} from '@/modules/settings/store/types/SettingsState';

import {store as divisions} from '@/modules/settings/store/modules/divisions';
import {store as workflow} from '@/modules/settings/store/modules/workflow';
import {store as contractorBranches} from '@/modules/settings/store/modules/contractor-branches';
import {store as permissions} from '@/modules/settings/store/modules/permissions';
import {store as activityLog} from '@/modules/settings/store/modules/activity-log';
import {store as deletedLog} from '@/modules/settings/store/modules/deleted-log';
import {store as taxes} from '@/modules/settings/store/modules/taxes';

export const store: Module<SettingsState, RootState> = {
	namespaced: true,
	modules: {
		'activity-log': activityLog,
		'deleted-log': deletedLog,
		'contractor-branches': contractorBranches,
		divisions,
		'permissions': permissions,
		workflow,
		taxes,
	}
};
