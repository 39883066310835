import { Client } from '@/modules/entities/types/entities';
import { computed, reactive } from 'vue';
import { merge } from 'lodash';
import { actions as clientActions } from './actions/useClient';
import { Options } from './types/composable';
import { UseClientComposables, UseClientContext, UseClientState } from './types/useClient';
import { useDynamicProps } from './useDynamicProps';
import useFilter from './useFilter';
import {mergeComposables, wrapActions} from './utils';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseClientState>): UseClientState => {
  return merge(
    {
      client: null,
      clientValidationErrors: null,
    },
    state
  );
};

/**
 * `UseClient`-composable
 *   - All Client-related interactions and functionality can be stored here.
 *   - If you need a global store, than just use this composable in eg a `store.ts`
 *
 * @param options
 * @returns
 */
export default function useClient(options?: Options<UseClientState, UseClientComposables>) {

  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    filter: useFilter(),
    showDialog: useDynamicProps<boolean>(),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseClientContext = { state, composables };
  const actions = wrapActions(ctx, clientActions);

  // return getters and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // other
      showDialog: ctx.composables.showDialog.getters,
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      client: computed<Client | null>(() => state.client as Client),
      clientValidationErrors: computed(() => state.clientValidationErrors),
    },
    actions: {
      ...ctx.composables.filter.actions,
      showDialog: ctx.composables.showDialog.actions.set,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...actions,
    },
  };
}
