
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    import { Actions, Getters } from '@/modules/accounting/store/types/StoreTypes';
    import { AccountingLedgerItem, AccountingLedgerItemEntity, AccountingLedger, AccountingLedgerEntity } from '../../types/entities';
    import AccountingLedgerUpdateModal from '../../components/accounting-ledger-update-modal/accounting-ledger-update-modal.component.vue'
	import { DataTableHeader } from 'vuetify';
	import { i18n } from '@/main';
	import { AxiosResponse } from 'axios';
	import { JsonResource } from '@/core/types/Entities';
  import { cloneDeep } from 'lodash'

    // namespaces
    const accounting = namespace('accounting');

    @Component({
        components: {
            'accounting-ledger-update-modal': AccountingLedgerUpdateModal,
        }
    })
    export default class AccountingLedgersComponent extends Vue {

        // actions
        @accounting.Action(Actions.FETCH_ACCOUNTING_LEDGERS) fetchAccountingLedgers!: () => Promise<void>;
		@accounting.Action(Actions.FETCH_ACCOUNTING_LEDGER) fetchAccountingLedgerById!: (id: string) => Promise<void>;
		@accounting.Action(Actions.CREATE_ACCOUNTING_LEDGER) createAccountingLedger!:(payload: AccountingLedger) => void;
		@accounting.Action(Actions.ACTIVATE_ACCOUNTING_LEDGER) activateAccountingLedger!: (payload: AccountingLedger) => Promise<void>;
		@accounting.Action(Actions.SAVE_ACCOUNTING_LEDGER_BY_ID) saveAccountingLedger!: (payload: AccountingLedger) => Promise<AxiosResponse<JsonResource<AccountingLedger>>>;

        // getters
		@accounting.Getter(Getters.GET_ACCOUNTING_LEDGERS) accountingLedgers!: AccountingLedger[];
		@accounting.Getter(Getters.GET_ACCOUNTING_LEDGER) accountingLedger!: AccountingLedger;
		@accounting.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;

		// vars
		loadingState: { listview: boolean } = { listview: false }
		state: {
			showModalUpdateAccountingLedger: boolean,
			showModalConfirmCopyAccountingLedger: boolean,
		} = {
			showModalUpdateAccountingLedger: false,
			showModalConfirmCopyAccountingLedger: false,
		}
		content: {
			copyAccountingLedger: AccountingLedger | null,
		} = {
			copyAccountingLedger: null,
		}
        accountingLedgerModalState: {
			show: boolean,
			showNew: boolean,
            key: number,
			loading: boolean,
		} = {
			show: false,
			showNew: false,
            key: 0,
			loading: false
		};
        headers: Array<DataTableHeader> = [
            {
                text: i18n.t('accounting.accountingLedger.details.labels.name').toString(),
                value: 'name',
                groupable: false,
                class:'text-no-wrap'
            } as DataTableHeader,
            {
                text: i18n.t('accounting.accountingLedger.details.labels.description').toString(),
                value: 'description',
                groupable: false,
                class:'text-no-wrap',
            } as DataTableHeader,
            {
                text: i18n.t('accounting.accountingLedger.details.labels.startDate').toString(),
                value: 'startDate',
                groupable: false,
                class:'text-no-wrap'
            } as DataTableHeader,
            {
                text: i18n.t('accounting.accountingLedger.details.labels.endDate').toString(),
                value: 'endDate',
                groupable: false,
                class:'text-no-wrap'
            } as DataTableHeader,
            {
                text: i18n.t('accounting.accountingLedger.details.labels.active').toString(),
                value: 'active',
                groupable: false,
                class:'text-no-wrap'
            } as DataTableHeader,
            {
                text: '',
                value: 'actions',
                groupable: false,
                class:'text-no-wrap'
            } as DataTableHeader,
        ];
        
        // handlers 
        onClickEditAccountingLedger(item: AccountingLedger) {
            if(item.id !== undefined) {
				this.state.showModalUpdateAccountingLedger = true;
				this.fetchAccountingLedgerById(item.id.toString());
			}
		}
		async onClickCopyAccountingLedger(item: AccountingLedger)
    {
      if(!item.id) return;

      await this.fetchAccountingLedgerById(item.id.toString())

			// get properties to be copied/cloned
			let accountingLedger = new AccountingLedgerEntity(cloneDeep(this.accountingLedger)) as AccountingLedger;
			let ledgerItems = [] as AccountingLedgerItem[];

			// copy ledger items
			if(accountingLedger.accountingLedgerItems) {
				ledgerItems = JSON.parse(JSON.stringify(accountingLedger.accountingLedgerItems))
				ledgerItems = accountingLedger.accountingLedgerItems.map((item: AccountingLedgerItem) => {
					item.id = undefined
					return item
				});
			}

			// clone ledger and reset some props
			accountingLedger = {
				...accountingLedger,
				id: undefined,
				name: accountingLedger.name + ' Copy',
				active: false,
				accountingLedgerItems: ledgerItems
			}

			// use new ledger in store
			this.content.copyAccountingLedger = accountingLedger
			// this.createAccountingLedger(accountingLedger);
			this.state.showModalConfirmCopyAccountingLedger = true
		}
		onUpdateLoadingAccountingLedgerModal(value: boolean) {
            this.accountingLedgerModalState = {...this.accountingLedgerModalState, loading: value}
		}
		onClickActivate(item: AccountingLedger) {
			this.loadingState = {...this.loadingState, listview: true}
			this.activateAccountingLedger(item).finally(() => {
				this.loadingState = {...this.loadingState, listview: false}
			});
		}
		onClickConfirmCopyAccountingLedger(accountingLedger: AccountingLedger) {
			this.saveAccountingLedger(accountingLedger).then((result) => {
				this.state.showModalConfirmCopyAccountingLedger = false
				this.onClickEditAccountingLedger(result.data.data)
			})
		}

		//watchers
		
		// computed
		get isListviewLoading() {
			return this.isLoading || this.loadingState.listview;
		}

        // hooks
        created() {
            this.fetchAccountingLedgers();
        }

    }

