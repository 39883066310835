
import { AxiosResponse } from 'axios';
import axios from 'axios';
import { CompanyType } from '@/modules/entities/types/entities';
import { Pagination } from '@/core/types/Entities';

const BASE_ENDPOINT = '/api/v1/settings/company-types';

export class CompanyTypesService {

	getBaseEndpoint(): string {
		return BASE_ENDPOINT
	}

	getAllCompanyTypes(url = `${ BASE_ENDPOINT }`): Promise<AxiosResponse<Pagination<CompanyType[]>>> {
		return axios.get<Pagination<CompanyType[]>>(`${ url }`);
	}

}
