
  import { AppMessage } from './entities/app-message.entity';
  import { AppMessageType } from './entities/app-message-type.entity';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import { computed, defineComponent, ref, watch } from 'vue';

  export default defineComponent({
    name: 'AppMessageComponent',
    components: {},
    emits: [],
    props: {},
    setup(props, { emit }) {
      const message = appMessageStore.getters.lastMessage;
      const showDetails = ref(false);
      let timer: any = null;

      const isSuccessType = computed(() => message.value !== null && message.value.type === AppMessageType.SUCCESS);
      const isErrorType = computed(() => message.value !== null && message.value.type === AppMessageType.ERROR);
      const isWarningType = computed(() => message.value !== null && message.value.type === AppMessageType.WARNING);
      const isInfoType = computed(() => message.value !== null && message.value.type === AppMessageType.INFO);

      const dismissMessage = (message: AppMessage) => {
        appMessageStore.actions.dismiss(message.id);
      };

      watch(message, (message, oldMessage) => {
        // should the message be shown?
        // if ((message && !oldMessage) || (message && oldMessage && message.id !== oldMessage.id)) show.value = true;
        // else show.value = false;
        showDetails.value = false;

        // set timer to close
        if (message && message.ttl && message.ttl > 0) {
          timer = setTimeout(() => dismissMessage(message), message.ttl);
        } else if (message && !message.ttl) {
          timer = setTimeout(() => dismissMessage(message), 2500);
        } else {
          if (timer) clearTimeout(timer);
        }
      });

      // `showMessage` is used as v-model to listen for user that 'clicks outside' (aka "closes") the message
      const showMessage = computed({
        get() {
          return !!message.value;
        },
        set(newValue) {
          if (message.value) dismissMessage(message.value);
        },
      });

      return {
        message,
        showMessage,
        showDetails,

        dismissMessage,

        isSuccessType,
        isErrorType,
        isWarningType,
        isInfoType,
      };
    },
  });
