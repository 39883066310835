import { AxiosPromise } from 'axios';
import { JsonResource } from '@/core/types/Entities';
import { Comment } from '@/modules/entities/types/entities';
import axios from 'axios';

const COMMENTS_ENDPOINT = '/api/v1/comments';

export class CommentsService {

	createComment(comment: Comment): AxiosPromise<JsonResource<Comment>> {
		return axios.post<JsonResource<Comment>>(`${ COMMENTS_ENDPOINT }`, comment);
	}

	editCommentById(commentId: number, comment: Comment): AxiosPromise<JsonResource<Comment>> {
		return axios.put<JsonResource<Comment>>(`${ COMMENTS_ENDPOINT }/${ commentId }`, comment);
	}

	deleteCommentById(commentId: number): AxiosPromise {
		return axios.delete(`${ COMMENTS_ENDPOINT }/${ commentId }`);
	}
}
