
import Vue, {defineComponent, computed, ref, watch, getCurrentInstance} from 'vue';
import { debounce } from 'lodash';
import { User, Role, UserEntity } from '@/modules/entities/types/entities';
import { Permissions } from '@/core/types/Permissions';
import UserInfoSectionComponent from '../../store/modules/users/components/user-info-section/user-info-section.component.vue';
import UserPasswordSectionComponent from '../../store/modules/users/components/user-password-section/user-password-section.component.vue';
import UserPermissionsSectionComponent from '../../store/modules/users/components/user-permissions-section/user-permissions-section.component.vue';
import { PasswordSet } from '../../store/modules/users/types';
import useUser from '@/composables/useUser';
import { store } from '../../store/modules/users/store';
import authStore from "@/modules/login/store";

  export default defineComponent({
    name: 'Users',
    components: {
      'user-info-section': UserInfoSectionComponent,
      'user-password-section': UserPasswordSectionComponent,
      'user-permissions-section': UserPermissionsSectionComponent,
    },
    setup() {
      const vm = getCurrentInstance()!.proxy

      // composables
      const { state: userState, actions: userActions } = store.usersListView;
      const { getters: createUserGetters, actions: createUserActions } = useUser();
      const { getters: updateUserGetters, actions: updateUserActions } = useUser();
      const { getters: deleteUserGetters, actions: deleteUserActions } = useUser();

      /**
       * Handlers
       *
       */
      const onUpdateUserInfo = (user: User, modal = 'add') => {
        if (modal === 'add' && createUserGetters.user.value) {
          createUserActions.setUser({
            ...createUserGetters.user.value,
            username: user.username,
            email: user.email,
            alias: user.alias,
            isActive: user.isActive,
            firstName: user.firstName,
            lastName: user.lastName,
          });
        } else if (modal === 'edit' && updateUserGetters.user.value) {
          updateUserActions.setUser({
            ...updateUserGetters.user.value,
            username: user.username,
            email: user.email,
            alias: user.alias,
            isActive: user.isActive,
            firstName: user.firstName,
            lastName: user.lastName,
          });
        }
      };
      const onUpdateUserPassword = (passwordSet: PasswordSet, modal = 'add') => {
        if (modal === 'add') {
          createUserActions.matchPassword(passwordSet);
        } else if (modal === 'edit') {
          updateUserActions.matchPassword(passwordSet);
        }
      };
      const onUpdateUserPermissions = (user: User, modal = 'add') => {
        if (modal === 'add' && createUserGetters.user.value) {
          createUserActions.setUser({ ...createUserGetters.user.value, role: user.role, roles: user.roles });
        } else if (modal === 'edit' && updateUserGetters.user.value) {
          updateUserActions.setUser({ ...updateUserGetters.user.value, role: user.role, roles: user.roles });
        }
      };
      const onUpdateUserRole = (role: Role, modal = 'add') => {
        if (modal === 'add' && createUserGetters.user.value) {
          createUserActions.setUser({ ...createUserGetters.user.value, role: role });
        } else if (modal === 'edit' && updateUserGetters.user.value) {
          updateUserActions.setUser({ ...updateUserGetters.user.value, role: role });
        }
      };
      const onUpdateUserRoles = (roles: Role[], modal = 'add') => {
        if (modal === 'add' && createUserGetters.user.value) {
          createUserActions.setUser({ ...createUserGetters.user.value, roles: roles });
        } else if (modal === 'edit' && updateUserGetters.user.value) {
          updateUserActions.setUser({ ...updateUserGetters.user.value, roles: roles });
        }
      };
      const onClickCreateUser = async () => {
        if (createUserGetters.user.value && createUserGetters.userPasswordMatchingResult.value.isConfirmed) {
          // set the password & user
          createUserActions.setUser({
            ...createUserGetters.user.value,
            password: createUserGetters.userPasswordMatchingResult.value.password,
          });

          await createUserActions.createUser().then(() => {
            if (createUserGetters.user.value) userActions.addItem(createUserGetters.user.value);
            createUserActions.showDialog('add', false);
            createUserActions.setUser(null);
          });
        }
      };
      const onClickUpdateUser = async () => {
        if (updateUserGetters.user.value && updateUserGetters.user.value.id) {
          // set user & update password if provided
          updateUserActions.setUser({
            ...updateUserGetters.user.value,
            ...(updateUserGetters.userPasswordMatchingResult.value &&
              updateUserGetters.userPasswordMatchingResult.value.isConfirmed && {
                password: updateUserGetters.userPasswordMatchingResult.value.password,
              }),
          });

          await updateUserActions.updateUser().then(() => {
            if (updateUserGetters.user.value)
              userActions.replaceItem(updateUserGetters.user.value, (x, item) => x.id === item.id);
            updateUserActions.showDialog('edit', false);
            updateUserActions.setUser(null);
          });
        }
      };
      const onClickDeleteUser = async () => {
        await deleteUserActions.deleteUser().then(() => {
          if (deleteUserGetters.user.value)
            userActions.removeItem(deleteUserGetters.user.value, (x, item) => x.id === item.id);
          deleteUserActions.showDialog('delete', false);
          deleteUserActions.setUser(null);
        });
      };

      /**
       * Methods
       *
       */
      const emailRequired = (v: any) => !!v || vm.$t('dictionary.required');
      const emailFormat = (v: any) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || vm.$t('dictionary.invalid');
      const isAllowedToImpersonate = () => Vue.prototype.$can(Permissions.impersonate)

      const openModal = (modal: string, user: User) => {
        switch (modal) {
          case 'add':
            createUserActions.clearUserValidationErrors();
            if (!createUserGetters.user.value) createUserActions.setUser(new UserEntity());
            createUserActions.showDialog(modal, true);
            break;
          case 'edit':
            if (user.id) {
              updateUserActions.fetchUser(user.id).then(() => {
                updateUserActions.showDialog(modal, true);
              });
            }
            break;
          case 'delete':
            deleteUserActions.clearUserValidationErrors();
            deleteUserActions.setUser(user);
            deleteUserActions.showDialog(modal, true);
            break;
        }
      };

      const impersonate = async (userToImpersonate: User) => {
        if(userToImpersonate?.id) {
          await authStore.auth.actions.impersonate(userToImpersonate.id)
        }
      };

      const searchUsers = (query: string) => searchUsersDebounced(query);
      const searchUsersDebounced = debounce((query: string) => {
        if (userState.filters.value['search'] !== query) {
          userActions.search(query, true);
        }
      }, 180);

      /**
       * Computed
       *
       * Computed values are cached. Only when the underlying data changes, the value gets re-computed.
       *   so, using a cached copy of the prop in combination with :value and @input on form elements, we can
       *   emit changes and let the parent mutate the changes
       */
      // const userCopy = computed<User>(() => JSON.parse(JSON.stringify(props.user)))
      const headers = computed<any>(() => {
        return [
          {
            text: vm.$t('dictionary.firstName'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.lastName'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.username'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.email'),
            sortable: false,
          },
          {
            text: vm.$tc('dictionary.role'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.alias'),
            sortable: false,
          },
          {
            text: vm.$t('dictionary.active'),
            sortable: false,
          },
          {
            text: '',
            sortable: false,
          },
        ];
      });
      const loading = computed(
        () =>
          userState.isLoading.value ||
          createUserGetters.isLoading.value ||
          updateUserGetters.isLoading.value ||
          deleteUserGetters.isLoading.value
      );

      /**
       * Watchers
       *
       */

      /**
       * Initial data
       *
       */
      userActions.fetchUsers();
      userActions.fetchRoles();

      return {
        onUpdateUserInfo,
        onUpdateUserPassword,
        onUpdateUserPermissions,
        onUpdateUserRole,
        onUpdateUserRoles,

        emailRequired,
        emailFormat,
        isAllowedToImpersonate,
        onClickCreateUser,
        onClickUpdateUser,
        onClickDeleteUser,
        openModal,
        impersonate,
        searchUsers,

        headers,
        loading,

        userState,
        createUserGetters,
        updateUserGetters,
        deleteUserGetters,
        userActions,
        createUserActions,
        updateUserActions,
        deleteUserActions,

        auth: authStore.auth,
      };
    },
  });
