import { readonly, reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import { UseFilterState, UseFilterComposables, UseFilterContext } from './types/useFilter';
import { Options } from './types/composable';
import { merge } from 'lodash';
import { useDynamicProps } from './useDynamicProps';
import { actions as filterActions } from '@/composables/actions/useFilter';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseFilterState>): UseFilterState => {
  return merge(
    {
      filters: {},
      groups: {},
      activeFilters: [],
      urlParamPrefix: 'filter',
      urlParamArraySeparator: ',',
    },
    state
  );
};

// composable
export default function useFilter(options?: Options<UseFilterState, UseFilterComposables>)
{
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseFilterContext = { state, composables };
  const actions = wrapActions(ctx, filterActions);

  // return state and actions
  return {
    state: readonly(state),
    getters: {
      filters: computed<{ [key: string]: null|number|string|boolean | Array<number|string|boolean> }>(() => state.filters),
      activeFilters: computed<string[]>(() => state.activeFilters),
      filterGroups: computed<{ [key: string]: string[] }>(() => state.groups),
      filterUrlQuery: computed<URLSearchParams>(() => {
        const { state } = ctx;
        const activeAndTruthyFilters: { [key: string]: null|number|string|boolean | Array<number|string|boolean> } = {};
        const parameters = new URLSearchParams();

        // get the active filters
        state.activeFilters.forEach(f => { activeAndTruthyFilters[f] = JSON.parse(JSON.stringify(state.filters[f])); } );
        // apply filter
        for (const [filterName, filterValue] of Object.entries(activeAndTruthyFilters)) {
          if (filterValue != null) {
            const parts = filterName.split('.');
            const partsPrefix = parts.map(part => `[${part}]`).join('');
            const key = state.urlParamPrefix ? `${state.urlParamPrefix}${partsPrefix}` : filterName;
            let value = null;
            if (Array.isArray(filterValue)) value = filterValue.join(state.urlParamArraySeparator);
            else value = filterValue.toString();
            parameters.set(key, value);
          }
        }
        return parameters;
      }),
    },
    actions: {
      ...actions,
    },
  };
}