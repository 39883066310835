export const enum Actions {
	FETCH_DIVISIONS = 'FETCH_DIVISIONS',
	FETCH_DIVISION_BY_ID = 'FETCH_DIVISION_BY_ID',
	EDIT_DIVISION_BY_ID = 'EDIT_DIVISION_BY_ID',
	EDIT_DIVISION_TEAM = 'EDIT_DIVISION_TEAM',
	DELETE_DIVISION_BY_ID = 'DELETE_DIVISION_BY_ID',
	ADD_NEW_DIVISION = 'ADD_NEW_DIVISION',
	ADD_NEW_DIVISION_TEAM = 'ADD_NEW_DIVISION_TEAM',
}

export const enum Getters {
	GET_DIVISIONS = 'GET_DIVISIONS',
	GET_DIVISION_DETAIL = 'GET_DIVISION_DETAIL'
}

export const enum Mutations {
	MUTATE_DIVISIONS = 'MUTATE_DIVISIONS',
	MUTATE_DIVISION_DETAIL = 'MUTATE_DIVISION_DETAIL'
}
