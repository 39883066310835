import Vue from 'vue';
import * as Sentry from "@sentry/vue";
import {UsersService} from "@/services/users.service";
import {User} from "@/modules/entities/types/entities";
import authStore from "@/modules/login/store";

export function setupSentry(): void {
  const usersService = new UsersService();

  const mapUserToSentryUser = (user?: User): Sentry.User => {
    const su: Sentry.User = {
      id: user && user.id ? user.id.toString() : undefined,
      username: user && user.username ? user.username : undefined,
      // eslint-disable-next-line @typescript-eslint/camelcase
      ip_address: '{{auto}}',
    };
    return su;
  };

  const skipEventByHttpBreadcrumb = (event: Sentry.Event): boolean => {
    const xhrBreadcrumbs = event.breadcrumbs && event.breadcrumbs.length ? event.breadcrumbs.filter(br => br.type === 'http') : [];
    const first = xhrBreadcrumbs && xhrBreadcrumbs.length ? xhrBreadcrumbs[0] : null;
    const statusCode = first && first.data ? first.data['status_code'] : null;
    const skipStatusCodes = Array<number>(401, 422);
    return skipStatusCodes.includes(statusCode);
  };

  console.log(process.env.VUE_APP_SENTRY_VUE_DSN);
  console.log(process.env.VUE_APP_SENTRY_VUE_ENVIRONMENT);

  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_VUE_DSN,
    environment: process.env.VUE_APP_SENTRY_VUE_ENVIRONMENT,
    debug: process.env.VUE_APP_SENTRY_VUE_DEBUG === 'true',
    enabled: process.env.VUE_APP_SENTRY_VUE_ENABLED === 'true',

    /**
     * Filter which events to send to Sentry
     * @param event event to be sent
     * @returns
     */
    beforeSend: (event: Sentry.Event) => {
      const e = !skipEventByHttpBreadcrumb(event) ? event : null;
      if(e && !e.user) {
        const user = authStore.auth.getters.user.value;
        if(user != null) {
          e.user = mapUserToSentryUser(user);
        } else {
          e.user = mapUserToSentryUser();
        }
      }
      return e;
    },

    beforeBreadcrumb: (breadcrumb: Sentry.Breadcrumb) => {
      // if(breadcrumb.level === 'warning') return null
      return breadcrumb;
    },

    /**
     * `tunnel` can be used when a custom endpoint is needed, despite the fat that
     * we could also set this endpoint/url in a custom `TransportClass` (see below, our custom `AxiosTransport`)
     */
    // tunnel: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_SENTRY_VUE_TUNNEL_ENDPOINT}`,

    /**
     * still log errors to the developer console?
     */
    logErrors: true,

    /**
     * Set transport explicitly, otherwise `fetch API` could be used (instead of axios/XHR)
     *   - transport: XHRTransport,
     */
    // transport: AxiosTransport,

    /**
     * Default integerations
     * Can be overridden by creating a new instance using the 'integrations' property
     */
    // defaultIntegrations: [],

    /**
     * Custom integerations
     * you can overwrite default integrations with new config here
     */
    // integrations: [
    // new Integrations.TryCatch({XMLHttpRequest: false}),
    // new Integrations.GlobalHandlers(),
    // new Integrations.BrowserTracing({
    //     // use this to allow tracing from frontend to api backend and have them linked
    //     // CORS might have to be setup for the sentry-trace header ("Access-Control-Allow-Headers: sentry-trace")
    //     // tracingOrigins: ["localhost","127.0.0.1","*.sfinx.be"],
    // })
    // ],

    /**
     * Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
     *  - We recommend adjusting this value in production
     */
    tracesSampleRate: 0,
  });
}