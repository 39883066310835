export const namespace = 'settings/activity-log';

export const enum Actions {
	FETCH_ACTIVITY_LOG = 'FETCH_ACTIVITY_LOG'
}

export const enum Getters {
	GET_ACTIVITY_LOG = 'GET_ACTIVITY_LOG',
}

export const enum Mutations {
	MUTATE_ACTIVITY_LOG = 'MUTATE_ACTIVITY_LOG'
}
