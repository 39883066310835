
import { Component, Vue, Provide, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Actions, Getters } from '@/modules/reports/store/types/StoreTypes';
import { Actions as SuppliersActions, Getters as SuppliersGetters } from "@/modules/entities/store/modules/suppliers/types/StoreTypes";
import { ProgressSnapshot, ProgressSnapshotItem, Project, ProjectStatus } from '@/modules/projects/types/entities';
import { User } from '@/modules/entities/types/entities';
import { GrossMarginFilterState } from '../../store/types/ReportState';
import GrossMarginSideMenuComponent from '../../components/gross-margin-sidebar/gross-margin-sidebar.component.vue';
import { sortNatural } from '@/core/helpers/Sort';
import { ProjectStatusMixin } from '@/mixins/project-status-mixin.component';
import { GetAddressMixin } from '../../../../mixins/get-address-mixin.component';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';
import { getContractorBranchCssClass as getGrossMarginContractorBranchCssClass } from '@/modules/reports/helpers';


const reports = namespace("reports");
const suppliers = namespace("entities/suppliers");

@Component({
	components: {
		'gross-margin-side-menu': GrossMarginSideMenuComponent,
	},
	mixins: [
		ProjectStatusMixin,
		GetAddressMixin
	],
})
export default class GrossMarginComponent extends Vue {

	// store actions
	@reports.Action(Actions.FETCH_PROJECT_STATUSES) fetchProjectStatuses!: () => Promise<ProjectStatus[]>;
	@reports.Action(Actions.FETCH_USERS) fetchUsers!: () => Promise<User[]>;
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_PROJECT_STATUS) filterOnProjectStatus!: (payload: number[]) => void;
	@reports.Action(Actions.FETCH_GROSS_MARGIN_REPORT) fetchGrossMarginReport!: () => Promise<any>;
	@reports.Action(Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT) fetchProjectsContractorBranchesOverviewReport!: () => Promise<any>;
	@reports.Action(Actions.SET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC) setProjectsContractorBranchesOverviewReportMetric!: (payload: string) => void;
	@reports.Action(Actions.TOGGLE_SHOW_PROJECTS_GROSS_MARGIN_REPORT) toggleShowProjectsGrossMarginReport!: () => void;
	@reports.Action(Actions.TOGGLE_SHOW_PROJECTS_CONTRACTOR_BRANCHES_OVERVIEW_REPORT) toggleShowProjectsContractorBranchesOverviewReport!: () => void;
	@suppliers.Action(SuppliersActions.FETCH_CONTRACTOR_TYPES) fetchContractorTypes!: () => Promise<void>;

	// store getters
	@reports.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;
	@reports.Getter(Getters.GET_PROJECT_STATUSES_TRANSLATED) availableProjectStatuses!: ProjectStatus[];
	@reports.Getter(Getters.GET_USERS) availableUsers!: User[];
	@reports.Getter(Getters.GET_GROSS_MARGIN_FILTERSTATE) grossMarginFilterState!: GrossMarginFilterState;
	@reports.Getter(Getters.GET_GROSS_MARGIN_REPORT) grossMarginReport!: any;
	@reports.Getter(Getters.GET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT) projectsContractorBranchesReport!: any;
	@reports.Getter(Getters.GET_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT) filteredProjectsContractorBranchesReport!: any;
	@reports.Getter(Getters.GET_SHOW_PROJECTS_GROSS_MARGIN_REPORT) showProjectsGrossMarginReport!: boolean;
	@reports.Getter(Getters.GET_SHOW_PROJECTS_CONTRACTOR_BRANCHES_OVERVIEW_REPORT) showProjectsContractorBranchesOverviewReport!: boolean;
	@reports.Getter(Getters.GET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC) projectsContractorBranchesOverviewReportMetric!: ProjectsContractorBranchesOverviewReportMetric;
	@suppliers.Getter(SuppliersGetters.GET_CONTRACTOR_TYPES) availableContractorBranches!: ContractorBranch[];

	// props
	
	// local state
	state: {
		contractorBranchGroups: string[][],
	} = {
		contractorBranchGroups: [['AF','GR'],['GR','RI'],['GR','RI','FU'],['AF','GR','RI','FU'],['ME','ST'],['CV','SA'],['EL','VT']]
	}
	content: {
	} = {
	}

	// handlers
	onClickToggleShowProjectsContractorBranchesOverviewReport() {
		if(!this.showProjectsContractorBranchesOverviewReport) {
			this.fetchProjectsContractorBranchesOverviewReport().finally(() => {
				this.toggleShowProjectsContractorBranchesOverviewReport();
			});
		} else {
			this.toggleShowProjectsContractorBranchesOverviewReport();
		}
	}
	onClickRefreshReports()  {
		this.fetchGrossMarginReport()
		this.fetchProjectsContractorBranchesOverviewReport()
	}

	// methods
    parseMetricToInt(metric: any) {
        const parsedMetric = Number.parseInt(metric);
        return isNaN(parsedMetric) ? 0 : parsedMetric;
    }
    getProjectContractorBranchMetrics(projectId: number, contractorBranchId: 'none'|number) {
        const projectData = this.filteredProjectsContractorBranchesReport[projectId]
        let contractorBranchMetrics = null

        if(projectData) {
            contractorBranchMetrics = projectData[contractorBranchId]
        }

        return contractorBranchMetrics
    }

	getCostForProjectAndContractorBranch(project: Project, contractorBranch: ContractorBranch | 'none', summary?: boolean) {
		const contractorBranchId = contractorBranch === 'none' ? 'none' : contractorBranch?.id as number | 'none';
		const contractorBranchData = this.filteredProjectsContractorBranchesReport[project.id]?.[contractorBranchId];
		if (contractorBranchData) {
			if (summary && contractorBranchData.excludeSummary) {
				return 0;
			}
			return this.parseMetricToInt(contractorBranchData.totalCosts);
		}
		return 0;
	}
	getTotalAmountExecutedForProjectAndContractorBranch(project: Project, contractorBranch: ContractorBranch | 'none', summary?: boolean) {
		if (contractorBranch === 'none') {
			return 0;
		}
		const contractorBranchId = contractorBranch?.id as number;
		const contractorBranchData = this.filteredProjectsContractorBranchesReport[project.id]?.[contractorBranchId];
		if (contractorBranchData) {
			if (summary && contractorBranchData.excludeSummary) {
				return 0;
			}
			return this.parseMetricToInt(contractorBranchData.totalAmountExecuted);
		}
		return 0;
	}
	getTotalAmountOrderedForProjectAndContractorBranch(project: Project, contractorBranch: ContractorBranch | 'none', summary?: boolean) {
		if (contractorBranch === 'none') {
			return 0;
		}
		const contractorBranchId = contractorBranch?.id as number;
		const contractorBranchData = this.filteredProjectsContractorBranchesReport[project.id]?.[contractorBranchId];
		if (contractorBranchData) {
			if (summary && contractorBranchData.excludeSummary) {
				return 0;
			}
			return this.parseMetricToInt(contractorBranchData.totalAmountOrdered);
		}
		return 0;
	}
	isCompletedForProjectAndContractorBranch(project: Project, contractorBranch: ContractorBranch | 'none') {
		if(this.getTotalAmountExecutedForProjectAndContractorBranch(project,contractorBranch) == 0 && this.getTotalAmountOrderedForProjectAndContractorBranch(project,contractorBranch) == 0){
			return false;
		}else
		 return this.getTotalAmountExecutedForProjectAndContractorBranch(project,contractorBranch) == this.getTotalAmountOrderedForProjectAndContractorBranch(project,contractorBranch);
	}
	getMarginPercentageForProjectAndContractorBranch(project: Project, contractorBranch: ContractorBranch | 'none', summary?: boolean) {
		const cost = this.getCostForProjectAndContractorBranch(project, contractorBranch);
		const totalAmountExecuted = this.getTotalAmountExecutedForProjectAndContractorBranch(project, contractorBranch,summary);
		// -1 used to represent a cost value of 0
		return cost !== 0 ? totalAmountExecuted / cost : -1
	}
	getWeightedMarginPercentageForProjectAndContractorBranch(project: Project, contractorBranch: ContractorBranch | 'none', summary?: boolean) {
		const cost = this.getCostForProjectAndContractorBranch(project, contractorBranch);
		const totalAmountExecuted = this.getTotalAmountExecutedForProjectAndContractorBranch(project, contractorBranch, summary);
		return cost !== 0 ? (totalAmountExecuted / cost) * totalAmountExecuted : 0
	}
	getProfitLossForProjectAndContractorBranch(project: Project, contractorBranch: ContractorBranch | 'none', summary?: boolean) {
		const cost = this.getCostForProjectAndContractorBranch(project, contractorBranch);
		const totalAmountExecuted = this.getTotalAmountExecutedForProjectAndContractorBranch(project, contractorBranch, summary);
		return totalAmountExecuted - cost
	}
	getCostForProjectAndContractorBranchGroup(project: Project, groupIndex: number, summary?: boolean) {
		const contractorBranches = this.contractorBranchGroups ? this.contractorBranchGroups[groupIndex] : null
		if(this.filteredProjectsContractorBranchesReport[project.id] && contractorBranches && contractorBranches.length) {
			const result = contractorBranches
				.map((cb: ContractorBranch) => this.getCostForProjectAndContractorBranch(project, cb, summary))
				.reduce((prev: number, curr: number) => prev + curr, 0)
			return result ? result : 0
		}	
		return 0
	}
	getTotalAmountExecutedForProjectAndContractorBranchGroup(project: Project, groupIndex: number, summary?: boolean) {
		const contractorBranches = this.contractorBranchGroups ? this.contractorBranchGroups[groupIndex] : null
		if(this.filteredProjectsContractorBranchesReport[project.id] && contractorBranches && contractorBranches.length) {
			const result = contractorBranches
				.map((cb: ContractorBranch) => this.getTotalAmountExecutedForProjectAndContractorBranch(project, cb, summary))
				.reduce((prev: number, curr: number) => prev + curr, 0)
			return result ? result : 0
		}
		return 0
	}
	getMarginPercentageForProjectAndContractorBranchGroup(project: Project, groupIndex: number, summary?: boolean) {
		const cost = this.getCostForProjectAndContractorBranchGroup(project, groupIndex);
		const totalAmountExecuted = this.getTotalAmountExecutedForProjectAndContractorBranchGroup(project, groupIndex, summary);
		// -1 used to represent a cost value of 0
		return cost !== 0 ? totalAmountExecuted / cost : -1
	}
	getWeightedMarginPercentageForProjectAndContractorBranchGroup(project: Project, groupIndex: number, summary?: boolean) {
		const cost = this.getCostForProjectAndContractorBranchGroup(project, groupIndex);
		const totalAmountExecuted = this.getTotalAmountExecutedForProjectAndContractorBranchGroup(project, groupIndex, summary);
		return cost !== 0 ? (totalAmountExecuted / cost) * totalAmountExecuted : 0
	}
	getProfitLossForProjectAndContractorBranchGroup(project: Project, groupIndex: number, summary?: boolean) {
		const cost = this.getCostForProjectAndContractorBranchGroup(project, groupIndex);
		const totalAmountExecuted = this.getTotalAmountExecutedForProjectAndContractorBranchGroup(project, groupIndex, summary);
		return totalAmountExecuted - cost
	}

	getContractorBranchCssClass(project: Project, contractorBranch: ContractorBranch) {
		let projectData = this.filteredProjectsContractorBranchesReport;
		if(contractorBranch.id && projectData && projectData[project.id] && projectData[project.id][contractorBranch.id]){
		let contractorBranchData = projectData[project.id][contractorBranch.id];
		let cssClass = getGrossMarginContractorBranchCssClass(this.projectsContractorBranchesOverviewReportMetric, this.grossMarginFilterState,contractorBranchData,project);
		return cssClass
	}
		else return '';
	}

	getContractorBranchFilterCssClass(project: Project, contractorBranch: ContractorBranch) {
		let projectData = this.filteredProjectsContractorBranchesReport;
		if(contractorBranch.id && projectData && projectData[project.id] && projectData[project.id][contractorBranch.id]){
		projectData = projectData[project.id][contractorBranch.id];
		return projectData ? projectData.cssClass : '';}
		else return '';
	}

    latestProgressSnapshotHasOrderedAmountForContractorBranch(latestProgressSnapshot: ProgressSnapshot, contractorBranch: ContractorBranch) {
        const latestProgressSnapshotItems = latestProgressSnapshot?.items?.filter(item => item.contractorBranch?.id === contractorBranch.id);

        if(latestProgressSnapshotItems && latestProgressSnapshotItems.length === 1) {
            return latestProgressSnapshotItems[0].totalAmountOrdered > 0;
        }

        return false;
    }
    latestProgressSnapshotHasOrderedAmountForContractorBranchGroup(latestProgressSnapshot: ProgressSnapshot, contractorBranches: ContractorBranch[]) {
        const contractorBranchIds = contractorBranches.map(cb => cb.id);
        const latestProgressSnapshotItems = latestProgressSnapshot?.items?.filter(item => contractorBranchIds.includes(item.contractorBranch?.id)) || [];

        const result = latestProgressSnapshotItems.some(item => {
            if(item.contractorBranch) {
                return this.latestProgressSnapshotHasOrderedAmountForContractorBranch(latestProgressSnapshot, item.contractorBranch)
            }

            return false;
        })

        return result;
    }
    showProjectContractorBranch(project: Project, contractorBranch: ContractorBranch): boolean {
        return contractorBranch?.id && this.filteredProjectsContractorBranchesReport[project.id] && this.filteredProjectsContractorBranchesReport[project.id][contractorBranch.id]
    }
    showProjectContractorBranchGroup(project: Project, contractorBranches: ContractorBranch[]): boolean {
        return contractorBranches.some(item => this.showProjectContractorBranch(project, item))
    }

	summarize(header: string, items: []) {
		let summarizer = { value: [] as string[], summarize: (header: string, items: []) => { return 0; } };
		const filteredSummarizers = this.summarizers.filter((i: any) => { return i.value.includes(header) })
		summarizer = filteredSummarizers[0];
		if (items && summarizer) {
			return summarizer.summarize(header, items);
		}
		else return null;
	}

	// getters

	get projectsMaxMargin(): number {
		const items = Array.from<any>(this.grossMarginReport).map(a => a.grossMargin-1)
		const margin = items.sort((a: number, b: number) => b - a)[0]
		return margin ? margin : 0
	}
	get projectsMinMargin(): number {
		const items = Array.from<any>(this.grossMarginReport).map(a => a.grossMargin-1)
		const margin = items.sort((a: number, b: number) => a - b)[0]
		return margin ? margin : 0
	}
	get allowedContractorBranches() {
		return this.availableContractorBranches.filter(cb => !['BH'].includes(cb.abbreviation))
	}
	get summarizers() {
		return [
			{
				value:['totalCosts','totalEmployeeCosts','totalPurchaseInvoices','totalSalesInvoices','grossProfitOrLoss'], 
				summarize: (header: string, items: []) => { 
					return items.reduce((a: number, b: any) => { return a + Number(b[header])},0);
				}, 
			},
			{
				value:['totalAmountOrdered','totalAmountExecuted'], 
				summarize: (header: string, items: []) => { 
					return items.reduce((a: number, b: any) => {return b['latestProgressSnapshot'] ? a + Number(b['latestProgressSnapshot']?.[header]) : a},0);
				}, 
			},
			{
				// Weighted margin
				value: ['grossMargin'],
				summarize: (header: string, items: []) => {
					const weightedSum = items.reduce((sum: any, item: any) => item[header] && item['totalSalesInvoices'] ? sum + Number(item[header]) * Number(item['totalSalesInvoices']) : sum, 0);
					const totalSalesInvoices = items.reduce((sum: any, item: any) => item['totalSalesInvoices'] ? sum + Number(item['totalSalesInvoices']) : sum, 0);
					return totalSalesInvoices !== 0 ? weightedSum / totalSalesInvoices : 0
					// return new Intl.NumberFormat(this.$i18n.locale, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalSalesInvoices !== 0 ? weightedSum / totalSalesInvoices : 0);
				},
			},
			{
				value: this.contractorBranchHeaders.map(cb => cb.value),
				summarize: (header: string, items: []) => {
					if(this.projectsContractorBranchesOverviewReportMetric === 'cost'){
						return this.summaryOfCostForContractorBranch[header];
					}
					if(this.projectsContractorBranchesOverviewReportMetric === 'executed'){
						return this.summaryOfTotalAmountExecutedForContractorBranch[header];
					}
					if(this.projectsContractorBranchesOverviewReportMetric === 'profit_loss'){
						return this.summaryOfProfitLossForContractorBranch[header];
					}
					if(this.projectsContractorBranchesOverviewReportMetric === 'margin_percentage'){
						return this.summaryOfWeightedMarginForContractorBranch[header];
					}
					return 0;
				}
			},
			{
				value: this.contractorBranchGroupHeaders.map(cbg => cbg.value),
				summarize: (header: string, items: []) => {
					if(this.projectsContractorBranchesOverviewReportMetric === 'cost'){
						return this.summaryOfCostForContractorBranchGroup[header];
					}
					if(this.projectsContractorBranchesOverviewReportMetric === 'executed'){
						return this.summaryOfTotalAmountExecutedForContractorBranchGroup[header];

					}
					if(this.projectsContractorBranchesOverviewReportMetric === 'profit_loss'){
						return this.summaryOfProfitLossForContractorBranchGroup[header];
					}
					if(this.projectsContractorBranchesOverviewReportMetric === 'margin_percentage'){
						return this.summaryOfWeightedMarginForContractorBranchGroup[header];
					}
					return 0;
				}
			}

		];
	}

	get projects(){
		return this.grossMarginReport ? Array.from<Project>(this.grossMarginReport) : [] ;
	}

	get summaryOfCostForContractorBranch() {
		const summary = {} as {[s: string]: number}
		this.availableContractorBranches.forEach((contractorBranch: ContractorBranch) => {
			if(contractorBranch.abbreviation) {
				let summaryForContractorBranch = 0;
				this.projects.forEach(project => {
					summaryForContractorBranch += this.getCostForProjectAndContractorBranch(project, contractorBranch,true)
				})
				summary[contractorBranch.abbreviation] = summaryForContractorBranch
			}
		})
		return summary
	}
	get summaryOfCostForContractorBranchGroup() {
		const summary = {} as {[s: string]: number}
		this.contractorBranchGroups.forEach((contractorBranchGroup: ContractorBranch[], index: number) => {
			let groupAbbreviation = contractorBranchGroup.map(b => b.abbreviation).join(' + ');
			let summaryForContractorBranchGroup = 0;
			this.projects.forEach(project => {
				summaryForContractorBranchGroup += this.getCostForProjectAndContractorBranchGroup(project, index, true)
			})
			summary[groupAbbreviation] = summaryForContractorBranchGroup
		})
		return summary
	}
	get summaryOfTotalAmountExecutedForContractorBranch() {
		const summary = {} as {[s: string]: number}
		this.availableContractorBranches.forEach((contractorBranch: ContractorBranch) => {
			if(contractorBranch.abbreviation) {
				let summaryForContractorBranch = 0;
				this.projects.forEach(project => {
					summaryForContractorBranch += this.getTotalAmountExecutedForProjectAndContractorBranch(project, contractorBranch,true)
				})
				summary[contractorBranch.abbreviation] = summaryForContractorBranch
			}
		})
		return summary
	}
	get summaryOfTotalAmountExecutedForContractorBranchGroup() {
		const summary = {} as {[s: string]: number}
		this.contractorBranchGroups.forEach((contractorBranchGroup: ContractorBranch[], index: number) => {
			let groupAbbreviation = contractorBranchGroup.map(b => b.abbreviation).join(' + ');
			let summaryForContractorBranchGroup = 0;
			this.projects.forEach(project => {
				summaryForContractorBranchGroup += this.getTotalAmountExecutedForProjectAndContractorBranchGroup(project, index, true)
			})
			summary[groupAbbreviation] = summaryForContractorBranchGroup
		})
		return summary
	}
	get summaryOfProfitLossForContractorBranch() {
		const summary = {} as {[s: string]: number}
		this.availableContractorBranches.forEach((contractorBranch: ContractorBranch) => {
			if(contractorBranch.abbreviation) {
				let summaryForContractorBranch = 0;
				this.projects.forEach(project => {
					summaryForContractorBranch += this.getProfitLossForProjectAndContractorBranch(project, contractorBranch,true)
				})
				summary[contractorBranch.abbreviation] = summaryForContractorBranch
			}
		})
		return summary
	}
	get summaryOfProfitLossForContractorBranchGroup() {
		const summary = {} as {[s: string]: number}
		this.contractorBranchGroups.forEach((contractorBranchGroup: ContractorBranch[], index: number) => {
			let groupAbbreviation = contractorBranchGroup.map(b => b.abbreviation).join(' + ');
			let summaryForContractorBranchGroup = 0;
			this.projects.forEach(project => {
				summaryForContractorBranchGroup += this.getProfitLossForProjectAndContractorBranchGroup(project, index, true)
			})
			summary[groupAbbreviation] = summaryForContractorBranchGroup
		})
		return summary
	}

	get summaryOfWeightedMarginForContractorBranch() {
		const summary = {} as {[s: string]: number}
		this.availableContractorBranches.forEach((contractorBranch: ContractorBranch) => {
			if(contractorBranch.abbreviation) {
				let weightedSum = 0;
				let totalWeightedMargin = 0;
				this.projects.forEach(project => {
					weightedSum += this.getWeightedMarginPercentageForProjectAndContractorBranch(project, contractorBranch,true);
					totalWeightedMargin += this.getTotalAmountExecutedForProjectAndContractorBranch(project, contractorBranch,true);
				})
				summary[contractorBranch.abbreviation] = weightedSum / totalWeightedMargin;
			}
		})
		return summary;
	}
	get summaryOfWeightedMarginForContractorBranchGroup() {
		const summary = {} as {[s: string]: number}
		this.contractorBranchGroups.forEach((contractorBranchGroup: ContractorBranch[], index: number) => {
			let groupAbbreviation = contractorBranchGroup.map(b => b.abbreviation).join(' + ');
			let weightedSum = 0;
			let totalWeightedMargin = 0;
			this.projects.forEach(project => {
				weightedSum += this.getWeightedMarginPercentageForProjectAndContractorBranchGroup(project, index, true);
				totalWeightedMargin += this.getTotalAmountExecutedForProjectAndContractorBranchGroup(project, index, true);
			})
			summary[groupAbbreviation] = weightedSum/ totalWeightedMargin;
		})
		return summary
	}

	get contractorBranchGroups() {
		const contractorBranchGroups = [] as ContractorBranch[][]
		if(this.availableContractorBranches) {
			this.state.contractorBranchGroups.forEach((group: string[]) => {
				const groupedContractorBranch = this.availableContractorBranches.filter(b => group.includes(b.abbreviation))
				contractorBranchGroups.push(groupedContractorBranch)
			});
		}
		return contractorBranchGroups
	}
	get dataTableHeaders() {
		return [
			...(this.grossMarginHeaders.map(c => { return {...c, group: 'project', cssClass: 'header--project' }} )),
			...((this.showProjectsContractorBranchesOverviewReport ? this.contractorBranchGroupHeaders : [] ).map(c => { return {...c, group: 'contractor-branch-group', cssClass: 'text-right header--contractor-branch-group' }} )),
			...((this.showProjectsContractorBranchesOverviewReport ? this.contractorBranchHeaders : []).map(c => { return { ...c, group: 'contractor-branch' , cssClass: 'text-right header--contractor-branch' }})),
			{
				text: '',
				// width: '110px',
				// value: 'projectName',
			},
		];
	}
	get grossMarginHeaders() {
		return [
			{
				text: this.$t('reports.grossMargin.dataTable.headers.projectName').toString(),
				value: 'projectName',
				width: '110px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.latestSalesInvoiceDate').toString(),
				value: 'latestSalesInvoiceDate',
				width: '110px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.latestPurchaseInvoiceDate').toString(),
				value: 'latestPurchaseInvoiceDate',
				width: '110px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.totalAmountOrdered').toString(),
				value: 'totalAmountOrdered',
				width: '130px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.totalAmountExecuted').toString(),
				value: 'totalAmountExecuted',
				width: '130px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.projectProgressPrecentage').toString(),
				value: 'projectProgressPrecentage',
				width: '110px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.totalSalesInvoices').toString(),
				value: 'totalSalesInvoices',
				width: '130px',
			},
			
			{
				text: this.$t('reports.grossMargin.dataTable.headers.totalCosts').toString(),
				value: 'totalCosts',
				width: '130px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.grossMargin').toString(),
				value: 'grossMargin',
				width: '75px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.grossProfitOrLoss').toString(),
				value: 'grossProfitOrLoss',
				width: '130px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.totalPurchaseInvoices').toString(),
				value: 'totalPurchaseInvoices',
				width: '130px',
			},
			{
				text: this.$t('reports.grossMargin.dataTable.headers.ownLabourCost').toString(),
				value: 'totalEmployeeCosts',
				width: '130px',
			},
			{
				text: '',
				value: '',
			},
		]
	}
	get contractorBranchGroupHeaders() {
		return [
			...(this.contractorBranchGroups.map((group, index) => {
				return {
					text: group.map(b => b.abbreviation).join(' + '),
					value: group.map(b => b.abbreviation).join(' + '),
					width: '160px'
				}
			})),
		]
	}
	get contractorBranchHeaders() {
		return [
			{
				text: 'Geen AT',
				value: 'none',
			},
			...(this.allowedContractorBranches.map(contractorBranch => {
				const contractorBranchId = contractorBranch.id as number
				return {
					text: contractorBranch.abbreviation,
					value: contractorBranch.abbreviation,
					width: '80px',
				}
			})),
		]
	}
	// setters

	// watchers

	// lifecycle
	fetchRequiredData() {
		return [
			this.availableUsers && this.availableUsers.length ? null : this.fetchUsers(),
			this.availableProjectStatuses && this.availableProjectStatuses.length ? null : this.fetchProjectStatuses(),
			this.availableContractorBranches && this.availableContractorBranches.length ? null : this.fetchContractorTypes(),
		].filter(v => !!v)
	}

	created(): void {
		Promise.all<any>(this.fetchRequiredData()).then(async () => {
			await this.fetchGrossMarginReport();
			if(await this.showProjectsContractorBranchesOverviewReport){
				await this.fetchProjectsContractorBranchesOverviewReport();
			}
		})
		if(this.showProjectsContractorBranchesOverviewReport){
				this.fetchProjectsContractorBranchesOverviewReport();
			}
		
	}
}
