
    import { Component, Vue, Provide, Watch, Prop, Model, Emit, PropSync } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    import { Actions, Getters } from '@/modules/sales-invoices/store/types/StoreTypes';
    import { Actions as ProjectActions, Getters as ProjectGetters } from '@/modules/projects/store/types/StoreTypes';
    import { SalesInvoice, SalesInvoiceStatus, SalesInvoiceType, SalesInvoiceRecordType, SalesInvoicePaymentStatus, SalesInvoiceTypeEntity } from '@/modules/sales-invoices/types/entities';
    import { ProgressSnapshot, ProgressSnapshotEntity, ProgressSnapshotItem, ProgressSnapshotItemEntity, Project } from '@/modules/projects/types/entities';
    import { Supplier, Client } from '@/modules/entities/types/entities';
    import {cloneDeep, debounce} from 'lodash';
    import dayjs from 'dayjs';
    import SalesInvoiceRelatedList from '@/modules/sales-invoices/components/sales-invoice-related-list/sales-invoice-related-list.component.vue';
    import PaymentsComponent from '@/modules/sales-invoices/components/sales-invoice-payments-list.component.vue';
    import Fuse from 'fuse.js'
    import { ProjectsService } from '@/services/projects.service';
    import { PropType } from 'vue';
    import { Payment } from '@/modules/payments/types/entities';
    import { Contact, ContactVM } from '@/modules/contacts/types/entities';
    import ProjectAutocompleteComponent from '@/modules/sales-invoices/components/project-autocomplete/project-autocomplete.component.vue'
    import ClientAutocompleteComponent from '@/modules/sales-invoices/components/client-autocomplete/client-autocomplete.component.vue'
    import SupplierAutocompleteComponent from '@/modules/sales-invoices/components/supplier-autocomplete/supplier-autocomplete.component.vue'
    import ContactAutocompleteComponent from '@/modules/sales-invoices/components/contact-autocomplete/contact-autocomplete.component.vue'
    import _ from 'lodash'
    import { calculateSalesInvoiceProgression, calculateTaxTotalForSalesInvoice } from '../../helpers';
    import { ProgressResult } from '../../types/SalesInvoiceCalculatorResult';
    import { Actions as TaxesActions, Getters as TaxesGetters } from '@/modules/settings/store/modules/taxes/types/StoreTypes';
    import { Tax } from '@/modules/settings/types/entities';
    import TaxAutocompleteComponent from '@/modules/settings/store/modules/taxes/components/tax-autocomplete/tax-autocomplete.component.vue';
    import ContractorBranchAutocompleteComponent from '@/modules/purchase-invoices/components/contractor-branch-autocomplete/contractor-branch-autocomplete.component.vue';
    import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';

    // namespaces
    const salesInvoices = namespace('salesInvoices');
    const projects = namespace('projects');
    const taxes = namespace('settings/taxes');

    // services
    const projectsService = new ProjectsService();
    
    @Component({
        components: {
            "sales-invoice-related-list": SalesInvoiceRelatedList,
            "sales-invoice-payments": PaymentsComponent,
            'project-autocomplete': ProjectAutocompleteComponent,
            'client-autocomplete': ClientAutocompleteComponent,
            'supplier-autocomplete': SupplierAutocompleteComponent,
            'contact-autocomplete': ContactAutocompleteComponent,
            'tax-autocomplete': TaxAutocompleteComponent,
            'contractor-branch-autocomplete': ContractorBranchAutocompleteComponent,
        }
    })
    export default class SalesInvoiceDetailsComponent extends Vue {

        // actions
        @salesInvoices.Action(Actions.SET_FILTER_ON_CONTACT_SEARCH) filterOnContactSearch!: (payload: string) => Promise<any>;
        @salesInvoices.Action(Actions.SET_FILTER_ON_CONTACT_SEARCH_PROJECTS) filterOnContactProject!: (payload: number) => void;
        @salesInvoices.Action(Actions.SET_FILTER_ON_CONTACT_SEARCH_SUPPLIERS) filterOnContactSupplier!: (payload: number) => void;
        @salesInvoices.Action(Actions.SET_FILTER_ON_CONTACT_SEARCH_CLIENTS) filterOnContactClient!: (payload: number) => void;
        @salesInvoices.Action(Actions.SET_FILTER_ON_CLIENT_SEARCH_PROJECTS) filterClientsByProjects!: (payload: number) => void;
        @salesInvoices.Action(Actions.CLEAR_FILTERS_ON_CONTACT_SEARCH) clearFiltersOnContactSearch!: (payload: string[]) => void;
        // @salesInvoices.Action(Actions.SET_FILTER_ON_PROJECT_SEARCH) filterOnProjectSearch!: (payload: string) => Promise<any>;
        @salesInvoices.Action(Actions.SET_FILTER_ON_SUPPLIER_SEARCH) filterOnSupplierSearch!: (payload: string) => Promise<any>;
        @salesInvoices.Action(Actions.SET_FILTER_ON_CLIENT_SEARCH) filterOnClientSearch!: (payload: string) => Promise<any>;
        @salesInvoices.Action(Actions.SET_FILTER_ON_CLIENT_SEARCH_PROJECTS) filterOnClientProjects!: (payload: number) => void;
        @salesInvoices.Action(Actions.SET_FILTER_ON_RELATED_BILLING_ADDRESSES_PROJECTS) filterOnRelatedBillingAddressesProject!: (payload: number) => void;
        @salesInvoices.Action(Actions.FETCH_NEW) fetchNew!: () => Promise<void>;
        @salesInvoices.Action(Actions.FETCH_PROJECT_INVOICES) fetchProjectInvoices!: (payload: number) => Promise<void>;
        @salesInvoices.Action(Actions.SEARCH_PROJECTS) searchProjects!: (payload: string) => Promise<any>;
        @salesInvoices.Action(Actions.SAVE_PROGRESS_SNAPSHOT_ITEM) saveProgressSnapshotItem!: (payload: {progressSnapshotId: string; progressSnapshotItem: ProgressSnapshotItem}) => Promise<any>;

        @salesInvoices.Action(Actions.SAVE_INVOICE) saveSalesInvoice!: (payload: SalesInvoice) => Promise<any>;
        @projects.Action(ProjectActions.FETCH_PROJECT_BY_ID) fetchProject!: (payload: string) => Promise<any>
        @projects.Action(ProjectActions.EDIT_PROJECT_BY_ID) saveProject!: (payload: Project) => Promise<any>
        
        // getters
        @salesInvoices.Getter(Getters.GET_STATUSES_TRANSLATED) statuses!: SalesInvoiceStatus[];
        @salesInvoices.Getter(Getters.GET_PAYMENT_STATUSES_TRANSLATED) paymentStatuses!: SalesInvoicePaymentStatus[];
        @salesInvoices.Getter(Getters.GET_TYPES) typesRaw!: SalesInvoiceType[];
        // @salesInvoices.Getter(Getters.GET_TYPES_TRANSLATED) types!: SalesInvoiceType[];
        @salesInvoices.Getter(Getters.GET_RECORD_TYPES_TRANSLATED) recordTypes!: SalesInvoiceRecordType[];
        @salesInvoices.Getter(Getters.GET_CONTACTS) contacts!: Contact[];
        @salesInvoices.Getter(Getters.GET_SEARCH_PROJECTS) projects!: Project[];
        @salesInvoices.Getter(Getters.GET_SUPPLIERS) suppliers!: Supplier[];
        @salesInvoices.Getter(Getters.GET_CLIENTS) clients!: Client[];
        @salesInvoices.Getter(Getters.GET_RELATED_BILLING_ADDRESSES) relatedBillingAddresses!: SalesInvoice[];
        @salesInvoices.Getter(Getters.GET_NEW) new!: SalesInvoice;
        @salesInvoices.Getter(Getters.GET_PROJECT_INVOICES) projectInvoices!: SalesInvoice[];
        @taxes.Getter(TaxesGetters.GET_TAXES_FOR_SALES) taxes!: Tax[];

        // props
        @PropSync('salesInvoice', { type: Object as PropType<SalesInvoice>, required: true }) salesInvoiceSync!: SalesInvoice;
        @PropSync('validationErrors', { type: Object }) validationErrorsSync!: any;
        @Prop({default: 'default'}) viewMode!: string; // 'default','modal'
        @Prop({type: Boolean, default: false}) hasModifications!: boolean;
        @Prop({type: Array as PropType<Array<ContractorBranch>>, default: () => [] }) contractorBranches!: ContractorBranch[];

        @Prop({type: Boolean}) 
        loading!: boolean;

        // local state + vars
        state: {
            searchingContacts: boolean,
            searchingProjects: boolean,
            searchingClients: boolean,
            searchingSuppliers: boolean,
            nameFieldLoading: boolean,
            nameFieldDisabled: boolean,
            openExpansionPanels: number[]
        } = {
            searchingContacts: false,
            searchingProjects: false,
            searchingClients: false,
            searchingSuppliers: false,
            nameFieldLoading: false,
            nameFieldDisabled: false,
            openExpansionPanels: [0,1,2,3],
        }
        expansionPanelChildrenPaymentDataState: Array<number> = [];
        selectedContact: Contact | null = null;
        projectClients: Client[] = []
        calculatedProgression: ProgressResult | null = null

        // handlers
        onInputName(name: string) {
            this.salesInvoiceSync = {...this.salesInvoiceSync, reference: name}
        }
        onSearchProject(searchTerm: string) { if(searchTerm) this.onSearchProjectDebounced(searchTerm, this) }
		onSearchProjectDebounced = _.debounce((searchTerm: string, vm: Vue) => {
			vm.$data.state = { ...vm.$data.state, searchingProjects: true }
			this.searchProjects(searchTerm).then(() => { vm.$data.state = {...vm.$data.state, searchingProjects: false} });
		}, 300);
        onSearchSupplier(searchTerm: string) { if(searchTerm) this.onSearchSupplierDebounced(searchTerm, this) }
		onSearchSupplierDebounced = _.debounce((searchTerm: string, vm: Vue) => {
			vm.$data.state = { ...vm.$data.state, searchingSuppliers: true }
            this.filterOnSupplierSearch(searchTerm).then(() => { vm.$data.state = {...vm.$data.state, searchingSuppliers: false} } );
		}, 300);
        onSearchContact(searchTerm: string) { if(searchTerm) this.onSearchContactDebounced(searchTerm, this)}
		onSearchContactDebounced = _.debounce((searchTerm: string, vm: Vue) => {
			vm.$data.state = { ...vm.$data.state, searchingContacts: true }
            this.filterOnContactSearch(searchTerm).then(() => { vm.$data.state = {...vm.$data.state, searchingContacts: false} } );
		}, 300);
        async fetchClientsForProject(project: Project) {
            this.state.searchingClients = true
            await projectsService.getAllClientsForProjectById(project.id)
                .then(res => {
                    this.projectClients = res.data.data
                }).catch(err => {
                    console.error(err)
                }).finally(() => {
                    this.state.searchingClients = false
                })
        }
        onChangeProject(project: Project) {
            
            // reset current selected client first
            if(this.salesInvoiceSync) {
                this.salesInvoiceSync.project = project
                this.salesInvoiceSync.client = null

              // if the invoice already has a snapshot, we'll replace it with a new one for the new project
                if(this.salesInvoiceSync.progressSnapshot) {
                  this.salesInvoiceSync.progressSnapshot = this.createProgressSnapshotFromLatest()
                }
            }

            // fetch clients for this project
            if(project) {
                this.fetchClientsForProject(project).then(() => {
                    if(this.projectClients.length && this.salesInvoiceSync) {
                        this.salesInvoiceSync.client = this.projectClients[0]
                    }
                })
            }

            // fetch related data when the project has changed
            if(project && project.id) {
                this.fetchProjectInvoices(project.id).then(() => this.simulateProgression());
                this.filterOnRelatedBillingAddressesProject(project.id);
            } 
        }
        onChangeClient(client: Client) {
            if(this.salesInvoiceSync) this.salesInvoiceSync.client = client
        }
        onFocusClient() {
            if(this.salesInvoiceSync && this.salesInvoiceSync.project) {
                this.fetchClientsForProject(this.salesInvoiceSync.project)
            }
        }
        onChangeSupplier(supplier: Supplier) {
            if(this.salesInvoiceSync) this.salesInvoiceSync.supplier = supplier
        }
        onChangeContact(contact: Contact) {
            this.selectedContact = contact
            if(contact) this.copyContactInformation(contact);
        }
        onFocusContactSearch() {
            if(this.salesInvoiceSync) {
                if(this.salesInvoiceSync.project) this.filterOnContactProject(this.salesInvoiceSync.project.id);
                else this.clearFiltersOnContactSearch(['projects']);
                if(this.salesInvoiceSync.client) this.filterOnContactClient(this.salesInvoiceSync.client.id);
                else this.clearFiltersOnContactSearch(['clients']);
                if(this.salesInvoiceSync.supplier) this.filterOnContactSupplier(this.salesInvoiceSync.supplier.id);
                else this.clearFiltersOnContactSearch(['suppliers']);
            }
        }
        onClickBillingAddress(salesInvoice: SalesInvoice) {
            const billingInformation = {
                contactName: salesInvoice.contactName,
                companyName: salesInvoice.companyName,
                vatNumber: salesInvoice.vatNumber,
                billingAddressStreet: salesInvoice.billingAddressStreet,
                billingAddressNumber: salesInvoice.billingAddressNumber,
                billingAddressStreetAdditional: salesInvoice.billingAddressStreetAdditional,
                billingAddressPostalCode: salesInvoice.billingAddressPostalCode,
                billingAddressCity: salesInvoice.billingAddressCity,
            } as SalesInvoice;
            this.copyBillingInformation(billingInformation);
        }
        onClickProjectAddress(project: Project) {
            const billingInformation = {
                contactName: this.salesInvoiceSync.client ? this.salesInvoiceSync.client.name : null,
                companyName: this.salesInvoiceSync.client && this.salesInvoiceSync.client.commercialName ? `${this.salesInvoiceSync.client.commercialName}${ this.salesInvoiceSync.client.companyType ? ' ('+this.salesInvoiceSync.client.companyType+')' : '' }` : null,
                vatNumber: this.salesInvoiceSync.client && this.salesInvoiceSync.client.vatNumber ? this.salesInvoiceSync.client.vatNumber : null,
                billingAddressStreet: project.street,
                billingAddressNumber: project.streetNumber,
                billingAddressPostalCode: project.postalCode,
                billingAddressCity: project.city,
            } as SalesInvoice;
            this.copyBillingInformation(billingInformation);
        }
        onClickClientAddress(client: Client) {
            const billingInformation = {
                contactName: this.salesInvoiceSync.client ? (this.salesInvoiceSync.client.commercialName ? this.salesInvoiceSync.client.commercialName : this.salesInvoiceSync.client.name) : null,
                companyName: this.salesInvoiceSync.client && this.salesInvoiceSync.client.commercialName ? `${this.salesInvoiceSync.client.commercialName}${ this.salesInvoiceSync.client.companyType ? ' ('+this.salesInvoiceSync.client.companyType+')' : '' }` : null,
                vatNumber: this.salesInvoiceSync.client && this.salesInvoiceSync.client.vatNumber ? this.salesInvoiceSync.client.vatNumber : null,
                billingAddressStreet: client.street,
                billingAddressNumber: client.streetNumber,
                billingAddressPostalCode: client.postalCode,
                billingAddressCity: client.city,
            } as SalesInvoice;
            this.copyBillingInformation(billingInformation);
        }
        onClickSuppliertAddress(supplier: Supplier) {
            const billingInformation = {
                contactName: this.salesInvoiceSync.supplier ? this.salesInvoiceSync.supplier.name : null,
                companyName: this.salesInvoiceSync.supplier && this.salesInvoiceSync.supplier.commercialName ? `${this.salesInvoiceSync.supplier.commercialName}${ this.salesInvoiceSync.supplier.companyType ? ' ('+this.salesInvoiceSync.supplier.companyType+')' : '' }` : null,
                vatNumber: this.salesInvoiceSync.supplier && this.salesInvoiceSync.supplier.vatNumber ? this.salesInvoiceSync.supplier.vatNumber : null,
                billingAddressStreet: supplier.street,
                billingAddressNumber: supplier.streetNumber,
                billingAddressPostalCode: supplier.postalCode,
                billingAddressCity: supplier.city,
            } as SalesInvoice;
            this.copyBillingInformation(billingInformation);
        }
        onVatPercentageChange(value: number) {
            this.calculatePaymentTotal();
        }
        onTotalChange() {
            this.calculatePaymentTotal();
        }
        onPaymentDiscountChange() {
            this.calculatePaymentTotal();
        }
        onClickRefreshInvoiceName() {
            this.fetchNewInvoiceName();
        }
        onClickInvoiceDate(invoiceDate: string) {
            this.addDaysToDueDate(invoiceDate);
            if(this.salesInvoiceSync && this.salesInvoiceSync.progressSnapshot) {
                this.salesInvoiceSync.progressSnapshot.snapshotCreatedAt = invoiceDate
            }
        }
        onClickConfirmProgression() {
            this.salesInvoiceSync.total = this.calculatedProgression ? this.calculatedProgression.toBeInvoicedWithAdvance : this.salesInvoiceSync.total
            this.salesInvoiceSync.deductedAdvance = this.calculatedProgression ? this.calculatedProgression.advance : this.salesInvoiceSync.deductedAdvance
            this.calculatePaymentTotal()
        }
        onClickConfirmInvoiceToCreditNoteForFinalAdvance() {
            this.salesInvoiceSync.recordType = this.recordTypes.find(rt => rt.name === 'credit_note')
            this.salesInvoiceSync.total = this.calculatedProgression && this.calculatedProgression.toBeInvoicedWithAdvance ? Math.abs(this.calculatedProgression.toBeInvoicedWithAdvance) : this.salesInvoiceSync.total
            this.salesInvoiceSync.deductedAdvance = this.calculatedProgression ? this.calculatedProgression.advance : this.salesInvoiceSync.deductedAdvance
            this.calculatePaymentTotal()
        }
        onClickCreateProgressSnapshot() {
          this.salesInvoiceSync.progressSnapshot = this.createProgressSnapshotFromLatest();
        }
        onClickAddProgressSnapshotItem() {
            if(this.salesInvoiceSync && this.salesInvoiceSync.progressSnapshot) {
                const item = new ProgressSnapshotItemEntity({
                    totalAmountOrdered: 0,
                    totalAmountExecuted: 0,
                    currency: 'EUR',
                    // progressSnapshot: JSON.parse(JSON.stringify(this.salesInvoiceSync.progressSnapshot))
                });
                if(!this.salesInvoiceSync.progressSnapshot.items) this.salesInvoiceSync.progressSnapshot.items = []
                this.salesInvoiceSync.progressSnapshot.items.push(item);
            }
        }
        onClickDeleteProgressSnapshotItem(progressSnapshotItem: ProgressSnapshotItem, index: number) {
            if(this.salesInvoiceSync && this.salesInvoiceSync.progressSnapshot && this.salesInvoiceSync.progressSnapshot.items) {
                const itemIndex = progressSnapshotItem.id ? this.salesInvoiceSync.progressSnapshot.items.findIndex((it) => progressSnapshotItem.id === it.id) : index;
                this.salesInvoiceSync.progressSnapshot.items.splice(itemIndex, 1);
            }
        }
        
        // methods
      createProgressSnapshotFromLatest() {
        const latestProgressSnapshot = this.salesInvoiceSync && this.salesInvoiceSync.project && this.salesInvoiceSync.project.latestProgressSnapshot || null;
        const snapshot = new ProgressSnapshotEntity({
          totalAmountOrdered: 0,
          totalAmountExecuted: 0,
          snapshotCreatedAt: this.salesInvoiceSync.invoiceDate ? this.salesInvoiceSync.invoiceDate : dayjs.utc().format('YYYY-MM-DD'),
          project: this.salesInvoiceSync.project ? cloneDeep(this.salesInvoiceSync.project) : null,
          salesInvoice: this.salesInvoiceSync ? cloneDeep(this.salesInvoiceSync) : null
        }) as ProgressSnapshot;

        // if project has a recent snapshot, add the items from that snapshot to snapshot we just created
        if(latestProgressSnapshot) {
          const latestProgressSnapshotCopy = cloneDeep(latestProgressSnapshot);

          snapshot.totalAmountOrdered = latestProgressSnapshotCopy.totalAmountOrdered
          snapshot.totalAmountExecuted = latestProgressSnapshotCopy.totalAmountExecuted

          if(latestProgressSnapshotCopy.items) {
            const items = latestProgressSnapshotCopy.items.map((item: ProgressSnapshotItem) => {
              const { id, totalAmountOrdered, totalAmountExecuted, contractorBranch, progressSnapshot  } = item
              return {
                id: undefined,
                totalAmountOrdered: item.totalAmountOrdered,
                totalAmountExecuted: item.totalAmountExecuted,
                contractorBranch: item.contractorBranch
              } as ProgressSnapshotItem
            })
            snapshot.items = items
          }
        }

        return snapshot;
      }
        copyContactInformation(contact: Contact) {
            if(this.salesInvoiceSync) {
                // name & address
                this.salesInvoiceSync = {...this.salesInvoiceSync, 
                    contactName: '' + (contact.firstName ? contact.firstName : '') + (contact.lastName ? contact.lastName : ''),
                    billingAddressStreet: contact.street,
                    billingAddressNumber: contact.streetNumber,
                    billingAddressStreetAdditional: contact.flatNumber,
                    billingAddressPostalCode: contact.postalCode,
                    billingAddressCity: contact.city,
                }

                // client company info
                if(this.salesInvoiceSync.client && this.salesInvoiceSync.client.vatNumber) {
                    this.salesInvoiceSync = {...this.salesInvoiceSync, 
                        companyName: this.salesInvoiceSync.client.commercialName,
                        vatNumber: this.salesInvoiceSync.client.vatNumber,
                    }
                }

                // supplier company info
                if(this.salesInvoiceSync.supplier && this.salesInvoiceSync.supplier.vatNumber) {
                    this.salesInvoiceSync = {...this.salesInvoiceSync, 
                        companyName: this.salesInvoiceSync.supplier.commercialName,
                        vatNumber: this.salesInvoiceSync.supplier.vatNumber,
                    }
                }
            }
        }
        copyBillingInformation(billingInformation: SalesInvoice) {
            if(billingInformation) {
                // copy name, company info, address
                this.salesInvoiceSync = {...this.salesInvoiceSync, ...billingInformation}
            }
        }
        fetchNewInvoiceName() {
            this.state = {...this.state, nameFieldLoading: true, nameFieldDisabled: true}
            this.fetchNew().then(() => {
                if(this.salesInvoiceSync) {
                    this.salesInvoiceSync = {...this.salesInvoiceSync, 
                        name: this.new.name,
                        reference: !this.salesInvoiceSync.reference ? this.new.name : this.salesInvoiceSync.reference,
                    }
                }
                this.state = {...this.state, nameFieldLoading: false, nameFieldDisabled: false}
            });
        }
        calculatePaymentTotal() {
            if(this.salesInvoiceSync && this.salesInvoiceSync.total !== undefined && this.salesInvoiceSync.vat) {
                const taxTotal = calculateTaxTotalForSalesInvoice(this.salesInvoiceSync);
                const grandTotal = this.salesInvoiceSync.total + taxTotal;
                const paymentTotal = this.salesInvoiceSync.paymentDiscount !== undefined ? grandTotal - this.salesInvoiceSync.paymentDiscount : grandTotal;
                this.salesInvoiceSync = {
                  ...this.salesInvoiceSync,
                    taxTotal: taxTotal,
                    grandTotal: grandTotal,
                    paymentTotal: paymentTotal
                }
            }
        }
        addDaysToDueDate(date: string, days: number = 7) {
            if(this.salesInvoiceSync){
                this.salesInvoiceSync = {...this.salesInvoiceSync, 
                    dueDate: dayjs(date).add(days,'day').format('YYYY-MM-DD')
                }
            }
        }
        simulateProgression() {
            this.calculatedProgression = calculateSalesInvoiceProgression(this.salesInvoiceSync, this.previousInvoices)
        }
        flattenRecursiveTypes(type: SalesInvoiceType, parent: SalesInvoiceType|null, level: number = 0) {
            const types = [] as SalesInvoiceType[];
            const parentLabel = parent ? '»'.repeat(level) + ' ' + this.$t('invoices.sales.types.'+parent.name).toString() + ' ' : '';
            const childLabel = parent ? this.$t('invoices.sales.types.'+type.name).toString().toLowerCase() : this.$t('invoices.sales.types.'+type.name).toString();
            types.push(new SalesInvoiceTypeEntity({...type, label: parentLabel + childLabel}) as SalesInvoiceType)
            if(type.children && type.children.length > 0) {
                type.children.forEach((value: SalesInvoiceType) => {
                    const result = this.flattenRecursiveTypes(value, type, level+1)
                    if(Array.isArray(result)) types.push(...result)
                    else types.push(result)
                })
            }
            return types;
        }
        progressSnapshotItemComparator(a: ProgressSnapshotItem , b: ProgressSnapshotItem) {
            return a.contractorBranch && b.contractorBranch ? a.contractorBranch.sequence - b.contractorBranch.sequence : 0;
        }

        // getters
        get filteredSuppliers(): Supplier[] {
            const suppliers = this.suppliers ? this.suppliers : [] as Supplier[]
            const all = [] as Supplier[];
            suppliers.forEach((s) => all.push(s));
            if(this.salesInvoiceSync && this.salesInvoiceSync.supplier) all.push(this.salesInvoiceSync.supplier);
            return all;
        }
        get showSelectedInvoiceDate(): string {
			if(this.salesInvoiceSync && this.salesInvoiceSync.invoiceDate) {
				return Vue.prototype.$formatDate(this.salesInvoiceSync.invoiceDate);
			}
			return '';
        }
        get showSelectedDueDate(): string {
			if(this.salesInvoiceSync && this.salesInvoiceSync.dueDate) {
				return Vue.prototype.$formatDate(this.salesInvoiceSync.dueDate);
			}
			return '';
        }
        get relatedSalesInvoices(): SalesInvoice[] {
            return this.relatedBillingAddresses;
        }
        get projectInvoicesExceptCurrent() {
		    return this.projectInvoices.filter((invoice) => this.salesInvoiceSync ? invoice.id !== this.salesInvoiceSync.id : false);
        }
        get firstAdvances() {
            return this.projectInvoicesExceptCurrent.filter((invoice) => invoice.subtype && invoice.subtype.name === 'advance_first');
        }
        get secondAdvances() {
            return this.projectInvoicesExceptCurrent.filter((invoice) => invoice.subtype && invoice.subtype.name === 'advance_second');
        }
        get fullAdvances() {
            return this.projectInvoicesExceptCurrent.filter((invoice) => invoice.subtype && invoice.subtype.name === 'advance_full');
        }
        get previousInvoices() {
            let skipCurrentAndNewerInvoices = false;
            return this.projectInvoices.filter((invoice: SalesInvoice) => {
                if(skipCurrentAndNewerInvoices) return false;
                if(this.salesInvoiceSync && invoice.id === this.salesInvoiceSync.id) { skipCurrentAndNewerInvoices = true; return false; };
                return true;
            });
        }
        get types() {
            const types = [] as SalesInvoiceType[];
            this.typesRaw.forEach((value: SalesInvoiceType) => {
                const result = this.flattenRecursiveTypes(value, null)
                if(Array.isArray(result)) types.push(...result)
                else types.push(result)
            })
            return types;
        }
        get typesForAutocomplete() {
            const typesForAutocomplete = [] as {text: string|null,value: SalesInvoiceType,disabled?: boolean,divider?: boolean,header?: string}[]
            this.types.forEach((value: SalesInvoiceType) => {
                typesForAutocomplete.push({
                    text: value.label !== undefined ? value.label.toString() : null,
                    value: value,
                    header: value && value.children && value.children.length > 0  ? value.label : undefined,
                    // disabled: value && value.children && value.children.length > 0,
                    // divider: value && value.children && value.children.length > 0
                });
            });
            return typesForAutocomplete;
        }
        get typeOrSubtypeAutocomplete() { 
            return this.salesInvoiceSync.type && this.salesInvoiceSync.subtype ? 
                this.typesForAutocomplete.filter((typeForAutocomplete: {text: string|null,value: SalesInvoiceType,disabled?: boolean,divider?: boolean,header?: string}) => { return this.salesInvoiceSync.subtype && this.salesInvoiceSync.subtype.id === typeForAutocomplete.value.id })[0] : 
                this.typesForAutocomplete.filter((typeForAutocomplete: {text: string|null,value: SalesInvoiceType,disabled?: boolean,divider?: boolean,header?: string}) => { return this.salesInvoiceSync.type && this.salesInvoiceSync.type.id === typeForAutocomplete.value.id })[0]
        }
        set typeOrSubtypeAutocomplete(typeOrSubtypeAutocomplete: {text: string|null,value: SalesInvoiceType,disabled?: boolean,divider?: boolean,header?: string}) {
            if(!!typeOrSubtypeAutocomplete) {
                const typeOrSubtype = typeOrSubtypeAutocomplete.value
                if(typeOrSubtype && typeOrSubtype.parentId) {
                    const type = this.types.filter((value: SalesInvoiceType) => {return typeOrSubtype.id === value.id})[0];
                    const parent = type ? this.types.filter((value: SalesInvoiceType) => { return typeOrSubtype.parentId === value.id })[0] : null;
                    this.salesInvoiceSync = {...this.salesInvoiceSync, type: parent, subtype: type}
                } else if(typeOrSubtype) {
                    const type = this.types.filter((value: SalesInvoiceType) => { return typeOrSubtype.id === value.id })[0];
                    this.salesInvoiceSync = {...this.salesInvoiceSync, type: type, subtype: null}
                }
            } else {
                this.salesInvoiceSync = {...this.salesInvoiceSync, type: null, subtype: null}
            }
        }
        get hasAppliedProgression() {
            return this.salesInvoiceSync && this.salesInvoiceSync.progressSnapshot && this.salesInvoiceSync.progressSnapshot.totalAmountOrdered && this.salesInvoiceSync.progressSnapshot.totalAmountExecuted
        }
        get hasDifferentProgression() {
            return this.calculatedProgression
                && (this.calculatedProgression.toBeInvoicedWithAdvance !== this.salesInvoiceSync.total || this.calculatedProgression.advance !== this.salesInvoiceSync.deductedAdvance)
        }
        get warnings() {
            const warnings = []
            if(this.salesInvoiceSync && this.salesInvoiceSync.id && this.salesInvoiceSync.type && this.salesInvoiceSync.type.name === 'default' && !this.hasAppliedProgression) warnings.push(this.$i18n.t('invoices.sales.details.errors.noProgressCalculationApplied').toString())
            if(this.salesInvoiceSync && this.salesInvoiceSync.type && this.salesInvoiceSync.type.name === 'default' && this.hasDifferentProgression) warnings.push(this.$i18n.t('invoices.sales.details.errors.differentProgression').toString())
            return warnings
        }
        get lastPayment() {
            const payments = this.salesInvoiceSync && this.salesInvoiceSync.payments && this.salesInvoiceSync.payments.length ? [...this.salesInvoiceSync.payments] : [] as Payment[]
            const sortedPayments = payments.sort((a: Payment, b: Payment) => {
                const aExecutedAt = a.bankTransaction && a.bankTransaction.executedAt ? dayjs(a.bankTransaction.executedAt) : dayjs.utc()
                const bExecutedAt = b.bankTransaction && b.bankTransaction.executedAt ? dayjs(b.bankTransaction.executedAt) : dayjs.utc()
                return aExecutedAt == bExecutedAt ? 0 : bExecutedAt < aExecutedAt ? -1 : 1;
            })
            return sortedPayments.length ? sortedPayments[0] : null
        }
        get totalPaid() {
            const payments = this.salesInvoiceSync && this.salesInvoiceSync.payments && this.salesInvoiceSync.payments.length ? [...this.salesInvoiceSync.payments] : [] as Payment[]
            return payments.map(p => p.total ? p.total : 0).reduce((prev, curr) => prev+curr, 0)
        }
        get previousProgressInvoice() {
            const filteredItems = this.previousInvoices.filter((invoice) => invoice.type && invoice.type.name === 'default');
            return filteredItems && filteredItems.length >= 1 ? filteredItems[filteredItems.length-1] : null;
        }
        get progression() {
            return this.calculatedProgression || (this.salesInvoiceSync && this.previousInvoices && calculateSalesInvoiceProgression(this.salesInvoiceSync, this.previousInvoices))
        }
        get sortedProgressSnapshotItems() {
            const items = this.salesInvoiceSync && this.salesInvoiceSync.progressSnapshot && this.salesInvoiceSync.progressSnapshot.items || []
            const sortedItems = [...items];//.sort(this.progressSnapshotItemComparator)
            return sortedItems
        }
        get selectedContractorBranches() {
            return this.salesInvoiceSync.progressSnapshot && this.salesInvoiceSync.progressSnapshot.items ? this.salesInvoiceSync.progressSnapshot.items.map(item => item.contractorBranch) : []
        }
        get totalAmountOrderedDiffByItems() {
            if(this.salesInvoiceSync.progressSnapshot && this.salesInvoiceSync.progressSnapshot.items) {
                return this.salesInvoiceSync.progressSnapshot.items.map(item => item.totalAmountOrdered).reduce((prev: number, curr: number) => prev + curr, 0) - this.salesInvoiceSync.progressSnapshot.totalAmountOrdered
            }
            return 0
        }
        get totalAmountExecutedDiffByItems() {
            if(this.salesInvoiceSync.progressSnapshot && this.salesInvoiceSync.progressSnapshot.items) {
                return this.salesInvoiceSync.progressSnapshot.items.map(item => item.totalAmountExecuted).reduce((prev: number, curr: number) => prev + curr, 0) - this.salesInvoiceSync.progressSnapshot.totalAmountExecuted
            }
            return 0
        }

        // watchers
        @Watch('state.openExpansionPanels')
        onWatchChangeOpenExpansionPanels(idx: number[]) {
            const isCreate = this.salesInvoiceSync && !this.salesInvoiceSync.id
            if(!isCreate) {
                localStorage.salesInvoices = JSON.stringify({openExpansionPanels: this.state.openExpansionPanels})
            }
        }

        // lifecylce
        mounted() {
            if (localStorage.salesInvoices) {
                const localStoragePurchaseInvoices = JSON.parse(localStorage.salesInvoices)
                const isCreate = this.salesInvoiceSync && !this.salesInvoiceSync.id
                this.state = {...this.state, 
                    // openExpansionPanels: isCreate ? [0,1,2,3] : (localStoragePurchaseInvoices.openExpansionPanels ? localStoragePurchaseInvoices.openExpansionPanels : [] as number[])
                    openExpansionPanels: isCreate ? [0,1,2,3] : [0,1,2,3]
                }
            }
        }
    }
    

