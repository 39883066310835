import { render, staticRenderFns } from "./accounting-ledger-update-modal.component.vue?vue&type=template&id=5adc339a&scoped=true"
import script from "./accounting-ledger-update-modal.component.vue?vue&type=script&lang=ts"
export * from "./accounting-ledger-update-modal.component.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5adc339a",
  null
  
)

export default component.exports