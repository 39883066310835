

	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { GetAddressMixin } from '@/mixins/get-address-mixin.component';
	import { PropType } from 'vue';
	import { Supplier } from '@/modules/entities/types/entities';
	import _ from 'lodash';

	@Component({
		mixins: [GetAddressMixin]
	})
	export default class SupplierBranchItemComponent extends Vue {
		@Prop({ type: Object as PropType<Supplier> }) companyLocation!: Supplier;

		getTeleinformation(supplier: Supplier, field: string): string {
			return _.get(supplier.teleinformations[0], field);
		}
	}
