import {WorkDay} from '@/modules/calendar/interfaces/project/workDay';
import {Quote} from '@/modules/calendar/interfaces/project/quote';
import {HolidayType} from '../store/modules/holidays/types/HolidaysState';

export interface Employee<WORKDAY = WorkDay> {
  id: number | null;
  firstName: string;
  lastName: string;
  wages: EmployeeWage[];
  currentWage: EmployeeWage | null;
  fullName?: string;
  timesheets?: Timesheet[] | null;
  workDays?: WORKDAY[] | null;
  absencePeriods?: AbsencePeriod[] | null;
  absences?: Absence[] | null;
}

export class EmployeeEntity implements Employee {
  id = null;
  firstName = '';
  lastName = '';
  wages = [];
  readonly currentWage = null;

  constructor(data: Partial<Employee> = {}) {
    Object.assign(this, data);
  }
}

export interface EmployeeWage {
  id: number | null;
  hourlyRate: number;
  avgMaterialCost: number;
  startDate: string;
  endDate: string;
}

export class EmployeeWageEntity implements EmployeeWage {
  id = null;
  hourlyRate = 0;
  avgMaterialCost = 0;
  startDate = '';
  endDate = '';

  constructor(data: Partial<EmployeeWage> = {}) {
    Object.assign(this, data);
  }
}

export interface TeamMember2 {
  id: number | undefined;
  start: string;
  end: string;
  readonly isActive?: boolean;
  readonly isFuture?: boolean;
  employee?: Employee | null;
}

export class TeamMember2Entity implements TeamMember2 {
  id = undefined;
  start = '';
  end = '';
  readonly isActive = false;
  readonly isFuture = false;
  employee = null;

  constructor(data: Partial<TeamMember2> = {}) {
    Object.assign(this, data);
  }
}

export interface Team {
  id: number;
  name: string;
  abbreviation: string;
  contactId?: number;
  workDays?: WorkDay[];
  teamMembers?: TeamMember2[];
  type?: string;
}

export interface Division {
  id: number;
  name: string;
  quotes?: Quote[];
  teams?: Team[];
  workDays?: WorkDay[];
  type?: string;
}

export interface DivisionDetail {
  id?: number;
  name?: string;
  teams?: Team[];
}

export interface LogItem {
  id: number;
  causerId: number;
  causerType: string;
  subjectId: number;
  subjectType: string;
  logName: string;
  properties?: any;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface Tax {
  id: number;
  name: string;
  label: string;
  color: string;
  sequence: number;
  percentage: number;
  forSales: boolean;
  forPurchases: boolean;
}

export interface TimesheetEntry {
  id: number | null;
  day: string | null;
  duration?: number;
  durationExtra?: number;
  comment?: string | null;
  timesheet?: Timesheet | null;
  workDay?: WorkDay | null;
  wage?: EmployeeWage | null;
  totalCost?: number;
}

export class TimesheetEntryEntity implements TimesheetEntry {
  id = -1;
  day = '';
  duration = 0;
  durationExtra = 0;
  comment = null;
  timesheet = null;
  workDay = null;

  constructor(data: Partial<TimesheetEntry> = {}) {
    Object.assign(this, data);
  }
}

export interface Timesheet {
  id: number | null;
  startDate: string;
  locked: boolean;
  lockedAt: string | null;
  employee: Employee | null;
  timesheetEntries?: TimesheetEntry[];
}

export interface Absence {
  id: number | null;
  day: string | null;
  duration?: number;
  comment: string | null;
  employee: Employee | null;
  holidayType: HolidayType | null;
}

export class AbsenceEntity implements Absence {
  id = -1;
  day = null;
  duration = 0;
  comment = null;
  employee = null;
  holidayType = null;

  constructor(data: Partial<Absence> = {}) {
    Object.assign(this, data);
  }
}

export interface AbsencePeriod {
  id: number | null;
  startDate: string | null;
  endDate: string | null;
  duration?: number;
  comment: string | null;
  employee: Employee | null;
  holidayType: HolidayType | null;
  absences?: Absence[] | null;
}

export class AbsencePeriodEntity implements AbsencePeriod {
  id = -1;
  startDate = null;
  endDate = null;
  duration = 0;
  comment = null;
  employee = null;
  holidayType = null;
  absences = [];

  constructor(data: Partial<AbsencePeriod> = {}) {
    Object.assign(this, data);
  }
}
