import { ReportState } from '@/modules/reports/store/types/ReportState';
import {GetterTree} from 'vuex';
import {RootState} from '@/core/types/RootState';
import {Getters} from '@/modules/reports/store/types/StoreTypes';
import {i18n} from '@/main';

export const getters: GetterTree<ReportState, RootState> = {
    
    [Getters.GET_IS_LOADING]: (state: ReportState) => {
        return state.isLoadingGrossMarginReport || state.isLoadingContractorBranchesReport;
    },

    [Getters.GET_USERS]: (state: ReportState) => {
        return state.users;
    },

    [Getters.GET_PROJECT_STATUSES]: (state: ReportState) => {
        return state.projectStatuses;
    },

    [Getters.GET_PROJECT_STATUSES_TRANSLATED]: (state: ReportState) => {
        return state.projectStatuses.map(projectStatus => {
            return {...projectStatus,
                label: i18n.t('projects.statuses.'+projectStatus.name).toString(),
            }
        });
    },

    [Getters.GET_GROSS_MARGIN_FILTERSTATE]: (state: ReportState) => {
        return state.grossMarginFilterState;
    },

    [Getters.GET_SEARCH_PROJECTS]: (state: ReportState) => {
        return state.searchProjects;
    },
    [Getters.GET_SEARCH_SUPPLIERS]: (state: ReportState) => {
        return state.searchSuppliers;
    },
    [Getters.GET_SEARCH_CONTRACTOR_BRANCHES]: (state: ReportState) => {
        return state.contractorBranches;
      },

    [Getters.GET_GROSS_MARGIN_REPORT]: (state: ReportState) => {
        return state.grossMarginReport;
    },

    [Getters.GET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT]: (state: ReportState) => {
        return state.projectsContractorBranchesReport;
    },

    [Getters.GET_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT]: (state: ReportState) => {
        return state.filteredProjectsContractorBranchesReport;
    },

    [Getters.GET_SHOW_PROJECTS_GROSS_MARGIN_REPORT]: (state: ReportState) => {
        return state.showProjectsGrossMarginReport;
    },

    [Getters.GET_SHOW_PROJECTS_CONTRACTOR_BRANCHES_OVERVIEW_REPORT]: (state: ReportState) => {
        return state.showProjectsContractorBranchesPurchaseReport;
    },

    [Getters.GET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC]: (state: ReportState) => {
        return state.projectsContractorBranchesPurchaseReportMetric;
    },
}