import { render, staticRenderFns } from "./sales-invoice-related-list.component.vue?vue&type=template&id=68755c06&scoped=true"
import script from "./sales-invoice-related-list.component.vue?vue&type=script&lang=ts"
export * from "./sales-invoice-related-list.component.vue?vue&type=script&lang=ts"
import style0 from "./sales-invoice-related-list.component.vue?vue&type=style&index=0&id=68755c06&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68755c06",
  null
  
)

export default component.exports