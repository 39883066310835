
// import Vue from 'vue'
import Vue, { computed, defineComponent, PropType } from 'vue'
import { cloneDeep } from 'lodash'
import { BankTransactionEntity, Payment, PaymentEntity, TransactionType } from '../types/entities'

export default defineComponent({
  name: 'PaymentEditPopup',
  emits: ['update:total', 'update:type', 'update:bankTransaction:executedAt', 'update:comment', 'onClickSave'],
  components: {},
  props: {
    payment: {
      type: Object as PropType<Payment>,
      default: () =>
        new PaymentEntity({
          id: -1,
          currency: 'EUR',
          total: 0,
          bankTransaction: new BankTransactionEntity({
            id: -1,
            currency: 'EUR',
            total: 0,
            executedAt: Date.now().toString(),
          }),
          invoice: null,
          comment: null,
          type: TransactionType.DEBIT,
        }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: Object as PropType<any>,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    // computed
    const paymentCopy = computed(() => cloneDeep(props.payment))
    const showExecutedAtDate = computed((): string => {
      if (paymentCopy.value && paymentCopy.value.bankTransaction && paymentCopy.value.bankTransaction.executedAt) {
        return Vue.prototype.$formatDate(paymentCopy.value.bankTransaction.executedAt)
      }
      return ''
    })
    return {
      paymentCopy,
      showExecutedAtDate,
    }
  },
})
