

	import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from "vuex-class";
	import { debounce } from "lodash";

	import { ProjectStatus, ProjectUser } from "@/modules/projects/types/entities";
	import { Actions } from "@/modules/projects/store/types/StoreTypes";
	import { PropType } from 'vue/types/options';
	import { ProjectStatusMixin } from '@/mixins/project-status-mixin.component';
	import { ProjectStateFilters } from '@/modules/projects/store/types/ProjectState';

	const projects = namespace("projects");

	@Component({
		mixins: [ProjectStatusMixin]
	})
	export default class ProjectsSideMenuComponent extends Vue {
		@projects.Action(Actions.SET_PROJECTS_FILTER_SIDEBAR_OPEN) setSideBarOpen!: (payload: boolean) => void;
		@projects.Action(Actions.SET_PROJECTS_FILTER_PROJECT_STATUS) setProjectStatusFilter!: (payload: number) => void;
		@projects.Action(Actions.SET_PROJECTS_FILTER_PROJECT_LEADER) setProjectLeaderFilter!: (payload: number) => void;
		@projects.Action(Actions.SET_PROJECTS_FILTER_ESTIMATOR) setEstimatorFilter!: (payload: number) => void;
		@projects.Action(Actions.SET_PROJECTS_FILTER_DESIGNER) setDesignerFilter!: (payload: number) => void;
		@projects.Action(Actions.SET_PROJECTS_FILTER_DRAWER) setDrawerFilter!: (payload: number) => void;
		@projects.Action(Actions.SET_PROJECTS_FILTER_CLEAR) setClearFilters!: () => void;
		@projects.Action(Actions.TOGGLE_IS_FILTER_TYPE_ACTIVE) toggleIsFilterTypeActive!: (payload: string) => void;
		@projects.Action(Actions.TOGGLE_FILTER_SHOW_MORE_LEADERS) toggleShowMoreLeadersFilter!: () => void;
		@projects.Action(Actions.TOGGLE_FILTER_SHOW_MORE_STATUSES) toggleShowMoreStatusesFilter!: () => void;

		@Prop() projectsFilters!: ProjectStateFilters;
		@Prop() filteredProjectStatuses!: ProjectStatus[];
		@Prop({ type: Array as PropType<ProjectUser[]>, default: [] }) users!: ProjectUser[];

		filteredLeaders: ProjectUser[] = [];
		filteredEstimators: ProjectUser[] = [];
		filteredDesigners: ProjectUser[] = [];
		filteredDrawers: ProjectUser[] = [];
		projectStatusSearchValue: string = "";
		projectLeaderSearchValue: string = "";
		designersSearchValue: string = "";
		estimatorsSearchValue: string = "";
		drawersSearchValue: string = "";
		projectStatusesFilterExpanded: boolean = false;
		projectLeadersFilterExpanded: boolean = false;
		otherCoworkersFilterExpanded: boolean = false;
		estimatorsFilterExpanded: boolean = false;
		designersFilterExpanded: boolean = false;
		drawersFilterExpanded: boolean = false;

		isNumberInNumericList(array: number[], item: number): boolean {
			return array.some(arrayNumber => arrayNumber === item);
		}

		toggleSideBarOpen(): void {
			this.setSideBarOpen(!this.projectsFilters.sideBarOpen);
		}

		clearFilters(): void {
			this.setClearFilters();
		}

		getCheckboxColour(filterGroup: string) {
			return "red";
		}

		onGroupDisable(filterGroup: string) {
			switch (filterGroup) {
				case "projectStatuses":
					this.projectsFilters.projectStatusFilterActive ? this.projectStatusesFilterExpanded = false : this.projectStatusesFilterExpanded = true;
					break;
				case "projectLeaders":
					this.projectsFilters.projectLeaderFilterActive ? this.projectLeadersFilterExpanded = false : this.projectLeadersFilterExpanded = true;
					break;
				case "otherCoworkers":
					this.projectsFilters.otherCoworkersFilterActive ? this.otherCoworkersFilterExpanded = false : this.otherCoworkersFilterExpanded = true;
					break;
				default:
					break;
			}
			this.toggleIsFilterTypeActive(filterGroup);
		}

		get anyOtherCoworkersSelected() {
			return this.projectsFilters.selectedEstimatorsIds.length ||
				this.projectsFilters.selectedDesignersIds.length ||
				this.projectsFilters.selectedDrawersIds.length;
		}

		get projectsFiltersAnyDirty() {
			return this.projectsFilters.mainQuery ||
				this.projectsFilters.nameQuery ||
				this.projectsFilters.selectedProjectStatusIds.length ||
				this.projectsFilters.selectedProjectLeadersIds.length ||
				this.anyOtherCoworkersSelected;
		}

		get filteredProjectStatusesList(): ProjectStatus[] {
			return this.filteredProjectStatuses.filter(projectStatus => {
				if (this.projectStatusSearchValue !== "") {
					return (this.$t(`projects.statuses.${ projectStatus.name }`) as string).toLowerCase().includes(this.projectStatusSearchValue.toLowerCase());
				}
				if (projectStatus.id === 7) return;
				return projectStatus;
			});
		}

		get filteredProjectLeadersList(): ProjectUser[] {
			return this.filteredLeaders.filter(user => {
				if (this.projectLeaderSearchValue !== "") {
					return user.alias.toUpperCase().includes(this.projectLeaderSearchValue.toUpperCase());
				}
				return user;
			});
		}

		get filteredDesignersList(): ProjectUser[] {
			return this.filteredDesigners.filter(designer => {
				if (this.designersSearchValue !== "") {
					return designer.alias.toUpperCase().includes(this.designersSearchValue.toUpperCase());
				}
				return designer;
			});
		}

		get filteredEstimatorsList(): ProjectUser[] {
			return this.filteredEstimators.filter(estimator => {
				if (this.estimatorsSearchValue !== "") {
					return estimator.alias.toUpperCase().includes(this.estimatorsSearchValue.toUpperCase());
				}
				return estimator;
			});
		}

		get filteredDrawersList(): ProjectUser[] {
			return this.filteredDrawers.filter(drawer => {
				if (this.drawersSearchValue !== "") {
					return drawer.alias.toUpperCase().includes(this.drawersSearchValue.toUpperCase());
				}
				return drawer;
			});
		}

		@Watch('users', { immediate: true })
		onUsersChange(newValue: ProjectUser[]) {
			this.filteredLeaders = [...newValue.filter(user => user.isActive)];
			this.filteredEstimators = [...newValue.filter(user => user.isActive)];
			this.filteredDesigners = [...newValue.filter(user => user.isActive)];
			this.filteredDrawers = [...newValue.filter(user => user.isActive)];
		}

		@Emit("clearFilters")
		onEmitClearFilters(): void {
			this.clearFilters();
		}
	}
