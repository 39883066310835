export interface FilterInterface {
    name: string | null;
    key: string | null;
    value: any | null;
    active: boolean;
    group: string | null;
    prefix: string | null;
    separator: string;
    type: filterType;
    clear: Function;
    hasValues: Function;
    buildUrlParameterKey: Function;
    buildUrlParameterValue: Function;
    isBackend:Function;
}

export enum filterType {
    FRONTEND = 'FRONTEND',
    BACKEND = 'BACKEND'
}

export class Filter<T> implements FilterInterface {
    name: string | null;
    key: string | null;
    value: T[];
    active: boolean;
    group: string | null;
    prefix: string | null;
    separator: string;
    type: filterType

    constructor(name: string, key: string, value: any, active: boolean, group: string, prefix: string|null = 'filter', separator = ',', type: filterType = filterType.BACKEND) {
        this.name = name;
        this.key = key;
        this.value = value;
        this.active = active;
        this.group = group;
        this.prefix = prefix;
        this.separator = separator;
        this.type = type;
    }

    clear = (): void => {
        this.value = [] as T[];
    };

    hasValues = (): boolean => {
        if(this.value && this.value.length > 0) return true;
        return false;
    };

    buildUrlParameterKey = (): string => {
        if(!this.key) throw 'No Filter key provided';
        if(this.prefix) return `${this.prefix}[${this.key}]`;
        return this.key;
    }

    buildUrlParameterValue = (): string => {
        if(this.value) return this.value.join();
        return '';
    }

    isBackend = (): boolean => {
        if(this.type === filterType.BACKEND) return true;
        return false;
    };
}

export interface FilterState {
	// interaction
	sideBarOpen?: boolean;
	// filters
	filters: { [key: string]: Filter<any> };
}


