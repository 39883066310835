import Vue from 'vue';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ProjectsState, ProjectStateFilters } from '@/modules/projects/store/types/ProjectState';
import { RootState } from '@/core/types/RootState';
import { Actions, Getters, Mutations } from '@/modules/projects/store/types/StoreTypes';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import {
  ContractorParty,
  Project,
  ProjectContractorBranch,
  ProjectStatus,
  ProjectUser,
  ThirdParty
} from '@/modules/projects/types/entities';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { City, Client, Comment, ProjectClient } from '@/modules/entities/types/entities';
import { ProjectIntersectionData } from '@/modules/workflow/types/entities';
import { ProjectsService } from '@/services/projects.service';
import dayjs from 'dayjs';
import { CommentsService } from '@/services/comments.service';
import { SocialSecurityRegistration } from '../types/social-security-registrations';
import { Quote } from '@/modules/calendar/interfaces/project/quote';
import CalendarService from '@/services/calendar.service';
import QuotesService from '@/services/quotes.service';
import { PlannerPeriod } from '@/modules/calendar/interfaces/project/plannerPeriod';
import { appMessageStore } from '@/core/store/app-messages.store';
import { AppMessageType } from '@/core/components/app-message/entities';
import PlannerPeriodsService from "@/services/planner-periods.service";

const projectsService = new ProjectsService();
const calendarService = new CalendarService();
const quotesService = new QuotesService();
const commentsService = new CommentsService();

const getters: GetterTree<ProjectsState, RootState> = {
  [Getters.GET_DETACHED_CONTRACTOR_STATE]: (state: ProjectsState) => {
    return state.detachedContractorState;
  },
  [Getters.GET_PROJECTS]: (state: ProjectsState) => {
    return state.projects;
  },
  [Getters.GET_PROJECT_DETAILS]: (state: ProjectsState) => {
    return state.inspectedProject;
  },
  [Getters.GET_PROJECT_STATUSES]: (state: ProjectsState) => {
    return state.projectStatuses;
  },
  [Getters.GET_USERS]: (state: ProjectsState) => {
    return state.users;
  },
  [Getters.GET_LOADING]: (state: ProjectsState) => {
    return state.isLoading;
  },
  [Getters.GET_PROJECTS_FILTERS]: (state: ProjectsState) => {
    return state.filters;
  },
  [Getters.GET_VALIDATION_ERRORS]: (state: ProjectsState) => {
    return state.validationErrors;
  },
  [Getters.GET_SOCIAL_SECURITY_REGISTRATIONS]: (state: ProjectsState) => {
    return state.socialSecurityRegistrations;
  },
};

const actions: ActionTree<ProjectsState, RootState> = {
  [Actions.ATTACH_NEW_QUOTE_TO_PROJECT]: async ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: { projectId: number; contractorTypeIds: number | number[]; withOffer: boolean }) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res: AxiosResponse<JsonResource<Quote>> = await quotesService.createNewQuote(payload.projectId, payload.contractorTypeIds, payload.withOffer);

      if (res.status === 200) {
        await dispatch(Actions.FETCH_PROJECT_BY_ID, state.inspectedProject.id);
      }
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.FETCH_NEXT_PAGE]: async ({ commit, state }: ActionContext<ProjectsState, RootState>) => {
    if (state.paginationDetails && state.paginationDetails.next) {
      commit(Mutations.MUTATE_LOADING_STATE, true);
      const urlSearchParams = new URLSearchParams();
      if (state.filters.fetchOngoing !== null) {
        urlSearchParams.set('ongoing', state.filters.fetchOngoing.toString());
      }
      if (state.filters.mainQuery !== '' && state.filters.mainQuery !== null && !state.filters.mainQuery.startsWith(' ')) {
        urlSearchParams.set('query', state.filters.mainQuery.toString());
      }
      if (state.filters.nameQuery !== '' && state.filters.nameQuery !== null && !state.filters.nameQuery.startsWith(' ')) {
        urlSearchParams.set('name', state.filters.nameQuery.toString());
      }
      if (state.filters.selectedProjectLeadersIds.length && state.filters.projectLeaderFilterActive) {
        urlSearchParams.set('leaders', state.filters.selectedProjectLeadersIds.join());
      }
      if (state.filters.selectedProjectStatusIds.length && state.filters.projectStatusFilterActive) {
        urlSearchParams.set('statuses', state.filters.selectedProjectStatusIds.join());
      }
      if (state.filters.otherCoworkersFilterActive) {
        if (state.filters.selectedEstimatorsIds.length) {
          urlSearchParams.set('estimators', state.filters.selectedEstimatorsIds.join());
        }
        if (state.filters.selectedDrawersIds.length) {
          urlSearchParams.set('drawers', state.filters.selectedDrawersIds.join());
        }
        if (state.filters.selectedDesignersIds.length) {
          urlSearchParams.set('designers', state.filters.selectedDesignersIds.join());
        }
      }

      try {
        const res = await projectsService.getAllProjects(`${state.paginationDetails.next}&${urlSearchParams.toString()}`);
        commit(Mutations.MUTATE_PUSH_NEXT_PAGE, res.data);
      } finally {
        commit(Mutations.MUTATE_LOADING_STATE, false);
      }
    }
  },
  [Actions.CREATE_PROJECT]: async ({ commit, dispatch }: ActionContext<ProjectsState, RootState>, payload: Project): Promise<JsonResource<Project>> => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    return new Promise(async (resolve, reject) => {
      try {
        const result = await projectsService.createNewProject(payload);
        resolve(result.data);
      } catch (err: any) {
        throw err;
      } finally {
        commit(Mutations.MUTATE_LOADING_STATE, false);
      }
    });
  },
  [Actions.EDIT_PROJECT_BY_ID]: async ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: Project) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const result = await projectsService.editProjectById(payload);
      const projects = state.projects.map(project => {
        return project.id === payload.id ? result.data.data : project;
      });
      commit(Mutations.MUTATE_PROJECTS_DATA, projects);
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.ADD_THIRD_PARTY]: async ({ state, commit }: ActionContext<ProjectsState, RootState>, payload: { projectId: number; supplierTypeId: number }): Promise<any> => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    return new Promise(async (resolve, reject) => {
      try {
        const result = await projectsService.attachThirdPartyToProject(payload.projectId, payload.supplierTypeId);
        commit(Mutations.MUTATE_INSPECTED_PROJECT, {
          ...state.inspectedProject,
          thirdParties: result.data.data.thirdParties,
        });
        resolve(result);
      } finally {
        commit(Mutations.MUTATE_LOADING_STATE, false);
      }
    });
  },
  [Actions.REMOVE_THIRD_PARTY]: async ({ state, commit }: ActionContext<ProjectsState, RootState>, payload: { projectId: number; pivotId: number }) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res = await projectsService.removeThirdPartyFromProject(payload.projectId, payload.pivotId);

      const mutatedThirdParties = state.inspectedProject.thirdParties.filter(thirdParty => {
        return thirdParty.pivotId !== payload.pivotId;
      });
      commit(Mutations.MUTATE_INSPECTED_PROJECT, {
        ...state.inspectedProject,
        thirdParties: mutatedThirdParties,
      });
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.EDIT_INSPECTED_PROJECT_BY_ID]: async ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: Project): Promise<JsonResource<Project>> => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    return new Promise<JsonResource<Project>>(async (resolve, reject) => {
      try {
        const result = await projectsService.editProjectById(payload);
        commit(Mutations.MUTATE_INSPECTED_PROJECT, result.data.data);
        resolve(result.data);
      } finally {
        commit(Mutations.MUTATE_LOADING_STATE, false);
      }
    });
  },
  [Actions.FILTER_PROJECT_CONTACTS]: ({ commit, state }: ActionContext<ProjectsState, RootState>, contactId: number) => {
    const project = {
      ...state.inspectedProject,
      contacts: state.inspectedProject.contacts && state.inspectedProject.contacts.filter(x => x.id !== contactId),
    };
    commit(Mutations.MUTATE_INSPECTED_PROJECT, project);
  },
  [Actions.FETCH_PROJECT_BY_ID]: async ({ commit, dispatch }: ActionContext<ProjectsState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const result = await projectsService.getProjectById(Number.parseInt(payload));
      commit(Mutations.MUTATE_INSPECTED_PROJECT, result.data.data);
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.TOGGLE_ASSOCIATION_EXISTING_SUPPLIER_TO_THIRD_PARTY]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: { projectId: number; thirdPartyPivotId: number; supplierTypeId: number; supplierId: number | null }) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const result = await projectsService.toggleAssociationSupplierToThirdParty(payload.projectId, payload.thirdPartyPivotId, { supplierId: payload.supplierId, supplierTypeId: payload.supplierTypeId });
      commit(Mutations.MUTATE_INSPECTED_PROJECT, result.data.data);
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.TOGGLE_THIRD_PARTY_ASSIGNMENT]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: { projectId: number; thirdPartyTypeId: number; thirdParty: ThirdParty }) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res = await projectsService.toggleAssociationThirdParty(payload.projectId, payload.thirdPartyTypeId, payload.thirdParty);

      commit(Mutations.MUTATE_INSPECTED_PROJECT, {
        ...state.inspectedProject,
        thirdParties: res.data.data.thirdParties,
      });
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.TOGGLE_ASSOCIATION_EXISTING_CLIENT_TO_PROJECT]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: { projectId: number; clientId: number }) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res = await projectsService.toggleAssociationClientToProject(payload.projectId, payload.clientId);

      commit(Mutations.MUTATE_INSPECTED_PROJECT, res.data.data);
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_PROJECT]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: { projectId: number; contactId: number }) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res = await projectsService.toggleAssociationContactToProject(payload.projectId, payload.contactId);
      commit(Mutations.MUTATE_INSPECTED_PROJECT, res.data.data);
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.FETCH_ALL_USERS]: async ({ commit }: ActionContext<ProjectsState, RootState>) => {
    const res = await Vue.prototype.$http.get(`/api/v1/settings/users`);

    commit(Mutations.MUTATE_USERS_DATA, res.data.data);
  },
  [Actions.FETCH_PROJECTS_BY_FILTERS]: async ({ state, commit }: ActionContext<ProjectsState, RootState>) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    const url = `/api/v1/projects`;
    const urlSearchParams = new URLSearchParams();
    if (state.filters.fetchOngoing !== null) {
      urlSearchParams.set('ongoing', state.filters.fetchOngoing.toString());
    }
    if (state.filters.mainQuery !== '' && state.filters.mainQuery !== null && !state.filters.mainQuery.startsWith(' ')) {
      urlSearchParams.set('query', state.filters.mainQuery.toString());
    }
    if (state.filters.nameQuery !== '' && state.filters.nameQuery !== null && !state.filters.nameQuery.startsWith(' ')) {
      urlSearchParams.set('name', state.filters.nameQuery.toString());
    }
    if (state.filters.selectedProjectLeadersIds.length && state.filters.projectLeaderFilterActive) {
      urlSearchParams.set('leaders', state.filters.selectedProjectLeadersIds.join());
    }
    if (state.filters.selectedProjectStatusIds.length && state.filters.projectStatusFilterActive) {
      urlSearchParams.set('statuses', state.filters.selectedProjectStatusIds.join());
    }
    if (state.filters.otherCoworkersFilterActive) {
      if (state.filters.selectedEstimatorsIds.length) {
        urlSearchParams.set('estimators', state.filters.selectedEstimatorsIds.join());
      }
      if (state.filters.selectedDrawersIds.length) {
        urlSearchParams.set('drawers', state.filters.selectedDrawersIds.join());
      }
      if (state.filters.selectedDesignersIds.length) {
        urlSearchParams.set('designers', state.filters.selectedDesignersIds.join());
      }
    }

    try {
      const res: AxiosResponse<Pagination<Project[]>> = await projectsService.getAllProjects(`${url}?${urlSearchParams.toString()}`);

      commit(Mutations.MUTATE_PROJECTS_DATA, res.data.data);
      commit(Mutations.MUTATE_PAGINATION_DETAILS, res.data);
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.DELETE_PROJECT_BY_ID]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, projectId: number) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      await projectsService.deleteProjectById(projectId);
      const projects = state.projects.filter(project => project.id !== projectId);
      commit(Mutations.MUTATE_PROJECTS_DATA, projects);
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.ARCHIVE_PROJECT_BY_ID]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, projectId: number): Promise<any> => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    return new Promise<void>(async (resolve, reject) => {
      try {
        const result = projectsService.archiveProjectById(projectId);
        if (state.inspectedProject.id > 0) {
          commit(Mutations.MUTATE_INSPECTED_PROJECT, {
            ...state.inspectedProject,
            archivedAt: dayjs().format('YYYY-MM-DD'),
          });
        } else {
          const projects = state.projects.filter(project => project.id !== projectId);
          commit(Mutations.MUTATE_PROJECTS_DATA, projects);
        }
        resolve();
      } finally {
        commit(Mutations.MUTATE_LOADING_STATE, false);
      }
    });
  },
  [Actions.UN_ARCHIVE_PROJECT_BY_ID]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, projectId: number): Promise<any> => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    const result = await projectsService.unarchiveProjectById(projectId);
    return new Promise((resolve, reject) => {
      try {
        if (state.inspectedProject.id > 0) {
          commit(Mutations.MUTATE_INSPECTED_PROJECT, {
            ...state.inspectedProject,
            archivedAt: null,
          });
        } else {
          const projects = state.projects.filter(project => project.id !== projectId);
          commit(Mutations.MUTATE_PROJECTS_DATA, projects);
        }
        Promise.resolve();
      } catch (err: any) {
        reject(err);
        throw err;
      } finally {
        commit(Mutations.MUTATE_LOADING_STATE, false);
      }
    });
  },
  [Actions.DELETE_INSPECTED_PROJECT_BY_ID]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, projectId: number) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      await projectsService.deleteProjectById(projectId);
      commit(Mutations.MUTATE_INSPECTED_PROJECT);
    }
    // catch not necessary, so you can catch it in the caller method
    // catch (err: any) {
    // 	console.warn(err)
    // 	throw err
    // }
    finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.DESTROY_PROJECT_DETAILS]: ({ commit }: ActionContext<ProjectsState, RootState>) => {
    commit(Mutations.MUTATE_INSPECTED_PROJECT);
  },
  [Actions.FETCH_PROJECT_STATUSES]: async ({ commit }: ActionContext<ProjectsState, RootState>) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res = await projectsService.getAllProjectStatuses();
      commit(Mutations.MUTATE_PROJECTS_STATUS_DATA, res.data.data);
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.SAVE_COMMENT]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: Comment) => {
    const res = await Vue.prototype.$http.post(`/api/v1/comments`, payload);

    if (res.status === 200 || res.status === 201) {
      let comments: Comment[] = [];

      if (state.inspectedProject) {
        if (state.inspectedProject.comments) {
          comments = [
            ...state.inspectedProject.comments,
          ];
        }
        comments = [
          ...comments,
          res.data.data,
        ];
        const inspectedProject = {
          ...state.inspectedProject,
          comments,
        };
        commit(Mutations.MUTATE_INSPECTED_PROJECT, inspectedProject);
      }
    }
  },
  [Actions.SAVE_PLANNER_PERIOD]: async ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: { quoteId: number; plannerPeriod: PlannerPeriod }) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      if(!payload.quoteId) return;
      let resp;

      if(payload.plannerPeriod?.id > 0) {
        resp = await quotesService.editPlannerPeriodById(payload.quoteId, payload.plannerPeriod);
      } else {
        throw new Error('Create upon save is not implemented yet to save a planner period.');
      }

      const mutatedProject = {
        ...state.inspectedProject,
        orders: state.inspectedProject.orders.map((order: Quote) => {
          if (!order.plannerPeriods) return order;
          return {
            ...order,
            plannerPeriods: order.plannerPeriods.map((period: PlannerPeriod) => {
              if (period.id !== payload.plannerPeriod.id) return period;
              return { ...period };
            }),
          };
        }),
      };

      commit(Mutations.MUTATE_INSPECTED_PROJECT, mutatedProject);
    } catch (err: any) {
      return err;
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.SAVE_PLANNER_PERIOD_COMMENT]: async ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: { plannerPeriodId: number; comment: Comment}) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);

    try {
      let res: AxiosResponse<JsonResource<Comment>> | undefined = undefined;
      if (payload.comment.id < 0 && payload.comment.body !== '') {
        res = await quotesService.createNewCommentForPlannerPeriod(payload.plannerPeriodId, payload.comment);

      } else if (payload.comment.id > 0 && payload.comment.body !== '') {
        res = await commentsService.editCommentById(payload.comment.id, payload.comment);
      }
      if (res) {
        const mutatedProject = {
          ...state.inspectedProject,
          orders: state.inspectedProject.orders.map((order: Quote) => {
            if (!order.plannerPeriods) return order;
            return {
              ...order,
              plannerPeriods: order.plannerPeriods.map((period: PlannerPeriod) => {
                if (period.id !== payload.plannerPeriodId) return period;
                if (!period.comments) return {
                  ...period,
                  comments: [res!.data.data],
                };
                return {
                  ...period,
                  comments: [
                    ...period.comments.filter(x => x.body === payload.comment.body),
										res!.data.data,
                  ],
                };
              }),
            };
          }),
        };
        commit(Mutations.MUTATE_INSPECTED_PROJECT, mutatedProject);
      } else {
        appMessageStore.actions.set({
          message: payload.comment.id < 0 ? 'common.provideTextToCreateComment' : 'common.cannotSaveEmptyComment',
          ttl: 3000,
          dismissed: false,
          type: AppMessageType.WARNING,
        });
      }
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.SAVE_CONTRACTOR_BRANCH]: async ({ commit, dispatch, state }: ActionContext<ProjectsState, RootState>, payload: {projectId: string; contractorBranch: ProjectContractorBranch}) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    // has id?
    const projectContracorBranchId = payload.contractorBranch && (payload.contractorBranch.id !== undefined && payload.contractorBranch.id) || undefined;
    let result: AxiosResponse<JsonResource<ProjectContractorBranch>> | undefined = undefined;
    let newProject;
    try {
      // create
      if(!projectContracorBranchId) {
        result = await projectsService.attachContractorBranchToProject(Number.parseInt(payload.projectId), payload.contractorBranch);
        // invoice data to be updated
        const updatedContractorBranch = result.data.data.id ? result.data.data : null;
        newProject = {
          ...state.inspectedProject,
          contractorBranches: [
            ...(state.inspectedProject.contractorBranches || []),
            updatedContractorBranch,
          ],
        }
      }else{
        result = await projectsService.editAttachedContractorBranchToProject(Number.parseInt(payload.projectId), payload.contractorBranch);
        if(state.inspectedProject && state.inspectedProject.contractorBranches && !!result) {
          const updatedContractorBranch = result.data.data;
          newProject = {
            ...state.inspectedProject,
            contractorBranches: state.inspectedProject.contractorBranches.map(branch => branch.id === updatedContractorBranch.id ? updatedContractorBranch : branch),
          };
        }
      }
      commit(Mutations.MUTATE_INSPECTED_PROJECT, newProject);
      // result
      return result;
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.DELETE_CONTRACTOR_BRANCH]: async ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: {projectId: string; contractorBranchId: string} ) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);

    try {
      const result = await projectsService.removeContractorBranchFromProject(Number.parseInt(payload.projectId), Number.parseInt(payload.contractorBranchId));
      let newProject;
      if(state.inspectedProject && state.inspectedProject.contractorBranches) {
        newProject = {
          ...state.inspectedProject,
          contractorBranches: state.inspectedProject.contractorBranches.filter(branch => branch.id !== Number.parseInt(payload.contractorBranchId)),
        };
      }
      commit(Mutations.MUTATE_INSPECTED_PROJECT, newProject);
    } catch (err: any) {
      // TODO: proper delete error message
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.EDIT_COMMENT]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: Comment) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res: AxiosResponse<JsonResource<Comment>> = await Vue.prototype.$http.put(`/api/v1/comments/${ payload.id }`, payload);

      if (state.inspectedProject.comments && state.inspectedProject.comments.length) {
        const updatedComments = state.inspectedProject.comments.map(x => x.id !== payload.id ? x : res.data.data);
        commit(Mutations.MUTATE_INSPECTED_PROJECT, {
          ...state.inspectedProject,
          comments: updatedComments,
        });
      }
    } finally { commit(Mutations.MUTATE_LOADING_STATE, false); }
  },
  [Actions.DELETE_COMMENT]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: number) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res = await Vue.prototype.$http.delete(`/api/v1/comments/${ payload }`);

      if (state.inspectedProject.comments && state.inspectedProject.comments.length) {
        const updatedComments = state.inspectedProject.comments.filter(x => x.id !== payload);
        const inspectedProject = {
          ...state.inspectedProject,
          comments: updatedComments,
        };
        commit(Mutations.MUTATE_INSPECTED_PROJECT, inspectedProject);
      }
    } finally { commit(Mutations.MUTATE_LOADING_STATE, false); }
  },
  [Actions.SET_PROJECTS_FILTER_PROJECT_STATUS]: ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: number) => {
    let mutatedProjectStatusesIds = [...state.filters.selectedProjectStatusIds];
    if (state.filters.selectedProjectStatusIds.some((id: number) => id === payload)) {
      if (payload === 9) {
        mutatedProjectStatusesIds = mutatedProjectStatusesIds.filter(id => id !== 7);
      }
      mutatedProjectStatusesIds = mutatedProjectStatusesIds.filter(id => id !== payload);
    } else {
      if (payload === 9) {
        mutatedProjectStatusesIds.push(7);
      }
      mutatedProjectStatusesIds.push(payload);
    }

    commit(Mutations.MUTATE_FILTERS_PROJECT_STATUS, mutatedProjectStatusesIds);
    dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
  },
  [Actions.SET_PROJECTS_FILTER_PROJECT_LEADER]: ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: number) => {
    let mutatedProjectLeadersIds = [...state.filters.selectedProjectLeadersIds];
    if (state.filters.selectedProjectLeadersIds.some((id: number) => id === payload)) {
      mutatedProjectLeadersIds = mutatedProjectLeadersIds.filter(id => id !== payload);
    } else {
      mutatedProjectLeadersIds.push(payload);
    }

    commit(Mutations.MUTATE_FILTERS_PROJECT_LEADER, mutatedProjectLeadersIds);
    dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
  },
  [Actions.SET_PROJECTS_FILTER_ESTIMATOR]: ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: number) => {
    let mutatedEstimatorsIds = [...state.filters.selectedEstimatorsIds];
    if (state.filters.selectedEstimatorsIds.some((id: number) => id === payload)) {
      mutatedEstimatorsIds = mutatedEstimatorsIds.filter(id => id !== payload);
    } else {
      mutatedEstimatorsIds.push(payload);
    }

    commit(Mutations.MUTATE_FILTERS_ESTIMATOR, mutatedEstimatorsIds);
    dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
  },
  [Actions.SET_PROJECTS_FILTER_DESIGNER]: ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: number) => {
    let mutatedDesignerIds = [...state.filters.selectedDesignersIds];
    if (state.filters.selectedDesignersIds.some((id: number) => id === payload)) {
      mutatedDesignerIds = mutatedDesignerIds.filter(id => id !== payload);
    } else {
      mutatedDesignerIds.push(payload);
    }

    commit(Mutations.MUTATE_FILTERS_DESIGNER, mutatedDesignerIds);
    dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
  },
  [Actions.SET_PROJECTS_FILTER_DRAWER]: ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: number) => {
    let mutatedDrawerIds = [...state.filters.selectedDrawersIds];
    if (state.filters.selectedDrawersIds.some((id: number) => id === payload)) {
      mutatedDrawerIds = mutatedDrawerIds.filter(id => id !== payload);
    } else {
      mutatedDrawerIds.push(payload);
    }

    commit(Mutations.MUTATE_FILTERS_DRAWER, mutatedDrawerIds);
    dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
  },
  [Actions.SET_PROJECTS_FILTER_CLEAR]: ({ commit, dispatch }: ActionContext<ProjectsState, RootState>) => {
    commit(Mutations.MUTATE_FILTERS_CLEAR);
    dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
  },
  [Actions.SET_PROJECTS_FILTER_MAIN_QUERY]: ({ commit, dispatch }: ActionContext<ProjectsState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_FILTERS_MAIN_QUERY, payload);
    dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
  },
  [Actions.SET_PROJECTS_FILTER_FETCH_ONGOING]: ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: boolean) => {
    if (payload !== state.filters.fetchOngoing) {
      commit(Mutations.MUTATE_FILTERS_FETCH_ONGOING, payload);
      dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
    }
  },
  [Actions.SET_PROJECTS_FILTER_SIDEBAR_OPEN]: ({ commit }: ActionContext<ProjectsState, RootState>, payload: boolean) => {
    commit(Mutations.MUTATE_FILTERS_SIDEBAR_OPEN, payload);
  },
  [Actions.SET_PROJECTS_FILTER_NAME_QUERY]: ({ commit, dispatch }: ActionContext<ProjectsState, RootState>, payload: string) => {
    commit(Mutations.MUTATE_FILTERS_NAME_QUERY, payload);
    dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
  },
  [Actions.TOGGLE_IS_FILTER_TYPE_ACTIVE]: ({ commit, dispatch }: ActionContext<ProjectsState, RootState>, payload: string) => {
    const updatedFilters = { ...state.filters };

    switch (payload) {
    case 'projectStatuses':
      updatedFilters.projectStatusFilterActive = !updatedFilters.projectStatusFilterActive;
      commit(Mutations.MUTATE_FILTERS, updatedFilters);
      if (updatedFilters.selectedProjectStatusIds.length) {
        dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
      }
      break;
    case 'projectLeaders':
      updatedFilters.projectLeaderFilterActive = !updatedFilters.projectLeaderFilterActive;
      commit(Mutations.MUTATE_FILTERS, updatedFilters);
      if (updatedFilters.selectedProjectLeadersIds.length) {
        dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
      }
      break;
    case 'otherCoworkers':
      updatedFilters.otherCoworkersFilterActive = !updatedFilters.otherCoworkersFilterActive;
      commit(Mutations.MUTATE_FILTERS, updatedFilters);
      if (updatedFilters.selectedEstimatorsIds.length || updatedFilters.selectedDesignersIds.length || updatedFilters.selectedDrawersIds.length) {
        dispatch(Actions.FETCH_PROJECTS_BY_FILTERS);
      }
      break;
    default:
      break;
    }
  },
  [Actions.TOGGLE_FILTER_SHOW_MORE_LEADERS]: ({ commit, state }: ActionContext<ProjectsState, RootState>) => {
    const updatedShowMoreLeadersValue = !state.filters.showMoreProjectLeaders;
    commit(Mutations.MUTATE_FILTERS_SHOW_MORE_LEADERS, updatedShowMoreLeadersValue);
  },
  [Actions.TOGGLE_FILTER_SHOW_MORE_STATUSES]: ({ commit, state }: ActionContext<ProjectsState, RootState>) => {
    commit(Mutations.MUTATE_FILTERS_SHOW_MORE_STATUSES, !state.filters.showMoreProjectStatuses);
  },
  [Actions.SET_PROJECT_WORKFLOW_TASK]: async (
    { state, commit }: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; workflowTaskId: number; taskStatusId?: number; futureStartDate?: Date }
  ) => {
    const res = await Vue.prototype.$http.put(`/api/v1/workflow/${ payload.projectId }/tasks/${ payload.workflowTaskId }`, {
      taskStatusId: payload.taskStatusId ? payload.taskStatusId : null,
      futureStartDate: payload.futureStartDate ? payload.futureStartDate : null,
    });
    if (res.status === 200) {
      commit(Mutations.MUTATE_INSPECTED_PROJECT, {
        ...state.inspectedProject,
        intersectionData: res.data.data.intersectionData,
      });
    }
  },
  [Actions.SAVE_PROJECT_WORKFLOW_TASK_NOTE]: async (
    { commit, state }: ActionContext<ProjectsState, RootState>,
    payload: { comment: Comment; projectId: number; taskId: number }
  ) => {
    /* If the task has not yet been associated with project, then no intersection
		* database record is present, hence we need to create it first, before associating comment with it */
    if (payload.comment.commentableId === 0) {
      const res = await Vue.prototype.$http.post(`/api/v1/workflow/${ payload.projectId }/tasks`, { taskId: payload.taskId });
      if (res.status === 200) {
        const intersectionDataResponse = res.data.data.intersectionData.find((x: ProjectIntersectionData) => x.projectId === payload.projectId && x.workflowTaskId === payload.taskId);
        payload.comment.commentableId = intersectionDataResponse.intersectionId;
        const response = await Vue.prototype.$http.post(`/api/v1/comments`, payload.comment);
        if (response.status === 201 && state.inspectedProject) {
          if (!state.inspectedProject.intersectionData) {
            commit(Mutations.MUTATE_INSPECTED_PROJECT, {
              ...state.inspectedProject,
              intersectionData: [intersectionDataResponse],
            });
            return;
          }

          const intersection = [...state.inspectedProject.intersectionData];

          commit(Mutations.MUTATE_INSPECTED_PROJECT, {
            ...state.inspectedProject,
            intersectionData: [
              ...intersection,
              {
                ...intersectionDataResponse,
                comments: [response.data.data],
              },
            ],
          });
        }
      }
      /* Intersection data is present, but we are adding yet another comment */
    } else if (payload.comment.id === 0) {
      const res = await Vue.prototype.$http.post(`/api/v1/comments`, payload.comment);
      if (res.status === 201 && state.inspectedProject) {
        const intersectionData = (state.inspectedProject.intersectionData || []).map(intersection => {
          if (intersection.intersectionId !== payload.comment.commentableId) {
            return intersection;
          }
          let comments: Comment[] = [];
          if (intersection.comments) {
            comments = [
              ...intersection.comments,
            ];
          }
          comments = [
            res.data.data,
            ...comments,
          ];
          return {
            ...intersection,
            comments: comments,
          };
        });

        commit(Mutations.MUTATE_INSPECTED_PROJECT, {
          ...state.inspectedProject,
          intersectionData,
        });
      }
      /* Existing comment edition */
    } else {
      const res = await Vue.prototype.$http.put(`/api/v1/comments/${ payload.comment.id }`, payload.comment);
      if (res.status === 200 && state.inspectedProject) {
        const intersectionData = (state.inspectedProject.intersectionData || []).map(intersection => {
          if (intersection.intersectionId !== payload.comment.commentableId) {
            return intersection;
          }
          return {
            ...intersection,
            comments: intersection.comments.map(comment => {
              if (comment.id !== res.data.data.id) {
                return comment;
              }
              return res.data.data;
            }),
          };
        }
        );

        commit(Mutations.MUTATE_INSPECTED_PROJECT, {
          ...state.inspectedProject,
          intersectionData,
        });
      }
    }
  },
  [Actions.DELETE_PROJECT_WORKFLOW_TASK_NOTE]: async (
    { commit, state }: ActionContext<ProjectsState, RootState>,
    payload: { projectId: number; taskId: number; noteId: number }
  ) => {
    const res = await Vue.prototype.$http.delete(`/api/v1/comments/${payload.noteId}`);
    if (res.status === 200 && state.inspectedProject) {
      const intersectionData = ( state.inspectedProject.intersectionData || []).map(intersection => {
        if (intersection.workflowTaskId !== payload.taskId) return intersection;
        return {
          ...intersection,
          comments: intersection.comments.filter(x => x.id !== payload.noteId),
        };
      });
      commit(Mutations.MUTATE_INSPECTED_PROJECT, {
        ...state.inspectedProject,
        intersectionData,
      });
    }
  },
  [Actions.CREATE_PLANNER_PERIOD]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: PlannerPeriod) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      if(!payload.quote) throw new Error('PlannerPeriod doesn\'t have a related quote attached which is necessary to save the PlannerPeriod.');
      const res = await quotesService.createNewPlannerPeriod(payload.quote.id, payload);

      const mutatedQuotes = state.inspectedProject.quotes.map((quote: Quote) => {
        if (payload.quote && quote.id !== payload.quote.id) {
          return quote;
        }
        return {
          ...quote,
          plannerPeriods: [
            ...quote.plannerPeriods || [],
            res.data.data,
          ],
        };
      });
      const mutatedOrders = state.inspectedProject.orders.map((order: Quote) => {
        if (payload.quote && order.id !== payload.quote.id) {
          return order;
        }
        return {
          ...order,
          plannerPeriods: [
            ...order.plannerPeriods || [],
            res.data.data,
          ],
        };
      });
      commit(Mutations.MUTATE_INSPECTED_PROJECT, {
        ...state.inspectedProject,
        quotes: mutatedQuotes,
        orders: mutatedOrders,
      });
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.REMOVE_QUOTE]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: Quote) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      await quotesService.deleteQuote(payload);

      const mutatedQuotes = state.inspectedProject.quotes.filter((quote: Quote) => quote.id !== payload.id);
      const mutatedOrders = state.inspectedProject.orders.filter((order: Quote) => order.id !== payload.id);
      commit(Mutations.MUTATE_INSPECTED_PROJECT, {
        ...state.inspectedProject,
        quotes: mutatedQuotes,
        orders: mutatedOrders,
      });
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.REMOVE_PLANNER_PERIOD]: async ({ commit, state }: ActionContext<ProjectsState, RootState>, payload: PlannerPeriod) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const plannerPeriodService = new PlannerPeriodsService();
      // await quotesService.deletePlannerPeriod(payload.id);
      await plannerPeriodService.deletePlannerPeriod(payload.id);

      const mutatedQuotes = state.inspectedProject.quotes.map((quote: Quote) => {
        if (!quote.plannerPeriods) {
          return quote;
        }
        return {
          ...quote,
          plannerPeriods: quote.plannerPeriods.filter((x: PlannerPeriod) => x.id !== payload.id),
        };
      });
      const mutatedOrders = state.inspectedProject.orders.map((order: Quote) => {
        if (!order.plannerPeriods) {
          return order;
        }
        return {
          ...order,
          plannerPeriods: order.plannerPeriods.filter((x: PlannerPeriod) => x.id !== payload.id),
        };
      });
      commit(Mutations.MUTATE_INSPECTED_PROJECT, {
        ...state.inspectedProject,
        quotes: mutatedQuotes,
        orders: mutatedOrders,
      });
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.EDIT_QUOTE]: async ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: Quote) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    commit(Mutations.MUTATE_VALIDATION_ERRORS, null);
    try {
      const res = await quotesService.editQuoteById(payload);
      commit(Mutations.MUTATE_INSPECTED_PROJECT, res.data.data.project);
      if (payload.plannerPeriods && res.data.data.plannerPeriods) {
        const periodsDatesUnChanged = res.data.data.plannerPeriods.every((period: PlannerPeriod) => {
          const payloadPeriod = payload.plannerPeriods!.find(x => x.id === period.id);
          return payloadPeriod ? payloadPeriod.plannerStartDate === period.plannerStartDate && payloadPeriod.plannerEndDate === period.plannerEndDate : false;
        });
        if (!periodsDatesUnChanged) {
          appMessageStore.actions.set({
            message: 'planner.globalMessage.periodDatesGotAdjusted',
            ttl: 7500,
            dismissed: false,
            type: AppMessageType.INFO,
          });
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        commit(Mutations.MUTATE_VALIDATION_ERRORS, error.response.data.errors);
        appMessageStore.actions.set({
          message: error.response.data.message,
          ttl: 10000,
          dismissed: false,
          type: AppMessageType.WARNING,
        });
      }

      if (error.response && error.response.status === 422) {
        commit(Mutations.MUTATE_VALIDATION_ERRORS, error.response.data);
        appMessageStore.actions.set({
          message: error.response.data.errors.existingWorkDays ? 'planner.globalMessage.workDayAlreadyExist' : "common.messages.invalidFormData",
          ttl: 5000,
          dismissed: false,
          type: AppMessageType.WARNING,
        });
      }
      throw error;
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.EDIT_PLANNER_PERIOD]: async ({ commit, state, dispatch }: ActionContext<ProjectsState, RootState>, payload: PlannerPeriod) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      if(!payload.quote) throw new Error('PlannerPeriod doesn\'t have a related quote attached which is necessary to save the PlannerPeriod.');
      const res = await quotesService.editPlannerPeriodById(payload.quote.id, payload);

      const mutatedQuotes = state.inspectedProject.quotes.map((quote: Quote) => {
        if (!quote.plannerPeriods) return quote;
        return {
          ...quote,
          plannerPeriods: quote.plannerPeriods.map((quoteRequest: PlannerPeriod) => {
            return quoteRequest.id === payload.id ? res.data.data : quoteRequest;
          }),
        };
      });
      const mutatedOrders = state.inspectedProject.orders.map((order: Quote) => {
        if (!order.plannerPeriods) return order;
        return {
          ...order,
          plannerPeriods: order.plannerPeriods.map((plannerData: PlannerPeriod) => {
            return plannerData.id === payload.id ? res.data.data : plannerData;
          }),
        };
      });
      commit(Mutations.MUTATE_INSPECTED_PROJECT, {
        ...state.inspectedProject,
        quotes: mutatedQuotes,
        orders: mutatedOrders,
      });
      if (res.data.data.plannerStartDate !== payload.plannerStartDate || res.data.data.plannerEndDate !== payload.plannerEndDate) {
        appMessageStore.actions.set({
          message: 'planner.globalMessage.periodDatesGotAdjusted',
          ttl: 7500,
          dismissed: false,
          type: AppMessageType.INFO,
        });
      }
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        commit(Mutations.MUTATE_VALIDATION_ERRORS, error.response.data);
        appMessageStore.actions.set({
          message: error.response.data.errors.existingWorkDays ? 'planner.globalMessage.workDayAlreadyExist' : "common.messages.invalidFormData",
          ttl: 5000,
          dismissed: false,
          type: AppMessageType.WARNING,
        });
      }
      throw error;
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.SET_VALIDATION_ERRORS]: ({ commit }: ActionContext<ProjectsState, RootState>, payload?: any) => {
    commit(Mutations.MUTATE_VALIDATION_ERRORS, payload);
  },
  [Actions.SET_DETACHED_CONTRACTOR]: ({ commit, dispatch }: ActionContext<ProjectsState, RootState>, payload: Quote) => {
    commit(Mutations.MUTATE_DETACHED_CONTRACTOR, payload);
    dispatch(Actions.SET_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP, true);
  },
  [Actions.SET_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP]: ({ commit }: ActionContext<ProjectsState, RootState>, payload: boolean) => {
    commit(Mutations.MUTATE_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP, payload);
  },
  [Actions.DETACH_CONTRACTOR]: async ({ state, dispatch }: ActionContext<ProjectsState, RootState>) => {
    try {
      if (state.detachedContractorState.quote) await dispatch(Actions.EDIT_QUOTE, { ...state.detachedContractorState.quote, contractor: undefined });
    } finally {
      dispatch(Actions.SET_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP, false);
    }
  },
  [Actions.FETCH_SOCIAL_SECURITY_REGISTRATIONS]: async ({ commit }: ActionContext<ProjectsState, RootState>) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res = await projectsService.getAllSocialSecurityRegistrations();
      commit(Mutations.MUTATE_SOCIAL_SECURITY_REGISTRATIONS_DATA, res.data.data);
      return res.data.data;
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.EDIT_SOCIAL_SECURITY_REGISTRATION_BY_ID]: async ({ state, commit, dispatch }: ActionContext<ProjectsState, RootState>, payload: SocialSecurityRegistration) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res = await projectsService.editSocialSecurityRegistrationById(payload);

      // update state items
      const items = state.socialSecurityRegistrations.map((item: SocialSecurityRegistration) => item.id !== res.data.data.id ? item : {...res.data.data});
      commit(Mutations.MUTATE_SOCIAL_SECURITY_REGISTRATIONS_DATA, items);
      // dispatch(Actions.FETCH_SOCIAL_SECURITY_REGISTRATIONS);
      return res.data.data;
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
  [Actions.EDIT_PROJECT_CLIENT]: async ({ state, commit, dispatch }: ActionContext<ProjectsState, RootState>, payload: ProjectClient) => {
    commit(Mutations.MUTATE_LOADING_STATE, true);
    try {
      const res = await projectsService.editProjectClientById(payload);

      // update state items
      const items = state.inspectedProject.clients.map((item: Client) => item.id !== res.data.data.clientId ? item : {...item, projectClient: res.data.data});
      commit(Mutations.MUTATE_INSPECTED_PROJECT, { ...state.inspectedProject, clients: items });
      return res;
    } finally {
      commit(Mutations.MUTATE_LOADING_STATE, false);
    }
  },
};

const mutations: MutationTree<ProjectsState> = {
  [Mutations.MUTATE_DETACHED_CONTRACTOR]: (state: ProjectsState, payload: Quote) => {
    state.detachedContractorState.quote = payload;
  },
  [Mutations.MUTATE_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP]: (state: ProjectsState, payload: boolean) => {
    state.detachedContractorState.showDeleteDialog = payload;
  },
  [Mutations.MUTATE_PROJECTS_STATUS_DATA]: (state: ProjectsState, payload: ProjectStatus[]) => {
    state.projectStatuses = payload;
  },
  [Mutations.MUTATE_PROJECTS_DATA]: (state: ProjectsState, payload: Project[]) => {
    state.projects = payload;
  },
  [Mutations.MUTATE_PAGINATION_DETAILS]: (state: ProjectsState, payload: Pagination<any>) => {
    state.paginationDetails = {
      first: payload.links.first,
      last: payload.links.last,
      prev: payload.links.prev,
      next: payload.links.next,
      current_page: payload.meta.current_page,
    };
  },
  [Mutations.MUTATE_VALIDATION_ERRORS]: (state: ProjectsState, payload?: any) => {
    state.validationErrors = payload;
  },
  [Mutations.MUTATE_PUSH_NEXT_PAGE]: (state: ProjectsState, payload: Pagination<Project[]>) => {
    state.paginationDetails = {
      first: payload.links.first,
      last: payload.links.last,
      prev: payload.links.prev,
      next: payload.links.next,
      current_page: payload.meta.current_page,
    };

    state.projects = [
      ...state.projects,
      ...payload.data,
    ];
  },
  [Mutations.MUTATE_INSPECTED_PROJECT]: (state: ProjectsState, payload?: Project) => {
    if (payload) {
      payload.quotes = payload.quotes.sort((a, b) => {
        if (a.contractorType.sequence < b.contractorType.sequence) {
          return -1;
        } else {
          return 1;
        }
      });
      payload.orders = payload.orders.sort((a, b) => {
        if (a.contractorType && b.contractorType && a.contractorType.sequence < b.contractorType.sequence) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    state.inspectedProject = payload ? payload : {
      id: 0,
      uniqueId: '',
      projectName: '',
      presentedPrice: undefined,
      initialOffer: 0,
      initialOfferComment: '',
      dateInitialOffer: null,
      dateFinancialDetails: null,
      thirdParties: [],
      clients: [],
      contractorParties: [],
      addedDate: null,
      street: null,
      streetNumber: null,
      postalCode: null,
      city: null,
      distanceToSfinxHq: null,
      socialSecurityCompleted: false,
      socialSecurityReference: '',
      socialSecurityStartDate: null,
      socialSecurityEndDate: null,
      projectLeaders: [],
      estimators: [],
      drawers: [],
      designers: [],
      quotes: [],
      orders: [],
      purchaseInvoices: [],
      salesInvoices: [],
      archivedAt: null,
      latestProgressSnapshot: null,
      contractorBranches: [],
    };
  },
  [Mutations.MUTATE_USERS_DATA]: (state: ProjectsState, payload: ProjectUser[]) => {
    state.users = payload;
  },
  [Mutations.MUTATE_CURRENT_FILTERS]: (state: ProjectsState, payload: ProjectStateFilters) => {
    state.filters = payload;
  },
  [Mutations.MUTATE_LOADING_STATE]: (state: ProjectsState, payload: boolean) => {
    state.isLoading = payload;
  },
  [Mutations.MUTATE_FILTERS_MAIN_QUERY]: (state: ProjectsState, payload: string) => {
    state.filters.mainQuery = payload;
  },
  [Mutations.MUTATE_FILTERS_FETCH_ONGOING]: (state: ProjectsState, payload: boolean|null) => {
    state.filters.fetchOngoing = payload;
  },
  [Mutations.MUTATE_FILTERS_SIDEBAR_OPEN]: (state: ProjectsState, payload: boolean) => {
    state.filters.sideBarOpen = payload;
  },
  [Mutations.MUTATE_FILTERS_NAME_QUERY]: (state: ProjectsState, payload: string) => {
    state.filters.nameQuery = payload;
  },
  [Mutations.MUTATE_FILTERS]: (state: ProjectsState, payload: ProjectStateFilters) => {
    state.filters = payload;
  },
  [Mutations.MUTATE_FILTERS_CLEAR]: (state: ProjectsState) => {
    state.filters = {
      fetchOngoing: state.filters.fetchOngoing,
      sideBarOpen: state.filters.sideBarOpen,
      nameQuery: null,
      mainQuery: null,
      selectedProjectStatusIds: [],
      projectStatusFilterActive: true,
      selectedProjectLeadersIds: [],
      projectLeaderFilterActive: true,
      showMoreProjectLeaders: state.filters.showMoreProjectLeaders,
      showMoreProjectStatuses: state.filters.showMoreProjectStatuses,
      otherCoworkersFilterActive: true,
      selectedEstimatorsIds: [],
      selectedDesignersIds: [],
      selectedDrawersIds: [],
    };
  },
  [Mutations.MUTATE_FILTERS_PROJECT_STATUS]: (state: ProjectsState, payload: number[]) => {
    state.filters.selectedProjectStatusIds = payload;
  },
  [Mutations.MUTATE_FILTERS_PROJECT_LEADER]: (state: ProjectsState, payload: number[]) => {
    state.filters.selectedProjectLeadersIds = payload;
  },
  [Mutations.MUTATE_FILTERS_ESTIMATOR]: (state: ProjectsState, payload: number[]) => {
    state.filters.selectedEstimatorsIds = payload;
  },
  [Mutations.MUTATE_FILTERS_DESIGNER]: (state: ProjectsState, payload: number[]) => {
    state.filters.selectedDesignersIds = payload;
  },
  [Mutations.MUTATE_FILTERS_DRAWER]: (state: ProjectsState, payload: number[]) => {
    state.filters.selectedDrawersIds = payload;
  },
  [Mutations.MUTATE_FILTERS_SHOW_MORE_LEADERS]: (state: ProjectsState, payload: boolean) => {
    state.filters.showMoreProjectLeaders = payload;
  },
  [Mutations.MUTATE_FILTERS_SHOW_MORE_STATUSES]: (state: ProjectsState, payload: boolean) => {
    state.filters.showMoreProjectStatuses = payload;
  },
  [Mutations.MUTATE_SOCIAL_SECURITY_REGISTRATIONS_DATA]: (state: ProjectsState, payload: SocialSecurityRegistration[]) => {
    state.socialSecurityRegistrations = payload;
  },
};

export const state: ProjectsState = {
  projects: [],
  paginationDetails: undefined,
  inspectedProject: {
    id: 0,
    uniqueId: '',
    projectName: '',
    presentedPrice: undefined,
    initialOffer: 0,
    initialOfferComment: '',
    dateInitialOffer: null,
    dateFinancialDetails: null,
    thirdParties: [],
    clients: [],
    contractorParties: [],
    addedDate: null,
    street: null,
    streetNumber: null,
    postalCode: null,
    city: null,
    distanceToSfinxHq: null,
    socialSecurityCompleted: false,
    socialSecurityReference: '',
    socialSecurityStartDate: null,
    socialSecurityEndDate: null,
    projectLeaders: [],
    estimators: [],
    drawers: [],
    designers: [],
    quotes: [],
    orders: [],
    purchaseInvoices: [],
    salesInvoices: [],
    comments: [],
    archivedAt: null,
    latestProgressSnapshot: null,
    contractorBranches:[]
  },
  filters: {
    fetchOngoing: null,
    sideBarOpen: false,
    nameQuery: null,
    mainQuery: null,
    selectedProjectStatusIds: [1, 2, 3, 4, 5, 6],
    projectStatusFilterActive: true,
    selectedProjectLeadersIds: [],
    projectLeaderFilterActive: true,
    showMoreProjectLeaders: false,
    showMoreProjectStatuses: false,
    otherCoworkersFilterActive: true,
    selectedEstimatorsIds: [],
    selectedDesignersIds: [],
    selectedDrawersIds: [],
  },
  projectStatuses: [],
  users: [],
  isLoading: false,
  validationErrors: null,
  detachedContractorState: {
    quote: undefined,
    showDeleteDialog: false,
  },
  socialSecurityRegistrations: [],
};

export const store: Module<ProjectsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
