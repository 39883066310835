import { GrossMarginFilterState } from "./store/types/ReportState";
import { Project } from '@/modules/projects/types/entities';
import { ContractorBranch } from '@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState';


// getContractorBranchFilterCssClass(project: Project, contractorBranch: ContractorBranch) {
//   let projectData = this.filteredProjectsContractorBranchesReport;
//   if(contractorBranch.id && projectData && projectData[project.id] && projectData[project.id][contractorBranch.id]){
//   projectData = projectData[project.id][contractorBranch.id];
//   return projectData ? projectData.cssClass : '';}
//   else return '';
// }

export function getContractorBranchCssClass(
  projectsContractorBranchesOverviewReportMetric: ProjectsContractorBranchesOverviewReportMetric,
  grossMarginFilterState: GrossMarginFilterState,
  contractorBranchData: any,
  projectData: Project
): string {
  const onlyCompleted = grossMarginFilterState.filters.showOnlyCompletedContractorBranches.value[0];
  const isOrdered = contractorBranchData.totalAmountOrdered != null && contractorBranchData.totalAmountOrdered > 0;
  const hasExecutedValue = contractorBranchData.totalAmountExecuted != null && contractorBranchData.totalAmountExecuted > 0;
  const hasCostValue = contractorBranchData.totalCosts != null && contractorBranchData.totalCosts > 0;
  const isCompleted = isOrdered && hasExecutedValue && (contractorBranchData.totalAmountOrdered == contractorBranchData.totalAmountExecuted);

  let cssClass = '';

  if (onlyCompleted) {
    if (!hasExecutedValue && !hasCostValue) {
      if (projectsContractorBranchesOverviewReportMetric == "executed") {
        cssClass = 'null-value';
      } else{
        cssClass = '';
      }
    } else if (!hasExecutedValue && hasCostValue) {
      cssClass = 'tilde';
    } else if (hasExecutedValue && !hasCostValue) {
      cssClass = 'tilde';
    } else if (!isCompleted && hasExecutedValue && hasCostValue) {
      cssClass = 'tilde';
    } else if (isCompleted && !hasCostValue) {
      cssClass = '';
    }
  } else if (!onlyCompleted) {
    if (!hasExecutedValue && !hasCostValue) {
      if (projectsContractorBranchesOverviewReportMetric == "executed") {
        cssClass = 'null-value';
      } else{
        cssClass = '';
      }
    } else if (!hasExecutedValue && hasCostValue) {
      if (projectsContractorBranchesOverviewReportMetric == "cost" || projectsContractorBranchesOverviewReportMetric == "profit_loss") {
        cssClass = 'tilde-value';
      } else{
        cssClass = 'tilde';
      }
    } else if (hasExecutedValue && !hasCostValue) {
      if (projectsContractorBranchesOverviewReportMetric == "cost" || projectsContractorBranchesOverviewReportMetric == "executed") {
        cssClass = 'tilde-value';
      }else{
        cssClass = 'tilde';
      }
    } else if (!isCompleted && hasExecutedValue && hasCostValue) {
      cssClass = 'tilde-value';
    } else if (isCompleted && !hasCostValue) {
      if (projectsContractorBranchesOverviewReportMetric == "profit_loss" ) {
        cssClass = 'tilde-value';
      } else if (projectsContractorBranchesOverviewReportMetric == "cost" || projectsContractorBranchesOverviewReportMetric == "margin_percentage") {
        cssClass = 'tilde';
      }
    }
  }
  return cssClass;
}

export function filterGrossMarginReport(
  report: any,
  grossMarginFilterState: GrossMarginFilterState,
  projectsContractorBranchesOverviewReportMetric: ProjectsContractorBranchesOverviewReportMetric,
): any {
  const filteredReport = JSON.parse(JSON.stringify(report)); // Clone the report to avoid direct state mutation
  const activeFrontendFilters = Object.values(grossMarginFilterState.filters).filter((gf) => !gf.isBackend() && gf.active && gf.hasValues());

  for (const projectId in filteredReport) {
    const project = filteredReport[projectId];
    let projectFilteredOut = false;
    let projectHidden = false;
    let projectExcludeSummary = false;

    activeFrontendFilters.forEach((grossMarginFilter) => {
      getFilters().filter((f) => f.type.includes("project") && grossMarginFilter.name && f.value.includes(grossMarginFilter.name)).forEach((filter) => {
        const result = filter.filter(grossMarginFilter.value, project); // Extracted filter result
        projectFilteredOut = result.filterOut;
        projectHidden = result.hide;
        projectExcludeSummary = result.excludeSummary;
      });
    });

    if (projectFilteredOut) {
      delete filteredReport[projectId];
      continue;
    }

    for (const contractorTypeId in project) {
      const contractorType = project[contractorTypeId];
      let contractorTypeFilteredOut = false;
      let contractorTypeHidden = false;
      let contractorTypeExcludeSummary = false;
      let contractorBranchDeleted = false;
      let contractorBranchExcludeSummary = false;

      activeFrontendFilters.forEach((grossMarginFilter) => {
        getFilters().filter((f) => f.type.includes("contractorBranch") && grossMarginFilter.name && f.value.includes(grossMarginFilter.name)).forEach((filter) => {
          const result = filter.filter(grossMarginFilter.value, contractorType); // Extracted filter result
          contractorTypeFilteredOut = result.filterOut;
          contractorTypeHidden = result.hide;
          contractorTypeExcludeSummary = result.excludeSummary;
          if (contractorTypeFilteredOut) {
            delete project[contractorTypeId];
            contractorBranchDeleted = true; // Fix: set contractorBranchDeleted to true
          } else if (contractorTypeHidden || projectHidden) {
            if (project[contractorTypeId]) {
              project[contractorTypeId].cssClass = "hide";
              if (contractorTypeExcludeSummary) {
                project[contractorTypeId].excludeSummary = true;
                contractorBranchExcludeSummary = true;
              }
            }
          }
        });
      });

      if (!contractorBranchDeleted && !contractorBranchExcludeSummary) {
        const isTildeFilter = getFilters().find((f) => f.value.includes("isTilde"));
        const result = isTildeFilter?.filter(projectsContractorBranchesOverviewReportMetric, contractorType);
        if (result?.excludeSummary && project[contractorTypeId]) { // Fix: check if project[contractorTypeId] is defined
          project[contractorTypeId].excludeSummary = true;
        }
      }
    }
  }

  return filteredReport;
}

export function getFilters() {
  return [
    {
      value: ["suppliers"],
      type: ["contractorBranch"],
      filter: (supplier: any, data: any) => {
        let contractorType = data;
        if (contractorType.supplierIds && contractorType.supplierIds != null && !contractorType.supplierIds.includes(supplier)) {
          return { filterOut: true, excludeSummary: true, hide: false }
        } else {
          return { filterOut: false, excludeSummary: false, hide: false };
        }
      },
    },
    {
      value: ["minAmountOrderedContractorBranch"],
      type: ["contractorBranch"],
      filter: (payload: any, contractorType: any) => {
        const contractorBranch = payload[0].contractorBranchId;
        const minAmount = payload[0].minAmountOrdered;
        if (contractorBranch == contractorType.contractorTypeId && contractorType.totalAmountOrdered <= minAmount * 100) {
          return { filterOut: true, excludeSummary: true, hide: false }
        } else {
          return { filterOut: false, excludeSummary: false, hide: false };
        }
      }
    },
    {
      value: ["maxAmountOrderedContractorBranch"],
      type: ["contractorBranch"],
      filter: (payload: any, contractorType: any) => {
        const contractorBranch = payload[0].contractorBranchId;
        const maxAmount = payload[0].maxAmountOrdered;
        if (contractorBranch == contractorType.contractorTypeId && contractorType.totalAmountOrdered > maxAmount * 100) {
          return { filterOut: true, excludeSummary: true, hide: false }
        } else {
          return { filterOut: false, excludeSummary: false, hide: false };
        }
      }
    },
    {
      value: ["showOnlyRelevantContractorBranches"],
      type: ["contractorBranch"],
      filter: (payload: any, contractorType: any) => {
        if (payload[0] && contractorType.isRelevant != undefined && !contractorType.isRelevant) {
          return { filterOut: false, excludeSummary: true, hide: true }
        } else {
          return { filterOut: false, excludeSummary: false, hide: false };
        }
      }
    },
    {
      value: ["isTilde"],
      type: ["contractorBranch"],
      filter: (payload: any, contractorType: any) => {
        const projectsContractorBranchesOverviewReportMetric = payload[0];
        const isOrdered = contractorType.totalAmountOrdered != null && contractorType.totalAmountOrdered > 0;
        const hasExecutedValue = contractorType.totalAmountExecuted != null && contractorType.totalAmountExecuted > 0;
        const hasCostValue = contractorType.totalCosts != null && contractorType.totalCosts > 0;
        const isCompleted = isOrdered && hasExecutedValue && (contractorType.totalAmountOrdered == contractorType.totalAmountExecuted);

        const isTilde = (!hasExecutedValue && hasCostValue) || 
                        (hasExecutedValue && !hasCostValue) || 
                        (!isCompleted && hasExecutedValue && hasCostValue);

        const isTildeValue = isTilde || 
                             (isCompleted && !hasCostValue) ||
                             (projectsContractorBranchesOverviewReportMetric == "cost" && !hasExecutedValue && hasCostValue) ||
                             (projectsContractorBranchesOverviewReportMetric == "executed" && hasExecutedValue && !hasCostValue) ||
                             (projectsContractorBranchesOverviewReportMetric == "profit_loss" && isCompleted && !hasCostValue);

        if (isTilde || isTildeValue) {
          return { filterOut: false, excludeSummary: true, hide: false };
        } else {
          return { filterOut: false, excludeSummary: false, hide: false };
        }
      }
    },
  ];
}