
  import { defineComponent } from 'vue';
  import helpers from '@/modules/calendar/services/helper.service';
  import SideMenu from '@/modules/calendar/components/sidemenu.component.vue';
  import SidemenuDateComponent from '@/modules/calendar/components/sidemenu-date.component.vue';
  import SidemenuWorkDayComponent from '@/modules/calendar/components/sidemenu-work-details.component.vue';
  import NewCommentComponent from '@/modules/calendar/components/new-comment.component.vue';
  import store from '../store';
  import { CalendarSidebarDialog } from '../types/CalendarSidebarDialog';

  export default defineComponent({
    name: 'SidemenuPeriodDetailsComponent',
    components: {
      sidemenu: SideMenu,
      'sidemenu-workday': SidemenuWorkDayComponent,
      'date-section': SidemenuDateComponent,
      'new-comment': NewCommentComponent,
    },
    setup(props, { emit }) {
      return {
        data: store.calendar,
        environment: store.calendar,
        helpers: helpers,
        CalendarSidebarDialog,
      };
    },
  });
