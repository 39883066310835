import {RouteConfig} from 'vue-router';
// import PurchaseInvoicesComponent from './views/purchase-invoices/purchase-invoices.component.vue';
// import PurchaseInvoiceComponent from './views/purchase-invoice/purchase-invoice.component.vue';

export const routes: RouteConfig[] = [
	// {
    //     path: '/invoices/purchase',
	// 	component: PurchaseInvoicesComponent
	// },
	// {
	// 	path: '/invoices/purchase/:id',
	// 	component: PurchaseInvoiceComponent
	// }
];
