
import {computed, defineComponent, inject, reactive} from 'vue';
import {DrawingEventHandlerService} from '@/modules/purchase-invoices/services/drawing-event-handler.service'
import {DrawingColours, DrawingTools} from '@/modules/purchase-invoices/types/drawing';

export default defineComponent({
  name: 'DrawingToolbarComponent',
  components: {},
  emits: [],
  props: {},
  setup() {

    const drawingEventHandler = inject<DrawingEventHandlerService>('drawingEventHandler');

    if (drawingEventHandler == null) {
      throw new Error('Drawing event handler is undefined')
    }

    const state = reactive({
      selectedTool: drawingEventHandler.selectedTool,
      selectedColor: drawingEventHandler.selectedColour
    })

    // getters
    const tools = computed(() => {
      return DrawingTools
    })

    const colors = computed(() => {
      return DrawingColours
    })

    const selectableColors = computed(() => {
      const allowedColors = [DrawingColours.BLACK, DrawingColours.RED, DrawingColours.GREEN, DrawingColours.BLUE]
      return Object.values(colors.value).filter(color => allowedColors.includes(color))
    })

    const selectedTool = computed(() => {
      // FIXME: event handler should be refactored as composable with reactive props
      // return drawingEventHandler.selectedTool
      return state.selectedTool
    })

    const selectedColor = computed(() => {
      // FIXME: event handler should be refactored as composable with reactive props
      // return drawingEventHandler.selectedColour
      return state.selectedColor
    })

    const onClickTool = (tool: DrawingTools) => {
      if (tool === tools.value.SELECT) {
        drawingEventHandler.onChangeDrawingTool()
      }

      state.selectedTool = tool
      drawingEventHandler.selectedTool = tool
    }

    const onClickColor = (color: DrawingColours) => {
      state.selectedColor = color
      drawingEventHandler.selectedColour = color
    }

    return {
      onClickTool,
      tools,
      selectedTool,
      selectableColors,
      onClickColor,
      selectedColor,
    }
  }
})
