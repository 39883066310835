import { UseUserContext } from '../types/useUser';
import { User } from '@/modules/entities/types/entities';
import { AxiosResponse } from 'axios';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { UsersService } from '@/services/users.service';
import { PasswordSet } from '@/modules/settings/store/modules/users/types';
import useFilter from '../useFilter';

const userService = new UsersService();

const setUser = async (ctx: UseUserContext, user: User | null) => {
  const { state } = ctx;
  try {
    state.user = user;
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  }
};

const clearUserValidationErrors = async (ctx: UseUserContext) => {
  const { state } = ctx;
  state.userValidationErrors = null;
  return Promise.resolve();
};

const clearUserPasswordMatchingResult = async (ctx: UseUserContext) => {
  const { state } = ctx;
  state.userPasswordMatchingResult.isConfirmed = false;
  state.userPasswordMatchingResult.password = null;
  return Promise.resolve();
};

const fetchUser = async (ctx: UseUserContext, id: number): Promise<AxiosResponse<JsonResource<User>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchUser.name, true);
  try {
    clearUserValidationErrors(ctx);
    clearUserPasswordMatchingResult(ctx);
    const res = await userService.getUserById(id);
    setUser(ctx, res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchUser.name, false);
  }
};

const createUser = async (ctx: UseUserContext): Promise<AxiosResponse<JsonResource<User>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(createUser.name, true);
  state.userValidationErrors = null;
  try {
    if (state.user !== null) {
      clearUserValidationErrors(ctx);
      clearUserPasswordMatchingResult(ctx);
      const res = await userService.createUser(state.user);
      setUser(ctx, res.data.data);
      return Promise.resolve(res);
    } else return Promise.reject(new Error('No user available in state'));
  } catch (err: any) {
    if (err.response && err.response.status === 422) state.userValidationErrors = err.response.data.errors;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(createUser.name, false);
  }
};

const updateUser = async (ctx: UseUserContext): Promise<AxiosResponse<JsonResource<User>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(updateUser.name, true);
  state.userValidationErrors = null;
  try {
    if (state.user !== null && state.user.id != null) {
      clearUserValidationErrors(ctx);
      const res = await userService.updateUserById(state.user, state.user.id);
      setUser(ctx, res.data.data);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No user available in state or id not provided'));
  } catch (err: any) {
    if (err.response && err.response.status === 422) state.userValidationErrors = err.response.data.errors;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateUser.name, false);
  }
};
const deleteUser = async (ctx: UseUserContext): Promise<AxiosResponse<any>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(deleteUser.name, true);
  state.userValidationErrors = null;
  try {
    if (state.user !== null && state.user.id != null) {
      const res = await userService.deleteUserById(state.user.id);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No user available in state or id not provided'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteUser.name, false);
  }
};

const matchPassword = async (ctx: UseUserContext, passwordSet: PasswordSet) => {
  const { state } = ctx;
  const passwordsMatch = !!passwordSet.password && passwordSet.password === passwordSet.passwordConfirmation; // ?  'dictionary.passwordsDoNotMatch' : true
  state.userPasswordMatchingResult.isConfirmed = passwordsMatch;
  state.userPasswordMatchingResult.password = passwordSet.password;
  return Promise.resolve();
};

const search = async (ctx: UseUserContext, query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<User[]>>> => {
  const { composables: { loadingActions, filter } } = ctx;
  loadingActions.actions.set(search.name, true);
  try {
    let urlSearchParams = null;
    if (useAsFilter) {
      if (query) filter.actions.setFilter('search', query);
      else filter.actions.deleteFilter('search');
      urlSearchParams = filter.getters.filterUrlQuery.value;
    } else {
      const f = useFilter();
      if (query) f.actions.setFilter('search', query);
      urlSearchParams = filter.getters.filterUrlQuery.value;
    }
    const url = userService.getBaseEndpoint() + `${urlSearchParams ? '?' + urlSearchParams.toString() : ''}`;
    const res = await userService.getAllUsers(url);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(search.name, false);
  }
};

export const actions = {
  fetchUser,
  createUser,
  updateUser,
  deleteUser,
  setUser,
  search,
  clearUserValidationErrors,
  clearUserPasswordMatchingResult,
  matchPassword,
};
