import { WorkflowState } from '@/modules/workflow/store/types/WorkflowState';
import { WorkflowFunctionGroup, ProjectIntersectionData, WorkflowTask, WorkflowTaskStatus } from '@/modules/workflow/types/entities';
import { MutationTree } from 'vuex';
import { Mutations } from '@/modules/workflow/store/types/StoreTypes';
import { Project } from '@/modules/projects/types/entities';
import { Pagination } from '@/core/types/Entities';

export const mutations: MutationTree<WorkflowState> = {
	[Mutations.MUTATE_WORFKLOW_TASKS]: (state: WorkflowState, payload: WorkflowTask[]) => {
		state.tasks = payload;
	},
	[Mutations.MUTATE_WORKFLOW_TASK_STATUSES]: (state: WorkflowState, payload: WorkflowTaskStatus[]) => {
		state.taskStatuses = payload;
	},
	[Mutations.MUTATE_WORKFLOW_EDIT_PROJECT_WORKFLOW_TASK]: (state: WorkflowState, payload: ProjectIntersectionData) => {
		state.selectedProjects = state.selectedProjects.map(project => {
			if (!project.intersectionData) {
				if (project.id === payload.projectId) {
					return {
						...project,
						intersectionData: [payload]
					}
				}
			}
			if (!project.intersectionData!.some(intersectionData => intersectionData.projectId === payload.projectId && intersectionData.workflowTaskId === payload.workflowTaskId)
				&& project.id === payload.projectId) {
				return {
					...project,
					intersectionData: [...project.intersectionData!, payload]
				};
			}
			return {
				...project,
				intersectionData: project.intersectionData!.map(intersectionData => {
					if (intersectionData.projectId === payload.projectId && intersectionData.workflowTaskId === payload.workflowTaskId) return payload;
					return intersectionData;
				})
			}
		});
	},
	[Mutations.MUTATE_WORKFLOW_SELECTED_PROJECTS]: (state: WorkflowState, payload: Project[]) => {
		state.selectedProjects = payload;
	},
	[Mutations.MUTATE_WORKFLOW_PREVIOUS_SELECTED_PROJECTS]: (state: WorkflowState, payload: Project[]) => {
		state.previousSelectedProjects = payload;
	},
	[Mutations.MUTATE_WORFKLOW_ALL_PROJECTS]: (state: WorkflowState, payload: Project[]) => {
		state.allProjects = payload;
	},
	[Mutations.MUTATE_WORFKLOW_FUNCTION_GROUPS]: (state: WorkflowState, payload: WorkflowFunctionGroup[]) => {
		state.functionGroups = payload;
	},
	[Mutations.MUTATE_WORKFLOW_LOADING]: (state: WorkflowState, payload: boolean) => {
		state.loading = payload;
	},
	[Mutations.MUTATE_WORKFLOW_SIDEBAR_OPEN]: (state: WorkflowState, payload: boolean) => {
		state.sidebarOpen = payload;
	},
	[Mutations.MUTATE_WORKFLOW_ADD_SELECTED_CONSTRUCTION_SITE]: (state: WorkflowState, payload: Project) => {
		state.selectedProjects = [
			...state.selectedProjects,
			payload
		];
	},
	[Mutations.MUTATE_WORKFLOW_REMOVE_SELECTED_CONSTRUCTION_SITE]: (state: WorkflowState, payload: Project) => {
		state.selectedProjects = state.selectedProjects.filter((val: Project) => {
			return val.id !== payload.id;
		});
	},
	[Mutations.MUTATE_WORKFLOW_ENVIRONMENT_ID]: (state: WorkflowState, payload: number) => {
		state.environmentId = payload;
	},
	[Mutations.MUTATE_WORKFLOW_USER_ID]: (state: WorkflowState, payload: number) => {
		state.userId = payload;
	},
	[Mutations.MUTATE_WORKFLOW_SHOW_FUNCTION_GROUPS]: (state: WorkflowState, payload: boolean) => {
		state.showFunctionGroups = payload;
	},
	[Mutations.MUTATE_WORKFLOW_ADD_EXPANDED_PROJECT]: (state: WorkflowState, payload: number[]) => {
		state.expandedProjects = payload
	},
	[Mutations.MUTATE_WORKFLOW_REMOVE_EXPANDED_PROJECT]: (state: WorkflowState, payload: number) => {
		state.expandedProjects = state.expandedProjects.filter((val: number) => {
			return val !== payload;
		});
	},
	[Mutations.MUTATE_WORKFLOW_ADD_EXPANDED_TASK]: (state: WorkflowState, payload: number) => {
		state.expandedTasks= [
			...state.expandedTasks,
			payload
		];
		state.anyTaskExpanded = true;
	},
	[Mutations.MUTATE_WORKFLOW_REMOVE_EXPANDED_TASK]: (state: WorkflowState, payload: number) => {
		state.expandedTasks = state.expandedTasks.filter((val: number) => {
			return val !== payload;
		});
		if (state.expandedTasks.length <= 0) state.anyTaskExpanded = false;
	},
	[Mutations.MUTATE_WORKFLOW_TOGGLE_ALL_TASKS_PANELS]: (state: WorkflowState) => {
		if (state.expandedTasks.length > 0) {
			state.expandedTasks = [];
			state.anyTaskExpanded = false;
		} else {
			state.expandedTasks = state.tasks.map(x => {
				return x.id
			});
			state.anyTaskExpanded = true;
		}
	},
	[Mutations.MUTATE_WORKFLOW_FILTER_USERS]: (state: WorkflowState, payload: {userId: number; userGroup: string}) => {
		switch (payload.userGroup) {
			case 'leaders':
				state.filters.leadersIds.includes(payload.userId) ?
					state.filters.leadersIds.splice(state.filters.leadersIds.indexOf(payload.userId)) :
					state.filters.leadersIds = [...state.filters.leadersIds, payload.userId];
				break;
			case 'estimators':
				state.filters.estimatorsIds.includes(payload.userId) ?
					state.filters.estimatorsIds.splice(state.filters.estimatorsIds.indexOf(payload.userId)) :
					state.filters.estimatorsIds = [...state.filters.estimatorsIds, payload.userId];
				break;
			case 'drawers':
				state.filters.drawersIds.includes(payload.userId) ?
					state.filters.drawersIds.splice(state.filters.drawersIds.indexOf(payload.userId)) :
					state.filters.drawersIds = [...state.filters.drawersIds, payload.userId];
				break;
			case 'designers':
				state.filters.designersIds.includes(payload.userId) ?
					state.filters.designersIds.splice(state.filters.designersIds.indexOf(payload.userId)) :
					state.filters.designersIds = [...state.filters.designersIds, payload.userId];
				break;
			default: break;
		}
	},
	[Mutations.MUTATE_WORKFLOW_FILTER_PROJECT_STATUS_ID]: (state: WorkflowState, payload: number) => {
		const projectStatusChecked = state.filters.projectStatusesIds.some(id => id === payload);
		if (projectStatusChecked) {
			state.filters.projectStatusesIds = state.filters.projectStatusesIds.filter(id => id !== payload);
		} else {
			state.filters.projectStatusesIds = [...state.filters.projectStatusesIds, payload];
		}
	},
	[Mutations.MUTATE_WORKFLOW_FILTER_SHOW_ALL_PROJECTS]: (state: WorkflowState, payload: boolean) => {
		if (payload) state.filters.projectStatusesIds = [1, 2, 3, 4, 5, 6, 7];
		state.filters.showMyProjects = payload;
	},
	[Mutations.MUTATE_WORKFLOW_FILTER_PROJECT_NAME]: (state: WorkflowState, payload: string) => {
		state.filters.projectNameQuery = payload;
	},
	[Mutations.MUTATE_WORKFLOW_MULTIPLE_TASK_STATUS_ID]: (state: WorkflowState, taskStatusId: number) => {
		state.multiTaskStatus.id = taskStatusId;
		if (taskStatusId !== 8) state.multiTaskStatus.futureStartDate = undefined;
	},
	[Mutations.MUTATE_WORKFLOW_MULTIPLE_TASK_STATUS_DATE]: (state: WorkflowState, futureStartDate: Date) => {
		state.multiTaskStatus.futureStartDate = futureStartDate;
	},
	[Mutations.MUTATE_PUSH_NEXT_PROJECTS]: (state: WorkflowState, payload: Pagination<Project[]>) => {
		state.paginationDetails = {
			first: payload.links.first,
			last: payload.links.last,
			prev: payload.links.prev,
			next: payload.links.next,
			// eslint-disable-next-line @typescript-eslint/camelcase
			current_page: payload.meta.current_page
		};

		state.allProjects = [
			...state.allProjects,
			...payload.data
		]
	},
	[Mutations.MUTATE_PROJECTS_PAGINATION_DETAILS]: (state: WorkflowState, payload: Pagination<Project[]>) => {
		state.paginationDetails = {
			first: payload.links.first,
			last: payload.links.last,
			prev: payload.links.prev,
			next: payload.links.next,
			// eslint-disable-next-line @typescript-eslint/camelcase
			current_page: payload.meta.current_page
		}
	},
	[Mutations.MUTATE_HIGHLIGHTED_PROJECT_ID]: (state: WorkflowState, payload: number) => {
		state.highlightedProjectId = payload;
	}
};
