
  import { computed, defineComponent, ref, watch, PropType } from 'vue';
  import { Supplier } from '@/modules/entities/types/entities';
  import { Division } from '@/modules/settings/types/entities';

  export default defineComponent({
    emits: ['onSelectCollaborator'],
    props: {
      collaborator: {
        type: Object as PropType<Division | Supplier>,
        required: true,
      },
    },
    setup(props, {}) {
      const isChecked = ref(false);
      return {
        isChecked,
      };
    },
  });
