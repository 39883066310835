import axios, { AxiosResponse, AxiosPromise } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import {Timesheet, TimesheetEntry} from "@/modules/settings/types/entities";

const TIMESHEETS_ENDPOINT = '/api/v1/timesheets';

export class TimesheetsService {

  getBaseEndpoint(): string {
    return TIMESHEETS_ENDPOINT;
  }

  getAllTimesheets(url = `${TIMESHEETS_ENDPOINT}`): Promise<AxiosResponse<Pagination<Timesheet[]>>> {
    return axios.get(url);
  }

  getTimesheetById(id: number): Promise<AxiosResponse<JsonResource<Timesheet>>> {
    return axios.get<JsonResource<Timesheet>>(`${ TIMESHEETS_ENDPOINT }/${ id }`);
  }

  createTimesheet(timesheet: Timesheet): AxiosPromise<JsonResource<Timesheet>> {
    return axios.post(`${ TIMESHEETS_ENDPOINT }`, timesheet);
  }

  editTimesheet(payload: Timesheet): AxiosPromise<JsonResource<Timesheet>> {
    return axios.put(`${ TIMESHEETS_ENDPOINT }/${ payload.id }`, payload);
  }

  deleteTimesheet(payload: number): AxiosPromise {
    return axios.delete(`${ TIMESHEETS_ENDPOINT }/${ payload }`);
  }

  createTimesheetEntry(timesheetId: number, entry: TimesheetEntry): Promise<AxiosResponse<JsonResource<Timesheet>>> {
    return axios.post(`${ TIMESHEETS_ENDPOINT }/${ timesheetId }/entries`, entry);
  }

  editTimesheetEntry(timesheetId: number, entry: TimesheetEntry): Promise<AxiosResponse<JsonResource<Timesheet>>> {
    return axios.put(`${ TIMESHEETS_ENDPOINT }/${ timesheetId }/entries/${ entry.id }`, entry);
  }

  deleteTimesheetEntry(timesheetId: number, entryId: number): Promise<AxiosResponse<JsonResource<Timesheet>>> {
    return axios.delete(`${ TIMESHEETS_ENDPOINT }/${ timesheetId }/entries/${ entryId }`);
  }
}
