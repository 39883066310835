export const enum Actions {
	FETCH_USERS = 'FETCH_USERS',
    FETCH_PROJECT_STATUSES = 'FETCH_PROJECT_STATUSES',
    SEARCH_PROJECTS = 'SEARCH_PROJECTS',
    SEARCH_SUPPLIERS = 'SEARCH_SUPPLIERS',
    // gross margin
    FETCH_GROSS_MARGIN_REPORT = 'FETCH_GROSS_MARGIN_REPORT',
    FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT = 'FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT',
    FETCH_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT = 'FETCH_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT',
    TOGGLE_GROSS_MARGIN_FILTER_SIDEBAR = 'TOGGLE_GROSS_MARGIN_FILTER_SIDEBAR',
    TOGGLE_GROSS_MARGIN_FILTER_GROUP = 'TOGGLE_GROSS_MARGIN_FILTER_GROUP',
    TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_STATUSES = 'TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_STATUSES',
    TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_LEADERS = 'TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_LEADERS',
    TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_ESTIMATORS = 'TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_ESTIMATORS',
    TOGGLE_SHOW_PROJECTS_GROSS_MARGIN_REPORT = 'TOGGLE_SHOW_PROJECTS_GROSS_MARGIN_REPORT',
    TOGGLE_SHOW_PROJECTS_CONTRACTOR_BRANCHES_OVERVIEW_REPORT = 'TOGGLE_SHOW_PROJECTS_CONTRACTOR_BRANCHES_OVERVIEW_REPORT',
    CLEAR_GROSS_MARGIN_FILTERS = 'CLEAR_GROSS_MARGIN_FILTERS',
    SET_GROSS_MARGIN_FILTER_ON_PROJECT = 'SET_GROSS_MARGIN_FILTER_ON_PROJECT',
    SET_GROSS_MARGIN_FILTER_ON_SUPPLIER = 'SET_GROSS_MARGIN_FILTER_ON_SUPPLIER',
    SET_GROSS_MARGIN_FILTER_ON_PROJECT_STATUS = 'SET_GROSS_MARGIN_FILTER_ON_PROJECT_STATUS',
    SET_GROSS_MARGIN_FILTER_ON_PROJECT_LEADER = 'SET_GROSS_MARGIN_FILTER_ON_PROJECT_LEADER',
    SET_GROSS_MARGIN_FILTER_ON_PROJECT_ESTIMATOR = 'SET_GROSS_MARGIN_FILTER_ON_PROJECT_ESTIMATOR',
    SET_GROSS_MARGIN_FILTER_ON_CONTRACTOR_TYPES = 'SET_GROSS_MARGIN_FILTER_ON_CONTRACTOR_TYPES',
    //Total Amount Ordered
    SET_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED = 'SET_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED',
    SET_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED = 'SET_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED',
    //Total Amount Ordered Contractor Branch
    SET_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH = 'SET_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH',
    SET_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH = 'SET_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH',
    //Startdate planning
    SET_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_FROM = 'SET_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_FROM',
    SET_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_TO = 'SET_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_TO',
    //Enddate planning
    SET_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_FROM = 'SET_GROSS_MARGIN_FILTER_ON_LAST__PLANNER_DATE_FROM',
    SET_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_TO = 'SET_GROSS_MARGIN_FILTER_ON_LAST__PLANNER_DATE_TO',
	//Date first sales invoice
    SET_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_FROM = 'SET_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_FROM',
    SET_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_TO = 'SET_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_TO',
	//Date last sales invoice
    SET_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_FROM = 'SET_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_FROM',
    SET_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_TO = 'SET_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_TO',
	// Date first purchase invoice
    SET_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_FROM = 'SET_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_FROM',
    SET_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_TO = 'SET_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_TO',
	// Date last purchase invoice
    SET_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_FROM = 'SET_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_FROM',
    SET_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_TO = 'SET_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_TO',
	
    SET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC = 'SET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC',
    SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_CONTRACTOR_BRANCHES = 'SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_CONTRACTOR_BRANCHES',
    SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_RELEVANT_CONTRACTOR_BRANCHES = 'SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_RELEVANT_CONTRACTOR_BRANCHES',
    SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_PROJECTS = 'SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_PROJECTS',

}

export const enum Getters {
    GET_IS_LOADING = 'GET_IS_LOADING',
    GET_USERS = 'GET_USERS',
    GET_PROJECT_STATUSES = 'GET_PROJECT_STATUSES',
    GET_PROJECT_STATUSES_TRANSLATED = 'GET_PROJECT_STATUSES_TRANSLATED',
    GET_SEARCH_PROJECTS = 'GET_SEARCH_PROJECTS',
    GET_SEARCH_SUPPLIERS = 'GET_SEARCH_SUPPLIERS',
    GET_SEARCH_CONTRACTOR_BRANCHES = 'GET_SEARCH_CONTRACTOR_BRANCHES',

    // gross margin
    GET_GROSS_MARGIN_REPORT = 'GET_GROSS_MARGIN_REPORT',
    GET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT = 'GET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT',
    GET_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT = 'GET_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT',
    GET_GROSS_MARGIN_FILTERSTATE = 'GET_GROSS_MARGIN_FILTERSTATE',
    GET_SHOW_PROJECTS_GROSS_MARGIN_REPORT = 'GET_SHOW_PROJECTS_GROSS_MARGIN_REPORT',
    GET_SHOW_PROJECTS_CONTRACTOR_BRANCHES_OVERVIEW_REPORT = 'GET_SHOW_PROJECTS_CONTRACTOR_BRANCHES_OVERVIEW_REPORT',
    GET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC = 'GET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC',
}

export const enum Mutations {
	MUTATE_IS_LOADING_GROSS_MARGIN_REPORT = 'MUTATE_IS_LOADING_GROSS_MARGIN_REPORT',
	MUTATE_IS_LOADING_CONTRACTOR_BRANCH_REPORT = 'MUTATE_IS_LOADING_CONTRACTOR_BRANCH_REPORT',
    MUTATE_USERS = 'MUTATE_USERS',
    MUTATE_PROJECT_STATUSES = 'MUTATE_PROJECT_STATUSES',
    MUTATE_SEARCH_PROJECTS = 'MUTATE_SEARCH_PROJECTS',
    MUTATE_SEARCH_SUPPLIERS = 'MUTATE_SEARCH_SUPPLIERS',

    // gross margin
    MUTATE_GROSS_MARGIN_REPORT = 'MUTATE_GROSS_MARGIN_REPORT',
    MUTATE_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT = 'MUTATE_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT',
    MUTATE_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT = 'MUTATE_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT',
    MUTATE_TOGGLE_GROSS_MARGIN_FILTER_SIDEBAR = 'MUTATE_TOGGLE_GROSS_MARGIN_FILTER_SIDEBAR',
    MUTATE_TOGGLE_GROSS_MARGIN_FILTER_GROUP = 'MUTATE_TOGGLE_GROSS_MARGIN_FILTER_GROUP',
    MUTATE_GROSS_MARGIN_FILTER_GROUP_ENABLE = 'MUTATE_GROSS_MARGIN_FILTER_GROUP_ENABLE',
    MUTATE_GROSS_MARGIN_FILTER_GROUP_DISABLE = 'MUTATE_GROSS_MARGIN_FILTER_GROUP_DISABLE',
    MUTATE_CLEAR_GROSS_MARGIN_FILTERS = 'MUTATE_CLEAR_FILTERS',
    MUTATE_GROSS_MARGIN_FILTER_PROJECTS = 'MUTATE_GROSS_MARGIN_FILTER_PROJECTS',
    MUTATE_GROSS_MARGIN_FILTER_SUPPLIER = 'MUTATE_GROSS_MARGIN_FILTER_SUPPLIER',
    MUTATE_GROSS_MARGIN_FILTER_SELECTED_PROJECT = 'MUTATE_GROSS_MARGIN_FILTER_SELECTED_PROJECT',
    MUTATE_GROSS_MARGIN_FILTER_SELECTED_SUPPLIER = 'MUTATE_GROSS_MARGIN_FILTER_SELECTED_SUPPLIER',
    MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_STATUS = 'MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_STATUS',
    MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_LEADER = 'MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_LEADER',
    MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_ESTIMATOR = 'MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_ESTIMATOR',
    MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_STATUSES = 'MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_STATUSES',
    MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_LEADERS = 'MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_LEADERS',
    MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_ESTIMATORS = 'MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_ESTIMATORS',
    MUTATE_GROSS_MARGIN_FILTER_ON_CONTRACTOR_TYPES = 'MUTATE_GROSS_MARGIN_FILTER_ON_CONTRACTOR_TYPES',

    //Total Amount Ordered
    MUTATE_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED = 'MUTATE_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED',
    MUTATE_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED = 'MUTATE_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED',
    //Total Amount Ordered Contractor Branch
    MUTATE_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH = 'MUTATE_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH',
    MUTATE_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH = 'MUTATE_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH',
    //Startdate planning
    MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_FROM = 'MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_FROM',
    MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_TO = 'MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_TO',
    //Enddate planning
    MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_FROM = 'MUTATE_GROSS_MARGIN_FILTER_ON_LAST__PLANNER_DATE_FROM',
    MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_TO = 'MUTATE_GROSS_MARGIN_FILTER_ON_LAST__PLANNER_DATE_TO',
    //Date first sales invoice
    MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_FROM = 'MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_FROM',
    MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_TO = 'MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_TO',
    //Date last sales invoice
    MUTATE_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_FROM = 'MUTATE_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_FROM',
    MUTATE_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_TO = 'MUTATE_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_TO',
    // Date first purchase invoice
    MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_FROM = 'MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_FROM',
    MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_TO = 'MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_TO',
    // Date last purchase invoice
    MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_FROM = 'MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_FROM',
    MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_TO = 'MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_TO',

    MUTATE_SHOW_PROJECTS_GROSS_MARGIN_REPORT = 'MUTATE_SHOW_PROJECTS_GROSS_MARGIN_REPORT',
    MUTATE_SHOW_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT = 'MUTATE_SHOW_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT',
    MUTATE_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC = 'MUTATE_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC',
    MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_CONTRACTOR_BRANCHES = 'MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_CONTRACTOR_BRANCHES',
    MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_RELEVANT_CONTRACTOR_BRANCHES = 'MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_RELEVANT_CONTRACTOR_BRANCHES',
    MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_PROJECTS = 'MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_PROJECTS',

}
