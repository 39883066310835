import { Contact } from '@/modules/contacts/types/entities';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class GetContactName extends Vue {
	getContactName(contact: Contact): string {
		const name = [];
		contact.firstName && name.push(contact.firstName);
		contact.lastName && name.push(name.length ? ` ${contact.lastName}` : contact.lastName);
		if (!name.length) name.push('Geen name');
		return name.join("");
	}
}
