
  import { Component, Vue } from 'vue-property-decorator';
  import { Permissions } from '@/core/types/Permissions';
  import AppMessageComponent from '@/core/components/app-message/app-message.component.vue';
  import { Role } from '@/modules/entities/types/entities';
  import { UsersService } from '@/services/users.service';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import { AppMessageType } from '@/core/components/app-message/entities';
  import authStore from '@/modules/login/store';

  const SFINX_SHOW_MENU = 'SFINX_SHOW_MENU';

  const icons = require.context('@/assets/navigation-menu', false, /\.svg$/);

  // services
  const usersService = new UsersService();

  @Component({
    components: {
      'app-message-component': AppMessageComponent,
    },
    setup() {
      return {
        auth: authStore.auth
      }
    }
  })
  export default class BaseComponent extends Vue {
    shouldShowMenu: boolean = false;
    changingRoleLoading: boolean = false;

    classes: [] = [];

    itemsMiddle: any[] = [
      // {
      //     name: 'common.menu.toggleMenu',
      //     icon: 'toggle',
      //     onClick: this.toggleMenu,
      //     permission: 1,
      //     type: 3
      // },

      {
        name: 'home.title',
        icon: 'home',
        route: '/home',
        permission: Permissions.accessHome,
      },

      {
        name: 'projects.title',
        icon: 'projects',
        route: '/projects',
        permission: Permissions.accessProjects,
      },

      {
        name: 'constructionSiteManagement.title',
        icon: 'work_outline',
        route: '/construction-site-management',
        permission: Permissions.accessConstructionSiteManagement,
      },

      {
        name: 'workflow.title',
        icon: 'workflow',
        permission: Permissions.accessWorkflow,
        route: '/workflow',
      },

      {
        name: 'planner.title',
        icon: 'planner',
        permission: Permissions.accessPlanner,
        route: '/planner',
      },

      {
        name: 'projects.socialSecurityRegistrations.title',
        icon: 'social-security-registrations',
        permission: Permissions.accessSocialSecurityRegistrations,
        route: '/social-security-registrations',
      },

      {
        name: 'suppliers.title',
        icon: 'suppliers',
        route: '/suppliers',
        permission: Permissions.accessSuppliers,
      },

      {
        name: 'invoices.title',
        icon: 'invoices',
        permission: Permissions.accessInvoices,
        isActive: (route: any) => route.path.includes('/invoices/'),
        children: [
          {
            name: 'invoices.sales.title',
            icon: 'invoices',
            route: '/invoices/sales',
            permission: Permissions.accessSalesInvoices,
          },
          {
            name: 'invoices.purchase.title',
            icon: 'invoices',
            route: '/invoices/purchase',
            permission: Permissions.accessPurchaseInvoices,
          },
        ],
      },

      {
        name: 'reports.title',
        icon: 'reports',
        permission: Permissions.accessReports,
        isActive: (route: any) => route.path.includes('/reports/'),
        children: [
          {
            name: 'reports.grossMargin.title',
            icon: 'gross-margin',
            route: '/reports/gross-margin',
            permission: Permissions.accessReportsGrossMargin,
          },
        ],
      },

      {
        name: 'monthlyOverview.title',
        icon: 'invoices',
        route: '/monthly-overview',
        permission: Permissions.accessMonthlyOverview,
      },

      {
        name: 'settings.title',
        icon: 'settings',
        permission: Permissions.accessSettings,
        isActive: (route: any) => route.path.includes('/settings/'),
        children: [
          {
            name: 'settings.divisions.title',
            icon: 'internal-teams',
            route: '/settings/divisions',
            permission: Permissions.login,
          },
          {
            name: 'dictionary.employees',
            icon: 'team-leaders',
            route: '/settings/employees',
            permission: Permissions.accessEmployees,
          },
          {
            name: 'settings.collectiveHolidays.title',
            icon: 'holidays',
            route: '/settings/holidays',
            permission: Permissions.login,
          },
          {
            name: 'dictionary.permission',
            icon: 'user-roles',
            route: '/settings/permissions',
            permission: Permissions.accessPermissions,
          },
          {
            name: 'settings.collaboratorBranches.title',
            icon: 'contractor-types',
            route: '/settings/contractor-types',
            permission: Permissions.login,
          },
          {
            name: 'settings.accounting.title',
            icon: 'accounting',
            route: '/settings/accounting',
            permission: Permissions.login,
          },
          {
            name: 'settings.deleted.title',
            icon: 'deleted',
            route: '/settings/deleted',
            permission: Permissions.login,
          },
          {
            name: 'settings.logs.title',
            icon: 'activity-log',
            route: '/settings/activity-log',
            permission: Permissions.login,
          },
          {
            name: 'settings.profile.title',
            icon: 'profile',
            route: '/settings/profile',
            permission: Permissions.login,
          },
          {
            name: 'settings.workflow.title',
            icon: 'portrait',
            route: '/settings/workflow',
            permission: Permissions.login,
          },
          {
            name: 'dictionary.user',
            icon: 'portrait',
            route: '/settings/users',
            permission: Permissions.login,
          },
        ],
      },
    ];

    itemsBottom: any[] = [
      {
        title: this.logoutLabel,
        icon: 'logout',
        permission: 1,
        type: 2,
        onClick: this.logout,
      },
    ];

    async onChangeRole(role: Role) {
      if (this.user && this.user.id) {
        this.changingRoleLoading = true;
        const resp = await usersService.getUserById(this.user.id);
        const user = resp.data.data;
        user.role = role;
        await usersService
          .updateUserById(user, this.user.id)
          .then(() => this.$router.go(0))
          .catch(() =>
            appMessageStore.actions.set({
              message: 'dictionary.switchingUserRolesFailed',
              ttl: 10000,
              type: AppMessageType.WARNING,
              dismissed: false,
            })
          )
          .finally(() => (this.changingRoleLoading = false));
      }
    }

    get logoutLabel() {
      const user = this.user;
      return !authStore.auth.getters.isImpersonating.value ? 'Afmelden' : `Afmelden als ${user?.alias} (${user?.username})`;
    }

    get user() {
      return authStore.auth.getters.user.value
    }

    get currentRole() {
      return this.user?.role;
    }

    getItemIcon(item: any) {
      return this.getIcon(item.icon);
    }

    getIcon(icon: string) {
      switch (icon) {
        case 'home':
          return icons('./home.svg');
        case 'projects':
          return icons('./projects.svg');
        case 'workflow':
          return icons('./workflow.svg');
        case 'planner':
          return icons('./planner.svg');
        case 'suppliers':
          return icons('./suppliers.svg');
        case 'settings':
          return icons('./settings.svg');
        case 'internal-teams':
          return icons('./internal-teams.svg');
        case 'team-leaders':
          return icons('./team-leaders.svg');
        case 'holidays':
          return icons('./holidays.svg');
        case 'user-roles':
          return icons('./user-roles.svg');
        case 'contractor-types':
          return icons('./contractor-types.svg');
        case 'deleted':
          return icons('./deleted.svg');
        case 'activity-log':
          return icons('./activity-log.svg');
        case 'profile':
          return icons('./profile.svg');
        case 'logout':
          return icons('./logout.svg');
        case 'toggle':
          return icons('./toggle.svg');
        case 'invoices':
          return icons('./invoices.svg');
        case 'reports':
          return icons('./reports.svg');
        case 'gross-margin':
          return icons('./gross-margin.svg');
        case 'social-security-registrations':
          return icons('./social-security-registrations.svg');
        default:
          return icons('./profile.svg');
      }
    }

    get showFullDescMenu() {
      return this.shouldShowMenu;
    }

    set showFullDescMenu(val: any) {
      localStorage.setItem(SFINX_SHOW_MENU, val);
      this.shouldShowMenu = val;
    }

    path() {
      return this.$route.path;
    }

    mounted() {
      this.showFullDescMenu = localStorage.getItem(SFINX_SHOW_MENU) === String(true);
    }

    toggleMenu(e: Event) {
      e.preventDefault();
      this.showFullDescMenu = !this.showFullDescMenu;
    }

    showBtn(title: string) {
      switch (title) {
        case 'kennisgeving': {
          return this.$store.getters['planner/warnings'].conflicts.length > 0;
        }
      }
      return true;
    }

    editableBtn(title: string) {
      const history = this.$store.getters['planner/history'];
      switch (title) {
        case 'undo': {
          return history.isUndo;
        }
        case 'redo': {
          return history.isRedo;
        }
      }
      return true;
    }

    logout() {
      authStore.auth.actions
          .logout()
          .finally(() => {
            this.$router.push({ name: 'login' })
          })
    }
  }
