
  import { defineComponent } from 'vue';
  import { CalendarViewType } from '@/modules/calendar/types/CalendarViewType';
  import store from '../store';

  export default defineComponent({
    setup(props) {
      const saveDateRange = () => {
        store.calendarSettings.actions.saveDateRangeSelection().then(() => {
          store.calendar.actions.fetchAllExpandedProjects();
          store.calendar.actions.fetchAllExpandedContractors();
        });
      };
      return {
        CalendarViewType,
        environment: store.calendar,
        calendarSettings: store.calendarSettings,
        saveDateRange,
      };
    },
  });
