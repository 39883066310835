import useFilter from "@/composables/useFilter";
import useUserList from "@/composables/useUserList";

// shared state composables
const usersListViewFilter = useFilter()
const usersListView = useUserList(usersListViewFilter)

/**
 * Global state/store
 *   Because we can re-use composables as different states, we can also use 1 composable as a global state.
 *   This global state can be stored in a `store.ts` file.
 */
export const store: {
    usersListView: typeof usersListView;
} = {
	usersListView: usersListView
};