
	import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";

	@Component
	export default class BaseDialogComponent extends Vue {

		// properties
		@PropSync('showDialog', { type: Boolean }) showDialogSync!: boolean;

		@Prop({type: String, default:'eg: Dialog Title'}) 
		title!: string;

		@Prop({type: String, default: () => { return 'Text can be put here...'; }})
		text!: any;

		@Prop({type: Array, default:() => { return new Array<String>(); }}) 
		hideActions!: string[]; // eg; ['save', 'saveAndNew', 'cancel]

		@Prop({type: Array, default:() => { return new Array<String>(); }}) 
		hideSlots!: string[]; // eg; ['actions']

		@Prop({type: Boolean, default:false}) 
		loading!: boolean;

		@Prop({type: Boolean, default:true}) 
		stickyActions!: boolean;

		@Prop({type: Boolean, default:false}) 
		hideActionsOnScroll!: boolean;

		// vars
		hideActionsOnScrollToggle = false;

		// methods

		// handlers
		onClickSave(event: any, showNew: boolean = false): void {
			this.$emit('onClickSave', showNew);
		}
		onClickCancel(event: any): void {
			this.$emit('onClickCancel');
		}

		// getters
		get attrs() {
			// eslint-disable-next-line
			const { ...attrs } = this.$attrs; // all but input event
			return attrs;
		}
		get slots() {
			// eslint-disable-next-line
			const { ...slots } = this.$slots; // pass all to datatable slots
			return slots;
		}
		get scopedSlots() {
			// eslint-disable-next-line
			const { ...scopedSlots } = this.$scopedSlots; // pass all to datatable scopedSlots
			return scopedSlots;
		}

		// hooks
		mounted() {
		}
	}
