import {
  AccountingItem,
  ProjectRelationship,
  PurchaseInvoice,
  PurchaseInvoiceRecordType,
  TransactionType
} from './types/entities';
import {Vue} from 'vue-property-decorator';
import {Supplier} from '../entities/types/entities';
import {Payment, PaymentEntity} from '../payments/types/entities';
import {summarizeBy as paymentsSummarizeBy} from '@/modules/payments/helpers';
import {AccountingLedger, AccountingLedgerItem, Type} from '../accounting/types/entities';

export function signAmount(amount: number, recordType: PurchaseInvoiceRecordType): number {
  let value = 0;
  switch (recordType.name) {
  case 'credit_note':
    value = -1 * Math.abs(amount);
    break;
  case 'invoice':
    value = Math.abs(amount);
    break;
  default:
    break;
  }
  return value;
}

export function signAmountProjectRelationship(amount: number, type: TransactionType): number {
  let value = 0;
  switch (type) {
  case TransactionType.CREDIT:
    value = -1 * Math.abs(amount);
    break;
  case TransactionType.DEBIT:
    value = Math.abs(amount);
    break;
  default:
    break;
  }
  return value;
}

export function signAmountAccountingItem(amount: number, type: TransactionType): number {

  let value = 0;
  switch (type) {
  case TransactionType.CREDIT:
    value = -1 * Math.abs(amount);
    break;
  case TransactionType.DEBIT:
    value = Math.abs(amount);
    break;
  default:
    break;
  }
  return value;



  // if (!ledgerItemType) return 0;
  //
  // if (ledgerItemType === Type.REVENUE && transactionType === TransactionType.DEBIT) return Math.abs(amount);
  // if (ledgerItemType === Type.REVENUE && transactionType === TransactionType.CREDIT) return -1 * Math.abs(amount);
  // if (ledgerItemType === Type.EXPENSE && transactionType === TransactionType.DEBIT) return -1 * Math.abs(amount);
  // if (ledgerItemType === Type.EXPENSE && transactionType === TransactionType.CREDIT) return Math.abs(amount);
  // return 0;
}

export function summarizeProjectRelationshipsBy(key: any, items: ProjectRelationship[], sign = true): number {
  return items.reduce((a: number, b: ProjectRelationship) => a + (sign && b.type ? signAmountProjectRelationship((b as any)[key] || 0, b.type) : ((b as any)[key] || 0)), 0);
}

export function summarizeAccountingItemsBy(key: any, items: AccountingItem[], sign = false): number {
  return items.reduce((a: number, b: AccountingItem) => a + (sign && b.type ? signAmountAccountingItem((b as any)[key] || 0, b.type) : ((b as any)[key] || 0)), 0);
}

export function calculateProjectRelationshipsDifference(invoice: PurchaseInvoice, projectRelationships: ProjectRelationship[], field: keyof typeof invoice = 'total'): number {
  if (invoice.total && invoice.recordType && invoice.projectRelationships) {
    // use unsigned values for easier calculation
    const total = invoice[field] as number;
    const creditProjectRelationshipsTotal = summarizeProjectRelationshipsBy(field, projectRelationships.filter(p => p.type === TransactionType.CREDIT), false);
    const debitProjectRelationshipsTotal = summarizeProjectRelationshipsBy(field, projectRelationships.filter(p => p.type === TransactionType.DEBIT), false);
    const balance = debitProjectRelationshipsTotal - creditProjectRelationshipsTotal;

    if (invoice.recordType.name === 'invoice') {
      return -1 * (total - balance);
    }
    else if (invoice.recordType.name === 'credit_note') {
      return -1 * (total + balance);
    }

    return 0;
  }
  return 0;
}

export function calculateAccountingItemsDifference(invoice: PurchaseInvoice, accountingItems: AccountingItem[], invoiceField: keyof typeof invoice = 'total', itemField: keyof AccountingItem = 'total'): number {
  const value = invoice[invoiceField] as number;
  if (value != null && invoice.recordType) {
    // use unsigned values for easier calculation
    const creditAccountingItemsTotal = summarizeAccountingItemsBy(itemField, accountingItems.filter(p => p.type === TransactionType.CREDIT), false);
    const debitAccountingItemsTotal = summarizeAccountingItemsBy(itemField, accountingItems.filter(p => p.type === TransactionType.DEBIT), false);
    const balance = debitAccountingItemsTotal - creditAccountingItemsTotal;

    if (invoice.recordType.name === 'invoice') {
      return -1 * (value - balance);
    }
    else if (invoice.recordType.name === 'credit_note') {
      return -1 * (value + balance);
    }

    return 0;
  }
  return 0;
}

export function calculatePaymentDifference(invoice: PurchaseInvoice, payments: Payment[]): number {
  if (invoice.paymentTotal && invoice.recordType && invoice.payments) {
    // use unsigned values for easier calculation
    const paymentTotal = invoice.paymentTotal;
    const creditPaymentsTotal = paymentsSummarizeBy('total', payments.filter(p => p.type === TransactionType.CREDIT), false);
    const debitPaymentsTotal = paymentsSummarizeBy('total', payments.filter(p => p.type === TransactionType.DEBIT), false);
    const balance = debitPaymentsTotal - creditPaymentsTotal;

    if (invoice.recordType.name === 'invoice') {
      return -1 * (paymentTotal + balance);
    }
    else if (invoice.recordType.name === 'credit_note') {
      return -1 * (paymentTotal - balance);
    }

    return 0;
  }
  return 0;
}

export function suggestPayment(invoice: PurchaseInvoice, payments: Payment[]): Payment {
  const payment = new PaymentEntity({type: TransactionType.DEBIT});
  if (invoice.recordType && invoice.recordType.name === 'invoice') {
    payment.type = calculatePaymentDifference(invoice, payments) <= 0 ? TransactionType.CREDIT : TransactionType.DEBIT;
  } else if (invoice.recordType && invoice.recordType.name === 'credit_note') {
    payment.type = calculatePaymentDifference(invoice, payments) <= 0 ? TransactionType.DEBIT : TransactionType.CREDIT;
  }

  return payment;
}

export function calculatePaymentDueDateForSupplier(purchaseInvoice: PurchaseInvoice, supplier: Supplier, defaultTerm = 30): string | undefined {
  const term = supplier.preferredPaymentTerm ? supplier.preferredPaymentTerm : defaultTerm;
  if (term > 0 && purchaseInvoice.invoiceDate) {
    const extendToEndOfMonth = supplier && supplier.preferredPaymentTermBoolean;
    let paymentDueDate = Vue.prototype.$dayjs(purchaseInvoice.invoiceDate).add(term, 'day');
    paymentDueDate = extendToEndOfMonth ? paymentDueDate.endOf('month') : paymentDueDate;
    return paymentDueDate.format('YYYY-MM-DD');
  }
}

export function calculatePaymentDiscountDueDateForSupplier(purchaseInvoice: PurchaseInvoice, supplier: Supplier, defaultTerm = 0): string | undefined {
  const term = supplier.preferredPaymentDiscountTerm ? supplier.preferredPaymentDiscountTerm : defaultTerm;
  if (term > 0 && purchaseInvoice.invoiceDate) {
    const extendToEndOfMonth = supplier && supplier.preferredPaymentDiscountTermBoolean;
    let paymentDueDate = Vue.prototype.$dayjs(purchaseInvoice.invoiceDate).add(term, 'day');
    paymentDueDate = extendToEndOfMonth ? paymentDueDate.endOf('month') : paymentDueDate;
    return paymentDueDate.format('YYYY-MM-DD');
  }
}

export function calculateGrandTotal(purchaseInvoice: PurchaseInvoice): number {
  if (purchaseInvoice.currency && purchaseInvoice.total !== undefined && purchaseInvoice.taxTotal !== undefined) {
    try {
      const total = Vue.prototype.$dinero({amount: purchaseInvoice.total, currency: purchaseInvoice.currency});
      const taxTotal = Vue.prototype.$dinero({amount: purchaseInvoice.taxTotal, currency: purchaseInvoice.currency});
      const grandTotal = total.add(taxTotal);
      return grandTotal.getAmount();
    } catch (error: any) {
      console.warn(error);
      return 0;
    }
  }
  return 0;
}

export function calculateGrandTotalForAccountingItem(accountingItem: AccountingItem): number {
  if (accountingItem.currency && accountingItem.total !== undefined && accountingItem.taxTotal !== undefined) {
    try {
      const total = Vue.prototype.$dinero({amount: accountingItem.total, currency: accountingItem.currency});
      const taxTotal = Vue.prototype.$dinero({amount: accountingItem.taxTotal, currency: accountingItem.currency});
      const grandTotal = total.add(taxTotal);
      return grandTotal.getAmount();
    } catch (error: any) {
      console.warn(error);
      return 0;
    }
  }
  return 0;
}

export function calculateTaxTotalForAccountingItem(accountingItem: AccountingItem): number {
  if (accountingItem.currency && accountingItem.vat && accountingItem.total !== undefined && accountingItem.total !== null) {
    try {
      const total = Vue.prototype.$dinero({amount: accountingItem.total, currency: accountingItem.currency});
      const taxTotal = accountingItem.vat
        ? total.multiply(accountingItem.vat.percentage / 100, 'HALF_UP')
        : Vue.prototype.$dinero({amount: 0, currency: accountingItem.currency});

      return taxTotal.getAmount();
    } catch (error: any) {
      console.warn(error);
      return 0;
    }
  }
  return 0;
}

export function calculatePaymentTotal(purchaseInvoice: PurchaseInvoice): number {
  if (purchaseInvoice.currency) {
    try {
      const grandTotal = Vue.prototype.$dinero({
        amount: calculateGrandTotal(purchaseInvoice) || 0,
        currency: purchaseInvoice.currency,
      });
      const discount = Vue.prototype.$dinero({
        amount: Math.abs(purchaseInvoice.paymentDiscount || 0),
        currency: purchaseInvoice.currency,
      });
      return grandTotal.subtract(discount).getAmount();
    } catch (error: any) {
      console.warn(error);
      return 0;
    }
  }
  return 0;
}

export function calculateAmountByPercentage(total: number, percentage: number, currency = 'EUR'): number {
  // allocate discount, result is array of 2 dinero objects: first one = remainder, second one = discount amount (same order as allocation)
  const val = Vue.prototype.$dinero({amount: total, currency: currency});
  const discount = val.allocate([100 - percentage, percentage])[1];
  return discount.getAmount();
}

export function calculatePaymentDiscountByPercentage(purchaseInvoice: PurchaseInvoice, discountPercentage: number): number {
  if (purchaseInvoice.grandTotal !== undefined && purchaseInvoice.currency) {
    try {
      return calculateAmountByPercentage(purchaseInvoice.grandTotal, discountPercentage, purchaseInvoice.currency);
    } catch (error: any) {
      return 0;
    }
  }
  return 0;
}

export function filterProjectRelationshipsByStatusName(projectRelationships: ProjectRelationship[], allowedStatuses: string[]): ProjectRelationship[] {
  return projectRelationships.filter(rel => rel.status && rel.status.name && allowedStatuses.includes(rel.status.name));
}

export function filterProjectRelationshipsByApprovalStatusName(projectRelationships: ProjectRelationship[], allowedStatuses: string[]): ProjectRelationship[] {
  return projectRelationships.filter(rel => rel.approvalStatus && rel.approvalStatus.name && allowedStatuses.includes(rel.approvalStatus.name));
}

export function filterAccountingLedgerItemsForProjectRelationship(projectRelationship: ProjectRelationship, accountingLedger: AccountingLedger): AccountingLedgerItem[] {

  const ledgerItems: AccountingLedgerItem[] = (accountingLedger?.accountingLedgerItems) || ([] as AccountingLedgerItem[]);

  if(projectRelationship.isProjectRequired) {
    return ledgerItems.filter(ledgerItem => ledgerItem.isProjectRelated);
  }

  return ledgerItems;
}