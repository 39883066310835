import {Supplier, SupplierType} from "@/modules/entities/types/entities";
import {PaginationDetails} from "@/core/types/Entities";
import {ContractorBranch} from "@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState";
import { ProvinceEntity } from '@/modules/entities/types/province.entity';
import { CompanyTypeEntity } from '@/modules/entities/types/company-type.entity';
import { LogItem, Team } from '@/modules/settings/types/entities';

export interface SupplierState {
	isLoading: boolean;
	suppliers: Supplier[];
	contractors: Array<Supplier | Team>;
	paginationDetails?: PaginationDetails;
	inspectedSupplier?: Supplier;
	supplierTypes: SupplierType[];
	currentSupplierType: string;
	contractorTypes: ContractorBranch[];
	currentContractorTypes: ContractorBranch[];
	contractorFilters: ContractorsStateFilters;
	filters: SupplierStateFilters;
	contactTypes: any;
	province: ProvinceEntity[];
	companyTypes: CompanyTypeEntity[];
	activityLog: SuppliersActivityLog;
}

export interface ContractorsStateFilters {
	nameQuery: string;
}

export interface SupplierStateFilters {
	sideBarOpen: boolean;
	nameQuery: string | null;
	mainQuery: string | null;
	selectedSupplierTypeIds: number[];
	supplierTypeFilterActive: boolean;
	selectedContractorTypeIds: number[];
	contractorTypeFilterActive: boolean;
	selectedPriceNumbers: number[];
	priceFilterActive: boolean;
	selectedQualityNumbers: number[];
	qualityFilterActive: boolean;
	selectedTechnicalExpertiseNumbers: number[];
	technicalExpertiseFilterActive: boolean;
	selectedAestheticQualityNumbers: number[];
	aestheticQualityFilterActive: boolean;
	selectedFutureWorkNumbers: number[];
	futureWorkFilterActive: boolean;
	additionalFiltersActive: boolean;
	offerReceivedFilterToggled: boolean;
	workedTogetherFilterToggled: boolean;
	plannedFilterToggled: boolean;
}

export interface SuppliersActivityLog {
	lastItem: {
		item: any;
		index: number;
	};
	lastBackendResponse?: LogItem;
	lastOperationType?: SuppliersLastActionType;
}


export enum SuppliersLastActionType {
	EDIT = 'EDIT',
	DELETE = 'DELETE'
}
