
import { PropType } from 'vue';
import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Actions, Getters } from '@/modules/accounting/store/types/StoreTypes';
import { AxiosError, AxiosResponse } from 'axios';
import { AccountingLedger, AccountingLedgerEntity, AccountingLedgerItem, AccountingLedgerItemEntity } from '../../types/entities';
import { JsonResource } from '@/core/types/Entities';
import { DataTableHeader } from 'vuetify';
import AccountingLedgerItemUpdateModalComponent from '../accounting-ledger-item-update-modal/accounting-ledger-item-update-modal.component.vue'
import AppInputComponent from '@/core/components/app-input/app-input.component.vue';
import AppTextareaComponent from '@/core/components/app-textarea/app-textarea.component.vue';
import { focusInput } from '@/core/helpers/input';

const accounting = namespace('accounting')
enum PartialEdit {
	EDIT_LEDGER_NAME = 'editLedgerName',
	EDIT_LEDGER_START_AND_END_DATE = 'editLedgerStartAndEndDate',
	EDIT_LEDGER_DESCRIPTION = 'editLedgerDescription',
}

@Component({
	components: {
		'accounting-ledger-item-update-modal': AccountingLedgerItemUpdateModalComponent,
		'accounting-ledger-item-create-modal': AccountingLedgerItemUpdateModalComponent,
	}
})
export default class AccountingLedgerModalComponent extends Vue {

	// actions
	@accounting.Action(Actions.SAVE_ACCOUNTING_LEDGER_BY_ID) saveAccountingLedger!: (payload: AccountingLedger) => Promise<AxiosResponse<JsonResource<AccountingLedger>>>;
	@accounting.Action(Actions.CREATE_ACCOUNTING_LEDGER_ITEM) createAccountingLedgerItem!: (payload: AccountingLedgerItem) => void;
	@accounting.Action(Actions.FETCH_ACCOUNTING_LEDGER_ITEM) fetchAccountingLedgerItemById!: (payload: {accountingLedgerId: string, accountingLedgerItemId: string }) => Promise<AxiosResponse<JsonResource<AccountingLedgerItem>>>;

	// getters
	@accounting.Getter(Getters.GET_ACCOUNTING_LEDGER_ITEM) accountingLedgerItem!: AccountingLedgerItem;
	@accounting.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;

	// props
	@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
	@Prop({ type: Object as PropType<AccountingLedger>, default: () => { return new AccountingLedgerEntity() } }) newDefaultAccountingLedger!: AccountingLedger
	// this will be the vuex store value, so we always need to make a copy before editing
	@Prop({ type: Object as PropType<AccountingLedger> }) accountingLedger!: AccountingLedger

	@Ref('name') name!: AppInputComponent
	@Ref('description') description!: AppTextareaComponent

	// local vars
	validationErrors: any = null;
	state: {
		showNew: boolean,
		showModalUpdateAccountingLedgerItem: boolean,
		showModalCreateAccountingLedgerItem: boolean,
	} = {
		showNew: false,
		showModalUpdateAccountingLedgerItem: false,
		showModalCreateAccountingLedgerItem: false,
	}
	content: {
		edit: AccountingLedger,
	} = {
		edit: {} as AccountingLedger,
	}

	// handlers
	onClickAddAccountingLedgerItem() {
		this.createAccountingLedgerItem(new AccountingLedgerItemEntity({
			active: true,
			isProjectRelated: false,
			accountingLedger: JSON.parse(JSON.stringify(this.accountingLedger)),
		}));
		this.state.showModalCreateAccountingLedgerItem = true;
	}
	onClickEditAccountingLedgerItem(item: AccountingLedgerItem) {
		const accountingLedgerId = (this.accountingLedger && this.accountingLedger.id) || null;
		const accountingLedgerItemId = item.id;
		if(accountingLedgerId && accountingLedgerItemId) {
			this.state.showModalUpdateAccountingLedgerItem = true
			this.fetchAccountingLedgerItemById({accountingLedgerId: accountingLedgerId.toString(), accountingLedgerItemId: accountingLedgerItemId.toString()}).then(() => {
				// this.state.showModalUpdateAccountingLedgerItem = true
			});
		}
	}

	// methods
	getPartialContent(partialEdit: PartialEdit, key?: string): object|null {
		const content = (this.content as any)[partialEdit]
		if(content && key) return content[key] ? content[key] : null
		else if(content) return content
		else return null
	}
	getPartialState(partialEdit: PartialEdit, key?: string): boolean|object {
		const state = (this.state as any)[partialEdit]
		if(state && key) return state[key] ? state[key] : false
		else if(state) return state
		else return false
	}
	activate(e: any, partialEdit: PartialEdit, key?: string) {
		let partialContent = this.getPartialContent(partialEdit, key)
		partialContent = partialContent ? partialContent : JSON.parse(JSON.stringify(this.accountingLedger)) as AccountingLedger
		this.$set(this.content, partialEdit, key ? {...this.getPartialContent(partialEdit), [key]: this.baseWithPartial(partialEdit, partialContent as AccountingLedger, key) } : this.baseWithPartial(partialEdit, partialContent as AccountingLedger, key))
		this.$set(this.state, partialEdit, key ? {...(this.getPartialState(partialEdit) as object), [key]: true } : true)
		this.focus(e, partialEdit);
	}
	submit(e: any, partialEdit: PartialEdit, key?: string) {
		const accountingLedger = this.baseWithPartial(partialEdit, key ?  (this.content as any)[partialEdit][key] : (this.content as any)[partialEdit], key)
		if(accountingLedger) {
			this.onSaveAccountingLedger(accountingLedger)
				.then(result => {
					let partialContent = this.getPartialContent(partialEdit)
					partialContent = partialContent ? partialContent : JSON.parse(JSON.stringify(this.accountingLedger))
					this.$set(this.content, partialEdit, key ? {...partialContent, [key]: null } : null)
					this.$set(this.state, partialEdit, key ? {...(this.getPartialState(partialEdit) as object), [key]: false } : false)
				})
				.catch(err => {})
		}
	}
	submitSilent(e: any, partialEdit: PartialEdit, key?: string) {
		const accountingLedger = this.baseWithPartial(partialEdit, key ?  (this.content as any)[partialEdit][key] : (this.content as any)[partialEdit], key)
		if(accountingLedger) {
			this.onSaveAccountingLedger(accountingLedger).then(result => {}).catch(err => {})
		}
	}
	cancel(e: any, partialEdit: PartialEdit, key?: string) {
		this.$set(this.state, partialEdit, key ? {...(this.getPartialState(partialEdit) as object), [key]: false } : false)
	}
	focus(e: Event, partialEdit: PartialEdit, select: boolean = true) {
		switch(partialEdit) {
			case PartialEdit.EDIT_LEDGER_NAME:
				this.$nextTick(() => focusInput(this.name.$el.getElementsByTagName('input')[0], select))
				break;
			case PartialEdit.EDIT_LEDGER_DESCRIPTION:
				this.$nextTick(() => focusInput(this.description.$el.getElementsByTagName('textarea')[0], false))
				break;
		}
	}
	baseWithPartial(partialEdit: PartialEdit, editedModel: AccountingLedger, key?: string) {
		const base = JSON.parse(JSON.stringify(this.accountingLedger)) as AccountingLedger;
		switch(partialEdit) {
			case PartialEdit.EDIT_LEDGER_NAME:
				return Object.assign(base, { name: editedModel.name } as AccountingLedger)
			case PartialEdit.EDIT_LEDGER_START_AND_END_DATE:
				return Object.assign(base, { startDate: editedModel.startDate, endDate: editedModel.endDate } as AccountingLedger)
			case PartialEdit.EDIT_LEDGER_DESCRIPTION:
				return Object.assign(base, { description: editedModel.description } as AccountingLedger)
			default:
				return base
		}
	}
	onSaveAccountingLedger(accountingLedger: AccountingLedger): Promise<AccountingLedger> {
		return new Promise<AccountingLedger>((resolve, reject) => {
			this.saveAccountingLedger(accountingLedger).then((resp) => {
				this.validationErrors = null;
				resolve(resp.data.data);
			}).catch((err: AxiosError) => {
				if (err.response && err.response.status === 422) {
					this.validationErrors = err.response.data.errors;
				}
				reject(err)
			});
		});
	}
	closeModal() {
		this.state.showNew = false
		this.showModalSync = false
	}
	newAccountingLedger(): AccountingLedger {
		return JSON.parse(JSON.stringify(this.newDefaultAccountingLedger)) as AccountingLedger
	}

	//getters
	get showSelectedStartDate(): string {
		const accountingLedger = this.getPartialContent(PartialEdit.EDIT_LEDGER_START_AND_END_DATE) as AccountingLedger
		if(accountingLedger) {
			return Vue.prototype.$formatDate(accountingLedger.startDate);
		}
		return '';
	}
	get showSelectedEndDate(): string {
		const accountingLedger = this.getPartialContent(PartialEdit.EDIT_LEDGER_START_AND_END_DATE) as AccountingLedger
		if(accountingLedger) {
			return Vue.prototype.$formatDate(accountingLedger.endDate);
		}
		return '';
	}
	get headers(): Array<DataTableHeader> {
		return [
			{
				text: this.$i18n.t('accounting.accountingLedgerItem.details.labels.name').toString(),
				value: 'name',
				groupable: false,
				class:'text-no-wrap'
			} as DataTableHeader,
			{
				text: this.$i18n.t('accounting.accountingLedgerItem.details.labels.description').toString(),
				value: 'description',
				groupable: false,
				class:'text-no-wrap',
			} as DataTableHeader,
			{
				text: this.$i18n.t('accounting.accountingLedgerItem.details.labels.active').toString(),
				value: 'active',
				groupable: false,
				class:'text-no-wrap',
			} as DataTableHeader,
			{
				text: this.$i18n.t('accounting.accountingLedgerItem.details.labels.isProjectRelated').toString(),
				value: 'isProjectRelated',
				groupable: false,
				class:'text-no-wrap',
			} as DataTableHeader,
			{ text: '', value: 'actions', sortable: false, groupable: false, align: 'center'} as DataTableHeader,
		];
	}

	// watchrs

	// boot methods
	created(): void {
		this.content.edit = JSON.parse(JSON.stringify(this.accountingLedger));
	}

	destroyed(): void {
		//
	}
}
