export default {
	common: {},
	home: {},
	projects: {},
	workflow: {
		title: 'Workflow',
		noConstructionSitesSelected: 'No construction sites selected.'
	},
	planner: {},
	siteManagement: {},
	entity: {},
	finances: {},
	administration: {},
	settings: {},

	$vuetify: {
		dataIterator: {
			rowsPerPageText: 'Items per page:',
			rowsPerPageAll: 'All',
			pageText: '{0}-{1} of {2}',
			noResultsText: 'No matching records found',
			nextPage: 'Next page',
			prevPage: 'Previous page'
		},
		dataTable: {
			rowsPerPageText: 'Rows per page:'
		},
		noDataText: 'No data available'
	}
};
