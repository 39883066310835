
  import CalendarHeaderComponent from '@/modules/calendar/components/calendar-header.component.vue';
  import CalendarGridHeaderComponent from '@/modules/calendar/components/calendar-grid-header.component.vue';
  import CalendarGridComponent from '@/modules/calendar/components/calendar-grid.component.vue';
  import {computed, defineComponent, toRaw, unref, watch} from 'vue';
  import PopupComponent from '@/core/components/delete-popup/delete-popup.component.vue';
  import SidemenuDetailsPeriodComponent from '@/modules/calendar/components/sidemenu-details-period.component.vue';
  import SidemenuDetailsDayComponent from '@/modules/calendar/components/sidemenu-details-day.component.vue';
  import SidemenuDetailsMoveProjectComponent from '@/modules/calendar/components/sidemenu-details-move-project.component.vue';
  import store from '@/modules/calendar/store';
  import helpers from '../services/helper.service';
  import { CalendarDialog } from '../types/CalendarDialog';
  import SettingsComponent from '../components/settings.component.vue';
  import { CalendarViewType } from '../types/CalendarViewType';
  import useCalendar from "@/modules/calendar/composables/useCalendar";
  import useCalendarSettings from "@/modules/calendar/composables/useCalendarSettings";

  export default defineComponent({
    data: () => {
      return {
        bottomScrollbarWidth: 0,
      };
    },
    components: {
      'calendar-header': CalendarHeaderComponent,
      'calendar-grid-header': CalendarGridHeaderComponent,
      'calendar-grid': CalendarGridComponent,
      'popup-dialog': PopupComponent,
      'sidemenu-details-period': SidemenuDetailsPeriodComponent,
      'sidemenu-details-day': SidemenuDetailsDayComponent,
      'sidemenu-details-move-project': SidemenuDetailsMoveProjectComponent,
      settings: SettingsComponent,
    },
    methods: {
      recalculateBottomScrollBarWidth(): void {
        this.$nextTick(() => {
          // const array = document.getElementsByClassName('cells-container');
          // const gridHeaderButtonsElement = document.getElementById('grid-header__buttons');
          // if (array.length && gridHeaderButtonsElement) {
          // 	this.bottomScrollbarWidth = array.item(0)!.clientWidth + gridHeaderButtonsElement.clientWidth + 20;
          // }
          const el = document.getElementById('calendar-header');
          if (el) {
            this.bottomScrollbarWidth = el.scrollWidth;
          }
        });
      },
      onScrollBarScroll(event: Event): void {
        this.$nextTick(() => {
          const target = event.target as HTMLDivElement;
          const gridHeader = document.getElementById('calendar-header');
          if (gridHeader) {
            this.handleScrollEvent(target, gridHeader);
          } else {
            event.preventDefault();
          }
        });
      },
      handleScrollEvent(target: HTMLDivElement, ...args: HTMLDivElement[] | HTMLElement[]): void {
        args.forEach((x: HTMLElement | HTMLDivElement) => {
          x.scrollLeft = target.scrollLeft;
        });
        const elements = document.getElementsByClassName('planner-grid__list__collaborators-grid__container');
        Array.from(elements).forEach((el: Element, idx: number) => {
          el.scrollLeft = target.scrollLeft;
        });
      },
      checkURLParams() {
        // Access the URL parameters using this.$route.query
        if (Object.keys(this.$route.query).length > 0) {
          const expandedProjects = parseInt(String(this.$route.query.expandedProjects));
          const expandedDivisions = parseInt(String(this.$route.query.expandedDivisions));
          const expandedSuppliers = parseInt(String(this.$route.query.expandedSuppliers));
          const startDate = String(this.$route.query.startDate);
          // Define an array to store all the promises
          const expansionPromises = [];

          // Check if the parameter exists and add corresponding expansion action promises to the array
          if (expandedProjects) {
            expansionPromises.push(store.calendar.actions.toggleProjectExpanded(expandedProjects));
          }

          if (expandedDivisions) {
            expansionPromises.push(store.calendar.actions.toggleDivisionExpanded(expandedDivisions));
          }

          if (expandedSuppliers) {
            expansionPromises.push(store.calendar.actions.toggleSupplierExpanded(expandedSuppliers));
          }

          // Execute all expansion promises and then perform the scrolling action
          Promise.all(expansionPromises)
            .then(() => {
              if (startDate) {
                const elementWithDataAttribute = document.querySelectorAll<HTMLElement>(`[data-date="${startDate}"]`);
                // If an element is found, scroll it into view
                if (elementWithDataAttribute.length > 0) {
                  elementWithDataAttribute[0].scrollIntoView({block: 'center',inline:'center'});

                  // Style all elements with the specified data-date attribute
                  elementWithDataAttribute.forEach(element => {
                    if(element.style.backgroundColor != 'rgb(0, 181, 160)'){
                      element.style.backgroundColor = 'rgb(137 179 238 / 40%)'; // styling
                    }
                  });

                }
              }
            })
            .catch(error => {
              console.error('Error expanding elements:', error);
            });
        }
      },
    },
    async mounted() {
      await store.calendarSettings.actions.fetchEnvironment();
      await store.calendar.actions.fetchAvailableTeams();
      // Await the fetch actions and then recalculate contractor column
      await Promise.all([
        store.calendar.actions.fetchProjects(),
        store.calendar.actions.fetchDivisions(),
        store.calendar.actions.fetchSuppliers()
      ]).then(() => {
        // All fetch actions are done, recalculate contractor column
        helpers.recalculateContractorColumn();
        // Now you can call checkURLParams()
        this.checkURLParams();
      });
    },
    updated() {
      this.recalculateBottomScrollBarWidth();
    },
    setup() {
        const isLoading = computed(() => {
        return store.calendar.getters.isLoading.value || store.calendarSettings.getters.isLoading.value
      })

      return {
        calendar: store.calendar,
        calendarSettings: store.calendarSettings,
        CalendarDialog,
        helpers,
        isLoading,
      };
    },
  });
