import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';

import {
	ActivityLogLastActionType, TaskListType,
	WorkflowState
} from '@/modules/settings/store/modules/workflow/types/WorkflowState';
import { RootState } from '@/core/types/RootState';
import { Actions, Mutations } from '@/modules/settings/store/modules/workflow/types/StoreTypes';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { WorkflowFunctionGroup, WorkflowTask, WorkflowTaskStatus } from '@/modules/workflow/types/entities';
import { LogItem } from '@/modules/settings/types/entities';

export const actions: ActionTree<WorkflowState, RootState> = {
	[Actions.FETCH_WORKFLOW_TASKS]: async ({commit, state}: ActionContext<WorkflowState, RootState>) => {
		let url = '/api/v1/settings/workflow-tasks?';
		if (state.filters.fetchArchived) {
			url += `archived=${ state.filters.fetchArchived }`;
			if (state.filters.sortByArchivedAt === 'asc' || state.filters.sortByArchivedAt === 'desc') {
				url += `&sort=${state.filters.sortByArchivedAt}`;
			}
		} else if (state.filters.sortBySequence === 'asc' || state.filters.sortBySequence === 'desc') {
			url += `&sort=${state.filters.sortBySequence}`;
		}

		const res: AxiosResponse<JsonResource<WorkflowTask[]>> = await Vue.prototype.$http.get(url);

		if (res.status === 200) {
			commit(Mutations.MUTATE_WORKFLOW_TASKS, res.data.data);
			// commit(Mutations.MUTATE_PAGINATION_DETAILS, res.data);
		}
	},
	[Actions.FETCH_WORKFLOW_TASK_STATUSES]: async ({ commit }: ActionContext<WorkflowState, RootState>) => {
		const res: AxiosResponse<JsonResource<WorkflowTaskStatus[]>> = await Vue.prototype.$http.get(`/api/v1/settings/workflow-task-statuses`);
		commit(Mutations.MUTATE_WORKFLOW_ACTIVE_LIST_TYPE, TaskListType.TASK_STATUSES);
		commit(Mutations.MUTATE_WORKFLOW_TASK_STATUSES, res.data.data);
		commit(Mutations.MUTATE_PAGINATION_DETAILS, res.data);
	},
	[Actions.FETCH_WORKFLOW_FUNCTION_GROUPS]: async ({commit}: ActionContext<WorkflowState, RootState>) => {
		const res: AxiosResponse<JsonResource<WorkflowFunctionGroup[]>> = await Vue.prototype.$http.get(`/api/v1/settings/workflow-function-groups`);
		commit(Mutations.MUTATE_WORKFLOW_ACTIVE_LIST_TYPE, TaskListType.FUNCTION_GROUPS);
		commit(Mutations.MUTATE_WORKFLOW_FUNCTION_GROUPS, res.data.data);
		commit(Mutations.MUTATE_PAGINATION_DETAILS, res.data)
	},
	[Actions.FETCH_HIGHEST_TASK_SEQUENCE]: async ({commit}: ActionContext<WorkflowState, RootState>) => {
		const res: AxiosResponse = await Vue.prototype.$http.get(`/api/v1/commands/workflow/get-highest-task-sequence`);

		if (res.status === 200) {
			commit(Mutations.MUTATE_WORKFLOW_HIGHEST_TASK_SEQUENCE, res.data.highestSequence);
		}
	},
	[Actions.FETCH_NEXT_PAGE]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: string) => {
		if (state.paginationDetails && state.paginationDetails.next) {
			let url = state.paginationDetails.next;
			if (state.filters.fetchArchived) {
				url += `&archived=true`;
			}
			const res: AxiosResponse<Pagination<any[]>> = await Vue.prototype.$http.get(url);

			if (res.status === 200) {
				commit(Mutations.MUTATE_PUSH_NEXT_PAGE, { pagination: res.data, listType: payload });
			}
		}
	},
	[Actions.EDIT_WORKFLOW_TASK_BY_ID]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: WorkflowTask) => {
		const res = await Vue.prototype.$http.put(`/api/v1/settings/workflow-tasks/${payload.id}`, payload);

		if (res.status === 200) {
			const item = state.tasks.find((task: WorkflowTask) => task.id === payload.id);
			const updatedWorkflowTasks = state.tasks.map((task: WorkflowTask) => {
				return task.id !== payload.id ? task : res.data.data
			});
			const activityLog = {
				lastActionType: ActivityLogLastActionType.EDIT,
				lastItem: {
					item: item,
					index: -1
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: !!res.data.logItem
			};

			commit(Mutations.MUTATE_WORKFLOW_TASKS, updatedWorkflowTasks);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
		}
	},
	[Actions.EDIT_WORKFLOW_FUNCTION_GROUP_BY_ID]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: WorkflowFunctionGroup) => {
		const res: AxiosResponse = await Vue.prototype.$http.put(`/api/v1/settings/workflow-function-groups/${payload.id}`, payload);

		if (res.status === 200) {
			const item = state.functionGroups.find(x => x.id === payload.id);
			const updatedWorkflowFunctionGroups = state.functionGroups.map((x: WorkflowFunctionGroup) => {
				return x.id !== payload.id ? x : res.data.data
			});
			const activityLog = {
				lastActionType: ActivityLogLastActionType.EDIT,
				lastItem: {
					item: item,
					index: -1
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: true
			};

			commit(Mutations.MUTATE_WORKFLOW_FUNCTION_GROUPS, updatedWorkflowFunctionGroups);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
		}
	},
	[Actions.EDIT_WORKFLOW_TASK_STATUS_BY_ID]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: WorkflowTaskStatus) => {
		const res: AxiosResponse = await Vue.prototype.$http.put(`/api/v1/settings/workflow-task-statuses/${payload.id}`, payload);

		if (res.status === 200) {
			const item = state.taskStatuses.find(x => x.id === payload.id);
			const updatedTaskStatusesList = state.taskStatuses.map((x: WorkflowTaskStatus) => {
				return x.id !== payload.id ? x : res.data.data
			});
			const activityLog = {
				lastActionType: ActivityLogLastActionType.EDIT,
				lastItem: {
					item: item,
					index: -1
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: true
			};

			commit(Mutations.MUTATE_WORKFLOW_TASK_STATUSES, updatedTaskStatusesList);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
		}
	},
	[Actions.ARCHIVE_WORKFLOW_TASK_BY_ID]: async ({ commit, state, dispatch }: ActionContext<WorkflowState, RootState>, payload: { workflowTask: WorkflowTask; archive: boolean }) => {
		let url = `/api/v1/settings/workflow-tasks/${payload.workflowTask.id}?archive=${payload.archive}`;
		if (state.filters.sortBySequence === 'asc' || state.filters.sortBySequence === 'desc') url += `?sort=${state.filters.sortBySequence}`;
		const res: AxiosResponse = await Vue.prototype.$http.put(`/api/v1/settings/workflow-tasks/${payload.workflowTask.id}?archive=${payload.archive}`, payload);

		if (res.status === 200) {
			const archivedIndex = state.tasks.findIndex(task => task.id === payload.workflowTask.id);
			const activityLog = {
				lastActionType: ActivityLogLastActionType.ARCHIVE,
				lastItem: {
					item: payload.workflowTask,
					index: archivedIndex
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: true
			};

			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
			dispatch(Actions.FETCH_WORKFLOW_TASKS);
		}
	},
	[Actions.DELETE_WORKFLOW_TASK_BY_ID]: async ({ commit, state, dispatch }: ActionContext<WorkflowState, RootState>, payload: WorkflowTask) => {
		let url = `/api/v1/settings/workflow-tasks/${payload.id}`;
		if (state.filters.sortBySequence === 'asc' || state.filters.sortBySequence === 'desc') url += `?sort=${state.filters.sortBySequence}`;
		const res = await Vue.prototype.$http.delete(url);

		if (res.status === 200) {
			let deletedIndex;
			if (payload.parentTaskId) {
				deletedIndex = state.tasks.find((task: WorkflowTask) => {
					return task.id === payload.parentTaskId
				})!.children!.findIndex((childTask: WorkflowTask) => childTask.id === payload.id)
			} else {
				deletedIndex = state.tasks.findIndex(x => x.id === payload.id);
			}
			const activityLog = {
				lastActionType: ActivityLogLastActionType.DELETE,
				lastItem: {
					item: payload,
					index: deletedIndex
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: true
			};

			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
			dispatch(Actions.FETCH_WORKFLOW_TASKS);
		}
	},
	[Actions.DELETE_MULTI_WORKFLOW_TASK_BY_ID]: async ({ commit, state, dispatch }: ActionContext<WorkflowState, RootState>, payload: number[]) => {
		const res: AxiosResponse = await Vue.prototype.$http.delete(`/api/v1/commands/workflow/tasks-multi-operations/${payload.join()}`);

		if (res.status === 200) {
			const activityLog = {
				lastActionType: ActivityLogLastActionType.DELETE_MULTIPLE,
				lastItem: {
					item: undefined,
					index: -1
				},
				lastBackendResponse: undefined,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: false
			};
			// let updatedTasks: WorkflowTask[] = JSON.parse(JSON.stringify(state.tasks));
			// updatedTasks = updatedTasks.filter(task => {
			// 	if (!task.children) {
			// 		return !payload.some(id => id === task.id);
			// 	}
			// 	return (task.children = task.children.filter(childTask => {
			// 		return !payload.some(id => id === childTask.id)
			// 	})) && !payload.some(id => id === task.id)
			// });

			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
			dispatch(Actions.FETCH_WORKFLOW_TASKS);
		}
	},
	[Actions.ARCHIVE_MULTI_WORKFLOW_TASK_BY_ID]: async ({ commit, state, dispatch }: ActionContext<WorkflowState, RootState>, payload: number[]) => {
		const res: AxiosResponse = await Vue.prototype.$http.put(`/api/v1/commands/workflow/tasks-multi-operations?archive=true&ids=${payload}`);

		if (res.status === 200) {
			const activityLog = {
				lastActionType: ActivityLogLastActionType.ARCHIVE_MULTIPLE,
				lastItem: {
					item: undefined,
					index: -1
				},
				lastBackendResponse: undefined,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: false
			};
			// let updatedTasks: WorkflowTask[] = JSON.parse(JSON.stringify(state.tasks));
			//
			// updatedTasks = updatedTasks.filter(task => {
			// 	if (!task.children) {
			// 		return !payload.some(id => id === task.id);
			// 	}
			// 	return (task.children = task.children.filter(childTask => {
			// 		return !payload.some(id => id === childTask.id)
			// 	})) && !payload.some(id => id === task.id)
			// });

			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
			dispatch(Actions.FETCH_WORKFLOW_TASKS);
		}
	},
	[Actions.UN_ARCHIVE_MULTI_WORKFLOW_TASK_BY_ID]: async ({ commit, state }: ActionContext<WorkflowState, RootState>, payload: number[]) => {
		const res: AxiosResponse = await Vue.prototype.$http.put(`/api/v1/commands/workflow/tasks-multi-operations?archive=false&ids=${payload}`);

		if (res.status === 200) {
			const activityLog = {
				lastActionType: ActivityLogLastActionType.UN_ARCHIVE_MULTIPLE,
				lastItem: {
					item: undefined,
					index: -1
				},
				lastBackendResponse: undefined,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: false
			};
			let updatedTasks: WorkflowTask[] = JSON.parse(JSON.stringify(state.tasks));

			updatedTasks = updatedTasks.filter(task => {
				if (!task.children) {
					return !payload.some(id => id === task.id);
				}
				return (task.children = task.children.filter(childTask => {
					return !payload.some(id => id === childTask.id)
				})) && !payload.some(id => id === task.id)
			});

			commit(Mutations.MUTATE_WORKFLOW_TASKS, updatedTasks);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
		}
	},
	[Actions.DELETE_WORKFLOW_TASK_STATUS_BY_ID]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: WorkflowTaskStatus) => {
		const res: AxiosResponse = await Vue.prototype.$http.delete(`/api/v1/settings/workflow-task-statuses/${payload.id}`);

		if (res.status === 200) {
			const deletedIndex = state.taskStatuses.findIndex(x => x.id === payload.id);
			const updatedTaskStatusesList = state.taskStatuses.filter(taskStatus => taskStatus.id !== payload.id);
			const activityLog = {
				lastActionType: ActivityLogLastActionType.DELETE,
				lastItem: {
					item: payload,
					index: deletedIndex
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: true
			};

			commit(Mutations.MUTATE_WORKFLOW_TASK_STATUSES, updatedTaskStatusesList);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
		}
	},
	[Actions.DELETE_WORKFLOW_FUNCTION_GROUP_BY_ID]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: WorkflowFunctionGroup) => {
		const res: AxiosResponse = await Vue.prototype.$http.delete(`/api/v1/settings/workflow-function-groups/${payload.id}`);

		if (res.status === 200) {
			const deletedIndex = state.functionGroups.findIndex(x => x.id === payload.id);
			const updatedFunctionGroupsList = state.functionGroups.filter((functionGroup: WorkflowFunctionGroup) => functionGroup.id !== payload.id);
			const activityLog = {
				lastActionType: ActivityLogLastActionType.DELETE,
				lastItem: {
					item: payload,
					index: deletedIndex
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: true
			};

			commit(Mutations.MUTATE_WORKFLOW_FUNCTION_GROUPS, updatedFunctionGroupsList);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
		}
	},
	[Actions.CREATE_NEW_WORKFLOW_TASK]: async ({commit, state, dispatch }: ActionContext<WorkflowState, RootState>, payload: WorkflowTask) => {
		const res = await Vue.prototype.$http.post(`/api/v1/settings/workflow-tasks`, payload);

		if (res.status === 200 || res.status === 201) {
			const activityLog = {
				lastActionType: ActivityLogLastActionType.CREATE,
				lastItem: {
					item: res.data.data,
					index: -1
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: true
			};

			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
			dispatch(Actions.FETCH_WORKFLOW_TASKS);
		}
	},
	[Actions.CREATE_NEW_WORKFLOW_TASK_STATUS]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: { status: string; symbol: string; description: string; colour: string}) => {
		const res: AxiosResponse = await Vue.prototype.$http.post(`/api/v1/settings/workflow-task-statuses`, payload);

		if (res.status === 200 || res.status === 201) {
			const updatedTaskStatusesList: WorkflowTaskStatus[] = [...state.taskStatuses];
			const index = updatedTaskStatusesList.push(res.data.data);
			const activityLog = {
				lastActionType: ActivityLogLastActionType.CREATE,
				lastItem: {
					item: res.data.data,
					index: index - 1
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: true
			};

			commit(Mutations.MUTATE_WORKFLOW_TASK_STATUSES, updatedTaskStatusesList);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
		}
	},
	[Actions.CREATE_NEW_WORKFLOW_FUNCTION_GROUP]: async ({commit, state}: ActionContext<WorkflowState, RootState>, payload: {name: string; description: string; color: string}) => {
		const res: AxiosResponse = await Vue.prototype.$http.post(`/api/v1/settings/workflow-function-groups`, payload);

		if (res.status === 200 || res.status === 201) {
			const updatedFunctionGroupList: WorkflowFunctionGroup[] = [...state.functionGroups];
			const index = updatedFunctionGroupList.push(res.data.data);
			const activityLog = {
				lastActionType: ActivityLogLastActionType.CREATE,
				lastItem: {
					item: res.data.data,
					index: index -1
				},
				lastBackendResponse: res.data.logItem,
				lastOperationListType: state.activeListType
			};
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: true
			};

			commit(Mutations.MUTATE_WORKFLOW_FUNCTION_GROUPS, updatedFunctionGroupList);
			commit(Mutations.MUTATE_WORKFLOW_ACTIVITY_LOG, activityLog);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
		}
	},
	[Actions.REVERT_LAST_ACTION]: async ({commit, state, dispatch}: ActionContext<WorkflowState, RootState>, payload: LogItem) => {
		const requestBody = {
			type: payload.subjectType,
			id: payload.subjectId
		};
		let res;

		switch (state.activityLog.lastOperationType) {
			case ActivityLogLastActionType.EDIT:
				res = await Vue.prototype.$http.post(`/api/v1/commands/revert-edition`, requestBody);

				if (res.status === 200) {
					commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false);

					let updatedList;
					switch (payload.subjectType) {
						case 'App\\Models\\Common\\WorkflowTask':
							if (state.activityLog.lastOperationListType === state.activeListType) {
								updatedList = state.tasks.map((task: WorkflowTask) => {
									return task.id !== payload.subjectId ? task : state.activityLog.lastItem.item;
								});
								commit(Mutations.MUTATE_WORKFLOW_TASKS, updatedList);
							}
							break;
						case 'App\\Models\\Common\\WorkflowTaskStatus':
							updatedList = state.taskStatuses.map((x: WorkflowTaskStatus) => {
								return x.id !== payload.subjectId ? x : state.activityLog.lastItem.item;
							});
							commit(Mutations.MUTATE_WORKFLOW_TASK_STATUSES, updatedList);
							break;
						case 'App\\Models\\Common\\WorkflowFunctionGroup':
							updatedList = state.functionGroups.map((x: WorkflowFunctionGroup) => {
								return x.id !== payload.subjectId ? x : state.activityLog.lastItem.item;
							});
							commit(Mutations.MUTATE_WORKFLOW_FUNCTION_GROUPS, updatedList);
							break;
						default:
							break;
					}
				}
				break;
			case ActivityLogLastActionType.ARCHIVE:
				res = await Vue.prototype.$http.post(`/api/v1/commands/revert-edition`, requestBody);

				if (res.status === 200) {
					commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false);
					dispatch(Actions.FETCH_WORKFLOW_TASKS);
				}
				break;
			case ActivityLogLastActionType.DELETE:
				res = await Vue.prototype.$http.post('/api/v1/commands/revert-deletion', requestBody);

				if (res.status === 200) {
					commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, false);

					let updatedList;
					switch (payload.subjectType) {
						case 'App\\Models\\Common\\WorkflowTask':
							dispatch(Actions.FETCH_WORKFLOW_TASKS);
							break;
						case 'App\\Models\\Common\\WorkflowTaskStatus':
							updatedList = [...state.taskStatuses];
							updatedList.splice(state.activityLog.lastItem.index, 0, state.activityLog.lastItem.item);
							commit(Mutations.MUTATE_WORKFLOW_TASK_STATUSES, updatedList);
							break;
						case 'App\\Models\\Common\\WorkflowFunctionGroup':
							updatedList = [...state.functionGroups];
							updatedList.splice(state.activityLog.lastItem.index, 0, state.activityLog.lastItem.item);
							commit(Mutations.MUTATE_WORKFLOW_FUNCTION_GROUPS, updatedList);
							break;
						default:
							break;
					}
				}
				break;
			case ActivityLogLastActionType.CREATE:
				res = await Vue.prototype.$http.post('/api/v1/commands/revert-creation', requestBody);

				if (res.status === 200) {
					commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, false);

					let updatedList;
					switch (payload.subjectType) {
						case 'App\\Models\\Common\\WorkflowTask':
							if (state.activityLog.lastOperationListType === state.activeListType) {
								updatedList = [...state.tasks];
								updatedList.splice(state.activityLog.lastItem.index, 1);
								commit(Mutations.MUTATE_WORKFLOW_TASKS, updatedList);
							}
							break;
						case 'App\\Models\\Common\\WorkflowTaskStatus':
							updatedList = [...state.taskStatuses];
							updatedList.splice(state.activityLog.lastItem.index, 1);
							commit(Mutations.MUTATE_WORKFLOW_TASK_STATUSES, updatedList);
							break;
						case 'App\\Models\\Common\\WorkflowFunctionGroup':
							updatedList = [...state.functionGroups];
							updatedList.splice(state.activityLog.lastItem.index, 1);
							commit(Mutations.MUTATE_WORKFLOW_FUNCTION_GROUPS, updatedList);
							break;
						default:
							break;
					}
				}
				break;
			default:
				break;
		}
	},
	[Actions.SET_WORKFLOW_FILTERS_SORT_BY_SEQUENCE]: async ({commit}: ActionContext<WorkflowState, RootState>) => {
		commit(Mutations.MUTATE_WORKFLOW_FILTERS_SORT_BY_SEQUENCE);
	},
	[Actions.SET_WORKFLOW_FILTERS_SORT_BY_ARCHIVED_AT]: async ({ commit, dispatch }: ActionContext<WorkflowState, RootState>) => {
		commit(Mutations.MUTATE_WORKFLOW_FILTERS_SORT_BY_ARCHIVED_AT);
		dispatch(Actions.FETCH_WORKFLOW_TASKS)
	},
	[Actions.SET_WORKFLOW_FILTERS_FETCH_ARCHIVED]: async ({ commit, dispatch }: ActionContext<WorkflowState, RootState>, payload: boolean) => {
		if (payload) {
			commit(Mutations.MUTATE_WORKFLOW_ACTIVE_LIST_TYPE, TaskListType.TASKS_ARCHIVED)
		} else {
			commit(Mutations.MUTATE_WORKFLOW_ACTIVE_LIST_TYPE, TaskListType.TASKS_ONGOING)
		}
		commit(Mutations.MUTATE_WORKFLOW_FILTERS_FETCH_ARCHIVED, payload);
		dispatch(Actions.FETCH_WORKFLOW_TASKS);
	},
	[Actions.CHANGE_WORKFLOW_TASK_SEQUENCE]: async ({ commit, dispatch }: ActionContext<WorkflowState, RootState>, payload: WorkflowTask) => {
		const res: AxiosResponse = await Vue.prototype.$http.put(`/api/v1/commands/workflow/update-sequence`, payload);

		if (res.status === 200) {
			const infoMessage = {
				text: res.data.message,
				visibility: true,
				buttonVisible: false
			};

			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE, infoMessage);
			const timeout = setTimeout(() => {
				commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_VISIBILITY, false)
			}, 3500);
			commit(Mutations.MUTATE_WORKFLOW_INFO_MESSAGE_TIMEOUT, timeout);
			dispatch(Actions.FETCH_WORKFLOW_TASKS);
		}
	}
};
