
	import {Component, Vue} from 'vue-property-decorator';
	import {CreateElement, VNode} from 'vue';

	@Component
	export default class MultiscrollGridComponent extends Vue {
		onScroll(e: Event) {
			console.log(e);
		}

		render(h: CreateElement) {
			let scrollableElements: any = [];

			let elIndex = 0;
			for (let node in this.$slots) {
				if (node === 'default') {
					this.$slots[node]!.forEach((vEl: VNode) => {
						let newvEl: VNode = h(vEl.tag, {
							...vEl.data,
							on: {
								wheel: (e: Event) => this.onScroll(e)
							}
						});

						scrollableElements.push(newvEl);
					})
				} else {

				}
				// if (node === 'default') {
				// 	this.$slots[node]!.forEach((vEl) => {
				// 		scrollableElements.push(
				// 			h('div', {
				// 				ref: `multiScrollEl-${elIndex}`,
				// 				on: {
				// 					wheel: (e: Event) => this.onScroll(e)
				// 				}
				// 			}, [
				// 				vEl
				// 			])
				// 		);
				// 	});
				// } else {
				// 	scrollableElements.push(
				// 		h('div', {
				// 			ref: `multiScrollEl-${elIndex}`,
				// 			on: {
				// 				wheel: (e: Event) => this.onScroll(e)
				// 			}
				// 		}, this.$slots[node])
				// 	);
				// }

				elIndex++;
			}

			return h('div', [
				...scrollableElements
			]);
		}
	}
