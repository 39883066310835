import axios, { AxiosResponse, AxiosPromise } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import { Team, TeamMember2 } from '@/modules/settings/types/entities';

const BASE_ENDPOINT = '/api/v1/settings/teams';

export class TeamsService {

	getBaseEndpoint(): string {
		return BASE_ENDPOINT
	}

	getAllTeams(url = `${BASE_ENDPOINT}`): Promise<AxiosResponse<Pagination<Team[]>>> {
		return axios.get(url);
	}

	getTeamById(id: number): Promise<AxiosResponse<JsonResource<Team>>> {
		return axios.get<JsonResource<Team>>(`${ BASE_ENDPOINT }/${ id }`);
	}

	createTeam(payload: Team): AxiosPromise<JsonResource<Team>> {
		return axios.post(`${ BASE_ENDPOINT }`, payload);
	}

	createTeamMember(teamId: number, teamMember: TeamMember2|null): AxiosPromise<JsonResource<Team>> {
		return axios.post(`${ BASE_ENDPOINT }/${ teamId }/team-members`, teamMember);
	}

	updateTeamMember(teamId: number, teamMember: TeamMember2): AxiosPromise<JsonResource<Team>> {
		return axios.put(`${ BASE_ENDPOINT }/${ teamId }/team-members/${ teamMember.id }`, teamMember);
	}

	deleteTeamMember(teamId: number, teamMemberId: number): AxiosPromise<JsonResource<Team>> {
		return axios.delete(`${ BASE_ENDPOINT }/${ teamId }/team-members/${ teamMemberId }`);
	}

	editTeam(payload: Team): AxiosPromise<JsonResource<Team>> {
		return axios.put(`${ BASE_ENDPOINT }/${ payload.id }`, payload);
	}

	deleteTeam(payload: number): AxiosPromise {
		return axios.delete(`${ BASE_ENDPOINT }/${ payload }`);
	}
}
