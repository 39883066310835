import axios, { AxiosPromise } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import { PurchaseInvoice } from '../types/entities';

const BASE_ENDPOINT = '/api/v1/commands/invoices';

export class PurchaseInvoicesCommandsService {

  getNewInvoice(): AxiosPromise<JsonResource<PurchaseInvoice>> {
    return axios.get<JsonResource<PurchaseInvoice>>(`${BASE_ENDPOINT}/new-purchase-invoice`);
  }

  getMyPurchaseInvoicesToBeCompleted(url?: string, status = 'open'): AxiosPromise<Pagination<PurchaseInvoice[]>> {
    const urlParams = new URLSearchParams();
    urlParams.set('filter[status]', status);
    return url
      ? axios.get<Pagination<PurchaseInvoice[]>>(`${ url }`)
      : axios.get<Pagination<PurchaseInvoice[]>>(`${BASE_ENDPOINT}/my-purchase-invoices-to-be-completed?${urlParams.toString()}`);
  }

  getDuplicatePurchaseInvoicesFor(purchaseInvoice: PurchaseInvoice): AxiosPromise<Pagination<PurchaseInvoice[]>> {
    return axios.post<Pagination<PurchaseInvoice[]>>(`${BASE_ENDPOINT}/duplicate-purchase-invoices`, purchaseInvoice);
  }

}
