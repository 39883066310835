

	import { Component, Emit, Vue } from 'vue-property-decorator';
	import _ from 'lodash';

	@Component
	export default class InfiniteScrollComponent extends Vue {
		private throttleEmitScrollToBottom = _.throttle(this.emitScrollToBottom, 1000, {
			trailing: false
		});

		@Emit('scrollToBottom')
		emitScrollToBottom() {
			return true;
		}

		scrollHandler(event: { target: HTMLElement }) {
			if (event.target.scrollHeight - event.target.scrollTop - event.target.offsetHeight < 500) {
				this.throttleEmitScrollToBottom();
			}
		}
	}
