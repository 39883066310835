export const enum Actions {
	FETCH_TAXES = 'FETCH_TAXES',
}

export const enum Getters {
	GET_TAXES = 'GET_TAXES',
	GET_TAXES_FOR_SALES = 'GET_TAXES_FOR_SALES',
	GET_TAXES_FOR_PURCHASES = 'GET_TAXES_FOR_PURCHASES',
}

export const enum Mutations {
	MUTATE_TAXES = 'MUTATE_TAXES',
}
