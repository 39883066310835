import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { Project, ProjectStatus, ThirdParty, ProjectContractorBranch } from '@/modules/projects/types/entities';
import { Client, ProjectClient } from '@/modules/entities/types/entities';
import { SocialSecurityRegistration } from '@/modules/projects/types/social-security-registrations';

const BASE_ENDPOINT = '/api/v1/projects';

export class ProjectsService {

  getBaseEndpoint(): string {
    return BASE_ENDPOINT;
  }

  createNewProject(project: Project): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.post<JsonResource<Project>>(BASE_ENDPOINT, project);
  }

  editProjectById(project: Project): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.put<JsonResource<Project>>(`${ BASE_ENDPOINT }/${ project.id }`, project);
  }

  editSocialSecurityRegistrationById(socialSecurityRegistration: SocialSecurityRegistration): Promise<AxiosResponse<JsonResource<SocialSecurityRegistration>>> {
    return axios.put<JsonResource<SocialSecurityRegistration>>(`/api/v1/social-security-registrations/${ socialSecurityRegistration.id }`, socialSecurityRegistration);
  }

  editProjectClientById(projectClient: ProjectClient): Promise<AxiosResponse<JsonResource<ProjectClient>>> {
    return axios.put<JsonResource<ProjectClient>>(`/api/v1/projects/${projectClient.projectId}/clients/${ projectClient.id }/certificates/${projectClient.id}`, projectClient);
  }

  deleteProjectById(projectId: number): AxiosPromise<void> {
    return axios.delete(`${ BASE_ENDPOINT }/${ projectId }`);
  }
  
  getAllProjects(url = `${ BASE_ENDPOINT }`): Promise<AxiosResponse<Pagination<Project[]>>> {
    return axios.get<Pagination<Project[]>>(url);
  }

  getAllProjectStatuses(): Promise<AxiosResponse<JsonResource<ProjectStatus[]>>> {
    return axios.get<JsonResource<ProjectStatus[]>>('/api/v1/project-statuses');
  }

  getProjectById(projectId: number): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.get<JsonResource<Project>>(`${ BASE_ENDPOINT }/${ projectId }`);
  }

  getAllSocialSecurityRegistrations(): Promise<AxiosResponse<JsonResource<SocialSecurityRegistration[]>>> {
    return axios.get<JsonResource<SocialSecurityRegistration[]>>('/api/v1/social-security-registrations');
  }

  getAllClientsForProjectById(projectId: number): Promise<AxiosResponse<JsonResource<Client[]>>> {
    return axios.get<JsonResource<Client[]>>(`${ BASE_ENDPOINT }/${ projectId }/clients`);
  }

  attachThirdPartyToProject(projectId: number, supplierTypeId: number): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.post<JsonResource<Project>>(`${ BASE_ENDPOINT }/${ projectId }/third-parties`, supplierTypeId);
  }

  removeThirdPartyFromProject(projectId: number, thirdPartyId: number): AxiosPromise<void> {
    return axios.delete(`${ BASE_ENDPOINT }/${ projectId }/third-parties/${ thirdPartyId }`);
  }

  attachContractorBranchToProject(projectId: number, contractorBranch: ProjectContractorBranch): Promise<AxiosResponse<JsonResource<ProjectContractorBranch>>> {
    return axios.post<JsonResource<ProjectContractorBranch>>(`${ BASE_ENDPOINT }/${ projectId }/contractor-types`, contractorBranch);
  }

  editAttachedContractorBranchToProject(projectId: number, contractorBranch: ProjectContractorBranch): Promise<AxiosResponse<JsonResource<ProjectContractorBranch>>> {
    return axios.put<JsonResource<ProjectContractorBranch>>(`${ BASE_ENDPOINT }/${ projectId }/contractor-types/${ contractorBranch.id }`, contractorBranch);
  }

  removeContractorBranchFromProject(projectId: number, contractorBranchId: number): AxiosPromise<void> {
    return axios.delete(`${ BASE_ENDPOINT }/${ projectId }/contractor-types/${ contractorBranchId }`);
  }

  toggleAssociationSupplierToThirdParty(projectId: number, thirdPartyId: number, requestBody: { supplierId: number | null; supplierTypeId: number }): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.put<JsonResource<Project>>(`${ BASE_ENDPOINT }/${ projectId }/third-parties/${ thirdPartyId }`, requestBody);
  }

  toggleAssociationThirdParty(projectId: number, thirdPartyId: number, thirdParty: ThirdParty): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.put<JsonResource<Project>>(`${ BASE_ENDPOINT }/${ projectId }/third-parties/${ thirdPartyId }`, thirdParty);
  }

  toggleAssociationClientToProject(projectId: number, clientId: number): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.put<JsonResource<Project>>(`${ BASE_ENDPOINT }/${ projectId }/clients/${ clientId }`);
  }

  toggleAssociationContactToProject(projectId: number, contactId: number): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.put<JsonResource<Project>>(`${ BASE_ENDPOINT }/${ projectId }/contacts/${ contactId }`);
  }

  archiveProjectById(projectId: number): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.put<JsonResource<Project>>(`/api/v1/commands/projects/archive/${ projectId }`);
  }

  unarchiveProjectById(projectId: number): Promise<AxiosResponse<JsonResource<Project>>> {
    return axios.put<JsonResource<Project>>(`/api/v1/commands/projects/un-archive/${ projectId }`);
  }
}
