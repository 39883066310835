

import { Filter } from '@/core/types/Filter';
import { Supplier, User } from '@/modules/entities/types/entities';
import { Actions, Getters } from '@/modules/reports/store/types/StoreTypes';
import { Project, ProjectStatus } from '@/modules/projects/types/entities';
import { PropType } from 'vue';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from "vuex-class";
import _ from 'lodash';
import { GrossMarginFilterState } from '../../store/types/ReportState';
import ProjectAutocompleteComponent from '@/modules/reports/components/project-autocomplete/project-autocomplete.component.vue'
import { ProjectStatusMixin } from '@/mixins/project-status-mixin.component';
import { PayloadParameters } from '../../store/actions';
import Fuse from 'fuse.js'
import datetimeCalculate from '@/core/helpers/DatetimeCalculator'
import { ContractorBranch } from "@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState";
import SupplierAutocompleteComponent from '../supplier-autocomplete/supplier-autocomplete.component.vue';

const reports = namespace("reports");

@Component({
	components: {
		'project-autocomplete': ProjectAutocompleteComponent,
		'supplier-autocomplete': SupplierAutocompleteComponent
	},
	mixins: [ProjectStatusMixin]
})
export default class GrossMarginSideMenuComponent extends Vue {

	// store
	@reports.Action(Actions.TOGGLE_GROSS_MARGIN_FILTER_SIDEBAR) toggleFilterSidebar!: () => void;
	@reports.Action(Actions.TOGGLE_GROSS_MARGIN_FILTER_GROUP) toggleFilterGroup!: (payload: PayloadParameters<string>) => void;
	@reports.Action(Actions.SEARCH_PROJECTS) searchProjects!: (payload: string) => Promise<Project[]>;
	@reports.Action(Actions.SEARCH_SUPPLIERS) searchSuppliers!: (payload: string) => Promise<Supplier[]>;
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_PROJECT) filterOnProject!: (payload: PayloadParameters<Project[]|null>) => void;
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_SUPPLIER) filterOnSupplier!: (payload: PayloadParameters<Supplier|null>) => void;
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_PROJECT_STATUS) filterOnProjectStatus!: (payload: number) => void;
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_PROJECT_LEADER) filterOnProjectLeader!: (payload: number) => void;
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_PROJECT_ESTIMATOR) filterOnProjectEstimator!: (payload: number) => void;
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_CONTRACTOR_TYPES) setContractorTypeFilter!: (payload: number) => void;
	// Total Amount Ordered 
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED) filterOnMinAmountOrdered!: (payload: number | null) => void
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED) filterOnMaxAmountOrdered!: (payload: number | null) => void
	// Total Amount Ordered Contractor Branch
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH) filterOnMinAmountOrderedContractorBranch!: (payload: {contractorBranchId: string | null, minAmountOrdered: number | null} | null) => void
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH) filterOnMaxAmountOrderedContractorBranch!: (payload: {contractorBranchId: string | null, maxAmountOrdered: number | null} | null) => void
	// Startdate planning
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_FROM) filterOnFirstPlannerDateFrom!: (payload: string | null) => void
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_TO) filterOnFirstPlannerDateTo!: (payload: string | null) => void
	//Enddate planning
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_FROM) filterOnLastPlannerDateFrom!: (payload: string | null) => void
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_TO) filterOnLastPlannerDateTo!: (payload: string | null) => void
	//Date first sales invoice
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_FROM) filterOnFirstSalesInvoiceDateFrom!: (payload: string | null) => void
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_TO) filterOnFirstSalesInvoiceDateTo!: (payload: string | null) => void
	// Date last sales invoice
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_FROM) filterOnLastSalesInvoiceDateFrom!: (payload: string | null) => void
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_TO) filterOnLastSalesInvoiceDateTo!: (payload: string | null) => void
	// Date first purchase invoice
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_FROM) filterOnFirstPurchaseInvoiceDateFrom!: (payload: string | null) => void
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_TO) filterOnFirstPurchaseInvoiceDateTo!: (payload: string | null) => void
	// Date first purchase invoice
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_FROM) filterOnLastPurchaseInvoiceDateFrom!: (payload: string | null) => void
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_TO) filterOnLastPurchaseInvoiceDateTo!: (payload: string | null) => void

	@reports.Action(Actions.TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_STATUSES) toggleShowMoreProjectStatuses!: () => void;
	@reports.Action(Actions.TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_LEADERS) toggleShowMoreProjectLeaders!: () => void;
	@reports.Action(Actions.TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_ESTIMATORS) toggleShowMoreProjectEstimators!: () => void;

	// Completed contractor branches
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_CONTRACTOR_BRANCHES) filterOnshowOnlyCompletedContractorBranches!: (payload: boolean) => void;
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_RELEVANT_CONTRACTOR_BRANCHES) filterOnshowOnlyRelevantContractorBranches!: (payload: boolean) => void;
	@reports.Action(Actions.SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_PROJECTS) filterOnshowOnlyCompletedProjects!: (payload: boolean) => void;

	@reports.Action(Actions.CLEAR_GROSS_MARGIN_FILTERS) clearFilters!: (payload: PayloadParameters<string[]|null>) => void;
	// getters
	@reports.Getter(Getters.GET_SEARCH_PROJECTS) projects!: Project[];
	@reports.Getter(Getters.GET_SEARCH_SUPPLIERS) suppliers!: Supplier[];
	@reports.Getter(Getters.GET_SEARCH_CONTRACTOR_BRANCHES) contractorBranches!: ContractorBranch[];


	// props
	@Prop() filterState!: GrossMarginFilterState;
	@Prop({ type: Array as PropType<ProjectStatus[]>, default: () => [] }) availableProjectStatuses!: ProjectStatus[];
	@Prop({ type: Array as PropType<User[]>, default: () => [] }) availableUsers!: User[];
	@Prop({ type: Array as PropType<ContractorBranch[]>, default: () => [] }) availableContractorBranches!: ContractorBranch[];


	// local vars
	state: {
		searchingProjects: boolean,
		searchingSuppliers: boolean,
		projectStatusFilterSearchValue: string,
		projectStatusFilterSearchFuseOptions: any,
		projectLeaderFilterSearchValue: string,
		projectLeaderFilterSearchFuseOptions: any,
		projectEstimatorFilterSearchValue: string,
		projectEstimatorFilterSearchFuseOptions: any,
		projectMinAmountOrderedSearchValue: number | null,
		projectMaxAmountOrderedSearchValue: number | null,
		projectMinAmountOrderedContractorBranchSearchValue: number | null,
		projectMaxAmountOrderedContractorBranchSearchValue: number | null,
		showOnlyCompletedContractorBranches: boolean,
		showOnlyRelevantContractorBranches: boolean,
		showOnlyCompletedProjects: boolean,

	} = {
			searchingProjects: false,
			searchingSuppliers: false,
			projectStatusFilterSearchValue: '',
			projectStatusFilterSearchFuseOptions: { keys: ['label'], threshold: 0.4 },
			projectLeaderFilterSearchValue: '',
			projectLeaderFilterSearchFuseOptions: { keys: ['lastName', 'firstName'], threshold: 0.4 },
			projectEstimatorFilterSearchValue: '',
			projectEstimatorFilterSearchFuseOptions: { keys: ['lastName', 'firstName'], threshold: 0.4 },
			projectMinAmountOrderedSearchValue: null,
			projectMaxAmountOrderedSearchValue: null,
			projectMinAmountOrderedContractorBranchSearchValue: null,
			projectMaxAmountOrderedContractorBranchSearchValue: null,
			showOnlyCompletedContractorBranches: true,
			showOnlyRelevantContractorBranches: true,
			showOnlyCompletedProjects: false,
		}	
	quickSelectDateCalculator: any = datetimeCalculate()


	// handlers
	onSearchProject(searchTerm: string) {
		if(searchTerm) this.onSearchProjectDebounced(searchTerm, this)
	}
	onSearchProjectDebounced = _.debounce((searchTerm: string, vm: Vue) => {
		vm.$data.state = { ...vm.$data.state, searchingProjects: true }
		this.searchProjects(searchTerm).then(() => { vm.$data.state = {...vm.$data.state, searchingProjects: false} });
	}, 300);
	onChangeProjects(projects: Project[]) {
		if(projects.length > 0) {
			if(!this.filterGroupIsActive('projects')) this.toggleFilterGroup({payload:'projects', fetch: false})
			this.filterOnProject({payload: projects, fetch: true})
		}
		else {
			if(this.filterGroupIsActive('projects')) this.toggleFilterGroup({payload:'projects', fetch: false})
			this.filterOnProject({payload: null, fetch: true})
		}
	}

	onSearchSupplier(searchTerm: string) {
		if(searchTerm) this.onSearchSupplierDebounced(searchTerm, this)
	}
	onSearchSupplierDebounced = _.debounce((searchTerm: string, vm: Vue) => {
		vm.$data.state = { ...vm.$data.state, searchingSuppliers: true }
		this.searchSuppliers(searchTerm).then(() => { vm.$data.state = {...vm.$data.state, searchingSuppliers: false} });
	}, 300);
	onChangeSuppliers(suppliers: Supplier) {
		if(this.suppliers.length > 0) {
			if(!this.filterGroupIsActive('suppliers')) this.toggleFilterGroup({payload:'suppliers', fetch: false})
			this.filterOnSupplier({payload: suppliers, fetch: true})
		}
		else {
			if(this.filterGroupIsActive('suppliers')) this.toggleFilterGroup({payload:'suppliers', fetch: false})
			this.filterOnSupplier({payload: null, fetch: true})
		}
	}

		// methods
		// 	parentFilterGroupIsActive(group: string) {
		// 	for (let [key, filter] of Object.entries<Filter<any>>(this.filterState.filters)) {
		// 		if(filter.group === group && filter.active) return true;
		// 	}
		// 	return false;
		// }

	// methods
	filterGroupIsActive(group: string) {
		for (let [key, filter] of Object.entries<Filter<any>>(this.filterState.filters)) {
			if(filter.group === group && filter.active) return true;
		}
		return false;
	}
	filterValuesSelected(filterName: string) {
		for (let [key, filter] of Object.entries<Filter<any>>(this.filterState.filters)) {	
			if(filter.name === filterName && filter.hasValues()){
				return true;
		}
			} 
		return false;
	}
	filterValueSelected(filterName: string, filterValue: any) {
		for (let [key, filter] of Object.entries<Filter<any>>(this.filterState.filters)) {
			if(filter.name === filterName && filter.value.includes(filterValue)) return true;
		}
		return false;
	}
	getProjectLeadersFilterSearchFuse(projectLeaders: User[]) { return new Fuse(projectLeaders, this.state.projectLeaderFilterSearchFuseOptions) }
	getProjectEstimatorsFilterSearchFuse(projectEstimators: User[]) { return new Fuse(projectEstimators, this.state.projectEstimatorFilterSearchFuseOptions) }


	onEnterBlurInputField(event: Event) {
		(event.target as HTMLInputElement).blur();
	}

	// Amount ordered
	onUpdateMinAmountOrdered() {
		if(this.state.projectMinAmountOrderedSearchValue != null || this.state.projectMinAmountOrderedSearchValue == null && this.filterValuesSelected("minAmountOrdered")){
		this.filterOnMinAmountOrdered(this.state.projectMinAmountOrderedSearchValue);
		}
    }
    onUpdateMaxAmountOrdered() {
		if(this.state.projectMaxAmountOrderedSearchValue != null){
		this.filterOnMaxAmountOrdered(this.state.projectMaxAmountOrderedSearchValue);
		}
    }

	// Amount ordered Contractor Branch
	onUpdateContractorBranch() {
		if (this.filterState.selectedContractorBranch?.id && this.state.projectMinAmountOrderedContractorBranchSearchValue != null) {
			let contractorBranchId = this.filterState.selectedContractorBranch?.id == undefined ? null : this.filterState.selectedContractorBranch?.id.toString();
			this.filterOnMinAmountOrderedContractorBranch({ contractorBranchId: contractorBranchId, minAmountOrdered: this.state.projectMinAmountOrderedContractorBranchSearchValue });
		}
		if (this.filterState.selectedContractorBranch?.id && this.state.projectMaxAmountOrderedContractorBranchSearchValue != null) {
			let contractorBranchId = this.filterState.selectedContractorBranch?.id == undefined ? null : this.filterState.selectedContractorBranch?.id.toString();
			this.filterOnMaxAmountOrderedContractorBranch({ contractorBranchId: contractorBranchId, maxAmountOrdered: this.state.projectMaxAmountOrderedContractorBranchSearchValue });
		}
	}
	onUpdateMinAmountOrderedContractorBranch() {
		if (this.filterState.selectedContractorBranch?.id && this.state.projectMinAmountOrderedContractorBranchSearchValue != null || (this.state.projectMinAmountOrderedSearchValue == null && this.filterValuesSelected("minAmountOrderedContractorBranch"))) {
			let contractorBranchId = this.filterState.selectedContractorBranch?.id == undefined ? null : this.filterState.selectedContractorBranch?.id.toString();
			if (this.state.projectMinAmountOrderedContractorBranchSearchValue == null) {
				this.filterOnMinAmountOrderedContractorBranch(null);
			} else {
				this.filterOnMinAmountOrderedContractorBranch({ contractorBranchId: contractorBranchId, minAmountOrdered: this.state.projectMinAmountOrderedContractorBranchSearchValue });
			}
		}
	}
	onUpdateMaxAmountOrderedContractorBranch() {
		if (this.filterState.selectedContractorBranch?.id && this.state.projectMaxAmountOrderedContractorBranchSearchValue != null || (this.state.projectMaxAmountOrderedSearchValue == null && this.filterValuesSelected("maxAmountOrderedContractorBranch"))) {
			let contractorBranchId = this.filterState.selectedContractorBranch?.id == undefined ? null : this.filterState.selectedContractorBranch?.id.toString();
			if (this.state.projectMaxAmountOrderedContractorBranchSearchValue == null) {
				this.filterOnMinAmountOrderedContractorBranch(null);
			} else {
				this.filterOnMaxAmountOrderedContractorBranch({ contractorBranchId: contractorBranchId, maxAmountOrdered: this.state.projectMaxAmountOrderedContractorBranchSearchValue });
			}
		}
	}

	// First planner date
	onSelectFirstPlannerDateFrom(from: string) {
		this.filterOnFirstPlannerDateFrom(from)
	}
	onSelectFirstPlannerDateTo(to: string) {
		this.filterOnFirstPlannerDateTo(to)
	}
	onClickClearFirstPlannerDateFrom() {
		this.filterOnFirstPlannerDateFrom(null)
	}
	onClickClearFirstPlannerDateTo() {
		this.filterOnFirstPlannerDateTo(null)
	}

	//Last planner date
	onSelectLastPlannerDateFrom(from: string) {
		this.filterOnLastPlannerDateFrom(from)
	}
	onSelectLastPlannerDateTo(to: string) {
		this.filterOnLastPlannerDateTo(to)
	}
	onClickClearLastPlannerDateFrom() {
		this.filterOnLastPlannerDateFrom(null)
	}
	onClickClearLastPlannerDateTo() {
		this.filterOnLastPlannerDateTo(null)
	}

	// First invoice date
	onSelectFirstSalesInvoiceDateFrom(from: string) {
		this.filterOnFirstSalesInvoiceDateFrom(from)
	}
	onSelectFirstSalesInvoiceDateTo(to: string) {
		this.filterOnFirstSalesInvoiceDateTo(to)
	}
	onClickClearFirstSalesInvoiceDateFrom() {
		this.filterOnFirstSalesInvoiceDateFrom(null)
	}
	onClickClearFirstSalesInvoiceDateTo() {
		this.filterOnFirstSalesInvoiceDateTo(null)
	}

	// Last sales invoice date
	onSelectLastSalesInvoiceDateFrom(from: string) {
		this.filterOnLastSalesInvoiceDateFrom(from)
	}
	onSelectLastSalesInvoiceDateTo(to: string) {
		this.filterOnLastSalesInvoiceDateTo(to)
	}
	onClickClearLastSalesInvoiceDateFrom() {
		this.filterOnLastSalesInvoiceDateFrom(null)
	}
	onClickClearLastSalesInvoiceDateTo() {
		this.filterOnLastSalesInvoiceDateTo(null)
	}

	// First purchase invoice date
	onSelectFirstPurchaseInvoiceDateFrom(from: string) {
		this.filterOnFirstPurchaseInvoiceDateFrom(from)
	}
	onSelectFirstPurchaseInvoiceDateTo(to: string) {
		this.filterOnFirstPurchaseInvoiceDateTo(to)
	}
	onClickClearFirstPurchaseInvoiceDateFrom() {
		this.filterOnFirstPurchaseInvoiceDateFrom(null)
	}
	onClickClearFirstPurchaseInvoiceDateTo() {
		this.filterOnFirstPurchaseInvoiceDateTo(null)
	}

	// Last purchase invoice date
	onSelectLastPurchaseInvoiceDateFrom(from: string) {
		this.filterOnLastPurchaseInvoiceDateFrom(from)
	}
	onSelectLastPurchaseInvoiceDateTo(to: string) {
		this.filterOnLastPurchaseInvoiceDateTo(to)
	}
	onClickClearLastPurchaseInvoiceDateFrom() {
		this.filterOnLastPurchaseInvoiceDateFrom(null)
	}
	onClickClearLastPurchaseInvoiceDateTo() {
		this.filterOnLastPurchaseInvoiceDateTo(null)
	}

	//First planner date
	quickSelectOnFirstPlannerDate(type: string): void {
		const selectedPlannerDates = this.calculateQuickSelectDate(type)
		this.filterOnFirstPlannerDateFrom(selectedPlannerDates[0])
		this.filterOnFirstPlannerDateTo(selectedPlannerDates[1])
	}

	//Last planner date
	quickSelectOnLastPlannerDate(type: string): void {
		const selectedPlannerDates = this.calculateQuickSelectDate(type)
		this.filterOnLastPlannerDateFrom(selectedPlannerDates[0])
		this.filterOnLastPlannerDateTo(selectedPlannerDates[1])
	}

	// First invoice date
	quickSelectOnFirstSalesInvoiceDate(type: string): void {
		const selectedInvoiceDates = this.calculateQuickSelectDate(type)
		this.filterOnFirstSalesInvoiceDateFrom(selectedInvoiceDates[0])
		this.filterOnFirstSalesInvoiceDateTo(selectedInvoiceDates[1])
	}

	// Last sales invoice date
	quickSelectOnLastSalesInvoiceDate(type: string): void {
		const selectedInvoiceDates = this.calculateQuickSelectDate(type)
		this.filterOnLastSalesInvoiceDateFrom(selectedInvoiceDates[0])
		this.filterOnLastSalesInvoiceDateTo(selectedInvoiceDates[1])
	}

	// First purchase invoice date
	quickSelectOnFirstPurchaseInvoiceDate(type: string): void {
		const selectedInvoiceDates = this.calculateQuickSelectDate(type)
		this.filterOnFirstPurchaseInvoiceDateFrom(selectedInvoiceDates[0])
		this.filterOnFirstPurchaseInvoiceDateTo(selectedInvoiceDates[1])
	}

	// Last purchase invoice date
	quickSelectOnLastPurchaseInvoiceDate(type: string): void {
		const selectedInvoiceDates = this.calculateQuickSelectDate(type)
		this.filterOnLastPurchaseInvoiceDateFrom(selectedInvoiceDates[0])
		this.filterOnLastPurchaseInvoiceDateTo(selectedInvoiceDates[1])
	}


	calculateQuickSelectDate(type: string): string[] {
    let start, end
    switch (type) {
      case 'lastMonth':
        return this.quickSelectDateCalculator.lastMonth()
        break
      case 'lastThreeMonths':
        return this.quickSelectDateCalculator.lastMonths(3)
        break
      case 'lastThreeMonthsToDate':
        return this.quickSelectDateCalculator.lastMonthsToDate(3)
        break
      case 'yearToDate':
        return this.quickSelectDateCalculator.yearToDate()
        break
      default:
        return []
        break
    }
  }

	@Emit('clearFilters')
	onEmitClearFilters(): void {
		// clear all available filters
		const filtersToClear = [] as string[];
		for (const [key, filter] of Object.entries<Filter<any>>(this.filterState.filters)) {
			filtersToClear.push(key);
		}
		this.clearFilters({payload: filtersToClear, fetch: true});
	}

	// getters
	get hasFilters() {
		for (let [key, filter] of Object.entries<Filter<any>>(this.filterState.filters)) {
			if(filter.hasValues()) return true;
		}
		return false;
	}
	get projectStatusFilterSearchFuse() { return new Fuse(this.availableProjectStatuses, this.state.projectStatusFilterSearchFuseOptions) }
	get filteredProjectStatusesList() {
		if (this.state.projectStatusFilterSearchValue !== "") {
			return this.projectStatusFilterSearchFuse
				.search(this.state.projectStatusFilterSearchValue)
				.map(value => value.item);
		}
		return this.availableProjectStatuses;
	}
	get projectLeaders() { return this.availableUsers.filter(user => user.roles && user.roles.some(role => role.name === "Projectleider"));}
	get filteredProjectLeaderList() {
		let filteredProjectLeaderList: User[] = this.projectLeaders; // Initialize with all project leaders
		if (!this.filterState.showMoreProjectLeaders) {
			filteredProjectLeaderList = filteredProjectLeaderList.filter(user => user.isActive);
		}
		if (this.state.projectLeaderFilterSearchValue !== "") {
			filteredProjectLeaderList = this.getProjectLeadersFilterSearchFuse(filteredProjectLeaderList)
				.search(this.state.projectLeaderFilterSearchValue)
				.map(value => value.item);
		}
		return filteredProjectLeaderList;
	}
	get projectEstimators() { return this.availableUsers.filter(user => user.roles && user.roles.some(role => role.name === "Calculator"));}
	get filteredProjectEstimatorList() {
		let filteredProjectEstimatorList: User[] = this.projectEstimators; // Initialize with all project leaders
		if (!this.filterState.showMoreProjectEstimators) {
			filteredProjectEstimatorList = filteredProjectEstimatorList.filter(user => user.isActive);
		}
		if (this.state.projectLeaderFilterSearchValue !== "") {
			filteredProjectEstimatorList = this.getProjectEstimatorsFilterSearchFuse(filteredProjectEstimatorList)
				.search(this.state.projectEstimatorFilterSearchValue)
				.map(value => value.item);
		}
		return filteredProjectEstimatorList;
	}

	// get contractorTypesList(): ContractorBranch[] {
	// 		return this.state.contractorTypes.filter(contractorType => {
	// 			if (this.state.contractorTypesFilterSearchValue !== "") {
	// 				return contractorType.abbreviation.includes(this.state.contractorTypesFilterSearchValue.toUpperCase());
	// 			}
	// 			return contractorType;
	// 		});
	// 	}

	// AmountOrdered
	// get minAmountOrdered() {
	// 	const date = this.filterState.filters.firstPlannerDateFrom.value
	// 	return date.length && date[0] ? date[0] : ''
	// }
	// get maxAmountOrdered() {
	// 	const date = this.filterState.filters.firstPlannerDateTo.value
	// 	return date.length && date[0] ? date[0] : ''
	// }

	// First planner date
	get firstPlannerDateFrom() {
		const date = this.filterState.filters.firstPlannerDateFrom.value
		return date.length && date[0] ? date[0] : ''
	}
	get firstPlannerDateTo() {
		const date = this.filterState.filters.firstPlannerDateTo.value
		return date.length && date[0] ? date[0] : ''
	}
	get showFirstPlannerDateFrom() {
		if (this.filterValuesSelected('firstPlannerDateFrom')) {
			const date = this.filterState.filters.firstPlannerDateFrom.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}
	get showFirstPlannerDateTo() {
		if (this.filterValuesSelected('firstPlannerDateTo')) {
			const date = this.filterState.filters.firstPlannerDateTo.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}

	//Last planner date
	get lastPlannerDateFrom() {
		const date = this.filterState.filters.lastPlannerDateFrom.value
		return date.length && date[0] ? date[0] : ''
	}
	get lastPlannerDateTo() {
		const date = this.filterState.filters.lastPlannerDateTo.value
		return date.length && date[0] ? date[0] : ''
	}
	get showLastPlannerDateFrom() {
		if (this.filterValuesSelected('lastPlannerDateFrom')) {
			const date = this.filterState.filters.lastPlannerDateFrom.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}
	get showLastPlannerDateTo() {
		if (this.filterValuesSelected('lastPlannerDateTo')) {
			const date = this.filterState.filters.lastPlannerDateTo.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}

		// First invoice date
		get firstSalesInvoiceDateFrom() {
		const date = this.filterState.filters.firstSalesInvoiceDateFrom.value
		return date.length && date[0] ? date[0] : ''
	}
	get firstSalesInvoiceDateTo() {
		const date = this.filterState.filters.firstSalesInvoiceDateTo.value
		return date.length && date[0] ? date[0] : ''
	}
	get showFirstSalesInvoiceDateFrom() {
		if (this.filterValuesSelected('firstSalesInvoiceDateFrom')) {
			const date = this.filterState.filters.firstSalesInvoiceDateFrom.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}
	get showFirstSalesInvoiceDateTo() {
		if (this.filterValuesSelected('firstSalesInvoiceDateTo')) {
			const date = this.filterState.filters.firstSalesInvoiceDateTo.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}

	// Last sales invoice date
	get lastSalesInvoiceDateFrom() {
		const date = this.filterState.filters.lastSalesInvoiceDateFrom.value
		return date.length && date[0] ? date[0] : ''
	}
	get lastSalesInvoiceDateTo() {
		const date = this.filterState.filters.lastSalesInvoiceDateTo.value
		return date.length && date[0] ? date[0] : ''
	}
	get showLastSalesInvoiceDateFrom() {
		if (this.filterValuesSelected('lastSalesInvoiceDateFrom')) {
			const date = this.filterState.filters.lastSalesInvoiceDateFrom.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}
	get showLastSalesInvoiceDateTo() {
		if (this.filterValuesSelected('lastSalesInvoiceDateTo')) {
			const date = this.filterState.filters.lastSalesInvoiceDateTo.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}

	// First purchase invoice date
	get firstPurchaseInvoiceDateFrom() {
		const date = this.filterState.filters.firstPurchaseInvoiceDateFrom.value
		return date.length && date[0] ? date[0] : ''
	}
	get firstPurchaseInvoiceDateTo() {
		const date = this.filterState.filters.firstPurchaseInvoiceDateTo.value
		return date.length && date[0] ? date[0] : ''
	}
	get showFirstPurchaseInvoiceDateFrom() {
		if (this.filterValuesSelected('firstPurchaseInvoiceDateFrom')) {
			const date = this.filterState.filters.firstPurchaseInvoiceDateFrom.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}
	get showFirstPurchaseInvoiceDateTo() {
		if (this.filterValuesSelected('firstPurchaseInvoiceDateTo')) {
			const date = this.filterState.filters.firstPurchaseInvoiceDateTo.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}

	// Last purchase invoice date
	get lastPurchaseInvoiceDateFrom() {
		const date = this.filterState.filters.lastPurchaseInvoiceDateFrom.value
		return date.length && date[0] ? date[0] : ''
	}
	get lastPurchaseInvoiceDateTo() {
		const date = this.filterState.filters.lastPurchaseInvoiceDateTo.value
		return date.length && date[0] ? date[0] : ''
	}
	get showLastPurchaseInvoiceDateFrom() {
		if (this.filterValuesSelected('lastPurchaseInvoiceDateFrom')) {
			const date = this.filterState.filters.lastPurchaseInvoiceDateFrom.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}
	get showLastPurchaseInvoiceDateTo() {
		if (this.filterValuesSelected('lastPurchaseInvoiceDateTo')) {
			const date = this.filterState.filters.lastPurchaseInvoiceDateTo.value[0]
			return date ? Vue.prototype.$formatDate(date) : ''
		}
		return ''
	}
}
