import { InvoiceState } from '@/modules/purchase-invoices/store/types/InvoiceState';
import {
  PurchaseInvoice,
  PurchaseInvoiceStatus,
  PurchaseInvoiceRecordType,
  PurchaseInvoicePaymentStatus,
  ProjectRelationshipStatus,
  ApprovalStatus,
  AccountingStatus
} from '@/modules/purchase-invoices/types/entities';
import { Filter, FilterState } from '@/core/types/Filter';
import datetimeCalculate from '@/core/helpers/DatetimeCalculator';
import { Supplier } from '@/modules/entities/types/entities';
import { Project } from '@/modules/projects/types/entities';

export const state: InvoiceState = {
  invoice: null,
  mediaAnnotations: null,

  listviewInvoices: [] as PurchaseInvoice[],
  statuses: [] as PurchaseInvoiceStatus[],
  approvalStatuses: [] as ApprovalStatus[],
  accountingStatuses: [] as AccountingStatus[],
  projectRelationshipStatuses: [] as ProjectRelationshipStatus[],
  paymentStatuses: [] as PurchaseInvoicePaymentStatus[],
  recordTypes: [] as PurchaseInvoiceRecordType[],
  suppliers: [] as Supplier[],
  new: null,

  // loading
  isLoading: false,
  listviewIsLoading: false,

  // search
  searchProjects: [] as Project[],
  searchSuppliers: [] as Supplier[],

  // reporting
  invoicesSummaryReport: null,
  invoicesMetricsReport: null,
  invoicesShowSummaryReport: true,
  invoicesShowMetricsReport: true,

  // filters
  filtersSupplierModel: null,
  listviewFilterState: {
    sideBarOpen: true,
    filters: {
      search: new Filter<string>(
        'search',
        'search',
                [] as string[],
                true,
                'search'
      ),
      status: new Filter<number[]>(
        'status',
        'statuses',
        [] as number[],
        true,
        'status'
      ),
      approvalStatus: new Filter<number[]>(
        'approvalStatus',
        'approval_statuses',
        [] as number[],
        true,
        'approvalStatus'
      ),
      accountingStatus: new Filter<number[]>(
        'accountingStatus',
        'accounting_statuses',
        [] as number[],
        true,
        'accountingStatus'
      ),
      paymentStatus: new Filter<number[]>(
        'paymentStatus',
        'payment_statuses',
        [] as number[],
        true,
        'paymentStatus'
      ),
      dueDateFrom: new Filter<string>(
        'dueDateFrom',
        'due_date_from',
        [],
        true,
        'dueDate'
      ),
      dueDateTo: new Filter<string>(
        'dueDateTo',
        'due_date_to',
        [],
        true,
        'dueDate'
      ),
      invoiceDateFrom: new Filter<string>(
        'invoiceDateFrom',
        'invoice_date_from',
        [datetimeCalculate().yearToDate()[0]],
        true,
        'invoiceDate'
      ),
      invoiceDateTo: new Filter<string>(
        'invoiceDateTo',
        'invoice_date_to',
        [datetimeCalculate().yearToDate()[1]],
        true,
        'invoiceDate'
      ),
      supplier: new Filter<number>(
        'suppliers',
        'suppliers',
                [] as number[],
                false,
                'suppliers'
      ),
      projectLead: new Filter<number>(
        'projectLead',
        'leaders',
                [] as number[],
                true,
                'projectLead'
      ),
      paymentDiscountDueDateFrom: new Filter<string>(
        'paymentDiscountDueDateFrom',
        'discount_due_date_from',
        [],
        true,
        'paymentDiscountDueDate'
      ),
      paymentDiscountDueDateTo: new Filter<string>(
        'paymentDiscountDueDateTo',
        'discount_due_date_to',
        [],
        true,
        'paymentDiscountDueDate'
      ),
    },
  },

};