
	import {Component, Emit, Prop, PropSync, Vue} from "vue-property-decorator";
	import {WorkflowFunctionGroup, WorkflowTask} from "@/modules/workflow/types/entities";
	import {Project} from '@/modules/projects/types/entities';
	import { orderBy } from "lodash";
	import { taskStatusIcon, groupColour, statusColour } from '@/modules/settings/views/workflow/helpers/workflow-helpers';

	@Component
	export default class TaskListComponent extends Vue {
		@Prop() tasks!: WorkflowTask[];
		@Prop() options!: any;
		@Prop() selectedProjectStatusIds!: number[];
		@Prop() isAnyProjectDisplayed!: Function;
		@Prop() selectedProjects!: Project[];
		@Prop() functionGroups!: WorkflowFunctionGroup[];
		@Prop() showFunctionGroups!: boolean;
		@Prop() anyTaskExpanded!: boolean;
		@Prop() isTaskExpanded!: Function;

		getGroupColor(functionGroup: WorkflowFunctionGroup): string {
			return groupColour(functionGroup.color);
		}

		sortedFunctionGroups(functionGroups: WorkflowFunctionGroup[]): WorkflowFunctionGroup[] {
			return orderBy(functionGroups, 'pivot.sequence')
		}

		@Emit('onParentTaskToggle')
		onParentTaskToggle(taskId: number) {
			return taskId;
		}

		@Emit('onToggleAllTasksPanels')
		onToggleAllTasksPanels() {}
	};
