
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
  import { ProjectIntersectionData, WorkflowTask, WorkflowTaskStatus } from '@/modules/workflow/types/entities';
  import { Project } from '@/modules/projects/types/entities';
  import { Comment } from '@/modules/entities/types/entities';

  import TaskStatusCarouselComponent from '../task-status-carousel/task-status-carousel.component.vue';

  @Component({
    components: {
      'task-status-carousel': TaskStatusCarouselComponent,
    },
  })
  export default class InfoPopupComponent extends Vue {
    @Prop() task!: WorkflowTask;
    @Prop() project!: Project;
    @Prop() intersectionData!: ProjectIntersectionData;
    @Prop() userId!: number;
    @Prop() taskStatuses!: WorkflowTaskStatus[];
    @Prop() getCurrentStatus!: Function;
    @Prop() getStatusChangerColor!: Function;

    infoPopupState: { [key: string]: any } = {
      carouselValue: 0,
      hoveredTaskStatus: null,
    };

    onHandleHoveredTaskChange(taskStatus: WorkflowTaskStatus) {
      // console.log(taskStatus);
      if (taskStatus) {
        this.infoPopupState.hoveredTaskStatus = taskStatus;
      } else {
        this.infoPopupState.hoveredTaskStatus = null;
      }
    }

    @Emit('onAddNote')
    onAddNote(project: Project, task: WorkflowTask, intersectionData: ProjectIntersectionData) {
      return { project, task, intersectionData };
    }

    @Emit('onEditNote')
    onEditNote(project: Project, task: WorkflowTask, intersectionData: ProjectIntersectionData, comment: Comment) {
      return { project, task, intersectionData, comment };
    }

    @Emit('onShowAllNotes')
    onShowAllNotes(project: Project, task: WorkflowTask, intersectionData: ProjectIntersectionData) {
      return { project, task, intersectionData };
    }

    @Emit('onSetTaskStatus')
    onSetTaskStatus(data: { projectId: number; workflowTaskId: number; taskStatusId: number }) {
      return data;
    }

    @Emit('onSetTaskDate')
    onSetTaskDate(data: { projectId: number; workflowTaskId: number; taskStatusId: number; futureStartDate: Date }) {
      return data;
    }

    @Emit('taskStatusMultipleSelect')
    taskStatusMultipleSelect(taskStatusId: number) {
      return taskStatusId;
    }

    @Emit('taskStatusMultipleSelectDate')
    taskStatusMultipleSelectDate(futureStartDate: Date) {
      return futureStartDate;
    }
  }
