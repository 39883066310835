export interface Holiday {
	id?: number;
	name: string;
	start: string;
	end: string;
	holidayTypes: HolidayType[];
}

export interface HolidayType {
	id: number;
	name: string;
	type: string;
	sequence: number;
}

export class HolidayEntity implements Holiday {
	id = undefined;
	name = '';
	start = new Date().toISOString().substr(0, 10);
	end = new Date().toISOString().substr(0, 10);
	holidayTypes = [] as Array<HolidayType>;

	constructor(data?: Partial<Holiday>) {
        Object.assign(this, data)
    }
}