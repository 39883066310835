import {MutationTree} from "vuex";
import {MonthlyOverviewState} from "@/modules/monthly-overview/store/types/MonthlyOverviewState";
import {Mutations} from "@/modules/monthly-overview/store/types/storeTypes";
import { Employee } from '@/modules/settings/types/entities';

export const mutations: MutationTree<MonthlyOverviewState> = {
	[Mutations.MUTATE_TEAM_MEMBERS]: (state: MonthlyOverviewState, payload: Employee[]) => {
		state.teamMembers = payload;
	},
	[Mutations.MUTATE_SELECTED_TEAM_MEMBER]: (state: MonthlyOverviewState, payload: Employee) => {
		state.selectedTeamMember = payload;
	},
	[Mutations.MUTATE_MONTHLY_OVERVIEW_DATA]: (state: MonthlyOverviewState, payload) => {
		state.monthlyOverviewData = payload;
	}
}
