/* eslint-disable @typescript-eslint/explicit-function-return-type */
import helpers from '@/modules/calendar/services/helper.service';
import CalendarService from '@/services/calendar.service';
import { UseCalendarSettingsContext } from '../types/useCalendarSettings';
import { cloneDeep } from 'lodash';
import { ProjectsService } from '@/services/projects.service';
import { UsersService } from '@/services/users.service';
import { SuppliersService } from '@/modules/entities/services/suppliers/suppliers.service';
import { ProjectUser } from '@/modules/projects/types/entities';
import { DivisionsService } from '@/services/divisions.service';
import { Supplier, Division } from '../../interfaces/project/interfaces';

const calendarService = new CalendarService();
const projectsService = new ProjectsService();
const suppliersService = new SuppliersService();
const divisionsService = new DivisionsService();

const showSidebar = async (ctx: UseCalendarSettingsContext, show: boolean) => {
  const { state } = ctx;
  state.sideBarOpen = show;
  return Promise.resolve();
};

const fetchEnvironment = async (ctx: UseCalendarSettingsContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchEnvironment.name, true);
  try {
    const res = await calendarService.fetchEnvironment();
    const environment = res.data.data;
    if (environment && environment.environmentId) {
      // Object.assign(state, {environment: environment});
      state.environment = environment;
      return Promise.resolve(res);
    }
    throw new Error('Environment could not be fetched.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchEnvironment.name, false);
  }
};
const ensureEnvironment = async (ctx: UseCalendarSettingsContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(ensureEnvironment.name, true);

  try {
    if(!state.environment || !state.environment.environmentId) await fetchEnvironment(ctx);
    if(state.environment) return Promise.resolve(state.environment);
    throw new Error('Environment not set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(ensureEnvironment.name, false);
  }
};

const setDateRangeMenuActive = async (ctx: UseCalendarSettingsContext, isActive: boolean, type?: string) => {
  const { state } = ctx;
  if (type === 'start') {
    state.isDateRangeMenuActive.start = isActive;
  } else if (type === 'end') {
    state.isDateRangeMenuActive.end = isActive;
  } else {
    state.isDateRangeMenuActive = { start: isActive, end: isActive };
  }
  return Promise.resolve();
};

const setSelectedDateRange = async (ctx: UseCalendarSettingsContext, date: string, type: string) => {
  const { state } = ctx;
  if (type === 'start') {
    state.selectedDateRange.start = date;
  } else if (type === 'end') {
    state.selectedDateRange.end = date;
  }
  return Promise.resolve();
};

const fetchSelectedProjects = async (ctx: UseCalendarSettingsContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchSelectedProjects.name, true);

  try {
    const environmentId = (await ensureEnvironment(ctx)).environmentId;
    if(environmentId) {
      const resp = await calendarService.fetchProjects(environmentId);
      state.selectedProjects = resp.data.data;
      return Promise.resolve(resp);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchSelectedProjects.name, false);
  }
};
const fetchSelectedCollaborators = async (ctx: UseCalendarSettingsContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchSelectedCollaborators.name, true);

  try {
    const environmentId = (await ensureEnvironment(ctx)).environmentId;
    if(environmentId) {
      const respDivisions = await calendarService.fetchDivisions(environmentId);
      const respSuppliers = await calendarService.fetchSuppliers(environmentId);
      state.selectedCollaborators = [...respDivisions.data.data, ...respSuppliers.data.data];
      return Promise.resolve([respDivisions, respSuppliers]);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchSelectedCollaborators.name, false);
  }
};
const removeProjectsFromCalendar = async (ctx: UseCalendarSettingsContext, projectIds: number[]) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(removeProjectsFromCalendar.name, true);

  try {
    const environmentId = (await ensureEnvironment(ctx)).environmentId;
    if(environmentId) {
      const removeProjectFromCalendarPromises = projectIds.map(async (projectId) => {
        return await calendarService.removeProjectFromEnvironment(environmentId, projectId);
      });
      const res = await Promise.all(removeProjectFromCalendarPromises);
      return Promise.resolve(res);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(removeProjectsFromCalendar.name, false);
  }
};
const addProjectsToCalendar = async (ctx: UseCalendarSettingsContext, projectIds: number[]) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(addProjectsToCalendar.name, true);

  try {
    const environmentId = (await ensureEnvironment(ctx)).environmentId;
    if(environmentId) {
      const addProjectToCalendarPromises = projectIds.map(async (projectId) => {
        return await calendarService.addProjectToEnvironment(environmentId, projectId);
      });
      const res = await Promise.all(addProjectToCalendarPromises);
      return Promise.resolve(res);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(addProjectsToCalendar.name, false);
  }
};
const addSuppliersToCalendar = async (ctx: UseCalendarSettingsContext, supplierIds: number[]) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(addSuppliersToCalendar.name, true);

  try {
    const environmentId = (await ensureEnvironment(ctx)).environmentId;
    if(environmentId) {
      const addSupplierToCalendarPromises = supplierIds.map(async (supplierId) => {
        return await calendarService.addSupplierToEnvironment(environmentId, supplierId);
      });
      const res = await Promise.all(addSupplierToCalendarPromises);
      return Promise.resolve(res);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(addSuppliersToCalendar.name, false);
  }
};
const addDivisionsToCalendar = async (ctx: UseCalendarSettingsContext, divisionIds: number[]) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(addDivisionsToCalendar.name, true);

  try {
    const environmentId = (await ensureEnvironment(ctx)).environmentId;
    if(environmentId) {
      const addDivisionToCalendarPromises = divisionIds.map(async (divisionId) => {
        return await calendarService.addDivisionToEnvironment(environmentId, divisionId);
      });
      const res = await Promise.all(addDivisionToCalendarPromises);
      return Promise.resolve(res);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(addDivisionsToCalendar.name, false);
  }
};
const removeSuppliersFromCalendar = async (ctx: UseCalendarSettingsContext, supplierIds: number[]) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(addSuppliersToCalendar.name, true);

  try {
    const environmentId = (await ensureEnvironment(ctx)).environmentId;
    if(environmentId) {
      const removeSuppliersFromCalendarPromises = supplierIds.map(async (supplierId) => {
        return await calendarService.removeSupplierFromEnvironment(environmentId, supplierId);
      });
      const res = await Promise.all(removeSuppliersFromCalendarPromises);
      return Promise.resolve(res);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(addSuppliersToCalendar.name, false);
  }
};
const removeDivisionsFromCalendar = async (ctx: UseCalendarSettingsContext, divisionIds: number[]) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(addDivisionsToCalendar.name, true);

  try {
    const environmentId = (await ensureEnvironment(ctx)).environmentId;
    if(environmentId) {
      const removeDivisionsFromCalendarPromises = divisionIds.map(async (divisionId) => {
        return await calendarService.removeDivisionFromEnvironment(environmentId, divisionId);
      });
      const res = await Promise.all(removeDivisionsFromCalendarPromises);
      return Promise.resolve(res);
    }
    throw new Error('No environment set.');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(addDivisionsToCalendar.name, false);
  }
};
const fetchProjectsByFilters = async (ctx: UseCalendarSettingsContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchProjectsByFilters.name, true);

  try {
    const filters = new URLSearchParams();
    if (!!state.filteredProjectsNameFilter && !!state.filteredProjectsNameFilter.trim()) {
      filters.append('name', state.filteredProjectsNameFilter);
    }
    if (state.selectedProjectStatusesIds.length > 0) {
      filters.append('statuses', [...state.selectedProjectStatusesIds].join());
    }
    if (state.selectedProjectLeadersIds.length > 0) {
      filters.append('leaders', [...state.selectedProjectLeadersIds].join());
    }
    // if (state.settingsModalFilters.selectedSupplierTypesIds.size) {
    // 	filters.append('leaders', [...state.settingsModalFilters.select].join());
    // }
    const url = `${ projectsService.getBaseEndpoint() }${ filters ? '?' + filters.toString() : '' }`;
    const res = await projectsService.getAllProjects(url);
    state.filteredProjects = res.data.data;
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchProjectsByFilters.name, false);
  }
};

const fetchCollaboratorsByFilters = async (ctx: UseCalendarSettingsContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchCollaboratorsByFilters.name, true);

  try {
    const divisionsFilters = new URLSearchParams();
    const suppliersFilters = new URLSearchParams();
    if (!!state.filteredCollaboratorsNameFilter && !!state.filteredCollaboratorsNameFilter.trim()) {
      divisionsFilters.append('name', state.filteredCollaboratorsNameFilter);
      suppliersFilters.append('name', state.filteredCollaboratorsNameFilter);
    }
    if (state.selectedSupplierTypesIds.length > 0) {
      suppliersFilters.append('supplierTypes', [...state.selectedSupplierTypesIds].join());
    }
    const divisionsResponse = await divisionsService.getDivisions(divisionsFilters);
    const suppliersResponse = await suppliersService.getSuppliers(suppliersFilters);
    state.filteredCollaborators = [...divisionsResponse.data.data as Division[], ...suppliersResponse.data.data as Supplier[]];
    return Promise.resolve([divisionsResponse, suppliersResponse]);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchCollaboratorsByFilters.name, false);
  }
};

const filterByProjectName = async (ctx: UseCalendarSettingsContext, name: string) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(filterByProjectName.name, true);

  try {
    state.filteredProjectsNameFilter = name;
    const res = await fetchProjectsByFilters(ctx);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(filterByProjectName.name, false);
  }
};

const filterByCollaboratorName = async (ctx: UseCalendarSettingsContext, name: string) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(filterByCollaboratorName.name, true);

  try {
    state.filteredCollaboratorsNameFilter = name;
    const res = await fetchCollaboratorsByFilters(ctx);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(filterByCollaboratorName.name, false);
  }
};

const filterByProjectStatusIds = async (ctx: UseCalendarSettingsContext, projectStatusIds: Array<number>, toggle = true) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(filterByProjectStatusIds.name, true);

  try {
    // TODO: fetch after filter (or in component?)
    if(toggle) {
      projectStatusIds.forEach((id) => {
        if(state.selectedProjectStatusesIds.includes(id)) state.selectedProjectStatusesIds.splice(state.selectedProjectStatusesIds.indexOf(id), 1);
        else state.selectedProjectStatusesIds.push(id);
      });
    } else {
      state.selectedProjectStatusesIds = cloneDeep(projectStatusIds);
    }
    await fetchProjectsByFilters(ctx);
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(filterByProjectStatusIds.name, false);
  }
};

const filterByProjectLeaderIds = async (ctx: UseCalendarSettingsContext, projectLeaderIds: Array<number>, toggle = true) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(filterByProjectLeaderIds.name, true);

  try {
    // TODO: fetch after filter (or in component?)
    if(toggle) {
      projectLeaderIds.forEach((id) => {
        if(state.selectedProjectLeadersIds.includes(id)) state.selectedProjectLeadersIds.splice(state.selectedProjectLeadersIds.indexOf(id), 1);
        else state.selectedProjectLeadersIds.push(id);
      });
    } else {
      state.selectedProjectLeadersIds = cloneDeep(projectLeaderIds);
    }
    await fetchProjectsByFilters(ctx);
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(filterByProjectLeaderIds.name, false);
  }
};

const filterBySupplierTypeIds = async (ctx: UseCalendarSettingsContext, supplierTypeIds: Array<number>, toggle = true) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(filterBySupplierTypeIds.name, true);

  try {
    // TODO: fetch after filter (or in component?)
    if(toggle) {
      supplierTypeIds.forEach((id) => {
        if(state.selectedSupplierTypesIds.includes(id)) state.selectedSupplierTypesIds.splice(state.selectedSupplierTypesIds.indexOf(id), 1);
        else state.selectedSupplierTypesIds.push(id);
      });
    } else {
      state.selectedSupplierTypesIds = cloneDeep(supplierTypeIds);
    }
    await fetchCollaboratorsByFilters(ctx);
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(filterBySupplierTypeIds.name, false);
  }
};

const expandExpansionPanel = async (ctx: UseCalendarSettingsContext, expansionPanel: string) => {
  const { state, composables: { loadingActions, silentActions } } = ctx;
  loadingActions.actions.set(expandExpansionPanel.name, true);

  try {
    // TODO
    // setProject(ctx, res.data.data)
    // return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(expandExpansionPanel.name, false);
  }
};

const initSettings = async (ctx: UseCalendarSettingsContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(initSettings.name, true);

  try {
    const suppliersService = new SuppliersService();
    const projectsService = new ProjectsService();
    const usersService = new UsersService();
    const supplierTypes = await suppliersService.getSupplierTypes();
    const projectStatuses = await projectsService.getAllProjectStatuses();
    const projectUsers = await usersService.getAllUsers();
    state.supplierTypes = supplierTypes.data.data;
    state.projectStatuses = projectStatuses.data.data;
    state.projectLeaders = projectUsers.data.data as ProjectUser[];

    return Promise.resolve([supplierTypes, projectStatuses, projectUsers]);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(initSettings.name, false);
  }
};

const saveDateRangeSelection = async (ctx: UseCalendarSettingsContext) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(saveDateRangeSelection.name, true);

  try {
    const environment = state.environment;
    if (environment) {
      const res = await calendarService.updatePlannerDateRange(environment.environmentId, [
        state.selectedDateRange.start || helpers.formatDate(environment.dateRange.start, 'YYYY-MM-DD'),
        state.selectedDateRange.end || helpers.formatDate(environment.dateRange.end, 'YYYY-MM-DD'),
      ]);
      await setDateRangeMenuActive(ctx, false);
      await fetchEnvironment(ctx);
      return Promise.resolve(res);
    }

    throw ('No environment id specified');
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveDateRangeSelection.name, false);
  }
};

const showWeekendsAndHolidays = async (ctx: UseCalendarSettingsContext, show: boolean) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(showWeekendsAndHolidays.name, true);
  try {
    state.displayWeekendsAndHolidays = show;
    return Promise.resolve(show);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(showWeekendsAndHolidays.name, false);
  }
};

const showEmptyRows = async (ctx: UseCalendarSettingsContext, show: boolean) => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(showEmptyRows.name, true);
  try {
    state.displayEmptyRows = show;
    return Promise.resolve(show);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(showEmptyRows.name, false);
  }
};

export const actions = {
  // environment
  initSettings,
  fetchEnvironment,
  setSelectedDateRange,
  setDateRangeMenuActive,
  saveDateRangeSelection,
  showWeekendsAndHolidays,
  showEmptyRows,

  // settings panel
  showSidebar,
  addProjectsToCalendar,
  addSuppliersToCalendar,
  addDivisionsToCalendar,
  removeProjectsFromCalendar,
  removeSuppliersFromCalendar,
  removeDivisionsFromCalendar,
  fetchProjectsByFilters,
  fetchCollaboratorsByFilters,
  filterByProjectName,
  filterByCollaboratorName,
  filterByProjectStatusIds,
  filterByProjectLeaderIds,
  filterBySupplierTypeIds,
  expandExpansionPanel,
  fetchSelectedProjects,
  fetchSelectedCollaborators,


};
