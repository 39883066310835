import {RouteConfig} from 'vue-router';

import NotFoundComponent from '@/core/views/not-found/not-found.component.vue';
import BaseComponent from '@/core/views/base/base.component.vue';
import UnauthorizedComponent from '@/core/views/unauthorized/unauthorized.component.vue';

import {loginRoutes} from '@/modules/login/login.module';
import {homeRoutes} from '@/modules/home/home.module';
import {workflowRoutes} from '@/modules/workflow/workflow.module';
import {entityRoutes} from '@/modules/entities/entities.module';
import {projectsRoutes} from '@/modules/projects/projects.module';
import {settingsRoutes} from '@/modules/settings/settings.module';
import {contactsRoutes} from '@/modules/contacts/contacts.module';
import {salesInvoiceRoutes} from '@/modules/sales-invoices/sales-invoices.module';
import {purchaseInvoiceRoutes} from '@/modules/purchase-invoices/purchase-invoices.module';
import {accountingRoutes} from '@/modules/accounting/accounting.module';
import {reportsRoutes} from '@/modules/reports/reports.module';
import {monthlyOverviewRoutes} from "@/modules/monthly-overview/monthly-overview.module";
import {calendarRoutes} from "@/modules/calendar/calendar.module";
import {clientsRoutes} from '@/modules/clients/clients.module';

export const routes: RouteConfig[] = [
	{
		path: '',
		component: BaseComponent,
		meta: {
			requiresAuth: true,
			auth: {
				roles: 'APP__LOGIN',
				forbiddenRedirect: '/403'
			}
		},
		children: [
			...homeRoutes,
			...workflowRoutes,
			...entityRoutes,
			...projectsRoutes,
			...settingsRoutes,
			...contactsRoutes,
			...salesInvoiceRoutes,
			...purchaseInvoiceRoutes,
			...accountingRoutes,
			...reportsRoutes,
			...monthlyOverviewRoutes,
			...calendarRoutes,
			...clientsRoutes,
		],
		beforeEnter: (to, from, next) => {
			next()
		}
	},
	...loginRoutes,
	{
		path: '/403',
		name: 'unauthorized',
		component: UnauthorizedComponent
	},
	{
		path: '*',
		name: '404',
		component: NotFoundComponent
	}
];
