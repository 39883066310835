
	import { PropType } from 'vue';
	import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { PurchaseInvoice, ProjectRelationship, ProjectRelationshipStatus, ProjectRelationshipEntity, PurchaseInvoiceEntity, ApprovalStatus, TransactionType, MediaAnnotatatable } from '@/modules/purchase-invoices/types/entities';
	import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
	import { Getters as ProjectGetters } from '@/modules/projects/store/types/StoreTypes';
	import { AxiosError, AxiosResponse } from 'axios';
	import ProjectRelationshipDetailsComponent from '@/modules/purchase-invoices/components/project-relationship-details/project-relationship-details.component.vue'
	import AssignToMultipleAutocompleteComponent from '@/modules/purchase-invoices/components/project-relationship-assign-to/project-relationship-assign-to-multiple-autocomplete.component.vue';
	import AssignToListComponent from '@/modules/purchase-invoices/components/project-relationship-assign-to/project-relationship-assign-to-list.component.vue';
	import PurchaseInvoiceCommentsComponent from '@/modules/purchase-invoices/components/purchase-invoice-comments/purchase-invoice-comments.component.vue';
	import { MediaAnnotation, User, UserEntity } from '@/modules/entities/types/entities';
	import { Project, ProjectUser } from '@/modules/projects/types/entities';
	import { UsersService } from '@/services/users.service';
	import { JsonResource } from '@/core/types/Entities';
    import {AccountingLedger, AccountingLedgerItem} from '@/modules/accounting/types/entities';
	import { validationMixin } from 'vuelidate';
	import { requiredIf } from 'vuelidate/lib/validators';
	import { isProjectRequiredValidator, isContractorTypeRequiredValidator, isAccountingLedgerItemRequiredValidator } from '@/modules/purchase-invoices/validators/project-relationship.validator'
	import UserAutocompleteComponent from '@/modules/purchase-invoices/components/user-autocomplete/user-autocomplete.component.vue';
	import { signAmountProjectRelationship } from '../../helpers';
	import PdfAnnotateComponent from '@/modules/purchase-invoices/components/pdf-annotate/pdf-annotate.component.vue';
    import authStore from "@/modules/login/store";

	const purchaseInvoices = namespace('purchaseInvoices');

	// services
	const usersService = new UsersService();

	@Component({
		mixins: [validationMixin],
		validations: {
			projectRelationshipSync: {
				project: { required: requiredIf(vm => isProjectRequiredValidator(vm)) },
				contractorType: { required: requiredIf(vm => isContractorTypeRequiredValidator(vm)) },
				accountingLedgerItem: { required: requiredIf(vm => isAccountingLedgerItemRequiredValidator(vm)) },
			},
		},
		components: {
			'project-relationship-details': ProjectRelationshipDetailsComponent,
			'assign-to-multiple-autocomplete': AssignToMultipleAutocompleteComponent,
			'assign-to-list': AssignToListComponent,
			'purchase-invoice-comments': PurchaseInvoiceCommentsComponent,
			'user-autocomplete': UserAutocompleteComponent,
			'pdf-annotate': PdfAnnotateComponent,
		},
      props: {
        currentAccountingLedger: {
          type: Object as PropType<AccountingLedger|undefined>,
          default: undefined
        }
      },
      setup(props, ctx) {

      }
	})
	export default class ProjectRelationshipCreateModalComponent extends Vue {

		// vuex actions
		@purchaseInvoices.Action(Actions.SAVE_PROJECT_RELATIONSHIP) saveProjectRelationship!: (payload: ProjectRelationship) => Promise<AxiosResponse<JsonResource<ProjectRelationship>>>;
		@purchaseInvoices.Action(Actions.FETCH_MEDIA_ANNOTATION_FOR_INVOICE_PDF) fetchMediaAnnotationsForInvoice!: (payload: {invoiceId: string; mediaId: string}) => Promise<AxiosResponse<JsonResource<MediaAnnotation>>>;
		@purchaseInvoices.Action(Actions.FETCH_MEDIA_ANNOTATIONS_BY_MEDIA_ID) fetchMediaAnnotationsByMediaId!: (mediaId: string) => Promise<AxiosResponse<JsonResource<MediaAnnotation[]>>>;
		@purchaseInvoices.Action(Actions.SAVE_MEDIA_ANNOTATION) saveMediaAnnotation!: (payload: {mediaAnnotation: MediaAnnotation; mediaId: string; annotatableId: string; annotatableType: MediaAnnotatatable}) => Promise<AxiosResponse<JsonResource<MediaAnnotation>>>;

		// vuex getters
		// @purchaseInvoices.Getter(Getters.GET_PROJECT_RELATIONSHIP) projectRelationship!: ProjectRelationship;
		@purchaseInvoices.Getter(Getters.GET_INVOICE) purchaseInvoice!: PurchaseInvoice;
		@purchaseInvoices.Getter(Getters.GET_PROJECT_RELATIONSHIP_STATUSES) statuses!: ProjectRelationshipStatus[];
		@purchaseInvoices.Getter(Getters.GET_APPROVAL_STATUSES) approvalStatuses!: ApprovalStatus[];
		@purchaseInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;

		// props
		@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
		@PropSync('projectRelationship', { type: Object as PropType<ProjectRelationship> }) projectRelationshipSync!: ProjectRelationship;
		@Prop({type: Array as PropType<User[]>, default: () => [] as User[] }) users!: User[]
		
		// vars
		viewMode: string = 'modal';
		state: {
			projectRelationshipRequestsSaving: boolean,
			projectRelationshipRequestsSaved: boolean,
			projectRelationshipSaved: boolean,
			projectRelationshipSaving: boolean,
			enableAssignedTos: boolean,
			pdfAnnotationChanged: boolean,
		} = {...this.newState()}
		content: {
			multipleAssignedTos: User[],
			purchaseInvoice: PurchaseInvoice|null,
			projectRelationshipValidationErrors: any,
			editMediaAnnotation: MediaAnnotation | null,
			mediaAnnotation: {
                current: MediaAnnotation|null, 
                all: MediaAnnotation[],
                annotatableType: MediaAnnotatatable|undefined,
                annotatableId: string|undefined,
            },
        } = {...this.newContentState()}

		// handlers
		onClickClose(value: any): void {
			this.closeModal();
			this.content = { ...this.newContentState() }
			this.state = { ...this.newState() }
		}
		onChangeProject(project: Project) {
			this.projectRelationshipSync.project = project
			const projectLeaders = project.projectLeaders
			if(projectLeaders && projectLeaders.length > 0) {
				const users = this.users.filter(u => projectLeaders.find(pu => pu.id === u.id))
				this.content = {...this.content, multipleAssignedTos: users}
			}
		}
		onChangeAccountingLedgerItem(ledgerItem: AccountingLedgerItem) {
			let isProjectRelated = false;
			if(ledgerItem) isProjectRelated = ledgerItem.isProjectRelated
			this.projectRelationshipSync = {...this.projectRelationshipSync, 
				accountingLedgerItem: ledgerItem,
				isProjectRequired: isProjectRelated,
			}
		}
		onBeforeSaveMultiple(projectRelationship: ProjectRelationship)
		{
			// purchase invoice
			let invoice = projectRelationship && projectRelationship.purchaseInvoice || undefined
			if(!invoice && !this.purchaseInvoice) throw new Error('No purchase invoice provided')
			invoice = this.purchaseInvoice

			// status
			let status = projectRelationship && projectRelationship.status || undefined
			if(!status) {
				status = this.statuses.find(s => s.name === 'requested');
				if(!status) throw new Error('Unexpected exception: default status for project relationship could not be set.')
			}

			// requested by
			const requestedBy = authStore.auth.getters.user.value;
			if(!requestedBy) throw new Error('No authenticated user.')

			// set values 
			projectRelationship = {...projectRelationship, 
				purchaseInvoice: invoice,
				status: status,
				requestedBy: requestedBy,
			}

			return projectRelationship;
		}
		onClickSaveAndSendProjectRelationships() {
			this.state = {...this.state, projectRelationshipRequestsSaving: true }
			this.saveProjectRelationships()
				.then(() => {
					if(this.content.editMediaAnnotation && this.content.mediaAnnotation.annotatableType && this.content.mediaAnnotation.annotatableId) {
						const mediaId = this.purchaseInvoice.pdf && this.purchaseInvoice.pdf.id ? this.purchaseInvoice.pdf.id.toString() : undefined
						if(mediaId) {
							this.saveMediaAnnotation({
								mediaAnnotation: this.content.editMediaAnnotation,
								mediaId: mediaId,
								annotatableId: this.content.mediaAnnotation.annotatableId,
								annotatableType: this.content.mediaAnnotation.annotatableType
							}).catch(err => {
								console.error(err)
							})
						}
					}
				})
				.finally(() => {
					// TODO: view the failed items?
					this.state = {...this.state, projectRelationshipRequestsSaved: true, projectRelationshipRequestsSaving: false }
				})
		}
		onClickSaveProjectRelationship(statusName: string, showNew: boolean = false) {
			this.state = {...this.state, projectRelationshipSaving: true }

			// on before save
			let invoice = this.projectRelationshipSync && this.projectRelationshipSync.purchaseInvoice || undefined
			if(!invoice && !this.purchaseInvoice) throw new Error('No purchase invoice provided')
			invoice = this.purchaseInvoice

			// status
			let status = this.statuses.find(s => s.name === 'pending_claim');
			if(statusName) {
				status = this.statuses.find(s => s.name === statusName);
			}

			// approval status
			let approvalStatus = this.approvalStatuses.find(s => s.name === 'pending');
			this.projectRelationshipSync.approvalStatus = approvalStatus;
			if(status && status.name === 'approved' && !this.projectRelationshipSync.isProjectRequired) {
				approvalStatus = this.approvalStatuses.find(s => s.name === 'approved');
			}

			// set values
			this.projectRelationshipSync.purchaseInvoice = invoice;
			this.projectRelationshipSync.status = status;
			this.projectRelationshipSync.assignedTo = this.projectRelationshipSync.assignedTo ? this.projectRelationshipSync.assignedTo : this.currentUser
			
			// validated
			const validated = this.validated()
			if(validated) {
				// save
				this.saveProjectRelationship(this.projectRelationshipSync).then(resp => {
					if(this.content.editMediaAnnotation && this.content.mediaAnnotation.annotatableType && this.content.mediaAnnotation.annotatableId) {
						const mediaId = this.purchaseInvoice.pdf && this.purchaseInvoice.pdf.id ? this.purchaseInvoice.pdf.id.toString() : undefined
						if(mediaId) {
							this.saveMediaAnnotation({
								mediaAnnotation: this.content.editMediaAnnotation,
								mediaId: mediaId,
								annotatableId: this.content.mediaAnnotation.annotatableId,
								annotatableType: this.content.mediaAnnotation.annotatableType
							}).catch(err => {
								console.error(err)
							})
						}
					}
				}).catch((err) => {
					throw err
				}).finally(() => {
					// update state
					this.state = {...this.state, projectRelationshipSaved: true, projectRelationshipSaving: false }
					this.content = { ...this.newContentState() }
					this.state = { ...this.newState() }
					this.closeModal()
					if(showNew) this.$emit('showNew')
				});

			} else {
				// update state
				this.state = {...this.state, projectRelationshipSaved: false, projectRelationshipSaving: false }
			}
		}
		saveProjectRelationships() {
			const assignedTos = this.content.multipleAssignedTos;
			const projectRelationshipRequests = [] as ProjectRelationship[]

			// status
			let status = this.statuses.find(s => s.name === 'requested');

			// requested by
			const requestedBy = authStore.auth.getters.user.value;

			// set values 
			this.projectRelationshipSync.purchaseInvoice = this.purchaseInvoice;
			this.projectRelationshipSync.status = status;
			this.projectRelationshipSync.requestedBy = requestedBy;
			assignedTos.forEach((assignedTo: User) => {
				projectRelationshipRequests.push({...this.projectRelationshipSync,
					assignedTo: assignedTo
				} as ProjectRelationship)
			})

			// save items
			return new Promise<{succeeded: ProjectRelationship[], failed: {projectRelationship: ProjectRelationship, error: AxiosError}[]}>((resolve, reject) => {
				const failedProjectRelationships = [] as {projectRelationship: ProjectRelationship, error: AxiosError}[]
				const succeededProjectRelationships = [] as ProjectRelationship[]
				const resolveApiRequests = [] as Promise<any>[]
			
				projectRelationshipRequests.forEach((rel) => {
					// TODO: create Action to save multiple records in vuex 
					const resultPromise = this.saveProjectRelationship(rel)
						.then((result: AxiosResponse<JsonResource<ProjectRelationship>>) => {
							succeededProjectRelationships.push(result.data.data)
						})
						.catch((err: AxiosError) => {
							failedProjectRelationships.push({
								projectRelationship: rel,
								error: err.response && err.response.data ? err.response.data : null
							})
						});
					resolveApiRequests.push(resultPromise)
				})

				Promise.all(resolveApiRequests).then(() => {
					resolve({
						succeeded: succeededProjectRelationships,
						failed: failedProjectRelationships
					})
				})
			})
		}

		// methods
		validated(): boolean {
			this.content = {...this.content, projectRelationshipValidationErrors: {} }
			const projectRelationshipValidator = this.$v.projectRelationshipSync
			if(projectRelationshipValidator) projectRelationshipValidator.$touch()

			if(this.$v.$invalid) {
				if(projectRelationshipValidator && projectRelationshipValidator.project && !projectRelationshipValidator.project.required) {
					this.content = {...this.content, projectRelationshipValidationErrors: {...this.content.projectRelationshipValidationErrors, project: this.$t('validation.required') }}
				}
				if(projectRelationshipValidator && projectRelationshipValidator.contractorType && !projectRelationshipValidator.contractorType.required) {
					this.content = {...this.content, projectRelationshipValidationErrors: {...this.content.projectRelationshipValidationErrors, contractorType: this.$t('validation.required') }}
				}
				if(projectRelationshipValidator && projectRelationshipValidator.accountingLedgerItem && !projectRelationshipValidator.accountingLedgerItem.required) {
					this.content = {...this.content, projectRelationshipValidationErrors: {...this.content.projectRelationshipValidationErrors, accountingLedgerItem: this.$t('validation.required') }}
				}
				return false
			}
			return true;
		}
		closeModal() {
			this.showModalSync = false
		}
		newContentState() {
			return {
				multipleAssignedTos: [] as User[],
				projectRelationship: new ProjectRelationshipEntity,
				purchaseInvoice: null,
				projectRelationshipValidationErrors: {},
				editMediaAnnotation: null,
				mediaAnnotation: {
					current: null, 
					all: [],
					annotatableType: undefined,
					annotatableId: undefined,
				},
			}
		}
		newState() {
			return {
				projectRelationshipRequestsSaved: false,
				projectRelationshipRequestsSaving: false,
				projectRelationshipSaved: false,
				projectRelationshipSaving: false,
				enableAssignedTos: false,
				pdfAnnotationChanged: false,
			}
		}
		signAmount(amount: number, type: TransactionType) {
            return signAmountProjectRelationship(amount, type);
		}
		loadAnnotationsForInvoice(invoice: PurchaseInvoice|undefined) {
            const invoiceId = invoice && invoice.id ? invoice.id.toString() : undefined
			const mediaId = this.purchaseInvoice.pdf && this.purchaseInvoice.pdf.id ? this.purchaseInvoice.pdf.id.toString() : undefined
            
            if(invoiceId && mediaId) {
                try {
					// const invoiceMediaAnnotationResult = await this.fetchMediaAnnotationsForProjectRelationship({projectRelationshipId: projectRelationshipId, mediaId: mediaId});
					this.fetchMediaAnnotationsForInvoice({invoiceId: invoiceId, mediaId: mediaId}).then(invoiceMediaAnnotationResult => {
						this.fetchMediaAnnotationsByMediaId(mediaId).then(allMediaAnnotationsResult => {
							this.content = {...this.content, 
								mediaAnnotation: { 
									current: invoiceMediaAnnotationResult.data.data, 
									all: allMediaAnnotationsResult.data.data,
									annotatableType: MediaAnnotatatable.PURCHASE_INVOICE,
									annotatableId: invoiceId,
								}
							}
							this.state = {...this.state, pdfAnnotationChanged: false}
						}).catch(err => {
							throw err
						})
					}).catch(err => {
						throw err
					});
                } catch(e) {
                    this.content = {...this.content, mediaAnnotation: { current: null, all: [], annotatableType: undefined, annotatableId: undefined }}
                    throw e;
                }
            }
        }

		// getters 
		get currentUser() {
          return authStore.auth.getters.user.value || null;
        }
		get remainder() {
			let remainder = 0;
			if(this.purchaseInvoice && this.purchaseInvoice.projectRelationships && this.projectRelationshipSync) {
				const total = this.purchaseInvoice.projectRelationships.map(pr => pr.total ? this.signAmount(pr.total, pr.type) : 0).reduce((prev, curr) => prev+curr, 0)
				const difference = (this.purchaseInvoice.total ? Math.abs(this.purchaseInvoice.total) : 0) - Math.abs(total);
				remainder = difference
			}
			return remainder
		}

		// watchers
		@Watch('purchaseInvoice', { deep: true, immediate: false })
		onWatchPurchaseInvoice(purchaseInvoice: any) {
			this.content = {...this.content, purchaseInvoice: JSON.parse(JSON.stringify(purchaseInvoice))}
		}
		@Watch('showModal', {immediate: true})
		onWatchShowModal(showModal: boolean) {
			if(showModal && this.purchaseInvoice && this.purchaseInvoice.pdf && this.purchaseInvoice.pdf.file) {
				this.loadAnnotationsForInvoice(this.purchaseInvoice)
			}
		}
		
		
		// lifecycle
		mounted(): void {
			//
		}

		created(): void {
			//
		}

		destroyed(): void {
			//
		}
	}
