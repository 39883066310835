import Vue from 'vue';
import { InvoiceState } from '@/modules/sales-invoices/store/types/InvoiceState';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/core/types/RootState';
import { Actions, Mutations } from '@/modules/sales-invoices/store/types/StoreTypes';
import { AxiosResponse } from 'axios';
import { SalesInvoice } from '@/modules/sales-invoices/types/entities';
import applyFilter from '@/core/helpers/Filter';
import { Filter, FilterState } from '@/core/types/Filter';
import { Supplier, Client } from '@/modules/entities/types/entities';
import { ProgressSnapshotItem, Project } from '@/modules/projects/types/entities';
import { SalesInvoicesService } from '../services/sales-invoices.service';
import { SalesInvoicesCommandsService } from '../services/sales-invoices-commands.service';
import { JsonResource, Pagination } from '@/core/types/Entities';
import { Payment } from '@/modules/payments/types/entities';
import { ProjectsService } from '@/services/projects.service';
import { ReportsService } from '@/modules/reports/services/reports.service';
import { Contact } from '@/modules/contacts/types/entities';
import { ProgressSnapshotsService } from '@/services/progress-snapshots.service';

const namespace = 'salesInvoices';
const salesInvoicesService = new SalesInvoicesService();
const reportsService = new ReportsService();
const salesInvoiceCommandsService = new SalesInvoicesCommandsService();

export const actions: ActionTree<InvoiceState, RootState> = {
  [Actions.FETCH_LISTVIEW_INVOICES]: async({state, commit, dispatch}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const filterUrl = applyFilter(state.filters);
      const url = '/api/v1/invoices/sales-invoices' + (filterUrl ? '?'+filterUrl : '');
      const result = await salesInvoicesService.getAllInvoices(url);
      commit(Mutations.MUTATE_LISTVIEW_LINKS, result.data.links);
      commit(Mutations.MUTATE_LISTVIEW_META, result.data.meta);
      commit(Mutations.MUTATE_LISTVIEW_INVOICES, result.data.data);

      // when reporting summary is shown, automatically retrieve report results to update summary
      if(state.invoicesShowSummaryReport) await dispatch(Actions.FETCH_INVOICES_SUMMARY_REPORT);
      if(state.invoicesShowMetricsReport) await dispatch(Actions.FETCH_INVOICES_METRICS_REPORT);

    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_LISTVIEW_NEXT_PAGE]: async ({ commit, state }: ActionContext<InvoiceState, RootState>) => {
    const listviewIsLoading = state.listviewIsLoading;
    if(!listviewIsLoading) {
      commit(Mutations.MUTATE_LISTVIEW_IS_LOADING, true);
      try {
        if (state.links && state.links.next) {
          const url = state.links.next;
          const result = await salesInvoicesService.getAllInvoices(url);
          commit(Mutations.MUTATE_LISTVIEW_LINKS, result.data.links);
          commit(Mutations.MUTATE_LISTVIEW_META, result.data.meta);
          commit(Mutations.MUTATE_LISTVIEW_PUSH_NEXT_PAGE, { links: result.data.links, invoices: result.data.data });
        }
      } catch (err: any) {
        console.warn(err);
        throw err;
      } finally {
        commit(Mutations.MUTATE_LISTVIEW_IS_LOADING, false);
      }
    }
  },
  [Actions.FETCH_INVOICES_SUMMARY_REPORT]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const filterUrl = applyFilter(state.filters);
      const url = '/api/v1/reports/sales-invoices-summary' + (filterUrl ? '?'+filterUrl : '');
      const result = await reportsService.getReport(url);
      commit(Mutations.MUTATE_INVOICES_SUMMARY_REPORT, result.data.data);
      return result.data.data;
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.TOGGLE_SHOW_INVOICES_SUMMARY_REPORT]: async({state, commit, dispatch}: ActionContext<InvoiceState, RootState>) => {
    if(state.invoicesShowSummaryReport) commit(Mutations.MUTATE_INVOICES_SHOW_SUMMARY_REPORT, false);
    else {
      await dispatch(Actions.FETCH_INVOICES_SUMMARY_REPORT);
      commit(Mutations.MUTATE_INVOICES_SHOW_SUMMARY_REPORT, true);
    }
  },
  [Actions.TOGGLE_SHOW_INVOICES_METRICS_REPORT]: async({state, commit, dispatch}: ActionContext<InvoiceState, RootState>) => {
    if(state.invoicesShowMetricsReport) commit(Mutations.MUTATE_INVOICES_SHOW_METRICS_REPORT, false);
    else {
      await dispatch(Actions.FETCH_INVOICES_METRICS_REPORT);
      commit(Mutations.MUTATE_INVOICES_SHOW_METRICS_REPORT, true);
    }
  },
  [Actions.FETCH_INVOICES_METRICS_REPORT]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const filterUrl = applyFilter(state.filters);
      const url = '/api/v1/reports/sales-invoices-metrics' + (filterUrl ? '?'+filterUrl : '');
      const result = await reportsService.getReport(url);
      commit(Mutations.MUTATE_INVOICES_METRICS_REPORT, result.data.data);
      return result.data.data;
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_STATUSES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await salesInvoicesService.getAllStatuses();
      commit(Mutations.MUTATE_STATUSES, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_STATUSES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await salesInvoicesService.getAllStatuses();
      commit(Mutations.MUTATE_STATUSES, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_PAYMENT_STATUSES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await salesInvoicesService.getAllPaymentStatuses();
      commit(Mutations.MUTATE_PAYMENT_STATUSES, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_TYPES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await salesInvoicesService.getAllTypes();
      commit(Mutations.MUTATE_TYPES, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_RECORD_TYPES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await salesInvoicesService.getAllRecordTypes();
      commit(Mutations.MUTATE_RECORD_TYPES, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_CONTACTS]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    const filterUrl = applyFilter(state.contactSearchFilters);
    const url = '/api/v1/commands/invoices/search-contacts' + (filterUrl ? '?'+filterUrl : '');
    const client = Vue.prototype.$http;

    // custom contact filter url
    await client.get(url).then((response: AxiosResponse<JsonResource<Contact[]>>) => {
      if(response.status === 200){
        commit(Mutations.MUTATE_CONTACTS, response.data.data);
      }
    });
  },
  [Actions.FETCH_PROJECTS]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    const filterUrl = applyFilter(state.projectSearchFilters);
    const url = '/api/v1/projects' + (filterUrl ? '?'+filterUrl : '');
    const client = Vue.prototype.$http;
    await client.get(url).then((response: AxiosResponse<JsonResource<Project[]>>) => {
      if(response.status === 200){
        commit(Mutations.MUTATE_PROJECTS, response.data.data);
      }
    });
  },
  [Actions.FETCH_SUPPLIERS]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    const filterUrl = applyFilter(state.supplierSearchFilters);
    const url = '/api/v1/suppliers' + (filterUrl ? '?'+filterUrl : '');
    const client = Vue.prototype.$http;
    await client.get(url).then((response: AxiosResponse<JsonResource<Supplier[]>>) => {
      if(response.status === 200){
        commit(Mutations.MUTATE_SUPPLIERS, response.data.data);
      }
    });
  },
  [Actions.FETCH_CLIENTS]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    const filterUrl = applyFilter(state.clientSearchFilters);
    const url = '/api/v1/clients' + (filterUrl ? '?'+filterUrl : '');
    const client = Vue.prototype.$http;
    await client.get(url).then((response: AxiosResponse<JsonResource<Client[]>>) => {
      if(response.status === 200){
        commit(Mutations.MUTATE_CLIENTS, response.data.data);
      }
    });
  },
  [Actions.FETCH_RELATED_BILLING_ADDRESSES]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    const filterUrl = applyFilter(state.relatedBillingAddressesSearchFilters);
    const url = '/api/v1/commands/invoices/search-billing-addresses' + (filterUrl ? '?'+filterUrl : '');
    const client = Vue.prototype.$http;

    // custom contact filter url
    await client.get(url).then((response: AxiosResponse<JsonResource<SalesInvoice[]>>) => {
      if(response.status === 200){
        commit(Mutations.MUTATE_RELATED_BILLING_ADDRESSES, response.data.data);
      }
    });
  },
  [Actions.FETCH_NEW]: async({state, commit}: ActionContext<InvoiceState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    try {
      const result = await salesInvoiceCommandsService.getNewInvoice();
      commit(Mutations.MUTATE_NEW, result.data.data);
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.FETCH_PROJECT_INVOICES]: async({state, commit}: ActionContext<InvoiceState, RootState>, payload: number) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    const filterState = state.projectInvoicesFilters;

    // TODO: until we have a /projects/sales-invoices endpoint, use the index endpoint of sales-invoices
    filterState.filters.projects = new Filter<number>(
      'projects',
      'projects',
            [payload] as number[],
            true,
            'projects'
    );

    try {
      const filterUrl = applyFilter(filterState);
      const url = '/api/v1/invoices/sales-invoices' + (filterUrl ? '?'+filterUrl : '');
      const result = await salesInvoicesService.getAllInvoices(url);
      commit(Mutations.MUTATE_PROJECT_INVOICES, result.data.data);

      return result.data.data;
    } catch (err: any) {
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.APPEND_TO_INVOICES]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: SalesInvoice) => {
    const invoices = state.listviewInvoices.slice();
    invoices.unshift(payload);
    commit(Mutations.MUTATE_APPEND_TO_INVOICES, invoices);
  },
  [Actions.DELETE_INVOICE_BY_ID]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: SalesInvoice) => {
    // create or update?
    if(payload.id !== undefined && payload.id) {
      const url = `/api/v1/invoices/sales-invoices/${ payload.id }`;
      const client = Vue.prototype.$http;
      await client.delete(url).then((response: AxiosResponse) => {
        if(response.status === 200){
          const invoices = state.listviewInvoices.filter(invoice => invoice.id !== payload.id);
          commit(Mutations.MUTATE_LISTVIEW_INVOICES, invoices);
        }
      });
    }
  },
  [Actions.CREATE_INVOICE]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: SalesInvoice) => {
    commit(Mutations.MUTATE_INVOICE, payload);
  },
  [Actions.UPDATE_INVOICE_IN_LISTVIEW_INVOICES]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: SalesInvoice) => {
    if(state.listviewInvoices) {
      const items = state.listviewInvoices.map((item: SalesInvoice) => item.id !== payload.id ? item : {...item, ...payload});
      commit(Mutations.MUTATE_LISTVIEW_INVOICES, items);
    }
  },
  [Actions.SAVE_PAYMENT]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: {invoiceId: string; payment: Payment}) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has id?
    const paymentId = payload.payment && (payload.payment.id !== undefined && payload.payment.id) || undefined;
    let result: AxiosResponse<JsonResource<Payment>> | undefined = undefined;
    let items = state.invoice && state.invoice.payments ? [...state.invoice.payments] : [] as Payment[];

    try {
      // create
      if(!paymentId) {
        result = await salesInvoicesService.createPayment(Number.parseInt(payload.invoiceId), payload.payment);

        // add item to list on invoice
        if(state.invoice && state.invoice.payments) {
          items = [...state.invoice.payments, result.data.data];
        }
      }
      // update
      else {
        result = await salesInvoicesService.updatePaymentById(Number.parseInt(payload.invoiceId), payload.payment);

        // edit item in list on invoice
        if(state.invoice && state.invoice.payments && !!result) {
          const payment = result.data.data;
          items = state.invoice.payments && state.invoice.payments.length ? [...state.invoice.payments] : [] as Payment[];
          const index = items.findIndex(item => item.id === payment.id);
          items.splice(index === -1 ? 0 : index, index === -1 ? 0 : 1, {...result.data.data});
        }
      }

      // invoice data to be updated
      const paymentStatus = result.data.data.invoice ? result.data.data.invoice.paymentStatus : null;

      const invoice = { ...state.invoice, payments: items, paymentStatus: paymentStatus } as SalesInvoice;
      commit(Mutations.MUTATE_INVOICE, invoice);

      // result
      return result;

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.SAVE_PAYMENT_ON_LISTVIEW_INVOICES]: async ({ commit, dispatch, state }: ActionContext<InvoiceState, RootState>, payload: {invoiceId: string; payment: Payment}) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    // has id?
    const paymentId = payload.payment && (payload.payment.id !== undefined && payload.payment.id) || undefined;
    let result: AxiosResponse<JsonResource<Payment>> | undefined = undefined;
    const items = state.listviewInvoices ? [...state.listviewInvoices] : [] as SalesInvoice[];

    try {
      // create
      if(!paymentId) {
        result = await salesInvoicesService.createPayment(Number.parseInt(payload.invoiceId), payload.payment);

        // invoice data to be updated
        const payment = result.data.data ? result.data.data : null;
        const paymentInvoice = result.data.data.invoice ? result.data.data.invoice as SalesInvoice : null;

        // update invoice in listview
        const index = items.findIndex(invoice => invoice.id === Number.parseInt(payload.invoiceId));
        if(index > -1 && paymentInvoice && paymentInvoice.paymentStatus) {
          const invoice = items[index];
          const payments = invoice.payments && payment ? [...invoice.payments.filter(p => p.id !== payment.id), payment ] : [];
          items.splice(index, 1, {...invoice, paymentStatus: paymentInvoice.paymentStatus, isDue: paymentInvoice.isDue, payments:  payments } as SalesInvoice);
        }
      }

      commit(Mutations.MUTATE_LISTVIEW_INVOICES, items);

      // result
      return result;

    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.DELETE_PAYMENT]: async ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: {invoiceId: string; paymentId: string} ) => {
    commit(Mutations.MUTATE_IS_LOADING, true);

    try {
      const result = await salesInvoicesService.deletePaymentById(Number.parseInt(payload.invoiceId), Number.parseInt(payload.paymentId));
      const items = state.invoice && state.invoice.payments ? [...state.invoice.payments] : [] as Payment[];

      if(state.invoice && state.invoice.payments) {
        const index = items.findIndex(payment => payment.id === Number.parseInt(payload.paymentId));
        items.splice(index, 1);
      }

      // invoice data to be updated
      const paymentStatus = result.data.data.invoice ? result.data.data.invoice.paymentStatus : null;
      const invoice = { ...state.invoice, payments: items, paymentStatus: paymentStatus };
      commit(Mutations.MUTATE_INVOICE, invoice);
    } catch (err: any) {
      // TODO: proper delete error message
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.SAVE_PROGRESS_SNAPSHOT_ITEM]: async ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: {progressSnapshotId: string; progressSnapshotItem: ProgressSnapshotItem} ) => {
    commit(Mutations.MUTATE_IS_LOADING, true);
    const progressSnapshotService = new ProgressSnapshotsService();
    let result: AxiosResponse<JsonResource<ProgressSnapshotItem>> | undefined = undefined;
    let items = state.invoice && state.invoice.progressSnapshot && state.invoice.progressSnapshot.items ? [...state.invoice.progressSnapshot.items] : [] as ProgressSnapshotItem[];

    try {
      // create
      if(!payload.progressSnapshotItem.id) {
        result = await progressSnapshotService.createProgressSnapshotItem(Number.parseInt(payload.progressSnapshotId), payload.progressSnapshotItem);

        // add item to list on progressSnapshot on invoice
        if(items) items = [...items, result.data.data];
      }
      // update
      else {
        result = await progressSnapshotService.updateProgressSnapshotItemById(Number.parseInt(payload.progressSnapshotId), payload.progressSnapshotItem);

        // edit item in list on invoice
        if(items && !!result) {
          const progressSnapshotItem = result.data.data;
          const index = items.findIndex(item => item.id === progressSnapshotItem.id);
          items.splice(index === -1 ? 0 : index, index === -1 ? 0 : 1, {...result.data.data});
        }
      }

      const progressSnapshot = state.invoice && state.invoice.progressSnapshot ? {...state.invoice.progressSnapshot, items: items} : null;
      commit(Mutations.MUTATE_INVOICE, {...state.invoice, progressSnapshot: progressSnapshot});

      // result
      return result;

    } catch (err: any) {
      // TODO: proper delete error message
      console.warn(err);
      throw err;
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },

  // filters
  [Actions.TOGGLE_FILTER_SIDEBAR]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: boolean) => {
    commit(Mutations.MUTATE_TOGGLE_SIDEBAR, payload);
  },
  [Actions.TOGGLE_FILTER_GROUP]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: {group: string|undefined; fetch?: boolean}) => {
    const newPayload = {group: undefined, fetch: true};
    Object.assign(newPayload, payload);

    commit(Mutations.MUTATE_TOGGLE_FILTER_GROUP, newPayload.group);
    if(newPayload.fetch) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.CLEAR_FILTERS]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: {filters: string[]; fetch?: boolean}) => {
    const newPayload = {filters: undefined, fetch: true};
    Object.assign(newPayload, payload);

    commit(Mutations.MUTATE_CLEAR_FILTERS, payload.filters);
    if(newPayload.fetch) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_PROJECT_NAME]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string) => {
    const projectName = payload.split(' ');
    commit(Mutations.MUTATE_FILTER_PROJECT_NAME, projectName);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_PROJECT]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: Project) => {
    // when a project is selected, all other filters should be cleared
    if(state.filters.filters.project && (!state.filters.filters.project.active || !state.filters.filters.project.hasValues())) {
      const filtersToClear = [] as string[];
      Object.keys(state.filters.filters).forEach((key: string) => {
        filtersToClear.push(key);
      });
      commit(Mutations.MUTATE_CLEAR_FILTERS, filtersToClear);
    }

    commit(Mutations.MUTATE_FILTER_PROJECT_MODEL, payload ? payload : null);
    commit(Mutations.MUTATE_FILTER_PROJECT, payload ? [payload.id] : []);

    if(state.invoicesMetricsReport) dispatch(Actions.FETCH_INVOICES_METRICS_REPORT);
    if(state.filters.filters.project.active) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_PROJECT_LEAD]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.filters.filters.projectLead.value];
    if (state.filters.filters.projectLead.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_FILTER_PROJECT_LEAD, ids);
    if(state.filters.filters.projectLead.active) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_STATUS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.filters.filters.status.value];
    if (state.filters.filters.status.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_FILTER_STATUS, ids);
    if(state.filters.filters.status.active) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_PAYMENT_STATUS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.filters.filters.paymentStatus.value];
    if (state.filters.filters.paymentStatus.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_FILTER_PAYMENT_STATUS, ids);
    if(state.filters.filters.paymentStatus.active) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_TYPE]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.filters.filters.type.value];
    if (state.filters.filters.type.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_FILTER_TYPE, ids);
    if(state.filters.filters.type.active) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_INVOICE_DATE_FROM]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_FILTER_INVOICE_DATE_FROM, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_INVOICE_DATE_TO]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_FILTER_INVOICE_DATE_TO, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_DUE_DATE_FROM]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_FILTER_DUE_DATE_FROM, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_DUE_DATE_TO]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string|null) => {
    commit(Mutations.MUTATE_FILTER_DUE_DATE_TO, payload ? [payload] : []);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_FOR_SEARCH]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string) => {
    const terms = [] as string[];
    terms.push(payload);

    // set search filters
    commit(Mutations.MUTATE_FILTER_FOR_SEARCH, terms);
    dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.SET_FILTER_ON_VAT]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    let ids = [...state.filters.filters.vat.value];
    if (state.filters.filters.vat.value.some((id: number) => id === payload)) {
      ids = ids.filter(id => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_FILTER_VAT, ids);

    if(state.filters.filters.vat.active) dispatch(Actions.FETCH_LISTVIEW_INVOICES);
  },
  [Actions.FETCH_SALES_INVOICE_BY_ID]: async ({ commit, state }: ActionContext<InvoiceState, RootState>, payload: string) => {
    // reset current state
    commit(Mutations.MUTATE_IS_LOADING, true);
    commit(Mutations.MUTATE_INVOICE, null);

    try {
      const result = await salesInvoicesService.getInvoiceById(Number.parseInt(payload));
      commit(Mutations.MUTATE_INVOICE, result.data.data);
    } finally {
      commit(Mutations.MUTATE_IS_LOADING, false);
    }
  },
  [Actions.SAVE_INVOICE]: async ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: SalesInvoice) => {
    // has ids?
    const salesInvoiceId = (payload.id !== undefined && payload.id) || undefined;

    // try {
    let result: AxiosResponse<JsonResource<SalesInvoice>> | null = null;

    // create
    if(!salesInvoiceId) {
      result = await salesInvoicesService.createInvoice(payload);
      commit(Mutations.MUTATE_INVOICE, result.data.data);
      dispatch(Actions.APPEND_TO_INVOICES, result.data.data);
    }
    // update
    else {
      result = await salesInvoicesService.updateInvoiceById(payload);
      commit(Mutations.MUTATE_INVOICE, result.data.data);
    }

    return result.data.data;
  },
  [Actions.SET_FILTER_ON_CONTACT_SEARCH]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string) => {
    const terms = [] as string[];
    terms.push(payload);
    commit(Mutations.MUTATE_FILTER_ON_CONTACT_SEARCH, terms);
    return dispatch(Actions.FETCH_CONTACTS);
  },
  [Actions.SET_FILTER_ON_CONTACT_SEARCH_PROJECTS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    commit(Mutations.MUTATE_FILTER_ON_CONTACT_SEARCH_PROJECTS, [payload]);
    dispatch(Actions.FETCH_CONTACTS);
  },
  [Actions.SET_FILTER_ON_CONTACT_SEARCH_CLIENTS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    commit(Mutations.MUTATE_FILTER_ON_CONTACT_SEARCH_CLIENTS, [payload]);
    dispatch(Actions.FETCH_CONTACTS);
  },
  [Actions.SET_FILTER_ON_CONTACT_SEARCH_SUPPLIERS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    commit(Mutations.MUTATE_FILTER_ON_CONTACT_SEARCH_SUPPLIERS, [payload]);
    dispatch(Actions.FETCH_CONTACTS);
  },
  [Actions.CLEAR_FILTERS_ON_CONTACT_SEARCH]: ({commit, state, dispatch}: ActionContext<InvoiceState, RootState>, payload: string[]) => {
    commit(Mutations.MUTATE_CLEAR_FILTERS_ON_CONTACT_SEARCH, payload);
    dispatch(Actions.FETCH_CONTACTS);
  },
  [Actions.SET_FILTER_ON_PROJECT_SEARCH]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string) => {
    const terms = [] as string[];
    terms.push(payload);
    commit(Mutations.MUTATE_FILTER_ON_PROJECT_SEARCH, terms);
    return dispatch(Actions.FETCH_PROJECTS);
  },
  [Actions.SET_FILTER_ON_SUPPLIER_SEARCH]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string) => {
    const terms = [] as string[];
    terms.push(payload);
    commit(Mutations.MUTATE_FILTER_ON_SUPPLIER_SEARCH, terms);
    return dispatch(Actions.FETCH_SUPPLIERS);
  },
  [Actions.SET_FILTER_ON_CLIENT_SEARCH]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: string) => {
    const terms = [] as string[];
    terms.push(payload);
    commit(Mutations.MUTATE_FILTER_ON_CLIENT_SEARCH, terms);
    return dispatch(Actions.FETCH_CLIENTS);
  },
  [Actions.SET_FILTER_ON_CLIENT_SEARCH_PROJECTS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    commit(Mutations.MUTATE_FILTER_ON_CLIENT_SEARCH_PROJECTS, [payload]);
    dispatch(Actions.FETCH_CLIENTS);
  },
  [Actions.SET_FILTER_ON_RELATED_BILLING_ADDRESSES_PROJECTS]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number) => {
    commit(Mutations.MUTATE_FILTER_ON_RELATED_BILLING_ADDRESSES_PROJECTS, [payload]);
    dispatch(Actions.FETCH_RELATED_BILLING_ADDRESSES);
  },
  [Actions.SET_FILTER_ON_PROJECT_INVOICES_EXCEPT]: ({ commit, state, dispatch }: ActionContext<InvoiceState, RootState>, payload: number[]) => {
    commit(Mutations.MUTATE_FILTER_ON_PROJECT_INVOICES_EXCEPT, payload);
    dispatch(Actions.FETCH_PROJECT_INVOICES);
  },

  // search
  [Actions.SEARCH_PROJECTS]: async({state, commit}: ActionContext<InvoiceState, RootState>, payload: string) => {
    const projectsService = new ProjectsService();

    try {
      const filterState = {
        filters: {
          search: new Filter<string>(
            'search',
            'query',
                        [payload] as string[],
                        true,
                        'search',
                        null
          ),
        },
      } as FilterState;
      const filterUrl = applyFilter(filterState);
      const url = '/api/v1/projects' + (filterUrl ? '?'+filterUrl : '');
      const result = await projectsService.getAllProjects(url);

      commit(Mutations.MUTATE_SEARCH_PROJECTS, result.data.data);
      return result.data.data;
    } catch (err: any) {
      console.error(err);
    }
  },
};