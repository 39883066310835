
	import { Comment } from '@/modules/entities/types/entities';
	import { defineComponent, PropType, ref, onMounted } from 'vue';
	import { formatDate } from '@/core/helpers/useFormatDate';

	export default defineComponent({
		name: 'AppCommentComponent',
		emits: ['input', 'edit', 'close', 'save'],
		props: {
			comment: {
				type: Object as PropType<Comment>, required: true
			},
			edit: {
				type: Boolean, default: false
			},
			isEditable: {
				type: Boolean, default: false
			}
		},
		setup(props) {
			return {
				formatDate
			}
		}
	})
