
import { Component, Vue, Provide, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { debounce } from 'lodash';
import {
  PurchaseInvoice,
  PurchaseInvoiceStatus,
  PurchaseInvoiceEntity,
  PurchaseInvoicePaymentStatus,
  PurchaseInvoiceRecordType,
  ApprovalStatus,
  AccountingStatus
} from '@/modules/purchase-invoices/types/entities';
import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
import InvoicesSideMenuComponent from '@/modules/purchase-invoices/components/purchase-invoices-sidebar.component.vue';
import PurchaseInvoiceModal from '@/modules/purchase-invoices/components/purchase-invoice-modal/purchase-invoice-modal.component.vue'
import { BankTransactionEntity, Payment, PaymentEntity, TransactionType } from '@/modules/payments/types/entities';
import { FilterState } from '@/core/types/Filter';
import { calculatePaymentDifference, signAmount, suggestPayment } from '../../helpers';
import { User } from '@/modules/entities/types/entities';
import useUserList from '@/composables/useUserList';
import PaymentEditPopupComponent from '@/modules/payments/components/payment-edit-popup.component.vue';
    
	const purchaseInvoices = namespace("purchaseInvoices");

	// composables
	const { state: userState, actions: userActions } = useUserList()

	@Component({
		components: {
			"invoices-side-menu": InvoicesSideMenuComponent,
			"purchase-invoice-modal": PurchaseInvoiceModal,
			'payment-create-popup': PaymentEditPopupComponent,
		}
	})
    export default class PurchaseInvoicesComponent extends Vue {

		// actions
		@purchaseInvoices.Action(Actions.FETCH_LISTVIEW_INVOICES) fetchInvoices!: () => void;
		@purchaseInvoices.Action(Actions.FETCH_LISTVIEW_NEXT_PAGE) fetchNextPage!: () => void;
		@purchaseInvoices.Action(Actions.FETCH_STATUSES) fetchStatuses!: () => Promise<void>;
		@purchaseInvoices.Action(Actions.FETCH_APPROVAL_STATUSES) fetchApprovalStatuses!: () => Promise<void>;
		@purchaseInvoices.Action(Actions.FETCH_PAYMENT_STATUSES) fetchPaymentStatuses!: () => Promise<void>;
    @purchaseInvoices.Action(Actions.FETCH_ACCOUNTING_STATUSES) fetchAccountingStatuses!: () => Promise<void>;
		@purchaseInvoices.Action(Actions.SET_LISTVIEW_FILTER_FOR_SEARCH) filterForSearch!: (value: string) => Promise<void>;
		@purchaseInvoices.Action(Actions.CLEAR_LISTVIEW_FILTERS) clearFilters!: (payload: string[]) => void;
		@purchaseInvoices.Action(Actions.CREATE_INVOICE) createPurchaseInvoice!: (payload: PurchaseInvoice) => void;
		@purchaseInvoices.Action(Actions.FETCH_RECORD_TYPES) fetchRecordTypes!: () => Promise<void>;
		@purchaseInvoices.Action(Actions.FETCH_NEW) fetchNew!: () => Promise<void>;
		@purchaseInvoices.Action(Actions.SAVE_PAYMENT_ON_LISTVIEW_INVOICES) savePayment!: (payload: { invoiceId: string, payment: Payment }) => Promise<void>;
		@purchaseInvoices.Action(Actions.TOGGLE_SHOW_INVOICES_SUMMARY_REPORT) toggleShowInvoicesSummaryReport!: () => Promise<void>;
		@purchaseInvoices.Action(Actions.TOGGLE_SHOW_INVOICES_METRICS_REPORT) toggleShowInvoicesMetricsReport!: () => Promise<void>;
		
		// getters 
		@purchaseInvoices.Getter(Getters.GET_LISTVIEW_FILTER_STATE) filterState!: FilterState;
		@purchaseInvoices.Getter(Getters.GET_LISTVIEW_INVOICES) invoices!: PurchaseInvoice[];
		@purchaseInvoices.Getter(Getters.GET_STATUSES_TRANSLATED) statuses!: PurchaseInvoiceStatus[];
		@purchaseInvoices.Getter(Getters.GET_APPROVAL_STATUSES_TRANSLATED) approvalStatuses!: ApprovalStatus[];
		@purchaseInvoices.Getter(Getters.GET_PAYMENT_STATUSES_TRANSLATED) paymentStatuses!: PurchaseInvoicePaymentStatus[];
    @purchaseInvoices.Getter(Getters.GET_ACCOUNTING_STATUSES_TRANSLATED) accountingStatuses!: AccountingStatus[];
		@purchaseInvoices.Getter(Getters.GET_RECORD_TYPES_TRANSLATED) recordTypes!: PurchaseInvoiceRecordType[];
		@purchaseInvoices.Getter(Getters.GET_LISTVIEW_IS_LOADING) isLoading!: boolean;
		@purchaseInvoices.Getter(Getters.GET_NEW) new!: PurchaseInvoice;
		@purchaseInvoices.Getter(Getters.GET_SHOW_INVOICES_SUMMARY_REPORT) showInvoicesSummaryReport!: boolean;
		@purchaseInvoices.Getter(Getters.GET_SHOW_INVOICES_METRICS_REPORT) showInvoicesMetricsReport!: boolean;
		@purchaseInvoices.Getter(Getters.GET_INVOICES_SUMMARY_REPORT) invoicesSummaryReport!: any;
		@purchaseInvoices.Getter(Getters.GET_INVOICES_METRICS_REPORT) invoicesMetricsReport!: any;
		
		// local vars
		state: {
			showModalCreatePurchaseInvoice: boolean,
			showStatusLabel: boolean,
			showMenuAddPayment: {},
        } = {
			showModalCreatePurchaseInvoice: false,
			showStatusLabel: false,
			showMenuAddPayment: {},
		}
		content: {
			searchValueMain: string,
			statusesAvailableForFilter: PurchaseInvoiceStatus[],
			approvalStatusesAvailableForFilter: ApprovalStatus[],
			paymentStatusesAvailableForFilter: PurchaseInvoicePaymentStatus[],
            accountingStatusesAvailableForFilter: AccountingStatus[],
			paymentCreate: Payment,
    } = {
			searchValueMain: '',
			statusesAvailableForFilter: [] as PurchaseInvoiceStatus[],
			approvalStatusesAvailableForFilter: [] as ApprovalStatus[],
			paymentStatusesAvailableForFilter: [] as PurchaseInvoicePaymentStatus[],
            accountingStatusesAvailableForFilter: [] as AccountingStatus[],
			paymentCreate: this.newPayment(),
    }

		filterForSearchDebounced = debounce((value: string) => {
			this.filterForSearch(value);
		}, 400);
		clearSearchFilter(): void {
			this.content = {...this.content, searchValueMain: ''}
			this.clearFilters(['search']);
		}

		// handlers
		onClickStatus(event: Event): void {
			this.state = {...this.state, showStatusLabel: !this.state.showStatusLabel};
		}
		async onClickAddPurchaseInvoice() {
			await this.fetchNew()
			this.createPurchaseInvoice({ ...this.newPurchaseInvoice(), name: this.new.name });
			this.state = {...this.state, showModalCreatePurchaseInvoice: true}
		}
		onClickAddPaymentForInvoice(invoice: PurchaseInvoice) {
			if(invoice.id) this.$set(this.state.showMenuAddPayment, invoice.id.toString(), true);
			const paymentSuggestion = this.suggestPayment(invoice)
			this.content = { ...this.content, 
				paymentCreate: {...this.newPayment(), type: paymentSuggestion.type } as Payment
			}
        }
        onClickSavePaymentForInvoice(payment: Payment, invoice: PurchaseInvoice) {
			const invoiceId = !!invoice.id && invoice.id;
            this.savePayment({ invoiceId: invoiceId.toString(), payment: payment }).then((value) => {
                this.$set(this.state.showMenuAddPayment, invoiceId.toString(), false);
                this.content = {...this.content, paymentCreate: {...this.newPayment()} }
            })
		}

		// methods
		loadNextPage(): void {
			this.fetchNextPage();
		}
		signAmount(amount: number, recordType: PurchaseInvoiceRecordType) {
			return signAmount(amount, recordType)
		}
		newPurchaseInvoice() {
			const invoice = new PurchaseInvoiceEntity({
				status: this.statuses.find((status) => status.name === 'not_labeled'),
				paymentStatus: this.paymentStatuses.find((paymentStatus) => paymentStatus.name === 'not_paid'),
				approvalStatus: this.approvalStatuses.find((approvalStatus) => approvalStatus.name === 'not_approved'),
				recordType: this.recordTypes.find((recordType) => recordType.name === 'invoice'),
            })
			return invoice;
		}
		newPayment() {
			return new PaymentEntity({
				total: null,
				bankTransaction: new BankTransactionEntity({
					executedAt: Vue.prototype.$formatISO8601()
				})
			})
		}
		calculatePaymentDifference(invoice: PurchaseInvoice) {
			if(invoice.paymentTotal && invoice.recordType && invoice.payments) {
				return calculatePaymentDifference(invoice, invoice.payments)
			}
			return 0
		}
		suggestPayment(invoice: PurchaseInvoice) {
			return suggestPayment(invoice, invoice.payments ? invoice.payments : [])
		}

		// computed get/set
		get users() { return userState.users.value || [] }
		get showReports() {
			return this.showInvoicesMetricsReport
		}
		get showExecutedAtDate(): string {
			if(this.content.paymentCreate && this.content.paymentCreate.bankTransaction && this.content.paymentCreate.bankTransaction.executedAt) {
				return Vue.prototype.$formatDate(this.content.paymentCreate.bankTransaction.executedAt);
			}
			return '';
        }
		get headers() {
			return [
				{
					text: '',
					iconPath: '',
					width: '45px',
					minWidth: '45px',
				},
				{
					text: "invoices.purchase.dataTable.headers.name",
					iconPath: "",
				},
				{
					text: "invoices.purchase.dataTable.headers.externalName",
					iconPath: "",
					// width: '120px',
					// minWidth: '120px',
				},
				{
					text: "invoices.purchase.dataTable.headers.supplier",
					iconPath: "",
					// width: '250px',
					// minWidth: '250px',
				},
				{
					text: "invoices.purchase.dataTable.headers.invoiceDate",
					iconPath: "",
					// width: '110px',
					// minWidth: '110px',
				},
				{
					text: "invoices.purchase.dataTable.headers.dueDate",
					iconPath: "",
					// width: '110px',
					// minWidth: '110px',
				},
				{
					text: "invoices.purchase.dataTable.headers.status",
					iconPath: "",
					width: '80px',
					minWidth: '80px',
				},
				{
					text: "invoices.purchase.dataTable.headers.total",
					iconPath: "",
					// width: '150px',
					// minWidth: '150px',
				},
				{
					text: "invoices.purchase.dataTable.headers.paymentTotal",
					iconPath: "",
					// width: '150px',
					// minWidth: '150px',
				},
				{
					text: "invoices.purchase.dataTable.headers.comment",
					iconPath: ""
				}
			];
		}
		get invoicesSummaryReportFormatters() {
			return [
				{
					value:'totalBalance', 
					format: (value: any) => { return Vue.prototype.$formatMoney(value) }
				},
				{
					value:'grandTotalBalance', 
					format: (value: any) => { return Vue.prototype.$formatMoney(value) }
				},
			]
		}
		get invoicesSummaryReportSummarizers() {
			return [
				{
					value: 'totalBalance',
					summarize: (items: any[]) => { 
						return items.map((item: any) => item.totalBalance ? Number.parseInt(item.totalBalance) : 0).reduce((prev, curr) => { return prev+curr }, 0)
					}, 
				},
				{
					value: 'grandTotalBalance',
					summarize: (items: any[]) => { 
						return items.map((item: any) => item.grandTotalBalance ? Number.parseInt(item.grandTotalBalance) : 0).reduce((prev, curr) => { return prev+curr }, 0)
					}, 
				},
			];
		}
		get invoicesSummaryReportHeaders() {
			return [
				{
					text: this.$t('invoices.purchase.reports.labels.year').toString(),
					align: 'end',
					sortable: true,
					value: 'year',
					groupable: true,
					sort: (a: string, b: string) => { return b && a ? Number.parseInt(a) - Number.parseInt(b) : 0}
				},
				{
					text: this.$t('invoices.purchase.reports.labels.month').toString(),
					align: 'start',
					sortable: true,
					value: 'month',
					groupable: true,
					sort: (a: string, b: string) => { return b && a ? Number.parseInt(a) - Number.parseInt(b) : 0}
				},
				{
					text: this.$t('invoices.purchase.dataTable.headers.total').toString(),
					align: 'end',
					sortable: true,
					value: 'totalBalance',
					groupable: false,
				},
				{
					text: this.$t('invoices.purchase.dataTable.headers.grandTotal').toString(),
					align: 'end',
					sortable: true,
					value: 'grandTotalBalance',
					groupable: false,
				},
			]
		}

		// watchers

		requiredData() {
      return [
        this.statuses && this.statuses.length ? null : this.fetchStatuses(),
        this.paymentStatuses && this.paymentStatuses.length ? null : this.fetchPaymentStatuses(),
        this.accountingStatuses && this.accountingStatuses.length ? null : this.fetchAccountingStatuses(),
        this.approvalStatuses && this.approvalStatuses.length ? null : this.fetchApprovalStatuses(),
        this.recordTypes && this.recordTypes.length ? null : this.fetchRecordTypes(),
      ].filter(v => !!v)
    }

		// hooks
		created(): void {
			if(!(this.users && this.users.length)) userActions.fetchUsers()
			Promise.all(this.requiredData()).then((results) => {
				this.fetchInvoices();
				this.content.statusesAvailableForFilter = [...this.statuses]
				this.content.paymentStatusesAvailableForFilter = [...this.paymentStatuses]
        this.content.accountingStatusesAvailableForFilter = [...this.accountingStatuses]
				this.content.approvalStatusesAvailableForFilter = [...this.approvalStatuses.filter((status: ApprovalStatus) => status.name && ['approved','partially_approved','not_approved'].includes(status.name))]
			});
		}
    }
