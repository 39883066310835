import Vue from 'vue';
import {routes} from './core.routing';
import {store as coreStore} from './store';

const req = require.context('./components/', true, /component\.(ts|js|vue)$/i);

/* Attach all core components as global components */
req.keys().map((key) => {
	const component = key.match(/[ \w-]+?(?=\.component\.(ts|js|vue))/);
	if (component) { return Vue.component(component[0], req(key).default); }
});

export const coreRoutes = routes;
export const store = coreStore;
