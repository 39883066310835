
import { computed, defineComponent, PropType } from 'vue'
import { cloneDeep } from 'lodash'
import { Retainment } from '../../types/entities'

export default defineComponent({
  name: 'RetainmentDeletePopup',
  emits: ['onClickDelete'],
  components: {},
  props: {
    retainment: {
      type: Object as PropType<Retainment>,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // computed
    const retainmentCopy = computed(() => cloneDeep(props.retainment))

    const confirmDelete = () => emit('onClickDelete')

    return {
      retainmentCopy,
      confirmDelete
    }
  },
})
