import { reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import useFilter, {createState as createFilterState} from '@/composables/useFilter';
import { UseContractorBranchListState, UseContractorBranchListComposables, UseContractorBranchListContext } from '@/composables/types/useContractorBranchList';
import { merge } from 'lodash';
import { Options } from '@/composables/types/composable';
import { actions as contractorBranchListActions } from '@/composables/actions/useContractorBranchList';
import { useDynamicProps } from './useDynamicProps';
import useList from '@/composables/useList';
import {ContractorBranch} from "@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState";
/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseContractorBranchListState>): UseContractorBranchListState => {
  return merge(
    {
      expandedIds: [],
    },
    state
  );
};

export default function useContractorBranchList(options?: Options<UseContractorBranchListState, UseContractorBranchListComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    list: useList<ContractorBranch>(),
    filter: useFilter({composables: {}, initialState: createFilterState({urlParamPrefix: null})}),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseContractorBranchListContext = { state, composables };
  const actions = wrapActions(ctx, contractorBranchListActions);

  // return state and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // searching
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      // content
      contractorBranches: computed(() => ctx.composables.list.getters.items.value),
    },
    actions: {
      ...ctx.composables.filter.actions,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...ctx.composables.list.actions,
      ...actions,
    },
  };
}