
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import { WorkflowFunctionGroup } from '@/modules/workflow/types/entities';

  @Component
  export default class WorkflowSettingsFunctionGroupsListComponent extends Vue {
    @Prop() functionGroups!: WorkflowFunctionGroup[];
    @Prop() getFunctionGroupColour!: Function;

    @Emit('onEditFunctionGroup')
    onEmitEditFunctionGroup(functionGroup: WorkflowFunctionGroup): WorkflowFunctionGroup {
      return functionGroup;
    }

    @Emit('onDeleteFunctionGroup')
    onEmitDeleteFunctionGroup(functionGroup: WorkflowFunctionGroup): WorkflowFunctionGroup {
      return functionGroup;
    }
  }
