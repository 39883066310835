import {Module} from 'vuex';
import {actions} from './actions';
import {mutations} from './mutations';
import {state} from './state';
import {getters} from './getters';
import {RootState} from '@/core/types/RootState';
import {ContactsState} from '@/modules/contacts/store/types/ContactsState';

export const store: Module<ContactsState, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
