import { ProgressSnapshot, Project } from '@/modules/projects/types/entities';
import { Supplier, Client, ProjectClient } from '@/modules/entities/types/entities';
import { Payment } from '@/modules/payments/types/entities';
import { Contact } from '@/modules/contacts/types/entities';
import { Tax } from '@/modules/settings/types/entities';

export interface SalesInvoice {
	id?: number;
	name: string;
	invoiceDate?: string;
	dueDate?: string;
	isDue: boolean;
	status?: SalesInvoiceStatus | null;
	paymentStatus?: SalesInvoicePaymentStatus | null;
	recordType?: SalesInvoiceRecordType | null;
	type?: SalesInvoiceType | null;
	subtype?: SalesInvoiceType | null;
	billingAddressStreet?: string;
	billingAddressStreetAdditional?: string;
	billingAddressNumber?: string;
	billingAddressPostalCode?: string;
	billingAddressCity?: string;
	project?: Project | null;
	supplier?: Supplier | null;
	contact?: Contact | null;
	client?: Client | null;
	vat: Tax | null;
	vatNumber?: string | null;
	contactName?: string | null;
	companyName?: string | null;
	total?: number | undefined;
	subTotal?: number | undefined;
	grandTotal?: number | undefined;
	taxTotal?: number | undefined;
	paymentTotal?: number | undefined;
	paymentDiscount?: number | undefined;
	deductedAdvance?: number | undefined;
	reuseBillingInformation?: boolean;
	reference?: string | null;
	currency?: string | null;
	comment?: string | null;
	description?: string | null;
	terms?: string | null;
	billingAddressState?: string | null;
	billingAddressCountry?: string | null;
	payments?: Payment[] | null;
	projectClient?: ProjectClient;
	progressSnapshot: ProgressSnapshot | null;
}

export class SalesInvoiceEntity implements SalesInvoice {
	id = undefined;
	name = '';
	invoiceDate = '';
	dueDate = '';
	isDue = false;
	status = null;
	paymentStatus = null;
	recordType = null;
	type = null;
	subtype = null;
	billingAddressStreet = '';
	billingAddressStreetAdditional = '';
	billingAddressNumber = '';
	billingAddressPostalCode = '';
	billingAddressCity = '';
	project = null;
	supplier = null;
	contact = null;
	client = null;
	vat = null;
	vatNumber = null;
	contactName = null;
	companyName = null;
	total = 0;
	subTotal = 0;
	grandTotal = 0;
	taxTotal = 0;
	paymentTotal = 0;
	paymentDiscount = 0;
	deductedAdvance = 0;
	reuseBillingInformation = false;
	reference = null;
	currency = 'EUR';
	comment = '';
	description = '';
	terms = '';
	billingAddressState = '';
	billingAddressCountry = '';
	payments = [] as Payment[];
	progressSnapshot = null;

	constructor(data: Partial<SalesInvoice> = {}) {
        Object.assign(this, data)
    }
}

export interface SalesInvoiceStatus {
	id: number;
	name: string;
	label: string;
	color?: string;
	sequence: number;
}

export interface SalesInvoicePaymentStatus {
	id: number;
	name: string;
	label: string;
	color?: string;
	sequence: number;
}

export interface SalesInvoiceType {
	id?: number;
	label?: string;
	name?: string;
	parentId?: number;
	children?: SalesInvoiceType[];
}
export class SalesInvoiceTypeEntity implements SalesInvoiceType {
	constructor(data: Partial<SalesInvoiceType> = {}) {
        Object.assign(this, data)
    }
}

export interface SalesInvoiceRecordType {
	id: number;
	label: string;
	name: string;
}

export interface ObjectForAutocomplete<T> {
	value: T | null;
	label: string;
}

