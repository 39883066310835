

	import { Component, Emit, Prop, Vue } from "vue-property-decorator";
	import { namespace } from "vuex-class";
	import { debounce } from "lodash";

	import { Actions, Getters } from "@/modules/entities/store/modules/suppliers/types/StoreTypes";
	import { SupplierType } from "@/modules/entities/types/entities";
	import { SupplierStateFilters } from "@/modules/entities/store/modules/suppliers/types/SupplierState";
	import { ContractorBranch } from "@/modules/settings/store/modules/contractor-branches/types/ContractorBranchesState";
	import { mixins } from 'vue-class-component';
	import { OneToFiveMixin } from '@/mixins/one-to-five-mixin.conponent';

	const suppliers = namespace("entities/suppliers");

	@Component({
		mixins: [OneToFiveMixin]
	})
	export default class SuppliersSideMenuComponent extends Vue {
		@suppliers.Action(Actions.FETCH_SUPPLIER_TYPES) fetchSupplierTypes!: () => Promise<void>;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_NAME_QUERY) setNameQueryFilter!: (payload: string) => void;
		@suppliers.Action(Actions.FETCH_CONTRACTOR_TYPES) fetchContractorTypes!: () => Promise<void>;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_SUPPLIER_TYPES) setSupplierTypeFilter!: (payload: number) => void;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_CONTRACTOR_TYPES) setContractorTypeFilter!: (payload: number) => void;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_PRICE) setPriceFilter!: (payload: number) => void;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_QUALITY) setQualityFilter!: (payload: number) => void;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_FUTURE_WORK) setFutureWorkFilter!: (payload: number) => void;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_ADDITIONAL_FILTER) setAdditionalFilter!: (payload: string) => void;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_SIDEBAR_OPEN) setSideBarOpen!: (payload: boolean) => void;
		@suppliers.Action(Actions.SET_SUPPLIERS_FILTER_CLEAR) setClearFilters!: () => void;
		@suppliers.Action(Actions.TOGGLE_IS_FILTER_TYPE_ACTIVE) toggleIsFilterTypeActive!: (payload: string) => void;


		@suppliers.Getter(Getters.GET_SUPPLIER_TYPES) supplierTypes!: SupplierType[];
		@suppliers.Getter(Getters.GET_SUPPLIER_FILTERS) suppliersFilter!: SupplierStateFilters;
		@suppliers.Getter(Getters.GET_CONTRACTOR_TYPES) contractorTypes!: ContractorBranch[];

		supplierTypesFilterExpanded: boolean = false;
		contractorTypesFilterExpanded: boolean = false;
		futureWorkFilterExpanded: boolean = false;
		priceFilterExpanded: boolean = false;
		qualityFilterExpanded: boolean = false;
		additionalFiltersExpanded = false;
		filteredSupplierTypes: SupplierType[] = [];
		filteredContractorTypes: ContractorBranch[] = [];
		supplierNameSearchValue: string = "";

		oneToFiveList: number[] = [-2,-1,0,1,2];
		contractorTypeSearchValue = "";
		supplierTypeSearchValue = "";
		additionalFiltersList = [
			{
				name: 'offerReceivedFilter',
				switchBoolean: 'offerReceivedFilterToggled',
				translationPath: 'suppliers.sideMenu.offerReceivedLabel'
			},
			{
				name: 'workedTogetherFilter',
				switchBoolean: 'workedTogetherFilterToggled',
				translationPath: 'suppliers.sideMenu.workedTogetherLabel'
			},
			{
				name: 'plannedFilter',
				switchBoolean: 'plannedFilterToggled',
				translationPath: 'suppliers.sideMenu.plannedLabel'
			}
		]

		isNumberInNumericList(array: number[], item: number): boolean {
			return array.some(arrayNumber => arrayNumber === item);
		}

		get filteredContractorTypesList(): ContractorBranch[] {
			return this.filteredContractorTypes.filter(contractorType => {
				if (this.contractorTypeSearchValue !== "") {
					return contractorType.abbreviation.includes(this.contractorTypeSearchValue.toUpperCase());
				}
				return contractorType;
			});
		}

		get filteredSupplierTypesList(): SupplierType[] {
			return this.filteredSupplierTypes.filter(supplierType => {
				if (this.supplierTypeSearchValue !== "") {
					return supplierType.type.includes(this.supplierTypeSearchValue);
				}
				return supplierType;
			});
		}

		onSearchValueChange = debounce((value: string) => {
			this.setNameQueryFilter(value);
		}, 200);

		toggleSideBarOpen(): void {
			this.setSideBarOpen(!this.suppliersFilter.sideBarOpen);
		}

		clearFilters(): void {
			this.supplierNameSearchValue = "";
			this.setClearFilters();
		}

		onGroupDisable(filterGroup: string) {
			switch (filterGroup) {
				case "supplierTypes":
					this.suppliersFilter.supplierTypeFilterActive ? this.supplierTypesFilterExpanded = false : this.supplierTypesFilterExpanded = true;
					break;
				case "contractorBranches":
					this.suppliersFilter.contractorTypeFilterActive ? this.contractorTypesFilterExpanded = false : this.contractorTypesFilterExpanded = true;
					break;
				case 'futureWork':
					this.suppliersFilter.futureWorkFilterActive ? this.futureWorkFilterExpanded = false : this.futureWorkFilterExpanded = true;
					break;
				case 'price':
					this.suppliersFilter.priceFilterActive ? this.priceFilterExpanded = false : this.priceFilterExpanded  = true;
					break;
				case 'quality':
					this.suppliersFilter.qualityFilterActive ? this.qualityFilterExpanded = false : this.qualityFilterExpanded = true;
					break;
				case 'additionalFilters':
					this.suppliersFilter.additionalFiltersActive ? this.additionalFiltersExpanded = false : this.additionalFiltersExpanded = true;
					break;
				default:
					break;
			}
			this.toggleIsFilterTypeActive(filterGroup);
		}

		get supplierFiltersAnyDirty() {
			return this.suppliersFilter.mainQuery ||
				this.suppliersFilter.nameQuery ||
				this.suppliersFilter.selectedSupplierTypeIds.length ||
				this.suppliersFilter.selectedContractorTypeIds.length ||
				this.suppliersFilter.selectedPriceNumbers.length ||
				this.suppliersFilter.selectedQualityNumbers.length ||
				this.suppliersFilter.selectedFutureWorkNumbers.length;
		}

		created(): void {
			this.fetchSupplierTypes().then(() => this.filteredSupplierTypes = [...this.supplierTypes]);
			this.fetchContractorTypes().then(() => this.filteredContractorTypes = [...this.contractorTypes]);
		}

		@Emit("clearFilters")
		onEmitClearFilters(): void {
			this.clearFilters();
		}
	}
