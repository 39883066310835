export default function useProjectHelpers(): { 
	getProjectStatusIcon: Function; 
	getProjectStatusColour: Function; 
} {
	const getProjectStatusIcon = (projectStatusId: number): string => {
		switch (projectStatusId) {
			case 1:
				return 'icon-prospect.svg';
			case 2:
				return 'icon-design.svg';
			case 3:
				return 'icon-offer_in_progress.svg';
			case 4:
				return `icon-offer_ready.svg`;
			case 5:
				return `icon-in_progress.svg`;
			case 6:
				return `icon-verification.svg`;
			case 7:
				return 'icon-administration_ready.svg';
			case 8:
				return 'icon-postponed_long_term.svg';
			case 9:
				return 'icon-done.svg';
			case 10:
				return 'icon-no_offer.svg';
			case 11:
				return 'icon-not_sold.svg';
			default: return '';
		}
	}

	const getProjectStatusColour = (projectStatusId: number): string => {
		switch (projectStatusId) {
			case 1:
				return "#109EDE";
			case 2:
				return "#0036C2";
			case 3:
				return "#6200EE";
			case 4:
				return "#00C3B8";
			case 5:
				return "#90C7D9";
			case 6:
				return "#E69F16";
			case 7:
				return "#C5A813";
			case 8:
				return "#B57EC4";
			case 9:
				return "#249528";
			case 10:
				return "#F48C89";
			case 11:
				return "#979797";
			default:
				return "#979797";
		}
	}

	return {
		getProjectStatusIcon,
		getProjectStatusColour
	}
}
