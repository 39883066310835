import { Payment, TransactionType } from "./types/entities";

export function signAmount(amount: number, type: TransactionType): number {
    let value = 0;
    switch(type) {
        case TransactionType.CREDIT: value = -1 * Math.abs(amount); break;
        case TransactionType.DEBIT: value = Math.abs(amount); break;
        default: break;
    }
    return value;
}

export function summarizeBy(key: any, items: Payment[], sign = true): number {
    return items.reduce((a: number, b: Payment) => a + (sign && b.type ? signAmount((b as any)[key] || 0, b.type) : ((b as any)[key] || 0)), 0)
}