import { reactive, computed, ComputedRef } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import { UseAppMessageListState, UseAppMessageListComposables, UseAppMessageListContext } from './types/useAppMessageList';
import { merge } from 'lodash';
import { Options } from './types/composable';
import { actions as appMessageListActions } from './actions/useAppMessageList';
import useList from './useList';
import { AppMessage } from '@/core/components/app-message/entities';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseAppMessageListState>): UseAppMessageListState => {
  return merge(
    {
      message: null,
    },
    state
  );
};

export default function useAppMessageList(options?: Options<UseAppMessageListState, UseAppMessageListComposables>) {

  const state: UseAppMessageListState = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    list: useList<AppMessage>(),
  }, options?.composables);
  const ctx: UseAppMessageListContext = { state, composables };
  const actions = wrapActions(ctx, appMessageListActions);

  // return state and actions
  return {
    getters: {
      // content
      messages: computed(() => ctx.composables.list.getters.items.value),
      lastMessage: computed(() => ctx.state.message),
      // lastMessage: (includeDismissed = false) => computed(() => {
      //   const items = ctx.composables.list.getters.items.value.filter((x) => includeDismissed ? x : (x as AppMessage).dismissed === false)
      //   return items && items.length ? items[items.length-1] : null
      // }) as ComputedRef<AppMessage|null>,
    },
    actions: {
      ...actions,
    },
  };
}