import { BankAccount, Client } from "@/modules/entities/types/entities";
import { cloneDeep } from "lodash";

/**
 * Partial entity setters can be used when complex/nested objects need to be modified dynamically.
 *   - eg: "edit address fields => submit => take the changed data => save (but keep previous state for other partial items without overwriting the data they're responsible for)"
 */
export const setClientAddressData = (target: Client, src: Client): Client => {
    return Object.assign(target, {
        street: src.street,
        streetNumber: src.streetNumber,
        postalCode: src.postalCode,
        city: src.city,
    } as Partial<Client>)
}
export const setClientAddressFromGoogleMapsAddress = (target: Client, src: google.maps.GeocoderAddressComponent[], fields: Array<string> = []): Client => {
    src.forEach(component => {
        const addressType = component.types[0];
        switch (addressType) {
            case 'route': target.street = fields && fields.includes('street') ? component.long_name : target.street; break;
            case 'postal_code': target.postalCode = fields && fields.includes('postalCode') ? component.short_name : target.postalCode; break;
            case 'locality': target.city = fields && fields.includes('city') ? component.long_name : target.city; break;
            case 'street_number': target.streetNumber = fields && fields.includes('streetNumber') ? component.short_name : target.streetNumber; break;
            default: break;
        }
    }) 
    return target
}
export const setClientCompanyInformation = (target: Client, src: Client): Client => {
    return Object.assign(target, {
        vatNumber: src.vatNumber,
        website: src.website,
        nationalInsuranceNumber: src.nationalInsuranceNumber,
        companyType: src.companyType,
        isCompany: src.isCompany,
    } as Partial<Client>)
}
export const setClientPrimaryAndSecondaryBankAccount = (target: Client, src: Client): Client => {
    if(target.bankAccounts == null) target.bankAccounts = []
    src.bankAccounts.forEach((bankAccount: BankAccount, idx: number) => {
        const t = target.bankAccounts[idx]
        if(t == null) {
            target.bankAccounts.push(cloneDeep(bankAccount))
        } else {
            t.accountNumber = bankAccount.accountNumber
            t.isMain = bankAccount.isMain
        }
    })
    return target
}