import { UseTimesheetContext } from "@/composables/types/useTimesheet";
import { AxiosResponse } from "axios";
import {JsonResource, Pagination} from "@/core/types/Entities";
import {Employee, Team, Timesheet, TimesheetEntry} from "@/modules/settings/types/entities";
import { TimesheetsService } from "@/services/timesheets.service";
import useFilter from "@/composables/useFilter";
import {cloneDeep, merge} from "lodash";
import {UseEmployeeContext} from "@/composables/types/useEmployee";

const timesheetsService = new TimesheetsService();

const setTimesheet = async (ctx: UseTimesheetContext, timesheet: Timesheet|null): Promise<void> => new Promise((resolve, reject) => {
  const { state } = ctx;
  try {
    if(timesheet) {
      const timesheetCopy = cloneDeep(timesheet);
      state.timesheet = merge(timesheetCopy, {
        timesheetEntries: timesheetCopy.timesheetEntries ? timesheetCopy.timesheetEntries : [],
      });
    } else state.timesheet = timesheet;
    resolve();
  } catch (err: any) { reject(err); }
});

const clearTimesheetValidationErrors = async (ctx: UseTimesheetContext) => {
  const { state } = ctx;
  state.timesheetValidationErrors = null;
  return Promise.resolve();
};

const clearTimesheetBusinessErrors = async (ctx: UseTimesheetContext) => {
  const { state } = ctx;
  state.timesheetBusinessErrors = null;
  return Promise.resolve();
};

const clearTimesheetErrors = async (ctx: UseTimesheetContext) => {
  clearTimesheetValidationErrors(ctx);
  clearTimesheetBusinessErrors(ctx);
  return Promise.resolve();
};

const fetchTimesheet = async (ctx: UseTimesheetContext, id: number): Promise<AxiosResponse<JsonResource<Timesheet>>> => {
  const { composables: { loadingActions } } = ctx;
  loadingActions.actions.set(fetchTimesheet.name, true);
  try {
    clearTimesheetErrors(ctx);
    const res = await timesheetsService.getTimesheetById(id);
    setTimesheet(ctx, res.data.data);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(fetchTimesheet.name, false);
  }
};

const createTimesheet = async (ctx: UseTimesheetContext): Promise<AxiosResponse<JsonResource<Timesheet>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(createTimesheet.name, true);
  clearTimesheetErrors(ctx);
  try {
    if(state.timesheet !== null) {
      clearTimesheetErrors(ctx);
      const res = await timesheetsService.createTimesheet(state.timesheet);
      setTimesheet(ctx, res.data.data);
      return Promise.resolve(res);
    }
    else return Promise.reject(new Error('Timesheet not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.timesheetValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.timesheetBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(createTimesheet.name, false);
  }
};

const updateTimesheet = async (ctx: UseTimesheetContext): Promise<AxiosResponse<JsonResource<Timesheet>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(updateTimesheet.name, true);
  clearTimesheetErrors(ctx);
  try {
    if(state.timesheet !== null && state.timesheet.id != null) {
      clearTimesheetErrors(ctx);
      const res = await timesheetsService.editTimesheet(state.timesheet);
      setTimesheet(ctx, res.data.data);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No timesheet available in state or id not provided'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.timesheetValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.timesheetBusinessErrors = err.response.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(updateTimesheet.name, false);
  }
};

const saveTimesheet = async (ctx: UseTimesheetContext): Promise<AxiosResponse<JsonResource<Timesheet>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(saveTimesheet.name, true);
  try {
    if(state.timesheet) {
      let res = null;
      if((state.timesheet.id && state.timesheet.id <= 0) || !state.timesheet.id) {
        res = createTimesheet(ctx);
      } else {
        res = updateTimesheet(ctx);
      }
      return Promise.resolve(res);
    } else return Promise.reject(Error('No timesheet available in state.'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveTimesheet.name, false);
  }
};

const deleteTimesheet = async (ctx: UseTimesheetContext): Promise<AxiosResponse<void>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(deleteTimesheet.name, true);
  clearTimesheetErrors(ctx);
  try {
    if(state.timesheet !== null && state.timesheet.id != null) {
      const res = await timesheetsService.deleteTimesheet(state.timesheet.id);
      return Promise.resolve(res);
    } else return Promise.reject(Error('No timesheet available in state or id not provided'));
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteTimesheet.name, false);
  }
};

const search = async (ctx: UseTimesheetContext, query: string, useAsFilter = false): Promise<AxiosResponse<Pagination<Timesheet[]>>> => {
  const { composables: { loadingActions, filter } } = ctx;
  loadingActions.actions.set(search.name, true);
  try {
    let urlSearchParams = null;
    if(useAsFilter) {
      if(query) filter.actions.setFilter('search', query);
      else filter.actions.deleteFilter('search');
      urlSearchParams = filter.getters.filterUrlQuery.value;
    } else {
      const f = useFilter();
      if(query) f.actions.setFilter('search', query);
      urlSearchParams = f.getters.filterUrlQuery.value;
    }
    const url = timesheetsService.getBaseEndpoint() + `${ urlSearchParams ? '?' + urlSearchParams.toString() : '' }`;
    const res = await timesheetsService.getAllTimesheets(url);
    return Promise.resolve(res);
  } catch (err: any) {
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(search.name, false);
  }
};

const saveTimesheetEntry = async (ctx: UseTimesheetContext, timesheetEntry: TimesheetEntry): Promise<AxiosResponse<JsonResource<Timesheet>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(saveTimesheetEntry.name, true);
  clearTimesheetErrors(ctx);
  try {
    if(state.timesheet !== null && state.timesheet.id) {
      clearTimesheetErrors(ctx);
      if(timesheetEntry.id) {
        const res = await timesheetsService.editTimesheetEntry(state.timesheet.id, timesheetEntry);
        setTimesheet(ctx, res.data.data);
        return Promise.resolve(res);
      } else {
        const res = await timesheetsService.createTimesheetEntry(state.timesheet.id, timesheetEntry);
        setTimesheet(ctx, res.data.data);
        return Promise.resolve(res);
      }
    } else return Promise.reject(new Error('Timesheet not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.timesheetValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.timesheetBusinessErrors = err.response.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(saveTimesheetEntry.name, false);
  }
};

const deleteTimesheetEntry = async (ctx: UseTimesheetContext, timesheetEntry: TimesheetEntry): Promise<AxiosResponse<JsonResource<Timesheet>>> => {
  const { state, composables: { loadingActions } } = ctx;
  loadingActions.actions.set(deleteTimesheetEntry.name, true);
  clearTimesheetErrors(ctx);
  try {
    if(state.timesheet != null && state.timesheet.id) {
      clearTimesheetErrors(ctx);
      if(!timesheetEntry.id) throw new Error('timesheetEntry has no id, cannot be deleted');
      const res = await timesheetsService.deleteTimesheetEntry(state.timesheet.id, timesheetEntry.id);
      setTimesheet(ctx, res.data.data);
      return Promise.resolve(res);
    } else return Promise.reject(new Error('Timesheet not set in state.'));
  } catch (err: any) {
    if(err.response && err.response.status === 422) state.timesheetValidationErrors = err.response.data.errors;
    if(err.response && err.response.status === 409) state.timesheetBusinessErrors = err.response.data.data;
    return Promise.reject(err);
  } finally {
    loadingActions.actions.set(deleteTimesheetEntry.name, false);
  }
};

export const actions = {
  setTimesheet,
  createTimesheet,
  updateTimesheet,
  saveTimesheet,
  clearTimesheetBusinessErrors,
  clearTimesheetValidationErrors,
  clearTimesheetErrors,
  search,
  saveTimesheetEntry,
  deleteTimesheetEntry,
};
