

	import {Component, Emit, Prop, Vue} from "vue-property-decorator";
	import {ContractorBranch} from "../../../store/modules/contractor-branches/types/ContractorBranchesState";

	@Component
	export default class EditContractorBranchPopup extends Vue {
		@Prop() title!: string;
		@Prop() showModal!: boolean;
		@Prop() item!: ContractorBranch;

		itemCopy: ContractorBranch = {
			id: undefined,
			abbreviation: '',
			name: '',
			description: '',
			sequence: 0
		};

		created() {
	        this.itemCopy = Object.assign({}, this.item);
		}

		@Emit('onEditContractorBranch')
		onEditContractorBranch(contractorBranch: ContractorBranch) {
			this.onCloseDialog();
			return contractorBranch;
		}

		@Emit('onCloseDialog')
		onCloseDialog() {}
	}
