
  import { defineComponent } from 'vue';
  import AbsencePeriodModalForm from '../layouts/absence-period-modal-form.vue';

  export default defineComponent({
    name: 'absence-period-modal',
    components: {
      AbsencePeriodModalForm,
    },
    setup() {
      return {};
    },
  });
