

    import {Component, Ref, Vue} from "vue-property-decorator";
	import {namespace} from "vuex-class";
    import {Actions, Getters} from "@/modules/contacts/store/types/StoreTypes";
	import {Client, Supplier, Comment, ClientVM, SupplierVM, TeleinformationDetailEntity, TeleinformationDetail} from "@/modules/entities/types/entities";
    import SelectSupplierListComponent from '@/modules/entities/components/suppliers/select-supplier-list.component.vue';
	import ProjectsSmall from "@/modules/projects/components/projectsSmall.component.vue";
    import ClientsSmall from '@/modules/entities/views/clients/clientsSmall.component.vue';
	import DetailsTextFieldComponent from '@/core/components/details-page/details-text-field.component.vue';
	import SelectClientListComponent from '@/modules/entities/components/clients/select-client-list.component.vue';
	import ContactClientsListComponent from '@/modules/contacts/components/contact-clients-list/contact-clients-list.component.vue';
	import ContactProjectsListComponent from '@/modules/contacts/components/contact-projects-list/contact-projects-list.component.vue';
	import ContactSuppliersListComponent from '@/modules/contacts/components/contact-suppliers-list/contact-suppliers-list.component.vue';
	import { Contact, ContactVM } from "@/modules/contacts/types/entities";
	import { AxiosError, AxiosResponse } from "axios";
	import { JsonResource } from "@/core/types/Entities";
	import AppInputComponent from "@/core/components/app-input/app-input.component.vue";
	import DeleteModal from '@/modules/contacts/components/delete-modal/delete-modal.component.vue'
	import AppTextareaComponent from "@/core/components/app-textarea/app-textarea.component.vue";
	import { selectInput } from '@/core/helpers/input';
	import { GetContactName } from '@/mixins/get-contact-name.component';

    const contacts = namespace('contacts');
	enum PartialEdit {
		EDIT_CONTACT_NAME = 'editContactName',
		EDIT_CONTACT_ADDRESS = 'editContactAddress',
		EDIT_CONTACT_PERSONAL_INFORMATION = 'editContactPersonalInformation',
		EDIT_CONTACT_TELEINFORMATIONS = 'editContactTeleinformations',
		EDIT_CONTACT_TELEINFORMATIONS_LANDLINEPHONE = 'editContactTeleinformationsLandlinePhone',
		EDIT_CONTACT_TELEINFORMATIONS_MOBILEPHONE = 'editContactTeleinformationsMobilePhone',
		EDIT_CONTACT_TELEINFORMATIONS_EMAIL = 'editContactTeleinformationsEmail',
		EDIT_CONTACT_FUNCTION = 'editContactFunction',
		EDIT_CONTACT_COMMENT = 'editContactComment',
	}

    @Component({
		mixins: [GetContactName],
        components: {
			ProjectsSmall,
			ClientsSmall,
            SelectSupplierTable: SelectSupplierListComponent,
			SelectClientTable: SelectClientListComponent,
			DetailsTextFieldComponent,
			'delete-modal': DeleteModal,
			'contact-clients-list': ContactClientsListComponent,
			'contact-projects-list': ContactProjectsListComponent,
			'contact-suppliers-list': ContactSuppliersListComponent,
        }
    })
    export default class ContactDetailsComponent extends Vue {
		@contacts.Action(Actions.DELETE_CONTACT_BY_ID) deleteContactById!: (id: number) => Promise<AxiosResponse<any>>;
		@contacts.Action(Actions.DELETE_TELEINFORMATION_BY_ID) deleteTeleinformationById!: (payload: {contactId: string, teleinformationId: string}) => void;
		@contacts.Action(Actions.SAVE_CONTACT) saveContact!: (contact: Contact) => Promise<AxiosResponse<JsonResource<Contact>>>;
		@contacts.Action(Actions.FETCH_CONTACT_BY_ID) fetchContactById!: (payload: string) => Promise<void>;

        @contacts.Getter(Getters.GET_CONTACT_DETAILS) contact!: Contact;
		@contacts.Getter(Getters.GET_LOADING) isLoading!: boolean;

		@Ref('firstName') firstName!: AppInputComponent
		@Ref('street') street!: AppInputComponent
		@Ref('bankAccountNumber') bankAccountNumber!: AppInputComponent
		@Ref('function') function!: AppInputComponent
		@Ref('comment') comment!: AppTextareaComponent

		validationErrors: any = null;

        state: {
            edit: boolean,
            addSupplier: boolean,
            removeSupplier: boolean,
			removeClient: boolean,
            delete: boolean,
			add_comment: boolean,
			deleteTeleinformation: boolean
        } = {
            edit: false,
            addSupplier: false,
            removeSupplier: false,
			removeClient: false,
            delete: false,
			add_comment: true,
			deleteTeleinformation: false
        };

        content: {
            edit: any,
			beforeEdit: any,
            removeSupplier: Supplier | any,
			removeClient: Client,
            delete: Contact,
			deleteTeleinformation: TeleinformationDetail,
			add_comment: string,
			supplierTypeId: number
        } = {
            edit: {},
			beforeEdit: {},
            delete: {} as Contact,
			deleteTeleinformation: {} as TeleinformationDetail,
			removeClient : {} as Client,
			removeSupplier: {} as Supplier,
			add_comment: '',
			supplierTypeId: 0
        };

		resetContactDetailsDialogContent() {
			this.content = {
				edit: this.content.edit,
				beforeEdit: this.content.beforeEdit,
				delete: new ContactVM(),
				removeClient: new ClientVM(),
				removeSupplier: new SupplierVM(),
				add_comment: '',
				supplierTypeId: 0,
				deleteTeleinformation: new TeleinformationDetailEntity()
			}
		}

		onNavigateBack() {
			window.history.length > 2 ? this.$router.go(-1) : this.$router.push("/home");
		}

		/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ CONTACT ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
		getPartialContent(partialEdit: PartialEdit, key?: string): Contact|null {
			const content = (this.content as any)[partialEdit]
			if(content && key) return content[key] ? content[key] : null
			else if(content) return content
			else return null
		}
		getPartialState(partialEdit: PartialEdit, key?: string): boolean|object {
			const state = (this.state as any)[partialEdit]
			if(state && key) return state[key] ? state[key] : false
			else if(state) return state
			else return false
		}
		activate(e: any, partialEdit: PartialEdit, key?: string) {
			let partialContent = this.getPartialContent(partialEdit, key)
			partialContent = partialContent ? partialContent : JSON.parse(JSON.stringify(this.contact)) as Contact
			this.$set(this.content, partialEdit, key ? {...this.getPartialContent(partialEdit), [key]: this.baseWithPartial(partialEdit, partialContent, key) } : this.baseWithPartial(partialEdit, partialContent, key))
			this.$set(this.state, partialEdit, key ? {...(this.getPartialState(partialEdit) as object), [key]: true } : true)
		}
		submit(e: any, partialEdit: PartialEdit, key?: string) {
			const contact = this.baseWithPartial(partialEdit, key ?  (this.content as any)[partialEdit][key] : (this.content as any)[partialEdit], key)
			if(contact) {
				this.onSaveContact(contact)
					.then(result => {
						let partialContent = this.getPartialContent(partialEdit)
						partialContent = partialContent ? partialContent : JSON.parse(JSON.stringify(this.contact))
						this.$set(this.content, partialEdit, key ? {...partialContent, [key]: null } : null)
						this.$set(this.state, partialEdit, key ? {...(this.getPartialState(partialEdit) as object), [key]: false } : false)
					})
			}
		}
		submitSilent(e: any, partialEdit: PartialEdit, key?: string) {
			const contact = this.baseWithPartial(partialEdit, key ?  (this.content as any)[partialEdit][key] : (this.content as any)[partialEdit], key)
			if(contact) {
				this.onSaveContact(contact).then(result => {})
			}
		}
		cancel(e: any, partialEdit: PartialEdit, key?: string) {
			this.$set(this.state, partialEdit, key ? {...(this.getPartialState(partialEdit) as object), [key]: false } : false)
		}
		selectInput(e: Event) {
			selectInput(e.target as HTMLInputElement)
		}
		baseWithPartial(partialEdit: PartialEdit, editedContact: Contact, key?: string) {
			const baseContact = JSON.parse(JSON.stringify(this.contact)) as Contact;
			switch(partialEdit) {
				case PartialEdit.EDIT_CONTACT_NAME:
					return Object.assign(baseContact, { firstName: editedContact.firstName, lastName: editedContact.lastName } as Contact)
				case PartialEdit.EDIT_CONTACT_ADDRESS:
					return Object.assign(baseContact, { street: editedContact.street, streetNumber: editedContact.streetNumber, flatNumber: editedContact.flatNumber, postalCode: editedContact.postalCode, city: editedContact.city } as Contact)
				case PartialEdit.EDIT_CONTACT_PERSONAL_INFORMATION:
					return Object.assign(baseContact, { website: editedContact.website, socialSecurityNumber: editedContact.socialSecurityNumber, bankAccountNumber: editedContact.bankAccountNumber } as Contact)
				case PartialEdit.EDIT_CONTACT_FUNCTION:
					return Object.assign(baseContact, { contactFunction: editedContact.contactFunction } as Contact)
				case PartialEdit.EDIT_CONTACT_COMMENT:
					return Object.assign(baseContact, { comment: editedContact.comment } as Contact)
				case PartialEdit.EDIT_CONTACT_TELEINFORMATIONS_LANDLINEPHONE:
					if(key) {
						const baseTeleinformation = baseContact.teleinformations.find(i=>i.id===Number.parseInt(key))
						const teleinformation = editedContact.teleinformations.find(i=>i.id===Number.parseInt(key))
						if(baseTeleinformation && teleinformation) {
							baseTeleinformation.landlinePhone = teleinformation.landlinePhone
							baseTeleinformation.landlinePhoneComment = teleinformation.landlinePhoneComment
						}
						return baseContact
					}
				case PartialEdit.EDIT_CONTACT_TELEINFORMATIONS_MOBILEPHONE:
					if(key) {
						const baseTeleinformation = baseContact.teleinformations.find(i=>i.id===Number.parseInt(key))
						const teleinformation = editedContact.teleinformations.find(i=>i.id===Number.parseInt(key))
						if(baseTeleinformation && teleinformation) {
							baseTeleinformation.mobilePhone = teleinformation.mobilePhone
							baseTeleinformation.mobilePhoneComment = teleinformation.mobilePhoneComment
						}
						return baseContact
					}
				case PartialEdit.EDIT_CONTACT_TELEINFORMATIONS_EMAIL:
					if(key) {
						const baseTeleinformation = baseContact.teleinformations.find(i=>i.id===Number.parseInt(key))
						const teleinformation = editedContact.teleinformations.find(i=>i.id===Number.parseInt(key))
						if(baseTeleinformation && teleinformation) {
							baseTeleinformation.email = teleinformation.email
							baseTeleinformation.emailComment = teleinformation.emailComment
						}
						return baseContact
					}
				default:
					return baseContact
			}
		}
		displayDeleteTeleinformationConfirmDialog(teleinformation: TeleinformationDetail): boolean {
			return this.state.deleteTeleinformation && this.content.deleteTeleinformation.id === teleinformation.id;
		}


		onClickAddTeleinformation() {
			const contact = JSON.parse(JSON.stringify(this.contact)) as Contact
			contact.teleinformations.push(new TeleinformationDetailEntity())
			this.onSaveContact(contact);
		}
		onClickDeleteTeleinformation(teleinformation: TeleinformationDetail) {
			this.state.deleteTeleinformation = true
			this.content.deleteTeleinformation = teleinformation
		}
		onClickMarkIsMainContactInformation(teleinformation: TeleinformationDetail) {
			const contact = JSON.parse(JSON.stringify(this.contact)) as Contact
			const mainTeleinformation = contact.teleinformations.find(item => item.id === teleinformation.id)
			if(mainTeleinformation) {
				contact.teleinformations.forEach(item => item.isMainContactInformation = false)
				mainTeleinformation.isMainContactInformation = true
				this.onSaveContact(contact)
			}
		}

		onSaveContact(contact: Contact): Promise<Contact> {
			return new Promise<Contact>((resolve, reject) => {
				this.saveContact(contact)
					.then((resp) => {
						this.validationErrors = null;
						resolve(resp.data.data);
					})
					.catch((err: AxiosError) => {
						if (err.response && err.response.status === 422) {
							this.validationErrors = err.response.data.errors;
						}
					});
			});
		}

		onClickDelete() {
			this.content.delete = JSON.parse(JSON.stringify(this.contact))
			this.state.delete = true
		}

		onDeleteContact() {
			if (this.content.delete.id) {
				this.deleteContactById(this.content.delete.id).then(result => this.$router.go(-1));
			}
		}
		onDeleteTeleinformation() {
			if (this.content.deleteTeleinformation.id) {
				this.deleteTeleinformationById({contactId: this.contact.id.toString(), teleinformationId: this.content.deleteTeleinformation.id.toString()})
			}
		}
		onCancelDeleteTeleinformation() {
			this.state.deleteTeleinformation = false
			this.content.deleteTeleinformation = {} as TeleinformationDetail
		}

		get contactFullName() {
			return `${ this.contact.firstName ? this.contact.firstName : '' }${ this.contact.lastName ? ' ' + this.contact.lastName : '' }`
		}

		get contactAddressUrl() {
			if(this.contact) {
				const parameters = new URLSearchParams()
				parameters.append('query',  this.contact.street && this.contact.street + (!!this.contact.streetNumber ? ' ' + this.contact.streetNumber : '') + (this.contact.flatNumber ? ' ' + this.contact.flatNumber : '') + (this.contact.postalCode ? ', ' + this.contact.postalCode : '') + (this.contact.city ? ' ' + this.contact.city : ''))
				return `https://www.google.com/maps/search/?api=1&${ parameters.toString() }`
			}
		}

		// onStartContactEdit() {
		// 	this.state.edit = true;
		// 	this.content.beforeEdit = JSON.parse(JSON.stringify(this.content.edit))
		// }

		// onCancelContactEdit() {
		// 	this.state.edit = false;
		// 	this.content.edit = this.content.beforeEdit;
		// }

		// onOpenDeleteContactDialog(contact: Contact) {
		// 	this.resetContactDetailsDialogContent();
		// 	this.state.delete = true;
		// 	this.content.delete = contact;
		// }

		/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ COMMENTS ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
		onAddComment() {
			this.state.add_comment = true;
		}

		onNewCommentSave() {
			if (this.content.add_comment !== '') {
				const comment: Comment = {
					id: 0,
					body: this.content.add_comment,
					commentableId: this.contact.id!,
					commentableType: 'App\\Models\\Common\\Contact'
				};
				// this.saveCommentToContact(comment);
				this.state.add_comment = false;
			}
			this.resetContactDetailsDialogContent();
		}

		/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ SUPPLIERS ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
		onExistingSupplierAttachToContact() {
			this.resetContactDetailsDialogContent();
			this.state.addSupplier = true;
		}

		onAttachedSupplierRemove(supplier: Supplier) {
			this.resetContactDetailsDialogContent();
			this.content.removeSupplier = supplier
			this.state.removeSupplier = true;
		}

		/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ CLIENTS ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
		onExistingClientAttachToContact() {
			this.resetContactDetailsDialogContent();
		}

		onAttachClientToContact(clientId: number) {
			// this.toggleAssociationExistingClientToContact({
			// 	contactId: this.contact.id,
			// 	clientId
			// })
		}

		onAttachedClientRemove(client: Client) {
			this.resetContactDetailsDialogContent();
			this.content.removeClient = client;
			this.state.removeClient = true;
		}

		

		/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ LIFECYCLE HOOKS ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
		created() {
			this.fetchContactById(this.$route.params.id)
				.then(() => {
					this.content.edit = JSON.parse(JSON.stringify(this.contact));
				})
				.catch((err: AxiosError) => {
					if(err.response && err.response.status === 403) this.$router.go(-1);
				})
		}
    }
