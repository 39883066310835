import {Route, RouteConfig} from 'vue-router';
import LoginComponent from '@/modules/login/views/login/Login.component.vue';
import authStore from '@/modules/login/store';

export const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: LoginComponent,
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to: Route, from: Route, next) => {
      if(authStore.auth.getters.isAuthenticated.value) {
        next({ name: 'home' });
      } else {
        next();
      }
    },
  },
];
