import {WorkflowState} from './types/WorkflowState';

export const state: WorkflowState = {
	taskStatuses: [],
	tasks: [],
	functionGroups: [],
	selectedProjects: [],
	previousSelectedProjects: [],
	expandedProjects: [],
	expandedTasks: [],
	anyTaskExpanded: false,
	environmentId: 0,
	userId: 0,
	allProjects: [],
	paginationDetails: undefined,
	loading: true,
	sidebarOpen: false,
	filters: {
		showMyProjects: false,
		projectStatusesIds: [1, 2, 3, 4, 5, 6, 7],
		projectNameQuery: '',
		leadersIds: [],
		estimatorsIds: [],
		designersIds: [],
		drawersIds: []
	},
	showFunctionGroups: false,
	multiTaskStatus: {
		id: 0,
		futureStartDate: undefined
	},
	highlightedProjectId: -1
};
