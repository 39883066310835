
	import { Component, Vue } from "vue-property-decorator";
	import { namespace } from "vuex-class";

	import { LogItem } from "@/modules/settings/types/entities";
	import { Actions, Getters } from "@/modules/settings/store/modules/deleted-log/types/StoreTypes";

	const deletedLog = namespace("settings/deleted-log");

	@Component
	export default class DeletedLogComponent extends Vue {
		@deletedLog.Action(Actions.FETCH_DELETED_LOG) fetchDeletedLog!: () => void;
		@deletedLog.Action(Actions.REVERT_DELETION) revertDeletion!: (payload: LogItem) => void;

		@deletedLog.Getter(Getters.GET_DELETED_LOG) deletedLog!: LogItem[];

		headers = [
			{
				text: "ID",
				align: "left",
				sortable: false,
				value: "id"
			},
			{
				text: "Log name",
				value: "logName"
			},
			{
				text: "Description",
				value: "description"
			},
			{
				text: "Causer ID",
				value: "causerId"
			},
			{
				text: "Causer Type",
				value: "causerType"
			},
			{
				text: "Subject ID",
				value: "subjectId"
			},
			{
				text: "Subject Type",
				value: "subjectType"
			},
			{
				sortable: false
			}
		];

		created() {
			this.fetchDeletedLog();
		}

		onRevertDeletion(logItem: LogItem) {
			this.revertDeletion(logItem);
		}
	}

