
  import { defineComponent, PropType } from 'vue';
  import { Quote } from '../interfaces/project/quote';
  import { CalendarViewType } from '@/modules/calendar/types/CalendarViewType';
  import helpers from '@/modules/calendar/services/helper.service';
  import store from '../store';
  import { CalendarSidebarDialog } from '../types/CalendarSidebarDialog';
  import { CalendarDivision } from '../interfaces/division/CalendarDivision';

  export default defineComponent({
    name: 'CalendarGridDivisionQuote',
    props: {
      division: Object as PropType<CalendarDivision | null>,
      quote: Object as PropType<Quote | null>,
    },
    setup() {
      return {
        CalendarViewType,
        CalendarSidebarDialog,
        calendar: store.calendar,
        calendarSettings: store.calendarSettings,
        ...helpers,
      };
    },
  });
