
import Vue, { computed, defineComponent, onMounted, PropType, getCurrentInstance } from 'vue'
import dayjs from 'dayjs'
import { Retainment } from '../../types/entities'

export default defineComponent({
  name: 'SupplierRetainmentsList',
  components: {
  },
  props: {
    retainments: {
      type: Array as PropType<Retainment[]>,
      default: () => [],
    },
  },
  setup() {
    const vm = getCurrentInstance()!.proxy

    // methods
    const dateExceeded = (validUntil: string) => {
      return dayjs(validUntil).isBefore(dayjs(), 'day')
    }
    // getters
    const headers = computed(() => {
      return [
        {
          text: vm.$t('dictionary.type').toString(),
          value: 'type',
          align: 'start column-name--width',
          sortable: true,
          groupable: true,
          class: 'text-no-wrap',
        },
        {
          text: vm.$t('dictionary.validUntilInclusive').toString(),
          value: 'validUntil',
          align: 'start column-name--width',
          sortable: true,
          groupable: false,
          class: 'text-no-wrap',
        },
        {
          text: vm.$t('dictionary.retain').toString(),
          value: 'retain',
          align: 'start column-name--width',
          sortable: true,
          groupable: true,
          class: 'text-no-wrap',
        },
        // {
        //   text: vm.$t('dictionary.checkedManually').toString(),
        //   value: 'checkedManually',
        //   align: 'start column-name--width',
        //   sortable: true,
        //   groupable: true,
        //   class: 'text-no-wrap',
        // },
        {
          text: vm.$t('dictionary.createdAt').toString(),
          value: 'createdAt',
          align: 'start column-name--width',
          sortable: true,
          groupable: false,
          class: 'text-no-wrap',
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
        },
      ]
    })

    return {
      headers,
      dateExceeded,
    }
  },
})
