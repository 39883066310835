import Vue from 'vue';
import { computed } from 'vue';

/**
 * Composable Router
 *
 * REMARK:
 *   - should be replaced with `useRouter` in Vue Router 4
 * @returns
 */
export default function useRouter()
{
  /**
     * Actions
     *
     */
  const navigateBack = (fallback: string | object = '/home'): void => {
    window.history.length > 2 ? Vue.router.go(-1) : Vue.router.push(fallback);
  };

  // return state and actions
  return {
    actions: {
      navigateBack,
    },
    getters: {
      params: computed(() => Vue.router.currentRoute.params),
    },
  };
}