export const enum Actions {
	FETCH_CONTACTS_PAGINATED = 'FETCH_CONTACTS_PAGINATED',
	FETCH_CONTACTS_PAGINATED_NEXT = 'FETCH_CONTACTS_PAGINATED_NEXT',
	FETCH_CONTACTS_PAGINATED_PREVIOUS = 'FETCH_CONTACTS_PAGINATED_PREVIOUS',
	FETCH_CONTACTS_BY_QUERY = 'FETCH_CONTACTS_BY_QUERY',
	FETCH_CONTACTS_BY_FILTER = 'FETCH_CONTACTS_BY_FILTER',
	FETCH_CONTACTS_BY_NAME = 'FETCH_CONTACTS_BY_NAME',

	FETCH_CONTACT_BY_ID = 'FETCH_CONTACT_BY_ID',
	CHECK_FOR_EXISTING_CONTACT = 'CHECK_FOR_EXISTING_CONTACT',
	CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT',
	DELETE_CONTACT_BY_ID = 'DELETE_CONTACT_BY_ID',
	SAVE_CONTACT = 'SAVE_CONTACT',
	DELETE_TELEINFORMATION_BY_ID = 'DELETE_TELEINFORMATION_BY_ID',
}

export const enum Getters {
	GET_CONTACTS_FILTERED = 'GET_CONTACTS_FILTERED',

	GET_CONTACT_DETAILS = 'GET_CONTACT',
	GET_LOADING = 'GET_LOADING'
}

export const enum Mutations {
	MUTATE_CONTACTS_FILTERED = 'MUTATE_CONTACTS_FILTERED',

	MUTATE_INSPECTED_CONTACT = 'MUTATE_INSPECTED_CONTACT',
	MUTATE_LOADING = 'MUTATE_LOADING'
}
