
	import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";

	const icons = require.context('../../../assets/core/components/delete-popup', false, /\.svg$/);

	@Component
	export default class PopupComponent extends Vue {
		@PropSync('showModal', { type: Boolean , default: false }) showModalSync!: boolean;
		@Prop({ type: String, default: 'common.delete' }) confirmButtonTranslationPath!: string;
		@Prop({ type: String, default: 'trash' }) icon!: string;
		@Prop({ type: String, default: 'Confirm deletion' }) title!: string;
		@Prop({ type: Boolean }) allowFullDelete?: boolean;
		@Prop({ type: String, default: 'common.deletePermanently' }) checkboxDeleteTranslationPath!: string;

		checkboxValue = false;

		get getIcon(): string {
			switch (this.icon) {
				case 'trash':
					return icons('./trash.svg');
				case 'archive':
					return icons('./archive.svg');
				case 'unArchive':
					return icons('./un-archive.svg');
				default:
					return icons('./trash.svg');
			}
		}

		onDelete(): void {
			if (this.allowFullDelete && this.checkboxValue) {
				this.onEmitDeleteEntirely();
				return;
			}
			this.onEmitDelete();
		}

		onCancel(): void {
			this.onEmitCancel();
		}

		@Emit('onDeleteEntirely')
		onEmitDeleteEntirely(): void {
			this.showModalSync = false;
		}

		@Emit('onDelete')
		onEmitDelete(): void {
			this.showModalSync = false;
		}

		@Emit('onCancel')
		onEmitCancel(): void {
			this.showModalSync = false;
		}
	}
