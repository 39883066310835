
    import { PropType } from 'vue';
    import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    import {
      AccountingItem, AccountingItemEntity,
      PurchaseInvoice,
      PurchaseInvoiceEntity,
    } from '@/modules/purchase-invoices/types/entities';
    import { DataTableHeader } from 'vuetify';
    import { calculateAccountingItemsDifference, summarizeAccountingItemsBy } from '@/modules/purchase-invoices/helpers'
    import {AccountingLedgerItem} from "@/modules/accounting/types/entities";
    import {sortNatural} from "@/core/helpers/Sort";
    const purchaseInvoices = namespace('purchaseInvoices');

    @Component({
        components: {
        }
    })
    export default class PurchaseInvoiceProjectRelationshipsListComponent extends Vue {

        // vuex actions

        // vuex getters

        // props
        @PropSync('accountingItems', { type: Array as PropType<AccountingItem[]>, required: true }) accountingItemsSync!: AccountingItem[];
        @Prop({type: Object as PropType<PurchaseInvoice>, default: () => new PurchaseInvoiceEntity(), required: true}) purchaseInvoice!: PurchaseInvoice;

        // local/children state
        state: {} = {}
        content: {} = {}

      // handlers
      onToggleRecalculateAccountingItems(recalculate: boolean) {
        this.$emit('recalculate-accounting-items', recalculate)
      }

      // methods
      calculateAccountingItemsDifference(invoice: PurchaseInvoice, invoiceField: keyof typeof invoice = 'total', itemField: keyof AccountingItem = 'total') {
          return calculateAccountingItemsDifference(invoice, invoice.accountingItems || [], invoiceField, itemField)
      }

        // getters
      get formatters() {
        return [
          {
            value:'total',
            format: (value: number) => {
              return this.$options.filters !== undefined ? this.$options.filters.formatMoney(value) : null
            }
          },
          {
            value:'taxTotal',
            format: (value: number) => {
              return this.$options.filters !== undefined ? this.$options.filters.formatMoney(value) : null
            }
          },
          {
            value:'grandTotal',
            format: (value: number) => {
              return this.$options.filters !== undefined ? this.$options.filters.formatMoney(value) : null
            }
          }
        ]
      }
      get summarizers() {
        return [
          {
            value:'total',
            summarize: (items: AccountingItem[] = []) => summarizeAccountingItemsBy('total', items, true)
          },
          {
            value:'taxTotal',
            summarize: (items: AccountingItem[] = []) => summarizeAccountingItemsBy('taxTotal', items, true)
          },
          {
            value:'grandTotal',
            summarize: (items: AccountingItem[] = []) => summarizeAccountingItemsBy('grandTotal', items, true)
          }
        ]
      }
      get headers() {
        return [
          {
            text: '',
            value: 'data-table-select',
            groupable: false,
            class:'text-no-wrap'
          } as DataTableHeader,
          {
            text: this.$t('invoices.purchaseAccountingItems.details.labels.total').toString(),
            value: 'total',
            align: 'end',
            groupable: false,
            class:'text-no-wrap'
          } as DataTableHeader,
          {
            text: this.$t('invoices.purchaseAccountingItems.details.labels.vat').toString(),
            value: 'vat',
            align: 'end',
            groupable: false,
            class:'text-no-wrap'
          } as DataTableHeader,
          {
            text: this.$t('invoices.purchaseAccountingItems.details.labels.taxTotal').toString(),
            value: 'taxTotal',
            align: 'end',
            groupable: false,
            class:'text-no-wrap'
          } as DataTableHeader,
          {
            text: this.$t('invoices.purchaseAccountingItems.details.labels.grandTotal').toString(),
            value: 'grandTotal',
            align: 'end',
            groupable: false,
            class:'text-no-wrap'
          } as DataTableHeader,
          {
            text: this.$t('invoices.purchaseAccountingItems.details.labels.accountingLedgerItem').toString(),
            value: 'accountingLedgerItem',
            groupable: false,
            align: 'start text-truncate td-text-truncate-2 td-toggle',
            class:'text-no-wrap',
            sort: (a: AccountingLedgerItem, b: AccountingLedgerItem) => { return sortNatural(a.name+' - '+a.description, b.name+' - '+b.description, {locale: this.$i18n.locale}) }
          },
          // {
          //     text: this.$t('invoices.purchaseAccountingItems.details.labels.description').toString(),
          //     value: 'description',
          //     groupable: false,
          //     class:'text-no-wrap'
          // } as DataTableHeader,
          { text: '', value: 'actions', sortable: false, groupable: false} as DataTableHeader,
        ];
      }

        
        

        // setters

        // watchers

        // lifecycle
    }
