import axios, { AxiosResponse } from 'axios';

import { JsonResource } from '@/core/types/Entities';
import { Division } from '@/modules/settings/types/entities';

const BASE_ENDPOINT = '/api/v1/settings/divisions';

export class DivisionsService {
  getDivisions(params?: URLSearchParams): Promise<AxiosResponse<JsonResource<Division[]>>> {
    return axios.get<JsonResource<Division[]>>(`${ BASE_ENDPOINT }${ params ? '?' + params.toString() : '' }`);
  }
}
