import Vue from 'vue';
import Router, { Route, RawLocation } from 'vue-router';
import {coreRoutes} from '@/core/core.module';
import authStore from '@/modules/login/store';

Vue.use(Router);

/**
 * Catch router exceptions from Vue Auth
 * https://stackoverflow.com/questions/58634914/getting-navigation-duplicated-error-while-the-route-is-being-replaced-on-vue-rou
 */
const originalPush: Promise<Route> = (Router as any).prototype.push;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
Router.prototype.push = function push(location: RawLocation) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return originalPush.call(this, location).catch(() => { });
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...coreRoutes,
    /*{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/about',
			name: 'about',
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import(/!* webpackChunkName: "about" *!/ './modules/About.vue')
		}*/
  ],
});

router.beforeEach((to, from, next) => {

  // Return 'check'-promise so we fetch a potential logged in user first.
  return authStore.auth.actions
    .check()
    .finally(() => {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!authStore.auth.getters.isAuthenticated.value && to.name !== 'login') {
          next({ name: 'login' });
        } else {
          next();
        }
      } else {
        next(); // make sure to always call next()!
      }
    });
});

export default router;
