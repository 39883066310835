import { render, staticRenderFns } from "./sales-invoices.component.vue?vue&type=template&id=48788a05&scoped=true"
import script from "./sales-invoices.component.vue?vue&type=script&lang=ts"
export * from "./sales-invoices.component.vue?vue&type=script&lang=ts"
import style0 from "./sales-invoices.component.vue?vue&type=style&index=0&id=48788a05&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48788a05",
  null
  
)

export default component.exports