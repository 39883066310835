export const enum Actions {
	FETCH_PAYMENT = 'FETCH_PAYMENT'
}

export const enum Getters {
	GET_PAYMENT = 'GET_PAYMENT'
}

export const enum Mutations {
	MUTATE_PAYMENT = 'MUTATE_PAYMENT'
}
