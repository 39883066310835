import axios, { AxiosResponse } from 'axios';
import { JsonResource } from '@/core/types/Entities';
import { PlannerPeriod } from '@/modules/calendar/interfaces/project/plannerPeriod';
import { Quote } from '@/modules/calendar/interfaces/project/quote';
import { Supplier, Comment } from '@/modules/entities/types/entities';
import { Team } from '@/modules/settings/types/entities';

const BASE_ENDPOINT = '/api/v1/planner/periods';

export default class PlannerPeriodsService {

	getBaseEndpoint(): string {
		return BASE_ENDPOINT
	}

	deletePlannerPeriod(plannerPeriodId: number): Promise<AxiosResponse<void>> {
		return axios.delete(`${ BASE_ENDPOINT }/${ plannerPeriodId }`);
	}
}