import { InvoiceState } from '@/modules/purchase-invoices/store/types/InvoiceState';
import {GetterTree} from 'vuex';
import {RootState} from '@/core/types/RootState';
import {Getters} from '@/modules/purchase-invoices/store/types/StoreTypes';
import {i18n} from '@/main';
import {
  PurchaseInvoiceStatus,
  PurchaseInvoiceRecordType,
  PurchaseInvoicePaymentStatus,
  ProjectRelationshipStatus,
  ApprovalStatus,
  AccountingStatus
} from '../types/entities';

export const getters: GetterTree<InvoiceState, RootState> = {

  [Getters.GET_LISTVIEW_INVOICES]: (state: InvoiceState) => {
    return state.listviewInvoices;
  },
  [Getters.GET_STATUSES]: (state: InvoiceState) => {
    return state.statuses;
  },
  [Getters.GET_APPROVAL_STATUSES]: (state: InvoiceState) => {
    return state.approvalStatuses;
  },
  [Getters.GET_PROJECT_RELATIONSHIP_STATUSES]: (state: InvoiceState) => {
    return state.projectRelationshipStatuses;
  },
  [Getters.GET_PAYMENT_STATUSES]: (state: InvoiceState) => {
    return state.paymentStatuses;
  },
  [Getters.GET_ACCOUNTING_STATUSES]: (state: InvoiceState) => {
    return state.accountingStatuses;
  },
  [Getters.GET_STATUSES_TRANSLATED]: (state: InvoiceState) => {
    const statuses = [] as PurchaseInvoiceStatus[];
    state.statuses.map((value) => {
      const status = {
        id: value.id,
        label: i18n.t('invoices.purchase.statuses.'+value.name).toString(),
        name: value.name,
        color: value.color,
        sequence: value.sequence,
      } as PurchaseInvoiceStatus;
      statuses.push(status);
    });
    return statuses;
  },
  [Getters.GET_APPROVAL_STATUSES_TRANSLATED]: (state: InvoiceState) => {
    const statuses = [] as ApprovalStatus[];
    state.approvalStatuses.map((value) => {
      const status = {
        id: value.id,
        label: i18n.t('invoices.purchase.approvalStatuses.'+value.name).toString(),
        name: value.name,
        color: value.color,
        sequence: value.sequence,
      } as ApprovalStatus;
      statuses.push(status);
    });
    return statuses;
  },
  [Getters.GET_PROJECT_RELATIONSHIP_STATUSES_TRANSLATED]: (state: InvoiceState) => {
    const projectRelationshipStatuses = [] as ProjectRelationshipStatus[];
    state.projectRelationshipStatuses.map((value) => {
      const status = {
        id: value.id,
        label: i18n.t('invoices.purchaseProjectRelationships.statuses.'+value.name).toString(),
        name: value.name,
        color: value.color,
        sequence: value.sequence,
      } as ProjectRelationshipStatus;
      projectRelationshipStatuses.push(status);
    });
    return projectRelationshipStatuses;
  },
  [Getters.GET_PAYMENT_STATUSES_TRANSLATED]: (state: InvoiceState) => {
    const paymentStatuses = [] as PurchaseInvoicePaymentStatus[];
    state.paymentStatuses.map((value) => {
      const status = {
        id: value.id,
        label: i18n.t('invoices.purchase.paymentStatuses.'+value.name).toString(),
        name: value.name,
        color: value.color,
        sequence: value.sequence,
      } as PurchaseInvoicePaymentStatus;
      paymentStatuses.push(status);
    });
    return paymentStatuses;
  },
  [Getters.GET_ACCOUNTING_STATUSES_TRANSLATED]: (state: InvoiceState) => {
    const accountingStatuses = [] as AccountingStatus[];
    state.accountingStatuses.map((value) => {
      const status = {
        id: value.id,
        label: i18n.t(`invoices.purchase.accountingStatuses.${value.name}`).toString(),
        name: value.name,
        color: value.color,
        sequence: value.sequence,
      } as AccountingStatus;
      accountingStatuses.push(status);
    });
    return accountingStatuses;
  },
  [Getters.GET_LISTVIEW_IS_LOADING]: (state: InvoiceState) => {
    return state.listviewIsLoading;
  },
  [Getters.GET_IS_LOADING]: (state: InvoiceState) => {
    return state.isLoading;
  },
  [Getters.GET_LISTVIEW_FILTER_STATE]: (state: InvoiceState) => {
    return state.listviewFilterState;
  },
  [Getters.GET_INVOICE]: (state: InvoiceState) => {
    return state.invoice;
  },
  [Getters.GET_RECORD_TYPES]: (state: InvoiceState) => {
    return state.recordTypes;
  },
  [Getters.GET_RECORD_TYPES_TRANSLATED]: (state: InvoiceState) => {
    const recordTypes = [] as PurchaseInvoiceRecordType[];
    state.recordTypes.map((value) => {
      const recordType = {
        id: value.id,
        label: i18n.t('invoices.purchase.recordTypes.'+value.name).toString(),
        name: value.name,
      } as PurchaseInvoiceRecordType;
      recordTypes.push(recordType);
    });
    return recordTypes;
  },
  [Getters.GET_NEW]: (state: InvoiceState) => {
    return state.new;
  },
  [Getters.GET_PROJECT_RELATIONSHIP]: (state: InvoiceState) => {
    // if(state.projectRelationshipState) return state.projectRelationshipState.new;
    return null;
  },
  [Getters.GET_LISTVIEW_FILTER_SUPPLIER_MODEL]: (state: InvoiceState) => {
    return state.filtersSupplierModel;
  },

  // reporting
  [Getters.GET_INVOICES_SUMMARY_REPORT]: (state: InvoiceState) => {
    return state.invoicesSummaryReport;
  },
  [Getters.GET_INVOICES_METRICS_REPORT]: (state: InvoiceState) => {
    return state.invoicesMetricsReport;
  },
  [Getters.GET_SHOW_INVOICES_SUMMARY_REPORT]: (state: InvoiceState) => {
    return state.invoicesShowSummaryReport;
  },
  [Getters.GET_SHOW_INVOICES_METRICS_REPORT]: (state: InvoiceState) => {
    return state.invoicesShowMetricsReport;
  },

  // search
  [Getters.GET_SEARCH_PROJECTS]: (state: InvoiceState) => {
    return state.searchProjects;
  },
  [Getters.GET_SEARCH_SUPPLIERS]: (state: InvoiceState) => {
    return state.searchSuppliers;
  },

};