import { User } from '@/modules/entities/types/entities';
import { reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import { PasswordMatchingResult } from '@/modules/settings/store/modules/users/types';
import { UseUserState, UseUserComposables, UseUserContext } from './types/useUser';
import { merge } from 'lodash';
import { Options } from './types/composable';
import { useDynamicProps } from './useDynamicProps';
import { actions as userActions } from './actions/useUser';
import useFilter from './useFilter';

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseUserState>): UseUserState => {
  return merge(
    {
      user: null,
      userPasswordMatchingResult: { isConfirmed: false, password: null },
      userValidationErrors: null,
    },
    state
  );
};

/**
 * `UseUser`-composable
 *   - All User-related interactions and functionality can be stored here.
 *   - If you need a global store, than just use this composable in eg a `store.ts`
 *
 * @param filter
 * @param defaultState
 * @returns
 */
export default function useUser(options?: Options<UseUserState, UseUserComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    filter: useFilter(),
    showDialog: useDynamicProps<boolean>(),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseUserContext = { state, composables };
  const actions = wrapActions(ctx, userActions);

  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // other
      showDialog: ctx.composables.showDialog.getters,
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      // state/getters
      user: computed<User | null>(() => state.user),
      userPasswordMatchingResult: computed<PasswordMatchingResult>(() => state.userPasswordMatchingResult),
      userValidationErrors: computed(() => state.userValidationErrors),
    },
    actions: {
      ...ctx.composables.filter.actions,
      showDialog: ctx.composables.showDialog.actions.set,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...actions,
    },
  };
}