import { MediaAnnotation } from "@/modules/entities/types/entities";

export enum DrawingColours {
    BLACK = 'rgba(0,0,0,1)',
    WHITE = 'rgba(255,255,255,1)',
    RED = 'rgba(255,0,0,1)',
    GREEN = 'rgba(0,255,0,1)',
    BLUE = 'rgba(0,0,255,1)',
    YELLOW = 'rgba(255,255,0,1)',
}

export enum DrawingThickness {
    THIN = 1,
    MEDIUM = 3,
    THICK = 5,
}

export enum DrawingTools {
    SELECT = 'SELECT',
    ERASER = 'ERASER',
    ELLIPSE = 'ELLIPSE',
    RECTANGLE = 'RECTANGLE',
    PENCIL = 'PENCIL',
    PENCILANDTEXT = 'PENCILANDTEXT',
    LINE = 'LINE',
    LINEANDTEXT = 'LINEANDTEXT',
    DASHED_LINE = 'DASHED_LINE',
    POLYGON = 'POLYGON',
    FILL = 'FILL',
    TEXT = 'TEXT',
    GARBAGE = 'GARBAGE',
}

export interface Pointer {
    x: number;
    y: number;
}

export interface CustomFabricAuth {
    isEditable: (fabricObject: CustomFabricObject) => boolean;
}

interface CustomFabricProps {
    id: string;
    userId: string;
    pageNumber: number;
    mediaAnnotationId?: string|null;
}

export type CustomFabricObject = fabric.Object & CustomFabricProps;
export type CustomFabricRect = fabric.Rect & CustomFabricObject;
export type CustomFabricEllipse = fabric.Ellipse & CustomFabricObject;
export type CustomFabricLine = fabric.Line & CustomFabricObject;
export type CustomFabricPolygon = fabric.Polygon & CustomFabricObject;
export type CustomFabricIText = fabric.IText & CustomFabricObject;
export type CustomFabricPath = fabric.Path & CustomFabricObject;

export enum FabricObjectType {
    RECT = 'rect',
    ELLIPSE = 'ellipse',
    I_TEXT = 'i-text',
    LINE = 'line',
    POLYGON = 'polygon',
    PATH = 'path',
}
  