export const enum Actions {
	SET_LOADING = 'SET_LOADING'
}

export const enum Getters {
	GET_LOADING = 'GET_LOADING'
}

export const enum Mutations {
	MUTATE_LOADING_STATE = 'MUTATE_LOADING_STATE'
}
