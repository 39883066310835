import datetimeCalculate from "@/core/helpers/DatetimeCalculator";
import useHolidayList from "@/composables/useHolidayList";
import useFilter from "@/composables/useFilter";

// shared state composables
const holidaysListViewFilter = useFilter()
holidaysListViewFilter.actions.setFilter('startDate', datetimeCalculate().year()[0])
holidaysListViewFilter.actions.setFilter('endDate', datetimeCalculate().year()[1])
const holidayListView = useHolidayList(holidaysListViewFilter)

/**
 * Global state/store:
 *   - Because we can re-use composables as different states, we can also use 1 composable as a global state.
 *   - This global state can be stored in a `store.ts` file.
 */
const store: {
	holidayListView: typeof holidayListView;
} = {
	holidayListView: holidayListView
};

export default store