import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { JsonResource, Pagination } from '@/core/types/Entities';
import { AccountingLedger, AccountingLedgerItem } from '@/modules/accounting/types/entities';

export const BASE_ENDPOINT = '/api/v1/accounting-ledgers';

export class AccountingService {

  getBaseEndpoint(): string {
    return BASE_ENDPOINT;
  }

  createAccountingLedger(accountingLedger: AccountingLedger): Promise<AxiosResponse<JsonResource<AccountingLedger>>> {
    return axios.post<JsonResource<AccountingLedger>>(BASE_ENDPOINT, accountingLedger);
  }

  createAccountingLedgerItem(accountingLedgerItem: AccountingLedgerItem): Promise<AxiosResponse<JsonResource<AccountingLedgerItem>>> {
    return axios.post<JsonResource<AccountingLedgerItem>>(`${ BASE_ENDPOINT }/accounting-ledger-items`, accountingLedgerItem);
  }

  getAllAccountingLedgers(url = `${ BASE_ENDPOINT }`): Promise<AxiosResponse<Pagination<AccountingLedger[]>>> {
    return axios.get<Pagination<AccountingLedger[]>>(`${ url }`);
  }

  getAccountingLedgerById(accountingLedgerId: number): Promise<AxiosResponse<JsonResource<AccountingLedger>>> {
    return axios.get<JsonResource<AccountingLedger>>(`${ BASE_ENDPOINT }/${ accountingLedgerId }`);
  }

  getAccountingLedgerItemById(accountingLedgerItemId: number): Promise<AxiosResponse<JsonResource<AccountingLedgerItem>>> {
    return axios.get<JsonResource<AccountingLedgerItem>>(`${ BASE_ENDPOINT }/accounting-ledger-items/${ accountingLedgerItemId }`);
  }

  updateAccountingLedgerById(accountingLedger: AccountingLedger): Promise<AxiosResponse<JsonResource<AccountingLedger>>> {
    return axios.put<JsonResource<AccountingLedger>>(`${ BASE_ENDPOINT }/${ accountingLedger.id }`, accountingLedger);
  }

  updateAccountingLedgerItem(accountingLedgerItem: AccountingLedgerItem): Promise<AxiosResponse<JsonResource<AccountingLedgerItem>>> {
    return axios.put<JsonResource<AccountingLedgerItem>>(`${ BASE_ENDPOINT }/accounting-ledger-items/${ accountingLedgerItem.id }`, accountingLedgerItem);
  }

  deleteAccountingLedgerById(accountingLedgerId: number): AxiosPromise<void> {
    return axios.delete(`${ BASE_ENDPOINT }/${ accountingLedgerId }`);
  }

}
