
import {computed, defineComponent, reactive, ref, toRefs, watch, set, unref} from 'vue';
  import dayjs from 'dayjs';
  import useEmployee from '@/composables/useEmployee';
  import useEmployeeList from '@/composables/useEmployeeList';
  import { DayData, MonthlyOverviewWorkDay } from '../../entities/monthlyOverview';
  import helpers from '@/modules/monthly-overview/services/helper.service';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import {
    Employee,
    Timesheet,
    TimesheetEntry,
    TimesheetEntryEntity,
    AbsencePeriodEntity,
    AbsencePeriod,
    Absence,
  } from '@/modules/settings/types/entities';
  import { WorkDay } from '@/modules/calendar/interfaces/project/workDay';
  import { cloneDeep, debounce } from 'lodash';
  import AbsencePeriodModal from '@/modules/settings/absences/components/absence-period-modal.vue';
  import AbsencePeriodModalDelete from '@/modules/settings/absences/components/absence-period-modal-delete.vue';
  import EmployeeAbsencesErrorComponent from '@/modules/settings/components/employee-absences-error.component.vue';
  import useHolidayList from "@/composables/useHolidayList";
  import useTimesheet from "@/composables/useTimesheet";
  import { formatTime as timeFormatter } from "@/modules/monthly-overview/helpers";

  export default defineComponent({
    components: {
      AbsencePeriodModal,
      AbsencePeriodModalDelete,
    },
    data: () => {
      return {
        filters: {
          expandDays: true,
          customer: true,
          branch: true,
          standard: false,
          overtime: false,
          distance: false,
          totals: false,
          plannerComments: false,
        },
      };
    },
    setup(props, other) {
      const holidayList = useHolidayList();
      const employeeList = useEmployeeList();
      const employee = useEmployee();
      const timesheet = useTimesheet();
      const menu = ref();
      const isMenuOpen = ref(false);
      const isEditingTimesheet = ref(false);
      const showAbsencePeriodModal = ref(false);
      const showDeleteAbsencePeriodModal = ref(false);
      const formData = reactive<{
        employee: null | Employee;
        selectedDate: string;
        monthlyOverview: { [key: string]: DayData } | null;
        locked: boolean;
        absencePeriod: AbsencePeriod | null;
        deleteAbsencePeriod: AbsencePeriod | null;
      }>({
        employee: null,
        selectedDate: dayjs().format('YYYY-MM'),
        monthlyOverview: {},
        locked: false,
        absencePeriod: null,
        deleteAbsencePeriod: null,
      });

      const formDataRefs = toRefs(formData);
      watch(formDataRefs.employee, (value, oldValue) => {
        if (value && value.id) {
          employee.actions.fetchEmployee(value.id).then(() => {
            if (formData.selectedDate) fetchTimesheetAndWorkDaysAndHolidaysAndAbsencePeriods();
          });
        }
      });
      watch(formDataRefs.selectedDate, (value, oldValue) => {
        if (value && value !== oldValue) fetchTimesheetAndWorkDaysAndHolidaysAndAbsencePeriods();
      });

      // const timesheet = computed(() => {
      //   return (
      //     (employee.getters.employee.value?.timesheets &&
      //       employee.getters.employee.value?.timesheets.length &&
      //       employee.getters.employee.value?.timesheets[0]) ||
      //     null
      //   );
      // });

      const loading = computed(() => employee.getters.isLoading.value || holidayList.state.isLoading.value);

      const timesheetEntries = computed(() => {
        let timesheetEntries: TimesheetEntry[] = [];
        if (!formData.monthlyOverview) throw new Error('No monthly overview to use when saving the timesheet.');
        for (const [date, day] of Object.entries(formData.monthlyOverview)) {
          timesheetEntries = [
            ...timesheetEntries,
            ...day.workDays
              .filter((wd) => wd.timesheetEntry !== undefined)
              .map((wd) => wd.timesheetEntry as TimesheetEntry),
          ];
        }
        return timesheetEntries;
      });

      const workDays = computed(() => {
        return (
          (employee.getters.employee.value?.workDays &&
            employee.getters.employee.value?.workDays.length &&
            employee.getters.employee.value?.workDays) ||
          []
        );
      });

      const formatTime = (timeToFormat: number) => {
        return timeFormatter(timeToFormat)
      };

      const transformTimeToDuration = (time: string): number => {
        const hours = Number.parseInt(time.split(':')[0]);
        const minutes = Number.parseInt(time.split(':')[1]);
        const totalMinutes = minutes + hours * 60;
        const durationInHours = totalMinutes / 60;
        return durationInHours;
      };

      const sumTotalHoursPlanned = (date: string, day: DayData) => {
        if (day.workDays && day.workDays.length) {
          return day.workDays
            .map((wd) => (wd.sequences && wd.sequences.length) || 0)
            .reduce((prev, curr) => prev + curr, 0);
        }
        return 0;
      };

      const sumTotalDuration = (date: string, day: DayData) => {
        if (day.workDays && day.workDays.length) {
          return day.workDays
            .map((wd) => (wd.timesheetEntry && wd.timesheetEntry.duration) || 0)
            .reduce((prev, curr) => prev + curr, 0);
        }
        return 0;
      };

      const sumTotalDurationExtra = (date: string, day: DayData) => {
        if (day.workDays && day.workDays.length) {
          return day.workDays
            .map((wd) => (wd.timesheetEntry && wd.timesheetEntry.durationExtra) || 0)
            .reduce((prev, curr) => prev + curr, 0);
        }
        return 0;
      };

      const selectEmployee = debounce((employee: Employee) => {
        formData.employee = employee;
      }, 300);

      const cancelInput = () => {
        resetTimesheet();
        isEditingTimesheet.value = false;
      };

      const refreshTimesheet = () => {
        fetchTimesheetAndWorkDaysAndHolidaysAndAbsencePeriods()
      };

      const toggleLockTimesheet = (locked: boolean) => {
        if (timesheet.getters.timesheet.value) {
          saveTimesheet({
            ...timesheet.getters.timesheet.value,
            locked: locked,
            lockedAt: locked ? dayjs.utc().format('YYYY-MM-DD') : null,
          });
        }
      };

      const resetTimesheet = (useWorkDayDurations = false) => {
        recalculateOverview(useWorkDayDurations);
      };

      const startEditTimesheet = () => {
        isEditingTimesheet.value = true;
      };

      const saveTimesheet = (ts: Timesheet) => {
        const timesheetCopy = cloneDeep(ts);

        if (timesheetCopy) {
          // make sure timesheetEntries is provided
          timesheetCopy.timesheetEntries = timesheetCopy.timesheetEntries
            ? timesheetCopy.timesheetEntries
            : timesheetEntries.value;
          // make sure employee is provided
          timesheetCopy.employee = cloneDeep(formData.employee)

          timesheet.actions
            .setTimesheet(timesheetCopy)
            .then(() => {
              timesheet.actions
                .saveTimesheet()
                .then(() => {
                  recalculateOverview()
                  isEditingTimesheet.value = false
                }).catch(() => null)
            })


          // employee.actions
          //   .saveTimesheet(timesheetCopy)
          //   .then(() => {
          //     isEditingTimesheet.value = false;
          //   })
          //   .catch();
        }
      };

      const addAbsencePeriod = (day: string) => {
        if (
          formData.absencePeriod == null ||
          (formData.absencePeriod && (day !== formData.absencePeriod.startDate || formData.absencePeriod.id != null))
        ) {
          const absencePeriod = new AbsencePeriodEntity({
            startDate: dayjs.utc(day).format('YYYY-MM-DD'),
            endDate: dayjs
              .utc(day)
              .add(7, 'day')
              .format('YYYY-MM-DD'),
            duration: 8,
            employee: cloneDeep(formData.employee),
          });
          formData.absencePeriod = absencePeriod;
        }
        showAbsencePeriodModal.value = true;
      };

      const editAbsence = (day: string) => {
        let absencePeriods = employee.getters.employee.value?.absencePeriods;
        if (absencePeriods && absencePeriods.length) {
          absencePeriods = absencePeriods.filter((absencePeriod) => {
            return absencePeriod.absences?.find((absence) => absence.day === day);
          });
          const absencePeriod = (absencePeriods && absencePeriods.length && absencePeriods[0]) || null;
          if (absencePeriod) {
            formData.absencePeriod = cloneDeep(absencePeriod);
            showAbsencePeriodModal.value = true;
          }
        }
      };

      const deleteAbsencePeriod = (day: string) => {
        let absencePeriods = employee.getters.employee.value?.absencePeriods;
        if (absencePeriods && absencePeriods.length) {
          absencePeriods = absencePeriods.filter((absencePeriod) => {
            return absencePeriod.absences?.find((absence) => absence.day === day);
          });
          const absencePeriod = (absencePeriods && absencePeriods.length && absencePeriods[0]) || null;
          if (absencePeriod) {
            formData.deleteAbsencePeriod = cloneDeep(absencePeriod);
            showDeleteAbsencePeriodModal.value = true;
          }
        }
      };

      const cancelAbsencePeriod = () => {
        showAbsencePeriodModal.value = false;
        // formData.absencePeriod = null;
      };
      const cancelDeleteAbsencePeriod = () => {
        showDeleteAbsencePeriodModal.value = false;
        formData.deleteAbsencePeriod = null;
      };

      const saveAbsencePeriod = (absencePeriod: AbsencePeriod) => {
        employee.actions
          .saveAbsencePeriod(absencePeriod)
          .then((resp) => {
            showAbsencePeriodModal.value = false;
            formData.absencePeriod = null;
          })
          .catch((err) => {
            if (employee.getters.employeeBusinessErrors.value) {
              appMessageStore.actions.warn({
                message: err.response.data.message,
                ttl: 60000,
                component: {
                  cmp: EmployeeAbsencesErrorComponent,
                  props: {
                    errorResponse: err.response.data,
                  },
                },
              });
            }
          });
      };

      const confirmDeleteAbsencePeriod = (absencePeriod: AbsencePeriod) => {
        employee.actions
          .deleteAbsencePeriod(absencePeriod)
          .then((resp) => {
            showDeleteAbsencePeriodModal.value = false;
            formData.deleteAbsencePeriod = null;
          })
          .catch((err) => {
            //
          });
      };

      const deleteTimesheetEntry = (timesheetEntry: TimesheetEntry) => {
        console.log(timesheetEntry)
        timesheet.actions.deleteTimesheetEntry(timesheetEntry).then(res => {
          recalculateOverview()
        })
      }

      const findTimesheetEntryForWorkDay = (workDay: MonthlyOverviewWorkDay) => {
        if (formData.monthlyOverview) {
          const day = formData.monthlyOverview[workDay.day];
          const wd = day?.workDays.find((wd) => wd.id === workDay.id);
          if (wd && wd.timesheetEntry) return wd.timesheetEntry;
        }
        return null;
      };

      const setTimesheetEntryDuration = (time: string, workDay: MonthlyOverviewWorkDay) => {
        const normalizedTime = time ? time : formatTime(0);
        const timesheetEntry = findTimesheetEntryForWorkDay(workDay);
        if (timesheetEntry) timesheetEntry.duration = transformTimeToDuration(normalizedTime);
      };

      const setTimesheetEntryDurationExtra = (time: string, workDay: MonthlyOverviewWorkDay) => {
        const normalizedTime = time ? time : formatTime(0);
        const timesheetEntry = findTimesheetEntryForWorkDay(workDay);
        if (timesheetEntry) timesheetEntry.durationExtra = transformTimeToDuration(normalizedTime);
      };

      const recalculateOverview = (useWorkDayDurations = false) => {
        resetOverview();
        for (const [date, day] of Object.entries(combineWorkDaysAndTimesheet(useWorkDayDurations))) {
          // make sure the DayData is reactive
          if(formData.monthlyOverview != null) {
            set(formData.monthlyOverview, date, day);
          }
        }
      };

      const resetOverview = () => {
        formData.monthlyOverview = {};
      };

      const getHolidaysForDate = (date: string) => {
        return holidayList.state.holidays.value.filter((item) => {
          const start = dayjs.utc(item.start)
          const end = dayjs.utc(item.end)
          const day = dayjs.utc(date)
          return day.toDate() >= start.toDate() && day.toDate() <= end.toDate()
        })
      };

      const getCollectiveHolidayForDate = (date: string) => {
        const holidays = getHolidaysForDate(date);
        return holidays.filter(holiday => holiday.holidayTypes.find(holidayType => ['collective'].includes(holidayType.type)))
      };

      const fetchTimesheetAndWorkDaysAndHolidaysAndAbsencePeriods = async () => {
        await employee.actions.filterTimesheets.setFilter('start_date', startOfMonth.value);
        await holidayList.actions.setFilters(new Map([
          ['startDate', startOfMonth.value],
          ['endDate', endOfMonth.value],
        ]))
        await employee.actions.filterAbsencePeriods.setFilters(
          new Map([
            ['start_date', startOfMonth.value],
            ['end_date', endOfMonth.value],
          ])
        );
        await employee.actions.filterWorkDays.setFilters(
          new Map([
            ['start', startOfMonth.value],
            ['end', endOfMonth.value],
          ])
        );

        await employee.actions.fetchTimesheets().then(() => {
          const ts = (employee.getters.employee.value?.timesheets?.length &&
            employee.getters.employee.value.timesheets[0]) || null
          timesheet.actions.setTimesheet(ts)
        }).catch((err) => null);
        await employee.actions.fetchWorkDays().catch((err) => null);
        await holidayList.actions.fetchHolidays();
        await employee.actions.fetchAbsencePeriods().catch((err) => null);
        recalculateOverview();
      };

      const combineWorkDaysAndTimesheet = (useWorkDayDurations = false) => {
        let days: { [key: string]: DayData } | null = {};
        let daysInMonth = dayjs(formData.selectedDate).daysInMonth();

        for (let i = 0; i < daysInMonth; i++) {
          let dayDate = dayjs.utc(formData.selectedDate).date(i + 1);
          days[`${dayDate.format('YYYY-MM-DD')}`] = {
            workDays: [],
            isWeekend: [0, 6].includes(dayDate.day()),
          };
        }

        workDays.value.forEach((workDay: WorkDay) => {
          const dayDate = dayjs.utc(workDay.day);
          const day: DayData = (days && days[workDay.day]) || {
            workDays: [],
            isWeekend: [0, 6].includes(dayDate.day()),
          };
          const workDayTimesheetEntry = timesheet.getters.timesheet.value?.timesheetEntries?.find(
            (tse) => tse.workDay?.id === workDay.id
          );
          const enrichedWorkDay: MonthlyOverviewWorkDay = Object.assign(
            {
              workedHours: 0,
              customer: '',
              contractorBranch: '',
              comment: '',
              timesheetEntry:
                (workDayTimesheetEntry && cloneDeep(workDayTimesheetEntry)) ||
                new TimesheetEntryEntity({
                  workDay: cloneDeep(workDay),
                  day: cloneDeep(workDay.day),
                  duration: 0, //workDay.sequences && workDay.sequences.length ? workDay.sequences.length : 0,
                }),
            },
            workDay
          );
          if (useWorkDayDurations && enrichedWorkDay.timesheetEntry) {
            enrichedWorkDay.timesheetEntry.duration =
              workDay.sequences && workDay.sequences.length ? workDay.sequences.length : 0;
          }
          enrichedWorkDay.workedHours = workDay.sequences.length;
          if (days) {
            days[workDay.day] = {
              ...day,
              workDays: [...day.workDays, enrichedWorkDay],
            };
          }
        });

        return days;
      };

      const startOfMonth = computed(() => {
        if(!formData.selectedDate) return null;
        return dayjs.utc(formData.selectedDate).startOf('month').format('YYYY-MM-DD');
      })

      const endOfMonth = computed(() => {
        if(!formData.selectedDate) return null;
        return dayjs.utc(formData.selectedDate).endOf('month').format('YYYY-MM-DD');
      })

      const dailyOverviewTotals = computed(() => {
        const dailyOverviewTotals: {
          [key: string]: { totalPlanned: number; totalDuration: number; totalDurationExtra: number };
        } = {};
        if (formData.monthlyOverview) {
          for (const [date, day] of Object.entries(formData.monthlyOverview)) {
            dailyOverviewTotals[date] = {
              totalPlanned: sumTotalHoursPlanned(date, day),
              totalDuration: sumTotalDuration(date, day),

              // todo: continue here for summing
              totalDurationExtra: sumTotalDurationExtra(date, day),
            };
          }
        }
        return dailyOverviewTotals;
      });

      const monthlyOverviewTotals = computed(() => {
        const monthlyOverviewTotals: { totalPlanned: number; totalDuration: number } = {
          totalPlanned: 0,
          totalDuration: 0,
        };
        for (const [date, day] of Object.entries(dailyOverviewTotals.value)) {
          monthlyOverviewTotals.totalPlanned += day.totalPlanned;
          monthlyOverviewTotals.totalDuration += day.totalDuration;
        }
        return monthlyOverviewTotals;
      });

      const absencesPerDay = computed(() => {
        const absencePeriods = employee.getters.employee.value?.absencePeriods || [];
        const absencesPerDay: { [key: string]: Absence | null } | null = {};
        let daysInMonth = dayjs(formData.selectedDate).daysInMonth();
        for (let i = 0; i < daysInMonth; i++) {
          let dayDate = dayjs(formData.selectedDate).date(i + 1);
          absencesPerDay[`${dayDate.format('YYYY-MM-DD')}`] = null;
        }
        let absences: Absence[] = [];
        absencePeriods.forEach((period) => {
          absences = [...absences, ...(period.absences ? period.absences : [])];
        });
        absences.forEach((absence) => {
          absencesPerDay[`${absence.day}`] = absence;
        });
        return absencesPerDay;
      });

      employeeList.actions.setFilter('is_active', true).then(() => {
        employeeList.actions.fetchEmployees();
      })

      return {
        holidayList,
        employeeList,
        employee,
        formData,
        dailyOverviewTotals,
        monthlyOverviewTotals,
        menu,
        isMenuOpen,
        isEditingTimesheet,
        helpers,
        timesheet,
        loading,
        absencesPerDay,
        timesheetEntries,

        formatTime,
        fetchTimesheetAndWorkDaysAndHolidaysAndAbsencePeriods,
        setTimesheetEntryDuration,
        setTimesheetEntryDurationExtra,
        startEditTimesheet,
        saveTimesheet,
        toggleLockTimesheet,
        selectEmployee,
        cancelInput,
        resetTimesheet,
        sumTotalHoursPlanned,
        sumTotalDuration,
        addAbsencePeriod,
        editAbsence,
        cancelAbsencePeriod,
        saveAbsencePeriod,
        deleteAbsencePeriod,
        cancelDeleteAbsencePeriod,
        confirmDeleteAbsencePeriod,
        showAbsencePeriodModal,
        showDeleteAbsencePeriodModal,
        getCollectiveHolidayForDate,
        deleteTimesheetEntry,
        refreshTimesheet,
      };
    },
  });
