
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { cloneDeep } from 'lodash'
import { IncomplianceStatus, ProjectCompliance } from "@/modules/projects/types/social-security-registrations";

@Component
export default class SocialSecurityRegistrationListItem extends Vue {
    @Prop() projectCompliance!: any;
    @Prop({default: false}) expanded!: boolean;

    socialSecurityReference: string|null = this.projectCompliance.socialSecurityReference;

    hasIncompleteProjectDataIncomplianceStatus(projectCompliance: ProjectCompliance) {
		const whitelist = [IncomplianceStatus.INCOMPLETE_PROJECT_SSR_DATA]
		return projectCompliance.ssrCompliances && projectCompliance.ssrCompliances.length ? projectCompliance.ssrCompliances.some(ssr => ssr.complianceStatus && whitelist.includes(ssr.complianceStatus)) : false
	}

    hasIncompletePlannerDataIncomplianceStatus(projectCompliance: ProjectCompliance) {
      const whitelist = [IncomplianceStatus.INCOMPLETE_PLANNER_DATA]
      return projectCompliance.ssrCompliances && projectCompliance.ssrCompliances.length ? projectCompliance.ssrCompliances.some(ssr => ssr.complianceStatus && whitelist.includes(ssr.complianceStatus)) : false
    }

    @Watch('projectCompliance.socialSecurityReference', { immediate: true })
    onWatchValue(value: string|null, oldValue: string|null) {
      this.socialSecurityReference = value
    }

    created() {}
}
