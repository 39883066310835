
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Client } from '@/modules/entities/types/entities';

@Component
export default class ContactClientsListComponent extends Vue {

    // vuex actions
    // vuex getters

    // props
    @Prop({type: Array as PropType<Client[]>, default: () => [], required: true}) clients!: Client[];

    // local/children state
    state: {
    } = {
    }

    // handlers

    // methods

    // getters
    get clientsHeaders() {
        return [
            {
                text: this.$t('projects.details.clients.name').toString(),
                value: 'name',
                align: 'start column-name--width',
                sortable: true,
                groupable: false,
                class:'text-no-wrap'
            },
            {
                text: '',
                value: 'actions',
                align: 'center'
            },
        ];
    }

    // setters

    // watchers

    // lifecycle
}
