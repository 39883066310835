export const enum Actions {
	ATTACH_NEW_QUOTE_TO_PROJECT = 'ATTACH_NEW_QUOTE_TO_PROJECT',
	ATTACH_EXISTING_CONTACT_TO_PROJECT = 'ATTACH_EXISTING_CONTACT_TO_PROJECT',
	FETCH_ALL_USERS = 'FETCH_ALL_USERS',
	SET_PROJECTS_FILTER_MAIN_QUERY = 'SET_FILTER_MAIN_QUERY',
	SET_PROJECTS_FILTER_FETCH_ONGOING = 'SET_FILTER_FETCH_ONGOING',
	SET_PROJECTS_FILTER_SIDEBAR_OPEN = 'SET_FILTER_SIDEBAR_OPEN',
	SET_PROJECTS_FILTER_NAME_QUERY = 'SET_FILTER_NAME_QUERY',
	SET_PROJECTS_FILTER_PROJECT_STATUS = 'SET_FILTER_PROJECT_STATUS',
	SET_PROJECTS_FILTER_PROJECT_LEADER = 'SET_FILTER_PROJECT_LEADER',
	SET_PROJECTS_FILTER_ESTIMATOR = 'SET_FILTER_ESTIMATOR',
	SET_PROJECTS_FILTER_DESIGNER = 'SET_FILTER_DESIGNER',
	SET_PROJECTS_FILTER_DRAWER = 'SET_FILTER_DRAWER',
	SET_PROJECTS_FILTER_CLEAR = 'SET_FILTER_CLEAR',
	TOGGLE_IS_FILTER_TYPE_ACTIVE = 'TOGGLE_IS_FILTER_TYPE_ACTIVE',
	TOGGLE_FILTER_SHOW_MORE_LEADERS = 'TOGGLE_FILTER_SHOW_MORE_LEADERS',
	TOGGLE_FILTER_SHOW_MORE_STATUSES = 'TOGGLE_FILTER_SHOW_MORE_STATUSES',
	FETCH_PROJECTS_BY_FILTERS = 'FETCH_PROJECTS_BY_FILTERS',
	FETCH_PROJECT_BY_ID = 'FETCH_PROJECT_BY_ID',
	FETCH_NEXT_PAGE = 'FETCH_NEXT_PAGE',
	CREATE_PROJECT = 'CREATE_PROJECT',
	EDIT_PROJECT_BY_ID = 'EDIT_PROJECT_BY_ID',
	EDIT_INSPECTED_PROJECT_BY_ID = 'EDIT_INSPECTED_PROJECT_BY_ID',
	FILTER_PROJECT_CONTACTS = 'FILTER_PROJECT_CONTACTS',
	DELETE_PROJECT_BY_ID = 'DELETE_PROJECT_BY_ID',
	ARCHIVE_PROJECT_BY_ID = 'ARCHIVE_PROJECT_BY_ID',
	UN_ARCHIVE_PROJECT_BY_ID = 'UN_ARCHIVE_PROJECT_BY_ID',
	DELETE_INSPECTED_PROJECT_BY_ID = 'DELETE_INSPECTED_PROJECT_BY_ID',
	DESTROY_PROJECT_DETAILS = 'DESTROY_PROJECT_DETAILS',
	TOGGLE_ASSOCIATION_EXISTING_CLIENT_TO_PROJECT = 'TOGGLE_ASSOCIATION_EXISTING_CLIENT_TO_PROJECT',
	TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_PROJECT = 'TOGGLE_ASSOCIATION_EXISTING_CONTACT_TO_PROJECT',
	TOGGLE_ASSOCIATION_EXISTING_SUPPLIER_TO_THIRD_PARTY = 'TOGGLE_ASSOCIATION_EXISTING_SUPPLIER_TO_THIRD_PARTY',
	FETCH_PROJECT_STATUSES = 'FETCH_PROJECT_STATUSES',
	ADD_THIRD_PARTY = 'ADD_THIRD_PARTY',
	REMOVE_THIRD_PARTY = 'REMOVE_THIRD_PARTY',
	TOGGLE_THIRD_PARTY_ASSIGNMENT = 'TOGGLE_THIRD_PARTY_ASSIGNMENT',
	SAVE_COMMENT = 'SAVE_COMMENT',
	SAVE_PLANNER_PERIOD_COMMENT = 'SAVE_PLANNER_PERIOD_COMMENT',
	SAVE_CONTRACTOR_BRANCH = 'SAVE_CONTRACTOR_BRANCH',
	DELETE_CONTRACTOR_BRANCH = 'DELETE_CONTRACTOR_BRANCH',
	SAVE_PLANNER_PERIOD = 'SAVE_PLANNER_PERIOD',
	EDIT_COMMENT = 'EDIT_COMMENT',
	DELETE_COMMENT = 'DELETE_COMMENT',
	SET_PROJECT_WORKFLOW_TASK = 'SET_PROJECT_WORKFLOW_TASK',
	SAVE_PROJECT_WORKFLOW_TASK_NOTE = 'SAVE_PROJECT_WORKFLOW_TASK_NOTE',
	DELETE_PROJECT_WORKFLOW_TASK_NOTE = 'DELETE_PROJECT_WORKFLOW_TASK_NOTE',
	CREATE_PLANNER_PERIOD = 'CREATE_PLANNER_PERIOD',
	REMOVE_QUOTE = 'REMOVE_QUOTE',
	REMOVE_PLANNER_PERIOD = 'REMOVE_PLANNER_PERIOD',
	EDIT_QUOTE = 'EDIT_QUOTE',
	EDIT_PLANNER_PERIOD = 'EDIT_PLANNER_PERIOD',
	SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
	SET_DETACHED_CONTRACTOR = 'SET_DETACHED_CONTRACTOR',
	SET_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP = 'SET_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP',
	DETACH_CONTRACTOR = 'DETACH_CONTRACTOR',
	FETCH_SOCIAL_SECURITY_REGISTRATIONS = 'FETCH_SOCIAL_SECURITY_REGISTRATIONS',
	EDIT_SOCIAL_SECURITY_REGISTRATION_BY_ID = 'EDIT_SOCIAL_SECURITY_REGISTRATION_BY_ID',
	EDIT_PROJECT_CLIENT = 'EDIT_PROJECT_CLIENT',
}

export const enum Getters {
	GET_DETACHED_CONTRACTOR_STATE = 'GET_DETACHED_CONTRACTOR_STATE',
	GET_PROJECTS = 'GET_PROJECTS',
	GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS',
	GET_PROJECT_STATUSES = 'GET_PROJECT_STATUSES',
	GET_USERS = 'GET_USERS',
	GET_LOADING = 'GET_LOADING',
	GET_PROJECTS_FILTERS = 'GET_PROJECTS_FILTERS',
	GET_VALIDATION_ERRORS = 'GET_VALIDATION_ERRORS',
	GET_SOCIAL_SECURITY_REGISTRATIONS = 'GET_SOCIAL_SECURITY_REGISTRATIONS',
}

export const enum Mutations {
	MUTATE_DETACHED_CONTRACTOR = 'MUTATE_DETACHED_CONTRACTOR',
	MUTATE_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP = 'MUTATE_DETACHED_CONTRACTOR_SHOW_DELETE_POPUP',
	MUTATE_PROJECTS_DATA = 'MUTATE_PROJECTS_DATA',
	MUTATE_PAGINATION_DETAILS = 'MUTATE_PAGINATION_DETAILS',
	MUTATE_VALIDATION_ERRORS = 'MUTATE_VALIDATION_ERRORS',
	MUTATE_PUSH_NEXT_PAGE = 'MUTATE_PUSH_NEXT_PAGE',
	MUTATE_INSPECTED_PROJECT = 'MUTATE_INSPECTED_PROJECT',
	MUTATE_PROJECTS_STATUS_DATA = 'MUTATE_PROJECTS_STATUS_DATA',
	MUTATE_USERS_DATA = 'MUTATE_USERS_DATA',
	MUTATE_SOCIAL_SECURITY_REGISTRATIONS_DATA = 'MUTATE_SOCIAL_SECURITY_REGISTRATIONS_DATA',
	MUTATE_CURRENT_FILTERS = 'MUTATE_CURRENT_FILTERS',
	MUTATE_LOADING_STATE = 'MUTATE_LOADING_STATE',
	MUTATE_FILTERS_MAIN_QUERY = 'MUTATE_FILTERS_MAIN_QUERY',
	MUTATE_FILTERS_FETCH_ONGOING = 'MUTATE_FILTERS_FETCH_ONGOING',
	MUTATE_FILTERS_SIDEBAR_OPEN = 'MUTATE_FILTERS_SIDEBAR_OPEN',
	MUTATE_FILTERS_NAME_QUERY = 'MUTATE_FILTERS_NAME_QUERY',
	MUTATE_FILTERS_PROJECT_STATUS = 'MUTATE_FILTERS_PROJECT_STATUS',
	MUTATE_FILTERS_PROJECT_LEADER = 'MUTATE_FILTERS_PROJECT_LEADER',
	MUTATE_FILTERS_ESTIMATOR = 'MUTATE_FILTERS_ESTIMATOR',
	MUTATE_FILTERS_DESIGNER = 'MUTATE_FILTERS_DESIGNER',
	MUTATE_FILTERS_DRAWER = 'MUTATE_FILTERS_DRAWER',
	MUTATE_FILTERS = 'MUTATE_FILTERS',
	MUTATE_FILTERS_CLEAR = 'MUTATE_FILTERS_CLEAR',
	MUTATE_FILTERS_SHOW_MORE_LEADERS = 'MUTATE_FILTERS_SHOW_MORE_LEADERS',
	MUTATE_FILTERS_SHOW_MORE_STATUSES = 'MUTATE_FILTERS_SHOW_MORE_STATUSES',
}
