import { reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import useFilter from '@/composables/useFilter';
import { UseProjectRelationshipState, UseProjectRelationshipComposables, UseProjectRelationshipContext } from '@/composables/types/useProjectRelationship';
import { merge } from 'lodash';
import { Options } from './types/composable';
import { actions as projectRelationshipActions } from '@/composables/actions/useProjectRelationship';
import { useDynamicProps } from './useDynamicProps';
import {ProjectRelationship} from "@/modules/purchase-invoices/types/entities";

/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseProjectRelationshipState>): UseProjectRelationshipState => {
  return merge(
    {
      projectRelationship: null,
      projectRelationshipValidationErrors: null,
      projectRelationshipBusinessErrors: null,
    },
    state
  );
};

export default function useProjectRelationship(options?: Options<UseProjectRelationshipState, UseProjectRelationshipComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    filter: useFilter(),
    showDialog: useDynamicProps<boolean>(),
    loadingActions: useDynamicProps<boolean>(),
  });
  const ctx: UseProjectRelationshipContext = { state, composables };
  const actions = wrapActions(ctx, projectRelationshipActions);

  // return state and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // other
      showDialog: ctx.composables.showDialog.getters,
      projectRelationship: computed<ProjectRelationship | null>(() => state.projectRelationship),
      projectRelationshipValidationErrors: computed(() => state.projectRelationshipValidationErrors),
      projectRelationshipBusinessErrors: computed(() => state.projectRelationshipBusinessErrors),
    },
    actions: {
      ...ctx.composables.filter.actions,
      showDialog: ctx.composables.showDialog.actions.set,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...actions,
    },
  };
}