import { reactive, computed } from 'vue';
import {mergeComposables, wrapActions} from '@/composables/utils';
import useFilter, {createState as createFilterState} from '@/composables/useFilter';
import { UseProjectListState, UseProjectListComposables, UseProjectListContext } from '@/composables/types/useProjectList';
import { merge } from 'lodash';
import { Options } from '@/composables/types/composable';
import { actions as projectListActions } from '@/composables/actions/useProjectList';
import { useDynamicProps } from './useDynamicProps';
import useList from '@/composables/useList';
import {Project} from "@/modules/projects/types/entities";
/**
 *
 * @param state
 * @returns
 */
export const createState = (state?: Partial<UseProjectListState>): UseProjectListState => {
  return merge(
    {
      expandedIds: [],
    },
    state
  );
};

export default function useProjectList(options?: Options<UseProjectListState, UseProjectListComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    list: useList<Project>(),
    filter: useFilter({composables: {}, initialState: createFilterState({urlParamPrefix: null})}),
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseProjectListContext = { state, composables };
  const actions = wrapActions(ctx, projectListActions);

  // return state and actions
  return {
    getters: {
      ...ctx.composables.filter.getters,
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // searching
      isSearching: computed<boolean>(() => Object.entries(ctx.composables.loadingActions.getters.all.value).some(v => v[0] === actions.search.name && v[1])),
      // content
      projects: computed(() => ctx.composables.list.getters.items.value),
    },
    actions: {
      ...ctx.composables.filter.actions,
      setLoading: ctx.composables.loadingActions.actions.set,
      ...ctx.composables.list.actions,
      ...actions,
    },
  };
}