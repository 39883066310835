import { reactive, computed } from 'vue';
import { UseListState, UseListComposables, UseListContext } from './types/useList';
import { merge } from 'lodash';
import { Options } from './types/composable';
import { useDynamicProps } from './useDynamicProps';
import {mergeComposables} from "@/composables/utils";
// import { wrapActions } from '@/composables/utils';
// import { actions as listActions } from './actions/useList';

/**
 *
 * @param state
 * @returns
 */
export const createState = <T>(state?: Partial<UseListState>): UseListState => {
  return merge(
    {
      items: [] as T[],
    },
    state
  );
};

/**
 * High-level list-composable
 *
 * @param options
 * @returns
 */
export default function useList<T>(options?: Options<UseListState, UseListComposables>) {
  const state = reactive(createState(options?.initialState));
  const composables = mergeComposables({
    loadingActions: useDynamicProps<boolean>(),
  }, options?.composables);
  const ctx: UseListContext = { state, composables };
  // const actions = wrapActions(ctx, listActions);

  const setItems = async (items: T[]): Promise<void> => {
    const { state, composables: { loadingActions } } = ctx;
    loadingActions.actions.set(setItems.name, true);
    try {
      state.items = [...items];
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      loadingActions.actions.set(setItems.name, false);
    }
  };
  const addItem = async (item: T): Promise<void> => {
    const { state, composables: { loadingActions } } = ctx;
    loadingActions.actions.set(addItem.name, true);
    try {
      state.items.push(item);
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      loadingActions.actions.set(addItem.name, false);
    }
  };
  const removeItem = async (item: T, whereEquals: (value: T, item: T) => boolean): Promise<void> => {
    const { state, composables: { loadingActions } } = ctx;
    loadingActions.actions.set(removeItem.name, true);
    try {
      state.items = [...state.items.filter(x => !whereEquals(x, item))];
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      loadingActions.actions.set(removeItem.name, false);
    }
  };
  const replaceItem = async (item: T, whereEquals: (value: T, item: T) => boolean): Promise<void> => {
    const { state, composables: { loadingActions } } = ctx;
    loadingActions.actions.set(replaceItem.name, true);
    try {
      const idx = state.items.findIndex(x => whereEquals(x, item));
      state.items.splice(idx === -1 ? 0 : idx, idx === -1 ? 0 : 1, item);
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      loadingActions.actions.set(replaceItem.name, false);
    }
  };

  // return state and actions
  return {
    getters: {
      // loading
      isLoading: computed<boolean>(() => !!ctx.composables.loadingActions.getters.some(true).value),
      loadingActions: computed(() => ctx.composables.loadingActions.getters),
      isLoadingAction: (actions: string[]) => computed(() => actions.some((action) => ctx.composables.loadingActions.getters.all.value[action])),
      // other
      items: computed<T[]>(() => state.items as T[]),
    },
    actions: {
      setItems,
      addItem,
      removeItem,
      replaceItem,
    },
  };
}