import {RouteConfig} from 'vue-router';
import ProjectsComponent from './views/projects/projects.component.vue';
import ProjectDetailsComponent from "@/modules/projects/views/project-details/project-details.component.vue";
import SocialSecurityRegistrationsComponent from './views/social-security-registrations/social-security-registrations.component.vue';

export const routes: RouteConfig[] = [
	{
		path: '/projects',
		component: ProjectsComponent
	},
	{
		path: '/projects/:id',
		name: 'projectDetails',
		component: ProjectDetailsComponent
	},
	{
		path: '/social-security-registrations',
		component: SocialSecurityRegistrationsComponent
	},
];
