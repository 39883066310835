

	import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';

	import ProjectHalfPieDonutChartComponent
		from '@/modules/projects/components/project-half-pie-donut-chart/project-half-pie-donut-chart.vue';
	import { PropType } from 'vue';
	import { Project } from '@/modules/projects/types/entities';

	@Component({
		components: {
			'project-donut-chart': ProjectHalfPieDonutChartComponent
		}
	})
	export default class ProjectFinancesSectionComponent extends Vue {
		@PropSync('editProjectFinances', { type: Boolean, default: false }) editProjectFinancesSync!: boolean;
		@Prop({ type: Object as PropType<Project>, required: true }) project!: Project;
		@Prop({ type: Object as PropType<any> }) salesInvoicesMetricsReport!: any;

		localContent: {
			project: Project
		} = {
			project: {
				id: 0,
				uniqueId: '',
				projectName: '',
				presentedPrice: undefined,
				initialOffer: 0,
				initialOfferComment: '',
				dateInitialOffer: null,
				dateFinancialDetails: null,
				thirdParties: [],
				clients: [],
				contractorParties: [],
				addedDate: null,
				street: null,
				streetNumber: null,
				postalCode: null,
				city: null,
				distanceToSfinxHq: null,
				socialSecurityCompleted: false,
				socialSecurityReference: '',
				socialSecurityStartDate: null,
				socialSecurityEndDate: null,
				projectLeaders: [],
				estimators: [],
				drawers: [],
				designers: [],
				quotes: [],
				orders: [],
				archivedAt: null,
				latestProgressSnapshot: null,
				contractorBranches: [],
			}
		}

		get percentageExecuted(): number {
			if(this.project.latestProgressSnapshot && this.project.latestProgressSnapshot.totalAmountOrdered) {
				return Math.floor((this.project.latestProgressSnapshot.totalAmountExecuted / this.project.latestProgressSnapshot.totalAmountOrdered) * 100);
			}
			return 0;
		}

		get percentagePaid(): number {
			let percentage = 0;
			if(this.totalInvoiced && this.totalInvoiced > 0) {
				percentage = Math.floor((this.totalPaid / this.totalInvoiced) * 100);
			}
			if (percentage > 100) percentage = 100;
			return percentage ? percentage : 0;
		}

		get totalPaid() {
			if(this.salesInvoicesMetricsReport && this.salesInvoicesMetricsReport.paymentAggregates && this.salesInvoicesMetricsReport.paymentAggregates.total && this.salesInvoicesMetricsReport.paymentAggregates.total.totalBalance) {
				return this.salesInvoicesMetricsReport.paymentAggregates.total.totalBalance
			}
			return 0
		}

		get totalInvoiced() {
			if(this.salesInvoicesMetricsReport && this.salesInvoicesMetricsReport.invoiceAggregates && this.salesInvoicesMetricsReport.invoiceAggregates.total && this.salesInvoicesMetricsReport.invoiceAggregates.total.grandTotalBalance) {
				return this.salesInvoicesMetricsReport.invoiceAggregates.total.grandTotalBalance
			}
			return 0
		}

		get percentageTotalPaidVsInvoiced() {
			if(this.totalInvoiced && this.totalInvoiced >= 0 && this.totalPaid) {
				return Math.round((this.totalPaid/this.totalInvoiced) * 10000) / 100
			}
			return 0
		}

		@Watch('editProjectFinances')
		onEditProjectFinancesChange(newVal: boolean): void {
			if (!newVal) this.localContent.project = JSON.parse(JSON.stringify(this.project));
		}

		@Watch('project', { immediate: true })
		onProjectChange(newVal: Project): void {
			this.localContent.project = JSON.parse(JSON.stringify(newVal));
		}
	}
