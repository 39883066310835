import { AccountingState } from './types/AccountingState';
import { AccountingLedger } from '../types/entities';

export const state: AccountingState = {
    // crud
    accountingLedger: null,
    accountingLedgerItem: null,

    activeAccountingLedger: null,
    accountingLedgers: Array<AccountingLedger>(),
    isLoading: false,

}