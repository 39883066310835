import Vue from 'vue';
import { ReportState } from '@/modules/reports/store/types/ReportState';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/core/types/RootState';
import { Actions, Mutations, Getters } from '@/modules/reports/store/types/StoreTypes';
import { UsersService } from '@/services/users.service';
import { ProjectsService } from '@/services/projects.service';
import { Filter } from '@/core/types/Filter';
import applyFilter from '@/core/helpers/Filter';
import { Project } from '@/modules/projects/types/entities';
import { ReportsService } from '../services/reports.service';
import { Supplier } from '@/modules/entities/types/entities';
import { SuppliersService } from '@/modules/entities/services/suppliers/suppliers.service';
import { filterGrossMarginReport } from '@/modules/reports/helpers';


const namespace = 'reports';
const usersService = new UsersService();
const projectsService = new ProjectsService();
const reportsService = new ReportsService();

export interface PayloadParameters<T> {
    payload: T;
    fetch?: boolean;
}

export const actions: ActionTree<ReportState, RootState> = {
  [Actions.FETCH_GROSS_MARGIN_REPORT]: async ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING_GROSS_MARGIN_REPORT, true);
    try {
      const filterUrl = applyFilter(state.grossMarginFilterState);
      const url =
        "/api/v1/reports/projects-gross-margin" +
        (filterUrl ? "?" + filterUrl : "");
      const result = await reportsService.getReport(url);
      commit(Mutations.MUTATE_GROSS_MARGIN_REPORT, result.data.data);
      return result.data.data;
    } catch (error: any) {
      console.warn(error);
    } finally {
        commit(Mutations.MUTATE_IS_LOADING_GROSS_MARGIN_REPORT, false);
    }
  },

  [Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT]: async ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    commit(Mutations.MUTATE_IS_LOADING_CONTRACTOR_BRANCH_REPORT, true);
    try {
      const filterUrl = applyFilter(state.grossMarginFilterState);
      const url =
        "/api/v1/reports/projects-contractor-branches-purchase" +
        (filterUrl ? "?" + filterUrl : "");
      const result = await reportsService.getReport(url);
      commit(
        Mutations.MUTATE_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT,
        result.data.data
      );
      dispatch(
        Actions.FETCH_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT
      );
      return result.data.data;
    } catch (error: any) {
      console.warn(error);
    } finally {
      commit(Mutations.MUTATE_IS_LOADING_CONTRACTOR_BRANCH_REPORT, false);
    }
  },

  [Actions.FETCH_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT]: async (
    { state, commit }: ActionContext<ReportState, RootState>
  ) => {
    commit(Mutations.MUTATE_IS_LOADING_CONTRACTOR_BRANCH_REPORT, true);
    try {
      let projectsContractorBranchesReport = state.projectsContractorBranchesReport;
      // Apply filtering based on new criteria
      const filteredReport = filterGrossMarginReport(projectsContractorBranchesReport, state.grossMarginFilterState, state.projectsContractorBranchesPurchaseReportMetric);
      commit(
        Mutations.MUTATE_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT,
        filteredReport
      );
    } catch (err: any) {
      console.error(err);
    }finally {
      commit(Mutations.MUTATE_IS_LOADING_CONTRACTOR_BRANCH_REPORT, false);
    }
  },

  [Actions.TOGGLE_GROSS_MARGIN_FILTER_SIDEBAR]: ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    commit(
      Mutations.MUTATE_TOGGLE_GROSS_MARGIN_FILTER_SIDEBAR,
      !state.grossMarginFilterState.sideBarOpen
    );
  },

  [Actions.TOGGLE_GROSS_MARGIN_FILTER_GROUP]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    { payload = undefined, fetch = true }: PayloadParameters<string | undefined>
  ) => {
    if (payload)
      commit(Mutations.MUTATE_TOGGLE_GROSS_MARGIN_FILTER_GROUP, payload);
    if (fetch) dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
    if (fetch && state.showProjectsContractorBranchesPurchaseReport)
      dispatch(Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },

  [Actions.FETCH_USERS]: async ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    try {
      const result = await usersService.getAllUsers();
      commit(Mutations.MUTATE_USERS, result.data.data);
      return result.data.data;
    } catch (error: any) {
      console.warn(error);
    } finally {
    }
  },

  [Actions.FETCH_PROJECT_STATUSES]: async ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    try {
      const result = await projectsService.getAllProjectStatuses();
      commit(Mutations.MUTATE_PROJECT_STATUSES, result.data.data);
      return result.data.data;
    } catch (error: any) {
      console.warn(error);
    } finally {
    }
  },

  [Actions.SEARCH_PROJECTS]: async (
    { state, commit }: ActionContext<ReportState, RootState>,
    payload: string
  ) => {
    const projectsService = new ProjectsService();

    try {
      const filterState = {
        filters: {
          search: new Filter<string>(
            "search",
            "query",
            [payload] as string[],
            true,
            "search",
            null
          ),
        },
      };
      const filterUrl = applyFilter(filterState);
      const url = "/api/v1/projects" + (filterUrl ? "?" + filterUrl : "");
      const result = await projectsService.getAllProjects(url);

      commit(Mutations.MUTATE_SEARCH_PROJECTS, result.data.data);
      return result.data.data;
    } catch (err: any) {
      console.error(err);
    }
  },

  [Actions.SEARCH_SUPPLIERS]: async (
    { state, commit }: ActionContext<ReportState, RootState>,
    payload: string
  ) => {
    const suppliersService = new SuppliersService();

    try {
      const parameters = new URLSearchParams();
      parameters.set("searchQuery", payload);
      const result = await suppliersService.getSuppliers(parameters);

      commit(Mutations.MUTATE_SEARCH_SUPPLIERS, result.data.data);
      return result;
    } catch (err: any) {
      console.error(err);
    }
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_PROJECT]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    { payload = null, fetch = true }: PayloadParameters<Project[] | null>
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_SELECTED_PROJECT,
      payload ? payload : null
    );
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_PROJECTS,
      payload ? payload.map((p) => p.id) : []
    );
    if (fetch) dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
    if (fetch && state.showProjectsContractorBranchesPurchaseReport)
      dispatch(Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },
  [Actions.SET_GROSS_MARGIN_FILTER_ON_SUPPLIER]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    { payload = null, fetch = true }: PayloadParameters<Supplier | null>
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_SELECTED_SUPPLIER,
      payload ? payload : null
    );
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_SUPPLIER,
      payload ? [payload.id] : []
    );
    if (state.showProjectsContractorBranchesPurchaseReport)
      dispatch(Actions.FETCH_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_PROJECT_STATUS]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: number | number[]
  ) => {
    const original = [
      ...state.grossMarginFilterState.filters.projectStatuses.value,
    ] as number[];
    const toggles = Array.isArray(payload)
      ? [...payload]
      : ([payload] as number[]);
    const difference = original
      .filter((x) => !toggles.includes(x))
      .concat(toggles.filter((x) => !original.includes(x)));

    commit(Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_STATUS, difference);
    if (state.grossMarginFilterState.filters.projectStatuses.active)
      dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
    if (
      state.grossMarginFilterState.filters.projectStatuses.active &&
      state.showProjectsContractorBranchesPurchaseReport
    )
      dispatch(Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_PROJECT_LEADER]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: number
  ) => {
    let ids = [...state.grossMarginFilterState.filters.projectLeaders.value];
    if (
      state.grossMarginFilterState.filters.projectLeaders.value.some(
        (id: number) => id === payload
      )
    ) {
      ids = ids.filter((id) => id !== payload);
    } else {
      ids.push(payload);
    }

    commit(Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_LEADER, ids);
    if (state.grossMarginFilterState.filters.projectLeaders.active)
      dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
    if (
      state.grossMarginFilterState.filters.projectLeaders.active &&
      state.showProjectsContractorBranchesPurchaseReport
    )
      dispatch(Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_PROJECT_ESTIMATOR]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: number
  ) => {
    let ids = [...state.grossMarginFilterState.filters.projectEstimators.value];
    if (
      state.grossMarginFilterState.filters.projectEstimators.value.some(
        (id: number) => id === payload
      )
    ) {
      ids = ids.filter((id) => id !== payload);
    } else {
      ids.push(payload);
    }
    commit(Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_PROJECT_ESTIMATOR, ids);
    if (state.grossMarginFilterState.filters.projectEstimators.active)
      dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
    if (
      state.grossMarginFilterState.filters.projectEstimators.active &&
      state.showProjectsContractorBranchesPurchaseReport
    )
      dispatch(Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_CONTRACTOR_TYPES]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: number
  ) => {
    let ids = [...state.grossMarginFilterState.filters.contractorBranches.value];
    if (
      state.grossMarginFilterState.filters.contractorBranches.value.some(
        (id: number) => id === payload
      )
    ) {
      ids = ids.filter((id) => id !== payload);
    } else {
      ids.push(payload);
    }
    commit(Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_CONTRACTOR_TYPES, ids);
    if (state.grossMarginFilterState.filters.contractorBranches.active)
      dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  //Total Amount Ordered
  [Actions.SET_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: number | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },
  //Total Amount Ordered Contractor Branch
  [Actions.SET_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: {contractorBranch: number | null, minAmountOrdered: number | null} | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_MIN_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH,
      payload ? [payload] : []
    );
    if (state.showProjectsContractorBranchesPurchaseReport)
      dispatch(Actions.FETCH_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: {contractorBranch: number | null, minAmountOrdered: number | null} | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_MAX_TOTAL_AMOUNT_ORDERED_CONTRACTOR_BRANCH,
      payload ? [payload] : []
    );
    if (state.showProjectsContractorBranchesPurchaseReport)
      dispatch(Actions.FETCH_FILTERED_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },
  //Startdate planning
  [Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_FROM]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_FROM,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_TO]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PLANNER_DATE_TO,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },
  //Enddate planning
  [Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_FROM]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_FROM,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_TO]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PLANNER_DATE_TO,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },
  //Date first sales invoice
  [Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_FROM]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_FROM,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_TO]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_SALES_INVOICE_DATE_TO,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },
  //Date last sales invoice
  [Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_FROM]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_FROM,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_TO]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_SALES_INVOICE_DATE_TO,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },
  // Date first purchase invoice
  [Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_FROM]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_FROM,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_TO]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_FIRST_PURCHASE_INVOICE_DATE_TO,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },
  // Date last purchase invoice
  [Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_FROM]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_FROM,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_TO]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: string | null
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_LAST_PURCHASE_INVOICE_DATE_TO,
      payload ? [payload] : []
    );
    dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_CONTRACTOR_BRANCHES]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: boolean
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_CONTRACTOR_BRANCHES,
      [payload]
    );
    if (
      state.grossMarginFilterState.filters.showOnlyCompletedContractorBranches.active &&
      state.showProjectsContractorBranchesPurchaseReport
    )
      dispatch(Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_PROJECTS]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: boolean
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_COMPLETED_PROJECTS,
      [payload]
    );
      dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
  },

  [Actions.SET_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_RELEVANT_CONTRACTOR_BRANCHES]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: boolean
  ) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_FILTER_ON_SHOW_ONLY_RELEVANT_CONTRACTOR_BRANCHES,
      [payload]
    );
    if (
      state.grossMarginFilterState.filters.showOnlyRelevantContractorBranches.active &&
      state.showProjectsContractorBranchesPurchaseReport
    )
      dispatch(Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },

  [Actions.CLEAR_GROSS_MARGIN_FILTERS]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    { payload = [], fetch = true }: PayloadParameters<string[]>
  ) => {
    commit(Mutations.MUTATE_CLEAR_GROSS_MARGIN_FILTERS, payload);
    if (payload.includes("projects")) {
      commit(Mutations.MUTATE_GROSS_MARGIN_FILTER_SELECTED_PROJECT, null);
      commit(Mutations.MUTATE_GROSS_MARGIN_FILTER_GROUP_DISABLE, "projects");
    }
    if (fetch) dispatch(Actions.FETCH_GROSS_MARGIN_REPORT);
    if (fetch && state.showProjectsContractorBranchesPurchaseReport)
      dispatch(Actions.FETCH_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT);
  },

  [Actions.TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_STATUSES]: ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_STATUSES,
      !state.grossMarginFilterState.showMoreProjectStatuses
    );
  },

  [Actions.TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_LEADERS]: ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_LEADERS,
      !state.grossMarginFilterState.showMoreProjectLeaders
    );
  },

  [Actions.TOGGLE_GROSS_MARGIN_SHOW_MORE_PROJECT_ESTIMATORS]: ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    commit(
      Mutations.MUTATE_GROSS_MARGIN_SHOW_MORE_PROJECT_ESTIMATORS,
      !state.grossMarginFilterState.showMoreProjectEstimators
    );
  },

  [Actions.TOGGLE_SHOW_PROJECTS_GROSS_MARGIN_REPORT]: ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    commit(
      Mutations.MUTATE_SHOW_PROJECTS_GROSS_MARGIN_REPORT,
      !state.showProjectsGrossMarginReport
    );
  },

  [Actions.TOGGLE_SHOW_PROJECTS_CONTRACTOR_BRANCHES_OVERVIEW_REPORT]: ({
    commit,
    state,
    dispatch,
  }: ActionContext<ReportState, RootState>) => {
    commit(
      Mutations.MUTATE_SHOW_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT,
      !state.showProjectsContractorBranchesPurchaseReport
    );
  },

  [Actions.SET_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC]: (
    { commit, state, dispatch }: ActionContext<ReportState, RootState>,
    payload: ProjectsContractorBranchesOverviewReportMetric
  ) => {
    commit(
      Mutations.MUTATE_PROJECTS_CONTRACTOR_BRANCHES_PURCHASE_REPORT_METRIC,
      payload
    );
  },
};