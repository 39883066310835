
import { JsonResource } from '@/core/types/Entities';
import { ReportsService, BASE_ENDPOINT as REPORTS_BASE_ENDPOINT } from '@/modules/reports/services/reports.service';
import { AxiosResponse } from 'axios';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Project } from '../../types/entities';
import SalesInvoiceMetricsComponent from "@/modules/reports/components/sales-invoice-metrics.component.vue";

    const reportsService = new ReportsService()

    @Component({
      components: {SalesInvoiceMetricsComponent}
    })
    export default class ProjectSalesInvoicesMetricsReportComponent extends Vue {

        // vuex actions

        // vuex getters

        // props
        // @Prop({type: Object as PropType<any>, required: true}) metrics!: any;
        @Prop({type: Object as PropType<Project>, required: true}) project!: Project;

        // local/children state
        state: {
            showInvoicesMetricsReport: boolean,
            invoicesMetricsReportLoading: boolean,
            invoicesMetricsReportData: any,
        } = {
            showInvoicesMetricsReport: false,
            invoicesMetricsReportLoading: false,
            invoicesMetricsReportData: null
        }
        content: {} = {}

        // handlers
        onClickToggleShowInvoicesMetricsReport() {
            if(this.state.showInvoicesMetricsReport) {
                this.state = { ...this.state, showInvoicesMetricsReport: false, invoicesMetricsReportLoading: false }
            } else {
                this.state = { ...this.state, invoicesMetricsReportLoading: true }
                const parameters = new URLSearchParams();
                parameters.set('filter[projects]', this.project.id.toString())
                reportsService.getReport(REPORTS_BASE_ENDPOINT + `/sales-invoices-metrics?${ parameters.toString() }` ).then((result: AxiosResponse<JsonResource<any>>) => {
                    this.state = { 
                        ...this.state, 
                        invoicesMetricsReportLoading: false,
                        showInvoicesMetricsReport: true,
                        invoicesMetricsReportData: result.data.data,
                    }
                })
            }
        }

        // methods

        // getters
        get showReports() {
            return this.state.showInvoicesMetricsReport
        }
        get invoicesMetricsReport() {
            return this.state.invoicesMetricsReportData;
        }
        
        // setters

        // watchers

        // lifecycle
    }
