import { ProjectRelationship } from '../types/entities';

export function isProjectRequiredValidator(vm: ProjectRelationship): boolean {
    if(vm.isProjectRequired && !vm.project && vm.status && vm.status.name === 'approved' ) return true;
    return false
}

export function isContractorTypeRequiredValidator(vm: ProjectRelationship): boolean {
    if(!vm.contractorType && vm.project && vm.status && vm.status.name === 'approved') return true;
    return false
}

export function isAccountingLedgerItemRequiredValidator(vm: ProjectRelationship): boolean {
    if(vm.status && vm.status.name === 'approved' && !vm.accountingLedgerItem) return true
    return false
}