import {WorkflowFunctionGroup, WorkflowTask, WorkflowTaskStatus} from '@/modules/workflow/types/entities';
import {LogItem} from '@/modules/settings/types/entities';
import { PaginationDetails } from '@/core/types/Entities';

export interface WorkflowState {
	tasks: WorkflowTask[];
	taskStatuses: WorkflowTaskStatus[];
	functionGroups: WorkflowFunctionGroup[];
	infoMessage: {
		text: string;
		isVisible: boolean;
		timeout?: number;
		buttonVisible: boolean;
	};
	activityLog: ActivityLog;
	highestTaskSequence: number;
	paginationDetails?: PaginationDetails;
	activeListType: TaskListType;
	filters: {
		fetchArchived: boolean;
		sortBySequence: string;
		sortByArchivedAt: string;
	};
}

export enum ActivityLogLastActionType {
	CREATE = 'CREATE',
	EDIT = 'EDIT',
	DELETE = 'DELETE',
	DELETE_MULTIPLE = 'DELETE_MULTIPLE',
	ARCHIVE = 'ARCHIVE',
	ARCHIVE_MULTIPLE = 'ARCHIVE_MULTIPLE',
	UN_ARCHIVE_MULTIPLE = 'UN_ARCHIVE_MULTIPLE'
}

export enum TaskListType {
	'TASKS_ARCHIVED' = 'TASKS_ARCHIVED',
	'TASKS_ONGOING' = 'TASKS_ONGOING',
	'TASK_STATUSES' = 'TASK_STATUSES',
	'FUNCTION_GROUPS' = 'FUNCTION_GROUPS'
}

export interface ActivityLog {
	lastItem: {
		item: any;
		index: number;
	};
	lastBackendResponse?: LogItem;
	lastOperationType: ActivityLogLastActionType | null;
	lastOperationListType: TaskListType | null;
}
