
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PdfAnnotateComponent from '@/modules/purchase-invoices/components/pdf-annotate/pdf-annotate.component.vue';
import { Media, MediaAnnotation, User } from '@/modules/entities/types/entities';

const purchaseInvoices = namespace('purchaseInvoices');

@Component({
	components: {
		'pdf-annotate': PdfAnnotateComponent
	}
})
export default class PdfAnnotateModalComponent extends Vue {

	// props
	@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;

	@Prop({required: true, default: ''}) src!: string;
	@Prop({required: true}) media!: Media;
	@Prop({required: true}) user!: User;
	@Prop({required: true}) mediaAnnotation!: {current: MediaAnnotation, all: MediaAnnotation[]}

	// local vars
	state: {} = {}
	content: {
		currentMediaAnnotationEdited: MediaAnnotation | null,
		mediaAnnotation: { current: MediaAnnotation|null, all: MediaAnnotation[] },
	} = {
		currentMediaAnnotationEdited: null,
		mediaAnnotation: { current: null, all: [] }
	}

	// handlers
	onClickCancel(): void {
		this.$emit('onClickCancel')
		// this.closeModal();
	}
	onClickSave() {
		if(this.content.currentMediaAnnotationEdited) this.$emit('onClickSave', this.content.currentMediaAnnotationEdited)
	}
	onUpdatedMediaAnnotation(mediaAnnotation: MediaAnnotation) {
		this.content = {...this.content,
			currentMediaAnnotationEdited: mediaAnnotation
		}
	}

	// methods
	closeModal() {
		this.showModalSync = false
	}

	// watchers
	@Watch('mediaAnnotation', {})
	onWatchMediaAnnotation(annotation: {current: MediaAnnotation; all: MediaAnnotation[]}|null) {
		if(annotation) this.content = { ...this.content, mediaAnnotation: JSON.parse(JSON.stringify(annotation)) }
		else this.content = { ...this.content, mediaAnnotation: { current: null, all: [] }}
	}

	// boot methods
	created(): void {
		//
	}

	destroyed(): void {
		//
	}
}
