import useAccountingLedgerList from "@/composables/useAccountingLedgerList";

const createAccountingStore = () => {

  const currentAccountingLedgerList = useAccountingLedgerList();

  currentAccountingLedgerList.actions
    .setFilter('active', true)
    .then(currentAccountingLedgerList.actions.fetchAccountingLedgers);

  return {
    currentAccountingLedger: currentAccountingLedgerList.getters.currentAccountingLedger,
    refreshCurrentAccountingLedger: currentAccountingLedgerList.actions.fetchAccountingLedgers,
  };
};

let store: ReturnType<typeof createAccountingStore>;

export const useAccounting = ()  => {

  if(store == null) {
    store = createAccountingStore();
  }

  return store;
};