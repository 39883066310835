
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { DataTableHeader } from 'vuetify';
    import { signAmount } from '@/modules/sales-invoices/helpers';
    import { Project } from '../../types/entities';
    import { SalesInvoice, SalesInvoiceRecordType } from '@/modules/sales-invoices/types/entities';
    import {calculatePaymentDifference} from "@/modules/sales-invoices/helpers";

    @Component
    export default class ProjectSalesInvoicesListComponent extends Vue {

        // vuex actions

        // vuex getters

        // props
        @Prop({type: Array as PropType<SalesInvoice[]>, default: () => [], required: true}) salesInvoices!: SalesInvoice[];
        @Prop({type: Object as PropType<Project>, required: true}) project!: Project;

        // local/children state
        state: {
            isGrouped: boolean,
            itemsPerPage: number,
        } = {
            isGrouped: false,
            itemsPerPage: 15,
        }
        content: {} = {}

        // handlers
        onClickGroupRemove() {
            this.state = { ...this.state, isGrouped: false }
        }
        onClickGroupBy() {
            this.state = { ...this.state, isGrouped: true }
        }
        onUpdateItemsPerPage(itemsPerPage: number) {
            if(!this.state.isGrouped) {
                this.state = { ...this.state, itemsPerPage: itemsPerPage }
            }
        }

        // methods
        signAmount(amount: number, recordType: SalesInvoiceRecordType) {
          return signAmount(amount, recordType)
        }
        calculatePaymentDifference(invoice: SalesInvoice) {
          if(invoice.paymentTotal && invoice.recordType && invoice.payments) {
            return calculatePaymentDifference(invoice, invoice.payments)
          }
          return 0
        }

        // getters
        get salesInvoicesHeaders() {
            return [
                {
                    text: this.$t('projects.details.salesInvoices.name').toString(),
                    value: 'name',
                    align: 'start',
                    sortable: true,
                    groupable: true,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.salesInvoices.invoiceDate').toString(),
                    value: 'invoiceDate',
                    align: 'start',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.salesInvoices.dueDate').toString(),
                    value: 'dueDate',
                    align: 'start',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.salesInvoices.type').toString(),
                    value: 'type.id',
                    align: 'start',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.salesInvoices.paymentStatus').toString(),
                    value: 'paymentStatus.id',
                    align: 'start',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.salesInvoices.total').toString(),
                    value: 'total',
                    align: 'end',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.salesInvoices.grandTotal').toString(),
                    value: 'grandTotal',
                    align: 'end',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('invoices.sales.dataTable.headers.unpaidTotal').toString(),
                    value: 'unpaidTotal',
                    align: 'end',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
                {
                    text: this.$t('projects.details.salesInvoices.vat').toString(),
                    value: 'vat',
                    align: 'end',
                    sortable: true,
                    groupable: false,
                    class:'text-no-wrap'
                } as DataTableHeader,
            ];
        }
        get salesInvoicesSummarizers() {
            return [
                {
                    value:'total',
                    summarize: (items: SalesInvoice[] = []) => { 
                        let totals = [];
                        totals = items.map((item: SalesInvoice) => item.total && item.recordType ? this.signAmount(item.total, item.recordType) : 0)
                        return totals.length > 0 ? totals.reduce((prev: number, curr: number) => { return prev+curr }) : 0
                    },
                }
            ]
        }
        get salesInvoicesFormatters() {
            return [
                {
                    value: 'total',
                    format: (value: number) => {
                        return this.$options.filters !== undefined ? this.$options.filters.formatMoney(value) : null
                    }
                }
            ]
        }
        
        

        // setters

        // watchers

        // lifecycle
    }
