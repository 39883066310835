
import { Component, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { namespace } from 'vuex-class';

import { Actions, Getters } from '@/modules/settings/store/modules/workflow/types/StoreTypes';
import { WorkflowFunctionGroup, WorkflowTask, WorkflowTaskStatus } from '@/modules/workflow/types/entities';
import TaskStatusModalComponent
	from '@/modules/settings/views/workflow/components/task-status-modal/task-status-modal.component.vue';
import { LogItem } from '@/modules/settings/types/entities';
import DeletePopup from '../../../../core/components/delete-popup/delete-popup.component.vue';
import FunctionGroupModalComponent
	from '@/modules/settings/views/workflow/components/function-group-modal/function-group-modal.component.vue';
import WorkflowSettingsTasksListComponent
	from '@/modules/settings/views/workflow/components/tasks-list/workflow-settings-tasks-list.component.vue';
import WorkflowSettingsTaskStatusesListComponent
	from '@/modules/settings/views/workflow/components/task-statuses-list/workflow-settings-task-statuses-list.component.vue';
import WorkflowSettingsFunctionGroupsListComponent
	from '@/modules/settings/views/workflow/components/function-groups-list/workflow-settings-function-groups-list.component.vue';
import TaskModalComponent from '@/modules/settings/views/workflow/components/task-modal/task-modal.component.vue';
import { taskStatusIcon, groupColour, statusColour } from './helpers/workflow-helpers';

const symbols = require.context('@/assets/settings/workflow/task-statuses', false, /\.svg$/);
const workflowSettings = namespace('settings/workflow');

@Component({
	components: {
		DeletePopup,
		draggable,
		'task-modal': TaskModalComponent,
		'task-status-modal': TaskStatusModalComponent,
		'function-group-modal': FunctionGroupModalComponent,
		'tasks-list': WorkflowSettingsTasksListComponent,
		'task-statuses-list': WorkflowSettingsTaskStatusesListComponent,
		'function-groups-list': WorkflowSettingsFunctionGroupsListComponent
	}
})
export default class WorkflowSettingsComponent extends Vue {
	@workflowSettings.Action(Actions.FETCH_WORKFLOW_TASKS) fetchAllTasks!: () => Promise<void>;
	@workflowSettings.Action(Actions.FETCH_WORKFLOW_TASK_STATUSES) fetchAllTaskStatuses!: () => void;
	@workflowSettings.Action(Actions.FETCH_WORKFLOW_FUNCTION_GROUPS) fetchAllFunctionGroups!: () => void;
	@workflowSettings.Action(Actions.FETCH_HIGHEST_TASK_SEQUENCE) fetchHighestWorkflowTaskSequence!: () => Promise<void>;
	@workflowSettings.Action(Actions.FETCH_NEXT_PAGE) fetchNextPage!: (activeList: string) => void;
	@workflowSettings.Action(Actions.DELETE_WORKFLOW_TASK_BY_ID) deleteWorkflowTaskById!: (workflowTask: WorkflowTask) => void;
	@workflowSettings.Action(Actions.DELETE_MULTI_WORKFLOW_TASK_BY_ID) deleteMultiWorkflowTaskById!: (payload: number[]) => void;
	@workflowSettings.Action(Actions.ARCHIVE_WORKFLOW_TASK_BY_ID) archiveWorkflowTaskById!: (payload: { workflowTask: WorkflowTask; archive: boolean }) => void;
	@workflowSettings.Action(Actions.ARCHIVE_MULTI_WORKFLOW_TASK_BY_ID) archiveMultiWorkflowTaskById!: (payload: number[]) => void;
	@workflowSettings.Action(Actions.UN_ARCHIVE_MULTI_WORKFLOW_TASK_BY_ID) unArchiveMultiWorkflowTaskById !: (payload: number[]) => void;
	@workflowSettings.Action(Actions.DELETE_WORKFLOW_TASK_STATUS_BY_ID) deleteWorkflowTaskStatusById!: (workflowTaskStatus: WorkflowTaskStatus) => void;
	@workflowSettings.Action(Actions.DELETE_WORKFLOW_FUNCTION_GROUP_BY_ID) deleteWorkflowFunctionGroupById!: (workflowFunctionGroup: WorkflowFunctionGroup) => void;
	@workflowSettings.Action(Actions.EDIT_WORKFLOW_TASK_BY_ID) editWorkflowTaskById!: (payload: WorkflowTask) => void;
	@workflowSettings.Action(Actions.EDIT_WORKFLOW_FUNCTION_GROUP_BY_ID) editWorkflowFunctionGroupById!: (payload: WorkflowFunctionGroup) => void;
	@workflowSettings.Action(Actions.EDIT_WORKFLOW_TASK_STATUS_BY_ID) editWorkflowTaskStatusById!: (payload: WorkflowTaskStatus) => void;
	@workflowSettings.Action(Actions.CREATE_NEW_WORKFLOW_TASK) createNewWorkflowTask!: (payload: WorkflowTask) => void;
	@workflowSettings.Action(Actions.CREATE_NEW_WORKFLOW_TASK_STATUS) createNewWorkflowTaskStatus!: (payload: { status: string; symbol: string; description: string; colour: string }) => void;
	@workflowSettings.Action(Actions.CREATE_NEW_WORKFLOW_FUNCTION_GROUP) createNewWorkflowFunctionGroup!: (payload: WorkflowFunctionGroup) => void;
	@workflowSettings.Action(Actions.REVERT_LAST_ACTION) revertLastAction!: (payload?: LogItem) => void;
	@workflowSettings.Action(Actions.SET_WORKFLOW_FILTERS_SORT_BY_SEQUENCE) setWorkflowSettingsFiltersSortBySequenceOrder!: () => Promise<void>;
	@workflowSettings.Action(Actions.SET_WORKFLOW_FILTERS_SORT_BY_ARCHIVED_AT) setWorkflowSettingsFiltersSortByArchivedAtOrder!: () => Promise<void>;
	@workflowSettings.Action(Actions.SET_WORKFLOW_FILTERS_FETCH_ARCHIVED) setWorkflowSettingsFiltersFetchArchived!: (payload: boolean) => Promise<void>;
	@workflowSettings.Action(Actions.CHANGE_WORKFLOW_TASK_SEQUENCE) changeWorkflowTaskSequence!: (payload: WorkflowTask) => void;

	@workflowSettings.Getter(Getters.GET_WORKFLOW_TASKS) tasks!: WorkflowTask[];
	@workflowSettings.Getter(Getters.GET_WORKFLOW_TASK_STATUSES) taskStatuses!: WorkflowTaskStatus[];
	@workflowSettings.Getter(Getters.GET_WORKFLOW_FUNCTION_GROUPS) functionGroups!: WorkflowFunctionGroup[];
	@workflowSettings.Getter(Getters.GET_WORKFLOW_INFO_MESSAGE) infoMessage!: { text: string; isVisible: boolean; buttonVisible: boolean };
	@workflowSettings.Getter(Getters.GET_WORKFLOW_ACTIVITY_LOG) activityLog!: { lastItem?: WorkflowTaskStatus; lastBackendResponse?: LogItem };
	@workflowSettings.Getter(Getters.GET_WORKFLOW_HIGHEST_TASK_SEQUENCE) highestWorkflowTaskSequence!: number;
	@workflowSettings.Getter(Getters.GET_WORKFLOW_FILTERS) workflowSettingsFilters!: { fetchArchived: boolean ; sortByArchivedAt: string; sortBySequence: string };

	tickedTasksList: number[] = [];
	expandedTasksList: number[] = [];

	workflowSettingsState = {
		activeList: 'tasks',
		createSubTask: false,
		showTasksModal: false,
		showTaskStatusesModal: false,
		showFunctionGroupsModal: false,
		archiveTask: false,
		archiveTasksMulti: false,
		unarchiveTask: false,
		unArchiveTasksMulti: false,
		deleteTask: false,
		deleteTasksMulti: false,
		deleteTaskStatus: false,
		deleteFunctionGroup: false,
		currentTitleTranslationPath: 'settings.workflow.tasks.title',
		currentAddButtonTranslationPath: 'settings.workflow.tasks.addTask'
	};

	workflowSettingsContent: {
		taskStatus: WorkflowTaskStatus | null;
		functionGroup: WorkflowFunctionGroup | null;
		task: WorkflowTask | null;
		subTasks: WorkflowTask[] | null;
		highestWorkflowTaskSequence: number | null;
	} = {
		taskStatus: null,
		functionGroup: null,
		task: null,
		subTasks: null,
		highestWorkflowTaskSequence: null
	};

	localTasks: WorkflowTask[] = [];

	fetchWorkflowSettingsList(listType: string) {
		this.workflowSettingsState.activeList = listType;
		switch (listType) {
			case 'tasks':
				this.fetchAllTasks();
				this.workflowSettingsState.currentTitleTranslationPath = 'settings.workflow.tasks.title';
				this.workflowSettingsState.currentAddButtonTranslationPath = 'settings.workflow.tasks.addTask';
				break;
			case 'taskStatuses':
				this.workflowSettingsState.currentTitleTranslationPath = 'settings.workflow.taskStatuses.title';
				this.workflowSettingsState.currentAddButtonTranslationPath = 'settings.workflow.taskStatuses.addTaskStatus';
				if (this.taskStatuses.length <= 0) {
					this.fetchAllTaskStatuses();
				}
				break;
			case 'functionGroups':
				this.workflowSettingsState.currentTitleTranslationPath = 'settings.workflow.functionGroups.title';
				this.workflowSettingsState.currentAddButtonTranslationPath = 'settings.workflow.functionGroups.addFunctionGroup';
				if (this.functionGroups.length <= 0) {
					this.fetchAllFunctionGroups();
				}
				break;
			default:
				break;
		}
	}

	getTaskStatusIcon(taskStatusSymbol: string) {
		return taskStatusIcon(taskStatusSymbol);
	}

	getFunctionGroupColour(functionGroupColour: string) {
		return groupColour(functionGroupColour);
	}

	getTaskStatusColour(taskStatusColor: string) {
		return statusColour(taskStatusColor);
	}

	openCreationModal() {
		this.resetWorkflowDialogContent();
		if (this.workflowSettingsState.activeList === 'tasks') {
			this.openTasksModal();
		} else if (this.workflowSettingsState.activeList === 'taskStatuses') {
			this.workflowSettingsState.showTaskStatusesModal = true;
		} else {
			this.workflowSettingsState.showFunctionGroupsModal = true;
		}
	}

	openTasksModal(task?: WorkflowTask, hierarchy?: string, childTaskSequence?: number): void {
		this.resetWorkflowDialogContent();
		if (!this.functionGroups.length) {
			this.fetchAllFunctionGroups();
		}

		if (task) {
			if (hierarchy) {
				if (childTaskSequence) {
					this.workflowSettingsState.createSubTask = true;
					this.workflowSettingsContent.subTasks = [{
						id: 0,
						name: '',
						sequence: childTaskSequence,
						description: '',
						parentTaskId: 0,
						archivedAt: null
					},];
					this.workflowSettingsContent.task = JSON.parse(JSON.stringify(task));
					this.workflowSettingsState.showTasksModal = true;
				} else {
					switch (hierarchy) {
						case 'up':
							if (this.workflowSettingsFilters.sortBySequence === 'desc') {
								this.workflowSettingsContent.highestWorkflowTaskSequence = task.sequence! + 1;
								this.workflowSettingsState.showTasksModal = true;
							} else {
								this.workflowSettingsContent.highestWorkflowTaskSequence = task.sequence!;
								this.workflowSettingsState.showTasksModal = true;
							}
							break;
						case 'down':
							if (this.workflowSettingsFilters.sortBySequence === 'desc') {
								this.workflowSettingsContent.highestWorkflowTaskSequence = task.sequence!;
								this.workflowSettingsState.showTasksModal = true;
							} else {
								this.workflowSettingsContent.highestWorkflowTaskSequence = task.sequence! +1 ;
								this.workflowSettingsState.showTasksModal = true;
							}
							break;
						default:
							break;
					}
				}
			} else {
				this.workflowSettingsContent.task = JSON.parse(JSON.stringify(task));
				this.workflowSettingsState.showTasksModal = true;
			}
		} else {
			this.fetchHighestWorkflowTaskSequence().then(() => {
				this.workflowSettingsContent.highestWorkflowTaskSequence = this.highestWorkflowTaskSequence;
				this.workflowSettingsState.showTasksModal = true;
			});
		}
	}

	showOngoingTasks() {
		this.setWorkflowSettingsFiltersFetchArchived(false).then(() => {
			this.fetchAllTasks();
		});
	}

	showArchivedTasks() {
		this.setWorkflowSettingsFiltersFetchArchived(true).then(() => {
			this.fetchAllTasks();
		});
	}

	onSaveTask(task: WorkflowTask) {
		this.workflowSettingsState.createSubTask = false;
		this.workflowSettingsState.showTasksModal = false;
		if (task.id !== 0) {
			this.editWorkflowTaskById(task);
		} else {
			this.createNewWorkflowTask(task);
		}
	}

	onDeleteTask(task: WorkflowTask) {
		this.resetWorkflowDialogContent();
		this.workflowSettingsContent.task = task;
		this.workflowSettingsState.deleteTask = true;
	}

	deleteWorkflowTask() {
		if (this.workflowSettingsContent.task && this.workflowSettingsContent.task.id !== 0) {
			this.deleteWorkflowTaskById(this.workflowSettingsContent.task);
		}
	}

	onArchiveTask(task: WorkflowTask) {
		this.resetWorkflowDialogContent();
		this.workflowSettingsContent.task = task;
		this.workflowSettingsState.archiveTask = true;
	}

	archiveWorkflowTask() {
		if (this.workflowSettingsContent.task && this.workflowSettingsContent.task.id !== 0) {
			this.archiveWorkflowTaskById({
				workflowTask: this.workflowSettingsContent.task,
				archive: true
			});
		}
	}

	onUnarchiveTask(task: WorkflowTask) {
		this.resetWorkflowDialogContent();
		this.workflowSettingsContent.task = task;
		this.workflowSettingsState.unarchiveTask = true;
	}

	unArchiveWorkflowTask() {
		if (this.workflowSettingsContent.task && this.workflowSettingsContent.task.id !== 0) {
			this.archiveWorkflowTaskById({
				workflowTask: this.workflowSettingsContent.task,
				archive: false
			});
		}
	}

	onDeleteTaskStatus(taskStatus: WorkflowTaskStatus) {
		this.resetWorkflowDialogContent();
		this.workflowSettingsContent.taskStatus = taskStatus;
		this.workflowSettingsState.deleteTaskStatus = true;
	}

	deleteWorkflowTaskStatus() {
		if (this.workflowSettingsContent.taskStatus && this.workflowSettingsContent.taskStatus.id !== 0) {
			this.deleteWorkflowTaskStatusById(this.workflowSettingsContent.taskStatus);
		}
	}

	onDeleteFunctionGroup(functionGroup: WorkflowFunctionGroup) {
		this.resetWorkflowDialogContent();
		this.workflowSettingsContent.functionGroup = functionGroup;
		this.workflowSettingsState.deleteFunctionGroup = true;
	}

	deleteWorkflowFunctionGroup() {
		if (this.workflowSettingsContent.functionGroup && this.workflowSettingsContent.functionGroup.id !== 0) {
			this.deleteWorkflowFunctionGroupById(this.workflowSettingsContent.functionGroup);
		}
	}

	onEditTaskStatus(taskStatus: WorkflowTaskStatus) {
		this.resetWorkflowDialogContent();
		this.workflowSettingsState.showTaskStatusesModal = true;
		this.workflowSettingsContent.taskStatus = taskStatus;
	}

	onSaveTaskStatus(value: { status: string; symbol: string; description: string; colour: string }) {
		if (this.workflowSettingsContent.taskStatus && this.workflowSettingsContent.taskStatus.id !== 0) {
			this.editWorkflowTaskStatusById({
				id: this.workflowSettingsContent.taskStatus.id,
				status: value.status,
				symbol: value.symbol,
				description: value.description,
				color: value.colour
			});
		} else {
			this.createNewWorkflowTaskStatus({
				status: value.status,
				symbol: value.symbol,
				description: value.description,
				colour: value.colour
			});
		}
	}

	onEditFunctionGroup(functionGroup: WorkflowFunctionGroup) {
		this.resetWorkflowDialogContent();
		this.workflowSettingsState.showFunctionGroupsModal = true;
		this.workflowSettingsContent.functionGroup = functionGroup;
	}

	onSaveFunctionGroup(value: { name: string; description: string; color: string }) {
		if (this.workflowSettingsContent.functionGroup && this.workflowSettingsContent.functionGroup.id !== 0) {
			this.editWorkflowFunctionGroupById({
				id: this.workflowSettingsContent.functionGroup.id,
				name: value.name,
				description: value.description,
				color: value.color
			});
		} else {
			this.createNewWorkflowFunctionGroup({
				id: 0,
				name: value.name,
				description: value.description,
				color: value.color
			});
		}
	}

	onEditTask(task: WorkflowTask): void {
		this.openTasksModal(task);
	}

	onAddTask(data: { task: WorkflowTask; hierarchy: string; childTaskSequence?: number}): void {
		this.openTasksModal(data.task, data.hierarchy, data.childTaskSequence);
	}

	onAddSubTask(task: WorkflowTask): void {
		let workflowTask: WorkflowTask;
		if (task.children) {
			let childrenHighestSequence = Math.max.apply(Math, [...task.children].map((childTask) => {
				return childTask.sequence!;
			}));
			childrenHighestSequence += 1;
			workflowTask = {
				...task,
				children: [
					...task.children,
					{
						id: 0,
						name: '',
						description: '',
						sequence: childrenHighestSequence,
						parentTaskId: task.id,
						archivedAt: null
					}
				]
			}
		} else {
			workflowTask = {
				...task,
				children: [{
					id: 0,
					name: '',
					description: '',
					sequence: 1,
					parentTaskId: task.id,
					archivedAt: null
				}]
			}
		}
		this.openTasksModal(workflowTask);
	}

	loadNextPage() {
		this.fetchNextPage(this.workflowSettingsState.activeList);
	}

	resetWorkflowDialogContent() {
		this.workflowSettingsContent = {
			taskStatus: null,
			functionGroup: null,
			task: null,
			subTasks: null,
			highestWorkflowTaskSequence: null
		};
	}

	get isAnyTaskTicked() {
		return this.tickedTasksList.length > 0;
	}

	tickAllTasks() {
		if (this.isAnyTaskTicked) {
			this.tickedTasksList = [];
		} else {
			this.localTasks.forEach((task: WorkflowTask) => {
				this.tickedTasksList.push(task.id);
			});
		}
	}

	onDeleteMultipleWorkflowTasks() {
		if (this.isAnyTaskTicked) {
			this.workflowSettingsState.deleteTasksMulti = true;
		}
	}

	onArchiveMultipleWorkflowTasks() {
		if (this.isAnyTaskTicked) {
			this.workflowSettingsState.archiveTasksMulti = true;
		}
	}

	onUnArchiveMultipleWorkflowTasks() {
		if (this.isAnyTaskTicked) {
			this.workflowSettingsState.unArchiveTasksMulti = true;
		}
	}

	onSortByArchivedAt() {
		this.setWorkflowSettingsFiltersSortByArchivedAtOrder();
	}

	onSortBySequence() {
		this.setWorkflowSettingsFiltersSortBySequenceOrder().then(() => {
			this.fetchAllTasks()
		});
	}

	onExpandAllTasks() {
		if (this.expandedTasksList.length > 0) {
			this.expandedTasksList = [];
		} else {
			this.localTasks.forEach((task: WorkflowTask) => {
				this.expandedTasksList.push(task.id);
			})
		}
	}

	onDragEnd(event: { moved: { element: WorkflowTask; newIndex: number; oldIndex: number } }) {
		const workflowTask: WorkflowTask = JSON.parse(JSON.stringify(event.moved.element));
		if (workflowTask.parentTaskId !== null) {
			workflowTask.sequence = event.moved.newIndex + 1;
		} else {
			if (this.workflowSettingsFilters.sortBySequence === 'desc') {
				workflowTask.sequence = this.localTasks[event.moved.oldIndex].sequence;
			} else {
				workflowTask.sequence = event.moved.newIndex + 1;
			}
		}
		this.changeWorkflowTaskSequence(workflowTask);
	}


	created() {
		this.fetchAllTasks().then(() => {
			this.localTasks = JSON.parse(JSON.stringify(this.tasks));
		});
	}

	@Watch('tasks')
	onTaskStateChange(val: WorkflowTask[]) {
		this.tickedTasksList = [];
		this.localTasks = JSON.parse(JSON.stringify(val));
	}
}

