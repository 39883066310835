export const enum Actions {
	FETCH_LISTVIEW_INVOICES = 'FETCH_LISTVIEW_INVOICES',
	FETCH_LISTVIEW_NEXT_PAGE = 'FETCH_LISTVIEW_NEXT_PAGE',
	FETCH_STATUSES = 'FETCH_STATUSES',
	FETCH_APPROVAL_STATUSES = 'FETCH_APPROVAL_STATUSES',
	FETCH_PROJECT_RELATIONSHIP_STATUSES = 'FETCH_PROJECT_RELATIONSHIP_STATUSES',
	FETCH_PAYMENT_STATUSES = 'FETCH_PAYMENT_STATUSES',
	FETCH_ACCOUNTING_STATUSES = 'FETCH_ACCOUNTING_STATUSES',
	FETCH_INVOICE_BY_ID = 'FETCH_INVOICE_BY_ID',
	FETCH_RECORD_TYPES = 'FETCH_RECORD_TYPES',
	FETCH_NEW = 'FETCH_NEW',
	SAVE_INVOICE = 'SAVE_INVOICE',
	APPEND_TO_INVOICES = 'APPEND_TO_INVOICES',
	DELETE_INVOICE = 'DELETE_INVOICE',
	SAVE_ACCOUNTING_ITEM = 'SAVE_ACCOUNTING_ITEM',
	CREATE_INVOICE = 'CREATE_INVOICE',
	DELETE_ACCOUNTING_ITEM = 'DELETE_ACCOUNTING_ITEM',
	FETCH_ACCOUNTING_ITEM_BY_ID = 'FETCH_ACCOUNTING_ITEM_BY_ID',
	FETCH_PROJECT_RELATIONSHIP_BY_ID = 'FETCH_PROJECT_RELATIONSHIP_BY_ID',
	SAVE_PROJECT_RELATIONSHIP = 'SAVE_PROJECT_RELATIONSHIP',
	DELETE_PROJECT_RELATIONSHIP = 'DELETE_PROJECT_RELATIONSHIP',
	SAVE_COMMENT_TO_PURCHASE_INVOICE = 'SAVE_COMMENT_TO_PURCHASE_INVOICE',
	DELETE_COMMENT = 'DELETE_COMMENT',
	FETCH_MEDIA_ANNOTATIONS_BY_MEDIA_ID = 'FETCH_MEDIA_ANNOTATIONS_BY_MEDIA_ID',
	FETCH_MEDIA_ANNOTATION_FOR_INVOICE_PDF = 'FETCH_MEDIA_ANNOTATION_FOR_INVOICE_PDF',
	FETCH_MEDIA_ANNOTATION_FOR_PROJECT_RELATIONSHIP = 'FETCH_MEDIA_ANNOTATION_FOR_PROJECT_RELATIONSHIP',
	SAVE_MEDIA_ANNOTATION = 'SAVE_MEDIA_ANNOTATION',
	SAVE_PAYMENT = 'SAVE_PAYMENT',
	SAVE_PAYMENT_ON_LISTVIEW_INVOICES = 'SAVE_PAYMENT_ON_LISTVIEW_INVOICES',
	DELETE_PAYMENT = 'DELETE_PAYMENT',

	// summary reports
	FETCH_INVOICES_SUMMARY_REPORT = 'FETCH_INVOICES_SUMMARY_REPORT',
	TOGGLE_SHOW_INVOICES_SUMMARY_REPORT = 'TOGGLE_SHOW_INVOICES_SUMMARY_REPORT',
	FETCH_INVOICES_METRICS_REPORT = 'FETCH_INVOICES_METRICS_REPORT',
	TOGGLE_SHOW_INVOICES_METRICS_REPORT = 'TOGGLE_SHOW_INVOICES_METRICS_REPORT',

	// media interaction
	UPLOAD_PDF = 'UPLOAD_PDF',
	DELETE_PDF = 'DELETE_PDF',

	// search
	SEARCH_PROJECTS = 'SEARCH_PROJECTS',
	SEARCH_SUPPLIERS = 'SEARCH_SUPPLIERS',

	// listview filters
	SET_LISTVIEW_FILTER_FOR_SEARCH = 'SET_LISTVIEW_FILTER_FOR_SEARCH',
	CLEAR_LISTVIEW_FILTERS = 'CLEAR_LISTVIEW_FILTERS',
	TOGGLE_LISTVIEW_FILTER_SIDEBAR = 'TOGGLE_LISTVIEW_FILTER_SIDEBAR',
	TOGGLE_LISTVIEW_FILTER_GROUP = 'TOGGLE_LISTVIEW_FILTER_GROUP',
	TOGGLE_LISTVIEW_FILTER = 'TOGGLE_LISTVIEW_FILTER',
	SET_LISTVIEW_FILTER_ON_STATUS = 'SET_LISTVIEW_FILTER_ON_STATUS',
	SET_LISTVIEW_FILTER_ON_PAYMENT_STATUS = 'SET_LISTVIEW_FILTER_ON_PAYMENT_STATUS',
	SET_LISTVIEW_FILTER_ON_APPROVAL_STATUS = 'SET_LISTVIEW_FILTER_ON_APPROVAL_STATUS',
	SET_LISTVIEW_FILTER_ON_ACCOUNTING_STATUS = 'SET_LISTVIEW_FILTER_ON_ACCOUNTING_STATUS',
	SET_LISTVIEW_FILTER_ON_DUE_DATE_FROM = 'SET_LISTVIEW_FILTER_ON_DUE_DATE_FROM',
	SET_LISTVIEW_FILTER_ON_DUE_DATE_TO = 'SET_LISTVIEW_FILTER_ON_DUE_DATE_TO',
	SET_LISTVIEW_FILTER_ON_INVOICE_DATE_FROM = 'SET_LISTVIEW_FILTER_ON_INVOICE_DATE_FROM',
	SET_LISTVIEW_FILTER_ON_INVOICE_DATE_TO = 'SET_LISTVIEW_FILTER_ON_INVOICE_DATE_TO',
	SET_LISTVIEW_FILTER_ON_SUPPLIER = 'SET_LISTVIEW_FILTER_ON_SUPPLIER',
	SET_LISTVIEW_FILTER_ON_PROJECT_LEAD = 'SET_LISTVIEW_FILTER_ON_PROJECT_LEAD',
	SET_LISTVIEW_FILTER_ON_PAYMENT_DISCOUNT_DUE_DATE_FROM = 'SET_LISTVIEW_FILTER_ON_PAYMENT_DISCOUNT_DUE_DATE_FROM',
	SET_LISTVIEW_FILTER_ON_PAYMENT_DISCOUNT_DUE_DATE_TO = 'SET_LISTVIEW_FILTER_ON_PAYMENT_DISCOUNT_DUE_DATE_TO',
}

export const enum Getters {
	GET_LISTVIEW_FILTER_STATE = 'GET_LISTVIEW_FILTER_STATE',
	GET_LISTVIEW_INVOICES = 'GET_LISTVIEW_INVOICES',
	GET_LISTVIEW_IS_LOADING = 'GET_LISTVIEW_IS_LOADING',
	GET_IS_LOADING = 'GET_IS_LOADING',
	GET_STATUSES = 'GET_STATUSES',
	GET_STATUSES_TRANSLATED = 'GET_STATUSES_TRANSLATED',
	GET_APPROVAL_STATUSES = 'GET_APPROVAL_STATUSES',
	GET_APPROVAL_STATUSES_TRANSLATED = 'GET_APPROVAL_STATUSES_TRANSLATED',
	GET_PROJECT_RELATIONSHIP_STATUSES = 'GET_PROJECT_RELATIONSHIP_STATUSES',
	GET_PROJECT_RELATIONSHIP_STATUSES_TRANSLATED = 'GET_PROJECT_RELATIONSHIP_STATUSES_TRANSLATED',
	GET_PAYMENT_STATUSES = 'GET_PAYMENT_STATUSES',
	GET_PAYMENT_STATUSES_TRANSLATED = 'GET_PAYMENT_STATUSES_TRANSLATED',
	GET_ACCOUNTING_STATUSES = 'GET_ACCOUNTING_STATUSES',
	GET_ACCOUNTING_STATUSES_TRANSLATED = 'GET_ACCOUNTING_STATUSES_TRANSLATED',
	GET_INVOICE = 'GET_INVOICE',
	GET_RECORD_TYPES = 'GET_RECORD_TYPES',
	GET_RECORD_TYPES_TRANSLATED = 'GET_RECORD_TYPES_TRANSLATED',
	GET_NEW = 'GET_NEW',
	GET_PROJECT_RELATIONSHIP = 'GET_PROJECT_RELATIONSHIP',
	GET_PROJECT_RELATIONSHIP_VALIDATION = 'GET_PROJECT_RELATIONSHIP_VALIDATION',
	GET_LISTVIEW_FILTER_SUPPLIER_MODEL = 'GET_LISTVIEW_FILTER_SUPPLIER_MODEL',

	// reporting
	GET_INVOICES_SUMMARY_REPORT = 'GET_INVOICES_SUMMARY_REPORT',
	GET_INVOICES_METRICS_REPORT = 'GET_INVOICES_METRICS_REPORT',
	GET_SHOW_INVOICES_SUMMARY_REPORT = 'GET_SHOW_INVOICES_SUMMARY_REPORT',
	GET_SHOW_INVOICES_METRICS_REPORT = 'GET_SHOW_INVOICES_METRICS_REPORT',

	// search
	GET_SEARCH_PROJECTS = 'GET_SEARCH_PROJECTS',
	GET_SEARCH_SUPPLIERS = 'GET_SEARCH_SUPPLIERS',
}

export const enum Mutations {
	MUTATE_LISTVIEW_IS_LOADING = 'MUTATE_LISTVIEW_IS_LOADING',
	MUTATE_IS_LOADING = 'MUTATE_IS_LOADING',
	MUTATE_LISTVIEW_INVOICES = 'MUTATE_LISTVIEW_INVOICES',
	MUTATE_LISTVIEW_LINKS = 'MUTATE_LISTVIEW_LINKS',
	MUTATE_LISTVIEW_META = 'MUTATE_LISTVIEW_META',
	MUTATE_LISTVIEW_PUSH_NEXT_PAGE = 'MUTATE_LISTVIEW_PUSH_NEXT_PAGE',
	MUTATE_STATUSES = 'MUTATE_STATUSES',
	MUTATE_APPROVAL_STATUSES = 'MUTATE_APPROVAL_STATUSES',
	MUTATE_PAYMENT_STATUSES = 'MUTATE_PAYMENT_STATUSES',
	MUTATE_ACCOUNTING_STATUSES = 'MUTATE_ACCOUNTING_STATUSES',
	MUTATE_INVOICE = 'MUTATE_INVOICE',
	MUTATE_RECORD_TYPES = 'MUTATE_RECORD_TYPES',
	MUTATE_SUPPLIERS = 'MUTATE_SUPPLIERS',
	MUTATE_NEW = 'MUTATE_NEW',
	MUTATE_APPEND_TO_INVOICES = 'MUTATE_APPEND_TO_INVOICES',
	MUTATE_PROJECT_RELATIONSHIP_STATUSES = 'MUTATE_PROJECT_RELATIONSHIP_STATUSES',
	MUTATE_MEDIA_ANNOTATIONS = 'MUTATE_MEDIA_ANNOTATION',

	// reporting
	MUTATE_INVOICES_SUMMARY_REPORT = 'MUTATE_INVOICES_SUMMARY_REPORT',
	MUTATE_INVOICES_METRICS_REPORT = 'MUTATE_INVOICES_METRICS_REPORT',
	MUTATE_INVOICES_SHOW_SUMMARY_REPORT = 'MUTATE_INVOICES_SHOW_SUMMARY_REPORT',
	MUTATE_INVOICES_SHOW_METRICS_REPORT = 'MUTATE_INVOICES_SHOW_METRICS_REPORT',

	// search
	MUTATE_SEARCH_PROJECTS = 'MUTATE_SEARCH_PROJECTS',
	MUTATE_SEARCH_SUPPLIERS = 'MUTATE_SEARCH_SUPPLIERS',

	// details filters


	// listview filters
	MUTATE_TOGGLE_LISTVIEW_FILTER_SIDEBAR = 'MUTATE_TOGGLE_LISTVIEW_FILTER_SIDEBAR',
	MUTATE_TOGGLE_LISTVIEW_FILTER_GROUP = 'MUTATE_TOGGLE_LISTVIEW_FILTER_GROUP',
	MUTATE_TOGGLE_LISTVIEW_FILTER = 'MUTATE_TOGGLE_LISTVIEW_FILTER',
	MUTATE_CLEAR_LISTVIEW_FILTERS = 'MUTATE_CLEAR_LISTVIEW_FILTERS',
	MUTATE_LISTVIEW_FILTER_STATUS = 'MUTATE_LISTVIEW_FILTER_STATUS',
	MUTATE_LISTVIEW_FILTER_PAYMENT_STATUS = 'MUTATE_LISTVIEW_FILTER_PAYMENT_STATUS',
	MUTATE_LISTVIEW_FILTER_APPROVAL_STATUS = 'MUTATE_LISTVIEW_FILTER_APPROVAL_STATUS',
	MUTATE_LISTVIEW_FILTER_ACCOUNTING_STATUS = 'MUTATE_LISTVIEW_FILTER_ACCOUNTING_STATUS',
	MUTATE_LISTVIEW_FILTER_DUE_DATE_FROM = 'MUTATE_LISTVIEW_FILTER_DUE_DATE_FROM',
	MUTATE_LISTVIEW_FILTER_DUE_DATE_TO = 'MUTATE_LISTVIEW_FILTER_DUE_DATE_TO',
	MUTATE_LISTVIEW_FILTER_INVOICE_DATE_FROM = 'MUTATE_LISTVIEW_FILTER_INVOICE_DATE_FROM',
	MUTATE_LISTVIEW_FILTER_INVOICE_DATE_TO = 'MUTATE_LISTVIEW_FILTER_INVOICE_DATE_TO',
	MUTATE_LISTVIEW_FILTER_FOR_SEARCH = 'MUTATE_LISTVIEW_FILTER_FOR_SEARCH',
	MUTATE_LISTVIEW_FILTER_SUPPLIER_MODEL = 'MUTATE_LISTVIEW_FILTER_SUPPLIER_MODEL',
	MUTATE_LISTVIEW_FILTER_SUPPLIER = 'MUTATE_LISTVIEW_FILTER_SUPPLIER',
	MUTATE_LISTVIEW_FILTER_PROJECT_LEAD = 'MUTATE_LISTVIEW_FILTER_PROJECT_LEAD',
	MUTATE_LISTVIEW_FILTER_PAYMENT_DISCOUNT_DUE_DATE_FROM = 'MUTATE_LISTVIEW_FILTER_PAYMENT_DISCOUNT_DUE_DATE_FROM',
	MUTATE_LISTVIEW_FILTER_PAYMENT_DISCOUNT_DUE_DATE_TO = 'MUTATE_LISTVIEW_FILTER_PAYMENT_DISCOUNT_DUE_DATE_TO',
}
