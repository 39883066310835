
	import { PropType } from 'vue';
	import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { requiredIf } from 'vuelidate/lib/validators';
	import { PurchaseInvoice, ProjectRelationship, ProjectRelationshipStatus, ProjectRelationshipEntity, MediaAnnotatatable, TransactionType } from '@/modules/purchase-invoices/types/entities';
	import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
	import { AxiosError, AxiosResponse } from 'axios';
	import ProjectRelationshipDetailsComponent from '@/modules/purchase-invoices/components/project-relationship-details/project-relationship-details.component.vue'
	import { MediaAnnotation, User, UserEntity } from '@/modules/entities/types/entities';
	import { UsersService } from '@/services/users.service';
	import { JsonResource } from '@/core/types/Entities';
	import { validationMixin } from 'vuelidate';
	import { isAccountingLedgerItemRequiredValidator, isContractorTypeRequiredValidator, isProjectRequiredValidator } from '@/modules/purchase-invoices/validators/project-relationship.validator'
	import UserAutocompleteComponent from '@/modules/purchase-invoices/components/user-autocomplete/user-autocomplete.component.vue';
	import AssignToListComponent from '@/modules/purchase-invoices/components/project-relationship-assign-to/project-relationship-assign-to-list.component.vue';
	import PdfAnnotateComponent from '@/modules/purchase-invoices/components/pdf-annotate/pdf-annotate.component.vue';
	import _ from 'lodash';
	import { signAmountProjectRelationship } from '../../helpers';
	import PurchaseInvoiceCommentsComponent from '@/modules/purchase-invoices/components/purchase-invoice-comments/purchase-invoice-comments.component.vue';
    import {AccountingLedger} from "@/modules/accounting/types/entities";
    import authStore from "@/modules/login/store";

	const purchaseInvoices = namespace('purchaseInvoices');

	const usersService = new UsersService();

	@Component({
		mixins: [validationMixin],
		validations: {
			projectRelationshipSync: {
				project: { required: requiredIf(vm => isProjectRequiredValidator(vm)) },
				contractorType: { required: requiredIf(vm => isContractorTypeRequiredValidator(vm)) },
				accountingLedgerItem: { required: requiredIf(vm => isAccountingLedgerItemRequiredValidator(vm)) },
			}
		},
		components: {
			'project-relationship-details': ProjectRelationshipDetailsComponent,
			'pdf-annotate': PdfAnnotateComponent,
			'user-autocomplete': UserAutocompleteComponent,
			'assign-to-list': AssignToListComponent,
			'purchase-invoice-comments': PurchaseInvoiceCommentsComponent,
		},
      props: {
        currentAccountingLedger: {
          type: Object as PropType<AccountingLedger|undefined>,
          default: undefined
        }
      },
      setup(props, ctx) {

      }
	})
	export default class ProjectRelationshipUpdateModalComponent extends Vue {

		// vuex actions
		@purchaseInvoices.Action(Actions.SAVE_PROJECT_RELATIONSHIP) saveProjectRelationship!: (payload: ProjectRelationship) => Promise<AxiosResponse<JsonResource<ProjectRelationship>>>;
		@purchaseInvoices.Action(Actions.FETCH_PROJECT_RELATIONSHIP_STATUSES) fetchProjectRelationshipStatuses!: () => Promise<void>;
		@purchaseInvoices.Action(Actions.SAVE_MEDIA_ANNOTATION) saveMediaAnnotation!: (payload: {mediaAnnotation: MediaAnnotation; mediaId: string; annotatableId: string; annotatableType: MediaAnnotatatable}) => Promise<AxiosResponse<JsonResource<MediaAnnotation>>>;
		@purchaseInvoices.Action(Actions.FETCH_MEDIA_ANNOTATION_FOR_PROJECT_RELATIONSHIP) fetchMediaAnnotationsForProjectRelationship!: (payload: {projectRelationshipId: string; mediaId: string}) => Promise<AxiosResponse<JsonResource<MediaAnnotation>>>;
		@purchaseInvoices.Action(Actions.FETCH_MEDIA_ANNOTATION_FOR_INVOICE_PDF) fetchMediaAnnotationsForInvoice!: (payload: {invoiceId: string; mediaId: string}) => Promise<AxiosResponse<JsonResource<MediaAnnotation>>>;
		@purchaseInvoices.Action(Actions.FETCH_MEDIA_ANNOTATIONS_BY_MEDIA_ID) fetchMediaAnnotationsByMediaId!: (mediaId: string) => Promise<AxiosResponse<JsonResource<MediaAnnotation[]>>>;

		// vuex getters
		@purchaseInvoices.Getter(Getters.GET_INVOICE) invoice!: PurchaseInvoice;
		@purchaseInvoices.Getter(Getters.GET_PROJECT_RELATIONSHIP_STATUSES) projectRelationshipStatuses!: ProjectRelationshipStatus[];
		@purchaseInvoices.Getter(Getters.GET_IS_LOADING) isLoading!: boolean;

		// props
		@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;
		@PropSync('projectRelationship', { type: Object as PropType<ProjectRelationship> }) projectRelationshipSync!: ProjectRelationship;
		@Prop({type: Array as PropType<User[]>, default: () => [] as User[] }) users!: User[]
		
		// vars
		viewMode: string = 'modal';
		state: {
			projectRelationshipSaving: boolean,
			projectRelationshipSaved: boolean,
			pdfAnnotationChanged: boolean,
			enableAssignedTos: boolean,
		} = {...this.newState()}
		content: {
			projectRelationshipValidationErrors: any,
			assignedTo: User | null,
			editMediaAnnotation: MediaAnnotation | null,
			mediaAnnotation: {
                current: MediaAnnotation|null, 
                all: MediaAnnotation[],
                annotatableType: MediaAnnotatatable|undefined,
                annotatableId: string|undefined,
            },
        } = {...this.newContentState()}

		// handlers
		onChangeProject(project: any) {
			this.projectRelationshipSync.project = project;
		}
		onClickClose(value: any): void {
			this.cancelMediaAnnotation()
			this.closeModal();
			this.content = { ...this.newContentState() }
			this.state = { ...this.newState() }
		}
		onClickSaveProjectRelationship(statusName: string) {
			this.state = {...this.state, projectRelationshipSaving: true }

			// before saving, copy state
			const beforeSave = {...this.projectRelationshipSync}

			// get status
			let status = this.projectRelationshipStatuses.find(s => s.name === 'pending_claim');
			if(statusName) {
				status = this.projectRelationshipStatuses.find(s => s.name === statusName);
			}

			// set data
			this.projectRelationshipSync.status = status
			if(status && status.name === 'requested') this.projectRelationshipSync.assignedTo = this.content.assignedTo
			else this.projectRelationshipSync.assignedTo = this.projectRelationshipSync.assignedTo ? this.projectRelationshipSync.assignedTo : this.currentUser
			
			// validated
			const validated = this.validated()
			if(validated) {
				// save
				this.save(this.projectRelationshipSync).then(resp => {
					if(this.content.editMediaAnnotation && this.content.mediaAnnotation.annotatableType && this.content.mediaAnnotation.annotatableId) {
						const mediaId = this.invoice.pdf && this.invoice.pdf.id ? this.invoice.pdf.id.toString() : undefined
						if(mediaId) {
							this.saveMediaAnnotation({
								mediaAnnotation: this.content.editMediaAnnotation,
								mediaId: mediaId,
								annotatableId: this.content.mediaAnnotation.annotatableId,
								annotatableType: this.content.mediaAnnotation.annotatableType
							}).catch(err => {
								console.error(err)
							})
						}
					}
				}).catch((err) => {
					throw err
				}).finally(() => {
					this.state = {...this.state, projectRelationshipSaved: true, projectRelationshipSaving: false }
					this.content = { ...this.newContentState() }
					this.state = { ...this.newState() }
					this.closeModal()
				});
			} else {
				this.projectRelationshipSync = beforeSave
				// update state
				this.state = {...this.state, projectRelationshipSaved: false, projectRelationshipSaving: false }
			}
		}
		loadAnnotationsForInvoice(invoice: PurchaseInvoice|undefined) {
            const invoiceId = invoice && invoice.id ? invoice.id.toString() : undefined
			const mediaId = this.invoice.pdf && this.invoice.pdf.id ? this.invoice.pdf.id.toString() : undefined
            
            if(invoiceId && mediaId) {
                try {
					// const invoiceMediaAnnotationResult = await this.fetchMediaAnnotationsForProjectRelationship({projectRelationshipId: projectRelationshipId, mediaId: mediaId});
					this.fetchMediaAnnotationsForInvoice({invoiceId: invoiceId, mediaId: mediaId}).then(invoiceMediaAnnotationResult => {
						this.fetchMediaAnnotationsByMediaId(mediaId).then(allMediaAnnotationsResult => {
							this.content = {...this.content, 
								mediaAnnotation: { 
									current: invoiceMediaAnnotationResult.data.data, 
									all: allMediaAnnotationsResult.data.data,
									annotatableType: MediaAnnotatatable.PURCHASE_INVOICE,
									annotatableId: invoiceId,
								}
							}
							this.state = {...this.state, pdfAnnotationChanged: false}
						}).catch(err => {
							throw err
						})
					}).catch(err => {
						throw err
					});
                } catch(e) {
                    this.content = {...this.content, mediaAnnotation: { current: null, all: [], annotatableType: undefined, annotatableId: undefined }}
                    throw e;
                }
            }
        }
		cancelMediaAnnotation() {
            this.content = {...this.content, mediaAnnotation: { current: null, all: [], annotatableType: undefined, annotatableId: undefined }}
        }
		validated(): boolean {
			this.content = {...this.content, projectRelationshipValidationErrors: {} }
			// const projectRelationshipValidator = this.$v.content && this.$v.content.projectRelationship
			const projectRelationshipValidator = this.$v.projectRelationshipSync
			if(projectRelationshipValidator) projectRelationshipValidator.$touch()

			if(this.$v.$invalid) {
				if(projectRelationshipValidator && projectRelationshipValidator.project && !projectRelationshipValidator.project.required) {
					this.content = {...this.content, projectRelationshipValidationErrors: {...this.content.projectRelationshipValidationErrors, project: this.$t('validation.required') }}
				}
				if(projectRelationshipValidator && projectRelationshipValidator.contractorType && !projectRelationshipValidator.contractorType.required) {
					this.content = {...this.content, projectRelationshipValidationErrors: {...this.content.projectRelationshipValidationErrors, contractorType: this.$t('validation.required') }}
				}
				if(projectRelationshipValidator && projectRelationshipValidator.accountingLedgerItem && !projectRelationshipValidator.accountingLedgerItem.required) {
					this.content = {...this.content, projectRelationshipValidationErrors: {...this.content.projectRelationshipValidationErrors, accountingLedgerItem: this.$t('validation.required') }}
				}
				return false
			}
			return true;
		}
		save(item: ProjectRelationship) {
			return new Promise<AxiosError|AxiosResponse>((resolve, reject) => {
				let projectRelationship = item ? {...item} : null
			
				if(projectRelationship) {
					// projectRelationship = this.onBeforeSave(projectRelationship)
					this.saveProjectRelationship(projectRelationship)
						.then((result: AxiosResponse<JsonResource<ProjectRelationship>>) => {
							resolve(result)
						})
						.catch((err: AxiosError) => {
							if (err.response && err.response.status === 422) {
								this.content = { ...this.content, projectRelationshipValidationErrors: err.response.data.errors }
							} else {
								this.state = {...this.state, projectRelationshipSaved: false, projectRelationshipSaving: false }
								throw err;
							}
							reject(err)
						});
				}
			})
		}

		// methods
		closeModal() {
			this.showModalSync = false
		}
		newContentState() {
			return {
				projectRelationship: new ProjectRelationshipEntity(),
				projectRelationshipValidationErrors: {},
				assignedTo: null,
				editMediaAnnotation: null,
				mediaAnnotation: {
					current: null, 
					all: [],
					annotatableType: undefined,
					annotatableId: undefined,
				},
			}
		}
		newState() {
			return {
				projectRelationshipSaved: false,
				projectRelationshipSaving: false,
				showModalAnnotatePdf: false,
				pdfAnnotationChanged: false,
				enableAssignedTos: false,
			}
		}
		signAmount(amount: number, type: TransactionType) {
            return signAmountProjectRelationship(amount, type);
		}

		// getters
		get currentUser() {
            return authStore.auth.getters.user.value || null;
        }
		get remainder() {
			let remainder = 0;
			if(this.invoice && this.invoice.projectRelationships && this.projectRelationshipSync) {
				const total = this.invoice.projectRelationships.filter(pr => pr.id !== this.projectRelationshipSync.id).map(pr => pr.total ? this.signAmount(pr.total, pr.type) : 0).reduce((prev, curr) => prev+curr, 0)
				const difference = (this.invoice.total ? Math.abs(this.invoice.total) : 0) - Math.abs((total + (this.projectRelationshipSync.total ? this.signAmount(this.projectRelationshipSync.total, this.projectRelationshipSync.type) : 0)));
				const base = (this.projectRelationshipSync.total ? this.projectRelationshipSync.total : 0)
				remainder = difference > 0 ? base + difference : difference < 0 ? base + difference : 0
			}
			return remainder
		}

		// watchers
		@Watch('showModal', {immediate: true})
		onWatchShowModal(showModal: boolean) {
			if(showModal && this.invoice && this.invoice.pdf && this.invoice.pdf.file) {
				this.loadAnnotationsForInvoice(this.invoice)
			}
		}
		
		// lifecycle
		mounted(): void {
			//
		}

		created(): void {
		}

		destroyed(): void {
			//
		}
	}
