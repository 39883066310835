
  import { defineComponent, PropType, ref } from 'vue';
  import helpers from '@/modules/calendar/services/helper.service';
  import { SubPeriod } from '@/modules/calendar/interfaces/project/subPeriod';

  export default defineComponent({
    name: 'WorkSubPeriodComponent',
    emits: ['toggleSequence'],
    components: {},
    props: {
      subPeriod: {
        type: Object as PropType<SubPeriod>,
        required: true,
      },
    },
    setup(props) {
      const expanded = ref(true);
      const toggleExpandedState = (): void => {
        expanded.value = !expanded.value;
      };
      return {
        expanded,
        toggleExpandedState,
      };
    },
  });
