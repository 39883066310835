import {UseFilterContext, FilterValue} from '../types/useFilter';
import { setReactiveProp } from '../utils';
import { del } from 'vue';

const activateFilter = async (ctx: UseFilterContext, filterNames: string[]) => {
  const { state } = ctx;
  try {
    state.activeFilters = [...state.activeFilters.filter(f => !filterNames.includes(f)), ...filterNames];
    return Promise.resolve();
  } catch (err: any) { return Promise.reject(err); }
};

const deactivateFilter = async (ctx: UseFilterContext, filterNames: string[]) => {
  const { state } = ctx;
  try {
    state.activeFilters = [...state.activeFilters.filter(f => !filterNames.includes(f))];
    return Promise.resolve();
  } catch (err: any) { return Promise.reject(err); }
};

const groupFilter = async (ctx: UseFilterContext, filterNames: string[], groupName: string) => {
  const { state } = ctx;
  try {
    const currentGroup = state.groups[groupName];
    const newGroup = [...currentGroup.filter(f => !filterNames.includes(f)), ...filterNames];
    setReactiveProp(state.groups, groupName, newGroup);
    return Promise.resolve();
  } catch (err: any) { return Promise.reject(err); }
};

const ungroupFilter = async (ctx: UseFilterContext, filterNames: string[], groupName: string) => {
  const { state } = ctx;
  try {
    const currentGroup = state.groups[groupName];
    const newGroup = [...currentGroup.filter(f => !filterNames.includes(f))];
    setReactiveProp(state.groups, groupName, newGroup);
    return Promise.resolve();
  } catch (err: any) { return Promise.reject(err); }
};

const ungroupFilterFromAll = async (ctx: UseFilterContext, filterNames: string[]) => {
  const { state } = ctx;
  try {
    for (const [groupName] of Object.entries(state.groups)) {
      ungroupFilter(ctx, filterNames, groupName);
    }
    return Promise.resolve();
  } catch (err: any) { return Promise.reject(err); }
};

const isActiveFilter = async (ctx: UseFilterContext, filterName: string) => {
  const { state } = ctx;
  try {
    const res = state.activeFilters.includes(filterName);
    return Promise.resolve(res);
  } catch (err: any) { return Promise.reject(err); }
};

const setFilter = async (ctx: UseFilterContext, filterName: string, value: FilterValue, activate = true) => {
  const { state } = ctx;
  try {
    setReactiveProp(state.filters, filterName, value);
    if (activate) await activateFilter(ctx, [filterName]);
    else await deactivateFilter(ctx, [filterName]);
    return Promise.resolve();
  } catch (err: any) { return Promise.reject(err); }
};

const setFilters = async (ctx: UseFilterContext, filters: Map<string, FilterValue>, activate = true) => {
  try {
    filters.forEach((value, key) => {
      setFilter(ctx, key, value);
    });
    return Promise.resolve();
  } catch (err: any) { return Promise.reject(err); }
};

const clearFilter = async (ctx: UseFilterContext, filterName: string, activate = true) => {
  const { state } = ctx;
  try {
    const newFilterValue = null;
    setFilter(ctx, filterName, newFilterValue, activate);
    return Promise.resolve();
  } catch (err: any) { return Promise.reject(err); }
};

const deleteFilter = async (ctx: UseFilterContext, filterName: string) => {
  const { state } = ctx;
  try {
    del(state.filters, filterName);
    deactivateFilter(ctx, [filterName]);
    ungroupFilterFromAll(ctx, [filterName]);
    return Promise.resolve();
  } catch (err: any) { return Promise.reject(err); }
};

export const actions = {
  setFilter,
  setFilters,
  deleteFilter,
  groupFilter,
  ungroupFilter,
  activateFilter,
  deactivateFilter,
  clearFilter,
  isActiveFilter,
};
