import { SalesInvoice, SalesInvoiceRecordType } from "./types/entities";
import { ProgressResult } from '@/modules/sales-invoices/types/SalesInvoiceCalculatorResult';
import { Payment, PaymentEntity, TransactionType } from "../payments/types/entities";
import { summarizeBy as paymentsSummarizeBy } from '@/modules/payments/helpers';
import { Vue } from 'vue-property-decorator';

export function signAmount(amount: number, recordType: SalesInvoiceRecordType): number {
  let value = 0;
  switch (recordType.name) {
  case 'credit_note': value = -1*amount; break;
  case 'invoice': value = amount; break;
  default: break;
  }
  return value;
}

export function summarizeBy(key: any, items: SalesInvoice[], sign = true): number {
  return items.reduce((a: number, b: SalesInvoice) => a + (sign && b.recordType ? signAmount((b as any)[key] || 0, b.recordType) : ((b as any)[key] || 0)), 0);
}

export function calculatePaymentDifference(invoice: SalesInvoice, payments: Payment[]): number {
  if(invoice.paymentTotal && invoice.recordType && invoice.payments) {
    // use unsigned values for easier calculation
    const paymentTotal = invoice.paymentTotal;
    const creditPaymentsTotal = paymentsSummarizeBy('total', payments.filter(p => p.type === TransactionType.CREDIT), false);
    const debitPaymentsTotal = paymentsSummarizeBy('total', payments.filter(p => p.type === TransactionType.DEBIT), false);

    const balance = debitPaymentsTotal - creditPaymentsTotal;
    if (invoice.recordType.name === 'invoice') return -1 * (paymentTotal - balance);
    else if (invoice.recordType.name === 'credit_note') return -1 * (paymentTotal + balance);
  }

  return 0;
}

export function calculateTaxTotalForSalesInvoice(salesInvoice: SalesInvoice): number {
  if(salesInvoice.currency && salesInvoice.vat && salesInvoice.total !== undefined && salesInvoice.total !== null) {
    try {
      const total = Vue.prototype.$dinero({amount: salesInvoice.total, currency: salesInvoice.currency});
      // percentage or allocate functions on dinero produce wrong results for sfinx:
      //   examples:
      //   30509.43, tax 21, should result in 6406.98 (but allocate resulted in 6406.99)
      //   30509.43, tax 6, should result in 1830.57
      //   7.75, tax 21, should result in 1.63
      //   7.75, tax 6, should result in 0.47 (percentage with 'HALF_UP' gave 0.46)
      const taxTotal = salesInvoice.vat
        ? total.multiply(salesInvoice.vat.percentage / 100, 'HALF_UP')
        : Vue.prototype.$dinero({amount: 0, currency: salesInvoice.currency});

      return taxTotal.getAmount();
    } catch (error: any) {
      console.warn(error);
      return 0;
    }
  }
  return 0;
}

export function suggestPayment(invoice: SalesInvoice, payments: Payment[]): Payment {
  const payment = new PaymentEntity({ type: TransactionType.DEBIT });
  if(invoice.recordType && invoice.recordType.name === 'invoice') {
    payment.type = calculatePaymentDifference(invoice, payments) <= 0 ? TransactionType.DEBIT : TransactionType.CREDIT;
  } else if(invoice.recordType && invoice.recordType.name === 'credit_note') {
    payment.type = calculatePaymentDifference(invoice, payments) <= 0 ? TransactionType.CREDIT : TransactionType.DEBIT;
  }

  return payment;
}

export function calculateSalesInvoiceProgression(salesInvoice: SalesInvoice, previousInvoices: SalesInvoice[] = []): ProgressResult {
  const totalAmountOrdered = salesInvoice.progressSnapshot && salesInvoice.progressSnapshot.totalAmountOrdered || 0;
  const totalAmountExecuted = salesInvoice.progressSnapshot && salesInvoice.progressSnapshot.totalAmountExecuted || 0;
  const progresses = summarizeBy('total', previousInvoices.filter((invoice) => invoice.type && invoice.type.name === 'default'));
  const advances = summarizeBy('total', previousInvoices.filter((invoice) => invoice.type && invoice.type.name === 'advance'));
  const deductedAdvances = summarizeBy('deductedAdvance', previousInvoices);
  const openAdvances = advances - deductedAdvances;
  const firstAdvance = Math.round(advances/2);
  const invoicedProgressAndDeductedAdvanceTotal = progresses+deductedAdvances;

  // results
  const result = {
    openAdvancesOld: openAdvances,
    deductedAdvancesOld: deductedAdvances,
    totalAdvances: advances,
    openAdvances: 0,
    deductedAdvances: 0,
    toBeInvoiced: 0,
    advance: 0,
    toBeInvoicedWithAdvance: 0,
    total: 0,
    message: '',
  } as ProgressResult;

  const toBeInvoiced = totalAmountExecuted - invoicedProgressAndDeductedAdvanceTotal; // this.processedAmount - this.allProgressInvoicesAmount('total');

  // half of total ordered amount is not exceeded yet
  if(totalAmountExecuted < totalAmountOrdered/2) {
    // Factuurbedrag = “Uitgevoerd bedrag” - “totaal bedrag vorderingsfacturen” (Rekenvoorbeeld 1)
    result.toBeInvoiced = toBeInvoiced;
    result.toBeInvoicedWithAdvance = toBeInvoiced;
    result.total = toBeInvoiced;
    result.message = 'defaultResult';
  }
  // half of total ordered amount is exceeded
  else {

    // apply first ~50% advance?
    if(openAdvances > 0 && deductedAdvances === 0) {
      if(toBeInvoiced > firstAdvance) {
        result.toBeInvoiced = toBeInvoiced;
        result.advance = firstAdvance;
        result.toBeInvoicedWithAdvance = toBeInvoiced-firstAdvance;
        result.openAdvances = openAdvances-firstAdvance;
        result.deductedAdvances = firstAdvance;
        result.total = result.toBeInvoicedWithAdvance;
        result.message = 'firstAdvanceCanBeDeducted';
      } else {
        result.toBeInvoiced = toBeInvoiced;
        result.advance = 0;
        result.toBeInvoicedWithAdvance = result.toBeInvoiced-result.advance; // will probably be negative
        result.openAdvances = openAdvances;
        result.deductedAdvances = deductedAdvances;
        result.total = 0;
        result.message = 'firstAdvanceCannotBeDeductedTooSmall';
      }
    }

    // apply last ~95% advance
    else if(openAdvances > 0 && deductedAdvances > 0 && totalAmountExecuted >= totalAmountOrdered-openAdvances) {
      const customToBeInvoiced = totalAmountOrdered - invoicedProgressAndDeductedAdvanceTotal;
      if(toBeInvoiced >= openAdvances) {
        // result.toBeInvoiced = customToBeInvoiced;//toBeInvoiced;
        result.toBeInvoiced = toBeInvoiced;
        result.advance = openAdvances;
        // result.toBeInvoicedWithAdvance = customToBeInvoiced-openAdvances;
        result.toBeInvoicedWithAdvance = result.toBeInvoiced-openAdvances;
        result.openAdvances = 0;
        result.deductedAdvances = openAdvances+deductedAdvances;
        result.total = result.toBeInvoicedWithAdvance;
        result.message = 'finalAdvanceCanBeDeducted';
      } else {
        result.toBeInvoiced = customToBeInvoiced;//toBeInvoiced;
        result.advance = 0;
        result.toBeInvoicedWithAdvance = result.toBeInvoiced-openAdvances; // will probably be negative
        result.openAdvances = openAdvances;
        result.deductedAdvances = deductedAdvances;
        result.total = 0;
        result.message = 'finalAdvanceCannotBeDeductedTooSmall';
      }
    }

    // final scenario (after all advantages applied)
    else {
      result.toBeInvoiced = toBeInvoiced;
      result.advance = 0;
      result.toBeInvoicedWithAdvance = toBeInvoiced;
      result.openAdvances = openAdvances;
      result.deductedAdvances = deductedAdvances;
      result.total = toBeInvoiced;
      result.message = 'defaultResult';
    }
  }

  return result;
}