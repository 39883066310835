
import { defineComponent } from 'vue';
import authStore from "@/modules/login/store";

export default defineComponent({
  setup() {
    return {
      auth: authStore.auth
    };
  },
});
