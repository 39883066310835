

	import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

	@Component
	export default class DetailsSingleSelectComponent extends Vue {
		@PropSync('selectedValue', { type: Object }) syncedSelectedValue!: any;
		@Prop() iconPath!: string;
		@Prop() translationPath!: string;
		@Prop() disabled!: boolean;
		@Prop() itemText!: string;
		@Prop() listValues!: any[];
		@Prop() placeholder!: string;
	}
