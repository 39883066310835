
	import { Comment, User } from '@/modules/entities/types/entities';
	import { UsersService } from '@/services/users.service';
	import { PropType } from 'vue';
    import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import { PurchaseInvoicesService } from '../../services/purchase-invoices.service';
	import { PurchaseInvoice, PurchaseInvoiceEntity } from '../../types/entities';
	import { Actions, Getters } from '@/modules/purchase-invoices/store/types/StoreTypes';
	import { AxiosResponse } from 'axios';
	import { JsonResource } from '@/core/types/Entities';
import { sortNatural } from '@/core/helpers/Sort';
import dayjs from 'dayjs';
    import authStore from "@/modules/login/store";

	const purchaseInvoices = namespace('purchaseInvoices');
	const projects = namespace('projects');

	const usersService = new UsersService();
	const purchaseInvoicesService = new PurchaseInvoicesService();

	@Component
	export default class PurchaseInvoiceCommentsComponent extends Vue {

		// vuex actions
		@purchaseInvoices.Action(Actions.SAVE_COMMENT_TO_PURCHASE_INVOICE) saveCommentToPurchaseInvoice!: (payload: Comment) => Promise<AxiosResponse<JsonResource<Comment>>>;
		@purchaseInvoices.Action(Actions.DELETE_COMMENT) deleteComment!: (payload: Comment) => void;

		// vuex getters

		
		//props
		@PropSync('purchaseInvoice', { type: Object as PropType<PurchaseInvoice>, required: true, default: () => new PurchaseInvoiceEntity }) purchaseInvoiceSync!: PurchaseInvoice;
		@PropSync('validationErrors', { type: Object }) validationErrorsSync!: any;

		//vars
		content: {
			addComment: Comment,
			comment: Comment|null,
		} = this.newContentState()
		state: {
			addComment: boolean,
			editComment: boolean,
			deleteComment: boolean
		} = {
			addComment: true,
			editComment: false,
			deleteComment: false
		}

		// handlers
		onClickSaveEditComment(): void {
			if (this.content.comment && this.content.comment.id !== undefined && this.content.comment && this.content.comment.body.length) {
				this.saveCommentToPurchaseInvoice(this.content.comment).then(() => {
					this.state = {...this.state, editComment: false}
				})
			}
		}
		onClickSaveAddComment() {
			if(this.purchaseInvoiceSync.id && this.content.addComment && this.content.addComment.body.length) {
				const comment = {...this.content.addComment, commentableId: this.purchaseInvoiceSync.id} as Comment
				this.saveCommentToPurchaseInvoice(comment).then(result => {
					this.content = {...this.content, addComment: { body: '' } as Comment }
				})
			}
		}
		onClickEditComment(comment: Comment): void {
			this.content = {...this.content, comment: {...comment}}
			this.state.editComment = true;
		}
		onClickDeleteComment(comment: Comment): void {
			this.content = {...this.content, comment: {...comment}}
			this.state.deleteComment = true;
		}

		// methods
		getUserName(user: User) {
			return user.firstName + (user.lastName ? ' '+user.lastName : '')  + (user.username ? ' ('+user.username+')' : '');
		}
		newContentState() {
			return {
				addComment: { body: '' } as Comment,
				comment: null,
			}
		}
		displayCommentsConfirmDialog(comment: Comment): boolean {
			const equalIds = this.content.comment && this.content.comment.id ? this.content.comment.id === comment.id : false
			return this.state.deleteComment && equalIds;
		}
		isRecentComment(comment: Comment) {
			if(comment.created) {
				const createdAt = dayjs.utc(comment.created.toString())
				return createdAt.isAfter(dayjs.utc().subtract(1, 'month'))
				// return createdAt.isAfter(dayjs.utc().subtract(15, 'day'))
			}
			return false
		}

		// getters
		get currentUserName() {
			const user = authStore.auth.getters.user.value || null;
			return user ? this.getUserName(user) : '';
		}
		get comments() {
			const comments = JSON.parse(JSON.stringify(this.purchaseInvoiceSync.comments)) as Comment[] || [] as Comment[]
			// reverse ordered, most recent comment is first
			return comments.sort((a,b) => sortNatural(b.created,a.created, {numeric: false, locale: this.$i18n.locale}))
		}

        // setters

        // watchers
	}
