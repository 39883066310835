
    import { defineComponent, PropType, computed, ref, watch } from 'vue';
    import { PasswordMatchingResult, PasswordSet } from '@/modules/settings/store/modules/users/types'

    export default defineComponent({
        name: 'UserPasswordSection',
        emits: ['update:password'],
        props: {
            passwordMatchingResult: { type: Object as PropType<PasswordMatchingResult>, default: null },
            validationErrors: { type: Object, default: null }
        },
        setup(props, { emit }) {

            /**
             * Data
             * 
             */
            const password = ref('')
            const passwordConfirmation = ref('')
            const showPassword = ref(false);
            const showPasswordConfirmation = ref(false);

            /**
             * Handlers
             */
            const onInputPassword = (pw: string) => { password.value = pw; emitPassword() }
            const onInputPasswordConfirmation = (pw: string) => { passwordConfirmation.value = pw; emitPassword() }

            /**
             * Methods
             * 
             */
            const emitPassword = () => {
                emit('update:password', { password: password.value, passwordConfirmation: passwordConfirmation.value } as PasswordSet )
            }

            /**
             * Computed
             * 
             * Computed values are cached. Only when the underlying data changes, the value gets re-computed.
             *   so, a cached copy of the prop in combination with :value and @input on form elements, we can 
             *   emit changes and let the parent mutate the changes 
             */
            // const passwordCopy = computed(() => {
            //     return JSON.parse(JSON.stringify(props.password))
            // })

            /**
             * Watchers
             * 
             */
            // watch(props.user, (newValue, oldValue) => {
            //     console.log(newValue)
            //     password.value = ''
            //     passwordConfirmation.value = ''
            // }, { immediate: true, deep: true })
            
            return {
                password,
                passwordConfirmation,
                onInputPassword,
                onInputPasswordConfirmation,
                emit,
                showPassword,
                showPasswordConfirmation,
            }
        },
    })
