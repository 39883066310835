import dayjs from "dayjs";
import {Supplier, Division } from "@/modules/calendar/interfaces/project/interfaces";
import Vue from "vue";
import {WorkDay, WorkDayEntity} from "@/modules/calendar/interfaces/project/workDay";
import {Quote} from "@/modules/calendar/interfaces/project/quote";
import {PlannerPeriod} from "@/modules/calendar/interfaces/project/plannerPeriod";
import store from '@/modules/calendar/store';
import {cloneDeep} from 'lodash';
import { WorkDaySequence } from '../interfaces/project/workDaySequence';
import useProjectHelpers from '@/modules/projects/helpers/useProjectHelpers';
import { Team } from '../interfaces/project/team';
import { TeamMember2, Absence } from '@/modules/settings/types/entities';
import { SubPeriod } from '../interfaces/project/subPeriod';
import { ContractorType } from '../types/ContractorType';
import { Comment } from '@/modules/entities/types/entities';

const helpers = {
  getDatesArray: (startDate: string, endDate: string, showHolidays: boolean, holidays: { name: string; days: string[] }[]): string[] => {
    const dateArray = [];
    let currentDate = dayjs.utc(startDate);
    const stopDate = dayjs.utc(endDate);
    const holidayDays: string[] = holidays
      .map(x => x.days)
      .reduce((prev, curr) => {
        prev.push(...curr);
        return prev;
      }, []);
    while (currentDate <= stopDate) {
      if (!showHolidays) {
        const dayOfWeek = currentDate.day();
        // 0 = sunday, 6 = saturday
        if (dayOfWeek !== 0 && dayOfWeek !== 6 && !holidayDays.some(x => currentDate.format('YYYY-MM-DD') === x)) {
          dateArray.push(currentDate.format('YYYY-MM-DD'));
        }
        currentDate = currentDate.add(1, 'day');
      } else {
        dateArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      }
    }
    return dateArray;
  },
  isSupplier: (item: any): item is Supplier => {
    return ('type' in item && item.type === 'supplier') || ('commercialName' in item);
  },
  isDivision: (item: any): item is Division => {
    return ('type' in item && item.type === 'division') || ('teams' in item);
  },

  getDaysCount: (start: string, end: string) => {
    const startDate = dayjs.utc(start);
    const endDate = dayjs.utc(end);
    return 1+endDate.diff(startDate, 'day');
  },

  isDateAToday: (date: string): boolean => {
    const evaluatedDate = new Date(date).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    return evaluatedDate === today;
  },

  isDateInQuote: (quote: Quote, date: string): WorkDay | undefined => {
    return quote.workDays && quote.workDays.find(x => x.day === date && x.contractorable?.type !== 'team');
  },

  isDateAPeriodStartDate: (quote: Quote, date: string): boolean | undefined => {
    return quote.plannerPeriods && quote.plannerPeriods.some(x => x.plannerStartDate === date);
  },

  getDateBackgroundColour: (date: string, workDays?: WorkDay[]): string => {
    if (workDays && workDays.length) {
      const day = workDays.find(x => x.day === date);
      if (day) {
        return day.contractorPermAssigned || day.contractorTypePermAssigned ? '#00B5A0' : day.contractorTempAssigned || day.contractorTypeTempAssigned ? '#008677' : '#70ffe8';
      }
    }
    if (helpers.isDateAToday(date)) {
      return '#89b3ee';
    }
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const color = dayjs(date).week() % 2 ? '#eeeeee' : '#e7e7e7';

    return isNaN(dayOfWeek) ? '' :
      ['#d2d4dd', color, color, color, color, color, '#d2d4dd'][dayOfWeek];
  },

  getWorkingTeamsOnDay: (allTeams: Team[], workDays: WorkDay[], date: string): Team[] => {
    const teams = allTeams && allTeams.filter((team: Team) => {
      return workDays && workDays.find((x: WorkDay) => {
        return x.day === date && x.sequences.length > 0 && x.contractorable && x.contractorable.id === team.id;
      });
    });
    return teams || [];
  },

  dateRange: (startDate: string, endDate: string, steps = 1) => {
    const dateArray = [];
    const currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().split('T')[0] );
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
  },

  /**
	 * Get TeamMembers in a Team that are active for a given date.
	 * If no date is specified, today will be used a reference date.
	 */
  getActiveTeamMembers: (team: Team, date?: string): TeamMember2[] => {
    let teamMembers: TeamMember2[] = [];
    const day = date ? dayjs.utc(date) : dayjs.utc();
    if(team.teamMembers && team.teamMembers.length) {
      teamMembers = team.teamMembers.filter((tm: TeamMember2) => {
        const start = dayjs.utc(tm.start);
        const end = dayjs.utc(tm.end);
        return start <= day && day <= end;
      });
    }
    return teamMembers;
  },

  /**
	 * Within a Period, a Team may not be 'active' for the full Period. This could cause issues because Teams may be planned for a full Period,
	 * but may only realy be available for several days (according to the Team Member assignments)
	 *   eg: a Period of 3 days for which "Team 1" is only available the first 2 days (there are only team members assigned for the first 2 days).
	 * So, what should happen in the Calendar/Planner in such case?
	 *  1. "Team 1" should be visible for every day in the Period? But make it visible to the user that, for certain days, the Team is in fact not available as there are no Team Members assigned?
	 *  2. OR "Team 1" should not be visible at all for the full Period
	 *
	 * The former option can be done by using `Array.some()`
	 * The latter option can be done by using `Array.every()`
	 */
  getActiveTeamsBetween: (teams: Team[], start: string, end: string, onlyTeamsActiveInFullRange = true): Team[] => {
    const dates = helpers.dateRange(start, end);
    const filteredTeams = teams && teams.filter((team: Team) => {
      if(onlyTeamsActiveInFullRange) {
        // include Team if they are 'active' for everyday within this date range
        return dates.every(date => {
          const teamMembers = helpers.getActiveTeamMembers(team, date);
          return teamMembers && teamMembers.length > 0;
        });
      } else {
        // include Team if they have at least 1 day for which they are 'active' whithin this date range
        return dates.some(date => {
          const teamMembers = helpers.getActiveTeamMembers(team, date);
          return teamMembers && teamMembers.length > 0;
        });
      }
    });
    return filteredTeams ? filteredTeams : [];
  },

  /**
	 * Get Absences for a given TeamMember.
	 * If no date is specified, today will be used a reference date.
	 */
  getTeamMemberAbsences: (teamMember: TeamMember2, date?: string): Absence[] => {
    let foundAbsences: Absence[] = [];
    const day = date ? dayjs.utc(date) : dayjs.utc();
    if(teamMember && teamMember.employee && teamMember.employee.absences?.length) {
      foundAbsences = teamMember.employee.absences.filter((absence: Absence) => {
        const absenceDay = absence.day ? dayjs.utc(absence.day) : null;
        return absenceDay?.isSame(day, 'date');
      });
    }
    return foundAbsences;
  },

  /**
	 * Get Absences for a given Team.
	 * If no date is specified, today will be used a reference date.
	 */
  getTeamAbsences: (team: Team, date?: string): Absence[] => {
    let foundAbsences: Absence[] = [];
    if(team?.teamMembers) {
      team.teamMembers.forEach(teamMember => {
        foundAbsences = [...foundAbsences, ...helpers.getTeamMemberAbsences(teamMember, date)];
      });
    }
    return foundAbsences;
  },

  /**
	 * This will return a series of WorkDays based on:
	 *   - the given Contractor Type
	 *   - where WorkDay ids are part of the subperiod
	 *   - (IMPORTANT) where the WorkDay day equals the start date of the subperiod. This means that the other
	 *     WorkDays are not included BUT will/need to "follow" changes made on the returned WorkDays upon changing/saving.
	 */
  getWorkDaysForSubPeriod: (period: PlannerPeriod, subPeriod: SubPeriod, includeContractorTypes: ContractorType[]): WorkDay[] => {
    // const subPeriodDateRange = helpers.dateRange(subPeriod.startDate, subPeriod.endDate);
    // const workDays = period.workDays?.filter((wd) =>
    //   subPeriodDateRange.includes(wd.day) &&
    //         includeContractorTypes.includes(wd.contractorable?.type as ContractorType)
    //     );
    // return workDays || [];
    const workDays = period.workDays?.filter((wd) => subPeriod.workDaysIds.includes(wd.id));
    return workDays || [];
  },

  // TODO:
  //   - create helper that makes dummy WorkDays for available teams in PlannerPeriod when a PlannerPeriod is fetched (see useCalendar)
  //   - see `mergeExistingAndActiveTeamWorkDaysForSubPeriod` as a base to start from? Maybe apply it in the useCalendar composables actions
  makeTeamWorkDaysForDays: (teamsWithTeamMembers: Team[], startDate: string, dummyWorkDay?: WorkDay, endDate?: string): WorkDay[] => {
    const start = dayjs.utc(startDate);
    const end = dayjs.utc(endDate ? endDate : startDate);
    const dummy = dummyWorkDay ? dummyWorkDay : new WorkDayEntity();
    // const activeTeams = helpers.getActiveTeamsBetween(teamsWithTeamMembers, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    const days = helpers.dateRange(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    const workDays: WorkDay[] = [];
    days.forEach(day => {
      const dayDate = dayjs.utc(day);
      const activeTeams = helpers.getActiveTeamsBetween(teamsWithTeamMembers, dayDate.format('YYYY-MM-DD'), dayDate.format('YYYY-MM-DD'));
      activeTeams.forEach(team => {
        const dayAsId = team.id.toString() + dayDate.format('YYYYMMDD');
        const workDay = {
          ...cloneDeep(dummy),
          id: -1 * Number.parseInt(dayAsId), // FIXME: what would be the best solution here?
          day: day,
          contractorable: cloneDeep(team),
        };
        workDays.push(workDay);
      });
    });
    return workDays;
  },
  makeContractorWorkDaysForDays: (contractor: Supplier|Division, startDate: string, dummyWorkDay?: WorkDay, endDate?: string): WorkDay[] => {
    const start = dayjs.utc(startDate);
    const end = dayjs.utc(endDate ? endDate : startDate);
    const dummy = dummyWorkDay ? dummyWorkDay : new WorkDayEntity();
    const days = helpers.dateRange(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    const workDays: WorkDay[] = [];
    days.forEach(day => {
      const workDay = {
        ...cloneDeep(dummy),
        id: -1 * contractor.id, // FIXME: what would be the best solution here?
        day: day,
        contractorable: cloneDeep(contractor),
      };
      workDays.push(workDay);
    });
    return workDays;
  },
  mergeWorkDays: (existingWorkDays: WorkDay[], availableWorkDays: WorkDay[]): WorkDay[] => {
    const filteredAvailableWorkDays: WorkDay[] = [...availableWorkDays];
    const removedWorkDays: WorkDay[] = [];
    existingWorkDays.forEach(existingWorkDay => {
      const workDayIdx = filteredAvailableWorkDays.findIndex((wd) => {
        return wd.day === existingWorkDay.day &&
					(wd.contractorable && existingWorkDay.contractorable &&
						wd.contractorable.type === existingWorkDay.contractorable.type &&
						wd.contractorable.id === existingWorkDay.contractorable.id);
      });
      // double workDay => remove it from available workDays
      if(workDayIdx > -1) removedWorkDays.push(...filteredAvailableWorkDays.splice(workDayIdx, 1));
    });
    return [
      ...existingWorkDays,
      ...filteredAvailableWorkDays,
    ];
  },

  mergeSubPeriods: (subPeriods: SubPeriod[], workDays: WorkDay[]): SubPeriod[] => {
    let usedWorkDayIds: number[] = [];
    const updatedSubPeriods = subPeriods.map((sp) => {
      const spCopy = cloneDeep(sp);
      const periodDates = helpers.dateRange(spCopy.startDate, spCopy.endDate);
      let workDayIds: number[] = [...spCopy.workDaysIds];

      periodDates.forEach((date) => {
        workDayIds = [...workDayIds, ...workDays.filter((wd) => {
          return wd.day === date;
        }).map(wd => wd.id)];
      });

      spCopy.workDaysIds = [...new Set(workDayIds)];
      usedWorkDayIds = [...usedWorkDayIds, ...spCopy.workDaysIds];
      return spCopy;
    });
    return [...updatedSubPeriods];
  },

  /**
	 * Merge existing WorkDays for Teams (even if a Team is is not active anymore) and
	 * build dummy WorkDays for Teams that are active for a given subperiod.
	 *
	 * @deprecated Do not use. Instead use getWorkDaysForSubPeriod
	 */
  mergeExistingAndActiveTeamWorkDaysForSubPeriod: (teams: Team[], period: PlannerPeriod, subPeriod: SubPeriod, contractorTypes: ContractorType[]) => {
    const existingTeamWorkDays = helpers.getWorkDaysForSubPeriod(period, subPeriod, contractorTypes);
    const existingWorkDaysTeamIds = existingTeamWorkDays.map(workDay => workDay.contractorable && workDay.contractorable.id);
    const activeTeams = helpers.getActiveTeamsBetween(teams, subPeriod.startDate, subPeriod.endDate);
    const teamsToBuildWorkdaysFor = activeTeams.filter(team => !existingWorkDaysTeamIds.includes(team.id));
    const newWorkDays = teamsToBuildWorkdaysFor.map((team, idx) => {
      return new WorkDayEntity({
        id: -1 * team.id, // what would be the best solution here
        day: subPeriod.startDate,
        periodId: period.id,
        quoteId: period.quote ? period.quote.id : undefined,
        sequences: [],
        contractorable: cloneDeep(team),
      });
    });

    return [...existingTeamWorkDays, ...newWorkDays];
  },

  getTeamSequences: (plannerPeriod: PlannerPeriod, team: Team, date: string): WorkDaySequence[] => {
    if (!plannerPeriod || !team || !date) return [];
    let sequences: WorkDaySequence[] = [];

    if(plannerPeriod.workDays) {
      const workDay = plannerPeriod.workDays.find(wd => {
        return wd.day === date && wd.sequences.length > 0 && wd.contractorable && wd.contractorable.id === team.id;
      });
      if(workDay) {
        sequences = cloneDeep(workDay.sequences);
      }
    }
    return sequences;
  },

  isProjectExpanded: (projectId: number, expandedProjects: Array<number>): boolean => {
    return expandedProjects.includes(projectId);
  },

  isDivisionExpanded: (divisionId: number, expandedDivisions: Array<number>): boolean => {
    return expandedDivisions.includes(divisionId);
  },

  isSupplierExpanded: (supplierId: number, expandedSuppliers: Array<number>): boolean => {
    return expandedSuppliers.includes(supplierId);
  },

  getDateTextColour: (date: string): string => {
    if (helpers.isDateAToday(date)) {
      return 'white';
    }
    return 'black';
  },

  getPlannerPeriodByDay: (quote: Quote, date: string): PlannerPeriod | undefined => {
    if (!quote.workDays || !quote.plannerPeriods) {
      return undefined;
    }

    return quote.plannerPeriods && quote.plannerPeriods.find(period => {
      return period.workDays && period.workDays.some(workDay => {
        return workDay.day === date && workDay.quoteId === quote.id && workDay.periodId === period.id;
      });
    });
  },

  getDayDescription: (quote: Quote, date: string): WorkDay|undefined => {
    return quote.workDays?.find((workDay: WorkDay) => {
      return workDay.day === date && workDay.description;
    });
  },

  dayHasDescription: (quote: Quote, date: string): boolean => {
    return !!helpers.getDayDescription(quote, date);
  },

  formatDate: (date: string | undefined, format = 'DD/MM/YYYY') => {
    return date ? dayjs(date).format(format) : undefined;
  },

  getProjectStatusIcon: (projectStatusId: number): string => {
    return useProjectHelpers().getProjectStatusIcon(projectStatusId);
  },

  getProjectStatusColour: (projectStatusId: number): string => {
    return useProjectHelpers().getProjectStatusColour(projectStatusId);
  },

  recalculateContractorColumn(): void {
    Vue.prototype.$nextTick(() => {
      const gridHeaderButtonsElement = document.getElementById('grid-header__buttons');
      if (gridHeaderButtonsElement) store.calendar.actions.setHeaderCalendarLeftOffset(gridHeaderButtonsElement.clientWidth + 18.5); // 20 is padding
    });
  },

  handleGridScroll(event: Event) {
    Vue.prototype.$nextTick(() => {
      const target = event.target as HTMLDivElement;
      const gridHeader = document.getElementById('calendar-header');
      const scrollbar = document.getElementById('scrollbar');
      if (gridHeader && scrollbar) {
        this.handleScrollEvent(target, gridHeader, scrollbar);
      } else {
        event.preventDefault();
      }
    });
  },

  handleScrollEvent(target: HTMLDivElement, ...args: HTMLDivElement[] | HTMLElement[]) {
    args.forEach((x: HTMLElement | HTMLDivElement) => {
      x.scrollLeft = target.scrollLeft;
    });
    const elements = document.getElementsByClassName('planner-grid__list__collaborators-grid__container');
    Array.from(elements).forEach((el: Element, idx: number) => {
      el.scrollLeft = target.scrollLeft;
    });
  },

  isDateAHoliday(date: string): boolean {
    const environment = store.calendarSettings.getters.environment.value;
    return !!environment && environment.holidays.some((holiday) => holiday.days.some((day: string) => day === date));
  },

  getHolidaysNames(date: string): string[] {
    const environment = store.calendarSettings.getters.environment.value;
    const holidayNames: string[] = [];
    environment && environment.holidays.forEach((holiday: { name: string; days: string[] }) => {
      if (holiday.days.some((day: string) => day === date)) holidayNames.push(holiday.name);
    });
    return holidayNames;
  },
};

export default helpers;