
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import { WorkflowTaskStatus } from '@/modules/workflow/types/entities';

  @Component
  export default class WorkflowSettingsTaskStatusesListComponent extends Vue {
    @Prop() taskStatuses!: WorkflowTaskStatus[];
    @Prop() getTaskStatusColour!: Function;
    @Prop() getTaskStatusIcon!: Function;

    @Emit('onEditTaskStatus')
    onEmitEditTaskStatus(taskStatus: WorkflowTaskStatus): WorkflowTaskStatus {
      return taskStatus;
    }

    @Emit('onDeleteTaskStatus')
    onEmitDeleteTaskStatus(taskStatus: WorkflowTaskStatus): WorkflowTaskStatus {
      return taskStatus;
    }
  }
