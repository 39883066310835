import {WorkflowState} from '@/modules/workflow/store/types/WorkflowState';
import {GetterTree} from 'vuex';
import {RootState} from '@/core/types/RootState';
import {Getters} from '@/modules/workflow/store/types/StoreTypes';

export const getters: GetterTree<WorkflowState, RootState> = {
	[Getters.GET_WORKFLOW_TASKS]: (state: WorkflowState) => {
		return state.tasks;
	},
	[Getters.GET_WORKFLOW_TASK_STATUSES]: (state: WorkflowState) => {
		return state.taskStatuses;
	},
	[Getters.GET_WORKFLOW_SELECTED_PROJECTS]: (state: WorkflowState) => {
		return state.selectedProjects;
	},
	[Getters.GET_WORKFLOW_ALL_PROJECTS]: (state: WorkflowState) => {
		return state.allProjects;
	},
	[Getters.GET_WORKFLOW_EXPANDED_PROJECTS]: (state: WorkflowState) => {
		return state.expandedProjects;
	},
	[Getters.GET_WORKFLOW_EXPANDED_TASKS]: (state: WorkflowState) => {
		return state.expandedTasks;
	},
	[Getters.GET_WORKFLOW_FUNCTION_GROUPS]: (state: WorkflowState) => {
		return state.functionGroups;
	},
	[Getters.GET_WORKFLOW_LOADING]: (state: WorkflowState) => {
		return state.loading;
	},
	[Getters.GET_WORKFLOW_SHOW_FUNCTION_GROUPS]: (state: WorkflowState) => {
		return state.showFunctionGroups;
	},
	[Getters.GET_WORKFLOW_USER_ID]: (state: WorkflowState) => {
		return state.userId;
	},
	[Getters.GET_WORKFLOW_ANY_TASK_EXPANDED]: (state: WorkflowState) => {
		return state.anyTaskExpanded;
	},
	[Getters.GET_WORKFLOW_FILTERS]: (state: WorkflowState) => {
		return state.filters
	},
	[Getters.GET_WORKFLOW_MULTIPLE_TASK_STATUS]: (state: WorkflowState) => {
		return state.multiTaskStatus;
	},
	[Getters.GET_WORKFLOW_SIDEBAR_OPEN]: (state: WorkflowState) => {
		return state.sidebarOpen;
	},
	[Getters.GET_WORKFLOW_HIGHLIGHTED_PROJECT_ID]: (state: WorkflowState) => {
		return state.highlightedProjectId;
	}
};
