
  import { defineComponent } from 'vue';
  import AbsencePeriodModalDeleteForm from '../layouts/absence-period-modal-delete-form.vue';

  export default defineComponent({
    name: 'AbsencePeriodModalDelete',
    components: {
      AbsencePeriodModalDeleteForm,
    },
    setup() {
      return {};
    },
  });
